import Icon from "../../../../../assets/spaceicons/link.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import { BaseComponentConfigState } from "../../../types";

import Config, { ensureSpaceLink } from "./Config";
import errorSelector from "./Config/errorSelector";
import SpaceLink from "./SpaceLink";
import { SpaceLinkComponent, SpaceLinkProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceLinkProperties,
  SpaceLinkComponent,
  BaseComponentConfigState
> = {
  type: "LINK",
  displayName: "Link",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "DETAIL",
    "CARD_LIST",
    "TABLE",
    "FLEX_BOX"
  ]),
  Component: SpaceLink,
  ConfigurationComponent: Config,
  ensureComponent: ensureSpaceLink,
  componentConfigReducer: commonComponentReducer,
  defaultElementLayout: new ElementLayout({
    width: "auto",
    height: "auto",
    snapToGrid: true
  }),
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Content],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
