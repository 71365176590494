import { Select as AntSelect, Button as AntButton } from "antd";
import styled from "styled-components";

export const SectionList = styled.div`
  & > section:last-child {
    border-bottom: 0;
  }
`;

export const Section = styled.section`
  border-bottom: 1px solid ${props => props.theme.inputBackgroundColor};
  padding-bottom: ${props => props.theme.spacermd};
  margin-bottom: 12px;
`;

export const SectionHeader = styled.div`
  margin-bottom: 12px;
  font-weight: ${props => props.theme.boldFontWeight};
`;

export const SectionContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;
`;

export const Select = styled(AntSelect)`
  width: 220px;
`;

export const RefreshAttributesButton = styled(AntButton)`
  margin-left: ${props => props.theme.spacermd};
`;
RefreshAttributesButton.displayName = "RefreshAttributesButton";
