import { AttributeTypes } from "../../../../../constants";
import { BindingShape, Binding } from "../../../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { toProperty } from "../common/util";

export const getSchema = (node: ComponentNode): Binding[] => {
  if (node.component.properties.validation_type === toProperty(AttributeTypes.FILE)) {
    return [
      {
        name: "value",
        shape: BindingShape.OBJECT,
        attributes: [
          {
            name: "size",
            shape: BindingShape.SCALAR
          },
          {
            name: "name",
            shape: BindingShape.SCALAR
          },
          {
            name: "type",
            shape: BindingShape.SCALAR
          },
          {
            name: "data",
            shape: BindingShape.SCALAR
          }
        ]
      },
      {
        name: "error",
        shape: BindingShape.UNKNOWN
      }
    ];
  } else {
    return [
      {
        name: "value",
        shape: BindingShape.SCALAR
      },
      {
        name: "error",
        shape: BindingShape.UNKNOWN
      }
    ];
  }
};
