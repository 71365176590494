import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

interface Data {
  deleteQueue: {
    ok: boolean;
  };
}

interface Variables {
  id: string;
}

const QUERY = gql`
  mutation DeleteQueue($id: ID!) {
    deleteQueue(id: $id) {
      ok
    }
  }
`;

export default function useDeleteQueue(options?: MutationHookOptions<Data, Variables>) {
  return useMutation<Data, Variables>(QUERY, options);
}
