import React from "react";

import FilterListManagerSection from "../../../common/FilterListManager";
import { DetailConfigState } from "../reducer";

interface Props {
  state: DetailConfigState;
  title: string;
}
export default function FiltersConfigSection({ title, state }: Props) {
  const { draftComponent, filtersOptions } = state;
  const { columns } = draftComponent.properties;

  if (!columns) return null;

  return <FilterListManagerSection title={title} filtersOptions={filtersOptions} />;
}
