import styled from "styled-components";

export const EditorDrawer = styled.div<{ offsetRight: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: ${props => props.offsetRight}px;
  bottom: 0;
  background-color: #2a2b2e;
  z-index: 1000;
  color: #d9d9d9;
`;
