import React from "react";

import { Input } from "antd";
import styled from "styled-components";

import { SpaceComponentObject } from "../../../../../../types";
import SortableList, { SortableItemCompact } from "../../../../../common/SortableList";
import Spacer from "../../../../../common/Spacer";
import { useTransformationActionContext } from "../../../../layout/TransformationContext/TransformationContext";
import { LayoutUnit, ElementLayout } from "../../../../layout/util";
import { InsertComponentButtons } from "../../../../SpaceConfig/AddComponentPopover";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import useComponentNode from "../../../../util/useComponentNode";
import {
  findSpaceComponentPackage,
  useStableSpaceContext
} from "../../../SpaceContext/StableSpaceContext";
import Button from "../../common/Button";
import ChildComponentItem from "../../common/ColumnListManager/ChildComponentItem";
import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import { ConfigSection, ConfigPanelPopper } from "../../common/ConfigPanel";
import {
  useSpaceConfigPanelContext,
  ConfigPanelActionTypes
} from "../../common/ConfigPanel/ConfigPanelContext";
import { createComponentPath } from "../../contexts/ComponentPathContext";

export default function ChildrenSection() {
  const configPanelContext = useSpaceConfigPanelContext();
  const { state } = useComponentConfigContext();
  const { draftComponent } = state;

  return (
    <ConfigSection
      id="children-config-section"
      title="Components"
      onAdd={() =>
        configPanelContext.dispatch({
          type: ConfigPanelActionTypes.OPEN_POPPER,
          payload: { popperIdentifier: "add-child-popper" }
        })
      }
    >
      <ChildList childComponents={state.draftComponent.componentTreeNodes} />
      <AddChildPopper parent={draftComponent} />
    </ConfigSection>
  );
}

function ChildList({ childComponents }: { childComponents: SpaceComponentObject[] }) {
  const spaceConfigContext = useSpaceConfigContext();
  const configPanelContext = useSpaceConfigPanelContext();
  const { select } = useTransformationActionContext();
  const parentNode = useComponentNode();
  const parentPath = parentNode?.path || "";

  if (childComponents.length === 0)
    return (
      <EmptyState>
        <EmptyStateMessage>This panel is empty.</EmptyStateMessage>
        <FinePrint>Add items with the + button.</FinePrint>
      </EmptyState>
    );

  return (
    <>
      <SortableList
        isCompact
        onSort={(currentIndex: number, nextIndex: number) => {
          spaceConfigContext.dispatch({
            type: "MOVE_COMPONENT",
            payload: {
              sourcePath: createComponentPath(
                parentPath,
                childComponents[currentIndex],
                {
                  index: currentIndex
                }
              ),
              destinationPath: parentPath,
              index: nextIndex
            }
          });
        }}
      >
        {childComponents.map((c, i) => {
          const key = `${c.slug}-${i}`;
          const isPopperOpen = configPanelContext.state.activePopperIdentifier === key;
          const pkg = findSpaceComponentPackage(c.type);
          if (pkg === undefined) {
            throw new Error(`Expected to find package for ${c.type}`);
          }

          return (
            <SortableItemCompact
              id={key}
              key={key}
              sortKey={key}
              isSelected={isPopperOpen}
              isHidden={undefined}
              errorMessage={
                spaceConfigContext.shouldDisplayError(c.slug)
                  ? "This component has a validation error."
                  : null
              }
              onClick={() => {
                configPanelContext.dispatch({
                  type: ConfigPanelActionTypes.OPEN_POPPER,
                  payload: {
                    popperIdentifier: key
                  }
                });
              }}
              onRemove={() => {
                spaceConfigContext.dispatch({
                  type: "REMOVE_COMPONENT",
                  payload: {
                    slug: c.slug
                  }
                });
              }}
            >
              <ChildComponentItem component={c} showErrorIcon={false} />
              {isPopperOpen && (
                <ConfigPanelPopper
                  popperId={key}
                  popperReferenceElement={document.getElementById(key) || undefined}
                  onCancel={() => {
                    configPanelContext.dispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    });
                  }}
                >
                  <ConfigSection title={pkg.displayName}>
                    <label>Type</label>
                    <Input disabled value={pkg.displayName} />
                    <Spacer size="md" />
                    <Button
                      type="link"
                      onClick={() => {
                        select(c.slug);
                      }}
                    >
                      Go to this component
                    </Button>
                  </ConfigSection>
                </ConfigPanelPopper>
              )}
            </SortableItemCompact>
          );
        })}
      </SortableList>
    </>
  );
}

function AddChildPopper({ parent }: { parent: SpaceComponentObject }) {
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const spaceConfigContext = useSpaceConfigContext();
  const configPanelContext = useSpaceConfigPanelContext();
  const { select } = useTransformationActionContext();

  const hostableChildComponentTypes = React.useMemo(
    () =>
      getSpaceComponentPackages()
        .filter(p => p.allowedHosts.has("FLEX_BOX"))
        .map(p => p.type),
    [getSpaceComponentPackages]
  );

  return (
    <ConfigPanelPopper
      popperId="add-child-popper"
      popperReferenceElement={
        document.getElementById("children-config-section") || undefined
      }
      onCancel={() => {
        configPanelContext.dispatch({
          type: ConfigPanelActionTypes.CLOSE_POPPER
        });
      }}
    >
      <ScrollableConfigSection title="Add Component">
        <InsertComponentButtons
          componentTypes={hostableChildComponentTypes}
          onClick={componentType => {
            const slug = spaceConfigContext.predictSlug(componentType);
            spaceConfigContext.dispatch({
              type: "INSERT_COMPONENT",
              payload: {
                parentSlug: parent.slug,
                componentType,
                componentConfig: {
                  layout: {
                    width: LayoutUnit.AUTO,
                    height: LayoutUnit.AUTO
                  } as ElementLayout
                }
              }
            });
            select(slug);
            configPanelContext.dispatch({
              type: ConfigPanelActionTypes.CLOSE_POPPER
            });
          }}
        />
      </ScrollableConfigSection>
    </ConfigPanelPopper>
  );
}

const EmptyState = styled.div`
  text-align: center;
`;

const EmptyStateMessage = styled.div`
  font-size: ${props => props.theme.defaultFontSize};
  color: ${props => props.theme.textColor};
  padding: ${props => props.theme.spacersm} 0;
  font-weight: 500;
`;

const FinePrint = styled.div`
  font-size: ${props => props.theme.smallFontSize};
  color: ${props => props.theme.textColorMid};
`;

const ScrollableConfigSection = styled(ConfigSection)`
  max-height: calc(100vh - ${props => props.theme.spacermd});
  overflow: auto;
`;
