import {
  SpaceConfigState as _SpaceConfigState,
  SpaceConfigDispatch as _SpaceConfigDispatch
} from "./SpaceConfigContext/useSpaceConfig/reducer";

export { default } from "./SpaceConfigRoot";
export { makeComponent } from "./SpaceConfigContext/useSpaceConfig";
export type SpaceConfigState = _SpaceConfigState;
export type SpaceConfigDispatch = _SpaceConfigDispatch;

export type SpaceConfigMenuType =
  | "APP_DATA"
  | "COMPONENTS"
  | "FUNCTION_CONFIG"
  | "COMPONENT_TREE";

export interface SpaceConfigMenuPathItem<T = any> {
  type: SpaceConfigMenuType;
  props?: T;
}
