import React from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import AutomationsIcon from "../../assets/automations-icon.svg";
import QueuesIcon from "../../assets/queues-icon.svg";
import SpacesIcon from "../../assets/spaces-icon.svg";
import useAuthUser from "../../components/common/hooks/useAuthUser";
import usePaths from "../../components/common/hooks/usePaths";
import { A, H6, P } from "../../components/common/StyledComponents";
import withErrorBoundary from "../../components/hoc/withErrorBoundary";
import { useConfigContext } from "../../ConfigContext";

const GET_CHANGES_REQUIRED = gql`
  query DowngradeRequirementsQuery {
    getDowngradeRequirements {
      changesRequired
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 48px 32px 24px;
`;

const NavRoot = styled.ul`
  margin: 0;
  padding: 0;
`;

const NavItemRoot = styled.li`
  list-style: none;
  margin-bottom: ${props => props.theme.spacermd};
`;

const AppNavItemRoot = styled.li<{ active: boolean }>`
  display: flex;
  list-style: none;
  align-items: center;
  margin: ${props => props.theme.spacerxs} 0;
  font-size: 12px;
  font-weight: 500;
  padding: 12px 16px;
  color: ${props => (props.active ? "#fff" : props.theme.textColor)}
  background-color: ${props =>
    props.active ? props.theme.darkBackgroundColor : "none"};
  border-radius: 4px;
  cursor: pointer;
`;

const DowngradeNoticeIconWarning = styled(ExclamationCircleFilled)<any>`
  color: ${props => props.theme.errorColor};
  font-size: 13px;
  margin-right: 20px;
  margin-left: 6px;
`;

const IconContainer = styled.div`
  margin-right: 16px;
  height: 24px;
  width: 24px;
`;

const InactiveLink = styled(Link)`
  color: ${props => props.theme.textColorMid};
  font-size: 13px;
  line-height: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const ActiveLink = styled(InactiveLink)`
  color: ${props => props.theme.textColor};
  font-weight: 600;
`;

function Nav() {
  const paths = usePaths();
  const { authUser } = useAuthUser();
  const { isOnPrem } = useConfigContext();

  const { data: downgradeRequirementData } = useQuery(GET_CHANGES_REQUIRED, {
    fetchPolicy: "cache-and-network"
  });

  const downgradeData = downgradeRequirementData?.getDowngradeRequirements || {};
  const changesRequired = downgradeData.changesRequired;

  return (
    <Root>
      <div>
        {window.FEATURE_FLAGS.includes("QUEUES") && (
          <>
            <NavRoot>
              <AppNavItem icon={<SpacesIcon />} path={paths.getSpacesHome()}>
                Spaces
              </AppNavItem>
              <AppNavItem icon={<QueuesIcon />} path={paths.queuesapp_getHome()}>
                Queues
              </AppNavItem>
              {window.FEATURE_FLAGS.includes("AUTOMATIONS") && (
                <AppNavItem
                  icon={<AutomationsIcon />}
                  path={paths.automations_getHome()}
                >
                  Automations
                </AppNavItem>
              )}
            </NavRoot>
            <hr />
          </>
        )}
        {authUser?.isAdmin && (
          <NavRoot>
            {!window.FEATURE_FLAGS.includes("QUEUES") && (
              <NavItem path={paths.getSpacesHome()}>Home</NavItem>
            )}
            <NavItem path={paths.getUsersSettings()}>Users</NavItem>
            <NavItem path={paths.getSettingsRolesAndPermissions()}>
              Roles and Permissions
            </NavItem>
            <NavItem path={paths.getDataSourceSettings()}>Data and Functions</NavItem>
            <NavItem path={paths.getAuthSettings()}>Authorization</NavItem>
            <NavItem path={paths.getSecuritySettings()}>Security</NavItem>
            {!isOnPrem && !authUser.organization.customLicensing && (
              <>
                {authUser.organization.currentPlan?.slug === "enterprise" ? (
                  <NavItem path={paths.getProductSettings()}>Change Plan</NavItem>
                ) : (
                  <NavItem path={paths.getProductSettings()}>Upgrade Plan</NavItem>
                )}
                <NavItem path={paths.getBillingSettings()}>
                  Account & Billing {changesRequired && <DowngradeNoticeIconWarning />}
                </NavItem>
              </>
            )}
          </NavRoot>
        )}
      </div>
      <PromoBox />
    </Root>
  );
}

export default withErrorBoundary(Nav);

function NavItem({ children, path }: { children: React.ReactNode; path: string }) {
  const active = window.location.pathname.indexOf(path) > -1;
  const Link = active ? ActiveLink : InactiveLink;
  return (
    <NavItemRoot>
      <Link to={path}>{children}</Link>
    </NavItemRoot>
  );
}

function AppNavItem({
  children,
  path,
  icon
}: {
  children: React.ReactNode;
  icon: React.ReactNode;
  path: string;
}) {
  const navigate = useNavigate();
  const active = window.location.pathname.indexOf(path) > -1;
  const onClick = () => {
    navigate(path);
  };
  return (
    <AppNavItemRoot onClick={onClick} active={active}>
      <IconContainer>{icon}</IconContainer>
      <span>{children}</span>
    </AppNavItemRoot>
  );
}

const PromoBoxRoot = styled.aside`
  display: flex;
  flex-direction: column;
  margin: 0 -12px;
  padding: ${props => props.theme.spacermd}
  border-radius: 8px;
  border: solid 1px ${props => props.theme.borderGreyStrong};
`;

const PromoBoxA = styled(A)`
  display: flex;
  align-items: center;
`;

const OpenNewWindowIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 10.6667H1.33333V1.33333H6V0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6H10.6667V10.6667ZM7.33333 0V1.33333H9.72667L3.17333 7.88667L4.11333 8.82667L10.6667 2.27333V4.66667H12V0H7.33333Z"
      fill="currentColor"
    />
  </svg>
);

function PromoBox() {
  return (
    <PromoBoxRoot>
      <H6>New feature - dashboard</H6>
      <P>Share and organize your team's spaces.</P>
      <PromoBoxA
        href="https://www.internal.io/blog/new-dashboard-experience"
        target="_blank"
      >
        Learn more&nbsp;
        <OpenNewWindowIcon />
      </PromoBoxA>
    </PromoBoxRoot>
  );
}
