import React from "react";

import { ComponentConfigProps } from "../../../../../types";
import SelectComponentConfig, {
  InlineConfig,
  TerseConfig
} from "../common/SelectBackedComponent/Config";

export default function SpaceDropdownConfig({ slug }: ComponentConfigProps) {
  return <SelectComponentConfig slug={slug} isMulti={false} />;
}

export function SpaceDropdownTerseConfig({ slug }: ComponentConfigProps) {
  return <TerseConfig slug={slug} isMulti={false} />;
}

export function SpaceDropdownInlineConfig({ slug }: ComponentConfigProps) {
  return <InlineConfig slug={slug} isMulti={false} />;
}
