/**
 * Component for listing and adding expressions.
 * --
 * UI notes:
 * Company Settings > Custom Auth Provider > Add Step > Add Expression
 */
import React from "react";

import { Icon, Input } from "antd";
import produce from "immer";
import styled from "styled-components";

import Editor from "../../../../common/Editor";
import { BasePanel } from "../../../../common/StyledComponents";
import { Expression, ExpressionStepOptions, PendingExpressionStep } from "../reducer";

import { ExpirationFields } from "./common/ExpirationFields";
import { ErrorInput } from "./Errors";
import { Checkbox, Control, Item, Label } from "./StyledComponents";

import { StepProps } from ".";

const Root = styled.div`
  .ant-select:not(:first-child) {
    margin-top: ${props => props.theme.spacersm};
  }
`;

const StyledBasePanel = styled(BasePanel)`
  padding: ${props => props.theme.spacermd};
`;

const ExpressionsLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExpressionLabel = styled(Item)`
  display: flex;
  justify-content: space-between;
`;

const AddIcon = styled(Icon)`
  font-size: ${props => props.theme.spacermd};
`;

const DeleteIcon = styled(Icon)`
  font-size: 14px;
`;

type Props = StepProps<PendingExpressionStep>;

export const selectExports = (step: PendingExpressionStep): string[] => {
  return step.options.expressions?.map((e: Expression) => `${e.name}`) || [];
};

const ExpressionStep = ({ step, state, dispatch, updateOptions }: Props) => {
  return (
    <Root>
      <Item>
        <ExpressionsLabel>
          Expressions{" "}
          <AddIcon
            type="plus"
            onClick={() =>
              updateOptions(
                produce(step.options, (draft: ExpressionStepOptions) => {
                  draft.expressions = draft.expressions || [];
                  draft.expressions.push({
                    name: `expression${draft.expressions.length + 1}`,
                    value: ""
                  });
                })
              )
            }
          />
        </ExpressionsLabel>
        <Control>
          {!step.options.expressions?.length && (
            <span>
              There are currently no expressions defined. Add one to get started.
            </span>
          )}
          {step.options.expressions?.map((e: Expression, index: number) => (
            <StyledBasePanel key={`exp-${index}`}>
              <Item>
                <ExpressionLabel>
                  <div>Name</div>
                  <DeleteIcon
                    type="delete"
                    onClick={() =>
                      updateOptions(
                        produce(step.options, (draft: ExpressionStepOptions) => {
                          draft.expressions.splice(index, 1);
                        })
                      )
                    }
                  />
                </ExpressionLabel>
                <Control>
                  <ErrorInput
                    state={state}
                    path={`step${step.order}.expressions[${index}].name`}
                  >
                    <Input
                      value={e.name}
                      onChange={e =>
                        updateOptions(
                          produce(step.options, (draft: ExpressionStepOptions) => {
                            draft.expressions[index].name = e.target.value;
                          })
                        )
                      }
                    />
                  </ErrorInput>
                </Control>
              </Item>
              <Item>
                <Label>Value</Label>
                <Control>
                  <ErrorInput
                    state={state}
                    path={`step${step.order}.expressions[${index}].value`}
                  >
                    <Editor
                      value={e.value}
                      mode="javascript"
                      height="96px"
                      onChange={(value: string) =>
                        updateOptions(
                          produce(step.options, (draft: ExpressionStepOptions) => {
                            draft.expressions[index].value = value;
                          })
                        )
                      }
                    />
                  </ErrorInput>
                </Control>
              </Item>
            </StyledBasePanel>
          ))}
        </Control>
      </Item>
      <h4>Refresh Options</h4>
      <Checkbox
        checked={step.options.persist}
        onChange={e => {
          updateOptions(
            produce(step.options, (draft: ExpressionStepOptions) => {
              draft.persist = e.target.checked;
            })
          );
        }}
      >
        Save expression results until they expire
      </Checkbox>
      {step.options.persist && (
        <ExpirationFields
          step={step}
          dispatch={dispatch}
          updateOptions={updateOptions}
        />
      )}
    </Root>
  );
};

ExpressionStep.selectExports = selectExports;
export default ExpressionStep;
