import React, { useState } from "react";

import { ExclamationCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { H6, B3 } from "../../../components/common/StyledComponents";
import { Edge, EnvironmentNode } from "../../../types";
import { planName } from "../../util/billing";

interface DowngradeNoticeProps {
  changesRequired: boolean;
  schedule: {
    current_phase: {
      end_date: number;
    };
    phases: [
      {
        plans: [
          {
            price: {
              product: {
                name: string;
              };
            };
          }
        ];
      }
    ];
  };
  requirements: {
    userLimit: {
      limit: number | null;
      value: number;
    };
    roles: {
      limit: number | null;
      value: number;
    };
    twoFactor: {
      limit: boolean;
      value: boolean;
    };
    sso: {
      limit: boolean;
      value: boolean;
    };
    permissions: {
      limit: boolean;
      value: boolean;
    };
    environments: {
      limit: boolean;
      value: boolean;
    };
    dataSources: {
      limit: number | null;
      value: number;
    };
  };
  product?: {
    name: string;
  };
  envs?: Edge<EnvironmentNode>[];
}

const DowngradeNoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
`;

const DowngradeNoticeText = styled.div`
  display: inline;
`;

const DowngradeNoticeB3 = styled(B3)`
  margin-bottom: 0;
`;

const DowngradeNoticeB3Warning = styled(DowngradeNoticeB3)`
  color: ${props => props.theme.errorColor};
`;

const DowngradeNoticeIconWarning = styled(ExclamationCircleFilled)<any>`
  color: ${props => props.theme.errorColor};
  font-size: 20px;
  margin-right: 20px;
`;

const DowngradeNoticeIconSuccess = styled(CheckCircleFilled)<any>`
  color: ${props => props.theme.successDarkGreenPressed};
  font-size: 20px;
  margin-right: 20px;
`;

const RequirementsLinkContainer = styled.div`
  flex-grow: 1;
  text-align: right;

  a {
    margin-bottom: 0;
  }
`;

const Requirement = styled.div`
  display: flex;
  margin-bottom: 20px;

  &.requirement-error {
    color: ${props => props.theme.errorColor};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
`;

const Extended = styled.div``;

export default function DowngradeNotice({
  product,
  schedule,
  requirements,
  changesRequired,
  envs
}: DowngradeNoticeProps) {
  const targetProduct =
    schedule?.phases[schedule.phases.length - 1].plans[0]?.price.product;

  const [requirementsOpen, setRequirementsOpen] = useState(false);

  const { userLimit, twoFactor, sso, environments, dataSources } = requirements;

  const toggleShowRequirements = () => {
    setRequirementsOpen(!requirementsOpen);
  };

  return (
    <DowngradeNoticeContainer>
      <Top>
        {changesRequired && <DowngradeNoticeIconWarning />}
        <DowngradeNoticeText>
          <H6>
            Your plan will switch from {planName(product?.name)} to {targetProduct.name}{" "}
            on{" "}
            {moment.unix(schedule.current_phase.end_date).utc().format("MMMM Do, YYYY")}
            .
          </H6>

          {changesRequired ? (
            <DowngradeNoticeB3Warning>
              Your account requires changes. Make sure your account meets the
              requirements by this date, or it will be locked.
            </DowngradeNoticeB3Warning>
          ) : (
            <DowngradeNoticeB3>
              Your account meets all requirements to downgrade.
            </DowngradeNoticeB3>
          )}
        </DowngradeNoticeText>
        {Object.keys(requirements).length > 0 && (
          <RequirementsLinkContainer>
            <Link onClick={toggleShowRequirements} to="">
              {requirementsOpen ? "Hide" : "Show"} requirements
            </Link>
          </RequirementsLinkContainer>
        )}
      </Top>

      {Object.keys(requirements).length > 0 && requirementsOpen && (
        <Extended>
          <hr />

          {!twoFactor.limit && (
            <>
              {twoFactor.value && !twoFactor.limit ? (
                <Requirement className="requirement-error">
                  <DowngradeNoticeIconWarning className="warning" />
                  <p>
                    'Require employees to use Two-Step Verification' option is turned
                    off
                  </p>
                </Requirement>
              ) : (
                <Requirement>
                  <DowngradeNoticeIconSuccess className="success" />
                  <p>
                    'Require employees to use Two-Step Verification' option is turned
                    off
                  </p>
                </Requirement>
              )}
            </>
          )}

          {!sso.limit && (
            <>
              {sso.value && !sso.limit ? (
                <Requirement className="requirement-error">
                  <DowngradeNoticeIconWarning className="warning" />
                  <p>'Require employees to use Single Sign-On' option is turned off</p>
                </Requirement>
              ) : (
                <Requirement>
                  <DowngradeNoticeIconSuccess className="success" />
                  <p>'Require employees to use Single Sign-On' option is turned off</p>
                </Requirement>
              )}
            </>
          )}

          {envs && !environments.limit && (
            <>
              {environments.value && !environments.limit ? (
                <Requirement className="requirement-error">
                  <DowngradeNoticeIconWarning className="warning" />
                  <p>
                    Maximum of 1 environment (currently exceeding max environments by{" "}
                    {envs.length - 1})
                  </p>
                </Requirement>
              ) : (
                <Requirement>
                  <DowngradeNoticeIconSuccess className="success" />
                  <p>Maximum of 1 environment</p>
                </Requirement>
              )}
            </>
          )}

          {dataSources.limit && (
            <>
              {dataSources.value > dataSources.limit ? (
                <Requirement className="requirement-error">
                  <DowngradeNoticeIconWarning />
                  <p>
                    Maximum of {dataSources.limit} data sources (currently exceeding max
                    data sources by {dataSources.value - dataSources.limit})
                  </p>
                </Requirement>
              ) : (
                <Requirement>
                  <DowngradeNoticeIconSuccess className="success" />
                  <p>Maximum of {dataSources.limit} data sources</p>
                </Requirement>
              )}
            </>
          )}

          {userLimit.limit && (
            <>
              {userLimit.value > userLimit.limit ? (
                <Requirement className="requirement-error">
                  <DowngradeNoticeIconWarning />
                  <p>
                    Maximum of {userLimit.limit} users (currently exceeding max users by{" "}
                    {userLimit.value - userLimit.limit})
                  </p>
                </Requirement>
              ) : (
                <Requirement>
                  <DowngradeNoticeIconSuccess className="success" />
                  <p>Maximum of {userLimit.limit} users</p>
                </Requirement>
              )}
            </>
          )}
        </Extended>
      )}
    </DowngradeNoticeContainer>
  );
}
