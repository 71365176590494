import React from "react";

import ExitButton from "./ExitButton";
import MoreOptions from "./MoreOptions";
import PublishButton from "./PublishButton";
import SaveButton from "./SaveButton";

export default function HeaderButtons() {
  return (
    <>
      <SaveButton />
      <PublishButton />
      <MoreOptions />
      <ExitButton />
    </>
  );
}
