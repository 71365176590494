import { PartialQueue, LocalQueues, Queue, toQueue, TaskFilters } from "../../../index";

import { Result, useQueueBySlug } from "./queries/QueueBySlug";

const getPartialQueue = (
  slug: string,
  taskFilters: Partial<TaskFilters>
): PartialQueue =>
  LocalQueues[slug] ? LocalQueues[slug] : { slug: slug, isLocal: false, taskFilters };

const maybeResolveQueue = (
  partial: PartialQueue,
  result: Result
): Queue | undefined => {
  if (LocalQueues[partial.slug]) return LocalQueues[partial.slug];
  const { loading, data } = result;
  return !loading && data?.queueBySlug
    ? toQueue(data.queueBySlug, partial.taskFilters)
    : undefined;
};

export default function useQueue(
  slug: string,
  filters: Record<string, any>
): Queue | undefined {
  const partialQueue = getPartialQueue(slug, filters);

  const result = useQueueBySlug({
    variables: { slug: partialQueue.slug, ...filters },
    skip: partialQueue.isLocal
  });

  return maybeResolveQueue(partialQueue, result);
}
