import styled from "styled-components";

export const Action = styled.div`
  padding: 16px 0;

  & + & {
    border-top: 1px solid ${props => props.theme.containerPrimaryTint};
  }
`;

export const ActionSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
`;
