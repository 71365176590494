import React, { ReactNode } from "react";

import { Dropdown, Menu, Icon } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import styled from "styled-components";

import ErrorBoundary from "../../../../../common/ErrorBoundary";
import { ErrorIcon } from "../../../../../common/Icons";
import { BasePanel } from "../../../../../common/StyledComponents";

interface Props {
  title: ReactNode;
  headerChildren?: ReactNode;
  actions?: ReactNode;
  className?: string;
  hasError?: boolean;
  children: ReactNode;
}

const Root = styled(BasePanel)`
  min-width: 150px;
  min-height: 150px;
  height: 100%;
  /* HACK: override media query specificity in base component */
  @media (min-width: 0) {
    margin-bottom: 0;
  }
`;
Root.displayName = "BasePanel";

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacerlg};
  border-bottom: solid 1px ${props => props.theme.borderGrey};
  color: ${props => props.theme.textColorOnDark};
  /* in safari Header can collapse and get clipped. Ensure text visible */
  min-height: 77px;
`;

const Title = styled.h2`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  width: fit-content;
  margin: 0;
  padding-right: ${props => props.theme.spacersm};
  font-size: ${props => props.theme.h2FontSize};
  line-height: 1.2;
  letter-spacing: -0.75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--body-font-family);
  font-weight: 400;
  color: ${props => props.theme.textColor};
`;
Title.displayName = "Title";

const ActionsContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  flex-basis: 40%;
  overflow: hidden;
`;

const Body = styled.div`
  position: relative;
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  top: ${props => props.theme.spacersm};
  left: ${props => props.theme.spacersm};
`;

export default function Panel({
  title,
  headerChildren = null,
  className = "",
  hasError = false,
  actions,
  children,
  ...others
}: Props) {
  const bodyRef = React.useRef(null);
  const showHeader = !!title || !!headerChildren;
  return (
    <Root className={className} {...others}>
      {hasError && <StyledErrorIcon />}
      {showHeader && (
        <Header>
          <Title>{title}</Title>
          {!!headerChildren && <ActionsContainer>{headerChildren}</ActionsContainer>}
          {!!actions && <MoreMenu actions={actions} />}
        </Header>
      )}
      <Body ref={bodyRef}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Body>
    </Root>
  );
}

const MoreMenuRoot = styled.div`
  padding-left: ${props => props.theme.spacersm};
  color: ${props => props.theme.textColor};
`;

const MoreMenuIcon = styled(Icon)`
  font-size: 18px;
`;

function MoreMenu({ actions }: { actions: ReactNode }) {
  let items;
  if (React.Children.count(actions) === 1) {
    items = <MenuItem>{actions}</MenuItem>;
  } else {
    items = React.Children.toArray(actions).map(c => <MenuItem>{c}</MenuItem>);
  }
  const menu = <Menu>{items}</Menu>;

  return (
    <MoreMenuRoot>
      <Dropdown trigger={["click"]} overlay={menu}>
        <MoreMenuIcon type="more" />
      </Dropdown>
    </MoreMenuRoot>
  );
}
