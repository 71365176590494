import gql from "graphql-tag";

import { TREE_NODE_FRAGMENT } from "../../../../SpaceRoot/queries";

export const SPACE_UPDATE = gql`
  mutation SpaceUpdate(
    $spaceInput: SpaceInput!
    $environmentsToPublish: [ID]!
    $currentEnvironmentId: ID!
  ) {
    spaceUpdate(
      spaceInput: $spaceInput
      environmentIdsToPublish: $environmentsToPublish
    ) {
      ok
      message
      source
      space {
        id
        name
        slug
        publishedVersion(environmentId: $currentEnvironmentId) {
          __typename
          ... on NoPublishedVersionErrorResult {
            message
          }
          ... on SpaceVersionNode {
            id
            componentTreeNodes {
              ...treeNodeFields
              componentTreeNodes {
                ...treeNodeFields
                componentTreeNodes {
                  ...treeNodeFields
                  componentTreeNodes {
                    ...treeNodeFields
                    componentTreeNodes {
                      ...treeNodeFields
                      componentTreeNodes {
                        ...treeNodeFields
                        componentTreeNodes {
                          ...treeNodeFields
                          componentTreeNodes {
                            ...treeNodeFields
                            componentTreeNodes {
                              ...treeNodeFields
                              componentTreeNodes {
                                ...treeNodeFields
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        latestVersion {
          id
          componentTreeNodes {
            ...treeNodeFields
            componentTreeNodes {
              ...treeNodeFields
              componentTreeNodes {
                ...treeNodeFields
                componentTreeNodes {
                  ...treeNodeFields
                  componentTreeNodes {
                    ...treeNodeFields
                    componentTreeNodes {
                      ...treeNodeFields
                      componentTreeNodes {
                        ...treeNodeFields
                        componentTreeNodes {
                          ...treeNodeFields
                          componentTreeNodes {
                            ...treeNodeFields
                            componentTreeNodes {
                              ...treeNodeFields
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TREE_NODE_FRAGMENT}
`;
