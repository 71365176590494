import React, { useContext } from "react";

import { useMutation } from "react-apollo";

import { UPDATE_CUSTOMER_MUTATION } from "../../../graphql/queries";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import { ModalNew } from "../../common/Modal";

import { BillingContext } from "./BillingContext";
import BillingInformationForm from "./BillingInformationForm";
import TAX_COUNTRY_OPTIONS from "./TaxCountryOptions";

interface ValidateableForm extends HTMLElement {
  requestSubmit(): void;
}

export default function UpdateBillingInfoModal({
  visible,
  onCancel,
  formValidation,
  setFormValidation
}: {
  visible: boolean;
  onCancel: any;
  formValidation: boolean;
  setFormValidation: (value: boolean) => void;
}) {
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER_MUTATION, {
    onCompleted: () => {
      window.location.reload();
    }
  });

  const context = useContext(BillingContext);

  const handleOnUpdateBillingInfo = async () => {
    if (context.billingCountry) {
      context.setBillingCountryError("");
    } else {
      context.setBillingCountryError("Billing country is required.");
      return;
    }

    setFormValidation(true);

    const billingForm = document.getElementById("billingForm") as ValidateableForm;

    if (billingForm && billingForm.requestSubmit) {
      billingForm.requestSubmit();

      if (billingForm?.querySelector("input:invalid")) {
        return;
      }
    }

    setFormValidation(false);

    updateCustomer({
      variables: {
        customerName: context.billingCompany || "",
        addressCity: context.billingCity || "",
        addressCountry: context.billingCountry || "",
        addressStreet1: context.billingAddress || "",
        addressZip: context.billingZip || "",
        taxIdType: context.taxIdType || "",
        taxId: context.taxId || ""
      }
    });
  };

  return (
    <ModalNew
      title="Update Company Information"
      visible={visible}
      onCancel={onCancel}
      width={570}
      footer={[
        <ButtonNew key="cancel" onClick={() => onCancel()}>
          Cancel
        </ButtonNew>,
        <ButtonNew
          type="primary"
          key="update"
          onClick={() => handleOnUpdateBillingInfo()}
          disabled={!TAX_COUNTRY_OPTIONS[context.billingCountry]}
        >
          Update Company Information
        </ButtonNew>
      ]}
    >
      <BillingInformationForm
        formValidation={formValidation}
        showTaxIds={false}
        onSubmit={e => e.preventDefault()}
      />
    </ModalNew>
  );
}
