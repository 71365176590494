import React from "react";

import { ErrorValues } from "../../../../../constants";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FlexContainer, FormErrorField } from "../../../../common/StyledComponents";
import { useStableSpaceContext } from "../../SpaceContext";
import { Checkbox } from "../common/ConfigPanel/styledComponents";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDefaultValue from "../common/useDefaultValue";
import useValidationErrors from "../common/useValidationErrors/useValidationErrors";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { ensureSpaceCheckboxComponent } from "./Config/reducer";

export default function SpaceCheckbox({ spaceComponent, hasConfigError }: Props) {
  const checkboxComponent = ensureSpaceCheckboxComponent(spaceComponent);
  const { output, updateOutput } = useComponentStateContext();
  const { editMode } = useStableSpaceContext();
  const { defaultValue } = useDefaultValue();

  // if defaultValue is ErrorValues.permissionDenied, use that value.
  // else, if not a boolean, set to false since checkbox only accepts true or false
  const defaultValueAdjusted = React.useMemo(() => {
    return defaultValue === ErrorValues.permissionDenied
      ? ErrorValues.permissionDenied
      : typeof defaultValue === "boolean"
      ? defaultValue
      : false;
  }, [defaultValue]);

  const [value, setValue] = React.useState(defaultValueAdjusted);

  // update value if defaultValue changes (ie. due to template changes)
  React.useEffect(() => {
    setValue(defaultValueAdjusted);
  }, [defaultValueAdjusted]);

  // null output indicates component needs to init its output
  React.useEffect(() => {
    if (output !== null) return;
    setValue(defaultValueAdjusted);
  }, [defaultValueAdjusted, output, setValue]);

  // initialize value and handle value changes
  React.useEffect(() => {
    updateOutput({ value });
  }, [updateOutput, value]);

  const errors = useValidationErrors();
  React.useEffect(() => {
    updateOutput({ error: errors[0] });
  }, [updateOutput, errors]);

  const handleChange = React.useCallback(e => {
    setValue(e.target.checked);
  }, []);

  return (
    <EditModeInteractionBlocker>
      <FlexContainer>
        {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
        <Checkbox
          checked={
            typeof value === "boolean" ? value : false
          } /* value will not be boolean only for permission denied case */
          onChange={handleChange}
        >
          {checkboxComponent.properties.text || ""}
        </Checkbox>
        {value === ErrorValues.permissionDenied && <PermissionWarningIcon />}
      </FlexContainer>
      {errors.length > 0 && <FormErrorField>{errors[0]}</FormErrorField>}
    </EditModeInteractionBlocker>
  );
}
