import React from "react";

import { Field } from "../../../common/ConfigPanel/styledComponents";
import SourceSelector from "../../../common/SourceSelector";

export default function BasicConfig() {
  return (
    <Field>
      <SourceSelector />
    </Field>
  );
}
