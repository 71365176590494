import React from "react";

import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { decodeCursor } from "../../../../../../constants";
import { FunctionAttributeNode } from "../../../../../../types";
import usePaths from "../../../../../common/hooks/usePaths";
import { FunctionNode } from "../../useRecordData/queries";
import { getFunctionCursor } from "../../util";
import { RecordParams } from "../useGetRecordPath";

interface Props {
  attribute: FunctionAttributeNode;
  children: React.ReactNode;
  func: FunctionNode;
  value: any;
}

const LinkButton = styled(Button)`
  padding: 0;
  margin: 0;
  height: fit-content;
  text-align: left;
`;

export const MaybeFunctionLink = ({ attribute, children, func, value }: Props) => {
  const navigate = useNavigate();
  const { getFunctionRecord } = usePaths();
  const params = useParams<RecordParams>();
  const { cursor, view: currentView } = params;
  const decodedRootCursor = decodeCursor(decodeURIComponent(cursor!));
  const rootCursor = getFunctionCursor(decodedRootCursor);

  const link = func.outboundFunctionLinks.edges.find(e => {
    const categories = e.node.toFunction.metadata?.categories || [];
    if (categories.includes("returns:one")) {
      const values = Object.values(e.node.parameterAttributeMapping);
      return values.length === 1 && values[0] === attribute.sourceName;
    }
    return false;
  });

  if (!link || value === undefined || value === null) {
    return <>{children}</>;
  } else {
    const key = Object.keys(link.node.parameterAttributeMapping)[0];

    const path = getFunctionRecord(rootCursor, currentView, {
      id: link.node.toFunction.id,
      params: { [key]: value }
    });

    return (
      <LinkButton
        type="link"
        onClick={evt => {
          evt.stopPropagation();
          navigate(path);
        }}
      >
        {children}
      </LinkButton>
    );
  }
};

export default MaybeFunctionLink;
