import { Connection, RelayNode, RoleNode } from "../../../types";

import { UpdateRolePermissionsVariables } from "./queries";

export interface Identifiable {
  id: string;
}

export interface Nameable {
  name: string;
}

export interface PolicyFunctionParameters extends RelayNode {
  functionParameter: Identifiable & Nameable;
}

export interface PolicyFunctionAttributes extends RelayNode {
  functionAttribute: Identifiable & Nameable;
}

export interface Permittable {
  id: string;
  permitted: boolean;
}

export interface FunctionParameter extends Permittable {
  name: string;
  required: boolean;
}

export interface FunctionAttribute extends Permittable {
  name: string;
}

export interface RoleFunctionPermissions {
  allowsAll: boolean;
  policyFunctionParameters: Connection<PolicyFunctionParameters>;
  policyFunctionAttributes: Connection<PolicyFunctionAttributes>;
}

export interface FunctionPermission {
  functionId: string;
  name: string;
  dataSource: string;
  allowsAll: boolean;
  parametersPermitted: number;
  parameters: FunctionParameter[];
  attributesPermitted: number;
  attributes: FunctionAttribute[];
}

export function isAdmin(role: RoleNode): boolean {
  return !!(role.isManaged && role.slug === "admin");
}

export function functionNodesToUpdateRolePermissions(
  roleId: string,
  roleName: string,
  functionPermissions: FunctionPermission[],
  scopes?: string[]
): UpdateRolePermissionsVariables {
  return {
    id: roleId,
    name: roleName,
    scopes: scopes || [],
    applyPermissions: functionPermissions.map(functionPermission => {
      return {
        functionId: functionPermission.functionId,
        permission: {
          allowsAll: functionPermission.allowsAll,
          parameterIds: functionPermission.parameters
            .filter(param => param.permitted)
            .map(param => param.id),
          attributeIds: functionPermission.attributes
            .filter(attr => attr.permitted)
            .map(attr => attr.id)
        }
      };
    })
  };
}

export function switchValues(
  valueConditions: Record<string, boolean>,
  defaultValue: string | undefined = undefined
): string | undefined {
  for (const value in valueConditions) {
    if (valueConditions[value]) {
      return value;
    }
  }

  return defaultValue;
}
