import React from "react";

import { Empty, Icon, Skeleton } from "antd";
import { partition } from "lodash";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";

import { AttributeNode, Cursor, Edge, FunctionAttributeNode } from "../../../../types";
import ErrorBoundary from "../../../common/ErrorBoundary";
import usePaths from "../../../common/hooks/usePaths";
import ViewErrorMessage from "../../../common/ViewErrorMessage";
import { reportException } from "../../../util/exceptionReporting";

import CoreRecord from "./CoreRecord";
import RelatedFunctions from "./RelatedFunctions";
import { Header, Main, Root, Sidebar, Wrapper } from "./styledComponent";
import useRecordData from "./useRecordData";
import { FunctionNode } from "./useRecordData/queries";
import {
  getDataAccessorRecordKey,
  getFriendlyFunctionName,
  getRecordTitle
} from "./util";

export interface Props {
  cursor: Cursor;
  inDrawer?: boolean;
}

export default function RecordDetail({ cursor, inDrawer = false }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { getFunctionRecord } = usePaths();
  const { loading, dataAccessor, records, errors } = useRecordData(cursor);

  if (loading)
    return (
      <Root>
        <Skeleton active />
      </Root>
    );

  if (!dataAccessor || errors.length > 0)
    return (
      <ViewErrorMessage
        customCTAText="Go to settings."
        customOnClick={() =>
          navigate(`/settings/data-sources/${dataAccessor?.dataSource?.id}`)
        }
        errorCode={(errors[0] && errors[0].code) || ""}
      />
    );

  if (!records.length) {
    return (
      <Empty description="This record does not exist. It may have been deleted." />
    );
  }

  // TODO: Revisit when Resource detail views go away.
  // HACK: Now records can have attributes from different tables so attr.sourceName
  // is no longer unique. Disambiguation is done in DVCs on FE by using id
  // as the attribute key instead in records. The following changes the key
  // from sourceName -> id so UI components can be shared.
  const attrEdges: Edge<AttributeNode | FunctionAttributeNode>[] =
    (dataAccessor as FunctionNode).functionAttributes?.edges || [];
  const [emptyAttrEdges, nonEmptyAttrEdges] = partition(
    attrEdges,
    e => e === undefined || e.node === undefined
  );

  if (emptyAttrEdges.length > 0) {
    // Original issue: https://sentry.io/organizations/internal/issues/2021123820/?environment=secure.internal.io&project=1517743&query=is%3Aunresolved&statsPeriod=14d
    reportException(new Error("Expected non empty attribute edges."), {
      extra: { dataAccessor }
    });
  }

  const record = records[0] || {};

  const recordById = Object.entries(record).reduce((memo, [attrName, val]) => {
    const foundEdge = nonEmptyAttrEdges.find(
      (edge: Edge<AttributeNode | FunctionAttributeNode>) =>
        edge.node.sourceName === attrName
    );
    return foundEdge ? { ...memo, [foundEdge.node.id]: val } : memo;
  }, {});

  const qp = location.search;
  const appendQueryParams = function (path: string): string {
    let result = path;
    if (qp) {
      result = result + qp;
    }
    return result;
  };

  const title = getFriendlyFunctionName(getRecordTitle(dataAccessor));

  let path: string | undefined;
  switch (dataAccessor.__typename) {
    case "FunctionNode":
      path = getFunctionRecord({
        id: dataAccessor.id,
        params: getDataAccessorRecordKey(dataAccessor, record)
      });
      break;
    default:
      throw new Error("unrecognized typename");
  }

  return (
    <Root>
      <Header>
        <span>{title}</span>
        {inDrawer && (
          <Link to={appendQueryParams(path)} replace>
            <Icon type="link" />
          </Link>
        )}
      </Header>
      <Wrapper>
        <Main>
          <ErrorBoundary>
            <CoreRecord dataAccessor={dataAccessor} record={recordById} />
          </ErrorBoundary>
        </Main>
        <Sidebar>
          {dataAccessor.__typename === "FunctionNode" &&
            !!dataAccessor.outboundFunctionLinks.edges.length && (
              <RelatedFunctions func={dataAccessor} record={record} />
            )}
        </Sidebar>
      </Wrapper>
    </Root>
  );
}
