import React from "react";

import { Alert, Button, Spin, Table, Switch } from "antd";
import classNames from "classnames";
import { uniqWith } from "lodash";
import styled from "styled-components";

import { SourceType } from "../../../../../../../types";
import { humanize } from "../../../../../../util";
import { BulkImportModalStepProps } from "../BulkImportModal";
import { BulkImportStep } from "../reducer";

type Props = BulkImportModalStepProps;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-switch {
    margin-right: ${props => props.theme.spacersm};
  }

  .ant-alert {
    margin-bottom: ${props => props.theme.spacermd};
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${props => props.theme.spacermd};

  button {
    margin-left: ${props => props.theme.spacersm};
  }
`;

const StyledTable = styled(Table)`
  overflow: auto;
  margin-top: ${props => props.theme.spacersm};

  td {
    padding: ${props => props.theme.spacersm} div {
      width: 100%;
    }

    div.invalid {
      background-color: ${props => props.theme.fadedBrandColors.red};
    }
  }

  .importing {
    .ant-table-spin-holder {
      display: flex;
      align-items: center;
      justify-content: center;

      div.ant-spin-dot {
        position: relative;
        top: unset;
        left: unset;
        width: 100%;
      }

      span.ant-spin-dot {
        position: relative;
        top: unset;
        left: unset;
        margin: ${props => props.theme.spacersm};
        height: auto;
      }

      .ant-spin-text {
        position: relative;
        top: unset;
        left: unset;
      }
    }
  }
`;

type ParsedRowEntry = [string | null, boolean];

enum BlockingReason {
  BLANK_VALUE = 0
}

const blockReasons = {
  [BlockingReason.BLANK_VALUE]: "{} is required and cannot be blank"
};

const ReviewStep = ({ dispatch, state, sourceType, importAllData }: Props) => {
  const [showOnlyInvalid, setShowOnlyInvalid] = React.useState<boolean>(false);
  const { parsedRows } = state;

  const onNext = React.useCallback(async () => {
    await importAllData();
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { step: BulkImportStep.Complete }
    });
  }, [importAllData, dispatch]);

  const onPrev = React.useCallback(() => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { step: BulkImportStep.MapData }
    });
  }, [dispatch]);

  const hasInvalidData = React.useMemo(() => {
    return parsedRows.find(row =>
      row.find(([text, isValid]) => text !== null && !isValid)
    );
  }, [parsedRows]);

  const blockingErrors = React.useMemo(() => {
    // check required blank values
    const errors = parsedRows
      .map((row): [string, BlockingReason][] =>
        row
          .map(([text, _], idx) => {
            if (!state.mappings[String(idx)]) return null;
            const param = state.mappings[String(idx)];
            if (param.required && text === "") {
              return [String(idx), BlockingReason.BLANK_VALUE];
            }
            return null;
          })
          .filter((e): e is NonNullable<[string, BlockingReason]> => e !== null)
      )
      .flat(1);
    return uniqWith(errors, (e1, e2) => {
      return e1[0] === e2[0] && e1[1] === e2[1];
    });
  }, [parsedRows, state.mappings]);

  const hasBlockingErrors = blockingErrors && blockingErrors.length > 0;

  const columns = Object.entries(state.mappings).map(([idx, param]) => ({
    title: humanize(param.name),
    dataIndex: idx,
    key: idx,
    render: ([val, isValid]: ParsedRowEntry) => {
      return (
        <div className={classNames({ invalid: !isValid })}>
          {sourceType === SourceType.FILE ? val || "(Empty String)" : String(val)}
        </div>
      );
    }
  }));
  const data = React.useMemo(() => {
    return parsedRows.filter(
      row =>
        !showOnlyInvalid || row.find(([text, isValid]) => text !== null && !isValid)
    );
  }, [showOnlyInvalid, parsedRows]);

  const dataByIdx = React.useMemo(() => {
    return data.map((row, rowIdx) =>
      Object.fromEntries(
        row.map((val, idx) => [String(idx), val]).concat([["_key", String(rowIdx)]])
      )
    );
  }, [data]);

  const tableLoading = {
    spinning: state.isImporting,
    wrapperClassName: "importing",
    indicator: (
      <div>
        <Spin size="large" tip={state.importingMessage} />
      </div>
    )
  };

  return (
    <StyledRoot>
      <div>
        <h3>Review Data</h3>
        <p>
          Check your data for accuracy before proceeding with the import. You will not
          be able revert this action.
        </p>
        {!hasBlockingErrors && hasInvalidData && (
          <Alert
            message="We found potential errors with your data. If you proceed without correcting these errors, your import may result in errors."
            type="warning"
          />
        )}
        {hasBlockingErrors && (
          <div>
            <Alert
              message="Your data cannot be validated. Resolve the following errors and try again."
              type="error"
            />
            <ul>
              {blockingErrors.map(([colIdx, error]) => (
                <li key={`${colIdx}-${error}`}>
                  {blockReasons[error].replace(
                    "{}",
                    humanize(state.mappings[colIdx].name)
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div>
          <Switch onChange={checked => setShowOnlyInvalid(checked)} />
          <span>Show only rows with errors</span>
        </div>
        <StyledTable
          loading={tableLoading}
          columns={columns}
          dataSource={dataByIdx}
          rowKey="_key"
        />
      </div>
      <StyledFooter>
        {sourceType === SourceType.FILE && (
          <Button key="back" disabled={state.isImporting} onClick={onPrev}>
            Back
          </Button>
        )}
        <Button
          key="submit"
          disabled={state.isImporting || hasBlockingErrors}
          loading={state.isImporting}
          type="primary"
          onClick={onNext}
        >
          Submit
        </Button>
      </StyledFooter>
    </StyledRoot>
  );
};

export default ReviewStep;
