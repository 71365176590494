import gql from "graphql-tag";

export const EXECUTE_FUNCTION = gql`
  mutation ExecuteFunction(
    $functionId: ID!
    $parameters: GenericScalar!
    $spaceId: ID
  ) {
    executeFunction(
      functionId: $functionId
      spaceId: $spaceId
      parameters: $parameters
    ) {
      __typename
      ... on PermissionErrorResult {
        message
      }
      ... on ClientErrorResult {
        code
        message
        errorInfo {
          reason
          domain
          metadata
        }
      }
      ... on ExecuteFunctionResultSuccess {
        __typename
        metadata
        returning {
          ... on PermissionErrorResult {
            message
          }
          ... on ClientErrorResult {
            code
            message
            errorInfo {
              reason
              domain
              metadata
            }
          }
          ... on ReturningRowsResultSuccess {
            rows {
              id
              row
            }
          }
        }
        value
      }
    }
  }
`;
