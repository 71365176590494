import React from "react";

import { Input } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";

import { FormItem, FormItemNoBottomMargin } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import SSHFieldSet from "./SSHFieldSet";
import { CredentialFormProps } from "./types";

export const CONNECTION_STRING_FIELD = "ConnectionString";
export const MONGO_DATABASE_FIELD = "Database";

export const MongoSpecificFields = (props: CredentialFormProps) => {
  const { getFieldDecorator } = props.form;
  return (
    <>
      <FormItem label={props.includeLabel && "Connection String"}>
        {getFieldDecorator(CONNECTION_STRING_FIELD, {
          rules: [
            {
              required: !props.allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="mongodb://<username>:<password>@<cluster-host-name>[:<port>]/<authSource>"
            data-test="connection-string-input"
            disabled={props.isLoading}
          />
        )}
      </FormItem>
      <FormItem label={props.includeLabel && "Database"}>
        {getFieldDecorator(MONGO_DATABASE_FIELD, {
          initialValue: props.credentials?.database,
          rules: [
            {
              required: !props.allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="Database"
            data-test="database-input"
            disabled={props.isLoading}
          />
        )}
      </FormItem>
    </>
  );
};

export default function MongoForm(props: CredentialFormProps) {
  const [isSSHTunnelingEnabled, setSSHTunnelingEnabled] = React.useState(
    props.credentials?.ssh_user || props.credentials?.ssh_host
  );
  const { getFieldDecorator } = props.form;

  return (
    <>
      <MongoSpecificFields {...props} />
      <FormItemNoBottomMargin>
        {getFieldDecorator("SSH Enabled", {
          initialValue: false
        })(
          <Checkbox
            indeterminate={false}
            onChange={(e: CheckboxChangeEvent) => {
              setSSHTunnelingEnabled(e.target.checked);
            }}
          >
            SSH Tunneling
          </Checkbox>
        )}
      </FormItemNoBottomMargin>
      {isSSHTunnelingEnabled && <SSHFieldSet {...props} />}
    </>
  );
}
