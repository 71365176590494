import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import { Analytics } from "../components/queues/Analytics";
import { Home as HomeComponent } from "../components/queues/App/Home";
import QueueComponent from "../components/queues/App/Queue";
import AssignedQueue from "../components/queues/App/Queue/AssignedQueue";
import { Config } from "../components/queues/Config";
import { Layout, PaddedLayout } from "../layouts";

import { AccountLockedGate } from "./errors";

export const Home = () => {
  return (
    <RequireAuth>
      <Layout>
        <AccountLockedGate>
          <HomeComponent />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};

export const NewQueue = () => {
  return (
    <RequireAuth>
      <PaddedLayout>
        <AccountLockedGate>
          <Config />
        </AccountLockedGate>
      </PaddedLayout>
    </RequireAuth>
  );
};

export const EditQueue = () => {
  return (
    <RequireAuth>
      <PaddedLayout>
        <AccountLockedGate>
          <Config />
        </AccountLockedGate>
      </PaddedLayout>
    </RequireAuth>
  );
};

export const Queue = () => {
  return (
    <RequireAuth>
      <Layout>
        <AccountLockedGate>
          <QueueComponent />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};

export const Assigned = () => {
  return (
    <RequireAuth>
      <Layout>
        <AccountLockedGate>
          <AssignedQueue />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};

export const QueueAnalytics = () => {
  return (
    <RequireAuth>
      <Layout>
        <AccountLockedGate>
          <Analytics />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};
