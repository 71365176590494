import Icon from "../../../../../assets/spaceicons/chart.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  ElementLayout,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  OverflowOption
} from "../../../layout/util";

import Config from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  makeInitialState,
  ensureSpaceChartComponent
} from "./Config/reducer";
import { SpaceChartComponent, ChartConfigState, SpaceChartProperties } from "./types";

import SpaceChart from ".";

const pkg: SpaceComponentPackage<
  SpaceChartProperties,
  SpaceChartComponent,
  ChartConfigState
> = {
  type: "CHART",
  displayName: "Chart",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["SPACE"]),
  isRepeatable: false,
  Component: SpaceChart,
  ConfigurationComponent: Config,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceChartComponent,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.STANDARD.height,
    snapToGrid: true
  }),
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    overflow: [OverflowOption.HIDDEN]
  },
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
