import React from "react";

import { isEqual } from "lodash";

import {
  AttributeTypes,
  AttributeTypesDisplayNames,
  ErrorValue,
  ErrorValues,
  isBlankValue
} from "../../../../../../constants";
import { AttributeNodeTypes, DataValue, FileObject } from "../../../../../../types";
import usePrevious from "../../../../../common/hooks/usePrevious";
import { getSerializer, isFileObject, isValidType } from "../../../../../common/utils";
import { BLANK_VALUE_TYPE_MAP, BlankValueType } from "../../../constants";
import { useStableSpaceContext } from "../../../SpaceContext";
import { MAX_DATA_SIZE } from "../../constants";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";
import {
  FunctionFormStatus,
  useFunctionFormContext
} from "../../SpaceFunctionForm/FunctionForm";
import useValidationErrors from "../useValidationErrors/useValidationErrors";
import { toAttributeType } from "../util";

export const FILE_TOO_LARGE_ERROR = `File size exceeds ${MAX_DATA_SIZE / 1048576}MB.`;
export const DATA_TOO_LARGE_ERROR = `Data size exceeds ${MAX_DATA_SIZE / 1048576}MB.`;

export type SpaceInputFieldState =
  | {
      value?: string;
      error?: string;
    }
  | undefined;

// we do not accept all DataValue types, because input components that pass boolean/json values
// pass the selected value as string and gets converted by the serializer in `formatValue`.
type InputValue =
  | string
  | (string | null)[]
  | number
  | FileObject
  | undefined
  | null
  | ErrorValue;

// if value is a blank value, will serialize it according to blankValueType.
// otherwise, will call appropriate serializer based on validationType to format the value.
const formatValue = (
  value: InputValue,
  validationType: AttributeTypes,
  blankValueType?: BlankValueType,
  treatEmptyArrayAsBlank?: boolean
) => {
  if (isBlankValue(value, treatEmptyArrayAsBlank)) {
    return blankValueType === undefined
      ? undefined
      : BLANK_VALUE_TYPE_MAP[blankValueType];
  }
  const formatter = getSerializer(validationType);
  return formatter ? formatter(value) : value;
};

const BASE64_DATA_URI_REGEX = /^data:([^;]+);base64,.+/;

// returns an error message based on type validation, allow_blank (ie. required) and form status
const getError = (
  val: DataValue | FileObject | undefined | ErrorValue,
  type: AttributeNodeTypes,
  allow_blank: boolean,
  status: FunctionFormStatus
) => {
  if (val === ErrorValues.permissionDenied) return undefined; // we render a warning in this case, not an error.
  if (
    (val === "" ||
      val === null ||
      val === undefined ||
      (Array.isArray(val) && val.length === 0)) &&
    !allow_blank &&
    [FunctionFormStatus.FAILED, FunctionFormStatus.SUBMITTING].includes(status)
  ) {
    return "This field is required.";
  }
  const valid = isValidType(val as DataValue | FileObject | undefined, type);
  if (!valid) {
    return `This field should be of type: ${AttributeTypesDisplayNames[
      toAttributeType(type)
    ].toLowerCase()}.`;
  }
  if (type === AttributeTypes.FILE && isFileObject(val) && val.size > MAX_DATA_SIZE) {
    return FILE_TOO_LARGE_ERROR;
  }
  if (
    type === AttributeTypes.BINARY &&
    val &&
    typeof val === "string" &&
    val.length > MAX_DATA_SIZE
  ) {
    // Give base64 encoded strings extra space because the underlying binary data may be under 10MB.
    // 1.4 because typical base64 encoding will increase size by 1.33 and data uri formatting must
    // be accounted for.
    if (!val.match(BASE64_DATA_URI_REGEX) || val.length > MAX_DATA_SIZE * 1.4) {
      return DATA_TOO_LARGE_ERROR;
    }
  }
  return undefined;
};

// consolidates updating of output values for input components
export default function useOutputSyncing(selectedValue: InputValue) {
  const { componentNode, output, updateOutput } = useComponentStateContext();
  const { editMode } = useStableSpaceContext();
  const { status } = useFunctionFormContext();
  const properties = componentNode?.component.properties || {};
  const { validation_type, allow_blank, blank_value_type } = properties;

  const [formattedValue, setFormattedValue] = React.useState<
    DataValue | FileObject | undefined
  >();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const validationErrors = useValidationErrors();

  // status will be undefined if component is not in a function form
  const formStatus = status || FunctionFormStatus.PENDING;

  const previousFormStatus = usePrevious(formStatus);
  const previousValidationErrors = usePrevious(validationErrors);
  const outputInitialized = output !== null;
  const shouldGuardEffect =
    outputInitialized &&
    (status === undefined || previousFormStatus === formStatus) &&
    validationErrors === previousValidationErrors &&
    (editMode ||
      // blank values need to undergo blank_value_type conversion in `formatValue`
      // so exclude those values from the guard here
      (!isBlankValue(selectedValue) &&
        selectedValue === (output as SpaceInputFieldState)?.value));
  React.useEffect(() => {
    if (shouldGuardEffect) return;

    const value =
      selectedValue === ErrorValues.permissionDenied
        ? selectedValue
        : formatValue(
            selectedValue,
            toAttributeType(validation_type),
            blank_value_type,
            componentNode?.component.type === "TAG_SELECTOR"
          );
    let _errorMessage = getError(
      value,
      toAttributeType(validation_type),
      allow_blank,
      formStatus
    );

    if (
      !status !== undefined ||
      [FunctionFormStatus.FAILED, FunctionFormStatus.SUBMITTING].includes(formStatus)
    ) {
      _errorMessage = _errorMessage || validationErrors[0];
    }

    if (
      !outputInitialized ||
      _errorMessage !== errorMessage ||
      !isEqual(value, formattedValue)
    ) {
      setFormattedValue(value);
      setErrorMessage(_errorMessage);
      updateOutput({
        value,
        error: _errorMessage
      });
    }
  }, [
    shouldGuardEffect,
    outputInitialized,
    errorMessage,
    formattedValue,
    status,
    formStatus,
    updateOutput,
    selectedValue,
    allow_blank,
    validation_type,
    blank_value_type,
    componentNode?.component.type,
    validationErrors
  ]);

  return {
    formattedValue,
    errorMessage
  };
}
