import React from "react";

import { Skeleton, Select as AntSelect } from "antd";
import styled from "styled-components";

import { ReturnSchema } from "../../../../../../constants";
import { SpaceComponentObject, ComponentConfigProps } from "../../../../../../types";
import ButtonDesignConfig, { ButtonTextConfig } from "../../common/ButtonDesignConfig";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError";
import { ConfigSection } from "../../common/ConfigPanel";
import { Field } from "../../common/ConfigPanel/styledComponents";
import {
  DISPLAY_MESSAGE,
  REFRESH_COMPONENT,
  AUTO_UPDATE_BY_KEY
} from "../../common/effects/Effects";
import { MessageType } from "../../common/effects/Effects/DisplayMessage";
import EffectsManager from "../../common/effects/EffectsManager/EffectsManager";
import { SubmittableEventType } from "../../common/effects/useSubmittableEffects/useSubmittableEffects";
import NameFields from "../../common/NameFields";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { SpaceS3UploaderComponent } from "../types";

import useS3Buckets from "./useS3Buckets";

const { Option } = AntSelect;

export const Container = styled.div`
  padding: ${props => props.theme.spacerxl};
  overflow-y: scroll;
  width: 100%;
  section {
    label {
      margin-bottom: ${props => props.theme.spacersm};
    }
    margin-bottom: ${props => props.theme.spacerlg};
  }
`;

export const Select = styled(AntSelect)`
  min-width: 40%;
`;

export const ensureUploadComponent = (
  sc: SpaceComponentObject
): SpaceS3UploaderComponent => {
  if (!sc.properties.effects) {
    sc.properties.effects = [
      {
        type: SubmittableEventType.SUBMIT_SUCCESS,
        effect: { type: "auto_update_by_key" }
      },
      {
        type: SubmittableEventType.SUBMIT_SUCCESS,
        effect: {
          type: "display_message",
          options: {
            type: MessageType.SUCCESS,
            message: "Your file has been uploaded."
          }
        }
      },
      {
        type: SubmittableEventType.SUBMIT_FAILURE,
        effect: {
          type: "display_message",
          options: {
            type: MessageType.ERROR,
            message: "File upload was not successful."
          }
        }
      }
    ];
  }
  // initialize button text if neither text nor icon is provided
  if (!sc.properties.open_uploader_button_text && !sc.properties.button_icon) {
    sc.properties.open_uploader_button_text = "Upload";
  }
  return sc;
};

export default function Config({ slug }: ComponentConfigProps) {
  const dataConfig = React.useMemo(() => <S3UploaderContent />, []);
  const effectsConfig = React.useMemo(() => <EffectsConfig />, []);
  const designConfig = React.useMemo(() => <ButtonDesignConfig />, []);
  return (
    <ComponentConfigContextContainer
      dataConfig={dataConfig}
      effectsConfig={effectsConfig}
      designConfig={designConfig}
      slug={slug}
    />
  );
}

export function S3UploaderContent() {
  const { state, dispatch } = useComponentConfigContext();
  const bucketFunctionTups = useS3Buckets();
  const {
    properties: {
      bucket_name,
      presign_function_id,
      open_uploader_button_text,
      button_icon
    }
  } = state.draftComponent;

  const value = React.useMemo(() => {
    const selectedTuple = bucketFunctionTups.find(
      tup => tup[0].sourceName === bucket_name && tup[1].id === presign_function_id
    );
    return selectedTuple ? selectedTuple[0].id : undefined;
  }, [presign_function_id, bucket_name, bucketFunctionTups]);

  if (!state) return <Skeleton active />;

  return (
    <>
      <ConfigSection title="S3 Uploader Details">
        <NameFields />
        <Field>
          <label>S3 Bucket</label>
          <Select
            value={value}
            data-test="bucketSelect"
            placeholder="Select a bucket"
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            onChange={val => {
              const pair = bucketFunctionTups.find(tup => tup[0].id === val)!;
              dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    functions: {
                      edges: [
                        {
                          node: {
                            id: pair[1].id,
                            name: `Upload to ${pair[0].sourceName}`,
                            returnSchema: ReturnSchema.UNKNOWN
                          }
                        }
                      ]
                    },
                    properties: {
                      bucket_name: pair[0].sourceName,
                      presign_function_id: pair[1].id,
                      open_uploader_button_text: `Upload to ${pair[0].sourceName}`
                    }
                  }
                }
              });
            }}
          >
            {bucketFunctionTups.map(tup => (
              <Option key={tup[0].id}>{tup[0].sourceName}</Option>
            ))}
          </Select>
          <ValidationError field="BUCKET" />
        </Field>

        <ButtonTextConfig
          placeholder="Upload"
          button_icon={button_icon}
          button_text={open_uploader_button_text}
          onIconChange={value =>
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.button_icon",
                value
              }
            })
          }
          onTextChange={value =>
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.open_uploader_button_text",
                value
              }
            })
          }
        />
      </ConfigSection>
      <VisibilityRulesManagerSection />
    </>
  );
}

export const S3_UPLOADER_EVENTS = [
  {
    name: "On Succesful Submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [DISPLAY_MESSAGE, REFRESH_COMPONENT, AUTO_UPDATE_BY_KEY],
    defaultEffect: {
      type: "refresh_component" as const,
      options: {
        component: undefined
      }
    }
  },
  {
    name: "On Failed Submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE],
    defaultEffect: {
      type: "display_message" as const,
      options: {
        type: MessageType.ERROR,
        message: "Something went wrong. Please try again."
      }
    }
  }
];

export const EffectsConfig = () => {
  return <EffectsManager eventOptions={S3_UPLOADER_EVENTS} />;
};
