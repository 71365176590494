import gql from "graphql-tag";

import { RelayNode } from "../../../types";

export interface AvailableDataSource {
  sourceName: string;
  name: string;
}

interface DataSourceProvider extends RelayNode {
  integration: string;
  availableDataSources: AvailableDataSource[];
}

export interface AvailableDataSourcesVariables {
  dataSourceProviderId: string;
}

export interface AvailableDataSourcesData {
  dataSourceProvider: DataSourceProvider;
}

export const FETCH_AVAILABLE_DATA_SOURCES = gql`
  query FetchAvailableDataSources($dataSourceProviderId: ID!) {
    dataSourceProvider(id: $dataSourceProviderId) {
      id
      integration
      availableDataSources {
        sourceName
        name
      }
    }
  }
`;
