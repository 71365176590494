import { Dispatch } from "react";

import { PageInfo } from "../../../../../../types";
import { assertNever } from "../../../../../util/assertNever";
import { CommentNode } from "../queries";

import { CommentData } from "./queries";

export enum ReducerActions {
  SetTask = "setTask",
  Load = "load",
  LoadNewer = "loadNewer",
  LoadOlder = "loadOlder",
  Create = "create",
  Update = "update",
  Delete = "delete"
}

export interface SetTaskAction {
  type: ReducerActions.SetTask;
  payload: string;
}

export interface LoadAction {
  type: ReducerActions.Load;
  payload: CommentData;
}

export interface LoadNewerAction {
  type: ReducerActions.LoadNewer;
  payload: CommentData;
}

export interface LoadOlderAction {
  type: ReducerActions.LoadOlder;
  payload: CommentData;
}

export interface CreateAction {
  type: ReducerActions.Create;
  payload: CommentNode;
}

export interface UpdateAction {
  type: ReducerActions.Update;
  payload: {
    commentId: string;
    content: string;
  };
}

export interface DeleteAction {
  type: ReducerActions.Delete;
  payload: {
    commentId: string;
  };
}

export type Action =
  | SetTaskAction
  | LoadAction
  | LoadNewerAction
  | LoadOlderAction
  | CreateAction
  | UpdateAction
  | DeleteAction;

export type CommentListDispatch = Dispatch<Action>;

export interface State {
  taskId?: string;
  comments?: CommentNode[];
  pageInfo?: PageInfo;
  totalCount?: number;
  startCursor?: string;
  endCursor?: string;
}

// Base on https://github.com/tc39/proposal-relative-indexing-method#polyfill
export function arrayAt<T>(items: T[], index: number): T | undefined {
  // ToInteger() abstract op
  let n = Math.trunc(index) || 0;
  // Allow negative indexing from the end
  if (n < 0) n += items.length;
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= items.length) return undefined;
  // Otherwise, this is just normal property access
  return items[n];
}

export function mergeLoadNewer(
  comments: CommentNode[],
  newComments: CommentNode[]
): CommentNode[] {
  if (comments.length === 0) {
    return newComments;
  }

  // Sometimes you can have multiple network requests returning overlapping data
  // we want to avoid duplicates, so we only return new data. We assume
  // the data is ordered by -createdAt in all cases
  const firstNode = comments[0];
  let index = 0;
  for (; index < newComments.length; index++) {
    if (newComments[index].id === firstNode.id) {
      break;
    }
  }

  const additionalComments = newComments.slice(0, index);

  return [...additionalComments, ...comments];
}

export function createInitialState(): State {
  return {};
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ReducerActions.SetTask:
      return {
        taskId: action.payload,
        comments: undefined,
        totalCount: undefined,
        pageInfo: undefined,
        startCursor: undefined,
        endCursor: undefined
      };
    case ReducerActions.Load:
      const commentData = action.payload.allComments;
      return {
        ...state,
        comments: commentData.edges.map(e => e.node) || [],
        totalCount: commentData.totalCount,
        startCursor: arrayAt(commentData.edges, 0)?.node.id,
        endCursor: arrayAt(commentData.edges, -1)?.node.id,
        pageInfo: commentData.pageInfo
      };
    case ReducerActions.LoadNewer:
      const commentsWithNewer = mergeLoadNewer(
        state.comments || [],
        action.payload.allComments.edges.map(e => e.node) || []
      );

      const commentsDelta = commentsWithNewer.length - (state.comments?.length || 0);

      return {
        ...state,
        comments: commentsWithNewer,
        totalCount: (state.totalCount || 0) + commentsDelta,
        startCursor: arrayAt(commentsWithNewer, 0)?.id
      };
    case ReducerActions.LoadOlder:
      const commentsWithOlder = [
        ...(state.comments || []),
        ...(action.payload.allComments.edges.map(e => e.node) || [])
      ];

      return {
        ...state,
        comments: commentsWithOlder,
        endCursor: arrayAt(commentsWithOlder, -1)?.id,
        pageInfo: action.payload.allComments.pageInfo
      };
    case ReducerActions.Create:
      const commentsAfterCreate = [action.payload, ...(state.comments || [])];

      return {
        ...state,
        comments: commentsAfterCreate,
        totalCount: (state.totalCount || 0) + 1,
        startCursor: arrayAt(commentsAfterCreate, 0)?.id,
        endCursor: arrayAt(commentsAfterCreate, -1)?.id
      };
    case ReducerActions.Update:
      const updateId = action.payload.commentId;
      const updateContent = action.payload.content;

      return {
        ...state,
        comments: (state.comments || []).map(c => {
          if (c.id === updateId) {
            return {
              ...c,
              content: updateContent
            };
          }

          return c;
        })
      };
    case ReducerActions.Delete:
      const commentsAfterDelete = (state.comments || []).filter(
        c => c.id !== action.payload.commentId
      );
      return {
        ...state,
        comments: commentsAfterDelete,
        totalCount: (state.totalCount || 0) - 1,
        startCursor: arrayAt(commentsAfterDelete, 0)?.id,
        endCursor: arrayAt(commentsAfterDelete, -1)?.id
      };
    default:
      return assertNever(action);
  }
}
