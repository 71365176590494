import styled from "styled-components";

import { Field } from "../ConfigPanel/styledComponents";

export const StyleField = styled(Field)`
  .ant-select-selection__rendered {
    display: flex;
    align-items: center;
  }
`;

export const StyleOption = styled.div`
  width: 21px;
  height: 21px;
  background: ${props => props.theme.selectContentColor};
`;
