import { cloneDeep, flow, partial } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import { SpaceComponentObject } from "../../../../../../types";
import { commonComponentReducer } from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import {
  BaseComponentConfigState,
  ComponentConfigState,
  SpaceConfigAction
} from "../../../../types";
import { DefaultValueType } from "../../../constants";
import { getInitialHardcodedOptions } from "../../common/HardcodedOptionFields/utils";
import inputReducer from "../../common/inputReducer";
import { getCommonInputProperties, toProperty } from "../../common/util";
import { hasInputComponentProperties } from "../../util/util";
import { SpaceRadioButtonComponent, SpaceRadioButtonProperties } from "../types";

export interface RadioButtonConfigState
  extends BaseComponentConfigState<SpaceRadioButtonComponent> {
  type: "RADIO_BUTTON";
}

export const INITIAL_STATE = {
  type: "RADIO_BUTTON" as const,
  draftComponent: {} as SpaceRadioButtonComponent
};

function isRadioButtonConfigState(
  state: ComponentConfigState
): state is RadioButtonConfigState {
  return state.type === "RADIO_BUTTON";
}

export function ensureRadioButtonConfigState(
  state: ComponentConfigState
): RadioButtonConfigState {
  if (isRadioButtonConfigState(state)) return state;

  throw new Error("Expected RadioButton config state.");
}

export const getComponentProperties = (
  properties: Partial<SpaceRadioButtonProperties>
) => {
  const isBoolean = properties.validation_type === toProperty(AttributeTypes.BOOL);
  return {
    ...properties,
    ...getCommonInputProperties(
      properties,
      isBoolean ? DefaultValueType.BOOLEAN_VALUE : DefaultValueType.TEXT_VALUE,
      isBoolean ? false : "",
      AttributeTypes.STRING,
      false
    ),
    hardcoded_options:
      getInitialHardcodedOptions(properties) || "Option 1,1\nOption 2,2", // add default value so that component is visible in space even if user hasn't configured it,
    allow_null: properties.allow_null || false
  };
};

export function ensureSpaceRadioButtonComponent(
  component: SpaceComponentObject
): SpaceRadioButtonComponent {
  if (
    typeof component.properties.allow_null === "boolean" &&
    hasInputComponentProperties(component)
  ) {
    return component;
  }

  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): RadioButtonConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceRadioButtonComponent(cloneDeep(draftComponent))
  };
}

export default (state: RadioButtonConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(inputReducer, partial.placeholder, action)])(
    state,
    action
  );
