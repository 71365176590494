import React from "react";

import styled from "styled-components";

import { InfoIcon } from "../../Icons/Icons";

interface Props {
  message: string;
}

const Wrapper = styled.div`
  margin-bottom: ${props => props.theme.spacermd};
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin-left: ${props => props.theme.spacersm};
`;

export const DisabledMessage = ({ message }: Props) => (
  <Wrapper>
    <InfoIcon />
    <Content>{message}</Content>
  </Wrapper>
);
