import React from "react";

import {
  CloneFunctionProps,
  FunctionEditorModal
} from "../../../../common/FunctionEditor/FunctionEditorModal/Modal";
import {
  addOrUpdateFunctionCacheUpdater,
  deleteFunctionCacheUpdater
} from "../AppDataFlyout/FunctionList/queries";
import { FunctionConfigMenuProps } from "../types";

type Props = FunctionConfigMenuProps;

export const FunctionConfigFlyout = ({
  dataSourceId,
  functionId,
  pendingFunctionId,
  setMenuPath,
  onClose
}: Props) => {
  const key = functionId || pendingFunctionId;
  if (typeof key !== "string")
    throw new Error("Expected functionId or pendingFunctionId to be present in URL.");

  return (
    <FunctionEditorModal
      key={key}
      dataSourceId={dataSourceId}
      functionId={functionId}
      cacheUpdater={addOrUpdateFunctionCacheUpdater}
      cacheEvictor={deleteFunctionCacheUpdater}
      onClone={({ functionId }: CloneFunctionProps) =>
        setMenuPath({
          type: "FUNCTION_CONFIG",
          props: {
            dataSourceId,
            functionId
          }
        })
      }
      onClose={onClose}
    />
  );
};
