import gql from "graphql-tag";

import { Connection, RelayNode } from "../../../../types";

export interface Vars {
  dataSourceId: string;
  first: number;
  offset: number;
  searchTitle: string | null;
  orderBy: string | null;
}

interface _FunctionNode extends RelayNode {
  title: string;
  isUserGenerated: boolean;
  updatedAt: string;
}

interface _AllFunctions extends Connection<_FunctionNode> {
  totalCount: number;
}

export interface Data {
  allFunctions?: _AllFunctions;
}

export const FETCH_FUNCTIONS = gql`
  query FetchFunctions(
    $dataSourceId: ID!
    $searchTitle: String
    $orderBy: String
    $first: Int!
    $offset: Int!
  ) {
    allFunctions(
      scope: "wrapped"
      dataSourceId: $dataSourceId
      searchTitle: $searchTitle
      orderBy: $orderBy
      first: $first
      offset: $offset
    ) {
      totalCount
      edges {
        node {
          id
          title
          isUserGenerated
          updatedAt
        }
      }
    }
  }
`;
