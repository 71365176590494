import React from "react";

import { isEqual, get, isObjectLike } from "lodash";

import { SpaceComponentState } from "..";
import { SpaceComponentObject, DataValue } from "../../../../../types";
import useStableRef from "../../../../common/hooks/useStableRef";
import { createPath, parsePath } from "../../../../util/binding";
import debug from "../../../../util/debug";
import { findInputBindings } from "../../../util";
import { useRenderTreeContext, ComponentNode } from "../../RenderTreeContext";
import { useStableSpaceContext } from "../../SpaceContext";
import { SUPERFICIAL_COMPONENT_TYPES, PERMANENT_COMPONENT_TYPES } from "../constants";
import { StateTree } from "../SpaceComponent";
import { useInputState } from "../util/util";

import ComponentPathContext, { useNextComponentPath } from "./ComponentPathContext";

export interface ComponentStateContextValue {
  // The ComponentNode from the ComponentGraph
  componentNode: ComponentNode | undefined;
  // The ComponentNodes's bound inputs from the StateTree
  input: Record<string, DataValue> | null;
  // The ComponentNode's output into the StateTree
  output: SpaceComponentState | null;
  // Merges state into the ComponentNode's output in the StateTree
  updateOutput: (state: Object) => void;
  // Dynamically register an input binding for this ComponentNode
  registerBinding: (bindingPath: string) => void;
  // Unregister a dynamically registered input binding
  unregisterBinding: (bindingPath: string) => void;
  // Recursively sets this componenent and all its descendant components' output to null
  // Optionally specify a childPath to start recursion, ie. <componentPath>.<childPath>
  recursivelyClearOutput: (childPath?: string) => void;
}

export const initialContext = {
  componentNode: undefined,
  input: null,
  output: null,
  updateOutput: (_state: Object) => {},
  registerBinding: (_bindingPath: string) => {},
  unregisterBinding: (_bindPath: string) => {},
  recursivelyClearOutput: (_childPath?: string) => {}
};

const ComponentStateContext =
  React.createContext<ComponentStateContextValue>(initialContext);

export default ComponentStateContext;

export const useComponentStateContext = () => React.useContext(ComponentStateContext);

// StateTreeContext is a seperate context since it updates with each state change.
// Use ComponentStateContext instead of it to access the StateTree.
export const StateTreeContext = React.createContext({});

export function ComponentStateContainer({
  component,
  collectionKey,
  index,
  itemKey,
  children
}: {
  component: SpaceComponentObject;
  collectionKey?: string;
  index?: number;
  itemKey?: string;
  children: React.ReactNode;
}) {
  debug("Start render ComponentStateContainer");
  const [bindingRegistrationState, bindingRegistrationDispatch] = React.useReducer(
    bindingRegistrationReducer,
    initialBindingRegistrationState
  );
  const isSuperficialComponent = SUPERFICIAL_COMPONENT_TYPES.includes(component.type);
  const isPermanentComponent = PERMANENT_COMPONENT_TYPES.includes(component.type);
  const componentPath = useNextComponentPath(component, collectionKey, index, itemKey);
  const {
    stateTree: rootStateTree,
    findNode,
    updateOutput,
    recursivelyClearOutput,
    registerNode,
    unregisterNode
  } = useRenderTreeContext();

  const parentStateTree = React.useContext(StateTreeContext);
  const stateTree = React.useMemo(() => {
    const stateTree = Object.keys(parentStateTree).length
      ? parentStateTree
      : rootStateTree;

    if (isSuperficialComponent) return stateTree;

    const ownState = get(stateTree, componentPath);

    const stateTreeProxy = new Proxy(stateTree, {
      get(target, prop, receiver) {
        if (prop === component.slug || prop === component.type.toLowerCase()) {
          return ownState;
        }
        return Reflect.get(target, prop, receiver);
      }
    });

    return stateTreeProxy;
  }, [
    parentStateTree,
    rootStateTree,
    componentPath,
    component.slug,
    component.type,
    isSuperficialComponent
  ]);

  const registerBinding = React.useCallback(
    function registerBinding(binding: string) {
      bindingRegistrationDispatch({
        type: "REGISTER_BINDING",
        payload: { binding }
      });
    },
    [bindingRegistrationDispatch]
  );

  const unregisterBinding = React.useCallback(
    function unregisterBinding(binding: string) {
      bindingRegistrationDispatch({
        type: "UNREGISTER_BINDING",
        payload: { binding }
      });
    },
    [bindingRegistrationDispatch]
  );

  const nextComponentStateContext = useNextComponentStateContext(
    component,
    componentPath,
    stateTree,
    bindingRegistrationState,
    registerBinding,
    unregisterBinding,
    findNode,
    updateOutput,
    recursivelyClearOutput
  );

  React.useLayoutEffect(() => {
    if (isSuperficialComponent) return;
    registerNode(componentPath);
    return () => {
      if (isSuperficialComponent || isPermanentComponent) return;
      unregisterNode(componentPath);
    };
    // eslint-disable-next-line
  }, [componentPath]);

  debug("Finish render ComponentStateContainer");

  return (
    <ComponentPathContext.Provider value={componentPath}>
      <StateTreeContext.Provider value={stateTree}>
        <ComponentStateContext.Provider value={nextComponentStateContext}>
          {children}
        </ComponentStateContext.Provider>
      </StateTreeContext.Provider>
    </ComponentPathContext.Provider>
  );
}

function useNextComponentStateContext(
  component: SpaceComponentObject,
  componentPath: string,
  stateTree: StateTree,
  registeredBindings: Set<string>,
  registerBinding: (binding: string) => void,
  unregisterBinding: (binding: string) => void,
  findNode: any,
  rootUpdateOutput: any,
  rootRecursivelyClearOutput: any
) {
  debug("Rendering useNextComponentStateContext", componentPath);
  const { findSpaceComponentPackage } = useStableSpaceContext();

  const componentNode = React.useMemo(
    () => findNode(componentPath),
    [findNode, componentPath]
  );
  // HACK: If this is a pseudo component need to grab parent component's
  // properties to calculate input selection. Psuedo components don't have
  // properties and any binding config they depend on is in the parent.
  const pkg = findSpaceComponentPackage(component.type);
  component = pkg?.isPseudoComponent
    ? componentNode?.parent?.component || component
    : component;

  const bindings = React.useMemo(() => {
    const bindings = findInputBindings(component.properties);
    return new Set([...bindings, ...registeredBindings]);
  }, [component.properties, registeredBindings]);

  const input = useInputState(stateTree, bindings);
  // NOTE: `componentNode` is a mutable object and will not change between
  //       renders. `updateOutput` similarly should not break memomization.
  //       Components often have `updateOutput` as useEffect a dependency
  //       and if it changes too frequently they will set output too frequently.
  const updateOutput = React.useCallback(
    function updateOutput(state: Record<string, any>) {
      if (
        componentNode?.output &&
        Object.entries(state).every(([k, v]) => isEqual(componentNode.output[k], v))
      )
        return;
      rootUpdateOutput(componentPath, state);
    },
    [componentNode, rootUpdateOutput, componentPath]
  );

  const recursivelyClearOutput = React.useCallback(
    function recursivelyClearOutput(childPath?: string) {
      rootRecursivelyClearOutput(
        childPath ? `${componentPath}.${childPath}` : componentPath
      );
    },
    [componentPath, rootRecursivelyClearOutput]
  );
  const output = componentNode?.output || null;
  const stablizedInput = useStableRef(input);
  const value = React.useMemo(
    () => ({
      componentNode,
      input: stablizedInput,
      output,
      updateOutput,
      recursivelyClearOutput,
      registerBinding,
      unregisterBinding
    }),
    [
      componentNode,
      stablizedInput,
      output,
      updateOutput,
      recursivelyClearOutput,
      registerBinding,
      unregisterBinding
    ]
  );
  return value;
}

const initialBindingRegistrationState = new Set<string>();
function bindingRegistrationReducer(
  state: typeof initialBindingRegistrationState,
  action:
    | { type: "REGISTER_BINDING"; payload: { binding: string } }
    | { type: "UNREGISTER_BINDING"; payload: { binding: string } }
) {
  switch (action.type) {
    case "REGISTER_BINDING": {
      if (state.has(action.payload.binding)) return state;
      const nextState = new Set(Array.from(state));
      nextState.add(action.payload.binding);
      return nextState;
    }

    case "UNREGISTER_BINDING": {
      if (!state.has(action.payload.binding)) return state;
      const nextState = new Set(Array.from(state));
      nextState.delete(action.payload.binding);
      return nextState;
    }

    default:
      throw new Error(`Unexpected action: ${(action as any).type}`);
  }
}

// helper to generate input for "internal data" to avoid lifecycle of
// `componentStateContext.updateOutput` -> `componentStateContext.input`
// for all rows. Note: should only be used when you require data locally
// within your component (and do not expect other components to bind to this data).
export const getLocalInput = (
  path: string,
  data: Record<string, DataValue> | DataValue,
  input: Record<string, DataValue> | null
) => {
  const localInput = isObjectLike(data)
    ? Object.entries(data as Record<string, DataValue>).reduce(
        (agg: Record<string, DataValue>, [key, val]) => {
          agg[createPath([...parsePath(path), key])] = val;
          return agg;
        },
        {}
      )
    : {
        [path]: data
      };
  return {
    ...localInput,
    ...input
  };
};
