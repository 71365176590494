import React from "react";

import * as styled from "../forms/styledComponents";

interface ErrorMessageFieldProps {
  className?: string;
  errorMessage?: string;
}

export const ErrorMessageField = (props: ErrorMessageFieldProps) => {
  return props.errorMessage ? (
    <styled.StyledErrorField className={props.className}>
      {props.errorMessage}
    </styled.StyledErrorField>
  ) : null;
};
ErrorMessageField.displayName = "ErrorMessageField";

interface FieldWithErrorProps {
  className?: string;
  errorMessage?: string;
  children?: React.ReactNode;
}

export const FieldWithError = (props: FieldWithErrorProps) => {
  return (
    <styled.FieldWithErrorContainer className={props.className}>
      {props.children}
      <ErrorMessageField errorMessage={props.errorMessage} />
    </styled.FieldWithErrorContainer>
  );
};
