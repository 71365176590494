import React from "react";

import styled from "styled-components";

import { DebouncedTemplateEditor } from "../TemplateEditor/TemplateEditor";

import ConditionalObject from "./ConditionalObject";
import ConditionalOperator from "./ConditionalOperator";
import {
  ConditionalExpression as ConditionalExpressionType,
  ConditionalOperator as ConditionalOperatorType,
  ManagedExpressionDefinitions
} from "./types";

interface ConditionalExpressionProps {
  subject?: React.ReactNode; // ConditionalSubject
  expression: ConditionalExpressionType;
  managedOptions?: ConditionalOperatorType[];
  onChange: (expression: ConditionalExpressionType) => void;
}

const ConditionalExpressionRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${props => props.theme.spacersm};
`;

export default function ConditionalExpression({
  expression,
  subject = null,
  managedOptions = Object.values(ConditionalOperatorType),
  onChange
}: ConditionalExpressionProps) {
  return (
    <ConditionalExpressionRoot>
      {expression.type === "managed" ? (
        <>
          {subject}
          <ConditionalOperator
            expression={expression}
            options={managedOptions}
            onChange={expression => {
              onChange({
                ...expression,
                object_template: ManagedExpressionDefinitions.get(expression.operator)
                  ?.hasConditionalObject
                  ? expression.object_template
                  : undefined
              });
            }}
          />
          {ManagedExpressionDefinitions.get(expression.operator)
            ?.hasConditionalObject && (
            <ConditionalObject expression={expression} onChange={onChange} />
          )}
        </>
      ) : (
        <>
          <DebouncedTemplateEditor
            value={expression.template}
            placeholder="true"
            mode="javascript"
            onChange={template => onChange({ ...expression, template })}
          />
        </>
      )}
    </ConditionalExpressionRoot>
  );
}
