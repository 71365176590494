import _ from "lodash";
import { v4 as uuid } from "uuid";

import { AttributeTypes } from "../../../../constants";
import { ParameterType, Resolver } from "../../../../types";
import { MaterialIconType } from "../../../common/Icons/MaterialIcons";
import { assertNever } from "../../../util/assertNever";
import { cacheBy } from "../../../util/cacheBy";
import { fromGlobalId } from "../../../util/graphql";
import {
  Action,
  ActionType,
  Dispatcher,
  DispatcherType,
  EventActionIndexMap,
  ExecuteFunctionOptions,
  Field,
  FieldInputParameter,
  State,
  Transition
} from "../types";

import { ReducerAction, ReducerActions, ReorderPayload } from "./actions";
import {
  createDefaultFieldInputParameter,
  createDefaultInputParameter,
  DEFAULT_STATE_COLOR,
  linkInputParameters,
  toState
} from "./utils";

export interface MoveTask {
  fromStateKey: string;
  toStateKey: string;
}

export interface ConfigState {
  id: string | undefined;
  name: string;
  icon?: MaterialIconType;
  color?: string;
  createTaskInputParameters: FieldInputParameter[];
  description: string;
  actions: Action[];
  states: State[];
  fields: Field[];
  transitions: Transition[];
  selectedStateIndex: number;
  moveTasks?: MoveTask[];
  _references: Record<string, number>;
}

export type IdGenerator = () => string;

export const DEFAULT_GENERATOR: IdGenerator = uuid;

type Reducer = (state: ConfigState, action: ReducerAction) => ConfigState;

export const createReducer = (
  idGenerator: IdGenerator = DEFAULT_GENERATOR
): Reducer => {
  const reducer = (s: ConfigState, a: ReducerAction): ConfigState => {
    switch (a.type) {
      case ReducerActions.ADD_FIELD:
        const fieldKey = idGenerator();
        return {
          ...s,
          fields: [
            ...s.fields,
            {
              key: fieldKey,
              name: "",
              type: AttributeTypes.STRING,
              required: true
            }
          ],
          createTaskInputParameters: [
            ...s.createTaskInputParameters,
            createDefaultFieldInputParameter(fieldKey, "", AttributeTypes.STRING)
          ]
        };
      case ReducerActions.ADD_ACTION: {
        const { dispatcherType, dispatcherIndex, event } = a.payload;

        let collection: Dispatcher[], collectionKey;
        switch (dispatcherType) {
          case DispatcherType.STATE:
            collection = s.states;
            collectionKey = "states";
            break;
          case DispatcherType.TRANSITION:
            collection = s.transitions;
            collectionKey = "transitions";
            break;
          default:
            return assertNever(dispatcherType);
        }
        const dispatcher = collection[dispatcherIndex];
        if (!dispatcher) {
          throw new Error("illegal state");
        }

        const key = idGenerator();
        return {
          ...s,
          _references: {
            ...s._references,
            [key]: (s._references[key] || 0) + 1
          },
          actions: [...s.actions, { key, type: undefined, options: undefined }],
          [collectionKey]: _replace(collection, dispatcherIndex, d =>
            _appendSubscription(d, _.camelCase(event), s.actions.length)
          )
        };
      }
      case ReducerActions.ADD_STATE:
        return {
          ...s,
          states: [
            ...s.states,
            {
              key: idGenerator(),
              name: "New State " + (s.states.length + 1),
              color: DEFAULT_STATE_COLOR,
              isArchive: false,
              assignmentExpanded: true,
              subscriptions: { beforeEnter: [] }
            }
          ],
          selectedStateIndex: s.states.length
        };
      case ReducerActions.ADD_TRANSITION:
        const stateKey = s.states[s.selectedStateIndex].key;
        const newTransitionKey = idGenerator();
        return {
          ...s,
          transitions: [
            ...s.transitions,
            {
              key: newTransitionKey,
              name: "",
              fromStateKey: stateKey,
              toStateKey: stateKey,
              isPrimary: false,
              expanded: true,
              subscriptions: {
                beforeExecute: []
              }
            }
          ]
        };
      case ReducerActions.DELETE_FIELD:
        const field = s.fields[a.payload];
        const ipIndex = s.createTaskInputParameters.findIndex(
          ip => ip.fieldKey === field.key
        );
        return {
          ...s,
          createTaskInputParameters: _remove(s.createTaskInputParameters, ipIndex),
          fields: _remove(s.fields, a.payload)
        };
      case ReducerActions.DELETE_ACTION: {
        return _deleteAction(s, a.payload.actionIndex);
      }
      case ReducerActions.DELETE_SELECTED_STATE: {
        const selectedStateKey = s.states[s.selectedStateIndex].key;
        const toStateKey = a.payload.moveTasksToStateKey;

        // Delete transitions
        const deleteKeys = s.transitions.reduce<string[]>(
          (acc, t) => (t.fromStateKey === selectedStateKey ? [...acc, t.key] : acc),
          []
        );
        let next = deleteKeys.reduce(_deleteTransition, s);

        // Reset transitions that are pointed to this state
        next = {
          ...next,
          transitions: next.transitions.map(t =>
            t.toStateKey === selectedStateKey ? { ...t, toStateKey: undefined } : t
          )
        };

        // Dereference all actions associated with this state
        const dispatcher = next.states[next.selectedStateIndex];
        const deleteIndexes = _dereferenceActions(next, dispatcher);

        const stateToDelete = next.states[s.selectedStateIndex];

        // If any tasks were being moved to this deleted state, move them to the new state
        const moveTasks = toStateKey
          ? (s.moveTasks || [])
              .map(mt => {
                if (mt.toStateKey === stateToDelete.key) {
                  return {
                    ...mt,
                    toStateKey
                  };
                }

                return mt;
              })
              .concat({
                fromStateKey: stateToDelete.key,
                toStateKey
              })
          : s.moveTasks;

        // Cleanup unreferenced actions
        return _cleanActions(
          {
            ...next,
            moveTasks,
            states: _remove(next.states, s.selectedStateIndex),
            selectedStateIndex: s.selectedStateIndex > 0 ? s.selectedStateIndex - 1 : 0
          },
          deleteIndexes
        );
      }
      case ReducerActions.DELETE_TRANSITION:
        const i = s.transitions.findIndex(t => t.key === a.payload);
        const deleteIndexes = _dereferenceActions(s, s.transitions[i]);
        return _cleanActions(
          {
            ...s,
            transitions: _remove(s.transitions, i)
          },
          deleteIndexes
        );
      case ReducerActions.LOAD_EXECUTE_FUNCTION:
        return {
          ...s,
          actions: _replace(s.actions, a.payload.index, action => {
            if (!_isExecuteFunctionAction(action)) {
              throw new Error(`expected ${ActionType.EXECUTE_FUNCTION} type`);
            }
            return {
              ...action,
              options: {
                functionId: fromGlobalId(a.payload.func.id)[1],
                inputParameters:
                  action.options?.inputParameters ||
                  a.payload.func.functionParameters.edges
                    .filter(({ node }) => node.required)
                    .map(({ node }) =>
                      createDefaultInputParameter(node.name, node.type)
                    )
              }
            };
          })
        };
      case ReducerActions.LOAD_QUEUE:
        return {
          ...toState(a.payload),
          selectedStateIndex: s.selectedStateIndex
        };
      case ReducerActions.SELECT_STATE_INDEX:
        return {
          ...s,
          selectedStateIndex: a.payload
        };
      case ReducerActions.UPDATE_ACTION:
        let updated = a.payload.action;
        if (
          updated.type === ActionType.UPDATE_TASK &&
          !s.actions[a.payload.index].options
        ) {
          updated = {
            ...updated,
            options: {
              inputParameters: [
                {
                  binding: "task.id",
                  name: "id",
                  type: ParameterType.BINDING,
                  resolver: Resolver.PIPELINE
                }
              ]
            }
          };
        }
        return {
          ...s,
          actions: _replace(s.actions, a.payload.index, action => ({
            ...action,
            ...updated
          }))
        };
      case ReducerActions.UPDATE_CREATE_TASK_INPUT_PARAMETERS:
        return {
          ...s,
          createTaskInputParameters: linkInputParameters(
            s.fields,
            a.payload.inputParameters
          )
        };
      case ReducerActions.UPDATE_DESCRIPTION:
        return {
          ...s,
          description: a.payload
        };
      case ReducerActions.UPDATE_EXECUTE_FUNCTION_ID:
        return {
          ...s,
          actions: _replace(s.actions, a.payload.index, action => ({
            ...action,
            options: {
              functionId: a.payload.functionId,
              inputParameters: undefined
            }
          }))
        };
      case ReducerActions.UPDATE_ACTION_INPUT_PARAMETERS:
        return {
          ...s,
          actions: _replace(s.actions, a.payload.index, action => {
            return {
              ...action,
              options: {
                ...action.options,
                inputParameters: a.payload.inputParameters
              }
            };
          })
        };
      case ReducerActions.UPDATE_FIELD: {
        const newField = a.payload.field;
        const existingField = s.fields[a.payload.index];

        const fields = _replace(s.fields, a.payload.index, field => ({
          ...field,
          ...newField
        }));

        const ipIndex = s.createTaskInputParameters.findIndex(
          ip => ip.fieldKey === existingField.key
        );

        let createTaskInputParameters = s.createTaskInputParameters;

        if (ipIndex > -1) {
          const existingParameter = s.createTaskInputParameters[ipIndex];
          // if field type changes AND the parameter is type component,
          // the component type and/or validation type will need to change, as well.
          if (existingParameter.type === ParameterType.COMPONENT && newField.type) {
            // current logic does not carry over any compatible componentProperties
            // when changing component and/or validation type
            const updatedParam = createDefaultFieldInputParameter(
              existingField.key,
              ("name" in newField ? newField.name : existingParameter.name) || "",
              newField.type || AttributeTypes.STRING
            );
            createTaskInputParameters = _replace(
              s.createTaskInputParameters,
              ipIndex,
              _ip => updatedParam
            );
          } else {
            createTaskInputParameters = _replace(
              s.createTaskInputParameters,
              ipIndex,
              ip => ("name" in newField ? { ...ip, name: newField.name! } : { ...ip })
            );
          }
        } else if ("required" in newField && newField.required) {
          createTaskInputParameters = _mergeParameters(fields, [
            ...s.createTaskInputParameters,
            createDefaultFieldInputParameter(
              existingField.key,
              existingField.name,
              existingField.type
            )
          ]);
        }
        return { ...s, createTaskInputParameters, fields };
      }
      case ReducerActions.UPDATE_FIELD_ORDER: {
        const fields = _reorder(s.fields, a.payload);
        return {
          ...s,
          fields
        };
      }
      case ReducerActions.UPDATE_NAME:
        return {
          ...s,
          name: a.payload
        };
      case ReducerActions.UPDATE_SELECTED_STATE:
        return {
          ...s,
          states: _replace(s.states, s.selectedStateIndex, state => ({
            ...state,
            ...a.payload
          }))
        };
      case ReducerActions.UPDATE_STATE_ORDER:
        let selectedStateIndex = s.selectedStateIndex;
        if (a.payload.sourceIndex === s.selectedStateIndex) {
          selectedStateIndex = a.payload.destinationIndex;
        } else if (a.payload.destinationIndex === s.selectedStateIndex) {
          selectedStateIndex = a.payload.sourceIndex;
        }
        return {
          ...s,
          states: _reorder(s.states, a.payload),
          selectedStateIndex
        };
      case ReducerActions.UPDATE_TRANSITION: {
        const i = s.transitions.findIndex(t => t.key === a.payload.key);
        if (i === -1) throw new Error("illegal state");
        return {
          ...s,
          transitions: _replace(s.transitions, i, transition => ({
            ...transition,
            ...a.payload
          }))
        };
      }
      case ReducerActions.TOGGLE_TRANSITION_EXPANDED: {
        return {
          ...s,
          transitions: s.transitions.map(t => {
            if (t.key === a.payload) {
              return {
                ...t,
                expanded: !t.expanded
              };
            }

            return t;
          })
        };
      }
      case ReducerActions.TOGGLE_STATE_AUTOMATIONS_EXPANDED: {
        return {
          ...s,
          states: s.states.map(s => {
            if (s.key === a.payload) {
              return {
                ...s,
                assignmentExpanded: !s.assignmentExpanded
              };
            }

            return s;
          })
        };
      }
      case ReducerActions.UPDATE_ICON: {
        return {
          ...s,
          icon: a.payload
        };
      }
      case ReducerActions.UPDATE_COLOR: {
        if (a.payload.length !== 6) {
          throw new Error("color must be hex with 6 characters");
        }

        return {
          ...s,
          color: a.payload
        };
      }
      default:
        return assertNever(a);
    }
  };
  return reducer;
};

const _isExecuteFunctionAction = (a: Action): a is Action<ExecuteFunctionOptions> =>
  a.type === ActionType.EXECUTE_FUNCTION;

const _reorder = <T>(arr: T[], payload: ReorderPayload) => {
  const { sourceIndex, destinationIndex } = payload;
  const copy = [...arr];
  const source = copy.splice(sourceIndex, 1)[0];
  copy.splice(destinationIndex, 0, source);
  return copy;
};

/**
 * Replaces a single item in an array.  Returns a new array object with the replaced item.
 * This is a convenience function for calling splice in a reducer as splice modifies the array in place
 * which is usually NOT what you want in a reducer.
 * @param arr - An array
 * @param idx - The index of the item to replace
 * @param replaceFunc - A function that returns the replacement item
 */
const _replace = <T>(arr: T[], idx: number, replaceFunc: (item: T) => T): T[] => {
  const next = [...arr];
  next.splice(idx, 1, replaceFunc(arr[idx]));
  return next;
};

const _remove = <T>(arr: T[], idx: number): T[] => {
  const next = [...arr];
  next.splice(idx, 1);
  return next;
};

const _appendSubscription = <T extends { subscriptions: { [k: string]: number[] } }>(
  target: T,
  event: string,
  actionIndex: number
): T => ({
  ...target,
  subscriptions: {
    ...target.subscriptions,
    [event]: [...target.subscriptions[event], actionIndex]
  }
});

const _removeSubscription = <T extends { subscriptions: EventActionIndexMap }>(
  target: T,
  actionIndex: number
): T => ({
  ...target,
  subscriptions: Object.entries(target.subscriptions).reduce<EventActionIndexMap>(
    (mapping, [event, indexes]) => {
      mapping[event] = indexes.reduce<number[]>((acc, v) => {
        if (v < actionIndex) acc.push(v);
        else if (v > actionIndex) acc.push(v - 1);
        return acc;
      }, []);
      return mapping;
    },
    {}
  )
});

const _deleteAction = (s: ConfigState, actionIndex: number): ConfigState => {
  return {
    ...s,
    actions: _remove(s.actions, actionIndex),
    transitions: s.transitions.map(dispatcher =>
      _removeSubscription(dispatcher, actionIndex)
    ),
    states: s.states.map(dispatcher => _removeSubscription(dispatcher, actionIndex))
  };
};

const _deleteTransition = (s: ConfigState, key: string): ConfigState => {
  const i = s.transitions.findIndex(t => t.key === key);

  // Get all action indexes
  const actionIndexes = Object.values(s.transitions[i].subscriptions).flat();

  // Dereference actions and queue unreferenced actions for delete
  const deleteIndexes: number[] = [];
  actionIndexes.forEach(index => {
    const key = s.actions[index].key;
    const count = --s._references[key];
    if (count === 0) {
      deleteIndexes.push(index);
    }
  });
  deleteIndexes.sort((a, b) => b - a);

  // Delete actions
  return deleteIndexes.reduce(_deleteAction, {
    ...s,
    transitions: _remove(s.transitions, i)
  });
};

const _dereferenceActions = (s: ConfigState, dispatcher: Dispatcher): number[] => {
  const actionIndexes = Object.values(dispatcher.subscriptions).reduce(
    (acc, indexes) => [...acc, ...indexes],
    []
  );

  // Dereference actions and queue unreferenced actions for delete
  const deleteIndexes: number[] = [];
  actionIndexes.forEach(index => {
    const key = s.actions[index].key;
    const count = --s._references[key];
    if (count === 0) {
      deleteIndexes.push(index);
    }
  });

  return deleteIndexes;
};

const _cleanActions = (s: ConfigState, deleteIndexes: number[]) =>
  deleteIndexes.sort((a, b) => b - a).reduce(_deleteAction, s);

const _mergeParameters = (
  fields: Field[],
  inputParameters: FieldInputParameter[]
): FieldInputParameter[] => {
  const ips = cacheBy(inputParameters, "fieldKey");
  return [
    createDefaultFieldInputParameter(null, "title", AttributeTypes.STRING),
    ...fields.reduce<FieldInputParameter[]>((acc, f) => {
      const ip = ips[f.key];
      if (ip) acc.push(ip);
      return acc;
    }, [])
  ];
};
