import { flow, partial } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import { SpaceComponentObject } from "../../../../../../types";
import { commonComponentReducer } from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import { BaseComponentConfigState, SpaceConfigAction } from "../../../../types";
import { DefaultValueType } from "../../../constants";
import inputReducer from "../../common/inputReducer";
import { getCommonInputProperties } from "../../common/util";
import { hasInputComponentProperties } from "../../util/util";
import { SpaceCustomFieldComponent, SpaceCustomFieldProperties } from "../types";

interface CustomFieldConfigState
  extends BaseComponentConfigState<SpaceCustomFieldComponent> {
  type: "CUSTOM_FIELD";
}

export const INITIAL_STATE = {
  type: "CUSTOM_FIELD" as const,
  draftComponent: {} as SpaceCustomFieldComponent
};

export function makeInitialState(
  draftComponent: SpaceComponentObject
): CustomFieldConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureCustomFieldComponent(draftComponent)
  };
}

export const getComponentProperties = (
  properties: Partial<SpaceCustomFieldProperties>
): SpaceCustomFieldProperties => ({
  ...properties,
  ...getCommonInputProperties(
    properties,
    DefaultValueType.TEXT_VALUE,
    "",
    AttributeTypes.STRING,
    true
  ),
  type: properties.type || "text"
});

export function ensureCustomFieldComponent(
  component: SpaceComponentObject
): SpaceCustomFieldComponent {
  if (
    !!component.type &&
    typeof component.properties.placeholder === "string" &&
    hasInputComponentProperties(component)
  ) {
    return component;
  }
  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}

export default (state: CustomFieldConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(inputReducer, partial.placeholder, action)])(
    state,
    action
  );
