import {
  AttributeFilterProperties,
  BaseFunctionName,
  ChangeSetProperties
} from "../../../../../../types";
import { assertNever } from "../../../../../util/assertNever";
import { SqlWriteFunctionName } from "../../constants";
import {
  formatChangeSetForSubmit,
  formatFiltersForSubmit,
  getJavaScriptParsingError
} from "../../utils";

import { ErrorMessageKeys, ErrorMessageState, SqlActionBaseState } from "./reducer";
import { WriteBaseFunctionParameterMapping } from "./WriteForm";

export const ErrorMessages = {
  actionType: "Please select an action type.",
  resourceSourceId: "Please select a resource.",
  filters:
    "Please include at least one valid filter. Make sure all filters have an attribute and operator, and that the value is a valid JavaScript expression.",
  changeSet:
    "Please include at least one value in your changeset. Make sure you've selected an attribute for each change, and that the value is a valid JavaScript expression."
};

const isFilterValid = (filters: AttributeFilterProperties[]) => {
  if (!filters || !filters.length) return false;
  const hasError = filters.some(filter => {
    return (
      !filter.column ||
      !filter.operator ||
      getJavaScriptParsingError(filter.value, true)
    );
  });
  return !hasError;
};

const isChangeSetValid = (changeSet: ChangeSetProperties[]) => {
  if (!changeSet || !changeSet.length) return false;
  const hasError = changeSet.some(changeSetItem => {
    return (
      !changeSetItem.column || getJavaScriptParsingError(changeSetItem.value, true)
    );
  });
  return !hasError;
};

export const getFunctionErrors = (
  functionState: SqlActionBaseState
): ErrorMessageState => {
  const errors: ErrorMessageState = {
    resourceSourceId: "",
    filters: "",
    changeSet: ""
  };
  if (!functionState.resourceSourceId) {
    errors[ErrorMessageKeys.ResourceSourceId] =
      ErrorMessages[ErrorMessageKeys.ResourceSourceId];
  }
  if (
    functionState.actionType === BaseFunctionName.UPDATE ||
    functionState.actionType === BaseFunctionName.DELETE
  ) {
    if (!isFilterValid(functionState.filters)) {
      errors[ErrorMessageKeys.Filters] = ErrorMessages[ErrorMessageKeys.Filters];
    }
  }
  if (
    (functionState.actionType === BaseFunctionName.INSERT ||
      functionState.actionType === BaseFunctionName.UPDATE) &&
    !isChangeSetValid(functionState.changeSet)
  ) {
    errors[ErrorMessageKeys.ChangeSet] = ErrorMessages[ErrorMessageKeys.ChangeSet];
  }
  return errors;
};

export const format = (
  resourceSourceId: string | undefined,
  actionType: SqlWriteFunctionName,
  changeSet: ChangeSetProperties[],
  filters: AttributeFilterProperties[],
  allowMultiple: boolean
): WriteBaseFunctionParameterMapping => {
  const table = resourceSourceId ? JSON.stringify(resourceSourceId) : "";

  switch (actionType) {
    case BaseFunctionName.UPDATE:
      return {
        table,
        set: formatChangeSetForSubmit(changeSet),
        where: formatFiltersForSubmit(filters),
        multiple: JSON.stringify(allowMultiple)
      };
    case BaseFunctionName.INSERT:
      return {
        into: table,
        values: formatChangeSetForSubmit(changeSet)
      };
    case BaseFunctionName.DELETE:
      return {
        from: table,
        where: formatFiltersForSubmit(filters),
        multiple: JSON.stringify(allowMultiple)
      };
    default:
      return assertNever(actionType);
  }
};
