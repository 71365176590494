import React from "react";

import { Icon as AntIcon } from "antd";
import styled from "styled-components";

import { SpaceComponentObject } from "../../../../../../types";
import { useStableSpaceContext } from "../../../SpaceContext";

interface Props {
  component: SpaceComponentObject;
  showErrorIcon: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    max-width: 36px;
    margin-right: ${props => props.theme.spacersm};
    opacity: 0.87;
  }
`;

const ErrorIconContainer = styled.div`
  display: flex;
  align-content: center;
`;

const Warning = styled(AntIcon)`
  margin-right: ${props => props.theme.spacerxs};
  color: ${props => props.theme.errorColor};
  align-self: center;
`;

export default function ChildComponentItem({ component, showErrorIcon }: Props) {
  const { getSpaceComponentDisplayName } = useStableSpaceContext();

  if (!component) return null;

  return (
    <Container>
      <IconContainer>
        <span>{getSpaceComponentDisplayName(component)}</span>
      </IconContainer>
      <ErrorIconContainer>
        {showErrorIcon && <Warning type="exclamation-circle" />}
      </ErrorIconContainer>
    </Container>
  );
}
