import { AttributeTypes } from "../../../../../../constants";
import { toAttributeType } from "../util";

export interface Option {
  label: string;
  value: string | null;
}

export const parseOptionsCsv = (input: string) => {
  return input.split("\n").reduce((agg: Option[], current: string) => {
    const parts = current.split(",");
    const label = parts[0].trim();
    // if only one value, set as both label and value
    if (parts.length === 1 && label.length) {
      agg.push({
        label,
        value: label
      });
    } else if (parts.length === 2) {
      agg.push({
        label,
        value: parts[1].trim()
      });
    }
    return agg;
  }, [] as Option[]);
};

export const booleanValueToString = (value: any) => {
  if (value === true) return "true";
  if (value === false) return "false";
  return undefined;
};

// use string values to easily convert to and from csv
const getDefaultBooleanStringOptions = (allowNull: boolean) => {
  const boolOptions: Option[] = [
    {
      label: "True",
      value: "true"
    },
    {
      label: "False",
      value: "false"
    }
  ];
  if (allowNull) {
    boolOptions.push({
      label: "Null",
      value: "null"
    });
  }
  return boolOptions;
};

export const getBooleanHardcodedOptionsString = ({
  validation_type,
  allow_null,
  hardcoded_options
}: {
  validation_type: string;
  allow_null: boolean;
  hardcoded_options: string;
}) => {
  const options = parseOptionsCsv(hardcoded_options || "");
  const isBoolean = toAttributeType(validation_type) === AttributeTypes.BOOL;
  const allowNull = allow_null || false;
  if (isBoolean) {
    const booleanOptions: Option[] = getDefaultBooleanStringOptions(allowNull);
    booleanOptions.forEach((boolOption: Option, i) => {
      const oldOption = options.find(o => o.value === boolOption.value);
      if (oldOption) {
        booleanOptions[i].label = oldOption.label;
      }
    });
    return booleanOptions
      .map(boolOption => `${boolOption.label},${boolOption.value}`)
      .join("\n");
  }
  return hardcoded_options || "";
};

export const getInitialHardcodedOptions = (properties: {
  validation_type?: string;
  allow_null?: boolean;
  hardcoded_options?: string;
}) => {
  if (
    toAttributeType(properties.validation_type) === AttributeTypes.BOOL &&
    !properties.hardcoded_options
  ) {
    return getBooleanHardcodedOptionsString({
      ...properties,
      validation_type: properties.validation_type || AttributeTypes.STRING,
      allow_null: properties.allow_null || false,
      hardcoded_options: properties.hardcoded_options || ""
    });
  }
  return properties.hardcoded_options || "";
};
