export interface URLCredentials {
  adapter: string;
  scheme: string;
  host: string;
  port?: string;
  path?: string;
}

export const displayURL = (credentials: URLCredentials) => {
  switch (credentials.adapter) {
    case "hubspot":
      return "https://api.hubapi.com";
    case "stripe":
      return "https://api.stripe.com";
    case "zendesk":
      const domain = (credentials as any).domain;
      return `https://${domain}.zendesk.com`;
  }
  let path = `${credentials.scheme}://${credentials.host}`;
  if (credentials.port) {
    path += ":" + credentials.port;
  }
  if (credentials.path) {
    path += "/" + credentials.path;
  }
  return path;
};
