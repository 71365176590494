import React, { ChangeEvent, Dispatch } from "react";

import GeneralSettings from "../../../common/GeneralSettings";
import { ConfigAction, ConfigState, ReducerActions } from "../reducer";

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ConfigAction>;
}
export const General = ({ state, dispatch }: Props) => {
  const handleNameChange = React.useCallback(
    (evt: ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          name: evt.target.value
        }
      }),
    [dispatch]
  );
  const handleDescriptionChange = React.useCallback(
    (evt: ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          description: evt.target.value
        }
      }),
    [dispatch]
  );
  const handleIconChange = React.useCallback(
    (icon: string) =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: { icon }
      }),
    [dispatch]
  );
  const handleColorChange = React.useCallback(
    (color: string) =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          color: color.slice(1)
        }
      }),
    [dispatch]
  );

  return (
    <GeneralSettings
      title="Automation"
      state={state}
      onNameChange={handleNameChange}
      onDescriptionChange={handleDescriptionChange}
      onIconChange={handleIconChange}
      onColorChange={handleColorChange}
    />
  );
};

export default General;
