import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

import { Row } from "./common/styledComponents";

const SSLMode = styled.span`
  padding-left: ${props => props.theme.spacerxs};
`;

const SSLRow = styled(Row)`
  margin-top: ${props => props.theme.spacersm};
`;

interface Props {
  credentials: any;
}

const SecureConnectionRow = ({ credentials }: Props) => {
  const normalized = credentials.sslmode || credentials.scheme || "default_https";
  return (
    <>
      <SSLRow>
        <>
          {normalized === "prefer" && (
            <span>
              <Icon type="unlock" theme="outlined" /> Secure
              <SSLMode>(SSL is preferred but not guaranteed when connecting)</SSLMode>
            </span>
          )}
          {normalized === "require" && (
            <span>
              <Icon type="lock" theme="outlined" /> Secure
              <SSLMode>(SSL is required to connect)</SSLMode>
            </span>
          )}
          {normalized === "verify-full" && (
            <span>
              <Icon type="lock" theme="outlined" /> Secure
              <SSLMode>(SSL certificate is verified when making a connection)</SSLMode>
            </span>
          )}
          {normalized === "http" && (
            <span>
              <Icon type="unlock" theme="outlined" /> Insecure
              <SSLMode>
                (Plain HTTP is susceptible to man in the middle attacks)
              </SSLMode>
            </span>
          )}
          {(normalized === "https" || normalized === "default_https") && (
            <span>
              <Icon type="lock" theme="outlined" /> Secure
              <SSLMode>(HTTPS)</SSLMode>
            </span>
          )}
        </>
      </SSLRow>
    </>
  );
};

export default SecureConnectionRow;
