import React from "react";

import { Select } from "antd";
import produce from "immer";

import {
  FilterTypes,
  ViewFilterOperator as Operator,
  ViewFilterOperatorHumanizedDisplayNames as HumanizedOperators,
  Filter,
  ValueFilter,
  BindingFilter
} from "../../../../../../../../types";

interface Props {
  filter: Filter;
  onChange: (filter: Filter) => void;
  supportedOperators: Operator[];
}

const OPERATOR_GROUPS: [FilterTypes, string, string][] = [
  [FilterTypes.Value, "Value", ""],
  [FilterTypes.Binding, "Value From Component", " component value"]
];

export const OPERATORS = Object.keys(HumanizedOperators) as Operator[];

export const getNextValueOnOperatorChange = (value: any, isNull: boolean) => {
  if (isNull) return null;
  if (!isNull && value === null) return undefined;
  return value;
};

export default function FilterOperator({
  supportedOperators,
  filter,
  onChange
}: Props) {
  const disabledOperators = React.useMemo(() => {
    const lookup = supportedOperators.reduce<Record<string, boolean>>(
      (acc, op) => ({ ...acc, [op]: true }),
      {}
    );
    return OPERATORS.filter(op => !lookup[op]);
  }, [supportedOperators]);

  let operator = `${filter.type}.${filter.operator}`.toLowerCase();
  if (
    [Operator.EQUALS, Operator.NOT_EQUALS].includes(filter.operator) &&
    filter.type === FilterTypes.Value &&
    filter.value === null
  ) {
    operator = operator + ".null";
  }

  React.useEffect(() => {
    const availableOperators = OPERATORS.filter(
      value => !disabledOperators.includes(value)
    );
    if (
      disabledOperators.includes(filter.operator) &&
      availableOperators &&
      availableOperators.length
    ) {
      const firstEnabledOperator = availableOperators[0];
      onChange({
        ...filter,
        operator: firstEnabledOperator || Operator.EQUALS
      });
    }
  }, [filter, disabledOperators, onChange]);

  const onOperatorSelect = (value: string) => {
    const [filterType, filterOperator, filterValue] = value.split(".", 3) as [
      FilterTypes,
      Operator,
      "null" | void
    ];

    onChange(
      produce(filter, (nextFilter: Filter) => {
        nextFilter.type = filterType;
        nextFilter.operator = filterOperator;
        (nextFilter as ValueFilter).value =
          filterType === FilterTypes.Value
            ? getNextValueOnOperatorChange(
                (filter as ValueFilter).value,
                filterValue === "null"
              )
            : undefined;
        (nextFilter as BindingFilter).binding =
          filterType === FilterTypes.Binding
            ? (nextFilter as BindingFilter).binding
            : undefined;
      })
    );
  };

  return (
    <Select
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      className="operatorSelect"
      value={operator}
      onChange={onOperatorSelect}
      data-test="operatorSelect"
    >
      {OPERATOR_GROUPS.map(([groupValue, groupLabel, groupLabelSuffix]) => {
        return (
          <Select.OptGroup key={groupValue} label={groupLabel}>
            {OPERATORS.map(operator => {
              const value = `${groupValue}.${operator}`.toLowerCase();
              return (
                <Select.Option
                  disabled={disabledOperators.includes(operator)}
                  key={value}
                  value={value}
                >
                  {HumanizedOperators[operator]}
                  {groupLabelSuffix}
                </Select.Option>
              );
            })}
            {groupValue === FilterTypes.Value && (
              <Select.Option
                disabled={disabledOperators.includes(Operator.EQUALS)}
                key={`${groupValue}.${Operator.EQUALS}.null`}
                value={`${groupValue}.${Operator.EQUALS}.null`}
              >
                is null
              </Select.Option>
            )}
            {groupValue === FilterTypes.Value && (
              <Select.Option
                disabled={disabledOperators.includes(Operator.NOT_EQUALS)}
                key={`${groupValue}.${Operator.NOT_EQUALS}.null`}
                value={`${groupValue}.${Operator.NOT_EQUALS}.null`}
              >
                is not null
              </Select.Option>
            )}
            {/* within last is a relative filter type but should appear to the user available with value filters  */}
            {groupValue === FilterTypes.Value && (
              <Select.Option
                disabled={disabledOperators.includes(Operator.GREATER_THAN)}
                key={`${FilterTypes.Duration}.${Operator.GREATER_THAN}`}
                value={`${FilterTypes.Duration}.${Operator.GREATER_THAN}`}
              >
                is within last
              </Select.Option>
            )}
          </Select.OptGroup>
        );
      })}
    </Select>
  );
}
