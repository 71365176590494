import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Icon, Spin, Table } from "antd";
import { ExecutionResult, MutationFunctionOptions } from "react-apollo";

import {
  FETCH_DATA_SOURCE_SYNC_STATUS,
  FETCH_SYNC_PLANS_FOR_DATA_SOURCE,
  SYNC_CONFIRM_MUTATION,
  SyncConfirmMutationResult,
  SyncNode,
  SyncStatusData
} from "../../../graphql/sync";
import { SyncStatus } from "../../../types";
import Message from "../../common/Message";

import * as styled from "./styledComponents";

interface Props {
  visible: boolean;
  loading: boolean;
  dataSource: { id: string; name: string };
  sync: SyncNode | undefined;
  onSuccess: () => void;
  onError: () => void;
  onCancel: () => void;
}

type SyncConfirmMutationFn = (
  options?: MutationFunctionOptions<SyncConfirmMutationResult, Record<string, any>>
) => Promise<ExecutionResult<SyncConfirmMutationResult>>;

const TABLE_COLUMNS = [
  {
    title: "Change",
    dataIndex: "operationLabel",
    key: "change",
    width: "35%"
  },
  {
    title: "Name",
    dataIndex: "description",
    key: "description",
    width: "65%",
    render: (text: string) => {
      return <span>{text || "-"}</span>;
    }
  }
];

function SyncModal(props: Props) {
  const handleError = () => {
    onError();
    setHasErrors(true);
  };

  const cancelSyncPlan = () => {
    setHasErrors(false);
    onCancel();
  };

  const confirmSyncPlan = async function (syncConfirmFn: SyncConfirmMutationFn) {
    if (!sync) return;

    try {
      await syncConfirmFn({ variables: { syncId: sync.id } });
      setPollInterval(2000);
    } catch (e) {
      handleError();
    }
  };

  const { visible, loading, dataSource, sync, onSuccess, onError, onCancel } = props;
  const [hasErrors, setHasErrors] = React.useState(false);
  const [pollInterval, setPollInterval] = React.useState(0);

  const { data } = useQuery<SyncStatusData>(FETCH_DATA_SOURCE_SYNC_STATUS, {
    variables: { dataSourceId: dataSource.id },
    pollInterval,
    skip: !pollInterval
  });

  React.useEffect(() => {
    if (data?.dataSource.lastSync?.status === SyncStatus.COMPLETE) {
      setPollInterval(0);
      setHasErrors(false);
      Message.success(`${dataSource.name} has been successfully synced.`);
      onSuccess();
    }
  }, [data, setPollInterval, setHasErrors, dataSource.name, onSuccess]);

  const [syncConfirmFn, { loading: confirmLoading }] =
    useMutation<SyncConfirmMutationResult>(SYNC_CONFIRM_MUTATION, {
      refetchQueries: [
        {
          query: FETCH_SYNC_PLANS_FOR_DATA_SOURCE,
          variables: { dataSourceId: dataSource.id }
        }
      ]
    });

  const operations = sync ? sync.operations.edges.map(edge => edge.node) : [];
  const hasOperations = operations && operations.length > 0;
  const modalLoading = loading;

  let modalContent;
  let cancelButtonProps;
  let okText;
  let onOk = () => cancelSyncPlan();
  if (modalLoading) {
    okText = "Okay";
    modalContent = (
      <div className="syncModal__messageContainer">
        <Spin size="large" />
      </div>
    );
  } else if (hasOperations && !hasErrors) {
    okText = "Update Schema";
    onOk = () => confirmSyncPlan(syncConfirmFn);
    modalContent = (
      <div>
        <div>
          We found {operations.length} schema changes. To update Internal with these
          changes, click 'Update Schema'. Your users will immediately see these changes.
        </div>
        <br />
        <styled.TableContainer>
          <Table
            dataSource={operations}
            pagination={{ pageSize: 4 }}
            columns={TABLE_COLUMNS}
          />
        </styled.TableContainer>
      </div>
    );
  } else {
    const message = !hasErrors
      ? "There is nothing to sync at this time. Your schema is up to date!"
      : "Oops, we encountered an issue. Please try again.";
    okText = !hasErrors ? "Okay" : "Try Again";
    cancelButtonProps = { className: "hidden" };
    modalContent = (
      <div className="syncModal__messageContainer">
        <div className="message">{message}</div>
      </div>
    );
  }

  return (
    <styled.StyledModal
      title={
        <div>
          <Icon type="sync" />
          <span>Preview schema changes.</span>
        </div>
      }
      okText={okText}
      closable={false}
      cancelButtonProps={cancelButtonProps}
      centered={true}
      width="70%"
      visible={visible}
      confirmLoading={confirmLoading || !!pollInterval}
      onOk={onOk}
      onCancel={() => cancelSyncPlan()}
      footer={modalLoading ? null : undefined}
    >
      {modalContent}
    </styled.StyledModal>
  );
}

export default SyncModal;
