import { ObjectMode } from "./types";

export const getObjectMode = (objectTemplate: string | undefined): ObjectMode => {
  if (typeof objectTemplate !== "string") {
    return "void";
  } else if (objectTemplate.match(/^`.*?`$/)) {
    return "text";
  } else {
    return "binding";
  }
};
