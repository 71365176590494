import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Col, Row, Form, Input, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import gql from "graphql-tag";
import TagManager from "react-gtm-module";
import { Navigate } from "react-router";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useConfigContext } from "../../ConfigContext";
import { Routes } from "../../constants";
import { publicClient } from "../../graphql";
import { AuthStatus } from "../../types";
import { AuthStatusProps, withAuthStatus } from "../auth/WithAuthStatus/withAuthStatus";
import usePaths from "../common/hooks/usePaths";
import { LogoColor } from "../common/Logo";
import { GoogleLoginButton, DarkTheme, FormItem } from "../common/StyledComponents";
import TitleContainer from "../common/TitleContainer";
import LicenseNoSeatsWarning from "../license/LicenseNoSeatsWarning";
import { VALIDATION_MESSAGES } from "../util/ClientValidator";

import CheckEmail from "./CheckEmail";
import RequestInvite from "./RequestInvite";

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization($email: String!) {
    createOrganization(email: $email) {
      status
    }
  }
`;

const Em = styled.span`
  text-decoration: underline;
  text-decoration-color: ${props => props.theme.secondaryColor};
`;

const FormContainer = styled.div`
  width: 380px;
  max-width: 100%;
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;

  .ant-input-search-enter-button
    input
    + .ant-input-group-addon
    .ant-input-search-button {
    width: 90px;
  }

  .ant-input-search-button[disabled],
  .ant-input-search-button[disabled]:hover {
    background-color: #a560eb;
    border-color: #a560eb;
    color: #fff;
  }
`;

const FormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const OrRow = styled(Row)`
  line-height: 30px;
  margin: 12px 0px;

  div#or_strikethrough {
    display: flex;
    flex-direction: row;

    span {
      margin-left: 7px;
      margin-right: 7px;
    }
  }

  div:before,
  div:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin: auto;
  }
`;

interface Fields {
  email: string;
}
type Props = FormComponentProps<Fields>;

function CreateOrganizationForm(props: Props & AuthStatusProps) {
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [requestEmail, setRequestEmail] = useState<string>("");
  const { isOnPrem, license } = useConfigContext();
  const { isGoogleOAuthEnabled } = useConfigContext();
  const { getDashboard } = usePaths();
  const [createOrganization, { loading: submitting }] = useMutation(
    CREATE_ORGANIZATION_MUTATION,
    {
      client: publicClient,
      onCompleted: data => {
        const email = props.form.getFieldValue("email");
        const { status } = data.createOrganization;
        switch (status) {
          case "SUCCESS":
            setConfirmEmail(email);
            TagManager.dataLayer({ dataLayer: { event: "emailSignup" } });
            break;
          case "ORGANIZATION_EXISTS":
            setRequestEmail(email);
            break;
          default:
            const messages: Record<string, string> = {
              INVALID_EMAIL: "Please provide a valid work email address.",
              USER_EXISTS: "A user with this email already exists, please log in.",
              NO_SEATS: "You have used all available license seats."
            };
            const error = new Error(
              messages[status] || "An unknown error occurred, please try again."
            );
            props.form.setFields({ email: { value: email, errors: [error] } });
        }
      }
    }
  );

  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search || "");
  const ssoEmail = urlParams.get("ssoEmail");
  const ssoRequestInvite = urlParams.get("ssoRequestInvite") === "true";

  if (ssoRequestInvite && ssoEmail && ssoEmail !== requestEmail) {
    setRequestEmail(ssoEmail);
    navigate(Routes.SIGNUP);
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    props.form.validateFields((errors: any, values: Fields) => {
      if (errors) {
        return;
      }

      createOrganization({ variables: { email: values.email } });
    });
  };

  if (props.status === AuthStatus.Authenticated) {
    return <Navigate to={getDashboard()} />;
  }

  if (confirmEmail) {
    return <CheckEmail email={confirmEmail} />;
  }

  if (requestEmail) {
    return (
      <RequestInvite email={requestEmail} onComplete={() => setRequestEmail("")} />
    );
  }

  return (
    <React.Fragment>
      <DarkTheme />
      <TitleContainer
        useLogoAsTitle={true}
        logoColor={LogoColor.White}
        title="Internal"
        description={
          <span>
            Create a <Em>free</Em> account. Get started by entering your work email.
          </span>
        }
      >
        <FormContainer>
          <Col>
            {isOnPrem && license && <LicenseNoSeatsWarning license={license} />}
            <Row>
              <a href={Routes.SIGNUP_GOOGLE_AUTH}>
                <GoogleLoginButton
                  disabled={!isGoogleOAuthEnabled || (isOnPrem && !license?.hasSeats)}
                >
                  Sign up with Google
                </GoogleLoginButton>
              </a>
            </Row>
            <OrRow>
              <div id="or_strikethrough">
                <span>or</span>
              </div>
            </OrRow>
            <Row>
              <Form onSubmit={handleSubmit}>
                <FormItem>
                  {props.form.getFieldDecorator("email", {
                    validateTrigger: "onSubmit",
                    rules: [
                      {
                        type: "email",
                        message: VALIDATION_MESSAGES.email
                      },
                      {
                        required: true,
                        whitespace: true,
                        message: VALIDATION_MESSAGES.requiredField
                      }
                    ]
                  })(
                    <Input.Search
                      enterButton={submitting ? <Icon type="loading" /> : "Sign Up"}
                      placeholder="Work email"
                      disabled={submitting || (isOnPrem && !license?.hasSeats)}
                      onSearch={(value: string, e: any) => handleSubmit(e)}
                      size="large"
                    />
                  )}
                </FormItem>

                <FormFooter>
                  <Link to={Routes.LOGIN}>Already have an account?</Link>
                </FormFooter>
              </Form>
            </Row>
          </Col>
        </FormContainer>
      </TitleContainer>
    </React.Fragment>
  );
}

export default Form.create<Props>({
  name: "createOrganizationForm"
})(withAuthStatus(CreateOrganizationForm));
