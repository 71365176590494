import React from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Alert, Skeleton } from "antd";
import styled from "styled-components";

import { SpacingUnit } from "../../../../cssConstants";
import {
  AuthorizationFlowStepType,
  AuthorizationFlowStepNode,
  AuthorizationFlowNode
} from "../../../../types";
import { reportException } from "../../../util/exceptionReporting";
import { useStableSpaceContext } from "../SpaceContext/StableSpaceContext";

import {
  AuthFlowsForSpaceResult,
  AuthFlowsForSpaceVars,
  AUTH_FLOWS_FOR_SPACE_QUERY,
  AuthFlowProcessStepMutationResult,
  AuthFlowProcessStepMutationVars,
  AUTH_FLOW_PROCESS_STEP
} from "./queries";
import reducer, { initialState } from "./reducer";
import InitiateStep from "./steps/InitiateStep";
import InputStep from "./steps/InputStep";

export interface AuthorizationFlowWrapperProps {
  children: React.ReactNode;
}

export interface AuthorizationFlowProps {
  flow: AuthorizationFlowNode;
  children?: React.ReactNode;
  displayText: React.ReactNode;
  showSubText?: boolean;
  environmentId?: string;
  onComplete: () => void;
}

export interface AuthorizationFlowFormProps {
  flow: AuthorizationFlowNode;
  flowState: any;
  step: AuthorizationFlowStepNode;
  displayText: React.ReactNode;
  showSubText: boolean;
  environmentId?: string;
  onComplete: () => void;
}

const PaddedContainer = styled.div`
  padding: ${props => props.theme.spacerxl};
`;

export const Panel = styled.div`
  padding: ${props => props.theme.spacerxl};
  margin: ${props => props.theme.spacerxl};
  border-radius: ${props => props.theme.borderRadiuslg};
  background-color: white;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-shadow: ${props => props.theme.boxShadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Heading = styled.div`
  margin-top: ${props => props.theme.spacerxl};
  margin-bottom: ${SpacingUnit.lg};
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: ${props => props.theme.spacermd};
  }

  p {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: ${props => props.theme.defaultFontSize};
    line-height: 24px;
  }
`;

const AuthorizationForm = ({
  flow,
  step,
  flowState,
  displayText,
  showSubText,
  environmentId,
  onComplete
}: AuthorizationFlowFormProps) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    flow: flow,
    step: step,
    flowState: flowState,
    errorMessage: "",
    environmentId: environmentId
  });

  const handleResult = React.useCallback(
    (result: AuthFlowProcessStepMutationResult) => {
      // All results return the latest flow step and flow state
      switch (result.authFlowProcessStep.__typename) {
        case "AuthFlowProcessStepSuccessResult":
          dispatch({ type: "SET_STEP_PROCESSED", payload: {} });
          onComplete();
          break;
        case "AuthFlowProcessStepErrorResult":
          dispatch({
            type: "SET_STEP_PROCESSED",
            payload: {
              message: result.authFlowProcessStep.message
            }
          });
          break;
        case "AuthFlowProcessStepRedirectResult":
          window.location.assign(result.authFlowProcessStep.location);
          break;
        case "AuthFlowProcessStepActionRequiredResult":
          dispatch({
            type: "SET_FLOW_STEP",
            payload: {
              step: result.authFlowProcessStep.step,
              state: result.authFlowProcessStep.state
            }
          });
          break;
        default:
          reportException(new Error("Unexpected AuthFlowProcessStepResult"), {
            extra: { result: result }
          });
          dispatch({
            type: "SET_STEP_PROCESSED",
            payload: {
              message: "An unexpected error occurred. Please try again."
            }
          });
      }
    },
    [dispatch, onComplete]
  );

  const [processStepMutation] = useMutation<
    AuthFlowProcessStepMutationResult,
    AuthFlowProcessStepMutationVars
  >(AUTH_FLOW_PROCESS_STEP, {
    onCompleted: handleResult,
    onError: () =>
      dispatch({
        type: "SET_STEP_PROCESSED",
        payload: { message: "An unexpected error occured. Please try again." }
      })
  });

  const processStep = React.useCallback(
    (inputState: any) => {
      dispatch({ type: "SET_STEP_PROCESSING" });
      return processStepMutation({
        variables: {
          authFlowId: state.flow?.id,
          state: { ...state.flowState, ...inputState },
          step: state.step?.order || 0,
          returnUri: window.location.href,
          environmentId: state.environmentId
        }
      });
    },
    [processStepMutation, state]
  );

  let stepNode: React.ReactNode = null;
  const stepProps = { state: state, processStep: processStep };
  switch (state.step?.type) {
    case AuthorizationFlowStepType.INPUT:
      stepNode = <InputStep {...stepProps} showSubText={showSubText} />;
      break;
    default:
      stepNode = <InitiateStep {...stepProps} showSubText={showSubText} />;
  }

  return (
    <>
      <Heading>{displayText}</Heading>
      {state.errorMessage && (
        <Alert type="error" message={state.errorMessage} showIcon />
      )}
      {stepNode};
    </>
  );
};

export function AuthorizationFlow({
  flow,
  children,
  displayText,
  showSubText,
  environmentId,
  onComplete
}: AuthorizationFlowProps) {
  const authSteps = flow.authSteps?.edges?.map(e => e.node) || [];
  const nextStep = authSteps.shift();
  if (!nextStep) {
    return <>{children}</>;
  }

  return (
    <AuthorizationForm
      flow={flow}
      step={nextStep}
      flowState={{}}
      displayText={displayText}
      showSubText={showSubText || false}
      environmentId={environmentId}
      onComplete={onComplete}
    />
  );
}

/**
 * AuthorizationFlowWrapper is used to preempt loading of a space until
 * all required authorization flows have been completed by the user.
 */
export function AuthorizationFlowWrapper({ children }: AuthorizationFlowWrapperProps) {
  const { spaceSlug, editMode } = useStableSpaceContext();
  const { data, loading, refetch } = useQuery<
    AuthFlowsForSpaceResult,
    AuthFlowsForSpaceVars
  >(AUTH_FLOWS_FOR_SPACE_QUERY, {
    variables: { slug: spaceSlug },
    // fetchPolicy: "cache-and-network",
    skip: editMode
  });

  if (loading) {
    return (
      <PaddedContainer>
        <Skeleton active />
      </PaddedContainer>
    );
  }

  const unauthorizedFlows =
    data?.space?.authorizationFlows?.filter(af => !af.authorized) || [];
  const hasAllAuthorizationFlows = unauthorizedFlows.length === 0;
  // TODO: ensure flow matches current flow being executed
  const nextFlow = unauthorizedFlows.shift();
  if (!nextFlow || hasAllAuthorizationFlows) {
    return <>{children}</>;
  }

  const displayText = (
    <>
      <h2>You need to be authorized to access this space.</h2>
      <p>Your company protects data in this space with an extra authorization step.</p>
      <p>Login below to get authorized.</p>
    </>
  );
  return (
    <Panel>
      <AuthorizationFlow
        flow={nextFlow}
        children={children}
        displayText={displayText}
        showSubText={true}
        onComplete={refetch}
      />
    </Panel>
  );
}
