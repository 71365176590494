import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CommentsContainer = styled.div`
  margin-top: 16px;
  flex-grow: 1;
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LoadMoreButton = styled(Button)`
  align-self: center;
  margin-bottom: 15px;
`;
