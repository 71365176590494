import { TaskFilters } from "../../..";
import { assertNever } from "../../../../../util/assertNever";
import { toGlobalId } from "../../../../../util/graphql";
import { TaskNode } from "../queries/AllTasks";

import { ReducerAction, ReducerActions } from "./actions";

export type PagingDirection = "backward" | "forward" | "none";

function getPagingDirection(filters?: Partial<TaskFilters>): PagingDirection {
  if (!filters) {
    return "none";
  }

  return !!filters.before ? "backward" : !!filters.after ? "forward" : "none";
}

// The "normal" value is how many tasks there are total before the current view.
// This is what you would expect paging forward.
// Paging backward, the beforeTaskCount is reversed, so we need to "reverse" it again to fit.
function normalizeBeforeTaskCount(
  taskCount: number,
  beforeTaskCount: number,
  totalCount: number,
  direction: PagingDirection
) {
  if (beforeTaskCount === totalCount) {
    return 0;
  }

  if (direction === "forward") {
    return beforeTaskCount;
  }

  return totalCount - beforeTaskCount - taskCount;
}

export function mapTasks(tasks?: TaskNode[]): Map<string, TaskNode> {
  return new Map<string, TaskNode>((tasks || []).map(t => [t.id, t]));
}

export interface State {
  tasks?: TaskNode[];
  taskMap: Map<string, TaskNode>;
  filters?: Partial<TaskFilters>;
  stopPolling: boolean;
  allPollingCursor: string;
  taskChangeCount: number;
  restartingPoller: boolean;
  createTaskCheckId?: string;
  updateTaskCheckId?: string;
  transitionTaskCheckId?: string;
  totalTaskCount?: number;
  beforeTaskCount?: number;
}

export function getDefaultState(): State {
  return {
    tasks: undefined,
    filters: undefined,
    allPollingCursor: "",
    taskMap: new Map<string, TaskNode>(),
    taskChangeCount: 0,
    restartingPoller: false,
    createTaskCheckId: undefined,
    updateTaskCheckId: undefined,
    transitionTaskCheckId: undefined,
    stopPolling: false,
    totalTaskCount: undefined,
    beforeTaskCount: undefined
  };
}

export function queueClientReducer(state: State, action: ReducerAction): State {
  switch (action.type) {
    case ReducerActions.CHANGE_TASK_QUERY: {
      return {
        ...getDefaultState(),
        filters: action.payload.filters
      };
    }
    case ReducerActions.LOAD_TASK_QUERY: {
      const tasks = action.payload.connection.edges.map(e => e.node);
      const beforeCount = action.payload.connection.beforeCount;
      const totalCount = action.payload.connection.totalCount;

      const normalizedBeforeTaskCount = normalizeBeforeTaskCount(
        tasks.length,
        beforeCount || 0,
        totalCount || 0,
        getPagingDirection(state.filters)
      );

      return {
        ...state,
        tasks: tasks,
        taskMap: mapTasks(tasks),
        allPollingCursor: action.payload.connection.pollingCursor,
        taskChangeCount: 0,
        totalTaskCount: totalCount,
        beforeTaskCount: normalizedBeforeTaskCount,
        restartingPoller: true,
        createTaskCheckId: undefined,
        updateTaskCheckId: undefined,
        transitionTaskCheckId: undefined
      };
    }
    case ReducerActions.POLL_ALL: {
      const { connection, startCursor } = action.payload;

      if (startCursor === state.allPollingCursor) {
        return state;
      }

      const states = state.filters?.stateIdIn || [];
      const changedTasks = connection.edges.map(e => e.node);

      const creatingId = toGlobalId("TaskNode", state.createTaskCheckId || "");

      const changes = changedTasks.reduce((prev, current) => {
        if (current.id === creatingId) {
          return 0;
        }

        const exists = state.taskMap.has(current.id);
        const sameState =
          state.filters?.stateIdIn === undefined || states.includes(current.state.id);

        // It's a change if: exists and state change
        // or, something new is in the state
        return prev + (exists !== sameState ? 1 : 0);
      }, 0);

      return {
        ...state,
        allPollingCursor: action.payload.startCursor,
        taskChangeCount: state.taskChangeCount + changes
      };
    }
    case ReducerActions.STOP_POLL: {
      return {
        ...state,
        stopPolling: true,
        restartingPoller: false
      };
    }
    case ReducerActions.RESTART_POLLER: {
      return {
        ...state,
        restartingPoller: false,
        stopPolling: false
      };
    }
    case ReducerActions.CREATE_TASK_CHECK: {
      return {
        ...state,
        createTaskCheckId: action.payload.taskId
      };
    }
    case ReducerActions.CREATE_TASK_CHECK_COMPLETE: {
      if (!state.tasks || action.payload.connection.edges.length !== 1) {
        return state;
      }

      const node = action.payload.connection.edges[0].node;
      const newTasks = [node].concat(state.tasks);

      return {
        ...state,
        tasks: newTasks,
        taskMap: mapTasks(newTasks),
        totalTaskCount: (state.totalTaskCount || 0) + 1
      };
    }
    case ReducerActions.UPDATE_TASK_CHECK: {
      return {
        ...state,
        updateTaskCheckId: action.payload.taskId
      };
    }
    case ReducerActions.UPDATE_TASK_CHECK_COMPLETE: {
      if (!state.tasks) {
        return state;
      }

      if (action.payload.connection.edges.length === 1) {
        const newTasks = state.tasks.map(t => {
          if (t.id === state.updateTaskCheckId) {
            return action.payload.connection.edges[0].node;
          }

          return t;
        });

        return {
          ...state,
          tasks: newTasks,
          taskMap: mapTasks(newTasks)
        };
      }

      const newTasks = state.tasks.filter(t => t.id !== state.updateTaskCheckId);
      return {
        ...state,
        tasks: newTasks,
        taskMap: mapTasks(newTasks),
        totalTaskCount: (state.totalTaskCount || 1) - 1
      };
    }
    case ReducerActions.TRANSITION_TASK_CHECK: {
      return {
        ...state,
        transitionTaskCheckId: action.payload.taskId
      };
    }
    case ReducerActions.TRANSITION_TASK_CHECK_COMPLETE: {
      if (!state.tasks) {
        return state;
      }

      if (action.payload.connection.edges.length === 1) {
        const newTasks = state.tasks.map(t => {
          if (t.id === state.transitionTaskCheckId) {
            return action.payload.connection.edges[0].node;
          }

          return t;
        });

        return {
          ...state,
          tasks: newTasks,
          taskMap: mapTasks(newTasks)
        };
      }

      const newTasks = state.tasks.filter(t => t.id !== state.transitionTaskCheckId);

      return {
        ...state,
        tasks: newTasks,
        taskMap: mapTasks(newTasks),
        totalTaskCount: (state.totalTaskCount || 1) - 1
      };
    }
    case ReducerActions.DELETE_TASK: {
      const newTasks = state.tasks?.filter(t => t.id !== action.payload.taskId);
      return {
        ...state,
        tasks: newTasks,
        taskMap: mapTasks(newTasks),
        totalTaskCount: (state.totalTaskCount || 1) - 1
      };
    }
    default:
      return assertNever(action);
  }
}
