import React, { useCallback, useState } from "react";

import { Form } from "antd";
import * as AntForm from "antd/lib/form/Form";
import styled from "styled-components";

import { SpaceFunctionType } from "../../../../../types";
import ClickEventCapturer from "../../../../common/ClickEventCapturer";
import { ErrorIcon } from "../../../../common/Icons";
import { WarningTooltip } from "../../../../common/ToolTips";
import { processEffects as processQueueEffects } from "../../../../queues/App/Queue/utils";
import {
  createSpaceFunction,
  FunctionExecutionStatus
} from "../../../FunctionExecutor/FunctionExecutor";
import { useComponentLayoutContext } from "../../../layout/LayoutContext/LayoutContext";
import { useStableSpaceContext } from "../../SpaceContext";
import Button from "../common/Button";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import { SubmittableComponentProps } from "../common/FunctionBackedPopover/reducer/reducer";
import useFuncParams from "../common/useFuncParams";
import useSpaceFunction from "../common/useSpaceFunction";
import { FunctionResult } from "../common/useSpaceFunction/useSpaceFunction";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props as BaseProps } from "../SpaceComponent";

import FunctionModalForm from "./FunctionModalForm";

const Root = styled.div`
  button > div > .anticon + span,
  button > div > span + .anticon {
    margin-left: ${props => props.theme.spacersm};
  }
`;

const Container = styled.div`
  width: 100%;
  text-align: left;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
`;

const ConfigContainer = styled.div`
  position: relative;
  cursor: default;

  button:disabled {
    color: white;
    background-color: ${props => props.theme.primaryColor};
  }
`;

const ConfigEventMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

type Props = BaseProps & { form: AntForm.WrappedFormUtils<any> };

export function SpaceFunctionModalFormComponent({
  spaceApi,
  spaceComponent,
  form,
  hasConfigError
}: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { properties } = spaceComponent;
  const { button_text = "Open", input_parameters = [] } =
    properties as SubmittableComponentProps;

  const spaceFunction = React.useMemo(
    () => createSpaceFunction(spaceComponent),
    [spaceComponent]
  );

  const { spaceId, editMode } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();

  const {
    prefillParams,
    funcParams,
    hasRequiredBindings,
    hasRequiredValues,
    hasValidValues,
    hasRequiredComponentValues,
    getCurrentFuncParams
  } = useFuncParams(spaceFunction, input_parameters, input, form, spaceComponent);

  const { triggerEffects } = useSubmittableEffects(spaceApi);

  const onCompleted = useCallback(
    (result: FunctionResult, metadata: unknown) => {
      updateOutput({ lastExecutionResult: result });
      setShowModal(false);
      triggerEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
      processQueueEffects(metadata);
    },
    [updateOutput, triggerEffects]
  );

  const onError = useCallback(
    (error: Error) => {
      triggerEffects({ type: SubmittableEventType.SUBMIT_FAILURE, error });
    },
    [triggerEffects]
  );

  const { executeFunction, status } = useSpaceFunction(
    spaceFunction,
    input_parameters,
    spaceId,
    onCompleted,
    onError
  );

  const onSubmit = React.useCallback(() => {
    executeFunction(getCurrentFuncParams());
  }, [executeFunction, getCurrentFuncParams]);

  const trySetShowModal = React.useCallback(
    (visible: boolean) => {
      if (!visible && status === FunctionExecutionStatus.IN_PROGRESS) return;
      setShowModal(visible);
    },
    [status, setShowModal]
  );

  const { layout } = useComponentLayoutContext();

  if (editMode) {
    return (
      <Root className="spaceButtonRoot" data-test="space-modal-form">
        <ConfigContainer>
          {!!hasConfigError && <StyledErrorIcon />}
          <Button type="primary" layout={layout} disabled>
            {button_text}
          </Button>
          <ConfigEventMask />
        </ConfigContainer>
      </Root>
    );
  }

  if (
    !spaceFunction ||
    [SpaceFunctionType.INVALID, SpaceFunctionType.VOID].includes(spaceFunction.type)
  ) {
    return (
      <WarningTooltip
        message="This pop-up form needs to be updated"
        description="The function associated with this pop-up form cannot be found or is invalid. It's
    possible that the name of the function was changed or that the
    function was removed from your system."
        placement="left"
      >
        <span>
          <StyledErrorIcon />
          <Button type="primary" layout={layout} disabled>
            {button_text}
          </Button>
        </span>
      </WarningTooltip>
    );
  }

  return (
    <Root className="spaceButtonRoot" data-test="space-modal-form">
      <Container>
        <Button
          type="primary"
          disabled={!hasRequiredBindings}
          fixedWidthLoadingState
          layout={layout}
          onClick={() => trySetShowModal(true)}
        >
          {button_text}
        </Button>
      </Container>
      {showModal && (
        <ClickEventCapturer>
          <FunctionModalForm
            form={form}
            func={spaceFunction}
            prefillParams={prefillParams}
            funcParams={funcParams}
            spaceApi={spaceApi}
            spaceComponent={spaceComponent}
            inputParameters={input_parameters}
            disabled={!hasRequiredValues || !hasValidValues}
            loading={status === FunctionExecutionStatus.IN_PROGRESS}
            functionExecutionStatus={status}
            isValid={hasRequiredComponentValues}
            onClose={() => trySetShowModal(false)}
            onSubmit={onSubmit}
          />
        </ClickEventCapturer>
      )}
    </Root>
  );
}

export default Form.create<Props>({
  name: "space-function-modal-form"
})(SpaceFunctionModalFormComponent);
