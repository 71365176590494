import { Input as AntInput } from "antd";
import styled from "styled-components";

import { ErrorIcon } from "../../../../../common/Icons";
import BaseInsertTarget from "../../common/InsertTarget";

export const EditRoot = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 100%;
  pointer-events: all;

  ${({ theme }) =>
    `
    background: ${theme.textColorMidOnDark};
    border: dashed 1px ${theme.borderGrey};
    padding: ${theme.spacermd};
    padding-left: 0;
  `}
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  top: ${props => props.theme.spacersm};
  /* odd pixel amount necessary to roughly align with <Panel /> erroricon */
  left: 6px;
`;

export const LeftHeader = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0; // Flex child min-width defaults to auto which is too wide with a nowrap child
  align-items: center;

  & > *:not(:first-child) {
    margin-left: ${props => props.theme.spacermd};
  }
`;
LeftHeader.displayName = "LeftHeader";

export const Filters = styled.div`
  display: flex;
  flex: 1 0.5 auto;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: ${props => props.theme.spacersm};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex: 1 0.5 auto;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: ${props => props.theme.spacerxs};
  }
`;
Actions.displayName = "Actions";

export const InsertTarget = styled(BaseInsertTarget)`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  border: 0;
  transform: scale(0.65);
`;

export const MouseDownCatcher = styled.div`
  display: inline-block;
`;

export const Input = styled(AntInput)`
  font-size: 33px;
  font-family: var(--header-font-family);
  font-weight: 500;
  height: 49px;
  min-width: 5em;
  color: ${props => props.theme.textColor};
  padding: ${props => props.theme.spacersm};
  border: none;
  padding-left: 0;
`;
