import React from "react";

import moment from "moment";

import { ModalNew } from "../../common/Modal";
import { planName } from "../../util/billing";

interface DowngradeModalProps {
  showDowngradeModal: boolean;
  name: string;
  onOk: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel: () => void;
  currentPlanSlug?: string;
  currentPeriodEnd: number;
}

export const DowngradeModal = ({
  showDowngradeModal,
  name,
  onOk,
  onCancel,
  currentPlanSlug,
  currentPeriodEnd
}: DowngradeModalProps) => {
  return (
    <ModalNew
      title={`Downgrade to ${name}`}
      visible={showDowngradeModal}
      onCancel={onCancel}
      okText={`Downgrade to ${name}`}
      buttonType="brand"
      onOk={onOk}
      width={540}
    >
      <p>
        By downgrading to {name}, your current subscription will automatically end on{" "}
        <strong>{moment.unix(currentPeriodEnd).utc().format("MMMM Do, YYYY")}</strong>.
        You'll still have access to all the premium features of the{" "}
        {planName(currentPlanSlug)} plan until then.
      </p>

      <p>
        Switching to the {name} plan means you’ll lose access to some premium features.
        Please be sure to remove or disable these premium features by{" "}
        {moment.unix(currentPeriodEnd).utc().format("MMMM Do, YYYY")} or your account
        may be locked.
      </p>
    </ModalNew>
  );
};
