import React, { cloneElement, Dispatch, SetStateAction, useState } from "react";

import classNames from "classnames";
import _ from "lodash";

import { AttributeTypes } from "../../../constants";

import { AttributeValueInputProps } from "./AttributeInput";
import { BooleanSelect } from "./BooleanInput";

interface FormAdapter {
  rules: any[];
  validationErrors: string[];
  setValidationErrors: Dispatch<SetStateAction<string[]>>;
  validate: (v: any) => void;
  getFieldDecorator: (name: any, options: any) => any;
  getFieldValue: () => any;
  setFieldsValue: (v: any) => void;

  /* no-ops below */
  resetFields: () => void;
  getFieldsValue: () => { [field: string]: any };
  validateFields: () => void;
  validateFieldsAndScroll: () => void;
  setFields: () => void;
  getFieldError: () => string[] | undefined;
  getFieldsError: () => Record<string, string[] | undefined>;
  isFieldValidating: () => boolean;
  isFieldTouched: () => boolean;
  isFieldsTouched: () => boolean;
}

/*
  formAdapter covers some of the api of the ant form prop.
  The ant form system has state so this adapater unfortunately needs to maintain some
  as well.
  - Runs ant validation rules and displaying feedback when invalid
  - Sets value and onChange directly on an inputs
*/
function makeFormAdapter(props: AttributeValueInputProps): FormAdapter {
  const adapter: FormAdapter = {
    rules: [],

    validationErrors: [],

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setValidationErrors: (errors: SetStateAction<string[]>) => {},

    validate: (nextVal: any) => {
      let nextErrors: string[] = [];
      adapter.rules.forEach((rule: any) => {
        if (rule.required) {
          if (props.sourceNullable === false && !nextVal) {
            nextErrors = nextErrors.concat(rule.message);
          }
        } else if (rule.validator) {
          rule.validator(rule, nextVal, (failed: any) => {
            if (failed) {
              nextErrors = nextErrors.concat(rule.message);
            }
          });
        }
      });
      if (!_.isEqual(nextErrors, adapter.validationErrors)) {
        props.onValidate && props.onValidate(nextErrors.length === 0);
        adapter.setValidationErrors(nextErrors);
      }
    },

    getFieldDecorator: (fieldName: any, options: any) => (input: any) => {
      const [validationErrors, setValidationErrors] = useState<string[]>([]);

      // getFieldDecorator runs on every render so using state hook here and making
      // current values available on adapter for other methods
      adapter.rules = options.rules;
      adapter.validationErrors = validationErrors;
      adapter.setValidationErrors = setValidationErrors;

      const handleChange = (evt: any) => {
        // Most ant inputs return an event object but some just return the val
        const nextVal = _.get(evt, "target.value", evt);
        props.onChange && props.onChange(nextVal);
        adapter.validate(nextVal);
      };

      // HACK - coerce values into what their inputs expect
      // TODO once prepare value is factored out in https://www.pivotaltracker.com/story/show/169195490
      // this section should be removable, except for possibly the last condition.
      let preparedValue = props.value;
      if (
        props.sourceType === AttributeTypes.BOOL &&
        (props.sourceNullable === true || props.verbose) &&
        !Object.values(BooleanSelect).includes(preparedValue)
      ) {
        const nonBooleanOption = props.sourceNullable ? BooleanSelect.NULL : undefined;
        preparedValue =
          props.value === true
            ? BooleanSelect.TRUE
            : props.value === false
            ? BooleanSelect.FALSE
            : nonBooleanOption;
      } else if (
        props.sourceType === AttributeTypes.BOOL &&
        props.sourceNullable === false &&
        preparedValue === null
      ) {
        preparedValue = false;
      } else if (
        [AttributeTypes.DATETIME, AttributeTypes.TIMESTAMP].includes(
          props.sourceType
        ) &&
        options.initialValue &&
        preparedValue !== options.initialValue
      ) {
        preparedValue = options.initialValue;
      }

      return (
        <div
          className={classNames({
            "has-error": validationErrors.length,
            [props.className || ""]: !!props.className
          })}
        >
          {cloneElement(input, {
            value: preparedValue,
            onChange: handleChange
          })}
          {validationErrors.map(error => (
            <span key={error} className="ant-form-explain">
              {error}
            </span>
          ))}
        </div>
      );
    },

    getFieldValue: () => {
      return props.value;
    },

    setFieldsValue: (value: any) => {
      // At this point used by date and json inputs. Api is { [key]: value }. Here we just need value
      const nextVal = Object.entries(value)[0][1];
      props.onChange && props.onChange(nextVal);
      adapter.validate(nextVal);
    },

    resetFields: () => {
      // no-op
    },

    getFieldsValue: () => {
      // no-op
      return [];
    },

    validateFields: () => {
      // no-op
    },

    validateFieldsAndScroll: () => {
      // no-op
    },

    setFields: () => {
      // no-op
    },

    getFieldError: () => {
      // no-op
      return undefined;
    },

    getFieldsError: () => {
      // no-op
      return {};
    },

    isFieldValidating: () => {
      // no-op
      return false;
    },

    isFieldTouched: () => {
      // no-op
      return false;
    },

    isFieldsTouched: () => {
      // no-op
      return false;
    }
  };
  return adapter;
}

export default makeFormAdapter;
