import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import { Spin } from "antd";
import gql from "graphql-tag";
import styled from "styled-components";

import { Connection } from "../../../../types";
import Avatar, { userToInitials } from "../../../common/Avatar/Avatar";
import ExpandoList from "../../../common/ExpandoList/ExpandoList";
import { H6 } from "../../../common/StyledComponents";
import { useColorConverter } from "../../../common/useColorCoverter/useColorCoverter";
import { fromGlobalId } from "../../../util/graphql";

const AdminInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

function AdminListItem({ user }: { user: UserNode }) {
  const { id, firstName, lastName } = user;
  const uuid = React.useMemo(() => {
    return fromGlobalId(id)[1];
  }, [id]);

  const color = useColorConverter(uuid);

  return (
    <AdminInfo>
      <Avatar color={color}>{userToInitials(user)}</Avatar>
      <span>
        {firstName} {lastName}
      </span>
    </AdminInfo>
  );
}

export default function AdminList() {
  const { data, loading } = useAdminUsers();
  return (
    <div data-test="adminList">
      <H6>Admins</H6>
      {loading ? (
        <Spin spinning />
      ) : (
        <ExpandoList>
          {data?.allUsers.edges.map(({ node }) => (
            <AdminListItem key={node.id} user={node} />
          ))}
        </ExpandoList>
      )}
    </div>
  );
}

interface UserNode {
  id: string;
  firstName: string;
  lastName: string;
}

interface Data {
  allUsers: Connection<UserNode>;
}

const FETCH_ADMIN_USERS = gql`
  query AdminUsers {
    allUsers(isActive: true, isAdmin: true) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const useAdminUsers = (options?: QueryHookOptions<Data>) =>
  useQuery<Data>(FETCH_ADMIN_USERS, options);
