import React from "react";

import { Checkbox } from "antd";
import { Column, UseTableHooks, UseRowSelectInstanceProps, Cell } from "react-table";

export default function useSelectMultiple(hooks: UseTableHooks<object>) {
  hooks.visibleColumnsDeps.push((deps, { instance }) => {
    return [...deps, (instance as any).state.allowSelectMultiple];
  });
  hooks.visibleColumns.push((columns: Column[], { instance }) => {
    if ((instance as any).state.allowSelectMultiple) {
      // Let's make a column for selection
      const selectColumn = {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({
          getToggleAllRowsSelectedProps
        }: UseRowSelectInstanceProps<object>) => (
          <div>
            <Checkbox {...(getToggleAllRowsSelectedProps() as any)} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: Cell) => (
          <div>
            <Checkbox {...(row.getToggleRowSelectedProps() as any)} />
          </div>
        )
      };
      return [selectColumn, ...columns];
    }
    return [...columns];
  });
}
