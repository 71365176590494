import { parse } from "acorn";
import _ from "lodash";

import { tryError } from "./index";

export class ParseError extends Error {
  private readonly _column: number;
  private readonly _line: number;

  constructor(message: string, line: number, column: number) {
    super(message);
    this._line = line;
    this._column = column;

    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ParseError.prototype);
  }

  get column(): number {
    return this._column;
  }

  get line(): number {
    return this._line;
  }
}

export const getFormattedJavaScriptError = (error: Error): ParseError | Error => {
  const POSITION_REGEX = /\((\d+):(\d+)\)$/;
  const message = error.message;
  const match = message.match(POSITION_REGEX);
  if (!match || match.length !== 3) return error;
  const newPosition = `(line ${match[1]}, col ${match[2]})`;
  return new ParseError(
    `${message.substring(0, match["index"])}${newPosition}`,
    Number(match[1]),
    Number(match[2])
  );
};

export const isParseError = (error: Error | ParseError | null): error is ParseError =>
  !!error && error instanceof ParseError;

const CURLY_REGEX = /^\s*{/;

export const getJavaScriptParseError = (input: string): ParseError | Error | null => {
  const src = input.match(CURLY_REGEX) ? `(${input})` : input;
  try {
    parse(src);
  } catch (e) {
    return getFormattedJavaScriptError(tryError(e));
  }
  return null;
};

export const unquasi = (text: string) => {
  const t = _.trim(text);
  return t.length >= 2 && t[0] === "`" && t[t.length - 1] === "`"
    ? t.substring(1, t.length - 1)
    : t;
};

export const quasi = (text: string) => {
  if (text.length === 0) return "``";
  else if (text.length === 1) return text[0] === "`" ? "``" : "`" + text + "`";
  else if (text[0] === "`" && text[text.length - 1] === "`") return text;
  else return "`" + text + "`";
};
