import React from "react";

import styled from "styled-components";

import WarningIcon from "../../../../../common/WarningIcon";

const StyledWarningIcon = styled(WarningIcon)`
  margin-left: ${props => props.theme.spacerxs};
`;

const PermissionWarningIcon = () => {
  return (
    <StyledWarningIcon
      tooltip={`This value could not be retrieved because you do not 
        have permission to read it.`}
    />
  );
};

export default PermissionWarningIcon;
