import React from "react";

import { FunctionScope } from "../../../../../../types";
import FunctionPicker from "../../../../../common/FunctionPicker";
import usePaths from "../../../../../common/hooks/usePaths";
import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentConfigContext } from "../ComponentConfigContext";
import ValidationError from "../ComponentConfigContext/ValidationError";
import { Action } from "../useViewConfig/reducer";

export default function ViewPicker() {
  const { state, dispatch } = useComponentConfigContext();
  const { spaceSlug } = useStableSpaceContext();
  const { openSpaceFunctionConfig } = usePaths();
  const { view } = state.draftComponent;

  return (
    <>
      <FunctionPicker
        functionId={(view && view.function?.id) || null}
        functionScope={FunctionScope.View}
        onChange={functionId => {
          dispatch({
            type: Action.CHANGE_FUNCTION,
            payload: { functionId }
          });
        }}
        onEditFunctionClick={e => openSpaceFunctionConfig(spaceSlug, e)}
      />
      <ValidationError field="FUNCTION" />
    </>
  );
}
