import React, { useState } from "react";

import { useQuery } from "react-apollo";
import styled from "styled-components";

import { GET_SUBSCRIPTION_DATA } from "../../../graphql/queries";
import useAuthUser from "../../common/hooks/useAuthUser";
import RadioSwitch from "../../common/RadioSwitch";
import { B2, D2 } from "../../common/StyledComponents";

import PricingProductDetail from "./PricingProductDetail";

const PricingGridContainer = styled.div`
  padding-right: 30px;
  display: flex;
  justify-content: center;
`;

const PricingContent = styled.div`
  padding-right: 30px;
  max-width: 1400px;
  text-align: left;
`;

const TermsTitle = styled.p`
  color: ${props => props.theme.primaryAccent}
  margin-top: 40px;
  display: inline-block;
  padding-right: 20px;
`;

const PlanRow = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const PricingProductColumn = styled.div`
  flex: 1;
`;

const PricingRadioSwitch = styled(RadioSwitch)`
  display: inline-block;
  text-align: left;
`;

const PricingB2 = styled(B2)`
  display: block;
`;

const HeaderContent = styled.div`
  padding-left: 10px;
`;

const PricingGrid = () => {
  const { authUser } = useAuthUser();
  const currentPlan = authUser?.organization.currentPlan;
  const [billingTerm, setBillingTerm] = useState("annual");

  const handleBillingTermToggle = (val: string) => {
    setBillingTerm(val);
  };

  const { data: subscriptionData } = useQuery(GET_SUBSCRIPTION_DATA, {
    fetchPolicy: "cache-and-network"
  });

  const currentPeriodEnd =
    subscriptionData?.stripeSubscription.subscription?.current_period_end;

  return (
    <PricingGridContainer>
      <PricingContent>
        <HeaderContent>
          <D2>Upgrade your plan.</D2>
          <PricingB2>Build unlimited custom internal tools for your team.</PricingB2>

          <TermsTitle>Showing pricing for:</TermsTitle>

          <PricingRadioSwitch
            name="subscriptionTerm"
            onChange={handleBillingTermToggle}
            options={{ annual: "Annual", monthly: "Monthly" }}
          />
        </HeaderContent>

        <PlanRow>
          <PricingProductColumn>
            <PricingProductDetail
              name="Basic"
              productSlug="basic"
              description="Getting started"
              monthlyPrice="Free"
              annualPrice="Free"
              billingTerm={billingTerm}
              billingUnit="forever"
              includesDescription="Basic includes:"
              currentPlan={currentPlan}
              tier={0}
              currentPeriodEnd={currentPeriodEnd}
            >
              <li>Admin mode</li>
              <li>3 data sources</li>
              <li>Unlimited apps with Spaces</li>
              <li>Out of the box components</li>
              <li>Function editor</li>
              <li>Unlimited workflows with Queues</li>
              <li>1,000 tasks</li>
              <li>Email support</li>
            </PricingProductDetail>
          </PricingProductColumn>
          <PricingProductColumn>
            <PricingProductDetail
              name="Teams"
              description="Best for small teams"
              monthlyPrice="$9"
              annualPrice="$5"
              billingTerm={billingTerm}
              billingUnit="user"
              productSlug="teams"
              includesDescription="Includes Basic features, plus:"
              mostPopular={true}
              currentPlan={currentPlan}
              tier={1}
              currentPeriodEnd={currentPeriodEnd}
            >
              <li>End user mode</li>
              <li>2 additional data sources (5 total)</li>
              <li>Additional 9,000 tasks (10,000 total)</li>
              <li>Environments</li>
              <li>Version history</li>
              <li>Revert version</li>
            </PricingProductDetail>
          </PricingProductColumn>
          <PricingProductColumn>
            <PricingProductDetail
              name="Business"
              productSlug="business"
              description="Best for mid-sized teams"
              monthlyPrice="$30"
              annualPrice="$19"
              billingTerm={billingTerm}
              billingUnit="user"
              includesDescription="Includes Teams features, plus:"
              currentPlan={currentPlan}
              tier={2}
              currentPeriodEnd={currentPeriodEnd}
            >
              <li>5 roles</li>
              <li>Additional 5 data sources (10 total)</li>
              <li>Additional 40,000 tasks (50,000 total)</li>
              <li>Audit logs</li>
              <li>Granular Permissions</li>
              <li>Enforce two factor authentication</li>
            </PricingProductDetail>
          </PricingProductColumn>
          <PricingProductColumn>
            <PricingProductDetail
              name="Enterprise"
              productSlug="enterprise"
              description="Tailored to your business"
              billingTerm={billingTerm}
              includesDescription="Includes Business features, plus:"
              currentPlan={currentPlan}
              tier={5}
              currentPeriodEnd={currentPeriodEnd}
            >
              <li>Unlimited roles</li>
              <li>Unlimited data sources</li>
              <li>Unlimited tasks</li>
              <li>Google SSO, Okta, Active Directory, and SAML 2.0</li>
              <li>Self-hosted option</li>
              <li>Premium support</li>
            </PricingProductDetail>
          </PricingProductColumn>
        </PlanRow>
      </PricingContent>
    </PricingGridContainer>
  );
};

export default PricingGrid;
