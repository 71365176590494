import { DataValue, ResourceNode } from "../../../../../../types";
import { FunctionNode } from "../../useRecordData/queries";

export const getRelatedRecordFunctionId = (
  func: FunctionNode,
  isOutbound: boolean
): string => {
  if (isOutbound) return func.id;

  const edge = func.outboundFunctionLinks.edges.find(e => {
    const categories = e.node.toFunction.metadata?.categories || [];
    if (!categories.includes("returns:one")) {
      return false;
    }

    const pkNames = func.functionAttributes.edges
      .filter(e => e.node.sourceKey)
      .map(e => e.node.sourceName);

    return (
      pkNames.length === Object.keys(e.node.parameterAttributeMapping).length &&
      pkNames.every(pk => e.node.parameterAttributeMapping[pk] === pk)
    );
  });

  if (!edge?.node.toFunction.id) {
    throw new Error("could not find related function");
  }

  return edge.node.toFunction.id;
};

export const getRecordTitle = (
  record: Record<string, DataValue>,
  dataAccessor: ResourceNode | FunctionNode | undefined
) => {
  if (!dataAccessor) return;

  let firstPk: string | undefined;
  if (dataAccessor.__typename === "ResourceNode") {
    firstPk = dataAccessor.attributes?.edges
      .map(edge => edge.node)
      .find(attr => attr.sourcePrimaryKey)?.sourceName;
  } else if (dataAccessor.__typename === "FunctionNode") {
    firstPk = dataAccessor.functionAttributes.edges
      .map(edge => edge.node)
      .find(attr => attr.sourceKey)?.sourceName;
  }
  return firstPk ? record[firstPk] : "Related record";
};
