import React from "react";

import { FunctionNode } from "../../../../types";
import { useFunctionsForIds } from "../queries";
import {
  createInitialState,
  functionPoliciesForSpaceReducer,
  FunctionPolicyActionTypes
} from "../reducer";
import {
  FunctionAttribute,
  FunctionParameter,
  FunctionPermission,
  Identifiable,
  RoleFunctionPermissions
} from "../util";

// Sort order is required first, then name alphabetical
export function parameterRequiredAlphaComparator(
  lhs: FunctionParameter,
  rhs: FunctionParameter
): number {
  if (lhs.required && !rhs.required) {
    return -1;
  }

  if (!lhs.required && rhs.required) {
    return 1;
  }

  return lhs.name.localeCompare(rhs.name);
}

function functionPermissionsInputFromNode(node: FunctionNode): FunctionPermission {
  let allowsAll = false;
  const attributes = new Map<string, FunctionAttribute>();
  const parameters = new Map<string, FunctionParameter>();
  let parametersPermitted = 0;
  let attributesPermitted = 0;

  let policy: RoleFunctionPermissions & Identifiable = {
    id: "",
    allowsAll: false,
    policyFunctionAttributes: { edges: [] },
    policyFunctionParameters: { edges: [] }
  };

  if (node.policies?.edges.length) {
    policy = node.policies.edges[0].node;
  }

  allowsAll = policy.allowsAll;

  // Set all of the potential attributes
  for (const { node: attribute } of node.functionAttributes?.edges || []) {
    attributes.set(attribute.id, {
      id: attribute.id,
      name: attribute.name,
      permitted: false
    });
  }

  // If it is in the policies, then it is permitted already
  for (const { node: attributePolicy } of policy.policyFunctionAttributes.edges) {
    const existingAttribute = attributes.get(attributePolicy.functionAttribute.id);
    if (existingAttribute) {
      existingAttribute.permitted = true;
      attributesPermitted++;
    }
  }

  // Set all of the potential parameters
  for (const { node: parameter } of node.functionParameters?.edges || []) {
    parameters.set(parameter.id, {
      id: parameter.id,
      name: parameter.name,
      permitted: false,
      required: parameter.required
    });
  }

  // If it is in the policies, then it is permitted already
  for (const { node: parameterPolicy } of policy.policyFunctionParameters.edges) {
    const existingParameter = parameters.get(parameterPolicy.functionParameter.id);
    if (existingParameter) {
      existingParameter.permitted = true;
      parametersPermitted++;
    }
  }

  return {
    functionId: node.id,
    name: node.title || "",
    dataSource: node.dataSource?.name || "",
    allowsAll: allowsAll,
    // Sort the parameters to required first, then alphabetical
    parameters: [...parameters.values()].sort(parameterRequiredAlphaComparator),
    parametersPermitted: parametersPermitted,
    // We don't sort attributes since their order is based on a pre-existing index
    attributes: [...attributes.values()],
    attributesPermitted: attributesPermitted
  };
}

export function useFunctionPolicies({
  roleId,
  functionIds
}: {
  roleId?: string;
  functionIds: string[];
}) {
  const [state, dispatch] = React.useReducer(
    functionPoliciesForSpaceReducer,
    createInitialState()
  );

  const [lastValidRoleId, setLastValidRoleId] = React.useState<string | undefined>();

  // This is a work-around for apollo which doesn't work nicely
  // when changing skip off and on
  React.useEffect(() => {
    if (roleId === undefined || roleId === lastValidRoleId) {
      return;
    }

    setLastValidRoleId(roleId);
  }, [roleId, lastValidRoleId, setLastValidRoleId]);

  const { data, loading, error, refetch } = useFunctionsForIds({
    fetchPolicy: "cache-and-network",
    variables: {
      ids: functionIds,
      roleId: lastValidRoleId!
    },
    skip: !lastValidRoleId || functionIds.length === 0
  });

  const pendingRoleId = roleId !== lastValidRoleId;

  React.useEffect(() => {
    // Don't display previous loaded data if we are loading new data
    const totalCount = pendingRoleId ? 0 : data?.nodes.length || 0;
    const functionNodes = pendingRoleId ? [] : data?.nodes || [];

    dispatch({
      type: FunctionPolicyActionTypes.LOAD_EXISTING_POLICIES,
      payload: {
        functions: functionNodes.map(func => functionPermissionsInputFromNode(func)),
        totalCount: totalCount
      }
    });
  }, [data, pendingRoleId]);

  return {
    state,
    loading,
    error,
    dispatch,
    refetch
  };
}
