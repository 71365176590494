import gql from "graphql-tag";

export const INPUT_PARAMETER_FRAGMENT = gql`
  fragment InputParameterFragment on InputParameter {
    name
    type
    componentProperties
    componentType
    template
    binding
    resolver
    value
  }
`;
