import { cloneDeep } from "lodash";

import {
  SpaceComponentObject,
  SpaceComponentPackage
} from "../../../../../../../types";
import { ElementLayout } from "../../../../../layout/util";
import { ComponentConfigState } from "../../../../../types";
import { INITIAL_STATE, TreeNode, SpaceConfigState } from "../reducer";

function flattenSpaceComponentTree(
  components: SpaceComponentObject[]
): Record<string, SpaceComponentObject> {
  return components.reduce((memo, c) => {
    Object.assign(
      memo,
      { [c.slug]: c },
      flattenSpaceComponentTree(c.componentTreeNodes)
    );
    return memo;
  }, {});
}

function extractTreeNodes(
  components: SpaceComponentObject[],
  container: null | TreeNode
): TreeNode[] {
  return components.map(c => {
    const node: TreeNode = {
      slug: c.slug,
      type: c.type,
      treeNodes: [],
      container
    };
    node.treeNodes = extractTreeNodes(c.componentTreeNodes, node);
    return node;
  });
}

export default function extractState(
  components: SpaceComponentObject[],
  packages: Map<string, SpaceComponentPackage>
): SpaceConfigState {
  const componentEntries = Object.entries(flattenSpaceComponentTree(components));
  return {
    ...cloneDeep(INITIAL_STATE),
    components: componentEntries.reduce<Record<string, ComponentConfigState>>(
      (acc, [k, v]) => {
        const _package = packages.get(v.type);
        // TODO: Unmaybe getInitialDraftState
        acc[k] =
          _package && _package.getInitialDraftState
            ? _package.getInitialDraftState!(v)
            : { draftComponent: v, type: v.type };
        return acc;
      },
      {}
    ),
    tree: {
      slug: "root",
      container: null,
      type: null,
      treeNodes: extractTreeNodes(components, null)
    },
    elementLayouts: componentEntries.reduce<Map<string, Partial<ElementLayout>>>(
      (acc, [k, v]) => {
        if (v.layout) {
          acc.set(k, v.layout);
        }
        return acc;
      },
      new Map()
    )
  };
}
