import {
  SpaceComponentState as _SpaceComponentState,
  SpaceStateInputs as _SpaceStateInputs,
  Props as _Props
} from "./SpaceComponent";

export { default } from "./SpaceComponent";

export type SpaceComponentState = _SpaceComponentState;
export type SpaceStateInputs = _SpaceStateInputs;
export type Props = _Props;
export { hasSource } from "./common/util";
