import React from "react";

import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import BaseAvatar, { userToInitials } from "../../components/common/Avatar/Avatar";
import { useEnvironmentContext } from "../../components/common/contexts/EnvironmentContext";
import EnvironmentSelect from "../../components/common/EnvironmentSelect";
import useAuthUser, {
  useAuthUserUuid
} from "../../components/common/hooks/useAuthUser/useAuthUser";
import TextLogo, { LogoColor } from "../../components/common/Logo";
import Portal from "../../components/common/Portal/Portal";
import { Theme } from "../../components/common/ThemeContainer";
import { useColorConverter } from "../../components/common/useColorCoverter/useColorCoverter";
import { ThemeContainer } from "../../components/spaces/SpaceConfig/LeftMenu/styledComponents";
import { colorTokens } from "../../cssConstants";
import { EnvironmentNode } from "../../types";

import { UserMenu } from "./HeaderMenu/HeaderMenu";

interface HeaderProps {
  homeButton?: React.ReactNode;
  title?: React.ReactNode;
  controls?: React.ReactNode;
  hideControls?: boolean;
  hideEnvironmentSelector?: boolean;
  theme?: "dark" | "light";
  className?: string;
  onToggleNav?: () => void;
}

const HeaderRoot = styled.header`
  height: ${props => props.theme.headerHeight};
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background: ${props => props.theme.headerBackgroundColor};
`;

const THEME_LOOKUP = {
  dark: Theme.Dark,
  light: Theme.Default
};
export function BaseHeader({
  theme = "dark",
  className = "",
  children
}: {
  theme?: "dark" | "light";
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <Portal id="header-portal">
      <ThemeContainer className={className} theme={THEME_LOOKUP[theme]}>
        <HeaderRoot>{children}</HeaderRoot>
      </ThemeContainer>
    </Portal>
  );
}

const sides = css`
  flex: 1;
  display: flex;
  min-width: -webkit-min-content; /* Workaround to Chrome bug */
`;

const Navigation = styled.div`
  ${sides}
  align-items: center;
  gap: 24px;
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: 500;
  &.hideControls {
    display: none;
  }
`;

const Controls = styled.div`
  ${sides}
  justify-content: flex-end;
  &.hideControls {
    display: none;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacermd};
`;

const StyledAvatar = styled(Avatar)`
  margin-left: ${props => props.theme.spacermd};
`;

function Logo() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.7143" cy="5.25739" r="4.28571" fill="white" />
      <rect y="11.2573" width="7.2" height="7.2" fill="#9D63E3" />
    </svg>
  );
}

function InternalHomeButton() {
  return (
    <Link data-test="homeButton" to="/">
      <Logo />
    </Link>
  );
}

function ToggleNavIcon() {
  return (
    <svg
      data-test="toggleNavIcon"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.5H15.5V13.5H7.5L7.5 1.5ZM6 1.5H1.5V13.5H6L6 1.5ZM6 15H1.5H0V13.5V1.5V0H1.5H15.5H17V1.5V13.5V15H15.5H7.5H6Z"
        fill="currentColor"
      />
    </svg>
  );
}

function ToggleNavButton({
  className,
  onClick
}: {
  className?: string;
  onClick: () => void;
}) {
  return (
    <div className={className} onClick={onClick}>
      <ToggleNavIcon />
    </div>
  );
}

const StyledToggleNavButton = styled(ToggleNavButton)`
  display: flex;
  color: ${props => props.theme.headerButtonColor};
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    color: ${props => props.theme.primaryColorDark};
  }
`;

function EnvironmentSwitcher() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { visibleEnvironments, getCurrentEnvironment } = useEnvironmentContext();
  if (visibleEnvironments.length <= 1 || !("environment" in params)) {
    return null;
  }

  const currEnv = getCurrentEnvironment();

  return (
    <EnvironmentSelect
      value={currEnv}
      onChange={(env: EnvironmentNode) => {
        const currPath = location.pathname;
        const nextPath = currPath.replace(
          `/environments/${currEnv.slug}/`,
          `/environments/${env.slug}/`
        );
        navigate(nextPath + location.search);
      }}
    />
  );
}

function Avatar({
  color = colorTokens.purpleBlue,
  children,
  className
}: {
  color?: string;
  children: React.ReactNode;
  className?: string;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <UserMenu className={className}>
      <BaseAvatar color={color} onClick={() => setMenuOpen(!menuOpen)}>
        {children}
      </BaseAvatar>
    </UserMenu>
  );
}

export default function Header({
  homeButton = <InternalHomeButton />,
  hideControls = false,
  title = null,
  controls = null,
  hideEnvironmentSelector,
  theme,
  className,
  onToggleNav
}: HeaderProps) {
  const { authUser } = useAuthUser();
  const authUserUuid = useAuthUserUuid(authUser);
  const color = useColorConverter(authUserUuid);

  return (
    <BaseHeader className={className} theme={theme}>
      <Navigation>
        <Group>
          {homeButton}
          {onToggleNav && <StyledToggleNavButton onClick={onToggleNav} />}
          {!hideControls && !hideEnvironmentSelector && <EnvironmentSwitcher />}
        </Group>
      </Navigation>
      <Title className={classNames({ hideControls })}>
        <div id="header-title-portal">{title}</div>
      </Title>
      <Controls className={classNames({ hideControls })}>
        <Group>
          <div id="header-controls-portal">{controls}</div>
          <StyledAvatar color={color}>{userToInitials(authUser)}</StyledAvatar>
        </Group>
      </Controls>
    </BaseHeader>
  );
}

export function HeaderTitle({ children }: { children: React.ReactNode }) {
  return (
    <Portal id="header-title-portal">
      <ThemeContainer theme={Theme.Dark}>
        <Group>{children}</Group>
      </ThemeContainer>
    </Portal>
  );
}

export function HeaderControls({ children }: { children: React.ReactNode }) {
  return (
    <Portal id="header-controls-portal">
      <ThemeContainer theme={Theme.Dark}>
        <Group>{children}</Group>
      </ThemeContainer>
    </Portal>
  );
}

const LightHomeButton = () => {
  return (
    <Link data-test="homeButton" to="/">
      <TextLogo color={LogoColor.Black} />
    </Link>
  );
};

const LightHeaderRoot = styled(Header)`
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 2px -2px rgba(200, 200, 200, 0.5);
`;

export function LightHeader({
  homeButton = <LightHomeButton />,
  ...rest
}: HeaderProps) {
  return <LightHeaderRoot theme="light" homeButton={homeButton} {...rest} />;
}
