import { MutationTuple, useMutation } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import _ from "lodash";

import { CREDENTIALS_QUERY } from "../../../ManageCredentialsTab/useManageCredentialsData";
import {
  UPDATE_API_KEY_DATA_SOURCE_MUTATION,
  UPDATE_DATA_SOURCE_MUTATION,
  UPDATE_DYNAMO_MUTATION,
  UPDATE_GOOGLE_SERVICE_ACCOUNT_MUTATION,
  UPDATE_HTTP_DATA_SOURCE_MUTATION,
  UPDATE_MAILGUN_DATA_SOURCE_MUTATION,
  UPDATE_MONGO_DATA_SOURCE_MUTATION,
  UPDATE_S3_MUTATION,
  UPDATE_SALESFORCE_DATA_SOURCE_MUTATION,
  UPDATE_SNOWFLAKE_DATA_SOURCE_MUTATION,
  UPDATE_ZENDESK_MUTATION,
  UpdateAPIKeyDataSourceMutationResult,
  UpdateAPIKeyDatasourceMutationVars,
  UpdateDataSourceArgumentsBase,
  UpdateDataSourceMutationResult,
  UpdateDataSourceMutationVars,
  UpdateHTTPDataSourceMutationResult,
  UpdateHTTPDatasourceMutationVars,
  UpdateMongoDatasourceMutationVars,
  UpdateSalesForceDataSourceMutationResult,
  UpdateSalesForceDataSourceMutationVars,
  UpdateSnowflakeDataSourceMutationResult,
  UpdateSnowflakeDataSourceMutationVars
} from "../../queries";

type UpdateNoopDataSourceMutationResult = {};

export type UpdateMutationResult =
  | UpdateAPIKeyDataSourceMutationResult
  | UpdateDataSourceMutationResult
  | UpdateHTTPDataSourceMutationResult
  | UpdateNoopDataSourceMutationResult
  | UpdateSalesForceDataSourceMutationResult
  | UpdateSnowflakeDataSourceMutationResult;
export type UpdateMutationVariables =
  | UpdateAPIKeyDatasourceMutationVars
  | UpdateDataSourceArgumentsBase
  | UpdateDataSourceMutationVars
  | UpdateHTTPDatasourceMutationVars
  | UpdateSalesForceDataSourceMutationVars
  | UpdateSnowflakeDataSourceMutationVars;

type OnCompleted = (success: boolean, message: string) => void;
interface Props {
  integration: string;
  providerId: string;
  onCompleted: OnCompleted;
  onError: (error: ApolloError) => void;
}

const wrapWithResultPath = <T>(cb: OnCompleted, path: string) => {
  return (data: T) => {
    const success = !!_.get(data, `${path}.ok`);
    const message = _.get(data, `${path}.message`, "");
    return cb(success, message);
  };
};

const useUpdateDataSourceMutationFactory = ({
  integration,
  onCompleted,
  onError,
  providerId = ""
}: Props) => {
  const refetchQueries = [
    {
      query: CREDENTIALS_QUERY,
      variables: { dataSourceProvider: providerId }
    }
  ];

  const apiMutation = useMutation<
    UpdateMutationResult,
    UpdateAPIKeyDatasourceMutationVars
  >(UPDATE_API_KEY_DATA_SOURCE_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateApiKeyDataSource"),
    refetchQueries,
    onError
  });

  const dataSourceMutation = useMutation<
    UpdateMutationResult,
    UpdateDataSourceMutationVars
  >(UPDATE_DATA_SOURCE_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateDataSource"),
    refetchQueries,
    onError
  });

  const dynamoDBMutation = useMutation(UPDATE_DYNAMO_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateDynamoDataSource"),
    refetchQueries,
    onError
  });

  const googleServiceAccountMutation = useMutation(
    UPDATE_GOOGLE_SERVICE_ACCOUNT_MUTATION,
    {
      onCompleted: wrapWithResultPath(
        onCompleted,
        "updateGoogleServiceAccountDataSource"
      ),
      refetchQueries,
      onError
    }
  );

  const httpMutation = useMutation<
    UpdateMutationResult,
    UpdateHTTPDatasourceMutationVars
  >(UPDATE_HTTP_DATA_SOURCE_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateHttpDataSource"),
    refetchQueries,
    onError
  });

  const mailgunMutation = useMutation<
    UpdateMutationResult,
    UpdateAPIKeyDatasourceMutationVars
  >(UPDATE_MAILGUN_DATA_SOURCE_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateMailgunDataSource"),
    refetchQueries,
    onError
  });

  const mongoMutation = useMutation<
    UpdateMutationResult,
    UpdateMongoDatasourceMutationVars
  >(UPDATE_MONGO_DATA_SOURCE_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateMongoDataSource"),
    refetchQueries,
    onError
  });

  const salesForceMutation = useMutation<
    UpdateMutationResult,
    UpdateSalesForceDataSourceMutationVars
  >(UPDATE_SALESFORCE_DATA_SOURCE_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateSalesForceDataSource"),
    refetchQueries,
    onError
  });

  const snowflakeMutation = useMutation<
    UpdateMutationResult,
    UpdateSnowflakeDataSourceMutationVars
  >(UPDATE_SNOWFLAKE_DATA_SOURCE_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateSnowflakeDataSource"),
    refetchQueries,
    onError
  });

  const s3Mutation = useMutation(UPDATE_S3_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateS3DataSource"),
    refetchQueries,
    onError
  });

  const zendeskMutation = useMutation(UPDATE_ZENDESK_MUTATION, {
    onCompleted: wrapWithResultPath(onCompleted, "updateZendeskDataSource"),
    refetchQueries,
    onError
  });

  if (["mssql", "mysql", "postgresql"].includes(integration)) {
    return dataSourceMutation;
  } else if (integration === "http") {
    return httpMutation;
  } else if (["bigquery", "firestore"].includes(integration)) {
    return googleServiceAccountMutation;
  } else if (integration === "s3") {
    return s3Mutation;
  } else if (integration === "dynamodb") {
    return dynamoDBMutation;
  } else if (integration === "mailgun") {
    return mailgunMutation;
  } else if (integration === "mongo") {
    return mongoMutation;
  } else if (integration === "salesforce") {
    return salesForceMutation;
  } else if (integration === "snowflake") {
    return snowflakeMutation;
  } else if (integration === "zendesk") {
    return zendeskMutation;
  } else if (["hubspot", "stripe"].includes(integration)) {
    return apiMutation;
  } else {
    // noop mutation if the integration isn't recognized
    return [
      () => new Promise<unknown>(() => null),
      { loading: false, called: false }
    ] as MutationTuple<
      UpdateNoopDataSourceMutationResult,
      UpdateDataSourceArgumentsBase
    >;
  }
};

export default useUpdateDataSourceMutationFactory;
