import React from "react";

import moment from "moment";

import { AttributeValueBaseProps } from "..";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../../constants";

export default function DateTimeValue({ className, value }: AttributeValueBaseProps) {
  let displayedValue = value || null;
  if (value && moment(value).isValid()) {
    displayedValue = moment(value).format(DATE_TIME_DISPLAY_FORMAT);
  }
  return <div className={className}>{displayedValue}</div>;
}
