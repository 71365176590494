import React, { cloneElement, useMemo, useState } from "react";

import { Icon } from "antd";
import { Modifier, usePopper } from "react-popper";
import styled from "styled-components";

import ButtonNew, { ButtonProps } from "../ButtonNew/ButtonNew";

interface MenuButtonAction {
  key: string;
  label?: React.ReactNode;
  button?: React.ReactElement;
  onClick?: () => void;
}

interface MenuButtonProps extends Omit<ButtonProps, "onClick"> {
  actions: MenuButtonAction[];
  button?: Omit<React.ReactElement, "onClick">;
  matchWidth?: boolean;
  maxWidthScale?: number;
}

const MenuButtonWrap = styled.div`
  display: inline-block;
`;

const Mask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

const Menu = styled.ul`
  background: white;
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  z-index: 3;
  box-shadow: ${props => props.theme.boxShadow};
`;

const MenuItem = styled.li`
  list-style: none;
`;

const menuButtonProps = {
  block: true,
  size: "sm" as const,
  type: "noFill" as const
};

const menuButtonStyle = {
  borderRadius: 0,
  textAlign: "left" as const,
  whiteSpace: "break-spaces" as const
};

export default function MenuButton({
  actions,
  button,
  maxWidthScale,
  matchWidth = false,
  ...buttonProps
}: MenuButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const modifiers = useMemo(() => {
    const mods: Modifier<any>[] = [];

    if (maxWidthScale) {
      mods.push({
        name: "maxWidthScale",
        enabled: true,
        fn: ({ state }: any) => {
          state.styles.popper.maxWidth = `${
            state.rects.reference.width * maxWidthScale
          }px`;
        },
        phase: "beforeWrite" as const,
        requires: ["computeStyles"],
        effect({ state }: any) {
          state.elements.popper.style.maxWidth = `${
            state.elements.reference.offsetWidth * maxWidthScale
          }px`;
        }
      });
    }

    if (matchWidth) {
      mods.push({
        name: "matchWidth",
        enabled: true,
        fn: ({ state }: any) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        phase: "beforeWrite" as const,
        requires: ["computeStyles"],
        effect({ state }: any) {
          state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
        }
      });
    }

    return mods;
  }, [maxWidthScale, matchWidth]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers
  });

  const openMenuButton = button ? (
    cloneElement(button, {
      onClick: () => {
        setIsOpen(!isOpen);
      }
    })
  ) : (
    <ButtonNew {...buttonProps} onClick={() => setIsOpen(!isOpen)}>
      {buttonProps.children || "More options"}
      <Icon type="down" />
    </ButtonNew>
  );

  return (
    <>
      <MenuButtonWrap ref={setReferenceElement}>{openMenuButton}</MenuButtonWrap>
      {isOpen && (
        <>
          <Mask onClick={() => setIsOpen(false)} />
          <Menu ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {actions.map(a => {
              let button;
              if (a.button) {
                const ogOnClick = a.button.props.onClick;
                // If the component has onComplete or onCancel props
                // close the menu when they are invoked instead of onClick
                const ogOnComplete = a.button.props.onComplete;
                const ogOnCancel = a.button.props.onCancel;
                const delayMenuClose = !!(ogOnComplete || ogOnCancel);
                button = cloneElement(a.button, {
                  ...menuButtonProps,
                  style: {
                    ...a.button.props.style,
                    ...menuButtonStyle
                  },
                  onClick: (evt: React.MouseEvent) => {
                    if (!delayMenuClose) setIsOpen(false);
                    ogOnClick && ogOnClick(evt);
                  },
                  onComplete: () => {
                    setIsOpen(false);
                    ogOnComplete && ogOnComplete();
                  },
                  onCancel: () => {
                    setIsOpen(false);
                    ogOnCancel && ogOnCancel();
                  }
                });
              } else {
                button = (
                  <ButtonNew
                    {...menuButtonProps}
                    style={menuButtonStyle}
                    onClick={() => {
                      setIsOpen(false);
                      a.onClick && a.onClick();
                    }}
                  >
                    {a.label}
                  </ButtonNew>
                );
              }
              return <MenuItem key={a.key}>{button}</MenuItem>;
            })}
          </Menu>
        </>
      )}
    </>
  );
}
