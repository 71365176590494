export const fromGlobalId = (nodeId: string) => atob(nodeId).split(":");

export const toGlobalId = (type: string, id: string) => btoa([type, id].join(":"));

// TODO: omitTypenameDeep is a hack.  Update when Apollo makes a fix.
//       Original issue: https://github.com/apollographql/apollo-client/issues/1564
//       Currently tracked at: https://github.com/apollographql/apollo-feature-requests/issues/6
export const omitTypenameDeep = function <T>(value: any): T | T[] {
  const key = "__typename";

  if (Array.isArray(value)) {
    return value.map(i => omitTypenameDeep(i)) as T[];
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject;
      return Object.assign({ [k]: omitTypenameDeep(value[k]) }, newObject);
    }, {}) as T;
  }
  return value;
};
