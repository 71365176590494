import { isEqual } from "lodash";

import { assertNever } from "../../util/assertNever";

export interface Permittable {
  id: string;
  permitted: boolean;
}

export interface FunctionParameter extends Permittable {
  name: string;
  required: boolean;
}

export interface FunctionAttribute extends Permittable {
  name: string;
}

export interface FunctionPermission {
  functionId: string;
  name: string;
  dataSource: string;
  allowsAll: boolean;
  parametersPermitted: number;
  parameters: FunctionParameter[];
  attributesPermitted: number;
  attributes: FunctionAttribute[];
}

export enum FunctionPolicyActionTypes {
  LOAD_EXISTING_POLICIES,
  ADD_ATTRIBUTE_POLICY,
  ADD_PARAMETER_POLICY,
  REMOVE_ATTRIBUTE_POLICY,
  REMOVE_PARAMETER_POLICY,
  TOGGLE_ALL_FIELDS_FOR_ROLE,
  // This allows you to select/deselect all attributes/parameters at once for a function
  TOGGLE_ALL_FIELDS_FOR_ROLE_FUNCTION,
  SET_ALLOW_ALL_FOR_ROLE,
  SET_ALLOW_ALL_FOR_ROLE_FUNCTION,
  COMMIT, // Commit current changes
  RESET // Reset to last loaded data
}

export type RelativeCount = "none" | "some" | "all";

/**
 * getRelativeCount returns
 *  "none" if value is 0
 *  "all" if value === total
 *  "some" otherwise
 *
 * Note that if value === 0 and total === 0, the return is "none", not all.
 *
 * value and total must both be greater than or equal to 0.
 */
export function getRelativeCount(value: number, total: number): RelativeCount {
  switch (value) {
    case 0:
      return "none";
    case total:
      return "all";
    default:
      return "some";
  }
}

export interface PolicySpaceFunctions {
  // Note: this is relative to functions below, not total functions in system
  fieldsEnabled: RelativeCount;
  // See above note.
  autoGrant: RelativeCount;
  functions: FunctionPermission[];
  totalCount: number;
}

export interface PolicySpaceReducerState {
  modified: boolean;
  current: PolicySpaceFunctions;
  original: PolicySpaceFunctions; // Keep track of original so we can see if we've made changes
}

export interface LoadExistingPoliciesAction {
  type: FunctionPolicyActionTypes.LOAD_EXISTING_POLICIES;
  payload: {
    functions: FunctionPermission[];
    totalCount: number;
  };
}

export interface AddParameterPolicyAction {
  type: FunctionPolicyActionTypes.ADD_PARAMETER_POLICY;
  payload: {
    functionId: string;
    parameterId: string;
  };
}

export interface AddAttributePolicyAction {
  type: FunctionPolicyActionTypes.ADD_ATTRIBUTE_POLICY;
  payload: {
    functionId: string;
    attributeId: string;
  };
}

export interface RemoveParameterPolicyAction {
  type: FunctionPolicyActionTypes.REMOVE_PARAMETER_POLICY;
  payload: {
    functionId: string;
    parameterId: string;
  };
}

export interface RemoveAttributePolicyAction {
  type: FunctionPolicyActionTypes.REMOVE_ATTRIBUTE_POLICY;
  payload: {
    functionId: string;
    attributeId: string;
  };
}

export interface SetAllowAllForRoleAction {
  type: FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE;
  payload: {
    allowAll: boolean;
  };
}

export interface SetAllowAllForRoleFunctionAction {
  type: FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE_FUNCTION;
  payload: {
    functionId: string;
    allowAll: boolean;
  };
}

export interface ToggleAllFieldsForRoleAction {
  type: FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE;
}

export interface ToggleAllFieldsForRoleFunctionAction {
  type: FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE_FUNCTION;
  payload: {
    functionId: string;
  };
}

export interface ClearModifiedAction {
  type: FunctionPolicyActionTypes.COMMIT;
}

export interface ResetAction {
  type: FunctionPolicyActionTypes.RESET;
}

export type PolicySpaceReducerAction =
  | LoadExistingPoliciesAction
  | AddAttributePolicyAction
  | AddParameterPolicyAction
  | RemoveAttributePolicyAction
  | RemoveParameterPolicyAction
  | ToggleAllFieldsForRoleAction
  | ToggleAllFieldsForRoleFunctionAction
  | SetAllowAllForRoleAction
  | SetAllowAllForRoleFunctionAction
  | ClearModifiedAction
  | ResetAction;

// modifyFunctionPermissionParametersPolicy takes a functionPermission and
// updates the parameter given it's id to the permitted state. paramtersPermitted
// is then also updated.
// If the parameter is not found, nothing is changed.
export function modifyFunctionPermissionParametersPolicy(
  functionPermission: FunctionPermission,
  parameterId: string,
  permitted: boolean
): FunctionPermission {
  const newFunc = {
    ...functionPermission
  };

  let found = false;

  newFunc.parameters = newFunc.parameters.map(param => {
    if (param.id === parameterId) {
      found = true;
      return { ...param, permitted };
    }

    return param;
  });

  if (found && permitted) {
    newFunc.parametersPermitted++;
  } else if (found) {
    newFunc.parametersPermitted--;
  }

  return newFunc;
}

// modifyFunctionPermissionAttributesPolicy takes a functionPermission and
// updates the attribute given it's id to the permitted state. attributesPermitted
// is then also updated.
// If the attribute is not found, nothing is changed.
export function modifyFunctionPermissionAttributesPolicy(
  functionPermission: FunctionPermission,
  attributeId: string,
  permitted: boolean
) {
  const newFunc = {
    ...functionPermission
  };

  let found = false;

  newFunc.attributes = newFunc.attributes.map(attr => {
    if (attr.id === attributeId) {
      found = true;
      return { ...attr, permitted: permitted };
    }

    return attr;
  });

  if (found && permitted) {
    newFunc.attributesPermitted++;
  } else if (found) {
    newFunc.attributesPermitted--;
  }

  return newFunc;
}

export interface FunctionPermissionStats {
  functionsCount: number;
  attributesCount: number;
  attributesPermittedCount: number;
  parametersCount: number;
  parametersPermittedCount: number;
  autoGrantCount: number;
}

export function calculateFunctionPermissionStats(
  permissions: FunctionPermission[]
): FunctionPermissionStats {
  return permissions.reduce(
    (prev, current) => {
      return {
        functionsCount: prev.functionsCount,
        attributesCount: prev.attributesCount + current.attributes.length,
        attributesPermittedCount:
          prev.attributesPermittedCount + current.attributesPermitted,
        parametersCount: prev.parametersCount + current.parameters.length,
        parametersPermittedCount:
          prev.parametersPermittedCount + current.parametersPermitted,
        autoGrantCount: prev.autoGrantCount + (current.allowsAll ? 1 : 0)
      };
    },
    {
      functionsCount: permissions.length,
      attributesCount: 0,
      attributesPermittedCount: 0,
      parametersCount: 0,
      parametersPermittedCount: 0,
      autoGrantCount: 0
    }
  );
}

export function policySpaceFunctionsFromFunctions(
  totalCount: number,
  functions: FunctionPermission[]
): PolicySpaceFunctions {
  const stats = calculateFunctionPermissionStats(functions);

  const fieldsEnabled = getRelativeCount(
    stats.attributesPermittedCount + stats.parametersPermittedCount,
    stats.attributesCount + stats.parametersCount
  );

  const autoGrant = getRelativeCount(stats.autoGrantCount, stats.functionsCount);

  return {
    fieldsEnabled: fieldsEnabled,
    autoGrant: autoGrant,
    functions: functions,
    totalCount: totalCount
  };
}

// modifyExistingState will calculate "total" values like - modified|fieldsEnabled|allGrant
// the modifier callback should return the modified PolicySpaceFunctions result
export function modifyExistingState(
  state: PolicySpaceReducerState,
  modifier: (functions: FunctionPermission[]) => FunctionPermission[]
): PolicySpaceReducerState {
  const newFunctions = modifier(state.current.functions);
  const newState = policySpaceFunctionsFromFunctions(
    state.current.totalCount,
    newFunctions
  );

  return {
    ...state,
    modified: !isEqual(state.original.functions, newState.functions),
    current: newState
  };
}

export function createInitialState(): PolicySpaceReducerState {
  const initial: PolicySpaceFunctions = {
    fieldsEnabled: "none",
    autoGrant: "none",
    functions: [],
    totalCount: 0
  };

  return {
    modified: false,
    original: initial,
    current: initial
  };
}

export function functionPoliciesForSpaceReducer(
  state: PolicySpaceReducerState,
  action: PolicySpaceReducerAction
): PolicySpaceReducerState {
  switch (action.type) {
    case FunctionPolicyActionTypes.LOAD_EXISTING_POLICIES:
      const { totalCount, functions } = action.payload;
      const initial = policySpaceFunctionsFromFunctions(totalCount, functions);

      return {
        modified: false,
        original: initial,
        current: initial
      };
    case FunctionPolicyActionTypes.COMMIT:
      return {
        ...state,
        modified: false,
        original: state.current,
        current: state.current
      };
    case FunctionPolicyActionTypes.RESET:
      return {
        ...state,
        modified: false,
        original: state.original,
        current: state.original
      };
    case FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE:
      return modifyExistingState(state, functions => {
        return functions.map(func => {
          return {
            ...func,
            allowsAll: action.payload.allowAll
          };
        });
      });
    case FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE_FUNCTION:
      return modifyExistingState(state, functions => {
        return functions.map(func => {
          if (func.functionId !== action.payload.functionId) {
            return func;
          }

          return {
            ...func,
            allowsAll: action.payload.allowAll
          };
        });
      });
    case FunctionPolicyActionTypes.ADD_ATTRIBUTE_POLICY:
      return modifyExistingState(state, functions => {
        return functions.map(func => {
          if (func.functionId !== action.payload.functionId) {
            return func;
          }

          return modifyFunctionPermissionAttributesPolicy(
            func,
            action.payload.attributeId,
            true
          );
        });
      });
    case FunctionPolicyActionTypes.ADD_PARAMETER_POLICY:
      return modifyExistingState(state, functions => {
        return functions.map(func => {
          if (func.functionId !== action.payload.functionId) {
            return func;
          }

          return modifyFunctionPermissionParametersPolicy(
            func,
            action.payload.parameterId,
            true
          );
        });
      });
    case FunctionPolicyActionTypes.REMOVE_ATTRIBUTE_POLICY:
      return modifyExistingState(state, functions => {
        return functions.map(func => {
          if (func.functionId !== action.payload.functionId) {
            return func;
          }

          return modifyFunctionPermissionAttributesPolicy(
            func,
            action.payload.attributeId,
            false
          );
        });
      });
    case FunctionPolicyActionTypes.REMOVE_PARAMETER_POLICY:
      return modifyExistingState(state, functions => {
        return functions.map(func => {
          if (func.functionId !== action.payload.functionId) {
            return func;
          }

          return modifyFunctionPermissionParametersPolicy(
            func,
            action.payload.parameterId,
            false
          );
        });
      });
    case FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE:
      return modifyExistingState(state, functions => {
        const allFieldsEnabled = functions.every(func => {
          return (
            func.parametersPermitted === func.parameters.length &&
            func.attributesPermitted === func.attributes.length
          );
        });

        if (allFieldsEnabled) {
          return functions.map(func => {
            return {
              ...func,
              parametersPermitted: 0,
              attributesPermitted: 0,
              parameters: func.parameters.map(param => {
                return { ...param, permitted: false };
              }),
              attributes: func.attributes.map(attr => {
                return { ...attr, permitted: false };
              })
            };
          });
        }

        // None, or some fields are enabled - enable them all on toggle.
        return functions.map(func => {
          return {
            ...func,
            parametersPermitted: func.parameters.length,
            attributesPermitted: func.attributes.length,
            parameters: func.parameters.map(param => {
              return { ...param, permitted: true };
            }),
            attributes: func.attributes.map(attr => {
              return { ...attr, permitted: true };
            })
          };
        });
      });
    case FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE_FUNCTION:
      return modifyExistingState(state, functions => {
        const existingFunction = functions.find(
          func => func.functionId === action.payload.functionId
        );
        if (!existingFunction) {
          return functions;
        }

        const allFieldsEnabled =
          existingFunction.parametersPermitted === existingFunction.parameters.length &&
          existingFunction.attributesPermitted === existingFunction.attributes.length;

        if (allFieldsEnabled) {
          return functions.map(func => {
            if (func.functionId !== action.payload.functionId) {
              return func;
            }

            return {
              ...func,
              parametersPermitted: 0,
              attributesPermitted: 0,
              parameters: func.parameters.map(param => {
                return { ...param, permitted: false };
              }),
              attributes: func.attributes.map(attr => {
                return { ...attr, permitted: false };
              })
            };
          });
        }

        // None, or some fields are enabled - enable them all on toggle.
        return functions.map(func => {
          if (func.functionId !== action.payload.functionId) {
            return func;
          }

          return {
            ...func,
            parametersPermitted: func.parameters.length,
            attributesPermitted: func.attributes.length,
            parameters: func.parameters.map(param => {
              return { ...param, permitted: true };
            }),
            attributes: func.attributes.map(attr => {
              return { ...attr, permitted: true };
            })
          };
        });
      });
    default:
      return assertNever(action);
  }
}
