import React, { ReactNode } from "react";

import styled from "styled-components";

import { AttributeValueInputProps } from "..";
import { DATE_TIME_DISPLAY_FORMAT, DATE_TIME_SAVE_FORMAT } from "../../../../constants";
import MomentFormItemPopper from "../../MomentFormItemPopper";
import { DateTimePicker } from "../../MomentFormItemPopper/DateTimePickers";

const Generated = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 28px;
  justify-content: center;
`;

export default function DateTimeInput({
  sourceName,
  value,
  form,
  fieldOptions,
  displayNullPlaceholder,
  disabled,
  generated,
  preventOverflow,
  sourceType,
  onChange,
  onFocus,
  onBlur
}: AttributeValueInputProps) {
  if (generated) {
    return (
      <Generated>
        <em>This field will be set automatically.</em>
      </Generated>
    );
  }

  const decorator = form
    ? form.getFieldDecorator(sourceName, {
        initialValue: value,
        ...fieldOptions
      })
    : (comp: ReactNode) => comp;

  return (
    <>
      {decorator(
        <MomentFormItemPopper
          sourceName={sourceName}
          sourceType={sourceType}
          fieldOptions={fieldOptions}
          onChange={onChange ? onChange : () => null}
          getFieldValue={form ? form.getFieldValue : () => null}
          displayNullPlaceholder={displayNullPlaceholder}
          disabled={disabled}
          preventOverflow={preventOverflow}
          displayFormat={DATE_TIME_DISPLAY_FORMAT}
          saveFormat={DATE_TIME_SAVE_FORMAT}
          PopoverPicker={DateTimePicker}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    </>
  );
}
