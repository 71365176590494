import { isPlainObject } from "is-plain-object";
import _ from "lodash";

import { AttributeTypes, ReturnSchema } from "../../../../constants";
import { FunctionAttribute, ViewFilter, ViewFilterOperator } from "../../../../types";
import {
  ParameterValues,
  ReservedListParameter,
  ReservedListParameterDefault
} from "../index";

export const serialize = (parameterValues: ParameterValues) =>
  Object.entries(parameterValues).reduce<{ [s: string]: any }>((acc, [k, v]) => {
    if (v === undefined) return acc;
    if (k === ReservedListParameter.FILTERS) {
      const viewFilters = v as ViewFilter[];
      const filters: { [k: string]: any }[] = [];
      const filter: { [k: string]: any } = {};

      viewFilters.forEach(f => {
        if (!f.sourceName) return;
        filters.push({
          attribute: f.sourceName,
          operator: f.operator,
          value: f.value
        });
        if (f.operator === ViewFilterOperator.EQUALS) {
          filter[f.sourceName] = f.value;
        }
      });
      acc["filters"] = filters;
      acc["filter"] = filter;
    } else if (k !== ReservedListParameter.FILTER) {
      acc[k] = v;
    }
    return acc;
  }, {});

export const fillDefaults = (parameterValues: ParameterValues) => ({
  ...parameterValues,
  ...Object.keys(ReservedListParameterDefault).reduce<ParameterValues>((acc, name) => {
    const key = name as ReservedListParameter;
    if (
      parameterValues[key] === undefined &&
      ReservedListParameterDefault[key] !== undefined
    ) {
      acc[key] = ReservedListParameterDefault[key];
    }
    return acc;
  }, {})
});

export const isValidListOutput = (data: any) => {
  if (_.isArray(data)) {
    return data.length ? isPlainObject(data[0]) : true;
  } else {
    return isPlainObject(data);
  }
};

export const readReturnSchema = (value: any): ReturnSchema => {
  if (isPlainObject(value)) {
    return ReturnSchema.OBJECT;
  } else if (_.isArray(value) && value.length && isPlainObject(value[0])) {
    return ReturnSchema.OBJECT_ARRAY;
  } else {
    return ReturnSchema.UNKNOWN;
  }
};

export const getAttributeType = (v: any) => {
  switch (typeof v) {
    case "number":
      return v % 1 === 0 ? AttributeTypes.INT : AttributeTypes.FLOAT;
    case "boolean":
      return AttributeTypes.BOOL;
    case "string":
      return AttributeTypes.STRING;
    default:
      return AttributeTypes.JSON;
  }
};

export const attributesFromObject = (obj: object) =>
  Object.entries(obj).reduce<Record<string, AttributeTypes>>((acc, [k, v]) => {
    acc[k] = getAttributeType(v);
    return acc;
  }, {});

export const readAttributes = (data: any) => {
  const sample = _.isArray(data) ? data : [data];
  const records = sample.reduce<Record<string, AttributeTypes>>((acc, obj) => {
    return { ...acc, ...attributesFromObject(obj) };
  }, {});
  return Object.entries(records).map<FunctionAttribute>(([k, v]) => ({
    key: false,
    name: k,
    type: v
  }));
};
