import React from "react";

import ErrorBoundary from "../common/ErrorBoundary";

import { getDisplayName } from "./util";

interface WithErrorProps {
  children?: React.ReactNode;
  hideMessage: boolean;
}

const WithErrorBoundary = ({ hideMessage, children }: WithErrorProps) => (
  <ErrorBoundary hideMessage={hideMessage}>{children}</ErrorBoundary>
);

interface ErrorBoundaryOptions {
  hideMessage?: boolean;
}

const withErrorBoundary =
  <P extends object>(
    Component: React.ComponentType<P>,
    options: ErrorBoundaryOptions = {}
  ) =>
  (props: P) => {
    (WithErrorBoundary as any).displayName = `withErrorBoundary(${getDisplayName(
      Component
    )})`;
    const { hideMessage = false } = options;
    return (
      <WithErrorBoundary hideMessage={!!hideMessage}>
        <Component {...props} />
      </WithErrorBoundary>
    );
  };

export default withErrorBoundary;
