import React from "react";

import ButtonNew, { ButtonProps } from "../../../../common/ButtonNew/ButtonNew";
import { FunctionResult } from "../../../../spaces/FunctionExecutor/FunctionExecutor";
import { TransitionNode, TaskNode } from "../useQueueClient/queries/AllTasks";
import { processEffects } from "../utils";

import ModalTransitionForm from "./ModalTransitionForm";

interface Props {
  task: TaskNode;
  transition: TransitionNode;
  className?: string;
  block?: boolean;
  type?: ButtonProps["type"];
  size?: ButtonProps["size"];
  style?: Partial<React.CSSProperties>;
  onClick?: () => void;
  onComplete?: () => void;
  onCancel?: () => void;
}

export default function TransitionButton({
  task,
  transition,
  className = "",
  block = false,
  type,
  size,
  style = {},
  onClick = () => {},
  onComplete = () => null,
  onCancel = () => null
}: Props) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <ButtonNew
        className={className}
        key={transition.name}
        type={type ? type : transition.isPrimary ? "brand" : "secondary"}
        size={size}
        block={block}
        style={style}
        onClick={() => {
          onClick();
          setShowModal(true);
        }}
      >
        {transition.name}
      </ButtonNew>
      <ModalTransitionForm
        task={task}
        transition={transition}
        visible={showModal}
        onCompleted={(result: FunctionResult, metadata: unknown) => {
          setShowModal(false);
          processEffects(metadata);
          onComplete();
        }}
        onCancel={() => {
          onCancel();
          setShowModal(false);
        }}
      />
    </>
  );
}
