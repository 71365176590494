import React, { useState, useReducer } from "react";

import { billingReducer } from "../BillingContext";

export function useStripeCustomer() {
  const initialState = {
    billingCity: "",
    billingAddress: "",
    billingZip: "",
    taxIdErrorMessage: "",
    billingCountry: "",
    billingCountryError: "",
    taxId: "",
    taxIdType: "",
    billingCompany: "",
    formValidation: false,
    setBillingCountryError: () => {},
    onCountryUpdate: () => {},
    onSelectTaxIdType: () => {}
  };

  const [state, dispatch] = useReducer(billingReducer, initialState);
  const [_, setBillingCountryError] = useState("");

  const setBillingCity = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_CITY", payload });
  const setBillingCompany = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_COMPANY", payload });
  const setBillingAddress = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_ADDRESS", payload });
  const setBillingZip = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_ZIP", payload });
  const setBillingCountry = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_COUNTRY", payload });
  const setTaxId = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_TAX_ID", payload });
  const setTaxIdType = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_TAX_ID_TYPE", payload });

  interface subscriptionType {
    customer: {
      name: string;
      address: {
        postal_code: string;
        country: string;
        city: string;
        line1: string;
      };
      tax_ids: {
        data: {
          value: string;
          type: string;
        }[];
      };
    };
  }

  const onCompanyUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingCompany(dispatch, e.target.value);
  };

  const onSelectTaxIdType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTaxIdType(dispatch, e.target.value);
  };

  const onTaxIdUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxId(dispatch, e.target.value);
  };

  const onCountryUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBillingCountry(dispatch, e.target.value);
    setTaxId(dispatch, "");
    setBillingCompany(dispatch, "");
    setBillingZip(dispatch, "");
    setBillingCity(dispatch, "");
    setBillingAddress(dispatch, "");
  };

  const onCityUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingCity(dispatch, e.target.value);
  };

  const onZipUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingZip(dispatch, e.target.value);
  };

  const onAddressUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingAddress(dispatch, e.target.value);
  };

  const setCompanyInfo = (subscription: subscriptionType) => {
    setBillingCompany(dispatch, subscription?.customer?.name);
    setBillingCountry(dispatch, subscription?.customer?.address?.country);
    setBillingAddress(dispatch, subscription?.customer?.address?.line1);
    setBillingCity(dispatch, subscription?.customer?.address?.city);
    setBillingZip(dispatch, subscription?.customer?.address?.postal_code);
    setTaxIdType(dispatch, subscription?.customer?.tax_ids?.data[0]?.type);
    setTaxId(dispatch, subscription?.customer?.tax_ids?.data[0]?.value);
  };

  const contextValue = {
    onCountryUpdate,
    onCompanyUpdate,
    onAddressUpdate,
    onCityUpdate,
    onZipUpdate,
    onSelectTaxIdType,
    onTaxIdUpdate,
    billingCountry: state.billingCountry,
    billingCountryError: state.billingCountryError,
    billingCompany: state.billingCompany,
    billingAddress: state.billingAddress,
    billingCity: state.billingCity,
    billingZip: state.billingZip,
    taxId: state.taxId,
    taxIdType: state.taxIdType,
    taxIdErrorMessage: state.taxIdErrorMessage,
    setBillingCountryError
  };

  return {
    reducer: [state, dispatch],
    setCompanyInfo,
    contextValue
  };
}
