import {
  Alert as AntAlert,
  Checkbox as AntCheckbox,
  Select as AntSelect,
  Button
} from "antd";
import styled from "styled-components";

import BaseDebouncedInput from "../../../../../common/DebouncedInput";

import BaseSection from "./Section";

export const StyledConfigContainer = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.textColor};
  }

  h1,
  h2 {
    font-size: ${props => props.theme.defaultFontSize};
    line-height: ${props => props.theme.defaultLineHeight};
  }

  h1 {
    font-weight: bold;
  }

  h2 {
    font-weight: 300;
  }

  label {
    color: ${props => props.theme.labelColor};
  }

  hr {
    border: 0;
    height: 1px;
    margin: ${props => props.theme.spacermd}
      ${props => props.theme.spacervaluelg * -1}px;
    background: ${props => props.theme.tableBorderColor};
  }

  .ant-tabs {
    color: ${props => props.theme.textColorMid};
    overflow: visible;

    > .ant-tabs-bar {
      margin-bottom: 0;
    }
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    margin-bottom: ${props => props.theme.spacerxs};
  }

  & + & {
    margin-top: ${props => props.theme.spacermd};
  }

  .ant-select:not(:first-child) {
    margin-top: ${props => props.theme.spacersm};
  }

  label + .ant-select:not(:first-child) {
    margin-top: 0;
  }
`;

export const ErrorField = styled.p`
  color: ${props => props.theme.errorColor};
  margin-top: ${props => props.theme.spacerxs};
  font-size: ${props => props.theme.smallFontSize};
`;
ErrorField.displayName = "ErrorField";

export const Select = styled(AntSelect)`
  width: 100%;
`;
Select.displayName = "Select";
export const SelectOption = AntSelect.Option;

export const Checkbox = styled(AntCheckbox)`
  display: flex;
  margin-bottom: 0;

  .ant-checkbox-checked::after {
    height: auto;
  }

  & > .ant-checkbox {
    margin-top: ${props => props.theme.spacerxs};
  }
`;
Checkbox.displayName = "Checkbox";

export const Alert = styled(AntAlert)`
  background-color: transparent;
  border: 0;
  padding: 0 0 0 24px; /* left padding accounts for icon */

  i {
    top: 6px;
    left: ${props => props.theme.spacerxs};
    font-size: ${props => props.theme.smallFontSize};
  }

  .ant-alert-message {
    color: ${props => props.theme.textColorMid};
    font-size: ${props => props.theme.smallFontSize};
  }
`;

export const Section = styled(BaseSection)``;

export const DebouncedInput = styled(BaseDebouncedInput)``;

export const ButtonWithTopMargin = styled(Button)`
  margin-top: ${props => props.theme.spacermd};
`;

export const WrapTextButton = styled(Button)`
  height: fit-content;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  padding-top: ${props => props.theme.spacerxs};
  padding-bottom: ${props => props.theme.spacerxs};

  & + & {
    margin-top: ${props => props.theme.spacersm};
  }
`;

export const IconButton = styled(Button)`
  color: ${props => props.theme.textColorMid};
  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`;
