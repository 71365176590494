import React, { ChangeEvent, Dispatch } from "react";

import { Input } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { InfoIconButton } from "../../../../../common/InfoIconButton";
import * as common from "../../../common/styledComponents";
import { ReducerAction, ReducerActions } from "../../../reducer/actions";
import { State, Transition as TransitionType } from "../../../types";

import * as styled from "./styledComponents";

interface Props {
  currentState: State;
  states: State[];
  transition: TransitionType;
  dispatch: Dispatch<ReducerAction>;
}

const Transition = ({ currentState, dispatch, states, transition }: Props) => {
  const onChangeToState = (key: string | undefined) =>
    dispatch({
      type: ReducerActions.UPDATE_TRANSITION,
      payload: { key: transition.key, toStateKey: key }
    });

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: ReducerActions.UPDATE_TRANSITION,
      payload: { key: transition.key, name: e.target.value }
    });

  const onChangePrimary = (e: CheckboxChangeEvent) =>
    dispatch({
      type: ReducerActions.UPDATE_TRANSITION,
      payload: { key: transition.key, isPrimary: e.target.checked }
    });

  const stateOptions = React.useMemo(() => {
    return states
      .filter(s => s.key !== currentState.key)
      .map(s => ({
        label: s.name,
        value: s.key,
        color: s.color
      }));
  }, [currentState.key, states]);

  const selectedStateOption = stateOptions.find(s => s.value === transition.toStateKey);

  if (transition.fromStateKey === null) {
    return <div>(when a task is created transition placeholder)</div>;
  }

  const transitionToSelf = transition.fromStateKey === transition.toStateKey;

  return (
    <styled.Container>
      <common.Fieldset>
        <common.Label>CTA label</common.Label>
        <Input value={transition.name} onChange={onChangeName} />
      </common.Fieldset>
      <styled.OptionContainer>
        <styled.Checkbox onChange={onChangePrimary} checked={transition.isPrimary}>
          Set as primary CTA{" "}
          <InfoIconButton title="A primary CTA is featured in the task detail view. It's recommended that you set one primary CTA for each state in a Queue." />
        </styled.Checkbox>
      </styled.OptionContainer>
      <styled.OptionContainer>
        <common.FieldsetBox>
          <styled.OptionContent>
            <styled.Checkbox
              checked={!transitionToSelf}
              onChange={e =>
                onChangeToState(e.target.checked ? undefined : currentState.key)
              }
            >
              When clicked, transition to another state
            </styled.Checkbox>
          </styled.OptionContent>
          {
            <styled.StateSelect
              onSelected={stateOption => onChangeToState(stateOption.value)}
              hidden={transitionToSelf}
              animateVisibility={false}
              options={stateOptions}
              value={transitionToSelf ? undefined : selectedStateOption}
            />
          }
        </common.FieldsetBox>
      </styled.OptionContainer>
    </styled.Container>
  );
};

export default Transition;
