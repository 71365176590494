import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  let errors: ConfigValidationError[] = [];
  const { draftComponent } = state;

  if (!draftComponent.properties.url_template) {
    errors = errors.concat({
      field: "URL_TEMPLATE",
      message: "Please provide a URL template for this link field."
    });
  }

  return errors.concat(selectVisibilityRuleErrors(draftComponent));
}
