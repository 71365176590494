import _ from "lodash";

import { ReturnSchema } from "../../../../constants";
import {
  APIFiltersOption,
  APISortByOption,
  BaseFunctionName,
  DescribeColumn,
  FunctionAttribute,
  FunctionAuthorizationFlowInput,
  FunctionNodeBasic,
  FunctionParameterInput,
  Metadata
} from "../../../../types";
import { assertNever } from "../../../util/assertNever";
import {
  DataSourceNodeWithFunctions,
  GeneralActionTypes,
  SetFieldValueAction
} from "../forms/types";
import { FunctionEditorMetadata } from "../FunctionEditor";
import {
  BaseCode,
  BaseFunctionParameterMapping,
  EditorTab,
  PreviewResult
} from "../index";
import { isSQLLike, SupportedIntegration } from "../support";

import { parseIdentifiers } from "./parsers";
import { EditorFunctionNode } from "./queries";
import {
  defaultBaseFunction,
  defaultBaseFunctionParameterMapping,
  defaultMetadata,
  defaultMetadataReducer,
  defaultReturnSchema,
  getParameters,
  toFunctionState
} from "./util";

export interface FunctionState<M> {
  integration: SupportedIntegration | undefined;
  title: string;
  baseFunctionId: string;
  baseFunctionName: BaseFunctionName | "";
  baseFunctionParameterMapping: BaseFunctionParameterMapping;
  functionId: string;
  metadata: M;
  reducer: string;
  metadataReducer: string;
  parameters: FunctionParameterInput[];
  attributes: FunctionAttribute[];
  returnSchema: ReturnSchema | undefined;
  authorizationFlows: FunctionAuthorizationFlowInput[];
  environmentIdsWithCredentials: { [id: string]: true };
}

export interface UIState<M> {
  activeEditorTab: EditorTab;
  lastSavedFunctionState: FunctionState<M> | undefined;

  // lastSeenParameters is the last viewed set of parameters
  // that the user has seen in the INPUT tab.
  lastSeenParameters: FunctionParameterInput[];

  lastSeenAttributes: FunctionAttribute[];

  // parameterCache is a cache of parameters that has
  // information about changes the user has made to a parsed
  // parameter.  It is used to populate parameters with
  // previously configured information (e.g. required and
  // type info) when a parameter has gone away and then
  // reappeared.
  parameterCache: FunctionParameterInput[];

  previewAttributes: FunctionAttribute[];
  previewId: number;
  previewReturnSchema: ReturnSchema | undefined;
  refreshId: number;
}

export type State<M = Metadata> = FunctionState<M> & UIState<M>;

export const getEmptyState = <M>(metadata: M): State<M> => ({
  integration: undefined,
  title: "",
  baseFunctionId: "",
  baseFunctionName: "",
  baseFunctionParameterMapping: {},
  functionId: "",
  metadata,
  reducer: "data",
  metadataReducer: "metadata",
  attributes: [],
  parameters: [],
  returnSchema: undefined,
  authorizationFlows: [],
  environmentIdsWithCredentials: {},
  ...getEmptyUIState()
});

const getEmptyUIState = <M>(): UIState<M> => ({
  activeEditorTab: EditorTab.CONFIGURE,
  lastSavedFunctionState: undefined,
  lastSeenParameters: [],
  lastSeenAttributes: [],
  parameterCache: [],
  previewAttributes: [],
  previewId: 0,
  previewReturnSchema: undefined,
  refreshId: 0
});

export enum ActionType {
  CHANGE_DATA_SOURCE = "CHANGE_DATA_SOURCE",
  LOAD_FUNCTION = "LOAD_FUNCTION",
  REFRESH_ATTRIBUTES = "REFRESH_ATTRIBUTES",
  UPDATE_ACTIVE_EDITOR_TAB = "UPDATE_ACTIVE_EDITOR_TAB",
  UPDATE_ATTRIBUTES = "UPDATE_ATTRIBUTES",
  UPDATE_BASE_CONFIG = "UPDATE_BASE_CONFIG",
  UPDATE_BASE_FUNCTION = "UPDATE_BASE_FUNCTION",
  UPDATE_PARAMETER = "UPDATE_PARAMETER",
  UPDATE_PREVIEW_RESULT = "UPDATE_PREVIEW_RESULT",
  UPDATE_RETURN_SCHEMA = "UPDATE_RETURN_SCHEMA",
  UPDATE_FUNCTION_AUTHORIZATION_FLOW = "UPDATE_FUNCTION_AUTHORIZATION_FLOW",
  UPDATE_DESCRIBE_COLUMNS = "UPDATE_DESCRIBE_COLUMNS",
  UPDATE_REDUCER = "UPDATE_REDUCER"
}

export interface LoadFunctionAction {
  type: ActionType.LOAD_FUNCTION;
  payload: EditorFunctionNode<any>;
}

export interface ChangeDataSourceAction {
  type: ActionType.CHANGE_DATA_SOURCE;
  payload: DataSourceNodeWithFunctions<FunctionNodeBasic>;
}

export interface BaseFunctionConfig<C> {
  baseFunctionParameterMapping: BaseFunctionParameterMapping;
  code?: C;
}

export interface UpdateActiveEditorTabAction {
  type: ActionType.UPDATE_ACTIVE_EDITOR_TAB;
  payload: {
    tab: EditorTab;
  };
}

export interface UpdateAttributesAction {
  type: ActionType.UPDATE_ATTRIBUTES;
  payload: FunctionAttribute[];
}

export interface RefreshAttributesAction {
  type: ActionType.REFRESH_ATTRIBUTES;
}

export interface UpdateBaseConfigAction<C> {
  type: ActionType.UPDATE_BASE_CONFIG;
  payload: BaseFunctionConfig<C>;
}

export interface UpdateBaseFunctionAction {
  type: ActionType.UPDATE_BASE_FUNCTION;
  payload: {
    baseFunctionId: string;
    baseFunctionName: BaseFunctionName;
  };
}

export interface UpdateParameterAction {
  type: ActionType.UPDATE_PARAMETER;
  payload: FunctionParameterInput;
}

export interface UpdatePreviewResult {
  type: ActionType.UPDATE_PREVIEW_RESULT;
  payload: PreviewResult;
}

export interface UpdateReturnSchema {
  type: ActionType.UPDATE_RETURN_SCHEMA;
  payload: ReturnSchema;
}

export interface UpdateAuthorizationFlowAction {
  type: ActionType.UPDATE_FUNCTION_AUTHORIZATION_FLOW;
  payload: { environmentId: string; authorizationFlowId: string | undefined };
}

export interface UpdateDescribeColumns {
  type: ActionType.UPDATE_DESCRIBE_COLUMNS;
  payload: DescribeColumn[];
}

export interface UpdateReducer {
  type: ActionType.UPDATE_REDUCER;
  payload: string;
}

type Action<C> =
  | LoadFunctionAction
  | SetFieldValueAction<keyof State>
  | ChangeDataSourceAction
  | UpdateActiveEditorTabAction
  | UpdateAttributesAction
  | RefreshAttributesAction
  | UpdateBaseConfigAction<C>
  | UpdateBaseFunctionAction
  | UpdateParameterAction
  | UpdatePreviewResult
  | UpdateReturnSchema
  | UpdateAuthorizationFlowAction
  | UpdateDescribeColumns
  | UpdateReducer;

function handleUpdateAttributes<M>(
  state: State<M>,
  attributes: FunctionAttribute[]
): State<M> {
  const newState: State<M> = { ...state, attributes };
  if (state.activeEditorTab === EditorTab.OUTPUTS) {
    newState.lastSeenAttributes = newState.attributes;
  }
  return newState;
}

function handleUpdateReturnSchema<C, M extends Metadata<C>>(
  state: State<M>,
  returnSchema: ReturnSchema | undefined
): State<M> {
  const categories = new Set(state.metadata.categories);
  categories.delete("returns:many");
  categories.delete("returns:one");
  switch (returnSchema) {
    case ReturnSchema.OBJECT_ARRAY:
      categories.add("returns:many");
      break;
    case ReturnSchema.OBJECT:
      categories.add("returns:one");
      break;
  }
  return {
    ...state,
    returnSchema,
    metadata: {
      ...state.metadata,
      categories: Array.from(categories).sort()
    }
  };
}

export const reducer = <
  C = BaseCode,
  M extends FunctionEditorMetadata<C> = FunctionEditorMetadata<C>
>(
  state: State<M>,
  action: Action<C>
): State<Metadata<any>> => {
  switch (action.type) {
    case ActionType.CHANGE_DATA_SOURCE: {
      const fn = defaultBaseFunction(action.payload);
      const mapping = fn
        ? defaultBaseFunctionParameterMapping(fn.name as BaseFunctionName)
        : {};

      const metadataReducer = fn
        ? defaultMetadataReducer(fn.name as BaseFunctionName)
        : "metadata";
      const returnSchema = fn
        ? defaultReturnSchema(fn.name as BaseFunctionName)
        : undefined;
      return {
        ...getEmptyState(fn ? defaultMetadata(fn.name as BaseFunctionName) : {}),
        integration: action.payload.integration,
        title: state.title,
        baseFunctionId: fn?.id || "",
        baseFunctionName: (fn?.name as BaseFunctionName) || "",
        baseFunctionParameterMapping: mapping,
        metadataReducer,
        returnSchema
      };
    }
    case ActionType.LOAD_FUNCTION: {
      const s = toFunctionState(action.payload);
      return {
        ...getEmptyState(s.baseFunctionName ? defaultMetadata(s.baseFunctionName) : {}),
        ...s,
        activeEditorTab: state.activeEditorTab || EditorTab.CONFIGURE,
        lastSavedFunctionState: _.cloneDeep(s),
        lastSeenParameters: s.parameters,
        lastSeenAttributes: s.attributes,
        parameterCache: s.parameters,
        previewAttributes: s.attributes,
        previewReturnSchema: s.returnSchema
      };
    }
    case ActionType.UPDATE_ACTIVE_EDITOR_TAB: {
      const newState: State<M> = {
        ...state,
        activeEditorTab: action.payload.tab
      };
      if (action.payload.tab === EditorTab.INPUTS) {
        newState.lastSeenParameters = newState.parameters;
      }
      if (action.payload.tab === EditorTab.OUTPUTS) {
        newState.lastSeenAttributes = newState.attributes;
      }
      return newState;
    }
    case ActionType.UPDATE_ATTRIBUTES: {
      return handleUpdateAttributes(state, action.payload);
    }
    case ActionType.REFRESH_ATTRIBUTES: {
      const newState: State<M> = {
        ...state,
        refreshId: state.previewId,
        returnSchema: state.previewReturnSchema,
        attributes: [...state.previewAttributes]
      };
      if (state.activeEditorTab === EditorTab.OUTPUTS) {
        newState.lastSeenAttributes = newState.attributes;
      }
      return newState;
    }
    case ActionType.UPDATE_BASE_CONFIG:
      if (!state.baseFunctionName) {
        console.warn("useFunctionEditor: baseFunctionName is not set", action.payload);
        return state;
      }
      if (!state.integration) {
        console.warn("useFunctionEditor: integration is not set", action.payload);
        return state;
      }

      const { baseFunctionParameterMapping, code } = action.payload;
      const metadata = { ...state.metadata };
      if (code !== undefined) {
        metadata.code = code;
      }

      const [identifiers] = parseIdentifiers(
        state.integration,
        state.baseFunctionName,
        baseFunctionParameterMapping,
        metadata
      );

      const parameters = getParameters(state.parameterCache, identifiers);

      return {
        ...state,
        metadata,
        baseFunctionParameterMapping,
        parameters
      };
    case ActionType.UPDATE_BASE_FUNCTION: {
      if (!state.integration) {
        console.warn("useFunctionEditor: integration is not set", action.payload);
        return state;
      }

      const { baseFunctionId, baseFunctionName } = action.payload;
      const metadata = defaultMetadata(baseFunctionName);
      const metadataReducer = defaultMetadataReducer(baseFunctionName);
      const baseFunctionParameterMapping =
        defaultBaseFunctionParameterMapping(baseFunctionName);
      const returnSchema = defaultReturnSchema(baseFunctionName);

      const [identifiers] = parseIdentifiers(
        state.integration,
        baseFunctionName,
        baseFunctionParameterMapping,
        metadata
      );
      const parameters = getParameters(state.parameters, identifiers);

      return {
        ...state,
        baseFunctionId,
        baseFunctionName,
        baseFunctionParameterMapping,
        returnSchema,
        metadata,
        metadataReducer,
        parameters
      };
    }
    case ActionType.UPDATE_PARAMETER: {
      const updated = action.payload;
      const parameters = [...state.parameters];
      const idx = parameters.findIndex(p => p.name === updated.name);
      if (idx > -1) {
        parameters[idx] = updated;
      }
      const lastSeenParameters =
        state.activeEditorTab === EditorTab.INPUTS
          ? parameters
          : state.lastSeenParameters;
      return {
        ...state,
        parameters,
        parameterCache: parameters,
        lastSeenParameters
      };
    }
    case GeneralActionTypes.SET_FIELD_VALUE:
      const { fieldName, fieldValue } = action.payload;

      // Some request body types have special default metadata reducers
      if (
        fieldName === "metadata" &&
        fieldValue.request_type !== state.metadata.request_type &&
        state.baseFunctionName
      ) {
        const currentMetadataReducer = defaultMetadataReducer(
          state.baseFunctionName,
          state.metadata.request_type
        );
        const metadataReducer = defaultMetadataReducer(
          state.baseFunctionName,
          fieldValue.request_type
        );

        // Only set the default metadata reducer if metadata reducer has not been modified
        if (
          state.metadataReducer === currentMetadataReducer &&
          state.metadataReducer !== metadataReducer
        ) {
          return {
            ...state,
            [action.payload.fieldName]: action.payload.fieldValue,
            metadataReducer
          };
        }
      }

      return {
        ...state,
        [action.payload.fieldName]: action.payload.fieldValue
      };
    case ActionType.UPDATE_PREVIEW_RESULT: {
      return {
        ...state,
        previewId: state.previewId + 1,
        previewAttributes: action.payload.attributes,
        previewReturnSchema: action.payload.returnSchema
      };
    }
    case ActionType.UPDATE_RETURN_SCHEMA: {
      return handleUpdateReturnSchema(state, action.payload);
    }
    case ActionType.UPDATE_FUNCTION_AUTHORIZATION_FLOW:
      const { authorizationFlowId, environmentId } = action.payload;
      const flows = [...state.authorizationFlows];
      const idx = flows.findIndex(f => f.environmentId === environmentId);
      const replace = authorizationFlowId
        ? [
            {
              environmentId,
              authorizationFlowId
            }
          ]
        : [];
      flows.splice(idx > -1 ? idx : flows.length, 1, ...replace);
      flows.sort((a, b) => a.environmentId.localeCompare(b.environmentId));
      return {
        ...state,
        authorizationFlows: flows
      };
    case ActionType.UPDATE_DESCRIBE_COLUMNS: {
      const { payload: columns } = action;

      const attributes = columns.map<FunctionAttribute>(c => {
        const existingAttribute = state.attributes.find(a => a.name === c.sourceName);
        return {
          name: c.sourceName,
          type: c.sourceType,
          key: existingAttribute?.key || c.sourceKey || false
        };
      });

      const nextState = handleUpdateAttributes(state, attributes);

      const filtersOptions = columns.reduce<APIFiltersOption[]>(
        (acc, c) =>
          c.operators.length
            ? acc.concat([
                {
                  operators: c.operators,
                  sourceName: c.sourceName,
                  sourceType: c.sourceType
                }
              ])
            : acc,
        []
      );

      const sortByOptions = columns.reduce<APISortByOption[]>(
        (acc, c) => (c.orderable ? acc.concat([c.sourceName]) : acc),
        []
      );

      const metadata = {
        ...nextState.metadata,
        filters: { options: filtersOptions },
        sortBy: { options: sortByOptions }
      };

      return { ...nextState, metadata };
    }
    case ActionType.UPDATE_REDUCER: {
      let returnSchema = state.returnSchema;
      if (isSQLLike(state.integration)) {
        switch (action.payload) {
          case "data":
            returnSchema = ReturnSchema.OBJECT_ARRAY;
            break;
          case "data[0]":
            returnSchema = ReturnSchema.OBJECT;
            break;
        }
      }
      return handleUpdateReturnSchema(
        { ...state, reducer: action.payload },
        returnSchema
      );
    }
    default:
      return assertNever(action);
  }
};
