import React from "react";

import LoginForm from "../components/auth/LoginForm";
import { RequirePartialAuth } from "../components/auth/RequirePartialAuth";
import TwoFactorForm from "../components/auth/TwoFactorForm";
import RequestPasswordResetSent from "../components/settings/password_reset/RequestPasswordResetConfirmation";
import RequestPasswordResetForm from "../components/settings/password_reset/RequestPasswordResetForm";
import ResetPasswordForm from "../components/settings/password_reset/ResetPasswordForm";
import { default as Confirm2FA } from "../components/settings/TwoFactor/ConfirmTwoFactor";
import SetUpTwoFactor from "../components/settings/TwoFactor/SetUpTwoFactor";
import { BasicLayout, NoHeaderLayout } from "../layouts";

export const New = () => (
  <NoHeaderLayout backgroundColor="black">
    <LoginForm />
  </NoHeaderLayout>
);

export const TwoFactor = () => (
  <RequirePartialAuth>
    <BasicLayout>
      <TwoFactorForm />
    </BasicLayout>
  </RequirePartialAuth>
);

export const ResetPasswordRequest = () => (
  <NoHeaderLayout backgroundColor="black">
    <RequestPasswordResetForm />
  </NoHeaderLayout>
);

export const ResetPasswordComplete = () => (
  <NoHeaderLayout backgroundColor="black">
    <RequestPasswordResetSent />
  </NoHeaderLayout>
);

export const ResetPasswordConfirm = () => (
  <NoHeaderLayout backgroundColor="black">
    <ResetPasswordForm />
  </NoHeaderLayout>
);

export const SetupTwoFactor = () => (
  <RequirePartialAuth>
    <BasicLayout>
      <SetUpTwoFactor />
    </BasicLayout>
  </RequirePartialAuth>
);

export const ConfirmTwoFactor = () => (
  <RequirePartialAuth>
    <BasicLayout>
      <Confirm2FA />
    </BasicLayout>
  </RequirePartialAuth>
);
