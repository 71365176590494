import React from "react";

import useResizeObserver from "use-resize-observer/polyfilled";

export default function useObservedRect(
  ref: React.RefObject<HTMLElement> | null,
  offset?: DOMPoint
) {
  const observer = useResizeObserver({ ref });
  // TODO: try and use oberver dims here. getBoundingClientRect is misleading wrt to top / left
  //       as they are relative to the viewport and not the offset parent. Currently this
  //       can be worked around by providing optional offset but overall wonky
  return React.useMemo(
    () => {
      if (!ref) return new DOMRect(0, 0, 0, 0);
      let rect = ref.current?.getBoundingClientRect() || new DOMRect();
      if (offset) {
        rect = new DOMRect(offset.x, offset.y, rect.width, rect.height);
      }
      return rect;
    },
    // HACK: Use observer to detect a change occurred, but when one does
    //       just use the ref's `boundingClientRect` directly.
    // eslint-disable-next-line
    [observer.width, observer.height]
  );
}
