import styled from "styled-components";

import { BasePanel } from "../../common/StyledComponents";

export const Panel = styled(BasePanel)`
  padding: ${props => props.theme.spacermd};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
    margin-right: ${props => props.theme.spacersm};
    font-size: ${props => props.theme.h3FontSize};
    font-weight: ${props => props.theme.boldFontWeight};
    color: ${props => props.theme.textColorMid};
  }

  span {
    color: ${props => props.theme.textColorMid};
  }
`;

export const RightSide = styled.div`
  button {
    margin: ${props => props.theme.spacerxs};
  }
`;
