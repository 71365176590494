import React from "react";

import PageHeader from "../../../layouts/Header/Header";

import HomeButton from "./HomeButton";

export default function QueuesPageHeader({
  title,
  controls = null,
  onToggleNav
}: {
  title: React.ReactNode;
  controls?: React.ReactNode;
  onToggleNav: () => void;
}) {
  return (
    <PageHeader
      homeButton={<HomeButton />}
      key="queue-tasks"
      title={title}
      controls={controls}
      onToggleNav={onToggleNav}
    />
  );
}
