import React from "react";

import { Radio } from "antd";
import styled from "styled-components";

import { AttributeTypes, ErrorValues, ErrorValue } from "../../../../../constants";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import { parseOptionsCsv, Option } from "../common/HardcodedOptionFields/utils";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDefaultValue from "../common/useDefaultValue";
import useOutputSyncing from "../common/useOutputSyncing";
import { toAttributeType } from "../common/util";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { ensureSpaceRadioButtonComponent } from "./Config/reducer";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: ${props => props.theme.spacerxs};
    }

    > .ant-radio-wrapper {
      display: flex;
      align-items: center;

      > span {
        white-space: initial;
      }
    }
  }
`;

export default function SpaceRadioButton({ spaceComponent, hasConfigError }: Props) {
  const radioButtonComponent = ensureSpaceRadioButtonComponent(spaceComponent);
  const { editMode } = useStableSpaceContext();
  const { output } = useComponentStateContext();
  const [selectedValue, setSelectedValue] = React.useState<
    string | null | undefined | ErrorValue
  >(undefined);
  const { defaultValue } = useDefaultValue();

  const { validation_type } = radioButtonComponent.properties;
  const isBoolean = toAttributeType(validation_type) === AttributeTypes.BOOL;

  const options: Option[] = React.useMemo(() => {
    const options = parseOptionsCsv(spaceComponent.properties.hardcoded_options || "");
    if (radioButtonComponent.properties.allow_null && !isBoolean) {
      options.push({
        label: "Null",
        value: null
      });
    }
    return options;
  }, [
    spaceComponent.properties.hardcoded_options,
    radioButtonComponent.properties.allow_null,
    isBoolean
  ]);

  const defaultOption = React.useMemo(() => {
    if (defaultValue === ErrorValues.permissionDenied) {
      return defaultValue;
    }
    return options.find(option => {
      if (defaultValue === null || defaultValue === undefined) {
        return option.value === defaultValue;
      }
      // option values are always strings or null, because they are derived
      // from hardcoded values. therefore, we must convert the defaultValue
      // to string to compare.
      // Types supported: string, int, decimal, float, and boolean.
      return option.value === defaultValue.toString();
    });
  }, [options, defaultValue]);

  const defaultOptionValue = React.useMemo(() => {
    return defaultOption === ErrorValues.permissionDenied
      ? defaultOption
      : (defaultOption as Option)?.value;
  }, [defaultOption]);

  React.useEffect(() => {
    setSelectedValue(defaultOptionValue);
  }, [defaultOptionValue]);

  React.useEffect(() => {
    if (output !== null) return;
    setSelectedValue(defaultOptionValue);
  }, [output, defaultOptionValue]);

  const { errorMessage } = useOutputSyncing(selectedValue);

  return (
    <EditModeInteractionBlocker>
      <Root>
        <FlexContainer>
          {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
          <RadioGroup
            onChange={e => setSelectedValue(e.target.value)}
            value={
              selectedValue === ErrorValues.permissionDenied ? undefined : selectedValue
            }
          >
            {options.map((o, i) => (
              <Radio value={o.value} key={`${i}-${o.value}`}>
                {o.label}
              </Radio>
            ))}
          </RadioGroup>
          {selectedValue === ErrorValues.permissionDenied && <PermissionWarningIcon />}
        </FlexContainer>

        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </Root>
    </EditModeInteractionBlocker>
  );
}
