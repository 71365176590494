import Icon from "../../../../../assets/spaceicons/stat.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout, LayoutUnit } from "../../../layout/util";

import Config from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceStatComponent,
  makeInitialState,
  StatConfigState
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceStat from "./SpaceStat";
import { SpaceStatComponent, SpaceStatProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceStatProperties,
  SpaceStatComponent,
  StatConfigState
> = {
  type: "STAT",
  displayName: "Stat",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["FLEX_BOX", "SPACE"]),
  Component: SpaceStat,
  ConfigurationComponent: Config,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceStatComponent,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: LayoutUnit.AUTO,
    height: LayoutUnit.AUTO,
    snapToGrid: false,
    minWidth: "80px",
    minHeight: "124px"
  }),
  allowSelfBinding: true,
  allowAncestorBinding: true,
  isRepeatable: false,
  tags: [SpaceComponentTags.Content],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
