import React from "react";

export function Icon({
  type,
  className = "",
  style = {}
}: {
  type: MaterialIconType;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <span style={style} className={`material-icons ${className}`}>
      {type}
    </span>
  );
}

const ACTION_ICON_NAMES = [
  "search",
  "home",
  "account_circle",
  "settings",
  "done",
  "info",
  "check_circle",
  "delete",
  "shopping_cart",
  "visibility",
  "favorite",
  "logout",
  "description",
  "favorite_border",
  "lock",
  "schedule",
  "language",
  "face",
  "help_outline",
  "manage_accounts",
  "fingerprint",
  "filter_alt",
  "event",
  "verified",
  "thumb_up",
  "dashboard",
  "calendar_today",
  "login",
  "list",
  "visibility_off",
  "check_circle_outline",
  "highlight_off",
  "date_range",
  "help",
  "question_answer",
  "task_alt",
  "article",
  "paid",
  "lightbulb",
  "shopping_bag",
  "open_in_new",
  "perm_identity",
  "trending_up",
  "credit_card",
  "history",
  "account_balance",
  "delete_outline",
  "report_problem",
  "fact_check",
  "assignment",
  "verified_user",
  "arrow_right_alt",
  "star_rate",
  "account_balance_wallet",
  "work",
  "print",
  "autorenew",
  "analytics",
  "build",
  "view_list",
  "today",
  "store",
  "delete_forever",
  "admin_panel_settings",
  "savings",
  "room",
  "lock_open",
  "code",
  "grade",
  "receipt",
  "watch_later",
  "update",
  "add_shopping_cart",
  "contact_support",
  "power_settings_new",
  "pets",
  "done_all",
  "explore",
  "bookmark",
  "bookmark_border",
  "reorder",
  "note_add",
  "account_box",
  "shopping_basket",
  "pending_actions",
  "payment",
  "drag_indicator",
  "launch",
  "supervisor_account",
  "touch_app",
  "zoom_in",
  "pending",
  "assessment",
  "thumb_up_off_alt",
  "done_outline",
  "open_in_full",
  "leaderboard",
  "exit_to_app",
  "preview",
  "assignment_ind",
  "view_in_ar",
  "card_giftcard",
  "work_outline",
  "feedback",
  "timeline",
  "published_with_changes",
  "dns",
  "swap_horiz",
  "assignment_turned_in",
  "sync_alt",
  "book",
  "flight_takeoff",
  "stars",
  "pan_tool",
  "label",
  "android",
  "bug_report",
  "contact_page",
  "gavel",
  "alarm",
  "translate",
  "cached",
  "accessibility",
  "supervised_user_circle",
  "space_dashboard",
  "calendar_month",
  "edit_calendar",
  "extension",
  "get_app",
  "minimize",
  "record_voice_over",
  "add_task",
  "trending_flat",
  "hourglass_empty",
  "accessibility_new",
  "thumb_down",
  "flutter_dash",
  "help_center",
  "sticky_note_2",
  "rule",
  "support",
  "dashboard_customize",
  "source",
  "tips_and_updates",
  "find_in_page",
  "close_fullscreen",
  "settings_applications",
  "view_headline",
  "announcement",
  "redeem",
  "group_work",
  "loyalty",
  "swap_vert",
  "compare_arrows",
  "sensors",
  "restore",
  "euro_symbol",
  "dangerous",
  "nightlight_round",
  "privacy_tip",
  "ads_click",
  "grading",
  "subject",
  "track_changes",
  "copyright",
  "https",
  "table_view",
  "arrow_circle_up",
  "disabled_by_default",
  "toc",
  "bookmarks",
  "api",
  "query_builder",
  "book_online",
  "arrow_circle_right",
  "build_circle",
  "perm_media",
  "input",
  "zoom_out",
  "backup",
  "perm_contact_calendar",
  "3d_rotation",
  "open_with",
  "settings_phone",
  "circle_notifications",
  "view_module",
  "arrow_circle_down",
  "label_important",
  "speaker_notes",
  "card_membership",
  "swipe",
  "file_present",
  "perm_phone_msg",
  "wysiwyg",
  "pageview",
  "integration_instructions",
  "upgrade",
  "rocket_launch",
  "question_mark",
  "trending_down",
  "change_history",
  "g_translate",
  "accessible",
  "class",
  "settings_accessibility",
  "offline_bolt",
  "production_quantity_limits",
  "expand",
  "model_training",
  "calendar_view_month",
  "aspect_ratio",
  "donut_large",
  "settings_backup_restore",
  "segment",
  "maximize",
  "schedule_send",
  "bookmark_add",
  "view_column",
  "thumbs_up_down",
  "thumb_down_off_alt",
  "alarm_on",
  "settings_ethernet",
  "view_agenda",
  "theaters",
  "invert_colors",
  "important_devices",
  "arrow_circle_left",
  "unpublished",
  "open_in_browser",
  "opacity",
  "commute",
  "youtube_searched_for",
  "addchart",
  "no_accounts",
  "percent",
  "tour",
  "history_toggle_off",
  "view_week",
  "settings_input_antenna",
  "turned_in",
  "shop",
  "mark_as_unread",
  "system_update_alt",
  "bookmark_added",
  "hide_source",
  "flight_land",
  "search_off",
  "plagiarism",
  "contactless",
  "saved_search",
  "not_started",
  "assignment_late",
  "donut_small",
  "view_carousel",
  "highlight_alt",
  "anchor",
  "mediation",
  "settings_input_component",
  "turned_in_not",
  "flaky",
  "settings_remote",
  "fit_screen",
  "remove_shopping_cart",
  "toll",
  "edit_off",
  "assignment_return",
  "all_inbox",
  "view_quilt",
  "hourglass_full",
  "lock_clock",
  "settings_voice",
  "online_prediction",
  "pregnant_woman",
  "camera_enhance",
  "swap_horizontal_circle",
  "event_seat",
  "next_plan",
  "view_sidebar",
  "restore_from_trash",
  "markunread_mailbox",
  "accessible_forward",
  "tab",
  "calendar_view_week",
  "add_to_drive",
  "vertical_split",
  "dynamic_form",
  "currency_exchange",
  "hotel_class",
  "settings_power",
  "smart_button",
  "remove_done",
  "card_travel",
  "view_stream",
  "request_page",
  "try",
  "data_exploration",
  "offline_pin",
  "calendar_view_day",
  "rowing",
  "outbox",
  "find_replace",
  "outlet",
  "compress",
  "chrome_reader_mode",
  "alarm_add",
  "settings_brightness",
  "assignment_returned",
  "http",
  "filter_alt_off",
  "play_for_work",
  "spellcheck",
  "gif",
  "credit_card_off",
  "backup_table",
  "balance",
  "restore_page",
  "wifi_protected_setup",
  "settings_overscan",
  "token",
  "shopping_cart_checkout",
  "settings_input_composite",
  "quickreply",
  "comment_bank",
  "view_day",
  "polymer",
  "new_label",
  "swap_vertical_circle",
  "line_weight",
  "generating_tokens",
  "cancel_schedule_send",
  "send_and_archive",
  "outbound",
  "batch_prediction",
  "horizontal_split",
  "rocket",
  "fax",
  "picture_in_picture",
  "settings_bluetooth",
  "code_off",
  "bookmark_remove",
  "free_cancellation",
  "flip_to_front",
  "lock_reset",
  "shop_two",
  "eject",
  "speaker_notes_off",
  "gif_box",
  "perm_data_setting",
  "perm_scan_wifi",
  "switch_access_shortcut",
  "disabled_visible",
  "settings_cell",
  "settings_input_hdmi",
  "alarm_off",
  "hourglass_disabled",
  "app_blocking",
  "work_off",
  "sensors_off",
  "line_style",
  "perm_device_information",
  "density_medium",
  "subtitles_off",
  "picture_in_picture_alt",
  "terminal",
  "satellite_alt",
  "update_disabled",
  "view_timeline",
  "settings_input_svideo",
  "switch_access_shortcut_add",
  "voice_over_off",
  "view_array",
  "flip_to_back",
  "open_in_new_off",
  "add_card",
  "all_out",
  "label_off",
  "html",
  "rounded_corner",
  "webhook",
  "tab_unselected",
  "123",
  "text_rotate_vertical",
  "private_connectivity",
  "explore_off",
  "shop_2",
  "text_rotation_none",
  "assured_workload",
  "output",
  "swipe_left",
  "not_accessible",
  "display_settings",
  "pin_invoke",
  "perm_camera_mic",
  "app_shortcut",
  "event_repeat",
  "extension_off",
  "join_full",
  "javascript",
  "swipe_right",
  "install_desktop",
  "pin_end",
  "swipe_up",
  "text_rotate_up",
  "abc",
  "text_rotation_down",
  "text_rotation_angledown",
  "text_rotation_angleup",
  "pan_tool_alt",
  "join_inner",
  "density_small",
  "install_mobile",
  "noise_control_off",
  "data_thresholding",
  "swipe_down",
  "commit",
  "join_left",
  "css",
  "view_kanban",
  "pinch",
  "swipe_vertical",
  "view_cozy",
  "manage_history",
  "lightbulb_circle",
  "join_right",
  "swipe_right_alt",
  "view_comfy_alt",
  "view_compact_alt",
  "work_history",
  "swipe_down_alt",
  "php",
  "swipe_up_alt",
  "noise_aware",
  "swipe_left_alt",
  "browse_gallery",
  "density_large",
  "network_ping",
  "spatial_audio_off",
  "hls",
  "hls_off",
  "spatial_tracking",
  "spatial_audio"
];

const SOCIAL_ICONS = [
  "person",
  "notifications",
  "groups",
  "people",
  "share",
  "person_outline",
  "school",
  "person_add",
  "public",
  "emoji_events",
  "group",
  "notifications_active",
  "engineering",
  "construction"
];

const CONTENT_ICONS = [
  "add",
  "add_circle_outline",
  "add_circle",
  "content_copy",
  "send",
  "clear",
  "mail",
  "save",
  "link",
  "remove",
  "filter_list",
  "inventory_2",
  "insights",
  "sort",
  "remove_circle_outline",
  "inventory",
  "bolt",
  "flag",
  "reply",
  "add_box",
  "create",
  "remove_circle",
  "push_pin",
  "block",
  "calculate",
  "undo",
  "how_to_reg",
  "content_paste",
  "report",
  "file_copy",
  "shield",
  "backspace",
  "archive",
  "save_alt",
  "policy",
  "tag",
  "change_circle",
  "redo",
  "content_cut",
  "forward",
  "inbox",
  "outlined_flag",
  "drafts",
  "biotech",
  "link_off",
  "ballot",
  "stacked_bar_chart",
  "markunread",
  "delete_sweep",
  "report_gmailerrorred",
  "square_foot",
  "stream",
  "where_to_vote",
  "add_link",
  "copy_all",
  "move_to_inbox",
  "waves",
  "dynamic_feed",
  "unarchive",
  "reply_all",
  "select_all",
  "low_priority",
  "text_format",
  "weekend",
  "font_download",
  "upcoming",
  "gesture",
  "how_to_vote",
  "attribution",
  "content_paste_search",
  "save_as",
  "next_week",
  "content_paste_go",
  "content_paste_off",
  "filter_list_off",
  "report_off",
  "flag_circle",
  "font_download_off",
  "deselect"
];

const MAP_ICONS = [
  "local_shipping",
  "place",
  "menu_book",
  "local_offer",
  "map",
  "badge",
  "category",
  "restaurant",
  "directions_car",
  "volunteer_activism",
  "local_fire_department",
  "my_location",
  "local_mall",
  "flight",
  "near_me",
  "handyman",
  "directions_run",
  "restaurant_menu",
  "layers",
  "medical_services",
  "lunch_dining",
  "park",
  "local_hospital",
  "directions_walk",
  "celebration",
  "pin_drop",
  "local_library",
  "local_atm",
  "local_activity",
  "local_cafe",
  "delivery_dining",
  "rate_review",
  "person_pin",
  "design_services",
  "directions_bike",
  "fastfood",
  "directions_bus",
  "local_police",
  "directions_car_filled",
  "local_phone",
  "home_repair_service",
  "zoom_out_map",
  "local_grocery_store",
  "miscellaneous_services",
  "hotel",
  "cleaning_services",
  "person_pin_circle",
  "navigation",
  "local_florist",
  "local_gas_station",
  "directions",
  "local_parking",
  "train",
  "money",
  "local_post_office",
  "two_wheeler",
  "traffic",
  "electrical_services",
  "alt_route",
  "local_bar",
  "pedal_bike",
  "directions_boat",
  "agriculture",
  "add_business",
  "360",
  "beenhere",
  "liquor",
  "moving",
  "diamond",
  "local_airport",
  "add_location_alt",
  "maps_ugc",
  "sailing",
  "local_dining",
  "route",
  "ramen_dining",
  "local_taxi",
  "hail",
  "local_drink",
  "local_printshop",
  "local_pizza",
  "theater_comedy",
  "trip_origin",
  "local_laundry_service",
  "not_listed_location",
  "add_location",
  "dinner_dining",
  "bakery_dining",
  "directions_bus_filled",
  "transfer_within_a_station",
  "wine_bar",
  "multiple_stop",
  "terrain",
  "takeout_dining",
  "icecream",
  "emergency",
  "local_pharmacy",
  "store_mall_directory",
  "museum",
  "nightlife",
  "factory",
  "add_road",
  "set_meal",
  "local_see",
  "departure_board"
];

export type MaterialIconType = typeof ICON_NAMES[number];
export const ICON_NAMES: Readonly<string[]> = ACTION_ICON_NAMES.concat(SOCIAL_ICONS)
  .concat(CONTENT_ICONS)
  .concat(MAP_ICONS);
