import styled from "styled-components";

import { colorTokens } from "../../../../../cssConstants";

export const Dot = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;

  &:hover,
  &.isFocused {
    background-color: ${colorTokens.purpleTransparent400};
    color: white;
  }

  &.isSelected {
    background-color: ${colorTokens.purple700};
    color: white;
  }
`;
