import React from "react";

import { useEnvironmentContext } from "../../../../common/contexts/EnvironmentContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

export default function SpaceEnvironment(_props: Props) {
  const { getCurrentEnvironment } = useEnvironmentContext();
  const { updateOutput } = useComponentStateContext();
  const env = getCurrentEnvironment();
  React.useEffect(() => {
    const { name, slug } = env;
    updateOutput({ current: { name, slug } });
  }, [env, updateOutput]);
  return null;
}
