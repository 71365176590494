import styled from "styled-components";

import { StateList as StateListComponent } from "./StateList";

export const Divider = styled.hr`
  margin: 0 0 21px 40px;
  background-color: ${props => props.theme.containerPrimaryTint};
`;

export const StateList = styled(StateListComponent)``;
