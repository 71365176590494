import { useMemo, useReducer, Dispatch } from "react";

export interface SpaceConsoleClientError {
  code: number;
  message: string;
  errorInfo: {
    reason: string;
    domain: string;
    metadata: any;
  };
  when: string;
}

export interface SpaceConsoleState {
  errors: SpaceConsoleClientError[];
  visible: boolean;
}

interface AddClientError {
  type: "ADD_CLIENT_ERROR";
  payload: { error: SpaceConsoleClientError };
}

interface SetConsoleVisible {
  type: "SET_CONSOLE_VISIBLE";
  payload: { visible: boolean };
}

export type SpaceConsoleStateActions = AddClientError | SetConsoleVisible;

export type SpaceConsoleStateDispatch = Dispatch<SpaceConsoleStateActions>;
export type Result = {
  state: SpaceConsoleState;
  dispatch: SpaceConsoleStateDispatch;
};

export const INITIAL_STATE = {
  errors: [],
  visible: false
};

function reducer(
  state: SpaceConsoleState,
  action: SpaceConsoleStateActions
): SpaceConsoleState {
  switch (action.type) {
    case "ADD_CLIENT_ERROR": {
      const { error } = action.payload;
      return {
        ...state,
        errors: [...state.errors, error]
      };
    }
    case "SET_CONSOLE_VISIBLE": {
      const { visible } = action.payload;
      return {
        ...state,
        visible: visible
      };
    }

    default:
      throw new Error("Unknown action in useSpaceConsoleState reducer.");
  }
}

export default function useSpaceConsole(): Result {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const result = useMemo(
    () => ({
      state,
      dispatch
    }),
    [state, dispatch]
  );
  return result;
}
