import React from "react";

import { Checkbox } from "antd";
import { isEqual } from "lodash";
import styled from "styled-components";

import DarkThemeModal from "../../../../common/DarkThemeModal";
import usePrevious from "../../../../common/hooks/usePrevious";

import useEnvironmentsToUnpublish from "./useEnvironmentsToUnpublish";

const DivWithLeftMargin = styled.div`
  margin-left: ${props => props.theme.spacermd};
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.textColorMid};
  padding-bottom: ${props => props.theme.spacermd};

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
    margin-top: ${props => props.theme.spacerlg};
  }
`;

const FixedHeightWithScroll = styled.div`
  max-height: 156px;
  overflow-y: scroll;
`;

const Error = styled.p`
  color: ${props => props.theme.errorColor};
  margin-bottom: 0px;
`;

interface Props {
  modalVisible: boolean;
  onClose: () => void;
}

export default function UnpublishModal({ modalVisible, onClose }: Props) {
  const lastVisible = usePrevious(modalVisible);
  const [error, setError] = React.useState("");
  const [selectedEnvironments, setSelectedEnvironments] = React.useState<string[]>([]);
  const { publishedEnvironments, unpublish, mutationLoading } =
    useEnvironmentsToUnpublish(() => onClose());

  React.useEffect(() => {
    // Should run when only once when the modal opens
    if (!modalVisible || !publishedEnvironments || lastVisible) return;
    const nextSelectedEnvironments =
      publishedEnvironments.length === 1 ? [publishedEnvironments[0].id] : [];
    if (!isEqual(selectedEnvironments, nextSelectedEnvironments)) {
      setSelectedEnvironments(nextSelectedEnvironments);
    }
  }, [modalVisible, publishedEnvironments, selectedEnvironments, lastVisible]);

  const content = React.useMemo(() => {
    if (!publishedEnvironments || publishedEnvironments.length === 0) {
      return <p>This space has not been published yet.</p>;
    } else if (publishedEnvironments?.length === 1) {
      return (
        <p>
          {`Are you sure you want to unpublish this Space from your ${publishedEnvironments[0].name} environment? `}
        </p>
      );
    } else {
      return (
        <>
          <FixedHeightWithScroll>
            <p>Select one or more environments to unpublish.</p>
            <DivWithLeftMargin>
              {publishedEnvironments.map(e => (
                <Checkbox
                  key={e.id}
                  onChange={evt => {
                    if (evt.target.checked) {
                      setError("");
                      setSelectedEnvironments([...selectedEnvironments, e.id]);
                    } else {
                      setSelectedEnvironments(
                        selectedEnvironments.filter(envId => envId !== e.id)
                      );
                    }
                  }}
                  checked={selectedEnvironments.includes(e.id)}
                >
                  {e.name}
                </Checkbox>
              ))}
            </DivWithLeftMargin>
          </FixedHeightWithScroll>
          {error && <Error>{error}</Error>}
        </>
      );
    }
  }, [publishedEnvironments, selectedEnvironments, setSelectedEnvironments, error]);

  return (
    <>
      {modalVisible && (
        <DarkThemeModal
          width={364}
          onOk={() => {
            if (selectedEnvironments.length === 0) {
              setError("Please select at least one environment.");
              return;
            }
            unpublish(selectedEnvironments);
          }}
          okButtonProps={{
            loading: mutationLoading,
            style: {
              display: publishedEnvironments.length === 0 ? "none" : "inline"
            }
          }}
          visible
          content={content}
          okText="Unpublish"
          title="Unpublish This Space"
          onCancel={onClose}
        ></DarkThemeModal>
      )}
    </>
  );
}
