import React from "react";

import { AttributeValueBaseProps, AttributeValueProps } from "..";

export function StringValueBase({ className = "", value }: AttributeValueBaseProps) {
  return <div className={className}>{String(value)}</div>;
}

export default function StringValue({
  className = "",
  attribute,
  value
}: AttributeValueProps) {
  // kind of a hack for Zendesk, remove when URL templating is done
  if (String(value).includes(".zendesk.com") && attribute.sourceName === "url") {
    const formattedLink = String(value).replace("/api/v2", "").replace(".json", "");
    return (
      <a target="_blank" href={formattedLink} rel="noopener noreferrer">
        {formattedLink}
      </a>
    );
  } else {
    return <StringValueBase className={className} value={value} />;
  }
}
