import React, { Fragment, useState } from "react";

import { Select, Skeleton, Typography } from "antd";
import styled from "styled-components";

import { SyncNode } from "../../../../graphql/sync";
import { DataSource } from "../../DataSourceTable";
import SyncModal from "../../SyncModal";
import { SubHead } from "../DataSourceSettingsPanel/styledComponents";
import { Panel } from "../styledComponents";

import { DataSourceSyncHistoryPanel } from "./DataSourceSyncHistoryPanel";
import { DataSourceSyncPanelHeader } from "./DataSourceSyncPanelHeader";

const { Text } = Typography;
const { Option } = Select;

const AutoSyncSettings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${props => props.theme.spacermd};

  > * {
    margin-right: ${props => props.theme.spacersm};
  }
`;

export interface DataSourceSyncPanelProps {
  loading: boolean;
  dataSource: DataSource;
}

export const DataSourceSyncPanel = ({
  dataSource,
  loading
}: DataSourceSyncPanelProps) => {
  const [isSyncModalVisible, setIsSyncModalVisible] = useState(false);
  const [isSyncPlanLoading, setIsSyncPlanLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [syncPlan, setSyncPlan] = useState<SyncNode>();

  // This is the state of the data source (ie. source of truth).  We keep this here so we can adjust it upon a successful
  // save to update the auto sync indicator appropriately.  Subsequent refreshes will obtain the correct data from the query.
  const [isAutoSyncEnabled, setIsAutoSyncEnabled] = useState(
    dataSource.autoSyncEnabled
  );
  // This is the value selected in the UI, does not become the auto sync state until a save has completed.
  const [autoSyncToggle, setAutoSyncToggle] = useState(
    isAutoSyncEnabled ? "automatic" : "manual"
  );

  const onSyncCancel = () => {
    setIsSyncModalVisible(false);
  };

  const onSyncError = () => {
    setIsSyncModalVisible(true);
  };

  const onSyncSuccess = () => {
    setIsSyncModalVisible(false);
  };

  return (
    <Panel>
      {loading ? (
        <Skeleton active />
      ) : (
        <Fragment>
          <DataSourceSyncPanelHeader
            dataSourceId={dataSource.id}
            lastSyncTime={dataSource.synchronizedAt}
            onCloseButtonClick={() => setIsExpanded(false)}
            onSaveComplete={(autoSyncEnabled: boolean) => {
              setIsExpanded(false);
              setIsAutoSyncEnabled(autoSyncEnabled);
            }}
            onSyncStart={() => {
              setIsSyncPlanLoading(true);
              setIsSyncModalVisible(true);
            }}
            onSyncComplete={data => {
              setSyncPlan(data.syncPlan.sync);
              setIsSyncPlanLoading(false);
            }}
            onViewButtonClick={() => setIsExpanded(true)}
            showViewButton={!isExpanded}
            autoSyncToggle={autoSyncToggle === "automatic"}
            autoSyncEnabled={isAutoSyncEnabled}
          />
          <div hidden={!isExpanded}>
            {dataSource.supportsSync && (
              <AutoSyncSettings>
                <SubHead>Sync Type:</SubHead>
                <Select
                  defaultValue={autoSyncToggle.toString()}
                  onChange={(val: string): void => setAutoSyncToggle(val)}
                >
                  <Option value={"automatic"}>Automatic</Option>
                  <Option value={"manual"}>Manual</Option>
                </Select>
                {autoSyncToggle === "automatic" && (
                  <Text type="secondary">Next Update 3:00am PST</Text>
                )}
              </AutoSyncSettings>
            )}
            <DataSourceSyncHistoryPanel dataSourceId={dataSource.id} />
          </div>
          <SyncModal
            visible={isSyncModalVisible}
            loading={isSyncPlanLoading}
            dataSource={dataSource}
            sync={syncPlan}
            onSuccess={onSyncSuccess}
            onError={onSyncError}
            onCancel={onSyncCancel}
          />
        </Fragment>
      )}
    </Panel>
  );
};
