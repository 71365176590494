import React from "react";

import { Menu, Skeleton } from "antd";

import { EnvironmentDataSourceCredentialNode } from "../../../../types";
import { CredentialsUpdateForms } from "../../../common/Adapters";
import ConfirmationModal from "../../../common/ConfirmationModal";
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import { DataSource } from "../../DataSourceTable";
import ManageEnvironmentsModal from "../../environments/ManageEnvironmentsModal";
import { LegacySettingsPanel } from "../DataSourceSettingsPanel/LegacySettingsPanel";

import CredentialPanel from "./CredentialPanel";
import reducer, { initialState } from "./reducer";
import * as styledComp from "./styledComponents";
import useManageCredentialsData from "./useManageCredentialsData";

interface DataSourceManageCredentialProps {
  dataSource: DataSource;
  onClose: () => void;
}

export default function DataSourceManageCredentials({
  dataSource,
  onClose
}: DataSourceManageCredentialProps) {
  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState<
    EnvironmentDataSourceCredentialNode | undefined
  >();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    allEnvironments,
    allEnvironmentDataSourceCredentials,
    deleteCredentials,
    loading,
    deleteLoading
  } = useManageCredentialsData(dataSource);

  const node = React.useRef<HTMLDivElement>(null); // used for popper to determine outside click
  useOutsideClick(node, () =>
    dispatch({ type: "SET_POPOVER", payload: { popoverOpen: false } })
  );

  const environmentsRemaining = React.useMemo(() => {
    if (!allEnvironments) return [];
    const configuredEnvIds = new Set(
      state.environmentCredentials.map(e => e.environment.id)
    );
    return allEnvironments.edges
      .map(e => e.node)
      .filter(env => !configuredEnvIds.has(env.id));
  }, [allEnvironments, state.environmentCredentials]);

  React.useEffect(() => {
    if (!state.popoverOpen) return;
    node?.current?.scrollIntoView({ behavior: "smooth" });
  }, [state.popoverOpen]);

  React.useMemo(() => {
    if (!allEnvironmentDataSourceCredentials) return;
    dispatch({
      type: "INITIALIZE_CREDENTIALS",
      payload: {
        credentials: allEnvironmentDataSourceCredentials.edges.map(e => e.node)
      }
    });
  }, [allEnvironmentDataSourceCredentials]);

  const isQueues = dataSource.integration === "queues";
  const removeCredentials = React.useCallback(
    async (env: EnvironmentDataSourceCredentialNode) => {
      if (
        !isQueues &&
        (!env.credentials || Object.keys(env.credentials).length === 0)
      ) {
        dispatch({
          type: "REMOVE_CREDENTIAL",
          payload: { id: env.id }
        });
      } else {
        setConfirmDeleteModal(env);
      }
    },
    [isQueues, dispatch, setConfirmDeleteModal]
  );

  if (!CredentialsUpdateForms[dataSource.integration] && !isQueues) {
    return (
      <>
        <LegacySettingsPanel dataSource={dataSource} />
        <styledComp.WithTopMargin>
          <styledComp.DangerThemeLink
            buttonProps={{ type: "link" }}
            dataSource={dataSource}
            onDelete={onClose}
          />
        </styledComp.WithTopMargin>
      </>
    );
  }

  return (
    <>
      <div>
        {loading && <Skeleton active />}
        {!loading &&
          state.environmentCredentials.map(env => (
            <CredentialPanel
              dataSource={dataSource}
              key={env.id}
              visible={env.id === state.openCredentialPanelId}
              hideSave={isQueues}
              removeCredentials={() => removeCredentials(env)}
              credentials={env.credentials}
              environment={env.environment}
            />
          ))}
        <styledComp.FlexRow>
          <span>
            <styledComp.AddNewButton
              onClick={() =>
                dispatch({
                  type: "SET_POPOVER",
                  payload: { popoverOpen: true }
                })
              }
              type="link"
            >
              + Add new
            </styledComp.AddNewButton>
            {state.popoverOpen && (
              <styledComp.MenuContainer ref={node}>
                <Menu>
                  {environmentsRemaining.length === 0 && (
                    <styledComp.Item disabled>
                      No environments available
                    </styledComp.Item>
                  )}
                  {environmentsRemaining.map(env => (
                    <styledComp.Item
                      key={env.id}
                      onClick={() =>
                        dispatch({
                          type: "ADD_CREDENTIAL",
                          payload: { environment: env }
                        })
                      }
                    >
                      {env.name} Environment
                    </styledComp.Item>
                  ))}
                  <styledComp.Item
                    onClick={() =>
                      dispatch({ type: "SET_MODAL", payload: { isOpen: true } })
                    }
                    key="manage"
                  >
                    Manage Environments
                  </styledComp.Item>
                </Menu>
              </styledComp.MenuContainer>
            )}
          </span>
          <styledComp.DangerThemeLink
            buttonProps={{ type: "link" }}
            dataSource={dataSource}
            onDelete={onClose}
          />
        </styledComp.FlexRow>
        {state.modalOpen && (
          <ManageEnvironmentsModal
            onClose={() => dispatch({ type: "SET_MODAL", payload: { isOpen: false } })}
          />
        )}
      </div>
      {confirmDeleteModal && (
        <ConfirmationModal
          data-test="deleteConfirmationModal"
          visible
          onClose={() => setConfirmDeleteModal(undefined)}
          modalOkText="Delete"
          title="Delete this environment"
          confirmationValueIsValid={(p: string) =>
            p === confirmDeleteModal.environment.name
          }
          confirmLoading={deleteLoading}
          onSubmit={async () => {
            await deleteCredentials(confirmDeleteModal?.id);
            setConfirmDeleteModal(undefined);
          }}
          okButtonProps={{ type: "danger" }}
          modalContent={
            <>
              <p>
                This action <strong>cannot</strong> be undone. This will permanently
                delete the <strong>{confirmDeleteModal.environment.name}</strong>{" "}
                environment from this data source.
              </p>

              <p>
                Please type <strong>{confirmDeleteModal.environment.name}</strong> to
                confirm.
              </p>
            </>
          }
        />
      )}
    </>
  );
}
