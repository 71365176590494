import React from "react";

import { Modal, Input as AntInput, Progress as AntProgress, Icon } from "antd";
import ReactS3Uploader from "react-s3-uploader";
import styled from "styled-components";

import { colors } from "../../../../../cssConstants";
import BaseButton from "../../../../common/Button";
import ClickEventCapturer from "../../../../common/ClickEventCapturer";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import Message from "../../../../common/Message";
import { useComponentLayoutContext } from "../../../layout/LayoutContext/LayoutContext";
import { useStableSpaceContext } from "../../SpaceContext";
import Button from "../common/Button";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import { Props as BaseProps } from "../SpaceComponent";

import useGetPutPresignedUrl from "./useGetPutPresignedUrl";

const Root = styled.div``;

const Input = styled(AntInput)`
  margin-bottom: ${props => props.theme.spacermd};
`;

const Progress = styled(AntProgress)`
  color: ${props => props.theme.primaryColor};
`;

const EditModeContainer = styled.div`
  pointer-events: none;
  cursor: default;

  button:disabled {
    color: white;
    background-color: ${props => props.theme.primaryColor};
  }
`;
EditModeContainer.displayName = "EditModeContainer";

const Container = styled.div`
  .custom-file-input::-webkit-file-upload-button {
    display: none;
  }
  .custom-file-input {
    display: none;
  }

  .visualUploader {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.spacermd};

    button {
      &:hover,
      &:focus,
      &:active {
        border-color: ${props => props.theme.primaryColor};
        color: ${props => props.theme.primaryColor};
      }
    }

    p {
      margin-left: ${props => props.theme.spacersm};
      margin-bottom: 0px;
      color: ${props => props.theme.textColorMid};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Uploader = styled(ReactS3Uploader)``;
Uploader.displayName = "Uploader";

export default function SpaceS3Uploader({
  spaceComponent,
  spaceApi,
  hasConfigError
}: BaseProps) {
  let inputRef = React.useRef<HTMLInputElement>(null);
  const uploaderRef = React.useRef<ReactS3Uploader>(null);

  const [showModal, setShowModal] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [uploadedFileName, setUploadedFileName] = React.useState("");
  const [percent, setPercent] = React.useState<undefined | number>(undefined);

  const { name, properties } = spaceComponent;
  const { spaceId, editMode } = useStableSpaceContext();
  const { getPresignedUrl } = useGetPutPresignedUrl(
    spaceComponent,
    spaceId,
    properties.bucket_name
  );

  const { triggerEffects } = useSubmittableEffects(spaceApi);

  const onClose = () => {
    setFileName("");
    setPercent(0);
    setUploadedFileName("");
    // HACK: clear() method not on type definition for ReactS3Uploader so cast to `any`
    const uploaderEl: any = uploaderRef?.current;
    if (uploaderEl !== null) uploaderEl.clear();
    setShowModal(false);
  };

  function getSignedUrl(file: File, callback: (p: any) => void) {
    getPresignedUrl!(fileName || file.name, callback, err => {
      if (err.name === "PermissionError") {
        Message.error("You do not have permission to upload files to S3");
      }
    });
  }

  const { layout } = useComponentLayoutContext();

  if (editMode) {
    return (
      <Root className="spaceButtonRoot">
        <EditModeContainer>
          {hasConfigError && <AbsoluteErrorIcon />}
          <Button type="primary" disabled layout={layout}>
            {properties.open_uploader_button_text}
          </Button>
        </EditModeContainer>
      </Root>
    );
  }
  const loading = !!percent && percent !== 100;

  return (
    <Root className="spaceButtonRoot">
      <div>
        <Button
          data-test="openUploader"
          type="primary"
          disabled={!getPresignedUrl}
          layout={layout}
          onClick={() => setShowModal(true)}
        >
          {properties.open_uploader_button_text}
        </Button>
      </div>
      {showModal && (
        <ClickEventCapturer>
          <Modal
            title={name || "Uploader"}
            visible
            okText="Upload"
            okButtonProps={{ loading }}
            onOk={() => (uploaderRef?.current as any).uploadFile()}
            onCancel={onClose}
            destroyOnClose
          >
            <Container>
              <div className="visualUploader">
                <BaseButton
                  disabled={loading}
                  onClick={() => {
                    inputRef?.current?.click();
                  }}
                >
                  <Icon type="upload" />
                  Choose a file
                </BaseButton>
                {!!uploadedFileName && <p>{uploadedFileName}</p>}
              </div>
              <Uploader
                ref={uploaderRef}
                onChange={() => {
                  if (inputRef?.current?.files?.length) {
                    const name = inputRef!.current!.files![0].name;
                    setUploadedFileName(name);
                    setFileName(name);
                  }
                }}
                inputRef={(cmp: any) => (inputRef = { current: cmp })}
                className="custom-file-input"
                getSignedUrl={getSignedUrl}
                uploadRequestHeaders={{}}
                onProgress={(p: number) => setPercent(p)}
                onError={(error: Error) => {
                  triggerEffects({
                    type: SubmittableEventType.SUBMIT_FAILURE,
                    error
                  });
                  onClose();
                }}
                onFinish={() => {
                  triggerEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
                  onClose();
                }}
                signingUrlWithCredentials
                autoUpload={false}
              />
              {(percent || 0) > 0 && (
                <Progress strokeColor={colors.primaryColor} percent={percent} />
              )}
              <p>Name your file:</p>
              <Input
                value={fileName}
                onChange={e => setFileName(e.target.value)}
                placeholder="example.txt"
              />
            </Container>
          </Modal>
        </ClickEventCapturer>
      )}
    </Root>
  );
}
