import { Button } from "antd";
import styled from "styled-components";

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: ${props => props.theme.spacersm};
  grid-row-gap: ${props => props.theme.spacersm};
`;

export const Card = styled.div`
  position: relative;
  padding: ${props => props.theme.spacermd};
  border: solid 1px ${props => props.theme.borderGrey};
  border-radius: ${props => props.theme.borderRadiuslg};
`;
Card.displayName = "Card";

export const Header = styled.h2`
  font-size: ${props => props.theme.largeFontSize};
  font-family: var(--body-font-family);
  word-break: break-word;
`;

export const Attribute = styled.div`
  margin: ${props => props.theme.spacermd} 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
Attribute.displayName = "CardList.Attribute";

export const AttributeName = styled.div`
  font-size: 11px;
  color: ${props => props.theme.textColorMid};
  text-transform: uppercase;
`;

export const AttributeNameWithMargin = styled(AttributeName)`
  margin-bottom: ${props => props.theme.spacerxs};
`;

export const MoreButton = styled(Button)`
  margin-top: ${props => props.theme.spacermd};
`;
