import produce from "immer";
import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject, SourceType } from "../../../../../../../types";
import commonComponentReducer from "../../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../../types";
import filterListManagerReducer, {
  INITIAL_STATE as FILTER_LIST_MANAGER_INITIAL_STATE,
  FilterListManagerState,
  getInitialFilterState
} from "../../../common/FilterListManager/reducer/reducer";
import parameterConfigReducer, {
  INITIAL_STATE as PARAMETER_CONFIG_INITIAL_STATE,
  getInitialParameterState,
  ParameterConfigState
} from "../../../common/ParametersManager/reducer/reducer";
import viewDataConfigReducer, {
  INITIAL_STATE as VIEW_DATA_CONFIG_INITIAL_STATE,
  ViewConfigState
} from "../../../common/useViewConfig/reducer";
import { functionTitleToComponentName } from "../../../common/util";
import { SpaceDetailComponent } from "../../types";

export interface DetailConfigState
  extends BaseComponentConfigState<SpaceDetailComponent>,
    ViewConfigState,
    FilterListManagerState,
    ParameterConfigState {
  type: "DETAIL";
}

export const INITIAL_STATE = {
  type: "DETAIL" as const,
  draftComponent: {} as SpaceDetailComponent,
  ...VIEW_DATA_CONFIG_INITIAL_STATE,
  ...FILTER_LIST_MANAGER_INITIAL_STATE,
  ...PARAMETER_CONFIG_INITIAL_STATE
};

export function ensureSpaceDetailComponent(
  component: SpaceComponentObject
): SpaceDetailComponent {
  if (
    Array.isArray(component.properties.columns) &&
    Array.isArray(component.properties.filters) &&
    Array.isArray(component.properties.order) &&
    Array.isArray(component.properties.input_parameters)
  ) {
    return component;
  }
  return {
    ...component,
    sourceType: component.sourceType || SourceType.VIEW,
    properties: {
      ...component.properties,
      columns: component.properties.columns || [],
      filters: component.properties.filters || [],
      is_header_enabled:
        typeof component.properties.is_header_enabled === "boolean"
          ? component.properties.is_header_enabled
          : true,
      is_filter_required:
        typeof component.properties.is_filter_required === "boolean"
          ? component.properties.is_filter_required
          : false,
      input_parameters: component.properties.input_parameters || [],
      title: component.properties.title || ""
    }
  };
}

function isDetailConfigState(state: ComponentConfigState): state is DetailConfigState {
  return state.type === "DETAIL";
}

export function ensureDetailConfigState(
  state: ComponentConfigState
): DetailConfigState {
  if (isDetailConfigState(state)) return state;

  throw new Error("Expected detail config state.");
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): DetailConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceDetailComponent(cloneDeep(draftComponent)),
    ...getInitialFilterState(draftComponent),
    ...getInitialParameterState(draftComponent)
  };
}

function reducer(
  state: DetailConfigState,
  action: SpaceConfigAction
): DetailConfigState {
  switch (action.type) {
    case "LOAD_FUNCTION": {
      if (state.draftComponent.name) return state;
      return produce(state, (draftState: DetailConfigState) => {
        const humanized = functionTitleToComponentName(action.payload.title);
        draftState.draftComponent.name = `${humanized} detail`;
        draftState.draftComponent.properties.title = `${humanized} detail`;
      });
    }
    default:
      return state;
  }
}

export default (state: DetailConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(viewDataConfigReducer, partial.placeholder, action),
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(filterListManagerReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
