import React from "react";

import {
  SpaceComponentType,
  SpaceComponentObject,
  SpaceComponentPackage
} from "../../../../../../types";
import { useSpaceContext, useStableSpaceContext } from "../../../SpaceContext";
import { FORM_COMPONENT_TYPES } from "../../constants";
import { useComponentContext } from "../../contexts/ComponentContext";

const RENDER_DEFERRING_COMPONENTS: SpaceComponentType[] = ["FUNCTION_MODAL_FORM"];

const CHILD_MANAGING_COMPONENTS: SpaceComponentType[] = [
  "TABLE",
  "CARD_LIST",
  "DETAIL",
  "FUNCTION_FORM",
  "FUNCTION_MODAL_FORM"
];

function isComponentRepeated(
  ancestorComponents: SpaceComponentObject[],
  collectionComponents: SpaceComponentType[]
) {
  const ancestors = [...ancestorComponents];
  while (ancestors.length) {
    const node = ancestors.pop();
    if (node === undefined) {
      throw new Error("Expected ancestor node to be defined.");
    } else if (RENDER_DEFERRING_COMPONENTS.includes(node.type)) {
      return false;
    } else if (collectionComponents.includes(node.type)) {
      return true;
    }
  }
  return false;
}

export function getIsManaged(
  ancestorComponents: SpaceComponentObject[],
  packages: SpaceComponentPackage[]
) {
  const ancestors = [...ancestorComponents];
  // Ancestor list is inclusive of the current component. It should
  // be ignored since its not managed by itself.
  const currentComponent = ancestors.pop();

  // ancestorComponents skips over superficial components, which includes HEADER,
  // so check for it explicitly
  if (currentComponent?.container?.type === "HEADER") {
    return true;
  }

  // otherwise traverse up ancestor chain
  while (ancestors.length) {
    const node = ancestors.pop();
    if (node === undefined) {
      throw new Error("Expected ancestor node to be defined.");
    } else if (CHILD_MANAGING_COMPONENTS.includes(node.type)) {
      return true;
    } else if (packages.find(p => p.type === node.type)?.isContainer) {
      return false;
    }
  }
  return false;
}

export function getIsManager(component: SpaceComponentObject) {
  return CHILD_MANAGING_COMPONENTS.includes(component.type);
}

function getIsFormItem(ancestorComponents: SpaceComponentObject[]) {
  const ancestors = [...ancestorComponents];
  while (ancestors.length) {
    const node = ancestors.pop();
    if (node === undefined) {
      throw new Error("Expected ancestor node to be defined.");
    } else if (FORM_COMPONENT_TYPES.includes(node.type)) {
      return true;
    }
  }
  return false;
}

function getIsInline(
  ancestorComponents: SpaceComponentObject[],
  inlineParentComponents: SpaceComponentType[]
) {
  const ancestors = [...ancestorComponents];
  while (ancestors.length) {
    const node = ancestors.pop();
    if (node === undefined) {
      throw new Error("Expected ancestor node to be defined.");
    } else if (RENDER_DEFERRING_COMPONENTS.includes(node.type)) {
      return false;
    } else if (inlineParentComponents.includes(node.type)) {
      return true;
    }
  }
  return false;
}

export default function useNestedStatus() {
  const { getAncestorComponents } = useSpaceContext();
  const {
    component: { slug }
  } = useComponentContext();
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const packages = getSpaceComponentPackages();

  const ancestorComponents = React.useMemo(
    () => getAncestorComponents(slug),
    [slug, getAncestorComponents]
  );
  const isNested = ancestorComponents.length > 1;

  const collectionComponents = React.useMemo(
    () => packages.filter(p => p.isCollection).map(p => p.type),
    [packages]
  );
  const inlineParentComponents = React.useMemo(
    () => packages.filter(p => p.hasInlineChildren).map(p => p.type),
    [packages]
  );

  return React.useMemo(
    () => ({
      isNested,
      isRepeated: isComponentRepeated(ancestorComponents, collectionComponents),
      isFormItem: getIsFormItem(ancestorComponents),
      isManaged: getIsManaged(ancestorComponents, packages),
      isInline: getIsInline(ancestorComponents, inlineParentComponents)
    }),
    [
      isNested,
      ancestorComponents,
      collectionComponents,
      inlineParentComponents,
      packages
    ]
  );
}
