import React from "react";

import { SpaceComponentObject, SpaceFunctionType } from "../../../../../types";
import { createSpaceFunction } from "../../../FunctionExecutor/FunctionExecutor";
import { useStableSpaceContext } from "../../SpaceContext";
import useSpaceFunction from "../common/useSpaceFunction/useSpaceFunction";

export interface UseGetPresignedUrlsResult {
  getPresignedUrl?: (
    filename: string,
    cb: (p: any) => void,
    ecb: (err: any) => void
  ) => void;
}
export default function useGetPutPresignedUrl(
  component: SpaceComponentObject,
  spaceId: string,
  bucket: string
): UseGetPresignedUrlsResult {
  const { editMode } = useStableSpaceContext();
  const func = React.useMemo(() => createSpaceFunction(component), [component]);
  if (editMode === false && func.type === SpaceFunctionType.INVALID)
    throw new Error("Presign function is not valid.");
  const { executeFunction } = useSpaceFunction(func, [], spaceId);
  return {
    getPresignedUrl: (
      filename: string,
      callback: (p: any) => void,
      errorCallback: (err: any) => void
    ) => {
      executeFunction({
        bucket,
        key: filename
      }).then((res: any) => {
        if (res.name === "PermissionError") {
          errorCallback(res);
        } else {
          callback({ signedUrl: res.value });
        }
      });
    }
  };
}
