import { cloneDeep, flow, partial } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import { SpaceComponentObject } from "../../../../../../types";
import { commonComponentReducer } from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import {
  BaseComponentConfigState,
  ComponentConfigState,
  SpaceConfigAction
} from "../../../../types";
import { DefaultValueType } from "../../../constants";
import inputReducer from "../../common/inputReducer";
import { getCommonInputProperties } from "../../common/util";
import { hasInputComponentProperties } from "../../util/util";
import { SpaceTextAreaComponent, SpaceTextAreaProperties } from "../types";

export interface TextAreaConfigState
  extends BaseComponentConfigState<SpaceTextAreaComponent> {
  type: "TEXT_AREA";
}

export const INITIAL_STATE = {
  type: "TEXT_AREA" as const,
  draftComponent: {} as SpaceTextAreaComponent
};

function isTextAreaConfigState(
  state: ComponentConfigState
): state is TextAreaConfigState {
  return state.type === "TEXT_AREA";
}

export function ensureTextAreaConfigState(
  state: ComponentConfigState
): TextAreaConfigState {
  if (isTextAreaConfigState(state)) return state;

  throw new Error("Expected TextArea config state.");
}

export const getComponentProperties = (
  properties: Partial<SpaceTextAreaProperties>
): SpaceTextAreaProperties => ({
  ...properties,
  ...getCommonInputProperties(
    properties,
    DefaultValueType.TEXT_VALUE,
    "",
    AttributeTypes.STRING,
    true
  )
});

export function ensureSpaceTextAreaComponent(
  component: SpaceComponentObject
): SpaceTextAreaComponent {
  if (
    !!component.type &&
    typeof component.properties.placeholder === "string" &&
    hasInputComponentProperties(component)
  ) {
    return component;
  }

  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): TextAreaConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceTextAreaComponent(cloneDeep(draftComponent))
  };
}

export default (state: TextAreaConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(inputReducer, partial.placeholder, action)])(
    state,
    action
  );
