import { FunctionNodeBasic } from "../../../../../../types";
import { DataSourceNodeWithFunctions } from "../../../../../common/FunctionEditor/forms/types";

import { FetchDataProvidersAndUserDefinedFunctionsData } from "./queries";

export const selectDataSources = (
  data: FetchDataProvidersAndUserDefinedFunctionsData | undefined
) => {
  const providers = data?.allDataSourceProviders?.edges || [];
  return providers.reduce<DataSourceNodeWithFunctions<FunctionNodeBasic>[]>(
    (acc, providerEdge) =>
      acc.concat(providerEdge.node.dataSources!.edges.map(e => e.node)),
    []
  );
};
