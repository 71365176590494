import React from "react";

import Select from "react-windowed-select";

import { colors, colorTokens } from "../../../../cssConstants";

export const DAY_IN_SECONDS = 1 * 24 * 60 * 60;
export const FIVE_DAYS_IN_SECONDS = 5 * DAY_IN_SECONDS;
export const TEN_DAYS_IN_SECONDS = 10 * DAY_IN_SECONDS;
export const FIFTEEN_DAYS_IN_SECONDS = 15 * DAY_IN_SECONDS;
export const THIRTY_DAYS_IN_SECONDS = 30 * DAY_IN_SECONDS;
export const SIXTY_DAYS_IN_SECONDS = 60 * DAY_IN_SECONDS;
export const NINETY_DAYS_IN_SECONDS = 90 * DAY_IN_SECONDS;

interface Option {
  label: string;
  value: number;
}

const Options: Option[] = [
  { label: "1 day", value: DAY_IN_SECONDS },
  { label: "5 days", value: FIVE_DAYS_IN_SECONDS },
  { label: "10 days", value: TEN_DAYS_IN_SECONDS },
  { label: "15 days", value: FIFTEEN_DAYS_IN_SECONDS },
  { label: "30 days", value: THIRTY_DAYS_IN_SECONDS },
  { label: "60 days", value: SIXTY_DAYS_IN_SECONDS },
  { label: "90 days", value: NINETY_DAYS_IN_SECONDS }
];

interface Props {
  value: number;
  className?: string;
  onChange: (value: number) => void;
}

export default function DurationSelect({
  value,
  className,
  onChange = () => null
}: Props) {
  const selectedOption = Options.find(option => option.value === value);

  if (!selectedOption) {
    throw new Error(`Unsupported value ${value}`);
  }

  return (
    <Select
      className={className}
      value={selectedOption}
      options={Options}
      onChange={(option: Option) => onChange(option.value)}
      styles={{
        dropdownIndicator: (provided: Record<string, any>) => ({
          ...provided,
          color: colors.dropdownIndicator
        }),
        indicatorSeparator: (provided: Record<string, any>) => ({
          ...provided,
          display: "none"
        })
      }}
      theme={(provided: Record<string, any>) => ({
        ...provided,
        colors: {
          ...provided.colors,
          primary25: colorTokens.purpleTransparent400,
          primary50: colorTokens.purpleTransparent400,
          primary75: colorTokens.purpleTransparent400,
          primary: colorTokens.purple700,
          danger: colorTokens.red300
        }
      })}
    />
  );
}
