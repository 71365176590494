export enum IntervalPeriod {
  Minutes = "MINUTES",
  Hours = "HOURS",
  Days = "DAYS",
  Weeks = "WEEKS",
  Months = "MONTHS"
}

export interface FrequencyOption {
  label: string;
  value: IntervalPeriod;
}
