import React, { useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { SpaceParamsComponent } from "./types";

export default function SpaceGlobal(props: Props) {
  const [queryParams] = useSearchParams();
  const { updateOutput } = useComponentStateContext();
  const params = (props.spaceComponent as SpaceParamsComponent).properties
    .url_parameters;

  const paramNames = params.map(p => p.name);
  const qp = Object.fromEntries(queryParams.entries());

  const locationParams = Object.fromEntries(
    Object.entries(qp).filter(([a, _]) => paramNames.includes(a))
  );

  const urlParameters = React.useMemo(() => {
    const parameterObject = Object.fromEntries(params.map(p => [p.name, undefined]));
    return { ...parameterObject, ...locationParams };
  }, [locationParams, params]);

  useEffect(() => {
    updateOutput(urlParameters);
  }, [updateOutput, urlParameters]);

  return null;
}
