import styled from "styled-components";

export const SubHead = styled.h4`
  font-size: ${props => props.theme.mediumFontSize};
  font-weight: ${props => props.theme.boldFontWeight};
  color: ${props => props.theme.textColorMid};
  margin-right: ${props => props.theme.spacersm};
  margin-bottom: 0;
  white-space: nowrap;
`;
