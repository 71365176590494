import React from "react";

import styled from "styled-components";

import Logo, { LogoColor } from "../components/common/Logo";

import { NoHeaderLayout, NoHeaderSidebarLayout } from "./Layout";

interface Props {
  children: React.ReactNode;
}

const StyledLogo = styled(Logo)`
  zoom: 2;
  margin-bottom: ${props => props.theme.spacerlg};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  padding-top: 77px;
`;

export const ErrorPageSidebarLayout = ({ children }: Props) => {
  return (
    <NoHeaderSidebarLayout>
      <Container>
        <StyledLogo color={LogoColor.Black} />
        {children}
      </Container>
    </NoHeaderSidebarLayout>
  );
};

const ErrorPageLayout = ({ children }: Props) => {
  return (
    <NoHeaderLayout>
      <Container>
        <StyledLogo color={LogoColor.Black} />
        {children}
      </Container>
    </NoHeaderLayout>
  );
};
export default ErrorPageLayout;
