import React from "react";

import styled from "styled-components";

import { colorTokens } from "../../../../../cssConstants";
import Button from "../../../../common/Button";

import { StepProps } from ".";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.theme.spacermd};
  padding: ${props => props.theme.spacermd};
  min-width: 280px;
  border: 1px solid ${props => props.theme.borderGrey};
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: ${props => props.theme.defaultFontSize};
  line-height: ${props => props.theme.spacerlg};
  color: ${colorTokens.greyTransparent1200};
`;

const InitiateStep = ({ processStep, state, showSubText }: StepProps) => {
  return (
    <Root>
      {showSubText && <Title>Login with {state.flow.name}</Title>}
      <Button
        loading={state.isProcessing}
        type="primary"
        onClick={() => processStep({})}
      >
        Begin Authorization
      </Button>
    </Root>
  );
};

export default InitiateStep;
