export { default } from "./CredentialsForm";

// The set of integrations where it's helpful to provide information about the
// inbound IPs during configuration.
export const inboundIpConfig = new Set([
  "cassandra",
  "http",
  "mailgun", // https://help.mailgun.com/hc/en-us/articles/360012244474-IP-Allowlist
  "mongo", // https://docs.atlas.mongodb.com/security/ip-access-list/
  "mssql",
  "mysql",
  "postgresql",
  "redshift", // https://docs.aws.amazon.com/redshift/latest/gsg/rs-gsg-authorize-cluster-access.html
  "s3", // https://aws.amazon.com/premiumsupport/knowledge-center/block-s3-traffic-vpc-ip/
  "salesforce", // https://developer.salesforce.com/docs/atlas.en-us.securityImplGuide.meta/securityImplGuide/login_ip_ranges.htm
  "snowflake", // https://docs.snowflake.com/en/user-guide/network-policies.html
  "zendesk" // https://support.zendesk.com/hc/en-us/articles/203663706-Restricting-access-to-Zendesk-Support-and-your-Help-Center-using-IP-restrictions
]);

// The set of integrations which support only a single data source, users will
// be prompted to add a display name instead of being shown a list of available
// data sources to choose from.
export const singleDataSource = new Set([
  "cassandra",
  "dynamodb",
  "firestore",
  "http",
  "hubspot",
  "mailgun",
  "mongo",
  "mssql",
  "mysql",
  "postgresql",
  "redshift",
  "s3",
  "salesforce",
  "snowflake",
  "stripe",
  "zendesk"
]);
