import React from "react";

import { useQuery } from "@apollo/react-hooks";

import { FunctionScope, InputParameter, ParameterType } from "../../../../../types";
import FormBuilderModal from "../../../../common/FormBuilderModal";
import FunctionPicker from "../../../../common/FunctionPicker";
import { LinkButtonNew } from "../../../../common/StyledComponents";

import ActionIcon from "./ActionIcon";
import { SELECTED_FUNCTION_QUERY } from "./queries";
import * as styled from "./styledComponents";

const SUPPORTED_PARAM_TYPES = [
  ParameterType.STATIC,
  ParameterType.BINDING,
  ParameterType.TEMPLATE
];
interface Props {
  functionId: string | undefined;
  inputParameters: InputParameter[];
  onChange: (functionId: string | null) => void;
  onParameterValuesChange: (inputParameters: InputParameter[]) => void;
}

const ActionPicker = ({
  functionId,
  inputParameters,
  onChange,
  onParameterValuesChange
}: Props) => {
  const [showFormBuilder, setShowFormBuilder] = React.useState(false);
  const { data } = useQuery(SELECTED_FUNCTION_QUERY, {
    skip: !functionId,
    variables: {
      id: functionId
    }
  });

  const selectedFunction = React.useMemo(() => {
    return data?.function;
  }, [data]);

  const handleParameterValuesSave = (inputParameters: InputParameter[]) => {
    onParameterValuesChange(inputParameters);
    setShowFormBuilder(false);
  };

  return (
    <styled.Root>
      <styled.Header>
        <ActionIcon integration={selectedFunction?.dataSource.integration} />
        <styled.HeaderDetails>
          {selectedFunction ? (
            <>
              <styled.DataSourceTitle>
                {selectedFunction?.dataSource.name}
              </styled.DataSourceTitle>
              <styled.ActionTitle>{selectedFunction?.title}</styled.ActionTitle>
            </>
          ) : (
            <styled.ActionTitle>Add action</styled.ActionTitle>
          )}
        </styled.HeaderDetails>
      </styled.Header>
      <styled.HrNoMargin />
      <styled.PickerContainer>
        <FunctionPicker
          functionId={functionId || null}
          functionScope={FunctionScope.Submittable}
          onChange={onChange}
          showLabels
        />
        <styled.ActionLinks>
          <LinkButtonNew onClick={() => setShowFormBuilder(true)}>
            Configure
          </LinkButtonNew>
        </styled.ActionLinks>
      </styled.PickerContainer>
      {showFormBuilder && (
        <FormBuilderModal
          func={selectedFunction}
          title={selectedFunction?.title}
          allowedParameterTypes={SUPPORTED_PARAM_TYPES}
          initialInputParameters={inputParameters}
          onSave={handleParameterValuesSave}
        />
      )}
    </styled.Root>
  );
};

export default ActionPicker;
