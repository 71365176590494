import React from "react";

import { Button } from "antd";
import classNames from "classnames";
import styled from "styled-components";

import { Size } from "../../../cssConstants";

const RailRoot = styled.div`
  transition: ${props => props.theme.animationFn};
  &.isStatic {
    min-width: 234px;
  }
  &:not(.isStatic) {
    &:not(.isActive) {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 0;
    }
  }
  @media (max-width: ${Size.md}) {
    position: absolute;
    background: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: translate(100%);
    &.isActive {
      display: block;
      transform: translate(0%);
      opacity: 1;
    }
  }
`;

const BackButton = styled(Button)`
  display: none;
  @media (max-width: ${Size.md}) {
    display: block;
    margin-left: -10px;
    margin-bottom: ${props => props.theme.spacerlg};
    color: ${props => props.theme.textColor};
  }
`;

interface RailProps {
  isActive?: boolean;
  isStatic?: boolean;
  className?: string;
  children: React.ReactNode;
  onBack?: () => void;
}

export default function Rail({
  className,
  isActive,
  isStatic,
  children,
  onBack = () => {}
}: RailProps) {
  return (
    <RailRoot className={classNames(className, { isActive, isStatic })}>
      <BackButton icon="arrow-left" type="link" size="large" onClick={() => onBack()} />
      {children}
    </RailRoot>
  );
}
