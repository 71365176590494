import React from "react";

import AcceptInviteForm from "../components/setup_flow/AcceptInviteForm";
import CompleteSignupForm from "../components/setup_flow/CompleteSignupForm";
import CreateOrganizationForm from "../components/setup_flow/CreateOrganizationForm";
import { NoHeaderLayout } from "../layouts";

export const New = () => (
  <NoHeaderLayout backgroundColor="black">
    <CreateOrganizationForm />
  </NoHeaderLayout>
);

export const AcceptInvite = () => (
  <NoHeaderLayout backgroundColor="black">
    <AcceptInviteForm />
  </NoHeaderLayout>
);

export const CompleteSignup = () => (
  <NoHeaderLayout backgroundColor="black">
    <CompleteSignupForm />
  </NoHeaderLayout>
);
