import { ConfigValidationError } from "../../../../../../types";
import { tryError } from "../../../../../util";
import { ComponentConfigState } from "../../../../types";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";
import { evaluateTemplate } from "../../util";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  const { draftComponent } = state;
  let errors: ConfigValidationError[] = [];

  if (!draftComponent.properties.template) {
    errors.push({
      field: "TEMPLATE",
      message: "Please provide a template for this calculated field."
    });
  }

  if (!window.FEATURE_FLAGS.includes("JS_EVAL")) {
    try {
      evaluateTemplate(draftComponent.properties.template, null);
    } catch (err) {
      const e = tryError(err);
      errors.push({
        field: "TEMPLATE",
        message: e.message
      });
    }
  }

  errors = errors.concat(selectVisibilityRuleErrors(draftComponent));
  return errors;
}
