import React from "react";

import { Tabs } from "antd";

import { formatTestHandle } from "../../../../../../../test";
import { AttributeNode, SpaceComponentType } from "../../../../../../../types";
import { InsertComponentButtons } from "../../../../../SpaceConfig/AddComponentPopover";
import { useStableSpaceContext } from "../../../../SpaceContext";
import { useComponentConfigContext } from "../../ComponentConfigContext";
import {
  ConfigPanelPopper,
  ConfigSection,
  ScrollableConfigSection
} from "../../ConfigPanel";
import { WrapTextButton } from "../../ConfigPanel/styledComponents";

interface Props {
  popperId: string;
  hidden: boolean;
  columnLabel: string;
  attributes: AttributeNode[];
  onInsertAttributeColumn: (a: AttributeNode) => void;
  onInsertComponentColumn: (componentType: SpaceComponentType) => void;
  onClose: () => void;
  popperReferenceElement?: HTMLElement;
}

export default function AddColumnPopper({
  popperId,
  columnLabel,
  attributes,
  hidden,
  onInsertAttributeColumn,
  onInsertComponentColumn,
  onClose,
  popperReferenceElement
}: Props) {
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const { state } = useComponentConfigContext();

  const hostableChildComponentTypes = getSpaceComponentPackages()
    .filter(p => p.allowedHosts.has(state.draftComponent.type))
    .map(p => p.type);

  const attributesSection = (
    <ScrollableConfigSection>
      <p>Select {columnLabel} to add to your component.</p>
      {attributes.length > 0 &&
        attributes
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(a => (
            <WrapTextButton
              key={a.id}
              data-test={formatTestHandle(`${a.name}-add-attribute-column-button`)}
              onClick={() => {
                onInsertAttributeColumn(a);
              }}
            >
              {a.sourceName}
            </WrapTextButton>
          ))}
    </ScrollableConfigSection>
  );

  return (
    <ConfigPanelPopper
      popperId={popperId}
      onCancel={onClose}
      popperReferenceElement={popperReferenceElement}
    >
      {hostableChildComponentTypes.length > 0 && !hidden ? (
        <Tabs animated={false}>
          <Tabs.TabPane key="attributes" tab="Attributes">
            {attributesSection}
          </Tabs.TabPane>
          {hostableChildComponentTypes.length > 0 && !hidden ? (
            <Tabs.TabPane key="components" tab="Components">
              <ConfigSection>
                <InsertComponentButtons
                  componentTypes={hostableChildComponentTypes}
                  onClick={componentType => {
                    onInsertComponentColumn(componentType);
                    onClose();
                  }}
                />
              </ConfigSection>
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      ) : (
        attributesSection
      )}
    </ConfigPanelPopper>
  );
}
