import React from "react";

import styled from "styled-components";

export const ToggleButton = styled.button<{ isOn: boolean }>`
  cursor: pointer;
  color: ${props => props.theme.backgroundSecondary};
  border: 1px solid
    ${props =>
      props.isOn
        ? props.theme.editorJSToggleOnBorder
        : props.theme.editorJSToggleOffBorder};
  line-height: 18px;
  border: 0;
  border-radius: 2px;
  padding: 0 6px;
  font-size: 12px;
  background-color: ${props =>
    props.isOn ? props.theme.link : props.theme.surfaceSecondary};
`;
ToggleButton.displayName = "ToggleButton";

interface JsToggleButtonProps {
  isOn: boolean;
  className?: string;
  onToggle: () => void;
}

export default function JsToggleButton({
  isOn,
  className,
  onToggle
}: JsToggleButtonProps) {
  return (
    <ToggleButton
      className={className || ""}
      isOn={isOn}
      onMouseDown={e => {
        e.preventDefault(); // maintain focus in inputs if editing when clicked
        onToggle();
      }}
    >
      JS
    </ToggleButton>
  );
}
