import React from "react";

import { Collapse } from "antd";
import styled from "styled-components";

import { DescribeColumn, Metadata } from "../../../../types";
import { DebouncedEditor } from "../../Editor";
import usePrevious from "../../hooks/usePrevious";
import Message from "../../Message";
import useDescribe from "../common/useDescribe";

export interface Func {
  metadata?: Metadata;
}

interface Props {
  func?: Func;
  dataSourceId: string;
  reducer: string;
  metadataReducer: string;
  onDescribeColumnsChange?: (columns: DescribeColumn[]) => void;
  onMetadataReducerChange?: (reducer: string) => void;
  onReducerChange?: (reducer: string) => void;
}

const EDITOR_HEIGHT = "240px";

const TransformerTitle = styled.div`
  display: flex;
  align-items: center;
`;

const getSQL = (func: Func | undefined): string | undefined =>
  typeof func?.metadata?.code === "object" && typeof func.metadata.code.sql === "string"
    ? func.metadata.code.sql.trim()
    : undefined;

export default function FunctionTransformer({
  func,
  dataSourceId,
  reducer,
  metadataReducer,
  onDescribeColumnsChange = () => {},
  onMetadataReducerChange = () => {},
  onReducerChange = () => {}
}: Props) {
  const previousReducer = usePrevious(reducer);
  const { describe } = useDescribe();

  React.useEffect(() => {
    if (previousReducer === undefined) return;

    const trimmed = reducer.trim();
    const previousTrimmed = previousReducer.trim();
    const sql = getSQL(func);

    if (
      !sql ||
      trimmed === previousTrimmed ||
      (trimmed !== "data" && trimmed !== "data[0]")
    )
      return;

    describe(dataSourceId, sql)
      .then(data => onDescribeColumnsChange(data.columns))
      .catch(_ => Message.error("Unable to automatically infer Inputs and Outputs"));
  }, [dataSourceId, func, reducer, previousReducer, describe, onDescribeColumnsChange]);

  return (
    <Collapse defaultActiveKey={["responseTransformer"]} bordered={false}>
      <Collapse.Panel
        key="responseTransformer"
        header={<TransformerTitle>Response Transformer</TransformerTitle>}
      >
        <DebouncedEditor
          className="editor-transformer"
          mode="javascript"
          height={EDITOR_HEIGHT}
          value={reducer}
          onChange={onReducerChange}
        />
      </Collapse.Panel>
      <Collapse.Panel
        key="metadataTransformer"
        header={<TransformerTitle>Metadata Transformer</TransformerTitle>}
      >
        <DebouncedEditor
          className="editor-transformer"
          mode="javascript"
          height={EDITOR_HEIGHT}
          value={metadataReducer}
          onChange={onMetadataReducerChange}
        />
      </Collapse.Panel>
    </Collapse>
  );
}
