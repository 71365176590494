import React from "react";

import { captureException } from "@sentry/browser";
import styled from "styled-components";

import ErrorPageLayout from "../../layouts/ErrorPageLayout";

const StyledContent = styled.div`
  padding: 50px;
  text-align: center;
`;

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<any, State> {
  state = { hasError: false };

  componentDidCatch(error: Error) {
    captureException(error);
    window.__ERRORS = Array.isArray(window.__ERRORS) ? window.__ERRORS : [];
    window.__ERRORS.push(error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPageLayout>
          <StyledContent>
            <h1>We're sorry — something's gone wrong.</h1>
            <p>
              Our team has been notified, <a href="/">refresh</a> to continue.
            </p>
            <p>
              If the problem persists you can get{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://internalapp.freshdesk.com/"
              >
                help here
              </a>
              .
            </p>
          </StyledContent>
        </ErrorPageLayout>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
