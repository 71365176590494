import React from "react";

import moment from "moment";
import styled from "styled-components";

import { AttributeTypes, ErrorValues } from "../../../../../constants";
import DatePicker from "../../../../common/DatePicker";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { MomentPopperInput } from "../../../../common/MomentFormItemPopper";
import {
  TimeStampPicker,
  TimePicker,
  DateTimePicker
} from "../../../../common/MomentFormItemPopper/DateTimePickers";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDefaultValue from "../common/useDefaultValue";
import useOutputSyncing from "../common/useOutputSyncing";
import { toAttributeType } from "../common/util";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { ensureSpaceDateTimePickerComponent } from "./Config/reducer";
import { displayFormat, saveFormat } from "./utils";

const FullWidthContainer = styled.div`
  width: 100%;
`;

const popoverPicker = {
  [AttributeTypes.TIMESTAMP]: TimeStampPicker,
  [AttributeTypes.DATETIME]: DateTimePicker,
  [AttributeTypes.DATE]: DatePicker,
  [AttributeTypes.TIME]: TimePicker
};

export type DateTimeAttributeTypes =
  | AttributeTypes.TIMESTAMP
  | AttributeTypes.DATETIME
  | AttributeTypes.DATE
  | AttributeTypes.TIME;

export default function SpaceDateTimePicker({ spaceComponent, hasConfigError }: Props) {
  const { properties } = ensureSpaceDateTimePickerComponent(spaceComponent);
  const { editMode } = useStableSpaceContext();
  const { output } = useComponentStateContext();
  const { blankValue, defaultValue } = useDefaultValue();
  const [localValue, setLocalValue] = React.useState(defaultValue);
  const { validation_type } = properties;

  const hasPermissionError = React.useMemo(() => {
    return localValue === ErrorValues.permissionDenied;
  }, [localValue]);

  const sourceType: DateTimeAttributeTypes = toAttributeType(
    validation_type
  ) as DateTimeAttributeTypes;

  const setLocalValueAsDefault = React.useCallback(() => {
    if (defaultValue === ErrorValues.permissionDenied) {
      setLocalValue(defaultValue);
      return;
    }
    // defaultValue can come back unformatted if current date time is specified, or if sourceType is changed.
    // update formatting based on sourceType (if needed) before setting local value and outputting the value to space state.
    const format = saveFormat[sourceType];
    const formatted: string =
      !defaultValue || moment(defaultValue, format, true).isValid()
        ? defaultValue
        : moment(defaultValue).format(format);
    setLocalValue(formatted);
  }, [defaultValue, sourceType]);

  // initialize default value if defaultValue or sourceType changes
  React.useEffect(() => {
    setLocalValueAsDefault();
  }, [setLocalValueAsDefault]);

  // init output when output null
  React.useEffect(() => {
    if (output !== null) return;
    setLocalValueAsDefault();
  }, [output, setLocalValueAsDefault]);

  const { errorMessage } = useOutputSyncing(localValue);

  return (
    <EditModeInteractionBlocker>
      <div>
        <FlexContainer>
          {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
          <FullWidthContainer>
            <MomentPopperInput
              value={hasPermissionError ? blankValue : localValue}
              placeholder={properties.placeholder}
              displayFormat={displayFormat[sourceType]}
              saveFormat={saveFormat[sourceType]}
              PopoverPicker={popoverPicker[sourceType]}
              onChange={value => setLocalValue(value)}
              hasError={!!errorMessage}
            />
          </FullWidthContainer>
          {hasPermissionError && <PermissionWarningIcon />}
        </FlexContainer>
        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </div>
    </EditModeInteractionBlocker>
  );
}
