import produce from "immer";
import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject, SourceType } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import filterListManagerReducer, {
  INITIAL_STATE as FILTER_LIST_MANAGER_INITIAL_STATE,
  FilterListManagerState,
  getInitialFilterState
} from "../../common/FilterListManager/reducer/reducer";
import parameterConfigReducer, {
  INITIAL_STATE as PARAMETER_CONFIG_INITIAL_STATE,
  getInitialParameterState,
  ParameterConfigState
} from "../../common/ParametersManager/reducer/reducer";
import viewDataConfigReducer, {
  INITIAL_STATE as VIEW_DATA_CONFIG_INITIAL_STATE,
  ViewConfigState
} from "../../common/useViewConfig/reducer";
import { functionTitleToComponentName } from "../../common/util";
import { SpaceCardListComponent } from "../types";

export interface CardListConfigState
  extends BaseComponentConfigState<SpaceCardListComponent>,
    ViewConfigState,
    FilterListManagerState,
    ParameterConfigState {
  type: "CARD_LIST";
}

export const INITIAL_STATE = {
  type: "CARD_LIST" as const,
  draftComponent: {} as SpaceCardListComponent,
  ...VIEW_DATA_CONFIG_INITIAL_STATE,
  ...FILTER_LIST_MANAGER_INITIAL_STATE,
  ...PARAMETER_CONFIG_INITIAL_STATE
};

function isCardListConfigState(
  state: ComponentConfigState
): state is CardListConfigState {
  return state.type === "CARD_LIST";
}

export function ensureCardListConfigState(
  state: ComponentConfigState
): CardListConfigState {
  if (isCardListConfigState(state)) return state;

  throw new Error("Expected card list config state.");
}

export function ensureSpaceCardListComponent(
  component: SpaceComponentObject
): SpaceCardListComponent {
  if (
    Array.isArray(component.properties.columns) &&
    Array.isArray(component.properties.filters) &&
    Array.isArray(component.properties.order) &&
    Array.isArray(component.properties.input_parameters) &&
    typeof component.properties.card_name === "string"
  ) {
    return component;
  }
  return {
    ...component,
    sourceType: component.sourceType || SourceType.VIEW,
    properties: {
      ...component.properties,
      is_header_enabled:
        typeof component.properties.is_header_enabled === "boolean"
          ? component.properties.is_header_enabled
          : true,
      columns: component.properties.columns || [],
      filters: component.properties.filters || [],
      order: component.properties.order || [],
      input_parameters: component.properties.input_parameters || [],
      card_name: {
        template: component.properties.card_name?.template || ""
      }
    }
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): CardListConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceCardListComponent(cloneDeep(draftComponent)),
    ...getInitialFilterState(draftComponent),
    ...getInitialParameterState(draftComponent)
  };
}

function reducer(
  state: CardListConfigState,
  action: SpaceConfigAction
): CardListConfigState {
  switch (action.type) {
    case "LOAD_FUNCTION": {
      if (state.draftComponent.name) return state;
      return produce(state, (draftState: CardListConfigState) => {
        const humanized = functionTitleToComponentName(action.payload.title);
        draftState.draftComponent.name = `${humanized} card list`;
        draftState.draftComponent.properties.title = `${humanized} card list`;
      });
    }
    default:
      return state;
  }
}

export default (state: CardListConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(viewDataConfigReducer, partial.placeholder, action),
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(filterListManagerReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
