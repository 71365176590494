export type ConditionalExpression =
  | ManagedConditionalExpression
  | EvaluatedConditionalExpression;

export interface ManagedConditionalExpression {
  type: "managed";
  subject_template: string;
  operator: ConditionalOperator;
  object_template?: string;
}

export interface EvaluatedConditionalExpression {
  type: "evaluated";
  template: string;
}

// Defining this separately from Filters since the set of supported operators is different
export enum ConditionalOperator {
  Equals = "=",
  NotEquals = "!=",
  IContains = "icontains",
  LessThan = "<",
  LessThanOrEqual = "<=",
  GreaterThan = ">",
  GreaterThanOrEqual = ">=",
  IsNull = "is_null",
  IsNotNull = "is_not_null",
  IsEmailAddress = "is_email_address",
  IsPhoneNumber = "is_phone_number"
}

export const ConditionalOperatorDisplayNames = new Map<ConditionalOperator, string>([
  [ConditionalOperator.Equals, "equal to"],
  [ConditionalOperator.NotEquals, "not equal to"],
  [ConditionalOperator.IContains, "contains"],
  [ConditionalOperator.LessThan, "less than"],
  [ConditionalOperator.LessThanOrEqual, "less than or equal"],
  [ConditionalOperator.GreaterThan, "greater than"],
  [ConditionalOperator.GreaterThanOrEqual, "greater than or equal"],
  [ConditionalOperator.IsNull, "is null"],
  [ConditionalOperator.IsNotNull, "is not null"],
  [ConditionalOperator.IsEmailAddress, "is an email address"],
  [ConditionalOperator.IsPhoneNumber, "is a phone number"]
]);

export interface ManagedExpressionDefinition {
  operatorType: ConditionalOperator;
  hasConditionalObject: boolean;
}

export type ObjectMode = "text" | "binding" | "void";

export const ManagedExpressionDefinitions = new Map<
  ConditionalOperator,
  ManagedExpressionDefinition
>([
  [
    ConditionalOperator.Equals,
    { operatorType: ConditionalOperator.Equals, hasConditionalObject: true }
  ],
  [
    ConditionalOperator.NotEquals,
    { operatorType: ConditionalOperator.NotEquals, hasConditionalObject: true }
  ],
  [
    ConditionalOperator.IContains,
    { operatorType: ConditionalOperator.IContains, hasConditionalObject: true }
  ],
  [
    ConditionalOperator.LessThan,
    { operatorType: ConditionalOperator.LessThan, hasConditionalObject: true }
  ],
  [
    ConditionalOperator.LessThanOrEqual,
    { operatorType: ConditionalOperator.LessThanOrEqual, hasConditionalObject: true }
  ],
  [
    ConditionalOperator.GreaterThan,
    { operatorType: ConditionalOperator.GreaterThan, hasConditionalObject: true }
  ],
  [
    ConditionalOperator.GreaterThanOrEqual,
    {
      operatorType: ConditionalOperator.GreaterThanOrEqual,
      hasConditionalObject: true
    }
  ],
  [
    ConditionalOperator.IsNull,
    { operatorType: ConditionalOperator.IsNull, hasConditionalObject: false }
  ],
  [
    ConditionalOperator.IsNotNull,
    {
      operatorType: ConditionalOperator.IsNotNull,
      hasConditionalObject: false
    }
  ],
  [
    ConditionalOperator.IsEmailAddress,
    { operatorType: ConditionalOperator.IsEmailAddress, hasConditionalObject: false }
  ],
  [
    ConditionalOperator.IsPhoneNumber,
    { operatorType: ConditionalOperator.IsPhoneNumber, hasConditionalObject: false }
  ]
]);
