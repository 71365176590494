import React from "react";

import { Checkbox, Col, Icon, Row, Switch, Tooltip } from "antd";
import styled from "styled-components";

import ChevronLeft from "../../../assets/icons/chevron-left.svg";
import ChevronRight from "../../../assets/icons/chevron-right.svg";
import { colors } from "../../../cssConstants";
import { EnvironmentNode } from "../../../types";
import { AntdEnvironmentSelect } from "../EnvironmentSelect/AntdEnvironmentSelect";
import { B3 } from "../StyledComponents";

import { FunctionPolicyActionTypes, PolicySpaceReducerAction } from "./reducer";
import {
  DisabledText,
  FunctionFieldCell,
  FunctionFieldLabel,
  PrefixSwitchLabel,
  SpaceBetweenLayout,
  StyledInput,
  StyledToggleChevron,
  TablePaginationButton
} from "./styledComponents";
import {
  FunctionAttribute,
  FunctionParameter,
  FunctionPermission,
  switchValues
} from "./util";

export const NO_DISABLE_PERMISSIONS_FOR_ADMIN =
  "Permissions cannot be disabled for Admins";

export function SpaceRolePermissionsFilter({
  selectedEnvironment,
  setSelectedEnvironment,
  setSearchText
}: {
  selectedEnvironment?: EnvironmentNode;
  setSelectedEnvironment: (environment?: EnvironmentNode) => void;
  setSearchText: (query: string) => void;
}) {
  const rootEleRef = React.useRef(null);

  return (
    <SpaceBetweenLayout ref={rootEleRef}>
      <AntdEnvironmentSelect
        selectedEnvironment={selectedEnvironment}
        setSelectedEnvironment={env => setSelectedEnvironment(env as EnvironmentNode)}
        popupContainer={rootEleRef.current!}
      />
      <StyledInput
        placeholder="Search functions"
        prefix={<Icon type="search" />}
        onChange={e => setSearchText(e.currentTarget.value)}
      />
    </SpaceBetweenLayout>
  );
}

function Paginator({
  page,
  pageSize,
  total,
  onPageChange,
  className
}: {
  page: number;
  pageSize: number;
  total: number;
  onPageChange?: (page: number) => void;
  className?: string;
}) {
  if (total === 0) {
    return <div className={className}></div>;
  }

  const start = pageSize * (page - 1) + 1;

  let end = pageSize * page;
  if (total < end) {
    end = total;
  }

  return (
    <div className={className}>
      <B3 color={colors.surfaceSecondary}>
        {start} - {end} of {total} functions
      </B3>
      <TablePaginationButton
        disabled={page === 1 || total === 0}
        onClick={() => {
          onPageChange?.(page - 1);
        }}
      >
        <Icon component={ChevronLeft} />
      </TablePaginationButton>
      <TablePaginationButton
        disabled={end === total}
        onClick={() => {
          onPageChange?.(page + 1);
        }}
      >
        <Icon component={ChevronRight} />
      </TablePaginationButton>
    </div>
  );
}

export const PermissionsPaginator = styled(Paginator)`
  height: 44px;
  background-color: ${colors.newContainerPrimary};
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 32px;
`;

export function PermissionParameter({
  permission,
  func,
  admin,
  className,
  onChange
}: {
  permission: FunctionParameter;
  func: FunctionPermission;
  admin: boolean;
  className?: string;
  onChange: (event: PolicySpaceReducerAction) => void;
}) {
  return (
    <div className={className}>
      <Tooltip title={admin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}>
        <Checkbox
          data-test="spaceParamCheckBox"
          checked={admin || permission.permitted || func.allowsAll}
          disabled={admin || func.allowsAll}
          onChange={e => {
            onChange({
              type: e.target.checked
                ? FunctionPolicyActionTypes.ADD_PARAMETER_POLICY
                : FunctionPolicyActionTypes.REMOVE_PARAMETER_POLICY,
              payload: {
                functionId: func.functionId,
                parameterId: permission.id
              }
            });
          }}
        >
          <B3>{permission.name}</B3>
          <FunctionFieldLabel>
            {permission.required ? "Parameter - required" : "Parameter"}
          </FunctionFieldLabel>
        </Checkbox>
      </Tooltip>
    </div>
  );
}

export const StyledPermissionParameter = styled(PermissionParameter)`
  display: block;
  height: 44px;
  line-height: 44px;
`;

function PermissionAttribute({
  attribute,
  func,
  admin,
  className,
  onChange
}: {
  attribute: FunctionAttribute;
  func: FunctionPermission;
  admin: boolean;
  className?: string;
  onChange: (event: PolicySpaceReducerAction) => void;
}) {
  return (
    <div className={className}>
      <Tooltip title={admin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}>
        <Checkbox
          data-test="spaceParamCheckBox"
          checked={admin || attribute.permitted || func.allowsAll}
          disabled={admin || func.allowsAll}
          onChange={e => {
            onChange({
              type: e.target.checked
                ? FunctionPolicyActionTypes.ADD_ATTRIBUTE_POLICY
                : FunctionPolicyActionTypes.REMOVE_ATTRIBUTE_POLICY,
              payload: {
                functionId: func.functionId,
                attributeId: attribute.id
              }
            });
          }}
        >
          <B3>{attribute.name}</B3>
          <FunctionFieldLabel>Attribute</FunctionFieldLabel>
        </Checkbox>
      </Tooltip>
    </div>
  );
}

export const StyledPermissionAttribute = styled(PermissionAttribute)`
  display: block;
  height: 44px;
  line-height: 44px;
`;

export interface FunctionParameterRow {
  id: string;
  name: string | React.ReactNode;
  level: number; // table row "depth" level, 0 for this interface, as it is "top"
  dataSource: string | React.ReactNode;
  fieldsCount?: number; // Number of parameters/attributes
  permission: React.ReactNode;
  children?: FunctionParameterRow[];
}

export const getFunctionParameterRow = (
  func: FunctionPermission,
  onPolicyChange: (event: PolicySpaceReducerAction) => void,
  expandedRows: string[],
  admin = false
): FunctionParameterRow => {
  const totalPermissionsGranted = func.attributesPermitted + func.parametersPermitted;
  const totalPermissionsCount = func.attributes.length + func.parameters.length;
  const allPermissionsGranted =
    totalPermissionsGranted !== 0 && totalPermissionsGranted === totalPermissionsCount;
  const noPermissionsGranted = totalPermissionsGranted === 0;

  let permissionsGrantedHeader = "All";
  if (!admin && noPermissionsGranted && !func.allowsAll) {
    permissionsGrantedHeader = "None";
  } else if (!admin && !func.allowsAll && !allPermissionsGranted) {
    permissionsGrantedHeader = `${totalPermissionsGranted} of ${totalPermissionsCount}`;
  }

  const expandable = func.parameters.length !== 0 || func.attributes.length !== 0;
  const functionExpanded = expandedRows.includes(func.functionId);

  return {
    id: func.functionId,
    name: func.name,
    level: 0,
    dataSource: func.dataSource,
    permission: (
      <Row type="flex" justify="space-between">
        <Col>
          <Tooltip
            title={switchValues({
              [NO_DISABLE_PERMISSIONS_FOR_ADMIN]: admin,
              "To configure granular permissions, turn off Auto-grant all":
                func.allowsAll
            })}
          >
            <Checkbox
              data-test="paramSelectAllCheckBox"
              checked={admin || allPermissionsGranted || func.allowsAll}
              indeterminate={
                !noPermissionsGranted && !allPermissionsGranted && !func.allowsAll
              }
              disabled={func.allowsAll || !expandable || admin}
              onClick={event => {
                event.stopPropagation();
                onPolicyChange({
                  type: FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE_FUNCTION,
                  payload: {
                    functionId: func.functionId
                  }
                });
              }}
            />
            <FunctionFieldCell>
              <B3>{permissionsGrantedHeader}</B3>
              {expandable && <StyledToggleChevron expanded={functionExpanded} />}
            </FunctionFieldCell>
          </Tooltip>
        </Col>
        <Col>
          <PrefixSwitchLabel>
            {admin || func.allowsAll ? "On" : "Off"}
          </PrefixSwitchLabel>
          <Tooltip title={admin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}>
            <Switch
              checked={admin || func.allowsAll}
              disabled={admin}
              onChange={(_, event) => {
                event.stopPropagation();
                onPolicyChange({
                  type: FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE_FUNCTION,
                  payload: {
                    functionId: func.functionId,
                    allowAll: !func.allowsAll
                  }
                });
              }}
            />
          </Tooltip>
        </Col>
      </Row>
    ),
    fieldsCount: func.parameters.length + func.attributes.length,
    children: [
      ...func.parameters.map(permission => {
        return {
          id: permission.id,
          level: 1,
          name: <DisabledText>{func.name}</DisabledText>,
          dataSource: <DisabledText>{func.dataSource}</DisabledText>,
          permission: (
            <StyledPermissionParameter
              permission={permission}
              func={func}
              admin={admin}
              onChange={onPolicyChange}
            />
          )
        };
      }),
      ...func.attributes.map(attribute => {
        return {
          id: attribute.id,
          level: 1,
          name: <DisabledText>{func.name}</DisabledText>,
          dataSource: <DisabledText>{func.dataSource}</DisabledText>,
          permission: (
            <StyledPermissionAttribute
              attribute={attribute}
              func={func}
              admin={admin}
              onChange={onPolicyChange}
            />
          )
        };
      })
    ]
  };
};
