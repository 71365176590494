import React from "react";

import { Button, Row, Table } from "antd";
import { TableProps } from "antd/lib/table";
import { throttle } from "lodash";
import styled from "styled-components";

export interface InfiniteScrollTableProps extends TableProps<any> {
  hasMoreData: boolean;
  loadingMoreData: boolean;
  dataSource: {}[];
  dataSourceId?: string;
  className?: string;
  onLoadMoreData: () => void;
}

const StyledRow = styled(Row)`
  margin-top: ${props => props.theme.spacerlg};
  margin-bottom: ${props => props.theme.spacerlg};
`;

const InfiniteScrollTable = (props: InfiniteScrollTableProps) => {
  const scrollParent = React.useRef<HTMLDivElement | null>(null);

  const onScroll = throttle(() => {
    if (!scrollParent || !scrollParent.current) {
      return;
    }

    const scrolledToBottom =
      scrollParent.current.offsetHeight + scrollParent.current.scrollTop >=
      scrollParent.current.scrollHeight;

    if (scrolledToBottom && props.hasMoreData && !props.loadingMoreData) {
      props.onLoadMoreData();
    }
  }, 200);

  React.useLayoutEffect(() => {
    if (!scrollParent || !scrollParent.current) return;
    const ref = scrollParent.current!;
    scrollParent.current.addEventListener("scroll", onScroll);

    return () => {
      ref.removeEventListener("scroll", onScroll);
    };
  });

  React.useEffect(() => {
    if (scrollParent && scrollParent.current) {
      scrollParent.current.scrollTop = 0;
    }
  }, [props.dataSourceId]);

  const { className, ...rest } = props;

  return (
    <div
      className={className}
      ref={scrollParent}
      // onWheel ensures an event fires even when no scroll is needed
      onWheel={onScroll}
    >
      <Table {...rest} />
      {props.dataSource && props.dataSource.length > 0 && (
        <StyledRow type="flex" justify="center">
          <Button
            disabled={!props.hasMoreData}
            type="primary"
            icon="reload"
            shape="round"
            loading={props.loadingMoreData}
            onClick={props.onLoadMoreData}
          >
            Load More
          </Button>
        </StyledRow>
      )}
    </div>
  );
};

export { InfiniteScrollTable };
