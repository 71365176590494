import React from "react";

import Message from "../../../../common/Message";
import { ModalNew } from "../../../../common/Modal";

import { useDeleteTask } from "./useDeleteTask";

interface Props {
  taskId: string;
  onCancel?: () => void;
  onComplete?: () => void;
}

export default function DeleteTaskModal({
  taskId,
  onCancel = () => null,
  onComplete = () => null
}: Props) {
  const [deleteTask, { loading }] = useDeleteTask({
    onCompleted: onComplete
  });

  return (
    <ModalNew
      title="Are you sure?"
      confirmLoading={loading}
      okText="Delete"
      onCancel={onCancel}
      onOk={async () => {
        const result = await deleteTask({
          variables: { id: taskId }
        });

        if (result.data?.deleteTask.ok) {
          Message.success("The task has been deleted.");
        } else {
          Message.error("Something went wrong. Please try again.");
        }
      }}
      visible
    >
      This task and all of its comments will be permanently deleted.
    </ModalNew>
  );
}
