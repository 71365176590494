import gql from "graphql-tag";

export type DeleteResult = {
  deleteAuthorizationProvider: {
    ok: boolean;
    message: string;
  };
};

export interface DeleteVars {
  id: string;
}

export const DELETE_AUTH_PROVIDER_MUTATION = gql`
  mutation DeleteAuthorizationProviderMutation($id: ID!) {
    deleteAuthorizationProvider(id: $id) {
      ok
      message
    }
  }
`;
