import {
  Button,
  Checkbox as AntCheckbox,
  Input,
  Radio,
  Select as AntSelect,
  Skeleton as AntSkeleton
} from "antd";
import styled from "styled-components";

import { Size } from "../../../../cssConstants";
import { ErrorField } from "../../StyledComponents";

const { TextArea: AntTextArea } = Input;

export const FormGridContainer = styled.section`
  display: grid;
  grid-template-columns: auto minmax(0, 4fr);
  grid-column-gap: ${props => props.theme.spacersm};
  grid-row-gap: ${props => props.theme.spacermd};
  align-items: center;
  max-height: 72vh;
  overflow: auto;
  padding: ${props => props.theme.modalPadding};
`;

export const GridLabel = styled.label<{ noTopMargin?: boolean }>`
  grid-column: 1 / 2;
  align-self: flex-start;
  ${props => (props.noTopMargin ? "" : `margin-top: ${props.theme.spacerxs};`)}
`;

export const GridLabelWide = styled(GridLabel)`
  grid-column: 1 / 3;
`;

interface GridFormItemProps {
  vertical?: boolean;
  alignStart?: boolean;
}

export const GridFormItem = styled.div<GridFormItemProps>`
  grid-column: 2 / 3;
  margin-bottom: 0;
  display: flex;
  flex-direction: ${props => (props.vertical ? "column" : "row")};
  align-items: ${props => (props.vertical ? "flex-start" : "center")};
`;

export const GridFormWideItem = styled(GridFormItem)`
  grid-column: 1 / 3;
`;

export const GridFormItemAlignStart = styled(GridFormItem)`
  align-items: flex-start;
`;

export const StyledErrorField = styled(ErrorField)`
  grid-column: 1 / 3;
  margin: 0;
`;

export const TextArea = styled(AntTextArea)`
  margin-top: ${props => props.theme.spacersm};
`;

export const DeleteButton = styled(Button)`
  margin-top: ${props => props.theme.spacerxl};
  margin: ${props => `${props.theme.spacerxl} ${props.theme.modalPadding} 0`};
  padding: 0;
`;

export const Checkbox = styled(AntCheckbox)`
  margin-top: ${props => props.theme.spacerxs};
  margin-bottom: ${props => props.theme.spacerxs};
`;

export const RadioGroup = styled(Radio.Group)`
  margin-bottom: ${props => props.theme.spacerxs};
`;

export const Section = styled.section`
  width: 100%;
  margin-top: ${props => props.theme.spacersm};
`;

export const Select = styled(AntSelect)`
  width: 100%;
`;

export const Skeleton = styled(AntSkeleton)`
  width: 100%;
`;

export const FieldWithErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormGridContainerWithMaxWidth = styled(FormGridContainer)`
  max-width: ${Size.md};
  flex-grow: 0;
`;
