import { useMutation, useQuery } from "@apollo/react-hooks";

import Message from "../../../../common/Message";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";

import {
  createCacheEvictor,
  UNPUBLISH,
  VERSIONS,
  Result,
  Vars,
  MutationResult
} from "./queries";

export default function useEnvironmentsToUnpublish(onClose: () => void) {
  const { spaceId } = useStableSpaceContext();
  const { data } = useQuery<Result, Vars>(VERSIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      spaceId
    },
    skip: !spaceId
  });
  const [unpublish, { loading }] = useMutation(UNPUBLISH, {
    update: (cache, response: { data: MutationResult }) =>
      createCacheEvictor(
        cache,
        spaceId,
        response.data.spaceUnpublish.environmentSpaceVersions
      ),
    onCompleted: () => {
      Message.success("Your Space has been unpublished.");
      onClose();
    }
  });
  return {
    mutationLoading: loading,
    unpublish: (environments: string[]) =>
      unpublish({ variables: { spaceId, environments } }),
    publishedEnvironments:
      data?.allEnvironmentSpaceVersions.edges
        .map(e => e.node.environment)
        .sort((a, b) => a.name.localeCompare(b.name)) || []
  };
}
