import React from "react";

import { Input } from "antd";

import { APISortByOption } from "../../../../../types";
import IconButton from "../../../IconButton";
import * as common from "../../common/styledComponents";
import { useListHelper } from "../../common/useListHelper";

import * as styled from "./styledComponents";

export const EMPTY_SORT_BY_OPTIONS_MESSAGE = "There are no sort options.";

export interface Props {
  editable: boolean;
  options: APISortByOption[];
  onOptionsChange: (options: APISortByOption[]) => void;
}

const createDefault = () => "";

export const SortByOptions = (props: Props) => {
  const {
    list: opts,
    onCreate,
    onUpdate,
    onDelete
  } = useListHelper(
    props.editable && !props.options.length ? [createDefault()] : props.options,
    createDefault,
    o => o,
    props.onOptionsChange
  );

  return props.editable || opts.length ? (
    <>
      <styled.SortByContent>
        <common.FieldColumnHeader>Name</common.FieldColumnHeader>
        <common.FieldColumnHeader />
        {opts.map((name, i) => (
          <React.Fragment key={`sortBy-${i}`}>
            <Input
              disabled={!props.editable}
              placeholder="Enter a name"
              value={name}
              onChange={evt => onUpdate(i, evt.target.value)}
            />
            <IconButton
              disabled={!props.editable}
              icon="minus"
              title="Remove"
              type="link"
              onClick={() => onDelete(i)}
            />
          </React.Fragment>
        ))}
      </styled.SortByContent>
      <common.IconButton
        disabled={!props.editable}
        type="link"
        icon="plus"
        title="Add Sort"
        onClick={onCreate}
      >
        Add Sort
      </common.IconButton>
    </>
  ) : (
    <div data-test="empty-sort-by-options">{EMPTY_SORT_BY_OPTIONS_MESSAGE}</div>
  );
};
