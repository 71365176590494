import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { publicClient } from "../../graphql";

import AlertModal from "./AlertModal";

const FETCH_FLASH_MESSAGES = gql`
  query FetchFlashMessages {
    messages {
      message
      level
    }
  }
`;

enum MessageLevel {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error"
}

const levelToTitle = {
  [MessageLevel.INFO]: "Information",
  [MessageLevel.SUCCESS]: "Success",
  [MessageLevel.WARNING]: "Warning",
  [MessageLevel.ERROR]: "Something went wrong"
};

export interface Message {
  message: string;
  level: MessageLevel;
}

const FlashMessages = () => {
  const { data } = useQuery<{ messages: Message[] }>(FETCH_FLASH_MESSAGES, {
    client: publicClient
  });

  // message rendering management
  const flash = React.useMemo(() => {
    return (data?.messages || []).map((m, idx) => (
      <AlertModal
        key={`flash-${idx}`}
        title={levelToTitle[m.level]}
        type={m.level}
        showIcon
      >
        {m.message}
      </AlertModal>
    ));
  }, [data?.messages]);

  return <>{flash}</>;
};

export default FlashMessages;
