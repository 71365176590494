import React from "react";

import { throttle } from "lodash";
import styled from "styled-components";

import { SpacingUnitValue } from "../../../../cssConstants";
import useMouseCoords from "../../../common/hooks/useMouseCoords";
import useObservedRect from "../../../common/hooks/useObservedRect";
import { useResizeContext } from "../../SpaceRoot/ResizeContext";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import {
  BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD,
  RELATIVE_GRID_SIZE
} from "../constants";
import { DimensionsContextContainer, useDimensionsContext } from "../DimensionsContext";
import {
  useLayoutContextDispatcher,
  useBoundaryContext
} from "../LayoutContext/LayoutContext";
import { useTransformationStateContext } from "../TransformationContext/TransformationContext";
import {
  subtractPoints,
  direction,
  DOMRectsEqual,
  DOMPointsEqual,
  TransformationType,
  LayoutUnit
} from "../util";

const CanvasViewportContext = React.createContext({
  viewportRect: new DOMRect(),
  viewportOffset: new DOMPoint(),
  canvasRect: new DOMRect(),
  getCanvasPointForPagePoint: (_pagePoint: DOMPoint) => new DOMPoint()
});
export default CanvasViewportContext;

export const useCanvasViewportContext = () => React.useContext(CanvasViewportContext);

export const VolatileCanvasViewportContext = React.createContext({
  scrollTop: 0,
  cursorDistanceFromTop: -1,
  cursorDistanceFromBottom: -1
});
export const useVolatileCanvasViewportContext = () =>
  React.useContext(VolatileCanvasViewportContext);

export function CanvasViewportProvider({ children }: { children: React.ReactNode }) {
  // HACK: just tracking this to get a re-render after canvas scroll
  const [_lastScrollTimestamp, setLastScrollTimestamp] = React.useState(0);
  const [mouseLeaveDirection, setMouseLeaveDirection] =
    React.useState<direction | null>(null);
  const [transformationOffset, setTransformationOffset] = React.useState(
    new DOMPoint()
  );
  const adjusterRef = React.useRef<HTMLDivElement>(null);
  const viewportRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<HTMLDivElement>(null);
  const scrollTop = viewportRef.current?.scrollTop || 0;

  const { editMode } = useStableSpaceContext();
  const { transformations } = useTransformationStateContext();
  const { boundaryRect } = useBoundaryContext();
  const { hasResizedRecently } = useResizeContext();
  const layoutDispatch = useLayoutContextDispatcher();
  const mouseCoords = useMouseCoords();

  const adjusterRect = useObservedRect(adjusterRef);
  const viewportRect = useObservedRect(viewportRef);
  const canvasRect = useObservedRect(canvasRef);

  // When the mouse leaves the page track the direction it exited so
  // autoscroll can continue
  React.useEffect(() => {
    function onMouseLeave(e: MouseEvent) {
      if (e.clientY <= 0) {
        setMouseLeaveDirection(direction.TOP);
      } else if (e.clientY >= window.innerHeight) {
        setMouseLeaveDirection(direction.BOTTOM);
      } else if (e.clientX <= 0) {
        setMouseLeaveDirection(direction.LEFT);
      } else if (e.clientX >= window.innerWidth) {
        setMouseLeaveDirection(direction.RIGHT);
      }
    }
    function onMouseEnter() {
      setMouseLeaveDirection(null);
    }
    window.document.body.addEventListener("mouseleave", onMouseLeave);
    window.document.body.addEventListener("mouseenter", onMouseEnter);
    return () => {
      if (window.document.body === null) return;
      window.document.body.removeEventListener("mouseleave", onMouseLeave);
      window.document.body.removeEventListener("mouseenter", onMouseEnter);
    };
  }, []);

  // If the canvas rect doesn't evenly divide into the grid adjust, calculate amount to
  // adjust it by so that it does
  const gridAdjustment = React.useMemo(() => {
    if (hasResizedRecently || adjusterRect.width === 0) {
      return -1;
    } else if (!editMode) {
      return 0;
    }

    const divisor = 1 / RELATIVE_GRID_SIZE;
    const remainder = adjusterRect.width % divisor;

    return remainder / 2;
  }, [editMode, hasResizedRecently, adjusterRect]);

  const viewportOffset = React.useMemo(() => {
    return new DOMPoint(viewportRect?.left || 0, viewportRect?.top || 0);
  }, [viewportRect]);

  const getCanvasPointForPagePoint = React.useCallback(
    function getCanvasPointPointForPagePoint(pagePoint: DOMPoint) {
      const offsetAddjustedPoint = subtractPoints(pagePoint, viewportOffset);
      return new DOMPoint(
        offsetAddjustedPoint.x,
        offsetAddjustedPoint.y + (viewportRef.current?.scrollTop || 0)
      );
    },
    [viewportOffset, viewportRef]
  );

  const canvasMouseCoords = React.useMemo(() => {
    return getCanvasPointForPagePoint(mouseCoords.page);
  }, [mouseCoords, getCanvasPointForPagePoint]);

  const deepestComponentPoint = boundaryRect.bottom;

  const throttledHandleScroll = React.useRef(
    throttle(() => {
      setLastScrollTimestamp(Date.now());
      layoutDispatch({
        type: "UPDATE_CANVAS_SCROLL_TOP",
        payload: { canvasScrollTop: viewportRef.current?.scrollTop || 0 }
      });
    }, 25)
  );

  const transformationBounds = React.useMemo(() => {
    if (transformations.size === 0) return new DOMRect();
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;

    transformations.forEach(({ currentLayout }) => {
      const rect = currentLayout.toRect();
      minX = Math.min(minX, rect.left);
      minY = Math.min(minY, rect.top);
      maxX = Math.max(maxX, rect.right);
      maxY = Math.max(maxY, rect.bottom);
    });

    return new DOMRect(minX, minY, maxX - minX, maxY - minY);
  }, [transformations]);

  const transformationType =
    transformations.size > 0
      ? transformations.entries().next().value[1].transformationType
      : TransformationType.NONE;

  // Calculate the offset from the tranformationBounds to the current cursor position
  // TODO: See if this can be refactored to not be an effect. canvasMouseCoords are
  // throttled but still causing a lot of double renders on every change
  React.useEffect(() => {
    if (DOMRectsEqual(transformationBounds, new DOMRect())) {
      if (!DOMPointsEqual(transformationOffset, new DOMPoint())) {
        setTransformationOffset(new DOMPoint());
      }
      return;
    }

    // Only track the offset at the start of a transform, not on each mouse move
    if (!DOMPointsEqual(transformationOffset, new DOMPoint())) return;
    const offset = subtractPoints(
      canvasMouseCoords,
      new DOMPoint(transformationBounds.x, transformationBounds.y)
    );

    if (DOMPointsEqual(offset, transformationOffset)) return;
    setTransformationOffset(offset);
  }, [
    canvasMouseCoords,
    transformationBounds,
    transformationOffset,
    setTransformationOffset
  ]);

  const deepestCurrentTransformPoint = transformationBounds.bottom;

  const cursorDistanceFromTop = React.useMemo(() => {
    if (mouseCoords.page.y === -1) return Number.MAX_SAFE_INTEGER;
    return Math.max(0, mouseCoords.page.y - viewportOffset.y);
  }, [mouseCoords, viewportOffset]);

  const cursorDistanceFromBottom = React.useMemo(() => {
    if (mouseLeaveDirection === direction.BOTTOM) return 0;
    return Math.max(0, viewportRect.height - (mouseCoords.page.y - viewportOffset.y));
  }, [mouseCoords, viewportOffset, mouseLeaveDirection, viewportRect.height]);

  // Calculate how "tall" the canvas is by finding the bottom edge of the
  // deepest component, including those currently undergoing transformation.
  // Further ensure visible portions of canvas are not removed if the deepest component
  // is moved up. If that retained portion is scrolled out of view it is collected.
  // This effect is also responsible for autoscrolling to keep transforming elements
  // in view.
  const withinAutoScrollTopBoundary =
    cursorDistanceFromTop < BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD;
  const withinAutoScrollBottomBoundary =
    cursorDistanceFromBottom < BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD;
  React.useLayoutEffect(() => {
    const viewportEl = viewportRef.current;
    const canvasEl = canvasRef.current;

    if (viewportEl === null || canvasEl === null) return;

    let nextScrollTop = viewportEl.scrollTop;
    const hasActiveTransform = deepestCurrentTransformPoint > 0;
    const scrollTopToDeepestCurrentTransform =
      deepestCurrentTransformPoint - viewportRect.height;
    if (withinAutoScrollBottomBoundary && hasActiveTransform) {
      // Adjust by distance between transformation bottom edge and cursor
      nextScrollTop = scrollTopToDeepestCurrentTransform;
      if (transformationType === TransformationType.MOVE) {
        nextScrollTop =
          nextScrollTop - (transformationBounds.height - transformationOffset.y);
      }
    } else if (withinAutoScrollTopBoundary && hasActiveTransform) {
      nextScrollTop = transformationBounds.top;
      if (transformationType === TransformationType.MOVE) {
        nextScrollTop = nextScrollTop + transformationOffset.y;
      }
    }

    let nextHeight = Math.max(
      viewportRect.height,
      deepestComponentPoint,
      deepestCurrentTransformPoint,
      nextScrollTop + viewportRect.height - 1
    );
    if (Number.isNaN(nextHeight)) {
      nextHeight = viewportRect.height;
    }
    let currentHeight = Math.max(
      parseFloat(canvasEl.style.height),
      viewportRect.height
    );
    if (Number.isNaN(currentHeight)) {
      currentHeight = viewportRect.height;
    }

    const preventShrinkThreshold = 4; // Unit is %
    if (
      currentHeight > nextHeight &&
      currentHeight - nextHeight < preventShrinkThreshold
    ) {
      nextHeight = currentHeight;
    }
    // If the bottom edge is within the gutter's height of the bottom of the viewport
    // and there is not already a gutter added and accounted for add in a gutter.
    const bottomGutter =
      nextHeight - SpacingUnitValue.md > viewportRect.height &&
      deepestComponentPoint + SpacingUnitValue.md >= nextHeight
        ? SpacingUnitValue.md
        : 0;
    canvasEl.style.height = Math.floor(nextHeight) + bottomGutter + LayoutUnit.PIXEL;
    viewportEl.scrollTop = nextScrollTop;
  }, [
    _lastScrollTimestamp,
    withinAutoScrollTopBoundary,
    withinAutoScrollBottomBoundary,
    deepestComponentPoint,
    deepestCurrentTransformPoint,
    viewportRect,
    transformationBounds,
    transformationType,
    transformationOffset
  ]);

  const volatileCanvasContextValue = React.useMemo(() => {
    return {
      scrollTop,
      cursorDistanceFromTop,
      cursorDistanceFromBottom
    };
  }, [scrollTop, cursorDistanceFromTop, cursorDistanceFromBottom]);

  const value = React.useMemo(() => {
    return {
      viewportRect,
      viewportOffset,
      canvasRect,
      getCanvasPointForPagePoint
    };
  }, [viewportRect, viewportOffset, canvasRect, getCanvasPointForPagePoint]);

  const widthAdjustment = editMode ? gridAdjustment : SpacingUnitValue.xl;
  const heightAdjustment = editMode ? 0 : SpacingUnitValue.xl;
  const height = editMode ? "100%" : undefined;

  const ScrollContainer = editMode ? EditModeScrollContainer : ViewModeScrollContainer;

  const hasVertScrolls =
    editMode ||
    parseFloat(canvasRef.current?.style.height || "0") >
      Math.floor(viewportRect.height || 0);

  return (
    <CanvasViewportContext.Provider value={value}>
      <VolatileCanvasViewportContext.Provider value={volatileCanvasContextValue}>
        <DimensionsAdjuster
          ref={adjusterRef}
          style={{
            height,
            paddingTop: `${heightAdjustment}px`,
            paddingLeft: `${widthAdjustment}px`,
            paddingRight: `${widthAdjustment}px`
          }}
        >
          <ScrollContainer
            ref={viewportRef}
            className="scrollContainer"
            style={{
              opacity: hasResizedRecently ? 0.5 : 1.0,
              overflowY: hasVertScrolls ? "scroll" : "hidden"
            }}
            onScroll={() => {
              // Need to trigger re-renders on scroll :O
              throttledHandleScroll.current();
            }}
          >
            <DimensionsContextContainer waitUntilReady>
              <CanvasContainer ref={canvasRef} className="canvasContainer">
                <DimensionsContextRegisterer>{children}</DimensionsContextRegisterer>
              </CanvasContainer>
            </DimensionsContextContainer>
          </ScrollContainer>
        </DimensionsAdjuster>
      </VolatileCanvasViewportContext.Provider>
    </CanvasViewportContext.Provider>
  );
}

const DimensionsAdjuster = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const BaseScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const EditModeScrollContainer = styled(BaseScrollContainer)`
  margin-top: 1px;
  outline: solid 1px ${props => props.theme.borderGrey};
`;

const ViewModeScrollContainer = React.forwardRef(function ViewModeScrollContainer(
  props: {
    children: React.ReactNode;
    style: React.CSSProperties;
    onScroll: () => void;
  },
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <BaseScrollContainer>
      <ViewModeChildContainer ref={ref}>{props.children}</ViewModeChildContainer>
    </BaseScrollContainer>
  );
});

const CanvasContainer = styled.div``;
const ViewModeChildContainer = styled.div`
  height: 100%;
  width: 100%;
`;

function DimensionsContextRegisterer({ children }: { children: React.ReactNode }) {
  const dispatch = useLayoutContextDispatcher();
  const { key } = useDimensionsContext();
  React.useEffect(() => {
    if (!key) return;
    dispatch({
      type: "UPDATE_COMPONENT_DIMENSIONS_CONTEXT_MAPPING",
      payload: { componentSlug: "root", dimensionsContextKey: key }
    });
  }, [key, dispatch]);

  return <>{children}</>;
}
