import React from "react";

import { userToInitials } from "../../../../../common/Avatar/Avatar";
import ButtonNew from "../../../../../common/ButtonNew/ButtonNew";
import Message from "../../../../../common/Message";
import { useColorConverter } from "../../../../../common/useColorCoverter/useColorCoverter";
import { CommentListDispatch, ReducerActions } from "../CommentList/reducer";
import { CommentNode } from "../queries";

import { useUpdateComment } from "./queries";
import * as Styled from "./styledComponents";

export interface Props {
  comment: CommentNode;
  dispatch: CommentListDispatch;
  onCancel?: () => void;
  onCompleted?: () => void;
}

export function UpdateComment({ comment, dispatch, onCancel, onCompleted }: Props) {
  const [content, setContent] = React.useState(comment.content);
  const [updateComment, { loading, error }] = useUpdateComment({
    onCompleted: () => onCompleted?.()
  });

  React.useEffect(() => {
    if (error) Message.error("Something went wrong. Please try again.");
  }, [error]);

  const color = useColorConverter(comment.author.id);

  const submitUpdate = React.useCallback(
    async (id: string, content: string) => {
      await updateComment({
        variables: {
          id,
          content
        }
      });

      dispatch({
        type: ReducerActions.Update,
        payload: {
          commentId: id,
          content: content
        }
      });
    },
    [updateComment, dispatch]
  );

  return (
    <Styled.Container>
      <Styled.Avatar color={color}>{userToInitials(comment.author)}</Styled.Avatar>
      <Styled.EditArea>
        <Styled.TextArea
          value={content}
          onChange={e => setContent(e.currentTarget.value)}
          rows={3}
        />
        <Styled.ButtonContainer>
          <ButtonNew size="sm" onClick={() => onCancel?.()}>
            Cancel
          </ButtonNew>
          <ButtonNew
            size="sm"
            type="primary"
            disabled={loading}
            loading={loading}
            onClick={() => submitUpdate(comment.id, content)}
          >
            Save
          </ButtonNew>
        </Styled.ButtonContainer>
      </Styled.EditArea>
    </Styled.Container>
  );
}
