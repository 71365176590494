import styled from "styled-components";

import { Icon as MaterialIcon } from "../Icons/MaterialIcons";

export const IconSelectOptionsWrapper = styled.div`
  .ant-select-dropdown-menu-item {
    display: inline-block;
  }
`;

export const Icon = styled(MaterialIcon)<{ color: string }>`
  font-size: 30px;
  color: ${props => props.color};
`;
