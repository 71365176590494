import React from "react";

import { templateToString } from "../../../../../common/utils";
import { assertNever } from "../../../../../util/assertNever";
import { parsePath } from "../../../../../util/binding";

import {
  ConditionalExpression,
  ConditionalOperatorDisplayNames,
  ManagedConditionalExpression
} from "./types";
import { getObjectMode } from "./util";

import { ConditionalOperator } from ".";

interface ConditionalSynopsisProps {
  expression: ConditionalExpression;
}

export default function ConditionalSynopsis({ expression }: ConditionalSynopsisProps) {
  return (
    <div>
      {expression.type === "evaluated" && (
        <span title={expression.template}>{expression.template}</span>
      )}
      {expression.type === "managed" && (
        <ManagedConditionalSynopsis expression={expression} />
      )}
    </div>
  );
}

const getOperatorDisplayName = (operatorType: ConditionalOperator) =>
  ConditionalOperatorDisplayNames.get(operatorType) || operatorType;

function ManagedConditionalSynopsis({
  expression
}: {
  expression: ManagedConditionalExpression;
}) {
  const mode = getObjectMode(expression.object_template);
  const getTemplateSynopis = (template: string) => {
    const mode = getObjectMode(template);
    switch (mode) {
      case "void":
        return "";
      case "text":
        return template;
      case "binding":
        const bindingParts = parsePath(template);
        return bindingParts.length > 0
          ? bindingParts[bindingParts.length - 1]
          : undefined;
      default:
        assertNever(mode);
    }
  };
  const ruleSubject = getTemplateSynopis(expression.subject_template || "");
  const ruleObject =
    mode === "binding"
      ? getTemplateSynopis(expression.object_template || "")
      : mode === "text"
      ? templateToString(expression.object_template)
      : "";

  const ruleDescription = ruleSubject
    ? `${ruleSubject} ${getOperatorDisplayName(expression.operator)} ${ruleObject}`
    : "Pending rule";

  return <>{ruleDescription}</>;
}
