import Icon from "../../../../../assets/spaceicons/calendar.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";

import Config, { InlineConfig, TerseConfig } from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceDateTimePickerComponent,
  makeInitialState,
  DateTimePickerConfigState,
  getComponentProperties
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceDateTimePicker from "./SpaceDateTimePicker";
import { SpaceDateTimePickerComponent, SpaceDateTimePickerProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceDateTimePickerProperties,
  SpaceDateTimePickerComponent,
  DateTimePickerConfigState
> = {
  type: "DATE_TIME_PICKER",
  displayName: "Date Time Picker",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FUNCTION_FORM",
    "FUNCTION_MODAL_FORM",
    "FLEX_BOX"
  ]),
  Component: SpaceDateTimePicker,
  ConfigurationComponent: Config,
  TerseConfigurationComponent: TerseConfig,
  InlineConfigurationComponent: InlineConfig,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceDateTimePickerComponent,
  getComponentProperties,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.INPUT.width,
    height: DEFAULT_SIZES.INPUT.height,
    snapToGrid: false
  }),
  fallbackWidth: 178,
  allowSelfBinding: true,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Input],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
