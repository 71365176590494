import React from "react";

import { Skeleton } from "antd";

import JsonViewer from "../../../JsonViewer";
import { PreviewError } from "../reducer";

import * as styled from "./styledComponents";
import { ValidationFeedback } from "./ValidationFeedback";

interface Props {
  data?: any;
  dataException?: string;
  metadata?: any;
  metadataException?: string;
  loading: boolean;
  error?: PreviewError;
  isValid?: boolean;
}

export const FunctionResponse = ({
  data,
  dataException,
  metadata,
  metadataException,
  isValid,
  error,
  loading
}: Props) => {
  return (
    <styled.Container>
      {loading && <Skeleton active />}

      <styled.ErrorMessageField errorMessage={error?.message} />

      {!loading && !error && (
        <>
          <styled.Section>
            <styled.Title>Data</styled.Title>
            {dataException ? (
              <styled.ErrorMessageField errorMessage={dataException} />
            ) : (
              <>
                <ValidationFeedback isValid={isValid} />
                <JsonViewer json={data} rawMode={true} defaultExpandedDepth={1} />
              </>
            )}
          </styled.Section>
          <styled.Section>
            <styled.Title>Metadata</styled.Title>

            {metadataException ? (
              <styled.ErrorMessageField errorMessage={metadataException} />
            ) : (
              <JsonViewer json={metadata} rawMode={true} defaultExpandedDepth={1} />
            )}
          </styled.Section>
        </>
      )}
    </styled.Container>
  );
};
