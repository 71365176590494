import { Button, Icon, Input } from "antd";
import { default as BaseForm } from "antd/lib/form";
import styled from "styled-components";

import { colors, defaultTheme } from "../../../../../../cssConstants";
import { default as BaseAvatar } from "../../../../../common/Avatar/Avatar";

const { TextArea } = Input;

export const Avatar = styled(BaseAvatar)`
  align-self: flex-start;
  margin-right: 16px;
  margin-top: 3px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Form = styled(BaseForm)`
  flex-grow: 1;
`;

export const FormItem = styled(Form.Item)`
  width: 100%;

  &.ant-form-item {
    margin-right: 0;
  }

  .ant-form-item-control-wrapper {
    width: 100%;
  }
`;

export const GrowingInputContainer = styled.div`
  display: flex;
  align-items: start;
  border: ${defaultTheme.inputBorder};
  border-radius: 4px;
  padding: 4px 11px;
  transition: all 0.3s, height 0s;

  &:hover,
  &:focus,
  &.active {
    border: ${defaultTheme.inputHoverBorder};
    box-shadow: 0 0 0 2px ${defaultTheme.primaryColorBackgroundHover};
  }
`;

export const GrowingTextArea = styled(TextArea)`
  border: none;
  resize: none;
  padding: 0 4px 0 0;

  &.ant-input {
    margin-bottom: 0;

    &:hover,
    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }
`;

export const SubmitButtonInterior = styled(Button)`
  flex-shrink: 0;

  &.ant-btn.ant-btn-primary {
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;

    &:disabled {
      background-color: ${colors.buttonSecondaryFill};
    }
  }
`;

export const SubmitIcon = styled(Icon)<{ disabled?: boolean }>`
  color: ${props => (!!props.disabled ? colors.buttonDisabledText : "white;")};
`;
