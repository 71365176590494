import React from "react";

export default function useDebouncedValue<T>(
  value: T,
  delay: number,
  flush?: boolean
): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const timeoutHandleRef = React.useRef<any>(-1);

  React.useEffect(
    () => {
      // Update debounced value after delay
      timeoutHandleRef.current = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(timeoutHandleRef.current);
      };
    },

    [value, delay] // Only re-call effect if value or delay changes
  );

  React.useEffect(() => {
    if (flush) {
      clearTimeout(timeoutHandleRef.current);
      if (value !== debouncedValue) {
        setDebouncedValue(value);
      }
    }
  }, [value, debouncedValue, flush]);

  return flush ? value : debouncedValue;
}
