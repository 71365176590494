/*
  useView

  Responsible for querying data for all view backed space components.
  Makes 2 queries: The first fetches the data view and the second fetches rows for
  that view. This is important for 2 reasons:

    - Space components define filters in their properties using the sourceName of
      the attribute being filtered upon, while view queries require the id of the
      attribute when applying filters, so the sourceName must be mapped to its id
      prior to querying
    - Space components may wish to delay querying for view data rows until some
      or all filters have been applied to the query. Importantly they still
      need to query for the attributes of the view so that manual filters can
      be applied.
*/

import React from "react";

import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import { NetworkStatus } from "apollo-client";
import produce from "immer";
import { get, isEqual, isObjectLike } from "lodash";

import { ErrorValue, ErrorValues } from "../../../../../../constants";
import {
  AttributeNode,
  BindingShape,
  bindingToAttribute,
  ClientErrorResult,
  DataValue,
  Edge,
  FiltersOption,
  FunctionAttributeNode,
  SortByOption,
  SortOrder,
  SourceType,
  SpaceComponentObject,
  StatusCode,
  ViewDataNode,
  ViewDataResultSuccess,
  ViewFilter,
  ViewNode,
  FunctionNode
} from "../../../../../../types";
import { useEnvironmentContext } from "../../../../../common/contexts/EnvironmentContext";
import Message from "../../../../../common/Message";
import { encode as b64Encode } from "../../../../../util/base64";
import { createSpaceFunction } from "../../../../FunctionExecutor/FunctionExecutor";
import { useSpaceConsoleContext } from "../../../SpaceConsoleContext/SpaceConsoleContext";
import { useSpaceContext, useStableSpaceContext } from "../../../SpaceContext";
import { SpaceStateInputs } from "../../SpaceComponent";
import {
  AttributeColumn,
  ColumnType,
  ComponentColumn,
  isAttributeColumn
} from "../ColumnListManager";
import useFuncParams from "../useFuncParams";
import { useAccess } from "../useFunctionAccess";
import { extractFiltersOptions, extractSortByOptions } from "../util";

import insertComponentColumns from "./insertComponentColumns";
import { VIEW_ROWS_QUERY, ViewRowsVariables } from "./queries";
import useFilters from "./useFilters";
import { ColumnViewMapping, createColumnViewMapping } from "./util";

export type AttributeColumnTuple = [
  ColumnType.ATTRIBUTE,
  AttributeColumn,
  AttributeNode | FunctionAttributeNode,
  DataValue
];
export type ComponentColumnRecord = [
  ColumnType.COMPONENT,
  ComponentColumn,
  SpaceComponentObject
];

export type ColumnRecord = AttributeColumnTuple | ComponentColumnRecord;
export interface Row {
  id: string;
  columns: ColumnRecord[];
}

export enum FetchType {
  NONE = "NONE",
  INITIAL_FETCH = "INITIAL_FETCH", // Query's first run
  FETCHING_MORE = "FETCHING_MORE", // Paging same query
  REFETCHING = "REFETCHING" // Changing to different query
}

export enum QueryExecutionRequirement {
  NONE = "NONE",
  ANY_FILTER = "ANY_FILTER",
  ALL_CONFIG_FILTERS = "ALL_CONFIG_FILTERS"
}

export interface ViewResult {
  sourceType: SourceType;
  bindingsSatisfied: boolean; // Filters defined in component properties have all been resolved
  loading: boolean; // Any sort of fetch in flight
  fetchType: FetchType; // Enum of currently in flight fetch type
  rows: Row[] | null; // Current set of rows with their columns
  filters: ViewFilter[]; // All currently applied filters.
  filtersOptions: FiltersOption[] | undefined; // All available options for filtering. Is undefined until view loaded.
  sortByOptions: SortByOption[]; // All available options for sorting.
  orders: SortOrder[]; // All currently applied sorts
  viewFunction: FunctionNode | null; // Root resources of the view
  attributes: (AttributeNode | FunctionAttributeNode)[]; // All constituent attributes of the view
  hasNextPage: boolean; // Whether another page of results exist for filter and sort
  errorCode?: StatusCode; // If error present the error's code
  viewMissing: boolean; // Flag indicating underyling resource of view was removed
  lastRefetch: number; // Timestamp of last refetch, meaning a change to the query that was not a fetchMore or refresh
  queryVariables: Partial<ViewRowsVariables>; // Variables used for the view rows query. Used by csv export to fetch data to generate csv.
  columnViewMapping: ColumnViewMapping; // Datastructure to map columns to view row indexes.
  fetchMore: () => void; // Get the next set of results and append to current rows
  refresh: () => void; // Refetch with a limit matching current count of rows and do not change lastRefetch. The limit for the query is permanently set to the new limit.
  sort: (sortOrder: SortOrder | null) => void; // Apply new manual sort
  filter: (filters: ViewFilter[], forceRefetch?: boolean) => void; // Apply new set of manual filters
  getUniqueFilterClientId: (prefix: string) => string; // Returns a string which is not already being used to track a client side filter
}

const extractError = (data: ViewDataNode | undefined): StatusCode | undefined => {
  if (data?.__typename !== "ClientErrorResult") return;
  return (data as ClientErrorResult).code;
};

function selectFiltersOptions(
  func: FunctionNode | undefined
): FiltersOption[] | undefined {
  return func ? extractFiltersOptions(func.metadata!) : undefined;
}

function selectSortByOptions(func: FunctionNode | undefined): SortByOption[] {
  return func ? extractSortByOptions(func.metadata!) : [];
}

function selectRecordRows(
  columns: AttributeColumn[] | null,
  attributes: (AttributeNode | FunctionAttributeNode)[] | undefined,
  rowEdges: Edge<{ id: string; row: DataValue[] }>[] | undefined,
  rowDataMask: { [key: string]: ErrorValue }
): Row[] | null {
  if (!columns?.length || !attributes?.length || !rowEdges?.length) return null;

  const rowIdHash: { [slug: string]: number } = {};
  function dedupeId(id: string): string {
    if (!rowIdHash[id]) {
      rowIdHash[id] = 1;
      return id;
    }
    const uniqueId = `${id}-${rowIdHash[id]}`;
    rowIdHash[id] = rowIdHash[id] + 1;
    return uniqueId;
  }

  return rowEdges.map(e => ({
    id: dedupeId(e.node.id),
    columns: e.node.row
      .filter((_r, i) => !!attributes[i])
      .map((_val, i) => {
        const val = rowDataMask[attributes[i].id] || _val;
        return [ColumnType.ATTRIBUTE, columns[i], attributes[i], val];
      })
  }));
}

const REFETCH_STATUS_CODES = [NetworkStatus.setVariables, NetworkStatus.refetch];
function getFetchType(viewRowsResult: QueryResult<any>) {
  if (
    viewRowsResult.networkStatus === undefined ||
    viewRowsResult.networkStatus === NetworkStatus.loading
  ) {
    return FetchType.INITIAL_FETCH;
  }
  if (viewRowsResult.networkStatus === NetworkStatus.fetchMore)
    return FetchType.FETCHING_MORE;
  if (REFETCH_STATUS_CODES.includes(viewRowsResult.networkStatus))
    return FetchType.REFETCHING;

  return FetchType.NONE;
}

interface ViewRowsOptions {
  limit: number;
  clearRowsOnVariablesChange: boolean;
  queryExecutionRequirement: QueryExecutionRequirement;
}

const DEFAULT_LIMIT = 12;

const EMPTY_FILTER_SET: ViewFilter[] = [];

const orderByColumn = (
  attributes: FunctionAttributeNode[],
  columns: (AttributeColumn | ComponentColumn)[] | null
): FunctionAttributeNode[] => {
  const ret: FunctionAttributeNode[] = [];
  if (columns === null) {
    return ret;
  }

  for (let i = 0; i < columns.length; ++i) {
    const c = columns[i];
    if (c.column_type !== ColumnType.ATTRIBUTE) continue;
    const attribute = attributes.find(a => a.sourceName === c.attribute);
    if (attribute) {
      ret.push(attribute);
    }
  }
  return ret;
};

export default function useView(
  spaceId: string | undefined,
  component: SpaceComponentObject,
  input: SpaceStateInputs | null,
  {
    limit = DEFAULT_LIMIT,
    clearRowsOnVariablesChange = true,
    queryExecutionRequirement = QueryExecutionRequirement.NONE
  }: Partial<ViewRowsOptions>
): ViewResult {
  const { addClientError } = useSpaceConsoleContext();
  const { editMode } = useStableSpaceContext();
  const { getBinding } = useSpaceContext();
  const environment = useEnvironmentContext().getCurrentEnvironment();
  const [manualSortOrder, setManualSortOrder] = React.useState<SortOrder | null>(null);
  const [lastNetworkStatus, setLastNetworkStatus] = React.useState(0);
  const [lastRefetch, setLastRefetch] = React.useState(0);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [currentLimit, setCurrentLimit] = React.useState(limit);

  const fetchPolicy = editMode ? "cache-first" : "cache-and-network";
  const view = component.view;
  const sourceType = component.sourceType!;

  const func = component.functions.edges.length
    ? component.functions.edges[0].node
    : undefined;

  const orderedFunctionAttributes = React.useMemo(
    () =>
      sourceType === SourceType.VIEW && func
        ? orderByColumn(
            func.functionAttributes?.edges.map(e => e.node) || [],
            component.properties.columns
          )
        : [],
    [sourceType, func, component.properties.columns]
  );

  const bindingAttrs = React.useMemo(() => {
    if (sourceType === SourceType.BINDING) {
      const binding = getBinding(component.properties.binding);
      return binding &&
        (binding.shape === BindingShape.OBJECT ||
          binding.shape === BindingShape.OBJECT_ARRAY)
        ? binding.attributes.map(b => bindingToAttribute(b))
        : [];
    }
    return [];
  }, [sourceType, component.properties.binding, getBinding]);

  const attrs: (AttributeNode | FunctionAttributeNode)[] =
    sourceType === SourceType.BINDING ? bindingAttrs : orderedFunctionAttributes;

  const filtersOptions = React.useMemo(
    () => (sourceType === SourceType.VIEW ? selectFiltersOptions(func) : undefined),
    [sourceType, func]
  );

  const sortByOptions = React.useMemo(
    () => (sourceType === SourceType.VIEW ? selectSortByOptions(func) : []),
    [sourceType, func]
  );

  const filtersResult = useFilters({
    input,
    filterConfigs: component.properties.filters,
    filtersOptions,
    syncToLocationFilters: !!component.properties.is_resource_table
  });

  const orders = React.useMemo(
    () =>
      !!manualSortOrder
        ? [manualSortOrder]
        : (component.properties.order || [])
            .map((o: { attribute: string; direction: string }) => {
              const sortByOption = sortByOptions.find(
                option => option.sourceName === o.attribute
              );
              if (!sortByOption) return null;
              return {
                sourceName: sortByOption.sourceName,
                direction: o.direction.toUpperCase()
              };
            })
            .filter(Boolean),
    [component.properties.order, manualSortOrder, sortByOptions]
  );

  const { hasRequiredValues, hasValidValues, funcParams } = useFuncParams(
    createSpaceFunction(component, {
      overrideFunction: func
    }),
    component.properties.input_parameters || [], // TODO: Backfill input_parameters for all components
    input
  );

  function queryRequirementsMet() {
    if (!hasRequiredValues || !hasValidValues) {
      return false;
    }
    switch (queryExecutionRequirement) {
      case QueryExecutionRequirement.NONE:
        return true;
      case QueryExecutionRequirement.ANY_FILTER:
        return !!(filtersResult.filters || []).length;
      case QueryExecutionRequirement.ALL_CONFIG_FILTERS:
        return filtersResult.allConfigFiltersSatisfied;
      default:
        throw new Error(
          `Unknown QueryExecutionRequirement ${queryExecutionRequirement}`
        );
    }
  }

  // start: VIEW_ROWS_QUERY query specific (may be able to move)
  const shouldQueryRows =
    sourceType === SourceType.VIEW &&
    view?.id &&
    filtersResult.ready &&
    queryRequirementsMet();

  const viewRowsVariables = {
    id: view?.id,
    environmentId: environment.id,
    spaceId,
    parameters: funcParams,
    filters: filtersResult.requestFilters,
    orders,
    limit: currentLimit,
    columns: orderedFunctionAttributes.map(a => a.sourceName)
  };

  const viewRowsResult = useQuery<{ node: ViewNode }, Partial<ViewRowsVariables>>(
    VIEW_ROWS_QUERY,
    {
      variables: viewRowsVariables,
      skip: !shouldQueryRows,
      notifyOnNetworkStatusChange: true,
      fetchPolicy,
      onCompleted: () => {
        if (isRefreshing) {
          setIsRefreshing(false);
          return;
        }
        if (REFETCH_STATUS_CODES.includes(lastNetworkStatus)) {
          setLastRefetch(Date.now());
        }
      }
    }
  );

  const viewDataNode = viewRowsResult.data?.node?.data;
  const viewRowsData = (viewDataNode as ViewDataResultSuccess)?.rows;

  React.useEffect(() => {
    if (viewDataNode?.__typename === "ClientErrorResult") {
      addClientError(viewDataNode);
    }
  }, [addClientError, viewDataNode]);

  React.useEffect(() => {
    if (viewRowsResult.error) Message.error("An unknown error occurred.");
  }, [viewRowsResult.error]);

  React.useEffect(() => {
    if (viewRowsResult.networkStatus === lastNetworkStatus) return;
    setLastNetworkStatus(viewRowsResult.networkStatus);
  }, [lastNetworkStatus, setLastNetworkStatus, viewRowsResult.networkStatus]);

  const networkStatus = viewRowsResult.networkStatus;
  const rowEdges = viewRowsData?.edges;
  // end: VIEW_ROWS_QUERY query specific

  const columns = React.useMemo(
    () => component.properties.columns?.filter(isAttributeColumn) || [],
    [component.properties.columns]
  );

  // start: binding data specific
  const { binding } = component.properties;
  const bindingRows = React.useMemo(() => {
    const rows = get(input, binding);
    return Array.isArray(rows) ? rows : isObjectLike(rows) ? [rows] : [];
  }, [input, binding]);
  // end: binding data specific

  const access = useAccess(func?.access);
  const rowDataMask = React.useMemo(
    () =>
      Object.fromEntries(
        attrs
          .filter(a => !access.attributeAllowed(a.sourceName))
          .map(a => [a.id, ErrorValues.permissionDenied])
      ),
    [attrs, access]
  );

  const rows = React.useMemo(() => {
    // Convert source binding rows into something that row aware components can consume.
    if (sourceType === SourceType.BINDING) {
      return bindingRows.map((r: Record<string, any>) => {
        let id = String(r.id);
        if (!id || id === "undefined") {
          const key = Object.entries(r)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([_, val]) => val);
          // https://sentry.io/organizations/internal/issues/2555128281/?project=1517743
          // If the key is too large b64Encode can throw `Maximum callstack exceeded` exception.
          // If that happens try again with a smaller slice of the rows's values.
          try {
            id = b64Encode(JSON.stringify(key));
          } catch (_err) {
            id = b64Encode(JSON.stringify(key.slice(0, 5)));
          }
        }

        return {
          id,
          columns: columns.map((c: AttributeColumn) => {
            const col: AttributeColumnTuple = [
              ColumnType.ATTRIBUTE,
              c,
              attrs.find(
                attr => attr.sourceName === c.attribute
              ) as FunctionAttributeNode,
              r[c.attribute]
            ];
            return col;
          })
        };
      });
    } else {
      return selectRecordRows(
        columns,
        attrs,
        networkStatus !== 2 || !clearRowsOnVariablesChange ? rowEdges : undefined,
        rowDataMask
      );
    }
  }, [
    columns,
    attrs,
    networkStatus,
    rowEdges,
    rowDataMask,
    clearRowsOnVariablesChange,
    sourceType,
    bindingRows
  ]);

  const attributes = React.useMemo(() => {
    const firstRow = rows && rows[0];
    if (!firstRow?.columns && !attrs) return [];
    const attributesFromColumns = firstRow?.columns
      ? firstRow!.columns.map((c: ColumnRecord) => {
          if (c[0] !== ColumnType.ATTRIBUTE) return null;
          const [, , attr] = c;
          return attr;
        })
      : // when an attribute is removed during sync but still referenced by a column,
        // those columns will not be rendered
        columns.map((c: AttributeColumn) =>
          c.column_type === ColumnType.ATTRIBUTE
            ? attrs?.find(a => a.sourceName === c.attribute)
            : null
        );
    return attributesFromColumns.filter(
      (attr: AttributeNode | FunctionAttributeNode | null | undefined) => !!attr
    );
  }, [rows, attrs, columns]);

  const sort = React.useCallback(
    function sort(sortOrder: SortOrder | null) {
      setManualSortOrder(sortOrder);
    },
    [setManualSortOrder]
  );

  const filter = React.useCallback(
    function filter(filters: ViewFilter[], forceRefetch = false) {
      // UX hack to support refreshing tables by reapplying same filter
      // Probably should just add refresh button to table
      if (forceRefetch && isEqual(filters, filtersResult.filters)) {
        viewRowsResult.refetch();
        return;
      }
      filtersResult.filter(filters);
    },
    [filtersResult, viewRowsResult]
  );

  const _fetchMore = viewRowsResult.fetchMore;
  const fetchMore = React.useCallback(() => {
    if (
      viewRowsResult.loading ||
      !viewRowsData?.pageInfo?.endCursor ||
      !viewRowsData?.pageInfo?.hasNextPage
    )
      return;

    _fetchMore({
      variables: {
        after: viewRowsData.pageInfo.endCursor,
        limit: currentLimit
      },
      updateQuery: (previousData, { fetchMoreResult: newData }) =>
        produce(previousData, draftData => {
          if (!newData) return previousData;
          if (
            draftData.node.data?.__typename === "ViewDataResultSuccess" &&
            newData.node.data?.__typename === "ViewDataResultSuccess"
          ) {
            // If this manages to be invoked twice with the same variables
            // duplicate rows will be produced. Guard agains that case by
            // examining the last page of ids and if they match no-op.
            const lastPageOfIds = draftData.node.data?.rows.edges
              .slice(
                draftData.node.data?.rows.edges.length -
                  newData.node.data.rows.edges.length
              )
              .map(edge => edge.node.id);
            const newIds = newData.node.data.rows.edges.map(edge => edge.node.id);
            if (isEqual(lastPageOfIds, newIds)) {
              return;
            }
            draftData.node.data.rows.pageInfo = newData.node.data?.rows.pageInfo;
            draftData.node.data.rows.edges = draftData.node.data?.rows.edges.concat(
              newData.node.data?.rows.edges
            );
          }
        })
    });
  }, [
    viewRowsResult.loading,
    _fetchMore,
    viewRowsData?.pageInfo?.endCursor,
    viewRowsData?.pageInfo?.hasNextPage,
    currentLimit
  ]);

  const refresh = React.useCallback(
    function refresh() {
      const nextLimit = Math.max(rows?.length || limit, limit);
      viewRowsResult.refetch({ limit: nextLimit });
      setIsRefreshing(true);
      setCurrentLimit(nextLimit);
    },
    [rows, limit, viewRowsResult]
  );

  const columnViewMapping = React.useMemo(() => {
    if (!component.properties.columns) return [];

    return createColumnViewMapping(component.properties.columns, attrs);
  }, [component.properties.columns, attrs]);

  return {
    sourceType,
    loading: viewRowsResult.loading,
    filtersOptions,
    sortByOptions,
    viewFunction: func || null,
    attributes,
    rows,
    filters: filtersResult.filters || EMPTY_FILTER_SET,
    orders,
    bindingsSatisfied: filtersResult.allConfigFiltersSatisfied,
    hasNextPage: !!viewRowsData?.pageInfo.hasNextPage,
    errorCode: extractError(viewDataNode),
    viewMissing: !func,
    fetchType: getFetchType(viewRowsResult),
    lastRefetch,
    queryVariables: viewRowsVariables,
    columnViewMapping,
    sort,
    filter,
    fetchMore,
    refresh,
    getUniqueFilterClientId: filtersResult.getUniqueFilterClientId
  };
}

export function useViewWithComponentColumns(
  spaceId: string | undefined,
  component: SpaceComponentObject,
  input: SpaceStateInputs | null,
  options: Partial<ViewRowsOptions>
): ViewResult {
  const result = useView(spaceId, component, input, options);
  const rows = React.useMemo(
    () => insertComponentColumns(component, result.rows),
    [component, result.rows]
  );

  return { ...result, rows };
}

export function viewRowToStateRow(row: Row = { id: "", columns: [] }) {
  return {
    data: row.columns.reduce<Record<string, DataValue>>((memo, col) => {
      if (col[0] !== ColumnType.ATTRIBUTE) return memo;
      const [, , attr, val] = col;
      if (attr === undefined) {
        return memo;
      }
      memo[attr.sourceName] = val;
      return memo;
    }, {})
  };
}
