import React from "react";

import styled from "styled-components";

import { formatTestHandle } from "../../../../../../test";
import IconButton from "../../../../../common/IconButton";

const StyledConfigSection = styled.section`
  /* slightly larger padding at bottom to give perception of equal padding on top and bottom */
  padding: ${props =>
    `${props.theme.spacermd} ${props.theme.spacermd} ${props.theme.spacerlg} ${props.theme.spacermd}`};

  color: ${props => props.theme.textColorMid};

  label {
    font-size: 13px;
    line-height: 16px;
    color: ${props => props.theme.textColor};
    margin-bottom: ${props => props.theme.spacersm};
  }

  & + & {
    border-top: 1px solid ${props => props.theme.borderColor};
  }
`;

export const Header = styled.header`
  margin-bottom: ${props => props.theme.spacermd};

  h1 {
    margin-bottom: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  h1 {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const SubTitle = styled.div`
  font-size: ${props => props.theme.tinyFontSize};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute; /* take out of flow so that height of header does not include button height*/
  right: ${props => props.theme.spacermd};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ConfigSectionProps {
  id?: string;
  className?: string;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  onAdd?: () => void;
}
const ConfigSection = (props: ConfigSectionProps) => {
  const showHeader = props.title || !!props.onAdd;

  return (
    <StyledConfigSection
      id={props.id}
      className={props.className}
      data-test={formatTestHandle(`${props.title}-config-section`)}
    >
      {showHeader && (
        <Header>
          <TitleContainer>
            {props.title && <h1 title={props.title}>{props.title}</h1>}
            {props.onAdd && (
              <StyledIconButton
                icon="plus"
                data-test="add-button"
                onClick={props.onAdd}
              />
            )}
          </TitleContainer>
          {props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
        </Header>
      )}

      <ContentContainer>{props.children}</ContentContainer>
    </StyledConfigSection>
  );
};

export default ConfigSection;

export const ScrollableConfigSection = styled(ConfigSection)`
  max-height: 300px;
  overflow: auto;
`;
