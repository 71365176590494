import React from "react";

import { ReturnSchema } from "../../../../../constants";
import Message from "../../../../common/Message";
import { toGlobalId } from "../../../../util/graphql";
import { AssigneeType } from "../../../Config/types";
import { useGetFunctionById } from "../../../queries/useGetFunctionById";
import SpaceComponentContext from "../spaces/SpaceComponentContext";
import { createComponent, SpaceForm } from "../spaces/SpaceForm";
import useSpaceContextParams from "../spaces/useSpaceContextParams";
import useAssigneeInputParameters, {
  AssigneeParameter
} from "../useAssigneeInputParameters";
import { useTask } from "../useQueueClient/queries/Task";

import { useQueueBySlug } from "./queries/useQueueBySlug";

interface Props {
  taskId: string;
  queueSlug: string;
  visible?: boolean;
  onCancel?: () => void;
  onCompleted?: () => void;
}

function UpdateTaskFormContainer({
  taskId,
  queueSlug,
  visible = false,
  onCancel = () => null,
  onCompleted = () => null
}: Props) {
  const { data: taskData } = useTask({
    variables: { id: taskId }
  });
  const task = taskData?.task;

  const {
    data: queueData,
    error: queueError,
    loading: queueLoading
  } = useQueueBySlug({
    fetchPolicy: "cache-and-network",
    variables: {
      slug: queueSlug
    }
  });

  React.useEffect(() => {
    if (queueError) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [queueError]);

  const selectedQueue = queueData?.queueBySlug;

  const updateTaskFunctionId =
    selectedQueue?.updateTaskForm?.functionId &&
    toGlobalId("FunctionNode", selectedQueue.updateTaskForm.functionId);

  const { data, loading: functionLoading } = useGetFunctionById({
    variables: { id: updateTaskFunctionId! },
    skip: !updateTaskFunctionId,
    fetchPolicy: "cache-and-network"
  });

  const contextParams = useSpaceContextParams(task);

  const injectAssigneeInputParameters = useAssigneeInputParameters();

  const taskInputParameters = React.useMemo(() => {
    return injectAssigneeInputParameters(selectedQueue?.updateTaskForm.inputParameters);
  }, [selectedQueue?.updateTaskForm.inputParameters, injectAssigneeInputParameters]);

  const component = React.useMemo(() => {
    const func = data?.node
      ? data.node
      : {
          id: "placeholder",
          name: "placeholder",
          returnSchema: ReturnSchema.UNKNOWN
        };

    const startComponent = createComponent(
      "Update task form",
      func,
      taskInputParameters
    );

    if (!task) {
      return startComponent;
    }

    const initialValues = new Map<string, any>(
      (task.fieldValues || []).map(fieldValue => [fieldValue.name, fieldValue.value])
    );
    initialValues.set("title", task.title);
    if (task.assignee?.type) {
      initialValues.set("assigneeType", task.assignee?.type);
    }
    if (task.assignee?.type === AssigneeType.ROLE) {
      initialValues.set(AssigneeParameter.ROLE_ID, task.assignee?.id);
    } else if (task.assignee?.type === AssigneeType.USER) {
      initialValues.set(AssigneeParameter.USER_ID, task.assignee?.id);
    }

    for (const treeNode of startComponent.componentTreeNodes) {
      const fieldName = treeNode.name;
      if (!initialValues.has(fieldName)) {
        continue;
      }

      treeNode.properties.default_value = initialValues.get(fieldName)!;
    }

    return startComponent;
  }, [task, data, taskInputParameters]);

  if (!visible) return null;

  return (
    <SpaceComponentContext component={component} contextParams={contextParams}>
      <SpaceForm
        inputParameters={component.properties.input_parameters}
        contentLoading={functionLoading || queueLoading}
        onCancel={onCancel}
        onCompleted={onCompleted}
      />
    </SpaceComponentContext>
  );
}

export default UpdateTaskFormContainer;
