import React from "react";

import { SpaceComponentObject } from "../../../../../types";
import { useStableSpaceContext } from "../../SpaceContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { SpaceContextParamProperties } from "./types";

export default function SpaceContextParam() {
  const { contextParams } = useStableSpaceContext();
  const { componentNode, updateOutput } = useComponentStateContext();

  const c = componentNode?.component as
    | SpaceComponentObject<SpaceContextParamProperties>
    | undefined;

  const key = c?.properties.context_param;
  const value = key && contextParams && contextParams[key];

  React.useEffect(() => {
    updateOutput(value && value.value);
  }, [updateOutput, value]);

  return null;
}
