import React from "react";

import { isEqual } from "lodash";
import WindowedSelect from "react-windowed-select";
import styled from "styled-components";

import { MAX_VIEW_ROWS, ErrorValue, ErrorValues } from "../../../../../constants";
import usePrevious from "../../../../common/hooks/usePrevious";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import WarningIcon from "../../../../common/WarningIcon";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import { Option } from "../common/HardcodedOptionFields/utils";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import { getCustomStyles, customTheme } from "../common/SelectBackedComponent/styles";
import useDefaultValue from "../common/useDefaultValue";
import useOutputSyncing from "../common/useOutputSyncing";
import useSelectOptions from "../common/useSelectOptions";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { ensureTagSelectorComponent } from "./utils";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

const SelectWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledWindowedSelect = styled(WindowedSelect)`
  width: 100%;
  height: 100%;
`;
StyledWindowedSelect.displayName = "StyledWindowedSelect";

const StyledWarningIcon = styled(WarningIcon)`
  margin-left: ${props => props.theme.spacerxs};
`;

export default function SpaceTagSelector({ spaceComponent, hasConfigError }: Props) {
  const tagComponent = ensureTagSelectorComponent(spaceComponent);
  const { editMode } = useStableSpaceContext();
  const { output } = useComponentStateContext();
  const { defaultValue } = useDefaultValue();
  const [selectedOptions, setSelectedOptions] = React.useState<Option[] | ErrorValue>(
    []
  );
  const {
    properties: { placeholder, serialize_value_as_csv }
  } = tagComponent;

  const { options, hasNextPage, loading, minWidth } = useSelectOptions(tagComponent);

  const defaultSelectedOptions = React.useMemo(() => {
    if (defaultValue === ErrorValues.permissionDenied) {
      return defaultValue;
    }
    let selectedOptions: Option[] = [];
    if (Array.isArray(defaultValue)) {
      selectedOptions = defaultValue.reduce((agg, value) => {
        const option = options.find(option => option.value === value);
        if (option) {
          agg.push(option);
        }
        return agg;
      }, []);
    } else if (serialize_value_as_csv) {
      const valuesArr: string[] = defaultValue ? defaultValue.split(",") : [];
      selectedOptions = valuesArr.reduce((agg: Option[], value: string | null) => {
        const option = options.find(option => option.value === value);
        if (option) {
          agg.push(option);
        }
        return agg;
      }, [] as Option[]);
    } else {
      const defaultOption = options.find(option => option.value === defaultValue);
      if (defaultOption) {
        selectedOptions.push(defaultOption);
      }
    }
    return selectedOptions;
  }, [defaultValue, options, serialize_value_as_csv]);

  const prevOptions = usePrevious(options);
  const prevDefaultValue = usePrevious(defaultValue);
  React.useEffect(() => {
    if (isEqual(prevOptions, options) && isEqual(prevDefaultValue, defaultValue)) {
      return;
    }

    setSelectedOptions(defaultSelectedOptions);
  }, [defaultValue, options, prevDefaultValue, prevOptions, defaultSelectedOptions]);

  React.useEffect(() => {
    if (output !== null) return;
    setSelectedOptions(defaultSelectedOptions);
  }, [output, defaultSelectedOptions]);

  const value = React.useMemo(() => {
    if (selectedOptions === ErrorValues.permissionDenied) {
      return selectedOptions;
    }
    if (serialize_value_as_csv) {
      return (selectedOptions as Option[])?.map(option => option.value).join(",") || "";
    } else {
      return (selectedOptions as Option[])?.map(option => option.value) || [];
    }
  }, [serialize_value_as_csv, selectedOptions]);

  const { errorMessage } = useOutputSyncing(value);

  const customStyles = React.useMemo(() => {
    return getCustomStyles(minWidth, !errorMessage, true);
  }, [minWidth, errorMessage]);

  return (
    <EditModeInteractionBlocker>
      <Root>
        <FlexContainer>
          {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
          <SelectWrapper
            onClick={evt => {
              evt.stopPropagation();
            }}
          >
            <StyledWindowedSelect
              classNamePrefix="tagSelector"
              value={
                selectedOptions === ErrorValues.permissionDenied ? [] : selectedOptions
              }
              placeholder={placeholder}
              isLoading={!editMode && loading}
              onChange={(options: Option[]) => {
                setSelectedOptions(options);
              }}
              menuPortalTarget={document.body}
              options={options}
              styles={customStyles}
              theme={customTheme}
              isMulti
              closeMenuOnSelect={false} // keep menu open for multiple selections
            />
          </SelectWrapper>
          {hasNextPage && (
            <StyledWarningIcon
              tooltip={`This dropdown is incomplete. It is displaying data from the first
              ${MAX_VIEW_ROWS} records, but more data is available.`}
            />
          )}
          {selectedOptions === ErrorValues.permissionDenied && (
            <PermissionWarningIcon />
          )}
        </FlexContainer>
        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </Root>
    </EditModeInteractionBlocker>
  );
}
