import React from "react";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface Props {
  children: JSX.Element[]; // Actually want stricter JSX.Element type here
  onSort: (oldIndex: number, newIndex: number) => void;
  isCompact?: boolean;
}

const List = styled.div`
  margin: ${props => props.theme.spacerxs} 0;

  &.isDraggingOver {
    background-color: ${props => props.theme.primaryColorFaded};
  }
`;

const CompactList = styled.div`
  width: ${props => `calc(100% + ${+props.theme.spacervaluemd * 2}px)`};
  margin: ${props =>
    `0 -${props.theme.spacermd}`}; /* negative margin so item hover background can extend to edge */
  padding: ${props => `0 ${props.theme.spacermd}`};

  &.isDraggingOver {
    background-color: ${props => props.theme.rowDragOverBackground};
  }
`;

export default function SortableList({ children, onSort, isCompact }: Props) {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    onSort(result.source.index, result.destination.index);
  };

  // react-beautiful-dnd breaks when inside a transformed parent
  // Popper, used for space config poppers relies on transform for positioning
  // https://github.com/atlassian/react-beautiful-dnd/issues/128
  const maybePortal = (
    styles: DraggingStyle | NotDraggingStyle | undefined,
    element: React.ReactElement<any>
  ) => {
    if (styles && (styles as DraggingStyle).position === "fixed") {
      return ReactDOM.createPortal(
        element,
        document.querySelector("#draggable-portal") as Element
      );
    }
    return element;
  };

  const ListComponent = isCompact ? CompactList : List;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, droppableSnapshot) => (
          <ListComponent
            ref={droppableProvided.innerRef}
            className={droppableSnapshot.isDraggingOver ? "isDraggingOver" : ""}
          >
            {React.Children.map(children, (child: JSX.Element, i) => (
              <Draggable
                key={child!.props.sortKey}
                draggableId={child.props.sortKey}
                index={i}
              >
                {(draggableProvided, draggableSnapshot) =>
                  maybePortal(
                    draggableProvided.draggableProps.style,
                    React.cloneElement(child, {
                      ref: draggableProvided.innerRef,
                      className: draggableSnapshot.isDragging ? "isDragging" : "",
                      dragHandleProps: draggableProvided.dragHandleProps,
                      ...draggableProvided.draggableProps
                    })
                  )
                }
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </ListComponent>
        )}
      </Droppable>
    </DragDropContext>
  );
}
