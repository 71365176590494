import { UseTableHooks, Column } from "react-table";

import { SpaceComponentObject } from "../../../types";

export default function useSyncComponentDefs(hooks: UseTableHooks<object>) {
  hooks.columnsDeps.push((deps, { instance }) => {
    return [...deps, (instance as any).state.columnDefs];
  });

  hooks.columns.push((columns: Column[], { instance }) => {
    // If columns are out of sync with state.columnDefs they should be updated
    // and synced on next render.
    if (
      !columns.every(
        (c, idx) => c?.accessor === (instance as any).state.columnDefs[idx]?.accessor
      )
    ) {
      return columns;
    }

    return columns.map((c, idx) => {
      return (c as Column & { component: SpaceComponentObject }).component
        ? (instance as any).state.columnDefs[idx]
        : c;
    });
  });
}
