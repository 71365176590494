import React from "react";

import { partition, cloneDeep } from "lodash";

import { SpaceComponentObject } from "../../../../../../types";
import {
  PopperOverrideContext,
  PopperOverrideContextValue
} from "../../../../../common/Popper/Popper";
import withChangeOnBlur from "../../../../../hoc/WithChangeOnBlur";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import SpaceComponent, { Props } from "../../SpaceComponent";

import * as styled from "./StyledComponents";

const InputChangeOnBlur = withChangeOnBlur(styled.Input);
(InputChangeOnBlur as any).displayName = "WithChangeOnBlur(Input)";

export default function ConfigSpaceHeader({ spaceComponent, ...props }: Props) {
  const { componentTreeNodes } = spaceComponent;
  const [touched, setInputTouched] = React.useState(false);
  const { dispatch } = useSpaceConfigContext();
  const [actionNodes, nodes] = partition(
    componentTreeNodes,
    node => node.type !== "CUSTOM_FIELD"
  );
  const {
    properties: { text }
  } = spaceComponent;

  const getPropsForComponent = (n: SpaceComponentObject) => ({
    ...props,
    output: null,
    input: null,
    onChangeSpaceState: () => null,
    key: n.slug,
    spaceComponent: n
  });

  const contextValue: PopperOverrideContextValue = React.useMemo(() => {
    return {
      placement: "bottom",
      modifiers: [
        {
          name: "preventOverflow",
          enabled: true,
          altBoundary: true
        }
      ]
    };
  }, []);

  return (
    <PopperOverrideContext.Provider value={contextValue}>
      <styled.EditRoot>
        <styled.LeftHeader>
          <InputChangeOnBlur
            className="space-name"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => e.stopPropagation()}
            data-test="space-header-input"
            onChange={e => {
              if (!touched) {
                setInputTouched(true);
              }
              const component = cloneDeep(spaceComponent);
              dispatch({
                type: "SET_COMPONENT",
                payload: {
                  slug: component.slug,
                  component: {
                    ...component,
                    properties: {
                      ...component.properties,
                      text: e.target.value
                    }
                  }
                }
              });
            }}
            value={text}
            placeholder="Enter text for your header"
          />
          <styled.Filters className="filters">
            {nodes.map(n => (
              <styled.MouseDownCatcher
                key={n.slug}
                onMouseDown={e => e.stopPropagation()}
              >
                <SpaceComponent {...getPropsForComponent(n)} />
              </styled.MouseDownCatcher>
            ))}
          </styled.Filters>
          {touched && !spaceComponent.properties.text && <styled.StyledErrorIcon />}
        </styled.LeftHeader>
        <styled.Actions>
          {actionNodes.map(n => (
            <styled.MouseDownCatcher
              key={n.slug}
              onMouseDown={e => e.stopPropagation()}
            >
              <SpaceComponent {...getPropsForComponent(n)} />
            </styled.MouseDownCatcher>
          ))}
        </styled.Actions>
        <styled.InsertTarget
          parentSlug={spaceComponent.slug}
          hostType="HEADER"
          onMouseDown={e => {
            e.stopPropagation();
          }}
        />
      </styled.EditRoot>
    </PopperOverrideContext.Provider>
  );
}
