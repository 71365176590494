import React from "react";

import produce from "immer";

import { SpaceComponentObject, ComponentConfigProps } from "../../../../../../types";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import AdvancedConfig from "../../common/image/AdvancedImageConfig";
import BasicConfig from "../../common/image/BasicImageConfig";
import { ImageConfigState } from "../../SpaceImage/types";
import { SpaceViewlessImageComponent } from "../types";

export const ensureViewlessImageComponent = (
  sc: SpaceComponentObject
): SpaceViewlessImageComponent => {
  return produce(sc, component => {
    component.properties.template = component.properties.template || "";
    component.properties.authentication = component.properties.authentication || null;
  });
};

export default function ViewlessImageConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer dataConfig={<ImageConfigContent />} slug={slug} />
  );
}

export function ImageConfigContent() {
  const { state, dispatch, errors } = useComponentConfigContext();
  const newState = {
    ...state,
    savedComponentColumns: [],
    attributes: [],
    filtersOptions: [],
    filterValidityTrackers: [],
    sortByOptions: []
  } as ImageConfigState;

  return (
    <>
      <BasicConfig state={newState} dispatch={dispatch} />
      <AdvancedConfig state={newState} dispatch={dispatch} errors={errors} />
    </>
  );
}
