import React from "react";

import { Input as AntInput } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";

const StyledInput = styled(AntInput)`
  &.medium {
    width: 256px;
    height: 40px;
  }
`;

interface Props extends InputProps {
  customSize?: "medium";
  autocomplete?: any;
}

const Input = ({ customSize, children, width, className, ...rest }: Props) => {
  let inputClass = `${className} ` || "";
  if (customSize) {
    inputClass += `${customSize}`;
  }
  let style = {};
  if (width) {
    style = { width };
  }

  return (
    <StyledInput style={style} className={inputClass} {...rest}>
      {children}
    </StyledInput>
  );
};

export default Input;
