import _ from "lodash";

import {
  AttributeFilterProperties,
  BaseFunctionName,
  ChangeSetProperties
} from "../../../../../../types";
import { assertNever } from "../../../../../util/assertNever";
import { BaseFunctionParameterMapping } from "../../../index";
import { SqlWriteFunctionName } from "../../constants";
import {
  CommonFunctionAction,
  DataSourceFunction,
  GeneralActionTypes
} from "../../types";
import {
  getBaseFunctionParameterMapping,
  getChangeSetsFromTemplateString,
  getFiltersFromTemplateString,
  getParsedString
} from "../../utils";
import {
  DeleteBaseFunctionParameterMapping,
  InsertBaseFunctionParameterMapping,
  UpdateBaseFunctionParameterMapping
} from "../SqlActionForm";

export enum ErrorMessageKeys {
  ResourceSourceId = "resourceSourceId",
  Filters = "filters",
  ChangeSet = "changeSet"
}

export interface ErrorMessageState {
  [ErrorMessageKeys.ResourceSourceId]: string;
  [ErrorMessageKeys.Filters]: string;
  [ErrorMessageKeys.ChangeSet]: string;
}

export interface SetInitialStateAction {
  type: GeneralActionTypes.SET_INITIAL_STATE;
  payload: {
    initialState: SqlActionState;
  };
}

type BulkFunctionAction = CommonFunctionAction | SetInitialStateAction;

export interface SqlActionBaseState {
  resourceSourceId?: string;
  actionType: SqlWriteFunctionName;
  changeSet: ChangeSetProperties[];
  filters: AttributeFilterProperties[];
}

export interface SqlActionState extends SqlActionBaseState {
  allowMultiple: boolean;
  errorMessages: ErrorMessageState;
}

const getEmptyInitialState = (
  actionType: SqlWriteFunctionName,
  resourceSourceId?: string
): SqlActionState => {
  return {
    resourceSourceId: resourceSourceId,
    actionType: actionType,
    filters: [{ column: undefined, operator: undefined, value: "" }],
    changeSet: [{ column: undefined, value: "" }],
    allowMultiple: false,
    errorMessages: {
      resourceSourceId: "",
      filters: "",
      changeSet: ""
    }
  };
};

export const getInitialState = (
  actionType: SqlWriteFunctionName,
  func?: DataSourceFunction
): SqlActionState => {
  if (!func) {
    return getEmptyInitialState(actionType);
  }
  const baseFunctionParameterMapping = getBaseFunctionParameterMapping(
    func.baseFunctionParameterMapping
  );
  return {
    actionType,
    ...parseBaseFunctionParameterMapping(actionType, baseFunctionParameterMapping),
    errorMessages: {
      ...getEmptyInitialState(actionType).errorMessages
    }
  };
};

interface ParseResult {
  resourceSourceId: any;
  changeSet: ChangeSetProperties[];
  filters: AttributeFilterProperties[];
  allowMultiple: boolean;
}

export const parseBaseFunctionParameterMapping = (
  actionType: SqlWriteFunctionName,
  mapping: BaseFunctionParameterMapping
): ParseResult => {
  const _getChangeSetsOrDefault = (template: string) => {
    const changeSets = getChangeSetsFromTemplateString(template);
    return changeSets.length ? changeSets : [{ column: undefined, value: "" }];
  };

  const _getFiltersOrDefault = (template: string) => {
    const filters = getFiltersFromTemplateString(template);
    return filters.length
      ? filters
      : [{ column: undefined, operator: undefined, value: "" }];
  };

  switch (actionType) {
    case BaseFunctionName.INSERT: {
      const m: InsertBaseFunctionParameterMapping =
        mapping as InsertBaseFunctionParameterMapping;
      return {
        resourceSourceId: getParsedString(m.into),
        changeSet: _getChangeSetsOrDefault(m.values),
        filters: [],
        allowMultiple: false
      };
    }
    case BaseFunctionName.UPDATE: {
      const m: UpdateBaseFunctionParameterMapping =
        mapping as UpdateBaseFunctionParameterMapping;
      return {
        resourceSourceId: getParsedString(m.table),
        changeSet: _getChangeSetsOrDefault(m.set),
        filters: _getFiltersOrDefault(m.where),
        allowMultiple: getParsedString(m.multiple)
      };
    }
    case BaseFunctionName.DELETE: {
      const m: DeleteBaseFunctionParameterMapping =
        mapping as DeleteBaseFunctionParameterMapping;
      return {
        resourceSourceId: getParsedString(m.from),
        changeSet: [],
        filters: _getFiltersOrDefault(m.where),
        allowMultiple: getParsedString(m.multiple)
      };
    }
    default:
      return assertNever(actionType);
  }
};

function reducer(state: SqlActionState, action: BulkFunctionAction) {
  switch (action.type) {
    case GeneralActionTypes.SET_FIELD_VALUE: {
      const fieldName = action.payload.fieldName;
      const fieldValue = action.payload.fieldValue;
      if (fieldName === "resourceSourceId") {
        return getEmptyInitialState(state.actionType, fieldValue);
      }
      const updatedState = {
        ...state,
        [fieldName]: fieldValue
      };
      return updatedState;
    }
    case GeneralActionTypes.SET_ERROR_MESSAGE: {
      const fieldName = action.payload.fieldName;
      const errorMessage = action.payload.errorMessage;
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          [fieldName]: errorMessage
        }
      };
    }
    case GeneralActionTypes.SET_ERROR_MESSAGES: {
      const errorMessages = action.payload.errorMessages;
      return {
        ...state,
        errorMessages: {
          ...errorMessages
        }
      };
    }
    case GeneralActionTypes.RESET_FIELDS: {
      return { ...getEmptyInitialState(state.actionType) };
    }
    case GeneralActionTypes.SET_INITIAL_STATE: {
      const initialState = action.payload.initialState;
      return _.cloneDeep(initialState);
    }
    default:
      return state;
  }
}

export default reducer;
