import React, { useEffect, useState } from "react";

import { Button } from "antd";
import styled from "styled-components";

import { AttributeFilterProperties, FiltersOption } from "../../../types";

import AttributeFilter from "./AttributeFilter";

const Container = styled.div`
  width: 100%;
`;

const AddButton = styled(Button)`
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & + & {
    margin-top: ${props => props.theme.spacersm};
  }
`;

interface Props {
  className?: string;
  value: AttributeFilterProperties[];
  filtersOptions: FiltersOption[];
  showOperator: boolean;
  filterUsedOptions?: boolean;
  useAttributeDisplayNames?: boolean;
  itemName?: string;
  onChange: (value: AttributeFilterProperties[]) => void;
}

const AttributeFilters = (props: Props) => {
  const [currentValue, setCurrentValue] = useState<AttributeFilterProperties[]>(
    props.value
  );
  const [filteredOptions, setFilteredOptions] = useState<FiltersOption[]>(
    props.filtersOptions
  );
  const filtersOptions = props.filterUsedOptions
    ? filteredOptions
    : props.filtersOptions;

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.filterUsedOptions) {
      const usedAttributes: string[] = currentValue.reduce(
        (acc: string[], value: AttributeFilterProperties) => {
          if (value.column) {
            acc.push(value.column);
          }
          return acc;
        },
        []
      );
      setFilteredOptions(
        props.filtersOptions.filter((attr: FiltersOption) => {
          return !usedAttributes.includes(attr.sourceName);
        })
      );
    }
  }, [props.filterUsedOptions, props.filtersOptions, currentValue]);

  const addRow = () => {
    const newValue = currentValue.concat({
      column: undefined,
      operator: undefined,
      value: ""
    });
    setCurrentValue(newValue);
    props.onChange(newValue);
  };

  const deleteRow = (index: number) => {
    if (index < 0 || index >= currentValue.length) return;
    const newValue = [...currentValue];
    newValue.splice(index, 1);
    setCurrentValue(newValue);
    props.onChange(newValue);
  };

  const onChange = (value: AttributeFilterProperties, index: number) => {
    if (index < 0 || index >= currentValue.length) return;
    const newValue = [...currentValue];
    newValue[index] = value;
    props.onChange(newValue);
  };

  const showAddButton = filtersOptions.length > 0;

  return (
    <Container className={props.className}>
      {props.value.map((filter, index) => {
        return (
          <Row key={index}>
            <AttributeFilter
              key={index}
              value={filter}
              filtersOptions={filtersOptions}
              allFiltersOptions={props.filtersOptions}
              useAttributeDisplayNames={!!props.useAttributeDisplayNames}
              showOperator={props.showOperator}
              onChange={value => onChange(value, index)}
            />
            <Button
              icon="close-circle"
              size="small"
              title="Remove"
              type="link"
              onClick={() => {
                deleteRow(index);
              }}
            />
          </Row>
        );
      })}
      {showAddButton && (
        <AddButton type="link" onClick={() => addRow()}>
          + Add {props.itemName || "filter"}
        </AddButton>
      )}
    </Container>
  );
};

export default AttributeFilters;
