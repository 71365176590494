const DATA_URL_REGEX = /^data:application\/([^;]+);base64,/;

export function getFilenameFromUrl(url: string) {
  const dataUrlMatch = url.match(DATA_URL_REGEX);
  if (dataUrlMatch) {
    return `download.${dataUrlMatch[1]}`;
  }
  const idxSlash = url.lastIndexOf("/") + 1;
  const idxQs = url.indexOf("?", idxSlash);
  return url.substring(idxSlash, idxQs > -1 ? idxQs : undefined) || "download";
}
