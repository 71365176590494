import React from "react";

import { ConfigValidationError } from "../../../../../../../types";
import useStableId from "../../../../../../common/hooks/useStableId";
import { SpaceConfigAction } from "../../../../../types";
import { ImageConfigState } from "../../../SpaceImage/ImageConfig/reducer/reducer";
import ColumnOrder from "../../ColumnOrder";
import { ConfigSection } from "../../ConfigPanel";
import { Field, Select } from "../../ConfigPanel/styledComponents";
import FilterListManagerSection from "../../FilterListManager";
import ParametersConfigSection from "../../ParametersManager";
import useS3DataSources from "../../useS3DataSources";
import { hasSource } from "../../util";
import VisibilityRulesManagerSection from "../../VisibilityRulesManager";

import * as styled from "./styledComponents";

interface Props {
  state: ImageConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
  errors: ConfigValidationError[]; // only used for config panel UI
}

export default function AdvancedConfig({ state, dispatch }: Props) {
  const { draftComponent, filtersOptions, sortByOptions } = state;
  const { authentication } = draftComponent.properties;
  const { s3DataSources } = useS3DataSources();
  const domId = useStableId("advancedImageConfig");

  return (
    <>
      {hasSource(state.draftComponent) && (
        <>
          <ParametersConfigSection title="Parameters" />
          <FilterListManagerSection title="Filters" filtersOptions={filtersOptions} />
          <ConfigSection title="Sorting">
            <ColumnOrder sortByOptions={sortByOptions} />
          </ConfigSection>
        </>
      )}
      {s3DataSources && Object.keys(s3DataSources).length > 0 && (
        <ConfigSection id={domId} title="Sign your image">
          <Field>
            <Select
              getPopupContainer={() => document.getElementById(domId) as HTMLElement}
              value={authentication?.function_id || "NONE"}
              onChange={val => {
                dispatch({
                  type: "SET_DRAFT_COMPONENT",
                  payload: {
                    path: "properties.authentication",
                    value:
                      val === "NONE" ? null : { type: "s3.presign", function_id: val }
                  }
                });
              }}
            >
              {Object.keys(s3DataSources).map((key: string) => {
                const ds = s3DataSources[key];
                return (
                  <styled.Option key={ds.presignId}>
                    {`${ds.name}'s credentials`}
                  </styled.Option>
                );
              })}
              <styled.Option key="NONE">Do not sign</styled.Option>
            </Select>
          </Field>
        </ConfigSection>
      )}
      <VisibilityRulesManagerSection />
    </>
  );
}
