import gql from "graphql-tag";

import { Connection, RelayNode } from "../../../../../../types";

interface _DataSourceNode extends RelayNode {
  name: string;
  resources: Connection<_ResourceNode>;
}

interface _ResourceNode extends RelayNode {
  name: string;
  sourceName: string;
}

export interface ResourceData {
  dataSource: _DataSourceNode;
}

export interface ResourceVars {
  dataSourceId: string;
}

export const FETCH_RESOURCES = gql`
  query FetchResourcesForResourceSelect($dataSourceId: ID!) {
    dataSource(id: $dataSourceId) {
      id
      name
      resources {
        edges {
          node {
            id
            name
            sourceName
          }
        }
      }
    }
  }
`;
