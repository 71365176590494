import React from "react";

import { Icon, Input, Select } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import produce from "immer";
import inflection from "inflection";
import styled from "styled-components";

import {
  PendingInputStep,
  InputField,
  InputStepOptions,
  InputFieldType
} from "../reducer";

import { ErrorInput } from "./Errors";
import { FormItem, Item, Label, Control } from "./StyledComponents";

import { StepProps } from ".";

const Root = styled.div`
  .ant-select:not(:first-child) {
    margin-top: ${props => props.theme.spacersm};
  }

  header {
    display: flex;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  padding: ${props => props.theme.spacersm};

  &.required,
  &.delete {
    flex: 1;
    justify-content: center;
  }

  &.delete {
    max-width: 50px;
    align-items: right;
  }
`;

const StyledFormItem = styled(FormItem)`
  display: flex;
  flex: 2;
  padding: ${props => props.theme.spacersm};

  &.required,
  &.delete {
    flex: 1;
    justify-content: center;
  }

  &.delete {
    max-width: 50px;
    align-items: right;
  }
`;

const StyledErrorInput = styled(ErrorInput)`
  display: flex;
  flex: 2;
  padding: ${props => props.theme.spacersm};
`;

const FieldsLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddIcon = styled(Icon)`
  font-size: ${props => props.theme.spacermd};
`;

const DeleteIcon = styled(Icon)`
  font-size: ${props => props.theme.spacermd};
`;

type Props = StepProps<PendingInputStep>;

export const selectExports = (step: PendingInputStep): string[] => {
  return step.options.input_fields?.map((f: InputField) => `${f.name}`) || [];
};

const InputStep = ({ step, state, updateOptions }: Props) => {
  React.useEffect(() => {
    // 'fields' was renamed to 'input_fields' due to a typesystem conflict
    // temporarily populate 'input_fields' based on 'fields'
    if (step.options.fields && step.options.fields.length) {
      updateOptions({
        ...step.options,
        fields: undefined,
        input_fields: [...step.options.fields]
      });
    }
  }, [step, updateOptions]);

  return (
    <Root>
      <Item>
        <FieldsLabel>
          Fields{" "}
          <AddIcon
            type="plus"
            onClick={() =>
              updateOptions(
                produce(step.options, (draft: InputStepOptions) => {
                  draft.input_fields = draft.input_fields || [];
                  draft.input_fields.push({
                    name: `field${draft.input_fields.length + 1}`,
                    label: `Field ${draft.input_fields.length + 1}`,
                    required: false,
                    type: InputFieldType.TEXT
                  });
                })
              )
            }
          />
        </FieldsLabel>
        <Control>
          {!step.options.input_fields?.length && (
            <span>Thare are no input fields configured. Add one to get started.</span>
          )}
          {!!step.options.input_fields?.length && (
            <section>
              <header>
                <Col>Label</Col>
                <Col>Type</Col>
                <Col className="required">Required</Col>
                <Col className="delete"></Col>
              </header>
              {step.options.input_fields?.map((field: InputField, index: number) => (
                <Row key={`field-${index}`}>
                  <StyledErrorInput
                    state={state}
                    path={`step${step.order}.input_fields[${index}].label`}
                  >
                    <Input
                      value={field.label}
                      onChange={e =>
                        updateOptions(
                          produce(step.options, (draft: InputStepOptions) => {
                            draft.input_fields[index].name = inflection.camelize(
                              e.target.value.replace(/\s/g, "_"),
                              true
                            );
                            draft.input_fields[index].label = e.target.value;
                            return draft;
                          })
                        )
                      }
                    />
                  </StyledErrorInput>
                  <StyledFormItem>
                    <Select
                      style={{ width: 200 }}
                      value={field.type}
                      onChange={(val: InputFieldType) =>
                        updateOptions(
                          produce(step.options, (draft: InputStepOptions) => {
                            draft.input_fields[index].type = val;
                            return draft;
                          })
                        )
                      }
                    >
                      <Select.Option value={InputFieldType.TEXT}>
                        Text Input
                      </Select.Option>
                      <Select.Option value={InputFieldType.PASSWORD}>
                        Password Input
                      </Select.Option>
                    </Select>
                  </StyledFormItem>
                  <StyledFormItem className="required">
                    <Checkbox
                      checked={field.required}
                      onChange={(e: CheckboxChangeEvent) =>
                        updateOptions(
                          produce(step.options, (draft: InputStepOptions) => {
                            draft.input_fields[index].required = e.target.checked;
                            return draft;
                          })
                        )
                      }
                    ></Checkbox>
                  </StyledFormItem>
                  <StyledFormItem className="delete">
                    <DeleteIcon
                      type="minus"
                      onClick={() =>
                        updateOptions(
                          produce(step.options, (draft: InputStepOptions) => {
                            draft.input_fields.splice(index, 1);
                            return draft;
                          })
                        )
                      }
                    />
                  </StyledFormItem>
                </Row>
              ))}
            </section>
          )}
        </Control>
      </Item>
    </Root>
  );
};

InputStep.selectExports = selectExports;

export default InputStep;
