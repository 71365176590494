import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import BackButton from "../../../../../common/BackButton";
import BaseDrawer from "../../../../../common/Drawer";
import usePaths from "../../../../../common/hooks/usePaths";
import useGetRecordPath from "../../../RecordDetail/common/useGetRecordPath";

const Title = styled.h1`
  margin: ${props => props.theme.spacerlg} 0;
`;

interface Props {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export default function Drawer({ title, children }: Props) {
  const navigate = useNavigate();
  const { params } = useGetRecordPath();
  const paths = usePaths();
  const { view, cursor } = params;

  return (
    <BaseDrawer
      visible
      onClose={() => navigate(paths.getRecordFromCursor(cursor!, view!))}
    >
      <BackButton />
      {!!title && <Title>{title}</Title>}
      {children}
    </BaseDrawer>
  );
}
