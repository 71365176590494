import styled from "styled-components";

import BaseIconButton from "../../../common/IconButton";
import { B3, C2, H4, H5 } from "../../../common/StyledComponents";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${props => props.theme.spacerlg};
  width: 100%;
`;

export const Title = styled(H4)`
  margin-bottom: ${props => props.theme.spacerlg};
`;

export const Section = styled.section`
  padding-bottom: ${props => props.theme.spacerxl};
  margin-bottom: ${props => props.theme.spacerxl};
`;

export const SubTitle = styled(H5)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const SubTitleWithDescription = styled(H5)`
  margin-bottom: ${props => props.theme.spacerxs};
`;

export const SectionDescription = styled(B3)`
  color: ${props => props.theme.surfaceSecondary};
`;

export const Label = styled(C2)`
  display: block;
  color: ${props => props.theme.surfaceSecondary};
  padding-bottom: ${props => props.theme.spacersm};
`;

export const Fieldset = styled.fieldset`
  padding-bottom: ${props => props.theme.spacermd};
`;

export const FieldsetBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > fieldset {
    margin-left: 20px;
  }

  > fieldset:first-child {
    margin-left: 0;
  }
`;

export const IconButton = styled(BaseIconButton)`
  color: ${props => props.theme.surfaceSecondary} !important;
  &:hover {
    color: ${props => props.theme.surfacePrimary} !important;
  }
`;
