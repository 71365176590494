import { BigNumber } from "bignumber.js";

import { AttributeTypes } from "../../constants";
import { AttributeNodeTypes, DataValue, FileObject } from "../../types";

export const isFileObject = (val: any): val is FileObject =>
  typeof val === "object" &&
  val !== null &&
  val.hasOwnProperty("name") &&
  val.hasOwnProperty("data") &&
  val.hasOwnProperty("size") &&
  val.hasOwnProperty("type");

// returns true if val passed in is compatible with type.
export const isValidType = (
  val: DataValue | FileObject | undefined,
  type: AttributeNodeTypes
) => {
  if (
    val === "Infinity" ||
    val === "-Infinity" ||
    val === "NaN" ||
    val === "" ||
    val === null ||
    val === undefined
  ) {
    return true;
  }
  const intRegex = /^([+-]?[1-9]\d*|0)$/;
  const floatOrDecimalRegex = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;

  switch (type) {
    case AttributeTypes.DECIMAL:
    case AttributeTypes.FLOAT:
      if (typeof val !== "string" && typeof val !== "number") return false;
      return floatOrDecimalRegex.test(val.toString());
    case AttributeTypes.INT:
      if (typeof val !== "string" && typeof val !== "number") return false;
      return intRegex.test(val.toString());
    case AttributeTypes.BOOL:
      return typeof val === "boolean";
    case AttributeTypes.JSON:
      if (typeof val !== "object") return false;
      try {
        JSON.parse(JSON.stringify(val));
        return true;
      } catch (e) {
        return false;
      }
    case AttributeTypes.FILE:
      return isFileObject(val);
    case AttributeTypes.BINARY:
    case AttributeTypes.STRING:
    default:
      return true;
  }
};

export type SerializerInput = string | number | Number | any | null | undefined;

export function formatDecimal(
  value: string | null | undefined,
  format: BigNumber.Format = {}
): string | null | undefined {
  if (value === null || value === undefined) {
    return value;
  }
  const formatOverride = {
    prefix: "",
    decimalSeparator: ".",
    groupSeparator: "", // no groupSeparator so that formatted values pass `isValidType` check
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: " ",
    fractionGroupSize: 0,
    suffix: "",
    ...format
  };
  return new BigNumber(value).toFormat(formatOverride);
}

export function formatFloat(
  value: string | Number | null | undefined
): string | null | undefined {
  if (value === undefined || value === null) {
    return value;
  }
  const [whole, fraction] = value.toString().split(".");
  // Large enough that toString returns scientific notation or is a non-finite
  // value (NaN, Infinity, -Infinity), don't try formatting.
  if (whole.match(/[a-z]/i)) {
    return whole;
  }

  // Add commas to the whole part and append the fractional part if one exists.
  let formattedValue = whole;
  if (fraction) {
    formattedValue = formattedValue + "." + fraction;
  }
  return formattedValue;
}

export function serializeBoolean(
  value: string | null | undefined,
  allowNull?: boolean
): boolean | null {
  if (value === "true") return true;
  if (value === "false") return false;
  return allowNull === undefined || allowNull ? null : false;
}

export const NUMERIC_FORMATTERS: {
  [key in AttributeTypes]?: (v: string) => string | null | undefined;
} = {
  [AttributeTypes.FLOAT]: formatFloat,
  [AttributeTypes.DECIMAL]: formatDecimal,
  [AttributeTypes.INT]: v => v
};

// if stringified version of json, converts string to json
export function serializeJson(value: string | null | undefined | any) {
  if (value === null || value === undefined || typeof value !== "string") {
    return value;
  }
  try {
    const json = JSON.parse(value);
    return json;
  } catch (e) {
    return value;
  }
}

export const SUPPORTED_SERIALIZERS = [
  AttributeTypes.FLOAT,
  AttributeTypes.DECIMAL,
  AttributeTypes.BOOL,
  AttributeTypes.JSON
];

type SupportedSerializers = typeof SUPPORTED_SERIALIZERS[number];

// For starters, only supporting attribute types that have multiple
// render type options when configuring function parameters.
// As fine-grained components are fleshed out and more component types
// are added, the remaining attribute types will be added here, as well.
export const getSerializer = (
  type: SupportedSerializers
): ((v: SerializerInput) => string | boolean | any | null | undefined) | undefined => {
  switch (type) {
    case AttributeTypes.FLOAT:
      return formatFloat;
    case AttributeTypes.DECIMAL:
      return formatDecimal;
    case AttributeTypes.BOOL:
      return serializeBoolean;
    case AttributeTypes.JSON:
      return serializeJson;
    default:
      return undefined;
  }
};

/**
 * parseISODate
 *
 * Parses an ISO 8601 string, assumed to be in UTC, returning a Date object
 * in the browser's local timezone. Values are truncated to millisecond
 * precision.
 *
 * This util exists because JavaScript date parsing is unreliable:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
 *
 * @param value string - ISO 8601 date string in UTC timezone
 * @returns Date - Date object in browser's local timezone.
 */
export function parseISODate(value: string) {
  // (YYYY)-(MM)-(DD)
  const isoYMD = /(\d{4})-(\d{2})-(\d{2})/;
  // (HH):(MM):(SS)[.(FFFFFFFFF)]
  const isoHMSF = /(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?/;
  // ^(YYYY)-(MM)-(DD)T(HH):(MM):(SS)[.(FFFFFFFFF)]$
  const isoDateTime = new RegExp(`^${isoYMD.source}T${isoHMSF.source}$`);

  const match = value.match(isoDateTime);
  if (!match) {
    return new Date(NaN);
  }

  const [year, month, day, hours, minutes, seconds] = match
    .slice(1, 7)
    .map(s => parseInt(s, 10));

  let milliseconds = 0;
  if (match[7]) {
    milliseconds = parseInt(match[7].substr(0, 3).padEnd(3, "0"), 10);
  }

  return new Date(
    Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds)
  );
}

export function ensureHexValue(hex: string) {
  if (hex.length === 7 && hex[0] === "#") return hex;
  if (hex.length === 6 && hex[0] !== "#") return `#${hex}`;
  if (hex.length === 0) return hex;
  throw new Error(`Expected hex color. Received: ${hex}.`);
}

/**
 * templateToString
 *
 * Unwraps the `s from a js Template Literal and returns the string.
 *
 * @param template string - A string containing a js template literal.
 * @returns string - The unwrapped string.
 */
export const templateToString = (template: string | undefined) =>
  template?.substring(1, template.length - 1) || "";

/**
 * stringToTemplate
 *
 * Wraps a string with `s producing a js template literal.
 *
 * @param string string - A string containing a js template literal.
 * @returns string - The string as js template literal.
 */
export const stringToTemplate = (string: string) => `\`${string}\``;
