import * as automations from "./automations";
import * as dashboard from "./dashboard";
import * as dataviewer from "./dataviewer";
import * as dev from "./dev";
import * as integrations from "./integrations";
import * as legal from "./legal";
import * as login from "./login";
import * as queues from "./queues";
import * as settings from "./settings";
import * as setup from "./setup";
import * as signup from "./signup";
import * as spaces from "./spaces";
import * as users from "./users";

export {
  automations,
  dashboard,
  dataviewer,
  dev,
  integrations,
  legal,
  login,
  queues,
  settings,
  setup,
  signup,
  spaces,
  users
};
