import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { AttributeTypes } from "../../../../../../constants";
import { Connection, InputParameter, RelayNode } from "../../../../../../types";
import { INPUT_PARAMETER_FRAGMENT } from "../../../../queries/common";

interface _FieldNode extends RelayNode {
  id: string;
  name: string;
  type: AttributeTypes;
  required: boolean;
}

interface TaskForm {
  functionId: string;
  inputParameters: InputParameter[];
}

export interface QueueNode extends RelayNode {
  __typename?: "QueueNode";
  name: string;
  slug: string;
  fields: _FieldNode[];
  enqueueTaskForm: TaskForm;
  updateTaskForm: TaskForm;
}

export interface Data {
  allQueues: Connection<QueueNode>;
}

const TASK_FORM_FRAGMENT = gql`
  fragment TaskFormFragment on TaskForm {
    functionId
    inputParameters {
      ...InputParameterFragment
    }
  }
  ${INPUT_PARAMETER_FRAGMENT}
`;

export const QUERY = gql`
  query AllQueues {
    allQueues {
      edges {
        node {
          id
          name
          slug
          enqueueTaskForm {
            ...TaskFormFragment
          }
          updateTaskForm {
            ...TaskFormFragment
          }
          fields {
            id
            name
            type
            required
          }
        }
      }
    }
  }
  ${TASK_FORM_FRAGMENT}
`;

export const useAllQueues = (options?: QueryHookOptions<Data>) => {
  return useQuery<Data>(QUERY, options);
};
