import React, { useState } from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Switch, Table, Tooltip } from "antd";
import styled from "styled-components";

import { ModalNew } from "../../../common/Modal";
import PerspectiveTimestamp from "../../../common/PerspectiveTimestamp/PerspectiveTimestamp";
import { B3, C2 } from "../../../common/StyledComponents";

import {
  FETCH_API_KEYS,
  ENABLE_API_KEY,
  DISABLE_API_KEY,
  DELETE_API_KEY,
  FetchAPIKeysData,
  TransitionAPIKeyVariables
} from "./queries";

const Disabled = styled(C2)`
  display: block;
`;

const InlinePerspectiveTimestamp = styled(PerspectiveTimestamp)`
  display: inline;
`;

const DeleteButton = styled(Button)`
  color: ${props => props.theme.errorColor};

  &.ant-btn-link:hover {
    color: ${props => props.theme.errorColor};
  }
  &.ant-btn-link[disabled] {
    color: ${props => props.theme.errorColor + "99"};
  }
`;

function Timestamp({ value }: { value: string }) {
  return value ? <InlinePerspectiveTimestamp isoDate={value} /> : null;
}

const APIKeys = () => {
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const [enableApiKey] = useMutation<TransitionAPIKeyVariables>(ENABLE_API_KEY, {
    refetchQueries: [{ query: FETCH_API_KEYS }]
  });
  const [disableApiKey] = useMutation<TransitionAPIKeyVariables>(DISABLE_API_KEY, {
    refetchQueries: [{ query: FETCH_API_KEYS }]
  });
  const [deleteApiKey, { loading: deleteLoading }] =
    useMutation<TransitionAPIKeyVariables>(DELETE_API_KEY, {
      onCompleted: () => setDeleteId(null),
      refetchQueries: [{ query: FETCH_API_KEYS }]
    });
  const { data, loading } = useQuery<FetchAPIKeysData>(FETCH_API_KEYS);

  return (
    <>
      <Table
        locale={{ emptyText: "No API keys" }}
        columns={[
          {
            key: "key",
            title: "Key",
            render: ({ prefix, suffix }) => `${prefix}••••${suffix}`
          },
          {
            key: "description",
            title: "Description",
            render: ({ description, disabledAt }) => (
              <>
                <B3>{description}</B3>
                {disabledAt && (
                  <Disabled>
                    Disabled <Timestamp value={disabledAt} />
                  </Disabled>
                )}
              </>
            )
          },
          {
            key: "createdAt",
            title: "Created",
            render: ({ createdAt }) => <Timestamp value={createdAt} />
          },
          {
            key: "lastUsedAt",
            title: "Last Used",
            render: ({ lastUsedAt }) => <Timestamp value={lastUsedAt} />
          },
          {
            key: "enabled",
            title: "Enabled",
            render: ({ id, disabledAt }) => {
              return (
                <Switch
                  checked={disabledAt === null}
                  onChange={checked => {
                    if (checked) {
                      enableApiKey({ variables: { id } });
                    } else {
                      disableApiKey({ variables: { id } });
                    }
                  }}
                />
              );
            }
          },
          {
            key: "actions",
            render: ({ id, disabledAt }) => {
              const button = (
                <DeleteButton
                  disabled={disabledAt === null}
                  type="link"
                  onClick={() => setDeleteId(id)}
                >
                  Delete
                </DeleteButton>
              );
              return disabledAt === null ? (
                <Tooltip title="To delete this API key, you must first disable it.">
                  {button}
                </Tooltip>
              ) : (
                button
              );
            }
          }
        ]}
        dataSource={(data?.allApiKeys.edges || []).map(e => e.node)}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
      {deleteId && (
        <ModalNew
          title="Delete API key"
          confirmLoading={deleteLoading}
          okText="Delete"
          onOk={() => deleteApiKey({ variables: { id: deleteId } })}
          cancelText="Cancel"
          onCancel={() => setDeleteId(null)}
          visible
        >
          Are you sure you want to permanently delete this API key?
        </ModalNew>
      )}
    </>
  );
};

export default APIKeys;
