import React from "react";

import _, { isEqual } from "lodash";

import { SpaceComponentObject } from "../../../../types";
import { createPath, parsePath } from "../../../util/binding";
import debug from "../../../util/debug";
import makeComponent from "../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/util/makeComponent";
import { findPivotInOtherTree, fromComponents } from "../../util/tree";
import { useSpaceContext } from "../SpaceContext";

import {
  createParentPath,
  findInvalidInputBindings as findInvalidInputBindingsUtil,
  hasParentPath
} from "./util";

const DEBUG_FIND_NODE = false;

const COLLECTION_PSEUDO_COMPONENT_SLUGS = new Set(["rows", "cards", "options"]);
const ITEM_PSEUDO_COMPONENT_SLUGS = new Set(["fieldset", "repeateditem"]);
const PSEUDO_COMPONENT_SLUGS = new Set([
  ...COLLECTION_PSEUDO_COMPONENT_SLUGS,
  ...ITEM_PSEUDO_COMPONENT_SLUGS
]);

export interface BaseNode {
  path: string;
  parent: BaseNode | undefined;
  children: BaseNode[];
}

interface StoredRootNode extends BaseNode {
  children: StoredComponentNode[];
  detachedBranches: Record<string, StoredComponentNode[]>;
  pendingOutputChanges: Record<string, Object | null>;
  path: "";
  pseudoComponents: Map<string, SpaceComponentObject>;
  lastMutationTimestamp: number;
  updateBatch: [string, Object | null][];
}

interface StoredComponentNode extends PendingComponentNode {
  parent: StoredComponentNode | StoredRootNode | PendingComponentNode;
  component?: SpaceComponentObject;
}

interface PendingComponentNode extends BaseNode {
  slug: string;
  children: StoredComponentNode[];
  output: object | null;
}

function createNode(slug: string, path: string): PendingComponentNode {
  return {
    slug,
    output: {},
    path,
    children: [],
    parent: undefined
  };
}
export interface RootNode extends BaseNode {
  children: ComponentNode[];
}

export interface ComponentNode extends BaseNode {
  component: SpaceComponentObject;
  output: Object | null;
  parent: ComponentNode | RootNode;
  children: ComponentNode[];
}

function isStoredComponentNode(node: BaseNode): node is StoredComponentNode {
  if (node === undefined) return false;
  return "slug" in node && "output" in node;
}

export function isComponentNode(node: BaseNode): node is ComponentNode {
  return "component" in node && "output" in node;
}

function isStoredRootNode(node: BaseNode): node is StoredRootNode {
  if (node === undefined) return false;
  return (
    "detachedBranches" in node &&
    "pendingOutputChanges" in node &&
    "pseudoComponents" in node
  );
}

let nodeMap = new Map<string, StoredComponentNode>();
let nodeSet = new Set<StoredComponentNode>();
function purgeCache(node: StoredComponentNode) {
  nodeMap.delete(node.path);
  nodeSet.delete(node);
  node.children.forEach(purgeCache);
}

export function createMockNode(
  component: SpaceComponentObject,
  path: string
): ComponentNode {
  const node = createNode(component.slug, path);
  return {
    ...node,
    component: makeComponent(new Set(), component),
    parent: {} as ComponentNode,
    children: [] as ComponentNode[]
  } as ComponentNode;
}

const pathCollectionIndexRegex = /\[([0-9]+)\]$/;
function getPathIndex(path: string) {
  const matches = path.match(pathCollectionIndexRegex);
  return (matches ? parseInt(matches[1]) : -1) as number;
}
function attachNode(
  node: PendingComponentNode,
  parent: StoredComponentNode | StoredRootNode | PendingComponentNode
): StoredComponentNode {
  const attachedNode: StoredComponentNode = node as StoredComponentNode;
  const existingNode = parent.children.find(c => c.path === node.path);
  if (!!existingNode) {
    // Node was already registered.
    existingNode.output = { ...existingNode.output, ...attachedNode.output };
    return existingNode;
  }
  attachedNode.parent = parent;
  parent.children.push(attachedNode);
  parent.children.sort((a, b) => {
    return getPathIndex(a.path) - getPathIndex(b.path);
  });
  if (!isStoredComponentNode(node)) {
    throw new Error("Expected node to be a valid stored component node.");
  }

  nodeMap.set(node.path, node);
  nodeSet.add(node);
  return attachedNode;
}

function attachDetachedBranches(
  state: StoredRootNode,
  parentNode: PendingComponentNode | StoredComponentNode
) {
  const detachedChildren = state.detachedBranches[parentNode.path];
  if (detachedChildren) {
    detachedChildren.forEach(n => {
      n = attachNode(n, parentNode);
      n.output = state.pendingOutputChanges[n.path] || {};
      delete state.pendingOutputChanges[n.path];
      const descendantCursor = [0];
      let descendant: StoredComponentNode | undefined = n.children[descendantCursor[0]];
      while (descendant) {
        descendant.output = state.pendingOutputChanges[descendant.path];
        delete state.pendingOutputChanges[descendant.path];
        // Do a depth first search for descendants with pending output that needs to be assigned.
        if (descendant.children.length) {
          descendantCursor.push(0);
          descendant = descendant.children[0];
        } else {
          while (descendantCursor.length) {
            const nextSiblingIndex = descendantCursor[descendantCursor.length - 1] + 1;
            if (descendant.parent.children[nextSiblingIndex]) {
              descendantCursor[descendantCursor.length - 1] = nextSiblingIndex;
              descendant = descendant.parent.children[nextSiblingIndex];
              break;
            }
            descendantCursor.pop();
          }
          descendant = undefined;
        }
      }
    });
    delete state.detachedBranches[parentNode.path];
  }
}

function findNodeByPath<T extends BaseNode = StoredComponentNode>(
  node: T,
  path: string,
  depth = 0
): T | undefined {
  if (DEBUG_FIND_NODE) {
    debug(`${Array(depth).fill("> ").join("")}findNode`, path, node);
  }
  if (node.path === path) return node;

  const pathParts = parsePath(path);
  let nextDepth = depth + 1;

  // Handles case when pathParts looks like this: ["table1", "rows", 0]
  if (typeof pathParts[nextDepth] === "number") nextDepth++;

  const nextPath = createPath(pathParts.slice(0, nextDepth));
  const nextNode = node.children.find(c => c.path === nextPath) as T;
  if (!nextNode) {
    return undefined;
  }
  return findNodeByPath(nextNode, path, nextDepth);
}

interface RegisterAction {
  type: "REGISTER";
  payload: {
    path: string;
    slug: string;
    isPsuedoComponent: boolean;
    timestamp: number;
  };
}

interface UnregisterAction {
  type: "UNREGISTER";
  payload: { path: string; timestamp: number };
}

interface UpdateComponentAction {
  type: "UPDATE_COMPONENT";
  payload: { path: string; component: SpaceComponentObject };
}

interface UpdateOutputAction {
  type: "UPDATE_OUTPUT";
  payload: { path: string; output: Object | null };
}

interface ComitUpdateBatchAction {
  type: "COMMIT_UPDATE_BATCH";
  payload: {};
}

interface RecursivelyClearOutput {
  type: "RECURSIVELY_CLEAR_OUTPUT";
  payload: { path: string };
}

type Action =
  | RegisterAction
  | UnregisterAction
  | UpdateComponentAction
  | UpdateOutputAction
  | RecursivelyClearOutput
  | ComitUpdateBatchAction;

// NOTE: The nodes in this graph are purposefully mutated
// rather than being replaced with new copies. If new copies
// of nodes (apart from root) are created the references held
// as parent and children will reference stale objects
function renderTreeReducer(state: StoredRootNode, action: Action) {
  debug(action.type, action.payload);
  switch (action.type) {
    case "REGISTER": {
      const { slug, path, isPsuedoComponent, timestamp } = action.payload;
      const existing = findNodeByPath(state, path);
      if (!!existing) {
        // TODO: This happens when navigating from edit to view. Not sure why yet
        debug(
          "Attempted to register existing component path.",
          path,
          "Treating as no-op."
        );
        return state;
      }

      let pseudoComponents = state.pseudoComponents;
      if (isPsuedoComponent && !pseudoComponents.has(slug)) {
        pseudoComponents = new Map(pseudoComponents);
        pseudoComponents.set(slug, {
          type: COLLECTION_PSEUDO_COMPONENT_SLUGS.has(slug)
            ? slug.substr(0, slug.length - 1).toUpperCase()
            : slug.toUpperCase(),
          slug
        } as SpaceComponentObject);
      }

      const pendingNode = createNode(slug, path);
      const pathParts = parsePath(path);

      let parent;
      if (hasParentPath(pathParts)) {
        const parentPath = createParentPath(pathParts);

        parent = findNodeByPath(state, parentPath);
        if (!parent) {
          // Due to useEffect execution order children may register before their parents.
          // This can be duplicated by editing a space, saving, returning to view mode and then
          // returning once again to edit mode. When edit mode loads nodes will register from the leafs
          // up. If a node registers before its parent it will be stored as a `detachedBranch` until
          // the parent registers, at which point it will be attached to the parent and be removed
          // from `detachedBranch`s.

          // If there are any detached nodes for this node's path attach them to this branch and
          // clean them up. They will be attached to the graph when this node is attached.
          attachDetachedBranches(state, pendingNode);

          const siblings = state.detachedBranches[parentPath] || [];
          state.detachedBranches = {
            ...state.detachedBranches,
            [parentPath]: siblings.concat(pendingNode as StoredComponentNode)
          };
          return { ...state, pseudoComponents };
        }
      } else {
        parent = state;
      }
      const attachedNode = attachNode(pendingNode, parent);

      // Check for any detached branches, attach them and stop tracking them
      attachDetachedBranches(state, attachedNode);

      if (state.pendingOutputChanges[path]) {
        attachedNode.output = state.pendingOutputChanges[path];
        delete state.pendingOutputChanges[path];
      }

      return { ...state, pseudoComponents, lastMutationTimestamp: timestamp };
    }

    case "UNREGISTER": {
      const { path, timestamp } = action.payload;
      const node = findNodeByPath(state, path);
      if (node === undefined) {
        // Ancestors nodes can be removed prior to their descendants
        // This is due to React firing useEffect cleanups in parents
        // before children. Treat as no-op
        debug(`Could not find node at path ${path}`, state);
        return state;
      }
      if (isStoredComponentNode(node)) {
        purgeCache(node);
      }

      delete state.detachedBranches[path];
      delete state.pendingOutputChanges[path];

      const index = node.parent?.children.findIndex(n => n === node);
      if (index !== undefined && index !== -1) {
        node.parent?.children.splice(index, 1);
      }
      return { ...state, lastMutationTimestamp: timestamp };
    }

    case "UPDATE_OUTPUT": {
      const { path, output } = action.payload;
      return {
        ...state,
        updateBatch: [...state.updateBatch, [path, output]] as [string, Object | null][]
      };
    }

    case "COMMIT_UPDATE_BATCH": {
      let nextState = { ...state, updateBatch: [] as [string, Object | null][] };
      state.updateBatch.forEach(([path, output]) => {
        const node = findNodeByPath(state, path);
        if (!node) {
          // Since useEffect effects will fire in descendants before anecestors
          // when a deep tree is mounted, there are cases where output updates need
          // to be captured and applied once their target node gets registered
          debug(
            "UPDATE_OUTPUT occurred before node registered. Saving change for later application",
            path,
            state
          );
          const existingPendingChanges = nextState.pendingOutputChanges[path] || {};
          nextState = {
            ...nextState,
            pendingOutputChanges: {
              ...nextState.pendingOutputChanges,
              [path]: output === null ? null : { ...existingPendingChanges, ...output }
            }
          };
        } else if (isStoredComponentNode(node)) {
          const prevOutput = node.output || {};
          const nextOutput = output === null ? null : { ...prevOutput, ...output };
          if (!isEqual(prevOutput, nextOutput)) {
            node.output = nextOutput;
          }
        }
      });
      return nextState as StoredRootNode;
    }

    case "RECURSIVELY_CLEAR_OUTPUT": {
      const { path } = action.payload;
      const node = findNodeByPath(state, path);
      let updateBatch = [...state.updateBatch];
      if (node && isStoredComponentNode(node)) {
        let queue: StoredComponentNode[] = [node];
        while (queue.length > 0) {
          const n = queue.shift();
          if (n) {
            n.output = null;
            updateBatch = updateBatch.filter(ub => ub[0] !== n.path);
            queue = queue.concat(n.children);
          }
        }
      }
      return { ...state, updateBatch };
    }

    default:
      return state;
  }
}

const pathIsCollectionRegex = /\[[0-9]+\]$/;

function getSlugOrThrow(parts: (string | number)[]) {
  const last = parts[parts.length - 1];
  const slug = typeof last === "number" ? parts[parts.length - 2] : last;
  if (typeof slug !== "string") throw new Error(`unexpected slug format: ${slug}`);
  return slug;
}

function selectStateTree(node: StoredComponentNode | StoredRootNode, treeNode: object) {
  if (isStoredComponentNode(node)) {
    treeNode = {
      ...node.output
    };
  }
  const subTree = node.children.reduce<Record<string, Object | Object[]>>(
    (acc, curr) => {
      const key = curr.slug;
      const path = curr.path;
      const branchNode = {};
      const val = selectStateTree(curr, branchNode);
      if (pathIsCollectionRegex.test(path)) {
        if (Array.isArray(acc[key])) {
          (acc[key] as Object[]).push(val);
        } else {
          acc[key] = [val];
        }
      } else {
        acc[key] = val;
      }
      return acc;
    },
    {}
  );
  treeNode = {
    ...treeNode,
    ...subTree
  };
  return treeNode;
}

const emptyComponents: SpaceComponentObject[] = [];
export const initialContext = {
  initialized: false,
  stateTree: {},
  components: emptyComponents,
  registerNode: (_path: string) => {},
  unregisterNode: (_path: string) => {},
  getIsPathRegistered: (_path: string) => false as boolean,
  findNode: (_path: string) => ({} as ComponentNode | undefined),
  updateOutput: (_path: string, _output: Object) => {},
  recursivelyClearOutput: (_path: string) => {},
  findInvalidInputBindings: (_component: ComponentNode) => [] as string[]
};
export const RenderTreeContext = React.createContext(initialContext);

export const useRenderTreeContext = () => React.useContext(RenderTreeContext);

interface RenderTreeContainerProps {
  children: React.ReactNode;
}

export function RenderTreeContainer({ children }: RenderTreeContainerProps) {
  // nodeMap and nodeSet are module level caches used to optimize render
  // tree lookups. Entries in them *should* be cleaned up as render nodes
  // unregister, but the root cause of https://www.pivotaltracker.com/story/show/176988824
  // was due to the entries not being cleaned up. Current theory is that this component
  // may unmount before processing all unregisters when transitioning from a space with many
  // render tree nodes. As a safe guard explicitly clear the cache on mount and unmount.
  // If this is a typical useEffect it is possible for components to register before the effect
  // runs and be premptively purged from the cache, so a useLayoutEffect is used to ensure
  // the cache is cleared before any downstream components' registration effects run.
  React.useLayoutEffect(() => {
    // HACK: In tests component trees can be mounted in the same render as the RenderTreeContainer
    // causing node registrations to effectively be dropped by this effect. As a hack skip clearing
    // the node caches if code is executing in a test environment.
    if (process.env.JEST_WORKER_ID === undefined) {
      nodeMap = new Map();
      nodeSet = new Set();
    }
    return () => {
      nodeMap = new Map();
      nodeSet = new Set();
    };
  }, []);

  const [state, dispatch] = React.useReducer(renderTreeReducer, {
    children: [],
    parent: undefined,
    detachedBranches: {},
    path: "",
    pendingOutputChanges: {},
    pseudoComponents: new Map(),
    lastMutationTimestamp: -1,
    updateBatch: [] as [string, Object | null][]
  });
  const { components, componentTree } = useSpaceContext();

  React.useEffect(() => {
    if (state.updateBatch.length) {
      dispatch({ type: "COMMIT_UPDATE_BATCH", payload: {} });
    }
  }, [state.updateBatch]);

  const stateTree = React.useMemo(() => {
    debug("derive stateTree from", state);
    const tree = selectStateTree(state, {});
    debug("derived stateTree", tree);
    return tree;
  }, [state]);

  const componentMap = React.useMemo(() => {
    const map = new Map(state.pseudoComponents);
    components.forEach(c => {
      map.set(c.slug, c);
    });
    return map;
  }, [components, state.pseudoComponents]);

  const registerNode = React.useCallback(
    function registerNode(path: string) {
      debug("registerNode", path);
      const slug = getSlugOrThrow(parsePath(path));
      dispatch({
        type: "REGISTER",
        payload: {
          path,
          slug,
          isPsuedoComponent: PSEUDO_COMPONENT_SLUGS.has(slug),
          timestamp: Date.now()
        }
      });
    },
    [dispatch]
  );

  const unregisterNode = React.useCallback(
    function unregisterNode(path: string) {
      debug("unregisterNode", path);
      dispatch({
        type: "UNREGISTER",
        payload: { path, timestamp: Date.now() }
      });
    },
    [dispatch]
  );

  const getComponent = React.useCallback(
    function getComponent(slug: string) {
      const component = componentMap.get(slug);
      if (component === undefined) {
        debug(
          "Expected to find component for StoredComponentNode. StoredComponent may have been deleted, but not yet unregistered.",
          slug
        );
      }
      return component;
    },
    [componentMap]
  );

  // Since render graph is not mutated it can not be used to break
  // memoization on the total set of nodes here. Instead we keep a
  // timestamp of the last graph mutation and break memoization when it
  // or the underlying component configs change.
  const { lastMutationTimestamp } = state;
  const findNode = React.useCallback(
    function findNode(path: string): ComponentNode | undefined {
      debug("begin findNode", path, lastMutationTimestamp);
      function resolveChildren(node: StoredComponentNode) {
        node.children.forEach(c => {
          c.component = getComponent(c.slug);
          if (c.component !== undefined) {
            resolveChildren(c);
          }
        });
        // Filter out children whose component is undefined. This case occurs
        // when a sub component has been deleted, but the effect to unregiser
        // it from the render graph has not been processed yet.
        node.children = node.children.filter(c => c.component !== undefined);
      }

      if (!path) return undefined;

      const resolvedNode = nodeMap.get(path);
      if (resolvedNode === undefined) {
        return undefined;
      }
      resolvedNode.component = getComponent(resolvedNode.slug);

      // Return undefined if the component for this node is undefined. This case
      // occurs when a component has been deleted, but the effect to unregister
      // it from the component graph has not been processed yet.
      if (resolvedNode.component === undefined) return undefined;

      resolveChildren(resolvedNode);

      let currentNode: StoredComponentNode = resolvedNode;
      while (isStoredComponentNode(currentNode.parent)) {
        currentNode = currentNode.parent;
        currentNode.component = getComponent(currentNode.slug);
        // Return undefined if an ancestor of this node's component is undefined.
        // This case occurs when an ancestor is deleted, and the effects to unregister
        // this branch of the render tree have not yet been processed.
        if (currentNode.component === undefined) {
          return undefined;
        }
      }

      // The node is in a detached branch and should not be "findable" yet.
      if (!isStoredRootNode(currentNode.parent)) {
        return undefined;
      }

      if (!isComponentNode(resolvedNode)) {
        throw new Error("Expected component node.");
      }
      debug("finish findNode", path, resolvedNode);
      return resolvedNode;
    },
    [getComponent, lastMutationTimestamp]
  );

  const getIsPathRegistered = React.useCallback(
    function getIsPathRegistered(path: string) {
      return !!findNodeByPath({ children: state.children } as StoredRootNode, path);
    },
    [state.children]
  );

  const updateOutput = React.useCallback(function updateOutput(
    path: string,
    output: Object
  ) {
    dispatch({ type: "UPDATE_OUTPUT", payload: { path, output } });
  },
  []);

  // This is a hydrated render tree that represents all components in
  // a space regardless of whether or not they are visible.
  const renderTreeSchema = React.useMemo(() => {
    return fromComponents(componentTree);
  }, [componentTree]);

  const findInvalidInputBindings = React.useCallback(
    function findInvalidInputBindings(node: ComponentNode) {
      const pivot = findPivotInOtherTree(renderTreeSchema, node);
      return pivot ? findInvalidInputBindingsUtil(pivot, renderTreeSchema) : [];
    },
    [renderTreeSchema]
  );

  const recursivelyClearOutput = React.useCallback(function (path: string) {
    dispatch({ type: "RECURSIVELY_CLEAR_OUTPUT", payload: { path } });
  }, []);

  const value = React.useMemo(
    () => ({
      initialized: true,
      stateTree,
      components,
      registerNode,
      unregisterNode,
      findNode,
      getIsPathRegistered,
      updateOutput,
      findInvalidInputBindings,
      recursivelyClearOutput
    }),
    [
      stateTree,
      components,
      registerNode,
      unregisterNode,
      findNode,
      getIsPathRegistered,
      updateOutput,
      findInvalidInputBindings,
      recursivelyClearOutput
    ]
  );

  return (
    <RenderTreeContext.Provider value={value}>{children}</RenderTreeContext.Provider>
  );
}
