import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Input } from "antd";
import styled from "styled-components";

import { SSHKeyFormatData, SSH_KEY_FORMAT } from "./queries";
import { FormItem } from "./styledComponents";

const Help = styled.p`
  color: ${props => props.theme.textColor};
`;

const SSHHelp = () => {
  const { data } = useQuery<SSHKeyFormatData>(SSH_KEY_FORMAT);
  const publicKey = data?.authOrganization?.sshKeyFormat || "";

  return (
    <>
      <FormItem label="SSH Public Key">
        <Input.TextArea value={publicKey} readOnly rows={6} />
      </FormItem>
      <Help>
        <a
          href="https://www.internal.io/docs/ssh-tunneling"
          target="_blank"
          rel="noopener noreferrer"
        >
          View instructions
        </a>{" "}
        for SSH Tunneling.
      </Help>
    </>
  );
};

export default SSHHelp;
