import moment from "moment";
import momentTz from "moment-timezone";

// The keys in this map represent the names generated from moment
// The values represent OUR business logic for how to render them
// https://github.com/moment/moment-timezone/issues/43
export const zoneDisplayNameMap: Record<string, string> = {
  PST: "Pacific Standard Time",
  PDT: "Pacific Daylight Time",
  // CST is not included because it corresponds to too many  names
  // for example China Standard/Central Standard
  // Omitting it means that it will appear as CST (China Standard Time)
  CDT: "Central Daylight Time",
  GMT: "Greenwich Mean Time",
  JST: "Japan Standard Time",
  MDT: "Mountain Daylight Time",
  MST: "Mountain Standard Time",
  EDT: "Eastern Daylight Time",
  EST: "Eastern Standard Time",
  AKDT: "Alaska Daylight Time",
  AKST: "Alaska Standard Time",
  HST: "Hawaii Standard Time",
  EET: "Eastern European Time",
  AEST: "Australian Eastern Standard Time",
  AEDT: "Australian Eastern Daylight Time",
  IST: "Ireland Standard Time",
  CEST: "Central European Summer Time",
  EEST: "Eastern European Summer Time",
  CET: "Central European Time",
  "-02": "Atlantic Islands Time",
  "-03": "Brazil Eastern Time",
  "+03": "Moscow Standard Time",
  "+04": "Dubai Standard Time",
  "+05": "Maldives Time",
  "+0530": "India Standard Time",
  "+06": "Bangladesh Standard Time",
  "+07": "Vietnam Standard Time",
  "+10": "Chamorro Time",
  GET: "Gulf Standard Time",
  "+11": "Solomon Standard Time",
  "+12": "New Zealand Standard Time"
};

export const momentZoneLocales = [
  "Etc/GMT",
  "Europe/Dublin",
  "Europe/Budapest",
  "Europe/Istanbul",
  "Asia/Muscat",
  "Indian/Maldives",
  "Asia/Colombo",
  "Asia/Dhaka",
  "Asia/Bangkok",
  "Asia/Macau",
  "Asia/Tokyo",
  "Australia/Brisbane",
  "Pacific/Bougainville",
  "Pacific/Funafuti",
  "Pacific/Honolulu",
  "America/Anchorage",
  "America/Los_Angeles",
  "America/Edmonton",
  "America/Chicago",
  "America/New_York",
  "America/Sao_Paulo",
  "America/Noronha"
];

// https://momentjs.com/timezone/docs/#/using-timezones/default-timezone/
// Moment TZ loads times in local zone. When this happens, currentMoment.tz() is undefined
// However if the moment object is  explicitly mutated to `value.tz("America/New_York")`
// We ARE able to detect the zone locale.
export const getZoneLocale = (currentMoment: moment.Moment) => {
  return currentMoment.tz() || momentTz.tz.guess();
};
