import React from "react";

import { Button, Icon, Input } from "antd";
import * as clipboard from "clipboard-polyfill/text";
import styled from "styled-components";

import Message from "../../../../common/Message";
import { ErrorField } from "../../../../common/StyledComponents";
import { ProviderProps } from "../SingleSignOnDrawer";

const Root = styled.div`
  & > div {
    margin-top: ${props => props.theme.spacermd};
  }
`;

const StyledList = styled.ul`
  margin-top: ${props => props.theme.spacermd};
`;

const SAMLSSOProvider = ({
  connection,
  setConnection,
  errors,
  redirect
}: ProviderProps) => {
  return (
    <Root>
      <div>
        <h3>Internal Single Sign-On URL</h3>
        <Input
          disabled
          value={connection.samlAcsUrl || ""}
          addonAfter={
            <Icon
              type="copy"
              onClick={() => {
                clipboard.writeText(connection.samlAcsUrl || "").then(
                  () => {
                    Message.info("Copied Single Sign-On URL to clipboard");
                  },
                  () => {
                    Message.error("Unable to copy to clipboard.");
                  }
                );
              }}
            />
          }
        />
      </div>
      <div>
        <h3>SAML Attribute Mapping</h3>
        <p>
          Ensure that the following attribute mappings have been created. Internal uses
          these to match a user from your identity provider to a user within Internal.
        </p>
        <StyledList>
          <li>
            <strong>email:</strong> The user's email address.
          </li>
          <li>
            <strong>firstName:</strong> The user's first name.
          </li>
          <li>
            <strong>lastName:</strong> The user's last name.
          </li>
        </StyledList>
      </div>
      <div>
        <h3>Identity Provider Single Sign-On URL</h3>
        <Input
          value={connection.samlSsoUrl || ""}
          onChange={e => setConnection({ ...connection, samlSsoUrl: e.target.value })}
        />
        {errors.samlSsoUrl && <ErrorField>{errors.samlSsoUrl}</ErrorField>}
      </div>
      <div>
        <h3>Identity Provider Issuer</h3>
        <Input
          value={connection.samlEntityId || ""}
          onChange={e => setConnection({ ...connection, samlEntityId: e.target.value })}
        />
        {errors.samlEntityId && <ErrorField>{errors.samlEntityId}</ErrorField>}
      </div>
      <div>
        <h3>X.509 Certificate</h3>
        <Input.TextArea
          value={connection.samlX509Cert || ""}
          onChange={e => setConnection({ ...connection, samlX509Cert: e.target.value })}
          rows={10}
        />
        {errors.samlX509Cert && <ErrorField>{errors.samlX509Cert}</ErrorField>}
      </div>
      <div>
        <p>
          Before requiring SSO for all employees, you must verify that the SAML 2.0
          provider works correctly.
        </p>
        <a href={`/api/sso/login/saml?next=${redirect}`}>
          <Button disabled={connection.state !== "active"}>
            Test SAML Configuration
          </Button>
        </a>
      </div>
    </Root>
  );
};

export default SAMLSSOProvider;
