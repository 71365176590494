import React from "react";

import styled from "styled-components";

import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import { B3, SurfaceTertiary } from "../../../common/StyledComponents";

interface ReloadBannerProps {
  newTasksCount: number;
  onRefresh: () => void;
}

const BannerRoot = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 20px;
  margin-left: 25%;
  margin-right: 25%;
  padding: 10px 20px;
  background: white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

export default function RefreshBanner({ newTasksCount, onRefresh }: ReloadBannerProps) {
  if (newTasksCount === 0) {
    return null;
  }
  return (
    <BannerRoot>
      <B3>
        <SurfaceTertiary>Just now</SurfaceTertiary> <span>{newTasksCount}</span> tasks
        changed.
      </B3>
      <ButtonNew type="noFillAccent" onClick={onRefresh}>
        Refresh queue
      </ButtonNew>
    </BannerRoot>
  );
}
