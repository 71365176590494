import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input, Select } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import gql from "graphql-tag";

import { AuthorizationFlowNode } from "../../../types";

import { Header, LeftSide, RightSide } from "./styledComponents";

interface Props extends FormComponentProps {
  authorizationProvider: AuthorizationFlowNode;
  onCloseButtonClick(): void;
  onEditComplete(): void;
}

const UPDATE_BASIC_AUTH_PROVIDER = gql`
  mutation UpdateBasicAuthorizationProvider($id: ID!, $name: String!) {
    updateBasicAuthorizationProvider(id: $id, name: $name) {
      ok
    }
  }
`;

const BasicAuthProviderDrawer = ({
  onCloseButtonClick,
  onEditComplete,
  form,
  authorizationProvider
}: Props) => {
  const getFieldDecorator = form.getFieldDecorator;

  const [updateProvider, { loading: isSaveLoading }] = useMutation(
    UPDATE_BASIC_AUTH_PROVIDER,
    {
      onCompleted: () => {
        onEditComplete();
      }
    }
  );

  const fireUpdate = () => {
    const vars = { id: authorizationProvider.id };

    const touchedFields = ["name"].filter(fieldName => form.isFieldTouched(fieldName));

    if (!touchedFields.length) return;

    const changes = form.getFieldsValue(touchedFields);

    if (changes.scopes) {
      changes.scopes = changes.scopes.split(" ").filter((s: string) => s.trim() !== "");
    }

    updateProvider({ variables: { ...vars, ...changes } });
  };

  return (
    <>
      <Header>
        <LeftSide>
          <h2>Basic Authorization Provider</h2>
        </LeftSide>
        <RightSide>
          <Button onClick={onCloseButtonClick}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!form.isFieldsTouched()}
            loading={isSaveLoading}
            onClick={fireUpdate}
          >
            Save
          </Button>
        </RightSide>
      </Header>

      <Form hideRequiredMark layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator("name", {
            initialValue: authorizationProvider.name,
            rules: [{ required: true, message: "Name is required." }]
          })(<Input placeholder="Name" />)}
        </Form.Item>
        <Form.Item label="Type">
          <Select disabled value={0}>
            <Select.Option value={0}>Basic Authorization</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create<Props>({
  name: "editBasicAuthProvider"
})(BasicAuthProviderDrawer);
