import React from "react";

import styled from "styled-components";

import ThemeContainer, { Theme } from "../../common/ThemeContainer";
import DefaultEnvironmentStatement from "../DefaultEnvironmentStatement";

const FormContainer = styled.div`
  margin-top: 80px;
  padding-left: ${props => props.theme.spacerlg};
  padding-right: ${props => props.theme.spacerlg};

  u {
    text-decoration-color: ${props => props.theme.secondaryColor};
  }

  button[type="submit"] {
    height: 40px;
    width: 216px;
    font-size: 16px;
  }
`;

interface Props {
  title: string;
  children: React.ReactNode;
}

const CreateDataSourceFormContainer = (props: Props) => {
  return (
    <ThemeContainer theme={Theme.Dark}>
      <FormContainer>
        <h2>
          <u>Connect</u> to {props.title}
        </h2>
        <DefaultEnvironmentStatement />
        {props.children}
      </FormContainer>
    </ThemeContainer>
  );
};

export default CreateDataSourceFormContainer;
