import { Layout, Drawer as AntDrawer } from "antd";
import styled from "styled-components";

import { MENU_DRAWER_SIZE } from "../../../../cssConstants";
import BaseThemeContainer from "../../../common/ThemeContainer";

export { Tooltip } from "antd";

export const AntSider = styled(Layout.Sider)`
  background-color: ${props => props.theme.backgroundColor};
  height: 100%;
  color: ${props => props.theme.textColor};
  transition: none;

  .ant-layout-sider-trigger {
    display: none;
  }

  &.push {
    margin-right: ${MENU_DRAWER_SIZE}px;
  }
`;

export const MenuTab = styled.div`
  display: flex;
  width: ${props => props.theme.leftMenuWidth};
  height: ${props => props.theme.leftMenuWidth};
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div<{ active: boolean }>`
  color: ${props => (props.active ? props.theme.primaryColor : props.theme.textColor)};

  > svg {
    margin-top: 20px;

    &:hover {
      cursor: pointer;
      color: ${props => props.theme.primaryColor};
    }
  }
`;

export const DrawerContainer = styled.div`
  height: 100%;
`;

export const Drawer = styled(AntDrawer)`
  &.ant-drawer-left {
    margin-top: ${props => props.theme.headerHeight};
    height: calc(100% - ${props => props.theme.headerHeight});
  }

  & > * {
    transition: 0s;
  }

  &.ant-drawer-left.ant-drawer-open {
    margin-left: ${props => props.theme.leftMenuWidth};

    .ant-drawer-mask {
      opacity: 0;
      margin-left: -${props => props.theme.leftMenuWidth};
      margin-top: -${props => props.theme.headerHeight};
      animation: 0s;
    }
  }

  .ant-drawer-body {
    padding: 0;
    height: 100%;
  }

  button.ant-drawer-close {
    display: none;
  }

  .ant-drawer-wrapper-body {
    background-color: ${props => props.theme.headerBackgroundColor};
    color: ${props => props.theme.borderGrey};
    box-sizing: border-box;
  }
`;

export const ExpandDrawer = styled(Drawer)`
  &.ant-drawer-left.ant-drawer-open {
    margin-left: ${props => parseInt(props.theme.leftMenuWidth) + MENU_DRAWER_SIZE}px;
    width: calc(
      100% - ${props => parseInt(props.theme.leftMenuWidth) + MENU_DRAWER_SIZE}px
    );
    border-left: 1px solid ${props => props.theme.menuBorderColor};

    .ant-drawer-mask {
      margin-left: 0;
    }
  }
`;

export const ThemeContainer = styled(BaseThemeContainer)`
  height: 100%;
`;
