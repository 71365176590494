import React from "react";

import styled from "styled-components";

import { useTransformationActionContext } from "../../layout/TransformationContext/TransformationContext";

const Mask = styled.div`
  width: 100%;
  height: 100%;
`;

export default function CanvasEventMask({ children }: { children: React.ReactNode }) {
  const { clearSelection } = useTransformationActionContext();

  return (
    <Mask
      onClick={() => {
        clearSelection();
      }}
    >
      {children}
    </Mask>
  );
}
