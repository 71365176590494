import React from "react";

import { Button, Form, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { ApolloError } from "apollo-client";
import styled from "styled-components";

import { Credentials, EnvironmentNode } from "../../../../../types";
import Message from "../../../../common/Message";
import { BasePanel } from "../../../../common/StyledComponents";
import { DataSource } from "../../../DataSourceTable";
import DataSourceSettingsForm from "../../DataSourceSettingsPanel/DataSourceSettingsForm";
import useUpdateDataSourceMutationFactory from "../../DataSourceSettingsPanel/DataSourceSettingsForm/useUpdateDataSourceMutationFactory";
import getChangedValues from "../../DataSourceSettingsPanel/getChangedValues";
import { SubHead } from "../../DataSourceSettingsPanel/styledComponents";

interface CredentialPanelProps extends FormComponentProps {
  credentials: Credentials;
  dataSource: DataSource;
  environment: EnvironmentNode;
  visible: boolean;
  hideSave?: boolean;
  removeCredentials: () => void;
}

const Container = styled.div`
  flex-direction: column;
  padding: ${props => props.theme.spacermd};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 2px 0;
`;

const PanelButtonContainer = styled.span`
  & > *:first-child:not(:last-child) {
    margin-right: ${props => props.theme.spacersm};
  }
`;

const DefaultText = styled.p`
  margin-top: ${props => props.theme.spacersm};
  font-style: italic;
`;

const CredentialPanel = ({
  visible,
  credentials,
  dataSource,
  hideSave,
  removeCredentials,
  form,
  environment
}: CredentialPanelProps) => {
  const [isOpen, setOpen] = React.useState(visible);
  const [modalVisible, setModalVisible] = React.useState<"update" | undefined>(
    undefined
  );
  const [error, setError] = React.useState("");
  const [updateDataSource, { loading: isUpdateLoading }] =
    useUpdateDataSourceMutationFactory({
      providerId: dataSource.dataSourceProviderId,
      integration: dataSource.integration,
      onCompleted: (success: boolean, message: string) => {
        if (success) {
          Message.success("Your credentials have been updated.");
          setError("");
          setOpen(false);
        } else {
          setError(message || "An unknown error occurred.");
        }
      },
      onError: (e: ApolloError) => {
        setError(`${e.message.replace("GraphQL error:", "")}`);
      }
    });

  React.useEffect(() => {
    if (isOpen) {
      setError("");
    }
  }, [isOpen]);

  const save = () => {
    form.validateFields(errors => {
      if (errors) return;
      updateDataSource({
        variables: {
          dataSourceId: dataSource.id,
          environmentId: environment.id,
          ...getChangedValues(dataSource.integration, form)
        }
      });
    });
  };

  return (
    <BasePanel>
      <Container>
        <Header>
          <SubHead>{environment.name} Environment</SubHead>
          {!isOpen && (
            <Button data-test="toggleVisible" onClick={() => setOpen(true)}>
              Edit
            </Button>
          )}
          {isOpen && (
            <PanelButtonContainer>
              {environment.isDefault && dataSource.supportsSync && !hideSave ? (
                <Button
                  data-test="confirmSaveCredentials"
                  type="primary"
                  onClick={() => setModalVisible("update")}
                >
                  Save
                </Button>
              ) : (
                !hideSave && (
                  <Button
                    data-test="saveCredentials"
                    loading={isUpdateLoading}
                    onClick={save}
                    type="primary"
                  >
                    Save
                  </Button>
                )
              )}
              <Button
                data-test="closeCredentialPanel"
                onClick={() => {
                  setOpen(false);
                  form.resetFields();
                }}
              >
                Close
              </Button>
            </PanelButtonContainer>
          )}
        </Header>
        {isOpen && (
          <div data-test="formContainer">
            <Form hideRequiredMark layout="inline" autoComplete="off">
              <DataSourceSettingsForm
                credentials={credentials}
                integration={dataSource.integration}
                isUpdateLoading={isUpdateLoading}
                form={form}
                error={error}
              />
              {environment.isDefault && dataSource.supportsSync && (
                <DefaultText data-test="defaultEnvCopy">
                  This is the default environment used to generate your schema.
                </DefaultText>
              )}
              {!environment.isDefault && (
                <Button
                  data-test="deleteCredential"
                  type="danger"
                  onClick={() => removeCredentials()}
                >
                  Delete
                </Button>
              )}
            </Form>
          </div>
        )}
      </Container>
      {modalVisible === "update" && (
        <Modal
          visible
          onCancel={() => setModalVisible(undefined)}
          title="Are you sure you want to update these credentials?"
          okButtonProps={{ loading: isUpdateLoading, type: "primary" }}
          okText="I'm Sure"
          onOk={() => {
            if (modalVisible) {
              setModalVisible(undefined);
            }
            save();
          }}
        >
          If your new credentials don’t have the same level of access, some Spaces may
          break.
        </Modal>
      )}
    </BasePanel>
  );
};

export default Form.create<CredentialPanelProps>({
  name: "credentials-update"
})(CredentialPanel);
