import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { isEqual } from "lodash";

import { SpaceNode, SpaceComponentNode, StatusCode } from "../../../../types";
import {
  PUBLISHED_SPACE_QUERY,
  LATEST_SPACE_QUERY,
  Data,
  LastSavedSpaceVars,
  PublishedSpaceVars
} from "../queries";

import useClientProvidedComponents from "./useClientProvidedComponents";

export interface Result {
  space: SpaceNode;
  loading: boolean;
  componentTree: SpaceComponentNode[];
  queryStatus: StatusCode;
  slug: string | undefined;
}

export const EMPTY_SPACE = Object.freeze({
  name: "",
  slug: "",
  id: "",
  latestVersion: { id: "", componentTreeNodes: [] },
  publishedVersion: { id: "", componentTreeNodes: [] }
});

enum SpaceResultTypes {
  NotFound = "NotFoundErrorResult",
  NotPublished = "NoPublishedVersionErrorResult"
}

export default function useSpace(
  slug: string | undefined,
  options: { editMode: boolean }
): Result {
  const { editMode } = options;
  const publishedSpaceResult = useQuery<Data, PublishedSpaceVars>(
    PUBLISHED_SPACE_QUERY,
    {
      variables: { slug: slug || "" },
      fetchPolicy: "cache-and-network",
      skip: editMode || !slug
    }
  );

  const latestSpaceResult = useQuery<Data, LastSavedSpaceVars>(LATEST_SPACE_QUERY, {
    variables: { slug: slug || "" },
    fetchPolicy: "cache-and-network",
    skip: !editMode || !slug
  });

  if (publishedSpaceResult.error) throw publishedSpaceResult.error;
  if (latestSpaceResult.error) throw latestSpaceResult.error;

  const result = editMode ? latestSpaceResult : publishedSpaceResult;

  const space = result.data?.space || EMPTY_SPACE;

  const clientProvidedComponents = useClientProvidedComponents();
  const componentTree = React.useMemo(() => {
    const nextComponentTree = editMode
      ? space.latestVersion?.componentTreeNodes
      : space.publishedVersion?.componentTreeNodes;
    if (nextComponentTree === undefined || result.loading) return [];
    return nextComponentTree.concat(clientProvidedComponents);
  }, [editMode, space, result, clientProvidedComponents]);

  const queryStatus = React.useMemo(() => {
    if (result.data?.space.__typename === SpaceResultTypes.NotFound) {
      return StatusCode.NOT_FOUND;
    }
    if (
      result.data?.space.publishedVersion?.__typename === SpaceResultTypes.NotPublished
    ) {
      return StatusCode.PERMISSION_DENIED;
    }
    return StatusCode.OK;
  }, [result.data]);

  return React.useMemo(() => {
    return {
      queryStatus,
      loading: result.loading || (!!slug && isEqual(space, EMPTY_SPACE)),
      space,
      componentTree,
      slug
    };
  }, [result.loading, space, componentTree, slug, queryStatus]);
}
