import React, { useEffect, useState } from "react";

import { getJavaScriptParseError } from "../../util/javascript";

import * as styled from "./styledComponents";
import { getQuotedValue } from "./utils";

interface EvaluatedStringProps {
  input: string;
  showValue: boolean;
  needsQuote: boolean;
}
const EvaluatedString = (props: EvaluatedStringProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const value = props.needsQuote ? getQuotedValue(props.input) : props.input;

  useEffect(() => {
    const error = getJavaScriptParseError(value);
    setErrorMessage(error ? error.message : "");
  }, [value]);

  const hasContent = (props.showValue && value) || errorMessage;
  return hasContent ? (
    <styled.EvaluatedStringContainer data-test="evaluatedStringContainer">
      {props.showValue && (
        <styled.StyledCode data-test="evaluatedValue" isValid={!errorMessage}>
          {value}
        </styled.StyledCode>
      )}
      {errorMessage && (
        <styled.ErrorMessage
          data-test="errorMessage"
          addMarginTop={props.showValue && !!value}
        >
          {errorMessage}
        </styled.ErrorMessage>
      )}
    </styled.EvaluatedStringContainer>
  ) : null;
};

export default EvaluatedString;
