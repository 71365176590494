import React from "react";

import { Button } from "antd";
import styled from "styled-components";

import GoogleLogo from "../../assets/g-logo.svg";

const Logo = styled(GoogleLogo)`
  width: 20px;
  height: 20px;
  margin-right: 11px;
`;

const ConnectButton = styled(Button)`
  @import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
  font-family: "Roboto", sans-serif;
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.65) !important;
  height: 40px;
  display: flex;
  align-items: center;
`;

const GoogleSheets = () => {
  const connect = () => {
    window.location.assign(`/api/oauth2/idp-redirect`);
  };
  return (
    <>
      <p>
        Granting access to your Google Sheets allows you to instantly build tools based
        on data within existing workbooks.
      </p>
      <ConnectButton onClick={() => connect()}>
        <Logo />
        <span>Continue with Google</span>
      </ConnectButton>
    </>
  );
};

export default GoogleSheets;
