import { AttributeTypes, ReturnSchema } from "../../../../constants";
import {
  BaseFunctionName,
  BaseFunctionNodeBasic,
  FunctionNodeBasic,
  FunctionParameterInput,
  Metadata
} from "../../../../types";
import { HttpMethods, RequestBodyType } from "../forms/constants";
import { DataSourceNodeWithFunctions } from "../forms/types";
import {
  HttpBaseFunctionParameterMapping,
  HttpFunctionMetadata,
  Identifier,
  IdentifierMap,
  ReservedListIdentifierMap,
  ReservedListParameter
} from "../index";
import { EditorSupport, SupportedIntegration } from "../support";

import { EditorFunctionNode } from "./queries";
import { FunctionState, State } from "./reducer";

export const defaultBaseFunction = (
  dataSource: DataSourceNodeWithFunctions<FunctionNodeBasic>
) => {
  const integration = dataSource?.integration;
  if (!integration) return;

  const functionName =
    EditorSupport[integration as SupportedIntegration].defaultFunctionName;
  if (!functionName) return;

  const fn = findFunction(dataSource, functionName);
  if (!fn) {
    throw new Error(
      `useFunctionEditor: could not find default function for datasource: ${dataSource.id}`
    );
  }
  return fn;
};

export const defaultBaseFunctionParameterMapping = (
  name: BaseFunctionName
): object | string => {
  switch (name) {
    case BaseFunctionName.REQUEST:
      const m: HttpBaseFunctionParameterMapping = {
        method: `"${HttpMethods.Get}"`,
        path: "``",
        header: "{}",
        query: "{}"
      };
      return m;
    default:
      return {};
  }
};

export const defaultMetadata = (name: BaseFunctionName) => {
  switch (name) {
    case BaseFunctionName.INSERT:
    case BaseFunctionName.UPDATE:
    case BaseFunctionName.DELETE:
      return { categories: ["mutation"] };
    case BaseFunctionName.REQUEST:
      const metadata: HttpFunctionMetadata = {
        categories: ["mutation"],
        request_type: RequestBodyType.None
      };
      return metadata;
    default:
      return {};
  }
};

export const METADATA_REDUCER_HTTP_STATUS_CODE_ERROR = `const status = metadata.http.response.status;
if (status < 200 || status >= 300) {
    throw new Error("Server responded with " + status);
}`;

export const METADATA_REDUCER_GRAPHQL_ERROR = `if (data && (typeof data === "object") && data.errors && data.errors.length) {
  const errorMessage = data.errors.filter(err => !!err.message)
                                  .map(err => err.message)
                                  .join(",") || "Data source response error";
  throw new Error(errorMessage);
}`;

export const METADATA_REDUCER_SQL_QUERY = "{ page: { hasNext: false } }";
export const METADATA_REDUCER_DEFAULT = "metadata";

export const defaultMetadataReducer = (
  name: BaseFunctionName,
  bodyType?: RequestBodyType
) => {
  switch (name) {
    case BaseFunctionName.REQUEST:
      let reducer = METADATA_REDUCER_HTTP_STATUS_CODE_ERROR;

      // GraphQL should have errors on data when an error occurs
      // https://spec.graphql.org/October2021/#sec-Errors
      if (bodyType === RequestBodyType.GraphQL) {
        // Add two newlines for nicer formatting
        reducer += `\n\n${METADATA_REDUCER_GRAPHQL_ERROR}`;
      }

      return reducer;
    case BaseFunctionName.SQL_QUERY:
      return METADATA_REDUCER_SQL_QUERY;
    default:
      return METADATA_REDUCER_DEFAULT;
  }
};

export const defaultReturnSchema = (name: BaseFunctionName) => {
  switch (name) {
    case BaseFunctionName.QUERY:
    case BaseFunctionName.AGGREGATE:
      return ReturnSchema.OBJECT_ARRAY;
    default:
      return ReturnSchema.UNKNOWN;
  }
};

export const findFunction = (
  dataSource: DataSourceNodeWithFunctions<FunctionNodeBasic>,
  name: string
) => {
  if (!dataSource) return;
  const fn = dataSource.functions.edges.find(edge => edge.node.name === name);
  return fn?.node;
};

export const toParameter = (identifier: Identifier): FunctionParameterInput => {
  const reserved = ReservedListIdentifierMap[identifier.name as ReservedListParameter];
  return reserved
    ? {
        name: reserved.name,
        type: reserved.type || AttributeTypes.STRING,
        required: false
      }
    : {
        name: identifier.name,
        type: identifier.type || AttributeTypes.STRING,
        required: true
      };
};

export const getParameters = (existing: FunctionParameterInput[], map: IdentifierMap) =>
  Object.entries(map).reduce<FunctionParameterInput[]>(function (
    acc,
    [name, identifier]
  ) {
    const found = existing.find(p => p.name === name);
    if (found) {
      acc.push(found);
    } else {
      acc.push(toParameter(identifier));
    }
    return acc;
  },
  []);

const ensureObject = (input: string | object): object =>
  typeof input === "string" ? JSON.parse(input) : input;

export const toFunctionState = <M>(func: EditorFunctionNode<M>): FunctionState<M> => {
  const parameters = func.functionParameters.edges.map(e => ({
    name: e.node.name,
    required: e.node.required,
    type: e.node.type
  }));
  const attributes = func.functionAttributes.edges.map(e => ({
    name: e.node.sourceName,
    type: e.node.sourceType,
    key: e.node.sourceKey
  }));
  const authorizationFlows = func.functionAuthorizationFlows.edges.map(e => ({
    environmentId: e.node.environment.id,
    authorizationFlowId: e.node.authorizationFlow.id
  }));
  const environmentIdsWithCredentials = (
    func.environmentsWithCredentials?.edges || []
  ).reduce((acc, e) => ({ ...acc, [e.node.id]: true }), {});
  return {
    integration: func.dataSource.integration,
    title: func.title,
    baseFunctionId: func.baseFunction.id,
    baseFunctionName: func.baseFunction.name as BaseFunctionName,
    baseFunctionParameterMapping: ensureObject(func.baseFunctionParameterMapping),
    functionId: func.id,
    metadata: func.metadata,
    reducer: func.reducer,
    metadataReducer: func.metadataReducer,
    returnSchema: func.returnSchema,
    parameters,
    attributes,
    authorizationFlows,
    environmentIdsWithCredentials
  };
};

export const getFunctionState = <M>(state: State<M>): FunctionState<M> => {
  return {
    integration: state.integration,
    authorizationFlows: state.authorizationFlows,
    attributes: state.attributes,
    baseFunctionId: state.baseFunctionId,
    baseFunctionName: state.baseFunctionName,
    baseFunctionParameterMapping: ensureObject(state.baseFunctionParameterMapping),
    functionId: state.functionId,
    metadata: state.metadata,
    metadataReducer: state.metadataReducer,
    parameters: state.parameters,
    reducer: state.reducer,
    returnSchema: state.returnSchema,
    title: state.title,
    environmentIdsWithCredentials: state.environmentIdsWithCredentials
  };
};

export const getDefaultFunctionState = (
  dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>,
  baseFunctionName: BaseFunctionName
): FunctionState<Metadata<unknown>> => {
  const basicFunction = findFunction(dataSource, baseFunctionName);

  if (!basicFunction) {
    throw new Error(`could not find function with name: ${baseFunctionName}`);
  }

  return {
    integration: dataSource.integration,
    attributes: [],
    baseFunctionId: basicFunction.id,
    baseFunctionName: baseFunctionName,
    baseFunctionParameterMapping: defaultBaseFunctionParameterMapping(baseFunctionName),
    functionId: "",
    metadata: defaultMetadata(baseFunctionName),
    metadataReducer: defaultMetadataReducer(baseFunctionName),
    parameters: [],
    reducer: "data",
    returnSchema: undefined,
    title: "",
    authorizationFlows: [],
    environmentIdsWithCredentials: {}
  };
};
