import React from "react";

import { Select } from "antd";
import produce from "immer";

import {
  ManagedConditionalExpression,
  ConditionalOperator as ConditionalOperatorType,
  ConditionalOperatorDisplayNames,
  ObjectMode,
  ManagedExpressionDefinitions
} from "./types";
import { getObjectMode } from "./util";

interface Props {
  expression: ManagedConditionalExpression;
  options?: ConditionalOperatorType[];
  dropdownStyle?: React.CSSProperties;
  onChange: (conditional: ManagedConditionalExpression) => void;
}

const OPERATOR_GROUPS: [ObjectMode, string, string][] = [
  ["text", "Value", ""],
  ["binding", "Value from component", " bound value"],
  ["void", "Pattern", ""]
];

export const OPERATORS: ConditionalOperatorType[] = Object.values(
  ConditionalOperatorType
);

export const getNextValueOnOperatorChange = (value: any, isNull: boolean) => {
  if (isNull) return null;
  if (!isNull && value === null) return "";
  return value;
};

export default function ConditionalOperator({
  expression,
  options = OPERATORS,
  dropdownStyle,
  onChange
}: Props) {
  const mode = getObjectMode(expression.object_template);
  const operator = `${mode}.${expression.operator}`.toLowerCase();
  const onOperatorSelect = (value: string) => {
    const [nextMode, operatorType] = value.split(".") as [
      ObjectMode,
      ConditionalOperatorType
    ];

    onChange(
      produce(expression, (nextExpression: ManagedConditionalExpression) => {
        nextExpression.type = "managed";
        nextExpression.operator = operatorType;
        if (mode !== nextMode) {
          if (nextMode === "text") {
            nextExpression.object_template = "``";
          } else if (nextMode === "binding") {
            nextExpression.object_template = "";
          } else {
            nextExpression.object_template = undefined;
          }
        }
      })
    );
  };

  return (
    <Select
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      dropdownStyle={dropdownStyle}
      value={operator}
      onChange={onOperatorSelect}
      data-test="operatorSelect"
    >
      {OPERATOR_GROUPS.map(([groupValue, groupLabel, groupLabelSuffix]) => {
        return (
          <Select.OptGroup key={groupValue} label={groupLabel}>
            {options.map(operator => {
              const value = `${groupValue}.${operator}`.toLowerCase();
              const optionText =
                ConditionalOperatorDisplayNames.get(operator) + groupLabelSuffix;
              if (
                (groupValue === "binding" || groupValue === "text") &&
                !ManagedExpressionDefinitions.get(operator)?.hasConditionalObject
              ) {
                return null;
              }
              if (
                groupValue === "void" &&
                ManagedExpressionDefinitions.get(operator)?.hasConditionalObject
              ) {
                return null;
              }
              return (
                <Select.Option key={value} value={value} title={optionText}>
                  {optionText}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        );
      })}
    </Select>
  );
}
