import gql from "graphql-tag";

import { QueueNode } from "../../queries/AllQueues";

export interface QueueWithFunctionsVariables {
  slug: string;
}

export interface QueueFunctionNode {
  id: string;
  name: string;
}

export interface QueueNodeWithFunctions extends QueueNode {
  functions: QueueFunctionNode[];
}

export interface QueueWithFunctionsData {
  queueBySlug: QueueNodeWithFunctions;
}

export const QUEUE_BY_SLUG_WITH_FUNCTIONS = gql`
  query QueueBySlug($slug: String!) {
    queueBySlug(slug: $slug) {
      id
      name
      description
      slug
      states {
        id
        name
      }
      functions {
        id
        name
      }
    }
  }
`;
