import React from "react";

import { Alert } from "antd";
import Papa from "papaparse";
import Dropzone, { FileError, FileRejection } from "react-dropzone";
import styled from "styled-components";

import { BulkImportModalStepProps } from "../BulkImportModal";
import { BulkImportStep } from "../reducer";

const StyledDropzone = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-width: ${props => props.theme.spacerxs};
  border-radius: ${props => props.theme.spacerxs};
  border-color: ${props => props.theme.primaryColorFaded};
  border-style: dashed;
  outline: none;
`;

const StyledAlert = styled(Alert)`
  margin-top: ${props => props.theme.spacersm};
`;

type Props = BulkImportModalStepProps;

function formatFileRejectionErrors(rejections: FileRejection[]): FileError[] {
  return rejections
    .flatMap(r => r.errors)
    .map(e => {
      if (e.code === "file-too-large") {
        return {
          code: e.code,
          message: "File is too big, it must 1 MB or less"
        };
      }

      return e;
    });
}

const SelectFileStep = ({ dispatch }: Props) => {
  const [dropErrors, setDropErrors] = React.useState<FileError[]>([]);

  const onDropRejected = React.useCallback(
    (rejections: FileRejection[]) => {
      const messages = formatFileRejectionErrors(rejections);
      setDropErrors(messages);
    },
    [setDropErrors]
  );

  const parseFile = React.useCallback(
    (f: File) => {
      Papa.parse(f, {
        error: () => {
          setDropErrors([
            {
              code: "parse",
              message:
                "We were unable to parse your file. Please upload a valid csv or tsv file."
            }
          ]);
        },
        complete: (results, file) => {
          dispatch({
            type: "SET_FILE_DATA",
            payload: { file: file, results: results }
          });
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: { step: BulkImportStep.MapData }
          });
        },
        skipEmptyLines: true
      });
    },
    [dispatch]
  );

  return (
    <>
      <Dropzone
        accept={
          ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, text/tab-separated-values"
        }
        maxSize={1024 * 1024} // 1 MB
        multiple={false}
        maxFiles={1}
        onDropAccepted={acceptedFiles => parseFile(acceptedFiles[0])}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps }) => (
          <StyledDropzone {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag 'n drop a CSV or TSV file here, or click to select a file</p>
          </StyledDropzone>
        )}
      </Dropzone>
      {dropErrors.map(err => (
        <StyledAlert key={err.code} message={err.message} type="error" />
      ))}
    </>
  );
};

export default SelectFileStep;
