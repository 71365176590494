import React from "react";

import styled, { css } from "styled-components";

import { SpaceComponentObject } from "../../../../../types";
import { DimensionsContextContainer } from "../../../layout/DimensionsContext";
import DropTarget from "../../../layout/DropTarget/DropTarget";
import { useStableSpaceContext } from "../../SpaceContext";
import { useIsFirstInstance } from "../contexts/ComponentPathContext";
import SpaceComponent, { Props } from "../SpaceComponent";

const sharedStyles = css`
  display: flex;
  align-items: flex-start;
  height: 100%;
  min-width: 10px;

  & > * {
    min-height: 0;
    max-height: 100%;

    /* 
      For components in panels, like table, which may be very tall a height contraints
      must exist as a direct ancestor of the flex children of the panel. If this height
      constraint is on the direct child of the flex_box all the flex children of the 
      flex_box would attempt to size to its height and thus be treated as having 
      equal height when the flex algorithim allocates space.
      
      --component-height is a bit of a fudge here to keep the "natural" height of descendants
      like tables from dwarfing the height of shorter components and being allocated almost 
      the entire flex height.

      If you change this check that the following case renders reasonably:
      ------------------------
      |  ----------          |
      |  | button |          |
      |  ----------          |
      |  ---------           |
      |  | table |           |
      |  ---------           |
      |  ----------          |
      |  | button |          |
      |  ----------          |
      ------------------------

    */
    & > * {
      height: 100%;
      max-height: var(--component-height);
    }
  }
`;

const Root = styled.div`
  ${sharedStyles}
`;

const StyledDropTarget = styled(DropTarget)`
  ${sharedStyles}
  width: 100%;
`;

const EditModeRoot = styled(Root)`
  min-width: 20px;
  min-height: 20px;
  border: dashed 1px ${props => props.theme.borderGrey};
`;

export default function SpaceFlexBox({
  spaceComponent: {
    componentTreeNodes,
    properties: { style }
  },
  ...props
}: Props) {
  const { editMode } = useStableSpaceContext();
  const isFirstInstance = useIsFirstInstance();

  const contents = (componentTreeNodes as SpaceComponentObject[]).map(ctn => (
    <SpaceComponent key={ctn.slug} spaceComponent={ctn} {...props} />
  ));

  if (!editMode || !isFirstInstance)
    return (
      <DimensionsContextContainer>
        <Root style={style}>{contents}</Root>
      </DimensionsContextContainer>
    );

  return (
    <DimensionsContextContainer>
      <EditModeRoot>
        <StyledDropTarget style={style}>{contents}</StyledDropTarget>
      </EditModeRoot>
    </DimensionsContextContainer>
  );
}
