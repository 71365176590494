import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import gql from "graphql-tag";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../constants";
import { publicClient } from "../../../graphql";
import Button from "../../common/Button";
import { DarkTheme, FormItem } from "../../common/StyledComponents";
import TitleContainer, { ContainerSize } from "../../common/TitleContainer";

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      ok
    }
  }
`;

const FormContainer = styled.div`
  width: 380px;
  max-width: 100%;
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;

  button {
    margin-bottom: 12px;
  }
`;

const FormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const RequestPasswordResetForm = (props: FormComponentProps) => {
  const navigate = useNavigate();
  const { getFieldDecorator } = props.form;

  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET_MUTATION, {
    client: publicClient,
    onCompleted: () => navigate(Routes.RESET_PASSWORD_SENT)
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }

      requestPasswordReset({ variables: { email: values.email } });
    });
  };

  return (
    <React.Fragment>
      <DarkTheme />

      <TitleContainer
        title="Enter the email address tied to your account."
        description="If there is an account that matches, we'll send an email with instructions for how to reset your password."
        size={ContainerSize.Large}
      >
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <FormItem>
              {getFieldDecorator(
                "email",
                {}
              )(<Input placeholder="Enter email address" />)}
            </FormItem>
            <FormFooter>
              <Button htmlType="submit" type="primary">
                Reset Password
              </Button>
              <Link to={Routes.LOGIN}>Back</Link>
            </FormFooter>
          </Form>
        </FormContainer>
      </TitleContainer>
    </React.Fragment>
  );
};

export default Form.create<FormComponentProps>({
  name: "requestPasswordResetForm"
})(RequestPasswordResetForm);
