import {
  Filter,
  FilterTypes,
  SpaceComponentObject,
  ConfigValidationError
} from "../../../../../../types";

export default function selectErrors(
  filterValidityTrackers: (true | false | undefined)[],
  component: SpaceComponentObject,
  findInvalidInputBindings: (component: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];

  const { filters } = component.properties;
  if (!filters) return [];

  const invalidBindings = findInvalidInputBindings(component);

  (filters as Filter[]).forEach((filter, index) => {
    const addError = (message: string) => {
      errors.push({ field: "FILTERS", message, index });
    };
    if (filter.type === FilterTypes.Binding && !filter.binding) {
      addError("This filter requires a binding.");
    }
    if (
      filter.type === FilterTypes.Binding &&
      filter.binding &&
      invalidBindings.includes(filter.binding)
    ) {
      addError(
        "This filter has an invalid value because a binding it was referencing no longer exists."
      );
    }

    if (
      filter.type === FilterTypes.Value &&
      (filterValidityTrackers[index] === false || filter.value === undefined)
    ) {
      addError("Please set a valid value for this filter.");
    }
  });

  return errors;
}
