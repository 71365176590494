import React, { useMemo, useState } from "react";

import { Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { isEmpty } from "lodash";

import AdditionalFields from "./AdditionalFields";
import FileUpload from "./FileUpload";
import InputBoolean from "./InputBoolean";
import InputNumber from "./InputNumber";
import InsecureWarning from "./InsecureWarning";
import { ObjectSchema, Schema, isObjectType } from "./schema";
import SSHHelp from "./SSHHelp";
import {
  Fieldset,
  FormItem,
  FormToggle,
  HiddenInput,
  RadioButton,
  RadioGroup
} from "./styledComponents";
import { fieldRules } from "./util";

interface FieldProps extends FormComponentProps {
  name: string;
  title: string;
  schema: Schema;
  required: boolean;
  initialValue?: any;
  disabled?: boolean;
}

interface HiddenFieldProps extends FormComponentProps {
  name: string;
  initialValue?: any;
}

interface ObjectFieldProps extends FieldProps {
  schema: ObjectSchema;
  help?: React.ReactNode;
}

const HiddenField = ({ form, name, initialValue }: HiddenFieldProps) => (
  <>{form.getFieldDecorator(name, { initialValue })(<HiddenInput />)}</>
);

const ObjectField = ({
  form,
  name,
  title,
  schema,
  required,
  initialValue,
  disabled,
  help
}: ObjectFieldProps) => {
  // Default checked if:
  // - Required (toggle won't be shown)
  // - Defined properties and initialValue is set
  // - Only additional properties and initialValues is not empty
  const [checked, setChecked] = useState(
    required ||
      (!isEmpty(schema.properties) && !!initialValue) ||
      (isEmpty(schema.properties) && !isEmpty(initialValue))
  );

  const [initialValues, additionalValues] = useMemo(() => {
    const properties = Object.keys(schema.properties || {});
    const initialValues = initialValue ? { ...initialValue } : null;
    const additionalValues: Record<string, any> = {};
    for (const key of Object.keys(initialValue || {})) {
      if (!properties.includes(key)) {
        delete initialValues[key];
        additionalValues[key] = initialValue[key];
      }
    }
    return [initialValues, additionalValues];
  }, [schema, initialValue]);

  // Show an empty value by default when no other values are present.
  if (!required && schema.additionalProperties && isEmpty(additionalValues)) {
    additionalValues[""] = "";
  }

  // See src/components/setup_flow/CredentialsForm/CredentialsForm.tsx for more
  // details on the `.null` HiddenField.
  const sharedProps = { form, disabled, path: name };
  const fields = checked ? (
    <>
      {schema.properties && (
        <Fields {...sharedProps} schema={schema} initialValues={initialValues} />
      )}
      {schema.additionalProperties && (
        <AdditionalFields
          {...sharedProps}
          schema={schema.additionalProperties}
          initialValues={additionalValues}
          maxProperties={schema.maxProperties}
        />
      )}
      {help}
    </>
  ) : (
    <HiddenField form={form} name={name + ".null"} initialValue={true} />
  );

  if (required) {
    return fields;
  }

  return (
    <FormItem extra={schemaFormatExtra(title, schema, checked)}>
      <FormToggle
        checked={checked}
        disabled={disabled}
        onChange={e => setChecked(e.target.checked)}
      >
        {title}
      </FormToggle>
      {fields}
    </FormItem>
  );
};

const Field = ({
  form,
  name,
  title,
  schema,
  required,
  initialValue,
  disabled
}: FieldProps) => {
  const errors = form.getFieldError(name);
  const options = { initialValue, rules: fieldRules(title, schema, required) };

  const fieldValue = form.getFieldValue(name);
  const value = fieldValue === undefined ? initialValue : fieldValue;
  const extra = schemaFormatExtra(title, schema, value) || schema.description;

  let label: string | undefined = title;
  let field = null;
  if ("const" in schema) {
    return <HiddenField form={form} name={name} initialValue={schema.const} />;
  } else if (schema.format === "file") {
    field = <FileUpload form={form} name={name} disabled={disabled} />;
  } else if (schema.type === "boolean") {
    label = undefined;
    field = <InputBoolean title={title} disabled={disabled} />;
  } else {
    const Component =
      schema.type === "integer" || schema.type === "number"
        ? InputNumber
        : schema.format === "password"
        ? Input.Password
        : Input;
    field = (
      <Component
        disabled={disabled}
        placeholder={schema.examples && schema.examples[0]}
      />
    );
  }

  return (
    <FormItem
      label={label}
      help={(errors && errors[0]) || ""}
      extra={extra}
      validateStatus={errors ? "error" : ""}
    >
      {form.getFieldDecorator(name, options)(field)}
    </FormItem>
  );
};

interface Props extends FormComponentProps {
  schema: Schema;
  initialValues?: Record<string, any>;
  disabled?: boolean;
  path?: string;
  name?: string;
  required?: boolean;
}

export const Fields = ({
  form,
  schema,
  initialValues,
  disabled,
  path,
  name,
  required = false
}: Props) => {
  function renderField({ schema, ...props }: FieldProps & { path: string }) {
    if (schema.oneOf !== undefined) {
      return (
        <OneOfField
          {...props}
          key={props.path}
          schema={schema}
          path={props.path}
          form={form}
        />
      );
    } else if (isObjectType(schema.type)) {
      return (
        <ObjectField
          {...props}
          key={props.path}
          schema={schema as ObjectSchema}
          help={props.name === "ssh" ? <SSHHelp /> : undefined}
        />
      );
    } else {
      return <Field {...props} key={props.path} schema={schema} />;
    }
  }
  const fields = [];
  const sharedProps = { form, disabled };
  if ("properties" in schema && schema.properties !== undefined) {
    Object.entries(schema.properties).forEach(([name, fieldSchema]) => {
      const key = path ? path + "." + name : name;
      const props = {
        ...sharedProps,
        key,
        name: key,
        path: key,
        schema: fieldSchema,
        title: fieldSchema.title || name,
        required: !!schema.required?.includes(name),
        initialValue: initialValues ? initialValues[name] : fieldSchema.default
      };
      fields.push(renderField(props));
    });
  } else {
    if (!name) {
      throw new Error("Schema node name expected.");
    }
    const key = path ? path + "." + name : name;
    fields.push(
      renderField({
        ...sharedProps,
        schema: schema,
        name,
        path: key,
        title: schema.title || name,
        required,
        initialValue: schema.default
      })
    );
  }
  return <>{fields}</>;
};

export default Field;

function OneOfField({
  schema,
  path,
  form,
  ...props
}: {
  schema: Schema;
  path: string;
} & FieldProps) {
  if (!schema.oneOf) {
    throw new Error("Expected oneOf to be defined on schema.");
  }

  const [activeOption, setActiveOption] = React.useState("0");

  return (
    <Fieldset>
      <legend>{schema.title}</legend>
      <RadioGroup
        value={activeOption}
        onChange={evt => {
          setActiveOption(evt.target.value);
        }}
      >
        {schema.oneOf.map((s, i) => {
          const key = String(i);
          return (
            <RadioButton key={key} value={key}>
              {s.title}
            </RadioButton>
          );
        })}
      </RadioGroup>
      <Fields
        {...props}
        schema={schema.oneOf[Number(activeOption)]}
        path={path}
        form={form}
      />
    </Fieldset>
  );
}

function schemaFormatExtra(title: string, schema: Schema, value: any) {
  if (schema.format === "require_ssl" && !value) {
    return <InsecureWarning cta={title} />;
  }
  return null;
}
