import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

interface Data {
  deleteAutomation: {
    ok: boolean;
  };
}

interface Variables {
  id: string;
}

const QUERY = gql`
  mutation DeleteAutomation($id: ID!) {
    deleteAutomation(id: $id) {
      ok
    }
  }
`;

export default function useDeleteAutomation(
  options?: MutationHookOptions<Data, Variables>
) {
  return useMutation<Data, Variables>(QUERY, options);
}
