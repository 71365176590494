import React from "react";

import styled from "styled-components";

import { RenderColor } from "../../spaces/SpaceRoot/SpaceComponent/common/AttributeValue/options";

interface PresetIconProps {
  schema: string;
}

const Square = styled.div`
  height: 20px;
  margin-top: 4px;
  width: 20px;

  &.green {
    background-color: rgba(191, 241, 153, 0.25);
    border: 1px solid #7ac70c;
  }

  &.red {
    background-color: rgba(255, 151, 151, 0.25);
    border: 1px solid #d33131;
  }

  &.pink {
    background-color: rgba(239, 77, 179, 0.25);
    border: 1px solid #ef4db3;
  }

  &.orange {
    background-color: rgba(255, 207, 122, 0.25);
    border: 1px solid #fa811b;
  }

  &.blue {
    background-color: rgba(188, 233, 255, 0.25);
    border: 1px solid #1cb0f6;
  }

  &.purple {
    background-color: rgba(202, 148, 255, 0.25);
    border: 1px solid #8549ba;
  }

  &.yellow {
    background-color: rgba(255, 242, 119, 0.25);
    border: 1px solid #ffb020;
  }

  &.white {
    background-color: #ffffff;
    border: 1px solid #1b1b1a;
  }

  &.grey {
    background-color: rgba(242, 242, 242, 0.25);
    border: 1px solid #a09d9d;
  }
`;

export default function PresetSchema(props: PresetIconProps) {
  if (props.schema === RenderColor.NONE) {
    return null;
  }
  return <Square className={props.schema} />;
}
