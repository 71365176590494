import React from "react";

// Really shouldn't change over the course of a session so re-use across
// hook invocations to reduce re-renders
let defaultScrollBarWidth = 15;
export default function useScrollbarWidth() {
  const [scrollbarWidth, setScrollbarWidth] = React.useState(defaultScrollBarWidth);
  React.useEffect(() => {
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    setScrollbarWidth(scrollbarWidth);
    defaultScrollBarWidth = scrollbarWidth;

    // Delete the DIV
    document.body.removeChild(scrollDiv);
  }, [scrollbarWidth]);
  return scrollbarWidth;
}
