import { AttributeTypes } from "../../../../../constants";
import {
  ParameterType,
  SpaceComponentPackage,
  SpaceComponentType
} from "../../../../../types";
import { Parameter } from "../../../../common/FormBuilderModal/reducer";
import { BlankValueType } from "../../../../spaces/SpaceRoot/constants";
import { ConditionalOperator } from "../../../../spaces/SpaceRoot/SpaceComponent/common/Conditionals";
import { SpaceDropdownProperties } from "../../../../spaces/SpaceRoot/SpaceComponent/SpaceDropdown/types";
import { getComponentValueBinding } from "../utils";

export enum AssigneeParameter {
  TYPE = "assigneeType",
  ROLE_ID = "assigneeRoleId",
  USER_ID = "assigneeUserId"
}

const ASSIGNEE_PARAMETERS: string[] = [
  AssigneeParameter.TYPE,
  AssigneeParameter.ROLE_ID,
  AssigneeParameter.USER_ID
];

// isInternalParameter must be passed in to ensure special assignee handling is needed
export const isAssigneeParameter = (name: string, isInternalParameter: boolean) => {
  return isInternalParameter && ASSIGNEE_PARAMETERS.includes(name);
};

export const isPipelineAssigneeParameter = (
  name: string,
  assigneeParameter: AssigneeParameter
) => {
  return name.match(new RegExp(`^(\\d+__)?${assigneeParameter}$`));
};

export const getDefaultAssigneeInputParameter = (
  parameterType: AssigneeParameter,
  assigneeTypeIndex: number,
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined,
  overrides?: Record<string, any>
): Parameter => {
  const assigneeTypeBinding = getComponentValueBinding(assigneeTypeIndex);
  const commonParameterProperties = {
    type: ParameterType.COMPONENT,
    componentType: "DROPDOWN" as SpaceComponentType,
    attributeType: AttributeTypes.STRING,
    required: false,
    included: false,
    binding: undefined,
    resolver: undefined,
    template: undefined,
    value: undefined
  };
  const dropdownPkg = findSpaceComponentPackage("DROPDOWN");
  switch (parameterType) {
    case AssigneeParameter.TYPE: {
      return {
        name: AssigneeParameter.TYPE,
        ...commonParameterProperties,
        componentProperties: dropdownPkg?.getComponentProperties
          ? dropdownPkg.getComponentProperties({
              label: "Assign to",
              placeholder: "Select assignee type",
              hardcoded_options: "User,user\nRole,role",
              blank_value_type: BlankValueType.NULL_VALUE
            } as SpaceDropdownProperties)
          : {},
        ...overrides
      };
    }
    case AssigneeParameter.ROLE_ID: {
      return {
        name: AssigneeParameter.ROLE_ID,
        ...commonParameterProperties,
        componentProperties: dropdownPkg?.getComponentProperties
          ? dropdownPkg.getComponentProperties({
              label: "Assign to role",
              placeholder: "Select a role",
              blank_value_type: BlankValueType.NULL_VALUE,
              visibility_rules: [
                {
                  type: "managed",
                  subject_template: assigneeTypeBinding,
                  operator: ConditionalOperator.Equals,
                  object_template: "`role`"
                }
              ]
            } as SpaceDropdownProperties)
          : {},
        ...overrides
      };
    }
    case AssigneeParameter.USER_ID: {
      return {
        name: AssigneeParameter.USER_ID,
        ...commonParameterProperties,
        componentProperties: dropdownPkg?.getComponentProperties
          ? dropdownPkg.getComponentProperties({
              label: "Assign to user",
              placeholder: "Select a user",
              blank_value_type: BlankValueType.NULL_VALUE,
              visibility_rules: [
                {
                  type: "managed",
                  subject_template: assigneeTypeBinding,
                  operator: ConditionalOperator.Equals,
                  object_template: "`user`"
                }
              ]
            } as SpaceDropdownProperties)
          : {},
        ...overrides
      };
    }
    default: {
      throw new Error("Expected valid assignee parameter.");
    }
  }
};

export const getAllEnabledParameters = (
  typeIndex: number,
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined
) => {
  const typeParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.TYPE,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: true,
      required: false
    }
  );
  const roleParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.ROLE_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: true,
      required: true
    }
  );
  const userParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.USER_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: true,
      required: true
    }
  );
  return [typeParam, roleParam, userParam];
};

export const getAllDisabledParameters = (
  typeIndex: number,
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined
) => {
  const typeParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.TYPE,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: false,
      required: false
    }
  );
  const roleParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.ROLE_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: false,
      required: false
    }
  );
  const userParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.USER_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: false,
      required: false
    }
  );
  return [typeParam, roleParam, userParam];
};

export const getOnlyRoleEnabledParameters = (
  typeIndex: number,
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined
) => {
  const baseTypeParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.TYPE,
    typeIndex,
    findSpaceComponentPackage
  );
  const typeParam = {
    ...baseTypeParam,
    included: true,
    required: true,
    componentProperties: {
      ...baseTypeParam.componentProperties,
      default_value: "role",
      // using visibility_rules here is a workaround until ParameterType.STATIC is supported
      visibility_rules: [
        {
          type: "managed",
          subject_template: "true",
          operator: "=",
          object_template: "false"
        }
      ]
    }
  };
  const roleParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.ROLE_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: true,
      required: false
    }
  );
  const userParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.USER_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: false,
      required: false
    }
  );
  return [typeParam, roleParam, userParam];
};

export const getOnlyUserEnabledParameters = (
  typeIndex: number,
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined
) => {
  const baseTypeParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.TYPE,
    typeIndex,
    findSpaceComponentPackage
  );
  const typeParam = {
    ...baseTypeParam,
    included: true,
    required: true,
    componentProperties: {
      ...baseTypeParam.componentProperties,
      default_value: "user",
      // using visibility_rules here is a workaround until ParameterType.STATIC is supported
      visibility_rules: [
        {
          type: "managed",
          subject_template: "true",
          operator: "=",
          object_template: "false"
        }
      ]
    }
  };
  const roleParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.ROLE_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: false,
      required: false
    }
  );
  const userParam = getDefaultAssigneeInputParameter(
    AssigneeParameter.USER_ID,
    typeIndex,
    findSpaceComponentPackage,
    {
      included: true,
      required: false
    }
  );
  return [typeParam, roleParam, userParam];
};
