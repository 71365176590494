import React from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Skeleton } from "antd";
import moment from "moment-timezone";

import Message from "../../../common/Message";
import { sentenceJoin } from "../../../util";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import RightDrawer from "../common/RightDrawer";

import {
  SPACE_VERSIONS_QUERY,
  SpaceVersionsQueryData,
  SpaceVersionsQueryVariables,
  RESTORE_SPACE_VERSION_MUTATION,
  RestoreSpaceVersionMutationData,
  ResoreSpaceVersionMutationVariables
} from "./queries";
import * as styled from "./styledComponents";

interface VersionHistoryDrawerProps {
  onClose: () => void;
}

export default function VersionHistoryDrawer({ onClose }: VersionHistoryDrawerProps) {
  const { spaceSlug } = useStableSpaceContext();
  const versionsQueryResult = useQuery<
    SpaceVersionsQueryData,
    SpaceVersionsQueryVariables
  >(SPACE_VERSIONS_QUERY, {
    fetchPolicy: "network-only",
    skip: !spaceSlug,
    variables: {
      slug: spaceSlug
    }
  });
  if (versionsQueryResult.error) throw versionsQueryResult.error;

  const [restoreVersion, restoreVersionsResult] = useMutation<
    RestoreSpaceVersionMutationData,
    ResoreSpaceVersionMutationVariables
  >(RESTORE_SPACE_VERSION_MUTATION, {
    onCompleted: ({ restoreSpaceVersion: { ok, message } }) => {
      if (ok) {
        window.location.reload();
      } else {
        Message.warning(message);
      }
    }
  });
  if (restoreVersionsResult.error) throw restoreVersionsResult.error;

  return (
    <RightDrawer onClose={onClose}>
      <h2>Version History</h2>
      {versionsQueryResult.loading ? (
        <Skeleton active title={false} />
      ) : versionsQueryResult.data !== undefined ? (
        <VersionList
          data={versionsQueryResult.data}
          restoring={restoreVersionsResult.loading}
          onRestore={versionId => restoreVersion({ variables: { versionId } })}
        />
      ) : null}
    </RightDrawer>
  );
}

interface VersionListProps {
  data: SpaceVersionsQueryData;
  restoring: boolean;
  onRestore: (versionId: string) => void;
}

function VersionList({ data, restoring, onRestore }: VersionListProps) {
  const [clickedVersion, setClickedVersion] = React.useState<null | string>(null);

  const getEnvironmentsPublishedTo = React.useCallback(
    (versionId: string) =>
      data.space.environmentSpaceVersions.edges
        .filter(esv => esv.node.version.id === versionId)
        .map(esv => esv.node.environment.name),
    [data]
  );

  const sortedVersions = React.useMemo(
    () =>
      data.space.versions.edges
        .map(({ node }) => node)
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
    [data]
  );

  return (
    <styled.List>
      {sortedVersions.map(v => {
        const publishedEnvs = getEnvironmentsPublishedTo(v.id);
        const publishedNotice = publishedEnvs.length
          ? `Current version on ${sentenceJoin(publishedEnvs)}`
          : null;
        return (
          <styled.ListItem data-test={`version-${v.id}`} key={v.id}>
            <styled.Description>
              <styled.VersionDate>
                {moment
                  .utc(v.createdAt)
                  .tz(moment.tz.guess())
                  .format("MMMM D, YYYY, h:mm A")}
              </styled.VersionDate>
              {!!publishedNotice && (
                <styled.PublishedNotice>{publishedNotice}</styled.PublishedNotice>
              )}
              {v.createdBy && <styled.ByLine>By {v.createdBy.fullName}</styled.ByLine>}
            </styled.Description>
            <styled.RestoreButton
              loading={v.id === clickedVersion}
              disabled={restoring}
              type="link"
              onClick={() => {
                setClickedVersion(v.id);
                onRestore(v.id);
              }}
            >
              Restore Version
            </styled.RestoreButton>
          </styled.ListItem>
        );
      })}
    </styled.List>
  );
}
