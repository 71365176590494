import React, { useState } from "react";

import styled, { ThemeContext } from "styled-components";

import {
  DARK_THEME_POPPER_PORTAL_ID,
  DEFAULT_THEME_POPPER_PORTAL_ID
} from "../../../constants";
import { InputParameter, ParameterType } from "../../../types";
import { useStableSpaceContext } from "../../spaces/SpaceRoot/SpaceContext";
import { ModalNew as BaseModalNew } from "../Modal";

import FormBuilder from "./FormBuilder";
import {
  Action,
  ActionType,
  FormState,
  InternalField,
  reducerFactory
} from "./reducer";
import { BindingSchema, FunctionNode } from "./types";
import { ALL_SUPPORTED_PARAMETER_TYPES, toInputParameters } from "./utils";

export const ModalNew = styled(BaseModalNew)`
  .ant-modal-body {
    padding: 25px 32px 0 32px
    margin-right: -32px;

    > * {
      padding-right: 32px;
    }
  }

  .ant-modal-footer {
    padding: 20px 32px;
  }
`;
export interface Props {
  func: FunctionNode;
  title: string;
  initialInputParameters?: InputParameter[];
  additionalSchema?: Record<string, BindingSchema>;
  onSave?: (inputParameters: InputParameter[]) => void;
  onCancel?: () => void;
  allowedParameterTypes?: ParameterType[];
  internalFields?: InternalField[];
  customReducer?: (state: FormState, action: Action) => FormState;
}

export default function FormBuilderModal({
  func,
  onCancel,
  title,
  additionalSchema = {},
  initialInputParameters = [],
  onSave = () => null,
  allowedParameterTypes = ALL_SUPPORTED_PARAMETER_TYPES,
  internalFields,
  customReducer
}: Props) {
  const theme = React.useContext(ThemeContext);
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const [reducer] = useState(() =>
    reducerFactory(findSpaceComponentPackage, customReducer)
  );
  const [state, dispatch] = React.useReducer(reducer, {
    initialInputParameters,
    additionalSchema,
    parameters: [],
    parameterErrors: {},
    allowedParameterTypes,
    internalFields: internalFields || []
  });

  React.useEffect(() => {
    dispatch({
      type: ActionType.LOAD_FUNCTION,
      payload: {
        func,
        allowedParameterTypes
      }
    });
  }, [func, allowedParameterTypes]);

  const container =
    theme.id === "dark"
      ? (document.getElementById(DARK_THEME_POPPER_PORTAL_ID) as HTMLElement)
      : (document.getElementById(DEFAULT_THEME_POPPER_PORTAL_ID) as HTMLElement);
  return (
    <ModalNew
      getContainer={container}
      visible={true}
      onCancel={onCancel}
      onOk={() => {
        if (
          Object.entries(state.parameterErrors).find(
            ([_paramName, hasError]: [string, boolean]) => {
              return !!hasError;
            }
          )
        ) {
          return;
        }
        onSave(toInputParameters(state));
      }}
      width={900}
    >
      <FormBuilder
        title={title}
        dispatch={dispatch}
        state={state}
        allowedParameterTypes={allowedParameterTypes}
      />
    </ModalNew>
  );
}
