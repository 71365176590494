import React from "react";

import { SpaceContext, useSpaceContext } from "../../SpaceRoot/SpaceContext";
import {
  collectComponents,
  _getBinding
} from "../../SpaceRoot/SpaceContext/SpaceContext";
import { OPAQUE_EDIT_MODE_KEY } from "../../SpaceRoot/SpaceContext/StableSpaceContext";
import { EMPTY_SPACE } from "../../SpaceRoot/SpaceContext/useSpace";
import {
  getAncestorComponents as baseGetAncestorComponents,
  maskSpace
} from "../../SpaceRoot/SpaceContext/util";

import useSpaceConfig, { Result as SpaceConfigResult } from "./useSpaceConfig";
import { INITIAL_STATE } from "./useSpaceConfig/reducer/reducer";
import { UseConfigMutationData } from "./useSpaceConfig/useConfigMutation/useConfigMutation";
import { DestroyConfigMutationData } from "./useSpaceConfig/useDestroyConfigMutation/useDestroyConfigMutation";

export type SpaceConfigContextType = SpaceConfigResult;

export interface SpaceContextProviderProps {
  slug: string;
  children: React.ReactNode;
  onNewSpaceCreated: (result: UseConfigMutationData) => void;
  onDestroy: (result: DestroyConfigMutationData) => void;
}

export const defaultContextValue: SpaceConfigContextType = {
  [OPAQUE_EDIT_MODE_KEY]: false, // This is set true when there SpaceConfigContextProvider present in tree
  space: maskSpace(EMPTY_SPACE),
  state: INITIAL_STATE,
  loading: true,
  mutationLoading: false,
  shouldDisplayError: (_: string) => false,
  dispatch: () => null,
  destroy: () => null,
  save: () => null,
  predictSlug: () => "",
  componentsWithErrors: new Set(),
  componentTree: [],
  queryStatus: 0,
  configErrors: {}
};

export const SpaceConfigContext =
  React.createContext<SpaceConfigContextType>(defaultContextValue);

export function SpaceConfigContextProvider({
  slug,
  children,
  onNewSpaceCreated,
  onDestroy
}: SpaceContextProviderProps) {
  const result = useSpaceConfig(onDestroy, onNewSpaceCreated, slug);
  const spaceContext = useSpaceContext();
  const components = React.useMemo(
    () => collectComponents(result.componentTree),
    [result.componentTree]
  );
  const getAncestorComponents = React.useCallback(
    (slug: string | undefined) => baseGetAncestorComponents(slug, components),
    [components]
  );
  const getBinding = React.useCallback(
    (path: string | null | undefined) => _getBinding(result.componentTree, path),
    [result.componentTree]
  );

  // Replace components with draft versions
  const spaceContextValue = React.useMemo(() => {
    return {
      ...spaceContext,
      loading: result.loading,
      editMode: true,
      componentTree: result.componentTree,
      components: collectComponents(result.componentTree),
      getAncestorComponents,
      getBinding
    };
  }, [
    result.componentTree,
    result.loading,
    spaceContext,
    getAncestorComponents,
    getBinding
  ]);

  return (
    <SpaceConfigContext.Provider value={result}>
      <SpaceContext.Provider value={spaceContextValue}>
        {children}
      </SpaceContext.Provider>
    </SpaceConfigContext.Provider>
  );
}

export const useSpaceConfigContext = () => React.useContext(SpaceConfigContext);
