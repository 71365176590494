import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import SelectIntegration from "../components/setup_flow/SelectIntegration";
import { LightBasicLayout } from "../layouts";

export const Select = () => (
  <RequireAuth scopes={["admin"]}>
    <LightBasicLayout>
      <SelectIntegration />
    </LightBasicLayout>
  </RequireAuth>
);
