import * as Sentry from "@sentry/browser";
import flatten from "flat";

interface SentryContext {
  tags?: Record<string, string>;
  extra?: any;
  level?: Sentry.Severity;
}

export const reportException = (error: unknown, context: SentryContext = {}) =>
  Sentry.withScope(scope => {
    scope.setTags(context.tags || {});
    scope.setExtras(context.extra);
    scope.setLevel(context.level || Sentry.Severity.Error);
    Sentry.captureException(typeof error === "string" ? new Error(error) : error);
  });

export const addBreadCrumb = (breadCrumb: Sentry.Breadcrumb) =>
  Sentry.addBreadcrumb(breadCrumb);

export const Severity = Sentry.Severity;

interface ReportNeverOptions {
  recoverValue?: any;
  context?: SentryContext;
}

const NO_RECOVER_VALUE = Symbol("reportNever");

export function reportNever(neverValue: never, options?: ReportNeverOptions) {
  const opts = {
    recoverValue: NO_RECOVER_VALUE,
    context: {},
    ...options
  };
  const error = new Error(`reportNever: ${neverValue}`);
  reportException(error, opts.context);
  if (opts.recoverValue === NO_RECOVER_VALUE) {
    throw error;
  } else {
    return opts.recoverValue;
  }
}

export const getFlattenedRedactedData = (data: Record<string, any>) => {
  return Object.entries(flatten(data, { maxDepth: 5 })).reduce((acc, [key, value]) => {
    if (value === undefined || value === null) {
      acc[key] = value;
    } else {
      acc[key] = `${typeof value} (redacted)`;
    }
    return acc;
  }, {} as { [key: string]: any });
};
