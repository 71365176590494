/**
 * CodeMirror text input field meant for multi-line code input,
 * with line numbers, javascript parsing and dark/default color themes.
 *
 * For single line text input, use `SingleLineEditor`.
 *
 * `mode` option can be either a language or a MIME type defined here:
 * https://codemirror.net/mode/index.html
 */
import React, { useCallback, useContext, useMemo } from "react";

import * as codemirror from "codemirror";
import { Controlled as CodeMirror } from "react-codemirror2";
import { ThemeContext } from "styled-components";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-ocean.css";
import "codemirror/addon/lint/lint.css";

import "codemirror/addon/display/placeholder";
import "codemirror/addon/selection/active-line";
import "codemirror/addon/edit/closebrackets";

import "codemirror-graphql/mode";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/sql/sql";

import withDebouncedValue from "../withDebouncedValue";

import * as linters from "./linters";
import * as styled from "./styledComponents";

const EDITOR_DEFAULT_THEME = "default";
const EDITOR_DARK_THEME = "material-ocean";

export interface Props {
  value: string;
  mode: "graphql" | "javascript" | "sql" | "application/json" | "text/plain";
  className?: string;
  placeholder?: string;
  height?: string;
  isFullHeight?: boolean;
  readOnly?: boolean;
  hideGutter?: boolean;
  hideLineNumbers?: boolean;
  style?: React.CSSProperties;
  onChange?: (value: string) => void;
  onFocus?: (evt: any) => void;
  onBlur?: (evt: any) => void;
}

const Editor = ({
  value,
  mode,
  className,
  placeholder,
  style,
  height = "auto",
  isFullHeight = false,
  readOnly = false,
  hideGutter = false,
  hideLineNumbers = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {}
}: Props) => {
  const theme = useContext(ThemeContext) || {};
  const isDarkTheme = theme.editorStyle === "dark";

  const options = useMemo((): codemirror.EditorConfiguration => {
    return {
      mode,
      placeholder,
      lineWrapping: true,
      autoCloseBrackets: true,
      lineNumbers: !hideGutter && !hideLineNumbers,
      readOnly: readOnly ? "nocursor" : false,
      styleActiveLine: isDarkTheme,
      lint: linters.get(mode),
      gutters: hideGutter ? [] : ["CodeMirror-lint-markers"],
      theme: isDarkTheme ? EDITOR_DARK_THEME : EDITOR_DEFAULT_THEME,
      screenReaderLabel:
        "Code editor field. Press option or alt, plus tab to traverse out."
    };
  }, [mode, placeholder, hideGutter, hideLineNumbers, readOnly, isDarkTheme]);

  const handleChange = useCallback(
    (editor: codemirror.Editor, data: codemirror.EditorChange, text: string) =>
      onChange(text),
    [onChange]
  );

  return (
    <styled.Container
      className={className}
      height={height}
      isFullHeight={isFullHeight}
      style={style}
    >
      <CodeMirror
        value={value}
        onBeforeChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        options={options}
      />
    </styled.Container>
  );
};

export default Editor;

export const DebouncedEditor = withDebouncedValue(Editor);
