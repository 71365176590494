import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Checkbox, Icon, Skeleton, Modal, Tooltip } from "antd";
import gql from "graphql-tag";
import _ from "lodash";
import styled from "styled-components";

import {
  GET_ROLE_BY_ID_WITH_PERMISSIONS,
  EXTENDED_ROLE_FRAGMENT,
  GET_ALL_ROLES
} from "../../../../graphql/queries";
import { AuthScope, RoleNode } from "../../../../types";
import Button from "../../../common/Button";
import Drawer from "../../../common/Drawer";
import usePaths from "../../../common/hooks/usePaths";
import Message from "../../../common/Message";
import FunctionPermissions, {
  useFunctionPolicies,
  FunctionPermissionInput,
  FunctionPolicyActionTypes
} from "../FunctionPermissions";

import * as styledComps from "./styledComponents";

type UpdateRolePermissionsData = RoleNode;
type UpdateRolePermissionsVariables = {
  id: string;
  name: string;
  scopes: string[];
  applyPermissions: FunctionPermissionInput[];
};

const UPDATE_ROLE_PERMISSIONS = gql`
  mutation UpdateRolePermissions(
    $id: ID!
    $name: String!
    $scopes: [String!]
    $applyPermissions: [FunctionPermissionInput!]
  ) {
    updateRolePermissions(
      id: $id
      name: $name
      scopes: $scopes
      applyPermissions: $applyPermissions
    ) {
      role {
        ...ExtendedRoleFragment
        scopes
      }
    }
  }
  ${EXTENDED_ROLE_FRAGMENT}
`;

const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($roleId: ID!) {
    deleteRole(roleId: $roleId) {
      ok
    }
  }
`;

const DeleteRoleBtn = styled(Button)`
  margin-left: 10px;

  &[disabled] {
    color: white !important;
    background-color: ${props => props.theme.errorColor} !important;
    opacity: 0.5;
  }
`;

interface RoleDrawerContentsProps {
  authScopes: AuthScope[];
  role: RoleNode;
  saving: boolean;
  errorMessage: string;
  features: any;
  onCancel: () => void;
  onDelete: () => void;
  onSave: (opts: { variables: UpdateRolePermissionsVariables }) => void;
}

function getBreadcrumbs(paths: ReturnType<typeof usePaths>, role: RoleNode) {
  return [
    {
      path: paths.getSettingsRolesAndPermissions(),
      breadcrumbName: "Roles and Permissions",
      children: []
    },
    {
      path: paths.getSettingsViewRole(role.id),
      breadcrumbName: `View ${role.name} Role`, // TODO replace with role name
      children: []
    }
  ];
}

export function RoleDrawerContents(props: RoleDrawerContentsProps) {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [draftRoleName, setDraftRoleName] = React.useState(props.role.name);
  const [draftScopes, setDraftScopes] = React.useState(props.role.scopes || []);
  const functionPolicies = useFunctionPolicies(props.role.id);
  const paths = usePaths();

  const toggleScope = React.useCallback(
    function toggleScope(scopeName: string, hasScope: boolean) {
      let nextScopes;
      if (hasScope && !draftScopes.includes(scopeName)) {
        nextScopes = draftScopes.concat(scopeName);
      } else {
        nextScopes = draftScopes.filter(s => s !== scopeName);
      }
      setDraftScopes(nextScopes);
    },
    [draftScopes, setDraftScopes]
  );

  const save = React.useCallback(
    function save() {
      props.onSave({
        variables: {
          id: props.role.id,
          name: draftRoleName,
          scopes: draftScopes,
          applyPermissions: functionPolicies.functionPermissionChanges
        }
      });
    },
    [props, draftRoleName, draftScopes, functionPolicies.functionPermissionChanges]
  );

  const resetForm = React.useCallback(
    function resetForm() {
      functionPolicies.dispatch({
        type: FunctionPolicyActionTypes.REVERT_CHANGES
      });
      setDraftRoleName(props.role.name);
      setDraftScopes(props.role.scopes || []);
      setIsEditMode(false);
    },
    [functionPolicies, props.role.name, props.role.scopes]
  );

  const header = isEditMode ? (
    <EditModeHeader
      role={props.role}
      saving={props.saving}
      onSave={save}
      onCancel={resetForm}
    />
  ) : (
    <ViewModeHeader
      role={props.role}
      onEnableEditMode={() => setIsEditMode(true)}
      onDelete={props.onDelete}
    />
  );

  return (
    <styledComps.TableLayoutNoMarginNoPadding
      title=""
      breadcrumbRoutes={getBreadcrumbs(paths, props.role)}
      headerContent={header}
    >
      {props.errorMessage && (
        <styledComps.ErrorField>{props.errorMessage}</styledComps.ErrorField>
      )}

      {isEditMode && (
        <styledComps.RoleConfigSection>
          <h3>Role Name</h3>
          <styledComps.RoleNameInput
            value={draftRoleName}
            onChange={e => {
              setDraftRoleName(e.target.value);
            }}
          />
        </styledComps.RoleConfigSection>
      )}

      <styledComps.RoleConfigSection>
        <h3>Access to Internal features</h3>
        <div>
          {props.authScopes.map((scope: AuthScope) => (
            <div key={scope.name}>
              <Checkbox
                checked={draftScopes.includes(scope.name)}
                disabled={!isEditMode}
                onChange={e => toggleScope(scope.name, e.target.checked)}
              >
                {scope.description}
              </Checkbox>
            </div>
          ))}
        </div>
      </styledComps.RoleConfigSection>

      <div>
        <h3>Access to your data</h3>
        <FunctionPermissions
          functionPolicies={functionPolicies}
          isEditMode={isEditMode}
          allowsAllFunctions={!!props.role.allowsAllFunctions}
          features={props.features}
        />
      </div>
    </styledComps.TableLayoutNoMarginNoPadding>
  );
}

interface RoleContainerProps {
  roleId: string | undefined;
  visible: boolean;
  features: any;
  onClose: () => void;
  onDelete: () => void;
}

export default function RoleDrawerContainer({
  roleId,
  visible,
  features,
  onClose,
  onDelete
}: RoleContainerProps) {
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const getRoleResult = useQuery(GET_ROLE_BY_ID_WITH_PERMISSIONS, {
    variables: { id: roleId },
    skip: roleId === undefined
  });

  const [updateRole, updateRoleResult] = useMutation<
    UpdateRolePermissionsData,
    UpdateRolePermissionsVariables
  >(UPDATE_ROLE_PERMISSIONS, {
    onCompleted: () => {
      setTimeout(() => Message.success("Role updated."), 500);
      onClose();
    },
    refetchQueries: ["FunctionPoliciesForRole"]
  });

  React.useEffect(() => {
    const graphQLErrors = updateRoleResult.error?.graphQLErrors || [];
    if (!updateRoleResult.error) {
      setErrorMessage("");
      return;
    }
    setErrorMessage(
      graphQLErrors.length && graphQLErrors[0].message
        ? graphQLErrors[0].message
        : "An unexpected error occured. Please try again."
    );
  }, [updateRoleResult.error, setErrorMessage]);

  const handleCancel = React.useCallback(
    function onCancel() {
      setErrorMessage("");
    },
    [setErrorMessage]
  );

  if (roleId === undefined) return null;

  const { authScopes, role } = getRoleResult.data || {};

  return (
    <Drawer
      placement="right"
      visible={visible}
      closable={false}
      width={700}
      onClose={onClose}
    >
      {getRoleResult.loading ? (
        <Skeleton active />
      ) : (
        <RoleDrawerContents
          authScopes={authScopes}
          role={role}
          features={features}
          saving={updateRoleResult.loading}
          errorMessage={errorMessage}
          onSave={updateRole}
          onDelete={onDelete}
          onCancel={handleCancel}
        />
      )}
    </Drawer>
  );
}

function EditModeHeader({
  role,
  saving,
  onSave,
  onCancel
}: {
  role: RoleNode;
  saving: boolean;
  onSave: () => void;
  onCancel: () => void;
}) {
  return (
    <styledComps.HeaderToolbar>
      <styledComps.HeaderText>
        <Icon type="info-circle" theme="filled" />
        You’re editing the role '{role.name}.'
      </styledComps.HeaderText>
      <styledComps.ButtonContainer>
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={saving}
          onClick={() => {
            onSave();
          }}
        >
          Save
        </Button>
      </styledComps.ButtonContainer>
    </styledComps.HeaderToolbar>
  );
}

function ViewModeHeader({
  role,
  onEnableEditMode,
  onDelete
}: {
  role: RoleNode;
  onEnableEditMode: () => void;
  onDelete: () => void;
}) {
  const [destroy] = useMutation(DELETE_ROLE_MUTATION, {
    onCompleted: data => {
      const { ok, message } = data.deleteRole;
      if (ok) {
        onDelete();
      } else {
        Message.error(message);
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ALL_ROLES }]
  });

  const handleDelete = () => {
    destroy({
      variables: {
        roleId: role.id
      }
    });
  };

  const showDeleteConfirmation = () =>
    Modal.confirm({
      title: "Are you sure you want to delete this role?",
      content: (
        <>
          This action <strong>cannot</strong> be undone. This will permanently delete
          the <strong>{role.name}</strong> role.
        </>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(),
      width: 520
    });

  return (
    <styledComps.HeaderToolbar>
      <styledComps.HeaderText>
        <h2>{role.name}</h2>
      </styledComps.HeaderText>
      <styledComps.ButtonContainer>
        <Button onClick={() => onEnableEditMode()} disabled={role.allowsAllFunctions}>
          Edit
        </Button>

        {role.name !== "Admin" && (
          <>
            {role.userCount ? (
              <Tooltip title="You cannot delete a role that has assigned users.">
                <DeleteRoleBtn type="danger" disabled>
                  Delete role
                </DeleteRoleBtn>
              </Tooltip>
            ) : (
              <DeleteRoleBtn type="danger" onClick={showDeleteConfirmation}>
                Delete role
              </DeleteRoleBtn>
            )}
          </>
        )}
      </styledComps.ButtonContainer>
    </styledComps.HeaderToolbar>
  );
}
