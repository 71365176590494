import React from "react";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import {
  FunctionAttributeNode,
  DataValue,
  FunctionDescriptor
} from "../../../../../../types";
import AttributeValue, {
  AttributeValueProps
} from "../../../../../common/AttributeValue";
import usePaths from "../../../../../common/hooks/usePaths";
import { useStableSpaceContext } from "../../../SpaceContext";

interface Props extends AttributeValueProps {
  rowValues: Record<string, DataValue>;
}

function getFunctionDescriptor(
  attribute: FunctionAttributeNode,
  rowValues: Record<string, DataValue>
): FunctionDescriptor | null {
  const funcLink = attribute.outboundReferenceLink;
  if (!funcLink || !funcLink.toFunction) return null;
  const params = Object.keys(funcLink.parameterAttributeMapping).reduce(
    (agg, param) => {
      const paramName = funcLink.parameterAttributeMapping[param];
      agg[param] = rowValues[paramName];
      return agg;
    },
    {} as Record<string, DataValue>
  );
  return {
    id: funcLink.toFunction.id,
    params
  };
}

export default function AutoLinkRenderer({
  value,
  attribute,
  rowValues,
  ...optionalProps
}: Props) {
  const { getFunctionRecord } = usePaths();
  const { spaceName, editMode } = useStableSpaceContext();
  const location = useLocation();
  const funcDescriptor = getFunctionDescriptor(
    attribute as FunctionAttributeNode,
    rowValues
  );
  const returnDisplayName = spaceName;
  let path = "";

  if (funcDescriptor && value) {
    path = getFunctionRecord(funcDescriptor);
  } else {
    return <AttributeValue attribute={attribute} value={value} {...optionalProps} />;
  }
  const queryParams = {
    returnUrl: location.pathname,
    returnDisplayName
  };
  const queryString = new URLSearchParams(queryParams).toString();
  if (queryString) {
    path = path + "?" + queryString;
  }

  return (
    <Link
      to={path}
      onClick={e => {
        // Don't allow the link to navigate away while editing a space.
        if (editMode) e.preventDefault();
      }}
    >
      <AttributeValue attribute={attribute} value={value} {...optionalProps} />
    </Link>
  );
}
