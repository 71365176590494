import React from "react";

import {
  ResponsiveContainer,
  PieChart as BasePieChart,
  Pie,
  Cell,
  // eslint-disable-next-line
  PieLabelRenderProps
} from "recharts";

import { chartColors, getChartLabelColor } from "../../../../../../cssConstants";

import { ChartData } from "./selectChartData";

interface Props {
  value: ChartData;
}

const renderLabel = ({ x, cx, y, name, value, index }: PieLabelRenderProps) => {
  const colors = Object.values(chartColors);
  return (
    <text
      x={x}
      y={y}
      fill={getChartLabelColor(colors[index! % colors.length])}
      textAnchor={x > (cx || 0) ? "start" : "end"}
    >
      {name}: {value}
    </text>
  );
};

export default function PieChart({ value: { data } }: Props) {
  const colors = Object.values(chartColors);
  return (
    <ResponsiveContainer>
      <BasePieChart>
        <Pie data={data} dataKey="value" nameKey="__xAxisDataKey" label={renderLabel}>
          {data.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </BasePieChart>
    </ResponsiveContainer>
  );
}
