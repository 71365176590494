import React from "react";

import { Icon as AntIcon } from "antd";
import styled from "styled-components";

import { RenderIcon } from "../../spaces/SpaceRoot/SpaceComponent/common/AttributeValue/options";

interface PresetIconProps {
  type: RenderIcon;
  inConfig: boolean;
}

const Icon = styled(AntIcon)`
  &.red {
    color: #d33131;
  }

  &.orange {
    color: #fa811b;
  }

  &.yellow {
    color: #ffb020;
  }

  &.green {
    color: #7ac70c;
  }

  &.blue {
    color: #1cb0f6;
  }

  &.pink {
    color: #db45a3;
  }

  &.black {
    color: #1b1b1a;
  }
`;

export default function PresetIcon(props: PresetIconProps) {
  switch (props.type) {
    case RenderIcon.CHECK:
      return <Icon className="green" theme="filled" type="check-circle" />;
    case RenderIcon.CLOSE:
      return <Icon className="red" theme="filled" type="close-circle" />;
    case RenderIcon.STOP:
      return <Icon className="red" theme="outlined" type="stop" />;
    case RenderIcon.WARNING:
      return <Icon className="orange" theme="filled" type="warning" />;
    case RenderIcon.BELL:
      return <Icon className="yellow" theme="filled" type="bell" />;
    case RenderIcon.CALENDAR:
      return <Icon className="blue" type="calendar" />;
    case RenderIcon.CAR:
      return <Icon className="black" theme="filled" type="car" />;
    case RenderIcon.CREDIT_CARD:
      return <Icon className="black" type="credit-card" />;
    case RenderIcon.THUNDERBOLT:
      return <Icon className="yellow" type="thunderbolt" />;
    case RenderIcon.SHOPPING_CART:
      return <Icon className="blue" type="shopping-cart" />;
    case RenderIcon.ENVIRONMENT:
      return <Icon className="black" type="environment" />;
    case RenderIcon.FLAG:
      return <Icon className="orange" type="flag" />;
    case RenderIcon.HEART:
      return <Icon theme="filled" className="pink" type="heart" />;
    case RenderIcon.HOME:
      return <Icon className="blue" type="home" />;
    case RenderIcon.MESSAGE:
      return <Icon className="green" type="message" />;
    case RenderIcon.LOCK:
      return <Icon className="black" theme="filled" type="lock" />;
    case RenderIcon.NOTIFICATION:
      return <Icon className="yellow" type="notification" />;
    case RenderIcon.PHONE:
      return <Icon className="green" theme="filled" type="phone" />;
    case RenderIcon.PUSHPIN:
      return <Icon className="blue" type="pushpin" />;
    case RenderIcon.TOOL:
      return <Icon className="black" type="tool" />;
    case RenderIcon.CLOCK:
      return <Icon className="blue" type="clock-circle" />;
    case RenderIcon.BULB:
      return <Icon className="yellow" type="bulb" />;
    case RenderIcon.ROCKET:
      return <Icon className="pink" type="rocket" />;
    case RenderIcon.CERT:
      return <Icon className="black" type="safety-certificate" />;
    case RenderIcon.SMILE:
      return <Icon className="black" type="smile" />;
    case RenderIcon.TROPHY:
      return <Icon className="yellow" type="trophy" />;
    case RenderIcon.STAR:
      return <Icon className="yellow" theme="filled" type="star" />;
    case RenderIcon.USER:
      return <Icon className="blue" type="user" />;

    case RenderIcon.NONE:
    default:
      return <>{props.inConfig ? "-" : null}</>;
  }
}
