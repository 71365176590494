import React from "react";

import { Icon as AntIcon } from "antd";
import styled from "styled-components";

import IntegrationLogo from "../../../../common/IntegrationLogo";

const Root = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${props => props.theme.buttonBackground};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
`;

const EmptyRoot = styled(Root)`
  color: white;
  background-color: ${props => props.theme.newContainerPrimaryTint};
  padding: ${props => props.theme.spacersmd};
`;

const Icon = styled(AntIcon)`
  font-size: 26px;
`;

interface Props {
  integration: string | undefined;
}

const ActionIcon = (props: Props) => {
  if (!props.integration) {
    return (
      <EmptyRoot>
        <Icon type="setting" theme="filled" />
      </EmptyRoot>
    );
  }
  return (
    <Root>
      <IntegrationLogo name={props.integration} />
    </Root>
  );
};

export default ActionIcon;
