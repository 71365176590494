import { BigNumber } from "bignumber.js";
import { groupBy } from "lodash";
import moment, { Moment } from "moment";

import { AttributeTypes, ErrorValues } from "../../../../../../constants";
import { DataValue } from "../../../../../../types";
import { ColumnType } from "../../common/ColumnListManager";
import { Row } from "../../common/useView";
import {
  AggregationFunction,
  AggregationFunctionType,
  ChartType,
  GroupingFunction,
  GroupingFunctionType,
  SpaceChartComponent
} from "../types";

export interface ChartData {
  data: ({ __xAxisDataKey: string } & Record<string, number | string>)[];
  dataKeys: string[];
}

const TIME_SERIES_GROUPING_FUNCTIONS = [
  GroupingFunctionType.DAILY,
  GroupingFunctionType.WEEKLY,
  GroupingFunctionType.MONTHLY,
  GroupingFunctionType.YEARLY
] as const;
type TimeSeriesGroupingFunctionType = typeof TIME_SERIES_GROUPING_FUNCTIONS[number];

const TIME_SERIES_FORMATS: {
  [key in TimeSeriesGroupingFunctionType]: string;
} = {
  [GroupingFunctionType.DAILY]: "DD",
  [GroupingFunctionType.WEEKLY]: "[week] w",
  [GroupingFunctionType.MONTHLY]: "MMM",
  [GroupingFunctionType.YEARLY]: "YYYY"
};

function makeGroupingFormat(groupingFunctionTypes: GroupingFunctionType[]): string {
  const formats = new Set(groupingFunctionTypes);
  let fmt = "";
  let sep = "";
  if (formats.has(GroupingFunctionType.YEARLY)) {
    fmt += TIME_SERIES_FORMATS[GroupingFunctionType.YEARLY];
    sep = "-";
  }
  if (formats.has(GroupingFunctionType.MONTHLY)) {
    fmt += sep + TIME_SERIES_FORMATS[GroupingFunctionType.MONTHLY];
    sep = "-";
  }
  if (formats.has(GroupingFunctionType.WEEKLY)) {
    fmt += sep + TIME_SERIES_FORMATS[GroupingFunctionType.WEEKLY];
    sep = "-";
  }
  if (formats.has(GroupingFunctionType.DAILY)) {
    fmt += sep + TIME_SERIES_FORMATS[GroupingFunctionType.DAILY];
    sep = "-";
  }
  return fmt;
}

function smallestGroupingFunctionType(
  groupingFunctionTypes: GroupingFunctionType[]
): TimeSeriesGroupingFunctionType {
  const ordering = [
    GroupingFunctionType.DAILY,
    GroupingFunctionType.WEEKLY,
    GroupingFunctionType.MONTHLY,
    GroupingFunctionType.YEARLY
  ];
  for (const v of ordering) {
    if (groupingFunctionTypes.includes(v)) {
      return v as TimeSeriesGroupingFunctionType;
    }
  }
  throw new Error("Could not locate a valid " + typeof GroupingFunctionType);
}

function makeGroupingFunction(
  groupingFunction: GroupingFunction
): (row: Record<string, DataValue>) => string {
  if (groupingFunction?.types.includes(GroupingFunctionType.EQUALITY)) {
    return row => String(row[groupingFunction.attribute!]);
  } else {
    return row => {
      try {
        return moment(row[groupingFunction.attribute!] as string | number).format(
          makeGroupingFormat(groupingFunction.types)
        );
      } catch (e) {
        console.warn(
          `Could not create a moment instance from ${row[groupingFunction.attribute!]}`
        );
        return "not a date";
      }
    };
  }
}

function makeAggregationFunction(
  aggregationFunction: AggregationFunction
): (rows: Record<string, DataValue>[]) => number {
  switch (aggregationFunction.type) {
    case AggregationFunctionType.COUNT: {
      return rows => rows.length;
    }
    case AggregationFunctionType.SUM: {
      return rows =>
        rows.reduce((agg, curr) => {
          const val = Number(curr[aggregationFunction.attribute!]);
          return agg + (typeof val === "number" ? val : 0);
        }, 0);
    }
    case AggregationFunctionType.PREAGGREGATED: {
      // Preaggregated data should have only one record per group
      return rows => Number(rows[0][aggregationFunction.attribute!]);
    }

    default:
      throw new Error(`Unknown aggregation function ${aggregationFunction.type}`);
  }
}

const INTERVAL_DURATIONS: {
  [key in TimeSeriesGroupingFunctionType]: "day" | "week" | "month" | "year";
} = {
  [GroupingFunctionType.DAILY]: "day",
  [GroupingFunctionType.WEEKLY]: "week",
  [GroupingFunctionType.MONTHLY]: "month",
  [GroupingFunctionType.YEARLY]: "year"
};

function fillGapsInTimeSeries(
  groups: [string, Record<string, DataValue>[]][],
  groupingFunction: GroupingFunction
): [string, Record<string, DataValue>[]][] {
  const types = [...groupingFunction.types];
  // If any type is not time-series-based, return the original groups.
  if (!types.every(type => TIME_SERIES_GROUPING_FUNCTIONS.includes(type as any))) {
    return groups;
  }

  // Get the start and end of the data time range as moments
  const allRecords = groups.flatMap(g => g[1]);
  const rangeStart = allRecords.reduce<Moment | null>((agg, curr) => {
    const currMoment = moment(curr[groupingFunction.attribute!] as string);
    if (agg === null) {
      return currMoment;
    }
    return agg.isBefore(currMoment) ? agg : currMoment;
  }, null);
  const rangeEnd = allRecords.reduce<Moment | null>((agg, curr) => {
    const currMoment = moment(curr[groupingFunction.attribute!] as string);
    if (agg === null) {
      return currMoment;
    }
    return agg.isAfter(currMoment) ? agg : currMoment;
  }, null);
  if (rangeStart === null || rangeEnd === null) return groups;

  // Build an array of all the slots between the start and end moment
  const smallestTSGType = smallestGroupingFunctionType(groupingFunction.types);
  const intervalDuration = INTERVAL_DURATIONS[smallestTSGType];
  rangeStart.startOf(intervalDuration);
  rangeEnd.endOf(intervalDuration);
  const cursor = moment(rangeStart);
  const slotKeys: string[] = [];
  const slotKeysSeen = new Set<string>();
  while (cursor.isBefore(rangeEnd.endOf(intervalDuration))) {
    const key = cursor.format(makeGroupingFormat(groupingFunction.types));
    cursor.add(1, intervalDuration);
    if (slotKeysSeen.has(key)) {
      continue;
    }
    slotKeysSeen.add(key);
    slotKeys.push(key);
  }

  // Map slots into new groups copying back in groups whose slots were already present
  return slotKeys.map(k => [k, groups.find(g => g[0] === k)?.[1] || []]);
}

export default function selectChartData(
  component: SpaceChartComponent,
  rows: Row[]
): ChartData {
  const xAxisGroupingFunc = makeGroupingFunction(
    component.properties.x_axis.grouping_function
  );
  const aggregationFunction = makeAggregationFunction(
    component.properties.y_axis.aggregation_function
  );
  const rowsBySourceName = rows.map(row =>
    row.columns.reduce<Record<string, DataValue>>((acc, col) => {
      if (col[0] !== ColumnType.ATTRIBUTE) return acc;
      const [, , attr, _val] = col;
      // TODO (ryan): temporarily return nulls so to not break charts when
      // a value is unavailable due to permission exceptions.
      const val = _val === ErrorValues.permissionDenied ? null : _val;
      acc[attr.sourceName] =
        attr.sourceType === AttributeTypes.DECIMAL && val !== null
          ? new BigNumber(val.toString()).toFormat()
          : val;
      return acc;
    }, {})
  );
  const groups = fillGapsInTimeSeries(
    Object.entries(groupBy(rowsBySourceName, xAxisGroupingFunc)),
    component.properties.x_axis.grouping_function
  );
  const subGroupKeys = new Set<string>();
  const subGroupFunc =
    component.properties.sub_group_functions.length === 0
      ? () =>
          component.properties.chart_type === ChartType.PIE
            ? "value"
            : component.properties.y_axis.aggregation_function.attribute || "count"
      : makeGroupingFunction(component.properties.sub_group_functions[0]);

  const data = groups.map(([xAxisDataKey, g]) => {
    const subGroups = Object.entries(groupBy(g, subGroupFunc)).reduce<
      Record<string, number>
    >((acc, [key, subGroup]) => {
      acc[key] = aggregationFunction(subGroup);
      subGroupKeys.add(key);
      return acc;
    }, {});
    const preaggregatedAttributes =
      component.properties.preaggregated_attributes.reduce<Record<string, DataValue>>(
        (acc, curr) => {
          // If preaggregated_attributes are present the data will not have been subgrouped
          // so can safely assume a single group here
          acc[curr] = g[0][curr];
          return acc;
        },
        {}
      );
    return {
      __xAxisDataKey: xAxisDataKey,
      ...subGroups,
      ...preaggregatedAttributes
    };
  });

  return {
    data,
    dataKeys: Array.from(subGroupKeys).concat(
      component.properties.preaggregated_attributes
    )
  };
}
