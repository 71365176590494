import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import DashboardRedirect from "../components/resource_dash/DashboardRedirect";

export const Redirect = () => (
  <RequireAuth>
    <DashboardRedirect />
  </RequireAuth>
);
