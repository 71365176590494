import React, { useCallback } from "react";

import { Form, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styled from "styled-components";

import { SpaceFunctionType } from "../../../../../types";
import { processEffects as processQueueEffects } from "../../../../queues/App/Queue/utils";
import {
  createSpaceFunction,
  FunctionExecutionStatus
} from "../../../FunctionExecutor/FunctionExecutor";
import { useStableSpaceContext } from "../../SpaceContext";
import Button from "../common/Button";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import EmptyState from "../common/EmptyState";
import Panel from "../common/Panel";
import PermissionFeedback from "../common/PermissionFeedback";
import useFuncParams, { InputParameter } from "../common/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import useSpaceFunction from "../common/useSpaceFunction";
import { FunctionResult } from "../common/useSpaceFunction/useSpaceFunction";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import FunctionForm from "./FunctionForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${props => props.theme.spacerlg};
  padding-bottom: 0;
`;

const FormContainer = styled.div`
  flex-grow: 1;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${props => props.theme.spacerlg};
  margin: 0 -${props => props.theme.spacerlg};
  border-top: solid 1px ${props => props.theme.borderGrey};
  background-color: ${props => props.theme.backgroundColor};
`;

const PaddedPanel = styled(Panel)`
  padding: ${props => props.theme.spacerlg};
`;

type SpaceFunctionFormProps = FormComponentProps & Props;

export function SpaceFunctionFormImpl({
  form,
  spaceApi,
  spaceComponent: { properties },
  spaceComponent,
  hasConfigError
}: SpaceFunctionFormProps) {
  const uiFormId = `${spaceComponent.slug}-space-function-form`;

  const func = React.useMemo(
    () => createSpaceFunction(spaceComponent),
    [spaceComponent]
  );

  const { spaceId, editMode } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();

  const inputParams: InputParameter[] = properties.input_parameters || [];
  const button_text = properties.button_text;
  const {
    prefillParams,
    funcParams,
    hasRequiredValues,
    hasRequiredBindings,
    hasValidValues,
    hasRequiredComponentValues,
    getCurrentFuncParams,
    resetForm
  } = useFuncParams(func, inputParams, input || {}, form, spaceComponent);
  const { triggerEffects } = useSubmittableEffects(spaceApi, resetForm);

  const onCompleted = useCallback(
    (result: FunctionResult, metadata: unknown) => {
      updateOutput({ lastExecutionResult: result });
      triggerEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
      processQueueEffects(metadata);
    },
    [updateOutput, triggerEffects]
  );

  const onError = useCallback(
    (error: Error) => {
      triggerEffects({ type: SubmittableEventType.SUBMIT_FAILURE, error });
    },
    [triggerEffects]
  );

  const { executeFunction, status } = useSpaceFunction(
    func,
    properties.input_parameters,
    spaceId,
    onCompleted,
    onError
  );

  const access = useFunctionAccess(func);

  const onSubmit = React.useCallback(() => {
    executeFunction(getCurrentFuncParams());
  }, [executeFunction, getCurrentFuncParams]);

  const enabled =
    !editMode &&
    status !== FunctionExecutionStatus.IN_PROGRESS &&
    hasRequiredValues &&
    hasRequiredBindings &&
    hasValidValues;

  if (func.type === SpaceFunctionType.VOID) {
    return (
      <Panel title="Form" hasError={hasConfigError}>
        <EmptyState id={spaceComponent.slug} message="No form to display." />
      </Panel>
    );
  }

  if (!func || func.type === SpaceFunctionType.INVALID) {
    return (
      <PaddedPanel title={properties.title} hasError={hasConfigError}>
        {editMode ? (
          <Alert
            showIcon
            type="warning"
            message="A valid function needs to be associated with this form."
            description="Please configure its function using the configuration panel to the right."
          />
        ) : (
          <Alert
            showIcon
            type="warning"
            message="This form needs to be updated."
            description="The function associated with this form cannot be found. It's possible that the name of the function was changed or that the function was removed from your system."
          />
        )}
      </PaddedPanel>
    );
  }

  return (
    <Panel
      title={
        spaceComponent.properties.is_header_enabled && (
          <>
            {properties.title}
            <PermissionFeedback
              attributes={func.functionAttributes}
              parameters={func.functionParameters.filter(fp =>
                properties.input_parameters.some(
                  (ip: InputParameter) => ip.name === fp.name
                )
              )}
              access={access}
            />
          </>
        )
      }
      hasError={hasConfigError}
    >
      <Container>
        {properties.instructions ? <p>{properties.instructions}</p> : null}
        <FormContainer>
          <FunctionForm
            spaceApi={spaceApi}
            spaceComponent={spaceComponent}
            inputParameters={inputParams}
            formId={uiFormId}
            form={form}
            func={func!}
            initialValues={prefillParams}
            values={funcParams}
            onSubmit={onSubmit}
            disabled={editMode}
            functionExecutionStatus={status}
            isValid={enabled && hasRequiredComponentValues}
          />
        </FormContainer>
        <Actions>
          <Button
            data-test="functionFormButton"
            type="primary"
            form={uiFormId}
            className={!enabled ? "disabled" : ""}
            style={{ pointerEvents: enabled ? undefined : "none" }}
            loading={status === FunctionExecutionStatus.IN_PROGRESS}
            htmlType="submit"
          >
            {button_text}
          </Button>
        </Actions>
      </Container>
    </Panel>
  );
}

export default Form.create<SpaceFunctionFormProps>({
  name: "space-function-form"
})(SpaceFunctionFormImpl);
