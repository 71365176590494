import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

const QUERY = gql`
  query OpenStateTasksAssignedUser($queueId: ID!) {
    openStateTasksAssignedUser(queueId: $queueId) {
      user {
        id
        name
      }
      taskCount
    }
  }
`;

interface Variables {
  queueId: string;
}

export interface UserStatistic {
  user: {
    id: string;
    name: string;
  };
  taskCount: number;
}

interface Data {
  openStateTasksAssignedUser: UserStatistic[];
}

export const useOpenStateTaskUserStatistics = (
  options?: QueryHookOptions<Data, Variables>
) => useQuery<Data, Variables>(QUERY, options);
