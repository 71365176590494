import React, { useEffect } from "react";

import { Row, Col, Alert, Form, Input } from "antd";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import { useConfigContext } from "../../../ConfigContext";
import { Routes } from "../../../constants";
import { setTrackingUserId } from "../../../logging";
import { AuthStatus } from "../../../types";
import Button from "../../common/Button/Button";
import usePaths from "../../common/hooks/usePaths";
import { LogoColor } from "../../common/Logo";
import {
  GoogleLoginButton,
  DarkTheme,
  ErrorField,
  FormItem
} from "../../common/StyledComponents";
import TitleContainer from "../../common/TitleContainer";
import { AuthStatusProps, withAuthStatus } from "../WithAuthStatus/withAuthStatus";

import { LoginUser } from "./queries";
import {
  AlertContainer,
  FormContainer,
  OrRow,
  ForgotPasswordDiv,
  FormFooter
} from "./styledComponents";
import useLogin from "./useLogin";
import useSSOState from "./useSSOState";

interface LocationState {
  message?: string;
  messageTitle?: string;
}

export const LoginForm = (props: AuthStatusProps) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [valid, setValid] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const paths = usePaths();
  const [params] = useSearchParams();
  const next = params.get("next");

  const locationState = location.state as LocationState | undefined;

  const { isGoogleOAuthEnabled } = useConfigContext();

  const nextLocation = (need2FA: boolean) => {
    if (need2FA) {
      return next
        ? `${Routes.LOGIN_TWO_FACTOR}?next=${encodeURIComponent(next)}`
        : Routes.LOGIN_TWO_FACTOR;
    } else {
      const url = new URL(next || paths.getDashboard(), window.location.href);
      return url.pathname + url.search;
    }
  };

  const {
    loading: ssoLoading,
    required: ssoRequired,
    url: ssoUrl
  } = useSSOState(email);
  const ssoRedirect = `${ssoUrl}?next=${encodeURIComponent(next || Routes.LOGIN)}`;

  const { login, loading } = useLogin(
    (user?: LoginUser) => {
      if (user) {
        setTrackingUserId(user.id);
        navigate(nextLocation(user.hasDevice));
      } else {
        setValid(false);
      }
    },
    () => setValid(false)
  );

  useEffect(() => {
    if (!submitting || ssoLoading) {
      return;
    }

    setSubmitting(false);
    if (ssoRequired) {
      window.location.replace(ssoRedirect);
    } else {
      login(email, password);
    }
  }, [submitting, ssoLoading, ssoRequired, ssoRedirect, email, password, login]);

  const disabled = loading || submitting;
  let googleLoginUrl = Routes.LOGIN_GOOGLE_AUTH;
  if (next) {
    googleLoginUrl += `?next=${encodeURIComponent(next)}`;
  }

  switch (props.status) {
    case AuthStatus.Authenticated:
      return <Navigate to={nextLocation(false)} />;
    case AuthStatus.Partial:
      return <Navigate to={nextLocation(true)} />;
  }

  return (
    <>
      <DarkTheme />
      {locationState && locationState.message && (
        <AlertContainer>
          <Alert
            message={locationState.messageTitle || "Success"}
            description={locationState.message}
            type="success"
            onClose={() =>
              navigate("", {
                replace: true,
                state: {
                  ...locationState,
                  message: undefined,
                  messageTitle: undefined
                }
              })
            }
            closable
            showIcon
          />
        </AlertContainer>
      )}

      <TitleContainer title="Internal" logoColor={LogoColor.White} useLogoAsTitle>
        <FormContainer>
          <Col>
            <Row>
              <a href={googleLoginUrl}>
                <GoogleLoginButton disabled={!isGoogleOAuthEnabled}>
                  Sign in with Google
                </GoogleLoginButton>
              </a>
            </Row>
            <OrRow>
              <div id="or_strikethrough">
                <span>or</span>
              </div>
            </OrRow>
            <Row>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  setSubmitting(true);
                }}
              >
                <FormItem>
                  <Input
                    placeholder="Email"
                    disabled={disabled}
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormItem>
                {!ssoRequired && (
                  <FormItem>
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={e => setPassword(e.target.value)}
                      disabled={disabled}
                    />
                    <ForgotPasswordDiv>
                      <Link to={Routes.RESET_PASSWORD}>Forgot password</Link>
                    </ForgotPasswordDiv>
                  </FormItem>
                )}
                <FormFooter>
                  {!valid && <ErrorField>Incorrect username and password</ErrorField>}
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={disabled}
                    style={{ width: "auto", minWidth: "176px" }}
                  >
                    {ssoRequired ? "Log in with Single Sign-On" : "Login"}
                  </Button>
                  <Link to={Routes.SIGNUP}>Register a new organization</Link>
                </FormFooter>
              </Form>
            </Row>
          </Col>
        </FormContainer>
      </TitleContainer>
    </>
  );
};

export default withAuthStatus(LoginForm);
