import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { ExecutionResult } from "react-apollo";

import { assertNever } from "../../../../util/assertNever";
import { reportException } from "../../../../util/exceptionReporting";
import { useEnvironmentContext } from "../../../contexts/EnvironmentContext";

import {
  SQL_DESCRIBE_MUTATION,
  SqlDescribeData,
  SqlDescribeResultSuccess,
  SqlDescribeVars
} from "./queries";

enum ErrorMessage {
  GENERIC = "Unable to automatically infer Inputs and Outputs"
}

interface Result {
  describe: (dataSourceId: string, sql: string) => Promise<SqlDescribeResultSuccess>;
}

export default function useDescribe(): Result {
  const { getCurrentEnvironment } = useEnvironmentContext();
  const [describeFn] = useMutation<SqlDescribeData, SqlDescribeVars>(
    SQL_DESCRIBE_MUTATION
  );

  const describe = React.useCallback(
    (dataSourceId: string, sql: string) =>
      new Promise<SqlDescribeResultSuccess>(async (resolve, reject) => {
        const variables: SqlDescribeVars = {
          dataSourceId: dataSourceId,
          environmentId: getCurrentEnvironment().id,
          sql
        };
        let result: ExecutionResult<SqlDescribeData> | undefined;
        try {
          result = await describeFn({ variables });
        } catch (e) {
          reportException(e);
          return reject(e);
        }

        if (!result || !result.data?.sqlDescribe) {
          return reject(new Error(ErrorMessage.GENERIC));
        }
        const sqlDescribe = result.data.sqlDescribe;
        switch (sqlDescribe.__typename) {
          case "SqlDescribeResultSuccess":
            return resolve(sqlDescribe);
          case "PermissionErrorResult":
          case "ClientErrorResult":
            return reject(new Error(sqlDescribe.message));
          default:
            return assertNever(sqlDescribe);
        }
      }),
    [describeFn, getCurrentEnvironment]
  );

  return { describe };
}
