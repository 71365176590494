import invariant from "invariant";

import { AttributeTypes } from "../../../constants";

export enum FieldType {
  FILE_INPUT = "file_input",
  TEXTAREA = "textarea",
  TEXT_INPUT = "text_input",
  NUMBER_INPUT = "number_input",
  BOOL_INPUT = "bool_input",
  DATETIME_INPUT = "datetime_input",
  TIMESTAMP_INPUT = "timestamp_input",
  TIME_INPUT = "time_input",
  DATE_INPUT = "date_input",
  JSON_INPUT = "json_input",
  RADIO = "radio",
  DROPDOWN = "dropdown"
}

export const FIELD_TYPE_BY_ATTRIBUTE_TYPE: Record<AttributeTypes, FieldType> = {
  [AttributeTypes.BINARY]: FieldType.TEXT_INPUT,
  [AttributeTypes.FILE]: FieldType.FILE_INPUT,
  [AttributeTypes.BOOL]: FieldType.BOOL_INPUT,
  [AttributeTypes.DATE]: FieldType.DATE_INPUT,
  [AttributeTypes.TIME]: FieldType.TIME_INPUT,
  [AttributeTypes.DATETIME]: FieldType.DATETIME_INPUT,
  [AttributeTypes.TIMESTAMP]: FieldType.TIMESTAMP_INPUT,
  [AttributeTypes.DECIMAL]: FieldType.NUMBER_INPUT,
  [AttributeTypes.FLOAT]: FieldType.NUMBER_INPUT,
  [AttributeTypes.INT]: FieldType.NUMBER_INPUT,
  [AttributeTypes.JSON]: FieldType.JSON_INPUT,
  [AttributeTypes.STRING]: FieldType.TEXT_INPUT
};

export const FIELD_TYPE_DISPLAY_NAMES: { [key in FieldType]: string } = {
  [FieldType.FILE_INPUT]: "File upload",
  [FieldType.TEXT_INPUT]: "Single line text",
  [FieldType.TEXTAREA]: "Paragraph text",
  [FieldType.NUMBER_INPUT]: "Number",
  [FieldType.BOOL_INPUT]: "Default",
  [FieldType.DATETIME_INPUT]: "Date and time",
  [FieldType.TIMESTAMP_INPUT]: "Date and time with a time zone",
  [FieldType.TIME_INPUT]: "Time",
  [FieldType.DATE_INPUT]: "Date",
  [FieldType.JSON_INPUT]: "JSON data",
  [FieldType.RADIO]: "Radio button",
  [FieldType.DROPDOWN]: "Dropdown"
};

export function getFieldType(attributeType: AttributeTypes) {
  const field_type = FIELD_TYPE_BY_ATTRIBUTE_TYPE[attributeType];
  invariant(!!field_type, `${attributeType} lacks a default FieldType.`);
  return field_type;
}
