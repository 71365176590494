import React, { ReactNode } from "react";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { SpacingUnitValue } from "../../../cssConstants";
import { ErrorIcon } from "../../common/Icons";
import ErrorBoundary from "../ErrorBoundary";
import { BasePanel } from "../StyledComponents";

import ActionButton, { Action } from "./ActionButton";

interface Props {
  title: string;
  actions?: Action[];
  headerChildren?: ReactNode;
  fixedHeight?: number;
  link?: string;
  omitBodyPadding?: boolean;
  hideOverflow?: boolean;
  className?: string;
  hasError?: boolean;
  children: ReactNode;
  onExpand?: () => void;
}

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacerlg};
  border-bottom: solid 1px ${props => props.theme.borderGrey};
  color: ${props => props.theme.textColorOnDark};
`;

const Title = styled.h2`
  flex-grow: 0;
  margin: 0;
  font-size: ${props => props.theme.h2FontSize};
  line-height: 1.2;
  letter-spacing: -0.75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--body-font-family);
  font-weight: 400;
  color: ${props => props.theme.textColor};

  &.link {
    &:hover {
      cursor: pointer;
      color: ${props => props.theme.primaryColor};
    }
  }
`;
Title.displayName = "Title";

const LinkContainer = styled.div`
  flex-grow: 0;
`;

const ActionsContainer = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const Body = styled.div`
  position: relative;
  padding: ${props => props.theme.spacerlg};
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: auto;

  &.omitBodyPadding {
    padding: 0;
  }

  &.hideOverflow {
    overflow: hidden;
  }
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  top: ${props => props.theme.spacersm};
  left: ${props => props.theme.spacersm};
`;

export default function Panel({
  title,
  actions = [],
  headerChildren = null,
  fixedHeight,
  link,
  omitBodyPadding = false,
  hideOverflow = false,
  className = "",
  hasError = false,
  onExpand,
  children
}: Props) {
  // HACK: This is specific to card list where the cards are seperated
  // by a spacersm row gap
  const padding = SpacingUnitValue.lg + SpacingUnitValue.sm;
  const navigate = useNavigate();
  const onTitleClick = () => {
    if (!!link) {
      navigate(link);
    }
  };

  const showHeader =
    !!title || typeof onExpand === "function" || !!headerChildren || !!actions.length;
  return (
    <BasePanel className={className}>
      {hasError && <StyledErrorIcon />}
      {showHeader && (
        <Header>
          <Title className={!!link ? "link" : ""} onClick={onTitleClick}>
            {title}
          </Title>
          {typeof onExpand === "function" && (
            <LinkContainer>
              <ActionButton icon="arrows-alt" type="link" onClick={onExpand} />
            </LinkContainer>
          )}
          <ActionsContainer className="actionsContainer">
            {headerChildren}
            {actions.map((action, index) => (
              <ActionButton key={index} {...action} />
            ))}
          </ActionsContainer>
        </Header>
      )}
      <Body
        className={classNames({
          omitBodyPadding,
          hideOverflow
        })}
        style={
          fixedHeight
            ? { maxHeight: fixedHeight + padding, overflowY: "scroll" }
            : undefined
        }
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </Body>
    </BasePanel>
  );
}
