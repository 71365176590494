import React from "react";

import { Input as AntInput, Select } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";

import { formatTestHandle } from "../../../../../../test";
import withDebouncedValue from "../../../../../common/withDebouncedValue";
import { LayoutUnit, parseCssUnit } from "../../../../layout/util";

const Field = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.smallFontSize};
  min-height: ${props => props.theme.inputHeight};
`;

const Label = styled.label`
  color: ${props => props.theme.textColorMid} !important;
  font-size: ${props => props.theme.smallFontSize} !important;
  width: 70px;
  margin: 0 !important;
`;

const Input = styled(AntInput)`
  width: 4em;
  padding: 1px 0 1px 2px;
  margin: 0 0.3em 0 0;
`;

interface UnitOption {
  name: string;
  value: string;
  hasNumericComponent: boolean;
}

interface BoxLayoutValueProps {
  label: string;
  value: string;
  min?: number;
  max?: number;
  extraUnitOptions?: UnitOption[];
  onChange: (val: string) => void;
  onChangeUnit: (unit: LayoutUnit) => void;
}

export function BoxLayoutValueField(props: BoxLayoutValueProps) {
  return (
    <Field>
      <Label>{props.label}</Label>
      <BoxLayoutValueInput
        dataTest={`layout-input-${formatTestHandle(props.label)}`}
        value={String(props.value)}
        extraUnitOptions={props.extraUnitOptions}
        min={props.min}
        max={props.max}
        onChange={props.onChange}
        onChangeUnit={props.onChangeUnit}
      />
    </Field>
  );
}

const BoxLayoutValueInputRoot = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.theme.inputHeight};
`;

const BASE_CSS_UNIT_OPTIONS: UnitOption[] = [
  {
    value: LayoutUnit.PERCENTAGE,
    name: LayoutUnit.PERCENTAGE,
    hasNumericComponent: true
  },
  {
    value: LayoutUnit.PIXEL,
    name: LayoutUnit.PIXEL,
    hasNumericComponent: true
  }
];

export function BoxLayoutValueInput({
  value,
  extraUnitOptions = [],
  dataTest,
  min,
  max,
  onChange,
  onChangeUnit,
  onFocus,
  onBlur
}: {
  value: string;
  extraUnitOptions?: UnitOption[];
  dataTest?: string;
  min?: number;
  max?: number;
  onChange: (value: string) => void;
  onChangeUnit: (unit: LayoutUnit) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}) {
  const numericPart = parseFloat(value).toString();
  const unitPart = parseCssUnit(value);
  const unitOptions = extraUnitOptions.concat(BASE_CSS_UNIT_OPTIONS);
  const selectedUnitOption = unitOptions.find(uo => uo.value === unitPart);

  const handleNumericChange = (nextNumericPart: string) => {
    onChange(`${nextNumericPart}${unitPart}`);
  };

  const handleUnitChange = (nextUnitPart: LayoutUnit) => {
    onChangeUnit(nextUnitPart);
  };
  return (
    <BoxLayoutValueInputRoot data-test={dataTest}>
      {selectedUnitOption?.hasNumericComponent === true && (
        <DebouncedNumericInput
          value={numericPart}
          min={min}
          max={max}
          onChange={handleNumericChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      <CssUnitSelect
        value={unitPart as LayoutUnit}
        options={unitOptions}
        onChange={val => handleUnitChange(val as LayoutUnit)}
      />
    </BoxLayoutValueInputRoot>
  );
}

function CssUnitSelect({
  value,
  options = [],
  onChange
}: {
  value: LayoutUnit | undefined;
  options: UnitOption[];
  onChange: (value: string) => void;
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      size="small"
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      dropdownStyle={{ minWidth: "100px" }}
    >
      {options.map(unit => (
        <Select.Option key={unit.value}>{unit.name}</Select.Option>
      ))}
    </Select>
  );
}

interface NumericInputProps extends Omit<InputProps, "onChange"> {
  value: string;
  precision?: number; // Number of decimal points
  emptyValue?: string;
  min?: number;
  max?: number;
  onChange: (value: string) => void;
}

function NumericInput({
  value,
  emptyValue = "0",
  precision = 0,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  onFocus = () => {},
  onBlur = () => {},
  onChange
}: NumericInputProps) {
  return (
    <Input
      value={parseFloat(value).toFixed(precision)}
      size="small"
      onFocus={evt => {
        onFocus(evt);
      }}
      onBlur={evt => {
        onBlur(evt);
      }}
      onChange={e => {
        let val = e.target.value;
        const asFloat = parseFloat(val);
        val = Number.isNaN(asFloat) ? "" : asFloat.toFixed(precision);
        const match = val.match(/\d+\.?\d*/);
        val = match ? match[0] : val === "" ? emptyValue : value;
        val = asFloat < min ? String(min) : val;
        val = asFloat > max ? String(max) : val;
        onChange(val);
      }}
    />
  );
}

export const DebouncedNumericInput = withDebouncedValue(NumericInput);

export const styledInputs = {
  Label,
  Field
};
