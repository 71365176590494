import {
  FiltersOption,
  ViewFilterOperator,
  ViewFilterOperatorDisplayNames
} from "../../../../../../types";

export const NULL_VALUE = "__INTERNAL_NULL_VALUE__";

export const parseSearchText = (
  value: string,
  attributeMap: Record<string, FiltersOption>
): [string, ViewFilterOperator, string | null] | null => {
  // NOTE (ryan): !=, >=, <= need to be checked prior to =, >, <
  // because they are substrings of each other.
  // Similarly, "not in" and "icontains" need to be checked prior to "in".
  const operatorPrecedent = [
    ViewFilterOperator.NOT_EQUALS,
    ViewFilterOperator.LESS_THAN_OR_EQUAL,
    ViewFilterOperator.GREATER_THAN_OR_EQUAL,
    ViewFilterOperator.EQUALS,
    ViewFilterOperator.LESS_THAN,
    ViewFilterOperator.GREATER_THAN,
    ViewFilterOperator.NOT_IN,
    ViewFilterOperator.ICONTAINS,
    ViewFilterOperator.IN
  ];
  for (const op of operatorPrecedent) {
    // check against the display operator because that is what users see in
    // the search bar (ie. " contains " instead of "icontains")
    const displayOp = ViewFilterOperatorDisplayNames[op];
    const idx = value.indexOf(displayOp);
    if (idx > -1) {
      const name = value.slice(0, idx);
      let val: string | null = value.slice(idx + displayOp.length);
      if (val === NULL_VALUE) {
        val = null;
      }
      const lowerCaseName = name.toLowerCase();
      const attribute = Object.values(attributeMap).find(value => {
        return value.sourceName === name || value.name.toLowerCase() === lowerCaseName;
      });
      const sourceName = attribute?.sourceName;
      // if sourceName exists and op is a supported operator,
      // return parsed results otherwise return null.
      return sourceName && attributeMap[sourceName].operators.includes(op)
        ? [sourceName, op, val]
        : null;
    }
  }
  return null;
};
