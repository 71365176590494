import React from "react";

import { Layout, PageHeader } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import classnames from "classnames";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { MainTitle } from "../components/common/StyledComponents";

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  height: 100%;

  &.overflowScrolls {
    overflow: auto;
  }
`;

const StylizedPageHeader = styled(PageHeader)`
  .ant-breadcrumb {
    width: 100%;
  }

  /* Fixes safari display issue https://www.pivotaltracker.com/story/show/167948985 */
  .ant-page-header-title-view-title {
    display: revert;
  }

  /* safari hack above breaks FireFox so target a fix at it */
  @-moz-document url-prefix() {
    .ant-page-header-title-view-title {
      display: inline-block;
    }
  }

  &.is-main-content-header {
    padding: ${props => props.theme.spacers.xl};
  }

  &.has-title.has-page-header-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: ${props => props.theme.spacers.xl};

    .ant-page-header-content-view {
      flex-grow: 1;
      align-items: flex-end;
      max-width: 40%;
    }
  }

  &.has-sub @media (max-width: ${props => props.theme.breakpoints.md}) {
    &.has-title.has-page-header-content {
      display: block;

      .ant-page-header-content-view {
        max-width: unset;
      }
    }
  }
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  color: ${props => props.theme.textColorMid};

  p {
    margin-bottom: 0;
  }
`;

const StyledContent = styled(Content)`
  height: 100%;
  background-color: #fff;

  & > div {
    height: 100%;
  }

  &.overflowScrolls > div {
    height: auto;
    background-color: #fff;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 0;
    padding: ${props => props.theme.spacers.md};
  }
`;

interface TableLayoutProps {
  className?: string;
  title: string;
  subTitle?: JSX.Element | string;
  breadcrumbRoutes?: BreadcrumbProps["routes"];
  headerContent?: JSX.Element;
  overflow?: "hidden" | "scroll";
  isMainContentLayout?: boolean;
  children: React.ReactNode;
}

function itemRender(route: any) {
  return <Link to={route.path}>{route.breadcrumbName}</Link>;
}

const TableLayout = (props: TableLayoutProps) => {
  const { overflow = "scroll", isMainContentLayout = false } = props;
  const rootClasses = classnames("table-layout-root", {
    [props.className || ""]: !!props.className,
    "is-main-content-layout": isMainContentLayout,
    overflowScrolls: overflow === "scroll"
  });
  const headerClasses = classnames({
    "has-title": !!props.title || !!props.subTitle,
    "has-sub-title": !!props.subTitle,
    "is-main-content-header": isMainContentLayout,
    "has-page-header-content": !!props.headerContent,
    "has-bread-crumbs": props.breadcrumbRoutes && !!props.breadcrumbRoutes.length
  });
  const contentClasses = classnames("table-layout-content", {
    overflowScrolls: overflow === "scroll"
  });
  return (
    <StyledLayout className={rootClasses}>
      <StylizedPageHeader
        className={headerClasses}
        title={
          <div>
            {!!props.title && (
              <MainTitle className={classnames({ noMargin: !props.subTitle })}>
                {props.title}
              </MainTitle>
            )}
            {!!props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
          </div>
        }
        breadcrumb={{ itemRender, routes: props.breadcrumbRoutes }}
      >
        {props.headerContent}
      </StylizedPageHeader>
      <StyledContent className={contentClasses}>{props.children}</StyledContent>
    </StyledLayout>
  );
};

export default TableLayout;

// for use in drawers, such as AuditLogDrawer or RoleDrawer
export const TableLayoutNoMargin = styled(TableLayout)`
  .ant-layout-content {
    margin: 0;
    padding-left: 24px;
  }
`;
