import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "react-apollo";

import { CommentNode, CommentPermissionDenied } from "../queries";

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: ID!, $content: String!) {
    updateComment(id: $id, content: $content) {
      ... on CommentNode {
        id
        content
      }
      ... on CommentErrorObjectNotFound {
        message
      }
    }
  }
`;

export interface Data {
  result: CommentNode | CommentPermissionDenied;
}

export interface Variables {
  id: string;
  content: string;
}

export const useUpdateComment = (options?: MutationHookOptions<Data, Variables>) => {
  return useMutation<Data, Variables>(UPDATE_COMMENT, options);
};
