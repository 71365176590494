import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Dropdown, Icon, Menu } from "antd";
import gql from "graphql-tag";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  withAuthStatus,
  AuthStatusProps
} from "../../../components/auth/WithAuthStatus/withAuthStatus";
import useAuthUser from "../../../components/common/hooks/useAuthUser";
import { Routes, Urls } from "../../../constants";
import { SpacingUnit } from "../../../cssConstants";
import { partialAuthClient, client, cache } from "../../../graphql";
import { setTrackingUserId } from "../../../logging";
import { AuthStatus } from "../../../types";

const StyledMenu = styled(Menu)`
  background-color: ${props => props.theme.backgroundColor};
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-item a {
    color: ${props => props.theme.inputColor};
    font-size: 13px;

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.inputColor};
      background-color: ${props => props.theme.selectHoverBackgroundColor};
    }
  }

  .ant-dropdown-menu-item-divider {
    background-color: ${props => props.theme.menuBorderColor};
  }
`;

const LogOutMessage = styled.p`
  cursor: pointer;
  color: ${props => props.theme.primaryColor};
`;

const MenuLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${SpacingUnit.sm};
  overflow: hidden;
  cursor: pointer;
`;

const MenuLabelEmail = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: ${SpacingUnit.xs};
  font-size: 13px;
`;

interface UserMenuProps {
  className?: string;
  children?: React.ReactNode;
}

export const LOGOUT_MUTATION = gql`
  mutation {
    authLogout {
      ok
    }
  }
`;

function useLogout(status: AuthStatus) {
  const navigate = useNavigate();
  const [logout] = useMutation(LOGOUT_MUTATION, {
    client: status === AuthStatus.Authenticated ? client : partialAuthClient,
    onCompleted: () => {
      cache.reset();
      navigate(Routes.LOGIN);
      setTrackingUserId(null);
    }
  });
  return logout;
}

export const UserMenu = (props: UserMenuProps) => {
  const { status, authUser, isAdmin } = useAuthUser();
  const logout = useLogout(status);
  const menu = (
    <StyledMenu>
      {isAdmin && !!authUser && (
        <Menu.Item>
          <Link to={Routes.SETTINGS_USERS}>Company Settings</Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to={Routes.USER_PROFILE}>Profile</Link>
      </Menu.Item>
      <Menu.Item>
        <a
          href="https://internal.io/docs/introduction-to-internal"
          target="_blank"
          rel="noopener noreferrer"
        >
          Documentation
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={Urls.SUPPORT_URL} target="_blank" rel="noopener noreferrer">
          Contact Us
        </a>
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>Log out</Menu.Item>
    </StyledMenu>
  );
  return (
    <Dropdown
      className={props.className}
      data-test="headerDropdown"
      trigger={["click"]}
      overlay={menu}
    >
      {props.children ? (
        props.children
      ) : (
        <MenuLabel>
          <MenuLabelEmail>{authUser?.email || "…"}</MenuLabelEmail>
          <Icon type="down" />
        </MenuLabel>
      )}
    </Dropdown>
  );
};

export const HeaderMenu = (props: AuthStatusProps) => {
  const logout = useLogout(props.status);

  if (props.status === AuthStatus.Unauthenticated) {
    return <Link to="/login">Log in</Link>;
  }

  if (props.status === AuthStatus.Partial) {
    return (
      <LogOutMessage className="link" onClick={() => logout()}>
        Log out
      </LogOutMessage>
    );
  }

  return <UserMenu />;
};

export default withAuthStatus(HeaderMenu);
