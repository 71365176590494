import React from "react";

import { Input } from "antd";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import { CredentialFormProps } from "./types";

export const Id = "APIKey";

export default function ApiKeyField(props: CredentialFormProps) {
  return (
    <FormItem label={props.includeLabel ? "API Key" : undefined}>
      {props.form.getFieldDecorator(Id, {
        validateTrigger: "onSubmit",
        rules: [
          {
            required: true,
            whitespace: true,
            message: VALIDATION_MESSAGES.requiredField
          }
        ]
      })(<Input placeholder="API Key" type="password" disabled={props.isLoading} />)}
    </FormItem>
  );
}
