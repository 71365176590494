import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

import { LinkButtonNew } from "../StyledComponents";

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li``;

export default function ExpandoList({
  limit = 5,
  children
}: {
  limit?: number;
  children: React.ReactNode;
}) {
  const [expanded, setExpanded] = React.useState(false);
  const childArray = React.Children.toArray(children);
  const enabled = childArray.length > limit;
  return (
    <div>
      <List>
        {childArray.map((c, i) =>
          expanded || i < limit ? <ListItem key={i}>{c}</ListItem> : null
        )}
      </List>
      {enabled && (
        <LinkButtonNew
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {expanded ? (
            <span>
              <Icon type="up" /> Fewer
            </span>
          ) : (
            <span>
              <Icon type="down" /> More
            </span>
          )}
        </LinkButtonNew>
      )}
    </div>
  );
}
