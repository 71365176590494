import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button, Skeleton } from "antd";
import gql from "graphql-tag";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../constants";
import { SpacingUnit } from "../../../cssConstants";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import { H4, MainTitle } from "../../common/StyledComponents";
import {
  FETCH_AUTHORIZATION_PROVIDERS,
  ResultData
} from "../AuthProviders/AuthProviderList";
import AuthProviderTable from "../AuthProviders/AuthProviderTable";

import APIKeys, { CreateKeyModal } from "./APIKeys";

const Root = styled.div`
  padding: ${props => props.theme.spacerxl};
`;

const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;

  .profileRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: ${SpacingUnit.md};

    .profileCell {
      min-width: 226px;

      &:first-child {
        font-color: #000000;
        font-weight: 500;
      }
    }

    .ant-table-wrapper {
      width: 100%;
    }
  }
`;

const Section = styled.section`
  margin-top: ${props => props.theme.spacerxl};
`;

const SectionHeader = styled.header`
  display: grid;
  grid-template-columns: 50% 50%;
  padding-bottom: ${props => props.theme.spacermd};
`;

const SectionTitle = styled(H4)`
  display: flex;
  align-items: center;
  margin: 0;
`;

const SectionActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const USER_PROFILE_QUERY = gql`
  query userProfileQuery {
    authUser {
      id
      email
      firstName
      lastName
      hasDevice
    }
  }
`;

export const CREATE_DEVICE_MUTATION = gql`
  mutation CreateDevice {
    createDevice {
      ok
    }
  }
`;

const UserProfile = () => {
  const [isCreateKeyModalOpen, setIsCreateKeyModalOpen] = useState(false);

  const navigate = useNavigate();
  const profileQuery = useQuery(USER_PROFILE_QUERY);
  const authQuery = useQuery<ResultData>(FETCH_AUTHORIZATION_PROVIDERS, {
    fetchPolicy: "cache-and-network"
  });
  const user = _.get(profileQuery.data, "authUser");

  if (!user) {
    return (
      <>
        <MainTitle className="noMargin">Profile</MainTitle>
        <Skeleton active={true} />
      </>
    );
  }
  return (
    <Root>
      <MainTitle className="noMargin">Profile</MainTitle>
      <Section>
        <ProfileContent>
          <div className="profileRow">
            <div className="profileCell">Name:</div>
            <div className="profileCell">{`${user.firstName} ${user.lastName}`}</div>
          </div>
          <div className="profileRow">
            <div className="profileCell">Email:</div>
            <div className="profileCell">{`${user.email}`}</div>
          </div>
          <div className="profileRow">
            <div className="profileCell">Two-Step Verification:</div>
            <div className="profileCell">
              {!user.hasDevice ? (
                <Button onClick={() => navigate(Routes.SETUP_TWO_FACTOR)}>
                  Enable
                </Button>
              ) : (
                <p>You have already set up two-step verification.</p>
              )}
            </div>
          </div>
        </ProfileContent>
      </Section>

      <Section>
        <SectionHeader>
          <SectionTitle>API Keys</SectionTitle>
          <SectionActions>
            <ButtonNew
              type="primary"
              icon="plus"
              onClick={() => setIsCreateKeyModalOpen(true)}
            >
              Create
            </ButtonNew>
          </SectionActions>
        </SectionHeader>
        <APIKeys />
      </Section>

      {!!authQuery.data?.allAuthorizationProviders?.length && (
        <Section>
          <SectionHeader>
            <SectionTitle>Authorization Providers</SectionTitle>
          </SectionHeader>
          <AuthProviderTable
            authProviders={authQuery.data?.allAuthorizationProviders || []}
            isLoading={authQuery.loading}
            refetchProviders={authQuery.refetch}
            enableRowClick={false}
            hiddenColumns={["createdAt", "createdBy", "expirationTime"]}
          />
        </Section>
      )}

      {isCreateKeyModalOpen && (
        <CreateKeyModal onClose={() => setIsCreateKeyModalOpen(false)} />
      )}
    </Root>
  );
};

export default UserProfile;
