import { SpaceParamsComponent } from "../types";

export default function validateComponent(c: SpaceParamsComponent) {
  const {
    properties: { url_parameters: parameters }
  } = c;

  const isDuplicate = (name: string) => {
    return parameters.filter(p => p.name === name).length > 1;
  };

  return parameters.every(c => {
    return !isDuplicate(c.name) && !!c.name && encodeURI(c.name) === c.name;
  });
}
