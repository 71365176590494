import styled from "styled-components";

const InteractionBlocker = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  user-select: none;
`;

export default InteractionBlocker;
