import gql from "graphql-tag";

import {
  ClientErrorResult,
  DescribeColumn,
  DescribeParameter,
  PermissionErrorResult
} from "../../../../../types";

export interface SqlDescribeResultSuccess {
  __typename: "SqlDescribeResultSuccess";
  columns: DescribeColumn[];
  parameters: DescribeParameter[];
}

export interface SqlDescribeData {
  sqlDescribe?: SqlDescribeResultSuccess | ClientErrorResult | PermissionErrorResult;
}

export interface SqlDescribeVars {
  environmentId: string;
  dataSourceId: string;
  sql: string;
}

export const SQL_DESCRIBE_MUTATION = gql`
  mutation SqlDescribeMutation($environmentId: ID!, $dataSourceId: ID!, $sql: String!) {
    sqlDescribe(environmentId: $environmentId, dataSourceId: $dataSourceId, sql: $sql) {
      __typename
      ... on SqlDescribeResultSuccess {
        columns {
          sourceName
          sourceType
          orderable
          operators
        }
        parameters {
          sourceName
        }
      }
      ... on PermissionErrorResult {
        message
      }
      ... on ClientErrorResult {
        code
        message
        errorInfo {
          reason
          domain
          metadata
        }
      }
    }
  }
`;
