// below utils are used for converting strings to template strings and vice versa

// regex for strings that start and end with either ', ", or `, allowing escaped quotes in the middle
const VALID_QUOTED_REGEX = /(^'([^']|\\')*'$)|(^"([^"]|\\")*"$)|(^`([^`]|\\`)*`$)/;

export const isQuoted = (input: string) => {
  return !!input.match(VALID_QUOTED_REGEX);
};

const hasBacktick = (input: string) => {
  return input.indexOf("`") > -1;
};

// value to send back to consumer of this component
export const getQuotedValue = (input: string) => {
  if (input === "null" || isQuoted(input)) {
    return input;
  }
  // if reaches this block, input may be wrapped in backticks but have invalid contents
  // (ie. a nested `). return input in this case.
  if (input.charAt(0) === "`" && input.charAt(input.length - 1) === "`") {
    return input;
  }
  return `\`${input}\``;
};

// value to render in input field
export const getUnquotedValue = (input: string) => {
  if (isQuoted(input)) {
    return input.substring(1, input.length - 1);
  } else if (input && input !== "null" && !hasBacktick(input as string)) {
    return `$\{${input}}`; // else treat as expression (backwards compatibility with existing expressions; ignore invalid strings with nested backticks)
  } else {
    return input;
  }
};
