import { BindingShape, Binding } from "../../../../../types";

export const getSchema = (): Binding[] => [
  {
    name: "value",
    shape: BindingShape.UNKNOWN
  },
  {
    name: "error",
    shape: BindingShape.UNKNOWN
  }
];
