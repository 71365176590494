import React, { useCallback, useEffect, useReducer } from "react";

import { Select } from "antd";

import { FunctionValidationStatus, HttpCode, HttpFunctionMetadata } from "../..";
import { displayURL } from "../../../../util/http";
import KeyValueInputs, { KeyValue } from "../../../KeyValueInputs";
import SingleLineEditor from "../../../SingleLineEditor";
import { ErrorMessageField } from "../../common/ErrorMessageField";
import { isHTTPLike } from "../../support";
import { BaseFunctionProps } from "../common";
import { GraphqlPart, HttpMethods, RequestBodyType } from "../constants";
import * as common from "../styledComponents";
import { DataSourceFunction, GeneralActionTypes } from "../types";
import { isFunctionValid } from "../utils";

import HttpBodyField from "./HttpBodyField";
import reducer, { getInitialState, HttpFunctionActionTypes } from "./reducer";
import { getFunctionErrors } from "./selectors";
import * as styled from "./styledComponents";
import useEnvCredentials from "./useEnvCredentials";
import { createMapping } from "./utils";

const { Option } = Select;

interface Props extends BaseFunctionProps {
  key: any;
  func: DataSourceFunction | undefined;
  onRequestTypeChange?: (type: RequestBodyType) => void;
  templatePopoverContent?: React.ReactNode;
  previewEnvironmentId: string | undefined;
}

const HttpFunctionForm = ({
  selectedDataSource,
  func,
  previewEnvironmentId,
  setBaseConfig,
  setValidationStatus,
  showErrors,
  templatePopoverContent,
  onRequestTypeChange = () => {}
}: Props) => {
  const initialState = getInitialState(
    func as DataSourceFunction<HttpFunctionMetadata> | undefined
  );
  const [draftState, draftDispatch] = useReducer(reducer, initialState);
  const errorMessages = getFunctionErrors(draftState);

  // Call callbacks if draft component state has changed.
  useEffect(() => {
    setBaseConfig(createMapping(draftState), draftState.metadata.code);
    setValidationStatus(
      isFunctionValid(draftState.errorMessages)
        ? FunctionValidationStatus.VALID
        : FunctionValidationStatus.INVALID
    );
  }, [draftState, setBaseConfig, setValidationStatus]);

  const setFieldValue = useCallback((name: string, value: any) => {
    draftDispatch({
      type: GeneralActionTypes.SET_FIELD_VALUE,
      payload: {
        fieldName: name,
        fieldValue: value
      }
    });
  }, []);

  const onBodyChange = useCallback((body: string, code?: HttpCode) => {
    draftDispatch({
      type: HttpFunctionActionTypes.SET_BODY_AND_CODE,
      payload: { body, code }
    });
  }, []);

  const onGraphqlQueryChange = useCallback(value => {
    draftDispatch({
      type: HttpFunctionActionTypes.SET_GRAPHQL_QUERY_BODY,
      payload: {
        fieldName: GraphqlPart.Query,
        fieldValue: value
      }
    });
  }, []);

  const onGraphqlVariablesChange = useCallback(value => {
    draftDispatch({
      type: HttpFunctionActionTypes.SET_GRAPHQL_QUERY_BODY,
      payload: {
        fieldName: GraphqlPart.Variables,
        fieldValue: value
      }
    });
  }, []);

  const credentials = useEnvCredentials(selectedDataSource, previewEnvironmentId);
  const path =
    credentials && isHTTPLike(credentials.adapter) ? displayURL(credentials) : "";

  return (
    <>
      <common.GridLabel>Path:</common.GridLabel>
      <common.GridFormItemAlignStart>
        <styled.PathPrefixText data-test="pathHostPrefix" title={path}>
          {path}
        </styled.PathPrefixText>
        <styled.PathTemplateContainer>
          <SingleLineEditor
            data-test="pathInput"
            onChange={value => setFieldValue("path", value)}
            placeholder="Add your path"
            value={draftState.path}
          />
          <ErrorMessageField errorMessage={showErrors ? errorMessages.path : ""} />
        </styled.PathTemplateContainer>
      </common.GridFormItemAlignStart>

      <common.GridLabel>Method:</common.GridLabel>
      <common.GridFormItem vertical>
        <common.Select
          data-test="methodSelect"
          placeholder="Select a method"
          value={draftState.method}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          onChange={value => setFieldValue("method", value)}
        >
          {Object.values(HttpMethods).map(method => {
            return (
              <Option value={method} key={method}>
                {method}
              </Option>
            );
          })}
        </common.Select>
        <ErrorMessageField errorMessage={showErrors ? errorMessages.method : ""} />
      </common.GridFormItem>

      <common.GridLabel>URL Parameters:</common.GridLabel>
      <common.GridFormItem vertical>
        <KeyValueInputs
          className="urlParameters"
          value={draftState.urlParameters}
          itemName="parameter"
          useTemplateStringInputs={true}
          popoverInnerContent={templatePopoverContent}
          onChange={(value: KeyValue[]) => setFieldValue("urlParameters", value)}
        />
        <ErrorMessageField
          errorMessage={showErrors ? errorMessages.urlParameters : ""}
        />
      </common.GridFormItem>

      <common.GridLabel>Headers:</common.GridLabel>
      <common.GridFormItem vertical>
        <KeyValueInputs
          className="headers"
          value={draftState.headers}
          itemName="header"
          useTemplateStringInputs={true}
          popoverInnerContent={templatePopoverContent}
          onChange={value => setFieldValue("headers", value)}
        />
        <ErrorMessageField errorMessage={showErrors ? errorMessages.headers : ""} />
      </common.GridFormItem>

      <common.GridLabel noTopMargin>Body:</common.GridLabel>
      <common.GridFormItem vertical>
        <HttpBodyField
          metadata={draftState.metadata}
          graphqlQuery={draftState.graphqlQuery}
          graphqlVariables={draftState.graphqlVariables}
          body={draftState.body}
          onRequestTypeChange={e => {
            draftDispatch({
              type: HttpFunctionActionTypes.SET_REQUEST_TYPE,
              payload: {
                fieldValue: e.target.value
              }
            });
            onRequestTypeChange(e.target.value);
          }}
          onGraphqlQueryChange={onGraphqlQueryChange}
          onGraphqlVariablesChange={onGraphqlVariablesChange}
          onBodyChange={onBodyChange}
        />
        <ErrorMessageField errorMessage={showErrors ? errorMessages.body : ""} />
      </common.GridFormItem>
    </>
  );
};

export default HttpFunctionForm;
