import { useMemo } from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  Connection,
  Edge,
  DataSourceNode,
  FunctionNode
} from "../../../../../../types";

export interface S3DataSourceResult {
  s3DataSources?: { [key: string]: { name: string; presignId: string } };
}

export const GET_S3_DATA_SOURCES = gql`
  {
    allDataSources(integration: "s3") {
      edges {
        node {
          id
          name
          functions {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function useS3DataSources(): S3DataSourceResult {
  const { data } = useQuery<{
    allDataSources: Connection<DataSourceNode>;
  }>(GET_S3_DATA_SOURCES);
  const dataSources = data?.allDataSources.edges;

  return {
    s3DataSources: useMemo(() => {
      if (!dataSources) return undefined;
      return dataSources.reduce((agg: any, nextDs: Edge<DataSourceNode>) => {
        const dataSource = dataSources.find(
          (e: Edge<DataSourceNode>) => e.node.id === nextDs.node.id
        )!.node;
        const presignId = dataSource.functions!.edges.find(
          (e: Edge<FunctionNode>) => e.node.name === "presigned.get_object"
        )?.node.id;

        if (!presignId) return agg;
        agg[nextDs.node.id] = {
          name: dataSource.name,
          presignId
        };
        return agg;
      }, {});
    }, [dataSources])
  };
}
