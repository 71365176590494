import React from "react";

import styled from "styled-components";

import { ModalNew } from "./Modal";
import { Kbd } from "./StyledComponents";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  font-size: 12px;
`;

const StyledLabel = styled.div`
  flex: 0 0 125px;
  padding-right: ${props => props.theme.spacermd};
`;

interface HotkeysModalProps {
  keyMap: { [key: string]: string[] };
  visible: boolean;
  handleOk: () => void;
}

const HotkeysModal = (props: HotkeysModalProps) => {
  const { keyMap, visible, handleOk } = props;
  return (
    <ModalNew
      title="Keyboard shortcuts"
      visible={visible}
      onCancel={handleOk}
      cancelText="Ok"
    >
      <StyledContainer>
        {Object.entries(keyMap).map(([actionName, keyCombos]) => (
          <StyledRow key={actionName}>
            <StyledLabel>
              {actionName.replace(new RegExp("_", "g"), " ").toLocaleLowerCase()}
            </StyledLabel>
            <div>
              {keyCombos.map(kc => (
                <Kbd key={kc}>{kc}</Kbd>
              ))}
            </div>
          </StyledRow>
        ))}
      </StyledContainer>
    </ModalNew>
  );
};

export default HotkeysModal;
