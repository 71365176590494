import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import AdvancedConfig from "../../common/image/AdvancedImageConfig";
import BasicConfig from "../../common/image/BasicImageConfig";
import useViewConfig from "../../common/useViewConfig";

import { ensureImageConfigState } from "./reducer/reducer";

export default function ImageConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer dataConfig={<ImageConfigContent />} slug={slug} />
  );
}

export function ImageConfigContent() {
  const context = useComponentConfigContext();
  const state = ensureImageConfigState(context.state);

  useViewConfig({
    state: state,
    dispatch: context.dispatch
  });

  return (
    <>
      <BasicConfig state={state} dispatch={context.dispatch} />
      <AdvancedConfig
        state={state}
        dispatch={context.dispatch}
        errors={context.errors}
      />
    </>
  );
}
