import React from "react";

import { Icon } from "antd";
import invariant from "invariant";
import styled from "styled-components";

import { ConfigurationSectionProps } from "../../../../../../types";
import InlineInput from "../../../../../common/InlineInput";
import Section, {
  Empty,
  IconButton,
  SectionChild
} from "../../../../../common/Menu/Section";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import { SpaceParam, SpaceParamsComponent } from "../types";

const ParameterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.spacerxl};

  &:not(:first-child) {
    margin-top: ${props => props.theme.spacerxs};
  }
`;
ParameterRow.displayName = "ParameterRow";

const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  div:last-child {
    max-width: 134px;
  }
`;

const LinkIcon = styled(Icon)`
  margin-right: ${props => props.theme.spacerxs};
`;

export const Error = styled.p`
  color: ${props => props.theme.errorColor};
  margin-left: ${props => props.theme.spacerlg};
  font-size: ${props => props.theme.tinyFontSize};
`;

export default function ParamsConfigurationSection(props: ConfigurationSectionProps) {
  const [lastActionInsertIndex, setLastActionInsertIndex] = React.useState<
    number | null
  >(null);
  const { spaceComponents, displayName } = props;
  const spaceComponent = spaceComponents[0];
  const { url_parameters: parameters } = (spaceComponent as SpaceParamsComponent)
    .properties;
  invariant(
    spaceComponents.length === 1,
    "Only one params component permitted per space."
  );

  const { dispatch } = useSpaceConfigContext();
  const selectError = React.useCallback(
    (p: string, i: number) => {
      if (!p && lastActionInsertIndex !== i) {
        return "Parameter cannot be blank.";
      }
      if (encodeURIComponent(p) !== p) return "Invalid character in parameter.";
      const allNames = parameters.map(p => p.name);
      if (allNames.slice(0, i).includes(p) && p !== "")
        return "Parameter cannot be repeated.";
    },
    [lastActionInsertIndex, parameters]
  );
  const updateParams = (newParams: SpaceParam[]) => {
    dispatch({
      type: "MERGE_DRAFT_COMPONENT",
      componentSlug: spaceComponent.slug,
      payload: {
        change: {
          properties: {
            url_parameters: newParams
          }
        }
      }
    });
  };

  return (
    <Section
      title={displayName}
      onTitleClick={() => {
        updateParams([...parameters, { name: "" }]);
        setLastActionInsertIndex(parameters.length);
      }}
    >
      {!parameters.length ? (
        <Empty>No parameters added</Empty>
      ) : (
        <SectionChild>
          {parameters.map((param, i) => {
            const error = selectError(param.name, i);
            return (
              <div key={`${param.name}-${i}`}>
                <ParameterRow>
                  <RowGroup>
                    <LinkIcon type="link" />
                    <InlineInput
                      data-test="inlineParamInput"
                      onChange={e => {
                        const nextParams = [...parameters];
                        nextParams.splice(i, 1, { name: e.target.value });
                        updateParams(nextParams);
                        setLastActionInsertIndex(null);
                      }}
                      value={param.name}
                      placeholder="Parameter name"
                    />
                  </RowGroup>
                  <IconButton
                    icon="minus"
                    type="link"
                    data-test="removeParam"
                    onClick={() => {
                      const nextParams = [...parameters];
                      nextParams.splice(i, 1);
                      setLastActionInsertIndex(null);
                      updateParams(nextParams);
                    }}
                  />
                </ParameterRow>
                {error && <Error data-test="errorMessage">{error}</Error>}
              </div>
            );
          })}
        </SectionChild>
      )}
    </Section>
  );
}
