import React from "react";

import produce from "immer";
import styled from "styled-components";

import SingleLineEditor from "../../../../../common/SingleLineEditor";
import {
  ActionType,
  Dispatch as FlowConfigDispatch,
  ExpressionStepOptions
} from "../../reducer";
import { Checkbox } from "../StyledComponents";

const ExpirationContainer = styled.div`
  & > div {
    margin-bottom: ${props => props.theme.spacermd};
  }
`;

const ExpirationTime = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ExpirableStep {
  expires: boolean;
  order: number;
  options: { expiration: string | undefined };
}

interface Props<T extends ExpirableStep> {
  step: T;
  dispatch: FlowConfigDispatch;
  updateOptions: (options: any) => void;
}

export function ExpirationFields<T extends ExpirableStep>({
  step,
  dispatch,
  updateOptions
}: Props<T>) {
  return (
    <ExpirationContainer>
      <Checkbox
        checked={!step.expires}
        onChange={e => {
          dispatch({
            type: ActionType.UPDATE_STEP_EXPIRATION_ENABLED,
            payload: { step: step.order, expires: !e.target.checked }
          });
        }}
      >
        This state never expires
      </Checkbox>
      <ExpirationTime>
        <span>
          Set an expiration template: (must evaluate to unix epoch in seconds)
        </span>
        <SingleLineEditor
          disabled={!step.expires}
          value={step.options.expiration || ""}
          onChange={value => {
            updateOptions(
              produce(step.options, (draft: ExpressionStepOptions) => {
                draft.expiration = value;
              })
            );
          }}
        />
      </ExpirationTime>
    </ExpirationContainer>
  );
}
