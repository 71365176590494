/**
 * Modal to display errors in a space.
 * --
 * UI notes:
 * To test locally, kill connector service to simulate an error.
 */
import React from "react";

import { Collapse, Modal } from "antd";
import moment from "moment";
import styled from "styled-components";

import Editor from "../../../../common/Editor";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 600px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > span.message {
    overflow-wrap: break-word;
    max-width: 75%;
  }

  & > span.relativeTime {
    color: ${props => props.theme.textColorMid};
    font-size: ${props => props.theme.smallFontSize};
    text-align: right;
  }
`;

export interface ModalProps {
  errors: any[];
  visible: boolean;
  onClose: () => void;
}

export function SpaceConsoleModal({ errors, visible, onClose }: ModalProps) {
  return (
    <StyledModal title="Errors" visible={visible} onOk={onClose} onCancel={onClose}>
      <Collapse accordion>
        {(errors || [])
          .sort((a, b) => moment(b.when).valueOf() - moment(a.when).valueOf())
          .map((e: any, idx: number) => {
            const header = (
              <StyledHeader>
                <span className="message">{e.message}</span>
                <span className="relativeTime">{moment(e.when).fromNow()}</span>
              </StyledHeader>
            );
            return (
              <Collapse.Panel key={`error-${idx}`} header={header}>
                <Editor
                  mode="application/json"
                  onChange={() => null}
                  value={JSON.stringify(e, null, 1)}
                  hideGutter
                  readOnly
                />
              </Collapse.Panel>
            );
          })}
      </Collapse>
    </StyledModal>
  );
}
