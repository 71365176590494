import produce from "immer";
import { isEqual } from "lodash";

import {
  AttributeNode,
  EmptyViewNode,
  FiltersOption,
  SortByOption,
  SourceType,
  SpaceComponentObject
} from "../../../../../../types";
import {
  BaseComponentConfigState,
  BaseConfigAction,
  ComponentConfigState,
  SpaceConfigAction
} from "../../../../types";
import {
  Column,
  ColumnType,
  createAttributeColumn,
  isComponentColumn
} from "../ColumnListManager";
import { generateInputParameters } from "../ParametersManager/utils";
import { ConfigFunctionNode } from "../queries";
import { extractFiltersOptions, extractSortByOptions } from "../util";

import { functionAttributeToAttribute } from "./util";

export enum Action {
  CHANGE_BINDING = "CHANGE_BINDING",
  CHANGE_FUNCTION = "CHANGE_FUNCTION",
  CHANGE_SOURCE = "CHANGE_SOURCE",
  RESET_SOURCE = "RESET_SOURCE",
  LOAD_BINDING = "LOAD_BINDING",
  LOAD_FUNCTION = "LOAD_FUNCTION"
}

export interface ChangeBinding extends BaseConfigAction {
  type: Action.CHANGE_BINDING;
  payload: { path: string };
}

export interface ChangeFunction extends BaseConfigAction {
  type: Action.CHANGE_FUNCTION;
  payload: { functionId: string | null };
}

export interface ChangeSource extends BaseConfigAction {
  type: Action.CHANGE_SOURCE;
  payload: { sourceType: SourceType };
}

export interface ResetSource extends BaseConfigAction {
  type: Action.RESET_SOURCE;
}

export interface LoadBinding extends BaseConfigAction {
  type: Action.LOAD_BINDING;
  payload: {
    name: string;
    attributes: AttributeNode[];
  };
}

export interface LoadFunction extends BaseConfigAction {
  type: Action.LOAD_FUNCTION;
  payload: ConfigFunctionNode;
}

export type ViewConfigAction =
  | ResetSource
  | ChangeBinding
  | ChangeFunction
  | ChangeSource
  | LoadBinding
  | LoadFunction;

export interface ViewConfigState {
  savedComponentColumns: Column[];
  attributes: AttributeNode[] | null;
  filtersOptions: FiltersOption[];
  sortByOptions: SortByOption[];
  lastFunction: undefined | ConfigFunctionNode;
}

export const INITIAL_STATE: ViewConfigState = {
  attributes: null,
  savedComponentColumns: [],
  filtersOptions: [],
  sortByOptions: [],
  lastFunction: undefined
};

export const emptyView: EmptyViewNode = {
  id: ""
};

type ComponentWithViewConfigState = ViewConfigState & BaseComponentConfigState;

export function ensureViewConfigState(
  state: ComponentConfigState
): ComponentWithViewConfigState {
  if (
    "attributes" in state &&
    (Array.isArray(state.attributes) || state.attributes === null) &&
    "savedComponentColumns" in state &&
    Array.isArray(state.savedComponentColumns) &&
    "filtersOptions" in state &&
    Array.isArray(state.filtersOptions) &&
    "sortByOptions" in state &&
    Array.isArray(state.sortByOptions)
  ) {
    return state;
  }
  throw new Error("Expected component config state to include view config state.");
}

function saveComponentColumns(state: ComponentWithViewConfigState) {
  return {
    ...state,
    savedComponentColumns:
      state.draftComponent.properties.columns?.filter(isComponentColumn) || []
  };
}

function resetViewProperties(component: SpaceComponentObject): SpaceComponentObject {
  return {
    ...component,
    view: {
      ...emptyView
    },
    properties: {
      ...component.properties,
      columns: null,
      filters: [],
      order: [],
      input_parameters: []
    }
  };
}

export default function reducer(
  state: ComponentWithViewConfigState,
  action: SpaceConfigAction
): ComponentWithViewConfigState {
  state = ensureViewConfigState(state);

  switch (action.type) {
    case Action.RESET_SOURCE: {
      const nextState = { ...state, ...INITIAL_STATE };
      nextState.draftComponent = resetViewProperties(state.draftComponent);
      nextState.draftComponent.sourceType = undefined;
      nextState.draftComponent.properties.columns = [];
      return nextState;
    }

    case Action.CHANGE_FUNCTION: {
      const { functionId } = action.payload;
      const nextState = saveComponentColumns(state);
      nextState.attributes = [];
      nextState.draftComponent = resetViewProperties(state.draftComponent);
      nextState.draftComponent.view = {
        id: "",
        function: { id: functionId || "" }
      };
      return nextState;
    }

    case Action.CHANGE_SOURCE: {
      const { sourceType } = action.payload;
      return produce(state, draftState => {
        draftState.attributes = [];
        draftState.draftComponent = resetViewProperties(state.draftComponent);
        draftState.draftComponent.sourceType = sourceType;
        draftState.draftComponent.view =
          sourceType === SourceType.BINDING
            ? undefined
            : {
                id: "",
                function: { id: "" }
              };
      });
    }

    case Action.CHANGE_BINDING: {
      const nextState = saveComponentColumns(state);
      nextState.attributes = [];
      nextState.draftComponent = resetViewProperties(state.draftComponent);
      nextState.draftComponent.view = undefined;
      nextState.draftComponent.properties.binding = action.payload.path;
      return nextState;
    }

    case Action.LOAD_BINDING: {
      const { attributes } = action.payload;
      return produce(state, (draftState: ComponentWithViewConfigState) => {
        draftState.draftComponent.view = undefined;
        draftState.attributes = attributes;
        draftState.filtersOptions = [];
        draftState.sortByOptions = [];
        if (draftState.draftComponent.properties.columns === null) {
          draftState.draftComponent.properties.columns =
            state.savedComponentColumns.concat(
              attributes
                .slice()
                .sort((a, b) => a.sourceIndex - b.sourceIndex)
                .map(a => createAttributeColumn(a))
            );
        }
      });
    }

    case Action.LOAD_FUNCTION: {
      const fn = action.payload;

      const functionId = fn.id;
      const filtersOptions = extractFiltersOptions(fn.metadata);
      const sortByOptions = extractSortByOptions(fn.metadata);
      const attributes = fn.functionAttributes.edges.map(e =>
        functionAttributeToAttribute(e.node)
      );

      // If functions are selected rapidly this action may be dispatched for a
      // function which is not longer the selected function. In that case ignore
      // the action as we're only interested in loading the currently configured
      // function id.
      if (functionId !== state.draftComponent.view?.function?.id) {
        return state;
      }

      if (isEqual(state.lastFunction, action.payload)) {
        return state;
      }

      return produce(state, (draftState: ComponentWithViewConfigState) => {
        draftState.attributes = attributes;
        draftState.filtersOptions = filtersOptions;
        draftState.sortByOptions = sortByOptions;
        if (draftState.draftComponent.properties.columns === null) {
          draftState.draftComponent.properties.columns =
            state.savedComponentColumns.concat(
              attributes
                .slice()
                .sort((a, b) => a.sourceIndex - b.sourceIndex)
                .map(a => createAttributeColumn(a))
            );
        }
        // If there are any AttributeColumns for FunctionAttributes no longer present in the Function prune them.
        const attrSourceNames = attributes.map(a => a.sourceName);
        draftState.draftComponent.properties.columns =
          draftState.draftComponent.properties.columns.filter((c: Column) => {
            return (
              c.column_type === ColumnType.COMPONENT ||
              attrSourceNames.includes(c.attribute)
            );
          });

        if (!draftState.draftComponent.properties.input_parameters?.length) {
          draftState.draftComponent.properties.input_parameters =
            generateInputParameters({
              ...draftState.draftComponent,
              functions: { edges: [{ node: fn }] }
            });
        }
        // needed for ParameterConfigSection to load params
        draftState.draftComponent.functions = {
          edges: [{ node: fn }]
        };

        draftState.lastFunction = action.payload;
      });
    }

    default:
      return state;
  }
}
