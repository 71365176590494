import {
  Binding,
  BindingShape,
  ObjectBinding,
  SpaceComponentPackage
} from "../../../../types";
import { reportException } from "../../../util/exceptionReporting";
import { ComponentNode } from "../RenderTreeContext";

import { getColumnsSchema } from "./common/ColumnListManager/getColumnsOutputShape";
import { getParametersSchema } from "./common/ParametersManager";
import { getBindingFromPath } from "./util";

// PseudoSpaceComponentPackage is used for type level enforcement that
// isPseudoComponent is properly set for pseudo component packages.
export interface PseudoSpaceComponentPackage extends SpaceComponentPackage {
  allowSelfBinding: true;
  isPseudoComponent: true;
}

export const Row: PseudoSpaceComponentPackage = {
  allowSelfBinding: true,
  allowAncestorBinding: true,
  isPseudoComponent: true,
  Component: () => null,
  ConfigurationComponent: () => null,
  componentConfigReducer: state => state,
  ensureComponent: component => component,
  allowedHosts: new Set(),
  displayName: "Row",
  type: "ROW",
  getSchema: (node: ComponentNode): Binding[] => {
    return getColumnsSchema(node.parent as ComponentNode);
  },
  isHeadless: false,
  errorSelector: () => [],
  isContainer: false
};

export const RepeatedItem: PseudoSpaceComponentPackage = {
  allowSelfBinding: true,
  allowAncestorBinding: true,
  isPseudoComponent: true,
  Component: () => null,
  ConfigurationComponent: () => null,
  componentConfigReducer: state => state,
  ensureComponent: component => component,
  allowedHosts: new Set(),
  displayName: "Repeated Item",
  type: "REPEATEDITEM",
  getSchema: (node: ComponentNode): Binding[] => {
    const path = (node.parent as ComponentNode)?.component?.properties?.binding;
    if (!path) return [];
    const binding = getBindingFromPath(node, path);
    if (
      binding?.shape === BindingShape.OBJECT_ARRAY ||
      binding?.shape === BindingShape.OBJECT
    ) {
      return (binding as ObjectBinding).attributes || [];
    }
    return [];
  },
  getSelfSchemaShape: (node: ComponentNode): BindingShape => {
    const path = (node.parent as ComponentNode)?.component?.properties?.binding;
    if (!path) return BindingShape.UNKNOWN;
    const binding = getBindingFromPath(node, path);
    switch (binding?.shape) {
      case BindingShape.SCALAR_ARRAY:
        return BindingShape.SCALAR;
      case BindingShape.OBJECT_ARRAY:
        return BindingShape.OBJECT;
      default:
        reportException(new Error("Unexpected binding shape for RepeatedItem node"), {
          extra: {
            node,
            parent: node.parent,
            parentProps: (node.parent as ComponentNode)?.component?.properties
          }
        });
        return BindingShape.UNKNOWN;
    }
  },
  isHeadless: false,
  errorSelector: () => [],
  isContainer: false
};

export const Card: PseudoSpaceComponentPackage = {
  allowSelfBinding: true,
  allowAncestorBinding: true,
  isPseudoComponent: true,
  Component: () => null,
  ConfigurationComponent: () => null,
  componentConfigReducer: state => state,
  ensureComponent: component => component,
  allowedHosts: new Set(),
  displayName: "Card",
  type: "CARD",
  getSchema: (node: ComponentNode): Binding[] => {
    return getColumnsSchema(node.parent as ComponentNode);
  },
  isHeadless: false,
  errorSelector: () => [],
  isContainer: false
};

export const Option: PseudoSpaceComponentPackage = {
  allowSelfBinding: true,
  allowAncestorBinding: true,
  isPseudoComponent: true,
  Component: () => null,
  ConfigurationComponent: () => null,
  componentConfigReducer: state => state,
  ensureComponent: component => component,
  allowedHosts: new Set(),
  displayName: "Option",
  type: "OPTION",
  getSchema: (node: ComponentNode): Binding[] => {
    return getColumnsSchema(node.parent as ComponentNode);
  },
  isHeadless: false,
  errorSelector: () => [],
  isContainer: false
};

export const Fieldset: PseudoSpaceComponentPackage = {
  allowSelfBinding: true,
  allowAncestorBinding: false,
  isPseudoComponent: true,
  Component: () => null,
  ConfigurationComponent: () => null,
  componentConfigReducer: state => state,
  ensureComponent: component => component,
  allowedHosts: new Set(),
  displayName: "Field Set",
  type: "FIELDSET",
  getSchema: (node: ComponentNode): Binding[] => {
    return getParametersSchema(node.parent as ComponentNode);
  },
  isHeadless: false,
  errorSelector: () => [],
  isContainer: false
};
