import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import { useStableSpaceContext } from "../../../SpaceContext";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import {
  useComponentConfigContext,
  ComponentConfigContextContainer
} from "../../common/ComponentConfigContext";
import { TerseComponentConfigContextContainer } from "../../common/ComponentConfigContext/ComponentConfigContext";
import { Field, DebouncedInput } from "../../common/ConfigPanel/styledComponents";
import DefaultValueField from "../../common/DefaultValueField";
import InputComponentNameFields from "../../common/InputComponentNameFields";
import { ValidationRulesSection } from "../../common/ValidationRulesConfigSection/ValidationRulesConfigSection";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import { ensureCheckboxConfigState } from "./reducer";

export default function SpaceCheckboxConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={React.useMemo(
        () => (
          <SpaceCheckboxConfigContent />
        ),
        []
      )}
      slug={slug}
    />
  );
}

export function TerseConfig({ slug }: ComponentConfigProps) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <DefaultValueField />
    </TerseComponentConfigContextContainer>
  );
}

export function InlineConfig({ slug }: ComponentConfigProps) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <InputComponentNameFields isInline />
      <DefaultValueField />
    </TerseComponentConfigContextContainer>
  );
}

function SpaceCheckboxConfigContent() {
  const context = useComponentConfigContext();
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const state = ensureCheckboxConfigState(context.state);
  const { draftComponent } = state;
  const displayName = findSpaceComponentPackage(draftComponent.type)?.displayName || "";

  return (
    <>
      <BaseComponentConfigSection>
        <InputComponentNameFields />
        <Field>
          <label>Text</label>
          <DebouncedInput
            data-test="textInput"
            value={state.draftComponent.properties.text}
            placeholder={`Text for ${displayName.toLowerCase()}`}
            onChange={text =>
              context.dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: { properties: { text } }
                }
              })
            }
          />
        </Field>
        <DefaultValueField />
      </BaseComponentConfigSection>
      <ValidationRulesSection />
      <VisibilityRulesManagerSection />
    </>
  );
}
