import { titleize } from "inflection";
import pluralize from "pluralize";

import { recordKeyFromFilters } from "../../../../constants";
import {
  DataValue,
  AttributeNode,
  FunctionAttributeNode,
  RecordKey,
  CursorType,
  Cursor,
  ResourceNode,
  RecordIdentifier,
  FunctionCursor
} from "../../../../types";
import { getResourceRecordKey } from "../../util";

import { FunctionNode } from "./useRecordData/queries";

export const reindexRecordsById = (
  records: DataValue[][],
  attributes: (AttributeNode | FunctionAttributeNode)[]
): Record<string, DataValue>[] =>
  records.map((record: DataValue[]) =>
    record.reduce(
      (memo: Record<string, DataValue>, val: any, i: number) => ({
        ...memo,
        [attributes[i].id]: val
      }),
      {}
    )
  );

export const getRecordIdentifier = (cursor: Cursor): RecordIdentifier => {
  let slug: string;
  let key: RecordKey;

  if (cursor.type === CursorType.RESOURCE) {
    slug = cursor.slug;
    key = recordKeyFromFilters(cursor.filters);
  } else if (cursor.type === CursorType.FUNCTION) {
    slug = cursor.id;
    key = cursor.params;
  } else {
    throw new Error("unrecognized cursor");
  }
  return { slug, key };
};

export const getDataAccessorId = (dataAccessor: ResourceNode | FunctionNode) => {
  switch (dataAccessor.__typename) {
    case "FunctionNode":
      return dataAccessor.id;
    case "ResourceNode":
      return dataAccessor.slug;
    default:
      throw new Error("unrecognized typename");
  }
};

export const getRecordTitle = (dataAccessor: ResourceNode | FunctionNode) => {
  return pluralize(getDataAccessorTitle(dataAccessor), 1);
};

export const getDataAccessorRecordKey = (
  dataAccessor: ResourceNode | FunctionNode,
  record: any
): RecordKey => {
  switch (dataAccessor.__typename) {
    case "FunctionNode":
      const key = dataAccessor.functionAttributes.edges.reduce<RecordKey>((acc, e) => {
        if (e.node.sourceKey) {
          acc[e.node.sourceName] = record[e.node.sourceName];
        }
        return acc;
      }, {});
      return key;
    case "ResourceNode":
      return getResourceRecordKey(dataAccessor, record);
    default:
      throw new Error("unrecognized typename");
  }
};

export const getDataAccessorTitle = (dataAccessor: ResourceNode | FunctionNode) => {
  switch (dataAccessor.__typename) {
    case "FunctionNode":
      return dataAccessor.title;
    case "ResourceNode":
      return dataAccessor.name;
    default:
      throw new Error("unrecognized typename");
  }
};

export const getFunctionCursor = (cursor: Cursor): FunctionCursor => {
  if (cursor.type !== CursorType.FUNCTION) {
    throw new Error("getFunctionCursor: expected function cursor");
  }
  return cursor;
};

const GET_FUNCTION_REGEX = /^Get (.*) record$/;
const LIST_FUNCTION_REGEX = /^List (.*) records$/;

export const getFriendlyFunctionName = (title: string): string => {
  let matches;

  matches = title.match(GET_FUNCTION_REGEX);
  if (matches) {
    return titleize(matches[1]);
  }

  matches = title.match(LIST_FUNCTION_REGEX);
  if (matches) {
    return titleize(matches[1]);
  }
  return title;
};
