import React from "react";

import useDebouncedValue from "../../../common/hooks/useDebouncedValue";
import useObservedRect from "../../../common/hooks/useObservedRect";
import { useDimensionsContext } from "../DimensionsContext";
import { useLayoutContextDispatcher } from "../LayoutContext/LayoutContext";
import { useLayoutDelegationContext } from "../LayoutDelegator/LayoutDelegator";
import { ElementLayout, isEmptyDOMRect } from "../util";

import useElementTransformer from "./useElementTransformer";

// layoutRect must be in px.
export default function useLayoutRegistrations(
  slug: string,
  layout: ElementLayout,
  ref: React.RefObject<HTMLElement>
) {
  const layoutContextDispatch = useLayoutContextDispatcher();
  const { isDelegated } = useLayoutDelegationContext();
  const { convertLayout } = useElementTransformer();
  const { key: dimensionsContextKey } = useDimensionsContext();
  const pxLayout = React.useMemo(() => convertLayout(layout), [layout, convertLayout]);

  const domRect = useObservedRect(
    isDelegated ? null : ref,
    new DOMPoint(parseInt(pxLayout.left), parseInt(pxLayout.top))
  );

  const debouncedDomRect = useDebouncedValue(domRect, 50);
  React.useLayoutEffect(() => {
    if (isDelegated) return;
    if (isEmptyDOMRect(debouncedDomRect)) return;
    layoutContextDispatch({
      type: "UPDATE_ELEMENT_DOM_RECT",
      payload: { slug, rect: debouncedDomRect }
    });
  }, [slug, debouncedDomRect, isDelegated, layoutContextDispatch]);

  React.useLayoutEffect(() => {
    if (isDelegated) return;
    layoutContextDispatch({
      type: "UPDATE_COMPONENT_DIMENSIONS_CONTEXT_MAPPING",
      payload: { componentSlug: slug, dimensionsContextKey }
    });
  }, [slug, dimensionsContextKey, isDelegated, layoutContextDispatch]);
}
