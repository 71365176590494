import React, { useState, useEffect, useMemo } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import { useParams } from "react-router-dom";

import useAuthUser from "../../../components/common/hooks/useAuthUser";
import Checkout from "../../../components/settings/Billing/Checkout";
import PlanChangeConfirmation from "../../../components/settings/Billing/PlanChangeConfirmation";
import { useConfigContext } from "../../../ConfigContext";
import {
  GET_PRICING_DATA,
  GET_ALL_USERS_DATA,
  REFRESH_SUBSCRIPTION_MUTATION
} from "../../../graphql/queries";
import { Subscription, Edge, UserNode } from "../../../types";
import { isInternalEmail } from "../../util/users";

export const CREATE_SUBSCRIPTION_INTENT_MUTATION = gql`
  mutation CreateSubscription(
    $productSlug: String!
    $productTerm: String!
    $quantity: Int!
  ) {
    createSubscription(
      productSlug: $productSlug
      productTerm: $productTerm
      quantity: $quantity
    ) {
      subscription
    }
  }
`;

interface Params extends Record<string, any> {
  planSlug: string | undefined;
  term: string | undefined;
}

const BillingCheckoutContainer = () => {
  const { stripePublicKey } = useConfigContext();
  const [stripeSecret, setStripeSecret] = useState(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const { planSlug, term } = useParams<Params>();
  const [paymentTerm, setPaymentTerm] = useState(term);

  const [createSubscriptionIntent] = useMutation(CREATE_SUBSCRIPTION_INTENT_MUTATION, {
    onCompleted: resp => {
      setSubscription(resp.createSubscription.subscription);
      const secret =
        resp.createSubscription.subscription.latest_invoice.payment_intent
          .client_secret;
      setStripeSecret(secret);
      setIsLoaded(true);
    }
  });

  const [refreshSubscription] = useMutation(REFRESH_SUBSCRIPTION_MUTATION);

  const { authUser } = useAuthUser();
  const { data: usersData, loading: usersLoading } = useQuery(GET_ALL_USERS_DATA, {
    fetchPolicy: "cache-and-network"
  });
  const users = usersData?.allUsers?.edges.map((e: Edge<UserNode>) => e.node) || [];
  const activeUsers = users.filter((user: UserNode) => {
    return user.status === "active" && !isInternalEmail(user.email);
  });

  const currentPlan = authUser?.organization.currentPlan;

  useEffect(() => {
    if (!authUser || usersLoading) {
      return undefined;
    }
    const quantity = activeUsers.length || 1; // we need at least 1 user, or subscription automatically activates;
    if (currentPlan?.slug === "basic") {
      createSubscriptionIntent({
        variables: { productSlug: planSlug, productTerm: term, quantity }
      });
    } else {
      setIsLoaded(true);
    }
  }, [
    authUser,
    usersLoading,
    createSubscriptionIntent,
    currentPlan?.slug,
    planSlug,
    term,
    activeUsers.length
  ]);

  const stripePromise: any = useMemo(() => {
    if (stripePublicKey) {
      return loadStripe(stripePublicKey);
    }
  }, [stripePublicKey]);

  const { data: currentPricesData, loading: pricesLoading } = useQuery(
    GET_PRICING_DATA,
    {
      variables: {
        slug: planSlug
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const updateSubscriptionTerms = (paymentTerm: string) => {
    const quantity = activeUsers.length || 1; // we need at least 1 user, or subscription automatically activates;
    setLoadingSubscription(true);
    refreshSubscription({
      variables: {
        subscriptionId: subscription?.id,
        newProductSlug: planSlug,
        newProductTerm: paymentTerm,
        quantity: quantity
      }
    }).then(resp => {
      setSubscription(resp.data.refreshSubscription.subscription);
      setStripeSecret(
        resp.data.refreshSubscription.subscription.latest_invoice.payment_intent
          .client_secret
      );
      setLoadingSubscription(false);
    });
  };

  const stripeOptions: {
    clientSecret?: string;
    options: any;
  } = {
    // passing the client secret obtained from the server
    options: {
      appearance: {
        theme: "stripe"
      }
    }
  };

  if (!authUser) {
    return null;
  }

  if (stripeSecret) {
    stripeOptions["clientSecret"] = stripeSecret;
  }

  const settingsBody = (
    <Elements
      key={Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5)}
      stripe={stripePromise}
      options={stripeOptions}
    >
      {authUser.organization.stripeSubscriptionId ? (
        <PlanChangeConfirmation
          currentPlanSlug={currentPlan?.slug}
          paymentTerm={paymentTerm}
          setPaymentTerm={setPaymentTerm}
          newPlanSlug={planSlug}
          priceData={currentPricesData}
          loadingSubscription={loadingSubscription}
        />
      ) : (
        <Checkout
          subscription={subscription}
          currentPlanSlug={currentPlan?.slug}
          paymentTerm={paymentTerm}
          newPlanSlug={planSlug}
          currentPricesData={currentPricesData}
          pricesLoading={pricesLoading}
          updateSubscriptionTerms={updateSubscriptionTerms}
          loadingSubscription={loadingSubscription}
          setPaymentTerm={setPaymentTerm}
        />
      )}
    </Elements>
  );

  return <>{isLoaded && settingsBody}</>;
};

export default BillingCheckoutContainer;
