import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";
import gql from "graphql-tag";
import styled from "styled-components";

import { publicClient } from "../../graphql";
import Message from "../common/Message";
import { DarkTheme } from "../common/StyledComponents";
import TitleContainer from "../common/TitleContainer";

export const RESEND_EMAIL_CONFIRMATION_MUTATION = gql`
  mutation ResendEmailConfirmation($email: String!) {
    resendEmailConfirmation(email: $email) {
      ok
    }
  }
`;

const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`;

interface Props {
  email: string;
}

const CheckEmail = (props: Props) => {
  const { email } = props;

  const [showResend, setShowResend] = useState<boolean>(true);

  const [resendEmailConfirmation] = useMutation(RESEND_EMAIL_CONFIRMATION_MUTATION, {
    client: publicClient,
    variables: { email },
    onCompleted: () => {
      Message.success("New verification link sent.");
      setShowResend(false);
    }
  });

  const handleClick = () => {
    resendEmailConfirmation();
  };

  return (
    <React.Fragment>
      <DarkTheme />

      <TitleContainer
        title="Check your email."
        description={`We sent a verification link to ${email}.`}
      >
        {showResend ? (
          <LinkWrapper>
            <Button type="link" onClick={handleClick}>
              Send a new verification link
            </Button>
          </LinkWrapper>
        ) : (
          <div />
        )}
      </TitleContainer>
    </React.Fragment>
  );
};

export default CheckEmail;
