import { Checkbox as AntCheckbox, Button } from "antd";
import styled from "styled-components";

import BaseAttributeInput from "../../../../../../common/AttributeInput";

export const Root = styled.div`
  position: relative;
  padding: ${props => props.theme.spacerlg};
  border-top: 0px;

  .has-error {
    display: flex;
    flex-direction: column;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 48%;
  }
`;

export const Section = styled.section`
  margin-bottom: ${props => props.theme.spacerlg};

  &:last-of-type {
    margin-bottom: 0;
  }

  & > label {
    margin-bottom: ${props => props.theme.spacersm};
    font-size: ${props => props.theme.defaultFontSize};
    font-weight: normal;
  }
`;

export const Checkbox = styled(AntCheckbox)`
  display: block;
`;

export const AttributeInput = styled(BaseAttributeInput)`
  width: calc(50% - ${props => props.theme.spacerxs} * 2);
`;

export const BoolWrapper = styled.div`
  display: flex;

  &:not(:last-child) > * {
    margin-bottom: ${props => props.theme.spacersm};
  }

  > *:last-child {
    margin-left: ${props => props.theme.spacersm};
  }
`;
BoolWrapper.displayName = "BoolWrapper";

export const ComponentConfigButton = styled(Button)`
  margin-top: ${props => props.theme.spacermd};
  margin-bottom: ${props => props.theme.spacermd};
`;
