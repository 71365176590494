import React from "react";

import { throttle } from "lodash";

const THROTTLE = 50;
const MIN_CALLBACK_INTERVAL = 1500;
const SCROLL_BUFFER = 53; // Estimated 1 row's height

function isScrolledToBottom(table: HTMLTableElement) {
  const scrollTop = table.scrollTop;
  const scrolledToBottom =
    table.offsetHeight + scrollTop >= table.scrollHeight - SCROLL_BUFFER;

  return scrolledToBottom;
}

export default function useInfiniteScroll(
  tableRef: React.MutableRefObject<HTMLTableElement | null>,
  onScrollToBottom: () => void
) {
  const [lastScrollToBottom, setLastScrollToBottom] = React.useState(Date.now());
  const [lastCallback, setLastCallback] = React.useState(Date.now());

  function onScroll() {
    if (tableRef.current === null) return;
    if (!isScrolledToBottom(tableRef.current)) return;
    setLastScrollToBottom(Date.now());
  }

  const throttledScrollToBottom = React.useRef(throttle(onScroll, THROTTLE));
  React.useEffect(() => {
    const throttled = throttledScrollToBottom.current;
    return () => {
      throttled.cancel();
    };
  });

  React.useEffect(() => {
    if (lastScrollToBottom - MIN_CALLBACK_INTERVAL <= lastCallback) return;
    onScrollToBottom();
    throttledScrollToBottom.current.cancel();
    setLastCallback(Date.now());
  }, [onScrollToBottom, lastScrollToBottom, lastCallback, setLastCallback]);

  React.useLayoutEffect(() => {
    const table = tableRef.current;
    const callbackRef = throttledScrollToBottom.current;
    if (!table) return;
    table.addEventListener("scroll", callbackRef);
    table.addEventListener("wheel", callbackRef);

    return () => {
      table.removeEventListener("scroll", callbackRef);
      table.removeEventListener("wheel", callbackRef);
    };
  }, [throttledScrollToBottom, tableRef]);
}
