import React from "react";

import {
  SpaceComponentObject,
  SourceType,
  BindingShape
} from "../../../../../../../types";
import { SpaceConfigAction } from "../../../../../types";
import { ImageConfigState } from "../../../SpaceImage/ImageConfig/reducer/reducer";
import { SpaceImageProperties } from "../../../SpaceImage/types";
import { SpaceViewlessImageProperties } from "../../../SpaceViewlessImage/types";
import BaseComponentConfigSection from "../../BaseComponentConfigSection";
import ValidationError from "../../ComponentConfigContext/ValidationError/ValidationError";
import { ConfigSection } from "../../ConfigPanel";
import { Field } from "../../ConfigPanel/styledComponents";
import NameFields from "../../NameFields";
import SourceSelector from "../../SourceSelector";
import { DebouncedTemplateEditor } from "../../TemplateEditor";

interface Props {
  state: ImageConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

export type ImageComponent =
  | SpaceComponentObject<SpaceViewlessImageProperties>
  | SpaceComponentObject<SpaceImageProperties>;

export default function BasicConfig({ state, dispatch }: Props) {
  const {
    properties: { template }
  } = state.draftComponent as ImageComponent;
  const inlinedImage = state.draftComponent.type.includes("VIEWLESS");

  return (
    <>
      {!inlinedImage && (
        <ConfigSection title="Source">
          <Field>
            <SourceSelector
              requiresSource={false}
              selectableSourceTypes={[SourceType.VIEW]}
              compatibleBindingShapes={[BindingShape.SCALAR]}
            />
          </Field>
        </ConfigSection>
      )}
      <ConfigSection title="Image URL">
        <Field>
          <DebouncedTemplateEditor
            value={template}
            placeholder="https://example.com/${myimage}"
            onChange={(template: string) =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.template",
                  value: template
                }
              })
            }
          />
          <ValidationError field="TEMPLATE" />
        </Field>
      </ConfigSection>
      <BaseComponentConfigSection>
        <NameFields shouldRenderTitle={!inlinedImage} />
      </BaseComponentConfigSection>
    </>
  );
}
