import React from "react";

import classNames from "classnames";
import styled from "styled-components";

import { ErrorIcon } from "./Icons";

const Root = styled.div`
  position: relative;
  display: inline-block;
  padding: 0
    calc(${props => props.theme.largeFontSize} + ${props => props.theme.spacerxs});
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  top: calc(50% - ${props => props.theme.largeFontSize} / 2);
  right: 0;
`;

interface Props {
  className?: string;
  children: React.ReactNode;
  hasError?: boolean;
  errorFillStyle?: "filled" | "outlined";
  dataTest?: string;
}

export default function TabTitle({
  className,
  hasError = false,
  errorFillStyle = "outlined",
  dataTest,
  children
}: Props) {
  const classes = classNames("tabTitle", className);
  return (
    <Root data-test={dataTest} className={classes}>
      {children}
      {hasError && <StyledErrorIcon fillStyle={errorFillStyle} />}
    </Root>
  );
}
