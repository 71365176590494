import { AuthorizationFlowNode, AuthorizationFlowStepNode } from "../../../../types";

export interface State {
  flow: AuthorizationFlowNode;
  flowState: any;
  step: AuthorizationFlowStepNode;
  errorMessage: string;
  isProcessing: boolean;
  environmentId?: string;
}

export const initialState: Pick<
  State,
  "flowState" | "errorMessage" | "isProcessing" | "environmentId"
> = {
  flowState: {},
  errorMessage: "",
  isProcessing: false,
  environmentId: undefined
};

interface SetStepProcessed {
  type: "SET_STEP_PROCESSED";
  payload: {
    message?: string;
  };
}

interface SetStepProcessing {
  type: "SET_STEP_PROCESSING";
}

interface SetFlowStep {
  type: "SET_FLOW_STEP";
  payload: {
    step: AuthorizationFlowStepNode;
    state: any;
  };
}

type Action = SetFlowStep | SetStepProcessing | SetStepProcessed;

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_FLOW_STEP": {
      const { step, state: flowState } = action.payload;
      return { ...state, step: step, flowState: flowState };
    }
    case "SET_STEP_PROCESSING": {
      return { ...state, isProcessing: true };
    }
    case "SET_STEP_PROCESSED": {
      const { message } = action.payload;
      return {
        ...state,
        errorMessage: message || state.errorMessage,
        isProcessing: false
      };
    }
    default:
      throw new Error("unknown authorization flow action");
  }
}
