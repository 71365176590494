import memoizeOne from "memoize-one";

import { ObjectArrayBinding, Binding, BindingShape } from "../../../../types";
import { isJsEvalEnabled } from "../../../common/CodeSandbox/CodeSandbox";
import { assertNever } from "../../../util/assertNever";
import { createPath, parsePath } from "../../../util/binding";
import findInputBindings from "../../util/findInputBindings";
import { find } from "../../util/tree";
import {
  getPseudoSpaceComponentPackages,
  findSpaceComponentPackage
} from "../SpaceContext/StableSpaceContext";

import { ComponentNode, isComponentNode, RootNode } from ".";

type _SchemaGenerator = (node: ComponentNode) => Binding[];

const DEFAULT_SCHEMA_GENERATOR = (n: ComponentNode) =>
  findSpaceComponentPackage(n.component.type).getSchema(n);

function _findInvalidInputBindings(
  source: ComponentNode,
  tree: RootNode,
  getSchema: _SchemaGenerator = DEFAULT_SCHEMA_GENERATOR
) {
  const bindings = findInputBindings(source.component.properties || {});
  return Array.from(bindings).reduce<string[]>((invalid, b) => {
    let parts: (string | number)[];
    try {
      parts = parsePath(b);
    } catch (e) {
      // if JS_EVAL is enabled, valid expressions will fail the attempt to parsePath on line 66
      if (!isJsEvalEnabled()) {
        invalid.push(b);
      }
      return invalid;
    }

    // `bound` is the node that provides the binding data. If component
    // `foo` has a binding to `bar.data.id`.  `source` will be `foo`'s
    // ComponentNode and `bar` is the `bound` Component node.
    // "card", "option" and "row" bindings are special and indicate that a
    // bindings `bound` node may be an ancestor or direct child of the
    // `source` node.
    let bound: ComponentNode | undefined;

    // Special case binding paths.
    const pseudoPackageTypes = getPseudoSpaceComponentPackages().map(pkg =>
      pkg.type.toLowerCase()
    );
    if (typeof parts[0] === "string" && pseudoPackageTypes.includes(parts[0])) {
      // Binding path relative to an ancestor row.
      for (
        let n: ComponentNode | RootNode | undefined = source;
        isComponentNode(n) && !bound;
        n = n.parent
      ) {
        if (n.component.type === parts[0].toUpperCase()) {
          bound = n;
          break;
        }
      }
      // Binding path is relative to the source node.
      if (!bound) {
        bound = source;
      }
    }
    // Self binding
    else if (
      parts[0] === source.component.type.toLowerCase() &&
      parts.filter(p => p === source.component.type.toLowerCase()).length === 1
    ) {
      bound = source;
    }
    // Binding path root may exist in the root of space or at some point up the
    // ancestor chain from the source node.
    else {
      bound = find(
        tree,
        n => n.component.slug === parts[0] && !("component" in n.parent)
      );
      if (bound === undefined) {
        for (
          let n: ComponentNode | RootNode | undefined = source;
          isComponentNode(n) && !bound;
          n = n.parent
        ) {
          if (n.component.slug === parts[0]) {
            bound = n;
            break;
          }
        }
      }
    }

    if (!bound || !validateBindingPath(bound, parts, getSchema)) {
      invalid.push(b);
    }
    return invalid;
  }, []);
}

// findInvalidInputBinding gets called repeatedly while validating a component
// so do a very simple memoization.
export const findInvalidInputBindings = memoizeOne(_findInvalidInputBindings);

export const validateBindingPath = (
  node: ComponentNode,
  pathParts: (string | number)[],
  getSchema: _SchemaGenerator = DEFAULT_SCHEMA_GENERATOR
) => {
  const _walk = (bindings: Binding[], parts: (string | number)[]): boolean => {
    parts.shift();
    if (!parts.length) return true;

    const binding = bindings.find(b => b.name === parts[0]);
    if (binding) {
      switch (binding.shape) {
        case BindingShape.OBJECT:
        case BindingShape.OBJECT_ARRAY:
          return _walk(binding.attributes, [...parts]);
        case BindingShape.SCALAR:
        case BindingShape.SCALAR_ARRAY:
        case BindingShape.UNKNOWN:
          return true; // TODO: what if there are more parts left
        default:
          return assertNever(binding);
      }
    } else {
      return false;
    }
  };

  const parts = [...pathParts];
  const schema = getSchema(node);

  let bindings: Binding[];
  if (node.component.type === "CARD_LIST" && parts[0] === "card") {
    const s = schema.find(b => b.name === "cards");
    if (!s) throw new Error("cards binding path does not exist on CARD_LIST");
    bindings = (s as ObjectArrayBinding).attributes;
  } else if (
    ["DROPDOWN", "TAG_SELECTOR"].includes(node.component.type) &&
    parts[0] === "option"
  ) {
    const s = schema.find(b => b.name === "options");
    if (!s) throw new Error("options binding path does not exist on DROPDOWN");
    bindings = (s as ObjectArrayBinding).attributes;
  } else if (
    node.component.type === "FUNCTION_BULK_IMPORT" &&
    parts[0] === "repeateditem"
  ) {
    const s = node.children.find(c => c.component.slug === parts[0]);
    if (!s)
      throw new Error(
        "repeated item binding path does not exist on FUNCTION_BULK_IMPORT"
      );
    bindings = getSchema(s);
  } else {
    bindings = schema;
  }
  return _walk(bindings, parts);
};

// hasParentPath returns true if parts exist that could resolve to a
// parent component node.
export const hasParentPath = (parts: (string | number)[]) =>
  (typeof parts[parts.length - 1] === "number" && parts.length > 2) ||
  (typeof parts[parts.length - 1] !== "number" && parts.length > 1);

// createParentPath creates a path to this components parent node and it
// handles the case when pathParts looks like this: ["table1", "rows", 0].
export const createParentPath = (parts: (string | number)[]) => {
  const copy = [...parts];
  if (typeof copy[copy.length - 1] === "number") {
    copy.pop();
  }
  copy.pop();
  return createPath(copy);
};
