import { FetchDataProvidersAndBaseFunctionsData } from "../../../../graphql/queries";
import { isSupported } from "../support";

export const selectSupportedDataSources = (
  data: FetchDataProvidersAndBaseFunctionsData | undefined
) => {
  return (data?.allDataSourceProviders?.edges || [])
    .flatMap(e => e.node.dataSources?.edges.map(e => e.node) || [])
    .filter(e => isSupported(e.integration));
};
