import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Select } from "antd";

import { FunctionAuthorizationFlowInput } from "../../../../types";
import { AuthorizationFlowNode } from "../../../settings/AuthProviders/types";
import { AuthorizationFlow } from "../../../spaces/SpaceRoot/AuthorizationContext/AuthorizationContext";
import { LOGOUT_MUTATION } from "../../../spaces/SpaceRoot/AuthorizationContext/queries";
import { useEnvironmentContext } from "../../contexts/EnvironmentContext";
import Message from "../../Message";
import { OnAuthorizationFlowChangeCallback } from "../index";

import * as styled from "./styledComponents";
import { useAuthorizationFlows } from "./useAuthorizationFlows";

interface Props {
  authorizationFlows: FunctionAuthorizationFlowInput[];
  environmentIdsWithCredentials: { [id: string]: true };
  onAuthorizationFlowChange?: OnAuthorizationFlowChangeCallback;
}

export default function FunctionAuthorizationFlows({
  authorizationFlows,
  environmentIdsWithCredentials,
  onAuthorizationFlowChange = () => {}
}: Props) {
  const { visibleEnvironments: environments } = useEnvironmentContext();
  const { authorizationFlows: flows, refetch: refetchAuthorizationFlows } =
    useAuthorizationFlows();
  const [environmentId, setEnvironmentId] = useState<string>();
  const [flow, setFlow] = useState<AuthorizationFlowNode>({} as AuthorizationFlowNode);
  const [showModal, setShowModal] = useState(false);
  const [logout, { loading }] = useMutation<
    { ok: boolean },
    { id: string; environmentId: string }
  >(LOGOUT_MUTATION, {
    onCompleted: () => refetchAuthorizationFlows(),
    onError: () => Message.error("Something went wrong. Please try again!")
  });

  const onChange = (environmentId: string, authorizationFlowId: string | undefined) => {
    onAuthorizationFlowChange({ environmentId, authorizationFlowId });
  };

  const envIdToFlowId = new Map(
    authorizationFlows.map(flow => [flow.environmentId, flow.authorizationFlowId])
  );

  const flowIdToFlow = React.useMemo(
    () => new Map(flows.map(flow => [flow.id, flow])),
    [flows]
  );

  const getAuthorizationFlow = (environmentId: string) => {
    const flowId = envIdToFlowId.get(environmentId);
    if (flowId) {
      return flowIdToFlow.get(flowId);
    }
  };

  const isLoggedIn = (environmentId: string) => {
    const edge = getAuthorizationFlow(
      environmentId
    )?.environmentsWithState.edges.filter(edge => edge.node.id === environmentId);
    return edge ? edge.length > 0 : false;
  };

  return (
    <>
      <div>
        <styled.Table>
          <thead>
            <tr>
              <th>Environment</th>
              <th>Auth Provider</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {environments.map(e => (
              <tr key={e.id}>
                <td>
                  {e.name}
                  {environmentIdsWithCredentials[e.id] && "*"}
                </td>
                <td>
                  <styled.Select
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    placeholder="None"
                    defaultValue={envIdToFlowId.get(e.id)}
                    onChange={value => onChange(e.id, value as string | undefined)}
                    allowClear
                  >
                    {flows.map(p => (
                      <Select.Option key={p.id}>{p.name}</Select.Option>
                    ))}
                  </styled.Select>
                </td>
                <td>
                  <Button
                    onClick={event => {
                      setEnvironmentId(e.id);
                      const flow = getAuthorizationFlow(e.id);
                      const loggedIn = isLoggedIn(e.id);
                      if (flow) {
                        setFlow(flow);
                        if (loggedIn) {
                          logout({ variables: { id: flow.id, environmentId: e.id } });
                        }
                      }
                      setShowModal(!loggedIn);
                      event?.currentTarget.blur();
                    }}
                    loading={loading || showModal}
                    disabled={getAuthorizationFlow(e.id) === undefined}
                    type={isLoggedIn(e.id) ? "default" : "primary"}
                  >
                    {isLoggedIn(e.id) ? "Logout" : "Login"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </styled.Table>
        {Object.keys(environmentIdsWithCredentials).length > 0 && (
          <div>
            * = These environments will also include credentials set on the data source.
          </div>
        )}
      </div>

      <Modal
        title="Authorization"
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        closable={false}
      >
        {showModal && (
          <AuthorizationFlow
            flow={flow}
            environmentId={environmentId}
            onComplete={() => {
              setShowModal(false);
              refetchAuthorizationFlows();
            }}
            displayText={<h2>{flow.name} login</h2>}
          />
        )}
      </Modal>
    </>
  );
}
