import Icon from "../../../../../assets/spaceicons/s3.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import Config, { ensureUploadComponent } from "./Config";
import { errorSelector } from "./Config/errorSelector";
import SpaceS3Uploader from "./SpaceS3Uploader";
import {
  SpaceS3UploaderComponent,
  S3UploaderComponentConfigState,
  SpaceS3UploaderProperties
} from "./types";

const pkg: SpaceComponentPackage<
  SpaceS3UploaderProperties,
  SpaceS3UploaderComponent,
  S3UploaderComponentConfigState
> = {
  type: "S3_UPLOADER",
  displayName: "S3 Uploader",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "HEADER",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FLEX_BOX"
  ]),
  Component: SpaceS3Uploader,
  ConfigurationComponent: Config,
  ensureComponent: ensureUploadComponent,
  componentConfigReducer: commonComponentReducer,
  defaultElementLayout: new ElementLayout({
    width: "auto",
    height: "auto",
    snapToGrid: false
  }),
  fallbackWidth: 80,
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
