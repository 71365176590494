import { assertNever } from "../../../../../../util/assertNever";

export enum FormEncoding {
  URL = "x-www-form-urlencoded",
  MULTIPART = "multipart/form-data"
}

export enum FieldType {
  FILE = "file",
  RAW = "raw"
}

export interface Field {
  key: string;
  value: string;
  type: FieldType;
}

export const serializeMultipartField = (field: Field) =>
  field.type === FieldType.FILE
    ? `{"header":{"Content-Disposition": "form-data; name=\\""+${field.key}+"\\";filename=\\""+${field.value}.name+"\\";"},"binary_body":${field.value}.data}`
    : `{"header":{"Content-Disposition": "form-data; name=\\""+${field.key}+"\\";"},"raw_body":${field.value}}`;

export const serializeMultipart = (fields: Field[]) =>
  '{"type":"form-data","parts":[' +
  fields.map(f => serializeMultipartField(f)).join(",") +
  "]}";

export const serializeUrl = (fields: Field[]) =>
  "{" + fields.map(f => `[${f.key}]: ${f.value}`).join(",") + "}";

export const serialize = (encoding: FormEncoding, fields: Field[]) => {
  switch (encoding) {
    case FormEncoding.MULTIPART:
      return serializeMultipart(fields);
    case FormEncoding.URL:
      return serializeUrl(fields);
    default:
      return assertNever(encoding);
  }
};
