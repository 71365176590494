import React, { ReactNode } from "react";

import * as AntForm from "antd/lib/form/Form";
import styled from "styled-components";

import { AttributeTypes } from "../../../constants";
import { AttributeNodeTypes } from "../../../types";
import { InputParameterOptions } from "../../spaces/SpaceRoot/SpaceComponent/common/useFuncParams";
import { assertNever } from "../../util/assertNever";
import { FieldValidationProps, VALIDATION_MESSAGES } from "../../util/ClientValidator";
import withDebouncedValue from "../withDebouncedValue";

import BinaryInput from "./BinaryInput";
import BooleanInput from "./BooleanInput";
import { FieldType } from "./constants";
import DateInput from "./DateInput";
import DateTimeInput from "./DateTimeInput";
import FileInput from "./FileInput";
import makeFormAdapter from "./formAdapter";
import JsonInput from "./JsonInput";
import NumberInput from "./NumberInput";
import StringInput from "./StringInput";
import TimeInput from "./TimeInput";
import TimestampInput from "./TimestampInput";

interface PartialAttributeValueInputProps {
  sourceType: AttributeNodeTypes;
  sourceName: string;
  sourceNullable?: boolean;
  className?: string;
  value?: any;
  form?: AntForm.WrappedFormUtils<any>;
  placeholder?: string;
  displayNullPlaceholder?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label?: ReactNode;
  useSimpleJsonInput?: boolean;
  preventOverflow?: boolean /* used for any popper fields, ie. DateTimePicker */;
  rows?: number;
  generated?: boolean;
  verbose?: boolean;
  options?: InputParameterOptions[];
  fieldType?: FieldType;
  getFieldValue?: (field: string) => any;
  addFieldTouched?: (field: string) => void;
  onChange?: (value: any) => void;
  onFocus?: (evt: any) => void;
  onBlur?: (evt: any) => void;
  onValidate?: (valid: boolean) => void;
}

export interface AttributeValueInputProps extends PartialAttributeValueInputProps {
  fieldOptions?: { validateTrigger: string; rules: AntForm.ValidationRule[] };
}

export interface AttributeInputProps
  extends FieldValidationProps,
    PartialAttributeValueInputProps {}

export const requiredValidator = (_rule: any, value: any, cb: (ob?: {}) => any) => {
  if (!!value) {
    cb();
  } else {
    cb({ error: true });
  }
};

function SourceTypeInput(props: AttributeValueInputProps) {
  switch (props.sourceType) {
    case AttributeTypes.BINARY: {
      return <BinaryInput {...props} />;
    }
    case AttributeTypes.BOOL: {
      return <BooleanInput {...props} />;
    }
    case AttributeTypes.TIMESTAMP: {
      return <TimestampInput {...props} />;
    }
    case AttributeTypes.DATETIME: {
      return <DateTimeInput {...props} />;
    }
    case AttributeTypes.TIME: {
      return <TimeInput {...props} />;
    }
    case AttributeTypes.DATE: {
      return <DateInput {...props} />;
    }
    case AttributeTypes.FILE: {
      return <FileInput {...props} />;
    }
    case AttributeTypes.JSON: {
      return <JsonInput {...props} />;
    }
    case AttributeTypes.INT:
    case AttributeTypes.DECIMAL:
    case AttributeTypes.FLOAT: {
      return <NumberInput {...props} />;
    }
    case AttributeTypes.STRING: {
      return <StringInput {...props} />;
    }
    default:
      return assertNever(props.sourceType);
  }
}

const Root = styled.div`
  width: 100%;
`;

const AttributeInput = (props: AttributeInputProps) => {
  const { validationRules = [], required = false, ...rest } = props;
  const rules = validationRules.concat([]);

  if (required) {
    rules.push({
      validator: requiredValidator,
      required: true,
      message: VALIDATION_MESSAGES.requiredField
    });
  }

  const fieldOptions = { validateTrigger: "onSubmit", rules };
  const inputProps = {
    ...rest,
    form: rest.form ? rest.form : makeFormAdapter({ ...props, fieldOptions }),
    fieldOptions
  };

  return (
    <Root>
      <SourceTypeInput {...inputProps} />
    </Root>
  );
};

export default AttributeInput;

export const DebouncedAttributeInput = withDebouncedValue(AttributeInput);
