import { useMemo } from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  DataSourceNode,
  ResourceNode,
  Connection,
  FunctionNode
} from "../../../../../../types";

const PRESIGN_PUT_FUNCTION_NAME = "presigned.put_object";

// One of the few remaining usages of "resources". Before we can remove it,
// we need a new way to list buckets, in lieu of using resource source_names
export const GET_S3_FUNCTIONS = gql`
  {
    allDataSources(integration: "s3") {
      edges {
        node {
          id
          name
          functions(scope: "wrapped") {
            edges {
              node {
                id
                name
              }
            }
          }
          resources {
            edges {
              node {
                id
                sourceName
              }
            }
          }
        }
      }
    }
  }
`;

export default function useS3Buckets(): [ResourceNode, FunctionNode][] {
  const { data } = useQuery<{
    allDataSources: Connection<DataSourceNode>;
  }>(GET_S3_FUNCTIONS);

  return useMemo(() => {
    if (!data) return [];
    const dataSources = data.allDataSources.edges.map(e => e.node);
    return dataSources.flatMap(ds =>
      ds.resources!.edges.map(r => [
        r.node,
        ds.functions!.edges.find(f => f.node.name === PRESIGN_PUT_FUNCTION_NAME)!.node
      ])
    ) as [ResourceNode, FunctionNode][];
  }, [data]);
}
