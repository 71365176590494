import React from "react";

import classNames from "classnames";
import { components, ControlProps, OptionProps } from "react-select";
import Select from "react-windowed-select";

import { colors, colorTokens, zIndex } from "../../../../../cssConstants";
import { DEFAULT_STATE_COLOR } from "../../../Config/reducer/utils";

import * as Styled from "./styledComponents";

export interface Option {
  label: string;
  value: string;
  color: string;
}

const SelectOption = (props: OptionProps<Option, false>) => {
  const { className, innerRef, innerProps, isFocused, isSelected, data } = props;

  return (
    <Styled.OptionContainer
      className={classNames({ className, isSelected, isFocused })}
      ref={innerRef}
      {...innerProps}
    >
      <Styled.Dot color={data.color} />
      <div>{data.label}</div>
    </Styled.OptionContainer>
  );
};

const SelectControl = ({ children, ...props }: ControlProps<Option, false>) => {
  const data: Option | undefined = props.selectProps.value as Option;
  return (
    <components.Control {...props}>
      {data?.color && <Styled.Dot color={data?.color || DEFAULT_STATE_COLOR} />}
      {children}
    </components.Control>
  );
};

export interface Props {
  className?: string;
  options: Option[];
  value?: Option;
  animateVisibility?: boolean;
  onSelected: (option: Option) => void;
}

export function StateSelect({
  className,
  options,
  value,
  animateVisibility = true,
  onSelected
}: Props) {
  const selectValue = value === undefined ? null : value;

  return (
    <Select
      className={className}
      options={options}
      value={selectValue}
      placeholder="Select a state"
      onChange={onSelected}
      isClearable={false}
      isSearchable={true}
      menuPortalTarget={document.getElementById("select-portal")}
      components={{
        Option: SelectOption,
        Control: SelectControl
      }}
      styles={{
        control: (provided: Record<string, any>) => ({
          ...provided,
          transition: animateVisibility
            ? provided.transition
            : "all 100ms, visibility 0s",
          "&:hover": {
            borderColor: colorTokens.purple700
          },
          //11px is specific to match the option color dot padding
          paddingLeft: !!value ? "11px" : "initial"
        }),
        dropdownIndicator: (provided: Record<string, any>) => ({
          ...provided,
          color: colors.dropdownIndicator
        }),
        menu: (provided: Record<string, any>) => ({
          ...provided,
          zIndex: zIndex.hoverItem
        }),
        indicatorSeparator: (provided: Record<string, any>) => ({
          ...provided,
          display: "none"
        })
      }}
      theme={(provided: Record<string, any>) => {
        return {
          ...provided,
          colors: {
            ...provided.colors,
            primary25: colorTokens.purpleTransparent400,
            primary50: colorTokens.purpleTransparent400,
            primary75: colorTokens.purpleTransparent400,
            primary: colorTokens.purple700,
            danger: colorTokens.red300
          }
        };
      }}
    />
  );
}
