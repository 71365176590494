import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useNavigate } from "react-router-dom";

import usePaths from "../../common/hooks/usePaths";
import ThemeContainer, { Theme } from "../../common/ThemeContainer";

import { Fields } from "./Field";
import {
  CreateDataSourceProviderResult,
  CreateDataSourceProviderVariables,
  CREATE_DATA_SOURCE_PROVIDER,
  CreateDataSourceResult,
  CreateDataSourceVariables,
  CREATE_DATA_SOURCE
} from "./queries";
import { ObjectSchema } from "./schema";
import { ErrorMessage, FormItem } from "./styledComponents";
import { applyAdditional } from "./util";

export interface Props extends FormComponentProps {
  integration: string;
  schema: ObjectSchema;
  displayName?: boolean;
  help?: React.ReactNode;
}

const CredentialsForm = ({ form, integration, schema, displayName, help }: Props) => {
  const { getSelectDataSource, getDashboard } = usePaths();
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");

  const [createDataSource, { loading: loadingDataSource }] = useMutation<
    CreateDataSourceResult,
    CreateDataSourceVariables
  >(CREATE_DATA_SOURCE, {
    onCompleted: data => {
      const { createDefaultEnvironmentDataSource } = data;
      switch (createDefaultEnvironmentDataSource.__typename) {
        case "ClientErrorResult":
          setError(createDefaultEnvironmentDataSource.message);
          break;
        case "CreateDefaultEnvironmentDataSourceResultSuccess": {
          navigate(getDashboard());
          break;
        }
      }
    },
    onError: () => {
      setError("An unknown error occurred.");
    }
  });
  const [createDataSourceProvider, { loading: loadingDataSourceProvider }] =
    useMutation<CreateDataSourceProviderResult, CreateDataSourceProviderVariables>(
      CREATE_DATA_SOURCE_PROVIDER,
      {
        onCompleted: data => {
          const { createDataSourceProvider } = data;
          switch (createDataSourceProvider.__typename) {
            case "ClientErrorResult":
              setError(createDataSourceProvider.message);
              break;
            case "CreateDataSourceProviderResultSuccess": {
              const { dataSourceProvider } = createDataSourceProvider;
              const available = dataSourceProvider.availableDataSources;
              if (displayName && available.length === 1) {
                createDataSource({
                  variables: {
                    dataSourceProviderId: dataSourceProvider.id,
                    dataSourceName: available[0].sourceName,
                    name: form.getFieldValue("display_name")
                  }
                });
              } else {
                navigate(getSelectDataSource(dataSourceProvider.id));
              }
            }
          }
        },
        onError: () => {
          setError("An unknown error occurred.");
        }
      }
    );

  // When displayName is enabled it will be shown as the first input and allow
  // the user to specify a data source name. If not enabled, or the credentials
  // have multiple data sources available, the user will be redirected to the
  // "Select Data Source" screen which shows a list of data sources to add.
  //
  // This should only be enabled when a data source provider is guaranteed to
  // return a single available data source (ex: postgresql).
  if (displayName) {
    schema = Object.assign({}, schema);
    schema.properties = Object.assign(
      {
        display_name: {
          type: "string",
          minLength: 1,
          title: "Display Name",
          default: schema.title
        }
      },
      schema.properties
    );
    schema.required = ["display_name"].concat(schema.required || []);
  }

  const loading = loadingDataSource || loadingDataSourceProvider;

  return (
    <ThemeContainer theme={Theme.Dark}>
      <Form
        hideRequiredMark
        autoComplete="off"
        layout="vertical"
        onSubmit={e => {
          e.preventDefault();
          if (loading) {
            return;
          }

          setError("");
          form.validateFields((errors, credentials) => {
            if (!errors) {
              credentials = { ...credentials };
              delete credentials.display_name;

              // rc-form (used by Ant 3.x forms) has issues which prevent adding
              // and removing fields from working correctly when an initialValue
              // is present. We work around that by adding a single hidden
              // "null" field set to `true` when the field is disabled.
              for (const key of Object.keys(credentials)) {
                if (credentials[key]["null"]) {
                  credentials[key] = null;
                }
              }

              createDataSourceProvider({
                variables: {
                  integration,
                  credentials: applyAdditional(credentials)
                }
              });
            }
          });
        }}
      >
        <Fields form={form} schema={schema} disabled={loading} />
        {help}
        <FormItem>
          <Button htmlType="submit" type="primary" loading={loading}>
            Connect to {schema.title}
          </Button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FormItem>
      </Form>
    </ThemeContainer>
  );
};

export default Form.create<Props>({ name: "credentials-form" })(CredentialsForm);
