import React from "react";

import AmazonFieldSet from "../setup_flow/common/AmazonFieldSet";
import ApiKeyField from "../setup_flow/common/ApiKeyField";
import DatabaseCommonFields from "../setup_flow/common/DatabaseFieldSet";
import {
  BigqueryFields,
  FirestoreFields
} from "../setup_flow/common/GoogleServiceAccountFieldSet";
import HttpFieldSet from "../setup_flow/common/HttpFieldSet";
import MongoForm from "../setup_flow/common/MongoFields";
import SalesForceDataSourceFieldSet from "../setup_flow/common/SalesforceFieldSet";
import SnowflakeFields from "../setup_flow/common/SnowflakeFields";
import ZendeskFields from "../setup_flow/common/ZendeskFields";

export const CredentialsUpdateForms: Record<string, React.ComponentType<any>> = {
  bigquery: BigqueryFields,
  dynamodb: AmazonFieldSet,
  firestore: FirestoreFields,
  http: HttpFieldSet,
  hubspot: ApiKeyField,
  mailgun: ApiKeyField,
  mongo: MongoForm,
  mssql: DatabaseCommonFields,
  mysql: DatabaseCommonFields,
  postgresql: DatabaseCommonFields,
  s3: AmazonFieldSet,
  salesforce: SalesForceDataSourceFieldSet,
  snowflake: SnowflakeFields,
  stripe: ApiKeyField,
  zendesk: ZendeskFields
};
