import styled from "styled-components";

import TaskDrawerBase from "./TaskDrawer/TaskDrawer";

export const QueueRoot = styled.div`
  display: flex;
  align-items: stretch;
`;

export const QueueMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 72%;
  height: 100%;
  overflow: hidden;
`;

export const TaskDrawer = styled(TaskDrawerBase)`
  flex-grow: 1;
  flex-basis: 28%;
  border-left: solid 1px #e6e6e6;
`;

export const TableContainer = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  justify-self: stretch;
  overflow: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
