import React from "react";

import { Checkbox, Col, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import classNames from "classnames";
import { useQuery } from "react-apollo";

import { RoleNode } from "../../../../../types";
import Message from "../../../../common/Message";
import { useFunctionPolicies } from "../../../../common/Permissions/hooks/useFunctionPolicies";
import {
  FunctionParameterRow,
  getFunctionParameterRow,
  NO_DISABLE_PERMISSIONS_FOR_ADMIN,
  PermissionsPaginator
} from "../../../../common/Permissions/permissions";
import {
  FUNCTIONS_FOR_IDS,
  useUpdateRolePermissions
} from "../../../../common/Permissions/queries";
import {
  FunctionPolicyActionTypes,
  PolicySpaceFunctions,
  PolicySpaceReducerAction
} from "../../../../common/Permissions/reducer";
import {
  ButtonContainer,
  FlexRow,
  FunctionH5,
  PermissionsTable,
  PermissionsHeader,
  RoleHeader,
  RoleListItem,
  ShareWithRoleName,
  StyledButtonSecondary,
  StyledHeaderButton,
  StyledInternalEmpty,
  StyledTableRow,
  StyledTableRowHeader,
  RightCol,
  AutoGrantAllHeaderSwitch,
  HelpIcon,
  RolePermissionsRoot
} from "../../../../common/Permissions/styledComponents";
import UnsavedChangesModal, {
  Action
} from "../../../../common/Permissions/UnsavedChangesModal/UnsavedChangedModal";
import {
  functionNodesToUpdateRolePermissions,
  isAdmin
} from "../../../../common/Permissions/util";
import { B3, C2 } from "../../../../common/StyledComponents";
import { StyledLinkButtonNew } from "../../../../spaces/Home/SpaceDetail/styledComponents";
import { fromGlobalId, toGlobalId } from "../../../../util/graphql";

import {
  QueueWithFunctionsData,
  QueueWithFunctionsVariables,
  QUEUE_BY_SLUG_WITH_FUNCTIONS
} from "./queries";

export interface QueueFunctionsArguments {
  queueSlug: string;
  roleId?: string;
}

export function queueFunctionIdToConsoleFunctionId(id: string): string {
  const consoleFunctionId = fromGlobalId(id)[1];
  return toGlobalId("FunctionNode", consoleFunctionId);
}

interface QueueRolePermissionsProps {
  queueName: string;
  queueSlug: string;
  className?: string;
  roles: RoleNode[];
  onCancel: () => void;
}

interface QueueRolePermissionsExpandedProps {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  queueName: string;
  state: PolicySpaceFunctions;
  role: RoleNode;
  loading: boolean;
  dispatch: React.Dispatch<PolicySpaceReducerAction>;
}

export function QueueRolePermissionsExpanded({
  page,
  pageSize,
  setPage,
  queueName,
  state,
  role,
  loading,
  dispatch
}: QueueRolePermissionsExpandedProps) {
  const roleIsAdmin = isAdmin(role);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  const dataSource = React.useMemo(() => {
    return state.functions.map(func =>
      getFunctionParameterRow(
        func,
        action => dispatch(action),
        expandedRowKeys,
        roleIsAdmin
      )
    );
  }, [state.functions, dispatch, expandedRowKeys, roleIsAdmin]);

  return (
    <>
      <PermissionsHeader>
        <FunctionH5>Functions for {queueName}</FunctionH5>
        <C2>Changes to function permissions are global</C2>
      </PermissionsHeader>
      <PermissionsTable
        data-test="spaceRowPermissionContent"
        dataSource={dataSource}
        pagination={false}
        indentSize={0}
        components={{
          header: {
            row: StyledTableRow,
            cell: StyledTableRowHeader
          },
          body: {
            row: StyledTableRow
          }
        }}
        locale={{
          emptyText: <StyledInternalEmpty content="No functions to display" />
        }}
        onRow={record => {
          const functionRow = record as FunctionParameterRow;
          return {
            onClick: () => {
              // Don't expand beyond top level
              if (functionRow.level > 0) {
                return;
              }

              const expanded = expandedRowKeys.includes(functionRow.id);
              if (expanded) {
                setExpandedRowKeys([]);
              } else if (functionRow.fieldsCount) {
                // Only if it has fields, expand.
                setExpandedRowKeys([functionRow.id]);
              }
            }
          };
        }}
        loading={loading}
        expandedRowKeys={expandedRowKeys}
        expandIcon={() => <></>}
        rowKey="id"
        rowClassName={record => {
          const element = record as { id: string };
          return expandedRowKeys.includes(element.id) ? "expanded" : "";
        }}
      >
        <Column
          title="Function"
          dataIndex="name"
          width="40%"
          render={text => <B3>{text}</B3>}
        />
        <Column
          className="full-width"
          title={() => (
            <FlexRow type="flex" justify="space-between">
              <Col>
                <Tooltip
                  title={roleIsAdmin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}
                >
                  <Checkbox
                    data-test="allPermissionsCheckBox"
                    checked={roleIsAdmin || state.fieldsEnabled === "all"}
                    disabled={roleIsAdmin}
                    indeterminate={state.fieldsEnabled === "some"}
                    onClick={() =>
                      dispatch({
                        type: FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE
                      })
                    }
                  >
                    Parameters and attributes
                  </Checkbox>
                </Tooltip>
              </Col>
              <RightCol>
                Auto-grant all
                <Tooltip title="Turning Auto-grant all on will enable access to all parameters and attributes (current and future)">
                  <HelpIcon type="question-circle" />
                </Tooltip>
                <Tooltip
                  title={roleIsAdmin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}
                >
                  <AutoGrantAllHeaderSwitch
                    data-test="autoGrantToggle"
                    disabled={roleIsAdmin}
                    checked={roleIsAdmin || state.autoGrant === "all"}
                    indeterminate={state.autoGrant === "some"}
                    onClick={() =>
                      dispatch({
                        type: FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE,
                        payload: {
                          allowAll: state.autoGrant !== "all"
                        }
                      })
                    }
                  >
                    Parameters and attributes
                  </AutoGrantAllHeaderSwitch>
                </Tooltip>
              </RightCol>
            </FlexRow>
          )}
          dataIndex="permission"
        />
      </PermissionsTable>
      {!loading && state.totalCount !== 0 && (
        <PermissionsPaginator
          page={page}
          pageSize={pageSize}
          total={state.totalCount}
          onPageChange={page => setPage(page)}
        />
      )}
    </>
  );
}

export function QueueRolePermissions({
  queueName,
  queueSlug,
  roles,
  onCancel
}: QueueRolePermissionsProps) {
  const [page, setPage] = React.useState(1);
  const pageSize = 15;

  const [expandedRole, setExpandedRole] = React.useState<RoleNode | undefined>();

  const {
    data,
    loading: queueLoading,
    error: queueError
  } = useQuery<QueueWithFunctionsData, QueueWithFunctionsVariables>(
    QUEUE_BY_SLUG_WITH_FUNCTIONS,
    {
      variables: {
        slug: queueSlug
      }
    }
  );

  const functionIds = React.useMemo(() => {
    return (
      data?.queueBySlug.functions.map(f => queueFunctionIdToConsoleFunctionId(f.id)) ||
      []
    );
  }, [data]);

  const {
    state,
    error: policiesError,
    loading: policiesLoading,
    dispatch
  } = useFunctionPolicies({
    roleId: expandedRole?.id,
    functionIds
  });

  const [updateRolePermissions, updateRolePermissionStats] = useUpdateRolePermissions({
    onCompleted: () => {
      Message.success("Permissions updated");
      dispatch({ type: FunctionPolicyActionTypes.COMMIT });
    },
    refetchQueries: options => {
      const roleId = options.data.updateRolePermissions.role.id;

      return [
        {
          query: FUNCTIONS_FOR_IDS,
          variables: {
            ids: functionIds,
            roleId
          }
        }
      ];
    }
  });

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = React.useState<{
    show: boolean;
    nextRole?: RoleNode;
    quit?: boolean;
  }>({ show: false });

  React.useEffect(() => {
    if (queueError || policiesError)
      Message.error("Something went wrong. Please try again.");
  }, [queueError, policiesError]);

  return (
    <RolePermissionsRoot>
      {roles.map(role => {
        const expanded = expandedRole?.id === role.id;
        return (
          <RoleListItem key={role.id} className={classNames({ expanded })}>
            <RoleHeader
              className={classNames({ expanded })}
              onClick={() => {
                if (role.id === expandedRole?.id) {
                  return;
                }

                if (state.modified) {
                  setShowUnsavedChangesModal({ show: true, nextRole: role });
                } else {
                  setExpandedRole(role);
                }
              }}
            >
              <ShareWithRoleName>
                <B3>{role.name}</B3>
              </ShareWithRoleName>
              {!expanded && (
                <StyledLinkButtonNew
                  className="permissionsLink"
                  onClick={() => {
                    if (role.id === expandedRole?.id) {
                      return;
                    }

                    if (state.modified) {
                      setShowUnsavedChangesModal({ show: true, nextRole: role });
                    } else {
                      setExpandedRole(role);
                    }
                  }}
                >
                  Function Permissions
                </StyledLinkButtonNew>
              )}
              {expanded && (
                <ButtonContainer>
                  <StyledButtonSecondary
                    type="secondary"
                    size="lg"
                    onClick={event => {
                      event.stopPropagation();
                      setExpandedRole(undefined);
                    }}
                  >
                    {state.modified ? "Cancel" : "Close"}
                  </StyledButtonSecondary>
                  <Tooltip title={!state.modified ? "No changes to save" : undefined}>
                    {/* Add "fake" disabling styling because Tooltips don't play nicely with disabled buttons */}
                    <StyledHeaderButton
                      type="primary"
                      className={classNames({
                        fakeDisabled: !state.modified
                      })}
                      size="lg"
                      loading={updateRolePermissionStats.loading}
                      onClick={() => {
                        if (state.modified) {
                          const data = functionNodesToUpdateRolePermissions(
                            role.id,
                            role.name,
                            state.current.functions,
                            role.scopes
                          );
                          updateRolePermissions({ variables: data });
                        }
                      }}
                    >
                      Save
                    </StyledHeaderButton>
                  </Tooltip>
                </ButtonContainer>
              )}
            </RoleHeader>
            {expanded && (
              <QueueRolePermissionsExpanded
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                queueName={queueName}
                loading={policiesLoading || queueLoading}
                dispatch={dispatch}
                role={role}
                state={state.current}
              />
            )}
          </RoleListItem>
        );
      })}
      <UnsavedChangesModal
        visible={showUnsavedChangesModal.show}
        onClick={async (action: Action) => {
          switch (action) {
            case Action.Cancel: {
              setShowUnsavedChangesModal({ show: false });
              break;
            }
            case Action.Discard: {
              dispatch({ type: FunctionPolicyActionTypes.RESET });
              setShowUnsavedChangesModal({ show: false });

              if (showUnsavedChangesModal.nextRole) {
                setExpandedRole(showUnsavedChangesModal.nextRole);
              } else if (!showUnsavedChangesModal.quit) {
                setExpandedRole(undefined);
              } else if (showUnsavedChangesModal.quit) {
                onCancel();
              }
              break;
            }
            case Action.Save: {
              if (!expandedRole) {
                return;
              }

              const data = functionNodesToUpdateRolePermissions(
                expandedRole!.id,
                expandedRole!.name,
                state.current.functions,
                expandedRole!.scopes
              );

              await updateRolePermissions({ variables: data });
              setShowUnsavedChangesModal({ show: false });

              if (showUnsavedChangesModal.nextRole) {
                setExpandedRole(showUnsavedChangesModal.nextRole);
              } else if (showUnsavedChangesModal.quit) {
                onCancel();
              }
              break;
            }
          }
        }}
      />
    </RolePermissionsRoot>
  );
}
