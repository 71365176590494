import React from "react";

import styled from "styled-components";

import hashFnv32a from "../../../../../util/hashFnv32a";

import EmptyState01 from "./assets/1.svg";
import EmptyState10 from "./assets/10.svg";
import EmptyState11 from "./assets/11.svg";
import EmptyState12 from "./assets/12.svg";
import EmptyState13 from "./assets/13.svg";
import EmptyState14 from "./assets/14.svg";
import EmptyState15 from "./assets/15.svg";
import EmptyState16 from "./assets/16.svg";
import EmptyState17 from "./assets/17.svg";
import EmptyState18 from "./assets/18.svg";
import EmptyState19 from "./assets/19.svg";
import EmptyState02 from "./assets/2.svg";
import EmptyState05 from "./assets/20.svg";
import EmptyState03 from "./assets/3.svg";
import EmptyState04 from "./assets/4.svg";
import EmptyState06 from "./assets/6.svg";
import EmptyState07 from "./assets/7.svg";
import EmptyState08 from "./assets/8.svg";
import EmptyState09 from "./assets/9.svg";

const Root = styled.main`
  overflow: hidden;
  aspect-ratio: 1.5;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
`;

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  height: 100%;
  min-height: 100px;

  &:after {
    content: " ";
    position: absolute;
    display: block;
    z-index: 1;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);
  }
`;

const SvgContainer = styled.div`
  z-index: 0;
  svg {
    margin-top: 5%;
    height: 68%;
    max-width: 85%;
    opacity: 0.75;
    border-radius: 50%;
  }
`;

const MessageContainer = styled.div`
  position: absolute;
  left: 10%;
  right: 10%;
  bottom: 25%;
`;

const TextContainer = styled.div``;

const Message = styled.h2`
  display: inline-block;
  width: 70%;
  max-width: 600px;
  margin-bottom: 0;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.86);
  color: ${props => props.theme.textColorMid};
`;

const Details = styled.div`
  display: inline-block;
  color: ${props => props.theme.textColor};
  width: 70%;
  max-width: 600px;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.86);
`;

const InlineMessage = styled.div`
  color: ${props => props.theme.textColorMid};
  text-align: center;
  font-size: ${props => props.theme.smallFontSize};
`;

const IMAGES = [
  EmptyState01,
  EmptyState02,
  EmptyState03,
  EmptyState04,
  EmptyState05,
  EmptyState06,
  EmptyState07,
  EmptyState08,
  EmptyState09,
  EmptyState10,
  EmptyState11,
  EmptyState12,
  EmptyState13,
  EmptyState14,
  EmptyState15,
  EmptyState16,
  EmptyState17,
  EmptyState18,
  EmptyState19
];

export default function EmptyState({
  message = "Select data to display",
  inline = false,
  details,
  id
}: {
  message?: string;
  inline?: boolean;
  details?: string;
  id: string;
}) {
  const hash = hashFnv32a(id) as number;
  const [imageIndex, setImageIndex] = React.useState(hash % IMAGES.length);
  const getRandomIndex = () => Math.floor(Math.random() * IMAGES.length);
  const ImageSvg = IMAGES[imageIndex];
  const imageContainerEl = React.useRef<HTMLDivElement>(null);

  if (inline) return <InlineMessage>{message}</InlineMessage>;

  return (
    <Root>
      <ImageContainer ref={imageContainerEl}>
        <SvgContainer>
          <ImageSvg />
        </SvgContainer>
        <MessageContainer>
          <TextContainer>
            <Message>{message}</Message>
          </TextContainer>
          {details && (
            <TextContainer>
              <Details>{details}</Details>
            </TextContainer>
          )}
        </MessageContainer>
      </ImageContainer>
      {window.FEATURE_FLAGS.includes("EMPTY_STATES") && (
        <span onClick={() => setImageIndex(getRandomIndex())}>test</span>
      )}
    </Root>
  );
}
