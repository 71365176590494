import React from "react";

import { Spin } from "antd";
import styled from "styled-components";

import { colors } from "../../../../../cssConstants";
import { B3 } from "../../../../common/StyledComponents";

export enum TabOption {
  Comments = "comments",
  Details = "details"
}

const Pill = styled.span`
  font-size: 12px;
  color: ${colors.surfaceSecondary};
  border-radius: 50rem;
  background-color: ${colors.newContainerPrimaryTint};
  padding: 3px 6px;
  font-weight: 500;
  margin-left: 4px;
`;

const StyledSpin = styled(Spin)`
  margin-left: 8px;
`;

const Tabs = styled.div`
  display: flex;
`;

interface TabProps {
  active: boolean;
}

const Tab = styled(B3)<TabProps>`
  cursor: pointer;
  flex-grow: 1;
  width: 100%;
  text-align: center;
  border-bottom: ${props =>
    props.active
      ? "2px solid " + props.theme.primaryColor
      : "1px solid " + colors.newContainerPrimaryTint};
  font-weight: ${props => (props.active ? 600 : 500)};
  padding-bottom: 8px;
`;

export interface TaskTabsProps {
  className?: string;
  commentsCount: number;
  commentsLoading: boolean;
  taskId?: string;
  activeTab: TabOption;
  onTabSelected: (tab: TabOption) => void;
}

export function TaskTabs({
  activeTab,
  className,
  commentsLoading,
  commentsCount,
  onTabSelected
}: TaskTabsProps) {
  return (
    <Tabs className={className}>
      <Tab
        active={activeTab === TabOption.Details}
        onClick={() => onTabSelected(TabOption.Details)}
      >
        Details
      </Tab>
      <Tab
        active={activeTab === TabOption.Comments}
        onClick={() => onTabSelected(TabOption.Comments)}
      >
        Comments
        {commentsLoading && <StyledSpin size="small" />}
        {!commentsLoading && <Pill>{commentsCount}</Pill>}
      </Tab>
    </Tabs>
  );
}
