import _ from "lodash";

// media breakpoints (from bootstrap)
export type SizeName = "default" | "sm" | "md" | "lg" | "xl";
export const Size = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px"
};

export const SizeAsNumbers: { [key in SizeName]?: number } = _.mapValues(Size, val =>
  parseInt(val, 10)
);

export const getWindowSize = (windowWidth: number): SizeName =>
  _.reduce(
    SizeAsNumbers,
    (memo: SizeName, val, key) =>
      !!val && windowWidth > val ? (key as SizeName) : memo,
    "default"
  );

export const SpacingUnitValue = {
  xs: 4,
  sm: 8,
  smd: 12, // small-medium
  md: 16,
  lg: 20,
  xl: 32,
  xxl: 64
};

export const SCROLLBAR_SIZE = 11;

export const MENU_DRAWER_SIZE = 260;

export const SpacingUnit = {
  xs: SpacingUnitValue.xs + "px",
  sm: SpacingUnitValue.sm + "px",
  smd: SpacingUnitValue.smd + "px",
  md: SpacingUnitValue.md + "px",
  lg: SpacingUnitValue.lg + "px",
  xl: SpacingUnitValue.xl + "px",
  xxl: SpacingUnitValue.xxl + "px"
};

export const zIndex = {
  hoverItem: 50,
  nestedHoverItem: 100,
  sider: 100,
  header: 200,
  configPanel: 500,
  selectPortal: 1000,
  popoverOverDrawer: 2000, // needs to be higher than ant drawer 1000
  popupOverPopover: 2500, // for visibility over poppers, such as config panel poppers
  mask: 3000,
  selection: 20
};

// design tokens - low level color definitions used in our product
export const colorTokens = {
  white: "#FFFFFF",
  black: "#000000",

  grey75: "#FBFBFB",
  grey100: "#FAFAFA",
  grey150: "#F9F9F9",
  grey200: "#f5f5f5",
  grey290: "#EFEFEF",
  grey300: "#EEEEEE",
  grey400: "#E8EAED",
  grey500: "#E5E5E5",
  grey600: "#D8D8D8",
  grey700: "#BDC1C6",
  grey800: "#979797",
  grey900: "#9B9B9B",
  grey1000: "#7D8488",
  grey1150: "#7F7C86",
  grey1100: "#6C7275",
  grey1200: "#656564",
  grey1250: "#606060",
  grey1300: "#5F6368",
  grey1400: "#5E5B62",
  grey1500: "#4A4D53",
  grey1550: "#4F4A54",
  grey1600: "#444442",
  grey1700: "#434546",
  grey1720: "#3A3A3C",
  grey1750: "#36363A",
  grey1800: "#343238",
  grey1850: "#303135",
  grey1900: "#2f3033",
  grey2000: "#2A282D",
  grey2100: "#29292D",
  grey2150: "#242427",
  grey2200: "#222222",
  grey2300: "#202124",
  grey2400: "#1D1F21",
  grey2500: "#0c0c0c",

  greyTransparent50: "rgba(0, 0, 0, 0.04)",
  greyTransparent100: "rgba(255, 255, 255, 0.85)",
  greyTransparent200: "rgba(255, 255, 255, 0.65)",
  greyTransparent250: "rgba(255, 255, 255, 0.24)",
  greyTransparent270: "rgba(255, 255, 255, 0.08)",
  greyTransparent300: "rgb(189, 193, 198, 0.5)",
  greyTransparent400: "rgb(232, 234, 237, 0.38)",
  greyTransparent500: "rgba(189, 193, 198, 0.3)",
  greyTransparent600: "rgba(228, 233, 242, 0.15)",
  greyTransparent700: "rgba(232, 234, 237, 0.12)",
  greyTransparent800: "rgba(232, 234, 237, 0.08)",
  greyTranpsarent900: " rgba(0,21,41,0.12)",
  greyTransparent1000: "rgba(0, 0, 0, 0.15)",
  greyTransparent1100: "rgba(0, 0, 0, 0.3)",
  greyTransparent1200: "rgba(0, 0, 0, 0.65)",
  greyTransparent1300: "rgba(0, 0, 0, 0.85)",
  greyTransparent2100: "rgba(41, 41, 45, 0.03)",
  greyTransparent2300: "rgba(41, 41, 45, 0.08)",

  greyTint2100: "rgba(41, 41, 45, 0.13)",
  greyHover2100: "rgba(41, 41, 45, 0.07)",

  red100: "#FF9797",
  red200: "#F07368",
  red300: "#E53B3B",
  red400: "#D33131",
  red500: "#b5202c",

  magenta100: "#FFCCFF",
  magenta300: "#FF66FF",
  magenta400: "#FF00FF",

  orange100: "#FFCF7A",
  orange200: "#FF9400",
  orange300: "#FA811B",

  yellow100: "#FFF277",
  yellow200: "#FFB020",
  yellow300: "#FAAD14",

  green100: "#fafef6",
  green200: "#BFF199",
  green300: "#8ee000",
  green400: "#7AC70C",
  green500: "#588E09",

  blue100: "#BCE9FF",
  blue200: "#14D4F4",
  blue300: "#1CB0F6",

  purple100: "#FAF0FF",
  purple200: "#E7CFFF",
  purple300: "#CA94FF",
  purple400: "#9D63E3",
  purple500: "#8869A8",
  purple600: "#8549ba",
  purple650: "#895FBA",
  purple700: "#7C4DB2",
  purple800: "#6F469E",
  purple900: "#6f35a4",
  purple1000: "#6F35A4",
  purple1100: "#62398C",
  purpleTransparent100: "rgba(133, 73, 186, 0.06)",
  purpleTransparent200: "rgba(165, 96, 235, 0.1)",
  purpleTransparent300: "rgba(165, 96, 235, 0.2)",
  purpleTransparent400: "rgba(165, 96, 235, 0.45)",
  purpleBlue: "#5658fb"
};

// design decisions - semantically named colors used in our product

export const brandColors = {
  heroPurple: colorTokens.purple600,
  accentGreen: colorTokens.green400,
  blue: colorTokens.blue300,
  red: colorTokens.red400,
  orange: colorTokens.orange300,
  yellow: colorTokens.yellow200
};

export const fadedBrandColors = {
  heroPurple: colorTokens.purple300,
  accentGreen: colorTokens.green200,
  blue: colorTokens.blue100,
  red: colorTokens.red100,
  orange: colorTokens.orange100,
  yellow: colorTokens.yellow100
};

export const colors = {
  primaryColor: colorTokens.purple400,
  primaryColorHover: colorTokens.purple400,
  primaryColorPressed: colorTokens.purple1000,
  primaryColorHoverLight: fadedBrandColors.heroPurple,
  primaryColorFaded: colorTokens.purple200,
  primaryColorDark: colorTokens.purple700,
  primaryColorDarker: colorTokens.purple800,
  primaryColorMuted: colorTokens.purple650,
  secondaryColor: colorTokens.green400,
  rowHoverColor: colorTokens.purple100,
  linkColor: colorTokens.purple600,
  warningBackgroundColor: colorTokens.orange300,
  tipColor: fadedBrandColors.blue,
  darkErrorColor: colorTokens.red500,
  warningColor: colorTokens.yellow300,
  warningBorderColor: colorTokens.orange200,
  successDarkGreenPressed: colorTokens.green500,
  successDarkGreen: colorTokens.green300,
  successLightGreen: colorTokens.green100,
  outlineFocusWithinColor: colorTokens.purpleTransparent100,

  borderGrey: colorTokens.grey500,
  borderGreyStrong: colorTokens.grey600,
  headerBorder: colorTokens.grey1250,
  greyBackgroundColor: colorTokens.grey800,
  cardBackgroundColor: colorTokens.greyTransparent50,
  headerColor: colorTokens.greyTransparent1200,
  menuBorderColor: colorTokens.grey1600,
  midGrey: colorTokens.grey800,

  // below colors are used in components that have not yet migrated to dark theme
  textColorOnDarkModal: colorTokens.grey400,
  textColorOnDark: colorTokens.greyTransparent100,
  textColorMidOnDark: colorTokens.greyTransparent200,
  textColorOnInputDark: colorTokens.grey300,
  inputColorOnDark: colorTokens.grey1200,
  secondaryButtonOnDark: colorTokens.grey1400,
  darkInputOnDarkBackground: colorTokens.grey1800,
  borderOnDarkBackground: colorTokens.grey1900, // added borderColor to themes but did not remove this since consumer component is not in dark theme
  darkBackgroundColor: colorTokens.grey2000,
  darkHeaderBackgroundColor: colorTokens.grey2200,

  newBackgroundPrimaryColor: colorTokens.white,
  newBackgroundSecondaryColor: colorTokens.grey75,
  newBackgroundTertiaryColor: colorTokens.grey290,

  newContainerPrimary: colorTokens.greyTransparent2100,
  newContainerPrimaryTint: colorTokens.greyTint2100,
  newContainerHover: colorTokens.greyHover2100,

  surfacePrimary: colorTokens.grey2100,
  surfaceSecondary: colorTokens.grey1400,
  surfaceTertiary: colorTokens.grey1150,
  surfaceDisabled: "rgba(42, 40, 45, 0.3)",

  buttonPrimaryFillHover: "#3E3E42",
  buttonSecondaryText: colorTokens.grey2100,
  buttonSecondaryFill: "rgba(41, 41, 45, 0.08)",
  buttonSecondaryFillHover: "rgba(41, 41, 45, 0.12)",
  buttonSecondaryFillActive: "rgba(41, 41, 45, 0.16)",
  buttonSecondaryFillDisabled: "rgba(41, 41, 45, 0.03)",
  buttonDisabledText: "rgba(255, 255, 255, 0.6)",
  textDisabled: "rgba(41, 41, 45, 0.5)",

  dropShadow: "rgba(0, 0, 0, 0.07)",

  primaryAccent: "#A560EB",

  dropdownIndicator: "#424242",
  dropdownBorderColor: "rgba(41, 41, 45, 0.23)"
};

// New color pallette
export const newColors = {
  backgroundPrimary: "#FFFFFF",
  backgroundSecondary: "#FBFBFB",
  backgroundTertiary: "#EFEFEF",
  containerPrimaryTint: "#29292D21",
  containerPrimary: "#29292D08",
  containerActive: "#29292D0D",
  containerHover: "#29292D12",
  containerInputInset: "#FFFFFF99",
  containerBorder: "#29292D3B",
  surfacePrimaryTint: "#141315",
  surfacePrimary: "#29292D",
  surfaceSecondary: "#5E5B62",
  surfaceTertiary: "#7F7C86",
  surfaceDisable: "#29292D1E",
  primaryAccent: "#A560EB",
  error: "#F07368",
  alert: "#FFC300",
  link: "#8549BA"
};

export const chartColors: { [key: string]: string } = {
  tintPurple: colorTokens.purple300,
  tintGreen: colorTokens.green200,
  tintBlue: colorTokens.blue100,
  tintMagenta: colorTokens.magenta100,
  tintRed: colorTokens.red100,
  tintOrange: colorTokens.orange100,
  tintYellow: colorTokens.yellow100,
  tintGrey: colorTokens.grey600,
  basePurple: colors.primaryAccent,
  baseGreen: colorTokens.green300,
  baseBlue: colorTokens.blue200,
  baseMagenta: colorTokens.magenta300,
  baseRed: colorTokens.red300,
  baseOrange: colorTokens.orange200,
  baseYellow: newColors.alert,
  baseGrey: colorTokens.grey900,
  shadePurple: colorTokens.purple600,
  shadeGreen: colorTokens.green400,
  shadeBlue: colorTokens.blue300,
  shadeMagenta: colorTokens.magenta400,
  shadeRed: colorTokens.red400,
  shadeOrange: colorTokens.orange300,
  shadeYellow: colorTokens.yellow200,
  shadeGrey: colorTokens.grey1400
};

export const getChartLabelColor = (color: string) => {
  const colorTuple = Object.entries(chartColors).find(entry => entry[1] === color);
  if (!colorTuple) return color;
  if (colorTuple[0].startsWith("tint"))
    return chartColors[colorTuple[0].replace("tint", "shade")];
  if (colorTuple[0].startsWith("base"))
    return chartColors[colorTuple[0].replace("base", "shade")];

  return color;
};

// below themes contain variables used in antd and other 3rd party overrides in `ThemeContainer`
// any new variables should be added to both themes.

export const defaultTheme = {
  editorIdentifierColor: colorTokens.purple400,
  editorPaddingTop: "5px",
  editorPaddingBottom: SpacingUnit.xs,
  editorPopoverBackgroundColor: colorTokens.white,
  editorPopoverValidTextColor: colorTokens.green500,
  editorSelectionColor: colorTokens.purpleTransparent400,
  editorStyle: "light",
  editorJSToggleOnColor: colorTokens.grey75,
  editorJSToggleOnBackground: colorTokens.purple600,
  editorJSToggleOnBorder: colorTokens.purple600,
  editorJSToggleOffColor: colorTokens.grey2100,
  editorJSToggleOffBackground: colorTokens.greyTransparent2300,
  editorJSToggleOffBorder: colorTokens.grey600,

  textColor: newColors.surfacePrimary,
  disabledTextColor: newColors.surfaceTertiary,
  textColorMid: colorTokens.grey1400,
  textColorLight: colorTokens.grey1150,
  labelColor: colorTokens.greyTransparent1200,
  backgroundColor: colorTokens.grey200,
  borderColor: colorTokens.grey500,
  tableBorderColor: colorTokens.grey500,
  inputBorderColor: colorTokens.grey500,
  h6Color: newColors.surfacePrimary,

  buttonColor: colorTokens.greyTransparent1200,
  buttonFontWeight: "normal",
  buttonBackground: colorTokens.white,
  buttonBorder: `1px solid ${colorTokens.grey600}`,
  buttonHoverColor: colorTokens.purple900,
  buttonHoverBackground: colorTokens.white,
  buttonHoverBorderColor: colorTokens.purple400,
  buttonFocusColor: colorTokens.purple900,
  buttonFocusBackground: colorTokens.white,
  buttonActiveBackground: colorTokens.white,
  buttonDisabledColor: colorTokens.greyTransparent1100,
  buttonDisabledBackground: colorTokens.greyTransparent700,
  buttonDisabledBorderColor: colorTokens.grey600,

  columnHeaderColor: colorTokens.grey700,

  primaryButtonColor: colorTokens.white,
  primaryButtonBackground: colorTokens.purple400,
  primaryButtonHoverBackground: colorTokens.purple600,
  primaryButtonFocusBackground: colorTokens.purple900,
  primaryButtonActiveBackground: colorTokens.purple900,
  primaryButtonDisabledColor: colorTokens.greyTransparent1100,
  primaryButtonDisabledBackground: colorTokens.greyTransparent700,

  newPrimaryButtonColor: colorTokens.white,
  newPrimaryButtonBackground: colorTokens.grey2100,
  newPrimaryButtonBackgroundLight: colorTokens.grey1750,
  newPrimaryButtonBackgroundDark: colorTokens.grey2150,
  newSecondaryButtonColor: colorTokens.grey2000,
  newSecondaryButtonBackground: colorTokens.grey300,
  newSecondaryButtonBackgroundDark: colorTokens.grey500,
  newSecondaryButtonBackgroundDarker: colorTokens.grey600,

  iconButtonColor: colorTokens.grey1400,
  iconButtonHoverBackground: colorTokens.greyTransparent270,

  menuItemHeight: "32px",
  inputHeight: "32px",
  inputHeightSm: "24px",

  inputFontSize: "14px",
  inputPadding: "4px 11px",
  inputColor: newColors.surfacePrimary,
  inputColorActive: colorTokens.greyTransparent1200,
  inputBackgroundColor: colorTokens.white,
  inputBorder: `1px solid ${colorTokens.grey600}`,
  inputFocusBoxShadow: `0 0 0 2px ${colorTokens.purpleTransparent300}`,
  inputHoverColor: colorTokens.greyTransparent1200,
  inputHoverBackground: colorTokens.white,
  inputHoverBorder: `1px solid ${colorTokens.purple400}`,
  inputHoverBoxShadow: `0 0 0 2px ${colorTokens.purpleTransparent300}`,
  inputDisabledColor: colorTokens.greyTransparent1100,
  inputDisabledBackground: colorTokens.grey200,
  inputPlaceholderColor: colorTokens.greyTransparent1100,
  selectPlaceholderColor: colorTokens.greyTransparent1100,
  selectFontSize: "14px",
  selectColor: colorTokens.greyTransparent1200,
  selectBackgroundColor: colorTokens.white,
  selectMenuBackgroundColor: colorTokens.white,
  selectBorder: `1px solid ${colorTokens.grey600}`,
  selectHoverBackgroundColor: colorTokens.purpleTransparent200,
  selectHoverAfterColor: "transparent",
  selectActiveBorderBottom: "none",
  selectActiveBorderBottomRadius: "4px",
  selectMenuSelectedBackgroundColor: colorTokens.grey100,
  selectContentColor: colorTokens.black,

  checkboxBorderColor: colorTokens.grey600,
  checkboxBorder: `1px solid ${colorTokens.grey600}`,
  checkboxHoverBorder: `1px solid ${colorTokens.purple400}`,
  checkboxHoverOpacity: 0,
  checkboxActiveOpacity: 0,

  radioBackground: colorTokens.white,
  radioBorderColor: colorTokens.grey600,
  radioBorder: `1px solid ${colorTokens.grey600}`,
  radioBorderHover: `1px solid ${colorTokens.purple400}`,
  radioInnerMargin: "3px",

  modalBackgroundColor: colors.newBackgroundPrimaryColor,
  modalBorderColor: colorTokens.grey500,

  checkboxRadioLabelPadding: SpacingUnit.sm,

  cascadeSelectedBackgroundColor: colorTokens.grey100,

  disabledLabelColor: colorTokens.greyTransparent1100,
  errorColor: colorTokens.red400,

  popperBackground: colorTokens.white,
  popperBackgroundColor: colorTokens.white,
  popperBoxShadow: `1px 1px 3px 1px ${colorTokens.greyTranpsarent900}`,

  tooltipBackgroundColor: colorTokens.grey2500,

  reactJsonStyle: "light",

  rowFontSize: "14px",
  rowHoverBackground: colorTokens.purple1100,
  rowFocusBackground: colorTokens.purple1100,
  rowFocusBorder: `2px solid ${colorTokens.purple1100}`,
  rowActiveBackground: colorTokens.purple1100,
  rowDragBackground: colorTokens.grey200,
  rowDragHandleColor: colorTokens.black,
  rowDragOverBackground: colors.primaryColorFaded,
  rowSelectedBackground: colorTokens.purple1100,

  tabColor: colorTokens.greyTransparent1200,
  tabActiveColor: colorTokens.purple400,
  tabInkBarColor: colorTokens.purple400,

  unseenDotColor: colors.primaryColor,

  // darkTheme has overridden these GlobalStyleVariables (may change)
  largeFontSize: "18px",
  borderRadiusmd: "3px",
  boxShadow: `1px 1px 3px 1px ${colorTokens.greyTranpsarent900}`,
  dropShadow: `1px 1px 3px ${colors.borderGrey}`,

  headerBackgroundColor: colorTokens.grey200,
  headerTextColor: colorTokens.greyTransparent1300,
  headerButtonColor: colorTokens.grey1150,
  leftNavBackgroundColor: colorTokens.grey1800,
  menuSeperatorBorderColor: colorTokens.grey1550,
  primaryColorBackgroundHover: colorTokens.purpleTransparent300,
  animationFn: "0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",

  containerPrimaryBackground: colorTokens.grey150,
  containerPrimaryBorderRadius: "4px",
  innerContainerBackground: colorTokens.greyTransparent2100
};

export const darkTheme = {
  editorIdentifierColor: colorTokens.purple300,
  editorPaddingTop: SpacingUnit.sm,
  editorPaddingBottom: SpacingUnit.sm,
  editorPopoverBackgroundColor: colorTokens.grey2400,
  editorPopoverValidTextColor: colorTokens.green300,
  editorSelectionColor: colorTokens.purpleTransparent400,
  editorStyle: "dark",
  editorJSToggleOnColor: colorTokens.grey75,
  editorJSToggleOnBackground: colorTokens.purple600,
  editorJSToggleOnBorder: colorTokens.purple600,
  editorJSToggleOffColor: colorTokens.grey75,
  editorJSToggleOffBackground: colorTokens.grey2100,
  editorJSToggleOffBorder: colorTokens.greyTransparent600,

  textColor: colorTokens.grey400,
  disabledTextColor: newColors.surfaceTertiary,
  textColorMid: colorTokens.grey900,
  textColorLight: colorTokens.grey1500,
  labelColor: colorTokens.white,
  backgroundColor: colorTokens.grey2300,
  borderColor: colorTokens.grey1900,
  tableBorderColor: colorTokens.grey1500,
  inputBorderColor: colorTokens.grey1400,
  h6Color: colorTokens.white,

  buttonColor: colorTokens.grey700,
  buttonFontWeight: "500",
  buttonBackground: colorTokens.grey1400,
  buttonBorder: "none",
  buttonHoverColor: colorTokens.grey700,
  buttonHoverBackground: colorTokens.grey1700,
  buttonHoverBorderColor: "transparent",
  buttonFocusColor: colorTokens.grey700,
  buttonFocusBackground: colorTokens.grey1100,
  buttonActiveBackground: colorTokens.grey1000,
  buttonDisabledColor: colorTokens.greyTransparent400,
  buttonDisabledBackground: colorTokens.greyTransparent700,
  buttonDisabledBorderColor: "transparent",

  columnHeaderColor: colorTokens.grey700,

  primaryButtonColor: colorTokens.grey400,
  primaryButtonBackground: colorTokens.purple400,
  primaryButtonHoverBackground: colorTokens.purple800,
  primaryButtonFocusBackground: colorTokens.purple700,
  primaryButtonActiveBackground: colorTokens.purple500,
  primaryButtonDisabledColor: colorTokens.greyTransparent400,
  primaryButtonDisabledBackground: colorTokens.greyTransparent700,

  newPrimaryButtonColor: colorTokens.grey2100,
  newPrimaryButtonBackground: colorTokens.white,
  newPrimaryButtonBackgroundLight: colorTokens.grey1750,
  newPrimaryButtonBackgroundDark: colorTokens.grey2150,
  newSecondaryButtonColor: colorTokens.grey300,
  newSecondaryButtonBackground: colorTokens.grey1600,
  newSecondaryButtonBackgroundDark: colorTokens.grey1700,
  newSecondaryButtonBackgroundDarker: colorTokens.grey1800,

  iconButtonColor: colorTokens.grey400,
  iconButtonHoverBackground: colorTokens.greyTransparent270,

  menuItemHeight: "32px",
  inputHeight: "36px",
  inputHeightSm: "24px",

  inputFontSize: "13px",
  inputPadding: `${SpacingUnit.xs} 12px`,
  inputColor: colorTokens.grey400,
  inputColorActive: colorTokens.grey700,
  inputBackgroundColor: colorTokens.greyTransparent600,
  inputBorder: "none",
  inputFocusBoxShadow: "none",
  inputHoverColor: colorTokens.grey100,
  inputHoverBackground: `linear-gradient(0deg, ${colorTokens.greyTransparent800}, ${colorTokens.greyTransparent800}), ${colorTokens.greyTransparent600}`,
  inputHoverBorder: "none",
  inputHoverBoxShadow: "none",
  inputDisabledColor: colorTokens.greyTransparent500,
  inputDisabledBackground: colorTokens.greyTransparent600,
  inputPlaceholderColor: colorTokens.grey900,
  selectPlaceholderColor: colorTokens.grey700,
  selectFontSize: "14px",
  selectColor: colorTokens.grey400,
  selectBackgroundColor: colorTokens.greyTransparent600,
  selectMenuBackgroundColor: `linear-gradient(0deg, ${colorTokens.greyTransparent800}, ${colorTokens.greyTransparent800}), ${colorTokens.grey2300}`,
  selectBorder: "none",
  selectHoverBackgroundColor: colorTokens.purple800,
  selectHoverAfterColor: colorTokens.purple800,
  selectActiveBorderBottom: `2px solid ${colorTokens.purple500}`,
  selectActiveBorderBottomRadius: "0",
  selectMenuSelectedBackgroundColor: colorTokens.purpleTransparent200,
  selectContentColor: colorTokens.white,

  checkboxBorderColor: colorTokens.grey1300,
  checkboxBorder: `2px solid ${colorTokens.grey1300}`,
  checkboxHoverBorder: `2px solid ${colorTokens.grey1300}`,
  checkboxHoverOpacity: 0.04,
  checkboxActiveOpacity: 0.12,

  radioBackground: "transparent",
  radioBorderColor: colorTokens.grey1300,
  radioBorder: `2px solid ${colorTokens.grey1300}`,
  radioBorderHover: `2px solid ${colorTokens.purple400}`,
  radioInnerMargin: "2px",

  modalBackgroundColor: colorTokens.grey1720,
  modalBorderColor: colorTokens.grey2300,

  checkboxRadioLabelPadding: "12px",

  cascadeSelectedBackgroundColor: colorTokens.greyTransparent600,

  disabledLabelColor: colorTokens.greyTransparent400,
  errorColor: colorTokens.red200,

  popperBackground: ` linear-gradient(0deg, ${colorTokens.grey2100}, ${colorTokens.grey2100}), ${colorTokens.grey2300}`,
  popperBackgroundColor: colorTokens.grey2100,
  popperBoxShadow: `0px 2px 6px 2px ${colorTokens.greyTransparent1000}, 0px 1px 2px ${colorTokens.greyTransparent1100}`,

  tooltipBackgroundColor: colorTokens.grey2500,

  reactJsonStyle: "dark",

  rowFontSize: "13px",
  rowHoverBackground: `linear-gradient(0deg, ${colorTokens.greyTransparent270}, ${colorTokens.greyTransparent270}), ${colorTokens.purple1100}`,
  rowFocusBackground: colorTokens.purple1100,
  rowFocusBorder: `2px solid ${colorTokens.purple1100}`,
  rowActiveBackground: `linear-gradient(0deg, ${colorTokens.greyTransparent250},${colorTokens.greyTransparent250}), ${colorTokens.purple1100}`,
  rowDragBackground: `${colorTokens.purple1100}`,
  rowDragHandleColor: colorTokens.white,
  rowDragOverBackground: colorTokens.purpleTransparent200,
  rowSelectedBackground: colorTokens.purple1100,

  tabColor: colorTokens.grey900,
  tabActiveColor: colorTokens.white,
  tabInkBarColor: colorTokens.purple400,

  unseenDotColor: colors.primaryColor,

  // overrides for some GlobalStyleVariables (may change)
  largeFontSize: "14px",
  borderRadiusmd: "4px",
  boxShadow: `0px 2px 6px 2px ${colorTokens.greyTransparent1000}, 0px 1px 2px ${colorTokens.greyTransparent1100}`,
  dropShadow: `1px 1px 3px ${colorTokens.greyTransparent250}`,

  headerBackgroundColor: newColors.surfacePrimary,
  headerTextColor: colors.textColorOnDark,

  headerButtonColor: defaultTheme.headerButtonColor,
  leftNavBackgroundColor: defaultTheme.leftNavBackgroundColor,
  menuSeperatorBorderColor: defaultTheme.menuSeperatorBorderColor,
  primaryColorBackgroundHover: defaultTheme.primaryColorBackgroundHover,
  animationFn: defaultTheme.animationFn,

  containerPrimaryBackground: colorTokens.greyTransparent270,
  containerPrimaryBorderRadius: "4px",
  innerContainerBackground: colorTokens.greyTransparent2100
};

// some of the below variables should be moved to themes,
// but we shouldn't clutter the themes with anything until
// they are updated by design.
export const GlobalStyleVariables = {
  // app level variables
  headerHeight: "44px",

  configPanelWidth: "277px",

  leftMenuWidth: "40px",
  dateTimePickers: {
    selectHeight: "58px"
  },

  confirmModal: 300,
  drawerWidth: 688,
  wideDrawerWidth: 960,

  popoverWidth: "380px",
  modalPadding: "24px",

  // numeric spacing unit values (ie. 8 rather than "8px")
  ...Object.entries(SpacingUnitValue).reduce(
    (memo, [k, v]) => ({ ...memo, [`spacervalue${k}`]: v }),
    {}
  ),
  // Phase out. Nesting in theme is problematic in tests, requiring mocks or guards
  spacers: SpacingUnit,
  // Prefer getting spacers like: theme.spacerxl
  ...Object.entries(SpacingUnit).reduce(
    (memo, [k, v]) => ({ ...memo, [`spacer${k}`]: v }),
    {}
  ),
  breakpoints: Size,
  ...Object.entries(Size).reduce(
    (memo, [k, v]) => ({ ...memo, [`breakpoint${k}`]: v }),
    {}
  ),

  zIndex,

  // colors
  brandColors,
  fadedBrandColors,
  ...colors,
  ...newColors,

  // typography
  headlineFontSize: "44px",
  // TODO get h tag usage consistent then manage from global stylesheet
  h2FontSize: "30px",
  h3FontSize: "24px",
  h4FontSize: "20px",
  // largeFontSize moved to themes
  mediumFontSize: "16px",
  defaultFontSize: "14px",
  smallFontSize: "12px",
  tinyFontSize: "11px",
  boldFontWeight: 600,
  defaultLineHeight: "20px",

  // borders
  greyBorder: `solid 1px ${colors.borderGrey}`,
  borderRadiussm: "2px",
  // borderRadiusmd moved to themes
  borderRadiuslg: "5px",

  // forms
  inputHeight: SpacingUnit.xl,

  // tables, cards, details
  listItemMaxHeight: 150,

  scrollBarSize: `${SCROLLBAR_SIZE}px`,

  // TODO adding here temporarily until we upgrade styled-components; then will move to theme
  buttonHeightValue: "32",
  buttonHeight: "32px"
};

export const DefaultTheme = {
  id: "default",
  ...GlobalStyleVariables,
  ...defaultTheme
};

export const DarkTheme = {
  id: "dark",
  ...GlobalStyleVariables,
  ...darkTheme
};

export const TEXT_COLOR_OPTIONS = [
  colorTokens.greyTransparent1300,
  colorTokens.red400,
  colorTokens.red200,
  colorTokens.orange300,
  colorTokens.yellow200,
  colorTokens.green400,
  colorTokens.blue300
];
