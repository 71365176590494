import gql from "graphql-tag";
import { QueryHookOptions, QueryResult, useQuery } from "react-apollo";

import { AUTOMATION_NODE_FRAGMENT, AutomationNode } from "./common";

export interface Data {
  automationBySlug: AutomationNode;
}

export interface Variables {
  slug: string;
}

export const QUERY = gql`
  query AutomationBySlug($slug: String!) {
    automationBySlug(slug: $slug) {
      ...AutomationNodeFragment
    }
  }
  ${AUTOMATION_NODE_FRAGMENT}
`;

export type Result = QueryResult<Data, Variables>;
export const useAutomationBySlug = (
  options?: QueryHookOptions<Data, Variables>
): Result => useQuery<Data, Variables>(QUERY, options);
