import { Icon } from "antd";
import styled from "styled-components";

const LIST_ITEM_WIDTH = "168px";

export const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-bottom: 0;
`;

export const ListItem = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.spacerxl};
  line-height: ${props => props.theme.spacerxl};
  font-size: ${props => props.theme.inputFontSize};
  cursor: pointer;
  background-color: ${props =>
    props.selected ? props.theme.rowSelectedBackground : "none"};

  & > i {
    visibility: hidden;
  }

  &:hover > i {
    visibility: visible;
  }
`;

export const ListItemCopy = styled.div`
  max-width: ${LIST_ITEM_WIDTH};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditButton = styled(Icon)`
  justify-self: flex-end;
  padding-left: 10px;
  padding-right: ${props => props.theme.spacermd};
  height: 12px;
  width: 7px;

  i {
    color: ${props => props.theme.borderGrey};
  }
`;
