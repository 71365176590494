import {
  QueryBaseFunctionParameterMapping,
  RawBaseFunctionParameterMapping
} from "../SqlActionForm";

import { QueryArg } from "./reducer";

export const serializeArgs = (args: QueryArg[]) => {
  if (args.length === 0) return "[]";

  const ordinal: string[] = [];
  const named: Record<string, string> = {};
  for (let i = 0; i < args.length; i++) {
    const arg = args[i];
    if (arg.name === "") {
      ordinal[i] = arg.value;
    } else {
      named[arg.name] = arg.value;
    }
  }

  if (ordinal.length !== 0) {
    return "[" + ordinal.join(", ") + "]";
  } else {
    const keys = Object.keys(named).sort();
    const parts = keys.map(key => `${JSON.stringify(key)}: ${named[key]}`);
    return "{" + parts.join(", ") + "}";
  }
};

export const wrap = (sql: string) => `(
  ${sql.replace(/[\s;]+$/, "")}
) AS query_function`;

export const format = (
  sql: string,
  args: QueryArg[]
): QueryBaseFunctionParameterMapping => {
  return {
    select: JSON.stringify(["*"]),
    from: JSON.stringify(wrap(sql)),
    where: `(function() {
  if (typeof filters === 'undefined') { return []; }
  return filters.map(function(f) { return { column: f.attribute, operator: f.operator, value: f.value }; });
})()`,
    order_by: `(function() {
  if (typeof sortBy === 'undefined') { return []; }
  return [{ column: sortBy, direction: global.arguments.sortAscending ? 'asc' : 'desc' }];
})()`,
    limit: "global.arguments.pageSize",
    offset: "global.arguments.pageOffset",
    arguments: serializeArgs(args)
  };
};

export const formatRaw = (
  sql: string,
  args: QueryArg[]
): RawBaseFunctionParameterMapping => ({
  sql: JSON.stringify(sql),
  arguments: serializeArgs(args)
});

export const nonUnique = (values: string[]) => {
  const counts = values.reduce<Record<string, number>>((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});
  return Object.keys(counts)
    .filter(k => counts[k] > 1)
    .sort();
};
