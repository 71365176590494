import React from "react";

import { Input } from "antd";
import { cloneDeep } from "lodash";

import {
  AttributeNode,
  SpaceComponentObject,
  SpaceComponentType,
  SpaceComponentPackage,
  FunctionAttributeNode
} from "../../../../../../types";
import SortableList, { SortableItemCompact } from "../../../../../common/SortableList";
import { useTransformationActionContext } from "../../../../layout/TransformationContext/TransformationContext";
import {
  ElementLayout,
  LayoutUnit,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS
} from "../../../../layout/util";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../../../../SpaceRoot/SpaceContext";
import {
  RenderType,
  ValueRenderOption,
  getInitialRenderType
} from "../AttributeValue/options";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { ConfigPanelPopper, ConfigSection } from "../ConfigPanel";
import {
  ConfigPanelActionTypes,
  useSpaceConfigPanelContext
} from "../ConfigPanel/ConfigPanelContext";
import { ButtonWithTopMargin } from "../ConfigPanel/styledComponents";

import AddColumnPopper from "./AddColumnPopper";
import AttributeColumnConfig from "./AttributeColumnConfig";
import ChildComponentItem from "./ChildComponentItem";

interface Props {
  title: string;
  attributes: AttributeNode[];
  columnLabel?: string;
  errorField?: React.ReactNode;
}

export enum ColumnType {
  ATTRIBUTE = "attribute",
  COMPONENT = "component"
}

interface BaseColumn {
  column_type: ColumnType;
  hidden: boolean;
}

export interface ComponentColumn extends BaseColumn {
  column_type: ColumnType.COMPONENT;
  component_slug: string;
}

export interface AttributeColumn extends BaseColumn {
  column_type: ColumnType.ATTRIBUTE;
  attribute: string;
  render_type: RenderType;
  render_options?: ValueRenderOption[];
  date_template?: string;
}

export type Column = AttributeColumn | ComponentColumn;

export function extractAttributeColumns(
  columns: Column[]
): Extract<Column, AttributeColumn>[] {
  return columns.filter(isAttributeColumn);
}

export function ensureAttributeColumn(column: Column): AttributeColumn {
  if (!isAttributeColumn(column)) throw new Error("Expected attribute column");
  return column;
}

export function isAttributeColumn(column: Column): column is AttributeColumn {
  return column.column_type === ColumnType.ATTRIBUTE;
}

export function extractComponentColumns(
  columns: Column[]
): Extract<Column, ComponentColumn>[] {
  return columns.filter(isComponentColumn);
}

export function isComponentColumn(column: Column): column is ComponentColumn {
  return column.column_type === ColumnType.COMPONENT;
}

export function createAttributeColumn(
  attribute: AttributeNode | FunctionAttributeNode,
  options: Partial<AttributeColumn> = {}
): AttributeColumn {
  const hidden = typeof options.hidden === "boolean" ? options.hidden : false;
  return {
    column_type: ColumnType.ATTRIBUTE,
    attribute: attribute.sourceName,
    hidden,
    render_type: hidden ? RenderType.DEFAULT : getInitialRenderType(attribute),
    ...options
  };
}

export function createComponentColumn(component_slug: string): ComponentColumn {
  return {
    column_type: ColumnType.COMPONENT,
    component_slug,
    hidden: false
  };
}

export enum FieldInsertType {
  None = "0",
  Visible = "1",
  Hidden = "2"
}

type ConfigPopperType = "fieldInsertType" | "activeAttribute" | "activeComponentSlug";

export default function ColumnListManagerSection({
  title,
  attributes,
  columnLabel = "columns",
  errorField
}: Props) {
  const spaceConfigContext = useSpaceConfigContext();
  const { state, dispatch } = useComponentConfigContext();
  const { findSpaceComponentPackage, getSpaceComponentDisplayName } =
    useStableSpaceContext();
  const { draftComponent } = state;
  const {
    properties: { columns }
  } = draftComponent;
  const { state: configPanelState, dispatch: configPanelDispatch } =
    useSpaceConfigPanelContext();
  const { select } = useTransformationActionContext();
  const [fieldInsertType, setFieldInsertType] = React.useState(FieldInsertType.None);

  const getConfigPopperIdentifier = (
    type: ConfigPopperType,
    value: FieldInsertType | string | undefined
  ) => {
    return `${type}-${value}`;
  };

  const openConfigPopper = React.useCallback(
    (
      configPopperType: ConfigPopperType,
      value: FieldInsertType | string | undefined
    ) => {
      configPanelDispatch({
        type: ConfigPanelActionTypes.OPEN_POPPER,
        payload: {
          popperIdentifier: getConfigPopperIdentifier(configPopperType, value)
        }
      });
      if (configPopperType === "fieldInsertType") {
        setFieldInsertType(value as FieldInsertType);
      }
    },
    [configPanelDispatch]
  );

  const updateColumns = React.useCallback(
    (nextColumns: Column[]) => {
      dispatch({
        type: "MERGE_DRAFT_COMPONENT",
        payload: {
          change: { properties: { columns: nextColumns } }
        }
      });
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (columns === null) return;
    const columnsWithoutRemovedComponents = columns.filter(
      (c: Column) =>
        c.column_type !== ColumnType.COMPONENT ||
        !!spaceConfigContext.state.components[c.component_slug]
    );
    if (columnsWithoutRemovedComponents.length !== columns.length) {
      updateColumns(columnsWithoutRemovedComponents);
    }
  }, [spaceConfigContext.state, columns, updateColumns]);

  const addHandler = React.useCallback(() => {
    openConfigPopper("fieldInsertType", FieldInsertType.Visible);
  }, [openConfigPopper]);

  const onSortAll = React.useCallback(
    (oldIndex: number, newIndex: number) => {
      if (!columns) return;
      const updatedColumns = [...columns];
      const [removed] = updatedColumns.splice(oldIndex, 1);
      updatedColumns.splice(newIndex, 0, removed);
      return updateColumns(updatedColumns);
    },
    [columns, updateColumns]
  );

  if (!columns) return null;
  const excludedAttributes: AttributeNode[] = attributes.filter(
    a =>
      !columns.find((c: AttributeColumn) => c.attribute && c.attribute === a.sourceName)
  );

  return (
    <>
      <ConfigSection id="addVisibleColumnsButton" title={title} onAdd={addHandler}>
        {errorField}
        <SortableList data-test="sortableFields" onSort={onSortAll} isCompact>
          {columns.map((c: Column, i: number) => {
            if (c.column_type === ColumnType.ATTRIBUTE) {
              const attributeColumnId = `attributeColumn${c.attribute}`;
              const attributeConfig = (
                <AttributeColumnConfig
                  attribute={attributes.find(a => a.sourceName === c.attribute)!}
                  configuration={c}
                  path={`properties.columns[${i}]`}
                />
              );
              const isFlyoutVisible =
                configPanelState.activePopperIdentifier ===
                getConfigPopperIdentifier("activeAttribute", c.attribute);
              return (
                <SortableItemCompact
                  id={attributeColumnId}
                  key={attributeColumnId}
                  sortKey={attributeColumnId}
                  errorMessage=""
                  isSelected={isFlyoutVisible}
                  isHidden={c.hidden}
                  onToggleHide={() => {
                    const nextColumns = [...columns];
                    nextColumns[i] = cloneDeep(columns[i]);
                    nextColumns[i].hidden = !c.hidden;
                    updateColumns(nextColumns);
                  }}
                  onClick={() => openConfigPopper("activeAttribute", c.attribute)}
                  onRemove={() => {
                    const nextColumns = [...columns];
                    nextColumns.splice(i, 1);
                    dispatch({
                      type: "SET_DRAFT_COMPONENT",
                      payload: {
                        path: "properties",
                        value: {
                          ...draftComponent.properties,
                          columns: nextColumns
                        }
                      }
                    });
                    configPanelDispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    });
                  }}
                  title={c.attribute}
                >
                  {c.attribute}
                  {isFlyoutVisible && (
                    <ConfigPanelPopper
                      popperId={getConfigPopperIdentifier(
                        "activeAttribute",
                        c.attribute
                      )}
                      popperReferenceElement={
                        document.getElementById(attributeColumnId) || undefined
                      }
                      onCancel={() => openConfigPopper("activeAttribute", undefined)}
                    >
                      {attributeConfig}
                    </ConfigPanelPopper>
                  )}
                </SortableItemCompact>
              );
            } else {
              // c.column_type === ColumnType.COMPONENT
              const component = state.draftComponent.componentTreeNodes.find(
                (n: SpaceComponentObject) => n.slug === c.component_slug
              );
              if (component === undefined)
                throw new Error("Expected to find child component.");

              const isFlyoutVisible =
                configPanelState.activePopperIdentifier ===
                getConfigPopperIdentifier("activeComponentSlug", c.component_slug);
              const componentColumnId = `componentColumn${c.component_slug}`;
              const componentConfig = (
                <ChildComponentItem
                  showErrorIcon={spaceConfigContext.shouldDisplayError(
                    c.component_slug
                  )}
                  component={component}
                />
              );
              const isDynamicButton = component?.type === "DYNAMIC_FUNCTION";
              return (
                <SortableItemCompact
                  id={componentColumnId}
                  key={componentColumnId}
                  sortKey={componentColumnId}
                  errorMessage={""}
                  onClick={() =>
                    openConfigPopper("activeComponentSlug", c.component_slug)
                  }
                  isSelected={isFlyoutVisible}
                  isHidden={c.hidden}
                  isHideDisabled={true}
                  onToggleHide={() => {}}
                  onRemove={() => {
                    const nextColumns = [...columns];
                    nextColumns.splice(i, 1);
                    updateColumns(nextColumns);
                    configPanelDispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    });
                    spaceConfigContext.dispatch({
                      type: "REMOVE_COMPONENT",
                      payload: { slug: c.component_slug }
                    });
                  }}
                >
                  {componentConfig}
                  {isFlyoutVisible && (
                    <ConfigPanelPopper
                      popperId={getConfigPopperIdentifier(
                        "activeComponentSlug",
                        c.component_slug
                      )}
                      popperReferenceElement={
                        document.getElementById(componentColumnId) || undefined
                      }
                      onCancel={() =>
                        openConfigPopper("activeComponentSlug", undefined)
                      }
                    >
                      <ConfigSection title={getSpaceComponentDisplayName(component)}>
                        <label>Type</label>
                        <Input
                          disabled
                          value={
                            findSpaceComponentPackage(component.type)?.displayName || ""
                          }
                        />
                        <ButtonWithTopMargin
                          type="link"
                          onClick={() => {
                            select(c.component_slug);
                          }}
                          disabled={isDynamicButton}
                          title={
                            isDynamicButton
                              ? "Dynamic buttons are no longer supported. Use Visibility Rules instead."
                              : ""
                          }
                        >
                          Go to this component
                        </ButtonWithTopMargin>
                      </ConfigSection>
                    </ConfigPanelPopper>
                  )}
                </SortableItemCompact>
              );
            }
          })}
        </SortableList>
      </ConfigSection>
      {fieldInsertType !== FieldInsertType.None && (
        <AddColumnPopper
          popperId={getConfigPopperIdentifier("fieldInsertType", fieldInsertType)}
          popperReferenceElement={
            document.getElementById("addVisibleColumnsButton") || undefined
          }
          hidden={false}
          columnLabel={columnLabel}
          attributes={excludedAttributes}
          onInsertAttributeColumn={(a: AttributeNode) => {
            const newColumn = createAttributeColumn(a, { hidden: false });
            updateColumns([...columns, newColumn]);
          }}
          onInsertComponentColumn={(componentType: SpaceComponentType) => {
            const nextSlug = spaceConfigContext.predictSlug(componentType);
            const pkg = findSpaceComponentPackage(
              componentType
            ) as SpaceComponentPackage;
            const initialLayout = {
              width: LayoutUnit.AUTO as string,
              height: LayoutUnit.AUTO as string
            };
            const layoutOptions =
              pkg.elementLayoutOptions || DEFAULT_ELEMENT_LAYOUT_OPTIONS;
            const defaultLayout = pkg.defaultElementLayout || new ElementLayout();

            // If component type does not support `AUTO` for a dimension fallback to its default
            if (!(layoutOptions.width || []).includes(LayoutUnit.AUTO)) {
              initialLayout.width = defaultLayout.width;
            }
            if (!(layoutOptions.height || []).includes(LayoutUnit.AUTO)) {
              initialLayout.height = defaultLayout.height;
            }
            spaceConfigContext.dispatch({
              type: "INSERT_COMPONENT",
              payload: {
                componentType,
                parentSlug: state.draftComponent.slug,
                componentConfig: {
                  layout: initialLayout as ElementLayout
                }
              }
            });
            const newColumn = createComponentColumn(nextSlug);
            updateColumns([newColumn, ...columns]);
            select(nextSlug);
          }}
          onClose={() => {
            openConfigPopper("fieldInsertType", FieldInsertType.None);
          }}
        />
      )}
    </>
  );
}
