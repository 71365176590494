import React from "react";

// temp flag while enabling JS_EVAL feature flag
// (can replace with `window.FEATURE_FLAGS.includes("JS_EVAL")` if we need to revert)
export const isJsEvalEnabled = () => {
  return !window.FEATURE_FLAGS.includes("DISABLE_JS_EVAL");
};

export const SANDBOX_IFRAME_ID = "code-sandbox";

export default function CodeSandbox() {
  const shouldRender = isJsEvalEnabled();
  return shouldRender ? (
    <iframe
      title="Code Sandbox"
      id={SANDBOX_IFRAME_ID}
      sandbox="allow-scripts"
      src="/sandbox.html?cb=1"
      style={{ display: "none" }}
    ></iframe>
  ) : null;
}
