import { Binding, BindingShape } from "../../../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { getColumnsSchema } from "../common/ColumnListManager/getColumnsOutputShape";

export function getSchema(node: ComponentNode): Binding[] {
  return [
    {
      title: "Selected Row",
      name: "selectedRow",
      shape: BindingShape.OBJECT,
      attributes: getColumnsSchema(node)
    },
    {
      title: "Selected Rows",
      name: "selectedRows",
      shape: BindingShape.OBJECT_ARRAY,
      attributes: getColumnsSchema(node)
    },
    {
      title: "All Rows",
      name: "rows",
      shape: BindingShape.OBJECT_ARRAY,
      attributes: getColumnsSchema(node)
    }
  ];
}
