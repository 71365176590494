import React from "react";

import styled from "styled-components";

import { ValidationErrorMessage } from "./queries/SaveQueue";

export const ErrorList = styled.ul`
  text-align: left;
`;

export default function ErrorPanel({ errors }: { errors: ValidationErrorMessage[] }) {
  return (
    <div>
      <div>We could not save your queue. Please review the following errors:</div>
      <ErrorList>
        {errors.map(m => (
          <li>{m.text}</li>
        ))}
      </ErrorList>
    </div>
  );
}
