import React from "react";

import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentContext } from "../../contexts/ComponentContext";
import { ViewResult } from "../useView";
import useViewBlockingStates from "../useViewBlockingStates/useViewBlockingStates";

interface ViewEnsurerProps {
  viewResult: ViewResult;
  children: React.ReactNode;
}

export default function ViewEnsurer({ viewResult, children }: ViewEnsurerProps) {
  const { editMode } = useStableSpaceContext();
  const { component } = useComponentContext();
  const { viewBlockingStateNode } = useViewBlockingStates({
    component,
    viewResult,
    editMode
  });
  return <>{viewBlockingStateNode !== null ? viewBlockingStateNode : children}</>;
}
