import React, { Fragment } from "react";

import { Button, Icon } from "antd";
import * as moment from "moment";
import { useMutation } from "react-apollo";
import styled from "styled-components";

import {
  SYNC_PLAN_MUTATION,
  SyncPlanMutationResult,
  TOGGLE_AUTO_SYNC
} from "../../../../graphql/sync";
import { Header, LeftSide, RightSide } from "../styledComponents";

const SyncsHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => props.theme.spacerxs};
`;

const AutoSyncIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    color: ${props => props.theme.secondaryColor};
  }

  > i {
    color: ${props => props.theme.secondaryColor};
    margin-right: ${props => props.theme.spacerxs};
  }
`;

interface HeaderProps {
  showViewButton: boolean;
  dataSourceId: string;
  lastSyncTime: Date | null;
  // The toggle is what the user sets.
  autoSyncToggle: boolean;
  // Enabled reflects what's persisted in the database.  It's the source of truth for the data source.
  autoSyncEnabled: boolean;
  onCloseButtonClick(): void;
  onSaveComplete(data: any): void;
  // When the sync button is clicked, it will call onSyncStart to notify the parent that the sync has started.
  onSyncStart(): void;
  // After the sync has completed, onSyncEnd is called with the result Sync Plan
  onSyncComplete(data: SyncPlanMutationResult): void;
  onViewButtonClick(): void;
}

export const DataSourceSyncPanelHeader = ({
  lastSyncTime,
  showViewButton,
  onViewButtonClick,
  onCloseButtonClick,
  onSaveComplete,
  dataSourceId,
  onSyncStart,
  onSyncComplete,
  autoSyncToggle,
  autoSyncEnabled
}: HeaderProps) => {
  const synchronizedAt = lastSyncTime
    ? moment.utc(lastSyncTime).local().format("MM/DD/YYYY [at] hh:mma z")
    : null;

  const [syncPlanMutation, { loading: syncPlanLoading }] = useMutation(
    SYNC_PLAN_MUTATION,
    {
      onCompleted: onSyncComplete
    }
  );

  const [toggleAutoSync, { loading: autoSyncLoading }] = useMutation(TOGGLE_AUTO_SYNC, {
    onCompleted: () => onSaveComplete(autoSyncToggle)
  });

  return (
    <Header>
      <LeftSide>
        <SyncsHeading>
          <h3>Syncs</h3>
          {autoSyncEnabled && (
            <AutoSyncIndicator>
              <Icon type="sync" />
              <span>Auto Sync On</span>
            </AutoSyncIndicator>
          )}
        </SyncsHeading>
        {synchronizedAt && <span>Last sync on {synchronizedAt}</span>}
      </LeftSide>
      <RightSide>
        <Button
          type={!showViewButton ? "default" : "primary"}
          loading={syncPlanLoading}
          icon="sync"
          onClick={() => {
            onSyncStart();
            syncPlanMutation({ variables: { dataSourceId } });
          }}
        >
          Sync
        </Button>
        {showViewButton && <Button onClick={onViewButtonClick}>View</Button>}
        {!showViewButton && (
          <Fragment>
            <Button
              type="primary"
              loading={autoSyncLoading}
              onClick={() =>
                toggleAutoSync({
                  variables: { dataSourceId, autoSyncEnabled: autoSyncToggle }
                })
              }
            >
              Save
            </Button>
            <Button onClick={onCloseButtonClick}>Close</Button>
          </Fragment>
        )}
      </RightSide>
    </Header>
  );
};
