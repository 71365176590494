import React from "react";

import styled from "styled-components";

import { SpacingUnitValue } from "../../cssConstants";

import Logo, { LogoColor } from "./Logo";
import { H2 } from "./StyledComponents";

export enum ContainerSize {
  Small,
  Large
}

interface ContainerProps {
  size?: ContainerSize;
}

const Container = styled.div`
  width: ${(props: ContainerProps) =>
    props.size === ContainerSize.Large ? "769px" : "500px"};
  max-width: calc(100% - ${SpacingUnitValue.md * 2 + "px"});
  margin: 134px auto 100px;
  margin-top: ${(props: ContainerProps) =>
    props.size === ContainerSize.Large ? "100px" : "134px"};
`;

const StyledLogo = styled(Logo)`
  display: flex;
  justify-content: space-around;

  svg {
    transform: scale(1.5);
  }
`;

const Description = styled.div`
  font-size: 16px;
  margin-top: 24px;
  text-align: center;
`;

interface TitleContainerProps {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  children: JSX.Element;
  size?: ContainerSize;
  useLogoAsTitle?: boolean;
  logoColor?: LogoColor;
}

const TitleContainer = (props: TitleContainerProps) => {
  return (
    <Container size={props.size}>
      {props.useLogoAsTitle ? (
        <StyledLogo color={props.logoColor} />
      ) : (
        <H2 className="u-textCenter">{props.title}</H2>
      )}

      {props.description && <Description>{props.description}</Description>}
      {props.children}
    </Container>
  );
};

export default TitleContainer;
