import { omit } from "lodash";

import {
  SpaceComponentObject,
  SpaceComponentInputType,
  SpaceComponentPackage,
  SpaceComponentType
} from "../../../../types";
import { SpaceConfigState } from "../SpaceConfigContext/useSpaceConfig/reducer";

const OMITTED_COMPONENT_KEYS = [
  "id",
  "container",
  "__typename",
  "isRemovable",
  "notVisibleFunctions"
];

const filterComponentFields = (
  component: SpaceComponentObject,
  state: SpaceConfigState
) => {
  const currentComponent = state.components[component.slug]?.draftComponent;
  if (currentComponent === undefined) throw new Error("Expected to find component");

  const scInput: Partial<SpaceComponentInputType> = omit(
    {
      ...currentComponent,
      container: null,
      componentTreeNodes: currentComponent.componentTreeNodes as any[],
      functions: component.functions.edges.map(({ node: { id } }) => ({
        id
      }))
    },
    OMITTED_COMPONENT_KEYS
  );

  if (scInput.view) {
    scInput.view = { id: scInput.view.id };
  }
  return scInput as SpaceComponentInputType;
};

function isComponentInput(
  input: SpaceComponentInputType | null
): input is SpaceComponentInputType {
  return input !== null;
}
export const getComponentsForMutation = (
  nodes: SpaceComponentObject[],
  state: SpaceConfigState,
  findSpaceComponentPackage: (
    componentType: SpaceComponentType
  ) => SpaceComponentPackage | undefined
) => {
  return nodes
    .map((node: SpaceComponentObject) => {
      const pkg = findSpaceComponentPackage(node.type);
      if (pkg?.isClientProvidedComponent) return null;
      const newNode: SpaceComponentInputType = filterComponentFields(node, state);
      newNode.componentTreeNodes = getComponentsForMutation(
        node.componentTreeNodes,
        state,
        findSpaceComponentPackage
      );
      newNode.layout = state.elementLayouts.get(newNode.slug);
      // TODO Remove once zIndex has been migrated out of component layouts
      if (newNode.layout) {
        delete newNode.layout.zIndex;
      }
      return newNode;
    })
    .filter(isComponentInput);
};
