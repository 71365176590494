import React from "react";

import moment from "moment-timezone";

import { AttributeValueBaseProps } from "..";
import { TIME_DISPLAY_FORMAT, TIME_SAVE_FORMAT } from "../../../../constants";

export default function TimeValue({ className, value }: AttributeValueBaseProps) {
  let displayedValue = value || null;
  if (value && moment(value, TIME_SAVE_FORMAT).isValid()) {
    displayedValue = moment(value, TIME_SAVE_FORMAT).format(TIME_DISPLAY_FORMAT);
  }
  return <div className={className}>{displayedValue}</div>;
}
