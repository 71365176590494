import gql from "graphql-tag";

import { AttributeTypes } from "../../../../constants";
import { InputParameter, RelayNode } from "../../../../types";
import { INPUT_PARAMETER_FRAGMENT } from "../../queries/common";
import { ActionType, Event, ActionOptions } from "../types";

export interface ActionNode extends RelayNode {
  __typename?: "ActionNode";
  type: ActionType;
  options: ActionOptions;
}

export interface SubscriptionNode extends RelayNode {
  __typename?: "SubscriptionNode";
  action: RelayNode;
  order: number;
  event: Event;
}

interface _FieldNode extends RelayNode {
  __typename?: "FieldNode";
  name: string;
  type: AttributeTypes;
  required: true;
}

interface _StateNode extends RelayNode {
  __typename?: "StateNode";
  name: string;
  color: string;
  isArchive: boolean;
  subscriptions: SubscriptionNode[];
}

interface _TransitionNode extends RelayNode {
  __typename?: "TransitionNode";
  name: string;
  isPrimary: boolean;
  fromState: RelayNode;
  toState: RelayNode;
  subscriptions: SubscriptionNode[];
}

export interface QueueNode extends RelayNode {
  __typename: "QueueNode";
  name: string;
  description: string;
  icon?: string;
  color?: string;
  slug: string;
  createTaskForm: { inputParameters: InputParameter[] };
  actions: ActionNode[];
  fields: _FieldNode[];
  states: _StateNode[];
  transitions: _TransitionNode[];
}

const SUBSCRIPTIONS_FRAGMENT = gql`
  fragment SubscriptionNodeFragment on SubscriptionNode {
    id
    event
    order
    action {
      id
    }
  }
`;

export const QUEUE_NODE_FRAGMENT = gql`
  fragment QueueNodeFragment on QueueNode {
    id
    name
    description
    icon
    color
    slug
    organizationId
    createTaskForm {
      functionId
      inputParameters {
        ...InputParameterFragment
      }
    }
    actions {
      id
      type
      options {
        __typename
        ... on ChangeAssigneeOptions {
          assigneeId
          assigneeType
          useCurrent
        }
        ... on ExecuteFunctionOptions {
          functionId
          inputParameters {
            ...InputParameterFragment
          }
        }
        ... on OpenUrlOptions {
          template
        }
        ... on UpdateTaskOptions {
          inputParameters {
            ...InputParameterFragment
          }
        }
      }
    }
    fields {
      id
      name
      type
      required
    }
    states {
      id
      name
      color
      isArchive
      subscriptions {
        ...SubscriptionNodeFragment
      }
    }
    transitions {
      id
      name
      isPrimary
      fromState {
        id
      }
      toState {
        id
      }
      subscriptions {
        ...SubscriptionNodeFragment
      }
    }
  }
  ${SUBSCRIPTIONS_FRAGMENT}
  ${INPUT_PARAMETER_FRAGMENT}
`;
