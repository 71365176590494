import React from "react";

import { Navigate } from "react-router";

import usePaths from "../common/hooks/usePaths";

const DashboardRedirectComponent = () => {
  const { getSpacesHome } = usePaths();
  return <Navigate to={getSpacesHome()} />;
};

export default DashboardRedirectComponent;
