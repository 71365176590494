import React from "react";

import { Alert } from "antd";
import styled from "styled-components";

import { License } from "../../ConfigContext";

interface Props {
  license: License;
}

const NoSeatsWarning = styled(Alert)`
  margin-bottom: ${props => props.theme.spacermd};
`;

const LicenseNoSeatsWarning = (props: Props) => {
  const { license } = props;
  if (license.hasSeats) return null;

  return (
    <div>
      {!license.hasSeats && (
        <NoSeatsWarning
          type="warning"
          message={
            <span>
              You have used {license.usedSeats} of {license.seats} seats.{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://internal.io/contact"
              >
                Contact Internal
              </a>{" "}
              to add more.
            </span>
          }
        />
      )}
    </div>
  );
};

export default LicenseNoSeatsWarning;
