import React from "react";

import styled from "styled-components";
import useResizeObserver from "use-resize-observer/polyfilled";

import { Tab, Tabs } from "../../../../common/StyledComponents";

import { Option, StateSelect } from "./StateSelect";

const StyledTabs = styled(Tabs)`
  flex-wrap: nowrap;
  padding-bottom: 0;
`;

interface TabSelectProps {
  className?: string;
  options: Option[];
  value?: Option;
  onSelected?: (option: Option) => void;
}

const TabSelect = React.forwardRef(
  (
    { className, options, value, onSelected }: TabSelectProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <StyledTabs ref={ref} className={className}>
        {options.map(option => (
          <Tab
            className={value?.value === option.value ? "active" : ""}
            color={option.color}
            onClick={() => onSelected?.(option)}
            key={option.value}
          >
            {option.label}
          </Tab>
        ))}
      </StyledTabs>
    );
  }
);

const StyledStateSelect = styled(StateSelect)`
  min-width: 40%;
  max-width: 75%;
`;

const InvisibleTabs = styled(TabSelect)`
  visibility: hidden;
  position: fixed;
  left: 0;
  top: -5000px;
  z-index: -99999;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  options: Option[];
  value?: Option;
  onSelected: (option: Option) => void;
}

export function StateTabSelect({ options, value, onSelected }: Props) {
  const [element, setElement] = React.useState<"tabs" | "select" | "none">("none");
  const { ref: tabsRef, width: tabsWidth } = useResizeObserver<HTMLDivElement>();
  const { ref: containerRef, width: containerWidth } =
    useResizeObserver<HTMLDivElement>();

  React.useEffect(() => {
    if (!tabsWidth || !containerWidth) {
      return;
    }

    if (tabsWidth <= containerWidth) {
      setElement("tabs");
    } else {
      setElement("select");
    }
  }, [containerWidth, tabsWidth, setElement]);

  return (
    <Container ref={containerRef}>
      <InvisibleTabs ref={tabsRef} options={options} value={value} />
      {element === "tabs" && (
        <TabSelect options={options} value={value} onSelected={onSelected} />
      )}
      {element === "select" && (
        <StyledStateSelect options={options} value={value} onSelected={onSelected} />
      )}
    </Container>
  );
}
