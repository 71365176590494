import React, { Dispatch } from "react";

import { Icon } from "antd";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import DragHandle from "../../../../common/SortableList/dragHandleCompact.svg";
import { State as QueueState } from "../../../Config/types";
import DeleteStateModal from "../../DeleteStateModal/DeleteStateModal";
import { State } from "../../reducer";
import { ReducerAction, ReducerActions } from "../../reducer/actions";

import * as styled from "./styledComponents";

interface Props {
  state: State;
  dispatch?: Dispatch<ReducerAction>;
  className?: string;
}

export const StateList = ({ state, className, dispatch = () => null }: Props) => {
  const [deleteStateModal, setDeleteStateModal] = React.useState<
    QueueState | undefined
  >();
  const onAdd = () => dispatch({ type: ReducerActions.ADD_STATE });
  const onSelect = (i: number) =>
    dispatch({ type: ReducerActions.SELECT_STATE_INDEX, payload: i });

  const onDragEnd = (result: DropResult) => {
    if (result.reason !== "DROP" || !result.destination) return;
    dispatch({
      type: ReducerActions.UPDATE_STATE_ORDER,
      payload: {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index
      }
    });
  };

  const handleDeleteState = (toStateKey?: string) => {
    if (state.states.length > 1) {
      dispatch({
        type: ReducerActions.DELETE_SELECTED_STATE,
        payload: { moveTasksToStateKey: toStateKey }
      });
    }

    setDeleteStateModal(undefined);
  };

  return (
    <>
      <div className={className}>
        <styled.Item onClick={onAdd}>
          <styled.Container>
            <Icon type="plus" />
            <styled.Title>New State</styled.Title>
          </styled.Container>
        </styled.Item>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="stateList">
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {state.states.map((s, i) => (
                  <Draggable key={i} draggableId={s.name + i} index={i}>
                    {(provided, _) => (
                      <styled.DragItem
                        key={s.key}
                        onClick={() => onSelect(i)}
                        selected={state.selectedStateIndex === i}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="drag-item-icon">
                          <DragHandle />
                        </div>
                        <styled.Container>
                          <styled.DotContainer>
                            <styled.Dot color={s.color} />
                          </styled.DotContainer>
                          <styled.Title>{s.name}</styled.Title>
                          {state.selectedStateIndex === i && state.states.length > 1 && (
                            <styled.DeleteButton
                              icon="delete"
                              title={`Delete ${s.name} state`}
                              onClick={() => {
                                setDeleteStateModal(s);
                              }}
                            />
                          )}
                        </styled.Container>
                      </styled.DragItem>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {!!deleteStateModal && (
        <DeleteStateModal
          stateId={deleteStateModal.id}
          states={state.states}
          tasksMovingToState={
            state.moveTasks?.some(mt => mt.toStateKey === deleteStateModal?.id) || false
          }
          onCancel={() => setDeleteStateModal(undefined)}
          onComplete={handleDeleteState}
        />
      )}
    </>
  );
};
