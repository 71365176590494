import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { Connection } from "../../../../../../../../types";

interface RoleNode {
  id: string;
  name: string;
}

interface Data {
  allRoles: Connection<RoleNode>;
}

const QUERY = gql`
  query AllRoles {
    allRoles {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const useAllRoles = (options?: QueryHookOptions<Data>) =>
  useQuery<Data>(QUERY, options);

export default useAllRoles;
