import { Modal } from "antd";
import styled from "styled-components";

import { colors } from "../../../../cssConstants";

export const ActionModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 24px;

    .ant-modal-header {
      border-bottom: 0;
      font-size: 13px;
      line-height: 19px;
      font-weight: 600;
      color: ${colors.surfacePrimary};
      padding: 0 0 16px 0;
    }

    .ant-modal-body {
      font-size: 13px;
      line-height: 21px;
      font-weight: 500;
      color: ${colors.surfacePrimary};
      padding: 0 0 27px 0;
    }

    .ant-modal-footer {
      border-top: 0;
      padding: 0 0 0px 0;
    }
  }
`;
