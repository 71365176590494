import React, { FormEvent } from "react";

import { Spin } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import classNames from "classnames";

import { userToInitials } from "../../../../../common/Avatar/Avatar";
import useAuthUser from "../../../../../common/hooks/useAuthUser";
import { useAuthUserUuid } from "../../../../../common/hooks/useAuthUser/useAuthUser";
import useOutsideClick from "../../../../../common/hooks/useOutsideClick";
import Message from "../../../../../common/Message";
import { useColorConverter } from "../../../../../common/useColorCoverter/useColorCoverter";

import { useCreateComment } from "./queries";
import * as Styled from "./styledComponents";

interface SubmitButtonProps {
  className?: string;
  disabled?: boolean;
}

function SubmitButton({ className, disabled }: SubmitButtonProps) {
  return (
    <Styled.SubmitButtonInterior
      type="primary"
      shape="circle"
      disabled={disabled}
      className={className}
      htmlType="submit"
    >
      <Styled.SubmitIcon type="arrow-right" disabled={disabled} />
    </Styled.SubmitButtonInterior>
  );
}

interface GrowingInputProps {
  className?: string;
  loading?: boolean;
  value: string;
  onSubmit: () => void;
  onChange: (value: string) => void;
}

function GrowingInput({
  value,
  onSubmit,
  onChange,
  className,
  loading
}: GrowingInputProps) {
  const [active, setActive] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, () => setActive(false));

  return (
    <Styled.GrowingInputContainer
      ref={containerRef}
      className={classNames({ className: !!className, active })}
    >
      <Styled.GrowingTextArea
        autosize={{ minRows: 1 }}
        value={value}
        onClick={() => setActive(true)}
        onChange={e => onChange(e.currentTarget.value)}
        onPressEnter={e => {
          if (loading || e.shiftKey || e.altKey || e.ctrlKey) {
            return;
          }

          e.preventDefault();

          if (value.trim().length !== 0) {
            onSubmit();
          }
        }}
        placeholder="Add comment"
      />
      {loading ? <Spin size="small" /> : <SubmitButton disabled={value.length === 0} />}
    </Styled.GrowingInputContainer>
  );
}

export interface Props extends FormComponentProps {
  taskId: string;
  className?: string;
  onSuccess?: () => void;
}

export function CreateComment({ taskId, className, onSuccess }: Props) {
  const [inputValue, setInputValue] = React.useState("");
  const { authUser } = useAuthUser();
  const authUserUuid = useAuthUserUuid(authUser);
  const color = useColorConverter(authUserUuid);

  const [postComment, { loading, error }] = useCreateComment({
    onCompleted: () => {
      onSuccess?.();
      setInputValue("");
    }
  });

  React.useEffect(() => {
    if (error) Message.error("Something went wrong. Please try again.");
  }, [error]);

  const submitData = React.useCallback(() => {
    postComment({
      variables: {
        taskId: taskId,
        content: inputValue
      }
    });
  }, [postComment, taskId, inputValue]);

  const handleSubmit = React.useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submitData();
    },
    [submitData]
  );

  return (
    <Styled.Container className={className}>
      <Styled.Avatar color={color}>{userToInitials(authUser)}</Styled.Avatar>
      <Styled.Form layout="inline" onSubmit={handleSubmit}>
        <Styled.FormItem>
          <GrowingInput
            value={inputValue}
            onChange={setInputValue}
            loading={loading}
            onSubmit={submitData}
          />
        </Styled.FormItem>
      </Styled.Form>
    </Styled.Container>
  );
}

export const CreateCommentForm = Form.create<Props>({ name: "create_comment" })(
  CreateComment
);
