import React from "react";

import { StyledSelect } from "../../common/StyledComponents";

import TAX_COUNTRY_OPTIONS from "./TaxCountryOptions";

const TAX_COUNTRY_MAP: { [key: string]: string } = {
  au_abn: "🇦🇺 Australian Business Number (AU ABN)",
  au_arn: "🇦🇹 Australian Taxation Office Reference Number",
  eu_vat: "🇪🇺 European VAT number",
  br_cpf: "🇧🇷 Brazilian CPF number",
  bg_uic: "🇧🇬 Bulgaria Unified Identification Code",
  ca_bn: "🇨🇦 Canadian BN",
  ca_gst_hst: "🇨🇦 Canadian GST/HST number",
  ca_pst_bc: "🇨🇦 Canadian PST number (British Columbia)",
  ca_pst_mb: "🇨🇦 Canadian PST number (Manitoba)",
  ca_pst_sk: "🇨🇦 Canadian PST number (Saskatchewan)",
  ca_qst: "🇨🇦 Canadian QST number (Québec)",
  cl_tin: "🇨🇱 Chilean TIN",
  ge_vat: "Georgian VAT",
  hk_br: "Hong Kong BR Number",
  hu_tin: "Hungary tax number (adószám)",
  is_vat: "Icelandic VAT",
  in_gst: "Indian GST number",
  id_npwp: "Indonesian NPWP number",
  il_vat: "Israel VAT",
  jp_cn: "Japanese Corporate Number (*Hōjin Bangō*)",
  jp_rn:
    "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
  li_uid: "Liechtensteinian UID number",
  my_frp: "Malaysian FRP number",
  my_itn: "Malaysian ITN",
  my_sst: "Malaysian SST number",
  mx_rfc: "Mexican RFC number",
  nz_gst: "New Zealand GST number",
  no_vat: "Norwegian VAT number",
  ru_inn: "Russian INN",
  ru_kpp: "Russian KPP ",
  sa_vat: "Saudi Arabia VAT",
  sg_gst: "Singaporean GST",
  sg_uen: "Singaporean UEN",
  si_tin: "Slovenia tax number (davčna številka)",
  za_vat: "South African VAT number",
  kr_brn: "Korean BRN",
  es_cif: "Spanish CIF number",
  ch_vat: "Switzerland VAT number ",
  tw_vat: "Taiwanese VAT",
  th_vat: "Thai VAT",
  ua_vat: "Ukrainian VAT",
  ae_trn: "United Arab Emirates TRN",
  gb_vat: "United Kingdom VAT number"
};

const optionsFor = (countryCode: string) => {
  if (!countryCode) {
    return TAX_COUNTRY_MAP;
  }

  if (TAX_COUNTRY_OPTIONS[countryCode]) {
    const asArray = Object.entries(TAX_COUNTRY_MAP);
    const filtered = asArray.filter(([key, _]) => {
      return TAX_COUNTRY_OPTIONS[countryCode].includes(key);
    });
    return Object.fromEntries(filtered);
  }
  return [];
};

export default function TaxIdTypeSelect({
  onChange,
  currentCountry,
  value
}: {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  currentCountry: string;
  value: string;
}) {
  return (
    <StyledSelect id="taxIdType">
      <div className="select">
        <select name="taxIdType" onChange={onChange}>
          <option>Select Tax ID type</option>
          {Object.entries(optionsFor(currentCountry)).map(([k, v], index) => (
            <option key={index} value={k} selected={k === value}>
              {v}
            </option>
          ))}
        </select>
      </div>
    </StyledSelect>
  );
}
