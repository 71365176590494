import { Checkbox, Select as AntSelect } from "antd";
import styled from "styled-components";

import { H5 } from "../../StyledComponents";
import { UpDownChevron } from "../../ToggleChevron";

const dragColumnWidth = "20px";
const includedColumnWidth = "60px";
const fieldNameColumnWidth = "250px";
const valueColumnWidth = "auto";
const chevronWidth = "42px";

export const Title = styled(H5)`
  color: ${props => props.theme.textColor};
  margin-bottom: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmptyContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  height: 189px;
  background-color: ${props => props.theme.innerContainerBackground};
  border-radius: 8px;
  font-size: 15px;
`;

export const StyledHeader = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d7d;
`;

export const DragHandleHeader = styled(StyledHeader)`
  width: ${dragColumnWidth};
  padding-left: 12px;
  padding-right: 0;
`;

export const IncludedHeader = styled(StyledHeader)`
  text-align: center;
  width: ${includedColumnWidth};
`;

export const NameHeader = styled(StyledHeader)`
  width: ${fieldNameColumnWidth};
  padding-left: 20px;
`;

export const ValueHeader = styled(StyledHeader)`
  width: ${valueColumnWidth};
  flex-grow: 1;
`;

export const ChevronHeader = styled(StyledHeader)`
  width: ${chevronWidth}; /* needed to align header columns with field columns */
`;

export const StyledCell = styled.div<{ disabled?: boolean }>`
  font-size: 13px;
  background-color: ${props => props.theme.containerPrimaryBackground};
  padding: ${props => props.theme.spacermd};
  padding-left: 0;
  vertical-align: top;

  label {
    font-size: 12px;
  }

  color: ${props =>
    props.disabled ? props.theme.disabledTextColor : props.theme.textColor};
`;

export const DragHandleCell = styled(StyledCell)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${dragColumnWidth};
  padding-left: 12px;
  padding-right: 0;

  svg {
    margin-top: 3px; // offset so it looks vertically centered in collapsed view but top aligned if text wraps
  }
`;

export const IncludedCell = styled(StyledCell)`
  text-align: center;
  width: ${includedColumnWidth};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 0;

  > .ant-checkbox-wrapper {
    margin-top: 3px; // offset so it looks vertically centered in collapsed view but top aligned if text wraps
  }
`;

export const NameCell = styled(StyledCell)`
  width: ${fieldNameColumnWidth};
  padding-top: 18px; // offset so it looks vertically centered in collapsed view but top aligned if text wraps
  padding-left: 20px;
`;

export const ValueCell = styled(StyledCell)`
  width: ${valueColumnWidth};
  flex-grow: 1;
  > p {
    margin-top: 2px; // offset so it looks vertically centered in collapsed view but top aligned if text wraps
  }
  .ant-checkbox-wrapper {
    font-size: 12px;
    color: ${props => props.theme.textColor};
  }
`;

export const Row = styled.div<{ hideBorder?: boolean; isClickTarget?: boolean }>`
  display: flex;
  align-items: stretch;
  margin-bottom: ${props => props.theme.spacermd};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  border: ${props => (props.hideBorder ? "" : `1px solid ${props.theme.borderColor}`)};
  cursor: ${props => (props.isClickTarget ? "pointer" : "initial")};
  overflow: auto;
`;

export const Select = styled(AntSelect)`
  width: 100%;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  button {
    font-size: 12px;
  }

  label {
    margin-bottom: ${props => props.theme.spacerxs};
  }

  & + & {
    margin-top: ${props => props.theme.spacermd};
  }
`;

export const ValueText = styled.p<{ active?: boolean }>`
  color: ${props =>
    props.active ? props.theme.textColor : props.theme.disabledTextColor};
  margin: 0;
`;

export const ChevronWrapper = styled(StyledCell)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: ${chevronWidth};
`;

export const Chevron = styled(UpDownChevron)`
  margin-top: 2px; // offset so it looks vertically centered in collapsed view but top aligned if text wraps
`;

export const NullCheckbox = styled(Checkbox)`
  margin-top: ${props => props.theme.spacersm};
`;
