import React from "react";

import { Select } from "antd";

import { SpaceConfigAction } from "../../../../../../types";
import ValidationError from "../../../../common/ComponentConfigContext/ValidationError/ValidationError";
import { Field } from "../../../../common/ConfigPanel/styledComponents";
import { SubSeriesMode, ChartType, ChartConfigState } from "../../../types";
import { GroupingFunctionSelect, AggregationFunctionSelect } from "../common/Inputs";

interface Props {
  state: ChartConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

const CHART_ELEMENT_NAMES = {
  [ChartType.COLUMN]: "bar",
  [ChartType.LINE]: "line",
  [ChartType.PIE]: "N/A"
};

export default function ColumnChartConfig({ state, dispatch }: Props) {
  React.useEffect(() => {
    let subSeriesMode = SubSeriesMode.NONE;
    if (state.draftComponent.properties.sub_group_functions.length > 0) {
      subSeriesMode = SubSeriesMode.GROUP;
    } else if (state.draftComponent.properties.preaggregated_attributes.length > 0) {
      subSeriesMode = SubSeriesMode.PREAGGREGATED;
    }
    dispatch({
      type: "CHANGE_SUB_SERIES_MODE",
      payload: { mode: subSeriesMode, retain: true }
    });
  }, []); // eslint-disable-line

  const elementName = CHART_ELEMENT_NAMES[state.draftComponent.properties.chart_type];

  return (
    <>
      <Field>
        <label>X-Axis</label>
        <GroupingFunctionSelect state={state} dispatch={dispatch} />
        <ValidationError field="X_AXIS" />
      </Field>
      <Field>
        <label>Y-Axis</label>
        <AggregationFunctionSelect state={state} dispatch={dispatch} />
        <ValidationError field="Y_AXIS" />
      </Field>
      <Field>
        <label>Number of {elementName}s</label>
        <Select
          data-test="sub-series-mode-select"
          placeholder="Select"
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          value={state.subSeriesMode}
          onChange={(mode: SubSeriesMode) =>
            dispatch({ type: "CHANGE_SUB_SERIES_MODE", payload: { mode } })
          }
        >
          <Select.Option key={SubSeriesMode.NONE}>Single</Select.Option>
          <Select.Option key={SubSeriesMode.GROUP}>
            Multiple, grouped by an attribute
          </Select.Option>
          <Select.Option key={SubSeriesMode.PREAGGREGATED}>
            Multiple, using pre-aggregated data
          </Select.Option>
        </Select>
      </Field>
      {state.subSeriesMode === SubSeriesMode.GROUP && (
        <Field>
          <label>Group by</label>
          <Select
            data-test="sub-grouping-function-select"
            placeholder="Select an attribute"
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            value={state.draftComponent.properties.sub_group_functions[0]?.attribute}
            onChange={(attribute: string | -1) => {
              dispatch({
                type: "CHANGE_SUB_GROUPING_FUNCTION_ATTRIBUTE",
                payload: { attribute }
              });
            }}
          >
            {
              <Select.Option key="-1" value={-1}>
                Do not group by another attribute
              </Select.Option>
            }
            {(state.attributes || []).map(a => (
              <Select.Option key={a.sourceName}>{a.name}</Select.Option>
            ))}
          </Select>
          <ValidationError field="SUB_GROUPING_FUNCTION" />
        </Field>
      )}
      {state.subSeriesMode === SubSeriesMode.PREAGGREGATED && (
        <Field>
          <label>Data to plot</label>
          <Select
            data-test="preaggregated-attributes-select"
            placeholder="Select an attribute"
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            mode="multiple"
            value={state.draftComponent.properties.preaggregated_attributes}
            onChange={(preaggregated_attributes: string[]) => {
              dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    properties: {
                      preaggregated_attributes
                    }
                  }
                }
              });
            }}
          >
            {(state.attributes || []).map(a => (
              <Select.Option key={a.sourceName}>{a.name}</Select.Option>
            ))}
          </Select>
        </Field>
      )}
    </>
  );
}
