import React from "react";

import { Modal, Steps } from "antd";
import { get, chunk, isObjectLike } from "lodash";
import styled from "styled-components";

import {
  FunctionNode,
  SourceType,
  BulkExecuteFunctionResult,
  FunctionParameterNode
} from "../../../../../../types";
import { tryError } from "../../../../../util";
import {
  reportException,
  getFlattenedRedactedData
} from "../../../../../util/exceptionReporting";
import { SpaceFunction } from "../../../../FunctionExecutor/FunctionExecutor";
import { SpaceStateInputs } from "../../../SpaceComponent";
import PermissionFeedback from "../../common/PermissionFeedback";
import useBulkSpaceFunction, {
  BulkFunctionCallResult
} from "../../common/useBulkSpaceFunction";
import { InputParameter } from "../../common/useFuncParams";
import { transformBlank } from "../../common/useFuncParams/useFuncParams";
import { fillParameterValues } from "../../common/useFuncParams/util";
import useFunctionAccess from "../../common/useFunctionAccess";
import injectState from "../../util/injectState";
import { parseText } from "../util";

import CompleteStep from "./CompleteStep";
import MapDataStep from "./MapDataStep";
import reducer, {
  BulkImportStep,
  State,
  initialState,
  Mappings,
  ParamMapping
} from "./reducer";
import ReviewStep from "./ReviewStep";
import SelectFileStep from "./SelectFileStep";

const { Step } = Steps;

export interface BulkImportModalProps {
  spaceId: string;
  function: SpaceFunction;
  visible: boolean;
  inputParameters: InputParameter[];
  inputState: SpaceStateInputs | null;
  sourceType?: SourceType;
  binding?: string;
  onCancel: () => void;
  onComplete: () => void;
  onError: (error: Error) => void;
}

export interface BulkImportModalStepProps {
  state: State;
  dispatch: any;
  sourceType?: SourceType;
  function: FunctionNode;
  inputParameters: InputParameter[];
  inputState: SpaceStateInputs | null;
  importAllData: () => void;
  onComplete: () => void;
}

const StyledModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    flex-flow: column;
    min-height: 400px;

    div.ant-steps {
      margin-bottom: ${props => props.theme.spacermd};
      flex: 0;
    }

    .ant-table-body {
      .ant-table-thead {
        th {
          padding: ${props => props.theme.spacersm};
        }
      }

      td {
        padding: ${props => props.theme.spacersm};
      }
    }
  }
`;

/**
 * identity returns the input value.
 */
export function identity<T>(value: T): T {
  return value;
}

// NameNormalizer is a function used to convert inputs to a standard value.
export type NameNormalizer = (arg: string) => string;

/**
 * findNameMapping attempts to map file headers to existing parameters.
 * The name must be an exact match for the mapping after running through normalize
 */
export function findNameMapping({
  inputParameters,
  functionParameters,
  fileHeaders,
  normalize = identity
}: {
  inputParameters: InputParameter[];
  functionParameters: FunctionParameterNode[];
  fileHeaders: string[];
  normalize?: NameNormalizer;
}) {
  const keyedParameters = new Map<string, InputParameter>(
    inputParameters.filter(ip => ip.type === "file").map(ip => [normalize(ip.name), ip])
  );

  const keyedFunctionParameters = new Map<string, FunctionParameterNode>(
    functionParameters.map(fp => [normalize(fp.name), fp])
  );

  const setNames = new Set<string>();
  const mapping: { [key: string]: ParamMapping } = {};
  return fileHeaders.reduce((prev, header, index) => {
    const headerNormalized = normalize(header);
    const ip = keyedParameters.get(headerNormalized);
    const fp = keyedFunctionParameters.get(headerNormalized);

    // It is important to not filter out invalid entries, as we need the
    // index to be based on given fileHeaders array
    // also choose the first file header if there are duplicates
    if (!ip || !fp || setNames.has(fp.name)) {
      return prev;
    }

    setNames.add(fp.name);

    return {
      ...prev,
      [String(index)]: {
        name: fp.name,
        type: fp.type,
        required: ip.required || fp.required,
        ip: ip
      }
    };
  }, mapping);
}

const BulkImportModal = ({
  spaceId,
  function: func,
  visible,
  inputParameters,
  inputState,
  sourceType,
  binding,
  onCancel,
  onComplete,
  onError
}: BulkImportModalProps) => {
  const initialStep =
    sourceType === SourceType.FILE ? BulkImportStep.SelectFile : BulkImportStep.Review;
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    currentStep: initialStep,
    sourceType: sourceType || null
  });

  const access = useFunctionAccess(func);

  const { mutationLoading, mutationError, bulkExecuteFunction } = useBulkSpaceFunction(
    func ? func.id : "",
    spaceId || ""
  );

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: "RESET_FLOW", payload: { step: initialStep } });
    }
  }, [visible, dispatch, initialStep]);

  const setBindingRows = React.useCallback(
    (rows: any[][], mappings: Mappings) => {
      dispatch({
        type: "INIT_BINDING_DATA",
        payload: { data: rows, mappings }
      });
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (sourceType !== SourceType.BINDING) return;
    if (!binding) return;
    const sourceData = get(inputState, binding) || [];
    const mappings = Object.fromEntries(
      (inputParameters || [])
        .map((ip: InputParameter, idx: number) => {
          const fp = func?.functionParameters?.find(fp => fp.name === ip.name);
          if (!fp) return null;
          return [
            String(idx),
            {
              name: ip.name,
              type: fp.type,
              required: ip.required || fp.required,
              ip: ip
            }
          ] as [string, ParamMapping];
        })
        .filter((mapping: [string, ParamMapping] | null) => !!mapping) as [
        string,
        ParamMapping
      ][]
    );
    let hasRowDataError = false;
    const mappedRows = sourceData.map((item: any) => {
      const isScalar = !isObjectLike(item);
      if (!isScalar && item.data === undefined) {
        hasRowDataError = true;
      }
      const data = item !== undefined ? item : {};
      const patchedInput = injectState(inputState, "repeateditem", data);
      return { ...fillParameterValues(inputParameters, patchedInput) };
    });

    // Add logging to debug sentry issue https://sentry.io/organizations/internal/issues/2693528028/?project=1517743
    // where row.data is undefined.
    if (hasRowDataError) {
      reportException(
        new Error("Expected each row in sourceData to have a data object."),
        {
          extra: {
            sourceData: sourceData.map((row: any) => getFlattenedRedactedData(row)),
            function: func.id,
            input:
              inputState === null ? inputState : getFlattenedRedactedData(inputState),
            binding
          }
        }
      );
    }

    const bindingRows = mappedRows.map((row: any) => {
      return Object.values(mappings as Mappings).map((val: ParamMapping) => {
        return get(row, val.name);
      });
    });
    setBindingRows(bindingRows, mappings);
  }, [
    setBindingRows,
    state.currentStep,
    sourceType,
    func,
    inputParameters,
    inputState,
    binding
  ]);

  const setCurrentStep = React.useCallback(
    (step: BulkImportStep) => {
      return dispatch({ type: "SET_CURRENT_STEP", payload: { step } });
    },
    [dispatch]
  );

  const rows = React.useMemo(() => {
    switch (sourceType) {
      case SourceType.FILE:
        return state.fileRows;
      case SourceType.BINDING:
        return state.bindingRows;
      default:
        throw new Error("unexpected bulk import source type");
    }
  }, [sourceType, state]);

  const hasResultErrors = React.useMemo(() => {
    return (
      state.currentStep === BulkImportStep.Complete &&
      (state.results.find(
        (r: BulkExecuteFunctionResult) =>
          r.__typename !== "ExecuteFunctionResultSuccess"
      ) ||
        state.results.length !== rows.length)
    );
  }, [state.currentStep, state.results, rows]);

  const getParameterData = React.useCallback(
    (row: any[]) => {
      const data = Object.fromEntries(
        row
          .map((val, idx) => {
            const param = state.mappings[idx];
            if (!param) return [undefined, val];
            switch (sourceType) {
              case SourceType.FILE:
                const [parsed, _] = parseText(val, param.type);
                return [param.name, transformBlank(parsed, param.ip)];
              case SourceType.BINDING:
                return [param.name, val];
              default:
                throw new Error("unexpected bulk import source type");
            }
          })
          .filter(([name, _]) => name)
      );
      let parameterValues = {};
      if (sourceType === SourceType.FILE) {
        parameterValues = fillParameterValues(inputParameters, inputState);
      }
      return { ...parameterValues, ...data };
    },
    [sourceType, state.mappings, inputParameters, inputState]
  );

  const handleCancel = React.useCallback(() => {
    onCancel();
    setCurrentStep(initialStep);
  }, [setCurrentStep, onCancel, initialStep]);

  const handleComplete = React.useCallback(() => {
    onComplete();
    setCurrentStep(initialStep);
  }, [onComplete, setCurrentStep, initialStep]);

  const importAllData = React.useCallback(async () => {
    dispatch({ type: "START_IMPORT" });
    let currentResults = [] as BulkExecuteFunctionResult[];
    let hasError = false;
    const chunks = chunk(rows, 10) as any[][][];
    const appendResults = (newResults: BulkExecuteFunctionResult[]) => {
      currentResults = [...currentResults, ...newResults];
    };
    const setError = (error: boolean) => (hasError = hasError || error);
    for (let i = 0; i < chunks.length; i++) {
      if (hasError) break;
      dispatch({
        type: "UPDATE_IMPORT_PROGRESS",
        payload: { chunk: i, total: rows.length }
      });
      const chunkParameterData = chunks[i].map(row => getParameterData(row));
      // this needs to execute synchronously since results are expected to be in order
      try {
        await bulkExecuteFunction(
          chunkParameterData,
          (data: BulkFunctionCallResult) => appendResults(data?.bulkExecuteFunction),
          err => {
            onError(err as Error);
            setError(true);
          }
        );
        if (mutationError) setError(true);
      } catch (e) {
        const err = tryError(e);
        onError(err);
        console.error("bulk execute function failed", err); // eslint-disable-line no-console
        break;
      }
    }
    dispatch({ type: "FINISH_IMPORT", payload: { results: currentResults } });
  }, [rows, getParameterData, bulkExecuteFunction, mutationError, onError]);

  let CurrentStep: React.ComponentType<BulkImportModalStepProps>;
  const stepProps = {
    dispatch,
    state,
    binding,
    sourceType,
    function: func.config,
    inputParameters,
    inputState,
    importAllData,
    onComplete: handleComplete
  };

  React.useEffect(() => {
    // If a file was just uploaded, check if there are any file header
    // column names that match the field names. If there are any, set them by default
    // for convenience
    if (
      state.previousStep !== BulkImportStep.SelectFile ||
      state.currentStep !== BulkImportStep.MapData
    ) {
      return;
    }

    const nameMapping = findNameMapping({
      inputParameters: inputParameters,
      fileHeaders: state.fileHeader,
      functionParameters: func.config.functionParameters?.edges.map(e => e.node) || [],
      normalize: arg => arg.toLocaleLowerCase()
    });

    if (Object.keys(nameMapping).length) {
      dispatch({ type: "SET_MAPPINGS", payload: { mappings: nameMapping } });
    }
  }, [
    state.previousStep,
    state.currentStep,
    inputParameters,
    state.fileHeader,
    func.config
  ]);

  switch (state.currentStep) {
    case BulkImportStep.SelectFile:
      CurrentStep = SelectFileStep;
      break;
    case BulkImportStep.MapData:
      CurrentStep = MapDataStep;
      break;
    case BulkImportStep.Review:
      CurrentStep = ReviewStep;
      break;
    case BulkImportStep.Complete:
      CurrentStep = CompleteStep;
      break;
    default:
      throw new Error("unexpected bulk import step");
  }

  const getStepStatus = React.useCallback(
    (step: BulkImportStep): "process" | "finish" | "wait" | "error" => {
      if (step === state.currentStep) {
        if (hasResultErrors) return "error";
        return "process";
      } else if (step < state.currentStep) {
        return "finish";
      } else {
        return "wait";
      }
    },
    [state.currentStep, hasResultErrors]
  );
  return (
    <StyledModal
      title={
        <>
          Bulk {(func.title || "import").toLowerCase()}{" "}
          <PermissionFeedback
            attributes={func.functionAttributes}
            parameters={func.functionParameters.filter(fp =>
              inputParameters.some(ip => ip.name === fp.name)
            )}
            access={access}
          />
        </>
      }
      onCancel={handleCancel}
      visible={visible}
      width={1000}
      footer={null}
      maskClosable={state.currentStep === BulkImportStep.SelectFile}
      closable={!state.isImporting && !mutationLoading}
    >
      <Steps current={state.currentStep}>
        {sourceType === SourceType.FILE && (
          <Step title="Upload" status={getStepStatus(BulkImportStep.SelectFile)} />
        )}
        {sourceType === SourceType.FILE && (
          <Step title="Map" status={getStepStatus(BulkImportStep.MapData)} />
        )}
        <Step title="Review" status={getStepStatus(BulkImportStep.Review)} />
        <Step title="Complete" status={getStepStatus(BulkImportStep.Complete)} />
      </Steps>
      <CurrentStep {...stepProps} />
    </StyledModal>
  );
};

export default BulkImportModal;
