import React from "react";

import useDebouncedValue from "../hooks/useDebouncedValue";
import usePrevious from "../hooks/usePrevious";

const DELAY = 400;

interface WithDebouncedValueProps {
  value: any;
  onChange: (value: any) => void;
  onBlur?: (evt: any) => void;
  onFocus?: (evt: any) => void;
}

interface RequiredInputProps {
  value?: any;
  onChange?: (value: any) => void;
  onBlur?: (evt: any) => void;
  onFocus?: (evt: any) => void;
}

type WithDebouncedValueOptions = {
  serializeExternalValue?: (value: any) => any;
  serializeLocalValue?: (value: any) => any;
  selectOnChangeValue?: (evt: any) => any;
  isEqual?: (localValue: any, externalValue: any) => boolean;
};

export default function withDebouncedValue<T extends RequiredInputProps, L, E>(
  WrappedComponent: React.ComponentType<T>,
  {
    serializeExternalValue = (val: E) => val,
    serializeLocalValue = (val: L) => val,
    selectOnChangeValue = val => val,
    isEqual = (local, external) => String(local) === String(external)
  }: WithDebouncedValueOptions = {}
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithDebouncedValue = ({
    value,
    onChange,
    ...props
  }: Omit<T, "onChange"> & WithDebouncedValueProps) => {
    const [localValue, setLocalValue] = React.useState(serializeExternalValue(value));
    const [focused, setFocused] = React.useState(false);

    const debouncedValue = useDebouncedValue(localValue, DELAY);
    const prevFocused = usePrevious(focused);
    React.useEffect(() => {
      if (focused === true && !isEqual(debouncedValue, value)) {
        // Sync to external
        onChange(serializeLocalValue(debouncedValue));
      } else if (focused === false && prevFocused === true) {
        // Set localValue upon blur (debounced essentially no-op after)
        onChange(serializeLocalValue(localValue));
      } else if (focused === false && !isEqual(localValue, value)) {
        // Sync from external
        setLocalValue(serializeExternalValue(value));
      }
    }, [value, debouncedValue, focused, prevFocused, onChange, localValue]);

    return (
      <WrappedComponent
        {...(props as unknown as T)}
        value={localValue}
        onFocus={evt => {
          if (typeof props.onFocus === "function") {
            props.onFocus(evt);
          }
          setFocused(true);
        }}
        onBlur={evt => {
          if (typeof props.onBlur === "function") {
            props.onBlur(evt);
          }
          setFocused(false);
        }}
        onChange={val => {
          setLocalValue(selectOnChangeValue(val));
        }}
      />
    );
  };

  ComponentWithDebouncedValue.displayName = `withDebouncedValue(${displayName})`;

  return ComponentWithDebouncedValue;
}
