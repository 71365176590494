import React, { ReactNode } from "react";

import FileReaderInput from "../../FileReaderInput";
import { AttributeValueInputProps } from "../index";

export default function FileInput({
  fieldOptions,
  form,
  sourceName,
  value,
  onFocus,
  onBlur
}: AttributeValueInputProps) {
  const decorator = form
    ? form.getFieldDecorator(sourceName, {
        ...fieldOptions,
        initialValue: value
      })
    : (comp: ReactNode) => comp;
  return <>{decorator(<FileReaderInput onFocus={onFocus} onBlur={onBlur} />)}</>;
}
