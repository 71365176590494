import React from "react";

import { ThemeProvider } from "styled-components";

import { DarkTheme, DefaultTheme } from "../../../cssConstants";

import { Container } from "./styledComponents";

// used to determine how nested a ThemeContainer is so that
// we can increase specificity on the css overrides in Container
const NestedThemeContext = React.createContext(1); // initial value of 1 adds one styled-component class name
export const useNestedThemeContext = () => React.useContext(NestedThemeContext);

export enum Theme {
  Default = "default",
  Dark = "dark"
}

interface Props {
  theme: Theme;
  children: React.ReactNode;
  className?: string;
  depthOverride?: number;
}

const ThemeContainer = (props: Props) => {
  const depth = useNestedThemeContext();
  const theme = props.theme === Theme.Default ? DefaultTheme : DarkTheme;
  const nextDepth =
    typeof props.depthOverride === "number" ? props.depthOverride + 1 : depth + 1;
  return (
    <NestedThemeContext.Provider value={nextDepth}>
      <ThemeProvider theme={theme}>
        <Container
          depth={depth}
          className={props.className}
          mode={props.theme === Theme.Default ? "light" : "dark"}
        >
          {props.children}
        </Container>
      </ThemeProvider>
    </NestedThemeContext.Provider>
  );
};

export default ThemeContainer;
