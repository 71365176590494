import React from "react";

import { ErrorValues } from "../../../constants";
import { reportException } from "../../util/exceptionReporting";

import { SANDBOX_IFRAME_ID, isJsEvalEnabled as _isJsEvalEnabled } from "./CodeSandbox";

export const EVALUATION_ERROR_PREFIX = "Executing function threw an exception:";
export const EVALUATION_TIMEOUT_ERROR = "Evaluation timed out.";

// max time to allow expression evaluation to run before timing out
const TIMEOUT_LENGTH = 1000;

// When passing `inputState` to the sandbox via postMessage,
// it gets serialized via the structured clone algorithm
// (https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm)
// which removes methods like `toString`. As a result, if a
// value is ErrorValues.permissionDenied, it gets rendered as
// "[object Object]". `getParsedValue` will parse all values
// of `inputState` and replace any permission denied objects with
// its string representation, "(Permission Denied)".
export function getParsedValue(value: any): any {
  const objectConstructor = {}.constructor;
  if (value === null || value === undefined) {
    return value;
  } else if (value === ErrorValues.permissionDenied) {
    return value.toString();
  } else if (Array.isArray(value)) {
    return value.map(val => getParsedValue(val));
  } else if ((value as any).constructor === objectConstructor) {
    return Object.fromEntries(
      Object.entries(value).map(([key, val]) => [key, getParsedValue(val)])
    );
  } else {
    return value;
  }
}

export default function useCodeSandbox() {
  const activeMessageChannels = React.useRef<MessageChannel[]>([]);
  const timeouts = React.useRef<any[]>([]);

  React.useEffect(() => {
    // clean up when hook is unmounted
    const channels = activeMessageChannels.current;
    const handles = timeouts.current;
    return () => {
      channels.forEach(channel => {
        channel.port1.close();
        channel.port2.close();
      });
      handles.forEach(handle => {
        clearTimeout(handle);
      });
    };
  }, []);

  const getMessageChannel = React.useCallback(() => {
    const channel = new MessageChannel();
    activeMessageChannels.current.push(channel);
    return channel;
  }, []);

  const closePort = React.useCallback((channel: MessageChannel) => {
    channel.port1.close();
    channel.port2.close();
    const i = activeMessageChannels.current.findIndex(current => channel === current);
    activeMessageChannels.current.splice(i, 1);
  }, []);

  const handleMessage = React.useCallback(
    (
      res: (value?: unknown) => void,
      rej: (reason?: any) => void,
      message: any,
      channel: MessageChannel,
      batch = false
    ) => {
      const sandboxFrame: HTMLIFrameElement = document.getElementById(
        SANDBOX_IFRAME_ID
      ) as HTMLIFrameElement;

      if (!sandboxFrame) {
        rej("Sandbox is not available.");
      }
      // listen for sandbox message response
      channel.port1.onmessage = event => {
        closePort(channel);
        if (batch) {
          res(event.data.result);
          return;
        }
        if (event.data.error) {
          rej(event.data.error);
        } else {
          res(event.data.result);
        }
      };
      sandboxFrame.contentWindow?.postMessage(message, "*", [channel.port2]);
    },
    [closePort]
  );

  const getNewTimeoutPromise = React.useCallback(
    (channel: MessageChannel, timeout = TIMEOUT_LENGTH) => {
      return new Promise((_res, rej) => {
        const handle = setTimeout(() => {
          rej(EVALUATION_TIMEOUT_ERROR);
          closePort(channel);
        }, timeout);
        timeouts.current.push(handle);
      });
    },
    [closePort]
  );

  const evaluateExpression = React.useCallback(
    (expression: string, inputState: any) => {
      const channel = getMessageChannel();
      const evalPromise = new Promise((res, rej) => {
        const message = {
          expression,
          inputState: getParsedValue(inputState || {})
        };
        handleMessage(res, rej, message, channel);
      });
      return Promise.race([evalPromise, getNewTimeoutPromise(channel)]);
    },
    [handleMessage, getMessageChannel, getNewTimeoutPromise]
  );

  const evaluateExpressions = React.useCallback(
    (expressions: string[], inputStates: any[], isBatch = false) => {
      const channel = getMessageChannel();
      const evalPromise = new Promise((res, rej) => {
        let message;
        // handle case where same expression needs to be evaluated with mult inputStates (ie. select component)
        if (expressions.length === 1 && !isBatch) {
          message = {
            expression: expressions[0],
            inputStates: inputStates.map(state => getParsedValue(state))
          };
        } else if (expressions.length === inputStates.length) {
          message = {
            expressions,
            inputStates: inputStates.map(state => getParsedValue(state))
          };
        } else {
          rej(
            "Unexpected `expressions` length. Expected single expression or same number of expressions and inputStates."
          );
        }
        handleMessage(res, rej, message, channel, true);
      });
      return Promise.race([
        evalPromise,
        getNewTimeoutPromise(channel, TIMEOUT_LENGTH * expressions.length)
      ]).catch(err => {
        reportException(err, { extra: { expressions, count: expressions.length } });
        throw err;
      });
    },
    [handleMessage, getMessageChannel, getNewTimeoutPromise]
  );

  const isJsEvalEnabled = _isJsEvalEnabled();

  const getConsoleError = React.useCallback((e: unknown) => {
    if (typeof e === "string") {
      if (e.indexOf(EVALUATION_ERROR_PREFIX) === 0) {
        return `JS eval: ${e.substring(EVALUATION_ERROR_PREFIX.length)}`;
      } else if (e.indexOf(EVALUATION_TIMEOUT_ERROR) > -1) {
        return `JS eval: ${e}`;
      }
    }
    return e;
  }, []);

  return {
    evaluateExpression,
    evaluateExpressions,
    isJsEvalEnabled,
    getConsoleError
  };
}
