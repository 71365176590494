import React from "react";

import produce from "immer";

import { AttributeTypes } from "../../../../../../constants";
import { DebouncedAttributeInput } from "../../../../../common/AttributeInput";
import { stringToTemplate, templateToString } from "../../../../../common/utils";
import { BindingCascader } from "../BindingCascader";

import { ManagedConditionalExpression } from "./types";
import { getObjectMode } from "./util";

interface Props {
  expression: ManagedConditionalExpression;
  onChange: (expression: ManagedConditionalExpression) => void;
}

export default function ConditionalObject({ expression, onChange }: Props) {
  const mode = getObjectMode(expression.object_template);
  const onBindingPathChange = (path: string) => {
    const updated = {
      ...expression,
      object_template: path
    };
    onChange(updated);
  };

  const handleChange = (value: any) => {
    onChange(
      produce(expression, (nextConditional: ManagedConditionalExpression) => {
        nextConditional.object_template = value;
      })
    );
  };

  if (mode === "void") return null;

  if (mode === "binding") {
    return (
      <BindingCascader
        placeholder="Select a value"
        value={expression.object_template || ""}
        onChange={onBindingPathChange}
        data-test="bindingSelect"
      />
    );
  }

  if (mode === "text") {
    /* Use an attribute input for text values including `s to treat as template expression */
    return (
      <DebouncedAttributeInput
        verbose
        sourceType={AttributeTypes.STRING}
        sourceName="value"
        sourceNullable={false}
        required={false}
        placeholder="Enter a value"
        value={templateToString(expression.object_template)}
        onChange={val => handleChange(stringToTemplate(val))}
      />
    );
  }

  return null;
}
