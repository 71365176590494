import gql from "graphql-tag";

import { RelayNode } from "../../../types";

export interface SSOStateVars {
  email: string;
}

export interface SSOStateData {
  ssoData: {
    required: boolean;
    url: string;
  };
}

export const GET_SSO_STATE = gql`
  query GetSSOState($email: String!) {
    ssoData(email: $email) {
      required
      url
    }
  }
`;

export interface LoginVars {
  email: string;
  password: string;
}

export interface LoginUser extends RelayNode {
  hasDevice: boolean;
  email: string;
  organization: RelayNode;
}

export interface LoginData {
  authLogin: {
    user: LoginUser;
  };
}

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    authLogin(email: $email, password: $password) {
      user {
        id
        hasDevice
        email
        organization {
          id
        }
      }
    }
  }
`;
