import { StylesConfig } from "react-select";

import {
  colorTokens,
  GlobalStyleVariables,
  zIndex
} from "../../../../../../cssConstants";
import { Option } from "../HardcodedOptionFields/utils";

export const getCustomStyles = (
  minWidth: number,
  isValid: boolean,
  isMulti?: boolean
): StylesConfig<Option, false> => {
  const dropdownHeight = GlobalStyleVariables.inputHeight;
  const dropdownInnerHeight = `calc(${GlobalStyleVariables.inputHeight} - 2px)`;
  return {
    control: (provided: Record<string, any>) => ({
      ...provided,
      height: !isMulti ? dropdownHeight : provided.height, // height settings will need to be adjusted if/when we support multi-select
      minHeight: dropdownHeight,
      boxShadow: "none",
      minWidth: `${minWidth}px`,
      borderColor: isValid ? provided.borderColor : colorTokens.red300,
      "&:hover": {
        borderColor: isValid ? colorTokens.purple400 : colorTokens.red300
      },
      maxHeight: "100%",
      overflow: isMulti ? "auto" : provided.overflow
    }),
    menuPortal: (provided: Record<string, any>) => ({
      ...provided,
      zIndex: zIndex.popoverOverDrawer
    }),
    indicatorSeparator: (_: Record<string, any>) => ({ display: "none" }),
    indicatorsContainer: (provided: Record<string, any>) => ({
      ...provided,
      height: dropdownInnerHeight,
      lineHeight: dropdownInnerHeight
    }),
    clearIndicator: (provided: Record<string, any>) => ({
      ...provided,
      paddingRight: 0,
      display: isMulti ? "none" : provided.display
    }),
    dropdownIndicator: (provided: Record<string, any>) => ({
      ...provided,
      paddingLeft: 0,
      display: isMulti ? "none" : provided.display
    }),
    valueContainer: (provided: Record<string, any>) => ({
      ...provided,
      padding: "0 0 0 8px"
    }),
    placeholder: (provided: Record<string, any>) => ({
      ...provided,
      height: dropdownHeight,
      lineHeight: dropdownHeight
    }),
    singleValue: (provided: Record<string, any>) => ({
      ...provided,
      height: dropdownInnerHeight,
      lineHeight: dropdownInnerHeight
    })
  };
};

export const customTheme = (theme: Record<string, any>) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: colorTokens.purpleTransparent200,
    primary50: colorTokens.purpleTransparent200,
    primary75: colorTokens.purpleTransparent200,
    primary: colorTokens.purple400,
    danger: colorTokens.red300
  }
});
