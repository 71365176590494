import React from "react";

import { Dropdown, Menu } from "antd";

import { userToInitials } from "../../../../../common/Avatar/Avatar";
import useAuthUser from "../../../../../common/hooks/useAuthUser";
import { confirm } from "../../../../../common/Modal";
import TextWithLinks from "../../../../../common/TextWithLinks/TextWithLinks";
import { useColorConverter } from "../../../../../common/useColorCoverter/useColorCoverter";
import { fromGlobalId } from "../../../../../util/graphql";
import { CommentNode } from "../queries";

import * as Styled from "./styledComponents";

export interface Props {
  disabled?: boolean;
  comment: CommentNode;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

export function Comment({ comment, disabled, onEditClick, onDeleteClick }: Props) {
  const name =
    comment.author.firstName + " " + comment.author.lastName.slice(0, 1) + ".";

  const { authUser, loading, isAdmin } = useAuthUser();

  const allowed = React.useMemo(() => {
    if (!authUser) {
      return false;
    }

    // Convert the id to just the uuid part because the comment author id comes in that way
    const uuid = fromGlobalId(authUser.id)[1];

    return isAdmin || uuid === comment.author.id;
  }, [authUser, isAdmin, comment.author.id]);

  const menu = React.useMemo(() => {
    return (
      <Menu>
        <Styled.MoreMenuItem key="edit" onClick={() => onEditClick?.()}>
          Edit
        </Styled.MoreMenuItem>
        <Styled.MoreMenuItem
          key="delete"
          onClick={() =>
            confirm({
              onOk: () => onDeleteClick?.(),
              title: "Are you sure?",
              content: "This will delete the comment",
              okText: "Delete",
              cancelText: "Cancel "
            })
          }
        >
          Delete comment
        </Styled.MoreMenuItem>
      </Menu>
    );
  }, [onEditClick, onDeleteClick]);

  const color = useColorConverter(comment.author.id);

  if (loading) {
    return null;
  }

  return (
    <Styled.Container disabled={disabled}>
      <Styled.Header>
        <Styled.Avatar color={color}>{userToInitials(comment.author)}</Styled.Avatar>
        <Styled.CommentBy>{name}</Styled.CommentBy>
        <Styled.Timestamp isoDate={comment.createdAt} />
        {allowed && (
          <Dropdown disabled={disabled} overlay={menu} trigger={["click"]}>
            <Styled.MoreIcon type="more" />
          </Dropdown>
        )}
      </Styled.Header>
      <Styled.Content>
        <TextWithLinks text={comment.content} />
      </Styled.Content>
    </Styled.Container>
  );
}
