import styled from "styled-components";

export const TooltipContainer = styled.div`
  border: 1px solid ${props => props.theme.borderGrey};
  background-color: #fff;
  padding: ${props => props.theme.spacersm};
  p {
    margin-bottom: 0;
  }
`;
