import moment from "moment-timezone";

const PUBLISH_BUTTON_FORMAT = "M/D/YYYY hh:mm A";

// Assumes time is in the past, regardless of value
// If time is less than 8 hours ago, return humanized "7 hours ago"
// Otherwise, return in format 10/10/2020 12:34 PM
export default function humanizeDateTimeFromNow(time: string) {
  const createdAtMoment = moment.tz(time, "Etc/GMT").tz(moment.tz.guess());
  const delta = moment.duration(createdAtMoment.diff(moment()));
  // should be negative, positive indicates a potential issue in computer clocks
  if (delta.asMilliseconds() > 0) {
    return createdAtMoment.format(PUBLISH_BUTTON_FORMAT);
  }
  return Math.abs(delta.asHours()) < 8
    ? delta.humanize(true)
    : createdAtMoment.format(PUBLISH_BUTTON_FORMAT);
}
