import { SpaceNode, MaskedSpaceNode, SpaceComponentObject } from "../../../../types";
import { SUPERFICIAL_COMPONENT_TYPES } from "../SpaceComponent/constants";

import { OPAQUE_SPACE_DATA_KEY } from "./StableSpaceContext";

export function maskSpace(space: SpaceNode): MaskedSpaceNode {
  const spaceCopy: Partial<SpaceNode> = { ...(space || ({} as SpaceNode)) };
  const { id, slug, name } = spaceCopy;
  delete spaceCopy.id;
  delete spaceCopy.slug;
  delete spaceCopy.name;

  (spaceCopy as unknown as MaskedSpaceNode)[OPAQUE_SPACE_DATA_KEY] = {
    id: id!,
    slug: slug!,
    name: name!
  };
  return spaceCopy as unknown as MaskedSpaceNode;
}

// This is used for cases where we need to traverse the component tree and not the render tree.
// For example, when we are configuring a dropdown in a modal form. The dropdown
// will not have been rendered yet, so we cannot use the render tree.
export function getAncestorComponents(
  slug: string | undefined,
  components: SpaceComponentObject[]
) {
  if (!slug) {
    return [];
  }
  const ancestorComponents: SpaceComponentObject[] = [];
  let currentSlug: string | undefined = slug;
  const getNode = (slug: string) => components.find(c => c.slug === slug);
  while (currentSlug !== undefined) {
    const currentNode: SpaceComponentObject | undefined = getNode(currentSlug);
    if (!currentNode || SUPERFICIAL_COMPONENT_TYPES.includes(currentNode.type)) {
      break;
    }
    ancestorComponents.unshift(currentNode); // add parent to front of array
    currentSlug = currentNode?.container?.slug;
  }
  return ancestorComponents;
}
