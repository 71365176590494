import { UserNode } from "../../../../../types";

export interface OutputRole {
  name: string;
}

export function getRoleForOutput(user: UserNode | null): OutputRole | undefined {
  const node = user?.roles?.edges[0]?.node;
  return node && { name: node.name };
}
