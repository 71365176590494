import React from "react";

import { Button, Spin } from "antd";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useRowState,
  HeaderGroup,
  Column,
  ColumnInstance,
  Row as RowType,
  TableOptions as TableOptionsType
} from "react-table";

import { SortOrder } from "../../../types";
import useDebouncedValue from "../hooks/useDebouncedValue";
import useObservedRect from "../hooks/useObservedRect";
import useScrollbarWidth from "../hooks/useScrollbarWidth";

import reducer, { initialState } from "./reducer";
import Row from "./Row";
import * as styled from "./styledComponents";
import TableHeader from "./TableHeader";
import useInfiniteScroll from "./useInfiniteScroll";
import useSelectMultiple from "./useSelectMultiple";
import useSyncComponentDefs from "./useSyncComponentDefs";

export type ColumnType = Partial<Column & { hidden: boolean }>;

export type TableOptions = TableOptionsType<object>;

interface Props {
  refetching: boolean;
  fetchingMore: boolean;
  columns: ColumnType[]; // Must be memoized
  data: Object[]; // Must be memoized
  lastRefetch: number;
  hasMoreData: boolean;
  allowSelectMultiple?: boolean;
  sortState?: SortOrder[];
  RowContainer?: React.ComponentType<any>;
  emptyState?: React.ReactNode;
  getRowId: (row: Object, i: number) => string; // Must be memoized
  getRowState: (row: RowType<Object>) => Record<string, unknown>;
  onSort: (attribute: string | null, order?: "ASC" | "DESC") => void;
  onScrollToBottom?: () => void;
  onSelectRows: (ids: string[]) => void;
}

export interface Handle {
  selectRows: (ids: string[]) => void;
  clearSelectedRows: () => void;
}

const MORE_BUTTON_WIDTH = 135;
const _Table: React.RefForwardingComponent<Handle, Props> = (
  {
    refetching,
    fetchingMore,
    columns,
    data,
    lastRefetch,
    hasMoreData,
    allowSelectMultiple = false,
    sortState,
    emptyState,
    RowContainer = children => children,
    getRowId,
    getRowState,
    onSort,
    onScrollToBottom = () => {},
    onSelectRows
  },
  ref: React.Ref<Handle>
) => {
  const scrollbarWidth = useScrollbarWidth();
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    visibleColumns: columns.filter(c => !c.hidden),
    scrollbarWidth,
    allowSelectMultiple
  });
  const rootRef = React.useRef<HTMLDivElement | null>(null);
  const tableRef = React.useRef<HTMLTableElement | null>(null);
  const theadRef = React.useRef<HTMLTableSectionElement | null>(null);
  const tbodyRef = React.useRef<HTMLTableSectionElement | null>(null);

  const rootWidth = useObservedRect(rootRef).width;
  const debouncedRootWidth = useDebouncedValue(rootWidth, 25);
  const colCount = allowSelectMultiple ? columns.length : columns.length + 1;

  const hasEmptyState = data.length === 0 && !!emptyState;
  React.useEffect(() => {
    dispatch({
      type: "RESIZE_TABLE"
    });
  }, [debouncedRootWidth]);

  React.useLayoutEffect(() => {
    dispatch({ type: "LOAD_SCROLLBAR_WIDTH", payload: { scrollbarWidth } });
  }, [scrollbarWidth]);

  React.useLayoutEffect(() => {
    dispatch({ type: "LOAD_COLUMNS", payload: { columns } });
  }, [columns]);

  React.useLayoutEffect(() => {
    dispatch({ type: "SET_MULTI_SELECT", payload: { allowSelectMultiple } });
  }, [allowSelectMultiple]);

  React.useLayoutEffect(() => {
    const rootWidth = rootRef.current?.getBoundingClientRect().width || 0;
    if (!rootRef.current || !theadRef.current || !tbodyRef.current || rootWidth <= 0) {
      return;
    }
    let tds = Array.from(
      tbodyRef.current.querySelectorAll(
        "tr:first-child:not(.loadMoreRow):not(.emptyStateRow) td"
      )
    );
    if (tds.length === 0 && theadRef.current) {
      tds = Array.from(theadRef.current.querySelectorAll("tr:last-child th"));
    }

    dispatch({
      type: "LOAD_TD_WIDTHS",
      payload: {
        naturalTdWidths: tds.map(td => td.getBoundingClientRect().width),
        tableWidth: rootWidth
      }
    });
  }, [state.measureRequest]);

  const hiddenColumnIds = React.useMemo(
    () => columns.filter(c => c.hidden).map(c => c.accessor as string),
    [columns]
  );
  const columnWithWidths = React.useMemo(() => {
    const visibleColLookup = Object.fromEntries(
      state.visibleColumns.map(vc => [vc.accessor, vc])
    );
    return columns.map(c => visibleColLookup[c.accessor as string] || c);
  }, [columns, state.visibleColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleRowSelected,
    toggleAllRowsSelected,
    state: { sortBy, selectedRowIds },
    dispatch: reactTableDispatch,
    setHiddenColumns
  } = useTable(
    {
      columns: columnWithWidths,
      data,
      manualSortBy: true,
      getRowId,
      autoResetSelectedRows: false,
      initialRowStateAccessor: getRowState,
      initialState: {
        allowSelectMultiple,
        hiddenColumns: hiddenColumnIds,
        columnDefs: columns
      },
      stateReducer: (newState: any, action: any) => {
        switch (action.type) {
          case "SET_ALLOW_SELECT_MULTIPLE":
            return {
              ...newState,
              allowSelectMultiple: action.payload.allowSelectMultiple
            };
          case "SET_COLUMN_DEFS":
            return {
              ...newState,
              columnDefs: action.payload.columnDefs
            };
          default:
            return newState;
        }
      }
    } as TableOptions,
    useSortBy,
    useRowSelect,
    useRowState,
    useSelectMultiple,
    useSyncComponentDefs
  );

  React.useEffect(() => {
    reactTableDispatch({
      type: "SET_ALLOW_SELECT_MULTIPLE",
      payload: { allowSelectMultiple }
    });
  }, [allowSelectMultiple, reactTableDispatch]);

  React.useEffect(() => {
    reactTableDispatch({
      type: "SET_COLUMN_DEFS",
      payload: { columnDefs: columns }
    });
  }, [columns, reactTableDispatch]);

  useInfiniteScroll(tableRef, onScrollToBottom);

  React.useEffect(() => {
    setHiddenColumns(hiddenColumnIds);
  }, [hiddenColumnIds, setHiddenColumns]);

  React.useEffect(() => {
    const _selectedRowIds = Object.entries(selectedRowIds)
      .filter(([_, selected]) => selected)
      .map(([rowId, _]) => rowId);
    onSelectRows(_selectedRowIds);
  }, [selectedRowIds, onSelectRows]);

  React.useImperativeHandle(ref, () => ({
    clearSelectedRows: () => {
      toggleAllRowsSelected(false);
    },
    selectRows: (ids: string[]) => {
      ids.forEach(id => {
        const row = rows.find((r: RowType) => r.id === id);
        if (row) {
          toggleRowSelected(id, true);
        }
      });
    }
  }));

  React.useEffect(() => {
    const sort = sortBy[0];
    sort ? onSort(sort.id, sort.desc ? "DESC" : "ASC") : onSort(null);
  }, [sortBy, onSort]);

  React.useLayoutEffect(() => {
    if (!tableRef || !tableRef.current) return;
    tableRef.current.scrollTop = 0;
  }, [lastRefetch]);

  const onRowClick = React.useCallback(
    evt => {
      if (!tbodyRef.current) return;

      const domRow = evt.target.closest("tr");
      if (!domRow) return;
      const rowId = domRow.getAttribute("data-row-id");
      const row = rows.find((r: RowType) => r.id === rowId);
      if (!allowSelectMultiple) {
        toggleAllRowsSelected(false);
        if (row && row.isSelected) return;
      }
      if (!row) return;
      row.toggleRowSelected();
    },
    [allowSelectMultiple, rows, toggleAllRowsSelected]
  );

  rows.forEach(prepareRow);

  return (
    <styled.Root ref={rootRef}>
      {refetching && (
        <styled.LoadingMask>
          <Spin size="large" />
        </styled.LoadingMask>
      )}
      <styled.Table
        {...getTableProps()}
        ref={tableRef}
        className={hasEmptyState ? "emptyTable" : ""}
        onMouseDown={evt => {
          // <Draggable /> begins tracking drags by listening for mouse downs,
          // but dragging the internal scroll bars of a table should not
          // be interpretted as a drag intended for <Draggable />, so cancel
          // propogation on those events. Detect whether a mousedown is on a
          // scrollbar by checking if the event target is the table itself
          // rather than a th or td. Normal mouse downs on a table will have
          // the cells themselves as the event target.
          if (evt.target === tableRef.current) {
            evt.stopPropagation();
          }
        }}
      >
        <thead
          ref={theadRef}
          className={headerGroups.length > 1 ? "hasMultipleHeaderGroups" : ""}
        >
          {headerGroups.map(({ headers, getHeaderGroupProps }: HeaderGroup) => (
            <tr {...getHeaderGroupProps()}>
              {headers.map((col: ColumnInstance) => {
                const sort = sortState && sortState.find(s => s.sourceName === col.id);
                const columnWithSort = sort
                  ? {
                      ...col,
                      isSorted: true,
                      isSortedDesc: sort.direction === "DESC"
                    }
                  : col;
                return (col as any).hidden ? null : (
                  <th
                    {...col.getHeaderProps(col.getSortByToggleProps())}
                    style={{
                      minWidth: col.minWidth
                    }}
                  >
                    {(col as any).render(({ column }: { column: ColumnInstance }) => (
                      <TableHeader key={column.id} column={columnWithSort} />
                    ))}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody ref={tbodyRef} {...getTableBodyProps()}>
          {rows.map((row: RowType, idx: number) => (
            <RowContainer key={`${row.id}-${idx}`} index={row.index} row={row}>
              <Row
                {...(row.getRowProps() || {})}
                row={row}
                isSelected={row.isSelected}
                onClick={onRowClick}
              />
            </RowContainer>
          ))}
          {hasEmptyState && (
            <tr className="emptyStateRow">
              <td colSpan={colCount} className="emptyStateCell">
                {emptyState}
              </td>
            </tr>
          )}
          <tr className="loadMoreRow">
            <td colSpan={colCount}>
              <div
                className="loadMoreContainer"
                style={{ marginLeft: rootWidth / 2 - MORE_BUTTON_WIDTH / 2 }}
              >
                <Button
                  disabled={!hasMoreData}
                  style={{ width: MORE_BUTTON_WIDTH }}
                  type="primary"
                  icon="reload"
                  shape="round"
                  loading={fetchingMore}
                  onClick={onScrollToBottom}
                >
                  Load More
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </styled.Table>
    </styled.Root>
  );
};

const Table = React.forwardRef(_Table);
Table.displayName = "Table";

export default React.memo(Table);
