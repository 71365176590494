import gql from "graphql-tag";

import { Connection, FiltersOption, RelayNode } from "../../../../../../types";

interface _Metadata {
  filters: { options: FiltersOption[] };
}

export interface SqlActionResourceNode extends RelayNode {
  name: string;
  sourceIdentifier: string;
  slug: string;
  metadata: _Metadata;
}

export interface FetchResourcesAndAttributesByDataSourceIdData {
  dataSource: {
    id: string;
    name: string;
    resources: Connection<SqlActionResourceNode>;
  };
}

export const FETCH_RESOURCES_BY_DATA_SOURCE_ID = gql`
  query ($dataSourceNodeId: ID!) {
    dataSource(id: $dataSourceNodeId) {
      id
      name
      resources {
        edges {
          node {
            id
            name
            sourceIdentifier
            slug
            metadata
          }
        }
      }
    }
  }
`;
