import { clone } from "lodash";
import { v4 as uuid } from "uuid";

import { EnvironmentNode } from "../../../types";

export interface ManageEnvironmentsState {
  environments: EnvironmentNode[];
  submitted: boolean;
}

export type ManageEnvironmentsAction =
  | { type: "SUBMIT" }
  | {
      type: "INITIALIZE_ENVIRONMENTS";
      payload: { environments: EnvironmentNode[] };
    }
  | {
      type: "SET_ENVIRONMENT";
      payload: { id: string; values: Partial<EnvironmentNode> };
    }
  | {
      type: "REMOVE_ENVIRONMENT";
      payload: {
        index: number;
      };
    }
  | { type: "ADD_ENVIRONMENT" };

export const initialState: ManageEnvironmentsState = {
  environments: [],
  submitted: false
};

export function getError(state: ManageEnvironmentsState) {
  const names = state.environments.map(e => e.name);
  if (names.length !== names.filter(n => !!n).length)
    return "You must name each environment.";
  return new Set(names).size !== names.length
    ? "You cannot have multiple environments with the same name."
    : "";
}

function reducer(
  state: ManageEnvironmentsState,
  action: ManageEnvironmentsAction
): ManageEnvironmentsState {
  switch (action.type) {
    case "INITIALIZE_ENVIRONMENTS": {
      const { environments } = action.payload;
      return { ...state, environments };
    }
    case "ADD_ENVIRONMENT": {
      const clonedEnvs = clone(state.environments);
      clonedEnvs.push({
        id: `temp-${uuid()}`,
        name: "",
        isDefault: false,
        dataSourceCount: 0
      } as EnvironmentNode);
      return { ...state, environments: clonedEnvs };
    }
    case "REMOVE_ENVIRONMENT": {
      const { index } = action.payload;
      const clonedEnvs = clone(state.environments);
      clonedEnvs.splice(index, 1);
      return {
        ...state,
        environments: clonedEnvs
      };
    }
    case "SET_ENVIRONMENT": {
      const { values, id } = action.payload;
      const clonedEnvs = clone(state.environments);
      return {
        ...state,
        environments: clonedEnvs.map(e => {
          if (e.id === id) {
            return {
              ...e,
              ...values
            };
          }
          return e;
        })
      };
    }
    case "SUBMIT": {
      return { ...state, submitted: true };
    }
    default:
      return state;
  }
}

export default reducer;
