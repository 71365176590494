import {
  SourceType,
  SpaceComponentObject,
  ConfigValidationError
} from "../../../../../../../types";
import { ComponentConfigState } from "../../../../../types";
import selectDefaultValueErrors from "../../DefaultValueField/selectErrors";
import { ensureFilterListManagerState } from "../../FilterListManager/reducer/reducer";
import selectFiltersErrors from "../../FilterListManager/selectErrors";
import selectParameterErrors from "../../ParametersManager/selectors/selectErrors";
import { selectViewConfigErrors } from "../../useViewConfig";
import selectValidationRulesErrors from "../../ValidationField/selectValidationRulesErrors";
import selectVisibilityRuleErrors from "../../VisibilityRulesManager/selectErrors";

export default function errorSelector(
  state: ComponentConfigState,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const { draftComponent } = state;
  let errors: ConfigValidationError[] = selectDefaultValueErrors(draftComponent);

  if (draftComponent.sourceType === SourceType.VIEW) {
    errors = errors.concat(selectViewConfigErrors(draftComponent));
  }

  return errors
    .concat(
      selectFiltersErrors(
        ensureFilterListManagerState(state).filterValidityTrackers,
        draftComponent,
        findInvalidInputBindings
      )
    )
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings))
    .concat(selectValidationRulesErrors(draftComponent));
}
