import styled from "styled-components";

import { colors } from "../../../cssConstants";

export const IndeterminateState = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 22px;
  background-color: ${colors.primaryAccent};

  vertical-align: middle;

  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 12px;
  cursor: pointer;
`;

export const Dash = styled.div`
  background-color: white;
  height: 0px;
  width: 10px;
  border: 1px solid white;
  border-radius: 2px;
`;
