import React from "react";

import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MutationType } from "../../SpaceConfigContext/useSpaceConfig";

export enum Status {
  UNSAVED = 1,
  SAVING = 2,
  SAVE_SUCCESSFUL = 3,
  SAVE_UNSUCCESSFUL = 4
}

export interface Result {
  status: Status;
  reset: () => void;
}

interface SaveStatusState {
  status: Status;
}

type SaveStatusAction =
  | { type: "HANDLE_LOADING" }
  | { type: "HANDLE_SAVE" }
  | { type: "HANDLE_ERROR_ON_SAVE" }
  | { type: "HANDLE_CLEAR_ERRORS" }
  | { type: "HANDLE_SPACE_EDIT" };

function reducer(state: SaveStatusState, action: SaveStatusAction) {
  switch (action.type) {
    case "HANDLE_LOADING": {
      return { status: Status.SAVING };
    }
    case "HANDLE_SAVE": {
      return state.status === Status.SAVING
        ? { status: Status.SAVE_SUCCESSFUL }
        : state;
    }
    case "HANDLE_ERROR_ON_SAVE": {
      return state.status === Status.SAVING
        ? { status: Status.SAVE_UNSUCCESSFUL }
        : state;
    }
    case "HANDLE_SPACE_EDIT": {
      return state.status === Status.SAVE_SUCCESSFUL ||
        state.status === Status.SAVE_UNSUCCESSFUL
        ? { status: Status.UNSAVED }
        : state;
    }
    case "HANDLE_CLEAR_ERRORS": {
      return state.status === Status.SAVE_UNSUCCESSFUL
        ? { status: Status.UNSAVED }
        : state;
    }
    default:
      return state;
  }
}

export default function useSaveStatus() {
  const [state, dispatch] = React.useReducer(reducer, {
    status: Status.UNSAVED
  });
  const { state: configState, mutationLoading } = useSpaceConfigContext();

  React.useEffect(() => {
    if (mutationLoading === MutationType.SAVE) {
      dispatch({ type: "HANDLE_LOADING" });
    }
  }, [mutationLoading]);

  React.useEffect(() => {
    if (!configState.dirty && mutationLoading !== MutationType.SAVE) {
      dispatch({ type: "HANDLE_SAVE" });
    }
  }, [configState.dirty, mutationLoading, state.status]);

  React.useEffect(() => {
    if (
      mutationLoading !== MutationType.SAVE &&
      Object.keys(configState.apiErrors).length > 0
    ) {
      dispatch({ type: "HANDLE_ERROR_ON_SAVE" });
    }
  }, [configState.apiErrors, mutationLoading, state.status]);

  React.useEffect(() => {
    if (configState.dirty) {
      dispatch({ type: "HANDLE_SPACE_EDIT" });
    }
  }, [state.status, configState.dirty]);

  React.useEffect(() => {
    if (Object.keys(configState.apiErrors).length === 0) {
      dispatch({ type: "HANDLE_CLEAR_ERRORS" });
    }
  }, [state.status, configState.apiErrors]);

  return {
    status: state.status,
    reset: React.useCallback(() => dispatch({ type: "HANDLE_SPACE_EDIT" }), [])
  };
}
