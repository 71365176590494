import React from "react";

import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import { useComponentContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentContext";
import {
  useComponentLayoutContext,
  useLayoutContextDispatcher
} from "../LayoutContext/LayoutContext";
import { ElementLayout, LayoutUnit, parseCssUnit, DOMRectsEqual } from "../util";

export default function useElementTransformer() {
  const {
    component: { slug }
  } = useComponentContext();
  const {
    state: { elementLayouts },
    dispatch: spaceConfigDispatch
  } = useSpaceConfigContext();
  const elementLayout = elementLayouts.get(slug);
  const { dimensionsContextDOMRect, elementDOMRect, dimensionsContextKey } =
    useComponentLayoutContext();
  const layoutDispatch = useLayoutContextDispatcher();

  const toRelativePoint = React.useCallback(
    function toRelativePoint(pixelPoint: DOMPoint) {
      return new DOMPoint(
        (pixelPoint.x / dimensionsContextDOMRect.width) * 100,
        (pixelPoint.y / dimensionsContextDOMRect.height) * 100
      );
    },
    [dimensionsContextDOMRect]
  );

  const toAbsoluteWidth = React.useCallback(
    function toAbsoluteWidth(relativeWidth) {
      return (relativeWidth * dimensionsContextDOMRect.width) / 100;
    },
    [dimensionsContextDOMRect]
  );

  const toAbsoluteHeight = React.useCallback(
    function toAbsoluteHeight(relativeHeight) {
      return (relativeHeight * dimensionsContextDOMRect.height) / 100;
    },
    [dimensionsContextDOMRect]
  );

  const toAbsolutePoint = React.useCallback(
    function toAbsolutePoint(relativePoint: DOMPoint) {
      return new DOMPoint(
        toAbsoluteWidth(relativePoint.x),
        toAbsoluteHeight(relativePoint.y)
      );
    },
    [toAbsoluteHeight, toAbsoluteWidth]
  );

  // Given an absolute layout, generates a layout converting to target units
  const convertLayout = React.useCallback(
    (
      sourceLayout,
      targetUnits = {
        width: LayoutUnit.PIXEL,
        height: LayoutUnit.PIXEL,
        top: LayoutUnit.PIXEL,
        left: LayoutUnit.PIXEL
      }
    ) => {
      const toConvert = {
        width: sourceLayout.width,
        height: sourceLayout.height,
        top: sourceLayout.top,
        left: sourceLayout.left
      };
      ["width", "height", "top", "left"].forEach(d => {
        const sourceUnit = parseCssUnit(sourceLayout[d] || "0" + LayoutUnit.PIXEL);
        const targetUnit = targetUnits[d] || LayoutUnit.PIXEL;
        if (sourceUnit !== targetUnit) {
          let convert: (val: DOMPoint) => DOMPoint = val => val;
          switch (targetUnit) {
            case LayoutUnit.PIXEL:
              convert = toAbsolutePoint;
              break;
            case LayoutUnit.PERCENTAGE:
              convert = toRelativePoint;
              break;
            case LayoutUnit.AUTO:
              break;
            default:
              throw new Error("Unexpected unit conversion");
          }
          if (toConvert[d as keyof typeof toConvert] === LayoutUnit.AUTO) {
            return;
          }
          if (d === "width" || d === "left") {
            toConvert[d] =
              targetUnit === LayoutUnit.AUTO
                ? LayoutUnit.AUTO
                : `${
                    convert(new DOMPoint(parseFloat(toConvert[d]), 0)).x
                  }${targetUnit}`;
          } else if (d === "height" || d === "top") {
            toConvert[d] =
              targetUnit === LayoutUnit.AUTO
                ? LayoutUnit.AUTO
                : `${
                    convert(new DOMPoint(0, parseFloat(toConvert[d]))).y
                  }${targetUnit}`;
          }
        }
      });
      const merged = {
        ...sourceLayout,
        ...toConvert
      };

      return new ElementLayout(merged);
    },
    [toRelativePoint, toAbsolutePoint]
  );

  const layout = React.useMemo(() => {
    return new ElementLayout(elementLayout);
  }, [elementLayout]);

  const transform = React.useCallback(
    (layoutChanges: Partial<ElementLayout>) => {
      spaceConfigDispatch({
        type: "UPDATE_COMPONENT_LAYOUT",
        payload: { slug, layout: layoutChanges }
      });

      const changedLayout = new ElementLayout({ ...layout, ...layoutChanges });
      const pxLayout = convertLayout(changedLayout);
      if (pxLayout.width === LayoutUnit.AUTO) {
        pxLayout.width = `${elementDOMRect.width}px`;
      }

      if (pxLayout.height === LayoutUnit.AUTO) {
        pxLayout.height = `${elementDOMRect.height}px`;
      }

      layoutDispatch({
        type: "UPDATE_ELEMENT_DOM_RECT",
        payload: { slug, rect: pxLayout.toRect() }
      });
    },
    [slug, layout, elementDOMRect, spaceConfigDispatch, layoutDispatch, convertLayout]
  );

  const changeLayoutUnit = React.useCallback(
    (field: "width" | "height" | "top" | "left", unit: LayoutUnit) => {
      // Construct a Layout in px, converting AUTO if needed
      let pxLayout = new ElementLayout(elementLayout);
      if (pxLayout.width === LayoutUnit.AUTO) {
        pxLayout.width = elementDOMRect.width + LayoutUnit.PIXEL;
      }
      if (pxLayout.height === LayoutUnit.AUTO) {
        pxLayout.height = elementDOMRect.height + LayoutUnit.PIXEL;
      }
      pxLayout = convertLayout(pxLayout);

      // Convert the field whose unit changed
      const targetUnits = {
        width: parseCssUnit(elementLayout?.width),
        height: parseCssUnit(elementLayout?.height),
        top: parseCssUnit(elementLayout?.top || "0" + LayoutUnit.PIXEL),
        left: parseCssUnit(elementLayout?.left || "0" + LayoutUnit.PIXEL),
        [field]: unit
      };
      const convertedLayout = convertLayout(pxLayout, targetUnits);
      transform(convertedLayout);
    },
    [elementLayout, elementDOMRect, convertLayout, transform]
  );

  const result = React.useMemo(() => {
    return {
      layoutContextReady:
        !!dimensionsContextKey &&
        !DOMRectsEqual(dimensionsContextDOMRect, new DOMRect()),
      layout,
      toRelativePoint,
      convertLayout,
      transform,
      changeLayoutUnit
    };
  }, [
    dimensionsContextKey,
    dimensionsContextDOMRect,
    layout,
    toRelativePoint,
    convertLayout,
    transform,
    changeLayoutUnit
  ]);

  return result;
}
