import React from "react";

import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import { Headline } from "../common/styledComponents";

import { useTasksCompleted } from "./queries";

export default function TasksCompletedStatistics({
  queueId,
  withinSeconds,
  className
}: {
  queueId?: string;
  withinSeconds: number;
  className?: string;
}) {
  const { data, loading, error } = useTasksCompleted({
    variables: { queueId: queueId!, withinSeconds },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="Tasks completed"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
    >
      <Headline>{data?.tasksCompleted}</Headline>
    </AnalyticCard>
  );
}
