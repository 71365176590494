import React from "react";

import { Select, Icon } from "antd";
import styled from "styled-components";

import { ErrorValues } from "../../../../../../constants";
import {
  ViewFilterOperatorDisplayNames,
  ViewFilterOperator,
  FiltersOption,
  ViewFilter
} from "../../../../../../types";

import { PendingFilter } from "./SpaceTableSearchBar";
import { NULL_VALUE } from "./utils";

const DefaultOptionContent = styled.div`
  white-space: normal;
  i {
    margin-right: ${props => props.theme.spacersm};
  }
  &:hover {
    .anticon.anticon-check {
      color: transparent;
    }
  }
`;

export const getDisplayedOperator = (operator?: ViewFilterOperator) =>
  operator ? ViewFilterOperatorDisplayNames[operator] : "";

export const getSearchValues = (
  searchFilters: ViewFilter[],
  attributeMap: { [key: string]: FiltersOption }
) => {
  return searchFilters.map((filter: ViewFilter) => {
    const attribute = attributeMap[filter.sourceName];
    if (!attribute) return null;
    const displayName = attribute.name;
    const operator = getDisplayedOperator(filter.operator);
    const filterValue =
      filter.value === ErrorValues.permissionDenied
        ? "(Permission Denied)"
        : filter.value;

    const label =
      filterValue === null
        ? `${displayName} ${
            filter.operator === ViewFilterOperator.EQUALS ? "is" : "is not"
          } null`
        : `${displayName}${operator}${filterValue}`;
    return {
      key: filter.__clientId,
      label: <strong title={label}>{label}</strong>
    };
  });
};

const getDefaultOption = () => {
  return [
    <Select.Option key="default" className="defautResourceSearchOption">
      <DefaultOptionContent>
        <Icon type="info-circle" theme="filled" />
        Specify the field (column) name, followed by the search term (E.g. id=123) and
        hit Enter to search. Note that search is case sensitive.
      </DefaultOptionContent>
    </Select.Option>
  ];
};

// If there's at least one search parameter and no pending search params, tell user how to search.
// If user has entered a valid attribute, tell user how to complete their search.
// Otherwise, tell user they must enter a valid column.
export const getNoMatchOption = (
  searchFilters: ViewFilter[],
  pendingFilter: PendingFilter
) => {
  if (
    searchFilters.length > 0 &&
    !pendingFilter.sourceName &&
    !pendingFilter.attributeNameDraft
  ) {
    return "Press enter to search. Or add more search terms.";
  }
  if (pendingFilter.sourceName) {
    return "Enter a value to complete your search.";
  }
  return "You must specify a valid field (column).";
};

const getNullOption = (
  attribute: FiltersOption,
  operator: ViewFilterOperator,
  label: string
) => (
  <Select.Option
    data-test="partialSearchOption"
    key={`${attribute.sourceName}${getDisplayedOperator(operator)}${NULL_VALUE}`}
  >
    {`${attribute.name} ${label}`}
  </Select.Option>
);

export const getOptions = (
  searchFilters: ViewFilter[],
  pendingFilter: PendingFilter,
  attributeMap: { [key: string]: FiltersOption },
  filtersOptions: FiltersOption[]
) => {
  if (pendingFilter.sourceName) {
    const attribute = attributeMap[pendingFilter.sourceName];
    if (!attribute) return null;
    if (!pendingFilter.operator) {
      const orderedOperators = [...attribute.operators];
      const icontainsIdx = orderedOperators.indexOf(ViewFilterOperator.ICONTAINS);
      // ensure icontains operator is always at start of list of operators
      if (icontainsIdx > -1) {
        const removed = orderedOperators.splice(icontainsIdx, 1);
        orderedOperators.unshift(...removed);
      }
      const operatorOptions = orderedOperators.map(operator => {
        const key = `${attribute.sourceName}${getDisplayedOperator(operator)}`;
        return (
          <Select.Option data-test="partialSearchOption" key={key}>
            {`${attribute.name}${getDisplayedOperator(operator)}`}
          </Select.Option>
        );
      });

      // add null options if underlying operators are supported
      if (orderedOperators.includes(ViewFilterOperator.EQUALS)) {
        operatorOptions.push(
          getNullOption(attribute, ViewFilterOperator.EQUALS, "is null")
        );
      }
      if (orderedOperators.includes(ViewFilterOperator.NOT_EQUALS)) {
        operatorOptions.push(
          getNullOption(attribute, ViewFilterOperator.NOT_EQUALS, "is not null")
        );
      }
      return operatorOptions;
    } else {
      const tempOption = (
        <Select.Option
          data-test="completedSearchOption"
          key={`${attribute.sourceName}${getDisplayedOperator(pendingFilter.operator)}`}
          value="tempOption"
        >
          {`${attribute.name}${getDisplayedOperator(pendingFilter.operator)}${
            pendingFilter.value || ""
          }`}
        </Select.Option>
      );
      return [tempOption];
    }
  }
  if (pendingFilter.attributeNameDraft) {
    return filtersOptions
      .filter(attr => attr.operators.length > 0)
      .map(attr => {
        return (
          <Select.Option data-test="attributeSearchOption" key={attr.sourceName}>
            {attr.name}
          </Select.Option>
        );
      });
  } else if (!searchFilters.length) {
    return getDefaultOption();
  } else {
    return [];
  }
};
