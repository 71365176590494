import React, { useMemo } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useSearchParams } from "react-router-dom";

import { useConfigContext } from "../../../ConfigContext";

import AccountBillingSettings from "./AccountBillingSettings";

const BillingSettingsContainer = () => {
  const { stripePublicKey } = useConfigContext();
  const [searchParams] = useSearchParams();
  const paymentIntentClientSecret = searchParams.get("paymentIntentClientSecret");

  const stripePromise = useMemo(() => {
    return loadStripe(stripePublicKey);
  }, [stripePublicKey]);

  const stripeOptions: { clientSecret?: string | undefined; options: any } = {
    // passing the client secret obtained from the server
    options: {
      appearance: {
        theme: "stripe"
      }
    }
  };

  return (
    <>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <AccountBillingSettings paymentIntentClientSecret={paymentIntentClientSecret} />
      </Elements>
    </>
  );
};

export default BillingSettingsContainer;
