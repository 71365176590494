import React from "react";

import styled from "styled-components";

import { Props } from "..";
import { DimensionsContextContainer } from "../../../layout/DimensionsContext";
import { useStableSpaceContext } from "../../SpaceContext";
import Panel from "../common/Panel";
import Row from "../common/Row";
import useRefreshComponent from "../common/useRefreshComponent";
import {
  useViewWithComponentColumns,
  QueryExecutionRequirement,
  viewRowToStateRow
} from "../common/useView";
import ViewComponentTitle from "../common/ViewComponentTitle/ViewComponentTitle";
import ViewEnsurer from "../common/ViewEnsurer/ViewEnsurer";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { ensureSpaceDetailComponent } from "./Config/reducer";
import { SpaceDetailState } from "./types";

const StyledPanel = styled(Panel)`
  dd:only-child {
    width: 100%;
  }

  div.spaceComponent > div.spaceButtonRoot {
    display: flex;
    justify-content: center;

    button {
      max-width: 200px;
      overflow: hidden;
    }
  }
`;

const DetailContainer = styled.div`
  height: 100%;
  padding: ${props => props.theme.spacerlg};
`;

const Table = styled.table`
  width: 100%;
`;
const WrapperComponent = styled.tr``;
const LabelTd = styled.td`
  padding-right: ${props => props.theme.spacersm};
  padding-bottom: ${props => props.theme.spacermd};
  font-size: ${props => props.theme.tinyFontSize};
  line-height: 21px;
  color: ${props => props.theme.textColorMid};
  text-transform: uppercase;
`;
const LabelContainer = styled.div`
  max-width: 200px;
`;

const ValueComponent = styled.td`
  padding-bottom: ${props => props.theme.spacermd};

  & > *:first-child {
    flex-grow: 1;
  }
`;

const LabelComponent = (props: { children: React.ReactNode }) => {
  return (
    <LabelTd>
      <LabelContainer>{props.children}</LabelContainer>
    </LabelTd>
  );
};

export default function SpaceDetail(props: Props) {
  const detailComponent = ensureSpaceDetailComponent(props.spaceComponent);
  const { input, updateOutput, recursivelyClearOutput } = useComponentStateContext();
  const { spaceId } = useStableSpaceContext();
  const viewResult = useViewWithComponentColumns(spaceId, detailComponent, input, {
    limit: 1,
    queryExecutionRequirement: !!detailComponent?.properties?.is_filter_required
      ? QueryExecutionRequirement.ANY_FILTER
      : QueryExecutionRequirement.NONE
  });
  const { rows, refresh } = viewResult;

  const refreshAndClear = React.useCallback(() => {
    refresh();
    recursivelyClearOutput();
  }, [refresh, recursivelyClearOutput]);
  useRefreshComponent(props.spaceComponent, props.spaceApi, refreshAndClear);

  const viewRow = (rows && rows[0]) || undefined;
  React.useEffect(() => {
    updateOutput(viewRowToStateRow(viewRow) as SpaceDetailState);
  }, [viewRow, updateOutput]);

  return (
    <StyledPanel
      title={
        detailComponent.properties.is_header_enabled && (
          <ViewComponentTitle component={detailComponent} viewResult={viewResult} />
        )
      }
      hasError={props.hasConfigError}
    >
      <ViewEnsurer viewResult={viewResult}>
        <DetailContainer>
          <DimensionsContextContainer>
            <Table>
              <tbody>
                {viewRow && (
                  <Row
                    row={viewRow}
                    columnComponent={WrapperComponent}
                    labelComponent={LabelComponent}
                    valueComponent={ValueComponent}
                    spaceApi={props.spaceApi}
                    decorateValueComponent={({ label }) =>
                      label === null ? { colSpan: 2 } : undefined
                    }
                  />
                )}
              </tbody>
            </Table>
          </DimensionsContextContainer>
        </DetailContainer>
      </ViewEnsurer>
    </StyledPanel>
  );
}
