import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";

import { unflatten } from "flat";

import { assertNever } from "../../util/assertNever";

import useCodeSandbox from "./useCodeSandbox";

const DEBOUNCE = 20;

type EvalJob = [string, any, (val: any) => void, (val: any) => void];

export const EvaluaterContext = createContext({
  evaluate: (_expression: string, _input: any) => new Promise(res => res(null as any)),
  isJsEvalEnabled: false,
  getConsoleError: (_e: unknown) => null as unknown
});

const initialState = {
  batch: [] as EvalJob[]
};
function reducer(
  state: typeof initialState,
  action:
    | {
        type: "ENQUEUE_EVAL";
        payload: {
          expression: string;
          input: any;
          resolver: (val: any) => void;
          rejecter: (val: any) => void;
        };
      }
    | { type: "CLEAR_BATCH"; payload: { size: number } }
) {
  switch (action.type) {
    case "ENQUEUE_EVAL":
      const { expression, input, resolver, rejecter } = action.payload;
      return {
        batch: [...state.batch, [expression, input, resolver, rejecter]] as EvalJob[]
      };
    case "CLEAR_BATCH":
      return { batch: state.batch.slice(action.payload.size) };
    default:
      return assertNever(action);
  }
}

export default function EvaluaterContextContainer({
  debounce = DEBOUNCE,
  children
}: {
  debounce?: number;
  children: React.ReactNode;
}) {
  const { evaluateExpressions, isJsEvalEnabled, getConsoleError } = useCodeSandbox();
  const [state, dispatch] = useReducer(reducer, initialState);

  const evaluate = useCallback((expression: string, input: any) => {
    let resolver = (_result: any) => {};
    let rejecter = (_err: any) => {};
    const promise = new Promise((res, rej) => {
      resolver = res;
      rejecter = rej;
    });
    if (expression === null) {
      setImmediate(() => {
        resolver(null);
      });
    } else {
      dispatch({
        type: "ENQUEUE_EVAL",
        payload: { expression, input, resolver, rejecter }
      });
    }
    return promise;
  }, []);

  const batch = state.batch;
  useEffect(() => {
    let timeoutHandle: number | null = null;
    if (batch.length) {
      timeoutHandle = window.setTimeout(async () => {
        const expressions: string[] = [];
        const inputs: any[] = [];
        const resolvers: Array<(val: any) => void> = [];
        const rejecters: Array<(val: any) => void> = [];
        batch.forEach(([expression, input, resolver, rejecter]) => {
          expressions.push(expression);
          inputs.push(unflatten(input));
          resolvers.push(resolver);
          rejecters.push(rejecter);
        });
        const result = (await evaluateExpressions(expressions, inputs, true)) as any[];
        result.forEach((val, i) => {
          if (val !== null && typeof val === "object" && "error" in val) {
            rejecters[i](val.error);
          } else {
            resolvers[i](val);
          }
        });
        dispatch({ type: "CLEAR_BATCH", payload: { size: batch.length } });
      }, debounce);
    }
    return () => {
      timeoutHandle && clearTimeout(timeoutHandle);
    };
  }, [batch, debounce, evaluateExpressions]);

  const value = useMemo(
    () => ({ evaluate, isJsEvalEnabled, getConsoleError }),
    [evaluate, isJsEvalEnabled, getConsoleError]
  );

  return (
    <EvaluaterContext.Provider value={value}>{children}</EvaluaterContext.Provider>
  );
}

export const useEvaluaterContext = () => useContext(EvaluaterContext);
