import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

interface Data {
  node: { isActive: boolean };
}

interface Variables {
  id: string;
}

const AUTOMATION_STATUS_QUERY = gql`
  query GetAutomationStatusByID($id: ID!) {
    node(id: $id) {
      ... on AutomationNode {
        isActive
      }
    }
  }
`;

export const useAutomationStatusById = (
  options?: QueryHookOptions<Data, Variables>
) => {
  return useQuery<Data>(AUTOMATION_STATUS_QUERY, options);
};
