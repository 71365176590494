import React from "react";

import RecordDetail, { Props as RecordDetailProps } from "../../RecordDetail";
import Drawer from "../common/Drawer";

export default function RecordDetailDrawer(props: RecordDetailProps) {
  return (
    <Drawer>
      <RecordDetail {...props} inDrawer />
    </Drawer>
  );
}
