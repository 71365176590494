import Icon from "../../../../../assets/spaceicons/table.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  ElementLayout,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  OverflowOption,
  LayoutUnit
} from "../../../layout/util";

import Config from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  makeInitialState,
  ensureSpaceTableComponent
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceTable from "./SpaceTable";
import { SpaceTableComponent, SpaceTableProperties, TableConfigState } from "./types";

const pkg: SpaceComponentPackage<
  SpaceTableProperties,
  SpaceTableComponent,
  TableConfigState
> = {
  type: "TABLE",
  displayName: "Table",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["SPACE", "FLEX_BOX"]),
  isCollection: true,
  isRepeatable: false,
  Component: SpaceTable,
  ConfigurationComponent: Config,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceTableComponent,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.WIDE.width,
    height: DEFAULT_SIZES.WIDE.height,
    snapToGrid: true
  }),
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    height: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL],
    overflow: [OverflowOption.HIDDEN]
  },
  allowSelfBinding: false,
  allowAncestorBinding: true,
  hasInlineChildren: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
