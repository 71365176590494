import React, { useCallback, useEffect, useState } from "react";

import { Input, Popover } from "antd";
import { InputProps } from "antd/es/input";
import _ from "lodash";
import styled from "styled-components";

import Editor from "../../Editor";
import { getQuotedValue, getUnquotedValue } from "../utils";

import TemplateStringPopoverContent from "./TemplateStringPopoverContent";

const StyledInput = styled(Input)`
  background-color: ${props =>
    `${props.theme.primaryColorFaded}33`}; /* add opacity to hex value to fade out even more */
`;

export interface TemplateStringInputProps extends InputProps {
  value: string;
  onValueChange: (value: string) => void;
  useEditor?: boolean;
  popoverInnerContent?: React.ReactNode;
}
/**
 * Input field that will wrap the value as a template string,
 * unless it is `null` or starts and ends with a quote (', ", or `).
 * Consumers must pass an onValueChange handler to retrieve the updated quoted value.
 *
 * For example:
 * null --> null
 * /v1/users/${userId} --> `/v1/users/${userId}`
 * simple text --> `simple text`
 * 123 --> `123`
 * "quoted text" --> "quoted text"
 * 'quoted text' --> 'quoted text'
 * `quoted text` --> `quoted text`
 *
 * For existing expressions:
 * bar --> `${bar}`
 */
const TemplateStringInput = (props: TemplateStringInputProps) => {
  const { onValueChange, useEditor = false, popoverInnerContent, ...rest } = props;

  // `inputValue` is the text rendered in the input that the user sees
  const [inputValue, setInputValue] = useState<string>(getUnquotedValue(props.value));

  useEffect(() => {
    if (props.value !== getQuotedValue(inputValue)) {
      setInputValue(getUnquotedValue(props.value));
    }
  }, [props.value, inputValue]);

  const content = (
    <TemplateStringPopoverContent
      value={inputValue}
      innerContent={popoverInnerContent}
    />
  );

  const onChangeHandler = useCallback(
    (value: string) => {
      setInputValue(value);
      // send updated value to consumer component
      onValueChange(getQuotedValue(value));
    },
    [onValueChange]
  );

  return useEditor ? (
    <Editor mode="text/plain" onChange={onChangeHandler} value={inputValue} />
  ) : (
    <Popover content={content} trigger="focus">
      <StyledInput
        {...rest}
        value={inputValue}
        onChange={e => {
          onChangeHandler(e.target.value);
        }}
      />
    </Popover>
  );
};

export default TemplateStringInput;
