import { cloneDeep, flow, partial } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import { SpaceComponentObject } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import { DefaultValueType } from "../../../constants";
import inputReducer from "../../common/inputReducer";
import { getCommonInputProperties } from "../../common/util";
import { hasInputComponentProperties } from "../../util/util";
import { SpaceFilePickerComponent, SpaceFilePickerProperties } from "../types";

export interface FilePickerConfigState
  extends BaseComponentConfigState<SpaceFilePickerComponent> {
  type: "FILE_PICKER";
}

export const INITIAL_STATE = {
  type: "FILE_PICKER" as const,
  draftComponent: {} as SpaceFilePickerComponent
};

function isFilePickerConfigState(
  state: ComponentConfigState
): state is FilePickerConfigState {
  return state.type === "FILE_PICKER";
}

export function ensureFilePickerConfigState(
  state: ComponentConfigState
): FilePickerConfigState {
  if (isFilePickerConfigState(state)) return state;

  throw new Error("Expected FilePicker config state.");
}

export const getComponentProperties = (
  properties: Partial<SpaceFilePickerProperties>
) => ({
  ...properties,
  ...getCommonInputProperties(
    properties,
    DefaultValueType.FILE_VALUE,
    "",
    AttributeTypes.BINARY,
    false
  )
});

export function ensureSpaceFilePickerComponent(
  component: SpaceComponentObject
): SpaceFilePickerComponent {
  if (!!component.type && hasInputComponentProperties(component)) {
    return component;
  }

  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): FilePickerConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceFilePickerComponent(cloneDeep(draftComponent))
  };
}

export default (state: FilePickerConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(inputReducer, partial.placeholder, action)])(
    state,
    action
  );
