import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
`;

export const ActionListContainer = styled.div`
  padding: 12px 40px;
`;
