import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { Connection } from "../../../../../../../../types";

interface UserNode {
  id: string;
  firstName: string;
  lastName: string;
}

interface Data {
  allUsers: Connection<UserNode>;
}

const QUERY = gql`
  query AllUsers {
    allUsers {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const useAllUsers = (options?: QueryHookOptions<Data>) =>
  useQuery<Data>(QUERY, options);

export default useAllUsers;
