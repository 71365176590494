import React from "react";

import { SpaceComponentObject, SpaceComponentPackage } from "../../../../../types";
import { useStableSpaceContext } from "../../SpaceContext";

interface ComponentContextValue {
  component: SpaceComponentObject;
  pkg: SpaceComponentPackage;
  isPseudo: boolean;
  parentContext: undefined | ComponentContextValue;
}

export const defaultComponentContextValue: ComponentContextValue = {
  component: {} as SpaceComponentObject,
  pkg: {} as SpaceComponentPackage,
  isPseudo: false,
  parentContext: undefined
};
const ComponentContext = React.createContext(defaultComponentContextValue);

export default ComponentContext;

export function ComponentContextContainer({
  component,
  isPseudo = false,
  children
}: {
  component: SpaceComponentObject;
  isPseudo?: boolean;
  children: React.ReactNode;
}) {
  const parentContext = useComponentContext();
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const pkg = findSpaceComponentPackage(component.type)!;
  if (!pkg) {
    throw new Error(`Expected to find package for ${component.type}`);
  }

  const value = React.useMemo(
    () => ({
      component,
      pkg,
      isPseudo,
      parentContext
    }),
    [component, pkg, isPseudo, parentContext]
  );
  return (
    <ComponentContext.Provider key={component.slug} value={value}>
      {children}
    </ComponentContext.Provider>
  );
}

export const useComponentContext = () => React.useContext(ComponentContext);
