import React from "react";

import {
  SpaceComponentObject,
  FunctionAttributeNode,
  SourceType
} from "../../../../../../types";
import PermissionFeedback from "../PermissionFeedback";
import { useAccess } from "../useFunctionAccess";
import { ViewResult } from "../useView";
import { getUsedFunctionParameters } from "../util";

interface ViewComponentTitleProps {
  component: SpaceComponentObject;
  viewResult: ViewResult;
}

export default function ViewComponentTitle({
  component,
  viewResult
}: ViewComponentTitleProps) {
  const access = useAccess(viewResult.viewFunction?.access);

  return (
    <>
      {component.properties.title || ""}
      {viewResult.sourceType === SourceType.VIEW && (
        <PermissionFeedback
          attributes={viewResult.attributes as FunctionAttributeNode[]}
          parameters={
            viewResult.viewFunction
              ? getUsedFunctionParameters(
                  viewResult.viewFunction,
                  component.properties.input_parameters
                )
              : []
          }
          access={access}
        />
      )}
    </>
  );
}
