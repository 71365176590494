import React, { ReactNode } from "react";

import { Drawer as AntdDrawer } from "antd";
import { DrawerProps } from "antd/lib/drawer";
import styled from "styled-components";

import { GlobalStyleVariables } from "../../cssConstants";
import { isMobile } from "../util/Mobile";

import ErrorBoundary from "./ErrorBoundary";

const StyledDrawer = styled(AntdDrawer)`
  .ant-drawer-body {
    padding: ${props => props.theme.spacerlg};
  }
`;

interface DrawerPropsWithChildren extends DrawerProps {
  children: ReactNode;
}

// this drawer adds mobile width support to the ant design drawer
const Drawer = ({
  width = GlobalStyleVariables.drawerWidth,
  children,
  ...rest
}: DrawerPropsWithChildren) => {
  return (
    <StyledDrawer
      width={isMobile() ? "90%" : width}
      children={<ErrorBoundary className="drawer">{children}</ErrorBoundary>}
      {...rest}
    />
  );
};

export default Drawer;
