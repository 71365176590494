import React from "react";

import styled from "styled-components";

import { TinyLightText } from "../StyledComponents";

interface LimitCounterProps {
  current: number | undefined;
  limit: number;
  className?: string;
}

const LimitCounterRoot = styled(TinyLightText)`
  display: block;
  text-align: right;
`;

export default function LimitCounter({
  current = 0,
  limit,
  className
}: LimitCounterProps) {
  return (
    <LimitCounterRoot className={className}>
      {current.toLocaleString()}/{limit.toLocaleString()}
    </LimitCounterRoot>
  );
}
