import React, { useMemo } from "react";

import { Button } from "antd";
import styled from "styled-components";

import ColorPicker from "../../../../../../common/ColorPicker";
import { ElementStyle, LayoutUnit } from "../../../../../layout/util";
import { useComponentConfigContext } from "../../../common/ComponentConfigContext";
import { ConfigSection } from "../../../common/ConfigPanel";
import { DebouncedNumericInput } from "../../../common/DesignConfig/Inputs";

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.spacermd};
  margin-bottom: ${props => props.theme.spacermd};
`;

const Field = styled.div`
  display: flex;
  gap: ${props => props.theme.spacersm};
  align-items: center;
`;

const Label = styled.div`
  color: white;
  font-size: ${props => props.theme.inputFontSize};
`;

const Unit = styled.div`
  font-size: ${props => props.theme.inputFontSize};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 2px;
`;

const ToggleButtonRoot = styled(Button)`
  color: white;
`;

export default function TypographyConfig() {
  const {
    state: {
      draftComponent: { properties }
    },
    dispatch
  } = useComponentConfigContext();
  const root = useMemo(() => {
    const styles = properties.styles || {};
    return styles.root || new ElementStyle();
  }, [properties.styles]);
  const {
    fontSize,
    lineHeight,
    fontWeight,
    textDecoration,
    fontStyle,
    color,
    textAlign
  } = root;
  const updateStyle = React.useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_STYLE",
        payload: {
          styles: { root: { ...root, ...change } }
        }
      });
    },
    [dispatch, root]
  );

  const cssUnitRegex = new RegExp(Object.values(LayoutUnit).join("|"));
  const fontSizeNumeral = fontSize.replace(cssUnitRegex, "");
  const lineHeightNumeral = lineHeight.replace(cssUnitRegex, "");

  return (
    <ConfigSection title="Typography">
      <FlexRow>
        <Field>
          <Label>Size</Label>
          <DebouncedNumericInput
            value={fontSizeNumeral}
            onChange={value => {
              updateStyle({ fontSize: `${value}px` });
            }}
          />
          <Unit>px</Unit>
        </Field>
        <Field>
          <Label>Height</Label>
          <DebouncedNumericInput
            value={lineHeightNumeral}
            onChange={value => {
              updateStyle({ lineHeight: `${value}px` });
            }}
          />
          <Unit>px</Unit>
        </Field>
      </FlexRow>
      <FlexRow>
        <ColorPicker
          value={color}
          size="small"
          onChange={value => {
            updateStyle({ color: value });
          }}
        />
        <ButtonGroup>
          <ToggleButton
            icon="bold"
            value={fontWeight}
            activeValue="600"
            inactiveValue="400"
            onClick={value => {
              updateStyle({ fontWeight: value });
            }}
          />
          <ToggleButton
            icon="italic"
            value={fontStyle}
            activeValue="italic"
            inactiveValue="normal"
            onClick={value => {
              updateStyle({ fontStyle: value });
            }}
          />
          <ToggleButton
            icon="underline"
            value={textDecoration}
            activeValue="underline"
            inactiveValue="none"
            onClick={value => {
              updateStyle({ textDecoration: value });
            }}
          />
          <ToggleButton
            icon="strikethrough"
            value={textDecoration}
            activeValue="line-through"
            inactiveValue="none"
            onClick={value => {
              updateStyle({ textDecoration: value });
            }}
          />
        </ButtonGroup>
        <ButtonGroup>
          <ToggleButton
            icon="align-left"
            value={textAlign}
            activeValue="left"
            inactiveValue="none"
            onClick={value => {
              updateStyle({ textAlign: value });
            }}
          />
          <ToggleButton
            icon="align-center"
            value={textAlign}
            activeValue="center"
            inactiveValue="none"
            onClick={value => {
              updateStyle({ textAlign: value });
            }}
          />
          <ToggleButton
            icon="align-right"
            value={textAlign}
            activeValue="right"
            inactiveValue="none"
            onClick={value => {
              updateStyle({ textAlign: value });
            }}
          />
        </ButtonGroup>
      </FlexRow>
    </ConfigSection>
  );
}

function ToggleButton({
  icon,
  value,
  activeValue,
  inactiveValue,
  onClick
}: {
  icon: string;
  value: string;
  activeValue: string;
  inactiveValue: string;
  onClick(value: string): void;
}) {
  return (
    <ToggleButtonRoot
      icon={icon}
      type={value === activeValue ? "primary" : "default"}
      size="small"
      onClick={() => {
        onClick(value === activeValue ? inactiveValue : activeValue);
      }}
    />
  );
}
