import React from "react";

import { Button, Icon, Popover } from "antd";
import styled from "styled-components";

import DebouncedInput from "../../../../../common/DebouncedInput";
import { useSpaceConfigPanelContext } from "../ConfigPanel/ConfigPanelContext";
import { Field } from "../ConfigPanel/styledComponents";

enum ButtonIconOptions {
  Plus = "plus",
  Minus = "minus",
  Close = "close",
  Delete = "delete",
  Check = "check",
  Left = "left",
  Right = "right",
  Star = "star",
  Lock = "lock",
  Unlock = "unlock",
  AddUser = "usergroup-add",
  Upload = "upload",
  Like = "like",
  Dislike = "dislike",
  Visible = "eye",
  Hidden = "eye-invisible",
  Tool = "tool",
  Flag = "flag",
  Save = "save",
  Edit = "edit",
  None = "None"
}
const ButtonContainer = styled.div`
  display: flex;
  > *:not(:first-child) {
    margin-left: ${props => props.theme.spacersm};
  }
`;

// TODO below styled-components are copied from ValueRenderOptions. see if they are necessary or can be shared.
const ButtonIconContainer = styled.div`
  min-width: 186px;
  max-width: 188px;

  .ant-btn-link {
    color: ${props => props.theme.buttonColor};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.white}; // TODO this should be a theme color
    }
  }
`;

const ButtonForIconSelector = styled(Button)`
  min-width: 44px; // width when icon is selected
`;

interface ButtonTextConfigProps {
  placeholder?: string;
  button_text?: string;
  button_icon?: string;
  onIconChange: (value: string | undefined) => void;
  onTextChange: (value: string) => void;
}
export default function ButtonTextConfig(props: ButtonTextConfigProps) {
  const { generateGetPopupContainer } = useSpaceConfigPanelContext();
  return (
    <Field>
      <label>Button Text</label>
      <ButtonContainer>
        <Popover
          getPopupContainer={generateGetPopupContainer()}
          placement="left"
          arrowPointAtCenter
          content={
            <ButtonIconContainer>
              {Object.values(ButtonIconOptions).map(icon => {
                const isNone = icon === ButtonIconOptions.None;
                return (
                  <Button
                    key={`${icon}-button`}
                    onClick={() => props.onIconChange(isNone ? undefined : icon)}
                    type="link"
                  >
                    {isNone ? "No Icon" : <Icon type={icon} />}
                  </Button>
                );
              })}
            </ButtonIconContainer>
          }
          trigger="click"
          style={{ pointerEvents: "all" }}
        >
          <ButtonForIconSelector data-test="iconSelect">
            {props.button_icon ? <Icon type={props.button_icon} /> : <span>-</span>}
          </ButtonForIconSelector>
        </Popover>
        <DebouncedInput
          data-test="buttonTextInput"
          placeholder={"Submit"}
          value={props.button_text}
          onChange={(value: string) => props.onTextChange(value)}
        />
      </ButtonContainer>
    </Field>
  );
}
