import styled from "styled-components";

import EditorWrapper from "../../../../Editor";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Editor = styled(EditorWrapper)`
  flex-grow: 1;
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const Parameters = styled.div`
  margin-left: 16px;
  width: 400px;
`;

export const ParameterRow = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 100%;

  & + & {
    margin-top: ${props => props.theme.spacersm};
  }
`;
