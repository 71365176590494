import React from "react";

import _ from "lodash";

import { SpaceFunctionType } from "../../../../../types";
import usePrevious from "../../../../common/hooks/usePrevious";
import { WarningTooltip } from "../../../../common/ToolTips";
import { processEffects as processQueueEffects } from "../../../../queues/App/Queue/utils";
import { ErrorMessage } from "../../../../setup_flow/common/styledComponents";
import { createSpaceFunction } from "../../../FunctionExecutor/FunctionExecutor";
import { useStableSpaceContext } from "../../SpaceContext";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import {
  isBoundParameterDenied,
  isRequiredParameterDenied
} from "../common/PermissionFeedback";
import useFuncParams from "../common/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import useSpaceFunction from "../common/useSpaceFunction";
import { FunctionResult } from "../common/useSpaceFunction/useSpaceFunction";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import * as styled from "./styledComponents";

export default function SpaceFunctionHeadless({
  spaceComponent: {
    properties: { input_parameters = [] }
  },
  spaceComponent,
  spaceApi
}: Props) {
  const { spaceId, editMode } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();
  const { triggerEffects } = useSubmittableEffects(spaceApi);

  const onCompleted = React.useCallback(
    (result: FunctionResult, metadata: unknown) => {
      triggerEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
      processQueueEffects(metadata);
      updateOutput({ lastExecutionResult: result });
    },
    [updateOutput, triggerEffects]
  );

  const onError = React.useCallback(
    error => {
      triggerEffects({ type: SubmittableEventType.SUBMIT_FAILURE, error });
    },
    [triggerEffects]
  );

  const func = React.useMemo(
    () => createSpaceFunction(spaceComponent),
    [spaceComponent]
  );

  const access = useFunctionAccess(func);
  const { executeFunction } = useSpaceFunction(
    func,
    input_parameters,
    spaceId,
    onCompleted,
    onError
  );

  const {
    funcParams,
    hasRequiredBindings,
    hasRequiredValues,
    hasValidValues,
    getCurrentFuncParams
  } = useFuncParams(func, input_parameters, input);

  const previousFuncParams = usePrevious(funcParams);

  React.useEffect(() => {
    if ([SpaceFunctionType.VOID, SpaceFunctionType.NOT_VISIBLE].includes(func.type))
      return;

    const changed = !_.isEqual(funcParams, previousFuncParams);
    if (changed && hasRequiredBindings && hasRequiredValues && hasValidValues) {
      executeFunction(getCurrentFuncParams());
    }
  }, [
    func,
    executeFunction,
    hasRequiredBindings,
    hasRequiredValues,
    hasValidValues,
    previousFuncParams,
    funcParams,
    getCurrentFuncParams
  ]);

  const paramDenied = isRequiredParameterDenied(input_parameters, func, access);
  const boundDenied = React.useMemo(
    () => isBoundParameterDenied(input_parameters, func.functionParameters, funcParams),
    [input_parameters, func.functionParameters, funcParams]
  );

  if (paramDenied || boundDenied || func.type === SpaceFunctionType.NOT_VISIBLE) {
    return (
      <WarningTooltip
        message="This function is disabled."
        description={
          paramDenied
            ? "You don't have access to one or more fields required to complete this action. Please contact your admin to update your permissions."
            : "At least one value required for this action could not be retrieved because you do not have permissions to access the data. Please contact your admin to update your permissions."
        }
        placement="left"
      >
        <styled.Error>
          <styled.ErrorIcon />
          <ErrorMessage>Function Permission Error ({spaceComponent.name})</ErrorMessage>
        </styled.Error>
      </WarningTooltip>
    );
  } else if (editMode) {
    return (
      <styled.ConfigRoot>
        <styled.Icon />
      </styled.ConfigRoot>
    );
  } else {
    return null;
  }
}
