import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { EventHandler, EffectType } from "../../../../types";

import * as allEffects from "./Effects";
import { EffectErrorSelector } from "./Effects/types";

const errorSelectors: Partial<Record<EffectType, EffectErrorSelector>> =
  Object.fromEntries(
    Object.entries(allEffects)
      .map(([_, v]) => [
        v.type as EffectType,
        "errorSelector" in v ? v.errorSelector : null
      ])
      .filter(([_, v]) => v !== null)
  );

export default function selectErrors(
  component: SpaceComponentObject,
  findInvalidBindings: (component: SpaceComponentObject) => string[],
  componentLookup: Record<string, SpaceComponentObject>
) {
  const handlers: EventHandler[] = component.properties.effects;
  const errors: ConfigValidationError[] = [];
  handlers.forEach(h => {
    const selector = errorSelectors[h.effect.type];
    if (selector) {
      const message = selector(h, component, findInvalidBindings, componentLookup);
      if (message) {
        errors.push({
          field: "EFFECTS",
          message,
          key: btoa(JSON.stringify(h))
        });
      }
    }
  });
  return errors;
}
