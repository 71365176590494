import _ from "lodash";

import { assertNever } from "../../../../util/assertNever";
import { KeyValue } from "../../../KeyValueInputs";
import { HttpCode, HttpFunctionMetadata } from "../../index";
import { GraphqlPart, HttpMethods, RequestBodyType } from "../constants";
import {
  DataSourceFunction,
  GeneralActionTypes,
  SetErrorMessageAction,
  SetErrorMessagesAction,
  SetFieldValueAction
} from "../types";
import {
  getBodyForRequestType,
  getBodyFromGraphqlValues,
  getGraphqlQueryDataFromJsonString,
  getHttpFunctionParams
} from "../utils";

export enum HttpFunctionActionTypes {
  SET_REQUEST_TYPE = "SET_REQUEST_TYPE",
  SET_GRAPHQL_QUERY_BODY = "SET_GRAPHQL_QUERY_BODY",
  SET_BODY_AND_CODE = "SET_BODY_AND_CODE"
}

interface SetBodyAction {
  type: HttpFunctionActionTypes.SET_BODY_AND_CODE;
  payload: {
    body: string;
    code?: HttpCode;
  };
}

interface SetRequestTypeAction {
  type: HttpFunctionActionTypes.SET_REQUEST_TYPE;
  payload: {
    fieldValue: RequestBodyType;
  };
}

interface SetGraphqlQueryAction {
  type: HttpFunctionActionTypes.SET_GRAPHQL_QUERY_BODY;
  payload: {
    fieldName: GraphqlPart;
    fieldValue: string;
  };
}

interface SetInitialStateAction {
  type: GeneralActionTypes.SET_INITIAL_STATE;
  payload: {
    initialState: HttpFunctionState;
  };
}

type HttpFunctionAction =
  | SetFieldValueAction
  | SetErrorMessageAction
  | SetErrorMessagesAction
  | SetBodyAction
  | SetRequestTypeAction
  | SetGraphqlQueryAction
  | SetInitialStateAction;

export enum ErrorMessageKeys {
  Method = "method",
  Path = "path",
  UrlParameters = "urlParameters",
  Headers = "headers",
  Body = "body"
}

export interface ErrorMessageState {
  [ErrorMessageKeys.Method]: string;
  [ErrorMessageKeys.Path]: string;
  [ErrorMessageKeys.UrlParameters]: string;
  [ErrorMessageKeys.Headers]: string;
  [ErrorMessageKeys.Body]: string;
}

// fields needed to check for errors
export interface HttpFunctionBaseState {
  metadata: HttpFunctionMetadata;
  path: string;
  method?: HttpMethods;
  urlParameters: KeyValue[];
  headers: KeyValue[];
  body: string;
  graphqlQuery: string;
  graphqlVariables: string;
}

export interface HttpFunctionState extends HttpFunctionBaseState {
  errorMessages: ErrorMessageState;
}

export const getInitialState = (
  func?: DataSourceFunction<HttpFunctionMetadata>
): HttpFunctionState => {
  return {
    metadata: { request_type: RequestBodyType.None },
    method: HttpMethods.Get,
    path: "``",
    urlParameters: [{ key: "``", value: "``" }],
    headers: [{ key: "``", value: "``" }],
    body: "",
    graphqlQuery: "",
    graphqlVariables: "",
    errorMessages: {
      method: "",
      path: "",
      urlParameters: "",
      headers: "",
      body: ""
    },
    ...(func && getHttpFunctionParams(func))
  };
};

function reducer(state: HttpFunctionState, action: HttpFunctionAction) {
  switch (action.type) {
    case GeneralActionTypes.SET_FIELD_VALUE: {
      const fieldName = action.payload.fieldName;
      const fieldValue = action.payload.fieldValue;
      const updatedState = {
        ...state,
        [fieldName]: fieldValue
      };
      return updatedState;
    }
    case HttpFunctionActionTypes.SET_GRAPHQL_QUERY_BODY: {
      const fieldName = action.payload.fieldName;
      const fieldValue = action.payload.fieldValue;
      const queryValue = fieldName === "graphqlQuery" ? fieldValue : state.graphqlQuery;
      const queryVariableValue =
        fieldName === "graphqlVariables" ? fieldValue : state.graphqlVariables;
      const body = getBodyFromGraphqlValues(queryValue, queryVariableValue);
      return {
        ...state,
        [fieldName]: fieldValue,
        body,
        method: HttpMethods.Post
      };
    }
    case HttpFunctionActionTypes.SET_REQUEST_TYPE: {
      const fieldValue = action.payload.fieldValue;
      const updatedState = {
        ...state,
        metadata: { ...state.metadata, request_type: fieldValue }
      };
      const updatedBody = getBodyForRequestType(state.body, fieldValue);

      // convert value to graphql format (retrieve `graphqlQuery` and `graphqlVariables`)
      if (fieldValue === RequestBodyType.GraphQL) {
        updatedState.method = HttpMethods.Post;
        // convert body to graphql if possible
        const graphqlData = getGraphqlQueryDataFromJsonString(updatedBody);
        updatedState.graphqlQuery = graphqlData.query;
        updatedState.graphqlVariables = graphqlData.variables;
      }
      updatedState.body = updatedBody;
      return updatedState;
    }
    case GeneralActionTypes.SET_ERROR_MESSAGE: {
      const fieldName = action.payload.fieldName;
      const errorMessage = action.payload.errorMessage;
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          [fieldName]: errorMessage
        }
      };
    }
    case GeneralActionTypes.SET_ERROR_MESSAGES: {
      const errorMessages = action.payload.errorMessages;
      return {
        ...state,
        errorMessages: {
          ...errorMessages
        }
      };
    }
    case GeneralActionTypes.SET_INITIAL_STATE: {
      const initialState = action.payload.initialState;
      return _.cloneDeep(initialState);
    }
    case HttpFunctionActionTypes.SET_BODY_AND_CODE: {
      const { body, code } = action.payload;
      return {
        ...state,
        body,
        metadata: {
          ...state.metadata,
          code
        }
      };
    }
    default:
      return assertNever(action);
  }
}

export default reducer;
