import React from "react";

import useResizeObserver from "use-resize-observer/polyfilled";

import SpaceComponent from "../..";
import { ErrorValues } from "../../../../../../constants";
import { GlobalStyleVariables } from "../../../../../../cssConstants";
import { DataValue } from "../../../../../../types";
import SpaceApi from "../../../../SpaceApi";
import AttributeValue from "../AttributeValue";
import { ColumnType } from "../ColumnListManager";
import { Row as RowType } from "../useView";

interface Props {
  row: RowType;
  rowComponent?: React.ComponentType<any>;
  columnComponent?: React.ComponentType<any>;
  labelComponent?: React.ComponentType<any>;
  componentLabelComponent?: React.ComponentType<any>;
  valueComponent?: React.ComponentType<any>;
  spaceApi: SpaceApi;
  decorateValueComponent?: ({
    label,
    value
  }: {
    label: string | null;
    value: any;
  }) => Record<string, any> | undefined;
}

function DefaultRow({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

function DefaultColumn({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

function DefaultValue({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

export default function Row({
  row,
  rowComponent: RowComponent = DefaultRow,
  columnComponent: ColumnComponent = DefaultColumn,
  labelComponent: LabelComponent,
  componentLabelComponent: ComponentLabelComponent,
  valueComponent: ValueComponent = DefaultValue,
  spaceApi,
  decorateValueComponent = _opts => ({})
}: Props) {
  const ref = React.useRef();
  const { width } = useResizeObserver({ ref: ref.current });
  const rowValues = row.columns.reduce((agg, col) => {
    const [colType, , attr, value] = col;
    if (colType === ColumnType.ATTRIBUTE && value !== undefined) {
      agg[attr.name] = value;
    }
    return agg;
  }, {} as Record<string, DataValue>);
  return (
    <RowComponent>
      {row.columns
        .filter(c => !c[1].hidden)
        .filter(([, , , value]) => value !== ErrorValues.permissionDenied)
        .map(col => {
          switch (col[0]) {
            case ColumnType.ATTRIBUTE: {
              const [, colConfig, attr, value] = col;
              if (attr === undefined) return null;
              const valueProps = decorateValueComponent({
                label: attr.name,
                value
              });
              return (
                <ColumnComponent key={attr.id}>
                  {LabelComponent && <LabelComponent>{attr.name}</LabelComponent>}
                  <ValueComponent {...valueProps}>
                    <AttributeValue
                      attribute={attr}
                      value={value}
                      format={colConfig}
                      rowValues={rowValues}
                    />
                  </ValueComponent>
                </ColumnComponent>
              );
            }
            case ColumnType.COMPONENT: {
              const [, , sc] = col;
              const valueProps = decorateValueComponent({
                label: sc.properties.label || null,
                value: sc
              });
              const Label = ComponentLabelComponent || LabelComponent;
              return (
                <ColumnComponent key={sc.slug} ref={ref}>
                  {sc.properties.label && Label ? (
                    <Label>{sc.properties.label}</Label>
                  ) : null}
                  <ValueComponent {...valueProps}>
                    <SpaceComponent
                      spaceComponent={sc}
                      spaceApi={spaceApi}
                      layoutConstraints={{
                        width: width || 0,
                        height: GlobalStyleVariables.listItemMaxHeight
                      }}
                    />
                  </ValueComponent>
                </ColumnComponent>
              );
            }
            default:
              throw new Error(`Unknown column type: ${col[0]}`);
          }
        })}
    </RowComponent>
  );
}
