import styled from "styled-components";

export const PopoverContainer = styled.div`
  width: ${props => props.theme.popoverWidth};
  max-width: 100%;
  overflow-wrap: break-word;
`;

export const ErrorMessage = styled.div<{ addMarginTop: boolean }>`
  color: ${props => props.theme.errorColor};
  font-size: ${props => props.theme.smallFontSize};
  margin-top: ${props => (props.addMarginTop ? props.theme.spacersm : 0)};
`;

interface StyledCodeProps {
  isValid: boolean;
}
export const StyledCode = styled.code<StyledCodeProps>`
  font-size: ${props => props.theme.smallFontSize};
  color: ${props => (props.isValid ? "green" : props.theme.errorColor)};
`;

export const EvaluatedStringContainer = styled.div`
  margin-top: ${props => props.theme.spacermd};
  margin-left: -${props => props.theme.spacersm};
  margin-right: -${props => props.theme.spacersm};
  border-top: 1px solid ${props => props.theme.borderGrey};
  padding: ${props => props.theme.spacersm};
  padding-bottom: 0;
`;
