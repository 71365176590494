import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectEffectsErrors from "../../common/effects/selectErrors";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

export const errorSelector = (
  state: ComponentConfigState,
  findInvalidInputBindings: (cn: SpaceComponentObject) => string[],
  componentLookup: Record<string, SpaceComponentObject>
): ConfigValidationError[] => {
  const { draftComponent } = state;
  const errors: ConfigValidationError[] = [];
  if (
    !state.draftComponent.properties.presign_function_id ||
    !state.draftComponent.properties.bucket_name
  ) {
    errors.push({
      field: "BUCKET",
      message: "Please select a bucket."
    });
  }
  return errors
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(
      selectEffectsErrors(draftComponent, findInvalidInputBindings, componentLookup)
    );
};
