import { AttributeTypes } from "../../../../../../constants";
import { BindingShape } from "../../../../../../types";
import { useEnvironmentContext } from "../../../../../common/contexts/EnvironmentContext";
import useAuthUser from "../../../../../common/hooks/useAuthUser";
import { SpaceContextParams } from "../../../../../spaces/SpaceRoot/SpaceContext/SpaceContext";
import { TaskNode } from "../../useQueueClient/queries/AllTasks";

import { createTaskSchema, createTaskValue } from "./utils";

export default function useSpaceContextParams(task?: TaskNode): SpaceContextParams {
  const authUserResult = useAuthUser();
  const authUser = authUserResult?.authUser;
  const email = authUser?.email;
  const roleName = authUser?.roles?.edges[0]?.node.name;

  const { getCurrentEnvironment } = useEnvironmentContext();
  const currentEnvironment = getCurrentEnvironment();

  return {
    user: {
      title: "User",
      value: { email, role: { name: roleName } },
      schema: [
        {
          name: "email",
          title: "Email",
          shape: BindingShape.SCALAR,
          type: AttributeTypes.STRING
        },
        {
          name: "role",
          title: "Role",
          shape: BindingShape.OBJECT,
          attributes: [
            {
              name: "name",
              title: "Name",
              shape: BindingShape.SCALAR,
              type: AttributeTypes.STRING
            }
          ]
        }
      ]
    },
    environment: {
      title: "Environment",
      value: {
        current: {
          name: currentEnvironment.name,
          slug: currentEnvironment.slug
        }
      },
      schema: [
        {
          title: "Current",
          name: "current",
          shape: BindingShape.OBJECT,
          attributes: [
            {
              title: "Name",
              name: "name",
              shape: BindingShape.SCALAR
            },
            {
              title: "Slug",
              name: "slug",
              shape: BindingShape.SCALAR
            }
          ]
        }
      ]
    },
    task: {
      title: "Task",
      value: task ? createTaskValue(task) : {},
      schema: task ? createTaskSchema(task) : []
    }
  };
}
