import React, { ChangeEvent, useEffect, useState } from "react";

import { Button, Input } from "antd";
import styled from "styled-components";

import SingleLineEditor from "./SingleLineEditor";

// this styled component is used for both SingleLineEditor and Input
const StyledInput = styled(SingleLineEditor)`
  width: ${props => `calc(50% - ${props.theme.spacersm})`};

  & + & {
    margin-left: ${props => props.theme.spacersm};
  }
`;

interface StringInputProps {
  placeholder: string;
  useTemplateStringInputs: boolean;
  value: string;
  onValueChange: (value: string) => void;
  togglable?: boolean;
}

const StringInput = (props: StringInputProps) => {
  const {
    useTemplateStringInputs,
    onValueChange,
    placeholder,
    value,
    togglable = true
  } = props;
  return useTemplateStringInputs ? (
    <StyledInput
      placeholder={placeholder}
      value={value}
      onChange={onValueChange}
      togglable={togglable}
    />
  ) : (
    // pass in `as={Input}` to render as `Input` component and not `SingleLineEditor` component
    <StyledInput
      as={Input}
      onChange={
        ((e: ChangeEvent<HTMLInputElement>) => onValueChange(e.target.value)) as any
      }
      value={value}
      placeholder={placeholder}
    />
  );
};

const DeleteButton = styled(Button)`
  color: ${props => props.theme.primaryColor};
  align-self: center;
`;

const AddButton = styled(Button)`
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;

  & + & {
    margin-top: ${props => props.theme.spacersm};
  }
`;

const KeyValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export interface KeyValue {
  key: string;
  value: string;
}

interface KeyValueInputProps {
  className?: string;
  value?: KeyValue[];
  itemName: string;
  onChange: (value: KeyValue[]) => void;
  useTemplateStringInputs?: boolean;
  useTemplateStringInputsForValue?: boolean;
  popoverInnerContent?: React.ReactNode;
}

const KeyValueInputs = React.forwardRef((props: KeyValueInputProps, _ref) => {
  const [currentValue, setCurrentValue] = useState<KeyValue[]>(props.value || []);
  const DEFAULT_VALUE = props.useTemplateStringInputs ? "``" : "";

  useEffect(() => {
    setCurrentValue(props.value || []);
  }, [props.value]);

  const addRow = () => {
    const newValue = currentValue.concat({
      key: DEFAULT_VALUE,
      value: DEFAULT_VALUE
    });
    setCurrentValue(newValue);
    props.onChange(newValue);
  };

  const deleteRow = (index: number) => {
    if (index < 0 || index >= currentValue.length) return;
    const newValue = [...currentValue];
    newValue.splice(index, 1);
    setCurrentValue(newValue);
    props.onChange(newValue);
  };

  const onKeyChange = (index: number, value: string) => {
    if (index < 0 || index >= currentValue.length) return;
    const newValue = [...currentValue];
    newValue[index].key = value;
    props.onChange(newValue);
  };

  const onValueChange = (index: number, value: string) => {
    if (index < 0 || index >= currentValue.length) return;
    const newValue = [...currentValue];
    newValue[index].value = value;
    props.onChange(newValue);
  };

  return (
    <KeyValueContainer className={props.className}>
      {currentValue.map((keyValue, index) => {
        return (
          <Row key={index}>
            <StringInput
              data-test="keyInput"
              placeholder={"Enter a key"}
              value={keyValue.key}
              onValueChange={(newValue: string) =>
                onKeyChange(index, newValue || DEFAULT_VALUE)
              }
              useTemplateStringInputs={!!props.useTemplateStringInputs}
              togglable={false}
            />
            <StringInput
              data-test="valueInput"
              placeholder={"Enter a value"}
              value={keyValue.value}
              onValueChange={(newValue: string) =>
                onValueChange(index, newValue || DEFAULT_VALUE)
              }
              useTemplateStringInputs={
                !!props.useTemplateStringInputs ||
                !!props.useTemplateStringInputsForValue
              }
            />
            <DeleteButton
              icon="close-circle"
              size="small"
              title="Remove"
              type="link"
              onClick={() => {
                deleteRow(index);
              }}
            />
          </Row>
        );
      })}

      <AddButton type="link" onClick={() => addRow()}>
        + Add {props.itemName}
      </AddButton>
    </KeyValueContainer>
  );
});

export default KeyValueInputs;
