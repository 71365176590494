import React from "react";

import { Grid } from "../GridContext";
import { ComponentLayoutContextContainer } from "../LayoutContext/LayoutContext";

import CanvasEventMask from "./CanvasEventMask";
import { CanvasViewportProvider } from "./CanvasViewportContext";

export default function Canvas({ children }: { children: React.ReactNode }) {
  return (
    <CanvasViewportProvider>
      <ComponentLayoutContextContainer slug="root">
        <CanvasEventMask>
          <Grid>{children}</Grid>
        </CanvasEventMask>
      </ComponentLayoutContextContainer>
    </CanvasViewportProvider>
  );
}
