import { useCallback, useEffect, useReducer, useRef } from "react";

import { useApolloClient } from "@apollo/react-hooks";
import Papa from "papaparse";

import { PageInfo, ViewDataNode, ViewNode } from "../../../../../../types";
import { reportException } from "../../../../../util/exceptionReporting";
import { ViewResult } from "../useView";
import { ViewRowsVariables, VIEW_ROWS_QUERY } from "../useView/queries";

import reducer, { ActionType, ExportStatus, statusText } from "./reducer";
import { downloadFile } from "./util";

const PAGE_SIZE = 1000;
const MAX_PAGES = 1000;

export default function useExportView(
  fileName: string,
  { queryVariables, columnViewMapping }: ViewResult
) {
  const [state, dispatch] = useReducer(reducer, {
    status: ExportStatus.WAITING
  });
  const client = useApolloClient();

  const cancelRef = useRef(false);
  useEffect(() => {
    return () => {
      cancelRef.current = true;
    };
  }, []);

  const query = useCallback(async () => {
    const queryPage = async (after?: string) => {
      const { data } = await client.query<
        { node: ViewNode },
        Partial<ViewRowsVariables>
      >({
        fetchPolicy: "network-only",
        query: VIEW_ROWS_QUERY,
        variables: { ...queryVariables, after, limit: PAGE_SIZE }
      });

      if (!data.node.data) {
        throw new Error("View data missing from response");
      }
      return data.node.data;
    };

    let after = undefined;
    for (let i = 0; i < MAX_PAGES; i++) {
      try {
        const data: ViewDataNode = await queryPage(after);
        if (cancelRef.current) {
          return;
        }
        switch (data.__typename) {
          case "ViewDataResultSuccess":
            dispatch({
              type: ActionType.TRACK_FETCHED_PAGE,
              payload: { rows: data.rows.edges.map(edge => edge.node.row) }
            });

            const { endCursor, hasNextPage }: PageInfo = data.rows.pageInfo;
            if (!hasNextPage || after === endCursor) {
              dispatch({ type: ActionType.TRACK_FETCHED });
              return;
            }
            after = endCursor;
            break;

          case "ClientErrorResult":
          case "PermissionErrorResult":
            dispatch({
              type: ActionType.TRACK_ERROR,
              payload: { message: data.message }
            });
            return;
        }
      } catch (err) {
        reportException(err);
        dispatch({
          type: ActionType.TRACK_ERROR,
          payload: { message: "An error occurred loading data for your CSV." }
        });
        return;
      }
    }
  }, [queryVariables, client]);

  const encode = useCallback(
    (rows: any[][]) => {
      const header = columnViewMapping
        .sort((a, b) => a.viewIndex - b.viewIndex)
        .map(m => m.name);
      const data = rows.map(row => {
        return row.map(value => {
          if (value !== null && typeof value === "object") {
            return JSON.stringify(value);
          }
          return value;
        });
      });
      data.unshift(header);
      const csv = Papa.unparse(data, { escapeFormulae: true, newline: "\n" });
      dispatch({ type: ActionType.TRACK_ENCODED, payload: { csv } });
    },
    [columnViewMapping]
  );

  const download = useCallback(
    (body: string) => {
      try {
        downloadFile(`${fileName}.csv`, body);
        dispatch({ type: ActionType.RESET });
      } catch (err) {
        reportException(err);
        dispatch({
          type: ActionType.TRACK_ERROR,
          payload: { message: "An error occurred downloading your CSV." }
        });
      }
    },
    [fileName]
  );

  useEffect(() => {
    switch (state.status) {
      case ExportStatus.START_FETCH:
        dispatch({ type: ActionType.TRACK_FETCHING });
        query();
        break;

      case ExportStatus.START_ENCODE:
        if (state.rows) {
          dispatch({ type: ActionType.TRACK_ENCODING });
          encode(state.rows);
        } else {
          reportException(new Error("CSV data missing during encode"));
          dispatch({
            type: ActionType.TRACK_ERROR,
            payload: { message: "An error occurred encoding your CSV." }
          });
        }
        break;

      case ExportStatus.START_DOWNLOAD:
        if (state.csv) {
          dispatch({ type: ActionType.TRACK_DOWNLOADING });
          download(state.csv);
        } else {
          reportException(new Error("CSV data missing during download"));
          dispatch({
            type: ActionType.TRACK_ERROR,
            payload: { message: "An error occurred generating your CSV." }
          });
        }
        break;
    }
  }, [state, query, download, encode]);

  return {
    start: useCallback(() => dispatch({ type: ActionType.START }), []),
    reset: useCallback(() => dispatch({ type: ActionType.RESET }), []),
    retry: useCallback(() => dispatch({ type: ActionType.RETRY }), []),
    status: state.status,
    statusMessage: statusText(state),
    errorMessage: state.error
  };
}
