import React from "react";

import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import { ConfigSection, Checkbox } from "../../common/ConfigPanel";

export default function SelectMultipleConfig() {
  const {
    state: {
      draftComponent: {
        properties: { allow_select_multiple }
      }
    },
    dispatch
  } = useComponentConfigContext();

  return (
    <ConfigSection title="Multi-Select">
      <Checkbox
        data-test="allow_select_multiple"
        checked={allow_select_multiple}
        onChange={({ target: { checked } }) => {
          dispatch({
            type: "MERGE_DRAFT_COMPONENT",
            payload: {
              change: { properties: { allow_select_multiple: checked } }
            }
          });
        }}
      >
        Allow selecting multiple rows.
      </Checkbox>
    </ConfigSection>
  );
}
