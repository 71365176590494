import { AttributeTypes } from "../../../constants";
import { SpaceComponentType } from "../../../types";
import { FieldType } from "../../common/AttributeInput/constants";

import { ParameterType } from "./SpaceComponent/common/useFuncParams/types";

export const ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT: Record<
  AttributeTypes,
  SpaceComponentType[]
> = {
  [AttributeTypes.BOOL]: ["CHECKBOX", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.DECIMAL]: ["CUSTOM_FIELD", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.FLOAT]: ["CUSTOM_FIELD", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.INT]: ["CUSTOM_FIELD", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.STRING]: [
    "CUSTOM_FIELD",
    "TEXT_AREA",
    "DROPDOWN",
    "RADIO_BUTTON",
    "TAG_SELECTOR"
  ],
  [AttributeTypes.DATE]: ["DATE_TIME_PICKER"],
  [AttributeTypes.TIME]: ["DATE_TIME_PICKER"],
  [AttributeTypes.DATETIME]: ["DATE_TIME_PICKER"],
  [AttributeTypes.TIMESTAMP]: ["DATE_TIME_PICKER"],
  [AttributeTypes.JSON]: ["JSON_INPUT", "TAG_SELECTOR"],
  [AttributeTypes.BINARY]: ["FILE_PICKER"],
  [AttributeTypes.FILE]: ["FILE_PICKER"]
};

// Default value types available for each component depend on 2 things:
// (1) attribute type (ie. string, boolean, json, etc.)
// (2) component type (ie. checkbox, input, json input, etc.)
// Components only allow default value types that can be supported by
// the underlying attribute types that are compatible with the component type.
// In addition, depending on the component type, some default value types
// are not allowed. Therefore, the final set of allowable default value types is
// the delta between DEFAULT_VALUE_OPTIONS (the comprehensive set of default value
// types based on attribute type) and DEFAULT_TYPE_EXCLUSION_LIST (the list
// of default value types that are not supported based on component type).
export enum DefaultValueType {
  UUID = "uuid",
  NULL = "null",
  BINDING = "binding",
  TEMPLATE = "template",
  CURRENT_DATETIME = "current_datetime",
  FILE_VALUE = "file_value",
  CSV_VALUE = "csv_value",
  TEXT_VALUE = "text_value",
  DATETIME_VALUE = "datetime_value",
  BOOLEAN_VALUE = "boolean_value",
  JSON_VALUE = "json_value"
}
const STRING_DEFAULT_VALUE_TYPES = [
  DefaultValueType.TEXT_VALUE,
  DefaultValueType.BINDING,
  DefaultValueType.TEMPLATE,
  DefaultValueType.UUID,
  DefaultValueType.NULL,
  DefaultValueType.CSV_VALUE
] as const; // explicitly specify for ordering
const NUMERIC_DEFAULT_VALUE_TYPES = [
  DefaultValueType.TEXT_VALUE,
  DefaultValueType.BINDING,
  DefaultValueType.TEMPLATE,
  DefaultValueType.NULL
];
const BOOLEAN_DEFAULT_VALUE_TYPES = [
  DefaultValueType.BOOLEAN_VALUE,
  DefaultValueType.BINDING,
  DefaultValueType.NULL
] as const;
const JSON_DEFAULT_VALUE_TYPES = [
  DefaultValueType.BINDING,
  DefaultValueType.JSON_VALUE,
  DefaultValueType.CSV_VALUE,
  DefaultValueType.NULL
] as const;
const DATETIME_DEFAULT_VALUE_TYPES = [
  DefaultValueType.BINDING,
  DefaultValueType.DATETIME_VALUE,
  DefaultValueType.NULL,
  DefaultValueType.CURRENT_DATETIME
];
const FILE_DEFAULT_VALUE_TYPES = [
  DefaultValueType.BINDING,
  DefaultValueType.FILE_VALUE,
  DefaultValueType.NULL
] as const;
export type DefaultValueTypes =
  | typeof STRING_DEFAULT_VALUE_TYPES[number]
  | typeof NUMERIC_DEFAULT_VALUE_TYPES[number]
  | typeof BOOLEAN_DEFAULT_VALUE_TYPES[number]
  | typeof JSON_DEFAULT_VALUE_TYPES[number]
  | typeof DATETIME_DEFAULT_VALUE_TYPES[number]
  | typeof FILE_DEFAULT_VALUE_TYPES[number];

export const DEFAULT_VALUE_OPTIONS: Record<
  AttributeTypes,
  Readonly<DefaultValueTypes[]>
> = {
  [AttributeTypes.BOOL]: BOOLEAN_DEFAULT_VALUE_TYPES,
  [AttributeTypes.DECIMAL]: NUMERIC_DEFAULT_VALUE_TYPES,
  [AttributeTypes.FLOAT]: NUMERIC_DEFAULT_VALUE_TYPES,
  [AttributeTypes.INT]: NUMERIC_DEFAULT_VALUE_TYPES,
  [AttributeTypes.STRING]: STRING_DEFAULT_VALUE_TYPES,
  [AttributeTypes.DATE]: DATETIME_DEFAULT_VALUE_TYPES,
  [AttributeTypes.TIME]: DATETIME_DEFAULT_VALUE_TYPES,
  [AttributeTypes.DATETIME]: DATETIME_DEFAULT_VALUE_TYPES,
  [AttributeTypes.TIMESTAMP]: DATETIME_DEFAULT_VALUE_TYPES,
  [AttributeTypes.JSON]: JSON_DEFAULT_VALUE_TYPES,
  [AttributeTypes.BINARY]: FILE_DEFAULT_VALUE_TYPES,
  [AttributeTypes.FILE]: FILE_DEFAULT_VALUE_TYPES
};

export const DEFAULT_VALUE_DISPLAY_NAMES: Record<DefaultValueTypes, string> = {
  template: "Set a template",
  text_value: "Set a value",
  null: "Set to null",
  uuid: "Set a UUID",
  boolean_value: "Set a value",
  binding: "Pre-fill with value from component",
  csv_value: "Enter values as CSV",
  json_value: "Enter a value",
  datetime_value: "Select a value",
  file_value: "Set a value",
  current_datetime: "Current date and time"
};

export const DEFAULT_VALUE_TYPE_BY_ATTRIBUTE_TYPE: Record<string, DefaultValueType> = {
  [AttributeTypes.JSON]: DefaultValueType.JSON_VALUE,
  [AttributeTypes.BOOL]: DefaultValueType.BOOLEAN_VALUE,
  [AttributeTypes.FILE]: DefaultValueType.FILE_VALUE,
  [AttributeTypes.BINARY]: DefaultValueType.FILE_VALUE,
  [AttributeTypes.STRING]: DefaultValueType.TEXT_VALUE,
  [AttributeTypes.INT]: DefaultValueType.TEXT_VALUE,
  [AttributeTypes.FLOAT]: DefaultValueType.TEXT_VALUE,
  [AttributeTypes.DECIMAL]: DefaultValueType.TEXT_VALUE,
  [AttributeTypes.DATETIME]: DefaultValueType.DATETIME_VALUE,
  [AttributeTypes.TIMESTAMP]: DefaultValueType.DATETIME_VALUE,
  [AttributeTypes.DATE]: DefaultValueType.DATETIME_VALUE,
  [AttributeTypes.TIME]: DefaultValueType.DATETIME_VALUE
};

export const DEFAULT_TYPE_EXCLUSION_LIST: {
  [key in SpaceComponentType]?: Readonly<DefaultValueTypes>[];
} = {
  CHECKBOX: [DefaultValueType.NULL],
  CUSTOM_FIELD: [DefaultValueType.CSV_VALUE],
  DROPDOWN: [DefaultValueType.UUID, DefaultValueType.CSV_VALUE],
  JSON_INPUT: [DefaultValueType.CSV_VALUE],
  RADIO_BUTTON: [DefaultValueType.UUID, DefaultValueType.CSV_VALUE],
  TAG_SELECTOR: [
    DefaultValueType.UUID,
    DefaultValueType.TEMPLATE,
    DefaultValueType.NULL,
    DefaultValueType.JSON_VALUE
  ],
  TEXT_AREA: [DefaultValueType.CSV_VALUE]
};

export const COMPONENTS_WITHOUT_BLANK_SUPPORT = ["CHECKBOX"];
export const COMPONENTS_WITHOUT_NULL_SUPPORT = ["CHECKBOX", "TAG_SELECTOR"];

export enum BlankValueType {
  NULL_VALUE = "null_value",
  EMPTY_STRING = "empty_string",
  UNDEFINED = "undefined",
  EMPTY_ARRAY = "empty_array"
}

export const BLANK_VALUE_TYPE_MAP = {
  [BlankValueType.NULL_VALUE]: null,
  [BlankValueType.EMPTY_STRING]: "",
  [BlankValueType.UNDEFINED]: undefined,
  [BlankValueType.EMPTY_ARRAY]: []
};

export const DATE_FIELD_TYPE_BY_PARAMETER_TYPE: Record<string, FieldType> = {
  [ParameterType.DATE_TODAY]: FieldType.DATE_INPUT,
  [ParameterType.TIME_NOW]: FieldType.TIME_INPUT,
  [ParameterType.DATETIME_NOW]: FieldType.DATETIME_INPUT
};
