import React from "react";

const NestedIcon = () => {
  return (
    <svg
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 -2.62268e-07L1 12L7 12" stroke="currentColor" />
    </svg>
  );
};

export default NestedIcon;
