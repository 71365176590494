import {
  DISPLAY_MESSAGE,
  REFRESH_COMPONENT,
  RESET_FORM_INPUTS,
  AUTO_UPDATE_BY_KEY
} from "../effects/Effects";
import { MessageType } from "../effects/Effects/DisplayMessage";
import { SubmittableEventType } from "../effects/useSubmittableEffects/useSubmittableEffects";

const defaultSuccessEffect = {
  type: "refresh_component" as const,
  options: {
    component: undefined
  }
};

const defaultFailureEffect = {
  type: "display_message" as const,
  options: {
    type: MessageType.ERROR,
    message: "Something went wrong. Please try again."
  }
};

export const FORM_EVENTS = [
  {
    name: "On Succesful Submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [
      DISPLAY_MESSAGE,
      REFRESH_COMPONENT,
      RESET_FORM_INPUTS,
      AUTO_UPDATE_BY_KEY
    ],
    defaultEffect: defaultSuccessEffect
  },
  {
    name: "On Failed Submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE, RESET_FORM_INPUTS],
    defaultEffect: defaultFailureEffect
  }
];

export const MODAL_FORM_EVENTS = [
  {
    name: "On Succesful Submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [DISPLAY_MESSAGE, REFRESH_COMPONENT, AUTO_UPDATE_BY_KEY],
    defaultEffect: defaultSuccessEffect
  },
  {
    name: "On Failed Submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE],
    defaultEffect: defaultFailureEffect
  }
];

export const BUTTON_EVENTS = MODAL_FORM_EVENTS;

export const BULK_IMPORT_EVENTS = MODAL_FORM_EVENTS;
