import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

interface Data {
  functionExists: boolean;
}

interface Variables {
  id: string;
}

const QUERY = gql`
  query FunctionExists($id: ID) {
    functionExists(id: $id)
  }
`;

export const useFunctionExistsById = (options?: QueryHookOptions<Data, Variables>) => {
  return useQuery<Data, Variables>(QUERY, options);
};
