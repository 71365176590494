import React from "react";

import { Modal, Icon } from "antd";
import styled from "styled-components";

import { SpaceComponentObject } from "../../../../../../types";
import usePrevious from "../../../../../common/hooks/usePrevious";
import useExportView, { ExportStatus } from "../../common/useExportView";
import { ViewResult } from "../../common/useView";

interface ExportButtonProps {
  component: SpaceComponentObject;
  viewResult: ViewResult;
}

const ExportStatusFeedback = styled.span`
  color: ${props => props.theme.textColorMid};
  font-style: italic;
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.errorColor};
`;

export default function ExportButton({ component, viewResult }: ExportButtonProps) {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <div>
      <span
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Icon type="download" /> Export table as CSV
      </span>
      {modalOpen && (
        <ExportModal
          component={component}
          viewResult={viewResult}
          onClose={() => {
            setModalOpen(false);
          }}
        />
      )}
    </div>
  );
}

function ExportModal({
  component,
  viewResult,
  onClose
}: ExportButtonProps & { onClose: () => void }) {
  const exportFileName = component.properties.title || component.name || "table_data";
  const { status, statusMessage, errorMessage, start, reset, retry } = useExportView(
    exportFileName,
    viewResult
  );

  const lastStatus = usePrevious(status, { returnCurrentOnFirstRender: true });
  React.useEffect(() => {
    if (lastStatus !== ExportStatus.WAITING && status === ExportStatus.WAITING) {
      onClose();
    }
  }, [lastStatus, status, onClose]);

  return (
    <Modal
      visible
      okText={status !== ExportStatus.FAILED ? "Save as CSV" : "Retry"}
      destroyOnClose
      confirmLoading={status !== ExportStatus.WAITING && status !== ExportStatus.FAILED}
      maskClosable={false}
      onOk={() => (status !== ExportStatus.FAILED ? start() : retry())}
      onCancel={() => {
        reset();
        onClose();
      }}
    >
      <h2>Data Export</h2>
      <p>
        Export your table as a CSV file which may be opened by spreadsheet software like
        Excel or Google Sheets.
      </p>
      <ExportStatusFeedback>{statusMessage}</ExportStatusFeedback>
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Modal>
  );
}
