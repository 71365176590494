import React from "react";

import { Button as AntButton } from "antd";
import { ButtonProps as AntButtonProps } from "antd/lib/button";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styled, { css, StyledComponent } from "styled-components";

export interface ButtonProps extends Omit<AntButtonProps, "size" | "type"> {
  size?: "sm" | "lg";
  type?: "brand" | "primary" | "secondary" | "noFill" | "noFillAccent";
  to?: string;
  className?: string;
}

// NOTE: Some of the styles below are repetitive and in isolation look unneeded.
//       They are however required to override antd styling and our older existing
//       overrides of antd styling. TODO: Audit and refactor our existing overrides
//       so theses styles may be simplified.
const commonStyle = css`
  font-weight: 500;
  line-height: 20px;
  height: auto;
  border-color: transparent;
  text-align: center;
  border-radius: 4px;
  box-shadow: none;
  &.ant-btn.ant-btn-loading:before {
    display: none;
  }
  &:focus {
    border-color: transparent;
  }
  &:focus-visible {
    border-color: ${props => props.theme.primaryColor};
    box-shadow: 0 0 0 2px rgb(165 96 235 / 20%);
  }
`;

const lgStyle = css`
  ${commonStyle}
  font-size: 15px;
  padding: 12px 16px;
`;

const smStyle = css`
  ${commonStyle}
  font-size: 13px;
  padding: 6px 12px;
`;

const brandStyle = css`
  color: white;
  background: ${props => props.theme.primaryColorDark};
  &:hover {
    color: white;
    background: ${props => props.theme.primaryColorMuted};
    border-color: transparent;
  }
  &:focus {
    color: white;
    background: ${props => props.theme.primaryColorDark};
  }
  &:active {
    color: white;
    background: ${props => props.theme.primaryColorDarker};
    border-color: transparent;
  }
  &[disabled],
  &[disabled]:hover {
    opacity: 0.6;
    color: white;
    background: ${props => props.theme.primaryColorDark};
    border-color: transparent;
  }
`;

const primaryStyle = css`
  color: ${props => props.theme.newPrimaryButtonColor};
  background: ${props => props.theme.newPrimaryButtonBackground};
  &:hover {
    color: ${props => props.theme.newPrimaryButtonColor};
    background: ${props => props.theme.newPrimaryButtonBackgroundLight};
    border-color: transparent;
  }
  &:focus {
    color: ${props => props.theme.newPrimaryButtonColor};
    background: ${props => props.theme.newPrimaryButtonBackground};
  }
  &:active {
    color: ${props => props.theme.newPrimaryButtonColor};
    background: ${props => props.theme.newPrimaryButtonBackgroundDark};
    border-color: transparent;
  }
  &[disabled],
  &[disabled]:hover {
    opacity: 0.6;
    color: ${props => props.theme.newPrimaryButtonColor};
    background: ${props => props.theme.newPrimaryButtonBackground};
    border-color: transparent;
  }
`;

const secondaryStyle = css`
  color: ${props => props.theme.newSecondaryButtonColor};
  background: ${props => props.theme.newSecondaryButtonBackground};
  &:hover {
    color: ${props => props.theme.newSecondaryButtonColor};
    background: ${props => props.theme.newSecondaryButtonBackgroundDark};
    border-color: transparent;
  }
  &:focus {
    color: ${props => props.theme.newSecondaryButtonColor};
    background: ${props => props.theme.newSecondaryButtonBackground};
  }
  &:active {
    color: ${props => props.theme.newSecondaryButtonColor};
    background: ${props => props.theme.newSecondaryButtonBackgroundDarker};
    border-color: transparent;
  }
  &[disabled],
  &[disabled]:hover {
    opacity: 0.6;
    color: ${props => props.theme.newSecondaryButtonColor};
    background: ${props => props.theme.newSecondaryButtonBackground};
    border-color: transparent;
  }
`;

const noFillStyle = css`
  color: ${props => props.theme.newSecondaryButtonColor};
  background: transparent;
  &:hover {
    color: ${props => props.theme.newSecondaryButtonColor};
    background: ${props => props.theme.newSecondaryButtonBackgroundDark};
    border-color: transparent;
  }
  &:focus {
    color: ${props => props.theme.newSecondaryButtonColor};
    background: transparent;
  }
  &:active {
    color: ${props => props.theme.newSecondaryButtonColor};
    background: ${props => props.theme.newSecondaryButtonBackgroundDarker};
    border-color: transparent;
  }
  &[disabled],
  &[disabled]:hover {
    opacity: 0.6;
    color: ${props => props.theme.newSecondaryButtonColor};
    background: transparent;
    border-color: transparent;
  }
`;

const noFillAccentStyle = css`
  color: ${props => props.theme.primaryColorDark};
  background: transparent;
  &:hover {
    color: ${props => props.theme.primaryColorDark};
    background: ${props => props.theme.newSecondaryButtonBackgroundDark};
    border-color: transparent;
  }
  &:focus {
    color: ${props => props.theme.primaryColorDark};
    background: transparent;
  }
  &:active {
    color: ${props => props.theme.primaryColorDark};
    background: ${props => props.theme.newSecondaryButtonBackgroundDarker};
    border-color: transparent;
  }
  &[disabled],
  &[disabled]:hover {
    opacity: 0.6;
    color: ${props => props.theme.primaryColorDark};
    background: transparent;
    border-color: transparent;
  }
`;

const sizes = {
  lg: lgStyle,
  sm: smStyle
};

const types = {
  brand: brandStyle,
  primary: primaryStyle,
  secondary: secondaryStyle,
  noFill: noFillStyle,
  noFillAccent: noFillAccentStyle
};

const FallbackComponent = ({ children }: { children: React.ReactNode }) => (
  <div>{children}</div>
);
const createVariants = (Component: any = FallbackComponent) =>
  Object.entries(sizes).reduce<
    Record<string, Record<string, StyledComponent<any, any>>>
  >((sizeAcc, [sizeName, sizeStyle]) => {
    sizeAcc[sizeName] = Object.entries(types).reduce<
      Record<string, StyledComponent<any, any>>
    >((typeAcc, [typeName, typeStyle]) => {
      typeAcc[typeName] = styled(Component)`
        ${sizeStyle}
        ${typeStyle}
      `;
      return typeAcc;
    }, {});
    return sizeAcc;
  }, {});

const btns = createVariants(AntButton);
const links = createVariants(Link);

function ButtonNew({
  size = "sm",
  type = "secondary",
  className = "",
  children,
  to,
  block,
  style,
  ...rest
}: ButtonProps) {
  const variants = to ? links : btns;
  const Component = variants[size][type];
  return (
    <Component
      className={classNames(className, "new-btn", { block: !!to && block })}
      to={to}
      block={!to ? block : undefined}
      style={{
        display: to ? (block ? "block" : "inline-block") : undefined,
        ...style
      }}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default ButtonNew;
