import React from "react";

import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled from "styled-components";

import Activity from "../../assets/icons/activity-icon.svg";
import Down from "../../assets/icons/chevron-down.svg";
import Left from "../../assets/icons/chevron-left.svg";
import Right from "../../assets/icons/chevron-right.svg";
import Up from "../../assets/icons/chevron-up.svg";
import Close from "../../assets/icons/close-icon.svg";
import HeartFilled from "../../assets/icons/heart-filled.svg";
import HeartOutlined from "../../assets/icons/heart-outline.svg";

const StyledButton = styled(Button)`
  &.ant-btn-link {
    color: ${props => props.theme.iconButtonColor};
    font-size: ${props => props.theme.defaultFontSize};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.iconButtonColor};
    }

    &:enabled:hover,
    &:enabled:focus-visible, /* keyboard only focus */
    &:enabled:active {
      background: ${props => props.theme.iconButtonHoverBackground};
      border-radius: 2px;
    }
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: ${props => props.theme.disabledLabelColor};
    pointer-events: none;
  }
`;

interface IconButtonProps extends ButtonProps {
  icon: string;
  className?: string;
}

const IconButton = ({
  className,
  onClick,
  disabled,
  type = "link",
  ...rest
}: IconButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (disabled || typeof onClick !== "function") return;
    onClick(e);
  };
  return (
    <StyledButton
      className={className}
      type={type}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    />
  );
};

export default IconButton;

const SvgIconButtonRoot = styled.button`
  width: 16px;
  height: 16px;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  color: ${props => props.theme.surfacePrimary};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.link};
  }
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: ${props => props.theme.surfaceDisable};
    pointer-events: none;
  }
`;

export function SvgIconButton({
  children,
  icon,
  disabled = false,
  className,
  onClick = () => {}
}: {
  children?: React.ReactNode;
  disabled?: boolean;
  icon?:
    | "activity"
    | "down"
    | "left"
    | "right"
    | "up"
    | "close"
    | "heartEmpty"
    | "heartFull";
  className?: string;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  let svg: React.ReactNode;
  switch (icon) {
    case "activity":
      svg = <Activity />;
      break;
    case "down":
      svg = <Down />;
      break;
    case "left":
      svg = <Left />;
      break;
    case "right":
      svg = <Right />;
      break;
    case "up":
      svg = <Up />;
      break;
    case "close":
      svg = <Close />;
      break;
    case "heartEmpty":
      svg = <HeartOutlined />;
      break;
    case "heartFull":
      svg = <HeartFilled />;
      break;
    default:
      svg = children;
  }

  return (
    <SvgIconButtonRoot className={className} disabled={disabled} onClick={onClick}>
      {svg}
    </SvgIconButtonRoot>
  );
}
