import * as AntForm from "antd/lib/form/Form";

export interface FieldValidationProps {
  validationRules?: AntForm.ValidationRule[];
  required?: boolean;
}

export const VALIDATION_MESSAGES = {
  requiredField: "This is a required field.",
  email: "Please enter a valid email address.",
  confirmPassword: "The two passwords that you entered are not the same."
};

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
