import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Skeleton } from "antd";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../../layouts/Header/Header";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import usePaths from "../../common/hooks/usePaths";
import Message from "../../common/Message";
import * as common from "../../common/StyledComponents";
import { assertNever } from "../../util/assertNever";
import HomeButton from "../common/HomeButton";

import ActionsAndEnvironments from "./ActionsAndEnvironments";
import General from "./General";
import Navigation, { Tab } from "./Navigation";
import * as AutomationBySlug from "./queries/AutomationBySlug";
import { AutomationNode } from "./queries/common";
import { useSaveAutomation } from "./queries/SaveAutomation";
import reducer, { INITIAL_STATE, ReducerActions } from "./reducer/reducer";
import { getSaveAutomationInput } from "./reducer/utils";
import Schedule from "./Schedule";

export const StyledNavigation = styled(Navigation)`
  flex-basis: 300px;
`;

interface PathParams extends Record<string, string> {
  environment: string;
  slug: string;
}

export const Config = () => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const { state: navState } = useLocation();
  const { slug } = useParams<PathParams>();
  const tab = (query.get("tab") as Tab) || Tab.GENERAL;
  const { automationsapp_getEditAutomation } = usePaths();

  // Loads the automation after a save occurs (the URL may or may not have changed as a result of the save)
  React.useEffect(() => {
    const node = navState as AutomationNode | undefined;
    if (!node?.id) return;

    dispatch({
      type: ReducerActions.LOAD_AUTOMATION,
      payload: navState as AutomationNode
    });
  }, [navState]);

  // Loads the automation by the URL slug when there is no previous state
  const { loading } = useQuery<AutomationBySlug.Data, AutomationBySlug.Variables>(
    AutomationBySlug.QUERY,
    {
      variables: { slug: slug! },
      skip: !slug || !!state.id,
      onCompleted: data => {
        dispatch({
          type: ReducerActions.LOAD_AUTOMATION,
          payload: data.automationBySlug
        });
      }
    }
  );

  const [save, { loading: saving }] = useSaveAutomation({
    onCompleted: data => {
      switch (data.saveAutomation.__typename) {
        case "AutomationNode": {
          Message.success("Saved");
          const search = tab ? `tab=${tab}` : undefined;
          navigate(
            {
              pathname: automationsapp_getEditAutomation(data.saveAutomation.slug),
              search
            },
            { replace: true, state: data.saveAutomation }
          );
          break;
        }
        case "ValidationErrorResult":
          // Message.error(<ErrorPanel errors={data.saveAutomation.messages} />);
          break;
        default:
          return assertNever(data.saveAutomation);
      }
    }
  });
  return (
    <div>
      <PageHeader
        homeButton={<HomeButton />}
        key="automations-config"
        title={state.name}
        controls={
          <ButtonNew
            type="brand"
            loading={saving}
            onClick={() => {
              const automation = getSaveAutomationInput(state);
              save({ variables: { automation } });
            }}
          >
            Save
          </ButtonNew>
        }
      />
      {loading ? (
        <Skeleton />
      ) : (
        <common.ConfigContainer>
          <StyledNavigation tab={tab} />
          <common.Content>
            {tab === Tab.GENERAL && <General state={state} dispatch={dispatch} />}
            {tab === Tab.SCHEDULE && <Schedule state={state} dispatch={dispatch} />}
            {tab === Tab.ACTIONS && (
              <ActionsAndEnvironments state={state} dispatch={dispatch} />
            )}
          </common.Content>
        </common.ConfigContainer>
      )}
    </div>
  );
};
