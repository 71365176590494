import React from "react";

import styled from "styled-components";

import InteractionBlocker from "../../../../../common/InteractionBlocker";
import { useStableSpaceContext } from "../../../SpaceContext";

interface EditModeInteractionBlockerProps {
  children: React.ReactNode;
}

export default function EditModeInteractionBlocker({
  children
}: EditModeInteractionBlockerProps) {
  const { editMode } = useStableSpaceContext();
  if (!editMode) return <>{children}</>;
  return <StyledInteractionBlocker>{children}</StyledInteractionBlocker>;
}

const StyledInteractionBlocker = styled(InteractionBlocker)`
  width: 100%;
  height: 100%;
`;
