import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../BaseComponentConfigSection";
import ButtonDesignConfig from "../ButtonDesignConfig";
import { ItemContainer } from "../CollectionItemContainer/CollectionItemContainer";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../ComponentConfigContext";
import HeaderConfigSection from "../HeaderConfig";
import ParametersConfigSection from "../ParametersManager/ParametersConfigSection";
import VisibilityRulesManagerSection from "../VisibilityRulesManager";

import EffectsConfig from "./EffectsConfig/EffectsConfig";
import FunctionConfig from "./FunctionConfig";
import { ensureSubmittableComponentConfigState } from "./reducer/reducer";
import TextConfig from "./TextConfig";

export default function SubmittableComponentConfig(props: ComponentConfigProps) {
  const dataContent = React.useMemo(() => <DataConfig />, []);
  const effectsContent = React.useMemo(() => <EffectsConfig />, []);
  const designContent = React.useMemo(() => <DesignConfig />, []);
  return (
    <ComponentConfigContextContainer
      slug={props.slug}
      dataConfig={dataContent}
      effectsConfig={effectsContent}
      designConfig={designContent}
    />
  );
}

export function DataConfig() {
  const context = useComponentConfigContext();
  const state = ensureSubmittableComponentConfigState(context.state);
  const { dispatch } = context;
  const func = state.draftComponent.functions.edges[0]?.node;
  const functionId = func?.id;

  return (
    <>
      <FunctionConfig state={state} dispatch={dispatch} />
      {!!functionId && (
        <BaseComponentConfigSection>
          <TextConfig state={state} dispatch={dispatch} />
        </BaseComponentConfigSection>
      )}
      {/* always render ParametersConfigSection so that component cleanup can occur when data source changes and functionId is undefined */}
      <ItemContainer itemKey="fieldset">
        <ParametersConfigSection title="Fields" />
      </ItemContainer>
      {!!functionId && <VisibilityRulesManagerSection />}
    </>
  );
}

function DesignConfig() {
  const context = useComponentConfigContext();
  const { draftComponent } = context.state;
  return (
    <>
      <ButtonDesignConfig />

      {draftComponent.type === "FUNCTION_FORM" && <HeaderConfigSection />}
    </>
  );
}
