import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Input, Skeleton } from "antd";
import gql from "graphql-tag";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import * as styled from "./styledComponents";
import { CredentialFormProps } from "./types";

const GET_SSH_KEY_FORMAT = gql`
  query GetSSHKeyFormat {
    authOrganization {
      id
      sshKeyFormat
    }
  }
`;

export default function SSHFieldSet({
  credentials,
  includeLabel = false,
  isLoading = false,
  form
}: CredentialFormProps) {
  const { loading, data } = useQuery(GET_SSH_KEY_FORMAT);
  if (process.env.INTERNALIO_ONPREM === "1") return null;
  const sshPublicKey = data?.authOrganization?.sshKeyFormat;
  const { getFieldDecorator } = form;
  return (
    <>
      <FormItem label={includeLabel && "Bastion User"}>
        {getFieldDecorator("SshUser", {
          initialValue: credentials?.ssh_user,
          preserve: true,
          rules: [{ required: true, message: VALIDATION_MESSAGES.requiredField }]
        })(
          <Input
            placeholder="Bastion User"
            data-test="ssh-user-input"
            disabled={isLoading}
          />
        )}
      </FormItem>
      <FormItem label={includeLabel && "Bastion Host"}>
        {getFieldDecorator("SshHost", {
          initialValue: credentials?.ssh_host,
          preserve: true,
          rules: [{ required: true, message: VALIDATION_MESSAGES.requiredField }]
        })(
          <Input
            placeholder="Bastion Host"
            data-test="ssh-host-input"
            disabled={isLoading}
          />
        )}
      </FormItem>
      <FormItem label={includeLabel && "Bastion Port"}>
        {getFieldDecorator("SshPort", {
          initialValue: credentials?.ssh_port,
          preserve: true,
          rules: [{ required: true, message: VALIDATION_MESSAGES.requiredField }]
        })(
          <Input
            placeholder="Bastion Port"
            data-test="ssh-port-input"
            disabled={isLoading}
          />
        )}
      </FormItem>
      <FormItem label="SSH Public Key">
        <Skeleton loading={loading} />
        {!loading && (
          <p>
            <Input.TextArea value={sshPublicKey} readOnly />
          </p>
        )}
      </FormItem>
      <styled.PTagWithTextColor>
        <a
          href={"https://www.internal.io/docs/ssh-tunneling"}
          target="_blank"
          rel="noopener noreferrer"
        >
          View instructions{" "}
        </a>
        for SSH Tunneling.
      </styled.PTagWithTextColor>
    </>
  );
}
