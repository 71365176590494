import styled from "styled-components";

import { Main } from "../../../common/layout/styledComponents";

import BaseGettingStarted from "./GettingStarted";

export const GettingStartedContainer = styled(Main)`
  padding: 0;
  overflow: initial;
`;

export const GettingStarted = styled(BaseGettingStarted)`
  padding: 48px 32px 24px;
`;
