import {
  AttributeTypes,
  TIME_SAVE_FORMAT,
  TIMESTAMP_DISPLAY_FORMAT,
  DATE_TIME_DISPLAY_FORMAT,
  TIMESTAMP_SAVE_FORMAT,
  DATE_TIME_SAVE_FORMAT,
  TIME_DISPLAY_FORMAT,
  DATE_SAVE_FORMAT,
  DATE_DISPLAY_FORMAT
} from "../../../../../constants";

export const saveFormat = {
  [AttributeTypes.TIMESTAMP]: TIMESTAMP_SAVE_FORMAT,
  [AttributeTypes.DATETIME]: DATE_TIME_SAVE_FORMAT,
  [AttributeTypes.DATE]: DATE_SAVE_FORMAT,
  [AttributeTypes.TIME]: TIME_SAVE_FORMAT
};

export const displayFormat = {
  [AttributeTypes.TIMESTAMP]: TIMESTAMP_DISPLAY_FORMAT,
  [AttributeTypes.DATETIME]: DATE_TIME_DISPLAY_FORMAT,
  [AttributeTypes.DATE]: DATE_DISPLAY_FORMAT,
  [AttributeTypes.TIME]: TIME_DISPLAY_FORMAT
};
