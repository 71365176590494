import { Dimensions } from "../../SpaceImage/types";

export default function fitImageToContainer(container: Dimensions, image: Dimensions) {
  const scaleDimension = getScaleToDimension(image, container);

  if (!scaleDimension) return {};

  if (image[scaleDimension] < container[scaleDimension]) {
    return { [scaleDimension]: image[scaleDimension] };
  }

  // find dimension that is closer to container, scale as little as possible
  return getScaleToDimension(image, container) === "height"
    ? {
        height: container.height
      }
    : {
        width: container.width
      };
}

export function getScaleToDimension(
  naturalSize: Dimensions | null,
  constraintSize: Dimensions
) {
  if (
    naturalSize === null ||
    constraintSize === null ||
    naturalSize.width === 0 ||
    naturalSize.height === 0
  )
    return;
  const widthScale = constraintSize.width / naturalSize.width;
  const heightScale = constraintSize.height / naturalSize.height;
  return heightScale < widthScale ? "height" : "width";
}
