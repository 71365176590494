/**
 * Editor component for function code input.
 * --
 * UI notes:
 * Found when you create or edit a function by opening App Data from the
 * space edit page, or going to "Data & Functions" in Company Settings,
 * etc. There are various editors in this UI, depending on the type of
 * data source, and the various tabs available. Examples:
 * - HTTP: JSON, GraphQL, etc under Configure tab
 * - HTTP: Transformers under Transform tab
 * - SQL/MongoDB editors under Configure tab
 */
import React, { ReactNode, useCallback, useMemo, useReducer, useState } from "react";

import { Tabs } from "antd";
import _ from "lodash";
import SplitPane from "react-split-pane";

import { ReturnSchema } from "../../../constants";
import {
  APIFiltersOption,
  APISortByOption,
  BaseFunctionName,
  BaseFunctionNodeBasic,
  DescribeColumn,
  FunctionAttribute,
  FunctionParameterInput,
  Metadata
} from "../../../types";
import TabTitle from "../TabTitle";

import * as common from "./common/styledComponents";
import { RequestBodyType } from "./forms/constants";
import HttpFunctionForm from "./forms/http/HttpFunctionForm";
import { MongoForm } from "./forms/mongo";
import SqlActionForm from "./forms/sql/SqlActionForm";
import { DataSourceNodeWithFunctions } from "./forms/types";
import FunctionAuthorizationFlows from "./FunctionAuthorizationFlows";
import { FunctionInput } from "./FunctionInput/FunctionInput";
import { FunctionOutput } from "./FunctionOutput/FunctionOutput";
import { FunctionPreview } from "./FunctionPreview";
import {
  getInitialState as getInitialPreviewState,
  reducer as previewReducer
} from "./FunctionPreview/reducer";
import FunctionTransformer from "./FunctionTransformer";
import * as styled from "./styledComponents";
import {
  areReservedParamsEditable,
  isHTTPLike,
  isSQLLike,
  supportsEditableInput
} from "./support";
import { BaseFunctionConfig, State } from "./useFunctionEditor/reducer";
import { toDataSourceFunction } from "./util";

import {
  BaseFunctionParameterMapping,
  EditorTab,
  FunctionValidationStatus,
  OnAuthorizationFlowChangeCallback,
  PreviewResult
} from "./index";

export interface FunctionEditorMetadata<M> extends Metadata<M> {
  request_type?: RequestBodyType;
}

export interface Props {
  dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>;
  isLoading: boolean;
  editorState: Readonly<State>;
  showErrors: boolean;
  onHasError: (hasError: boolean) => void;
  onBaseConfigChange: <C>(config: BaseFunctionConfig<C>) => void;
  onBaseFunctionNameChange: (name: BaseFunctionName) => void;
  onMetadataChange: <M>(metadata: FunctionEditorMetadata<M>) => void;
  onReducerChange: (reducer: string) => void;
  onMetadataReducerChange: (reducer: string) => void;
  onParameterChange: (parameter: FunctionParameterInput) => void;
  onAttributesChange: (attributes: FunctionAttribute[]) => void;
  onPreviewResult: (result: PreviewResult) => void;
  onRefreshAttributes: () => void;
  onActiveEditorTabChange: (tab: EditorTab) => void;
  onReturnSchemaChange: (returnSchema: ReturnSchema) => void;
  onAuthorizationFlowChange: OnAuthorizationFlowChangeCallback;
  onDescribeColumnsChange: (columns: DescribeColumn[]) => void;
}

const Tab = (props: { children: ReactNode; unseen: boolean }) => {
  return (
    <div>
      <span>{props.children}</span>
      {props.unseen && <styled.UnseenIndicator />}
    </div>
  );
};

export const FunctionEditor = ({
  dataSource,
  editorState,
  onHasError,
  onBaseConfigChange,
  onBaseFunctionNameChange,
  onMetadataChange,
  onReducerChange,
  onMetadataReducerChange,
  onParameterChange,
  onAttributesChange,
  onPreviewResult,
  onRefreshAttributes,
  onActiveEditorTabChange,
  onDescribeColumnsChange,
  ...props
}: Props) => {
  const [validationStatus, setValidationStatus] = useState<FunctionValidationStatus>(
    FunctionValidationStatus.VALID
  );

  const previewer = useReducer(previewReducer, getInitialPreviewState());

  const func = React.useMemo(
    () => toDataSourceFunction(dataSource, editorState),
    [dataSource, editorState]
  );

  const _onBaseConfigChange = useCallback(
    function <C>(mapping: BaseFunctionParameterMapping, code?: C) {
      return onBaseConfigChange({
        baseFunctionParameterMapping: mapping,
        code
      });
    },
    [onBaseConfigChange]
  );

  const onHttpRequestTypeChange = useCallback(
    (requestBodyType: RequestBodyType) => {
      const updated = {
        ...editorState.metadata,
        request_type: requestBodyType
      };
      onMetadataChange(updated);
    },
    [editorState.metadata, onMetadataChange]
  );

  const onFiltersOptionsChange = useCallback(
    (options: APIFiltersOption[]) => {
      const updated = {
        ...editorState.metadata,
        filters: { options }
      };
      onMetadataChange(updated);
    },
    [onMetadataChange, editorState.metadata]
  );

  const onSortByOptionsChange = useCallback(
    (options: APISortByOption[]) => {
      const updated = {
        ...editorState.metadata,
        sortBy: { options }
      };
      onMetadataChange(updated);
    },
    [onMetadataChange, editorState.metadata]
  );

  const onIsMutationChange = useCallback(
    (isMutation: boolean) => {
      const categories = new Set(editorState.metadata.categories);
      if (isMutation) {
        categories.add("mutation");
      } else {
        categories.delete("mutation");
      }
      onMetadataChange({
        ...editorState.metadata,
        categories: Array.from(categories).sort()
      });
    },
    [onMetadataChange, editorState.metadata]
  );

  const onFunctionAttributesChange = useCallback(
    (attributes: FunctionAttribute[]) => {
      onAttributesChange(attributes);
    },
    [onAttributesChange]
  );

  const onValidationStatusChange = useCallback(
    (status: FunctionValidationStatus) => {
      setValidationStatus(status);
      onHasError(status === FunctionValidationStatus.INVALID);
    },
    [onHasError, setValidationStatus]
  );

  const inputsChanged = useMemo(
    () => !_.isEqual(editorState.parameters, editorState.lastSeenParameters),
    [editorState.parameters, editorState.lastSeenParameters]
  );
  const outputsChanged = useMemo(
    () => !_.isEqual(editorState.attributes, editorState.lastSeenAttributes),
    [editorState.attributes, editorState.lastSeenAttributes]
  );

  if (props.isLoading) return null;

  const { parameters } = editorState;
  const filtersOptions = editorState.metadata.filters?.options || [];
  const sortByOptions = editorState.metadata.sortBy?.options || [];
  const isMutation = (editorState.metadata.categories || []).includes("mutation");
  const inputsEditable = supportsEditableInput(
    dataSource.integration,
    func?.baseFunction?.name as BaseFunctionName
  );
  const reservedInputsEditable = areReservedParamsEditable(dataSource.integration);
  const outputsEditable =
    !isSQLLike(dataSource.integration) ||
    (isSQLLike(dataSource.integration) &&
      func?.reducer !== "data" &&
      func?.reducer !== "data[0]");
  const outputsRefreshable =
    outputsEditable && editorState.previewId > editorState.refreshId;

  return (
    <SplitPane split="horizontal" defaultSize="500px">
      <styled.ConfigurationPane>
        <common.Tabs
          onChange={tab => onActiveEditorTabChange(tab as EditorTab)}
          animated={false}
          activeKey={editorState.activeEditorTab}
        >
          <Tabs.TabPane
            key={EditorTab.CONFIGURE}
            tab={
              <TabTitle
                hasError={
                  props.showErrors &&
                  validationStatus === FunctionValidationStatus.INVALID
                }
              >
                <div>Configure</div>
              </TabTitle>
            }
          >
            {isHTTPLike(dataSource.integration) && (
              <styled.FormGridContainer>
                <HttpFunctionForm
                  key={func?.id}
                  func={func}
                  selectedDataSource={dataSource}
                  previewEnvironmentId={previewer[0].environmentId}
                  setBaseConfig={_onBaseConfigChange}
                  setValidationStatus={onValidationStatusChange}
                  onRequestTypeChange={onHttpRequestTypeChange}
                  showErrors={props.showErrors}
                />
              </styled.FormGridContainer>
            )}
            {dataSource.integration === "mongo" && (
              <MongoForm
                key={`${func?.baseFunction?.name || ""}-${func?.id || "new"}`}
                func={func}
                selectedDataSource={dataSource}
                setBaseConfig={_onBaseConfigChange}
                setBaseFunctionName={onBaseFunctionNameChange}
                setValidationStatus={onValidationStatusChange}
                showErrors={props.showErrors}
              />
            )}
            {isSQLLike(dataSource.integration) && (
              <SqlActionForm
                func={func}
                selectedDataSource={dataSource}
                setBaseConfig={_onBaseConfigChange}
                setBaseFunctionName={onBaseFunctionNameChange}
                setValidationStatus={onValidationStatusChange}
                setDescribeColumns={onDescribeColumnsChange}
                showErrors={props.showErrors}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Transform" key={EditorTab.TRANSFORM}>
            <FunctionTransformer
              func={func}
              dataSourceId={dataSource.id}
              metadataReducer={editorState.metadataReducer}
              reducer={editorState.reducer}
              onDescribeColumnsChange={onDescribeColumnsChange}
              onMetadataReducerChange={onMetadataReducerChange}
              onReducerChange={onReducerChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            key={EditorTab.INPUTS}
            tab={<Tab unseen={inputsChanged}>Inputs</Tab>}
          >
            <FunctionInput
              editable={inputsEditable}
              reservedEditable={reservedInputsEditable}
              parameters={parameters}
              onParameterChange={onParameterChange}
              filtersOptions={filtersOptions}
              onFiltersOptionsChange={onFiltersOptionsChange}
              sortByOptions={sortByOptions}
              onSortByOptionsChange={onSortByOptionsChange}
              isMutation={isMutation}
              onIsMutationChange={onIsMutationChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            key={EditorTab.OUTPUTS}
            tab={<Tab unseen={outputsChanged}>Outputs</Tab>}
          >
            <FunctionOutput
              editable={outputsEditable}
              refreshable={outputsRefreshable}
              attributes={editorState.attributes}
              returnSchema={editorState.returnSchema}
              onReturnSchemaChange={props.onReturnSchemaChange}
              onAttributesChange={onFunctionAttributesChange}
              onRefreshAttributes={onRefreshAttributes}
            />
          </Tabs.TabPane>
          {isHTTPLike(dataSource.integration) && (
            <Tabs.TabPane key={EditorTab.AUTH} tab={<Tab unseen={false}>Auth</Tab>}>
              <FunctionAuthorizationFlows
                authorizationFlows={editorState.authorizationFlows}
                environmentIdsWithCredentials={
                  editorState.environmentIdsWithCredentials
                }
                onAuthorizationFlowChange={props.onAuthorizationFlowChange}
              />
            </Tabs.TabPane>
          )}
        </common.Tabs>
      </styled.ConfigurationPane>
      <FunctionPreview
        baseFunctionId={editorState.baseFunctionId}
        baseFunctionName={editorState.baseFunctionName as BaseFunctionName}
        baseFunctionParameterMapping={editorState.baseFunctionParameterMapping}
        metadata={editorState.metadata}
        reducer={editorState.reducer}
        metadataReducer={editorState.metadataReducer}
        integration={dataSource.integration}
        parameters={parameters}
        filtersOptions={filtersOptions}
        sortByOptions={sortByOptions}
        validationStatus={validationStatus}
        previewer={previewer}
        authorizationFlows={editorState.authorizationFlows}
        onPreviewResult={onPreviewResult}
        onActiveEditorTabChange={onActiveEditorTabChange}
      />
    </SplitPane>
  );
};
