import React from "react";

import { InputParameter } from "../../../../../../../types";
import ButtonNew from "../../../../../../common/ButtonNew/ButtonNew";
import FormBuilderModal from "../../../../../../common/FormBuilderModal";
import formBuilderReducer, {
  INTERNAL_TASK_PARAMETERS
} from "../../../../common/formBuilderReducer";
import { ReducerActions } from "../../../../reducer/actions";
import { getTaskSchemaFromFields } from "../../../../reducer/utils";
import { UpdateTaskOptions } from "../../../../types";
import * as common from "../styledComponents";
import { ActionProps } from "../types";

import * as styled from "./styledComponents";
import { fieldsToUpdateFunctionNode } from "./utils";

export default function UpdateTask({
  action,
  dispatch,
  index,
  state
}: ActionProps<UpdateTaskOptions>) {
  const [showFormBuilder, setShowFormBuilder] = React.useState(false);

  const onInputParametersSave = (inputParameters: InputParameter[]) => {
    dispatch({
      type: ReducerActions.UPDATE_ACTION_INPUT_PARAMETERS,
      payload: {
        index,
        inputParameters
      }
    });
    setShowFormBuilder(false);
  };

  return (
    <common.Container>
      <styled.ConfigureContainer>
        <ButtonNew onClick={() => setShowFormBuilder(true)}>Edit form</ButtonNew>
      </styled.ConfigureContainer>
      {showFormBuilder && (
        <FormBuilderModal
          title={"Update task form"}
          additionalSchema={getTaskSchemaFromFields(state.fields)}
          func={fieldsToUpdateFunctionNode(state.fields)}
          initialInputParameters={action.options?.inputParameters}
          onSave={onInputParametersSave}
          onCancel={() => setShowFormBuilder(false)}
          internalFields={INTERNAL_TASK_PARAMETERS}
          customReducer={formBuilderReducer}
        />
      )}
    </common.Container>
  );
}
