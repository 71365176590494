import { useCallback } from "react";

import { useMutation } from "@apollo/react-hooks";
import { omit } from "lodash";

import { EnvironmentNode } from "../../../types";
import Message from "../../common/Message";

import { ManageEnvironmentsState } from "./manageEnvironmentsReducer";
import { ALL_ENVIRONMENTS, UPDATE_ENVIRONMENTS } from "./queries";

interface Result {
  save: () => void;
  loading: boolean;
}

export type UseSetEnvironmentsData = {
  updateEnvironments: {
    environments?: EnvironmentNode[];
    message?: string;
    __typename: "UpdateEnvironmentsResultSuccess" | "UpdateEnvironmentsResultError";
  };
};

interface EnvironmentForSubmit {
  id?: string;
  name: string;
}
export type UseSetEnvironmentsVars = {
  environmentsInput: EnvironmentForSubmit[];
};

const FIELDS_TO_EXCLUDE = ["isDefault", "slug", "dataSourceCount", "__typename"];

export default function useSetEnvironmentsMutation(
  state: ManageEnvironmentsState,
  onCompleted: () => void
): Result {
  const [saveConfig, { loading }] = useMutation<
    UseSetEnvironmentsData,
    UseSetEnvironmentsVars
  >(UPDATE_ENVIRONMENTS, {
    update: (cache, response: { data: UseSetEnvironmentsData }) => {
      if (!response.data.updateEnvironments.environments) return;
      const allEnvironments = {
        __typename: "EnvironmentNodeConnection",
        edges: response.data.updateEnvironments.environments.map(environment => ({
          __typename: "EnvironmentNodeEdge",
          node: {
            ...environment,
            __typename: "EnvironmentNode"
          }
        }))
      };
      cache.writeQuery({
        query: ALL_ENVIRONMENTS,
        data: { allEnvironments }
      });
    },
    onCompleted: res => {
      if (res.updateEnvironments.__typename === "UpdateEnvironmentsResultSuccess") {
        Message.success("Your environments have been updated.");
        onCompleted();
        return;
      }
      Message.error(res.updateEnvironments.message);
    }
  });

  const save = useCallback(() => {
    saveConfig({
      variables: {
        environmentsInput: state.environments.map(e => {
          const fieldsToExclude = e.id.startsWith("temp-")
            ? [...FIELDS_TO_EXCLUDE, "id"]
            : FIELDS_TO_EXCLUDE;
          return omit(e, fieldsToExclude);
        }) as EnvironmentForSubmit[]
      }
    });
  }, [state, saveConfig]);

  return {
    save,
    loading
  };
}
