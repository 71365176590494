import React from "react";

import { captureException } from "@sentry/browser";
import { message, Icon as AntIcon } from "antd";
import styled from "styled-components";

import { colorTokens, SpacingUnit } from "../../../cssConstants";

// Since this element is rendered via an ant message which is really
// an RCNotification that's not inside the ThemeProvider of our app,
// we must access the constants directly instead of via the theme.
const Icon = styled(AntIcon)`
  &.anticon {
    position: relative;
    top: 3px;
    margin-left: ${SpacingUnit.sm};
    color: ${colorTokens.greyTransparent1000};
    align-self: flex-start;

    margin-right: 0px;
  }
`;

const Container = styled.div`
  display: inline-flex;
  /* 72px is the width of the all of the ant message including padding which does not include the container */
  max-width: calc(100vw - 72px);
`;

const CloseIcon = () => <Icon onClick={() => message.destroy()} type="close" />;

type Content = string | React.ReactNode;

// Based on Antd API https://3x.ant.design/components/message/ VERSION 3
// If we upgrade to antd current version, the constants in the styled components above may change
export default class Message {
  static destroy() {
    try {
      message.destroy();
    } catch (e) {
      captureException(e);
    }
  }

  static error(content: Content, duration?: number) {
    return message.error(
      <Container>
        {content}
        <CloseIcon />
      </Container>,
      duration
    );
  }

  static info(content: Content, duration?: number) {
    return message.info(
      <Container>
        {content}
        <CloseIcon />
      </Container>,
      duration
    );
  }

  static warning(content: Content, duration?: number) {
    return message.warning(
      <Container>
        {content}
        <CloseIcon />
      </Container>,
      duration
    );
  }

  static success(content: Content, duration?: number) {
    return message.success(
      <Container>
        {content}
        <CloseIcon />
      </Container>,
      duration
    );
  }
}
