import React from "react";

import styled from "styled-components";

import DatePicker from "../DatePicker";
import TimePickerBase from "../TimePicker";
import TimezonePicker from "../TimezonePicker";

import { MomentPopperProps } from ".";

const FlexContainer = styled.div`
  display: flex;
`;

const TimePickerContainer = styled.div`
  padding-left: ${props => props.theme.spacermd};
`;

const TimezoneContainer = styled.div`
  padding-top: ${props => props.theme.spacermd};
`;

export const TimeStampPicker = (props: MomentPopperProps) => (
  <FlexContainer>
    <DatePicker {...props} />
    <TimePickerContainer>
      <TimePicker {...props} />
      <TimezoneContainer>
        <TimezonePicker {...props} />
      </TimezoneContainer>
    </TimePickerContainer>
  </FlexContainer>
);

export const DateTimePicker = (props: MomentPopperProps) => (
  <FlexContainer>
    <DatePicker {...props} />
    <TimePickerContainer>
      <TimePicker {...props} />
    </TimePickerContainer>
  </FlexContainer>
);

export const TimePicker = (props: MomentPopperProps) => (
  <FlexContainer>
    <TimePickerBase {...props} />
  </FlexContainer>
);
