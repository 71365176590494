import { SpaceComponentHostType, SpaceComponentPackage } from "../../../../../types";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import getSchema from "./getSchema";

import SpaceContextParam from "./";

const pkg: SpaceComponentPackage = {
  type: "CONTEXT_PARAM",
  displayName: "Context Param",
  allowedHosts: new Set<SpaceComponentHostType>([]),
  Component: SpaceContextParam,
  ensureComponent: component => component,
  componentConfigReducer: commonComponentReducer,
  ConfigurationComponent: null,
  ConfigurationSection: () => null,
  getSchema,
  isHeadless: true,
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isClientProvidedComponent: false,
  errorSelector: () => [],
  isContainer: false
};

export default pkg;
