import React from "react";

import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { FormToggle } from "./styledComponents";

export type ChangeEvent = Omit<CheckboxChangeEvent, "target"> & {
  target: { value: boolean };
};

interface Props {
  title: string;
  disabled?: boolean;

  value?: boolean;
  onChange?: (e: ChangeEvent) => void;
}

const InputBoolean = ({ title, disabled, value, onChange }: Props) => {
  return (
    <FormToggle
      checked={value}
      disabled={disabled}
      onChange={(e: CheckboxChangeEvent) => {
        if (onChange) {
          onChange({ ...e, target: { value: e.target.checked } });
        }
      }}
    >
      {title}
    </FormToggle>
  );
};

export default InputBoolean;
