import React from "react";

import { Button, Tooltip } from "antd";
import produce from "immer";
import invariant from "invariant";
import { cloneDeep } from "lodash";
import styled from "styled-components";

import { AttributeTypes } from "../../../../../../constants";
import {
  SourceType,
  SpaceComponentObject,
  SpaceFunctionType
} from "../../../../../../types";
import { ReservedListParameter } from "../../../../../common/FunctionEditor";
import SortableList, { SortableItemCompact } from "../../../../../common/SortableList";
import {
  createSpaceFunction,
  SpaceFunction
} from "../../../../FunctionExecutor/FunctionExecutor";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import {
  selectAllSlugs,
  ensureSlug
} from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import useComponentNode from "../../../../util/useComponentNode";
import {
  DEFAULT_VALUE_TYPE_BY_ATTRIBUTE_TYPE,
  DATE_FIELD_TYPE_BY_PARAMETER_TYPE,
  DefaultValueType
} from "../../../constants";
import { useStableSpaceContext } from "../../../SpaceContext";
import { FORM_COMPONENT_TYPES } from "../../constants";
import { useComponentConfigContext } from "../ComponentConfigContext";
import ValidationError from "../ComponentConfigContext/ValidationError";
import {
  ConfigPanelPopper,
  ConfigSection,
  ScrollableConfigSection
} from "../ConfigPanel";
import {
  useSpaceConfigPanelContext,
  ConfigPanelActionTypes
} from "../ConfigPanel/ConfigPanelContext";
import { WrapTextButton } from "../ConfigPanel/styledComponents";
import { InputParameter, ParameterType } from "../useFuncParams";
import { InputParameterComponent } from "../useFuncParams/types";

import ParameterConfigurationPanel from "./ParameterConfigurationPanel";
import {
  makeInputParameter,
  makeUpdatedParam,
  selectExcludedParameters,
  getBindingPath,
  getDefaultComponentType,
  createInsertComponentPayload
} from "./utils";

interface Props {
  title: string;
}

const ButtonWithMargin = styled(Button)`
  margin-bottom: ${props => props.theme.spacerxl};
`;
ButtonWithMargin.displayName = "AddFieldButton";

const Error = styled.p`
  color: ${props => props.theme.errorColor};
`;
Error.displayName = "Error";

const INSERT_PARAMS_CONFIG_POPPER_ID = "insertParams";

// used in toggleHide to map input types to form param types,
// because when we hide an input field, we persist its properties on the form
const getParameterType = (
  inputType: DefaultValueType,
  attributeType: AttributeTypes
) => {
  if (inputType === DefaultValueType.CURRENT_DATETIME) {
    // "Default Value" dropdown -> "Current date and time"
    switch (attributeType) {
      case AttributeTypes.DATETIME:
      case AttributeTypes.TIMESTAMP:
        return ParameterType.DATETIME_NOW;
      case AttributeTypes.DATE:
        return ParameterType.DATE_TODAY;
      case AttributeTypes.TIME:
        return ParameterType.TIME_NOW;
    }
  } else {
    switch (inputType) {
      case DefaultValueType.BINDING:
        // "Default Value" dropdown -> "Pre-fill with value from component"
        return ParameterType.BINDING;
      case DefaultValueType.UUID:
        // "Default Value" dropdown -> "Set a UUID"
        return ParameterType.UUID;
      case DefaultValueType.TEMPLATE:
        // "Default Value" dropdown -> "Set a template"
        // Templates don't map directly to a form property, & they support JS eval,
        // e.g. ${table1.selectedRow.data.email.toUpperCase()}
        return ParameterType.PENDING;
      default:
        // "Default Value" dropdown -> "Set to null" / "Set a value" / ("Select a value" for date type)
        return ParameterType.STATIC;
    }
  }
};

export default function ParametersConfigSection({ title }: Props) {
  const { state, dispatch, errors } = useComponentConfigContext();
  const componentNode = useComponentNode();
  const { state: configPanelState, dispatch: configPanelDispatch } =
    useSpaceConfigPanelContext();
  const {
    state: spaceConfigState,
    dispatch: spaceConfigDispatch,
    predictSlug
  } = useSpaceConfigContext();
  const { draftComponent } = state;
  const inputParameters = draftComponent.properties.input_parameters || [];
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const pkg = findSpaceComponentPackage(draftComponent.type);
  const isFormConfig = FORM_COMPONENT_TYPES.includes(draftComponent.type);
  const isFileSource = draftComponent.sourceType === SourceType.FILE;

  const func = createSpaceFunction(draftComponent);

  const functionParameterNodes = React.useMemo(() => {
    if (!func?.functionParameters) {
      return [];
    }
    const reservedListParameterValues = Object.values(
      ReservedListParameter
    ) as string[];
    return func.functionParameters.filter(param => {
      return func?.metadata?.categories?.includes("returns:many")
        ? !reservedListParameterValues.includes(param.name)
        : true;
    });
  }, [func]);

  const excludedParameters = React.useMemo(() => {
    return selectExcludedParameters(draftComponent, functionParameterNodes);
  }, [draftComponent, functionParameterNodes]);

  const removeComponent = React.useCallback(
    (inputParameter: InputParameterComponent) => {
      if (inputParameter.component_slug) {
        spaceConfigDispatch({
          type: "REMOVE_COMPONENT",
          payload: {
            slug: inputParameter.component_slug
          }
        });
      }
    },
    [spaceConfigDispatch]
  );

  const onChange = React.useCallback(
    (updatedParameters: InputParameter[]) =>
      dispatch({
        type: "SET_DRAFT_COMPONENT",
        payload: {
          path: "properties.input_parameters",
          value: updatedParameters
        }
      }),
    [dispatch]
  );

  const onAdd = React.useCallback(
    (name: string) => {
      const funcParam = func!.functionParameters.find(n => n.name === name);
      invariant(!!funcParam, `Expected function parameter with name ${name}.`);
      const updatedParameters = produce(
        draftComponent.properties.input_parameters as InputParameter[],
        (params: InputParameter[]) => {
          params.push(makeInputParameter(funcParam!, draftComponent.type));
        }
      );
      onChange(updatedParameters);
    },
    [onChange, func, draftComponent.properties.input_parameters, draftComponent.type]
  );

  const onRemove = React.useCallback(
    (name: string) => {
      const updatedParameters = produce(
        draftComponent.properties.input_parameters as InputParameter[],
        params => {
          const index = params.findIndex(param => param.name === name);
          const paramToDelete = draftComponent.properties.input_parameters[index];
          if (paramToDelete.type === ParameterType.COMPONENT) {
            removeComponent(paramToDelete);
          }
          params.splice(index, 1);
        }
      );
      onChange(updatedParameters);
    },
    [draftComponent.properties.input_parameters, onChange, removeComponent]
  );

  const onReorder = React.useCallback(
    (oldIndex, newIndex) => {
      const updatedParameters = produce(
        draftComponent.properties.input_parameters as InputParameter[],
        params => {
          const [removed] = params.splice(oldIndex, 1);
          params.splice(newIndex, 0, removed);
        }
      );
      onChange(updatedParameters);
    },
    [onChange, draftComponent.properties.input_parameters]
  );

  const onConfigure = React.useCallback(
    (
      payload: {
        name: string;
      } & Partial<InputParameter>
    ) => {
      const updatedParameters = produce(
        draftComponent.properties.input_parameters as InputParameter[],
        params => {
          const index = params.findIndex(
            (param: InputParameter) => param.name === payload.name
          );
          const oldParam = params.splice(index, 1)[0];
          params.splice(
            index,
            0,
            makeUpdatedParam(
              { ...oldParam, ...payload } as InputParameter,
              draftComponent
            )
          );
        }
      );
      onChange(updatedParameters);
    },
    [draftComponent, onChange]
  );

  /**
   * when field is hidden, params are persisted on the form component via input_parameters,
   * e.g. "type": "datetime_now"
   * when field is visible, params are persisted on the input component
   * e.g. "default_value_type": "current_datetime"
   */
  const toggleHide = React.useCallback(
    (inputParameter: InputParameter) => {
      const hidden = !inputParameter.hidden;
      const newConfig: {
        name: string;
        component_slug?: string; // explicitly add optional InputParameterComponent props so we can set values to undefined if needed
        component_type?: string;
        value?: string;
        error?: {
          binding?: string;
        };
      } & Partial<InputParameter> = {
        name: inputParameter.name,
        hidden
      };
      const funcParam = functionParameterNodes.find(
        node => node.name === inputParameter.name
      );
      if (!funcParam) {
        onConfigure(newConfig);
        return;
      }

      if (hidden) {
        if (inputParameter.type === ParameterType.COMPONENT) {
          const sc = draftComponent.componentTreeNodes.find(
            sc => sc.slug === inputParameter.component_slug
          );

          // get default input parameter properties when field is hidden
          const newInput = makeInputParameter(funcParam, draftComponent.type, true);

          // keep existing config, but update type, value, and field_type
          const defaultValueType = sc?.properties.default_value_type;
          const paramType = getParameterType(defaultValueType, funcParam.type);

          if (
            (funcParam.required || !sc?.properties.allow_blank) &&
            !sc?.properties.default_value &&
            paramType === ParameterType.STATIC
          ) {
            // If no default value is set for required or non-blank static field,
            // type is set to "none", so that we can show a warning message.
            newConfig.type = newInput.type;
          } else {
            newConfig.type = paramType;
            if (paramType === ParameterType.STATIC) {
              newConfig.value =
                defaultValueType === DefaultValueType.NULL
                  ? null
                  : sc?.properties.default_value;
            }
          }
          newConfig.field_type = newInput.field_type;

          // explicitly clear component properties
          newConfig.component_slug = undefined;
          newConfig.component_type = undefined;
          newConfig.error = undefined;
          newConfig.binding = sc?.properties.default_value_binding?.binding;
          newConfig.required = sc
            ? !sc.properties.allow_blank
            : inputParameter.required;
          newConfig.blank_value_type =
            sc?.properties.blank_value_type || inputParameter.blank_value_type;

          removeComponent(inputParameter);
        }
      } else {
        // get default input parameter properties when field is NOT hidden
        const newInput = makeInputParameter(funcParam, draftComponent.type, false);

        const isDateType = [
          ParameterType.DATE_TODAY,
          ParameterType.TIME_NOW,
          ParameterType.DATETIME_NOW
        ].includes(inputParameter.type);

        // "Set date/time/(date and time) the button is clicked" in the "Value" dropdown
        if (isDateType) {
          newConfig.type = inputParameter.type;
          newConfig.field_type = DATE_FIELD_TYPE_BY_PARAMETER_TYPE[inputParameter.type];
        } else {
          newConfig.type = newInput.type;
        }

        if (newInput.type === ParameterType.COMPONENT) {
          const payload = createInsertComponentPayload(
            newInput.component_type,
            draftComponent.slug,
            funcParam
          );

          if (inputParameter.type === ParameterType.BINDING) {
            payload.componentConfig.properties.default_value_type = "binding";
            payload.componentConfig.properties.default_value_binding = {
              binding: inputParameter.binding
            };
          } else if (inputParameter.type === ParameterType.STATIC) {
            payload.componentConfig.properties.default_value_type =
              inputParameter.value === null
                ? DefaultValueType.NULL
                : DEFAULT_VALUE_TYPE_BY_ATTRIBUTE_TYPE[funcParam.type];
            payload.componentConfig.properties.default_value = inputParameter.value;
          } else {
            if (inputParameter.type === ParameterType.UUID) {
              payload.componentConfig.properties.default_value_type =
                DefaultValueType.UUID;
            }
          }

          // set required and blank value type values
          payload.componentConfig.properties.allow_blank = !inputParameter.required;
          payload.componentConfig.properties.blank_value_type =
            inputParameter.blank_value_type;

          const nextSlug = predictSlug(newInput.component_type);
          spaceConfigDispatch({
            type: "INSERT_COMPONENT",
            payload
          });
          newConfig.component_type = newInput.component_type;
          newConfig.component_slug = nextSlug;
          newConfig.binding = getBindingPath(componentNode, nextSlug, "value");
          newConfig.error = {
            binding: getBindingPath(componentNode, nextSlug, "error")
          };
          if (!isDateType) newConfig.field_type = undefined;
        }
      }
      onConfigure(newConfig);
    },
    [
      functionParameterNodes,
      onConfigure,
      removeComponent,
      draftComponent.componentTreeNodes,
      draftComponent.type,
      draftComponent.slug,
      componentNode,
      predictSlug,
      spaceConfigDispatch
    ]
  );

  // for any params added during initialization, components may not have been added yet.
  const pendingInputs = React.useMemo(
    () =>
      draftComponent.properties.input_parameters.filter(
        (param: InputParameter) =>
          param.type === ParameterType.COMPONENT &&
          (param.component_slug === undefined ||
            !draftComponent.componentTreeNodes.some(
              ctn => ctn.slug === param.component_slug
            ))
      ),
    [draftComponent.componentTreeNodes, draftComponent.properties.input_parameters]
  );

  // insert space components and update param config for pendingInputs
  React.useEffect(() => {
    if (!pendingInputs.length || !componentNode) return;

    let updatedParameters = cloneDeep(draftComponent.properties.input_parameters);

    const pendingSlugs: string[] = [];
    pendingInputs.forEach((param: InputParameterComponent) => {
      const functionParameterNode = functionParameterNodes.find(
        node => node.name === param.name
      );
      if (functionParameterNode === undefined) return;
      const componentType =
        param.component_type || getDefaultComponentType(functionParameterNode.type);
      if (componentType === undefined) return;

      // component inserts are essentially batched (dispatch events handled contiguously)
      // so available component slugs are not updated during this iteration
      const nextSlug = ensureSlug(
        componentType,
        new Set([...selectAllSlugs(spaceConfigState), ...pendingSlugs])
      );
      pendingSlugs.push(nextSlug);
      spaceConfigDispatch({
        type: "INSERT_COMPONENT",
        payload: createInsertComponentPayload(
          componentType,
          draftComponent.slug,
          functionParameterNode
        )
      });

      const paramConfig = {
        name: functionParameterNode.name,
        component_type: componentType,
        component_slug: nextSlug,
        binding: getBindingPath(componentNode, nextSlug, "value"),
        error: {
          binding: getBindingPath(componentNode, nextSlug, "error")
        }
      };

      // batch updates to parameters so that all updates are applied (otherwise,
      // configuring each param individually would update only one parameter with
      // a stale list of parameters)
      updatedParameters = produce(updatedParameters as InputParameter[], params => {
        const index = params.findIndex(
          (param: InputParameter) => param.name === paramConfig.name
        );
        const oldParam = params.splice(index, 1)[0];
        params.splice(
          index,
          0,
          makeUpdatedParam(
            { ...oldParam, ...paramConfig } as InputParameter,
            draftComponent
          )
        );
      });
    });
    onChange(updatedParameters);
  }, [
    componentNode,
    draftComponent,
    functionParameterNodes,
    onChange,
    pendingInputs,
    spaceConfigDispatch,
    spaceConfigState
  ]);

  // for FORM components, if any parameters are removed due to function changes,
  // underlying components need to be removed, as well
  React.useEffect(() => {
    if (!isFormConfig) return;
    const childComponents: SpaceComponentObject[] = draftComponent.componentTreeNodes;
    const removedComponents = childComponents.filter(c => {
      const foundParam = draftComponent.properties.input_parameters.find(
        (param: InputParameter) =>
          param.type === ParameterType.COMPONENT && param.component_slug === c.slug
      );
      return !foundParam;
    });
    removedComponents.forEach(c => {
      spaceConfigDispatch({
        type: "REMOVE_COMPONENT",
        payload: {
          slug: c.slug
        }
      });
    });
  }, [
    isFormConfig,
    draftComponent.type,
    draftComponent.componentTreeNodes,
    draftComponent.properties.input_parameters,
    spaceConfigDispatch
  ]);

  const hasPipelineBinding = React.useCallback(
    (binding: string | undefined) => {
      const pipelineBinding = `${draftComponent.slug}.lastExecutionResult`;
      return binding && binding.indexOf(pipelineBinding) > -1;
    },
    [draftComponent.slug]
  );

  // hidden params fulfilled by pipeline
  const inputParamsFulfilledByPipeline = React.useMemo(() => {
    return draftComponent.properties.input_parameters.filter(
      (param: InputParameter) => {
        return (
          param.type === ParameterType.BINDING && hasPipelineBinding(param.binding)
        );
      }
    );
  }, [hasPipelineBinding, draftComponent.properties.input_parameters]);

  // visible (component) params fulfilled by pipeline
  const inputParamsWithComponentPipelineBindings: InputParameter[] =
    React.useMemo(() => {
      // future TODO: make more generic and check for any inputs in sub-tree not just direct children
      const childComponents: SpaceComponentObject[] = draftComponent.componentTreeNodes;
      const componentSlugsWithPipelineBindings = childComponents
        .filter(c => hasPipelineBinding(c.properties.default_value_binding?.binding))
        .map(c => c.slug);

      return draftComponent.properties.input_parameters.filter(
        (param: InputParameter) => {
          return (
            param.type === ParameterType.COMPONENT &&
            param.component_slug &&
            componentSlugsWithPipelineBindings.includes(param.component_slug)
          );
        }
      );
    }, [
      hasPipelineBinding,
      draftComponent.componentTreeNodes,
      draftComponent.properties.input_parameters
    ]);

  React.useEffect(() => {
    inputParamsWithComponentPipelineBindings.forEach(param => {
      if (!param.hidden) {
        toggleHide(param);
      }
    });
  }, [inputParamsWithComponentPipelineBindings, toggleHide]);

  if (!functionParameterNodes.length) return null; // do not render section if no function params

  return (
    <ConfigSection
      id="fieldsConfigSection"
      title={title}
      onAdd={() =>
        configPanelDispatch({
          type: ConfigPanelActionTypes.OPEN_POPPER,
          payload: {
            popperIdentifier: INSERT_PARAMS_CONFIG_POPPER_ID
          }
        })
      }
    >
      <ValidationError field="REQUIRED_PARAMETERS" />
      <SortableList isCompact onSort={onReorder}>
        {inputParameters.map((inputParameter: InputParameter, idx: number) => {
          const functionParameterNode = functionParameterNodes.find(
            n => n.name === inputParameter.name
          );
          const required = functionParameterNode
            ? functionParameterNode.required
            : false;
          const isRemoveable = !required;
          const errorMessage =
            errors
              .filter(err => err.key === inputParameter.name && err.field === "FIELDS")
              .map(err => err.message)
              .join(", ") || null;
          const fieldConfigPanel = functionParameterNode ? (
            <ParameterConfigurationPanel
              allowUserInput={inputParameter.hidden ? false : isFormConfig}
              allowFileInput={inputParameter.hidden ? false : isFileSource}
              functionParameterNode={functionParameterNode}
              inputParameter={inputParameter}
              shouldDisambiguateParameter={func?.type === SpaceFunctionType.PIPELINE}
              functionParameterDescriptor={func?.describeFunctionParameter(
                functionParameterNode.name
              )}
              dispatch={dispatch}
              onConfigure={onConfigure}
              index={idx}
            />
          ) : undefined;

          const isConfigOpen =
            configPanelState.activePopperIdentifier === inputParameter.name;

          const isHideDisabled = !!inputParamsFulfilledByPipeline.find(
            (param: InputParameter) => param.name === inputParameter.name
          );
          return (
            <SortableItemCompact
              id={inputParameter.name}
              key={inputParameter.name}
              sortKey={inputParameter.name}
              onRemove={isRemoveable ? () => onRemove(inputParameter.name) : undefined}
              errorMessage={errorMessage}
              isSelected={isConfigOpen}
              isHidden={inputParameter.hidden}
              isHideDisabled={isHideDisabled}
              hideButtonTooltip={
                isHideDisabled
                  ? "This parameter must be hidden because it is bound to the output of another function in this form."
                  : undefined
              }
              onToggleHide={
                isFormConfig ? () => toggleHide(inputParameter) : undefined
              } /* only enable hide for form config */
              onClick={() =>
                configPanelDispatch({
                  type: ConfigPanelActionTypes.OPEN_POPPER,
                  payload: {
                    popperIdentifier: inputParameter.name
                  }
                })
              }
              title={inputParameter.name}
            >
              <ParameterDescription fn={func} paramName={inputParameter.name} />
              {isConfigOpen && functionParameterNode && (
                <ConfigPanelPopper
                  popperId={inputParameter.name}
                  popperReferenceElement={
                    document.getElementById(inputParameter.name) || undefined
                  }
                  onCancel={() =>
                    configPanelDispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    })
                  }
                >
                  {fieldConfigPanel}
                </ConfigPanelPopper>
              )}
            </SortableItemCompact>
          );
        })}
      </SortableList>
      <ConfigPanelPopper
        popperId={INSERT_PARAMS_CONFIG_POPPER_ID}
        popperReferenceElement={
          document.getElementById("fieldsConfigSection") || undefined
        }
        onCancel={() =>
          configPanelDispatch({
            type: ConfigPanelActionTypes.CLOSE_POPPER
          })
        }
      >
        <ScrollableConfigSection title="Add fields">
          <p>
            Select fields to add to your {pkg?.displayName.toLowerCase() || "component"}
            .
          </p>
          {excludedParameters
            .sort((a, b) => a.localeCompare(b))
            .map(name => {
              const paramDescription = func!.describeFunctionParameter(name);
              return (
                <WrapTextButton key={name} onClick={() => onAdd(name)}>
                  {func?.type === SpaceFunctionType.PIPELINE ? (
                    <>
                      {paramDescription?.param.name || "Unknown param"}{" "}
                      <small>
                        ({paramDescription?.fn.title || "Unknown function"})
                      </small>
                    </>
                  ) : (
                    name
                  )}
                </WrapTextButton>
              );
            })}
        </ScrollableConfigSection>
      </ConfigPanelPopper>
    </ConfigSection>
  );
}

const ParameterDescriptionRoot = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding-right: ${props => props.theme.spacersm};
`;

const FnBadge = styled.div`
  display: inline-flex;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: ${props => props.theme.spacerxs};
  border-radius: 50%;
  background: ${props => props.theme.textColorMid};
  color: ${props => props.theme.borderColor};
  font-size: ${props => props.theme.tinyFontSize};
  font-weight: bold;
  font-variant-numeric: tabular-nums;
`;

function ParameterDescription({
  paramName,
  fn
}: {
  paramName: string;
  fn?: SpaceFunction;
}) {
  if (fn === undefined || fn.type !== SpaceFunctionType.PIPELINE)
    return <>{paramName}</>;

  const paramDescription = fn.describeFunctionParameter(paramName);
  const fnIndex = (paramDescription?.fnIndex || 0) + 1;

  return (
    <ParameterDescriptionRoot>
      <Tooltip
        title={
          <div>
            <dl>
              <dt>Data source</dt>
              <dd>{paramDescription?.fn.dataSource?.name || "Unknown datasource"}</dd>
              <dt>Function</dt>
              <dd>{paramDescription?.fn.title || "Unknown function"}</dd>
            </dl>
          </div>
        }
      >
        <FnBadge>{fnIndex}</FnBadge>
      </Tooltip>{" "}
      {paramDescription?.param.name || "Unknown parameter"}
    </ParameterDescriptionRoot>
  );
}
