import React from "react";

import { decodeResourceQueryDescriptor } from "../../../../constants";
import {
  SpaceComponentPackage,
  SpaceComponentType,
  MaskedSpaceNode,
  ResourceQueryDescriptor,
  SpaceComponentObject
} from "../../../../types";
import { isError } from "../../../util";
import { reportException } from "../../../util/exceptionReporting";
import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";

import { SpaceContextParams, useSpaceContext } from "./SpaceContext";

// StableSpaceContext is intended for frequently needed space meta data that is not volatile.
// SpaceContext re-renders frequently, particularly in config mode as components are being
// edited. Where possible components should depend on this context instead.

export const OPAQUE_SPACE_DATA_KEY = Symbol("opaque meta data key");
export const OPAQUE_EDIT_MODE_KEY = Symbol("opaque edit mode key");

export interface StableSpaceContextValue {
  spaceId: string;
  spaceSlug: string;
  spaceName: string;
  contextParams: SpaceContextParams;
  editMode: boolean;
  resourceQueryDescriptor: ResourceQueryDescriptor | null;
  packagesRegistered: boolean;
  getSpaceComponentPackages: () => SpaceComponentPackage[];
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined;
  getSpaceComponentDisplayName: (component: SpaceComponentObject) => string;
  getHostableTypes: (type: SpaceComponentType) => SpaceComponentType[];
}

export interface StableSpaceContextContainerProps {
  encodedResourceQueryDescriptor?: string | undefined;
  spaceSlug?: string;
  editing?: boolean;
  children: React.ReactNode;
}

const getSpaceComponentPackages = () =>
  Array.from(window.__INTERNAL_SPACE_COMPONENT_PACKAGES).filter(
    p => !p.featureFlag || !!window.FEATURE_FLAGS.includes(p.featureFlag)
  );

export const getPseudoSpaceComponentPackages = () =>
  getSpaceComponentPackages().filter(pkg => pkg.isPseudoComponent);

export const findSpaceComponentPackage = (
  type: SpaceComponentType
): SpaceComponentPackage => {
  const pkg = getSpaceComponentPackages().find(p => p.type === type);
  if (!pkg) {
    reportException(
      new Error("findSpaceComponentPackage: Could not find package for type"),
      { extra: { type } }
    );
  }
  return pkg!;
};

export const getSpaceComponentDisplayName = (component: SpaceComponentObject) => {
  return (
    component.name ||
    findSpaceComponentPackage(component.type).displayName ||
    component.slug
  );
};

export const getHostableTypes = (type: SpaceComponentType): SpaceComponentType[] =>
  getSpaceComponentPackages()
    .filter(p => p.allowedHosts.has(type))
    .map(p => p.type);

export const initialStableSpaceContext = {
  spaceId: "",
  spaceSlug: "",
  spaceName: "",
  contextParams: {},
  editMode: false,
  resourceQueryDescriptor: null,
  packagesRegistered: false,
  getSpaceComponentPackages,
  findSpaceComponentPackage,
  getSpaceComponentDisplayName,
  getHostableTypes
};

export const StableSpaceContext = React.createContext<StableSpaceContextValue>(
  initialStableSpaceContext
);

export function StableSpaceContextContainer({
  encodedResourceQueryDescriptor,
  spaceSlug: spaceSlugProp,
  editing,
  children
}: StableSpaceContextContainerProps) {
  const spaceConfigContext = useSpaceConfigContext();
  const editMode =
    typeof editing === "boolean" ? editing : spaceConfigContext[OPAQUE_EDIT_MODE_KEY];
  const { space, slug, contextParams } = useSpaceContext();

  const resourceQueryDescriptor: ResourceQueryDescriptor | null = React.useMemo(() => {
    if (!encodedResourceQueryDescriptor) return null;
    try {
      return decodeResourceQueryDescriptor(encodedResourceQueryDescriptor);
    } catch (e) {
      console.warn(e, isError(e) ? e.stack : undefined); // eslint-disable-line no-console
      return null;
    }
  }, [encodedResourceQueryDescriptor]);

  const spaceId = (space as MaskedSpaceNode)?.[OPAQUE_SPACE_DATA_KEY].id || "";
  const spaceSlug =
    slug ||
    (space as MaskedSpaceNode)?.[OPAQUE_SPACE_DATA_KEY].slug ||
    spaceSlugProp ||
    "";
  const spaceName = (space as MaskedSpaceNode)?.[OPAQUE_SPACE_DATA_KEY].name || "";
  const packagesRegistered = getSpaceComponentPackages().length > 0;
  const spaceConfigDispatch = spaceConfigContext.dispatch;
  React.useEffect(() => {
    if (!packagesRegistered) return;
    spaceConfigDispatch({
      type: "REGISTER_PACKAGES",
      payload: { packages: getSpaceComponentPackages() }
    });
  }, [packagesRegistered, spaceConfigDispatch]);

  const spaceMetaContextValue = React.useMemo(() => {
    return {
      spaceId,
      spaceSlug,
      spaceName,
      contextParams,
      editMode,
      resourceQueryDescriptor,
      packagesRegistered,
      getSpaceComponentPackages,
      findSpaceComponentPackage,
      getSpaceComponentDisplayName,
      getHostableTypes
    };
  }, [
    spaceId,
    spaceSlug,
    spaceName,
    contextParams,
    editMode,
    resourceQueryDescriptor,
    packagesRegistered
  ]);

  return (
    <StableSpaceContext.Provider value={spaceMetaContextValue}>
      {children}
    </StableSpaceContext.Provider>
  );
}

export const useStableSpaceContext = () => React.useContext(StableSpaceContext);

export function useSpaceComponentPackage(type: SpaceComponentType) {
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const pkg = findSpaceComponentPackage(type);
  if (!pkg) {
    throw new Error(`Expected to find space component package for ${type}`);
  }
  return pkg;
}
