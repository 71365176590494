import gql from "graphql-tag";

import { ReturnSchema } from "../../../../constants";
import {
  Connection,
  FunctionAttribute,
  FunctionAttributeNode,
  FunctionAuthorizationFlowInput,
  FunctionNodeBasic,
  FunctionParameterInput,
  FunctionParameterNode,
  Metadata,
  RelayNode
} from "../../../../types";
import { SupportedIntegration } from "../support";

// TODO: Use this wherever FunctionNodeBasic is used
export const FUNCTION_BASIC_FRAGMENT = gql`
  fragment FunctionBasicFragment on FunctionNode {
    id
    name
    title
  }
`;

export const FUNCTION_PARAMETER_FRAGMENT = gql`
  fragment FunctionParameterFragment on FunctionParameterNode {
    id
    name
    type
    required
  }
`;

export const FUNCTION_ATTRIBUTE_FRAGMENT = gql`
  fragment FunctionAttributeFragment on FunctionAttributeNode {
    id
    name
    sourceIndex
    sourceName
    sourceType
    sourceKey
    outboundReferenceLink {
      toFunction {
        id
      }
      parameterAttributeMapping
    }
  }
`;

export const FUNCTION_AUTHORIZATION_FLOW_FRAGMENT = gql`
  fragment FunctionAuthorizationFlowFragment on FunctionAuthorizationFlowNode {
    id
    authorizationFlow {
      id
    }
    environment {
      id
    }
  }
`;

export const FUNCTION_FRAGMENT = gql`
  fragment FunctionFragment on FunctionNode {
    id
    name
    title
    isUserGenerated
    baseFunction {
      ...FunctionBasicFragment
    }
    baseFunctionParameterMapping
    metadata
    reducer
    metadataReducer
    returnSchema
    dataSource {
      id
      name
      integration
    }
    functionParameters {
      edges {
        node {
          ...FunctionParameterFragment
        }
      }
    }
    functionAttributes {
      edges {
        node {
          ...FunctionAttributeFragment
        }
      }
    }
  }
  ${FUNCTION_BASIC_FRAGMENT}
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;

export interface FunctionAuthorizationFlowNode extends RelayNode {
  authorizationFlow: RelayNode;
  environment: RelayNode;
}

interface FunctionFragmentDataSourceNode extends RelayNode {
  name: string;
  integration: SupportedIntegration;
}

export interface FunctionFragmentNode<M extends Metadata = Metadata> extends RelayNode {
  name: string;
  title: string;
  isUserGenerated: boolean;
  baseFunction: FunctionNodeBasic;
  baseFunctionParameterMapping: string;
  dataSource: FunctionFragmentDataSourceNode;
  metadata: M;
  reducer: string;
  metadataReducer: string;
  returnSchema: ReturnSchema;
  functionParameters: Connection<FunctionParameterNode>;
  functionAttributes: Connection<FunctionAttributeNode>;
}

export interface FetchFunctionByIdData<F extends FunctionFragmentNode> {
  node: F;
}

export interface FetchFunctionByIdVars {
  id: string;
}

export interface EditorFunctionNode<M> extends FunctionFragmentNode<M> {
  environmentsWithCredentials: Connection<RelayNode>;
  functionAuthorizationFlows: Connection<FunctionAuthorizationFlowNode>;
}

export const EDITOR_FUNCTION_FRAGMENT = gql`
  fragment EditorFunctionFragment on FunctionNode {
    ...FunctionFragment
    ... on FunctionNode {
      environmentsWithCredentials {
        edges {
          node {
            id
          }
        }
      }
      functionAuthorizationFlows {
        edges {
          node {
            ...FunctionAuthorizationFlowFragment
          }
        }
      }
    }
  }
  ${FUNCTION_FRAGMENT}
  ${FUNCTION_AUTHORIZATION_FLOW_FRAGMENT}
`;

export const FETCH_FUNCTION_BY_ID = gql`
  query FetchFunctionById($id: ID!) {
    node(id: $id) {
      ...EditorFunctionFragment
    }
  }
  ${EDITOR_FUNCTION_FRAGMENT}
`;

export interface WriteFunctionVariables {
  baseFunctionParameterMapping: any;
  metadata: Metadata;
  title: string;
  reducer?: string;
  metadataReducer?: string;
  parameters: FunctionParameterInput[];
  // TODO: Make this required when old function editors are removed.
  attributes?: FunctionAttribute[];
  returnSchema: ReturnSchema;
  authorizationFlows: FunctionAuthorizationFlowInput[];
}

export interface CreateFunctionData {
  createFunction?: {
    function: EditorFunctionNode<Metadata>;
  };
}

export interface CreateFunctionVariables extends WriteFunctionVariables {
  baseFunctionId: string;
}

export const CREATE_FUNCTION = gql`
  mutation CreateFunction(
    $baseFunctionId: ID!
    $baseFunctionParameterMapping: GenericScalar!
    $metadata: GenericScalar!
    $title: String!
    $reducer: String
    $metadataReducer: String
    $parameters: [FunctionParameterInput]!
    $attributes: [FunctionAttributeInput]
    $returnSchema: ReturnSchema!
    $authorizationFlows: [FunctionAuthorizationFlowInput]!
  ) {
    createFunction(
      baseFunctionId: $baseFunctionId
      baseFunctionParameterMapping: $baseFunctionParameterMapping
      metadata: $metadata
      title: $title
      reducer: $reducer
      metadataReducer: $metadataReducer
      parameters: $parameters
      attributes: $attributes
      returnSchema: $returnSchema
      authorizationFlows: $authorizationFlows
    ) {
      function {
        ...EditorFunctionFragment
      }
    }
  }
  ${EDITOR_FUNCTION_FRAGMENT}
`;

export interface CloneFunctionData {
  cloneFunction: {
    function: {
      id: string;
      name: string;
    };
  };
}

export interface CloneFunctionVariables {
  functionId: string;
}

export const CLONE_FUNCTION = gql`
  mutation CloneFunction($functionId: ID!) {
    cloneFunction(id: $functionId) {
      function {
        id
        name
      }
    }
  }
`;

export interface UpdateFunctionData {
  updateFunction?: {
    function: EditorFunctionNode<Metadata>;
  };
}

export interface UpdateFunctionVariables extends WriteFunctionVariables {
  functionId: string;
}

export const UPDATE_FUNCTION = gql`
  mutation UpdateFunction(
    $functionId: ID!
    $baseFunctionParameterMapping: GenericScalar!
    $metadata: GenericScalar!
    $title: String!
    $reducer: String
    $metadataReducer: String
    $parameters: [FunctionParameterInput]!
    $attributes: [FunctionAttributeInput]
    $returnSchema: ReturnSchema!
    $authorizationFlows: [FunctionAuthorizationFlowInput]!
  ) {
    updateFunction(
      functionId: $functionId
      baseFunctionParameterMapping: $baseFunctionParameterMapping
      metadata: $metadata
      title: $title
      reducer: $reducer
      metadataReducer: $metadataReducer
      parameters: $parameters
      attributes: $attributes
      returnSchema: $returnSchema
      authorizationFlows: $authorizationFlows
    ) {
      function {
        ...EditorFunctionFragment
      }
    }
  }
  ${EDITOR_FUNCTION_FRAGMENT}
`;

export interface DeleteFunctionData {
  deleteFunction?: {
    ok: boolean;
  };
}

export interface DeleteFunctionVariables {
  functionId: string;
}

export const DELETE_FUNCTION = gql`
  mutation DeleteFunction($functionId: ID!) {
    deleteFunction(functionId: $functionId) {
      ok
    }
  }
`;
