import { Option } from "../HardcodedOptionFields/utils";

const INDICATOR_WIDTH = 36;
const CLEAR_BUTTON_WIDTH = 36;
export const DEFAULT_MIN_WIDTH = 88; // includes 36px INDICATOR_WIDTH + 16px loader
export const NESTED_DROPDOWN_MAX_WIDTH = 240; // maximum min width that can be set for nested dropdowns (based on 240 component cell width)
const NESTED_MIN_STRING_LENGTH = 3;
const STRING_LENGTH_PIXEL_MULTIPLIER = 8;

export const getMinWidth = (
  useComputedWidth: boolean,
  options: Option[],
  placeholder?: string
) => {
  if (!useComputedWidth) return DEFAULT_MIN_WIDTH;
  const optionLengths = options.slice(0, 10).map(o => o.label.length);
  const maxOptionLength = Math.max(...optionLengths);
  const length = Math.max(
    maxOptionLength,
    placeholder?.length || 0,
    NESTED_MIN_STRING_LENGTH // added when placeholder is empty in edit mode
  );
  return Math.min(
    length * STRING_LENGTH_PIXEL_MULTIPLIER + INDICATOR_WIDTH + CLEAR_BUTTON_WIDTH, // estimate length based on string length and pixel multiplier
    NESTED_DROPDOWN_MAX_WIDTH
  );
};
