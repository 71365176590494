import React from "react";

import { Button, Icon, Select } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

import { AuthorizationFlowNode, AuthorizationFlowStepType } from "../../../../types";
import { BasePanel } from "../../../common/StyledComponents";
import { assertNever } from "../../../util/assertNever";

import {
  ActionType,
  Dispatch as FlowConfigDispatch,
  PendingAuthFlowStep,
  PendingCredentialStep,
  State as FlowConfigState
} from "./reducer";
import {
  CredentialStep,
  ExecuteFunctionStep,
  ExpressionStep,
  InputStep,
  OAuth2Step,
  StepComponent,
  StepProps
} from "./steps";

const StyledBasePanel = styled(BasePanel)`
  margin-bottom: ${props => props.theme.spacermd};
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
  margin: ${props => props.theme.spacersm};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacersm} ${props => props.theme.spacermd};
  background-color: ${props => props.theme.backgroundColor};
  border-radius: ${props => props.theme.borderRadiuslg}
    ${props => props.theme.borderRadiuslg} 0px 0px;
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0px ${props => props.theme.spacermd} 0px 0px;
  }
`;

const StyledContent = styled.div`
  padding: ${props => props.theme.spacermd};
`;

const Exports = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  border: solid 1px ${props => props.theme.borderGrey};
  padding: ${props => props.theme.spacersm};

  & > ul {
    margin: 0px;
  }
`;

interface Props {
  flow: AuthorizationFlowNode;
  dispatch: FlowConfigDispatch;
  state: FlowConfigState;
}

const Step = ({
  step,
  state,
  dispatch
}: Omit<StepProps<PendingAuthFlowStep>, "updateOptions">) => {
  let StepTypeComponent: StepComponent<any> | null;
  switch (step.type) {
    case AuthorizationFlowStepType.INPUT:
      StepTypeComponent = InputStep;
      break;
    case AuthorizationFlowStepType.OAUTH2:
      StepTypeComponent = OAuth2Step;
      break;
    case AuthorizationFlowStepType.EXECUTEFUNCTION:
      StepTypeComponent = ExecuteFunctionStep;
      break;
    case AuthorizationFlowStepType.EXPRESSION:
      StepTypeComponent = ExpressionStep;
      break;
    case AuthorizationFlowStepType.CREDENTIAL:
      throw new Error("cannot render credential step");
    case null:
      StepTypeComponent = null;
      break;
    default:
      return assertNever(step);
  }
  const exports = StepTypeComponent?.selectExports?.(step) || [];
  return (
    <>
      {!StepTypeComponent && <span>Select a step type above to begin.</span>}
      {StepTypeComponent && (
        <>
          <StepTypeComponent
            step={step}
            state={state}
            dispatch={dispatch}
            updateOptions={(options: any) =>
              dispatch({
                type: ActionType.UPDATE_STEP_OPTIONS,
                payload: { step: step.order, options: options }
              })
            }
          />
          <h4>Exports</h4>
          <p>
            The following variables are exported by this step. You may refer to these in
            subsequent steps.
          </p>
          <Exports>
            {!exports.length && <span>This step does not export any data.</span>}
            {!!exports.length && (
              <ul>
                {exports.map((o: string, idx: number) => (
                  <li key={`${o}-${idx}`}>{`step${step.order}.${o}`}</li>
                ))}
              </ul>
            )}
          </Exports>
        </>
      )}
    </>
  );
};

const FlowConfiguration = ({ flow, state, dispatch }: Props) => {
  const onDragEnd = React.useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      dispatch({
        type: ActionType.REORDER_STEP,
        payload: {
          startIndex: result.source.index,
          endIndex: result.destination.index
        }
      });
    },
    [dispatch]
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`droppable-${flow.id}`}>
          {(provided, _) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {state.steps.slice(0, state.steps.length - 1).map((s, index) => (
                <Draggable
                  key={s.id || `${s.type}-${s.order}`}
                  draggableId={s.id || `${s.type}-${s.order}`}
                  index={index}
                >
                  {(provided, _) => (
                    <StyledBasePanel
                      ref={provided.innerRef}
                      key={s.id || `${s.type}-${s.order}`}
                      {...provided.draggableProps}
                    >
                      <StyledHeader>
                        <StyledTitle>
                          <h3>Step {s.order}</h3>
                          <Select
                            style={{ width: 200 }}
                            value={s.type}
                            onChange={(val: AuthorizationFlowStepType | null) =>
                              dispatch({
                                type: ActionType.UPDATE_STEP_TYPE,
                                payload: { step: index, type: val }
                              })
                            }
                            placeholder="Select a step type"
                          >
                            <Select.Option value={AuthorizationFlowStepType.INPUT}>
                              Form
                            </Select.Option>
                            <Select.Option value={AuthorizationFlowStepType.OAUTH2}>
                              OAuth 2.0
                            </Select.Option>
                            <Select.Option
                              value={AuthorizationFlowStepType.EXECUTEFUNCTION}
                            >
                              Execute Function
                            </Select.Option>
                            <Select.Option value={AuthorizationFlowStepType.EXPRESSION}>
                              Expression
                            </Select.Option>
                          </Select>
                        </StyledTitle>
                        <div>
                          <StyledIcon
                            type="delete"
                            onClick={() =>
                              dispatch({
                                type: ActionType.REMOVE_STEP,
                                payload: { step: index }
                              })
                            }
                          />
                          <StyledIcon type="drag" {...provided.dragHandleProps} />
                        </div>
                      </StyledHeader>
                      <StyledContent>
                        <Step step={s} state={state} dispatch={dispatch} />
                      </StyledContent>
                    </StyledBasePanel>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <CredentialStep
        step={state.steps[state.steps.length - 1] as PendingCredentialStep}
        state={state}
        dispatch={dispatch}
        updateOptions={(options: any) =>
          dispatch({
            type: ActionType.UPDATE_STEP_OPTIONS,
            payload: { step: state.steps.length - 1, options: options }
          })
        }
      />
      <div>
        <Button
          data-test="add-button"
          type="link"
          onClick={() => dispatch({ type: ActionType.ADD_STEP })}
        >
          Add Another Step
        </Button>
      </div>
    </>
  );
};

export default FlowConfiguration;
