import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const FETCH_AUTH_ORG_CLIENT_SECRET = gql`
  query authOrganizationClientSecretQuery {
    authOrganization {
      id
      clientSecret
    }
  }
`;

export interface Result {
  loading: boolean;
  clientSecret: string | null;
}

export default function useOrgClientSecret() {
  const { loading, data } = useQuery(FETCH_AUTH_ORG_CLIENT_SECRET);
  const clientSecret =
    (data && data.authOrganization && data.authOrganization.clientSecret) || null;

  return {
    loading,
    clientSecret
  };
}
