import React from "react";

import {
  Popper as ReactPopper,
  PopperChildrenProps,
  PopperProps,
  StrictModifierNames
} from "react-popper";
import styled, { keyframes } from "styled-components";

interface CommonPopperProps<Modifiers> extends PopperProps<Modifiers> {
  maskClosable: boolean;
  onCancel: (evt: React.MouseEvent) => void;
}

type ExtendedModifierNames = StrictModifierNames | "popperOffsetsAdjusted";

export type PopperOverrideContextValue = Pick<
  PopperProps<ExtendedModifierNames>,
  "placement" | "modifiers"
>;
export const PopperOverrideContext = React.createContext<PopperOverrideContextValue>({
  placement: undefined,
  modifiers: []
});

export const PopperOverlay = styled.div`
  pointer-events: all;
  z-index: 3000;
  width: 100vw;
  height: 100%;
`;
PopperOverlay.displayName = "PopperOverlay";

// Following styles adapted from:
// https://github.com/popperjs/react-popper/blob/8994933c430e48ab62e71495be71e4f440b48a5a/demo/styles.js
export const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const PoppersContainer = styled.div`
  z-index: 4000;
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in 0s forwards;
`;

export default function Popper({
  maskClosable,
  onCancel,
  placement,
  ...rest
}: CommonPopperProps<ExtendedModifierNames>) {
  const overrides = React.useContext(PopperOverrideContext);
  const handleCancelClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    if (maskClosable) {
      onCancel(evt);
    }
  };

  const handleClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt.stopPropagation();
    (evt as any).nativeEvent.stopImmediatePropagation();
  };

  const { children: popperChildren, ...popperProps } = rest;

  return (
    <PopperOverlay onClick={handleCancelClick}>
      <PoppersContainer>
        <ReactPopper {...popperProps} {...overrides}>
          {(props: PopperChildrenProps) => {
            return <div onClick={handleClick}>{popperChildren(props)}</div>;
          }}
        </ReactPopper>
      </PoppersContainer>
    </PopperOverlay>
  );
}
