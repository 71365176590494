import React from "react";

import { Select } from "antd";

import { SpaceConfigAction } from "../../../../../../types";
import AggregationFunctionSelector from "../../../../common/AggregationFunctionSelector";
import {
  AggregationFunctionType,
  ChartConfigState,
  GroupingFunctionType
} from "../../../types";

interface Props {
  state: ChartConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

export function GroupingFunctionSelect({ state, dispatch }: Props) {
  return (
    <>
      <Select
        placeholder="e.g. day"
        data-test="grouping-function-attribute-select"
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={state.draftComponent.properties.x_axis.grouping_function.attribute}
        onChange={(attribute: string) => {
          dispatch({
            type: "MERGE_DRAFT_COMPONENT",
            payload: {
              change: {
                properties: {
                  x_axis: { grouping_function: { attribute } }
                }
              }
            }
          });
        }}
      >
        {(state.attributes || []).map(a => (
          <Select.Option key={a.sourceName}>{a.name}</Select.Option>
        ))}
      </Select>
      {!!state.draftComponent.properties.x_axis.grouping_function.attribute && (
        <Select
          data-test="grouping-function-type-select"
          mode="multiple"
          allowClear
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          value={state.draftComponent.properties.x_axis.grouping_function.types}
          onChange={(selectedTypes: string[]) => {
            const currentTypes =
              state.draftComponent.properties.x_axis.grouping_function.types;
            const types =
              selectedTypes.length === 0
                ? [GroupingFunctionType.EQUALITY]
                : currentTypes.length === 1 &&
                  currentTypes.includes(GroupingFunctionType.EQUALITY)
                ? selectedTypes.filter(type => type !== GroupingFunctionType.EQUALITY)
                : selectedTypes;
            dispatch({
              type: "MERGE_DRAFT_COMPONENT",
              payload: {
                change: {
                  properties: {
                    x_axis: { grouping_function: { types } }
                  }
                }
              }
            });
          }}
        >
          <Select.Option key={GroupingFunctionType.EQUALITY}>
            grouped by value
          </Select.Option>
          <Select.Option key={GroupingFunctionType.DAILY}>grouped by day</Select.Option>
          <Select.Option key={GroupingFunctionType.WEEKLY}>
            grouped by week
          </Select.Option>
          <Select.Option key={GroupingFunctionType.MONTHLY}>
            grouped by month
          </Select.Option>
          <Select.Option key={GroupingFunctionType.YEARLY}>
            grouped by year
          </Select.Option>
        </Select>
      )}
    </>
  );
}

export function AggregationFunctionSelect({ state, dispatch }: Props) {
  return (
    <AggregationFunctionSelector
      type={state.draftComponent.properties.y_axis.aggregation_function.type}
      attribute={state.draftComponent.properties.y_axis.aggregation_function.attribute}
      attributes={state.attributes || []}
      onChangeFunctionType={(type: AggregationFunctionType) =>
        dispatch({
          type: "CHANGE_Y_AXIS_AGGREGATION_FUNCTION_TYPE",
          payload: { type }
        })
      }
      onChangeAttribute={(attribute: string) =>
        dispatch({
          type: "MERGE_DRAFT_COMPONENT",
          payload: {
            change: {
              properties: {
                y_axis: { aggregation_function: { attribute } }
              }
            }
          }
        })
      }
    />
  );
}
