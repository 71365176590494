import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

export const errorSelector = (state: ComponentConfigState): ConfigValidationError[] => {
  const { draftComponent } = state;
  const errors: ConfigValidationError[] = [];
  if (!draftComponent.properties.template) {
    errors.push({ field: "TEMPLATE", message: "Please add a template." });
  }

  return errors.concat(selectVisibilityRuleErrors(draftComponent));
};
