import React from "react";

import { DARK_THEME_POPPER_PORTAL_ID } from "../../../../../../constants";
import { useSelectionStateContext } from "../../../../layout/TransformationContext/TransformationContext";

interface ConfigPanelState {
  activePopperIdentifier?: string;
}

export enum ConfigPanelActionTypes {
  OPEN_POPPER = "OPEN_POPPER",
  CLOSE_POPPER = "CLOSE_POPPER"
}

interface OpenConfigPopper {
  type: ConfigPanelActionTypes.OPEN_POPPER;
  payload: {
    popperIdentifier: string;
  };
}

interface CloseConfigPopper {
  type: ConfigPanelActionTypes.CLOSE_POPPER;
}

type ConfigPanelAction = OpenConfigPopper | CloseConfigPopper;

const reducer = (state: ConfigPanelState, action: ConfigPanelAction) => {
  switch (action.type) {
    case ConfigPanelActionTypes.OPEN_POPPER: {
      return {
        ...state,
        activePopperIdentifier: action.payload.popperIdentifier
      };
    }
    case ConfigPanelActionTypes.CLOSE_POPPER: {
      return {
        ...state,
        activePopperIdentifier: undefined
      };
    }
  }
};

const initialState = {
  activePopperIdentifier: undefined // any component that is rendered in config popper; consumers must ensure it is unique within the component from which it's opened
};

export const ConfigPanelContext = React.createContext<{
  state: ConfigPanelState;
  dispatch: React.Dispatch<ConfigPanelAction>;
  generateGetPopupContainer: (
    domId?: string
  ) => ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}>({
  state: initialState,
  dispatch: (_: ConfigPanelAction) => {},
  generateGetPopupContainer: () => undefined
});

interface ConfigPanelProviderProps {
  children: React.ReactNode;
}

export function ConfigPanelProvider(props: ConfigPanelProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { selected } = useSelectionStateContext();

  // when active component changes, close any open popper
  React.useEffect(() => {
    if (selected) {
      dispatch({
        type: ConfigPanelActionTypes.CLOSE_POPPER
      });
    }
  }, [selected]);

  const generateGetPopupContainer = React.useCallback((domId?: string) => {
    const elt = document.getElementById(domId || DARK_THEME_POPPER_PORTAL_ID);
    if (elt) {
      return (_: HTMLElement) => elt;
    }
    return undefined;
  }, []);

  const configValue = React.useMemo(() => {
    return {
      state,
      dispatch,
      generateGetPopupContainer
    };
  }, [state, dispatch, generateGetPopupContainer]);

  return (
    <ConfigPanelContext.Provider value={configValue}>
      {props.children}
    </ConfigPanelContext.Provider>
  );
}

export const useSpaceConfigPanelContext = () => React.useContext(ConfigPanelContext);
