import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => props.theme.spacersm};

  > label {
    font-size: ${props => props.theme.mediumFontSize};
    font-weight: ${props => props.theme.boldFontWeight};
    color: ${props => props.theme.textColorMid};
    margin-right: ${props => props.theme.spacersm};
  }
  > span {
    font-size: ${props => props.theme.mediumFontSize};
    font-weight: 300;
    color: ${props => props.theme.textColorMid};
  }
`;

export const Root = styled.div`
  .ant-form-item {
    display: flex;
    flex-direction: row;

    label {
      font-size: ${props => props.theme.mediumFontSize};
      font-weight: ${props => props.theme.boldFontWeight};
      color: ${props => props.theme.textColorMid};
    }
    margin-bottom: ${props => props.theme.spacersm};
  }

  .ant-form-item-control-wrapper {
    flex-grow: 1;
  }
`;
