import styled from "styled-components";

import Down from "../../../../../assets/icons/chevron-down.svg";
import { colors, zIndex } from "../../../../../cssConstants";

export const StateFilter = styled.div<{ focused?: boolean }>`
  padding: 4px 16px;
  background-color: white;
  border: 1px solid
    ${props => (props.focused ? colors.primaryAccent : colors.dropdownBorderColor)};
  box-shadow: ${props => (props.focused ? props.theme.inputFocusBoxShadow : "initial")};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  max-width: 300px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 36px;
  align-self: center;
  justify-content: space-between;
  transition: all 0.3s;

  &:hover {
    border: 1px solid ${colors.primaryAccent};
    box-shadow: ${props => props.theme.inputFocusBoxShadow};
  }
`;

export const Mask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

export const Menu = styled.div`
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);
  background-color: ${colors.newBackgroundSecondaryColor};
  z-index: ${zIndex.hoverItem};
  padding: 16px;
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  overflow: auto;
  max-height: 300px;
`;

export const ChevronDown = styled(Down)`
  color: ${colors.dropdownIndicator};
  width: 9px;
  height: 5px;
`;
