import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

import { FunctionAttributeNode, FunctionParameterNode } from "../../../../../../types";
import { WarningTooltip } from "../../../../../common/ToolTips";
import { reportException } from "../../../../../util/exceptionReporting";
import humanizeList from "../../../../../util/humanizeList";

import { Access } from "./util";

interface PermissionFeedbackProps {
  parameters: FunctionParameterNode[];
  attributes: FunctionAttributeNode[];
  access: Access;
  className?: string;
}

export default function PermissionFeedback({
  parameters,
  attributes,
  access,
  className
}: PermissionFeedbackProps) {
  const unpermittedParameters = React.useMemo(() => {
    return parameters
      .filter(p => !p || !access.parameterAllowed(p.name))
      .map(p => p || { name: "Unknown parameter" });
  }, [parameters, access]);
  const unpermittedAttributes = React.useMemo(() => {
    return attributes
      .filter(a => !a || !access.attributeAllowed(a.name))
      .map(a => a || { name: "Unknown attribute" });
  }, [attributes, access]);

  if (parameters.some(p => !p) || attributes.some(a => !a)) {
    reportException(
      new Error("Expected all attributes and parameters to be defined."),
      {
        extra: { parameters, attributes }
      }
    );
  }

  if (!unpermittedParameters.length && !unpermittedAttributes.length) {
    return null;
  }

  const paramsMessage = unpermittedParameters.length
    ? `Parameters: ${humanizeList(unpermittedParameters.map(p => p.name))}`
    : "";

  const attrsMessage = unpermittedAttributes.length
    ? `Attributes: ${humanizeList(unpermittedAttributes.map(a => a.name))}`
    : "";

  return (
    <WarningTooltip
      className={className}
      message="Some data is inaccessible due to your permissions."
      description={
        <>
          {[paramsMessage, attrsMessage]
            .filter(m => !!m)
            .map(m => (
              <BlockSpan key={m}>{m}</BlockSpan>
            ))}
        </>
      }
    >
      <StyledIcon type="warning" />
    </WarningTooltip>
  );
}

const StyledIcon = styled(Icon)`
  font-size: ${props => props.theme.mediumFontSize};
  color: ${props => props.theme.warningColor};
  vertical-align: middle;
  padding-left: ${props => props.theme.spacersm};
  cursor: help;
`;

const BlockSpan = styled.span`
  display: block;
`;
