import { Input } from "antd";
import styled from "styled-components";

export const PathPrefixText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 0;
  max-width: 50%;
  margin-right: ${props => props.theme.spacersm};
  text-overflow: ellipsis;
  line-height: ${props => props.theme.inputHeight};
`;

export const PathInput = styled(Input)`
  margin-left: ${props => props.theme.spacersm};
`;

export const PathTemplateContainer = styled.div`
  width: 50%;
  flex-grow: 1;
`;
