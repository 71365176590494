import styled from "styled-components";

import { Hr } from "../../../../common/StyledComponents";

export const Root = styled.div`
  background-color: ${props => props.theme.containerPrimaryBackground};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
`;

export const Header = styled.div`
  display: flex;
  padding: ${props => props.theme.spacermd};
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${props => props.theme.spacerlg};
`;

export const DataSourceTitle = styled.div`
  font-size: 13px;
  line-height: 21px;
  color: ${props => props.theme.textColorMid};
`;
export const ActionTitle = styled.h6`
  font-size: 13px;
  line-height: 21px;
  margin: 0;
`;

export const HrNoMargin = styled(Hr)`
  margin: 0;
`;

export const PickerContainer = styled.div`
  padding: ${props => props.theme.spacermd};
`;

export const ActionLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${props => props.theme.spacersm};
`;
