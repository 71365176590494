/**
 * Editor component for JSON code input.
 * --
 * UI notes:
 * Found in the config panel, under "Default Value",
 * when editing a JSON Input component in a space.
 */
import React, { ReactNode, useCallback } from "react";

import { ValidationRule } from "antd/lib/form";
import _ from "lodash";

import { AttributeValueInputProps } from "..";
import Editor from "../../../common/Editor";
import { FormItemNoBottomMargin } from "../../StyledComponents";

export default function JsonInput(props: AttributeValueInputProps) {
  const {
    value,
    sourceName,
    form,
    readOnly = false,
    fieldOptions: parentFieldOptions,
    addFieldTouched = () => null,
    onFocus,
    onBlur
  } = props;
  const parentRules: ValidationRule[] = parentFieldOptions?.rules || [];

  let jsonString = value;
  try {
    const json = JSON.parse(value);
    jsonString = JSON.stringify(json);
  } catch (e) {
    jsonString = JSON.stringify(value);
  }

  const rules = parentRules.concat({
    message: `Value must be valid json.`,
    validator: (_rule, value, cb) => {
      try {
        JSON.parse(value);
        cb();
      } catch (e) {
        cb({ error: true });
      }
    }
  });

  const handleEdit = useCallback(
    (value: any) => {
      form &&
        form.setFieldsValue({
          [sourceName]: value
        });
      if (addFieldTouched) {
        addFieldTouched(sourceName);
      }
    },
    [form, addFieldTouched, sourceName]
  );

  const decorator = form
    ? form.getFieldDecorator(sourceName, {
        ...parentFieldOptions,
        initialValue: jsonString,
        rules: rules,
        validateTrigger: "onChange"
      })
    : (wrapper: ReactNode) => wrapper;

  return (
    <FormItemNoBottomMargin>
      {decorator(
        <Editor
          value={jsonString}
          mode="application/json"
          hideLineNumbers
          readOnly={readOnly}
          onChange={handleEdit}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    </FormItemNoBottomMargin>
  );
}
