import { Effect } from "./types";

export const getComponentValueBinding = (index: number) =>
  `modalForm1.fieldset.component${index}.value`;

export const getComponentErrorBinding = (index: number) =>
  `modalForm1.fieldset.component${index}.error`;

export const processEffects = (meta: unknown) => {
  const effects =
    !!meta && typeof meta === "object" && "effects" in meta
      ? (meta as { effects: Effect[] }).effects
      : [];

  effects.forEach(c => {
    if (c.command === "open_url") {
      window.open(c.options.url, "_blank");
    }
  });
};
