import React from "react";

import styled from "styled-components";

import { SpacingUnit } from "../../cssConstants";

interface SpacerProps {
  size: keyof typeof SpacingUnit;
}

const Xs = styled.div`
  height: ${props => props.theme.spacerxs};
`;

const Sm = styled.div`
  height: ${props => props.theme.spacersm};
`;

const Smd = styled.div`
  height: ${props => props.theme.spacersmd};
`;

const Md = styled.div`
  height: ${props => props.theme.spacermd};
`;

const Lg = styled.div`
  height: ${props => props.theme.spacerlg};
`;

const Xl = styled.div`
  height: ${props => props.theme.spacerxl};
`;

const Xxl = styled.div`
  height: ${props => props.theme.spacerxxl};
`;

const componentMap = {
  xs: Xs,
  sm: Sm,
  smd: Smd,
  md: Md,
  lg: Lg,
  xl: Xl,
  xxl: Xxl
};

export default function Spacer({ size }: SpacerProps) {
  const SizedSpacer = componentMap[size];
  return <SizedSpacer />;
}
