import React from "react";

import CloseIconSvg from "../../../../assets/icons/close-icon.svg";
import { colors } from "../../../../cssConstants";
import { useRoles } from "../../../common/Permissions/queries";
import {
  CloseIcon,
  HeaderName,
  StyledModal as BasePermissionsModal
} from "../../../common/Permissions/styledComponents";
import { B2 } from "../../../common/StyledComponents";

import { AutomationRolePermissions } from "./Permissions";

export function PermissionsModal({
  automationName,
  automationSlug,
  onCancel
}: {
  automationName: string;
  automationSlug: string;
  onCancel: () => void;
}) {
  const { data, loading } = useRoles();
  const roles = data?.allRoles.edges.map(e => e.node);

  return (
    <BasePermissionsModal
      data-test="shareModal"
      visible
      width={"95vw"}
      confirmLoading={loading}
      closeIcon={<CloseIcon component={CloseIconSvg} />}
      backgroundColor={colors.newBackgroundSecondaryColor}
      hasFooter={false}
      header={
        <>
          <B2>Automations</B2>
          <HeaderName>{automationName}</HeaderName>
        </>
      }
      onCancel={() => onCancel()}
    >
      {roles && (
        <AutomationRolePermissions
          name={automationName}
          slug={automationSlug}
          roles={roles}
          onCancel={onCancel}
        />
      )}
    </BasePermissionsModal>
  );
}
