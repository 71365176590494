import styled from "styled-components";

export const PopoverPickerContainer = styled.div`
  .ant-fullcalendar {
    color: ${props => props.theme.textColorMid};
  }

  .ant-fullcalendar-value {
    color: ${props => props.theme.textColor};

    &:hover {
      color: ${props => props.theme.buttonHoverColor};
      background: ${props => props.theme.buttonHoverBackground};
    }
  }

  .ant-fullcalendar-selected-day .ant-fullcalendar-value,
  .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
    color: white;
  }

  .ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
  .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
    color: ${props => props.theme.buttonDisabledColor};
  }

  .ant-fullcalendar-column-header-inner {
    color: ${props => props.theme.textColorMid};
  }

  .ant-select-selection {
    background-color: ${props => props.theme.selectBackgroundColor};
    border: ${props => props.theme.selectBorder};
  }

  .ant-select-selection__placeholder,
  .ant-select-selection-selected-value,
  .ant-select-arrow {
    color: ${props => props.theme.inputColor};
  }

  .ant-select-dropdown,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-selected {
    color: ${props => props.theme.selectColor};
    background: ${props => props.theme.selectMenuBackgroundColor};

    &.ant-select-dropdown-menu-item-disabled {
      color: ${props => props.theme.inputDisabledColor};
    }
  }

  .ant-select-dropdown {
    box-shadow: ${props => props.theme.boxShadow};
    border-top-left-radius: ${props => props.theme.selectActiveBorderBottomRadius};
    border-top-right-radius: ${props => props.theme.selectActiveBorderBottomRadius};
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${props => props.theme.selectHoverBackgroundColor};
  }

  .ant-select-selection:focus,
  .ant-select-selection:hover,
  .ant-select-selection:active {
    border-bottom-right-radius: ${props => props.theme.selectActiveBorderBottomRadius};
    border-bottom-left-radius: ${props => props.theme.selectActiveBorderBottomRadius};
    box-shadow: none;

    &::after {
      content: "";
      position: absolute;
      background-color: ${props => props.theme.selectHoverAfterColor};
      width: 100%;
      height: 3px;
      bottom: -3px;
      transition: none;
    }
  }

  .ant-select-dropdown-menu-item:first-child {
    border-top-left-radius: ${props =>
      props.theme.selectActiveBorderBottomRadius}; /* match select border bottom */
    border-top-right-radius: ${props =>
      props.theme.selectActiveBorderBottomRadius}; /* match select border bottom */
  }

  .ant-select-dropdown-menu-item-group-title {
    color: ${props => props.theme.selectColor};
  }
`;
