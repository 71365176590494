import gql from "graphql-tag";

import { Connection, RelayNode } from "../../../../../types";
import {
  FUNCTION_FRAGMENT,
  FunctionFragmentNode
} from "../../../../common/FunctionEditor/useFunctionEditor/queries";

export interface FunctionCallVariables {
  functionId: string;
  parameters: any;
}

export const EXECUTE_FUNCTION = gql`
  mutation ExecuteFunction($functionId: ID!, $parameters: GenericScalar!) {
    executeFunction(functionId: $functionId, parameters: $parameters) {
      __typename
      ... on PermissionErrorResult {
        message
      }
      ... on ClientErrorResult {
        code
        message
        errorInfo {
          reason
          domain
          metadata
        }
      }
      ... on ExecuteFunctionResultSuccess {
        __typename
        value
      }
    }
  }
`;

const LINK_FUNCTION_FRAGMENT = gql`
  fragment LinkFunctionFragment on FunctionNode {
    id
    name
    title
    metadata
    __typename
  }
`;

const OUTBOUND_FUNCTION_LINKS_FRAGMENT = gql`
  fragment OutboundFunctionLinksFragment on FunctionNode {
    outboundFunctionLinks {
      edges {
        node {
          id
          parameterAttributeMapping
          toFunction {
            ...LinkFunctionFragment
          }
          __typename
        }
      }
    }
  }
  ${LINK_FUNCTION_FRAGMENT}
`;

export const FETCH_FUNCTION_BY_ID = gql`
  query FetchFunctionById($id: ID!) {
    node(id: $id) {
      ... on FunctionNode {
        defaultSpace {
          id
          slug
        }
      }
      ...FunctionFragment
      ...OutboundFunctionLinksFragment
    }
  }
  ${FUNCTION_FRAGMENT}
  ${OUTBOUND_FUNCTION_LINKS_FRAGMENT}
`;

interface LinkFunctionNode extends RelayNode {
  name: string;
  title: string;
  metadata: Record<string, any>;
}

interface OutboundFunctionLinkNode extends RelayNode {
  parameterAttributeMapping: Record<string, string>;
  toFunction: LinkFunctionNode;
}

interface OutboundFunctionLinksFragment {
  outboundFunctionLinks: Connection<OutboundFunctionLinkNode>;
}

interface DefaultSpaceNode extends RelayNode {
  slug: string;
}

export interface FunctionNode
  extends FunctionFragmentNode,
    OutboundFunctionLinksFragment {
  __typename: "FunctionNode";
  defaultSpace?: DefaultSpaceNode;
}
