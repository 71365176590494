import React from "react";

import { throttle, uniqueId } from "lodash";
import styled from "styled-components";

import usePrevious from "../../../common/hooks/usePrevious";
import { useVolatileCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import {
  BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD,
  EDGE_DRAG_ADJUST
} from "../constants";
import { addPoints, subtractPoints } from "../util";

const DRAG_THRESHOLD = 3;
interface DraggableProps {
  children?: React.ReactNode;
  className?: string;
  disable?: boolean;
  transferDragStart?: DOMPoint;
  onDragStart: (coords: DOMPoint) => void;
  onDrag?: (coords: DOMPoint) => void;
  onDragEnd?: (coords: DOMPoint) => void;
  onTransfer?: () => void;
}

const initialDraggableState = {
  draggableId: "",
  dragStartCoords: new DOMPoint(),
  dragCurrentCoords: new DOMPoint(),
  dragEndCoords: new DOMPoint(),
  adjustment: new DOMPoint(),
  pendingEffect: "NONE" as "NONE" | "START" | "MOVE" | "END",
  tracking: false,
  dragging: false
};

type DraggableState = typeof initialDraggableState;

type DraggableAction =
  | { type: "START"; payload: { coords: DOMPoint } }
  | { type: "MOVE"; payload: { coords: DOMPoint } }
  | { type: "REPEAT_MOVE" }
  | { type: "END"; payload: { coords: DOMPoint } }
  | { type: "ADJUST"; payload: { adjustment: DOMPoint } }
  | { type: "RESET" };

function detectDrag(a: DOMPoint, b: DOMPoint) {
  const diff = subtractPoints(a, b);
  return Math.abs(diff.x) >= DRAG_THRESHOLD || Math.abs(diff.y) >= DRAG_THRESHOLD;
}

function draggableReducer(
  state: DraggableState,
  action: DraggableAction
): DraggableState {
  switch (action.type) {
    case "START": {
      const { coords } = action.payload;
      return {
        ...state,
        tracking: true,
        dragStartCoords: coords,
        dragCurrentCoords: coords,
        pendingEffect: "NONE"
      };
    }
    case "MOVE": {
      const { tracking, dragStartCoords } = state;
      if (!tracking) return state;
      const { coords } = action.payload;
      if (!state.dragging && !detectDrag(coords, dragStartCoords)) {
        return state;
      }
      return {
        ...state,
        dragCurrentCoords: coords,
        dragging: true,
        pendingEffect: state.dragging ? "MOVE" : "START"
      };
    }
    case "REPEAT_MOVE": {
      const { dragging, dragCurrentCoords } = state;
      if (!dragging) return state;
      return {
        ...state,
        pendingEffect: "MOVE",
        dragCurrentCoords: DOMPoint.fromPoint(dragCurrentCoords)
      };
    }
    case "END": {
      const { coords } = action.payload;
      return {
        ...state,
        dragCurrentCoords: coords,
        dragEndCoords: coords,
        pendingEffect: "END"
      };
    }
    case "ADJUST": {
      const { adjustment } = action.payload;
      return {
        ...state,
        adjustment: addPoints(state.adjustment, adjustment)
      };
    }
    case "RESET": {
      return { ...initialDraggableState, draggableId: state.draggableId };
    }
    default:
      throw new Error();
  }
}

const NOOP = () => {};
export default function Draggable({
  children = null,
  disable = false,
  transferDragStart,
  onDragStart,
  onDrag = () => {},
  onDragEnd = NOOP,
  onTransfer = NOOP
}: DraggableProps) {
  const [state, dispatch] = React.useReducer(draggableReducer, {
    ...initialDraggableState,
    draggableId: uniqueId("draggable")
  });

  const { cursorDistanceFromTop, cursorDistanceFromBottom, scrollTop } =
    useVolatileCanvasViewportContext();

  const throttledMouseMove = React.useRef(
    throttle((e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      dispatch({
        type: "MOVE",
        payload: { coords: new DOMPoint(e.pageX, e.pageY) }
      });
    }, 25)
  );

  React.useEffect(() => {
    const throttledCB = throttledMouseMove.current;
    return () => {
      throttledCB.cancel();
    };
  }, []);

  const handleMouseUp = React.useCallback(
    (e: MouseEvent) => {
      throttledMouseMove.current.cancel();
      if (state.dragging) {
        e.stopPropagation();
        e.preventDefault();
      }
      dispatch({
        type: "END",
        payload: { coords: new DOMPoint(e.pageX, e.pageY) }
      });
    },
    [state.dragging]
  );

  React.useEffect(() => {
    if (transferDragStart === undefined) return;
    dispatch({
      type: "START",
      payload: {
        coords: transferDragStart
      }
    });
    onTransfer();
  }, [onTransfer, transferDragStart]);

  React.useEffect(() => {
    if (!state.tracking) return;
    const handleMouseMove = throttledMouseMove.current;
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [state.tracking, handleMouseUp]);

  React.useLayoutEffect(() => {
    if (state.pendingEffect === "NONE") {
      return;
    } else if (state.pendingEffect === "START") {
      onDragStart(state.dragStartCoords);
    } else if (state.pendingEffect === "MOVE" && state.tracking) {
      onDrag(addPoints(state.dragCurrentCoords, state.adjustment));
    } else if (state.pendingEffect === "END") {
      // The last onDrag event was likely throttled and cancelled.
      // Simulate it before firing onDragEnd
      if (state.dragging) {
        onDrag(addPoints(state.dragEndCoords, state.adjustment));
        onDragEnd(addPoints(state.dragEndCoords, state.adjustment));
      }
      dispatch({ type: "RESET" });
    }
  }, [state, onDragStart, onDrag, onDragEnd]);

  // If the mouse stops moving but mouseUp has yet to occur simulate
  // a continued drag with the last coords
  const lastDragCurrentCoords = usePrevious(state.dragCurrentCoords);
  const mouseMoved = lastDragCurrentCoords !== state.dragCurrentCoords;
  React.useEffect(() => {
    if (!state.dragging || mouseMoved) return;

    const intervalHandle = setInterval(() => {
      dispatch({ type: "REPEAT_MOVE" });
    }, 25);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [state.dragging, mouseMoved, lastDragCurrentCoords, state.adjustment, onDrag]);

  // If the cursor is near a verticle boundary of the canvas during a drag
  // simulate a continued drag
  const scrolledToTop = scrollTop === 0;
  React.useEffect(() => {
    if (!state.tracking) return;
    let adjustment = new DOMPoint();
    if (cursorDistanceFromBottom < BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD) {
      adjustment = new DOMPoint(0, EDGE_DRAG_ADJUST);
    } else if (
      cursorDistanceFromTop < BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD &&
      !scrolledToTop
    ) {
      adjustment = new DOMPoint(0, EDGE_DRAG_ADJUST * -1);
    } else {
      return;
    }
    dispatch({
      type: "ADJUST",
      payload: { adjustment }
    });
  }, [
    state.tracking,
    scrolledToTop,
    cursorDistanceFromTop,
    cursorDistanceFromBottom,
    state.dragCurrentCoords // fire effect any time current drag point changes
  ]);

  return (
    <DraggableRoot
      data-draggable-id={state.draggableId}
      data-draggable-disabled={disable}
      onMouseDown={e => {
        if (disable) return;

        // Don't start drags on right clicks
        if (e.nativeEvent?.which === 3) {
          return;
        }

        // Don't trigger uptree draggables
        let closestDraggable;
        let targetAncestor = e.target as HTMLElement;
        while (targetAncestor) {
          if (
            targetAncestor.dataset?.draggableId &&
            targetAncestor.dataset?.draggableDisabled !== "true"
          ) {
            closestDraggable = targetAncestor;
            break;
          }
          targetAncestor = targetAncestor.parentNode as HTMLElement;
        }
        if (closestDraggable?.dataset?.draggableId !== state.draggableId) {
          return;
        }

        dispatch({
          type: "START",
          payload: {
            coords: new DOMPoint(e.pageX, e.pageY)
          }
        });
      }}
    >
      {children}
    </DraggableRoot>
  );
}

const DraggableRoot = styled.div`
  width: 100%;
  height: 100%;
`;
