import React from "react";

import {
  BindingFilter,
  ComponentConfigProps,
  Filter,
  FilterTypes,
  ValueFilter
} from "../../../../../../types";
import { FieldType } from "../../../../../common/AttributeInput/constants";
import { BlankValueType } from "../../../constants";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import { ItemContainer } from "../../common/CollectionItemContainer/CollectionItemContainer";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import {
  DISPLAY_MESSAGE,
  REFRESH_COMPONENT,
  AUTO_UPDATE_BY_KEY
} from "../../common/effects/Effects";
import { MessageType } from "../../common/effects/Effects/DisplayMessage";
import EffectsManager from "../../common/effects/EffectsManager/EffectsManager";
import { SubmittableEventType } from "../../common/effects/useSubmittableEffects/useSubmittableEffects";
import FilterListManagerSection from "../../common/FilterListManager";
import FunctionConfig from "../../common/FunctionBackedPopover/FunctionConfig";
import { ensureSubmittableComponentConfigState } from "../../common/FunctionBackedPopover/reducer/reducer";
import TextConfig from "../../common/FunctionBackedPopover/TextConfig";
import ParametersConfigSection from "../../common/ParametersManager";
import { ParameterType } from "../../common/useFuncParams";
import {
  extractFilterBinding,
  extractFiltersFromInputParameters,
  extractFiltersOptions
} from "../../common/util";

export default function Config(props: ComponentConfigProps) {
  const dataContent = React.useMemo(() => <DataConfig />, []);
  const effectsContent = React.useMemo(() => <EffectsConfig />, []);
  return (
    <ComponentConfigContextContainer
      slug={props.slug}
      dataConfig={dataContent}
      effectsConfig={effectsContent}
    />
  );
}

function DataConfig() {
  const context = useComponentConfigContext();
  const state = ensureSubmittableComponentConfigState(context.state);
  const { dispatch } = context;
  const func = state.draftComponent.functions.edges[0]?.node;
  const functionId = func?.id;
  const filtersOptions = func?.metadata ? extractFiltersOptions(func.metadata) : [];

  const filterToInputParameter = React.useCallback(
    (filter: Filter) => ({
      type: filter.type,
      attribute: filter.attribute,
      operator: filter.operator,
      value:
        filter.type === FilterTypes.Binding
          ? "${" + (filter as BindingFilter).binding + "}"
          : (filter as ValueFilter).value
    }),
    []
  );

  const onChangeFilters = React.useCallback(
    (filters: Filter[]) => {
      const input_parameters = [
        {
          type: ParameterType.TEMPLATE,
          name: "filters",
          hidden: false,
          required: false,
          field_type: FieldType.JSON_INPUT,
          blank_value_type: BlankValueType.UNDEFINED,
          template:
            "`" +
            JSON.stringify(
              filters
                .filter(
                  filter =>
                    filter.type === FilterTypes.Binding ||
                    filter.type === FilterTypes.Value
                )
                .map(filterToInputParameter)
            ) +
            "`"
        }
      ];
      dispatch({
        type: "MERGE_DRAFT_COMPONENT",
        payload: {
          change: {
            properties: {
              input_parameters
            }
          }
        }
      });
    },
    [dispatch, filterToInputParameter]
  );

  return (
    <>
      <FunctionConfig state={state} dispatch={dispatch} />
      {!!functionId && (
        <BaseComponentConfigSection title="Function Component">
          <TextConfig state={state} dispatch={dispatch} />
        </BaseComponentConfigSection>
      )}
      {/* always render ParametersConfigSection so that component cleanup can occur when data source changes and functionId is undefined */}
      <ItemContainer itemKey="fieldset">
        <ParametersConfigSection title="Fields" />
      </ItemContainer>
      <FilterListManagerSection
        title="Filters"
        filtersOptions={filtersOptions}
        extractFilters={extractFiltersFromInputParameters}
        extractFilterBinding={extractFilterBinding}
        onChange={onChangeFilters}
      />
    </>
  );
}

export const FUNCTION_EVENTS = [
  {
    name: "On Succesful Submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [DISPLAY_MESSAGE, REFRESH_COMPONENT, AUTO_UPDATE_BY_KEY],
    defaultEffect: {
      type: "refresh_component" as const,
      options: {
        component: undefined
      }
    }
  },
  {
    name: "On Failed Submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE],
    defaultEffect: {
      type: "display_message" as const,
      options: {
        type: MessageType.ERROR,
        message: "Something went wrong. Please try again."
      }
    }
  }
];

function EffectsConfig() {
  return <EffectsManager eventOptions={FUNCTION_EVENTS} />;
}
