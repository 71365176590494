import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Form } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import "../../../index.less";
import { useNavigate } from "react-router-dom";

import usePaths from "../../common/hooks/usePaths";
import NameField, { Id as NAME_ID } from "../common/NameField";
import selectError from "../common/selectError";
import * as styled from "../common/styledComponents";
import ZendeskFields from "../common/ZendeskFields";

export const CREATE_ZENDESK_DATA_SOURCE = gql`
  mutation CreateZendeskDataSource(
    $domain: String!
    $user: String!
    $apiToken: String!
    $name: String!
  ) {
    createZendeskDataSource(
      domain: $domain
      user: $user
      apiToken: $apiToken
      name: $name
    ) {
      dataSource {
        id
      }
    }
  }
`;

const ZendeskForm = (props: FormComponentProps) => {
  const navigate = useNavigate();
  const { getDashboard } = usePaths();
  const [errorMessage, setErrorMessage] = useState("");
  const [createDataSource, { loading }] = useMutation(CREATE_ZENDESK_DATA_SOURCE, {
    awaitRefetchQueries: true,
    onCompleted: () => {
      navigate(getDashboard());
    },
    onError: (err: ApolloError) => setErrorMessage(selectError(err))
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setErrorMessage("");
    props.form.validateFields((errors: any) => {
      if (errors) {
        return;
      }
      const variables = {
        domain: props.form.getFieldValue("domain").trim(),
        user: props.form.getFieldValue("user"),
        apiToken: props.form.getFieldValue("apiToken"),
        name: props.form.getFieldValue(NAME_ID)
      };
      createDataSource({ variables });
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <NameField isLoading={loading} form={props.form} />
      <ZendeskFields isLoading={loading} form={props.form} />
      <styled.FormFooter>
        <styled.PTagWithTextColor>
          You can generate an API token by going your Zendesk API settings.
        </styled.PTagWithTextColor>
        <Button
          htmlType="submit"
          type="primary"
          className="createDataSource"
          loading={loading}
          data-test="create-data-source-button"
        >
          Connect to Zendesk
        </Button>
        <styled.ErrorMessage>{errorMessage}</styled.ErrorMessage>
      </styled.FormFooter>
    </Form>
  );
};

export default Form.create({ name: "ZendeskForm" })(ZendeskForm);
