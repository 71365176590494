import React from "react";

import { Button, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styled from "styled-components";

import { Schema } from "./schema";
import { KeyValueFormItem } from "./styledComponents";
import { fieldRules } from "./util";

interface AdditionalFieldsProps extends FormComponentProps {
  schema: Schema;
  initialValues?: Record<string, any>;
  maxProperties?: number;
  disabled?: boolean;
  path?: string;
}

export const Add = styled(Button)`
  margin-bottom: ${props => props.theme.spacersm};
  padding: 0;
`;

export const Remove = styled(Button)`
  color: ${props => props.theme.primaryColor};
`;

// See also: ./util.applyAdditional function.
const AdditionalFields = ({
  form,
  schema,
  initialValues,
  maxProperties,
  disabled,
  path
}: AdditionalFieldsProps) => {
  const metaIndexesPath = "meta.indexes." + path;
  const metaKeysPath = "meta.keys." + path;
  const metaValuesPath = "meta.values." + path;

  form.getFieldDecorator(metaIndexesPath, {
    initialValue: Object.keys(initialValues || {}).map((_, index) => index)
  });

  const addRow = () => {
    const indexes = form.getFieldValue(metaIndexesPath);
    const index = indexes.length === 0 ? 0 : Math.max(...indexes) + 1;
    form.setFieldsValue({
      [metaIndexesPath]: indexes.concat(index)
    });
  };

  const removeRow = (i: number) => {
    const indexes: number[] = form.getFieldValue(metaIndexesPath);
    const keys: string[] = form.getFieldValue(metaKeysPath);
    const values: any[] = form.getFieldValue(metaValuesPath);
    form.setFieldsValue({
      [metaIndexesPath]: indexes.filter(index => index !== i),
      [metaKeysPath]: keys.map((k, index) => (index === i ? undefined : k)),
      [metaValuesPath]: values.map((v, index) => (index === i ? undefined : v))
    });
  };

  const indexes: number[] = form.getFieldValue(metaIndexesPath);
  const formItems = indexes.map(index => {
    const keyName = metaKeysPath + `[${index}]`;
    const keyField = form.getFieldDecorator(keyName, {
      initialValue: Object.keys(initialValues || {})[index],
      rules: fieldRules("Key", { type: "string" }, true)
    })(<Input disabled={disabled} />);

    const valueName = metaValuesPath + `[${index}]`;
    const valueField = form.getFieldDecorator(valueName, {
      initialValue: Object.values(initialValues || {})[index],
      rules: fieldRules("Value", schema, true)
    })(<Input disabled={disabled} />);

    const keyErrors = form.getFieldError(keyName) || [];
    const valueErrors = form.getFieldError(valueName) || [];
    const errors = keyErrors.concat(valueErrors);

    return (
      <KeyValueFormItem
        key={index}
        help={(errors && errors[0]) || ""}
        validateStatus={errors ? "error" : ""}
      >
        {keyField}
        {valueField}
        <Remove
          icon="close-circle"
          size="small"
          title="Remove"
          type="link"
          onClick={() => removeRow(index)}
        />
      </KeyValueFormItem>
    );
  });

  const showAdd = indexes.length < (maxProperties || Number.MAX_SAFE_INTEGER);
  return (
    <>
      {indexes.length > 0 && (
        <KeyValueFormItem>
          <label>Key</label>
          <label>Value</label>
        </KeyValueFormItem>
      )}
      {formItems}
      {showAdd && (
        <Add type="link" onClick={() => addRow()}>
          + Add
        </Add>
      )}
    </>
  );
};

export default AdditionalFields;
