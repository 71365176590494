import { SpaceComponentObject, ConfigValidationError } from "../../../../../types";
import { ComponentConfigState } from "../../../types";
import selectVisibilityRuleErrors from "../common/VisibilityRulesManager/selectErrors";

export default function errorSelector(
  state: ComponentConfigState,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { draftComponent } = state;
  const invalidBindings = findInvalidInputBindings(draftComponent);
  const bindingValue = state.draftComponent.properties.binding;
  if (bindingValue && invalidBindings.includes(bindingValue)) {
    errors.push({
      field: "BINDING",
      message: "This binding is invalid because its reference no longer exists."
    });
  }
  return errors.concat(selectVisibilityRuleErrors(draftComponent));
}
