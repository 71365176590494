import { useCallback } from "react";

import { useMutation } from "@apollo/react-hooks";

import { SpaceConfigDispatch, SpaceConfigState } from "../reducer";

import { SPACE_DESTROY } from "./queries";

export interface MutationOptions {
  id?: string;
  onCompleted: (data: DestroyConfigMutationData) => void;
}

export interface DestroyConfigMutationVars {
  id: string;
}

export type DestroyConfigMutationData = {
  spaceDelete: {
    ok: boolean;
  };
};

const useDestroyConfigMutation = (
  state: SpaceConfigState,
  dispatch: SpaceConfigDispatch,
  options: MutationOptions
) => {
  const { id, onCompleted } = options;

  const [execDestroy, { loading }] = useMutation<
    DestroyConfigMutationData,
    DestroyConfigMutationVars
  >(SPACE_DESTROY, { onCompleted });

  const destroy = useCallback(() => {
    if (!id) {
      throw new Error("destroy: id is not present");
    }
    return execDestroy({ variables: { id } });
  }, [id, execDestroy]);

  return {
    destroy,
    loading
  };
};

export default useDestroyConfigMutation;
