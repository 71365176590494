import React from "react";

import moment from "moment";
import styled from "styled-components";

import { License } from "../../ConfigContext";

interface Props {
  license: License;
}

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  div {
    text-align: center;
    width: fit-content;
  }
`;

const LicenseExpired = (props: Props) => {
  const { license } = props;
  return (
    <Root>
      <div>
        <h2>Your license expired {moment(license.expiresAt).fromNow()}.</h2>
        <p>
          Your license to Internal OnPrem has expired.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://internal.io/contact"
          >
            Contact Internal
          </a>{" "}
          to renew.
        </p>
      </div>
    </Root>
  );
};

export default LicenseExpired;
