import { Icon, Progress } from "antd";
import styled from "styled-components";

import { colors } from "../../../../cssConstants";
import { H2 } from "../../../common/StyledComponents";

export const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    background-color: ${colors.newContainerPrimaryTint};
  }

  .ant-progress-success-bg,
  .ant-progress-bg {
    border-radius: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  font-size: ${props => props.theme.mediumFontSize};
  color: ${props => props.theme.warningColor};
  vertical-align: middle;
  cursor: help;
`;

export const Main = styled.div`
  display: flex;
`;

export const StateLabel = styled.div`
  display: flex;
  gap: 12px;
  align-items: baseline;
`;

export const TaskCount = styled.div`
  text-align: right;
  padding: 0 12px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StateProgress = styled(Column)`
  flex-grow: 1;
`;

export const TaskData = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Headline = styled(H2)`
  margin-bottom: 0;
`;
