import React from "react";

import { Input } from "antd";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import { CredentialFormProps } from "./types";

export const Id = "Name";

export default function NameField(props: CredentialFormProps) {
  return (
    <FormItem>
      {props.form.getFieldDecorator(Id, {
        rules: [{ required: true, message: VALIDATION_MESSAGES.requiredField }]
      })(
        <Input
          placeholder="Display Name"
          data-test="name-input"
          disabled={props.isLoading}
        />
      )}
    </FormItem>
  );
}
