import React, { useState } from "react";

import InviteModal from "./InviteModal";

interface Props {
  email?: string;
}

const InviteReview = (props: Props) => {
  const [showInviteModal, setShowInviteModal] = useState<boolean>(!!props.email);

  const close = () => {
    setShowInviteModal(false);
  };

  return (
    <InviteModal
      defaultValue={props.email}
      handleOk={close}
      handleCancel={close}
      visible={showInviteModal}
    />
  );
};

export default InviteReview;
