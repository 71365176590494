import React from "react";

import { Button, Icon, Input } from "antd";
import * as clipboard from "clipboard-polyfill/text";
import styled from "styled-components";

import Message from "../../../../common/Message";
import { ErrorField } from "../../../../common/StyledComponents";
import { ProviderProps } from "../SingleSignOnDrawer";

const Root = styled.div`
  & > div {
    margin-top: ${props => props.theme.spacermd};
  }
`;

const OktaOpenIdSSOProvider = ({
  connection,
  setConnection,
  errors,
  redirect
}: ProviderProps) => {
  return (
    <Root>
      <div>
        <h3>External Key</h3>
        <Input
          disabled
          value={connection.externalKey}
          addonAfter={
            <Icon
              type="copy"
              onClick={() => {
                clipboard.writeText(connection.externalKey).then(
                  () => {
                    Message.info("Copied External Key to clipboard");
                  },
                  () => {
                    Message.error("Unable to copy to clipboard.");
                  }
                );
              }}
            />
          }
        />
      </div>
      <div>
        <h3>Okta Domain</h3>
        <Input
          value={connection.oktaDomain || ""}
          onChange={e => setConnection({ ...connection, oktaDomain: e.target.value })}
        />
        {errors.oktaDomain && <ErrorField>{errors.oktaDomain}</ErrorField>}
      </div>
      <div>
        <h3>Okta Client ID</h3>
        <Input
          value={connection.oauthClientId || ""}
          onChange={e =>
            setConnection({ ...connection, oauthClientId: e.target.value })
          }
        />
        {errors.oauthClientId && <ErrorField>{errors.oauthClientId}</ErrorField>}
      </div>
      <div>
        <h3>Okta Client Secret</h3>
        <Input
          value={connection.oauthClientSecret || ""}
          onChange={e =>
            setConnection({ ...connection, oauthClientSecret: e.target.value })
          }
        />
        {errors.oauthClientSecret && (
          <ErrorField>{errors.oauthClientSecret}</ErrorField>
        )}
      </div>
      <div>
        <p>
          Before requiring SSO for all employees, you must verify that the Okta OpenId
          provider works correctly.
        </p>
        <a href={`/api/sso/login/okta-openidconnect?next=${redirect}`}>
          <Button disabled={connection.state !== "active"}>
            Test Okta Configuration
          </Button>
        </a>
      </div>
    </Root>
  );
};

export default OktaOpenIdSSOProvider;
