import ErrorPageLayout from "./ErrorPageLayout";
import Header from "./Header/Header";
import Layout, {
  BasicLayout,
  LightBasicLayout,
  NoHeaderLayout,
  PaddedLayout
} from "./Layout";
import Nav from "./Nav/Nav";

export {
  Layout,
  BasicLayout,
  LightBasicLayout,
  NoHeaderLayout,
  PaddedLayout,
  ErrorPageLayout,
  Header,
  Nav
};
