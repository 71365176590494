import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Skeleton } from "antd";
import gql from "graphql-tag";
import styled from "styled-components";

import { OrganizationNode } from "../../../../types";
import useAuthUser from "../../../common/hooks/useAuthUser";
import { H6, P, Hr, LinkButtonNew } from "../../../common/StyledComponents";
import withErrorBoundary from "../../../hoc/withErrorBoundary";

import AdminList from "./AdminList";
import ConfigureOrgModal from "./ConfigureOrgModal";

const EmptyImage = styled.div`
  max-width: 170px;
  width: 100%;
  aspect-ratio: 3 / 2;
  margin-bottom: ${props => props.theme.spacerlg};
  border: 1px dashed #adadad;
  box-sizing: border-box;
  border-radius: 4px;
`;

const OrgLogo = styled.img`
  margin-bottom: ${props => props.theme.spacerlg};
  max-height: 85px;
  max-width: 170px;
`;

export default withErrorBoundary(() => {
  const [openModal, setOpenModal] = React.useState<null | "CONFIGURE_ORG">(null);
  const { data, loading } = useQuery<AuthOrgQueryData>(AUTH_ORG_QUERY);
  const { isAdmin } = useAuthUser();

  if (loading || data?.authOrganization === undefined) return <Skeleton active />;

  const org = data.authOrganization;

  return (
    <div>
      {org.logo?.url ? (
        <OrgLogo data-test="orgLogo" src={org.logo.url} alt="Organization's logo" />
      ) : (
        <EmptyImage />
      )}
      <H6 data-test="orgName">{org.name} on Internal</H6>
      {org.description && <P data-test="orgDesc">{org.description}</P>}
      {isAdmin && (
        <LinkButtonNew
          onClick={() => {
            setOpenModal("CONFIGURE_ORG");
          }}
        >
          Settings
        </LinkButtonNew>
      )}
      <Hr />
      <AdminList />
      {openModal === "CONFIGURE_ORG" && (
        <ConfigureOrgModal
          org={org}
          onCancel={() => {
            setOpenModal(null);
          }}
        />
      )}
    </div>
  );
});

type AuthOrgQueryData = { authOrganization: OrganizationNode };
const AUTH_ORG_QUERY = gql`
  query OrgDetailQuery {
    authOrganization {
      id
      name
      description
      logo {
        url
      }
    }
  }
`;
