import React from "react";

import { useNavigate } from "react-router-dom";

import AutomationsIcon from "../../../../../assets/automations-icon.svg";
import AutomationsHeroImage from "../../../../../assets/automations_hero.png";
import { Urls } from "../../../../../constants";
import ButtonNew from "../../../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import usePaths from "../../../../common/hooks/usePaths";
import * as styled from "../../../../common/StyledComponents";

import { AutomationsHero, IconContainer } from "./styledComponents";

const GettingStarted = ({ className }: { className?: string }) => {
  const { isAdmin } = useAuthUser();
  const { automationsapp_getNewAutomation } = usePaths();
  const navigate = useNavigate();
  return (
    <styled.Container className={className}>
      <styled.InnerContainer>
        <styled.GettingStartedHeader>
          <styled.TitleHeaderContainer>
            <styled.TitleContainer>
              <IconContainer>
                <AutomationsIcon />
              </IconContainer>
              <styled.Title>Automations</styled.Title>
              {!isAdmin && (
                <styled.PillContainer>
                  <styled.Pill color="#807C86">Not enabled yet</styled.Pill>
                </styled.PillContainer>
              )}
            </styled.TitleContainer>
            <styled.Tagline>
              Automate work across your databases, APIs, and business apps.
            </styled.Tagline>
          </styled.TitleHeaderContainer>
          {isAdmin && (
            <ButtonNew
              type="brand"
              size="lg"
              icon="plus"
              onClick={() => navigate(automationsapp_getNewAutomation())}
            >
              Create new automation
            </ButtonNew>
          )}
        </styled.GettingStartedHeader>
        <AutomationsHero src={AutomationsHeroImage} />
        <styled.DescriptionContainer>
          <div>
            <styled.BigP>
              Whether it’s a weekly job to update your database, a daily REST API call,
              or a complex sequence of tasks to onboard customers every hour, Internal
              makes it fast and easy to automate work. Save valuable development and
              operations time all at once.
            </styled.BigP>
            <styled.BigP>
              With Automations, you’ll define a custom schedule and an action (what you
              want to happen automatically). An action can be a single database query,
              an API call, or an entire workflow that automates tasks across your
              backend systems. Automations run against one or more of your environments
              (such as development, staging and production) and you’ll get a detailed
              log out of the box to see what happened and when.
            </styled.BigP>
            <styled.BigP>
              Designed for no-code first, it’s easy for anyone to create an Automation –
              and powerful enough for developers who want to dive deeper with SQL and
              JavaScript.
            </styled.BigP>
          </div>
          <styled.AppLinkContainer>
            <styled.AppLinks>
              <styled.AppLinkItem>
                <styled.AppLink href={Urls.AUTOMATIONS_DOCUMENTATION} target="blank">
                  <styled.AppLinkIcon type="file" />
                  <span>Documentation</span>
                </styled.AppLink>
              </styled.AppLinkItem>
            </styled.AppLinks>
          </styled.AppLinkContainer>
        </styled.DescriptionContainer>
      </styled.InnerContainer>
    </styled.Container>
  );
};

export default GettingStarted;
