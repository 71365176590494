import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import ButtonNew from "../components/common/ButtonNew/ButtonNew";
import useAuthUser from "../components/common/hooks/useAuthUser";
import usePaths from "../components/common/hooks/usePaths";
import { Urls } from "../constants";
import ErrorPageLayout, { ErrorPageSidebarLayout } from "../layouts/ErrorPageLayout";
import PageHeader from "../layouts/Header/Header";
import { LeftCol } from "../layouts/Layout";
import Nav from "../layouts/Nav";

const LeftRail = styled(LeftCol)``;

const Cols = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  font-weight: 500;
`;

export const ServiceUnavailable = () => {
  const { getDashboard } = usePaths();
  return (
    <ErrorPageLayout>
      <p>The service is currently unavailable. Please try again in a few moments.</p>
      <Link to={getDashboard()}>Back to home</Link>
    </ErrorPageLayout>
  );
};

export const Unauthorized = () => {
  const { getDashboard } = usePaths();
  return (
    <ErrorPageLayout>
      <p>You are not authorized to view this page.</p>
      <Link to={getDashboard()}>Back to home</Link>
    </ErrorPageLayout>
  );
};

export const NotFound = () => {
  const { getDashboard } = usePaths();
  return (
    <ErrorPageLayout>
      <p>The page you were looking for does not exist.</p>
      <Link to={getDashboard()}>Back to home</Link>
    </ErrorPageLayout>
  );
};

export const AccountLocked = () => {
  const { isAdmin } = useAuthUser();
  return (
    <>
      <PageHeader key="space-home" />
      <Cols>
        {(isAdmin || window.FEATURE_FLAGS.includes("QUEUES")) && (
          <LeftRail open>
            <Nav />
          </LeftRail>
        )}

        <ErrorPageSidebarLayout>
          <p>Your account has been locked. Please contact support for more details.</p>
          <ButtonNew
            type="brand"
            onClick={() => window.open(Urls.SUPPORT_URL, "_blank")}
          >
            Contact Support
          </ButtonNew>
        </ErrorPageSidebarLayout>
      </Cols>
    </>
  );
};

export function AccountLockedGate({ children }: { children: React.ReactNode }) {
  const { authUser } = useAuthUser();
  if (authUser?.organization.locked) return <AccountLocked />;
  return <>{children}</>;
}
