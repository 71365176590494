import gql from "graphql-tag";
import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery
} from "react-apollo";

import { EXTENDED_ROLE_FRAGMENT } from "../../../graphql/queries";
import { Connection, FunctionNode, RoleNode } from "../../../types";
import { FunctionPermissionInput } from "../../settings/roles/FunctionPermissions";

export interface UpdateRolePermissionsData {
  updateRolePermissions: {
    role: RoleNode;
    __typename: "UpdateRolePermissions";
  };
}

export interface UpdateRolePermissionsVariables {
  id: string;
  name: string;
  scopes: string[];
  applyPermissions: FunctionPermissionInput[];
}

export const UPDATE_ROLE_PERMISSIONS = gql`
  mutation UpdateRolePermissions(
    $id: ID!
    $name: String!
    $scopes: [String!]
    $applyPermissions: [FunctionPermissionInput!]
  ) {
    updateRolePermissions(
      id: $id
      name: $name
      scopes: $scopes
      applyPermissions: $applyPermissions
    ) {
      role {
        ...ExtendedRoleFragment
        scopes
      }
    }
  }
  ${EXTENDED_ROLE_FRAGMENT}
`;

export const useUpdateRolePermissions = (
  options?: MutationHookOptions<
    UpdateRolePermissionsData,
    UpdateRolePermissionsVariables
  >
) => {
  return useMutation<UpdateRolePermissionsData, UpdateRolePermissionsVariables>(
    UPDATE_ROLE_PERMISSIONS,
    options
  );
};

export interface FunctionsForIdsVariables {
  ids: string[];
  roleId: string;
}

export interface FunctionsForIdsData {
  nodes: FunctionNode[];
}

export const FUNCTIONS_FOR_IDS = gql`
  query FunctionsForIds($ids: [ID!]!, $roleId: ID!) {
    nodes(ids: $ids) {
      ... on FunctionNode {
        id
        name
        title
        dataSource {
          id
          name
        }
        policies(roleId: $roleId) {
          edges {
            node {
              allowsAll
              policyFunctionParameters {
                edges {
                  node {
                    functionParameter {
                      id
                      name
                    }
                  }
                }
              }
              policyFunctionAttributes {
                edges {
                  node {
                    functionAttribute {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        functionParameters {
          edges {
            node {
              id
              name
              required
            }
          }
        }
        functionAttributes {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const useFunctionsForIds = (
  options?: QueryHookOptions<FunctionsForIdsData, FunctionsForIdsVariables>
) =>
  useQuery<FunctionsForIdsData, FunctionsForIdsVariables>(FUNCTIONS_FOR_IDS, options);

export const FETCH_ROLES = gql`
  query Roles {
    allRoles {
      edges {
        node {
          id
          name
          scopes
          isManaged
          slug
        }
      }
    }
  }
`;

export interface RolesData {
  allRoles: Connection<RoleNode>;
}

export const useRoles = (options?: QueryHookOptions<RolesData, {}>) =>
  useQuery<RolesData, {}>(FETCH_ROLES, options);
