import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { AttributeTypes } from "../../../../constants";
import { InputParameter } from "../../../../types";
import { ActionOptions } from "../types";

import { QUEUE_NODE_FRAGMENT, QueueNode } from "./common";

export interface ValidationErrorMessage {
  text: string;
  index: string[];
}

export interface ValidationErrorResult {
  __typename: "ValidationErrorResult";
  messages: ValidationErrorMessage[];
}

export type SaveQueueResult = QueueNode | ValidationErrorResult;

export type Data = { saveQueue: SaveQueueResult };

export interface QueueInput {
  id?: string;
  description: string;
  name: string;
  icon?: string;
  color?: string;
  createTaskInputParameters: InputParameter[];
  actions: ActionInput[];
  fields: FieldInput[];
  states: StateInput[];
  transitions: TransitionInput[];
  moveTasks?: MoveTaskInput[];
}

interface ActionInput {
  id?: string;
  type: string;
  options: ActionOptions;
}

interface FieldInput {
  id?: string;
  name: string;
  type: AttributeTypes;
  required: boolean;
}

export interface StateInput {
  id?: string;
  key: string;
  name: string;
  color: string;
  isArchive: boolean;
  subscriptions: {
    beforeEnter: number[];
    beforeExit: number[];
  };
}

export interface TransitionInput {
  id?: string;
  fromStateKey: string;
  toStateKey: string;
  name: string;
  isPrimary: boolean;
  subscriptions: {
    beforeExecute: number[];
  };
}

export interface MoveTaskInput {
  fromStateKey: string;
  toStateKey: string;
}

interface Variables {
  queue: QueueInput;
}

const QUERY = gql`
  mutation SaveQueue($queue: QueueInput!) {
    saveQueue(queue: $queue) {
      __typename
      ...QueueNodeFragment
      ... on ValidationErrorResult {
        messages {
          text
          index
        }
      }
    }
  }
  ${QUEUE_NODE_FRAGMENT}
`;

const useSaveQueue = (options?: MutationHookOptions<Data, Variables>) => {
  return useMutation<Data, Variables>(QUERY, options);
};

export default useSaveQueue;
