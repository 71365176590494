import React from "react";

import { Button, Menu, Popover } from "antd";
import { PopoverProps } from "antd/lib/popover";
import styled from "styled-components";

import DeleteDataSourceButton from "../DataSourceSettingsPanel/DeleteDataSourceButton";

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.spacerxl};
`;

export const DangerThemeLink = styled(DeleteDataSourceButton)`
  &.ant-btn-link {
    font-size: ${props => props.theme.mediumFontSize};
    color: ${props => props.theme.errorColor};
    &:hover {
      color: ${props => props.theme.darkErrorColor};
    }
  }
`;

export const WithTopMargin = styled.div`
  margin-top: ${props => props.theme.spacermd};
`;

export const LeftTextAlignButton = styled(Button)`
  &.ant-btn-link {
    border-radius: 0px;
    text-align: initial;
    color: ${props => props.theme.textColorMid};

    &:hover {
      color: white;
      background-color: ${props => props.theme.primaryColorHover};
    }

    &:focus,
    &:active {
      color: white;
      background-color: ${props => props.theme.primaryColorPressed};
    }
  }
`;

export const AddNewButton = styled(Button)`
  padding-left: 0px;
  &.ant-btn-link {
    font-size: ${props => props.theme.mediumFontSize};
  }
`;

export const MenuContainer = styled.div`
  box-shadow: ${props => props.theme.boxShadow};
`;

export const Item = styled(Menu.Item)`
  &.ant-menu-item {
    margin: 0px !important;
    border-radius: ${props => props.theme.borderRadiusmd};
    font-size: ${props => props.theme.mediumFontSize};

    &:hover {
      color: ${props => props.theme.textColorMid};
      background-color: ${props => props.theme.rowHoverColor};
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

interface StyledPopoverProps extends PopoverProps {
  className?: string;
  children?: React.ReactNode;
}
const PopoverToStyle = ({ className, children, ...props }: StyledPopoverProps) => {
  return (
    <Popover overlayClassName={className} {...props}>
      {children}
    </Popover>
  );
};

export const StyledPopover = styled(PopoverToStyle)`
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0px;
  }
`;
