import React from "react";

import { AssigneeType } from "../../Config/types";

import { AssigneeNode } from "./useQueueClient/queries/AllTasks";

interface AssigneeProps {
  assignee: AssigneeNode;
  labelRoleAsUnassigned?: boolean;
}

export default function AssigneeField({
  assignee,
  labelRoleAsUnassigned
}: AssigneeProps) {
  if (!assignee) {
    return <div>Unassigned</div>;
  }
  return (
    <div title={assignee.name}>
      {assignee.type === AssigneeType.ROLE && labelRoleAsUnassigned
        ? "Unassigned"
        : assignee.name}
    </div>
  );
}
