import styled from "styled-components";

import { Hero } from "../../../../common/StyledComponents";

export const AutomationsHero = styled(Hero)`
  box-shadow: none;
  margin-left: -26px; // to account for box-shadow of image
  width: calc(100% + 52px); // +52 to right-align and account for box-shadow
`;

export const IconContainer = styled.div`
  height: 24px;
  margin-right: 12px;
`;
