import React, { Dispatch } from "react";

import EnvironmentSelector from "../../../common/EnvironmentSelector";
import { Hr } from "../../../common/StyledComponents";
import { ConfigAction, ConfigState, ReducerActions } from "../reducer";
import * as styled from "../styledComponents";

import ActionPicker from "./ActionPicker";

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ConfigAction>;
}
const ActionsAndEnvironments = ({ state, dispatch }: Props) => {
  const handleEnvironmentsChange = React.useCallback(
    value =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          environmentIds: value
        }
      }),
    [dispatch]
  );

  const handleFunctionChange = React.useCallback(
    value =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          functionId: value
        }
      }),
    [dispatch]
  );

  const handleParameterValuesChange = React.useCallback(
    value =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          inputParameters: value
        }
      }),
    [dispatch]
  );

  return (
    <div>
      <styled.Title>Actions and environments</styled.Title>
      <styled.Section>
        <styled.SectionTitle>Actions</styled.SectionTitle>
        <styled.SectionDescription>
          What happens when this automation is run
        </styled.SectionDescription>
        <ActionPicker
          functionId={state.functionId}
          inputParameters={state.inputParameters}
          onChange={handleFunctionChange}
          onParameterValuesChange={handleParameterValuesChange}
        />
      </styled.Section>
      <Hr />
      <styled.Section>
        <styled.SectionTitle>Environments</styled.SectionTitle>
        <styled.SectionDescription>
          Which environments this automation will run in
        </styled.SectionDescription>
        <EnvironmentSelector
          value={state.environmentIds}
          onChange={handleEnvironmentsChange}
        />
      </styled.Section>
    </div>
  );
};

export default ActionsAndEnvironments;
