import React from "react";

import styled from "styled-components";

import useExternalCidrRanges from "../../common/hooks/useExternalCidrRanges";

const Help = styled.p`
  color: ${props => props.theme.textColor};
`;

const IPRange = styled.p`
  margin-bottom: ${props => props.theme.spacermd};
  color: ${props => props.theme.labelColor};
  font-size: ${props => props.theme.mediumFontSize};
  font-weight: ${props => props.theme.boldFontWeight};
`;

const IPHelp = () => {
  const { externalCidrRanges } = useExternalCidrRanges();
  return (
    <>
      <Help>
        To ensure Internal has access, configure your network policies to allow
        connections from these IP addresses:
      </Help>
      {externalCidrRanges.map((cidr: string) => (
        <IPRange key={cidr}>{cidr}</IPRange>
      ))}
    </>
  );
};

export default IPHelp;
