import React, { Dispatch } from "react";

import { B3, LinkButtonNew } from "../../../../common/StyledComponents";
import { humanizeCollection } from "../../../../util/humanizeList/humanizeList";
import { ReducerAction, ReducerActions } from "../../reducer/actions";
import { ConfigState } from "../../reducer/reducer";
import { State, Transition } from "../../types";

interface TransitionLinkData {
  key: string; // unique identifier for react
  state: State; // Target state to link to
  stateIndex: number; // array-index of state in a State array
}

export function mapStateToStateIndex(states: State[]) {
  return new Map<string, { state: State; index: number }>(
    states.map((state, index) => [state.id || "", { state, index }])
  );
}

export function getTransitionToLinkData(
  transitions: Transition[],
  states: State[],
  selectedState: State
): TransitionLinkData[] {
  const stateMap = mapStateToStateIndex(states);

  return transitions
    .filter(t => {
      return (
        t.id &&
        t.toStateKey &&
        t.fromStateKey === selectedState.id &&
        stateMap.has(t.toStateKey)
      );
    })
    .map(t => {
      if (!t.toStateKey) {
        throw new Error("toStateKey should be defined");
      }

      const mapResult = stateMap.get(t.toStateKey);
      if (!mapResult) {
        throw new Error(`StateMap should have ${t.toStateKey} as a key`);
      }

      const { index, state } = mapResult;

      return {
        key: t.key,
        state: state,
        stateIndex: index
      };
    });
}

export function getTransitionFromLinkData(
  transitions: Transition[],
  states: State[],
  selectedState: State
) {
  const stateMap = mapStateToStateIndex(states);

  return transitions
    .filter(t => {
      return (
        t.id &&
        t.toStateKey &&
        t.toStateKey === selectedState.id &&
        stateMap.has(t.fromStateKey)
      );
    })
    .map(t => {
      if (!t.fromStateKey) {
        throw new Error("toStateKey should be defined");
      }

      const mapResult = stateMap.get(t.fromStateKey);
      if (!mapResult) {
        throw new Error(`StateMap should have ${t.fromStateKey} as a key`);
      }

      const { index, state } = mapResult;

      return {
        key: t.key,
        state: state,
        stateIndex: index
      };
    });
}

export enum TransitionLinkDirection {
  from = "from",
  to = "to"
}

interface Props {
  className?: string;
  state: ConfigState;
  selectedState: State;
  direction: TransitionLinkDirection;
  dispatch: Dispatch<ReducerAction>;
}

export const TransitionLinks = ({
  className,
  state,
  selectedState,
  direction,
  dispatch
}: Props) => {
  const links =
    direction === TransitionLinkDirection.from
      ? getTransitionFromLinkData(state.transitions, state.states, selectedState)
      : getTransitionToLinkData(state.transitions, state.states, selectedState);

  if (!links.length) {
    return null;
  }

  const prefix =
    direction === TransitionLinkDirection.from
      ? "Transitions from "
      : "Transitions to ";

  const linkButtons = links.map(link => (
    <LinkButtonNew
      key={link.key}
      inline={true}
      size="large"
      onClick={() => {
        dispatch({
          type: ReducerActions.SELECT_STATE_INDEX,
          payload: link.stateIndex
        });
      }}
    >
      {link.state.name}
    </LinkButtonNew>
  ));

  return (
    <B3 className={className}>
      {prefix}
      {humanizeCollection(linkButtons, (separator, index) => (
        <span key={index}>{separator}</span>
      ))}
    </B3>
  );
};
