import React from "react";

import { Select } from "antd";

import { AttributeTypes } from "../../../../../../../constants";
import { APIFiltersOption, ViewFilter } from "../../../../../../../types";
import AttributeInput from "../../../../../AttributeInput";
import * as common from "../../common/styledComponents";

import * as styled from "./styledComponents";

export interface Props {
  filtersOptions: APIFiltersOption[];
  viewFilter: Partial<ViewFilter>;
  onChange?: (viewFilter: Partial<ViewFilter>) => void;
  onRemove?: (viewFilter: Partial<ViewFilter>) => void;
}

export const ViewFilterInput = ({
  filtersOptions,
  viewFilter,
  onChange = () => {},
  onRemove = () => {}
}: Props) => {
  const filterOption = filtersOptions.find(o => o.sourceName === viewFilter.sourceName);
  const validFiltersOptions = filtersOptions.filter(o => o.sourceName);
  const type = filterOption?.sourceType || AttributeTypes.STRING;
  const operators = filterOption?.operators || [];

  return (
    <styled.Container>
      <styled.IconButton
        onClick={() => onRemove(viewFilter)}
        icon="minus"
        title="Remove"
        type="link"
      />
      <common.Select
        placeholder="Select a field"
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={viewFilter.sourceName}
        onChange={(sourceName: any) => onChange({ ...viewFilter, sourceName })}
      >
        {validFiltersOptions.map(o => (
          <Select.Option key={o.sourceName}>{o.sourceName}</Select.Option>
        ))}
      </common.Select>
      <common.Select
        placeholder="Select operator"
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={viewFilter.operator}
        onChange={(operator: any) => onChange({ ...viewFilter, operator })}
      >
        {operators.map(operator => (
          <Select.Option key={operator}>{operator}</Select.Option>
        ))}
      </common.Select>
      <AttributeInput
        placeholder="Enter a value"
        sourceNullable={true}
        sourceType={type}
        sourceName={viewFilter.sourceName || ""}
        value={viewFilter.value}
        onChange={value => onChange({ ...viewFilter, value })}
      />
    </styled.Container>
  );
};
