import styled from "styled-components";

import {
  Header,
  Title as BaseTitle,
  Section as BaseSection
} from "../common/styledComponents";

export const Title = styled(BaseTitle)`
  margin-bottom: ${props => props.theme.spacersm};
`;

export const Section = styled(BaseSection)`
  border-bottom: 1px solid ${props => props.theme.containerPrimaryTint};
`;

export const SectionHeader = styled(Header)`
  padding-bottom: ${props => props.theme.spacerlg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SectionTitleContainer = styled.div``;

export const ColorBox = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: #a560eb;
  margin-top: 5px;
`;

export const TransitionLinksContainer = styled.div`
  margin-bottom: ${props => props.theme.spacerxl};
  & > * {
    display: block;
  }
`;
