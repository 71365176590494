import React from "react";

import styled from "styled-components";

import { H1 } from "../../../components/common/StyledComponents";
import { Urls } from "../../../constants";
import PageHeader from "../../../layouts/Header/Header";
import ButtonNew from "../../common/ButtonNew/ButtonNew";

const ConfirmationContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Confirmation = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 180px auto 20px auto;
  align-items: center
  text-align: center;
`;

const ConfirmationButton = styled(ButtonNew)`
  margin: 20px auto;
  width: 30%;
`;

const BillingConfirmation = () => {
  return (
    <>
      <PageHeader title="Settings" />
      <ConfirmationContainer>
        <Confirmation>
          <H1>Thanks for your purchase!</H1>
          <ConfirmationButton
            size="lg"
            type="brand"
            onClick={() => {
              window.location.href = "/settings/billing";
            }}
          >
            Return to account and billing
          </ConfirmationButton>

          <p>
            For questions about your order or plan,{" "}
            <a href={Urls.SUPPORT_URL} target="_blank" rel="noreferrer">
              contact support
            </a>
            .
          </p>
        </Confirmation>
      </ConfirmationContainer>
    </>
  );
};

export default BillingConfirmation;
