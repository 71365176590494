import React from "react";

import { SpaceComponentNode, SpaceComponentObject } from "../../../../../../types";
import SpaceApi from "../../../../../spaces/SpaceApi";
import { RenderTreeContainer } from "../../../../../spaces/SpaceRoot/RenderTreeContext";
import SpaceComponent from "../../../../../spaces/SpaceRoot/SpaceComponent";
import { ComponentContextContainer } from "../../../../../spaces/SpaceRoot/SpaceComponent/contexts/ComponentContext";
import { ComponentStateContainer } from "../../../../../spaces/SpaceRoot/SpaceComponent/contexts/ComponentStateContext";
import {
  StableSpaceContextContainer,
  useStableSpaceContext
} from "../../../../../spaces/SpaceRoot/SpaceContext";
import {
  createSpaceContextParamComponent,
  EmbedSpaceContextProvider,
  SpaceContextParams
} from "../../../../../spaces/SpaceRoot/SpaceContext/SpaceContext";

export default function SpaceComponentContext({
  component,
  contextParams,
  children
}: {
  children: React.ReactNode;
  component: SpaceComponentObject;
  contextParams: SpaceContextParams;
}) {
  const spaceApi = new SpaceApi();

  return (
    // Probably need ParamGenerationContext too for handling
    // generated DateTimes and UUIDs.
    <EmbedSpaceContextProvider
      componentTreeNodes={[component as SpaceComponentNode]}
      contextParams={contextParams}
    >
      {/* Registers packages */}
      <StableSpaceContextContainer
        spaceSlug="taskForm"
        encodedResourceQueryDescriptor={undefined}
      >
        {/* Provides findNode implementation needed by default useDefaultValue */}
        <RenderTreeContainer>
          {/* Renders components that create bindings for user, environment and task */}
          <SpaceContextParamComponents spaceApi={spaceApi} />
          <ComponentContextContainer component={component}>
            <ComponentStateContainer component={component}>
              {children}
            </ComponentStateContainer>
          </ComponentContextContainer>
        </RenderTreeContainer>
      </StableSpaceContextContainer>
    </EmbedSpaceContextProvider>
  );
}

function SpaceContextParamComponents({ spaceApi }: { spaceApi: SpaceApi }) {
  const { contextParams } = useStableSpaceContext();
  const contextParamComponents = Object.entries(contextParams).map(([key, value]) =>
    createSpaceContextParamComponent(key, value)
  );
  return (
    <>
      {contextParamComponents.map(c => (
        <SpaceComponent key={c.slug} spaceComponent={c} spaceApi={spaceApi} />
      ))}
    </>
  );
}
