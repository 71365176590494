import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_EXTERNAL_IPS = gql`
  query {
    externalCidrRanges
  }
`;

export interface Result {
  loading: boolean;
  externalCidrRanges: string[];
}

export default function useExternalCidrRanges() {
  const { loading, data } = useQuery(GET_EXTERNAL_IPS);

  return {
    loading,
    externalCidrRanges: data?.externalCidrRanges || []
  };
}
