/**
 * Editor component for MongoDB code input.
 * --
 * See FunctionEditor for UI notes.
 */
import React from "react";

import { Select, Checkbox } from "antd";
import styled from "styled-components";

import { BaseCode } from "../../..";
import { Size } from "../../../../../../cssConstants";
import { BaseFunctionName } from "../../../../../../types";
import Editor from "../../../../Editor";
import usePrevious from "../../../../hooks/usePrevious";
import { ErrorMessageField } from "../../../common/ErrorMessageField";
import { BaseFunctionProps } from "../../common";
import * as common from "../../styledComponents";
import { FormGridContainer as FunctionFormGridContainer } from "../../styledComponents";

import { MongoCodeParameterType, MongoBaseFunctionParameterMapping } from "./types";
import { useResources } from "./useResources";
import {
  prepareParameterMapping,
  parseCollection,
  changeBaseFunction,
  mongoSupportedBaseFunctions,
  MongoSupportedBaseFunctionNames,
  getCodeBlocks,
  getEmptyCodeMetadata,
  selectErrors,
  isCodeMetadataCompatible,
  baseFunctionsWithMultipleSupport
} from "./util";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormGridContainer = styled(FunctionFormGridContainer)`
  max-width: ${Size.md};
  overflow: unset;
  padding: ${props => props.theme.modalPadding} 0;
`;

const CodeBlockTitle = styled(common.GridLabelWide)`
  text-transform: capitalize;
  padding: ${props => props.theme.spacersm} 0;
`;

export interface MongoFunctionFormProps extends BaseFunctionProps {
  setBaseFunctionName: (name: BaseFunctionName) => void;
}

const MongoForm = ({
  func,
  selectedDataSource,
  setBaseConfig,
  setBaseFunctionName,
  showErrors
}: MongoFunctionFormProps) => {
  const { resources } = useResources(selectedDataSource.id);

  const baseFunctionName = (func?.baseFunction?.name ||
    mongoSupportedBaseFunctions[0]) as MongoSupportedBaseFunctionNames;
  const mapping =
    func?.baseFunctionParameterMapping as MongoBaseFunctionParameterMapping;
  const collection = parseCollection(mapping?.collection);
  const codeMetadata =
    func?.metadata?.code !== undefined
      ? func.metadata.code
      : getEmptyCodeMetadata(baseFunctionName);

  const handleMappingChange = React.useCallback(
    (mapping: MongoBaseFunctionParameterMapping, nextCodeMetadata?: BaseCode) => {
      nextCodeMetadata =
        nextCodeMetadata === undefined ? codeMetadata : nextCodeMetadata;
      setBaseConfig(
        prepareParameterMapping(mapping, baseFunctionName, nextCodeMetadata),
        nextCodeMetadata
      );
    },
    [baseFunctionName, codeMetadata, setBaseConfig]
  );

  // This effect resets the mapping and code metadata following
  // a base function name change, while maintaining the selected collection
  const lastBaseFunctionName = usePrevious(baseFunctionName, {
    returnCurrentOnFirstRender: true
  });
  const lastCollection = usePrevious(collection, {
    returnCurrentOnFirstRender: true
  });
  React.useEffect(() => {
    if (mapping === undefined || baseFunctionName === lastBaseFunctionName) return;

    const nextCodeMetadata = isCodeMetadataCompatible(
      baseFunctionName,
      codeMetadata,
      mapping
    )
      ? codeMetadata
      : getEmptyCodeMetadata(baseFunctionName);
    const nextMapping = changeBaseFunction(baseFunctionName, lastCollection);

    handleMappingChange(nextMapping, nextCodeMetadata);
  }, [
    baseFunctionName,
    lastBaseFunctionName,
    lastCollection,
    mapping,
    codeMetadata,
    handleMappingChange
  ]);

  const onCollectionChange = (collection: string) => {
    handleMappingChange({ ...mapping, collection });
  };

  const onCodeChange = (paramType: MongoCodeParameterType, code: BaseCode) => {
    handleMappingChange({ ...mapping, [paramType]: code }, code);
  };

  const onAllowsMultipleChange = (multiple: boolean) => {
    handleMappingChange({ ...mapping, multiple: multiple.toString() });
  };

  if (func === undefined) return null;

  const codeBlocks = getCodeBlocks(codeMetadata, baseFunctionName, onCodeChange);
  const errors = selectErrors(func);

  return (
    <common.Container>
      <FormGridContainer>
        <common.GridLabel>Operation:</common.GridLabel>
        <common.GridFormItem vertical>
          <common.Select
            placeholder="Select the operation to perform"
            value={baseFunctionName}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            data-test="baseFunctionSelect"
            onChange={baseFunctionName =>
              setBaseFunctionName(baseFunctionName as MongoSupportedBaseFunctionNames)
            }
          >
            {mongoSupportedBaseFunctions.map(o => (
              <Select.Option key={o}>{o}</Select.Option>
            ))}
          </common.Select>
        </common.GridFormItem>
        <common.GridLabel>Collection:</common.GridLabel>
        <common.GridFormItem vertical>
          <common.Select
            placeholder="Select a collection"
            value={collection}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            data-test="collectionSelect"
            onChange={collection => onCollectionChange(collection as string)}
          >
            {resources.map(r => (
              <Select.Option key={r.sourceName}>{r.sourceName}</Select.Option>
            ))}
          </common.Select>
          <ErrorMessageField errorMessage={showErrors ? errors.collection : ""} />
        </common.GridFormItem>
      </FormGridContainer>
      {baseFunctionsWithMultipleSupport.includes(baseFunctionName) && (
        <>
          <common.GridLabel></common.GridLabel>
          <common.GridFormItem vertical>
            <Checkbox
              data-test="allowMultipleCheckbox"
              checked={mapping.multiple === "true"}
              onChange={e => {
                onAllowsMultipleChange(e.target.checked);
              }}
            >
              Allow multiple records to be updated
            </Checkbox>
          </common.GridFormItem>
        </>
      )}
      {codeBlocks.map(({ key, label, code, onChange }) => (
        <common.Container key={key}>
          <CodeBlockTitle>{label}:</CodeBlockTitle>
          <Editor
            mode="javascript"
            isFullHeight={true}
            value={code}
            onChange={onChange}
          />
          <ErrorMessageField errorMessage={showErrors ? errors[key] : ""} />
        </common.Container>
      ))}
    </common.Container>
  );
};

export default MongoForm;
