import React from "react";

import { Skeleton } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { encodeCursor } from "../../../../../../constants";
import { Cursor, CursorType, DataValue, StatusCode } from "../../../../../../types";
import usePaths from "../../../../../common/hooks/usePaths";
import withErrorBoundary from "../../../../../hoc/withErrorBoundary";
import useRecordData from "../../useRecordData";
import { FunctionNode } from "../../useRecordData/queries";
import { getDataAccessorRecordKey } from "../../util";
import { useGetFunctionPath } from "../useGetFunctionPath";
import useGetRecordPath from "../useGetRecordPath";

import { getRecordTitle, getRelatedRecordFunctionId } from "./util";

interface Props {
  cursor: Cursor;
  // TODO: The name isOutbound should probably change when Related Resources go away.
  isOutbound: boolean;
}

const Root = styled.div`
  margin: ${props => props.theme.spacersm} 0 ${props => props.theme.spacersm}
    ${props => props.theme.spacerxl};
  overflow: hidden;
`;

const RecordLinkWrapper = styled.a`
  display: block;
  max-width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RelatedRecords = ({ cursor, isOutbound }: Props) => {
  const identifier = cursor.type === CursorType.FUNCTION ? cursor.id : cursor.slug;

  const { getSpace } = usePaths();
  const { gotoRecordPath } = useGetRecordPath();
  const { gotoContextRecordPath } = useGetFunctionPath();
  const { loading, errors, dataAccessor, records } = useRecordData(cursor);

  if (loading) {
    return (
      <Root>
        <Skeleton active title={false} paragraph={{ rows: isOutbound ? 1 : 3 }} />
      </Root>
    );
  } else if (errors.length) {
    if (errors[0].code === StatusCode.PERMISSION_DENIED) {
      return (
        <Root>
          <span>You don't have access to this data</span>
        </Root>
      );
    } else {
      throw new Error(errors[0].message);
    }
  } else if (records.length === 0) {
    return (
      <Root>
        <span>No records found</span>
      </Root>
    );
  } else {
    return (
      <Root>
        {records.map((record: Record<string, DataValue>, idx: number) => (
          <RecordLinkWrapper
            key={`${identifier}-related-${idx}`}
            onClick={() => {
              switch (cursor.type) {
                case CursorType.FUNCTION: {
                  const func = dataAccessor as FunctionNode;
                  return gotoContextRecordPath({
                    id: getRelatedRecordFunctionId(func, isOutbound),
                    params: getDataAccessorRecordKey(func, record)
                  });
                }
                case CursorType.RESOURCE: {
                  return gotoRecordPath({
                    contextResource: dataAccessor,
                    contextRecord: record,
                    view: "detail"
                  });
                }
                default:
                  throw new Error("unrecognized cursor type");
              }
            }}
          >
            {getRecordTitle(record, dataAccessor)}
          </RecordLinkWrapper>
        ))}

        {dataAccessor?.__typename === "FunctionNode" &&
          dataAccessor.defaultSpace?.slug && (
            <Link to={getSpace(dataAccessor.defaultSpace?.slug, encodeCursor(cursor))}>
              View all
            </Link>
          )}
      </Root>
    );
  }
};

export default withErrorBoundary(RelatedRecords);
