import { isEqual } from "lodash";

import { BaseFunctionParameterMapping } from "../../..";

export enum MongoCodeParameterType {
  PIPELINE = "pipeline",
  DOCUMENTS = "documents",
  FILTER = "filter",
  UPDATE = "update"
}

export interface MongoBaseFunctionParameterMapping {
  collection: string | undefined;
  documents?: string;
  pipeline?: string;
  filter?: string;
  update?: string;
  multiple?: string;
}

export function isMongoBaseFunctionParameterMapping(
  mapping: BaseFunctionParameterMapping | string | undefined
): mapping is MongoBaseFunctionParameterMapping {
  if (mapping === undefined || typeof mapping === "string" || isEqual(mapping, {}))
    return false;

  const coerced = mapping as unknown as MongoBaseFunctionParameterMapping;

  return (
    !!coerced.collection &&
    (typeof coerced.documents === "string" ||
      typeof coerced.pipeline === "string" ||
      typeof coerced.filter === "string" ||
      (typeof coerced.filter === "string" && typeof coerced.update === "string"))
  );
}
