import React, { ReactElement, useState, useEffect, useRef } from "react";

import { Select } from "antd";
import { OptionProps } from "antd/lib/select";

import { FiltersOption, ViewFilter } from "../../../../../../types";
import withErrorBoundary from "../../../../../hoc/withErrorBoundary";

import {
  getDisplayedOperator,
  getSearchValues,
  getNoMatchOption,
  getOptions
} from "./SpaceTableSearchComponents";
import { parseSearchText } from "./utils";

export interface SpaceSearchBarProps {
  filtersOptions: FiltersOption[];
  filters: ViewFilter[];
  onFilter: (filters: ViewFilter[], forceRefetch?: boolean) => void;
  getUniqueFilterClientId: (prefix: string) => string;
}

export interface PendingFilter extends Partial<ViewFilter> {
  attributeNameDraft?: string;
}

interface RcSelectRef {
  rcSelect: any;
}

function isViewFilter(filter: PendingFilter): filter is ViewFilter {
  return filter.sourceName !== undefined && filter.attributeNameDraft === undefined;
}

function toViewFilter(pendingFilter: PendingFilter): ViewFilter {
  const { attributeNameDraft, ...viewFilter } = pendingFilter;
  if (!isViewFilter(viewFilter)) {
    throw new Error("pendingFilter was not a valid ViewFilter.");
  }
  return viewFilter;
}

function isPendingFilterComplete(filter: PendingFilter) {
  return (
    filter.sourceName !== undefined &&
    filter.operator !== undefined &&
    filter.value !== undefined
  );
}

export const SpaceTableSearchBar = ({
  filtersOptions,
  filters,
  onFilter,
  getUniqueFilterClientId
}: SpaceSearchBarProps) => {
  const selectRef = useRef(null);
  const [pendingFilter, setPendingFilter] = useState<PendingFilter>({});
  const attributeMap = React.useMemo(
    () =>
      filtersOptions.reduce(
        (agg: { [key: string]: FiltersOption }, curr: FiltersOption) => {
          agg[curr.sourceName] = curr;
          return agg;
        },
        {}
      ),
    [filtersOptions]
  );

  useEffect(() => {
    if (Object.keys(pendingFilter).length === 0) {
      (selectRef.current! as RcSelectRef).rcSelect.setInputValue("");
    }
    if (pendingFilter.sourceName) {
      const attribute = attributeMap[pendingFilter.sourceName];
      if (!attribute) {
        return;
      }
      const operator = getDisplayedOperator(pendingFilter.operator);
      const value = pendingFilter.value || "";
      (selectRef.current! as RcSelectRef).rcSelect.setInputValue(
        `${attribute.name}${operator}${value}`
      );
    }
  }, [pendingFilter, attributeMap, selectRef]);

  // if only a column attribute is entered (with no value), autocomplete the selected attribute.
  const onSearchSelect = (_: any, option: ReactElement<OptionProps>): void => {
    if (!pendingFilter.sourceName) {
      const sourceName = !!option.key ? option.key.toString() : "";
      const attribute = attributeMap[sourceName];
      if (!sourceName || !attribute) return;
      setPendingFilter({
        ...pendingFilter,
        sourceName,
        attributeNameDraft: ""
      });
      return;
    } else if (!pendingFilter.operator) {
      const result = parseSearchText(option.key as string, attributeMap);
      if (result) {
        const [name, op, val] = result;
        // when value is null, we create a filter (not a pending filter)
        // with operator and value, since no value needs to be specified.
        if (val === null) {
          onFilter(
            filters.concat(
              toViewFilter({
                ...pendingFilter,
                sourceName: name,
                operator: op,
                value: val
              })
            )
          );
          setPendingFilter({});
        } else {
          setPendingFilter({
            ...pendingFilter,
            sourceName: name,
            operator: op,
            value: val
          });
        }
        return;
      }
    }

    if (isPendingFilterComplete(pendingFilter)) {
      onFilter(filters.concat(toViewFilter(pendingFilter)));
      setPendingFilter({});
    }
  };

  const onSearchDeselect = (value: any) => {
    onFilter(filters.filter(f => f.__clientId !== value.key));
  };

  const isSearchReady = !pendingFilter.sourceName && !pendingFilter.attributeNameDraft;

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isSearchReady) {
      onFilter(filters, true);
    } else if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onInputChange = (value: string) => {
    const __clientId =
      pendingFilter.__clientId || getUniqueFilterClientId("searchBarFilter");
    // the value could be blank if the user clicks into the select field without
    // selecting an option, or deletes the entire filter manually. In those cases,
    // remove the pending filter if it exists and is complete.
    if (value === "" && isPendingFilterComplete(pendingFilter)) {
      setPendingFilter({});
      return;
    }
    // if an attribute is specified but not the operator
    if (pendingFilter.sourceName && !pendingFilter.operator) {
      const attribute = attributeMap[pendingFilter.sourceName];
      // we expect the value to include the attribute name (not source name) once an attribute is selected
      // and is set on the pendingFilter.
      if (value.indexOf(attribute.name) > -1) {
        // parse text to see if an operator had been typed in (and not selected).
        // if there's a valid operator, set it on the pendingFilter.
        const result = parseSearchText(value, attributeMap);
        if (result) {
          setPendingFilter({
            ...pendingFilter,
            operator: result[1],
            value: result[2]
          });
        } else {
          // pendingFilter is up to date
          return;
        }
      } else {
        // if the value doesn't contain the attribute name, the user may have clicked backspace.
        // remove the sourceName and update the attributeNameDraft in that case.
        setPendingFilter({
          ...pendingFilter,
          sourceName: undefined,
          attributeNameDraft: value
        });
      }
    }
    // if an operator is also specified
    else if (pendingFilter.sourceName && pendingFilter.operator) {
      const displayedOperator = getDisplayedOperator(pendingFilter.operator);
      if (value.indexOf(displayedOperator!) === -1) {
        // this can occur if the user had selected an operator and then click backspace;
        // remove the operator in that case. Also clear the value because it is possible
        // for the user to have a pending operator and value and then selects both to
        // delete.
        setPendingFilter({
          ...pendingFilter,
          operator: undefined,
          value: undefined
        });
      } else {
        const filterValue = value.split(displayedOperator!)[1];
        // If user is pasting multiple characters (based on filterValue.length),
        // trim the value. This is due to bug where when a user copies a value
        // from a link, a space is inserted before the value upon paste
        // (ie. `id= 123456` instead of `id=123456`).
        // There can be use cases where a space is desired, so if a user
        // manually enters a space, we honor it.
        const val =
          pendingFilter.value === "" && filterValue.length > 1
            ? filterValue.trim()
            : filterValue;
        setPendingFilter({
          ...pendingFilter,
          value: val,
          __clientId
        });
      }
    }
    // if no pendingFilter has been specified, parse the value and set the pending filter based on parsed string
    else {
      const result = parseSearchText(value, attributeMap);
      if (result) {
        const [name, op, value] = result;
        setPendingFilter({
          sourceName: name,
          operator: op,
          value,
          __clientId
        });
      } else {
        setPendingFilter({ attributeNameDraft: value, __clientId });
      }
    }
  };

  return (
    <>
      <Select
        ref={selectRef}
        className="resourceSearchBar"
        dropdownClassName="resourceSearchBarDropdown"
        optionFilterProp="children"
        value={getSearchValues(filters, attributeMap).filter(Boolean)}
        onSelect={onSearchSelect}
        onDeselect={onSearchDeselect}
        onSearch={onInputChange}
        onInputKeyDown={onInputKeyDown}
        mode="multiple"
        placeholder="Search"
        autoClearSearchValue={false}
        notFoundContent={getNoMatchOption(filters, pendingFilter)}
        menuItemSelectedIcon={<span />}
        labelInValue
        data-test="table-filter"
        aria-autocomplete="none"
      >
        {getOptions(filters, pendingFilter, attributeMap, filtersOptions)}
      </Select>
    </>
  );
};

export default withErrorBoundary(SpaceTableSearchBar, { hideMessage: true });
