import React from "react";

import { Button, Table, Switch } from "antd";
import moment from "moment";
import Papa from "papaparse";
import styled from "styled-components";

import { BulkExecuteFunctionResult, SourceType } from "../../../../../../../types";
import { humanize } from "../../../../../../util";
import { assertNever } from "../../../../../../util/assertNever";
import humanizeList from "../../../../../../util/humanizeList";
import { BulkImportModalStepProps } from "../BulkImportModal";
import { ParamMapping } from "../reducer";

type Props = BulkImportModalStepProps;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-switch {
    margin-right: ${props => props.theme.spacersm};
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${props => props.theme.spacermd};

  button {
    margin-left: ${props => props.theme.spacersm};
  }
`;

const StyledTable = styled(Table)`
  overflow: auto;
  margin-top: ${props => props.theme.spacersm};

  .ant-table-tbody {
    tr.success > td,
    tr.success:hover > td {
      background-color: ${props => props.theme.fadedBrandColors.accentGreen};
    }

    tr.error > td,
    tr.error:hover > td {
      background-color: ${props => props.theme.fadedBrandColors.red};
    }

    tr.incomplete > td,
    tr.incomplete:hover > td {
      background-color: ${props => props.theme.fadedBrandColors.orange};
    }
  }
`;

type ResultRow = [string[], BulkExecuteFunctionResult];

function extractErrorMessage(result: BulkExecuteFunctionResult): string {
  switch (result.__typename) {
    case "ExecuteFunctionResultSuccess":
      return "";
    case "ValidationErrorResult":
      return humanizeList(result.messages.map(m => m.text));
    case "ClientErrorResult":
    case "PermissionErrorResult":
      return result.message;
    default:
      return assertNever(result);
  }
}

const CompleteStep = ({ state, sourceType, onComplete }: Props) => {
  const [showOnlyInvalid, setShowOnlyInvalid] = React.useState<boolean>(false);

  const hasResultErrors = React.useMemo(() => {
    return (
      state.results.find(r => r.__typename !== "ExecuteFunctionResultSuccess") ||
      state.results.length !== state.parsedRows.length
    );
  }, [state.results, state.parsedRows]);

  const columns = Object.entries(state.mappings).map(
    ([idx, fp]: [string, ParamMapping]) => ({
      title: humanize(fp.name),
      key: idx,
      render: (record: any) =>
        sourceType === SourceType.FILE
          ? record[idx] || "(Empty String)"
          : String(record[idx])
    })
  );

  if (hasResultErrors) {
    columns.push({
      title: "Error",
      key: "error",
      render: ({
        result
      }: Record<string, any> & { result: BulkExecuteFunctionResult }) => {
        return extractErrorMessage(result);
      }
    });
  }

  const rows = React.useMemo(() => {
    switch (sourceType) {
      case SourceType.FILE:
        return state.fileRows;
      case SourceType.BINDING:
        return state.bindingRows;
      default:
        throw new Error("unexpected bulk import source type");
    }
  }, [sourceType, state]);
  const rowsWithResults = React.useMemo((): ResultRow[] => {
    return rows.map((row, idx) => [row, state.results[idx]]);
  }, [rows, state.results]);
  const invalidRowsWithResults = React.useMemo((): ResultRow[] => {
    return rowsWithResults.filter(
      ([_, result]) => result && result?.__typename !== "ExecuteFunctionResultSuccess"
    );
  }, [rowsWithResults]);
  const incompleteRows = React.useMemo((): ResultRow[] => {
    return rowsWithResults.filter(([_, result]) => !result);
  }, [rowsWithResults]);

  const data = React.useMemo(() => {
    return showOnlyInvalid
      ? [...invalidRowsWithResults, ...incompleteRows]
      : rowsWithResults;
  }, [showOnlyInvalid, invalidRowsWithResults, incompleteRows, rowsWithResults]);

  const dataByIdx = React.useMemo(() => {
    return data.map(([row, result], rowIdx) => {
      const obj = Object.fromEntries(row.map((val, idx) => [String(idx), val]));
      (obj as any)._key = rowIdx;
      (obj as any).result = result;
      return obj;
    });
  }, [data]);

  const numErrors = invalidRowsWithResults.length;
  const numIncomplete = incompleteRows.length;
  const numComplete = rows.length - numErrors - numIncomplete;

  let copy = "";
  if (numIncomplete === 0) {
    if (numComplete === rows.length) {
      copy = "All rows have been completed successfully.";
    } else {
      copy = `This action could not be completed for ${numErrors} rows due to errors. Please download the file below, make appropriate corrections to your data, and retry the action for all incomplete rows.`;
    }
  } else {
    copy = `This action was cancelled part way through due to a connection issue or transient error. Please download the file below and retry the action for all incomplete rows.`;
  }

  const downloadIncomplete = React.useCallback(() => {
    const allIncomplete = [...invalidRowsWithResults, ...incompleteRows];
    const r = Papa.unparse(
      [
        state.fileHeader.concat("error"),
        ...allIncomplete.map(([row, result]) => row.concat(extractErrorMessage(result)))
      ],
      state.fileConfig
    );
    const element = document.createElement("a");
    const fileBlob = new Blob([r], { type: state.file?.type || "text/plain" });
    element.href = URL.createObjectURL(fileBlob);
    const fileNameParts = (state.file?.name || "results.txt").split(".", 2);
    const fileNameWithDate = [
      fileNameParts[0] + "-incompletes-" + moment().format("YYYY-MM-DD_HH-mm-ss"),
      fileNameParts[1]
    ].join(".");
    element.download = fileNameWithDate;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }, [
    invalidRowsWithResults,
    incompleteRows,
    state.file,
    state.fileHeader,
    state.fileConfig
  ]);

  return (
    <StyledRoot>
      <div>
        <h3>
          Completed {numComplete} / {rows.length}{" "}
        </h3>
        <p>{copy}</p>
        <div>
          <Switch onChange={checked => setShowOnlyInvalid(checked)} />
          <span>Show only rows with errors</span>
        </div>
        <StyledTable
          columns={columns}
          dataSource={dataByIdx}
          rowClassName={(record: any) =>
            record.result?.__typename !== "ExecuteFunctionResultSuccess"
              ? record.result?.__typename !== undefined
                ? "error"
                : "incomplete"
              : "success"
          }
          rowKey="_key"
        />
      </div>
      <StyledFooter>
        <Button type="primary" key="submit" onClick={onComplete}>
          Close
        </Button>
        {hasResultErrors && (
          <Button key="download" onClick={downloadIncomplete}>
            Download Incomplete
          </Button>
        )}
      </StyledFooter>
    </StyledRoot>
  );
};

export default CompleteStep;
