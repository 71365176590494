import { InputParameter } from "../../../../types";
import { MaterialIconType } from "../../../common/Icons/MaterialIcons";
import { FunctionNode } from "../../queries/useGetFunctionById";
import { QueueNode } from "../queries/common";
import {
  Field,
  Action,
  ActionOptions,
  State,
  Transition,
  Event,
  DispatcherType
} from "../types";

export enum ReducerActions {
  ADD_ACTION = "ADD_ACTION",
  ADD_FIELD = "ADD_FIELD",
  ADD_STATE = "ADD_STATE",
  ADD_TRANSITION = "ADD_TRANSITION",
  DELETE_ACTION = "DELETE_ACTION",
  DELETE_FIELD = "DELETE_FIELD",
  DELETE_SELECTED_STATE = "DELETE_SELECTED_STATE",
  DELETE_TRANSITION = "DELETE_TRANSITION",
  LOAD_EXECUTE_FUNCTION = "LOAD_EXECUTE_FUNCTION",
  LOAD_QUEUE = "LOAD_QUEUE",
  SELECT_STATE_INDEX = "SELECT_STATE_INDEX",
  TOGGLE_TRANSITION_EXPANDED = "TOGGLE_TRANSITION_EXPANDED",
  TOGGLE_STATE_AUTOMATIONS_EXPANDED = "TOGGLE_STATE_AUTIOMATIONS_EXPANDED",
  UPDATE_ACTION = "UPDATE_ACTION",
  UPDATE_CREATE_TASK_INPUT_PARAMETERS = "UPDATE_CREATE_TASK_INPUT_PARAMETERS",
  UPDATE_EXECUTE_FUNCTION_ID = "UPDATE_EXECUTE_FUNCTION_ID",
  UPDATE_ACTION_INPUT_PARAMETERS = "UPDATE_ACTION_INPUT_PARAMETERS",
  UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION",
  UPDATE_FIELD = "UPDATE_FIELD",
  UPDATE_FIELD_ORDER = "UPDATE_FIELD_ORDER",
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_SELECTED_STATE = "UPDATE_SELECTED_STATE",
  UPDATE_STATE_ORDER = "UPDATE_STATE_ORDER",
  UPDATE_TRANSITION = "UPDATE_TRANSITION",
  UPDATE_ICON = "UPDATE_ICON",
  UPDATE_COLOR = "UPDATE_COLOR"
}

export interface AddAction {
  type: ReducerActions.ADD_ACTION;
  payload: {
    dispatcherType: DispatcherType;
    dispatcherIndex: number;
    event: Event;
  };
}

export interface AddField {
  type: ReducerActions.ADD_FIELD;
}

export interface AddState {
  type: ReducerActions.ADD_STATE;
}

export interface AddTransition {
  type: ReducerActions.ADD_TRANSITION;
}

export interface DeleteAction {
  type: ReducerActions.DELETE_ACTION;
  payload: {
    actionIndex: number;
  };
}

export interface DeleteField {
  type: ReducerActions.DELETE_FIELD;
  payload: number;
}

export interface DeleteSelectedState {
  type: ReducerActions.DELETE_SELECTED_STATE;
  payload: {
    moveTasksToStateKey?: string; // May be undefined if there are no tasks for the state
  };
}

export interface DeleteTransition {
  type: ReducerActions.DELETE_TRANSITION;
  payload: string;
}

export interface LoadExecuteFunction {
  type: ReducerActions.LOAD_EXECUTE_FUNCTION;
  payload: {
    index: number;
    func: FunctionNode;
  };
}

export interface LoadQueue {
  type: ReducerActions.LOAD_QUEUE;
  payload: QueueNode;
}

export interface SelectStateIndex {
  type: ReducerActions.SELECT_STATE_INDEX;
  payload: number;
}

export interface ToggleTransitionExpanded {
  type: ReducerActions.TOGGLE_TRANSITION_EXPANDED;
  payload: string;
}

export interface ToggleStateassignmentExpanded {
  type: ReducerActions.TOGGLE_STATE_AUTOMATIONS_EXPANDED;
  payload: string; // The state key
}

export interface UpdateAction<T extends ActionOptions> {
  type: ReducerActions.UPDATE_ACTION;
  payload: {
    action: Partial<Action<T>>;
    index: number;
  };
}

export interface UpdateActionInputParameters {
  type: ReducerActions.UPDATE_ACTION_INPUT_PARAMETERS;
  payload: { index: number; inputParameters: InputParameter[] };
}

export interface UpdateCreateTaskInputParameters {
  type: ReducerActions.UPDATE_CREATE_TASK_INPUT_PARAMETERS;
  payload: { inputParameters: InputParameter[] };
}

export interface UpdateDescription {
  type: ReducerActions.UPDATE_DESCRIPTION;
  payload: string;
}

export interface UpdateExecuteFunctionId {
  type: ReducerActions.UPDATE_EXECUTE_FUNCTION_ID;
  payload: {
    index: number;
    functionId: string | null;
  };
}

export interface UpdateField {
  type: ReducerActions.UPDATE_FIELD;
  payload: {
    field: Partial<Field>;
    index: number;
  };
}

export interface ReorderPayload {
  sourceIndex: number;
  destinationIndex: number;
}

export interface UpdateFieldOrder {
  type: ReducerActions.UPDATE_FIELD_ORDER;
  payload: ReorderPayload;
}

export interface UpdateName {
  type: ReducerActions.UPDATE_NAME;
  payload: string;
}

export interface UpdateSelectedState {
  type: ReducerActions.UPDATE_SELECTED_STATE;
  payload: Partial<State>;
}

export interface UpdateStateOrder {
  type: ReducerActions.UPDATE_STATE_ORDER;
  payload: ReorderPayload;
}

export interface UpdateIcon {
  type: ReducerActions.UPDATE_ICON;
  payload: MaterialIconType;
}

export interface UpdateColor {
  type: ReducerActions.UPDATE_COLOR;
  payload: string;
}

type RequireOnly<T, K extends keyof T> = Partial<Omit<T, K>> & Required<Pick<T, K>>;

export interface UpdateTransition {
  type: ReducerActions.UPDATE_TRANSITION;
  payload: RequireOnly<Transition, "key">;
}

export type ReducerAction =
  | AddAction
  | AddField
  | AddState
  | AddTransition
  | DeleteAction
  | DeleteField
  | DeleteTransition
  | DeleteSelectedState
  | LoadExecuteFunction
  | LoadQueue
  | SelectStateIndex
  | ToggleTransitionExpanded
  | ToggleStateassignmentExpanded
  | UpdateAction<ActionOptions>
  | UpdateCreateTaskInputParameters
  | UpdateDescription
  | UpdateExecuteFunctionId
  | UpdateActionInputParameters
  | UpdateField
  | UpdateFieldOrder
  | UpdateName
  | UpdateSelectedState
  | UpdateStateOrder
  | UpdateTransition
  | UpdateIcon
  | UpdateColor;
