import React from "react";

import { Modal, Skeleton } from "antd";
import * as AntForm from "antd/lib/form/Form";
import styled from "styled-components";

import { SpaceComponentObject } from "../../../../../types";
import { SubTitle } from "../../../../common/Modal";
import {
  SpaceFunction,
  FunctionExecutionStatus
} from "../../../FunctionExecutor/FunctionExecutor";
import SpaceApi from "../../../SpaceApi";
import PermissionFeedback from "../common/PermissionFeedback";
import { InputParameter } from "../common/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import FunctionForm from "../SpaceFunctionForm/FunctionForm";

interface FunctionModalProps {
  form: AntForm.WrappedFormUtils<any>;
  func: SpaceFunction;
  prefillParams: Record<string, any>;
  funcParams: Record<string, any>;
  spaceApi: SpaceApi;
  spaceComponent: SpaceComponentObject;
  inputParameters: InputParameter[];
  loading: boolean;
  contentLoading?: boolean;
  emptyContent?: React.ReactNode;
  disabled: boolean;
  functionExecutionStatus: FunctionExecutionStatus;
  isValid: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function FunctionModalForm({
  form,
  func,
  prefillParams,
  funcParams,
  spaceApi,
  spaceComponent: { slug, properties },
  spaceComponent,
  inputParameters,
  disabled,
  loading,
  contentLoading,
  emptyContent = <Skeleton active />,
  functionExecutionStatus,
  isValid,
  onClose,
  onSubmit
}: FunctionModalProps) {
  const uiFormId = `${slug}-function-modal-form`;
  const submitDisabled = contentLoading || loading || disabled;

  const access = useFunctionAccess(func);

  return (
    <Modal
      visible={true}
      maskClosable={false}
      confirmLoading={loading}
      okText="Submit"
      okButtonProps={{
        form: uiFormId,
        htmlType: "submit",
        disabled: submitDisabled
      }}
      onCancel={onClose}
    >
      {contentLoading ? (
        emptyContent
      ) : (
        <>
          <Title>
            {properties.title}{" "}
            <PermissionFeedback
              attributes={func.functionAttributes}
              parameters={func.functionParameters.filter(fp =>
                inputParameters.some(ip => ip.name === fp.name)
              )}
              access={access}
            />
          </Title>
          {properties.instructions ? (
            <SubTitle>{properties.instructions}</SubTitle>
          ) : null}
          <FunctionForm
            formId={uiFormId}
            form={form}
            func={func}
            spaceApi={spaceApi}
            spaceComponent={spaceComponent}
            inputParameters={inputParameters}
            initialValues={prefillParams}
            values={funcParams}
            disabled={false}
            onSubmit={onSubmit}
            functionExecutionStatus={functionExecutionStatus}
            isValid={!submitDisabled && isValid}
          />
        </>
      )}
    </Modal>
  );
}

const Title = styled.h2`
  display: flex;
  align-items: center;
`;
