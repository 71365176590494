/**
 * PerspectiveTimestamp
 *
 * Displays a UTC ISO 8601 date string in a human readable format.
 * Time is displayed as relative time if it is more recent then
 * the absolute threshold. If it is older then the absolute threshold
 * then the absolute time is displayed omitting the time component.
 *
 * Relative timestamp units transitions defined are here:
 * https://momentjs.com/docs/#/displaying/fromnow/
 *
 * @param date string - ISO 8601 date string in UTC timezone.
 * @param absoluteThreshold number | undefined - Number of milliseconds to use as absolute threshold.
 *                                               Defaults to 5 days.
 * @param className string | undefined - CSS class name.
 * @returns Date - Date object in browser's local timezone.
 */

import React, { useEffect, useRef } from "react";

import Moment from "moment";

import { parseISODate } from "../utils";

// 5 days in milliseconds
const DEFAULT_ABSOLUTE_THRESHOLD = 60 * 60 * 24 * 5 * 1000;
interface PerspectiveTimestampProps {
  isoDate: string;
  absoluteThreshold?: number;
  className?: string;
}

export default function PerspectiveTimestamp({
  isoDate,
  absoluteThreshold = DEFAULT_ABSOLUTE_THRESHOLD,
  className
}: PerspectiveTimestampProps) {
  const momentDateRef = useRef(Moment(parseISODate(isoDate)));

  useEffect(() => {
    momentDateRef.current = Moment(parseISODate(isoDate));
  }, [isoDate]);

  const timestamp = parseInt(momentDateRef.current.format("x"), 10); // Unix timestamp in milliseconds
  const secondsAgo = Date.now() - timestamp;
  const isAbsolute = secondsAgo > absoluteThreshold;

  return (
    <div className={className} title={momentDateRef.current.toDate().toLocaleString()}>
      {isAbsolute
        ? momentDateRef.current.toDate().toLocaleDateString()
        : momentDateRef.current.fromNow()}
    </div>
  );
}
