import React from "react";

import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { findInvalidInputBindings } from "../../../../SpaceRoot/RenderTreeContext/util";
import { useStableSpaceContext } from "../../../../SpaceRoot/SpaceContext";
import { collectComponents } from "../../../../SpaceRoot/SpaceContext/SpaceContext";
import { ComponentConfigState } from "../../../../types";
import { fromComponents, findComponentNodeBySlug } from "../../../../util/tree";

export default function useConfigErrors(
  components: Record<string, ComponentConfigState>,
  componentTreeNodes: SpaceComponentObject[]
): Record<string, ConfigValidationError[]> {
  const renderTree = React.useMemo(
    () => fromComponents(componentTreeNodes),
    [componentTreeNodes]
  );

  // componentTreeNodes provides the SpaceComponentObject graph where
  // all parent / child relationships have been connected and must be
  // used to provide the component / config state passed to error selectors
  const componentLookup = React.useMemo(() => {
    const componentNodes = collectComponents(componentTreeNodes);
    return Object.fromEntries(componentNodes.map(cn => [cn.slug, cn]));
  }, [componentTreeNodes]);

  const { findSpaceComponentPackage } = useStableSpaceContext();

  const wrappedFindInvalidBindings = React.useCallback(
    (component: SpaceComponentObject) =>
      findInvalidInputBindings(
        findComponentNodeBySlug(renderTree, component.slug)!,
        renderTree
      ),
    [renderTree]
  );

  return React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(components).map(([key, val]) => {
          const pkg = findSpaceComponentPackage(val.draftComponent.type);
          if (!pkg)
            throw new Error(`Expected package for type: ${val.draftComponent.type}`);
          return [
            key,
            pkg.errorSelector(
              {
                ...val,
                draftComponent: componentLookup[val.draftComponent.slug]!
              },
              wrappedFindInvalidBindings,
              componentLookup
            )
          ];
        })
      ),
    [components, componentLookup, findSpaceComponentPackage, wrappedFindInvalidBindings]
  );
}
