import { useCallback, useEffect, useState } from "react";

import { isEqual } from "lodash";

import { useEvaluaterContext } from "../../../../../common/CodeSandbox/EvaluaterContext";

import {
  ConditionalOperator,
  ConditionalExpression,
  ManagedConditionalExpression
} from "./types";

const NULL_STRING_REGEX = /^\s*null\s*$/i;

const getString = (value: any) => {
  if (value === undefined || value === null || typeof value === "string") {
    return value;
  }
  // handle objects
  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  // handle all other types, ie. numbers, booleans
  return value.toString();
};

const isSubjectEqualToObject = (subject: any, object: any) => {
  if (isEqual(subject, object)) {
    return true;
  } else if (isNullLike(subject) && isNullLike(object)) {
    return true;
  } else if (typeof subject === "object" && object !== null) {
    // Do not JSON.parse strings that =~ the text "null"
    if (typeof object === "string" && object.match(NULL_STRING_REGEX)) {
      return false;
    } else {
      try {
        return isEqual(subject, JSON.parse(object as string));
      } catch {
        return false;
      }
    }
  }
  // handle all other types, ie. strings, numbers, booleans
  else {
    return subject?.toString() === object?.toString();
  }
};

const isNullLike = (subject: any) => subject === null || subject === undefined;

const doesSubjectIncludeObject = (subject: any, object: any) => {
  const subjectStr = getString(subject);
  const objectStr = getString(object);
  return typeof subjectStr === "string" && subjectStr.includes(objectStr);
};

function maybeCastToNumber(val: any): any {
  if (typeof val !== "string") return val;
  // If a toString after a parse still equals the orginal string
  // the val is a plain number
  const asFloat = parseFloat(val);
  if (asFloat.toString() === val) {
    return asFloat;
  }
  return val;
}

// Inequality operators are fairly good at handling heterogenous types
// but "1023" > 15 needs explicit handling.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Greater_than
export const evalInequalityCondition = (
  operator: ConditionalOperator,
  val1: any,
  val2: any
) => {
  // If both operands are *only* numbers cast them before comparing
  const _val1 = maybeCastToNumber(val1);
  const _val2 = maybeCastToNumber(val2);

  switch (operator) {
    case ConditionalOperator.LessThan:
      return _val1 < _val2;
    case ConditionalOperator.LessThanOrEqual:
      return _val1 <= _val2;
    case ConditionalOperator.GreaterThanOrEqual:
      return _val1 >= _val2;
    case ConditionalOperator.GreaterThan:
      return _val1 > _val2;
    default:
      throw new Error(`Unexpected operator: ${operator}`);
  }
};

// http://emailregex.com/
const EMAIL_REGEX =
  /* eslint-disable-next-line no-control-regex */
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

//  https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export function evaluateManagedConditional(
  conditional: ManagedConditionalExpression,
  subject: any,
  object?: any
) {
  const { operator } = conditional;
  switch (operator) {
    case ConditionalOperator.Equals:
      return isSubjectEqualToObject(subject, object);
    case ConditionalOperator.NotEquals:
      return !isSubjectEqualToObject(subject, object);
    case ConditionalOperator.IContains:
      return doesSubjectIncludeObject(subject, object);
    case ConditionalOperator.LessThan:
    case ConditionalOperator.LessThanOrEqual:
    case ConditionalOperator.GreaterThan:
    case ConditionalOperator.GreaterThanOrEqual:
      return evalInequalityCondition(operator, subject, object);
    case ConditionalOperator.IsNull:
      return isNullLike(subject);
    case ConditionalOperator.IsNotNull:
      return !isNullLike(subject);
    case ConditionalOperator.IsEmailAddress:
      return EMAIL_REGEX.test(subject);
    case ConditionalOperator.IsPhoneNumber:
      return PHONE_REGEX.test(subject);
    default: {
      return true;
    }
  }
}

export function useConditionEvaluator() {
  const { evaluate } = useEvaluaterContext();
  return useCallback(
    (conditionalExpression: ConditionalExpression, input: Record<string, any> | null) =>
      new Promise<Boolean>(async resolve => {
        try {
          if (conditionalExpression.type === "managed") {
            const managedEvals = [
              evaluate(conditionalExpression.subject_template, input)
            ];
            if (conditionalExpression.object_template) {
              managedEvals.push(evaluate(conditionalExpression.object_template, input));
            }
            const [subject, object] = await Promise.all(managedEvals);
            resolve(evaluateManagedConditional(conditionalExpression, subject, object));
          } else if (conditionalExpression.type === "evaluated") {
            const result = await evaluate(conditionalExpression.template, input);
            resolve(!!result);
          }
        } catch (e) {
          resolve(false);
          console.error(e);
        }
      }),
    [evaluate]
  );
}

export function useEvaluatedConditions(
  conditions: ConditionalExpression[],
  input: Record<string, any> | null
) {
  const [results, setResults] = useState<Boolean[] | null>(null);
  const evalCondition = useConditionEvaluator();
  useEffect(() => {
    const evalAll = async () => {
      const results = await Promise.all(
        conditions.map(condition => evalCondition(condition, input))
      );
      setResults(results);
    };
    evalAll();
  }, [conditions, input, evalCondition]);
  return results;
}
