import { assertNever } from "../../../../util/assertNever";
import { RequestBodyType } from "../constants";
import { formatKeyValuesForSubmit, getBodyFromGraphqlValues } from "../utils";

import { FormEncoding, serialize } from "./bodies/Form/utils"; // TODO: Should not depend on dependency at a lower level.
import { HttpFunctionState } from "./reducer";

interface ParameterMapping {
  method: string;
  path: string;
  query: string;
  header: string;
  json_body?: string;
  raw_body?: string;
  binary_body?: string;
  multipart_body?: string;
  form_body?: string;
}

export const createMapping = (s: HttpFunctionState): ParameterMapping => {
  const mapping: ParameterMapping = {
    method: JSON.stringify(s.method),
    path: s.path,
    query: formatKeyValuesForSubmit(s.urlParameters),
    header: formatKeyValuesForSubmit(s.headers)
  };
  switch (s.metadata.request_type) {
    case RequestBodyType.None:
      break;
    case RequestBodyType.GraphQL:
      mapping.json_body =
        s.body || getBodyFromGraphqlValues(s.graphqlQuery, s.graphqlVariables) || "{}";
      break;
    case RequestBodyType.JSON:
      mapping.json_body = s.body || "{}";
      break;
    case RequestBodyType.Raw:
      mapping.raw_body = s.body;
      break;
    case RequestBodyType.Binary:
      mapping.binary_body = s.body;
      break;
    case RequestBodyType.Form:
      const encoding = s.metadata.code?.encoding;
      if (!encoding) {
        break;
      }
      const body = () =>
        s.body ||
        (s.metadata.code?.fields && serialize(encoding, s.metadata.code.fields));

      switch (encoding) {
        case FormEncoding.URL:
          mapping.form_body = body();
          break;
        case FormEncoding.MULTIPART:
          mapping.multipart_body = body();
          break;
        default:
          assertNever(encoding);
      }
      break;
    default:
      assertNever(s.metadata.request_type);
  }
  return mapping;
};
