import React from "react";

import { Skeleton } from "antd";
import pluralize from "pluralize";

import { zIndex } from "../../../../cssConstants";
import { ModalNew } from "../../../common/Modal";
import { B3 } from "../../../common/StyledComponents";
import { toGlobalId } from "../../../util/graphql";
import { StateSelect } from "../../App/Queue/StateSelect/StateSelect";
import { State } from "../types";

import * as Styled from "./styledComponents";
import { useGetStateById } from "./useState";

interface Props {
  stateId?: string;
  states: State[];
  tasksMovingToState: boolean;
  onCancel: () => void;
  onComplete: (toStateId?: string) => void;
}

function getDeleteMessage(taskCount: number, tasksMovingToState: boolean): string {
  if (taskCount > 0) {
    const taskWord = pluralize("task", taskCount);

    return `This state currently has ${taskCount} ${taskWord}. 
    To delete this state, you must first move ${pluralize(
      "this",
      taskCount
    )} ${taskWord} into another state. 
    This action cannot be undone.`;
  }

  if (tasksMovingToState) {
    return `This state currently has tasks being moved to it.         
    To delete this state, you must first move these tasks into another state. 
    This action cannot be undone.`;
  }

  return "This state and all of its configuration will be deleted.";
}

export default function DeleteStateModal({
  stateId,
  states,
  tasksMovingToState,
  onCancel,
  onComplete
}: Props) {
  const [toStateKey, setToStateKey] = React.useState<string | undefined>();
  const { data, loading } = useGetStateById({
    variables: { id: toGlobalId("StateNode", stateId!) },
    skip: !stateId
  });

  const message = getDeleteMessage(data?.state.taskCount || 0, tasksMovingToState);

  const stateOptions = React.useMemo(() => {
    return states
      .filter(s => s.id !== stateId)
      .map(s => ({
        label: s.name,
        value: s.key,
        color: s.color
      }));
  }, [stateId, states]);

  const toState = states.find(s => s.key === toStateKey);
  const toStateOption = toState
    ? {
        label: toState.name,
        value: toState.key,
        color: toState.color
      }
    : undefined;

  const stateHasTasks =
    tasksMovingToState || !!(stateId && data && data?.state.taskCount > 0);

  const title = stateHasTasks ? "Move tasks to another state?" : "Are you sure?";

  const okText = stateHasTasks ? "Move tasks and delete state" : "Delete";

  return (
    <ModalNew
      title={title}
      okText={okText}
      width={600}
      onCancel={onCancel}
      okButtonProps={{
        disabled: loading || (stateHasTasks && !toStateKey)
      }}
      onOk={() => {
        // Require selecting a move-to state if deleting a state with tasks
        if (stateHasTasks && !toStateKey) {
          return;
        }

        onComplete(toStateKey);
      }}
      // Make z-index smaller than Dropdown select-portal so select appears over the content
      // and not behind it
      zIndex={zIndex.selectPortal - 1}
      visible
    >
      {loading && <Skeleton active />}
      {!loading && (
        <Styled.Content>
          <B3>{message}</B3>
          {stateHasTasks && (
            <>
              <Styled.Label>Move tasks to:</Styled.Label>
              <StateSelect
                value={toStateOption}
                options={stateOptions}
                onSelected={option => setToStateKey(option.value)}
              />
            </>
          )}
        </Styled.Content>
      )}
    </ModalNew>
  );
}
