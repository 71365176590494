import React from "react";

import CloseIconSvg from "../../../../../assets/icons/close-icon.svg";
import { colors } from "../../../../../cssConstants";
import { useRoles } from "../../../../common/Permissions/queries";
import {
  CloseIcon,
  HeaderName,
  StyledModal as BasePermissionsModal
} from "../../../../common/Permissions/styledComponents";
import { B2 } from "../../../../common/StyledComponents";

import { QueueRolePermissions } from "./Permissions";

export function PermissionsModal({
  queueName,
  queueSlug,
  onCancel
}: {
  queueName: string;
  queueSlug: string;
  onCancel: () => void;
}) {
  const { data, loading } = useRoles();
  const roles = data?.allRoles.edges.map(e => e.node);

  return (
    <BasePermissionsModal
      data-test="shareModal"
      visible
      width={"95vw"}
      confirmLoading={loading}
      closeIcon={<CloseIcon component={CloseIconSvg} />}
      backgroundColor={colors.newBackgroundSecondaryColor}
      hasFooter={false}
      header={
        <>
          <B2>Queues</B2>
          <HeaderName>{queueName}</HeaderName>
        </>
      }
      onCancel={() => onCancel()}
    >
      {roles && (
        <QueueRolePermissions
          queueName={queueName}
          queueSlug={queueSlug}
          roles={roles}
          onCancel={onCancel}
        />
      )}
    </BasePermissionsModal>
  );
}
