import React from "react";

import { Input } from "antd";

export type ChangeEvent = Omit<React.ChangeEvent<HTMLInputElement>, "target"> & {
  target: { value?: number };
};

interface Props {
  placeholder?: string;
  disabled?: boolean;

  value?: number;
  onChange?: (e: ChangeEvent) => void;
}

const InputNumber = ({ disabled, placeholder, value, onChange }: Props) => {
  return (
    <Input
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          const value = parseInt(e.target.value.replace(/\D/g, ""), 10);
          onChange({
            ...e,
            target: { value: isNaN(value) ? undefined : value }
          });
        }
      }}
    />
  );
};

export default InputNumber;
