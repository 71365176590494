import React from "react";

import styled from "styled-components";

import ChevronRight from "../../../assets/icons/chevron-right.svg";

export const AnimatedRotationIcon = styled.span`
  cursor: pointer;
  svg {
    transition: transform 0.3s;
    width: ${props => props.style?.width || "9px"}
    height: ${props => props.style?.height || "10px"}
  }
`;

interface Props {
  expanded: boolean;
  className?: string;
}

interface AnimatedChevronProps {
  rotation: number;
  className?: string;
}

export function AnimatedChevron({ rotation, className }: AnimatedChevronProps) {
  return (
    <AnimatedRotationIcon className={className}>
      <ChevronRight style={{ transform: `rotate(${rotation}deg)` }} />
    </AnimatedRotationIcon>
  );
}

// DownUpChevron switches between a chevron pointing
// * down (collapsed)
// * up (expanded)
// with a rotate animation
export function DownUpChevron({ expanded, className }: Props) {
  return <AnimatedChevron rotation={expanded ? 270 : 90} className={className} />;
}

// RightDownChevron switches between a chevron pointing
// * right (collapsed)
// * down (expanded)
// with a rotate animation
export function RightDownChevron({ expanded, className }: Props) {
  return <AnimatedChevron rotation={expanded ? 90 : 0} className={className} />;
}
