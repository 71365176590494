import * as React from "react";

import { Checkbox, Radio } from "antd";

import { quasi, unquasi } from "../../../../../../util/javascript";
import SingleLineEditor from "../../../../../SingleLineEditor";
import Repeat from "../../../../common/Repeat";
import { HttpCode } from "../../../../index";

import * as styled from "./styledComponents";
import { FormEncoding, Field, FieldType, serialize } from "./utils";

interface Props {
  code?: HttpCode;
  onChange?: (serialized: string, code?: HttpCode) => void;
}

const factory = (): Field => ({ key: "``", value: "``", type: FieldType.RAW });

export default function Form({ code = {}, onChange = () => null }: Props) {
  const encoding = code.encoding || FormEncoding.URL;
  const fields = code?.fields || [];

  const handleFieldsChange = (changed: Field[]) => {
    onChange(serialize(encoding, changed), {
      encoding,
      fields: changed
    });
  };

  const handleEncodingChange = (changed: FormEncoding) => {
    onChange(serialize(changed, fields), {
      encoding: changed,
      fields: fields
    });
  };

  return (
    <div>
      <styled.EncodingChoice>
        <div>Encoding:</div>
        <div>
          <Radio.Group
            value={encoding}
            onChange={e => handleEncodingChange(e.target.value)}
          >
            <Radio value={FormEncoding.URL}>URL Encoded</Radio>
            <Radio value={FormEncoding.MULTIPART}>Multipart</Radio>
          </Radio.Group>
        </div>
      </styled.EncodingChoice>

      <styled.Table>
        <thead>
          <styled.WideHeader>Name</styled.WideHeader>
          <styled.WideHeader>Value</styled.WideHeader>
          <styled.SmallHeader>File</styled.SmallHeader>
          <styled.SmallHeader>&nbsp;</styled.SmallHeader>
        </thead>
        <tbody>
          <Repeat
            factory={factory}
            createPrompt="Add field"
            items={fields.length ? fields : [factory()]}
            onChange={handleFieldsChange}
            render={(item, { update, DeleteButton }) => (
              <tr>
                <td>
                  <SingleLineEditor
                    data-test="editor-key"
                    placeholder="Enter name"
                    value={item.key}
                    onChange={value => update({ ...item, key: value })}
                  />
                </td>
                <td>
                  <SingleLineEditor
                    data-test="editor-value"
                    placeholder="Enter value"
                    mode={item.type === FieldType.FILE ? "javascript" : "jstl"}
                    value={item.value}
                    onChange={value => update({ ...item, value })}
                    togglable
                  />
                </td>
                <styled.SmallCell>
                  <Checkbox
                    data-test="editor-type"
                    checked={item.type === FieldType.FILE}
                    onChange={e =>
                      update({
                        ...item,
                        type: e.target.checked ? FieldType.FILE : FieldType.RAW,
                        value: e.target.checked
                          ? unquasi(item.value)
                          : quasi(item.value)
                      })
                    }
                  />
                </styled.SmallCell>
                <styled.SmallCell>
                  <DeleteButton />
                </styled.SmallCell>
              </tr>
            )}
          />
        </tbody>
      </styled.Table>
    </div>
  );
}
