import styled from "styled-components";

import _Icon from "../../../../../assets/spaceicons/function.svg";
import { ErrorIcon as _ErrorIcon } from "../../../../common/Icons";

export const ConfigRoot = styled.div`
  border: 1px dotted black;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
ConfigRoot.displayName = "ConfigRoot";

export const Icon = styled(_Icon)`
  height: 100%;
  width: 100%;
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
`;
Error.displayName = "Error";

export const ErrorIcon = styled(_ErrorIcon)`
  margin-right: ${props => props.theme.spacersm};
`;
