import React, { useEffect, useState } from "react";

import { Button, Icon, Upload } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { RcFile } from "antd/lib/upload/interface";

interface Props extends FormComponentProps {
  name: string;
  disabled?: boolean;
}

const FileUpload = ({ form, name, disabled }: Props) => {
  const [file, setFile] = useState<RcFile | undefined>();

  useEffect(() => {
    const formValue = form.getFieldValue(name);
    if (file) {
      file.text().then((text: string) => {
        if (formValue !== text) {
          form.setFieldsValue({ [name]: text });
        }
      });
    } else if (formValue !== "") {
      form.setFieldsValue({ [name]: "" });
    }
  }, [form, name, file]);

  const fileList = file
    ? [{ uid: file.uid, size: file.size, name: file.name, type: file.type }]
    : [];
  const buttonIcon = file ? "paper-clip" : "upload";
  const buttonText = file ? file.name : "Upload";
  return (
    <Upload
      disabled={disabled}
      fileList={fileList}
      showUploadList={false}
      beforeUpload={(file: RcFile) => {
        setFile(file);
        return false;
      }}
    >
      <Button disabled={disabled}>
        <Icon type={buttonIcon} /> {buttonText}
      </Button>
    </Upload>
  );
};

export default FileUpload;
