import { ErrorValues } from "../../../../../../constants";
import { FunctionParameterNode } from "../../../../../../types";
import { SpaceFunction } from "../../../../FunctionExecutor/FunctionExecutor";
import { InputParameter } from "../useFuncParams";

export interface AttributeAccess {
  attributeAllowed: (name: string) => boolean;
}

export interface ParameterAccess {
  parameterAllowed: (name: string) => boolean;
}

export interface Access extends AttributeAccess, ParameterAccess {}

export const isBoundParameterDenied = (
  inputParameters: InputParameter[],
  functionParameters: FunctionParameterNode[],
  values: Record<string, any>
) => {
  const lookup = functionParameters.reduce<{
    [k: string]: FunctionParameterNode;
  }>((acc, p) => {
    acc[p.name] = p;
    return acc;
  }, {});

  return inputParameters.some((ip: InputParameter) => {
    const fp = lookup[ip.name];
    return fp && fp.required && values?.[ip.name] === ErrorValues.permissionDenied;
  });
};

export const isRequiredParameterDenied = (
  inputParameters: InputParameter[],
  fn: SpaceFunction,
  access: ParameterAccess
) => {
  return fn.functionParameters.some(fp => {
    return (
      fp.required &&
      inputParameters.find(p => p.name === fp.name) &&
      !access.parameterAllowed(fp.name)
    );
  });
};
