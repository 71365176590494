import { get } from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";

import { TIME_SAVE_FORMAT, DATE_SAVE_FORMAT } from "../../../../../../constants";
import { TemplateInputParameter } from "../../../../../../types";
import { SpaceStateInputs } from "../../SpaceComponent";
import { evaluateTemplate } from "../../util";

import { ParameterType, InputParameter } from "./types";

export const GENERATED_DATE_TIME_PARAMETER_TYPES = [
  ParameterType.DATETIME_NOW,
  ParameterType.DATE_TODAY,
  ParameterType.TIME_NOW
];

export const isGeneratedParameter = (p: InputParameter) =>
  GENERATED_DATE_TIME_PARAMETER_TYPES.includes(p.type);

// ISO_8601
const DATE_FORMATS: Record<string, string> = {
  [ParameterType.DATETIME_NOW]: "",
  [ParameterType.TIME_NOW]: TIME_SAVE_FORMAT,
  [ParameterType.DATE_TODAY]: DATE_SAVE_FORMAT
};

export const fillParameterValues = (
  inputParameters: InputParameter[],
  inputState: SpaceStateInputs | null
) => {
  return inputParameters.reduce<{ [k: string]: any }>((memo, input) => {
    let value: any;
    switch (input.type) {
      case ParameterType.FILE:
      case ParameterType.PENDING:
        value = undefined;
        break;
      case ParameterType.DATE_TODAY:
      case ParameterType.DATETIME_NOW:
      case ParameterType.TIME_NOW:
        value = moment().format(DATE_FORMATS[input.type]);
        break;
      case ParameterType.BINDING:
      case ParameterType.COMPONENT:
        if (input.binding) {
          value = get(inputState, input.binding);
        }
        break;
      case ParameterType.TEMPLATE:
        if ("template" in input) {
          const templateInput = input as TemplateInputParameter;
          if (templateInput.template) {
            value = evaluateTemplate(
              templateInput.template.slice(1, templateInput.template.length - 1),
              inputState,
              { flattenInput: true }
            );
          }
        }
        break;
      case ParameterType.STATIC:
        value = input.value;
        break;
      case ParameterType.UUID:
        value = uuid();
        break;
      default:
        throw new Error("Unexpected parameter type");
    }
    memo[input.name] = value;
    return memo;
  }, {});
};
