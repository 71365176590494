import React, { useState, ReactNode } from "react";

import { Modal, Alert } from "antd";
import styled from "styled-components";

import { zIndex } from "../../../cssConstants";

interface Props {
  type: "success" | "info" | "warning" | "error";
  title: string;
  showIcon?: boolean;
  children?: ReactNode;
}

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0;

    // Ensure space for modal close button
    & > * {
      padding-right: 40px;
    }
  }
`;

const StyledAlert = styled(Alert)`
  &.ant-alert-warning {
    border-color: ${props => props.theme.warningBorderColor};

    .ant-alert-icon {
      color: ${props => props.theme.warningBorderColor};
    }
  }
`;

export default function AlertModal(props: Props) {
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  return (
    <StyledModal
      visible={modalOpen}
      mask={false}
      footer={null}
      onCancel={() => setModalOpen(false)}
      zIndex={zIndex.popupOverPopover}
    >
      <StyledAlert
        type={props.type}
        message={props.title}
        description={props.children}
        showIcon={props.showIcon}
      />
    </StyledModal>
  );
}
