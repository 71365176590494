import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "react-apollo";

import { QUEUE_NODE_FRAGMENT } from "../queries/common";
import { SaveQueueResult } from "../queries/SaveQueue";

export interface QueueSettingsInput {
  id: string;
  name: string;
  icon?: string;
  color?: string;
  description: string;
}

export interface Variables {
  queue: QueueSettingsInput;
}

export interface Data {
  updateQueueSettings: SaveQueueResult;
}

const UPDATE_QUEUE_SETTINGS = gql`
  mutation UpdateQueueSettings($queue: QueueSettingsInput!) {
    updateQueueSettings(queue: $queue) {
      __typename
      ...QueueNodeFragment
      ... on ValidationErrorResult {
        messages {
          text
          index
        }
      }
    }
  }
  ${QUEUE_NODE_FRAGMENT}
`;

export const useUpdateQueueSettings = (
  options?: MutationHookOptions<Data, Variables>
) => {
  return useMutation<Data, Variables>(UPDATE_QUEUE_SETTINGS, options);
};
