import { SpaceComponentObject } from "../../../../../../../types";
import { reportException } from "../../../../../../util/exceptionReporting";
import { ComponentConfigState } from "../../../../../types";
import { TreeNode } from "../reducer";

function _selectSpaceComponentTree(
  spaceComponents: Record<string, ComponentConfigState>,
  treeNode: TreeNode | null
): SpaceComponentObject | null {
  if (!treeNode || !treeNode.slug) return null;

  const componentNode = { ...spaceComponents[treeNode.slug]?.draftComponent };
  if (!componentNode) {
    reportException(new Error("Expected to find draftComponent for TreeNode"), {
      extra: {
        treeNode,
        componentSlugs: Object.entries(spaceComponents).map(([k]) => k)
      }
    });
  }
  componentNode.container = null;
  if (
    treeNode.container &&
    treeNode.container.slug !== null &&
    treeNode.container.slug !== "root"
  ) {
    const containerConfig = spaceComponents[treeNode.container.slug];
    if (!containerConfig) {
      reportException(
        new Error("Expected to find container component config for TreeNode"),
        {
          extra: {
            treeNode,
            componentSlugs: Object.entries(spaceComponents).map(([k]) => k)
          }
        }
      );
    } else {
      componentNode.container = {
        ...containerConfig.draftComponent
      };
    }
  }
  componentNode.componentTreeNodes = treeNode.treeNodes.map(
    ltn => _selectSpaceComponentTree(spaceComponents, ltn) as SpaceComponentObject
  );
  return componentNode;
}

export default function selectSpaceComponentTree(
  components: Record<string, ComponentConfigState>,
  treeNode: TreeNode | null
): SpaceComponentObject[] {
  return _selectSpaceComponentTree(components, treeNode)?.componentTreeNodes || [];
}
