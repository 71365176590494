import React from "react";

import * as AntForm from "antd/lib/form/Form";
import invariant from "invariant";
import { isEqual, omitBy, get } from "lodash";

import { createFunctionNode } from "../../../../../../__mocks__/factories";
import { ErrorValues, isBlankValue } from "../../../../../../constants";
import { SpaceComponentObject, SpaceFunctionType } from "../../../../../../types";
import usePrevious from "../../../../../common/hooks/usePrevious";
import { parsePath } from "../../../../../util/binding";
import {
  SpaceFunction,
  VoidFunction
} from "../../../../FunctionExecutor/FunctionExecutor";
import { BLANK_VALUE_TYPE_MAP } from "../../../constants";
import { useParamGenerationContext } from "../../../ParamGenerationContext/ParamGenerationContext";
import { useComponentPathContext } from "../../contexts/ComponentPathContext";
import { SpaceStateInputs } from "../../SpaceComponent";
import useFunctionAccess from "../useFunctionAccess";

import {
  InputParameter,
  InputParameterBinding,
  ParameterType,
  InputParameterComponent
} from "./types";
import { fillParameterValues, GENERATED_DATE_TIME_PARAMETER_TYPES } from "./util";

type Params = Record<string, any>;
export interface Result {
  prefillParams: Params;
  funcParams: Params;
  hasRequiredValues: boolean;
  hasValidValues: boolean;
  hasRequiredBindings: boolean;
  hasRequiredComponentValues: boolean;
  resetForm: () => void;
  getCurrentFuncParams: () => Params;
}

export const PIPELINE_FULFILLED_VALUE = Symbol("Pipeline fulfilled value.");
// HACK: Hopefully very unlikely string to be present in data source, function or param names.
//       This string is used to replace `.`s in field names as antd forms treat
//       dots in field names as indication that the fields value should be
//       nested within an object. We replace those dots with this string
//       and reverse that replacement when parsing the form values.
const ANTD_FORM_FIELD_NAME_DOT_REPLACEMENT = "_!&@||@&!_";
export const escapeParamName = (name: string) =>
  name.split(".").join(ANTD_FORM_FIELD_NAME_DOT_REPLACEMENT);
export const unescapeParamName = (name: string) =>
  name.split(ANTD_FORM_FIELD_NAME_DOT_REPLACEMENT).join(".");

export const transformBlank = (value: any, input: InputParameter) => {
  if (input.type === ParameterType.COMPONENT) {
    return value;
  }
  if (input.required) {
    invariant(
      !isBlankValue(value),
      `required InputParameter (${input.name}) cannot have a blank value`
    );
  }

  return !input.required && isBlankValue(value)
    ? BLANK_VALUE_TYPE_MAP[input.blank_value_type!]
    : value;
};

const EMPTY_STATE_CHANGES = {};

function selectInputChanges(
  input: SpaceStateInputs | null,
  lastInput: SpaceStateInputs | null | undefined
) {
  if (!input || isEqual(input, lastInput)) {
    return EMPTY_STATE_CHANGES;
  }

  // Build hash of the changed entries in space state input since last render
  let diff = Object.entries(input).reduce<Record<string, any>>((acc, [k, v]) => {
    if (lastInput && v === lastInput[k]) return acc;
    acc[k] = v;
    return acc;
  }, {});
  const missingInputKeys = Object.keys(lastInput || {}).filter(
    k => input[k] === undefined
  );
  // Fill empty strings for any input enteries which were present but are now gone
  diff = missingInputKeys.reduce<Record<string, any>>((acc, k) => {
    acc[k] = "";
    return acc;
  }, diff);
  return diff;
}

export default function useFuncParams(
  func: SpaceFunction | undefined,
  inputParams: InputParameter[],
  input: SpaceStateInputs | null,
  form?: AntForm.WrappedFormUtils<any>,
  spaceComponent?: SpaceComponentObject
): Result {
  const [pendingReset, setPendingReset] = React.useState(false);
  const access = useFunctionAccess(
    func ||
      new VoidFunction({
        type: SpaceFunctionType.VOID,
        ...createFunctionNode()
      })
  );
  const path = useComponentPathContext();
  const { stabilizeGeneratedParams } = useParamGenerationContext();

  const lastInput = usePrevious(input);
  const inputStateChanges = selectInputChanges(input, lastInput);

  // Apply form field values from their sources according this precedence order:
  // provided space state changes > form values > prefills > empty input configs
  const emptyParams = Object.fromEntries(
    inputParams.map(({ name }) => [name, undefined])
  );
  const prefillParams = fillParameterValues(inputParams, input);
  const getUnescapedFieldValues = () => {
    if (!form) return {};
    const fieldValuesEntries = Object.entries(form.getFieldsValue() || {});
    return fieldValuesEntries.reduce<Record<string, any>>((acc, [key, val]) => {
      acc[unescapeParamName(key)] = val;
      return acc;
    }, {});
  };
  // NOTE: its important that undefined keys be removed so that they don't overwrite
  const formParams = omitBy(getUnescapedFieldValues(), (v, _) => v === undefined);

  const stateParamChanges = fillParameterValues(
    inputParams.filter(
      p =>
        p.type === ParameterType.BINDING &&
        inputStateChanges.hasOwnProperty((p as InputParameterBinding).binding)
    ),
    inputStateChanges
  );

  const componentParamChanges = fillParameterValues(
    inputParams.filter(p => {
      return (
        p.type === ParameterType.COMPONENT &&
        p.binding !== undefined &&
        inputStateChanges.hasOwnProperty(p.binding)
      );
    }),
    inputStateChanges
  );

  const funcType = func?.type;
  const slug = React.useMemo(() => {
    const pathParts = parsePath(path);
    return pathParts[pathParts.length - 1];
  }, [path]) as string;
  const selfBindingSubPath = `${slug}.lastExecutionResult`;

  const getIsComponentFulfilledByPipeline = React.useCallback(
    (ip: InputParameterComponent) => {
      if (!spaceComponent) return false;
      const sc = spaceComponent.componentTreeNodes.find(
        sc => sc.slug === ip.component_slug
      );
      if (sc && sc.properties.default_value_type === "binding") {
        const binding = sc.properties.default_value_binding?.binding || "";
        if (binding.indexOf(selfBindingSubPath) > -1) {
          return true;
        }
      }
      return false;
    },
    [selfBindingSubPath, spaceComponent]
  );

  const getIsFulfilledByPipeline = React.useCallback(
    (ip: InputParameter) => {
      if (ip.type === ParameterType.COMPONENT) {
        return getIsComponentFulfilledByPipeline(ip);
      }
      return (
        funcType === SpaceFunctionType.PIPELINE &&
        ip.type === ParameterType.BINDING &&
        // TODO: BIDING parameter type is not accurate and binding may be undefined while configuring
        // so this explicit guard is required.
        ip.binding &&
        ip.binding.indexOf(selfBindingSubPath) >= 0
      );
    },
    [funcType, getIsComponentFulfilledByPipeline, selfBindingSubPath]
  );

  const fulfilledByPipelineParams = React.useMemo(() => {
    return inputParams.reduce<Record<string, typeof PIPELINE_FULFILLED_VALUE>>(
      (acc, curr) => {
        if (getIsFulfilledByPipeline(curr)) {
          acc[curr.name] = PIPELINE_FULFILLED_VALUE;
        }
        return acc;
      },
      {}
    );
  }, [getIsFulfilledByPipeline, inputParams]);

  const getIsFulfilledByFieldsetComponent = React.useCallback((ip: InputParameter) => {
    return ip.binding && ip.binding.indexOf("fieldset") >= 0;
  }, []);

  const functionParameters = func?.functionParameters || [];

  const functionParamPermissionMask = React.useMemo(() => {
    const functionParameters = func?.functionParameters || [];
    return Object.fromEntries(
      functionParameters
        .filter(fp => !access.parameterAllowed(fp.name))
        .map(fp => [fp.name, ErrorValues.permissionDenied])
    );
  }, [func?.functionParameters, access]);

  const requiredComponents = React.useMemo(() => {
    if (!spaceComponent) {
      return [];
    }
    return inputParams.reduce((agg, ip) => {
      if (ip.type !== ParameterType.COMPONENT) {
        return agg;
      }
      const sc = spaceComponent.componentTreeNodes.find(
        sc => sc.slug === ip.component_slug
      );
      const isFulfilledByPipeline = getIsComponentFulfilledByPipeline(ip);

      if (
        sc &&
        "allow_blank" in sc.properties &&
        !sc.properties.allow_blank &&
        !isFulfilledByPipeline
      ) {
        agg.push(sc.name);
      }
      return agg;
    }, [] as string[]);
  }, [spaceComponent, inputParams, getIsComponentFulfilledByPipeline]);

  React.useEffect(() => {
    const changedKeys = Object.keys(stateParamChanges);
    if (form && changedKeys.length > 0) {
      form.resetFields(changedKeys);
    }
  }, [form, stateParamChanges]);

  const resetParams = pendingReset
    ? {
        ...Object.fromEntries(Object.keys(emptyParams).map(k => [k, ""])),
        ...Object.fromEntries(
          Object.entries(prefillParams).filter(([_, v]) => v !== undefined)
        )
      } // Reset with empty strings and prefills that don't resolve to undefined
    : {};

  React.useEffect(() => {
    if (pendingReset) {
      form?.resetFields();
      setPendingReset(false);
    }
  }, [form, pendingReset]);

  let funcParams: { [key: string]: any } = {
    ...emptyParams,
    ...prefillParams,
    ...formParams,
    ...stateParamChanges,
    ...componentParamChanges,
    ...fulfilledByPipelineParams,
    ...resetParams
  };
  // Once a first pass of params has been calculated, use it to stabilize
  // generated params, as they only re-generate when non generated params
  // change.
  funcParams = {
    ...funcParams,
    ...stabilizeGeneratedParams(slug, inputParams, funcParams),
    ...functionParamPermissionMask
  };

  const resetForm = React.useCallback(() => {
    setPendingReset(true);
  }, [setPendingReset]);

  return {
    funcParams,
    prefillParams,
    hasRequiredValues: inputParams.every(ip => {
      const fp = functionParameters.find(fp => fp.name === ip.name);
      // No function parameter? Always return false
      if (!fp) return false;

      const functionParamPermission = functionParamPermissionMask[fp.name];

      // If the user does not have access to the value, check parameter requiredness
      // and handle special cases related to bindings.
      if (funcParams[fp.name] === ErrorValues.permissionDenied) {
        // When the parameter is a binding with no permission but
        // the user has access to the function parameter allow submission
        // when the field is visible and allows blanks.
        if (
          !ip.hidden &&
          (ip.type === "binding" || ip.type === "component") &&
          functionParamPermission !== ErrorValues.permissionDenied
        ) {
          return !ip.required;
        }

        // If the parameter isn't required and the user doesn't have access to the value anyway, ignore it
        if (!fp.required) return true;
      }

      // Check whether the parameter allows blanks
      if (
        ip.type !== ParameterType.FILE &&
        ip.type !== ParameterType.COMPONENT &&
        ip.required &&
        isBlankValue(funcParams[ip.name])
      ) {
        return false;
      }

      // Finally, check function parameter requiredness:
      //
      // 1. If the parameter isn't required, always return true
      // 2. If it is required, check that some value exists (even if undefined/a blank value)
      return (
        !fp.required ||
        (fp.required && funcParams[fp.name] !== ErrorValues.permissionDenied)
      );
    }),
    hasValidValues: inputParams.every(ip => {
      if (ip.type === ParameterType.FILE) return true;
      if (ip.type === ParameterType.COMPONENT) {
        return ip.error?.binding ? !get(input, ip.error?.binding) : true;
      }
      if (ip.render_options) {
        return (
          !!ip.render_options.find(o => o.value === funcParams[ip.name]) ||
          (!ip.required && isBlankValue(funcParams[ip.name]))
        );
      }
      return true;
    }),
    hasRequiredBindings: inputParams
      .filter(ip => ip.hidden && ip.type === ParameterType.BINDING)
      .every(
        ip =>
          (!!input && input.hasOwnProperty((ip as InputParameterBinding).binding)) ||
          getIsFulfilledByPipeline(ip) ||
          getIsFulfilledByFieldsetComponent(ip)
      ),
    hasRequiredComponentValues: inputParams
      .filter(ip => ip.type === ParameterType.COMPONENT)
      .every(ip => {
        const fp = functionParameters.find(fp => fp.name === ip.name);
        if (!fp) return false;
        const functionParamPermission = functionParamPermissionMask[fp.name];
        // if user does not have perms but function param is not required, omit field and allow user to submit form
        if (functionParamPermission === ErrorValues.permissionDenied && !fp.required) {
          return true;
        }

        if (ip.type === ParameterType.COMPONENT) {
          const value = ip.binding ? get(input, ip.binding) : undefined;

          if (requiredComponents.includes(ip.name) && isBlankValue(value, true)) {
            return false;
          }
        }
        return true;
      }),
    resetForm,
    getCurrentFuncParams: () => {
      const generateDateTimeAndComponentParams = inputParams.filter(
        p =>
          GENERATED_DATE_TIME_PARAMETER_TYPES.includes(p.type) ||
          p.type === ParameterType.COMPONENT
      );

      const currentFuncParams = {
        ...funcParams,
        ...(form ? getUnescapedFieldValues() : {}),
        ...fillParameterValues(generateDateTimeAndComponentParams, input),
        ...fulfilledByPipelineParams,
        ...functionParamPermissionMask
      };
      return inputParams
        .filter(ip => {
          // If the user doesn't have access to the value, just don't include it
          if (currentFuncParams[ip.name] === ErrorValues.permissionDenied) return false;
          return true;
        })
        .reduce(
          (acc, ip) => ({
            ...acc,
            [ip.name]: transformBlank(currentFuncParams[ip.name], ip)
          }),
          {}
        );
    }
  };
}
