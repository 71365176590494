import { SpaceConfigState } from "..";
import { SpaceComponentPackage, SpaceComponentType } from "../../../../../../../types";

const GENERATED_COMPONENT_TYPES = ["HEADER", "PARAMS"];

export interface SpaceError {
  NAME: string[];
  COMPONENT: string[];
  PENDING_CHANGES: string[];
}

export const getFirstSpaceError = (e: SpaceError): string => {
  if (e.NAME.length > 0) return e.NAME[0];
  if (e.COMPONENT.length > 0) return e.COMPONENT[0];
  if (e.PENDING_CHANGES.length > 0) return e.PENDING_CHANGES[0];
  return "";
};

export default function getSpaceErrors(
  state: SpaceConfigState,
  findSpaceComponentPackage: (
    t: SpaceComponentType
  ) => SpaceComponentPackage | undefined,
  componentsWithErrors: string[]
): SpaceError {
  const errors: SpaceError = { NAME: [], COMPONENT: [], PENDING_CHANGES: [] };
  if (!state?.name) {
    errors["NAME"].push(
      "It looks like your space is missing a name. Please add a name before saving."
    );
  }

  if (state?.name?.toLowerCase() === "new") {
    errors["NAME"].push(
      "Please rename your space before saving. 'New' is not a valid name."
    );
  }

  const components = Object.values(state.components).map(c => c.draftComponent);

  const header = components.find(c => c.type === "HEADER");
  if (!!header && !header?.properties.text) {
    errors["COMPONENT"].push(
      "Your space contains a Header with empty text. Please provide text for your header before saving."
    );
  }

  // ensure one component is present aside from header
  const manuallyAddedComponent = components.find(
    c =>
      !GENERATED_COMPONENT_TYPES.includes(c.type) &&
      !findSpaceComponentPackage(c.type)?.isClientProvidedComponent
  );
  if (!manuallyAddedComponent)
    errors["COMPONENT"].push(
      "It looks like your space is empty. You can't save a space until you've added at least one component."
    );

  components.forEach(c => {
    const { validateComponent, displayName } = findSpaceComponentPackage(c.type) || {};

    if (validateComponent && !validateComponent(c)) {
      errors["COMPONENT"].push(
        `Your space contains an invalid ${displayName} component. Please fix this issue before saving.`
      );
    }
  });

  if (Object.keys(state.apiErrors).length > 0 || componentsWithErrors.length > 0) {
    errors["COMPONENT"].push(
      "It looks like some of the components in this space are not configured correctly. Please review each marked component and resolve any problems."
    );
  }

  return errors;
}
