import React from "react";

import { Icon } from "antd";
import { ColumnInstance } from "react-table";
import styled from "styled-components";

interface Props {
  column: ColumnInstance;
}

const Root = styled.div`
  &.sortable:hover {
    background-color: rgb(231, 231, 231);
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  margin-left: ${props => props.theme.spacersm};

  & > :first-child {
    margin-bottom: -4px;
  }

  &.asc > :first-child {
    color: ${props => props.theme.primaryColor};
  }

  &.desc > :last-child {
    color: ${props => props.theme.primaryColor};
  }
`;

const SortIcon = styled(Icon)`
  font-size: ${props => props.theme.tinyFontSize};
  color: ${props => props.theme.greyBackgroundColor};
`;

export default function TableHeader({ column }: Props) {
  return (
    <Root className={column.canSort ? "sortable" : ""}>
      <Container>
        <span>{column.render("Header")}</span>
        {column.canSort && (
          <IconContainer
            className={column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : ""}
          >
            <SortIcon type="caret-up" />
            <SortIcon type="caret-down" />
          </IconContainer>
        )}
      </Container>
    </Root>
  );
}
