import React from "react";

import { message } from "antd";
import moment from "moment";

import LicenseExpired from "./components/license/LicenseExpired";
import LicenseMissing from "./components/license/LicenseMissing";

export interface License {
  isExpired?: boolean;
  hasSeats: boolean;
  usedSeats: number;
  expiresAt?: string;
  seats: number;
  domains?: string[];
  status?: string;
}

export interface ConfigContextType {
  isOnPrem: boolean;
  isGoogleOAuthEnabled: boolean;
  stripePublicKey: string;
  license: License | null;
}

const ConfigContext = React.createContext<ConfigContextType>({
  isOnPrem: false,
  isGoogleOAuthEnabled: false,
  stripePublicKey: "",
  license: null
});

export interface ConfigContextProviderProps {
  children: React.ReactNode;
  value: ConfigContextType;
}

export function ConfigContextProvider({ value, children }: ConfigContextProviderProps) {
  const { isOnPrem, license } = value;

  // license expiration management
  React.useEffect(() => {
    if (license === null || license.expiresAt === null) return;
    const expiration = moment(license.expiresAt);
    const isExpiringSoon =
      !license.isExpired && expiration.diff(moment.utc(), "days") <= 7;
    const content = (
      <>
        Your license will expire {expiration.fromNow()}.{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://internal.io/contact">
          Contact Internal
        </a>{" "}
        to renew.
      </>
    );
    if (isExpiringSoon) message.warning(content, 10);
  }, [license]);

  if (isOnPrem) {
    if (license === null || license.status === "invalid") return <LicenseMissing />;
    if (license.isExpired) return <LicenseExpired license={license} />;
  }

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
}

export const useConfigContext = () =>
  React.useContext<ConfigContextType>(ConfigContext);
