import React from "react";

import { Select, Tooltip, Radio } from "antd";

import { SortByOption } from "../../../../../../types";
import { Field } from "../../common/ConfigPanel/styledComponents";
import { AttributeColumn, isAttributeColumn } from "../ColumnListManager";
import { useComponentConfigContext } from "../ComponentConfigContext";

interface Props {
  sortByOptions: SortByOption[];
}

export default function ColumnOrder({ sortByOptions }: Props) {
  const { state, dispatch } = useComponentConfigContext();
  const {
    draftComponent: { properties }
  } = state;
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const columns: AttributeColumn[] = properties.columns?.filter(isAttributeColumn);
  const sortedOptions = React.useMemo(() => {
    return [...sortByOptions].sort((a, b) => (a.name < b.name ? -1 : 1));
  }, [sortByOptions]);

  if (!columns) return null;

  const currentOrder = properties.order?.length > 0 ? properties.order[0] : undefined;

  return (
    <>
      <Field>
        <Tooltip
          placement="right"
          onVisibleChange={(v: boolean) => setTooltipVisible(v)}
          visible={tooltipVisible && sortByOptions.length === 0}
          title="None of the columns included support ordering"
        >
          <Select
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            data-test="attributeSortSelect"
            disabled={sortByOptions.length === 0}
            onChange={(val: string | -1) => {
              const value =
                val === -1
                  ? []
                  : [
                      {
                        direction: currentOrder?.direction || "asc",
                        attribute: val
                      }
                    ];
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.order",
                  value
                }
              });
            }}
            value={!!currentOrder ? currentOrder.attribute : undefined}
            placeholder="No default order"
          >
            {currentOrder && (
              <Select.Option key="-1" value={-1}>
                No default order
              </Select.Option>
            )}
            {sortedOptions.map((attr: SortByOption) => (
              <Select.Option key={attr.sourceName} value={attr.sourceName}>
                {attr.sourceName}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
      </Field>
      {currentOrder && currentOrder.attribute && (
        <Field>
          <Radio.Group
            data-test="sortDirection"
            onChange={e =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.order[0].direction",
                  value: e.target.value
                }
              })
            }
            value={currentOrder.direction}
          >
            <Radio value={"asc"}>Ascending</Radio>
            <Radio value={"desc"}>Descending</Radio>
          </Radio.Group>
        </Field>
      )}
    </>
  );
}
