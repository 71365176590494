import React, { ReactNode, useState } from "react";

import { FileObject } from "../../../../types";
import FileReaderInput from "../../FileReaderInput";
import { AttributeValueInputProps } from "../index";

export default function BinaryInput({
  fieldOptions,
  form,
  sourceName,
  value
}: AttributeValueInputProps) {
  const decorator = form
    ? form.getFieldDecorator(sourceName, {
        ...fieldOptions,
        initialValue: value,
        getValueFromEvent: (result: FileObject | null) => {
          return result?.data;
        }
      })
    : (comp: ReactNode) => comp;

  return <>{decorator(<BindableFileReaderInput />)}</>;
}

const fileFromData = (data?: string): FileObject | null =>
  data ? { size: 0, name: "(bound data)", data, type: "unknown" } : null;

interface BindableFileReaderInputProps {
  onChange?: (result: FileObject | null) => void;
  value?: string;
  onFocus?: () => {};
  onBlur?: () => {};
}

const BindableFileReaderInput = ({
  onChange,
  value,
  onFocus,
  onBlur
}: BindableFileReaderInputProps) => {
  const [currentFile, setCurrentFile] = useState<FileObject | null>(
    fileFromData(value)
  );

  const _onChange = (result: FileObject | null) => {
    setCurrentFile(result);
    onChange && onChange(result);
  };

  let file: FileObject | null = null;
  if (value && value === currentFile?.data) {
    file = currentFile;
  } else if (value) {
    file = fileFromData(value);
  }
  return (
    <FileReaderInput
      onChange={_onChange}
      value={file}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
