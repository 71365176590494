import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";
import { ConditionalExpressionType, useEvaluatedConditions } from "../Conditionals";

const emptyRules: ConditionalExpressionType[] = [];
export default function useIsComponentVisible({
  visibility_rules = emptyRules
}: {
  visibility_rules: ConditionalExpressionType[] | undefined;
}) {
  const { editMode } = useStableSpaceContext();
  const { input } = useComponentStateContext();
  const evaluatedConditions = useEvaluatedConditions(visibility_rules, input);

  if (editMode || !visibility_rules.length) return true;

  if (evaluatedConditions === null) return false;

  return evaluatedConditions.every(r => r);
}
