import React, { useEffect, useReducer, useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { Checkbox, Select } from "antd";

import { FunctionValidationStatus } from "../../..";
import { BaseFunctionName, FiltersOption } from "../../../../../../types";
import AttributeFilters from "../../../../AttributeFilters";
import { ErrorMessageField } from "../../../common/ErrorMessageField";
import { BaseFunctionProps } from "../../common";
import { SqlWriteFunctionName } from "../../constants";
import * as common from "../../styledComponents";
import { GeneralActionTypes } from "../../types";
import { isFunctionValid } from "../../utils";
import {
  DeleteBaseFunctionParameterMapping,
  InsertBaseFunctionParameterMapping,
  UpdateBaseFunctionParameterMapping
} from "../SqlActionForm";

import {
  FETCH_RESOURCES_BY_DATA_SOURCE_ID,
  FetchResourcesAndAttributesByDataSourceIdData,
  SqlActionResourceNode
} from "./queries";
import reducer, { getInitialState } from "./reducer";
import { format, getFunctionErrors } from "./selectors";

const { Option } = Select;

export type WriteBaseFunctionParameterMapping =
  | InsertBaseFunctionParameterMapping
  | UpdateBaseFunctionParameterMapping
  | DeleteBaseFunctionParameterMapping;

interface Props extends BaseFunctionProps {
  key: any;
  actionType: SqlWriteFunctionName;
}

export const WriteForm = ({
  actionType,
  selectedDataSource,
  func,
  setBaseConfig,
  setValidationStatus,
  showErrors
}: Props) => {
  const initialState = getInitialState(actionType, func);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [filtersOptions, setFiltersOptions] = useState<FiltersOption[]>([]);
  const [resources, setResources] = useState<SqlActionResourceNode[]>([]);

  const { resourceSourceId, errorMessages, filters, changeSet, allowMultiple } = state;

  // update `baseFunctionParameterMapping` and errors on relevant field updates
  useEffect(() => {
    const baseFunctionParameterMapping = format(
      resourceSourceId,
      actionType,
      changeSet,
      filters,
      allowMultiple
    );

    setBaseConfig(baseFunctionParameterMapping);
    const errors = getFunctionErrors({
      resourceSourceId,
      actionType,
      filters,
      changeSet
    });
    dispatch({
      type: GeneralActionTypes.SET_ERROR_MESSAGES,
      payload: {
        errorMessages: errors
      }
    });
    setValidationStatus(
      isFunctionValid(errors)
        ? FunctionValidationStatus.VALID
        : FunctionValidationStatus.INVALID
    );
  }, [
    resourceSourceId,
    actionType,
    filters,
    changeSet,
    allowMultiple,
    setBaseConfig,
    setValidationStatus
  ]);

  const { data, loading, error } =
    useQuery<FetchResourcesAndAttributesByDataSourceIdData>(
      FETCH_RESOURCES_BY_DATA_SOURCE_ID,
      {
        variables: {
          dataSourceNodeId: selectedDataSource.id
        }
      }
    );

  useEffect(() => {
    const resources = data ? data.dataSource.resources.edges.map(e => e.node) : [];
    setResources(resources);

    const selectedResource = resources.find(
      resource => resource.sourceIdentifier === resourceSourceId
    );

    setFiltersOptions(selectedResource?.metadata.filters.options || []);
  }, [data, resourceSourceId]);

  if (error) throw error;
  if (loading) {
    return (
      <common.GridFormWideItem>
        <common.Skeleton active />
      </common.GridFormWideItem>
    );
  }

  return (
    <>
      <common.GridLabel>Resource:</common.GridLabel>
      <common.GridFormItem vertical>
        <common.Select
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          data-test="resourceSelect"
          placeholder="Select a resource"
          value={resourceSourceId}
          onChange={value => {
            dispatch({
              type: GeneralActionTypes.SET_FIELD_VALUE,
              payload: {
                fieldName: "resourceSourceId",
                fieldValue: value
              }
            });
          }}
        >
          {resources.map(resource => (
            <Option value={resource.sourceIdentifier} key={resource.sourceIdentifier}>
              {resource.name}
            </Option>
          ))}
        </common.Select>
        <ErrorMessageField
          errorMessage={showErrors ? errorMessages.resourceSourceId : ""}
        />
      </common.GridFormItem>

      {resourceSourceId && (
        <>
          {(actionType === BaseFunctionName.UPDATE ||
            actionType === BaseFunctionName.DELETE) && (
            <>
              <common.GridLabel>Filter by:</common.GridLabel>
              <common.GridFormItem vertical>
                <AttributeFilters
                  className="sqlActionFilters"
                  value={filters}
                  filtersOptions={filtersOptions}
                  showOperator={true}
                  onChange={value => {
                    dispatch({
                      type: GeneralActionTypes.SET_FIELD_VALUE,
                      payload: {
                        fieldName: "filters",
                        fieldValue: value
                      }
                    });
                  }}
                />
                <ErrorMessageField
                  errorMessage={showErrors ? errorMessages.filters : ""}
                />
              </common.GridFormItem>
            </>
          )}

          {(actionType === BaseFunctionName.INSERT ||
            actionType === BaseFunctionName.UPDATE) && (
            <>
              <common.GridLabel>Set:</common.GridLabel>
              <common.GridFormItem vertical>
                <AttributeFilters
                  className="sqlActionChangeSet"
                  value={changeSet}
                  filtersOptions={filtersOptions}
                  showOperator={false}
                  filterUsedOptions={true}
                  itemName="field"
                  onChange={value => {
                    dispatch({
                      type: GeneralActionTypes.SET_FIELD_VALUE,
                      payload: {
                        fieldName: "changeSet",
                        fieldValue: value
                      }
                    });
                  }}
                />
                <ErrorMessageField
                  errorMessage={showErrors ? errorMessages.changeSet : ""}
                />
              </common.GridFormItem>
            </>
          )}

          {(actionType === BaseFunctionName.UPDATE ||
            actionType === BaseFunctionName.DELETE) && (
            <>
              <common.GridLabel></common.GridLabel>
              <common.GridFormItem vertical>
                <Checkbox
                  data-test="allowMultipleCheckbox"
                  checked={allowMultiple}
                  onChange={e => {
                    dispatch({
                      type: GeneralActionTypes.SET_FIELD_VALUE,
                      payload: {
                        fieldName: "allowMultiple",
                        fieldValue: e.target.checked
                      }
                    });
                  }}
                >
                  Allow multiple records to be {actionType}d
                </Checkbox>
              </common.GridFormItem>
            </>
          )}
        </>
      )}
    </>
  );
};
