import { Input as AntInput } from "antd";
import styled from "styled-components";

import { AttributeTypeSelect } from "../../../common/AttributeTypeSelect";
import * as common from "../common/styledComponents";

export const HeaderRow = styled.div`
  display: flex;
  width: 100%;
`;

export const Label = styled(common.Label)`
  width: 50%;

  &:nth-child(2) {
    margin-right: 6px;
  }
  &:nth-child(3) {
    margin-left: 6px;
  }
`;

export const RequiredLabel = styled(common.Label)`
  display: flex;
  justify-content: center;
  width: 75px;
`;

export const RequiredColumn = styled.div`
  display: flex;
  justify-content: center;
  width: 75px;
`;

export const FieldRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: ${props => props.theme.spacermd};

  background-color: ${props => props.theme.containerPrimary};
  border-radius: 4px;
`;

export const Input = styled(AntInput)`
  width: 50%;
  margin-right: 6px;
`;

export const StyledTypeSelect = styled(AttributeTypeSelect)`
  width: 50%;
  margin-left: 6px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`;

export const Placeholder = styled.div`
  width: 40px;
`;
