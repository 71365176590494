import { Binding, BindingShape } from "../../../../../../types";
import { getOption, Option } from "../../../../../common/BindingCascader";
import { ComponentNode, RootNode } from "../../../RenderTreeContext";

interface PackageLike {
  allowSelfBinding: boolean;
  allowAncestorBinding: boolean;
  isPseudoComponent?: boolean;
  getSchema: (node: ComponentNode) => Binding[];
  getSelfSchemaShape?: (node: ComponentNode) => BindingShape;
}

export type SchemaGenerator = (node: ComponentNode) => Binding[];
export type PackageLocator = (node: ComponentNode) => PackageLike;
export type LabelProvider = (node: ComponentNode, isAncestor: boolean) => string;
export type ValueProvider = (node: ComponentNode, isAncestor: boolean) => string;

export const getOptions = (
  node: ComponentNode | undefined,
  findPackage: PackageLocator,
  label: LabelProvider,
  value: ValueProvider = c => c.component.slug
): Option[] => {
  if (!node) return [];

  const root = getRootNode(node);
  const ancestorPath = hasParentComponent(node) ? getAncestors(node, root) : [];

  const pkg = findPackage(node);
  const excludes: ComponentNode[] = pkg.allowSelfBinding ? [] : [node];

  function _getSchema(n: ComponentNode) {
    const pkg = findPackage(n);
    if (pkg === undefined) return [];
    return pkg.getSchema(n);
  }

  function _getComponentNodeShape(n: ComponentNode) {
    const pkg = findPackage(n);
    if (pkg === undefined) return BindingShape.OBJECT;
    return pkg.getSelfSchemaShape ? pkg.getSelfSchemaShape(n) : BindingShape.OBJECT;
  }

  const ancestorOptions = ancestorPath
    .filter(c => {
      const ancestorPkg = findPackage(c);
      return !excludes.includes(c) && ancestorPkg.allowAncestorBinding;
    })
    .map<Option>(ancestor => ({
      label: label(ancestor, true),
      value: value(ancestor, true),
      bindingShape: _getComponentNodeShape(ancestor),
      children: _getSchema(ancestor).map(getOption)
    }));

  const rootOptions = root.children
    .filter(c => !excludes.includes(c) && !ancestorPath.includes(c))
    .map<Option>(child => ({
      label: label(child, false),
      value: value(child, false),
      bindingShape: _getComponentNodeShape(child),
      children: _getSchema(child).map(getOption)
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return [...ancestorOptions, ...rootOptions];
};

const hasParentComponent = (node: ComponentNode | RootNode): boolean =>
  !!(node.parent && (node.parent as ComponentNode).component);

const getRootNode = (node: ComponentNode | RootNode | undefined): RootNode => {
  let n = node;
  while (n?.parent) {
    n = n.parent as ComponentNode | RootNode;
  }
  return n!;
};

// Returns ancestor path array inclusive of node and exclusive of stopNode: [node...stopNode)
const getAncestors = (
  node: ComponentNode | RootNode,
  stopNode: ComponentNode | RootNode
): ComponentNode[] => {
  const path = [];
  for (let n = node; n && n !== stopNode; n = n.parent as ComponentNode) {
    path.push(n as ComponentNode);
  }
  return path;
};
