import { Input } from "antd";

import withDebouncedValue from "./withDebouncedValue";

const selectOnChangeValue = (evt: any) => evt.target.value;

export default withDebouncedValue(Input, { selectOnChangeValue });

export const DebouncedTextArea = withDebouncedValue(Input.TextArea, {
  selectOnChangeValue
});
