import { RequestBodyType } from "../constants";
import {
  getJavaScriptParsingError,
  getJsonError,
  isKeyValueInputValid,
  GENERAL_JSON_ERROR
} from "../utils";

import { HttpFunctionBaseState, ErrorMessageKeys, ErrorMessageState } from "./reducer";

export const ErrorMessages = {
  method: "Please select an HTTP method.",
  path: "Please enter a valid JavaScript template string",
  keyValueInputs:
    "Please enter a valid key value pair. Make sure any keys are filled out and that you are entering a valid JavaScript template string.",
  json: GENERAL_JSON_ERROR
};

export const getFunctionErrors = (
  functionState: HttpFunctionBaseState
): ErrorMessageState => {
  const errors: ErrorMessageState = {
    method: "",
    path: "",
    urlParameters: "",
    headers: "",
    body: ""
  };
  if (!functionState.method) {
    errors[ErrorMessageKeys.Method] = ErrorMessages.method;
  }
  if (getJavaScriptParsingError(functionState.path, true)) {
    errors[ErrorMessageKeys.Path] = ErrorMessages.path;
  }
  if (!isKeyValueInputValid(functionState.urlParameters)) {
    errors[ErrorMessageKeys.UrlParameters] = ErrorMessages.keyValueInputs;
  }
  if (!isKeyValueInputValid(functionState.headers)) {
    errors[ErrorMessageKeys.Headers] = ErrorMessages.keyValueInputs;
  }
  if (functionState.metadata.request_type === RequestBodyType.JSON) {
    const error = getJsonError(functionState.body);
    errors[ErrorMessageKeys.Body] = error ? error.message : "";
  } else if (functionState.metadata.request_type === RequestBodyType.Raw) {
    const error = getJavaScriptParsingError(functionState.body);
    errors[ErrorMessageKeys.Body] = error ? error.message : "";
  }
  return errors;
};
