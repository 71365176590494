import React from "react";

import { Input } from "antd";
import styled from "styled-components";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import { CredentialFormProps } from "./types";

const SubdomainInput = styled(Input)`
  width: 50%;
`;

const BoldSubdomainText = styled.span`
  color: ${props => props.theme.textColor};
  font-size: ${props => props.theme.mediumFontSize};
  font-weight: ${props => props.theme.boldFontWeight};
  margin-left: ${props => props.theme.spacerxs};
`;

const InlineSubdomainText = styled.span`
  color: ${props => props.theme.textColor};
  margin-left: ${props => props.theme.spacerxs};
`;

export default function ZendeskFields(props: CredentialFormProps) {
  const SubdomainText = props.includeLabel ? InlineSubdomainText : BoldSubdomainText;

  const { getFieldDecorator } = props.form;

  return (
    <>
      <FormItem label={props.includeLabel ? "Subdomain" : undefined}>
        {getFieldDecorator("domain", {
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            },
            {
              pattern: /^[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]$/,
              message:
                'Please enter a valid subdomain. If your zendesk url is https://your-company.zendesk.com, enter "your-company".'
            }
          ]
        })(
          <SubdomainInput
            placeholder="your-zendesk-subdomain"
            disabled={props.isLoading}
          />
        )}
        <SubdomainText>.zendesk.com</SubdomainText>
      </FormItem>
      <FormItem label={props.includeLabel ? "Email" : undefined}>
        {getFieldDecorator("user", {
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(<Input placeholder="Email" disabled={props.isLoading} />)}
      </FormItem>
      <FormItem label={props.includeLabel ? "Api Token" : undefined}>
        {getFieldDecorator("apiToken", {
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="Paste your api token here"
            disabled={props.isLoading}
            type="password"
          />
        )}
      </FormItem>
    </>
  );
}
