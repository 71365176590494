import { AttributeTypes } from "../../../../../constants";
import { Binding, BindingShape } from "../../../../../types";
import { ComponentNode } from "../../RenderTreeContext";

export function getSchema(_: ComponentNode): Binding[] {
  return [
    {
      name: "value",
      shape: BindingShape.SCALAR,
      type: AttributeTypes.STRING
    },
    {
      name: "error",
      shape: BindingShape.UNKNOWN
    }
  ];
}
