import styled from "styled-components";

import ButtonNew from "../../common/ButtonNew/ButtonNew";

import BaseNavigation from "./Navigation/Navigation";

export const Navigation = styled(BaseNavigation)`
  flex-basis: 300px;
`;

export const OpenQueueButton = styled(ButtonNew)`
  margin-right: 8px;
`;
