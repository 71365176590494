import React from "react";

import { Input } from "antd";

import { AttributeTypes } from "../../../../../constants";
import { APIFiltersOption, ViewFilterOperator } from "../../../../../types";
import { AttributeTypeSelect } from "../../../AttributeTypeSelect";
import IconButton from "../../../IconButton";
import * as common from "../../common/styledComponents";
import { useListHelper } from "../../common/useListHelper";

import * as styled from "./styledComponents";

export const EMPTY_FILTERS_OPTIONS_MESSAGE = "There are no filter options.";

export interface Props {
  editable: boolean;
  options: APIFiltersOption[];
  onOptionsChange: (options: APIFiltersOption[]) => void;
}

const OPERATORS = Object.values(ViewFilterOperator);

const createDefault = () => ({
  operators: [ViewFilterOperator.EQUALS],
  sourceName: "",
  sourceType: AttributeTypes.STRING
});

export const FiltersOptions = (props: Props) => {
  const {
    list: opts,
    onCreate,
    onUpdate,
    onDelete
  } = useListHelper(
    props.editable && !props.options.length ? [createDefault()] : props.options,
    createDefault,
    o => o.sourceName,
    props.onOptionsChange
  );

  const addOrRemove = (
    operators: ViewFilterOperator[],
    operator: ViewFilterOperator,
    add: boolean
  ) => {
    const ops = new Set(operators);
    if (add) {
      ops.add(operator);
    } else if (!add) {
      ops.delete(operator);
    }
    return Array.from(ops);
  };

  return props.editable || opts.length ? (
    <>
      <styled.FiltersContent>
        <common.FieldColumnHeader>Name</common.FieldColumnHeader>
        <common.FieldColumnHeader>Type</common.FieldColumnHeader>
        {OPERATORS.map(operator => (
          <common.FieldColumnHeader key={operator} center={true}>
            {operator}
          </common.FieldColumnHeader>
        ))}
        <common.FieldColumnHeader />
        {opts.map((o, i) => (
          <React.Fragment key={`filters-${i}`}>
            <Input
              disabled={!props.editable}
              placeholder="Enter a name"
              value={o.sourceName}
              onChange={evt => onUpdate(i, { ...o, sourceName: evt.target.value })}
            />
            <AttributeTypeSelect
              disabled={!props.editable}
              value={o.sourceType}
              onChange={sourceType => onUpdate(i, { ...o, sourceType })}
            />
            {OPERATORS.map(operator => (
              <common.Checkbox
                disabled={!props.editable}
                key={`${o.sourceName}-${operator}`}
                checked={o.operators.includes(operator)}
                onChange={evt =>
                  onUpdate(i, {
                    ...o,
                    operators: addOrRemove(o.operators, operator, evt.target.checked)
                  })
                }
              />
            ))}
            <IconButton
              disabled={!props.editable}
              icon="minus"
              title="Remove"
              type="link"
              onClick={() => onDelete(i)}
            />
          </React.Fragment>
        ))}
      </styled.FiltersContent>
      <common.IconButton
        disabled={!props.editable}
        type="link"
        icon="plus"
        title="Add Filter"
        onClick={onCreate}
      >
        Add Filter
      </common.IconButton>
    </>
  ) : (
    <div data-test="empty-filters-options">{EMPTY_FILTERS_OPTIONS_MESSAGE}</div>
  );
};
