import React from "react";

import { Skeleton } from "antd";
import classNames from "classnames";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer/polyfilled";

import { reportException } from "../../../../../util/exceptionReporting";
import { useComponentLayoutContext } from "../../../../layout/LayoutContext/LayoutContext";
import { LayoutUnit, parseCssUnit } from "../../../../layout/util";
import { useComponentContext } from "../../contexts/ComponentContext";
import { Dimensions } from "../../SpaceImage/types";
import EmptyState from "../EmptyState";
import Panel from "../Panel";

import fitImageToContainer from "./fitImageToContainer";

interface ImageProps {
  src: string;
  name: string;
  inline?: boolean;
  layoutConstraints?: { width: number; height: number };
  onError: () => void;
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgWithShadow = styled.img`
  &.shadow {
    box-shadow: ${props => props.theme.boxShadow};
  }
`;

/*
  Scaling approach:
  There are two approaches to scaling images chosen depending on whether
  the layoutConstraints prop is present:

  1. If the layout constraints prop is present, it is used as the maximum
     dimensions for the image. 
  2. If the layout contraints prop is not present, the element containing
     the image is measured with a resize observer and is used as the maximum
     dimensions.

  In practice layout contraints are provided when the image is inline. Once
  max dimensions have been determined the image will be scaled down if either
  of its dimensions exceed their max. The dimension which exceeds its 
  max the most, in relative terms, is selected as the dimension to be scaled 
  while the other dimension scales naturally according to the image's 
  aspect ratio.
*/

export default function ScaledImage({
  src,
  name,
  inline,
  layoutConstraints,
  onError
}: ImageProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [naturalDims, setNaturalDims] = React.useState<Dimensions | null>(null);
  const { layout } = useComponentLayoutContext();
  const {
    component: { slug }
  } = useComponentContext();
  const containerResizeObserver = useResizeObserver({ ref });
  const [error, setError] = React.useState(false);
  const img = React.useRef(new Image());

  React.useEffect(() => {
    const handleLoad = () => {
      setNaturalDims({
        width: img.current.naturalWidth,
        height: img.current.naturalHeight
      });
    };

    const handleError = () => {
      setError(true);
      onError();
    };

    setError(false);
    img.current = new Image();
    const _img = img.current;
    img.current.src = src;
    img.current.addEventListener("error", handleError);
    img.current.addEventListener("load", handleLoad);
    return function cleanup() {
      _img.remove();
    };
  }, [onError, src]);

  const constraints = layoutConstraints ||
    (containerResizeObserver as Dimensions) || { width: 0, height: 0 };
  if (layout && layout.width && layout.height) {
    constraints.width =
      parseCssUnit(layout.width) === LayoutUnit.PIXEL &&
      parseFloat(layout.width) < constraints.width
        ? parseFloat(layout.width)
        : constraints.width;
    constraints.height =
      parseCssUnit(layout.height) === LayoutUnit.PIXEL &&
      parseFloat(layout.height) < constraints.height
        ? parseFloat(layout.height)
        : constraints.height;
  } else {
    reportException(new Error("Unexpected partial ElementLayout"), {
      extra: { layout }
    });
  }

  const scaledDims =
    naturalDims && constraints !== undefined
      ? fitImageToContainer(constraints, naturalDims)
      : { height: 0, width: 0 };

  if (error) {
    const emptyState = (
      <EmptyState id={slug} message="Your image could not be loaded" inline={inline} />
    );

    return !inline ? <Panel title={name}>{emptyState}</Panel> : emptyState;
  }

  return (
    <Root ref={ref}>
      {scaledDims.height === 0 && scaledDims.width === 0 ? (
        <Skeleton active avatar title={false} paragraph={{ rows: 0 }} />
      ) : (
        <ImgWithShadow
          className={classNames({ shadow: !inline })}
          {...scaledDims}
          alt={name}
          src={src}
        />
      )}
    </Root>
  );
}
