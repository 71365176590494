import inflection from "inflection";

import { SpaceComponentType } from "../../../../../../../types";

export function getSlugForType(type: SpaceComponentType): string {
  switch (type) {
    case "FUNCTION_BUTTON":
      return "button";
    case "FUNCTION_FORM":
      return "form";
    case "FUNCTION_MODAL_FORM":
      return "modalForm";
    default:
      return inflection.camelize(type.toLowerCase(), true);
  }
}

export default function ensureSlug(
  type: SpaceComponentType,
  slugs: Set<string>,
  requestedSlug?: string
): string {
  let idx = 1;
  let slug = requestedSlug;
  while (!slug || slugs.has(slug)) {
    slug = (requestedSlug || getSlugForType(type)) + idx++;
  }
  return slug;
}
