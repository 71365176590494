import { Button } from "antd";
import styled from "styled-components";

export const List = styled.ul`
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacermd};
  margin-bottom: ${props => props.theme.spacersm};
  border: solid 1px ${props => props.theme.tableBorderColor};
  border-radius: 3px;
  list-style: none;
`;

export const Description = styled.div``;

export const VersionDate = styled.div`
  font-size: ${props => props.theme.mediumFontSize};
  font-weight: 500;
`;

export const PublishedNotice = styled.div``;

export const ByLine = styled.div``;

export const RestoreButton = styled(Button)`
  font-weight: 500;
`;
