import {
  MAINTAIN_SELECTED_ROWS_BY_ID,
  MAINTAIN_SELECTED_ROWS_BY_INDEX,
  SELECT_FIRST_ROW
} from "../common/effects/Effects";

export enum TableEventType {
  INITIAL_LOAD = "initial_load",
  RELOAD = "reload"
}

export const TABLE_EVENTS = [
  {
    name: "On Initial Load",
    type: TableEventType.INITIAL_LOAD,
    effects: [SELECT_FIRST_ROW],
    defaultEffect: { type: "select_first_row" as const, options: {} }
  },
  {
    name: "On Reload",
    type: TableEventType.RELOAD,
    effects: [
      SELECT_FIRST_ROW,
      MAINTAIN_SELECTED_ROWS_BY_INDEX,
      MAINTAIN_SELECTED_ROWS_BY_ID
    ],
    defaultEffect: { type: "select_first_row" as const, options: {} }
  }
];
