import React from "react";

import { Button, Icon, Input, Spin } from "antd";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";

import { LeftCol } from "../../../../layouts/Layout";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../../common/hooks/useAuthUser";
import useDebouncedValue from "../../../common/hooks/useDebouncedValue";
import usePaths from "../../../common/hooks/usePaths";
import NavMenu, { List, ListItem, Link } from "../../../common/NavMenu/NavMenu";
import { assertNever } from "../../../util/assertNever";
import { SpaceSortingSelect, SpaceSortOption } from "../../Home/SpaceFilters";
import useSpacesManager, {
  useHasFavorites
} from "../../hooks/useSpacesManager/useSpacesManager";

interface LeftNavProps {
  open: boolean;
}

export default function LeftNav({ open }: LeftNavProps) {
  const hasFavorites = useHasFavorites();
  return (
    <LeftCol darkMode open={open}>
      {open ? (
        typeof hasFavorites === "boolean" ? (
          <Nav hasFavorites={hasFavorites} />
        ) : (
          <div />
        )
      ) : null}
    </LeftCol>
  );
}

const SPACE_LIST_TYPES = {
  favorites: "Favorites",
  shared: "Shared with you",
  all: "All spaces",
  drafts: "Drafts"
} as const;
type SpaceListType = keyof typeof SPACE_LIST_TYPES;
const SPACE_LIST_ORDER_OPTIONS: Readonly<Record<SpaceListType, SpaceSortOption[]>> = {
  favorites: ["favorite", "name", "-name"],
  shared: ["name", "-name"],
  all: ["name", "-name"],
  drafts: ["name", "-name"]
};

const BaseList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const SpaceNavigator = styled.div``;

const CtaWrapper = styled.div`
  padding: 0 20px;
`;

const NewSpaceButton = styled(ButtonNew)`
  display: block;
`;

interface NavSpaceFilter {
  orderBy: SpaceSortOption;
  nameContains: string;
}

enum LeftNavActionTypes {
  TOGGLE_SECTION,
  CHANGE_FILTER
}
type LeftNavState = NavSpaceFilter & {
  openSection: SpaceListType | null;
  restoreSection: SpaceListType | null;
};
type LeftNavAction =
  | { type: LeftNavActionTypes.TOGGLE_SECTION; payload: { section: SpaceListType } }
  | { type: LeftNavActionTypes.CHANGE_FILTER; payload: { filter: NavSpaceFilter } };

function leftNavReducer(state: LeftNavState, action: LeftNavAction) {
  switch (action.type) {
    case LeftNavActionTypes.TOGGLE_SECTION: {
      const { section } = action.payload;
      const nextOpenSection = state.openSection === section ? null : section;
      const nextOrderBy =
        nextOpenSection &&
        !SPACE_LIST_ORDER_OPTIONS[nextOpenSection].includes(state.orderBy)
          ? SPACE_LIST_ORDER_OPTIONS[nextOpenSection][0]
          : state.orderBy;
      return {
        ...state,
        openSection: nextOpenSection,
        orderBy: nextOrderBy as SpaceSortOption
      };
    }
    case LeftNavActionTypes.CHANGE_FILTER: {
      const {
        filter: { orderBy, nameContains }
      } = action.payload;
      let nextOpenSection = nameContains ? "all" : state.openSection;
      const nextOrderBy =
        nextOpenSection && !SPACE_LIST_ORDER_OPTIONS[nextOpenSection].includes(orderBy)
          ? SPACE_LIST_ORDER_OPTIONS[nextOpenSection][0]
          : orderBy;
      let nextRestoreSection = state.restoreSection;
      if (nameContains && !state.nameContains) {
        // entering search
        nextRestoreSection = state.openSection;
      } else if (!nameContains && state.nameContains) {
        // leaving search
        nextOpenSection = state.restoreSection;
        nextRestoreSection = null;
      }
      return {
        ...state,
        openSection: nextOpenSection,
        restoreSection: nextRestoreSection,
        orderBy: nextOrderBy as SpaceSortOption,
        nameContains
      };
    }
    default:
      return assertNever(action);
  }
}

function Nav({ hasFavorites }: { hasFavorites: boolean }) {
  const { isAdmin, isSpaceAdmin } = useAuthUser();
  const navigate = useNavigate();
  const { getNewSpace } = usePaths();
  const [state, dispatch] = React.useReducer(leftNavReducer, {
    orderBy: hasFavorites ? "favorite" : "name",
    nameContains: "",
    openSection: hasFavorites ? "favorites" : "all",
    restoreSection: null
  });
  const filter = { orderBy: state.orderBy, nameContains: state.nameContains };
  const canEditSpaces = isAdmin || isSpaceAdmin;
  const spaceLists = [
    hasFavorites ? "favorites" : null,
    "shared",
    canEditSpaces ? "all" : null,
    canEditSpaces ? "drafts" : null
  ].filter(sl => sl !== null) as SpaceListType[];
  function toggleSection(section: SpaceListType) {
    dispatch({ type: LeftNavActionTypes.TOGGLE_SECTION, payload: { section } });
  }
  const favoritesPresenceChecked = typeof hasFavorites === "boolean";
  const hasSearchTerm = filter.nameContains;

  return (
    <>
      <SpaceNavigator>
        <Controls
          filter={filter}
          openSection={state.openSection}
          onFilterChange={filter => {
            dispatch({ type: LeftNavActionTypes.CHANGE_FILTER, payload: { filter } });
          }}
        />
        {favoritesPresenceChecked && (
          <BaseList>
            {spaceLists
              .filter(sl => (hasSearchTerm ? sl === "all" : true))
              .map(sl => (
                <li key={sl}>
                  <SpacesSection
                    type={sl}
                    open={state.openSection === sl}
                    filter={filter}
                    showTitle={!hasSearchTerm}
                    onToggle={toggleSection}
                  />
                </li>
              ))}
          </BaseList>
        )}
      </SpaceNavigator>
      {canEditSpaces && (
        <CtaWrapper>
          <NewSpaceButton
            data-test="leftNavNewSpaceButton"
            icon="plus"
            type="brand"
            block
            onClick={() => {
              navigate(getNewSpace());
            }}
          >
            New space
          </NewSpaceButton>
        </CtaWrapper>
      )}
    </>
  );
}

const ControlsRoot = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2px 8px 24px;
  align-items: center;
`;

const Search = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;

  .ant-input,
  .ant-input:hover {
    background: transparent;
    width: 100%;
  }
`;

const SearchIcon = styled(Icon)`
  &.searchFocused,
  &:hover {
    color: ${props => props.theme.primaryColorDark};
  }
`;

const ClearButton = styled(Button)`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 14px;
  color: ${props => props.theme.textColorLight};
`;

const SearchInput = styled(Input)``;

function Controls({
  filter,
  openSection,
  onFilterChange
}: {
  filter: NavSpaceFilter;
  openSection: SpaceListType | null;
  onFilterChange: (filter: NavSpaceFilter) => void;
}) {
  const searchInputEl = React.useRef<Input>(null);
  const [searchFocused, setSearchFocused] = React.useState(false);
  return (
    <ControlsRoot>
      <Search>
        <SearchIcon
          type="search"
          className={classNames({ searchFocused })}
          onClick={() => {
            if (searchInputEl.current) {
              searchInputEl.current.focus();
            }
          }}
        />
        <SearchInput
          ref={searchInputEl}
          placeholder="Search"
          value={filter.nameContains}
          onFocus={() => {
            setSearchFocused(true);
          }}
          onBlur={() => {
            setSearchFocused(false);
          }}
          onChange={ev => {
            onFilterChange({ ...filter, nameContains: ev.target.value });
          }}
        />
        {!!filter.nameContains && (
          <ClearButton
            type="link"
            icon="close"
            size="small"
            onClick={() => {
              onFilterChange({ ...filter, nameContains: "" });
              searchInputEl.current?.blur();
            }}
          />
        )}
      </Search>
      {openSection && (
        <SpaceSortingSelect
          value={filter.orderBy}
          includeFavorites={openSection === "favorites"}
          searchActive={searchFocused}
          onChange={orderBy => {
            onFilterChange({ ...filter, orderBy });
          }}
        />
      )}
    </ControlsRoot>
  );
}

function SpacesSection({
  type,
  filter,
  open = true,
  showTitle = true,
  onToggle
}: {
  type: SpaceListType;
  filter: NavSpaceFilter;
  open: boolean;
  showTitle: boolean;
  onToggle: (type: SpaceListType) => void;
}) {
  const debouncedFilter = useDebouncedValue(filter, 100);
  const { spaceNodes, loading, hasLoaded, isEmpty, pageInfo, fetchMore } =
    useSpacesManager({
      skip: !open,
      variableOverrides: {
        ...SPACE_LIST_TYPE_FILTERS[type],
        ...debouncedFilter
      }
    });
  const { spaceSlug } = useParams();
  const { getSpace } = usePaths();

  if (!hasLoaded && isEmpty) {
    return <Spin />;
  }

  return (
    <NavMenu
      open={open}
      showTitle={showTitle}
      title={SPACE_LIST_TYPES[type]}
      className={classNames({ open })}
      onToggle={() => {
        onToggle(type);
      }}
    >
      <List
        loading={!hasLoaded && isEmpty}
        hasNextPage={pageInfo?.hasNextPage}
        fetchMoreLoading={loading}
        onFetchMore={fetchMore}
      >
        {spaceNodes.map(sn => (
          <ListItem key={sn.slug}>
            <Link
              data-test="leftNavSpaceLink"
              className={classNames({ active: spaceSlug === sn.slug })}
              to={getSpace(sn.slug)}
            >
              {sn.name}
            </Link>
          </ListItem>
        ))}
      </List>
    </NavMenu>
  );
}

const SPACE_LIST_TYPE_FILTERS = {
  favorites: { isFavorite: true },
  shared: { isFeatured: true },
  all: { allSpaces: true },
  drafts: { onlyUnpublished: true }
} as const;
