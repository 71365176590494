import React from "react";

import styled from "styled-components";

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  div {
    text-align: center;
    width: fit-content;
  }
`;

const LicenseMissing = () => {
  return (
    <Root>
      <div>
        <h2>No license found.</h2>
        <p>
          You need a license to use Internal OnPrem.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://internal.io/contact"
          >
            Contact Internal
          </a>{" "}
          to continue.
        </p>
      </div>
    </Root>
  );
};

export default LicenseMissing;
