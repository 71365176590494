import { Input } from "antd";
import styled from "styled-components";

import { default as BaseAvatar } from "../../../../../common/Avatar/Avatar";

const { TextArea: BaseTextArea } = Input;

export const Container = styled.div`
  display: flex;
`;

export const Avatar = styled(BaseAvatar)`
  margin-right: 16px;
`;

export const EditArea = styled.div`
  padding: 12px 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  flex-grow: 1;
`;

export const TextArea = styled(BaseTextArea)`
  resize: none;
  border: none;
  padding: 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 9px;
  display: flex;
  justify-content: end;
  gap: 6px;
`;
