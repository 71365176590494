import React from "react";

import { Select } from "antd";

import { AttributeTypes, AttributeTypesDisplayNames } from "../../../constants";

interface Props {
  className?: string;
  value: AttributeTypes;
  onChange: (value: AttributeTypes) => void;
  disabled?: boolean;
  enabledAttributeTypes?: AttributeTypes[];
}

export const AttributeTypeSelect = (props: Props) => {
  const enabled =
    props.enabledAttributeTypes || Object.keys(AttributeTypesDisplayNames);

  return (
    <Select
      className={props.className}
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      value={props.value}
      onChange={props.onChange}
      disabled={!!props.disabled}
    >
      {enabled.map(type => (
        <Select.Option value={type} key={type}>
          {AttributeTypesDisplayNames[type as AttributeTypes]}
        </Select.Option>
      ))}
    </Select>
  );
};
