import { ApolloError } from "apollo-client";

import { StatusCode } from "../../../types";

const DEFAULT_ERROR_COPY = "An error occurred while creating your data source.";

export default function selectError(error: ApolloError) {
  const firstError = (
    error.graphQLErrors as {
      message: string;
      code?: StatusCode;
    }[]
  )[0];
  if (firstError && firstError.code !== StatusCode.UNKNOWN) {
    return firstError.message.replace("GraphQL error: ", "");
  } else {
    return DEFAULT_ERROR_COPY;
  }
}
