import React from "react";

import { DataValue } from "../../../../../../types";
import AttributeValue from "../../../../../common/AttributeValue";
import DescriptionList from "../../../../../common/DescriptionList";
import { FunctionNode } from "../../useRecordData/queries";
import { MaybeFunctionLink } from "../MaybeRefLink";

interface Props {
  func: FunctionNode;
  record: Record<string, DataValue>;
}

const FunctionRecordDescription = ({ func, record }: Props) => {
  const attrs = func.functionAttributes.edges
    .map(e => e.node)
    .sort((a, b) => a.sourceIndex - b.sourceIndex);

  return (
    <div>
      {attrs.map(attr => (
        <DescriptionList.DescriptionWrapper key={attr.id}>
          <DescriptionList.Term title={attr.name}>{attr.name}</DescriptionList.Term>
          <DescriptionList.Details>
            <MaybeFunctionLink func={func} attribute={attr} value={record[attr.id]}>
              <AttributeValue attribute={attr} value={record[attr.id]} />
            </MaybeFunctionLink>
          </DescriptionList.Details>
        </DescriptionList.DescriptionWrapper>
      ))}
    </div>
  );
};

export default FunctionRecordDescription;
