import gql from "graphql-tag";

import { RelayNode } from "../../../../../../types";

import { FunctionAccess } from "./types";

interface FunctionNodeAccess extends RelayNode {
  __typename: "FunctionNode";
  name: string;
  access: FunctionAccess;
}

export interface FunctionAccessVars {
  ids: string[];
}

export interface FunctionAccessData {
  nodes: FunctionNodeAccess[];
}

export const GET_FUNCTION_ACCESS = gql`
  query GetFunctionAccess($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on FunctionNode {
        id
        name
        access {
          onlyParameters
          onlyAttributes
        }
      }
    }
  }
`;
