import { SpaceComponentObject } from "../../../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { getChildrenSchema } from "../util";

export const getSchema = (componentNode: ComponentNode) =>
  getChildrenSchema(
    componentNode,
    (componentNode.component.componentTreeNodes as SpaceComponentObject[]).map(
      ctn => ctn.slug
    )
  );
