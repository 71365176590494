import React from "react";

import classNames from "classnames";
import styled from "styled-components";

import { DEFAULT_COLOR_OPTIONS } from "../ColorPicker";

const AvatarRoot = styled.div`
  width: 24px;
  height: 24px;
  padding: 0px;
  border-radius: 50%;
  transition: background 0.5s;
  &.clickable {
    width: 30px;
    height: 30px;
    padding: 3px;
    :hover {
      background: rgba(255, 255, 255, 0.23);
    }
  }
`;

const AvatarWrap = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 12px;
  &.clickable {
    cursor: pointer;
  }
`;

function getRandomColor() {
  const i = Math.floor(Math.random() * (DEFAULT_COLOR_OPTIONS.length - 1));
  return DEFAULT_COLOR_OPTIONS[i];
}

export default function Avatar({
  children,
  className,
  color,
  onClick
}: {
  children: React.ReactNode;
  className?: string;
  color?: string;
  onClick?: (ev: React.MouseEvent) => void;
}) {
  const clickable = typeof onClick === "function";
  const ensuredColor = React.useMemo(() => {
    return color ? color : getRandomColor();
  }, [color]);
  return (
    <AvatarRoot data-test="avatarRoot" className={classNames(className, { clickable })}>
      <AvatarWrap
        style={{ backgroundColor: ensuredColor }}
        className={classNames({ clickable })}
        onClick={onClick}
      >
        {children}
      </AvatarWrap>
    </AvatarRoot>
  );
}

export function userToInitials(user: { firstName?: string; lastName?: string } | null) {
  if (!user) return "";
  const initials = [];
  if (user.firstName && user.firstName.length) initials.push(user.firstName[0]);
  if (user.lastName && user.lastName.length) initials.push(user.lastName[0]);
  return initials.join("");
}
