import React from "react";

import styled from "styled-components";

import { InfoIconButton } from "./InfoIconButton";

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  > button {
    margin-left: ${props => props.theme.spacersm};
    height: fit-content;
  }
`;

interface Props {
  children: React.ReactNode;
  title: string;
  showInfoIcon?: boolean;
  style?: React.CSSProperties;
}

const Label = ({ children, title, showInfoIcon = false, style }: Props) => {
  return (
    <StyledLabel style={style}>
      {children}
      {showInfoIcon && <InfoIconButton title={title} />}
    </StyledLabel>
  );
};

export default Label;
