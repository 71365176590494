import { useNavigate, useParams } from "react-router-dom";

import { decodeCursor } from "../../../../../constants";
import { DataValue, RecordKey, RecordView, ResourceNode } from "../../../../../types";
import usePaths from "../../../../common/hooks/usePaths";
import { FunctionNode } from "../useRecordData/queries";
import {
  getDataAccessorId,
  getDataAccessorRecordKey,
  getRecordIdentifier
} from "../util";

export interface RecordParams extends Record<string, string> {
  cursor: string;
  contextCursor: string;
  view: RecordView;
}

interface Options {
  rootResource?: ResourceNode | FunctionNode;
  rootRecord?: Record<string, DataValue>;
  contextResource?: ResourceNode | FunctionNode;
  contextRecord?: Record<string, DataValue>;
  contextRecordKey?: RecordKey;
  view?: RecordView;
}

type Result = {
  gotoRecordPath: (options: Options) => void;
  getRecordPath: (options: Options) => string;
  params: Partial<RecordParams>;
};

export default function useGetRecordPath(): Result {
  const navigate = useNavigate();
  const params = useParams<RecordParams>();
  const { getRecord } = usePaths();
  const { cursor, view: currentView } = params;

  const decodedRootCursor = decodeCursor(decodeURIComponent(cursor!));
  const rootIdentifier = getRecordIdentifier(decodedRootCursor);

  function getRecordPath({
    rootResource,
    rootRecord,
    contextResource,
    contextRecord,
    contextRecordKey,
    view = currentView
  }: Options) {
    return getRecord(
      {
        slug: rootResource ? getDataAccessorId(rootResource) : rootIdentifier.slug,
        key:
          rootRecord && rootResource
            ? getDataAccessorRecordKey(rootResource, rootRecord)
            : rootIdentifier.key
      },
      view,
      contextRecord && contextResource
        ? {
            slug: getDataAccessorId(contextResource),
            key: getDataAccessorRecordKey(contextResource, contextRecord)
          }
        : contextRecordKey && contextResource
        ? {
            slug: getDataAccessorId(contextResource),
            key: contextRecordKey
          }
        : undefined
    );
  }

  return {
    getRecordPath,
    gotoRecordPath: options => navigate(getRecordPath(options)),
    params
  };
}
