import { colors } from "../../../cssConstants";
import { Edge } from "../../../types";

import { QueueNode as TerseQueueNode } from "./queries/AllQueues";
import { DEFAULT_TASK_ORDER, TaskOrder } from "./Queue/useQueueClient/queries/AllTasks";
import {
  QueueNode,
  StateNode
} from "./Queue/useQueueClient/useQueue/queries/QueueBySlug";

type RequireOnly<T, K extends keyof T> = Partial<Omit<T, K>> & Required<Pick<T, K>>;

export interface TaskFilters {
  orderBy: TaskOrder;
  queueSlug?: string;
  isAssignedTo?: boolean;
  isAssignedToUser?: boolean;
  isAssignedToUsersRole?: boolean;
  isArchived?: boolean;
  stateIdIn?: string[];
  search?: string;
  before?: string;
  after?: string;
  first?: number;
}

export interface Queue {
  id: string;
  name: string;
  description: string;
  slug: string;
  isLocal: boolean;
  icon?: string;
  color?: string;
  isListed: boolean;
  states?: StateNode[];
  taskFilters: TaskFilters;
}

export type PartialQueue = Omit<
  RequireOnly<Queue, "slug" | "isLocal">,
  "taskFilters"
> & { taskFilters: Partial<TaskFilters> };

export const LocalQueues: Record<string, Queue> = {
  assigned: {
    id: "assigned",
    name: "My tasks",
    description: "Tasks assigned to you or your roles",
    slug: "assigned",
    icon: "person",
    color: colors.midGrey,
    isLocal: true,
    isListed: true,
    taskFilters: { orderBy: DEFAULT_TASK_ORDER, isAssignedTo: true, isArchived: false }
  },
  assignedToUser: {
    id: "assignedToUser",
    name: "Assigned to you",
    description: "Tasks assigned to you",
    slug: "assignedToUser",
    isLocal: true,
    isListed: false,
    taskFilters: {
      orderBy: DEFAULT_TASK_ORDER,
      isAssignedToUser: true,
      isArchived: false
    }
  },
  assignedToRole: {
    id: "assignedToRole",
    name: "Assigned to role",
    description: "Tasks assigned to your roles",
    slug: "assignedToRole",
    isLocal: true,
    isListed: false,
    taskFilters: {
      orderBy: DEFAULT_TASK_ORDER,
      isAssignedToUsersRole: true,
      isArchived: false
    }
  }
};

export const resolveQueues = (edges: Edge<TerseQueueNode>[] | undefined): Queue[] =>
  edges
    ? [
        ...Object.values(LocalQueues),
        ...edges.map<Queue>(({ node }) => ({
          id: node.id,
          name: node.name,
          description: node.description,
          icon: node.icon,
          color: node.color,
          slug: node.slug,
          isLocal: false,
          isListed: true,
          taskFilters: {
            orderBy: DEFAULT_TASK_ORDER,
            queueSlug: node.slug,
            isArchived: false
          }
        }))
      ]
    : [];

export const toQueue = (
  node: QueueNode | TerseQueueNode,
  taskFilters?: Partial<TaskFilters>
): Queue => ({
  id: node.id,
  isLocal: false,
  isListed: true,
  name: node.name,
  description: node.description,
  slug: node.slug,
  states: "states" in node ? node.states : [],
  taskFilters: {
    orderBy: DEFAULT_TASK_ORDER,
    queueSlug: node.slug,
    ...taskFilters
  }
});
