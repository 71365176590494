import React from "react";

import { throttle, uniqueId } from "lodash";
import styled from "styled-components";

import useMouseCoords from "../../../common/hooks/useMouseCoords";
import { useComponentPathContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import {
  useSelectionStateContext,
  useTransformationActionContext
} from "../TransformationContext/TransformationContext";
import { getRectCorners, getDistanceBetweenPoints, getIsPtInsideRect } from "../util";

// Currently this is naive as the only type of `DropTarget`
// parent is a FlexBox. As more "container" components
// are added this will need to be extended
function getInsertionAxis(el: HTMLElement) {
  if (el.style.flexDirection === "" || el.style.flexDirection === "row") {
    return "x";
  } else {
    return "y";
  }
}

export default function DropTarget({
  children,
  className = "",
  style = {}
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  const el = React.useRef<HTMLDivElement>(null);
  const path = useComponentPathContext();
  const { activeDropTarget } = useSelectionStateContext();
  const { registerDropTarget, deregisterDropTarget } = useTransformationActionContext();
  const clientRectCache = React.useRef(new Map());
  const mouseCoords = useMouseCoords();

  // mouseEnter and mouseLeave events can be skipped if the cursor is moving
  // fast, so do explicit collision detecting
  const isActive = activeDropTarget?.path === path;
  const rect = el.current?.getBoundingClientRect() || new DOMRect();
  const isMouseOver = getIsPtInsideRect(rect, mouseCoords.client);
  const shouldRegister = isMouseOver && !isActive;
  const shouldDeregister = !isMouseOver && isActive;
  React.useEffect(() => {
    let handle: number;
    if (shouldRegister) {
      handle = requestAnimationFrame(() => registerDropTarget({ path, index: 0 }));
    } else if (shouldDeregister) {
      handle = requestAnimationFrame(() => deregisterDropTarget(path));
    }
    return () => {
      cancelAnimationFrame(handle);
    };
  }, [
    shouldRegister,
    shouldDeregister,
    path,
    registerDropTarget,
    deregisterDropTarget
  ]);

  React.useEffect(() => {
    clientRectCache.current = new Map();
  }, [isActive]);

  function getRect(node: HTMLElement) {
    let key = node.id;
    if (!key) {
      key = node.id = uniqueId("layout-node");
    }
    if (clientRectCache.current.has(key)) {
      return clientRectCache.current.get(key);
    } else {
      const rect = node.getBoundingClientRect();
      clientRectCache.current.set(key, rect);
      return rect;
    }
  }

  const throttledHandleMouseMove = React.useRef(
    throttle((evt: React.MouseEvent) => {
      const containerEl = evt.currentTarget as HTMLElement;

      if (!containerEl) return;

      // See if the cursor is over a child
      let el = ([] as HTMLElement[]).find.call(containerEl.children, c => {
        const rect = getRect(c);
        return (
          rect.left <= evt.clientX &&
          rect.right >= evt.clientX &&
          rect.top <= evt.clientY &&
          rect.bottom >= evt.clientY
        );
      });

      // If not find the closest child to insert relative to
      if (!el) {
        let closestEl = [-1, Number.POSITIVE_INFINITY];
        const mousePoint = new DOMPoint(evt.clientX, evt.clientY);
        ([] as HTMLElement[]).forEach.call(containerEl.children, (c, i) => {
          const corners = getRectCorners(getRect(c));
          corners.forEach(c => {
            const distance = getDistanceBetweenPoints(c, mousePoint);
            if (distance < closestEl[1]) {
              closestEl = [i, distance];
            }
          });
        });

        if (closestEl[0] !== -1) {
          el = containerEl.children[closestEl[0]] as HTMLElement;
        }
      }

      if (!el) return;

      let idx = ([] as HTMLElement[]).indexOf.call(containerEl.children, el);

      // If the cursor is a preceding element decrement idx to ignore it
      let prev = el.previousElementSibling;
      while (prev) {
        if (prev.id === "drop-target-cursor") {
          idx--;
          break;
        }
        prev = prev.previousElementSibling;
      }

      // Determine whether component will insert horizontally or vertically
      const insertionAxis = getInsertionAxis(containerEl);
      const elDOMRect = getRect(el);

      // Position drop target cursor after if mouse position past mid point
      if (insertionAxis === "x") {
        const midX = elDOMRect.left + elDOMRect.width / 2;
        if (evt.clientX > midX) {
          idx++;
        }
      } else {
        const midY = elDOMRect.top + elDOMRect.height / 2;
        if (evt.clientY > midY) {
          idx++;
        }
      }

      registerDropTarget({ path, index: idx });
    }, 25)
  );

  React.useEffect(() => {
    const throttledMouseMove = throttledHandleMouseMove.current;
    return () => {
      throttledMouseMove.flush();
    };
  }, []);

  let processedChildren = React.Children.toArray(children);
  if (activeDropTarget?.path === path) {
    processedChildren = [
      ...processedChildren.slice(0, activeDropTarget.index),
      <DropTargetCursor key="drop-target-cursor" id="drop-target-cursor" />,
      ...processedChildren.slice(activeDropTarget.index)
    ];
  }

  return (
    <Root
      ref={el}
      className={className}
      style={style}
      onMouseMove={(evt: React.MouseEvent) => {
        if (path !== activeDropTarget?.path) return;
        evt.persist();
        throttledHandleMouseMove.current(evt);
      }}
    >
      {processedChildren}
    </Root>
  );
}

const Root = styled.div`
  min-width: 20px;
  min-height: 20px !important;
`;

const DropTargetCursor = styled.div`
  max-height: 100%;
  width: 2px;
  background-color: ${props => props.theme.primaryColor};
  animation: blink 1s steps(1, end) infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
