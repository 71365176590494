import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import HeaderConfigSection from "../../common/HeaderConfig";
import useViewConfig from "../../common/useViewConfig";
import { hasSource } from "../../common/util";

import AdvancedConfigSections from "./AdvancedConfig";
import BasicConfig from "./BasicConfig";
import { ensureChartConfigState } from "./reducer";

export default function SpaceChartConfig(props: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<SpaceChartConfigTabs />}
      designConfig={<DesignConfig />}
      slug={props.slug}
    />
  );
}

function SpaceChartConfigTabs() {
  const context = useComponentConfigContext();
  const state = ensureChartConfigState(context.state);

  useViewConfig({
    state: context.state,
    dispatch: context.dispatch
  });

  return (
    <>
      <BasicConfig state={state} dispatch={context.dispatch} />
      {hasSource(context.state.draftComponent) && (
        <AdvancedConfigSections state={state} />
      )}
    </>
  );
}

function DesignConfig() {
  return <HeaderConfigSection />;
}
