import React from "react";

import { throttle } from "lodash";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer/polyfilled";

export interface ResizeContextType {
  lastResize: number;
  hasResizedRecently: boolean;
}

export const ResizeContext = React.createContext<ResizeContextType>({
  lastResize: -1,
  hasResizedRecently: false
});

const Root = styled.div`
  height: 100%;
`;

export interface ResizeContextProviderProps {
  children: React.ReactNode;
}

interface ObservedSize {
  width: number | undefined;
  height: number | undefined;
}

const initialResizeContextState = {
  hasResizedRecently: false,
  size: { width: 0, height: 0 } as ObservedSize,
  lastResize: -1,
  initialMount: -1
};

// NOTE: If this duration is too short very fast zooms can cause layout bugs.
//       At a 500ms duration I was able to trigger bugs by zooming in very quickly
//       at 800 I could no longer reproduce them. This value may need tuning over time.
const RECENCY_DURATION = 150;
const POLL_FREQUENCY = 50;
const INITIAL_DELAY = 1000;

type ResizeContextAction =
  | { type: "UPDATE_SIZE"; payload: { now: number; size: ObservedSize } }
  | { type: "TICK"; payload: { now: number } };

function resizeContextReducer(
  state: typeof initialResizeContextState,
  action: ResizeContextAction
) {
  switch (action.type) {
    case "UPDATE_SIZE": {
      const { size, now } = action.payload;
      if (now - state.initialMount < INITIAL_DELAY) {
        return state;
      }
      return {
        ...state,
        lastResize: now,
        hasResizedRecently: true,
        size
      };
    }
    case "TICK": {
      const { lastResize } = state;
      const { now } = action.payload;

      if (!state.hasResizedRecently) return state;

      const hasResizedRecently = now - lastResize < RECENCY_DURATION;

      if (hasResizedRecently === state.hasResizedRecently) return state;

      return {
        ...state,
        hasResizedRecently
      };
    }
    default: {
      return state;
    }
  }
}

export function ResizeContextProvider({ children }: ResizeContextProviderProps) {
  const [{ size, lastResize, hasResizedRecently }, dispatch] = React.useReducer(
    resizeContextReducer,
    {
      ...initialResizeContextState,
      initialMount: Date.now()
    }
  );
  const throttledOnResize = React.useRef(
    throttle((observedSize: ObservedSize) => {
      if (size.width === observedSize.width && size.height === observedSize.height)
        return;
      dispatch({
        type: "UPDATE_SIZE",
        payload: { size: observedSize, now: Date.now() }
      });
    }, 25)
  );
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: throttledOnResize.current
  });

  React.useEffect(() => {
    let intervalHandle: any = undefined;
    if (!hasResizedRecently) return;
    intervalHandle = setInterval(() => {
      dispatch({ type: "TICK", payload: { now: Date.now() } });
    }, POLL_FREQUENCY);
    return () => {
      clearInterval(intervalHandle);
    };
  }, [hasResizedRecently]);

  const providedValue = React.useMemo(() => {
    return {
      lastResize,
      hasResizedRecently
    };
  }, [lastResize, hasResizedRecently]);

  return (
    <ResizeContext.Provider value={providedValue}>
      <Root ref={ref}>{children}</Root>
    </ResizeContext.Provider>
  );
}

export const useResizeContext = () => React.useContext(ResizeContext);
