import React from "react";

import { useSpaceConfigContext } from "../SpaceConfig/SpaceConfigContext";
import { useComponentContext } from "../SpaceRoot/SpaceComponent/contexts/ComponentContext";

import { findComponentNodeBySlug, fromComponents } from "./tree";

export default function useComponentNode() {
  const { componentTree } = useSpaceConfigContext();
  const { component, isPseudo, parentContext } = useComponentContext();
  const tree = React.useMemo(() => fromComponents(componentTree), [componentTree]);

  if (!isPseudo) {
    return findComponentNodeBySlug(tree, component.slug);
  }

  let currParent = parentContext;
  let concreteAncestor;
  while (currParent) {
    if (!currParent.isPseudo) {
      concreteAncestor = currParent.component;
      break;
    }
    currParent = currParent.parentContext;
  }
  if (!concreteAncestor) {
    throw new Error("Expected to find concrete ancestor for pseudo component.");
  }
  return findComponentNodeBySlug(tree, component.slug, concreteAncestor.slug);
}
