import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import UserProfile from "../components/settings/UserProfile";
import LeftNav from "../components/spaces/SpaceRoot/LeftNav/LeftNav";
import { Layout, Header } from "../layouts";

const UsersLayout = () => (
  <RequireAuth>
    <Layout>
      <Header title="User profile" />
      <LeftNav open />
      <UserProfile />
    </Layout>
  </RequireAuth>
);

export const Detail = () => {
  return <UsersLayout />;
};
