import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../constants";
import Button from "../../common/Button";
import { DarkTheme } from "../../common/StyledComponents";
import TitleContainer, { ContainerSize } from "../../common/TitleContainer";

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

const RequestPasswordResetSent = () => {
  const navigate = useNavigate();

  const title = "Check your email.";

  const description =
    "If there is an account that matches the email address you provided, you'll receive an email with instructions for how to reset your password.";

  return (
    <React.Fragment>
      <DarkTheme />

      <TitleContainer
        title={title}
        description={description}
        size={ContainerSize.Large}
      >
        <ButtonWrapper>
          <Button onClick={() => navigate(Routes.LOGIN)} type="primary">
            Login
          </Button>
        </ButtonWrapper>
      </TitleContainer>
    </React.Fragment>
  );
};

export default RequestPasswordResetSent;
