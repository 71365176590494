import { AttributeTypes } from "../../../../constants";
import { newColors } from "../../../../cssConstants";
import {
  Binding,
  BindingInputParameter,
  BindingShape,
  ComponentInputParameter,
  InputParameter,
  InputParameterBase,
  ParameterType,
  StaticInputParameter,
  TemplateInputParameter
} from "../../../../types";
import { FunctionNode } from "../../../common/FormBuilderModal/types";
import {
  getComponentProperties,
  getDefaultComponentType
} from "../../../common/FormBuilderModal/utils";
import { ensureHexValue } from "../../../common/utils";
import { findSpaceComponentPackage } from "../../../spaces/SpaceRoot/SpaceContext/StableSpaceContext";
import { assertNever } from "../../../util/assertNever";
import { cacheBy } from "../../../util/cacheBy";
import { AssigneeParameter } from "../../App/Queue/useAssigneeInputParameters";
import { ActionNode, QueueNode, SubscriptionNode } from "../queries/common";
import { QueueInput } from "../queries/SaveQueue";
import { Event, Field, FieldInputParameter } from "../types";

import { ConfigState, DEFAULT_GENERATOR, IdGenerator } from "./reducer";

export const DEFAULT_STATE_COLOR = newColors.primaryAccent;

export const createDefaultInputParameter = (
  name: string,
  attrType: AttributeTypes
): InputParameter => {
  const type = getDefaultComponentType(attrType);
  if (!type) {
    throw new Error(`Components are not supported for attribute type: ${attrType}`);
  }
  const pkg = findSpaceComponentPackage(type);
  return {
    name,
    type: ParameterType.COMPONENT,
    componentType: type,
    componentProperties: getComponentProperties(type, attrType, pkg)
  };
};

export const createDefaultFieldInputParameter = (
  fieldKey: string | null,
  name: string,
  attrType: AttributeTypes
): FieldInputParameter => ({
  fieldKey: fieldKey,
  ...createDefaultInputParameter(name, attrType)
});

export const getDefaultConfigState = (
  idGenerator: IdGenerator = DEFAULT_GENERATOR
): ConfigState => {
  const fieldKey = idGenerator();
  const fields = [
    {
      key: fieldKey,
      name: "My custom field",
      type: AttributeTypes.STRING,
      required: true
    }
  ];

  return {
    id: undefined,
    name: "Untitled queue",
    description: "",
    createTaskInputParameters: [
      createDefaultFieldInputParameter(null, "title", AttributeTypes.STRING),
      ...fields.map<FieldInputParameter>(f =>
        createDefaultFieldInputParameter(f.key, f.name, f.type)
      )
    ],
    actions: [],
    fields,
    states: [
      {
        name: "New",
        key: idGenerator(),
        color: DEFAULT_STATE_COLOR,
        isArchive: false,
        assignmentExpanded: true,
        subscriptions: { beforeEnter: [] }
      }
    ],
    transitions: [],
    selectedStateIndex: 0,
    _references: {}
  };
};

export const linkInputParameters = (
  fields: Field[],
  inputParameters: InputParameter[]
): FieldInputParameter[] => {
  const cache = cacheBy(fields, "name");
  return inputParameters.map(ip => {
    const field = cache[ip.name];
    return {
      ...ip,
      fieldKey: field?.key || null
    };
  });
};

export const toState = (node: QueueNode): ConfigState => {
  const fields: Field[] = node.fields.map(f => ({
    key: f.id,
    id: f.id,
    name: f.name,
    required: f.required,
    type: f.type
  }));

  return {
    id: node.id,
    name: node.name,
    description: node.description,
    icon: node.icon,
    color: node.color,
    selectedStateIndex: 0,
    createTaskInputParameters: linkInputParameters(
      fields,
      node.createTaskForm.inputParameters
    ),
    actions: node.actions.map(a => ({
      id: a.id,
      key: a.id,
      type: a.type,
      options: a.options
    })),
    fields,
    states: node.states.map(s => ({
      id: s.id,
      key: s.id,
      name: s.name,
      color: ensureHexValue(s.color),
      isArchive: s.isArchive,
      assignmentExpanded: true,
      subscriptions: {
        beforeEnter: getActionIndexList(
          node.actions,
          s.subscriptions,
          Event.BEFORE_ENTER
        )
      }
    })),
    transitions: node.transitions.map(t => ({
      key: t.id,
      id: t.id,
      name: t.name,
      isPrimary: t.isPrimary,
      fromStateKey: t.fromState.id,
      toStateKey: t.toState.id,
      subscriptions: {
        beforeExecute: getActionIndexList(
          node.actions,
          t.subscriptions,
          Event.BEFORE_EXECUTE
        )
      }
    })),
    _references: _getReferenceCounts(node.actions, [
      ...node.states,
      ...node.transitions
    ])
  };
};

export const toQueueInput = (state: ConfigState): QueueInput => {
  return {
    id: state.id,
    name: state.name,
    description: state.description,
    icon: state.icon,
    color: state.color,
    createTaskInputParameters: state.createTaskInputParameters.map<InputParameter>(
      ip => {
        switch (ip.type) {
          case ParameterType.COMPONENT:
            const cip: ComponentInputParameter = {
              name: ip.name,
              type: ip.type,
              componentType: ip.componentType,
              componentProperties: ip.componentProperties
            };
            return cip;
          case ParameterType.BINDING:
            const bip: BindingInputParameter = {
              name: ip.name,
              type: ip.type,
              binding: ip.binding,
              resolver: ip.resolver
            };
            return bip;
          case ParameterType.TEMPLATE:
            const tip: TemplateInputParameter = {
              name: ip.name,
              type: ip.type,
              template: ip.template,
              resolver: ip.resolver
            };
            return tip;
          case ParameterType.STATIC:
            const staticParam: StaticInputParameter = {
              name: ip.name,
              type: ip.type,
              value: ip.value
            };
            return staticParam;
          case ParameterType.DATE_TODAY: {
            const generatedParam: InputParameterBase<ParameterType.DATE_TODAY> = {
              name: ip.name,
              type: ip.type
            };
            return generatedParam;
          }
          case ParameterType.DATETIME_NOW: {
            const generatedParam: InputParameterBase<ParameterType.DATETIME_NOW> = {
              name: ip.name,
              type: ip.type
            };
            return generatedParam;
          }
          case ParameterType.TIME_NOW: {
            const generatedParam: InputParameterBase<ParameterType.TIME_NOW> = {
              name: ip.name,
              type: ip.type
            };
            return generatedParam;
          }
          case ParameterType.UUID: {
            const generatedParam: InputParameterBase<ParameterType.UUID> = {
              name: ip.name,
              type: ip.type
            };
            return generatedParam;
          }
          default:
            return assertNever(ip);
        }
      }
    ),
    actions: state.actions.map(h => {
      if (!h.type) throw new Error("missing action type");
      if (!h.options) throw new Error("missing action options");
      return {
        id: h.id,
        type: h.type.toLowerCase(), // TODO: This input field should accept Enum values
        options: h.options
      };
    }),
    fields: state.fields.map(f => ({
      id: f.id,
      name: f.name,
      type: f.type,
      required: f.required
    })),
    states: state.states.map(s => ({
      id: s.id,
      key: s.key,
      name: s.name,
      color: s.color.substring(1),
      isArchive: s.isArchive,
      subscriptions: { ...s.subscriptions, beforeExit: [] }
    })),
    transitions: state.transitions.map(t => {
      if (!t.toStateKey) throw new Error("invalid transition");
      return {
        id: t.id,
        name: t.name,
        isPrimary: t.isPrimary,
        fromStateKey: t.fromStateKey,
        toStateKey: t.toStateKey,
        subscriptions: t.subscriptions
      };
    }),
    moveTasks: state.moveTasks
      ? state.moveTasks.map(mt => ({
          fromStateKey: mt.fromStateKey,
          toStateKey: mt.toStateKey
        }))
      : undefined
  };
};

export const getActionIndexList = (
  actions: ActionNode[],
  subscriptions: SubscriptionNode[],
  event: Event
): number[] => {
  const sorted = [...subscriptions].sort((a, b) => a.order - b.order);
  return sorted.reduce<number[]>((acc, s) => {
    if (s.event !== event) return acc;

    const found = actions.findIndex(a => a.id === s.action.id);
    if (found === -1) throw new Error("missing action");
    acc.push(found);
    return acc;
  }, []);
};

const _getReferenceCounts = (
  actions: ActionNode[],
  dispatchers: { subscriptions: SubscriptionNode[] }[]
) => {
  return dispatchers.reduce<Record<string, number>>((acc, dispatcher) => {
    dispatcher.subscriptions.forEach(s => {
      acc[s.action.id] = (acc[s.action.id] || 0) + 1;
    });
    return acc;
  }, {});
};

// moved from FormBuilderModal/utils

const fieldsToTaskSchema = (fields: Field[]): Binding[] => [
  {
    name: "id",
    title: "Id",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "title",
    title: "Title",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "assigneeType",
    title: "Assignee Type",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "assigneeRoleId",
    title: "Role Id",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "assigneeUserId",
    title: "User Id",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "createdAt",
    title: "Created At",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.DATETIME
  },
  {
    name: "updatedAt",
    title: "Updated At",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.DATETIME
  },
  {
    name: "fieldValues",
    title: "Field Values",
    shape: BindingShape.OBJECT,
    attributes: fields.map(f => ({
      name: f.name,
      title: f.name,
      shape: BindingShape.SCALAR,
      type: f.type
    }))
  }
];

export const getTaskSchemaFromFields = (fields: Field[]) => {
  const taskSchema = fieldsToTaskSchema(fields);
  return {
    task: {
      title: "Task",
      schema: taskSchema
    }
  };
};

export const fieldsToCreateFunctionNode = (fields: Field[]): FunctionNode => {
  return {
    functionParameters: {
      edges: [
        {
          node: {
            name: "title",
            required: true,
            type: AttributeTypes.STRING
          }
        },
        {
          node: {
            name: AssigneeParameter.TYPE,
            required: false,
            type: AttributeTypes.STRING
          }
        },
        {
          node: {
            name: AssigneeParameter.ROLE_ID,
            required: false,
            type: AttributeTypes.STRING
          }
        },
        {
          node: {
            name: AssigneeParameter.USER_ID,
            required: false,
            type: AttributeTypes.STRING
          }
        },
        ...fields.map(f => ({
          node: { name: f.name, required: f.required, type: f.type }
        }))
      ]
    }
  };
};
