import React from "react";

import { SimpleAttributeValueProps, AttributeValueProps } from "..";
import { AttributeTypes } from "../../../../constants";
import { formatDecimal, formatFloat } from "../../utils";

export function NumberValueBase({
  className = "",
  value,
  type
}: SimpleAttributeValueProps) {
  let formattedValue = value;
  switch (type) {
    case AttributeTypes.DECIMAL:
      formattedValue = formatDecimal(value, { groupSeparator: "," });
      break;
    case AttributeTypes.FLOAT:
      formattedValue = formatFloat(value);
      break;
    case AttributeTypes.INT:
      formattedValue = parseInt(value).toString();
      break;
    default:
      formattedValue = String(value);
  }
  return <div className={className}>{formattedValue}</div>;
}

export default function NumberValue({
  className = "",
  attribute,
  value
}: AttributeValueProps) {
  let formattedValue = value;
  switch (attribute.sourceType) {
    case AttributeTypes.DECIMAL:
      formattedValue = formatDecimal(value, { groupSeparator: "," });
      break;
    case AttributeTypes.FLOAT:
      formattedValue = formatFloat(value);
      break;
    case AttributeTypes.INT:
      formattedValue = attribute.sourcePrimaryKey
        ? parseInt(value)
        : parseInt(value).toString();
      break;
    default:
      formattedValue = String(value);
  }
  return <div className={className}>{formattedValue}</div>;
}
