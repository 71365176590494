import styled from "styled-components";

import { colors } from "../../../../../../cssConstants";
import { C2 } from "../../../../../common/StyledComponents";

export const StateHeading = styled(C2)`
  color: ${colors.surfaceSecondary};
`;

export const StateList = styled.div`
  margin-top: 16px;
`;

export const StateItemContainer = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
`;

export const StateName = styled.div`
  margin-left: 10px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
  min-width: 40px;
`;

export const Hr = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.newContainerPrimaryTint};
  margin: 16px 0;
`;
