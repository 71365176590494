import React from "react";

import styled from "styled-components";

const Container = styled.div`
  margin: 100px auto;
  max-width: 1000px;
`;

const TermsOfService = () => {
  return (
    <Container>
      <h1>INTERNAL SERVICES AGREEMENT</h1>

      <p>Last Updated December 11, 2020</p>

      <h2>1. SERVICES AND SUPPORT</h2>
      <p>
        1.1 Console Corp. (“Internal”) provides its cloud-based data management and
        analytics platform and services, which may include Software (as defined below),
        applications and/or hosted subscription services (collectively “Service(s)”) to
        you (“Customer”) pursuant to this Internal Services Agreement (the “Agreement”).
        By accepting this Agreement, either by clicking a box indicating your
        acceptance, or by entering into a Services Order Form (or other ordering
        document) or Services-related agreement with Internal (each an “Order Form”) or
        otherwise registering for, accessing or using the Services, Customer
        unconditionally accepts and agrees to all of the terms of this Agreement. By
        entering into this agreement on behalf of a company or other legal entity,
        Customer represents that it has the authority to bind such entity and its
        affiliates to the terms of this Agreement, and, accordingly, the terms
        "Customer" shall refer to such entity and its affiliates. If Customer does not
        have such authority, or Customer does not agree to all of the terms of this
        Agreement, Customer may not use the Services. Subject to the terms of this
        Agreement, Internal will use commercially reasonable efforts to provide Customer
        (a) the Services solely for Customer’s business operations in accordance with
        the terms and limitations of each Order Form, and (b) reasonable support
        services, through electronic mail or another online mechanism, in accordance
        with Internal’s standard practice. Capitalized terms not defined herein shall be
        given the meaning set forth in the applicable Order Form. We have implemented
        and will maintain commercially reasonable measures intended to avoid unplanned
        Service interruptions. We will use commercially reasonable efforts to notify you
        in advance of planned Service interruptions. In the event of an unplanned
        Service interruption, you may contact us for technical support services, as
        described in this Agreement.
      </p>
      <p>
        1.2 Internal reserves the right to change or modify portions of this Agreement
        at any time. If Internal does so, it will post the changes on this page and will
        indicate at the top of this page the date this Agreement was last revised.
        Internal will also notify Customer, either through the Services user interface,
        in an email notification or through other reasonable means. Any such changes
        will become effective no earlier than fourteen (14) days after being posted,
        except that changes addressing new functions of the Services or changes made for
        legal reasons may become effective immediately. Customer’s continued use of the
        Service after the date any such changes become effective constitutes acceptance
        of the new Agreement.
      </p>
      <p>
        1.3 If Customer receives free access or a trial or evaluation subscription to
        the Service (a "Trial Subscription"), then Customer may use the Services in
        accordance with the terms and conditions of this Agreement for a period of
        fourteen (14) days or such other period granted by Internal (the "Trial
        Period"). Trial Subscriptions are permitted solely for Customer's use to
        determine whether to purchase a paid subscription to the Services. Trial
        Subscriptions may not include all functionality and features accessible as part
        of a paid Service Term. If Customer does not enter into a paid Service Term,
        this Agreement and Customer's right to access and use the Services will
        terminate at the end of the Trial Period. Internal has the right to terminate a
        Trial Subscription at any time for any reason. NOTWITHSTANDING ANYTHING TO THE
        CONTRARY IN THIS AGREEMENT, INTERNAL WILL HAVE NO WARRANTY, INDEMNITY, SUPPORT,
        OR OTHER OBLIGATIONS WITH RESPECT TO TRIAL SUBSCRIPTIONS.
      </p>

      <h2>2. RESTRICTIONS AND RESPONSIBILITIES</h2>
      <p>
        2.1 Customer will only use the Services as expressly permitted herein and in the
        applicable Order Form and agrees that it will not, directly or indirectly:
        (i)reverse engineer, decompile, disassemble or otherwise attempt to discover the
        source code, object code or underlying structure, ideas, know-how or algorithms
        relevant to the Services or any software, documentation or data related to the
        Services (“Software”); (ii) modify, translate, or create derivative works based
        on the Services or any Software (except to the extent expressly permitted by
        Internal or authorized within the Services); (iii) use the Services or any
        Software for timesharing or service bureau purposes or otherwise for the benefit
        of a third; (iv) use the Services or Software in violation of any applicable
        laws and regulations; or (v)remove any proprietary notices or labels; or modify,
        adapt or hack the Services, or otherwise attempt to gain unauthorized access to
        the Service or its related systems or networks. With respect to any Software (in
        any form) that is provided to Customer, Internal hereby grants Customer a
        non-exclusive, non-transferable, non-sublicensable license to use such Software
        solely in connection with the Services and for no other purpose. All Software is
        Confidential Information of Internal and subject to the terms of Section 3.
      </p>
      <p>
        2.2 Customer represents, covenants, and warrants that Customer will use the
        Services only in compliance with Internal’s standard published policies and
        codes of conduct then in effect and all applicable laws and regulations
        (including, without limitation, those relevant to privacy, spam, intellectual
        property and the like). Customer hereby agrees to indemnify and hold harmless
        Internal against any damages, losses, liabilities, settlements and expenses
        (including without limitation costs and attorneys’ fees) in connection with any
        claim or action that arises from an alleged violation of any of the foregoing or
        otherwise from Customer’s use of Services. Although Internal has no obligation
        to monitor Customer’s use of the Services, Internal may do so and may prohibit
        any use of the Services (or disable content or data) it believes may be (or
        alleged to be) in violation of the foregoing or any other term of this
        Agreement.
      </p>
      <p>
        2.3 Customer shall be responsible for obtaining and maintaining any equipment
        and ancillary services needed to connect to, access or otherwise use the
        Services, including, without limitation, modems, hardware, server, software,
        operating system, networking, web servers and the like (collectively,
        “Equipment”). Customer shall also be responsible for maintaining the security of
        the Equipment, Customer account, passwords (including but not limited to
        administrative and user passwords) and files, and for all uses of Customer
        account or the Equipment with or without Customer’s knowledge or consent.
      </p>

      <h2>3. CONFIDENTIALITY; PROPRIETARY RIGHTS</h2>
      <p>
        3.1 Each party (the “Receiving Party”) understands that the other party (the
        “Disclosing Party”) has disclosed or may disclose business, technical or
        financial information relating to the Disclosing Party’s business (hereinafter
        referred to as “Proprietary Information” of the Disclosing Party). Proprietary
        Information of Internal includes all Software and other non-public information
        regarding features, functionality and performance of the Service. Proprietary
        Information of Customer includes non-public data provided by Customer to Company
        to enable the provision of the Services (“Customer Data”). The Receiving Party
        agrees: (i) to take reasonable precautions to protect such Proprietary
        Information, and (ii) not to use (except in performance of the Services or as
        otherwise permitted herein) or divulge to any third person any such Proprietary
        Information. The Disclosing Party agrees that the foregoing shall not apply with
        respect to any information that the Receiving Party can document (a) is or
        becomes generally available to the public, or (b) was in its possession or known
        by it prior to receipt from the Disclosing Party, or (c) was rightfully
        disclosed to it without restriction by a third party, or (d) was independently
        developed without use of any Proprietary Information of the Disclosing Party.
        The Receiving Party may disclose Proprietary Information as required by law, in
        which case the Receiving Party will provide the Disclosing Party with prior
        written notification thereof, will provide the Disclosing Party with the
        opportunity to contest such disclosure, and will use its reasonable efforts to
        minimize such disclosure to the extent permitted by applicable law.
      </p>
      <p>
        3.2 Internal shall own and retain all right, title and interest in and to (a)
        the Services and Software, and all improvements, enhancements or modifications
        thereto, (b) any software, applications, inventions or other technology
        developed in connection with Services or support, and (c) all intellectual
        property rights related to any of the foregoing. Customer shall own all right,
        title and interest to the Customer Data. Notwithstanding anything to the
        contrary, Internal shall have the right to collect and analyze data and
        information relating to the use and performance of various aspects of the
        Services and related technologies (including, without limitation, IP addresses,
        URLs, network traffic information and the like), and Internal will be free
        (during and after the term hereof) to (i) use such information and data to
        provide, improve and enhance the Services and other Internal offerings, and (ii)
        disclose such data solely in aggregate or other de-identified form in connection
        with its business. Further, Internal shall have the right to use Customer’s name
        in a factual manner for marketing or promotional purposes on Internal’s website
        and in other communication with existing or potential Internal customers. To
        decline Internal this right, Customer must email Internal (at the email address
        provided in the Service) stating that Customer does not wish to grant Internal
        this right. No rights or licenses are granted except as expressly set forth
        herein.
      </p>

      <h2>4. DATA SECURITY</h2>
      <p>
        4.1 Internal will maintain a security program materially in accordance with
        industry standards that is designed to (i) ensure the security and integrity of
        Customer Data uploaded or otherwise transferred by or on behalf of Customer to
        Internal in performance of the Services; (ii) protect against threats or hazards
        to the security or integrity of Customer Data; and (iii) prevent unauthorized
        access to Customer Data. In furtherance of the foregoing, Internal will maintain
        administrative, physical and technical safeguards to protect the security of
        Customer Data. Internal’s security safeguards include measures for preventing
        access, use, modification or disclosure of Customer Data by Internal personnel
        except (a) to provide the Services and prevent or address service or technical
        problems, (b) as required by applicable law, or (c) as Customer expressly
        permits in writing or under this Agreement. Internal will not materially
        diminish the protections provided in this Section during the term of this
        Agreement.
      </p>
      <p>
        4.2 Customer acknowledges that use of the Service involves transmission of
        Customer Data and other communications over the Internet and other networks, and
        that such transmissions could potentially be accessed by unauthorized parties.
        Customer must protect its login names and passwords from access or use by
        unauthorized parties, and are solely responsible for any failure to do so.
        Customer must promptly notify us of any suspected security breach at
        support@internal.io.
      </p>
      <p>
        4.3 The Services do not include the storage or archiving of Customer Data
        without Customer’s express consent. Subject to Customer’s consent to receive
        such storage or archiving services, Internal agrees only that it will not
        intentionally delete any Customer Data from the Services prior to termination of
        Customer's applicable Service Term. Internal expressly disclaims all other
        obligations with respect to storage and archiving of Customer Data.
      </p>

      <h2>5. PAYMENT OF FEES</h2>
      <p>
        5.1 Customer will pay Internal the then applicable fees for the Services as
        described in the relevant Order Form (or in the Service itself, as applicable)
        in accordance with the terms therein (the “Fees”). All Fees are non-cancelable
        and non-refundable regardless of any early termination of this Agreement. If
        Customer’s use of the Services exceeds any applicable limits set forth on the
        Order Form or otherwise requires the payment of additional fees (per the terms
        of this Agreement), Customer shall be billed for such usage and Customer agrees
        to pay the additional fees in the manner provided herein. Internal reserves the
        right to change the Fees or applicable charges and to institute new charges and
        Fees at the end of the initial Service Term or the current renewal term, as
        specified in the Order Form, upon thirty (30) days prior notice to Customer
        (which may be sent by email). If Customer believes that Internal has billed
        Customer incorrectly, Customer must contact Internal no later than 60 days after
        the closing date on the first billing statement in which the error or problem
        appeared, in order to receive an adjustment or credit. Inquiries should be
        directed to Internal’s customer support department.
      </p>
      <p>
        5.2 Internal may also choose to bill through an invoice, in which case, full
        payment for invoices issued in any given month must be received by Internal
        thirty (30) days after the mailing date of the invoice. Unpaid amounts are
        subject to a finance charge of 1.5% per month on any outstanding balance, or the
        maximum permitted by law, whichever is lower, plus all expenses of collection
        and may result in immediate termination of Service. Customer shall be
        responsible for all taxes associated with Services other than U.S. taxes based
        on Internal’s net income.
      </p>

      <h2>6. TERMINATION</h2>
      <p>
        6.1 Subject to earlier termination as provided below, this Agreement is for the
        Service Term as specified in the Order Form, and shall be automatically renewed
        for additional periods of the same duration as the Service Term (collectively,
        the “Term”), unless either party requests termination at least thirty (30) days
        prior to the end of the then-current term.
      </p>
      <p>
        6.2 In addition to any other remedies it may have, either party may also
        terminate this Agreement (i) upon thirty (30) days’ notice (or without notice in
        the case of nonpayment), if the other party materially breaches any of the terms
        or conditions of this Agreement and such breach is not cured during the notice
        period. Internal may also reasonably suspend Customer’s and/or any users’ access
        to Services at any time in its reasonable discretion if it possesses a good
        faith belief that Customer’s use of the Service may be in violation of this
        Agreement or otherwise place Internal (or its customers or other interests) at
        risk of harm, damage, loss or liability. Upon termination, Customer’s right to
        use the Services shall immediate terminate, all outstanding Fees due for the
        Services for the entire Services Term (regardless of any early termination)
        shall immediately become due and payable, Customer shall return (or at
        Internal’s option destroy) all Software, and each party shall return to the
        other all Proprietary Information. Notwithstanding the foregoing, upon any
        termination, Internal will make all Customer Data available to Customer for
        electronic retrieval for a period of thirty (30) days, but thereafter Internal
        may, but is not obligated to, delete stored Customer Data. All sections of this
        Agreement which by their nature should survive termination will survive
        termination, including, without limitation, all rights to payment of Fees,
        confidentiality obligations, warranty disclaimers, and limitations of liability.
      </p>

      <h2>7. WARRANTY AND DISCLAIMERS</h2>
      <p>
        Internal shall use reasonable efforts consistent with prevailing industry
        standards to maintain the Services in a manner which minimizes errors and
        interruptions in the Services. Services may be temporarily unavailable for
        scheduled maintenance or for unscheduled emergency maintenance, either by
        Internal or by third-party providers, or because of other causes beyond
        Internal’s reasonable control, but Internal shall use reasonable efforts to
        provide advance notice in writing or by e-mail of any scheduled service
        disruption. HOWEVER, INTERNAL DOES NOT WARRANT THAT THE SERVICES WILL BE
        UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS
        THAT MAY BE OBTAINED FROM USE OF THE SERVICES. EXCEPT AS EXPRESSLY SET FORTH IN
        THIS SECTION, THE SERVICES ARE PROVIDED “AS IS” AND INTERNAL DISCLAIMS ALL
        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
        NONINFRINGEMENT.
      </p>

      <h2>8. INDEMNITY</h2>
      <p>
        Internal shall hold Customer harmless from liability to third parties resulting
        from infringement by the Service of any United States patent or any copyright or
        misappropriation of any trade secret, provided Internal is promptly notified of
        any and all threats, claims and proceedings related thereto and given reasonable
        assistance and the opportunity to assume sole control over defense and
        settlement; Internal will not be responsible for any settlement it does not
        approve in writing. The foregoing obligations do not apply with respect to
        portions or components of the Service (i) not supplied by Internal, (ii) made in
        whole or in part in accordance with Customer specifications, (iii) that are
        modified after delivery by Internal, (iv) combined with other products,
        processes or materials where the alleged infringement relates to such
        combination, (v) where Customer continues allegedly infringing activity after
        being notified thereof or after being informed of modifications that would have
        avoided the alleged infringement, or (vi) where Customer’s use of the Service is
        not strictly in accordance with this Agreement. If, due to a claim of
        infringement, the Services are held by a court of competent jurisdiction to be
        or are believed by Internal to be infringing, Internal may, at its option and
        expense (a) replace or modify the Service to be non-infringing provided that
        such modification or replacement contains substantially similar features and
        functionality, (b) obtain for Customer a license to continue using the Service,
        or (c) if neither of the foregoing is commercially practicable, terminate this
        Agreement and Customer’s rights hereunder and provide Customer a refund of any
        prepaid, unused fees for the Service. This Section states Internal’s sole and
        exclusive liability and obligation, and Customer’s exclusive remedy, for any
        claim of any nature related to infringement or misappropriation of intellectual
        property.
      </p>

      <h2>9. LIMITATION OF LIABILITY</h2>
      <p>
        NOTWITHSTANDING ANYTHING TO THE CONTRARY, INTERNAL AND ITS SUPPLIERS (INCLUDING
        BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS,
        AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE
        OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND
        CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR
        OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR
        CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR
        TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL,
        SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND INTERNAL’S
        REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS
        ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES RECEIVED BY INTERNAL FOR THE
        SERVICES UNDER THIS AGREEMENT IN THE TWELVE (12) MONTHS PRIOR TO THE ACT THAT
        GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT INTERNAL HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>

      <h2>10. GOVERNMENT MATTERS</h2>
      <p>
        Customer may not remove or export from the United States or allow the export or
        re-export of the Services, Software or anything related thereto, or any direct
        product thereof in violation of any restrictions, laws or regulations of the
        United States Department of Commerce, the United States Department of Treasury
        Office of Foreign Assets Control, or any other United States or foreign agency
        or authority. As defined in FAR section 2.101, the Software and documentation
        are “commercial items” and according to DFAR section 252.2277014(a)(1) and (5)
        are deemed to be “commercial computer software” and “commercial computer
        software documentation.” Consistent with DFAR section 227.7202 and FAR section
        12.212, any use modification, reproduction, release, performance, display, or
        disclosure of such commercial software or commercial software documentation by
        the U.S. Government will be governed solely by the terms of this Agreement and
        will be prohibited except to the extent expressly permitted by the terms of this
        Agreement.
      </p>

      <h2>11. MISCELLANEOUS</h2>
      <p>
        If any provision of this Agreement is found to be unenforceable or invalid, that
        provision will be limited or eliminated to the minimum extent necessary so that
        this Agreement will otherwise remain in full force and effect and enforceable.
        This Agreement is not assignable, transferable or sublicensable by Customer
        except with Internal’s prior written consent. Internal may transfer and assign
        any of its rights and obligations under this Agreement without consent. Except
        to the extent the parties have mutually executed and delivered a separate
        written agreement covering the same Internal Services (a “Separate Signed
        Agreement”), this Agreement is the complete and exclusive statement of the
        mutual understanding of the parties and supersedes and cancels all previous
        written and oral agreements, communications and other understandings relating to
        the subject matter of this Agreement, and all waivers and modifications must be
        in a writing signed by both parties, except as otherwise provided herein. In the
        event of a conflict between the terms of this Agreement and the terms of a
        Separate Signed Agreement, the terms of the Separate Signed Agreement shall
        supersede and control. However, any different or additional terms of any
        purchase order, confirmation, or similar pre-printed form will have no force or
        effect. No agency, partnership, joint venture, or employment is created as a
        result of this Agreement and Customer does not have any authority of any kind to
        bind Internal in any respect whatsoever. In any action or proceeding to enforce
        rights under this Agreement, the prevailing party will be entitled to recover
        costs and attorneys’ fees. All notices under this Agreement will be in writing
        and will be deemed to have been duly given when received, if personally
        delivered; when receipt is electronically confirmed, if transmitted by facsimile
        or e-mail; the day after it is sent, if sent for next day delivery by recognized
        overnight delivery service; and upon receipt, if sent by certified or registered
        mail, return receipt requested. This Agreement shall be governed by the laws of
        the State of California without regard to its conflict of laws provisions.
      </p>
    </Container>
  );
};
export default TermsOfService;
