import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectDefaultValueErrors from "../../common/DefaultValueField/selectErrors";
import selectValidationRulesErrors from "../../common/ValidationField/selectValidationRulesErrors";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

export default function errorSelector({
  draftComponent
}: ComponentConfigState): ConfigValidationError[] {
  return selectDefaultValueErrors(draftComponent)
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(selectValidationRulesErrors(draftComponent));
}
