export interface PublishPanelState {
  environmentsToPublish: Set<string>;
  allEnvironmentIds: string[];
  dirty: boolean;
  submitted: boolean;
}

type PublishPanelAction =
  | { type: "HANDLE_SAVE" }
  | { type: "SUBMIT" }
  | { type: "SELECT_ALL" }
  | { type: "CLEAR_ALL" }
  | {
      type: "SET_ENVIRONMENT";
      payload: {
        id: string;
        includeEnvironment: boolean;
      };
    }
  | {
      type: "SET_ALL_ENVIRONMENTS";
      payload: {
        environmentIds: string[];
      };
    };

export const initialState: PublishPanelState = {
  allEnvironmentIds: [],
  environmentsToPublish: new Set<string>(),
  dirty: false,
  submitted: false
};

function reducer(
  state: PublishPanelState,
  action: PublishPanelAction
): PublishPanelState {
  switch (action.type) {
    case "SUBMIT": {
      return { ...state, submitted: true };
    }

    case "HANDLE_SAVE": {
      return { ...state, dirty: false };
    }
    case "SET_ALL_ENVIRONMENTS": {
      return {
        ...state,
        dirty: true,
        allEnvironmentIds: action.payload.environmentIds
      };
    }
    case "SET_ENVIRONMENT": {
      const { id, includeEnvironment } = action.payload;
      const environmentsToPublish = new Set(state.environmentsToPublish);
      if (includeEnvironment) {
        environmentsToPublish.add(id);
      } else {
        environmentsToPublish.delete(id);
      }
      return { ...state, dirty: true, environmentsToPublish };
    }
    case "SELECT_ALL": {
      return {
        ...state,
        dirty: true,
        submitted: false,
        environmentsToPublish: new Set(state.allEnvironmentIds)
      };
    }
    case "CLEAR_ALL": {
      return {
        ...state,
        dirty: true,
        submitted: false,
        environmentsToPublish: new Set()
      };
    }
    default:
      return state;
  }
}

export default reducer;
