import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  BaseComponentProperties
} from "../../../../../types";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import { BaseComponentConfigState } from "../../../types";

import { getSchema } from "./getSchema";
import SpaceEnvironment from "./SpaceEnvironment";
import { SpaceEnvironmentComponent } from "./types";

const pkg: SpaceComponentPackage<
  BaseComponentProperties,
  SpaceEnvironmentComponent,
  BaseComponentConfigState
> = {
  type: "ENVIRONMENT",
  displayName: "Environment",
  allowedHosts: new Set<SpaceComponentHostType>([]),
  Component: SpaceEnvironment,
  ConfigurationComponent: () => null,
  ensureComponent: component => component,
  getSchema,
  componentConfigReducer: commonComponentReducer,
  isHeadless: true,
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isClientProvidedComponent: true,
  errorSelector: () => [],
  isContainer: false
};

export default pkg;
