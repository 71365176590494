// https://usehooks.com/usePrevious/
import React from "react";

export default function usePrevious<T>(
  value: T,
  options: { returnCurrentOnFirstRender: boolean } = {
    returnCurrentOnFirstRender: false
  }
): T | undefined {
  const ref = React.useRef<T>();
  // Handle first render
  if (ref.current === undefined) {
    ref.current = value;
    return options.returnCurrentOnFirstRender ? value : undefined;
  }
  const prevValue = ref.current;
  ref.current = value;

  return prevValue;
}
