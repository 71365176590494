/**
 * Editor component for GraphQL code input.
 * --
 * See FunctionEditor for UI notes.
 */
import React from "react";

import Editor from "../../../../Editor";
import { Label } from "../../../../StyledComponents";
import * as common from "../../styledComponents";

interface Props {
  query: string;
  variables: string;
  onQueryChange: (value: string, event?: any) => void;
  onVariablesChange: (value: string, event?: any) => void;
}

export default function GraphQL(props: Props) {
  return (
    <>
      <common.Section>
        <Label>GraphQL Query:</Label>
        <Editor mode="graphql" onChange={props.onQueryChange} value={props.query} />
      </common.Section>
      <common.Section>
        <Label>Query Variables:</Label>
        <Editor
          mode="javascript"
          onChange={props.onVariablesChange}
          value={props.variables}
        />
      </common.Section>
    </>
  );
}
