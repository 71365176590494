import Icon from "../../../../../assets/spaceicons/flex-box.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";

import Config from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceFlexBoxComponent,
  makeInitialState,
  FlexBoxConfigState
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceFlexBox from "./SpaceFlexBox";
import { SpaceFlexBoxComponent, SpaceFlexBoxProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceFlexBoxProperties,
  SpaceFlexBoxComponent,
  FlexBoxConfigState
> = {
  type: "FLEX_BOX",
  displayName: "Flex Box",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "CARD_LIST",
    "DETAIL",
    "FLEX_BOX"
  ]),
  Component: SpaceFlexBox,
  ConfigurationComponent: Config,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceFlexBoxComponent,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.STANDARD.height,
    snapToGrid: true
  }),
  allowSelfBinding: false,
  allowAncestorBinding: true,
  hasInlineChildren: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  isContainer: true,
  errorSelector
};

export default pkg;
