import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  ComponentInputParameter,
  Edge,
  InputParameter,
  RoleNode,
  UserNode
} from "../../../../../types";
import { SpaceDropdownProperties } from "../../../../spaces/SpaceRoot/SpaceComponent/SpaceDropdown/types";
import { findSpaceComponentPackage } from "../../../../spaces/SpaceRoot/SpaceContext/StableSpaceContext";
import { fromGlobalId } from "../../../../util/graphql";

import { AssigneeParameter, isPipelineAssigneeParameter } from "./utils";

export const GET_ALL_ROLES_AND_USERS = gql`
  query AllRolesAndUsers {
    allRoles {
      edges {
        node {
          id
          name
        }
      }
    }
    allUsers {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default function useAssigneeInputParameters() {
  const { data } = useQuery(GET_ALL_ROLES_AND_USERS, {
    fetchPolicy: "cache-and-network"
  });

  const roleOptions = React.useMemo(() => {
    return (
      data?.allRoles?.edges
        .map((e: Edge<RoleNode>) => e.node)
        .map((role: RoleNode) => {
          const id = fromGlobalId(role.id)[1];
          return `${role.name},${id}`;
        }) || []
    ).join("\n");
  }, [data]);

  const userOptions = React.useMemo(() => {
    return (
      data?.allUsers?.edges
        .map((e: Edge<UserNode>) => e.node)
        .map((user: UserNode) => {
          const id = fromGlobalId(user.id)[1];
          return `${user.firstName} ${user.lastName},${id}`;
        }) || []
    ).join("\n");
  }, [data]);

  const injectAssigneeInputParameters = React.useCallback(
    (inputParameters: InputParameter[] | undefined) => {
      const dropdownPkg = findSpaceComponentPackage("DROPDOWN");
      const params = inputParameters || [];
      return params.map(param => {
        if (isPipelineAssigneeParameter(param.name, AssigneeParameter.ROLE_ID)) {
          return {
            ...param,
            componentProperties: dropdownPkg?.getComponentProperties
              ? dropdownPkg.getComponentProperties({
                  ...(param as ComponentInputParameter).componentProperties,
                  hardcoded_options: roleOptions
                } as SpaceDropdownProperties)
              : {}
          };
        } else if (isPipelineAssigneeParameter(param.name, AssigneeParameter.USER_ID)) {
          return {
            ...param,
            componentProperties: dropdownPkg?.getComponentProperties
              ? dropdownPkg.getComponentProperties({
                  ...(param as ComponentInputParameter).componentProperties,
                  hardcoded_options: userOptions
                } as SpaceDropdownProperties)
              : {}
          };
        }
        return param;
      });
    },
    [roleOptions, userOptions]
  );

  return injectAssigneeInputParameters;
}
