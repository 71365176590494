import React from "react";

import { Select } from "antd";
import { padStart, range } from "lodash";
import styled from "styled-components";

import useStableId from "../hooks/useStableId";
import { MomentEditorProps } from "../MomentFormItemPopper";

const { Option } = Select;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const PNoMarginCentered = styled.p`
  align-self: center;
  margin: 0px ${props => props.theme.spacerxs} 0px ${props => props.theme.spacerxs};
`;

const StyledSelect = styled(Select)`
  height: ${props => props.theme.dateTimePickers.selectHeight};
  width: ${props => props.theme.dateTimePickers.selectHeight};

  .ant-select-selection {
    font-size: ${props => props.theme.h4FontSize};
    height: 100%;

    .ant-select-selection__rendered {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;
    }
  }

  &.last {
    margin-left: ${props => props.theme.spacersm};
  }
`;

export default function Time(props: MomentEditorProps) {
  const { value, onChange } = props;
  const timeSelectId = useStableId("timeSelect");

  const updateHour = (hour: any) => {
    const isInPM = value.format("A") === "AM";
    onChange(value.clone().hour(isInPM ? hour : hour + 12));
  };

  const updateMinute = (minute: any) => {
    onChange(value.clone().minute(minute));
  };

  const updateSecond = (second: any) => {
    onChange(value.clone().second(second));
  };
  const updateAmPm = (meridiam: any) => {
    const nextValue = value.clone();
    if (meridiam === "AM" && meridiam !== value.format("A")) {
      nextValue.subtract(12, "hours");
    }
    if (meridiam === "PM" && meridiam !== value.format("A")) {
      nextValue.add(12, "hours");
    }
    onChange(nextValue);
  };

  return (
    <Container id={timeSelectId} data-test="hourMinuteSelect">
      <StyledSelect
        key="hour"
        showArrow={false}
        onSelect={updateHour}
        getPopupContainer={() => document.getElementById(timeSelectId) as HTMLElement}
        value={parseInt(value.format("h"))}
        data-test="timeSelect-hour"
        showSearch
      >
        {range(1, 13).map((key: number) => (
          <Option key={`hour-${key}`} value={key}>
            {padStart(String(key), 2, "0")}
          </Option>
        ))}
      </StyledSelect>
      <PNoMarginCentered>:</PNoMarginCentered>
      <StyledSelect
        key={"minute"}
        showArrow={false}
        onSelect={updateMinute}
        value={value.minute()}
        data-test="timeSelect-minute"
        getPopupContainer={() => document.getElementById(timeSelectId) as HTMLElement}
        showSearch
      >
        {range(0, 60).map((key: number) => (
          <Option key={`minute-${key}`} value={key}>
            {padStart(String(key), 2, "0")}
          </Option>
        ))}
      </StyledSelect>
      <PNoMarginCentered>:</PNoMarginCentered>
      <StyledSelect
        key="second"
        showArrow={false}
        onSelect={updateSecond}
        getPopupContainer={() => document.getElementById(timeSelectId) as HTMLElement}
        value={value.seconds()}
        data-test="timeSelect-second"
        showSearch
      >
        {range(0, 60).map((key: number) => (
          <Option key={`second-${key}`} value={key}>
            {padStart(String(key), 2, "0")}
          </Option>
        ))}
      </StyledSelect>
      <StyledSelect
        key={"meridiem"}
        className="last"
        onSelect={updateAmPm}
        showArrow={false}
        value={value.format("A")}
        data-test="timeSelect-amPm"
        getPopupContainer={() => document.getElementById(timeSelectId) as HTMLElement}
        showSearch
      >
        <Option key="AM" value="AM">
          AM
        </Option>
        <Option key="PM" value="PM">
          PM
        </Option>
      </StyledSelect>
    </Container>
  );
}
