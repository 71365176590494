import React from "react";

import { Calendar, Icon } from "antd";
import moment from "moment";
import styled from "styled-components";

import { MomentEditorProps } from "../MomentFormItemPopper";

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function Date(props: MomentEditorProps) {
  const { value, onChange } = props;

  const updateCalendar = (newDate?: moment.Moment) => {
    if (!newDate) return;
    onChange(newDate.clone());
  };

  const renderHeader = ({
    value,
    onTypeChange
  }: {
    value: moment.Moment;
    onTypeChange: (m: any) => any;
  }) => {
    return (
      <CalendarHeader>
        <div>
          <Icon
            onClick={() => onTypeChange(value.year(value.year() - 1))}
            type="double-left"
          />
          <Icon
            onClick={() => onTypeChange(value.month(value.month() - 1))}
            type="left"
          />
        </div>
        <p>{value.format("MMMM YYYY")} </p>
        <div>
          <Icon
            onClick={() => onTypeChange(value.month(value.month() + 1))}
            type="right"
          />
          <Icon
            onClick={() => onTypeChange(value.year(value.year() + 1))}
            type="double-right"
          />
        </div>
      </CalendarHeader>
    );
  };

  return (
    <div className="calendarContainer">
      <Calendar
        headerRender={renderHeader}
        fullscreen={false}
        value={value}
        onChange={updateCalendar}
        onPanelChange={updateCalendar}
      />
    </div>
  );
}
