import React from "react";

import { SourceType } from "../../../../../../types";
import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import { ConfigSection, Checkbox } from "../../common/ConfigPanel";

export default function ExportConfig() {
  const {
    state: {
      draftComponent: {
        sourceType,
        properties: { is_csv_export_enabled }
      }
    },
    dispatch
  } = useComponentConfigContext();

  if (sourceType === SourceType.BINDING) return null;

  return (
    <ConfigSection title="Export">
      <Checkbox
        data-test="filters_required"
        checked={is_csv_export_enabled}
        onChange={({ target: { checked } }) => {
          dispatch({
            type: "MERGE_DRAFT_COMPONENT",
            payload: {
              change: { properties: { is_csv_export_enabled: checked } }
            }
          });
        }}
      >
        Allow this table to be exported as a CSV file.
      </Checkbox>
    </ConfigSection>
  );
}
