import React from "react";

import { Spin } from "antd";

import { LinkButtonNew } from "../../../common/StyledComponents";
import { WarningTooltip } from "../../../common/ToolTips";
import { StyledIcon } from "../common/styledComponents";

import { CenterCardContent, Header, Root, Title } from "./styledComponents";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again.";

interface Props {
  title: string;
  headerActionText?: string;
  loading?: boolean;
  className?: string;
  children: React.ReactNode;
  errorMessage?: string;
  onHeaderActionClick?: () => void;
}

export default function AnalyticCard({
  title,
  headerActionText,
  children,
  loading,
  className,
  errorMessage,
  onHeaderActionClick = () => null
}: Props) {
  return (
    <Root className={className}>
      <Header>
        <Title>{title}</Title>
        {headerActionText && (
          <LinkButtonNew onClick={() => onHeaderActionClick()}>
            {headerActionText}
          </LinkButtonNew>
        )}
      </Header>
      {!loading && errorMessage && (
        <CenterCardContent>
          <WarningTooltip
            message={""}
            description="Something went wrong. Please try again."
            placement="right"
          >
            <StyledIcon type="warning" />
          </WarningTooltip>
        </CenterCardContent>
      )}
      {!loading && !errorMessage && <>{children}</>}
      {loading && (
        <CenterCardContent>
          <Spin />
        </CenterCardContent>
      )}
    </Root>
  );
}
