import React, { useState } from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Table, Skeleton } from "antd";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import usePaths from "../../common/hooks/usePaths";
import IntegrationIcon from "../../common/IntegrationIcon";
import TitleContainer, { ContainerSize } from "../../common/TitleContainer";
import withScrollHandler, { ScrollHandlerProps } from "../../hoc/ScrollHandler";
import {
  CreateDataSourceResult,
  CreateDataSourceVariables,
  CREATE_DATA_SOURCE
} from "../CredentialsForm/queries";
import { SetupFooter } from "../SetupFooter";
import { Contents } from "../styledComponents";

import {
  AvailableDataSource,
  AvailableDataSourcesData,
  AvailableDataSourcesVariables,
  FETCH_AVAILABLE_DATA_SOURCES
} from "./queries";

// `as unknown` required here since typescript complains about Column and
// ColumnGroup being present on Table but not StyledTable.
//
// See: https://github.com/styled-components/styled-components/issues/1803
const StyledTable = styled(Table)`
  width: 100%;
` as unknown as typeof Table;

const Name = styled.span`
  display: flex;
  align-items: center;

  img {
    margin-right: ${props => props.theme.spacersm};
  }
`;

interface Params extends Record<string, string | undefined> {
  dataSourceProviderId: string;
}

const SelectDataSource = (props: ScrollHandlerProps) => {
  const navigate = useNavigate();
  const { dataSourceProviderId } = useParams<Params>();
  const { getDashboard } = usePaths();
  const [selected, setSelected] = useState<AvailableDataSource | null>(null);
  const [error, setError] = useState<string>("");

  const { data, loading } = useQuery<
    AvailableDataSourcesData,
    AvailableDataSourcesVariables
  >(FETCH_AVAILABLE_DATA_SOURCES, {
    variables: { dataSourceProviderId: dataSourceProviderId! }
  });

  const [createDataSource, { loading: submitting }] = useMutation<
    CreateDataSourceResult,
    CreateDataSourceVariables
  >(CREATE_DATA_SOURCE, {
    onCompleted: data => {
      const { createDefaultEnvironmentDataSource } = data;
      switch (createDefaultEnvironmentDataSource.__typename) {
        case "ClientErrorResult":
          setError(createDefaultEnvironmentDataSource.message);
          break;
        case "CreateDefaultEnvironmentDataSourceResultSuccess": {
          navigate(getDashboard());
          break;
        }
      }
    }
  });

  const integration = data?.dataSourceProvider?.integration || "";
  const available = data?.dataSourceProvider?.availableDataSources || [];
  return (
    <>
      <TitleContainer
        title="Which data source would you like to add?"
        size={ContainerSize.Large}
      >
        {!loading ? (
          <Contents>
            <StyledTable<AvailableDataSource>
              dataSource={available}
              rowKey="sourceName"
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  render: (text: string) => (
                    <Name>
                      <IntegrationIcon name={integration} /> {text}
                    </Name>
                  )
                }
              ]}
              pagination={false}
              scroll={{ y: true }}
              rowSelection={{
                type: "radio",
                getCheckboxProps: () => ({ disabled: submitting }),
                onSelect: dataSource => {
                  setSelected(dataSource);
                  setError("");
                }
              }}
            />
            <SetupFooter
              error={error}
              loading={submitting}
              buttonText="Continue"
              onClick={() => {
                if (!selected) {
                  setError("You must select a data source to continue.");
                  return;
                }

                setError("");
                const selection = selected as AvailableDataSource;
                createDataSource({
                  variables: {
                    dataSourceProviderId: dataSourceProviderId!,
                    dataSourceName: selection.sourceName,
                    name: selection.name
                  }
                });
              }}
              hasScrolled={props.hasScrolled}
            />
          </Contents>
        ) : (
          <Skeleton active={true} />
        )}
      </TitleContainer>
    </>
  );
};

export default withScrollHandler(SelectDataSource);
