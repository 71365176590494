import React from "react";

import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import { Headline } from "../common/styledComponents";

import { useNewTasksCreated } from "./queries";

export default function NewTasksCreatedStatistics({
  queueId,
  withinSeconds,
  className
}: {
  queueId?: string;
  withinSeconds: number;
  className?: string;
}) {
  const { data, loading, error } = useNewTasksCreated({
    variables: { queueId: queueId!, withinSeconds },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="New tasks created"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
    >
      <Headline>{data?.newTasksCreated}</Headline>
    </AnalyticCard>
  );
}
