import Icon from "../../../../../assets/spaceicons/image.svg";
import { SpaceComponentPackage, SpaceComponentHostType } from "../../../../../types";
import {
  ElementLayout,
  LayoutUnit,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS
} from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import SpaceViewlessImage from "./SpaceViewlessImage";
import {
  SpaceViewlessImageComponent,
  SpaceViewlessImageProperties,
  ViewlessImageComponentConfigState
} from "./types";
import ViewlessImageConfig from "./ViewlessImageConfig";
import { errorSelector } from "./ViewlessImageConfig/selector";
import { ensureViewlessImageComponent } from "./ViewlessImageConfig/ViewlessImageConfig";

const pkg: SpaceComponentPackage<
  SpaceViewlessImageProperties,
  SpaceViewlessImageComponent,
  ViewlessImageComponentConfigState
> = {
  type: "VIEWLESS_IMAGE",
  displayName: "Image",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "CARD_LIST",
    "DETAIL",
    "TABLE",
    "FLEX_BOX"
  ]),
  Component: SpaceViewlessImage,
  ConfigurationComponent: ViewlessImageConfig,
  ensureComponent: ensureViewlessImageComponent,
  componentConfigReducer: commonComponentReducer,
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    width: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL],
    height: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL]
  },
  defaultElementLayout: new ElementLayout({
    width: "100" + LayoutUnit.PERCENTAGE,
    height: "100" + LayoutUnit.PERCENTAGE,
    snapToGrid: true
  }),
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
