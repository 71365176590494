import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../layouts/Header/Header";
import { LeftCol } from "../../layouts/Layout";
import Nav from "../../layouts/Nav";
import { MainTitle } from "../common/StyledComponents";

import { AuthProviderList } from "./AuthProviders";
import PricingGrid from "./Billing";
import BillingSettingsContainer from "./Billing/BillingSettingsContainer";
import DataSourceList from "./DataSourceList";
import RolesAndPermissions from "./roles/RolesAndPermissions";
import SecuritySettings from "./SecuritySettings";
import UserSettings from "./UserSettings";

enum SettingsSection {
  USERS = "users",
  ROLES_AND_PERMISSIONS = "roles-and-permissions",
  DATA_SOURCES = "data-sources",
  SECURITY = "security",
  AUTH_PROVIDERS = "auth-providers",
  PRODUCTS = "change-plan",
  BILLING = "billing"
}

const SettingsSectionTitles = {
  [SettingsSection.USERS]: "Manage Users",
  [SettingsSection.ROLES_AND_PERMISSIONS]: "Manage Roles and Permissions",
  [SettingsSection.DATA_SOURCES]: "Manage Data Sources",
  [SettingsSection.SECURITY]: "Security",
  [SettingsSection.AUTH_PROVIDERS]: "Manage Authorization Providers",
  [SettingsSection.PRODUCTS]: "",
  [SettingsSection.BILLING]: "Manage account and billing"
};

interface Params extends Record<string, string> {
  tab: SettingsSection;
}

const SETTINGS_COMPONENTS = {
  [SettingsSection.USERS]: UserSettings,
  [SettingsSection.ROLES_AND_PERMISSIONS]: RolesAndPermissions,
  [SettingsSection.DATA_SOURCES]: DataSourceList,
  [SettingsSection.SECURITY]: SecuritySettings,
  [SettingsSection.AUTH_PROVIDERS]: AuthProviderList,
  [SettingsSection.PRODUCTS]: PricingGrid,
  [SettingsSection.BILLING]: BillingSettingsContainer
};

const Root = styled.div`
  border-left: solid 1px ${props => props.theme.borderGrey};
  padding: ${props => props.theme.spacerxl};
  padding-top: 48px;
  height: 100%;
  overflow: hidden;
`;

const PricingRoot = styled(Root)`
  padding: 0 32px;
`;

const SettingsComponentContainer = styled.div<{ paddingTop?: number }>`
  overflow: auto;
  height: 100%;
  padding-top: ${props => props.paddingTop || 0}px;
  margin-right: -${props => props.theme.spacerxl};
`;

const SettingsContainer = () => {
  const { tab = SettingsSection.USERS } = useParams<Params>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SettingComponent = SETTINGS_COMPONENTS[tab];

  if (!SettingComponent) {
    return null;
  }

  return (
    <>
      <PageHeader title="Settings" />
      <LeftCol open>
        <Nav />
      </LeftCol>

      {tab === "change-plan" ? (
        <PricingRoot>
          <SettingsComponentContainer paddingTop={48}>
            <SettingComponent />
          </SettingsComponentContainer>
        </PricingRoot>
      ) : (
        <Root>
          {SettingsSectionTitles[tab] && (
            <MainTitle>{SettingsSectionTitles[tab]}</MainTitle>
          )}
          <SettingsComponentContainer>
            <SettingComponent />
          </SettingsComponentContainer>
        </Root>
      )}
    </>
  );
};

export default SettingsContainer;
