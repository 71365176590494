import { SpaceComponentObject } from "../../../../../../../types";
import { ComponentConfigState } from "../../../../../types";
import { TreeNode } from "../reducer";

export default function insertComponentInTree(
  node: TreeNode | null,
  spaceComponents: Record<string, ComponentConfigState>,
  slugs: Set<string>,
  component: SpaceComponentObject,
  parentSlug: string | null
): TreeNode | null {
  if (node === null) return null;
  if (node.slug === parentSlug) {
    // Just append new component to current parent's children
    const newNode: TreeNode = {
      slug: component.slug,
      type: component.type,
      treeNodes: [],
      container: null
    };
    const newParent = {
      ...node,
      treeNodes: [...node.treeNodes, newNode]
    };
    newNode.container = newParent;
    return newParent;
  } else {
    node.treeNodes = node.treeNodes.map(
      ltn =>
        insertComponentInTree(
          ltn,
          spaceComponents,
          slugs,
          component,
          parentSlug
        ) as TreeNode
    );
  }
  return node;
}
