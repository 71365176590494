import { assertNever } from "../../../../../util/assertNever";
import { reportException } from "../../../../../util/exceptionReporting";

export enum ExportStatus {
  WAITING,
  START_FETCH,
  FETCHING,
  START_ENCODE,
  ENCODING,
  START_DOWNLOAD,
  DOWNLOADING,
  FAILED
}

export interface State {
  status: ExportStatus;
  rows?: any[][];
  csv?: string;
  error?: string;
}

export const initialState = Object.freeze({ status: ExportStatus.WAITING });

export function statusText(state: State) {
  switch (state.status) {
    case ExportStatus.WAITING:
      return "";
    case ExportStatus.START_FETCH:
    case ExportStatus.FETCHING:
      if (state.rows && state.rows.length > 0) {
        return `Loading data (${state.rows.length.toLocaleString()} records)…`;
      } else {
        return "Loading data…";
      }
    case ExportStatus.START_ENCODE:
    case ExportStatus.ENCODING:
      return "Generating CSV…";
    case ExportStatus.START_DOWNLOAD:
    case ExportStatus.DOWNLOADING:
      return "Downloading file…";
    case ExportStatus.FAILED:
      return "Export failed";
    default:
      assertNever(state.status);
  }
}

export enum ActionType {
  START,
  TRACK_FETCHING,
  TRACK_FETCHED_PAGE,
  TRACK_FETCHED,
  TRACK_ENCODING,
  TRACK_ENCODED,
  TRACK_DOWNLOADING,
  TRACK_ERROR,
  RESET,
  RETRY
}

export type Action =
  | { type: ActionType.START }
  | { type: ActionType.TRACK_FETCHING }
  | {
      type: ActionType.TRACK_FETCHED_PAGE;
      payload: { rows: any[][] };
    }
  | { type: ActionType.TRACK_FETCHED }
  | { type: ActionType.TRACK_ENCODING }
  | {
      type: ActionType.TRACK_ENCODED;
      payload: { csv: string };
    }
  | { type: ActionType.TRACK_DOWNLOADING }
  | {
      type: ActionType.TRACK_ERROR;
      payload: { message: string };
    }
  | { type: ActionType.RESET }
  | { type: ActionType.RETRY };

// Action              From State        To State
// ------              ----------        --------
// START               WAITING        -> START_FETCH
// TRACK_FETCHING      START_FETCH    -> FETCHING
// TRACK_FETCHED_PAGE  FETCHING       -> FETCHING (no transition)
// TRACK_FETCHED       FETCHING       -> START_ENCODE
// TRACK_ENCODING      START_ENCODE   -> ENCODING
// TRACK_ENCODED       ENCODING       -> START_DOWNLOAD
// TRACK_DOWNLOADING   START_DOWNLOAD -> DOWNLOADING
// TRACK_ERROR         *              -> FAILED
// RESET               *              -> WAITING
// RETRY               FAILED         -> START_FETCH
export default function reducer(state: State, action: Action) {
  function handleBadStateTransition(): State {
    reportException(new Error("Bad state transition attempted."), {
      extra: { currentStatus: state.status, actionType: action.type }
    });
    return { status: ExportStatus.FAILED, error: "An unknown error occurred." };
  }

  switch (action.type) {
    case ActionType.START:
      if (state.status !== ExportStatus.WAITING) {
        return handleBadStateTransition();
      }
      return { status: ExportStatus.START_FETCH };

    case ActionType.TRACK_FETCHING:
      if (state.status !== ExportStatus.START_FETCH) {
        return handleBadStateTransition();
      }
      return { status: ExportStatus.FETCHING, rows: [] };

    case ActionType.TRACK_FETCHED_PAGE:
      if (state.status !== ExportStatus.FETCHING || !state.rows) {
        return handleBadStateTransition();
      }
      return {
        status: ExportStatus.FETCHING,
        rows: state.rows!.concat(action.payload.rows)
      };

    case ActionType.TRACK_FETCHED:
      if (state.status !== ExportStatus.FETCHING) {
        return handleBadStateTransition();
      }
      return { status: ExportStatus.START_ENCODE, rows: state.rows };

    case ActionType.TRACK_ENCODING:
      if (state.status !== ExportStatus.START_ENCODE) {
        return handleBadStateTransition();
      }
      return { status: ExportStatus.ENCODING, rows: state.rows };

    case ActionType.TRACK_ENCODED:
      if (state.status !== ExportStatus.ENCODING) {
        return handleBadStateTransition();
      }
      return { status: ExportStatus.START_DOWNLOAD, csv: action.payload.csv };

    case ActionType.TRACK_DOWNLOADING:
      if (state.status !== ExportStatus.START_DOWNLOAD) {
        return handleBadStateTransition();
      }
      return { status: ExportStatus.DOWNLOADING, csv: state.csv };

    case ActionType.TRACK_ERROR:
      return { status: ExportStatus.FAILED, error: action.payload.message };

    case ActionType.RESET:
      return { status: ExportStatus.WAITING };

    case ActionType.RETRY:
      if (state.status !== ExportStatus.FAILED) {
        return handleBadStateTransition();
      }
      return { status: ExportStatus.START_FETCH };

    default:
      return assertNever(action);
  }
}
