import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import PageHeader from "../../../layouts/Header/Header";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import usePaths from "../../common/hooks/usePaths";
import Message from "../../common/Message";
import * as common from "../../common/StyledComponents";
import { tryError } from "../../util";
import { assertNever } from "../../util/assertNever";
import HomeButton from "../common/HomeButton";

import { Data } from "./Data";
import ErrorPanel from "./ErrorPanel";
import { General } from "./General";
import { QueueNode } from "./queries/common";
import * as QueueBySlug from "./queries/QueueBySlug";
import useSaveQueue from "./queries/SaveQueue";
import { ReducerActions } from "./reducer/actions";
import { createReducer } from "./reducer/reducer";
import { toQueueInput, getDefaultConfigState } from "./reducer/utils";
import State from "./State";
import * as styled from "./styledComponents";
import { Tab } from "./types";

const _reducer = createReducer();

interface PathParams extends Record<string, string> {
  environment: string;
  slug: string;
}

export const Config = () => {
  const [state, dispatch] = React.useReducer(_reducer, getDefaultConfigState());

  const navigate = useNavigate();
  const { state: navState } = useLocation();
  const { slug } = useParams<PathParams>();
  const [query] = useSearchParams();

  const { queuesapp_getEditQueue, queuesapp_getQueue } = usePaths();
  const tab = (query.get("tab") as Tab) || Tab.GENERAL;

  // Loads the queue after a save occurs (the URL may or may not have changed as a result of the save)
  React.useEffect(() => {
    const node = navState as QueueNode | undefined;
    if (!node?.id) return;

    dispatch({
      type: ReducerActions.LOAD_QUEUE,
      payload: navState as QueueNode
    });
  }, [navState]);

  // Loads the queue by the URL slug when there is no previous state
  const { loading } = useQuery<QueueBySlug.Data, QueueBySlug.Variables>(
    QueueBySlug.QUERY,
    {
      variables: { slug: slug! },
      skip: !slug || !!state.id,
      onCompleted: data => {
        dispatch({ type: ReducerActions.LOAD_QUEUE, payload: data.queueBySlug });
      }
    }
  );

  // Saves a queue and then redirects which triggers a queue load via location state
  const [save, { loading: saving }] = useSaveQueue({
    refetchQueries: ["AllTasks", "GetFunctionById"],
    onCompleted: data => {
      switch (data.saveQueue.__typename) {
        case "QueueNode": {
          Message.success("Saved");
          const search = tab ? `tab=${tab}` : undefined;
          navigate(
            { pathname: queuesapp_getEditQueue(data.saveQueue.slug), search },
            { replace: true, state: data.saveQueue }
          );
          break;
        }
        case "ValidationErrorResult":
          Message.error(<ErrorPanel errors={data.saveQueue.messages} />);
          break;
        default:
          return assertNever(data.saveQueue);
      }
    },
    onError: e => {
      console.error(e);
      Message.error(e.message);
    }
  });

  const onSave = () => {
    let input;
    try {
      input = toQueueInput(state);
    } catch (e) {
      const err = tryError(e);
      Message.error(err.message);
      return;
    }
    return save({ variables: { queue: input } });
  };

  return (
    <div>
      <PageHeader
        homeButton={<HomeButton />}
        key="queues-config"
        title={state.name}
        hideEnvironmentSelector
        controls={
          <>
            {slug && (
              <styled.OpenQueueButton to={queuesapp_getQueue(slug)} type="noFill">
                Open queue
              </styled.OpenQueueButton>
            )}
            <ButtonNew type="brand" loading={saving} onClick={onSave}>
              Save
            </ButtonNew>
          </>
        }
      />
      {!loading && (
        <common.ConfigContainer>
          <styled.Navigation tab={tab} state={state} dispatch={dispatch} />
          <common.Content>
            {tab === Tab.GENERAL && <General state={state} dispatch={dispatch} />}
            {tab === Tab.DATA && <Data state={state} dispatch={dispatch} />}
            {tab === Tab.STATES && <State state={state} dispatch={dispatch} />}
          </common.Content>
        </common.ConfigContainer>
      )}
    </div>
  );
};
