import gql from "graphql-tag";

import {
  EnvironmentNode,
  Connection,
  EnvironmentSpaceVersionNode
} from "../../../../../types";

export const ENVIRONMENT_VERSION_QUERY = gql`
  query EnvironmentVersionsForSpace($space: ID!) {
    allEnvironmentSpaceVersions(space: $space) {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          createdAt
          environment {
            __typename
            id
          }
        }
      }
    }
    allEnvironments {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
        }
      }
    }
  }
`;

export interface Data {
  allEnvironments: Connection<EnvironmentNode>;
  allEnvironmentSpaceVersions: Connection<EnvironmentSpaceVersionNode>;
}

export interface Vars {
  space: string;
}
