import React from "react";

import { BaseCode } from "./components/common/FunctionEditor";
import { SupportedIntegration } from "./components/common/FunctionEditor/support";
import { MaterialIconType } from "./components/common/Icons/MaterialIcons";
import {
  ElementLayout,
  ElementStyle,
  ElementLayoutOptions
} from "./components/spaces/layout/util";
import { ComponentNode } from "./components/spaces/SpaceRoot/RenderTreeContext";
import { Props as SpaceComponentProps } from "./components/spaces/SpaceRoot/SpaceComponent";
import { OPAQUE_SPACE_DATA_KEY } from "./components/spaces/SpaceRoot/SpaceContext/StableSpaceContext";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState,
  EventHandler
} from "./components/spaces/types";
import { AttributeTypes, ErrorValue, ReturnSchema } from "./constants";

export enum CursorType {
  RESOURCE = "RESOURCE",
  FUNCTION = "FUNCTION"
}

export interface RecordIdentifier {
  slug: string;
  key: RecordKey;
}

export interface RecordKey {
  [k: string]: any;
}

export interface FunctionDescriptor {
  id: string;
  params: Record<string, DataValue>;
}

export type RecordView = "detail" | "audit_log";

export interface CursorFilter {
  attribute: string;
  operator: ViewFilterOperator;
  value: DataValue;
  __clientId?: string;
}

export type Cursor = ResourceCursor | FunctionCursor;

export interface ResourceQueryDescriptor {
  slug: string;
  filters: CursorFilter[];
}

export interface ResourceCursor extends ResourceQueryDescriptor {
  type: CursorType.RESOURCE;
}

export interface FunctionCursor extends FunctionDescriptor {
  type: CursorType.FUNCTION;
}

export interface ListFunctionCursor extends FunctionCursor {
  params: { filters: CursorFilter[] };
}

export interface Resource {
  id: string;
  name: string;
}

// https://pkg.go.dev/google.golang.org/grpc/codes?tab=doc#Code
export enum StatusCode {
  OK = 0,
  CANCELED = 1,
  UNKNOWN = 2,
  INVALID_ARGUMENT = 3,
  DEADLINE_EXCEEDED = 4,
  NOT_FOUND = 5,
  ALREADY_EXISTS = 6,
  PERMISSION_DENIED = 7,
  RESOURCE_EXHAUSTED = 8,
  FAILED_PRECONDITION = 9,
  ABORTED = 10,
  OUT_OF_RANGE = 11,
  UNIMPLEMENTED = 12,
  INTERNAL = 13,
  UNAVAILABLE = 14,
  DATA_LOSS = 15,
  UNAUTHENTICATED = 16
}

export enum AuthStatus {
  Unauthenticated = "UNAUTHENTICATED",
  Partial = "PARTIALLY_AUTHENTICATED",
  Authenticated = "AUTHENTICATED"
}

// APIFilterOption is the filter option information returned from the server.
export interface APIFiltersOption<T = AttributeNodeTypes> {
  operators: ViewFilterOperator[];
  sourceName: string;
  sourceType: T;
}

export interface FiltersOption<T = AttributeNodeTypes> extends APIFiltersOption<T> {
  name: string;
}

// APISortByOption is the filter option information returned from the server.
export type APISortByOption = string;

export interface SortByOption {
  name: string;
  sourceName: string;
}

export interface Metadata<C = BaseCode> {
  categories?: string[];
  code?: C;
  filters?: { options: APIFiltersOption[] };
  sortBy?: { options: APISortByOption[] };
}

export interface SortOrder {
  sourceName: string;
  direction: "ASC" | "DESC";
}

export interface ViewFilter {
  sourceName: string;
  operator: ViewFilterOperator;
  value: DataValue;
  __clientId?: string;
}

export enum ViewFilterOperator {
  EQUALS = "=",
  NOT_EQUALS = "!=",
  LESS_THAN = "<",
  LESS_THAN_OR_EQUAL = "<=",
  GREATER_THAN = ">",
  GREATER_THAN_OR_EQUAL = ">=",
  IN = "in",
  NOT_IN = "not in",
  ICONTAINS = "icontains"
}

export interface ViewOrder {
  sourceName: string;
  direction: string;
}

export interface DescribeColumn {
  sourceName: string;
  sourceType: AttributeTypes;
  sourceKey: boolean;
  orderable: boolean;
  operators: ViewFilterOperator[];
}

export interface DescribeParameter {
  sourceName: string;
}

export const ViewFilterOperatorDisplayNames: Record<ViewFilterOperator, string> = {
  [ViewFilterOperator.EQUALS]: "=",
  [ViewFilterOperator.NOT_EQUALS]: "!=",
  [ViewFilterOperator.LESS_THAN]: "<",
  [ViewFilterOperator.LESS_THAN_OR_EQUAL]: "<=",
  [ViewFilterOperator.GREATER_THAN]: ">",
  [ViewFilterOperator.GREATER_THAN_OR_EQUAL]: ">=",
  [ViewFilterOperator.IN]: " in ",
  [ViewFilterOperator.NOT_IN]: " not in ",
  [ViewFilterOperator.ICONTAINS]: " contains "
};

export const ViewFilterOperatorHumanizedDisplayNames: Record<
  ViewFilterOperator,
  string
> = {
  [ViewFilterOperator.EQUALS]: "is equal to",
  [ViewFilterOperator.NOT_EQUALS]: "is not equal to",
  [ViewFilterOperator.LESS_THAN]: "is less than",
  [ViewFilterOperator.LESS_THAN_OR_EQUAL]: "is less than or equal to",
  [ViewFilterOperator.GREATER_THAN]: "is greater than",
  [ViewFilterOperator.GREATER_THAN_OR_EQUAL]: "is greater than or equal to",
  [ViewFilterOperator.IN]: "is in",
  [ViewFilterOperator.NOT_IN]: "is not in",
  [ViewFilterOperator.ICONTAINS]: "contains"
};

export interface RoleNode extends RelayNode {
  isManaged?: boolean;
  name: string;
  slug?: string;
  scopes?: string[];
  functions?: FunctionNode[];
  allowsAllFunctions?: boolean;
  userCount?: number;
}

export type BasicObject = Record<string, boolean | string | number>;

export type AttributeNodeTypes =
  | AttributeTypes.BINARY
  | AttributeTypes.BOOL
  | AttributeTypes.DATE
  | AttributeTypes.DECIMAL
  | AttributeTypes.DATETIME
  | AttributeTypes.TIME
  | AttributeTypes.FILE
  | AttributeTypes.FLOAT
  | AttributeTypes.INT
  | AttributeTypes.JSON
  | AttributeTypes.TIMESTAMP
  | AttributeTypes.STRING;

export interface RelayNode {
  id: string;
  __typename?: string;
}

export interface BasicAttributeNode<T = AttributeNodeTypes> extends RelayNode {
  name: string;
  sourceName: string;
  sourceType: T;
  sourcePrimaryKey?: boolean;
  renderTemplate: string | undefined | null;
  recordIndex: number | null;

  // TODO: These should not be optional types
  sourceIndex: number;
  sourceNullable?: boolean; // TODO: This should be removable
}

export interface AttributeNode<T = AttributeNodeTypes> extends BasicAttributeNode<T> {
  resource?: ResourceNode | null;
  function?: FunctionNode;
  canRead?: boolean; // Used in bindings
}

interface FileNode extends RelayNode {
  url: string;
}

export interface FileInput {
  data: string;
}

export interface OrganizationNode extends RelayNode {
  name: string;
  description?: string;
  logo?: FileNode;
  ssoRequired?: boolean;
  ssoConnection?: SSOConnectionNode;
  licenseData?: {
    userLimit?: number;
    roles?: number;
    sso?: boolean;
    permissions?: boolean;
    twoFactor?: boolean;
    environments?: boolean;
    dataSources?: number;
    versionHistory?: boolean;
    auditLogs?: boolean;
  };
  stripeSubscriptionId?: string | null | undefined;
  currentPlan?: {
    slug?: string;
    tier?: number | undefined;
  };
  locked?: boolean;
  customLicensing?: boolean;
}

export type OrganizationNodeInput = Omit<OrganizationNode, "logo"> & {
  logo?: FileInput;
};

export interface OrganizationInviteNode extends RelayNode {
  email: string;
  role: RoleNode;
}

export interface UserNode extends RelayNode {
  email: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  status?: string;
  isAdmin?: boolean;
  scopes?: string[];
  roles?: Connection<RoleNode>;
  organization: OrganizationNode;
}

export interface AuthScope {
  name: string;
  description: string;
}

export enum SSOProvider {
  AZUREAD_TENANT = "AZUREAD_TENANT",
  DISABLED = "DISABLED",
  GOOGLE = "GOOGLE",
  OKTA_OPENIDCONNECT = "OKTA_OPENIDCONNECT",
  SAML = "SAML"
}

export interface SSOUserNode extends RelayNode {
  providerType: SSOProvider;
}

export interface SSOConnectionNode extends RelayNode {
  connectionType: SSOProvider;
  externalKey: string;
  state: string;
  ssoUrl: string;
  azureadTenantId: string | null;
  azureadClientId: string | null;
  azureadClientSecret: string | null;
  samlEntityId: string | null;
  samlSsoUrl: string | null;
  samlX509Cert: string | null;
  samlAcsUrl: string | null;
  oauthClientId: string | null;
  oauthClientSecret: string | null;
  oauthRedirectUri: string | undefined;
  oktaDomain: string | null;
}

export enum UserStatus {
  Active = "active",
  Inactive = "inactive"
}

export interface Edge<P extends RelayNode> {
  node: P;
  __typename?: string;
}

export interface ResourceNode extends RelayNode {
  __typename: "ResourceNode";

  name: string;
  sourceName: string;
  slug: string;
  sourceIdentifier?: string;
  dataSource?: DataSourceNode;
  attributes?: {
    edges: { node: AttributeNode }[];
  };
}

export interface HttpCredentials {
  adapter: "http";
  scheme: string;
  host: string;
  port: string;
  path?: string;
  shared_secret: string;
  user?: string;
  headers_json?: string;
}

export interface DatabaseCredentials {
  adapter: "mssql" | "mysql" | "postgresql";
  database: string;
  host: string;
  port: string;
  user: string;
}

export interface MongoCredentials {
  adapter: "mongo";
  ssh_user: string;
  ssh_host: string;
  ssh_port: number;
  database: string;
}

export type Credentials = HttpCredentials | DatabaseCredentials | MongoCredentials;

export enum FunctionScope {
  Base = "base",
  Wrapped = "wrapped",
  View = "view",
  Submittable = "submittable"
}

export interface OutboundFunctionLinkNode extends RelayNode {
  toFunction: {
    id: string;
    name: string;
  };
  parameterAttributeMapping: Record<string, string>;
}

interface InboundFunctionLinkNode extends RelayNode {
  fromFunction: {
    id: string;
    name: string;
  };
  parameterAttributeMapping: Record<string, string>;
}

interface PolicyFunctionParameters extends RelayNode {
  functionParameter: FunctionParameterNode;
}

interface PolicyFunctionAttributes extends PolicyNode {
  functionAttribute: FunctionAttributeNode;
}

interface PolicyNode extends RelayNode {
  role: RoleNode;
}

export enum BaseFunctionName {
  AGGREGATE = "aggregate",
  INSERT = "insert",
  QUERY = "query",
  REQUEST = "request",
  UPDATE = "update",
  DELETE = "delete",
  SQL_EXECUTE = "sql.execute",
  SQL_QUERY = "sql.query"
}

interface FunctionPolicyNode extends PolicyNode {
  allowsAll: boolean;
  policyFunctionParameters: Connection<PolicyFunctionParameters>;
  policyFunctionAttributes: Connection<PolicyFunctionAttributes>;
}

export enum SpaceFunctionType {
  VOID,
  REMOTE,
  PIPELINE,
  INVALID,
  NOT_VISIBLE
}

interface _Metadata extends Record<string, any> {
  filters?: { options: APIFiltersOption[] };
  sortBy?: { options: APISortByOption[] };
}

export interface FunctionNode extends RelayNode {
  returnSchema: ReturnSchema;
  description?: string;
  title?: string;
  name: string;
  functionParameters?: Connection<FunctionParameterNode>;
  functionAttributes?: Connection<FunctionAttributeNode>;
  access?: {
    __typename: "FunctionAccess";
    onlyParameters: string[] | null;
    onlyAttributes: string[] | null;
  };
  policies?: Connection<FunctionPolicyNode>;
  baseFunction?: FunctionNode;
  baseFunctionParameterMapping?: any; // any JSON object
  reducer?: string;
  metadata?: _Metadata;
  dataSource?: DataSourceNode;
  isUserGenerated?: boolean;
  outboundFunctionLinks?: Connection<OutboundFunctionLinkNode>;
  inboundFunctionLinks?: Connection<InboundFunctionLinkNode>;
}

export interface FunctionLinkNode extends RelayNode {
  fromFunction?: FunctionNode;
  toFunction?: FunctionNode;
  parameterAttributeMapping?: any; // any JSON object
}

export interface FunctionParameterNode extends RelayNode {
  name: string;
  type: AttributeNodeTypes;
  required: boolean;
}

export interface FunctionAttributeNode extends RelayNode {
  name: string;
  function?: Partial<FunctionNode>;
  sourceIndex: number;
  sourceName: string;
  sourceType: AttributeTypes;
  sourceKey: boolean;
  outboundReferenceLink?: FunctionLinkNode;
  // legacy attribute fields used to satisfy
  // AttributeNode | FunctionAttributeNode union type
  resource?: ResourceNode;
  recordIndex?: null;
  sourcePrimaryKey?: false;
  renderTemplate?: null;
}

export interface AllRolesNode {
  edges: Edge<RoleNode>[];
}

export interface AllRolesResponse {
  allRoles: AllRolesNode;
}

export enum SyncStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  CANCELED = "CANCELED",
  ERROR = "ERROR"
}

export interface DataSourceNodeBase extends RelayNode {
  name: string;
}

export interface DataSourceNode extends DataSourceNodeBase {
  dataSourceProvider?: DataSourceProviderNode;
  autoSyncEnabled: boolean;
  synchronizedAt?: Date;
  credentials?: Credentials;
  resources?: Connection<ResourceNode>;
  functions?: Connection<FunctionNode>;
  integration?: SupportedIntegration;
}

export interface DataSourceProviderNode<P extends DataSourceNodeBase = DataSourceNode>
  extends RelayNode {
  adapter: string;
  dataSources?: { edges: Edge<P>[] };
}

export interface PageInfo {
  hasNextPage: boolean;
  endCursor: string;
  __typename: "PageInfo";
}

export interface Connection<P extends RelayNode> {
  edges: Edge<P>[];
  pageInfo?: PageInfo;
  __typename?: string;
}

export type ValueOf<T> = T[keyof T];

export type DataValue =
  | null
  | boolean
  | number
  | string
  | CursorFilter[]
  | ErrorValue
  | DataValue[]
  | { [prop: string]: DataValue };

export interface ResourceRecord {
  [key: string]: DataValue;
}

export interface ViewAttributeNode extends RelayNode {
  order: number | null;
  attribute: AttributeNode | FunctionAttributeNode;
}

export interface ViewRowNode extends RelayNode {
  row: any[];
}

export interface ViewRows {
  edges: Edge<ViewRowNode>[];
  pageInfo: PageInfo;
  __typename: "ViewRowConnection";
}

export interface ViewDataResultSuccess {
  rows: ViewRows;
  __typename: "ViewDataResultSuccess";
}
export type ViewDataNode =
  | ViewDataResultSuccess
  | ClientErrorResult
  | PermissionErrorResult;

export interface ViewNode extends RelayNode {
  function?: RelayNode;
  rows: ViewRows;
  data?: ViewDataNode;
}

export interface EmptyViewNode extends Partial<ViewNode> {
  id: "";
}

export interface EnvironmentSpaceVersionNode extends RelayNode {
  createdAt: string;
  createdBy: UserNode;
  space: SpaceNode;
  environment: EnvironmentNode;
}

export interface EnvironmentDataSourceCredentialNode extends RelayNode {
  credentials: Credentials;
  dataSourceProvider: DataSourceProviderNode;
  environment: EnvironmentNode;
}

export interface EnvironmentNode extends RelayNode {
  name: string;
  isDefault?: boolean;
  dataSourceCount?: number;
  slug: string;
}

export enum SourceType {
  VIEW = "VIEW",
  BINDING = "BINDING",
  FILE = "FILE"
}

export const SourceTypeDisplayNames = new Map<SourceType, String>();
SourceTypeDisplayNames.set(SourceType.VIEW, "Function");
SourceTypeDisplayNames.set(SourceType.BINDING, "Binding");
SourceTypeDisplayNames.set(SourceType.FILE, "File Upload");

// Define all the different component types that we support in spaces.
export type SpaceComponentType =
  | "CALCULATED_FIELD"
  | "CARD_LIST"
  | "CHART"
  | "CHECKBOX"
  | "CONTEXT_PARAM"
  | "CUSTOM_FIELD"
  | "DATA_VIEWER"
  | "DATE_TIME_PICKER"
  | "DETAIL"
  | "DROPDOWN"
  | "DYNAMIC_FUNCTION"
  | "ENVIRONMENT"
  | "FILE_PICKER"
  | "FLEX_BOX"
  | "FUNCTION_BULK_IMPORT"
  | "FUNCTION_BUTTON"
  | "FUNCTION_HEADLESS"
  | "FUNCTION_FORM"
  | "FUNCTION_MODAL_FORM"
  | "HEADER"
  | "IMAGE"
  | "JSON_INPUT"
  | "LINK"
  | "PARAMS"
  | "RADIO_BUTTON"
  | "REPEATEDITEM"
  | "S3_UPLOADER"
  | "STAT"
  | "TABLE"
  | "TAG_SELECTOR"
  | "TEXT_AREA"
  | "USER"
  | "VIEWLESS_IMAGE"
  | "VOID"
  // pseudocomponents:
  | "CARD"
  | "ROW"
  | "OPTION"
  | "FIELDSET";

export interface SpaceComponentCondition {
  operator: ViewFilterOperator;
  value: any;
  binding: string;
}

export interface SpaceComponentDynamicState {
  conditions: SpaceComponentCondition[] | null;
  slug: string | null;
}

export interface BaseComponentProperties {
  styles?: Record<string, ElementStyle>;
}
interface BaseSpaceComponent<T extends BaseComponentProperties> {
  name: string;
  slug: string;
  sourceType?: SourceType;
  view?: ViewNode | EmptyViewNode;
  isRemovable?: boolean;
  functions: Connection<FunctionNode>;
  notVisibleFunctions?: Connection<RelayNode>;
  type: SpaceComponentType;
  properties: T;
  layout?: ElementLayout;
}

export interface FunctionBackedComponentProperties {
  form_name?: string;
  instructions?: string;
  input_parameters: any[];
  effects: EventHandler[];
}

export interface SpaceComponentNode<T = any> extends RelayNode, BaseSpaceComponent<T> {
  container: SpaceComponentNode | null;
  componentTreeNodes: SpaceComponentNode[];
}

export interface DraftSpaceComponent<T = any> extends BaseSpaceComponent<T> {
  container: DraftSpaceComponent | null;
  componentTreeNodes: DraftSpaceComponent[];
  id?: string;
}

export interface SpaceComponentInputType {
  name: string;
  slug: string;
  view?: Partial<ViewNode>;
  functions?: { id: string }[];
  type: SpaceComponentType;
  container: SpaceComponentInputType | null;
  componentTreeNodes: SpaceComponentInputType[];
  layout?: Partial<ElementLayout>;
  properties: any;
}

export type SpaceComponentObject<T = any> =
  | SpaceComponentNode<T>
  | DraftSpaceComponent<T>;

export interface SpaceVersionNode extends RelayNode {
  componentTreeNodes: SpaceComponentNode[];
  createdBy?: UserNode;
  createdAt?: string;
}

export enum AuthorizationFlowStepType {
  CREDENTIAL = "CREDENTIAL",
  INPUT = "INPUT",
  OAUTH2 = "OAUTH2",
  EXECUTEFUNCTION = "EXECUTEFUNCTION",
  EXPRESSION = "EXPRESSION"
}

export interface AuthorizationFlowStepNode extends RelayNode {
  type: AuthorizationFlowStepType;
  order: number;
  slug: string;
  options: any;
  function?: {
    id: string;
    name: string;
    title: string;
  };
}

export interface AuthorizationFlowNode extends RelayNode {
  name: string;
  authorized?: boolean;
  description?: string;
  authSteps?: {
    edges: Edge<AuthorizationFlowStepNode>[];
  };
}

export interface SpaceNode extends RelayNode {
  name: string;
  slug: string;
  icon?: MaterialIconType;
  color?: string;
  description?: string;
  isFavorite?: boolean;
  latestVersion?: SpaceVersionNode;
  publishedVersion?: SpaceVersionNode;
  authorizationFlows?: AuthorizationFlowNode[];
}

export type _SpaceNode = Omit<Omit<Omit<SpaceNode, "id">, "name">, "slug">;

export type MaskedSpaceNode = _SpaceNode & {
  [OPAQUE_SPACE_DATA_KEY]: {
    id: string;
    name: string;
    slug: string;
  };
};

export type SpaceComponentHostType = SpaceComponentType | "SPACE";

export enum BindingShape {
  OBJECT_ARRAY = "object_array",
  OBJECT = "object",
  SCALAR_ARRAY = "scalar_array",
  SCALAR = "scalar",
  UNKNOWN = "unknown"
}

interface BaseBinding {
  shape: BindingShape;
  name: string;
  title?: string;
}

export interface UnknownBinding extends BaseBinding {
  shape: BindingShape.UNKNOWN;
}

export interface ScalarBinding extends BaseBinding {
  shape: BindingShape.SCALAR;
  type?: AttributeTypes;
  functionAttributeId?: string;
  inaccessible?: boolean;
}

export const bindingToAttribute = (b: Binding): AttributeNode =>
  b.shape === BindingShape.SCALAR
    ? ({
        id: b.functionAttributeId || b.name,
        name: b.name,
        sourceName: b.name,
        sourceType: b.type,
        canRead: !b.inaccessible
      } as AttributeNode)
    : ({
        id: b.name,
        name: b.name,
        sourceName: b.name,
        sourceType: AttributeTypes.JSON,
        canRead: true
      } as AttributeNode);

export const attributeToScalar = (
  a: FunctionAttributeNode,
  onlyAttributes: string[] | null
): ScalarBinding => ({
  name: a.sourceName,
  shape: BindingShape.SCALAR,
  type: a.sourceType,
  inaccessible: !!onlyAttributes && !onlyAttributes.includes(a.sourceName),
  functionAttributeId: a.id
});

export interface ObjectBinding extends BaseBinding {
  shape: BindingShape.OBJECT;
  attributes: Binding[];
}

export interface ObjectArrayBinding extends BaseBinding {
  shape: BindingShape.OBJECT_ARRAY;
  attributes: Binding[];
}

export interface ScalarArrayBinding extends BaseBinding {
  shape: BindingShape.SCALAR_ARRAY;
}

export type Binding =
  | ObjectArrayBinding
  | ObjectBinding
  | ScalarArrayBinding
  | ScalarBinding
  | UnknownBinding;

export interface ConfigurationSectionProps {
  displayName: string;
  spaceComponents: SpaceComponentObject[];
}

export interface ConfigValidationError {
  field: string;
  message: string;
  index?: number;
  key?: string;
}

type ErrorSelector = (
  state: ComponentConfigState,
  findInvalidInputBindings: (component: SpaceComponentObject) => string[],
  componentLookup: Record<string, SpaceComponentObject>
) => ConfigValidationError[];

export type ComponentConfigProps = { slug: string; isTerse?: boolean };
export interface SpaceComponentPackage<
  P extends BaseComponentProperties = BaseComponentProperties,
  T extends SpaceComponentObject = SpaceComponentObject<P>,
  S extends BaseComponentConfigState = BaseComponentConfigState<any>
> {
  type: SpaceComponentType;
  displayName: string;
  Icon?: React.ComponentType;
  allowedHosts: Set<SpaceComponentHostType>;
  isCollection?: boolean;
  featureFlag?: string;
  isHeadless: boolean;
  isContainer: boolean; // Container components may have components dropped on them
  elementLayoutOptions?: ElementLayoutOptions;
  defaultElementLayout?: ElementLayout;
  fallbackWidth?: number;
  fallbackHeight?: number;
  Component: React.ComponentType<SpaceComponentProps>;
  validateComponent?: (o: T) => boolean;
  ConfigurationComponent: React.ComponentType<ComponentConfigProps> | null;
  TerseConfigurationComponent?: React.ComponentType<ComponentConfigProps> | null;
  InlineConfigurationComponent?: React.ComponentType<ComponentConfigProps> | null;
  ConfigurationSection?: React.ComponentType<ConfigurationSectionProps> | null;
  getOutputBinding?: (sc: T, property: string) => Binding;
  getSchema: (node: ComponentNode) => Binding[];
  getSelfSchemaShape?: (node: ComponentNode) => BindingShape;
  allowSelfBinding: boolean;
  allowAncestorBinding: boolean; // allows binding to itself if it is an ancestor of the component
  componentConfigReducer: (state: S, action: SpaceConfigAction) => S;
  ensureComponent: (component: SpaceComponentObject<any>) => T;
  getInitialDraftState?: (sc: SpaceComponentObject) => S;
  getComponentProperties?: (properties: Partial<P>) => P; // used as interface with apps outside console
  isPseudoComponent?: boolean;
  isClientProvidedComponent?: boolean;
  isRepeatable?: boolean;
  hasInlineChildren?: boolean;
  tags?: string[];
  errorSelector: ErrorSelector;
}

// categories for space components
export enum SpaceComponentTags {
  Base = "base",
  Content = "content",
  Input = "input"
}

export const AUDIT_LOG_ACTIONS = {
  VIEWED_RECORD: "VIEWED_RECORD",
  VIEWED_RECORD_SET: "VIEWED_RECORD_SET",
  VIEWED_RESOURCE: "VIEWED_RESOURCE",
  FUNCTION_EXECUTED: "FUNCTION_EXECUTED",
  CREATED: "CREATED"
};

export interface AuditLogNode {
  action: string;
  resource: ResourceNode;
  payload: any; // any json object
  createdAt: string;
  user: UserNode;
  function: FunctionNode;
}

export interface AttributeFilterProperties {
  column?: string; // attribute sourceName
  operator?: string;
  value?: any;
}

export interface ChangeSetProperties {
  column?: string;
  value?: any;
}

export enum FilterTypes {
  Value = "value",
  Binding = "binding",
  Duration = "duration"
}

export interface Order {
  attribute: string;
  direction: "asc" | "desc";
}
interface BaseConfigFilter {
  type: FilterTypes;
  operator: ViewFilterOperator;
  attribute: string;
}

export interface BindingFilter extends BaseConfigFilter {
  type: FilterTypes.Binding;
  binding: string | undefined;
}

export interface DurationFilter extends BaseConfigFilter {
  type: FilterTypes.Duration;
  duration_iso: string;
}

export interface ValueFilter extends BaseConfigFilter {
  type: FilterTypes.Value;
  value: DataValue | undefined;
}

export type Filter = BindingFilter | DurationFilter | ValueFilter;

export interface ClientErrorInfo {
  domain: "" | "adapter" | "database.adapter";
  reason: "NETWORK" | "QUERY" | "TLS_REFUSED";
  metadata: any;
  __typename?: "ClientErrorInfoResult";
}

export interface ClientErrorResult {
  code: StatusCode;
  message: string;
  errorInfo: ClientErrorInfo;
  __typename: "ClientErrorResult";
}

export interface PermissionErrorResult {
  message: string;
  __typename: "PermissionErrorResult";
}

export interface ValidationErrorMessage {
  text: string;
  code: string;
  index: string[];
  __typename: "ValidationErrorMessage";
}

export interface ValidationErrorResult {
  messages: ValidationErrorMessage[];
  __typename: "ValidationErrorResult";
}

export interface ReturningRowsResultSuccess {
  rows: ViewRowNode[];
  __typename?: string;
}

export interface ExecuteFunctionResultSuccess {
  value?: any;
  metadata?: unknown;
  returning?: ReturningRowsResultSuccess | ClientErrorResult | PermissionErrorResult;
  __typename: "ExecuteFunctionResultSuccess";
}

export type ExecuteFunctionResult =
  | ExecuteFunctionResultSuccess
  | ClientErrorResult
  | PermissionErrorResult;

export type BulkExecuteFunctionResult = ExecuteFunctionResult | ValidationErrorResult;

export interface FunctionNodeBasic extends RelayNode {
  title: string;
  name: string;
}

export interface BaseFunctionNodeBasic extends RelayNode {
  title: string;
  name: BaseFunctionName;
}

export interface FunctionAttribute {
  name: string;
  type: AttributeTypes;
  key: boolean;
}

export interface FunctionParameterInput {
  name: string;
  type: AttributeTypes;
  required: boolean;
}

export interface FunctionAuthorizationFlowInput {
  environmentId: string;
  authorizationFlowId: string;
}

export interface FileObject {
  readonly size: number;
  readonly name: string;
  readonly type: string;
  readonly data: string;
}

export interface Price {
  recurring: {
    interval: string;
  };
  unit_amount: number;
}

export interface PaymentIntent {
  id: number;
  invoice: {
    hosted_invoice_url?: string;
    lines: {
      data: {
        description?: string;
        quantity?: number;
      }[];
    };
  };
  created: number;
  payment_method: {
    type: string;
    card?: {
      last4: number;
      brand: string;
    };
  };
  amount: number;
  status: string;
}

export interface Subscription {
  id: string;
  plan: {
    amount: number;
  };
  latest_invoice: {
    total: number;
  };
  quantity: number;
  items?: {
    data: {
      price: Price;
    }[];
  };
  subscriptionPeriodEnd?: number;
}

export interface PaymentMethod {
  paymentMethod?: {
    id: string;
  };
  error?: {
    message: string;
  };
}

// types shared across apps, queues and automations

// copied from useFuncParams/types but should be moved since used across apps
export enum ParameterType {
  COMPONENT = "component",
  PENDING = "none",
  FILE = "file",
  BINDING = "binding",
  STATIC = "value",
  UUID = "uuid",
  DATE_TODAY = "date_today",
  DATETIME_NOW = "datetime_now",
  TIME_NOW = "time_now",
  TEMPLATE = "template"
}

export interface InputParameterBase<T = ParameterType> {
  name: string;
  type: T;
  required?: boolean;
}

export interface ComponentInputParameter
  extends InputParameterBase<ParameterType.COMPONENT> {
  componentType: SpaceComponentType;
  componentProperties: Record<string, any>;
}

export enum Resolver {
  UI = "ui",
  PIPELINE = "pipeline"
}

export interface BindingInputParameter
  extends InputParameterBase<ParameterType.BINDING> {
  binding: string;
  resolver: Resolver;
}

export interface TemplateInputParameter
  extends InputParameterBase<ParameterType.TEMPLATE> {
  template: string;
  resolver: Resolver;
}

export interface StaticInputParameter extends InputParameterBase<ParameterType.STATIC> {
  value: DataValue | undefined;
}

export type InputParameter =
  | BindingInputParameter
  | ComponentInputParameter
  | StaticInputParameter
  | TemplateInputParameter
  | InputParameterBase<ParameterType.UUID>
  | InputParameterBase<ParameterType.DATETIME_NOW>
  | InputParameterBase<ParameterType.DATE_TODAY>
  | InputParameterBase<ParameterType.TIME_NOW>;
