import React from "react";

import { default as TOS } from "../components/legal/TermsOfService";
import { NoHeaderLayout } from "../layouts";

export const TermsOfService = () => (
  <NoHeaderLayout>
    <TOS />
  </NoHeaderLayout>
);

export const Privacy = () => {
  window.location.href = "https://internal.io/privacy";
  return null;
};
