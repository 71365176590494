import React, { ReactNode } from "react";

import { Icon } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled from "styled-components";

import CommonIconButton from "../../IconButton";

const BaseSection = styled.div`
  border-bottom: 1px solid ${props => props.theme.menuBorderColor};
`;

export const SectionChild = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.inputFontSize};

  padding: ${({ theme }) =>
    `0px ${theme.spacermd} ${theme.spacersm} ${theme.spacerxl}`};
`;

export const Empty = styled(SectionChild)`
  font-style: italic;
`;

export const IconButton = styled(CommonIconButton)`
  &.ant-btn-link {
    font-size: ${props => props.theme.inputFontSize};
  }
  max-height: ${props => props.theme.spacerlg};
  max-width: ${props => props.theme.spacerlg};
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${props => props.theme.spacersm} ${props => props.theme.spacermd};
  font-size: ${props => props.theme.defaultFontSize};
  cursor: pointer;
  font-weight: bold;
`;

const IconWithMargin = styled(Icon)`
  margin-right: ${props => props.theme.spacerxs};
`;

interface Props {
  className?: string;
  children: ReactNode;
  title: string;
  titleButtonProps?: ButtonProps;
  onTitleClick?: () => void;
}

export default function Section(props: Props) {
  const [open, setOpen] = React.useState(false);
  const buttonProps = props.titleButtonProps || {};
  return (
    <BaseSection className={props.className}>
      <SectionHeader data-test="section" onClick={() => setOpen(!open)}>
        <span>
          <IconWithMargin type={open ? "caret-down" : "caret-right"} />
          {props.title}
        </span>
        {!!props.onTitleClick && (
          <IconButton
            data-test="iconButton"
            icon="plus"
            type="link"
            onClick={_ => {
              props.onTitleClick!();
              setOpen(true);
            }}
            {...buttonProps}
          />
        )}
      </SectionHeader>
      {open && props.children}
    </BaseSection>
  );
}
