import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Connection, HttpCredentials, RelayNode } from "../../../../../types";
import { useEnvironmentContext } from "../../../contexts/EnvironmentContext";
import { BaseDataSource } from "../common";

interface EnvironmentDataSourceProviderNode extends RelayNode {
  credentials: HttpCredentials;
  environment: RelayNode;
}

interface CredentialsQueryData {
  allEnvironmentDataSourceCredentials: Connection<EnvironmentDataSourceProviderNode>;
}

export const CREDENTIALS_QUERY = gql`
  query EnvironmentDataSourceCredentials($dataSourceProviderId: ID!) {
    allEnvironmentDataSourceCredentials(dataSourceProvider: $dataSourceProviderId) {
      edges {
        node {
          id
          credentials
          environment {
            id
          }
        }
      }
    }
  }
`;

export default function useEnvCredentials(
  dataSource: BaseDataSource,
  previewEnvId: string | undefined
) {
  const { data } = useQuery<CredentialsQueryData>(CREDENTIALS_QUERY, {
    variables: { dataSourceProviderId: dataSource.dataSourceProvider?.id }
  });

  const { getCurrentEnvironment } = useEnvironmentContext();
  const envId = previewEnvId || getCurrentEnvironment().id;

  const envDataSourceProvider = data?.allEnvironmentDataSourceCredentials.edges.find(
    ({ node: { environment } }) => environment.id === envId
  );

  if (!!data && !envDataSourceProvider) {
    return null;
  }

  return envDataSourceProvider?.node.credentials;
}
