export type InstanceType =
  | "array"
  | "boolean"
  | "integer"
  | "null"
  | "number"
  | "object"
  | "string";

export type SchemaType = InstanceType | InstanceType[];

interface BaseSchema {
  format?: string;
  type: SchemaType;
  const?: string | number | boolean | null | Object | [];

  // Metadata
  title?: string;
  description?: string;
  default?: any;
  examples?: any[];

  oneOf?: Schema[];
}

// Schema is a *very* limited subset of JSON Schema.
//
// See: https://json-schema.org/specification.html
export interface Schema extends BaseSchema {
  // Numeric validations
  minimum?: number;
  maximum?: number;

  // Object subschemas and validations
  properties?: Record<string, Schema>;
  additionalProperties?: Schema;
  maxProperties?: number;
  required?: string[];

  // String validations
  minLength?: number;
  maxLength?: number;
}

export interface ObjectSchema extends BaseSchema {
  type: "object" | ["null", "object"] | ["object", "null"];

  title: string;

  properties: Record<string, Schema>;
  additionalProperties?: Schema;
  maxProperties?: number;
  required?: string[];
}

export const isObjectType = (schemaType: SchemaType) => {
  return (
    schemaType === "object" ||
    (Array.isArray(schemaType) && schemaType.includes("object"))
  );
};
