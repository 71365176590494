import { Input as AntInput } from "antd";
import styled from "styled-components";

import { IconSelect as BaseIconSelect } from "../../../common/IconSelect";
import BaseLimitCounter from "../../../common/LimitCounter/LimitCounter";

export const Cols = styled.div`
  display: flex;
  justify-content: stretch;
  gap: ${props => props.theme.spacersm};
`;

export const Input = styled(AntInput)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const IconSelectLabel = styled.label`
  flex-grow: 1;
`;

export const IconSelect = styled(BaseIconSelect)`
  display: block;
  margin-bottom: ${props => props.theme.spacermd};
`;

export const LimitCounter = styled(BaseLimitCounter)`
  margin-top: ${props => props.theme.spacerxs};
  text-align: right;
`;
