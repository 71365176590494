import styled from "styled-components";

export const Root = styled.div``;

export const Header = styled.h1`
  display: flex;
  align-items: center;
  justify-content: start;

  margin: ${props => props.theme.spacersm} 0;

  @media (min-width: ${props => props.theme.breakpointmd}) {
    margin: ${props => props.theme.spacermd} 0;
  }

  @media (min-width: ${props => props.theme.breakpointlg}) {
    margin: ${props => props.theme.spacerxl} 0;
  }

  font-size: ${props => props.theme.headlineFontSize};

  & > a {
    margin-left: ${props => props.theme.spacermd};
    font-size: 0.6em;
    color: ${props => props.theme.textColorMid};
  }

  & > a:hover {
    color: ${props => props.theme.textColor};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -${props => props.theme.spacermd};
`;

export const Main = styled.div`
  flex-grow: 2;
  flex-basis: 60%;
  min-width: 298px; /* Ensures fits in drawer at iPhone size */
  margin: 0 ${props => props.theme.spacermd};

  @media (min-width: ${props => props.theme.breakpointmd}) {
    min-width: 420px; /* Ensures wide enough to always be one col in drawer */
  }
`;

export const Sidebar = styled.div`
  flex-grow: 1;
  flex-basis: 30%;
  min-width: 200px;
  margin: 0 ${props => props.theme.spacermd};
`;
