import { Button as AntButton } from "antd";
import styled from "styled-components";

export const Root = styled.div`
  font-size: 13px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding-top: 22px;
`;

export const Button = styled(AntButton)`
  color: #2a282d;
`;
