import { Checkbox, Form, Radio, Input } from "antd";
import styled from "styled-components";

export const Warning = styled.div`
  color: ${props => props.theme.textColor};
  padding-left: ${props => props.theme.spacerlg};
  position: relative;

  .anticon {
    color: ${props => props.theme.warningColor};
    left: 0;
    position: absolute;
    top: ${props => props.theme.spacerxs};
  }
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.errorColor};
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: ${props => props.theme.spacersm};

  .ant-form-item-label {
    padding-bottom: ${props => props.theme.spacerxs};
  }

  label {
    color: ${props => props.theme.labelColor};
    padding-bottom: 0;
  }

  .ant-form-extra {
    color: ${props => props.theme.textColor};
  }

  .ant-btn-primary[type="submit"] {
    margin-bottom: ${props => props.theme.spacersm};
    min-width: 216px;
    width: unset;
  }
`;

export const FormToggle = styled(Checkbox)`
  display: block;
  margin-bottom: ${props => props.theme.spacersm};
  padding-bottom: ${props => props.theme.spacersm};
`;

export const KeyValueFormItem = styled(Form.Item)`
  margin-bottom: 0px;

  .ant-form-item-children {
    align-items: center;
    display: flex;
    width: 100%;
  }

  input {
    width: ${props => `calc(50% - ${props.theme.spacersm})`};
  }

  input + input {
    margin-left: ${props => props.theme.spacersm};
  }

  label {
    color: ${props => props.theme.labelColor};
    width: ${props => `calc(50% - ${props.theme.spacersm})`};
  }
`;

export const Fieldset = styled.fieldset`
  border: dotted 1px ${props => props.theme.tableBorderColor};
  border-radius: ${props => props.theme.borderRadiussm};
  padding: ${props => props.theme.spacermd};
  padding-bottom: 0;
  margin-bottom: ${props => props.theme.spacersm};

  legend {
    color: ${props => props.theme.textColorMid};
    font-size: ${props => props.theme.smallFontSize};
    border-bottom: none;
    margin-bottom: 0;
    display: inline-block;
    width: auto;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  padding-bottom: ${props => props.theme.spacermd};
`;

export const RadioButton = Radio.Button;

export const HiddenInput = styled(Input)`
  display: none;
`;
