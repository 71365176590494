import React from "react";

import { Row, Col } from "antd";
import * as moment from "moment";
import styled from "styled-components";

import { DataSource } from "../../DataSourceTable";

const StyledConnectionInfo = styled.div`
  div.ant-row {
    margin-top: ${props => props.theme.spacersm};
  }
`;

interface LegacySettingsPanelProps {
  dataSource: DataSource;
}

// This is the old panel used to display connection settings.  We're using this to fall back on for data sources that
// we haven't created new settings panels for yet.
export const LegacySettingsPanel = ({ dataSource }: LegacySettingsPanelProps) => {
  return (
    <StyledConnectionInfo>
      {dataSource.credentials && dataSource.credentials.host && (
        <Row>
          <Col span={12}>Host</Col>
          <Col span={12}>{dataSource.credentials.host}</Col>
        </Row>
      )}

      {dataSource.credentials && dataSource.credentials.port && (
        <Row>
          <Col span={12}>Port</Col>
          <Col span={12}>{dataSource.credentials.port}</Col>
        </Row>
      )}

      {dataSource.credentials && dataSource.credentials.user && (
        <Row>
          <Col span={12}>Username</Col>
          <Col span={12}>{dataSource.credentials.user}</Col>
        </Row>
      )}

      {dataSource && (
        <Row>
          <Col span={12}>Schema Version</Col>
          <Col span={12}>
            {(dataSource.synchronizedAt &&
              moment
                .default(dataSource.synchronizedAt)
                .format("YYYY-MM-DD HH:mm:ss")) ||
              "N/A"}
          </Col>
        </Row>
      )}
    </StyledConnectionInfo>
  );
};
