import React, { useState } from "react";

import _ from "lodash";
import { Query } from "react-apollo";
import { useNavigate } from "react-router";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { GET_ALL_ROLES } from "../../../graphql/queries";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../common/hooks/useAuthUser";
import usePaths from "../../common/hooks/usePaths";
import { ModalNew } from "../../common/Modal";
import { Table } from "../../common/StyledComponents";
import { nextPlanUp } from "../../util/billing";
import { DetailParams } from "../common";

import AddRoleModal from "./AddRoleModal";
import RoleDrawer from "./RoleDrawer";

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const AddRoleButton = styled(ButtonNew)`
  background-color: ${props => props.theme.primaryAccent};
  border-color: ${props => props.theme.primaryAccent};
  font-size: 14px;
  font-weight: 400;
`;

const PaywallPopup = styled(ModalNew)`
  left: 180px;
  top: 60px;
`;

const RolesAndPermissions = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPaywall, setShowPaywall] = useState<boolean>(false);

  const { id: roleId } = useParams<DetailParams>();
  const navigate = useNavigate();
  const paths = usePaths();
  const { authUser } = useAuthUser();

  const features = authUser ? authUser.organization.licenseData : {};
  const rolesFeature = features?.roles;

  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Functions",
      dataIndex: "functionCount",
      key: "functionCount"
    },
    {
      title: "Users",
      dataIndex: "userCount",
      key: "userCount"
    }
  ];

  columns.push();

  const openRoleModal = () => {
    setShowModal(true);
  };

  const closeRoleModal = () => {
    setShowModal(false);
  };

  const handleRowClick = (role: any) => {
    navigate(paths.getSettingsViewRole(role.id));
  };

  const displayPaywall = (atRolesLimit: boolean) => {
    if (atRolesLimit) {
      setShowPaywall(true);
    }
  };

  const hidePaywall = () => {
    setShowPaywall(false);
  };

  const handleDelete = () => {
    navigate(paths.getSettingsRolesAndPermissions());
  };

  return (
    <Query<any, any> query={GET_ALL_ROLES}>
      {({ data, loading }) => {
        const edges = _.get(data, "allRoles.edges", []);
        const roles = edges.map((edge: any) => {
          return _.clone(edge.node);
        });

        const atRolesLimit = (rolesFeature && roles.length >= rolesFeature) || false;

        return (
          <React.Fragment>
            <Toolbar onMouseLeave={hidePaywall}>
              <AddRoleButton
                type="brand"
                onClick={openRoleModal}
                disabled={atRolesLimit}
                onMouseEnter={() => displayPaywall(atRolesLimit)}
              >
                Add Role
              </AddRoleButton>

              <PaywallPopup
                absolutePositioning
                title="Upgrade to add more roles"
                visible={showPaywall}
                onCancel={hidePaywall}
                mask={false}
                footer={null}
                width={270}
              >
                <p>
                  You've reached the maximum number of roles for your account. To add
                  more roles, upgrade to the{" "}
                  <Link to="/settings/change-plan">
                    {nextPlanUp(authUser?.organization.currentPlan?.slug)} plan
                  </Link>
                  .
                </p>
              </PaywallPopup>
            </Toolbar>

            <Table
              loading={loading}
              dataSource={roles}
              rowKey="id"
              columns={columns}
              pagination={false}
              onRow={role => ({
                onClick: () => handleRowClick(role)
              })}
            />

            <AddRoleModal
              visible={showModal}
              handleOk={closeRoleModal}
              handleCancel={closeRoleModal}
              roles={roles}
              features={features}
            />
            <RoleDrawer
              roleId={roleId}
              visible={!!roleId}
              features={features}
              onClose={() => navigate(paths.getSettingsRolesAndPermissions())}
              onDelete={handleDelete}
            />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default RolesAndPermissions;
