import gql from "graphql-tag";

export const ALL_ENVIRONMENTS = gql`
  query AllEnvironments {
    allEnvironments {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
          dataSourceCount
          isDefault
        }
      }
    }
  }
`;

export const UPDATE_ENVIRONMENTS = gql`
  mutation UpdateEnvironments($environmentsInput: [EnvironmentInput]!) {
    updateEnvironments(environmentsInput: $environmentsInput) {
      ... on UpdateEnvironmentsResultError {
        message
      }
      ... on UpdateEnvironmentsResultSuccess {
        environments {
          id
          name
          isDefault
          dataSourceCount
          __typename
        }
      }
      __typename
    }
  }
`;
