import React, { useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { toQueue } from "..";
import EvaluaterContextContainer from "../../../common/CodeSandbox/EvaluaterContext";
import useDebouncedValue from "../../../common/hooks/useDebouncedValue";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import MenuButton from "../../../common/MenuButton/MenuButton";
import Message from "../../../common/Message";
import PerspectiveTimestamp from "../../../common/PerspectiveTimestamp/PerspectiveTimestamp";
import HighlightingTableRow from "../../../common/TableNew/HighlightingTableRow";
import TableNew, { TableInstance } from "../../../common/TableNew/TableNew";
import PageHeader from "../../common/PageHeader";
import StatePill from "../../common/StatePill";
import { DEFAULT_STATE_COLOR } from "../../Config/reducer/utils";
import DeleteTaskModal from "../Home/DeleteTaskModal";
import { QueueNode, useAllQueues } from "../queries/AllQueues";

import AssigneeField from "./AssigneeField";
import LeftNav from "./LeftNav";
import ModalTaskForm from "./ModalTaskForm";
import QueueHeader from "./QueueHeader";
import RefreshBanner from "./RefreshBanner";
import { QueueRoot, QueueMain, TaskDrawer, TableContainer } from "./styledComponents";
import { TabOption } from "./TaskDrawer/TaskTabs";
import UpdateTaskFormContainer from "./UpdateTaskForm/UpdateTaskForm";
import useQueueClient from "./useQueueClient/useQueueClient";

const columns = [
  {
    Header: "Queue",
    accessor: "queue.name"
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: (data: any) => <span title={data.value}>{data.value}</span>
  },
  {
    Header: "State",
    accessor: "state",
    Cell: (data: any) => <StatePill state={data.cell.value} />
  },
  {
    Header: "Created at",
    accessor: "createdAt",
    Cell: (data: any) => <PerspectiveTimestamp isoDate={data.cell.value} />
  },
  {
    Header: "Updated at",
    accessor: "updatedAt",
    Cell: (data: any) => <PerspectiveTimestamp isoDate={data.cell.value} />
  },
  {
    Header: "Assigned to",
    accessor: "assignee",
    Cell: (data: any) => (
      <AssigneeField labelRoleAsUnassigned assignee={data.cell.value} />
    )
  }
];

export default function AssignedQueue() {
  const tableRef = useRef<TableInstance>(null);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [createTaskQueue, setCreateTaskQueue] = useState<null | QueueNode>(null);
  const [searchParams] = useSearchParams();

  const updateParams = useSearchParamsUpdater();
  const selectedTaskId = searchParams.get("task_id") || undefined;
  const taskTab = searchParams.get("taskTab") || "details";
  const tabParam = searchParams.get("tab");
  const afterCursor = searchParams.get("afterCursor") || undefined;
  const beforeCursor = searchParams.get("beforeCursor") || undefined;
  const searchParam = searchParams.get("search") || undefined;

  const [search, setSearch] = React.useState<string | undefined>(searchParam);
  const debouncedSearch = useDebouncedValue(search, 300);

  React.useEffect(() => {
    updateParams({ search: debouncedSearch });
  }, [updateParams, debouncedSearch]);

  const allQueuesResponse = useAllQueues();

  const {
    tasks,
    pageInfo,
    taskChangeCount,
    beforeTaskCount,
    totalTaskCount,
    refetchTasks,
    onTaskUpdated: dispatchTaskUpdated,
    onTaskCreated: dispatchTaskCreated,
    onTaskDeleted: dispatchTaskDeleted,
    onTaskTransitioned: dispatchTaskTransitioned
  } = useQueueClient({
    queueSlug: tabParam || "assigned",
    taskFilters: {
      isArchived: !tabParam ? false : undefined, // Don't show archived for ALL tab
      search: debouncedSearch
    },
    afterCursor,
    beforeCursor,
    setCursors: updateParams
  });

  const selectedTaskIndex = React.useMemo(() => {
    return tasks.findIndex(t => t.id === selectedTaskId);
  }, [tasks, selectedTaskId]);

  const selectedTask = selectedTaskIndex > -1 ? tasks[selectedTaskIndex] : undefined;

  // Keep table selection consistent with selectedTaskIndex
  React.useEffect(() => {
    if (selectedTaskIndex > -1) {
      tableRef.current?.selectRows([selectedTaskIndex]);
    } else {
      tableRef.current?.toggleAllRowsSelected(false);
    }
  }, [selectedTaskIndex]);

  const onSelectTasks = React.useCallback(
    (taskIndexes?: number[]) => {
      if (!taskIndexes?.length) {
        updateParams({ task_id: undefined, taskTab: "details" });
        return;
      }

      // At the moment we only support a single index
      const selectedIndex = taskIndexes[0];
      if (selectedIndex < 0 || selectedIndex >= tasks.length) {
        throw new Error("Index out of range");
      }

      const taskSelected = tasks[selectedIndex];
      if (taskSelected.id !== selectedTaskId) {
        updateParams({ task_id: taskSelected.id, taskTab: "details" });
      }
    },
    [tasks, selectedTaskId, updateParams]
  );

  const [deleteTaskId, setDeleteTaskId] = React.useState<string | undefined>();
  const [updateTaskId, setUpdateTaskId] = React.useState<string | undefined>();

  const onTaskAdded = React.useCallback(
    async (taskId: string) => {
      await dispatchTaskCreated(taskId);
      setCreateTaskQueue(null);
    },
    [setCreateTaskQueue, dispatchTaskCreated]
  );

  const onTaskDeleted = React.useCallback(
    (taskId: string) => {
      setDeleteTaskId(undefined);
      dispatchTaskDeleted(taskId);
    },
    [setDeleteTaskId, dispatchTaskDeleted]
  );

  const onTaskUpdated = React.useCallback(
    (taskId: string) => {
      setUpdateTaskId(undefined);
      dispatchTaskUpdated(taskId);

      Message.success("Task updated");
    },
    [setUpdateTaskId, dispatchTaskUpdated]
  );

  const tabs = React.useMemo(() => {
    return [
      {
        label: "All open",
        value: "all",
        color: DEFAULT_STATE_COLOR
      },
      {
        label: "Assigned to me",
        value: "assignedToUser",
        color: DEFAULT_STATE_COLOR
      },
      {
        label: "Assigned to my role",
        value: "assignedToRole",
        color: DEFAULT_STATE_COLOR
      }
    ];
  }, []);

  return (
    <QueueRoot>
      <EvaluaterContextContainer>
        <PageHeader
          title="My tasks"
          onToggleNav={() => {
            setIsNavOpen(!isNavOpen);
          }}
        />
        <LeftNav open={isNavOpen} selected="assigned" />
        <QueueMain>
          <RefreshBanner
            newTasksCount={taskChangeCount}
            onRefresh={() => refetchTasks()}
          />
          <QueueHeader
            title="My tasks"
            tabs={tabs}
            activeTab={tabParam ? tabParam : "all"}
            search={search}
            setSearch={setSearch}
            visibleTaskCount={tasks.length}
            beforeTaskCount={beforeTaskCount}
            totalTaskCount={totalTaskCount}
            onTabSelected={tab =>
              updateParams({
                beforeCursor: undefined,
                afterCursor: undefined,
                tab: tab.value === "all" ? undefined : tab.value,
                task_id: undefined,
                taskTab: "details"
              })
            }
            pageInfo={pageInfo}
            controls={
              <MenuButton
                type="primary"
                icon="plus"
                maxWidthScale={3}
                actions={(allQueuesResponse.data?.allQueues.edges || []).map(
                  ({ node }) => ({
                    key: node.slug,
                    label: node.name,
                    onClick: () => setCreateTaskQueue(node)
                  })
                )}
              >
                Add task
              </MenuButton>
            }
            onNextPage={() => {
              updateParams({
                task_id: undefined,
                beforeCursor: undefined,
                afterCursor: pageInfo?.endCursor,
                taskTab: "details"
              });
            }}
            onPreviousPage={() => {
              updateParams({
                task_id: undefined,
                beforeCursor: pageInfo?.startCursor,
                afterCursor: undefined,
                taskTab: "details"
              });
            }}
          />
          <TableContainer>
            <TableNew
              ref={tableRef}
              data={tasks}
              columns={columns}
              Row={HighlightingTableRow}
              onSelectRows={onSelectTasks}
            />
          </TableContainer>
        </QueueMain>
        <TaskDrawer
          taskId={selectedTask?.id}
          tab={taskTab as TabOption}
          selectedTaskIndex={selectedTaskIndex || 0}
          taskCount={tasks.length}
          onSelectTask={onSelectTasks}
          onUpdateTask={setUpdateTaskId}
          onDeleteTask={setDeleteTaskId}
          onTransitionTask={dispatchTaskTransitioned}
          onChangeTab={newTab => updateParams({ taskTab: newTab })}
        />
        {createTaskQueue && (
          <ModalTaskForm
            visible
            queue={toQueue(createTaskQueue)}
            onCompleted={taskId => onTaskAdded(taskId)}
            onCancel={() => setCreateTaskQueue(null)}
          />
        )}
        {deleteTaskId && (
          <DeleteTaskModal
            taskId={deleteTaskId}
            onComplete={() => onTaskDeleted(deleteTaskId)}
            onCancel={() => setDeleteTaskId(undefined)}
          />
        )}
        {selectedTask && updateTaskId && (
          <UpdateTaskFormContainer
            visible
            taskId={selectedTask.id}
            queueSlug={selectedTask.queue.slug}
            onCompleted={() => onTaskUpdated(selectedTask.id)}
            onCancel={() => setUpdateTaskId(undefined)}
          />
        )}
      </EvaluaterContextContainer>
    </QueueRoot>
  );
}
