import React, { Dispatch } from "react";

import { Checkbox, Icon } from "antd";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import ButtonNew from "../../../../common/ButtonNew/ButtonNew";
import DragHandle from "../../../../common/SortableList/dragHandleCompact.svg";
import { QUEUE_ATTRIBUTE_TYPES } from "../../../constants";
import * as common from "../../common/styledComponents";
import { ReducerAction, ReducerActions } from "../../reducer/actions";
import { ConfigState } from "../../reducer/reducer";
import { Field } from "../../types";
import * as styled from "../styledComponents";

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ReducerAction>;
}

export default function Fields({ state, dispatch }: Props) {
  const onAdd = () => dispatch({ type: ReducerActions.ADD_FIELD });

  const onChangeField = (field: Partial<Field>, i: number) =>
    dispatch({ type: ReducerActions.UPDATE_FIELD, payload: { index: i, field } });

  const onDelete = (i: number) =>
    dispatch({
      type: ReducerActions.DELETE_FIELD,
      payload: i
    });

  const onDragEnd = (result: DropResult) => {
    if (result.reason !== "DROP" || !result.destination) return;
    dispatch({
      type: ReducerActions.UPDATE_FIELD_ORDER,
      payload: {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index
      }
    });
  };

  return (
    <common.Section>
      <common.Header>
        <common.SubTitle>Fields</common.SubTitle>
        <ButtonNew type="primary" icon="plus" onClick={onAdd}>
          New field
        </ButtonNew>
      </common.Header>
      <styled.HeaderRow>
        <styled.Placeholder />
        <styled.Label>Name</styled.Label>
        <styled.Label>Type</styled.Label>
        <styled.RequiredLabel>Required</styled.RequiredLabel>
        <styled.Placeholder />
      </styled.HeaderRow>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="fieldList">
          {(provided, _) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {state.fields.map((field, i) => (
                <Draggable key={i} draggableId={field.name + i} index={i}>
                  {(provided, _) => (
                    <styled.FieldRow
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <styled.IconContainer {...provided.dragHandleProps}>
                        <DragHandle />
                      </styled.IconContainer>
                      <styled.Input
                        onChange={e => onChangeField({ name: e.target.value }, i)}
                        value={field.name}
                      />
                      <styled.StyledTypeSelect
                        onChange={type => onChangeField({ type }, i)}
                        value={field.type}
                        enabledAttributeTypes={QUEUE_ATTRIBUTE_TYPES}
                      />
                      <styled.RequiredColumn>
                        <Checkbox
                          checked={field.required}
                          onChange={e =>
                            onChangeField({ required: e.target.checked }, i)
                          }
                        />
                      </styled.RequiredColumn>
                      <styled.IconContainer>
                        <Icon type="delete" onClick={() => onDelete(i)} />
                      </styled.IconContainer>
                    </styled.FieldRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </common.Section>
  );
}
