import { SpaceComponentPackage, SpaceComponentHostType } from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import SpaceHeader from "./SpaceHeader";

const pkg: SpaceComponentPackage = {
  type: "HEADER",
  displayName: "Header",
  allowedHosts: new Set<SpaceComponentHostType>([]),
  Component: SpaceHeader,
  ConfigurationComponent: null,
  ensureComponent: component => component,
  componentConfigReducer: commonComponentReducer,
  defaultElementLayout: new ElementLayout({ snapToGrid: true }),
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isHeadless: false,
  errorSelector: () => [],
  isContainer: false
};

export default pkg;
