import React from "react";

import { partition } from "lodash";

import { SpaceComponentObject } from "../../../../../types";
import { useStableSpaceContext } from "../../SpaceContext";
import SpaceComponent, { Props } from "../SpaceComponent";

import ConfigSpaceHeader from "./ConfigSpaceHeader/ConfigSpaceHeader";
import * as styled from "./StyledComponents";

export default function SpaceHeader(props: Props) {
  const {
    spaceComponent: { componentTreeNodes }
  } = props;
  const { editMode } = useStableSpaceContext();
  const [actionNodes, nodes] = partition(
    componentTreeNodes,
    node => node.type !== "CUSTOM_FIELD"
  );

  const getPropsForComponent = (n: SpaceComponentObject) => ({
    ...props,
    key: n.id,
    spaceComponent: n
  });

  if (editMode) return <ConfigSpaceHeader {...props} />;
  return (
    <styled.Root>
      <styled.LeftHeader>
        <styled.Title>{props.spaceComponent.properties.text}</styled.Title>
        <styled.Filters>
          {nodes.map(n => (
            <SpaceComponent {...getPropsForComponent(n)} />
          ))}
        </styled.Filters>
      </styled.LeftHeader>
      <styled.Actions>
        {actionNodes.map(n => (
          <SpaceComponent {...getPropsForComponent(n)} />
        ))}
      </styled.Actions>
    </styled.Root>
  );
}
