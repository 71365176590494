import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { isEqual } from "lodash";

import { BindingShape, bindingToAttribute } from "../../../../../../types";
import usePrevious from "../../../../../common/hooks/usePrevious";
import { ComponentConfigState, SpaceConfigAction } from "../../../../types";
import { useSpaceContext } from "../../../SpaceContext";
import { extractAttributeColumns } from "../ColumnListManager";
import {
  CONFIG_FUNCTION_QUERY,
  ConfigFunctionData,
  CREATE_FUNCTION_VIEW
} from "../queries";
import { ColumnRecord } from "../useView/useView";

import { Action, ensureViewConfigState } from "./reducer";

export interface Result {
  loading: boolean;
  submitting: boolean;
}

const EMPTY_COLUMNS_ARRAY: ColumnRecord[] = [];

export default function useViewConfig({
  state,
  dispatch
}: {
  state: ComponentConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}): Result {
  const stateWithView = ensureViewConfigState(state);
  const view = stateWithView.draftComponent.view;

  // HACK - For some reason, doing an optional chain on function (ie. view?.function?.id),
  //        in this particular module breaks babel parsing while running tests!
  let funcId: undefined | string;
  if (view && view["function"] && view["function"].id) {
    funcId = view["function"].id;
  }

  const {
    data: functionData,
    loading: functionLoading,
    error: functionError
  } = useQuery<ConfigFunctionData>(CONFIG_FUNCTION_QUERY, {
    variables: {
      functionId: funcId,
      viewId: view?.id
    },
    fetchPolicy: "cache-and-network",
    skip: !funcId
  });
  if (functionError) throw functionError;

  // FIXME: findNode should be a useEffect dependency but adding it causes recursive rerendering
  const { getBinding } = useSpaceContext();

  React.useEffect(() => {
    if (stateWithView.draftComponent.sourceType === "VIEW") {
      return;
    }

    const path = stateWithView.draftComponent.properties.binding;
    const binding = getBinding(path);

    if (binding) {
      const attributes =
        binding.shape === BindingShape.OBJECT ||
        binding.shape === BindingShape.OBJECT_ARRAY
          ? binding.attributes.map(b => bindingToAttribute(b))
          : [];

      if (isEqual(attributes, stateWithView.attributes)) return;

      dispatch({
        type: Action.LOAD_BINDING,
        payload: {
          name: path,
          attributes
        }
      });
    }
  }, [
    stateWithView.attributes,
    stateWithView.draftComponent.sourceType,
    stateWithView.draftComponent.properties.binding,
    getBinding,
    dispatch
  ]);

  const columns =
    stateWithView?.draftComponent.properties.columns || EMPTY_COLUMNS_ARRAY;
  const attrColumns = React.useMemo(() => extractAttributeColumns(columns), [columns]);

  const [
    createFunctionView,
    { loading: submittingFunctionView, data: createFunctionViewData }
  ] = useMutation(CREATE_FUNCTION_VIEW, {
    variables: {
      functionId: funcId,
      functionAttributeIds: attrColumns
        .map(
          c =>
            stateWithView?.attributes?.find(a => a.sourceName === c.attribute)?.id ||
            undefined
        )
        .filter(a => !!a)
    }
  });

  React.useEffect(() => {
    if (!functionLoading && functionData) {
      // null functionData indicates the component's view's function has been deleted.
      if (functionData === null || functionData.node === null) {
        dispatch({
          type: Action.CHANGE_FUNCTION,
          payload: { functionId: null }
        });
        return;
      }
      dispatch({
        type: Action.LOAD_FUNCTION,
        payload: functionData.node
      });
    }
  }, [functionLoading, functionData, dispatch]);

  const attrNames = React.useMemo(
    () => attrColumns.map(c => c.attribute),
    [attrColumns]
  );
  const viewKey = funcId && attrNames.length ? `${funcId}:${attrNames.join()}` : null;
  const lastViewKey = usePrevious(viewKey, { returnCurrentOnFirstRender: true });
  React.useEffect(() => {
    if (viewKey === lastViewKey || viewKey === null) return;
    createFunctionView();
  }, [view, viewKey, lastViewKey, createFunctionView]);

  const nextViewId = createFunctionViewData?.createFunctionView.view.id;
  React.useEffect(() => {
    if (!nextViewId) return;
    dispatch({
      type: "SET_DRAFT_COMPONENT",
      payload: { path: "view.id", value: nextViewId }
    });
  }, [nextViewId, dispatch]);

  return {
    loading: functionLoading,
    submitting: submittingFunctionView
  };
}
