import React from "react";

import { get } from "lodash";
import styled from "styled-components";

import JsonViewer from "../../../../common/JsonViewer";
import Panel from "../common/Panel";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

const Root = styled(Panel)`
  header {
    flex-wrap: wrap;
    padding: 15px;
  }

  h2 {
    flex-grow: 1;
    margin: 5px;
  }
`;
Root.displayName = "Panel";

const DataViewerContainer = styled.div`
  padding: ${props => props.theme.spacerlg};
`;

// Define the shape of the properties specific to this Component type.  This should probably be in the place we configure
// it rather than here.
interface Properties {
  binding: string;
}

// An extremely simple component which displays a binding in a JSONViewer.
export default function SpaceDataViewer(props: Props) {
  // The spaceComponent represents the state of a particular instance of the SpaceDataViewer.  It's a SpaceComponentObject
  // which contains the attributes that all SpaceComponents share.
  const { spaceComponent } = props;
  // When we configure this component in the UI, this is where the values specific to this component live.
  const properties: Properties = spaceComponent.properties;
  // The slug of the component we're bound to.
  const { binding } = properties;

  const { input } = useComponentStateContext();

  // This is the value we're going to display in the JSON Viewer.  We assume that the JSONViewer can handle it.
  const displayValue = get(input, binding);
  const isJSONObject = typeof displayValue === "object";

  // And the rest here is just normal react
  return (
    <Root title={spaceComponent.properties.title} hasError={props.hasConfigError}>
      <DataViewerContainer>
        {displayValue ? (
          isJSONObject ? (
            <JsonViewer json={displayValue} showBorder={false} />
          ) : (
            displayValue
          )
        ) : (
          ""
        )}
      </DataViewerContainer>
    </Root>
  );
}
