import { ConfigValidationError, SpaceComponentObject } from "../../../../../../types";
import validateConditionalExpression from "../Conditionals/validateConditional";

import { ValidationRule } from "./types";

export default function selectValidationRulesErrors(component: SpaceComponentObject) {
  const errors: ConfigValidationError[] = [];
  const { validation_rules: rules } = component.properties;
  if (!rules) return errors;
  rules.forEach((rule: ValidationRule, idx: number) => {
    if (!rule.message_template) {
      errors.push({
        field: "VALIDATION_RULES",
        index: idx,
        message: "This validation rule needs a message for when the input is invalid."
      });
    }
    const conditionalErrors = validateConditionalExpression(
      rule.conditional_expression
    );
    conditionalErrors.forEach(error => {
      errors.push({
        field: "VALIDATION_RULES",
        index: idx,
        message: error
      });
    });
  });
  return errors;
}
