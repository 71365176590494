import React from "react";

import { Select as AntSelect } from "antd";
import styled from "styled-components";

import { GlobalStyleVariables } from "../../../../../../cssConstants";
import useElementTransformer from "../../../../layout/Element/useElementTransformer";
import { ElementLayout } from "../../../../layout/util";
import {
  ButtonBackgroundToTextColorMap,
  ButtonShapes,
  DEFAULT_BUTTON_BACKGROUND_COLOR
} from "../Button/constants";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { ConfigSection } from "../ConfigPanel";
import { Field, Select } from "../ConfigPanel/styledComponents";
import { StyleField, StyleOption } from "../DesignConfig/styledComponents";
import useNestedConfig from "../useNestedStatus";

const { Option } = AntSelect;

const Container = styled.div`
  display: flex;

  ${Field} + ${Field} {
    margin-top: 0;
    margin-left: ${props => props.theme.spacersm};

    .ant-select-selection__rendered {
      display: flex;
      align-items: center;
    }
  }
`;

const ColorOption = styled(StyleOption)<{ backgroundColor: string }>`
  background: ${props => props.backgroundColor};
  color: ${props => ButtonBackgroundToTextColorMap[props.backgroundColor]};
`;

const ColorField = styled(StyleField)`
  min-width: 74px;
`;

const ShapeField = styled(StyleField)`
  min-width: 90px;
`;

const Rectangle = styled(StyleOption)`
  border-radius: ${props => props.theme.borderRadiusmd};
  width: 36px;
`;

const Circle = styled(StyleOption)`
  border-radius: 50%;
  margin-left: 7px;
  margin-right: 7px;
`;

export default function DesignConfig() {
  const context = useComponentConfigContext();
  const { transform } = useElementTransformer();
  const { isNested } = useNestedConfig();
  const { draftComponent } = context.state;
  const { dispatch } = context;

  return (
    <ConfigSection title="Style">
      <Container>
        <ColorField>
          <label>Color</label>
          <Select
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            value={
              draftComponent.properties.button_background_color ||
              DEFAULT_BUTTON_BACKGROUND_COLOR
            }
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.button_background_color",
                  value
                }
              })
            }
          >
            {Object.keys(ButtonBackgroundToTextColorMap).map(color => (
              <Option key={color}>
                <ColorOption backgroundColor={color} />
              </Option>
            ))}
          </Select>
        </ColorField>
        <ShapeField>
          <label>Shape</label>
          <Select
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            value={draftComponent.properties.button_shape || ButtonShapes.Rectangle}
            onChange={value => {
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.button_shape",
                  value
                }
              });

              // update minWidth to create circle-shaped button; for non-form components, default width as well (user can resize width after)
              const changes: Partial<ElementLayout> = {
                minWidth:
                  value === ButtonShapes.Circle
                    ? GlobalStyleVariables.buttonHeight
                    : undefined
              };
              if (
                value === ButtonShapes.Circle &&
                draftComponent.type !== "FUNCTION_FORM" &&
                !isNested
              ) {
                changes["width"] = GlobalStyleVariables.buttonHeight;
              }
              transform(changes);
            }}
          >
            <Option key={ButtonShapes.Rectangle}>
              <Rectangle />
            </Option>
            <Option key={ButtonShapes.Circle}>
              <Circle />
            </Option>
          </Select>
        </ShapeField>
      </Container>
    </ConfigSection>
  );
}
