import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { QueryHookOptions } from "react-apollo";

import { INPUT_PARAMETER_FRAGMENT } from "../../../../queries/common";

import { AllTasksData, AllTasksVariables } from "./AllTasks";

export const TASK_CHECK_QUERY = gql`
  query TaskCheck(
    $orderBy: String
    $id: String
    $queueSlug: String
    $isAssignedTo: Boolean
    $isAssignedToUser: Boolean
    $isAssignedToUsersRole: Boolean
    $stateIdIn: [String]
    $isArchived: Boolean
    $search: String
    $after: String
    $before: String
    $first: Int
  ) {
    allTasks(
      orderBy: $orderBy
      id: $id
      queueSlug: $queueSlug
      isArchived: $isArchived
      isAssignedTo: $isAssignedTo
      isAssignedToUser: $isAssignedToUser
      isAssignedToUsersRole: $isAssignedToUsersRole
      stateIdIn: $stateIdIn
      search: $search
      after: $after
      before: $before
      first: $first
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          transitionedAt
          title
          requester {
            id
            name
          }
          queue {
            id
            slug
            name
          }
          assignee {
            id
            type
            name
          }
          state {
            id
            name
            color
            fromTransitions {
              id
              name
              isPrimary
              functionId
              inputParameters {
                ...InputParameterFragment
              }
            }
          }
          fieldValues {
            name
            value
            type
          }
        }
      }
    }
  }
  ${INPUT_PARAMETER_FRAGMENT}
`;

export const useTaskCheck = (
  options?: QueryHookOptions<AllTasksData, Partial<AllTasksVariables>>
) => {
  const variables = options?.variables;
  return useQuery<AllTasksData, AllTasksVariables>(TASK_CHECK_QUERY, {
    ...options,
    variables: {
      ...variables,
      first: variables?.first ?? 50,
      orderBy: variables?.orderBy === undefined ? "-transitioned_at" : variables.orderBy
    }
  });
};
