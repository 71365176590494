import styled from "styled-components";

import { Size } from "../../../cssConstants";

import { FormGridContainer as FunctionFormGridContainer } from "./forms/styledComponents";

export const FormGridContainer = styled(FunctionFormGridContainer)`
  max-width: ${Size.md};
  flex-grow: 1;
`;

export const ConfigurationPane = styled.div`
  height: 100%;
  width: 100%;
`;

export const UnseenIndicator = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${props => props.theme.unseenDotColor};
  border-radius: 50%;
  display: inline-block;
  margin-left: 6px;
  margin-bottom: 1px;
`;
