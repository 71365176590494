import "core-js/stable";
import "whatwg-fetch"; // Polyfill fetch since it's not included in core-js

// The `blob-polyfil` npm package and Parcel do not play nicely together.
// Replace this with a full polyfill for Blob in the future.
//
// https://developer.mozilla.org/en-US/docs/Web/API/Blob/text#Polyfill
if (!Blob.prototype.text) {
  Object.defineProperty(Blob.prototype, "text", {
    configurable: true,
    enumerable: true,
    writable: true,
    value: function text() {
      return new Response(this).text();
    }
  });
}
