import { AttributeTypes } from "../../../../../../constants";
import { Binding, BindingShape } from "../../../../../../types";
import { fromGlobalId } from "../../../../../util/graphql";
import { TaskNode } from "../../useQueueClient/queries/AllTasks";

interface TaskValue {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  fieldValues: Record<string, any>;
}

export const createTaskValue = (task: TaskNode): TaskValue => ({
  id: fromGlobalId(task.id)[1],
  title: task.title,
  createdAt: task.createdAt,
  updatedAt: task.updatedAt,
  fieldValues: Object.fromEntries(
    (task.fieldValues || []).map(value => [value.name, value.value])
  )
});

export const createTaskSchema = (task: TaskNode): Binding[] => [
  {
    name: "id",
    title: "Id",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "title",
    title: "Title",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "createdAt",
    title: "Created At",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.DATETIME
  },
  {
    name: "updatedAt",
    title: "Updated At",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.DATETIME
  },
  {
    name: "fieldValues",
    title: "Field Values",
    shape: BindingShape.OBJECT,
    attributes: (task.fieldValues || []).map(fv => ({
      name: fv.name,
      title: fv.name,
      shape: BindingShape.SCALAR,
      type: fv.type
    }))
  }
];
