import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import { Home as HomeComponent } from "../components/automations/App/Home";
import { Config } from "../components/automations/Config";
import { Layout, PaddedLayout } from "../layouts";

import { AccountLockedGate } from "./errors";

export const Home = () => {
  return (
    <RequireAuth>
      <Layout>
        <AccountLockedGate>
          <HomeComponent />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};

export const NewAutomation = () => {
  return (
    <RequireAuth>
      <PaddedLayout>
        <AccountLockedGate>
          <Config />
        </AccountLockedGate>
      </PaddedLayout>
    </RequireAuth>
  );
};

export const EditAutomation = () => {
  return (
    <RequireAuth>
      <PaddedLayout>
        <AccountLockedGate>
          <Config />
        </AccountLockedGate>
      </PaddedLayout>
    </RequireAuth>
  );
};
