import usePrevious from "../../../../common/hooks/usePrevious";
import { FetchType } from "../common/useView";

import { SpaceTableStatus } from "./types";

export default function useComponentStatus(
  viewFetchStatus: FetchType,
  tableStatus: SpaceTableStatus,
  updateOutput: (state: Object) => void
) {
  const lastViewFetchStatus = usePrevious(viewFetchStatus);
  if (
    viewFetchStatus === FetchType.INITIAL_FETCH &&
    lastViewFetchStatus !== FetchType.INITIAL_FETCH
  ) {
    updateOutput({ status: SpaceTableStatus.INITIAL_LOADING });
  } else if (
    lastViewFetchStatus === FetchType.INITIAL_FETCH &&
    viewFetchStatus === FetchType.NONE
  ) {
    updateOutput({ status: SpaceTableStatus.INITIAL_LOAD_COMPLETE });
  } else if (
    viewFetchStatus === FetchType.NONE &&
    tableStatus === SpaceTableStatus.INITIAL_LOAD_COMPLETE
  ) {
    updateOutput({ status: SpaceTableStatus.IDLE });
  } else if (viewFetchStatus === FetchType.REFETCHING) {
    updateOutput({ status: SpaceTableStatus.RELOADING });
  } else if (
    lastViewFetchStatus === FetchType.REFETCHING &&
    viewFetchStatus === FetchType.NONE
  ) {
    updateOutput({ status: SpaceTableStatus.RELOAD_COMPLETE });
  } else if (
    viewFetchStatus === FetchType.NONE &&
    tableStatus === SpaceTableStatus.RELOAD_COMPLETE
  ) {
    updateOutput({ status: SpaceTableStatus.IDLE });
  } else if (tableStatus !== SpaceTableStatus.IDLE) {
    updateOutput({ status: SpaceTableStatus.IDLE });
  }
}
