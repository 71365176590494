import React from "react";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { GlobalStyleVariables } from "../../../../cssConstants";
import { SpaceNode } from "../../../../types";
import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";
import { useSpacesContext } from "../../hooks/useSpacesManager/useSpacesManager";

export default function DeleteSpaceModal({
  space,
  onCancel
}: {
  space: SpaceNode;
  onCancel: () => void;
}) {
  const { updateSpaceListQuery } = useSpacesContext();
  const [deleteSpace, { loading, client }] = useMutation<
    SpaceDeleteData,
    SpaceDeleteVariables
  >(SPACE_DELETE);
  return (
    <ModalNew
      visible
      title="Are you sure?"
      width={GlobalStyleVariables.confirmModal}
      okText="Delete"
      confirmLoading={loading}
      onOk={async () => {
        const result = await deleteSpace({ variables: { id: space.id } });
        if (result.data?.spaceDelete.ok) {
          Message.success("The space has been deleted.");
          if (!client) return;
          updateSpaceListQuery("DELETE", { id: space.id });
        }
        if (result.errors) {
          Message.error("Something went wrong. Please try again.");
        }
      }}
      onCancel={onCancel}
    >
      <p>This space and all of its configuration will be permanently deleted.</p>
    </ModalNew>
  );
}

type SpaceDeleteVariables = { id: string };
type SpaceDeleteData = { spaceDelete: { ok: boolean } };
export const SPACE_DELETE = gql`
  mutation SpaceDelete($id: ID!) {
    spaceDelete(id: $id) {
      ok
    }
  }
`;
