import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button } from "antd";
import _ from "lodash";
import { useNavigate, useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { FETCH_DATA_SOURCES } from "../../graphql/queries";
import useAuthUser from "../common/hooks/useAuthUser";
import usePaths from "../common/hooks/usePaths";
import { ModalNew } from "../common/Modal";
import { nextPlanUp } from "../util/billing";

import { DetailParams } from "./common";
import DataSourceDrawer from "./DataSource/DataSourceDrawer";
import DataSourceTable, { DataSource } from "./DataSourceTable";
import ManageEnvironmentsModal from "./environments/ManageEnvironmentsModal";

const StyledSection = styled.section`
  display: flex;
  margin-bottom: ${props => props.theme.spacermd};

  button + button {
    margin-left: ${props => props.theme.spacersm};
  }
`;

const DataSourcePaywall = styled(ModalNew)`
  left: 180px;
  top: 60px;
`;

type AllDataSourceProvidersResponse = any;

export const reduce = (data: AllDataSourceProvidersResponse): DataSource[] => {
  const dataSources = [];
  for (const dspEdge of _.get(data, "allDataSourceProviders.edges", [])) {
    for (const dsEdge of _.get(dspEdge, "node.dataSources.edges", [])) {
      const dataSourceProviderId = dspEdge.node.id;
      dataSources.push({ ...dsEdge.node, dataSourceProviderId });
    }
  }
  return dataSources;
};

export const DataSourceListComponent = () => {
  const navigate = useNavigate();
  const { authUser } = useAuthUser();
  const { getSetupDataSource } = usePaths();
  const { id: dataSourceId } = useParams<DetailParams>();
  const [isManageEnvironmentsVisible, setManageEnvironmentsVisible] = useState(false);
  const [showDSPaywall, setShowDSPaywall] = useState<boolean>(false);
  const { data, loading } = useQuery(FETCH_DATA_SOURCES, {
    fetchPolicy: "cache-and-network"
  });

  const features = authUser ? authUser.organization.licenseData : {};
  const dataSources = reduce(data);
  const showDrawer = !!dataSourceId;
  const selectedDataSource = dataSources.find(
    dataSource => dataSource.id === dataSourceId
  );

  const handleAddDataSourceClick = () => {
    navigate(getSetupDataSource());
  };

  const providerCount = (dataSources || []).filter(
    ds => ds.integration !== "queues"
  )?.length;

  return (
    <React.Fragment>
      <StyledSection>
        <Button
          type="primary"
          onClick={handleAddDataSourceClick}
          disabled={!!features?.dataSources && providerCount >= features?.dataSources}
          onMouseEnter={() => setShowDSPaywall(true)}
        >
          Add Data Source
        </Button>

        <Button onClick={() => setManageEnvironmentsVisible(true)}>
          Manage Environments
        </Button>
      </StyledSection>
      <DataSourceTable loading={loading} dataSources={dataSources} />
      {showDrawer && (
        <DataSourceDrawer
          onClose={() => navigate("/settings/data-sources")}
          isVisible={showDrawer}
          loading={loading}
          dataSource={selectedDataSource}
        />
      )}
      {isManageEnvironmentsVisible && (
        <ManageEnvironmentsModal onClose={() => setManageEnvironmentsVisible(false)} />
      )}
      {!!features?.dataSources && providerCount >= features?.dataSources && (
        <DataSourcePaywall
          absolutePositioning={true}
          title="Upgrade to add more data sources"
          visible={showDSPaywall}
          onCancel={() => setShowDSPaywall(false)}
          mask={false}
          footer={null}
          width={270}
        >
          <p>
            You've reached the maximum number of data sources for your account. To add
            more data sources, upgrade to the{" "}
            <Link to="/settings/change-plan">
              {nextPlanUp(authUser?.organization.currentPlan?.slug)} plan
            </Link>
            .
          </p>
        </DataSourcePaywall>
      )}
    </React.Fragment>
  );
};

export default DataSourceListComponent;
