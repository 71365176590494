import gql from "graphql-tag";

import { DataSourceNodeWithFunctions } from "../components/common/FunctionEditor/forms/types";
import { BaseFunctionNodeBasic, Connection, DataSourceProviderNode } from "../types";

export const INVITE_USER_MUTATION = gql`
  mutation InviteUser($email: String!, $roleId: String!) {
    inviteUser(email: $email, roleId: $roleId) {
      ok
    }
  }
`;

export const RESEND_INVITE_MUTATION = gql`
  mutation ResendInvite($email: String!) {
    resendInvite(email: $email) {
      ok
    }
  }
`;

export const REMOVE_INVITE_MUTATION = gql`
  mutation RemoveInvite($email: String!) {
    removeInvite(email: $email) {
      ok
    }
  }
`;

export const FETCH_AUTH_USER = gql`
  query authUserQuery {
    authUser {
      id
      email
      firstName
      lastName
      isAdmin
      scopes
      organization {
        id
        licenseData {
          userLimit
          roles
          sso
          permissions
          twoFactor
          environments
          dataSources
          versionHistory
          auditLogs
        }
        stripeSubscriptionId
        currentPlan
        locked
        customLicensing
      }
      roles {
        edges {
          node {
            id
            isManaged
            name
            slug
          }
        }
      }
    }
  }
`;

export const FETCH_DATA_SOURCES = gql`
  query FetchDataSources {
    allDataSourceProviders {
      edges {
        node {
          id
          dataSources {
            edges {
              node {
                id
                integration
                integrationTitle
                name
                environmentCount
                functionCount
                synchronizedAt
                credentials
                supportsSync
                autoSyncEnabled
              }
            }
          }
        }
      }
    }
  }
`;

export interface FetchDataProvidersAndBaseFunctionsData {
  allDataSourceProviders: Connection<
    DataSourceProviderNode<DataSourceNodeWithFunctions<BaseFunctionNodeBasic>>
  >;
}

export const FETCH_DATA_PROVIDERS_AND_BASE_FUNCTIONS = gql`
  query FetchDataProvidersAndFunctions {
    allDataSourceProviders {
      edges {
        node {
          id
          adapter
          dataSources {
            edges {
              node {
                id
                integration
                name
                credentials
                dataSourceProvider {
                  id
                }
                functions(scope: "base") {
                  edges {
                    node {
                      id
                      title
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/* Settings related queries */
export const GET_ALL_USERS_DATA = gql`
  query AllUsersQuery {
    allUsers {
      edges {
        node {
          id
          firstName
          lastName
          email
          roles {
            edges {
              node {
                id
                name
              }
            }
          }
          status
        }
      }
    }
  }
`;

export const GET_ALL_INVITES_DATA = gql`
  query AllInvitesQuery {
    allOrganizationInvites {
      edges {
        node {
          id
          email
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      roles {
        edges {
          node {
            id
            name
          }
        }
      }
      status
    }
  }
`;

const BASIC_ROLE_FRAGMENT = gql`
  fragment BasicRoleFragment on RoleNode {
    id
    name
    userCount
    functionCount
    createdAt
  }
`;

export const EXTENDED_ROLE_FRAGMENT = gql`
  fragment ExtendedRoleFragment on RoleNode {
    ...BasicRoleFragment
    functions {
      id
      title
    }
    allowsAllFunctions
  }
  ${BASIC_ROLE_FRAGMENT}
`;

export const GET_ALL_ROLES = gql`
  query GetAllRoles {
    allRoles {
      edges {
        node {
          ...BasicRoleFragment
        }
      }
    }
  }
  ${BASIC_ROLE_FRAGMENT}
`;

export const GET_ROLE_BY_ID_WITH_PERMISSIONS = gql`
  query RoleWithPermissionsQuery($id: ID!) {
    authScopes {
      name
      description
    }
    role(id: $id) {
      ...ExtendedRoleFragment
      scopes
    }
  }
  ${EXTENDED_ROLE_FRAGMENT}
`;

export const UPDATE_ROLE_FOR_USERS = gql`
  mutation UpdateRoleForUsers($userIds: [String!], $roleId: String!) {
    updateRoleForUsers(userIds: $userIds, roleId: $roleId) {
      users {
        id
        roles {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const ADD_ROLE = gql`
  mutation AddRole($roleName: String!, $permissions: [RoleDerivedPermissionInput!]) {
    createRole(roleName: $roleName, permissions: $permissions) {
      role {
        ...ExtendedRoleFragment
      }
    }
  }
  ${EXTENDED_ROLE_FRAGMENT}
`;

export const GET_AUDIT_LOGS = gql`
  query GetAuditLogs(
    $user: ID
    $space: ID
    $function: ID
    $environment: ID
    $cursor: String
  ) {
    allAuditLogs(
      user: $user
      space: $space
      function: $function
      environment: $environment
      first: 10
      after: $cursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          user {
            id
            email
            firstName
            lastName
          }
          function {
            id
            name
            title
          }
          createdAt
          action
          payload
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_DATA = gql`
  query SubscriptionQuery {
    stripeSubscription {
      subscription
    }
  }
`;

export const REFRESH_SUBSCRIPTION_MUTATION = gql`
  mutation RefreshSubscription(
    $subscriptionId: String!
    $newProductSlug: String!
    $newProductTerm: String!
    $quantity: Int!
  ) {
    refreshSubscription(
      subscriptionId: $subscriptionId
      newProductSlug: $newProductSlug
      newProductTerm: $newProductTerm
      quantity: $quantity
    ) {
      subscription
    }
  }
`;

export const GET_PRICING_DATA = gql`
  query PricingQuery($slug: String!) {
    getAllProductPrices(slug: $slug)
  }
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation UpdateCustomer(
    $customerName: String
    $addressCity: String
    $addressCountry: String
    $addressStreet1: String
    $addressZip: String
    $taxIdType: String
    $taxId: String
  ) {
    updateCustomer(
      customerName: $customerName
      addressCity: $addressCity
      addressCountry: $addressCountry
      addressStreet1: $addressStreet1
      addressZip: $addressZip
      taxIdType: $taxIdType
      taxId: $taxId
    ) {
      customer
    }
  }
`;

export const UPDATE_PAYMENT_METHOD_MUTATION = gql`
  mutation UpdatePaymentMethod($paymentMethodId: String!) {
    updatePaymentMethod(paymentMethodId: $paymentMethodId) {
      ok
    }
  }
`;

export const GET_ACCOUNT_DATA = gql`
  query AccountDataQuery {
    stripeSubscription {
      subscription
    }
    getUpcomingInvoice {
      invoice
    }
    getAllPaymentIntents
  }
`;
