import React, { useState, useReducer } from "react";

import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { H3, H4 } from "../../../components/common/StyledComponents";
import { UPDATE_CUSTOMER_MUTATION } from "../../../graphql/queries";
import { Price, Subscription } from "../../../types";
import Message from "../../common/Message";
import { planName } from "../../util/billing";

import { BillingContext, billingReducer } from "./BillingContext";
import BillingInformationForm from "./BillingInformationForm";
import { OrderSummary } from "./checkout/OrderSummary";
import PlanPicker from "./PlanPicker";
import TAX_COUNTRY_OPTIONS from "./TaxCountryOptions";

const PlanFormContainer = styled.div`
  position: relative;
  width: 100%;

  p.error {
    color: red;
  }
`;

const PlanForm = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;

  .body {
    display: flex;
  }
`;

const PlanFormTitle = styled.div`
  span {
    text-transform: capitalize;
  }
`;

const TitleH3 = styled(H3)`
  margin-bottom: 60px;
`;

const PaymentDetails = styled.div`
  width: 60%;
  border-right: 1px solid #cccccc;
  padding: 0 40px 40px 0;

  label {
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  #taxIdType {
    display: inline-block;
    margin-right: 20px;
    width: 48%;
  }

  input[type="text"] {
    width: 100%;
    border: 1px solid ${props => props.theme.borderGrey};
    border-radius: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    padding-left: 10px;
    line-height: 3;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    position: relative
    margin-bottom: 20px;

    &[name="billingTaxId"] {
      display: inline-block;
      width: 48%;

      &.invalid {
        border: 1px solid red;
      }
    }
  }

  #billingForm.validate {
    input:invalid {
      border: 1px solid red;
    }
  }
`;

const CancelLink = styled(Link)`
  display: block
  margin: 20px 0;
`;

interface CheckoutProps {
  subscription: Subscription | null;
  currentPricesData:
    | {
        getAllProductPrices: {
          data: Price[];
        };
      }
    | null
    | undefined;
  paymentTerm: string | undefined;
  currentPlanSlug: string | undefined;
  newPlanSlug: string | undefined;
  updateSubscriptionTerms: (paymentTerm: string) => void;
  loadingSubscription: boolean;
  setPaymentTerm: (paymentTerm: string) => void;
  pricesLoading: boolean;
}

interface ValidateableForm extends HTMLElement {
  requestSubmit(): void;
}

const Checkout = ({
  subscription,
  currentPricesData,
  paymentTerm,
  currentPlanSlug,
  newPlanSlug,
  updateSubscriptionTerms,
  loadingSubscription,
  setPaymentTerm,
  pricesLoading
}: CheckoutProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const initialState = {
    billingCity: "",
    billingAddress: "",
    billingZip: "",
    taxIdErrorMessage: "",
    billingCountry: "",
    billingCountryError: "",
    taxId: "",
    taxIdType: "",
    billingCompany: "",
    formValidation: false,
    setBillingCountryError: () => {},
    onCountryUpdate: () => {},
    onSelectTaxIdType: () => {}
  };
  const [formValidation, setFormValidation] = useState(false);
  const [_, setBillingCountryError] = useState("");
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>(
    ""
  );

  const [state, dispatch] = useReducer(billingReducer, initialState);

  const setBillingCity = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_CITY", payload });
  const setBillingAddress = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_ADDRESS", payload });
  const setBillingZip = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_ZIP", payload });
  const setTaxIdErrorMessage = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_TAX_ID_ERROR_MESSAGE", payload });
  const setBillingCompany = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_COMPANY", payload });
  const setBillingCountry = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_BILLING_COUNTRY", payload });
  const setTaxId = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_TAX_ID", payload });
  const setTaxIdType = (dispatch: React.Dispatch<any>, payload: {}) =>
    dispatch({ type: "SET_TAX_ID_TYPE", payload });

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER_MUTATION);

  const handlePlanClick = (termVal: string) => {
    if (termVal) {
      setPaymentTerm(termVal);
      updateSubscriptionTerms(termVal);
    }
  };

  const onCityUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingCity(dispatch, e.target.value);
  };

  const onZipUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingZip(dispatch, e.target.value);
  };

  const onAddressUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingAddress(dispatch, e.target.value);
  };

  const onCountryUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBillingCountry(dispatch, e.target.value);
    setTaxIdType(dispatch, "");
    setTaxId(dispatch, "");
  };

  const onCompanyUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingCompany(dispatch, e.target.value);
  };

  const onSelectTaxIdType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTaxIdType(dispatch, e.target.value);
  };

  const onTaxIdUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxId(dispatch, e.target.value);
  };

  const billingFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const priceData: { monthly?: number; annual?: number } = {};

  if (!pricesLoading) {
    currentPricesData?.getAllProductPrices.data.forEach((price: Price) => {
      const key = price.recurring.interval === "month" ? "monthly" : "annual";
      priceData[key] = price.unit_amount / 100;
    });
  }

  const handleOrderSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (state.billingCountry) {
      setBillingCountryError("");
    } else {
      setBillingCountryError("Billing country is required.");
      return;
    }

    setFormValidation(true);

    const billingForm = document.getElementById("billingForm") as ValidateableForm;

    if (billingForm && billingForm.requestSubmit) {
      billingForm.requestSubmit();

      if (billingForm?.querySelector("input:invalid")) {
        return;
      }
    }

    setSubmitDisabled(true);
    setFormValidation(false);

    const address = {
      city: state.billingCity,
      country: state.billingCountry,
      line1: state.billingAddress,
      postal_code: state.billingZip
    };

    updateCustomer({
      variables: {
        customerName: state.billingCompany,
        addressCity: state.billingCity,
        addressCountry: state.billingCountry,
        addressStreet1: state.billingAddress,
        addressZip: state.billingZip,
        taxIdType: state.taxIdType,
        taxId: state.taxId
      }
    })
      .then(async () => {
        setTaxIdErrorMessage(dispatch, "");
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.protocol}//${window.location.host}/settings/change-plan/confirmation`,
            payment_method_data: {
              billing_details: {
                address
              }
            }
          }
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)

          setPaymentErrorMessage(error.message);
          Message.error(error.message);
          setSubmitDisabled(false);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      })
      .catch(() => {
        setSubmitDisabled(false);
        setTaxIdErrorMessage(dispatch, "The given Tax ID is invalid.");
        Message.error("The given Tax ID is invalid.");
      });
  };

  const contextValue = {
    onCountryUpdate,
    onCompanyUpdate,
    onAddressUpdate,
    onCityUpdate,
    onZipUpdate,
    onSelectTaxIdType,
    onTaxIdUpdate,
    billingCountry: state.billingCountry,
    billingCountryError: state.billingCountryError,
    billingCompany: state.billingCompany,
    billingAddress: state.billingAddress,
    billingCity: state.billingCity,
    billingZip: state.billingZip,
    taxId: state.taxId,
    taxIdType: state.taxIdType,
    taxIdErrorMessage: state.taxIdErrorMessage,
    setBillingCountryError
  };

  return (
    <BillingContext.Provider value={contextValue}>
      <PlanFormContainer>
        <PlanForm>
          <PlanFormTitle>
            <CancelLink to="/settings/change-plan">Cancel</CancelLink>
            <TitleH3>
              Upgrade from <span>{planName(currentPlanSlug)}</span> to{" "}
              <span>{planName(newPlanSlug)}</span>
            </TitleH3>
          </PlanFormTitle>
          <div className="body">
            <PaymentDetails>
              <PlanPicker
                onClick={handlePlanClick}
                priceData={priceData}
                paymentTerm={paymentTerm}
              />

              <hr />

              <H4>Company Information</H4>

              <BillingInformationForm
                formValidation={formValidation}
                onSubmit={billingFormSubmit}
                showTaxIds={true}
              />

              {(state.billingCountry === "" ||
                TAX_COUNTRY_OPTIONS[state.billingCountry]) && (
                <>
                  <hr />

                  <H4>Payment Information</H4>

                  {paymentErrorMessage && (
                    <p className="error">{paymentErrorMessage}</p>
                  )}

                  <form>
                    <PaymentElement options={{ business: { name: "Internal.io" } }} />
                  </form>
                </>
              )}
            </PaymentDetails>

            <OrderSummary
              loadingSubscription={loadingSubscription}
              submitDisabled={
                submitDisabled || !TAX_COUNTRY_OPTIONS[state.billingCountry]
              }
              currentPlanSlug={currentPlanSlug}
              newPlanSlug={newPlanSlug}
              paymentTerm={paymentTerm}
              priceData={currentPricesData}
              subscription={subscription}
              total={subscription?.latest_invoice.total}
              subscriptionPeriodEnd={subscription?.subscriptionPeriodEnd}
              proration={false}
              handleOrderSubmit={handleOrderSubmit}
            />
          </div>
        </PlanForm>
      </PlanFormContainer>
    </BillingContext.Provider>
  );
};

export default Checkout;
