import React from "react";

import Message from "../../../../common/Message";
import { ModalNew } from "../../../../common/Modal";

import useDeleteQueue from "./useDeleteQueue";

interface Props {
  queueId: string;
  onCancel?: () => void;
  onComplete?: () => void;
  onSuccess?: () => void;
}

export default function DeleteQueueModal({
  queueId,
  onCancel = () => null,
  onComplete = () => null,
  onSuccess = () => null
}: Props) {
  const [deleteQueue, { loading }] = useDeleteQueue({
    onCompleted: onComplete,
    onError: onComplete
  });

  return (
    <ModalNew
      title="Are you sure?"
      confirmLoading={loading}
      okText="Delete"
      onCancel={onCancel}
      onOk={async () => {
        const result = await deleteQueue({
          refetchQueries: ["AllQueues"],
          variables: { id: queueId }
        });
        if (result.data?.deleteQueue.ok) {
          Message.success("The queue has been deleted.");
          onSuccess();
        } else {
          Message.error("Something went wrong. Please try again.");
        }
      }}
      visible
    >
      This queue and all of its tasks will be permanently deleted.
    </ModalNew>
  );
}
