import React from "react";

import { Input, Select as AntSelect } from "antd";
import momentTz from "moment-timezone";
import styled from "styled-components";

import { AttributeColumn } from "../..";
import {
  DATE_DISPLAY_FORMAT,
  DATE_TIME_DISPLAY_FORMAT,
  TIMESTAMP_DISPLAY_FORMAT,
  TIME_DISPLAY_FORMAT
} from "../../../../../../../../constants";
import { AttributeNode } from "../../../../../../../../types";
import { Icon } from "../../../../../../../common/Icons/MaterialIcons";
import { ConfigSection } from "../../../ConfigPanel";
import { Select } from "../../../ConfigPanel/styledComponents";

const { Option } = AntSelect;
const Preview = styled.div`
  color: white;
`;

const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${props => props.theme.primaryAccent};
  gap: 3px;
`;

const LinkIcon = styled(Icon)`
  font-size: ${props => props.theme.defaultFontSize};
`;

const SpacerMD = styled.div`
  height: ${props => props.theme.spacermd};
`;

const SpacerXS = styled.div`
  height: ${props => props.theme.spacerxs};
`;

interface DateFormSelectProps {
  attribute: AttributeNode;
  configuration: AttributeColumn;
  onChange: (value: string) => void;
}

const CUSTOM_OPTION = "Custom";
const EXAMPLE_TIMESTAMP_DISPLAY_FORMAT = "MMM Do YYYY hh:mm a";

const OPTIONS = [
  TIME_DISPLAY_FORMAT,
  "MM-DD-YYYY",
  "MM/DD/YY",
  "DD/MM/YY",
  DATE_DISPLAY_FORMAT,
  "MM/DD/YY hh:mm A",
  "MM/DD/YY hh:mm A Z",
  "DD-MM-YYYY hh:mm:ss",
  DATE_TIME_DISPLAY_FORMAT,
  TIMESTAMP_DISPLAY_FORMAT
];

function _getCustomFormat(value?: string) {
  return value !== undefined && !OPTIONS.includes(value) ? value : "";
}

export default function DateFormatSelection({
  attribute,
  configuration,
  onChange
}: DateFormSelectProps) {
  const [now, _] = React.useState(momentTz());
  const [format, setFormat] = React.useState<string>(
    _getCustomFormat(configuration.date_template)
  );
  const [isCustom, setIsCustom] = React.useState<boolean>(
    !!_getCustomFormat(configuration.date_template)
  );
  return (
    <ConfigSection>
      <Select
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={isCustom ? CUSTOM_OPTION : configuration.date_template}
        placeholder="Select a template"
        onChange={value => {
          const isCustom = !OPTIONS.includes(value as string);
          setIsCustom(isCustom);
          onChange(value as string);
        }}
      >
        {[...OPTIONS, CUSTOM_OPTION].map((o: string) => (
          <Option
            key={attribute.sourceName + "date" + o}
            value={o !== CUSTOM_OPTION ? o : ""}
          >
            {o === CUSTOM_OPTION ? o : now.format(o)}
          </Option>
        ))}
      </Select>
      {isCustom && (
        <>
          <SpacerMD />
          <Input
            placeholder={EXAMPLE_TIMESTAMP_DISPLAY_FORMAT}
            value={format}
            autoFocus
            onChange={event => setFormat(event.target.value)}
            onBlur={() => onChange(format || EXAMPLE_TIMESTAMP_DISPLAY_FORMAT)}
          />
          <SpacerMD />
          <ExternalLink target="_blank" href="https://www.internal.io/docs/timezones">
            Learn about custom formatting <LinkIcon type="open_in_new" />
          </ExternalLink>
          <SpacerMD />
          Preview
          <SpacerXS />
          <Preview>{now.format(format || EXAMPLE_TIMESTAMP_DISPLAY_FORMAT)}</Preview>
        </>
      )}
    </ConfigSection>
  );
}
