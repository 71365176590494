import { Binding, BindingShape } from "../../../../../types";
import { ComponentNode } from "../../RenderTreeContext";

import { SpaceImageComponent } from "./types";

export function getSchema(node: ComponentNode): Binding[] {
  const columns = (node.component as SpaceImageComponent).properties.columns || [];

  return [
    {
      name: "data",
      shape: BindingShape.OBJECT,
      attributes: columns.map(c => ({
        name: c.attribute,
        shape: BindingShape.SCALAR
      }))
    }
  ];
}
