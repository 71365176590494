import { TaskFilters } from "../../..";
import { TasksConnection } from "../queries/AllTasks";

export enum ReducerActions {
  CHANGE_TASK_QUERY = "CHANGE_TASK_QUERY",
  LOAD_TASK_QUERY = "LOAD_TASK_QUERY",
  POLL_ALL = "POLL_ALL",
  STOP_POLL = "STOP_POLL",
  RESTART_POLLER = "RESTART_POLLER",
  CREATE_TASK_CHECK = "CREATE_TASK_CHECK",
  CREATE_TASK_CHECK_COMPLETE = "CREATE_TASK_CHECK_COMPLETE",
  UPDATE_TASK_CHECK = "UPDATE_TASK_CHECK",
  UPDATE_TASK_CHECK_COMPLETE = "UPDATE_TASK_CHECK_COMPLETE",
  DELETE_TASK = "DELETE_TASK",
  TRANSITION_TASK_CHECK = "TRANSITION_TASK_CHECK",
  TRANSITION_TASK_CHECK_COMPLETE = "TRANSITION_TASK_CHECK_COMPLETE"
}

export interface ChangeTaskQueryAction {
  type: ReducerActions.CHANGE_TASK_QUERY;
  payload: {
    filters: Partial<TaskFilters>;
  };
}

export interface LoadTaskQueryAction {
  type: ReducerActions.LOAD_TASK_QUERY;
  payload: {
    connection: TasksConnection;
  };
}

export interface PollAllAction {
  type: ReducerActions.POLL_ALL;
  payload: {
    connection: TasksConnection;
    startCursor: string;
  };
}

export interface StopPollAction {
  type: ReducerActions.STOP_POLL;
}

export interface RestartPollerAction {
  type: ReducerActions.RESTART_POLLER;
}

export interface DeleteTaskAction {
  type: ReducerActions.DELETE_TASK;
  payload: {
    taskId: string;
  };
}

export interface CreateTaskCheckAction {
  type: ReducerActions.CREATE_TASK_CHECK;
  payload: {
    taskId: string;
  };
}

export interface CreateTaskCheckCompleteAction {
  type: ReducerActions.CREATE_TASK_CHECK_COMPLETE;
  payload: {
    connection: TasksConnection;
  };
}

export interface UpdateTaskCheckAction {
  type: ReducerActions.UPDATE_TASK_CHECK;
  payload: {
    taskId: string;
  };
}

export interface UpdateTaskCheckCompleteAction {
  type: ReducerActions.UPDATE_TASK_CHECK_COMPLETE;
  payload: {
    connection: TasksConnection;
  };
}

export interface TransitionTaskCheckAction {
  type: ReducerActions.TRANSITION_TASK_CHECK;
  payload: {
    taskId: string;
  };
}

export interface TransitionTaskCheckCompleteAction {
  type: ReducerActions.TRANSITION_TASK_CHECK_COMPLETE;
  payload: {
    connection: TasksConnection;
  };
}

export type ReducerAction =
  | ChangeTaskQueryAction
  | LoadTaskQueryAction
  | StopPollAction
  | PollAllAction
  | RestartPollerAction
  | CreateTaskCheckAction
  | CreateTaskCheckCompleteAction
  | UpdateTaskCheckAction
  | UpdateTaskCheckCompleteAction
  | TransitionTaskCheckAction
  | TransitionTaskCheckCompleteAction
  | DeleteTaskAction;
