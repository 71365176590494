import React from "react";

import { Button as AntdButton } from "antd";
import styled from "styled-components";

interface SetupFooterProps {
  hasScrolled: boolean;
  onClick: any;
  buttonText: string;
  loading?: boolean;
  error?: string;
}

interface StickyFooterProps {
  theme: any;
  show: boolean;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlainFooter = styled(Content)`
  margin-top: 36px;
`;

const StickyFooter = styled(Content)`
  position: fixed;
  bottom: 0;
  transform: translateY(150px);

  width: 100%;
  height: 150px;
  background-color: ${(props: StickyFooterProps) => props.theme.backgroundColor};
  box-shadow: 0 -1px 3px 0 rgba(200, 200, 200, 0.5);
  transition: transform 200ms ease-out;

  .error {
    margin-top: 6px;
    color: red;
  }

  ${(props: StickyFooterProps) =>
    props.show &&
    `
    transform: translateY(0);
  `}

  button {
    margin-top: 12px;
  }
`;

const Button = styled(AntdButton)`
  min-width: 176px;
`;

export const SetupFooter: React.FunctionComponent<SetupFooterProps> = props => {
  return (
    <React.Fragment>
      <PlainFooter>
        {props.children}
        <Button
          htmlType="submit"
          type="primary"
          onClick={props.onClick}
          loading={props.loading}
          data-test="plain-footer-primary-button"
        >
          {props.buttonText}
        </Button>
      </PlainFooter>

      <StickyFooter show={props.hasScrolled}>
        {props.children}
        <Button
          htmlType="submit"
          type="primary"
          onClick={props.onClick}
          loading={props.loading}
          data-test="sticky-footer-primary-button"
        >
          {props.buttonText}
        </Button>
        {props.error && props.error !== "" && <p className="error">{props.error}</p>}
      </StickyFooter>
    </React.Fragment>
  );
};
