import React from "react";

import { Button, Table, Select } from "antd";
import styled from "styled-components";

import { AttributeTypes } from "../../../../../../../constants";
import { humanize } from "../../../../../../util";
import { ParameterType } from "../../../common/useFuncParams";
import { parseText } from "../../util";
import { BulkImportModalStepProps } from "../BulkImportModal";
import { BulkImportStep } from "../reducer";

type Props = BulkImportModalStepProps;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${props => props.theme.spacermd};

  button {
    margin-left: ${props => props.theme.spacersm};
  }
`;

const StyledTable = styled(Table)`
  overflow: auto;

  th {
    height: ${props => props.theme.spacersm}
    padding: ${props => props.theme.spacersm}

    span.ant-table-header-column {
      width: 100%;

      div.ant-select {
        width: 100%;
        min-width: 150px;
      }
    }
  }
`;

const MapDataStep = ({ dispatch, state, function: func, inputParameters }: Props) => {
  const onNext = React.useCallback(() => {
    let parsedRows = state.fileRows.map(row => {
      return row.map((text, idx) => {
        if (!state.mappings[String(idx)]) return [null, false];
        const param = state.mappings[String(idx)];
        try {
          const trimmed = text.trim();
          if (trimmed === "") {
            return [trimmed, !param.required];
          }
          return parseText(trimmed, param.type || AttributeTypes.STRING);
        } catch {
          return [text, false];
        }
      });
    });

    parsedRows = parsedRows.map(rows => {
      while (rows.length < state.fileHeader.length) {
        rows = rows.concat([["(Missing Column)", false]]);
      }

      return rows;
    });

    dispatch({
      type: "SET_PARSED_ROWS",
      payload: { data: parsedRows }
    });
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { step: BulkImportStep.Review }
    });
  }, [dispatch, state.fileRows, state.mappings, state.fileHeader.length]);

  const onPrev = React.useCallback(() => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { step: BulkImportStep.SelectFile }
    });
  }, [dispatch]);

  const fileRowsByIdx = React.useMemo(() => {
    return state.fileRows.map((row, rowIdx) =>
      Object.fromEntries(
        row.map((text, idx) => [String(idx), text]).concat([["_key", String(rowIdx)]])
      )
    );
  }, [state.fileRows]);

  const mapIdxToParameter = React.useCallback(
    (idx: number, name: string) => {
      const fp = func?.functionParameters?.edges
        ?.map(e => e.node)
        ?.find(fp => fp.name === name);
      const ip = inputParameters.find(ip => ip.name === name);
      if (!fp || !ip) return;
      const next = Object.fromEntries(
        Object.entries(state.mappings)
          .filter(([_, prev]) => prev.name !== name)
          .concat([
            [
              String(idx),
              {
                name: name,
                type: fp.type,
                required: ip.required || fp.required,
                ip: ip
              }
            ]
          ])
      );
      dispatch({ type: "SET_MAPPINGS", payload: { mappings: next } });
    },
    [func, inputParameters, state.mappings, dispatch]
  );

  const mappableInputParameters = React.useMemo(() => {
    return inputParameters.filter(ip => ip.type === ParameterType.FILE);
  }, [inputParameters]);

  const columns = React.useMemo(() => {
    return state.fileHeader.map((col, idx) => ({
      title: () => (
        <Select
          value={state.mappings[String(idx)]?.name}
          onSelect={(name: string) => mapIdxToParameter(idx, name)}
          placeholder="Select a parameter"
        >
          {mappableInputParameters.map(ip => (
            <Select.Option key={ip.name}>{humanize(ip.name)}</Select.Option>
          ))}
        </Select>
      ),
      children: [
        {
          title: col,
          dataIndex: String(idx),
          key: col + idx, // add idx in case there are duplicate column names
          render: (text?: string) => {
            if (text === undefined) {
              return "(Missing Column)";
            }

            return text || "(Empty String)";
          }
        }
      ]
    }));
  }, [state.fileHeader, state.mappings, mapIdxToParameter, mappableInputParameters]);
  return (
    <StyledRoot>
      <div>
        <h3>Map Data</h3>
        <p>
          For each column you want to import, specify a mapping to the fields in your
          system. Unmapped columns will not be imported and multiple columns cannot be
          mapped to the same field.
        </p>
        <StyledTable columns={columns} dataSource={fileRowsByIdx} rowKey="_key" />
      </div>
      <StyledFooter>
        <Button key="back" onClick={onPrev}>
          Back
        </Button>
        <Button key="submit" type="primary" onClick={onNext}>
          Review
        </Button>
      </StyledFooter>
    </StyledRoot>
  );
};

export default MapDataStep;
