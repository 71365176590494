import React from "react";

import { Collapse as AntCollapse } from "antd";
import styled from "styled-components";

import { SpaceComponentPackage, SpaceComponentTags } from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  useLayoutContext,
  useScrollContext
} from "../../../layout/LayoutContext/LayoutContext";
import { useTransformationActionContext } from "../../../layout/TransformationContext/TransformationContext";
import {
  ElementLayout,
  PositionOption,
  LayoutUnit,
  parseCssUnit,
  addPoints
} from "../../../layout/util";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";
import SpaceComponentIcon from "../../AddComponentPopover/SpaceComponentIcon";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MenuProps } from "../Sider";

const Collapse = styled(AntCollapse)`
  border: none;

  .ant-collapse-item,
  .ant-collapse-content {
    border-color: ${props => props.theme.menuBorderColor};
  }

  > .ant-collapse-item > .ant-collapse-header {
    line-height: 12px;
  }
`;

const Panel = styled(AntCollapse.Panel)`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const ComponentGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: minmax(min-content, max-content);
  column-gap: 1px;
  row-gap: 1px;
  background: ${props => props.theme.tableBorderColor};
`;

const DroppableComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: solid 1px ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColorMid};
  &:hover {
    background: rgba(255, 255, 255, 0.14);
  }
`;

const StyledSpaceComponentIcon = styled(SpaceComponentIcon)`
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.spacermd};

  &:hover {
    color: ${props => props.theme.textColor};
  }
`;

const EXCLUDED_COMPONENT_TYPES = ["DYNAMIC_FUNCTION", "HEADER", "VIEWLESS_IMAGE"];

export default function ComponentsFlyout(props: MenuProps) {
  const { onClose } = props;
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const { startMove, select, clearSelection } = useTransformationActionContext();
  const { rootDOMRect } = useLayoutContext();
  const { canvasScrollTop } = useScrollContext();
  const configContext = useSpaceConfigContext();

  // Create a div on mount to hold dragImages for snapshotting
  // Browsers require that the dom node to be used as the image
  // is actually attached to document
  const dragImageContainer = React.useRef<HTMLDivElement>(
    document.createElement("div")
  );
  React.useEffect(() => {
    const div = document.createElement("div");
    div.id = "components-flyout-drag-image-container";
    div.style.position = "fixed";
    div.style.bottom = "-1000px";
    document.body.appendChild(div);
    dragImageContainer.current = div;
    return () => {
      document.body.removeChild(dragImageContainer.current);
    };
  }, []);

  const droppableComponents = React.useMemo(
    () =>
      getSpaceComponentPackages()
        .filter(p => !p.isHeadless && !EXCLUDED_COMPONENT_TYPES.includes(p.type))
        .sort((p1, p2) => {
          if (p1.displayName < p2.displayName) {
            return -1;
          } else if (p1.displayName > p2.displayName) {
            return 1;
          }
          return 0;
        }),
    [getSpaceComponentPackages]
  );

  const baseComponents = React.useMemo(() => {
    return droppableComponents.filter(p => p.tags?.includes(SpaceComponentTags.Base));
  }, [droppableComponents]);

  const contentComponents = React.useMemo(() => {
    return droppableComponents.filter(p =>
      p.tags?.includes(SpaceComponentTags.Content)
    );
  }, [droppableComponents]);

  const inputComponents = React.useMemo(() => {
    return droppableComponents.filter(p => p.tags?.includes(SpaceComponentTags.Input));
  }, [droppableComponents]);

  const getWidth = React.useCallback(
    (defaultElementLayout: ElementLayout | undefined) =>
      defaultElementLayout?.width === LayoutUnit.AUTO
        ? LayoutUnit.AUTO
        : defaultElementLayout?.width || DEFAULT_SIZES.STANDARD.width,
    []
  );

  const getHeight = React.useCallback(
    (defaultElementLayout: ElementLayout | undefined) =>
      defaultElementLayout?.height === LayoutUnit.AUTO
        ? LayoutUnit.AUTO
        : defaultElementLayout?.height || DEFAULT_SIZES.STANDARD.height,
    []
  );

  const createLayout = React.useCallback(
    (
      defaultElementLayout: ElementLayout | undefined,
      left: string,
      top: string,
      width: string,
      height: string
    ) => {
      return new ElementLayout({
        ...defaultElementLayout,
        position: PositionOption.ABSOLUTE,
        left,
        top,
        width,
        height,
        minWidth:
          typeof defaultElementLayout?.minWidth === "number"
            ? `${defaultElementLayout?.minWidth}px`
            : typeof defaultElementLayout?.minWidth === "string"
            ? defaultElementLayout.minWidth
            : undefined,
        minHeight:
          typeof defaultElementLayout?.minHeight === "number"
            ? `${defaultElementLayout?.minHeight}px`
            : typeof defaultElementLayout?.minHeight === "string"
            ? defaultElementLayout.minHeight
            : undefined
      });
    },
    []
  );

  const getDroppableComponent = React.useCallback(
    (p: SpaceComponentPackage) => {
      const { defaultElementLayout, fallbackWidth, fallbackHeight } = p;
      const width = getWidth(defaultElementLayout);
      const height = getHeight(defaultElementLayout);

      return (
        <DroppableComponent
          key={p.type}
          draggable
          onClick={() => {
            clearSelection();
            // Center the new component in the canvas
            const leftNum =
              50 - (width !== LayoutUnit.AUTO ? parseFloat(width) : 0) / 2;
            const left = `${leftNum}%`;
            const topNum =
              rootDOMRect.height / 2 -
              (height !== LayoutUnit.AUTO ? parseFloat(height) : 0) / 2;

            const top = `${topNum}px`;
            const layout = createLayout(defaultElementLayout, left, top, width, height);
            configContext.dispatch({
              type: "INSERT_FLEXIBLE_LAYOUT_COMPONENT",
              payload: {
                componentType: p.type,
                layout
              }
            });
            const predictedSlug = configContext.predictSlug(p.type);
            // Wait until component exists and has been rendered before selecting.
            setImmediate(() => select(predictedSlug));
            onClose();
          }}
          onDragStart={e => {
            clearSelection();
            const pagePt = new DOMPoint(e.pageX, e.pageY);
            const canvasPt = addPoints(
              pagePt,
              new DOMPoint(rootDOMRect.x * -1, rootDOMRect.y * -1), // canvas offset
              new DOMPoint(0, canvasScrollTop)
            );
            const left = `${(canvasPt.x / rootDOMRect.width) * 100}%`;
            const top = `${canvasPt.y}px`;
            const predictedSlug = configContext.predictSlug(p.type);

            const layout = createLayout(defaultElementLayout, left, top, width, height);
            configContext.dispatch({
              type: "INSERT_FLEXIBLE_LAYOUT_COMPONENT",
              payload: {
                componentType: p.type,
                layout
              }
            });
            startMove(
              predictedSlug,
              pagePt,
              layout,
              new DOMRect(
                canvasPt.x,
                canvasPt.y,
                width === LayoutUnit.AUTO
                  ? fallbackWidth
                    ? fallbackWidth
                    : 100
                  : parseCssUnit(width) === LayoutUnit.PERCENTAGE
                  ? (parseFloat(width) * rootDOMRect.width) / 100
                  : parseFloat(width),
                height === LayoutUnit.AUTO
                  ? fallbackHeight
                    ? fallbackHeight
                    : 100
                  : parseCssUnit(height) === LayoutUnit.PERCENTAGE
                  ? (parseFloat(height) * rootDOMRect.height) / 100
                  : parseFloat(height)
              )
            );
            select(predictedSlug);
            onClose();
          }}
        >
          <StyledSpaceComponentIcon type={p.type} />
        </DroppableComponent>
      );
    },
    [
      configContext,
      rootDOMRect,
      canvasScrollTop,
      clearSelection,
      select,
      createLayout,
      getHeight,
      getWidth,
      startMove,
      onClose
    ]
  );

  return (
    <Collapse defaultActiveKey={["base", "content", "input"]}>
      <Panel key="base" header="Components">
        <ComponentGrid>
          {baseComponents.map(p => getDroppableComponent(p))}
        </ComponentGrid>
      </Panel>
      <Panel key="content" header="Content">
        <ComponentGrid>
          {contentComponents.map(p => getDroppableComponent(p))}
        </ComponentGrid>
      </Panel>
      <Panel key="input" header="Inputs">
        <ComponentGrid>
          {inputComponents.map(p => getDroppableComponent(p))}
        </ComponentGrid>
      </Panel>
    </Collapse>
  );
}
