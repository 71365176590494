import { useState, useEffect } from "react";

export default function useWindowVisible() {
  const [visible, setVisible] = useState(true);

  const onVisibilityChange = () => {
    setVisible(document.visibilityState === "visible");
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  return visible;
}
