import { ComponentConfigState } from "../../../../../types";
import { TreeNode } from "../reducer";

function collectDescendantSlugs(node: TreeNode, slugs: string[] = []): string[] {
  if (node.slug === null) {
    throw new Error("Expected TreeNode slug to be defined for descendant TreeNodes.");
  }
  slugs.push(node.slug);
  node.treeNodes.forEach(ltn => {
    collectDescendantSlugs(ltn, slugs);
  });
  return slugs;
}

export default function removeComponentFromTree(
  treeNode: TreeNode,
  components: Record<string, ComponentConfigState>,
  slug: string,
  depth = 0
): { treeNode: TreeNode; removed: boolean; removedSlugs: string[] } {
  const nodeToRemove = treeNode.treeNodes.find(ltn => ltn.slug === slug);
  if (!!nodeToRemove) {
    const removedSlugs = collectDescendantSlugs(nodeToRemove);
    treeNode.treeNodes = treeNode.treeNodes.filter(ltn => ltn.slug !== slug);
    return { treeNode, removed: true, removedSlugs };
  }

  for (let i = 0; i < treeNode.treeNodes.length; i++) {
    const result = removeComponentFromTree(
      treeNode.treeNodes[i],
      components,
      slug,
      depth + 1
    );

    if (result.removed) {
      treeNode.treeNodes[i] = result.treeNode;

      return {
        treeNode,
        removed: true,
        removedSlugs: result.removedSlugs
      };
    }
  }
  return { treeNode, removed: false, removedSlugs: [] };
}
