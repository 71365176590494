import React from "react";

import { Button, Skeleton } from "antd";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../../layouts/Header/Header";
import Nav from "../../../layouts/Nav";
import CardList from "../../common/CardList";
import useAuthUser from "../../common/hooks/useAuthUser";
import usePaths from "../../common/hooks/usePaths";
import useSearchParamsUpdater from "../../common/hooks/useSearchParamsUpdater";
import { Cols, LeftRail, Main, RightRail } from "../../common/layout/styledComponents";
import { Header, Hr, MainTitle, NewButton } from "../../common/StyledComponents";
import useSpacesManager, {
  SpaceListContext
} from "../hooks/useSpacesManager/useSpacesManager";

import EmptyState from "./EmptyState";
import OrgDetail from "./OrgDetail/OrgDetail";
import SpaceDetail from "./SpaceDetail/SpaceDetail";
import SpaceFilters from "./SpaceFilters";

const StyledSpaceFilters = styled(SpaceFilters)`
  flex: 0 0 auto;
`;

const StyledSpaceCardList = styled(CardList)`
  justify-self: stretch;
`;

const MoreButton = styled(Button)`
  margin-top: ${props => props.theme.spacermd};
`;

export default function Home() {
  const { isAdmin, isSpaceAdmin } = useAuthUser();
  const [searchParams] = useSearchParams();
  const updateSearchParams = useSearchParamsUpdater();
  const navigate = useNavigate();
  const { getNewSpace, getSpace } = usePaths();
  const [searchActive, setSearchActive] = React.useState(
    !!searchParams.get("nameContains")
  );
  const spacesResult = useSpacesManager({
    forceSearch: searchActive
  });

  const hasFavoritesLoading = typeof spacesResult.hasFavorites !== "boolean";
  const {
    loading,
    hasLoaded,
    spaceNodes,
    pageInfo,
    orderable,
    fetchMore,
    favorite,
    unfavorite,
    orderFavorite
  } = spacesResult;

  const selectedSpaceSlug = searchParams.get("selectedSpace");
  const selectedSpace = spaceNodes.find(s => s.slug === selectedSpaceSlug);
  const showLoader = hasFavoritesLoading || !hasLoaded;
  function selectSpace(slug: string | null) {
    updateSearchParams({ selectedSpace: slug ? slug : undefined });
  }

  return (
    <SpaceListContext.Provider value={spacesResult}>
      <PageHeader key="space-home" />
      <Cols>
        {(isAdmin || window.FEATURE_FLAGS.includes("QUEUES")) && (
          <LeftRail open>
            <Nav />
          </LeftRail>
        )}
        <Main>
          <Header>
            <MainTitle>Your spaces</MainTitle>
            {(isAdmin || isSpaceAdmin) && (
              <NewButton
                data-test="newSpaceButton"
                icon="plus"
                type="link"
                onClick={() => {
                  navigate(getNewSpace());
                }}
              >
                New space
              </NewButton>
            )}
          </Header>
          <Hr />
          <StyledSpaceFilters
            hasFavorites={spacesResult.hasFavorites}
            hasFeatured={spacesResult.hasFeatured}
            searchActive={searchActive}
            onDeactivateSearch={() => setSearchActive(false)}
            onActivateSearch={() => setSearchActive(true)}
          />
          {showLoader && <Skeleton active />}
          <StyledSpaceCardList
            items={
              hasLoaded
                ? spaceNodes.map(n => ({
                    ...n,
                    href: getSpace(n.slug),
                    description: n.description || ""
                  }))
                : []
            }
            emptyState={<EmptyState />}
            loading={!hasLoaded}
            moreButton={
              pageInfo?.hasNextPage ? (
                <MoreButton block loading={loading} onClick={() => fetchMore()}>
                  Load more
                </MoreButton>
              ) : null
            }
            selectedSlug={selectedSpaceSlug}
            onSelect={slug => {
              selectSpace(slug);
            }}
            onOrder={orderable ? orderFavorite : undefined}
          />
        </Main>
        <RightRail isStatic isActive={!!selectedSpace} onBack={() => selectSpace(null)}>
          {selectedSpace ? (
            <SpaceDetail
              space={selectedSpace}
              onFavorite={(spaceId: string, refetch?: boolean) =>
                favorite(spaceId, refetch)
              }
              onUnfavorite={(spaceId: string) => unfavorite(spaceId)}
            />
          ) : (
            <OrgDetail />
          )}
        </RightRail>
      </Cols>
    </SpaceListContext.Provider>
  );
}
