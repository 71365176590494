import React from "react";

import Switch, { SwitchProps } from "antd/lib/switch";

import { Dash, IndeterminateState } from "./styledComponents";

/**
 * An IndeterminateSwitch adds an "indeterminate" state to a Switch.
 */
export function IndeterminateSwitch(
  props: SwitchProps & { indeterminate?: boolean; children?: React.ReactNode }
) {
  const { indeterminate, children, ...switchProps } = props;

  if (indeterminate) {
    return (
      <IndeterminateState
        className={props.className}
        style={props.style}
        onClick={event => props.onClick?.(true, event.nativeEvent)}
      >
        <Dash />
      </IndeterminateState>
    );
  }

  return <Switch {...switchProps}>{children}</Switch>;
}
