import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { ConditionalExpressionType } from "../Conditionals";
import validateConditionalExpression from "../Conditionals/validateConditional";

export default function selectErrors(
  component: SpaceComponentObject
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { visibility_rules: rules } = component.properties;
  if (!rules) return [];

  const addError = (message: string, index: number) => {
    errors.push({ field: "VISIBILITY_RULES", message, index });
  };
  (rules as ConditionalExpressionType[]).forEach((rule, index) => {
    const conditionalErrors = validateConditionalExpression(rule);
    conditionalErrors.forEach(error => {
      addError(error, index);
    });
  });

  return errors;
}
