import React from "react";

import { Icon } from "antd";

import { Warning } from "./styledComponents";

interface Props {
  cta: string;
}

const InsecureWarning = ({ cta }: Props) => {
  return (
    <Warning>
      <Icon type="exclamation-circle" theme="filled" />
      <p>
        Connections will not be private and your data will be visible to anyone in
        transit. {cta} to ensure that your data is encrypted between your host and
        Internal.
      </p>
    </Warning>
  );
};

export default InsecureWarning;
