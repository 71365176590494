import styled from "styled-components";

export const Root = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${props => props.theme.spacermd};
  padding-bottom: 0;
  overflow: hidden;
  padding-left: 0;
`;

export const Title = styled.h1`
  flex: 0 1 auto;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
Title.displayName = "Title";

export const LeftHeader = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0; // Flex child min-width defaults to auto which is too wide with a nowrap child
  align-items: center;

  & > *:not(:first-child) {
    margin-left: ${props => props.theme.spacermd};
  }

  & > button {
    height: 100%;
  }

  & > a,
  button,
  button:focus,
  button:active {
    font-size: 1.5em;
    color: ${props => props.theme.textColorMid};

    span.ant-badge {
      font-size: unset;
    }
  }

  & > a:hover,
  button:hover {
    color: ${props => props.theme.textColor};
  }
`;
LeftHeader.displayName = "LeftHeader";

export const Filters = styled.div`
  display: flex;
  flex: 1 0.5 auto;
  flex-wrap: wrap;

  & > * {
    margin: ${props => props.theme.spacerxs};
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex: 1 0.5 auto;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: ${props => props.theme.spacerxs};
  }
`;
Actions.displayName = "Actions";
