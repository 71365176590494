import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import QRCode from "qrcode.react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../../constants";
import { SpacingUnit } from "../../../../cssConstants";
import { partialAuthClient } from "../../../../graphql";
import { AuthStatus } from "../../../../types";
import {
  withAuthStatus,
  AuthStatusProps
} from "../../../auth/WithAuthStatus/withAuthStatus";
import Button from "../../../common/Button";
import TitleContainer, { ContainerSize } from "../../../common/TitleContainer";

const CodeContainer = styled.div`
  display: flex;
  margin-top: ${SpacingUnit.lg};
  margin-bottom: ${SpacingUnit.xl};
  justify-content: center;
  color: ${props => props.theme.textColorMid};

  .code__text {
    width: 144px;
    margin-left: ${SpacingUnit.xl};
    text-align: left;
    font-size: 13px;
  }
`;

const SetupContainer = styled.div`
  margin-top: ${SpacingUnit.sm};
  text-align: center;

  p {
    color: ${props => props.theme.textColorMid};
  }
`;

export const SETUP_DEVICE_QUERY = gql`
  query setUpProfileQuery {
    setUpDevice {
      url
      key
    }
  }
`;

export const formatKey = (key: string) => {
  return key.match(/.{1,4}/g)!.join(" ");
};

export const SetUpTwoFactor = (props: AuthStatusProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const options =
    props.status !== AuthStatus.Authenticated
      ? { client: partialAuthClient }
      : undefined;
  const { data, error } = useQuery(SETUP_DEVICE_QUERY, options);

  if ((!data || !data.setUpDevice) && !error) {
    return <div></div>;
  }

  if (error) {
    return (
      <React.Fragment>
        <TitleContainer title="Set up Two-Step Verification" size={ContainerSize.Large}>
          <SetupContainer>
            <p>
              Sorry, you cannot set up two-factor at this time. You may already have a
              device configured for two-step log in.
            </p>
            <Link to="/profile">Click to return to Profile</Link>
          </SetupContainer>
        </TitleContainer>
      </React.Fragment>
    );
  }

  const configUrl = data.setUpDevice.url;

  return (
    <React.Fragment>
      <TitleContainer title="Set up Two-Step Verification" size={ContainerSize.Large}>
        <SetupContainer>
          <p>
            Add an extra layer of security each time you sign in to your Internal
            account by requiring your password as well as a verification code. Using
            your authenticator app such as Google Authenticator, Duo Mobile, or
            Microsoft Authenticator, scan the image below.
          </p>
          <CodeContainer>
            <QRCode renderAs="svg" size={128} value={configUrl} />
            <div className="code__text">
              <p>
                {`If you're unable to scan the image, enter the following information in your app:  ${formatKey(
                  data.setUpDevice.key
                )}`}
              </p>
            </div>
          </CodeContainer>
          <Button
            size="medium"
            onClick={() =>
              navigate({
                pathname: Routes.CONFIRM_TWO_FACTOR,
                search: location.search
              })
            }
            type="primary"
          >
            Next
          </Button>
        </SetupContainer>
      </TitleContainer>
    </React.Fragment>
  );
};

export default withAuthStatus(SetUpTwoFactor);
