import { Upload as AntUpload } from "antd";
import styled from "styled-components";

export const Upload = styled(AntUpload)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacersm};

  // prevent scrollbar flash
  .ant-upload-list {
    overflow: hidden;

    // antd override to prevent flicker during file change
    // if layout shifts from vertical to horizontal
    .ant-upload-animate-enter,
    .ant-upload-animate-leave {
      animation-duration: 1ms;
    }
  }

  .ant-upload-list-item {
    margin-top: 0;
    .anticon-close {
      top: 4px;
    }
  }

  .ant-upload-list-item-name {
    max-width: 170px; // inherits ellipsis from antd
  }

  .ant-upload-list-item-info {
    padding-right: ${props => props.theme.spacerlg};

    > span {
      display: flex;
    }

    .anticon-paper-clip {
      top: 4px;
    }
  }
`;
