import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

// https://github.com/ant-design/ant-design/issues/14199
const ErrorIconWrapper = styled.div`
  position: relative;
  width: ${props => props.theme.largeFontSize};
  height: ${props => props.theme.largeFontSize};

  & > i.anticon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: ${props => props.theme.largeFontSize};
    color: ${props => props.theme.errorColor};

    &:hover {
      color: ${props => props.theme.errorColor};
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      display: block;
      top: 0;
      left: 0;
      width: ${props => props.theme.largeFontSize};
      height: ${props => props.theme.largeFontSize};
      border-radius: 50%;
      background-color: white;
    }
  }
`;

interface Props {
  className?: string;
  fillStyle?: "filled" | "outlined";
  title?: string;
}

export function ErrorIcon({ className = "", fillStyle = "filled", title = "" }: Props) {
  return (
    <ErrorIconWrapper className={className} title={title}>
      <Icon type="exclamation-circle" theme={fillStyle} />
    </ErrorIconWrapper>
  );
}

export const AbsoluteErrorIcon = styled(ErrorIcon)`
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
`;

const IconWrapper = styled.div`
  font-size: ${props => props.theme.largeFontSize};
`;

export const HelpIcon = ({
  className = "",
  fillStyle = "filled",
  title = ""
}: Props) => (
  <IconWrapper className={className} title={title}>
    <Icon type="question-circle" theme={fillStyle} />
  </IconWrapper>
);

export const InfoIcon = ({
  className = "",
  fillStyle = "filled",
  title = ""
}: Props) => (
  <IconWrapper className={className} title={title}>
    <Icon type="info-circle" theme={fillStyle} />
  </IconWrapper>
);

const WarningIconWrapper = styled(IconWrapper)`
  color: ${props => props.theme.warningColor};
`;

export const WarningIcon = ({
  className = "",
  fillStyle = "filled",
  title = ""
}: Props) => (
  <WarningIconWrapper className={className} title={title}>
    <Icon type="warning" theme={fillStyle} />
  </WarningIconWrapper>
);

export const AbsoluteWarningIcon = styled(WarningIcon)`
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
`;
