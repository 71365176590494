import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { RelayNode, Connection } from "../../../../types";

export interface AutomationNode extends RelayNode {
  __typename?: "AutomationNode";
  name: string;
  description: string;
  slug: string;
  icon: string;
  color: string;
}

export interface Data {
  allAutomations: Connection<AutomationNode>;
}

export const ALL_AUTOMATIONS_QUERY = gql`
  query AllAutomations {
    allAutomations {
      edges {
        node {
          id
          name
          description
          icon
          color
          slug
        }
      }
    }
  }
`;

export const useAllAutomations = (options?: QueryHookOptions<Data>) => {
  return useQuery<Data>(ALL_AUTOMATIONS_QUERY, options);
};
