import { useCallback } from "react";

import { useMutation } from "@apollo/react-hooks";

import { publicClient } from "../../../graphql";
import { AUTH_STATUS } from "../../common/hooks/useAuthStatus";

import { LOGIN_MUTATION, LoginVars, LoginData, LoginUser } from "./queries";

export default function useLogin(
  onCompleted: (user?: LoginUser) => void,
  onError: () => void
) {
  const [submit, { loading }] = useMutation<LoginData, LoginVars>(LOGIN_MUTATION, {
    awaitRefetchQueries: true,
    client: publicClient,
    onCompleted: data => onCompleted(data?.authLogin.user),
    onError,
    refetchQueries: [{ query: AUTH_STATUS }]
  });

  const login = useCallback(
    (email: string, password: string) => {
      submit({ variables: { email, password } });
    },
    [submit]
  );

  return { loading, login };
}
