import { Modal as AntModal, Table as AntTable, Button } from "antd";
import styled from "styled-components";

export const Modal = styled(AntModal)`
  .ant-modal-header {
    padding-left: ${props => props.theme.spacerxl};
    padding-right: ${props => props.theme.spacerxl};
  }
  .ant-modal-body {
    padding: 0;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Error = styled.p`
  margin-left: ${props => props.theme.spacerxl};
  color: ${props => props.theme.errorColor};
`;

export const RemoveEnvironmentButton = styled(Button)`
  &.ant-btn-link {
    color: ${props => props.theme.textColorMid};

    &:hover {
      background-color: ${props => props.theme.rowHoverColor};
    }
  }
`;

export const AddNewButton = styled(Button)`
  margin-left: 16px;
  margin-bottom: ${props => props.theme.spacermd};
`;

export const Table = styled(AntTable)`
  .ant-table-body {
    padding: 0 ${props => props.theme.spacermd} 0 ${props => props.theme.spacermd};
  }
  .ant-table-body .ant-table-thead th {
    text-align: center;
    background-color: white;
    border: 0px;
  }

  .ant-table-body .ant-table-row td {
    text-align: center;
    border-bottom: 0;
  }

  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: unset;
  }

  .ant-table-body .ant-table-row td:first-child {
    text-align: left;
  }

  .ant-table-body .ant-table-row td:last-child {
    text-align: center;
  }

  .ant-table-thead > tr:first-child > th:first-child {
    text-align: left;
  }

  .ant-table-thead > tr:last-child > th:last-child {
    text-align: center;
  }
`;
