import React from "react";

import { SpaceComponentObject } from "../../../../../../types";
import SpaceApi, { SpaceEventMessage } from "../../../../SpaceApi";

export default function useRefreshComponent(
  component: SpaceComponentObject,
  spaceApi: SpaceApi,
  refresh: () => void
) {
  const onRefresh = React.useCallback(
    (message: SpaceEventMessage) => {
      const { components } = message;
      if (component?.slug && components?.find(slug => slug === component.slug)) {
        refresh();
      }
    },
    [refresh, component]
  );
  React.useEffect(() => {
    if (spaceApi) spaceApi.on("refresh_component", onRefresh);
    return () => {
      spaceApi?.off("refresh_component", onRefresh);
    };
  }, [spaceApi, onRefresh]);
}
