import {
  Button as AntButton,
  Checkbox as AntCheckbox,
  Input as AntInput,
  Tabs as AntTabs
} from "antd";
import styled from "styled-components";

import CommonIconButton from "../../IconButton";

export const FieldColumnHeader = styled.div<{ center?: boolean }>`
  line-height: 30px;
  font-weight: ${props => props.theme.boldFontWeight};
  text-transform: uppercase;
  font-size: ${props => props.theme.smallFontSize};
  color: ${props => props.theme.columnHeaderColor};
  ${props => (props.center ? "justify-self: center;" : "")}
`;

export const LinkButton = styled(AntButton)`
  padding: 0;
`;

export const IconButton = styled(CommonIconButton)`
  margin-top: ${props => props.theme.spacersm}
  padding: 0;
`;

export const Checkbox = styled(AntCheckbox)`
  justify-self: center;
`;

export const Input = styled(AntInput)``;

export const Tabs = styled(AntTabs)`
  height: 100%;
  width: 100%;

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-content {
    padding: ${props => props.theme.spacermd};
    overflow: auto;
  }

  .ant-tabs-content {
    height: calc(100% - 44px); // hack: 44px is the height of .ant-tabs-bar
  }

  .ant-tabs-tabpane {
    height: 100%;
  }
`;
