import React, { KeyboardEvent } from "react";

import { InputActionMeta, OptionProps } from "react-select";
import Select, { components } from "react-windowed-select";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { colorTokens } from "../../../cssConstants";
import { EnvironmentNode } from "../../../types";
import {
  useAccessibleEnvironments,
  useEnvironmentsQuery
} from "../contexts/EnvironmentContext/EnvironmentContext";
import useDebouncedValue from "../hooks/useDebouncedValue";
import Message from "../Message";

interface EnvironmentSelectProps {
  value: EnvironmentNode;
  onChange: (e: EnvironmentNode) => void;
}

const StyledSelect = styled(Select)`
  min-width: 200px;
  max-width: 200px;
  .react-select__input {
    color: white;
    font-size: 12px;
    input {
      font-weight: 500;
    }
  }
`;

interface EnvironmentOption {
  label: string;
  value: string;
  type: "selected" | "option" | "load-indicator";
}

const LoadMoreOption = styled(components.Option)`
  text-align: center;
`;

function CustomOption(props: OptionProps<EnvironmentOption, false>) {
  switch (props.data.type) {
    case "selected":
      return <components.Option {...props} />;
    case "option":
      return <components.Option {...props} />;
    case "load-indicator":
      return <LoadMoreOption {...props} />;
  }
}

export default function EnvironmentSelect({ value, onChange }: EnvironmentSelectProps) {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const [showValue, setShowValue] = React.useState(true);
  const [searchText, setSearchText] = React.useState<string | undefined>();
  const debouncedSearchText = useDebouncedValue(searchText, 200);
  const debounceTextDifferent = searchText !== debouncedSearchText;
  const selectRef = React.useRef<HTMLElement>(null);
  const { data, loading, error, fetchMore } = useEnvironmentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 150,
      offset: 0,
      searchText: debouncedSearchText
    }
  });

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data!.allEnvironments.edges.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          allEnvironments: {
            pageInfo: fetchMoreResult.allEnvironments.pageInfo,
            edges: [
              ...prev.allEnvironments.edges,
              ...fetchMoreResult.allEnvironments.edges
            ],
            __typename: prev.allEnvironments.__typename
          }
        };
      }
    });
  };

  React.useEffect(() => {
    if (error) {
      Message.error("An error occurred while loading environments, please try again.");
    }
  }, [error]);

  const { accessibleEnvironments, loading: filterLoading } = useAccessibleEnvironments(
    data?.allEnvironments.edges.map(e => e.node)
  );
  const environments = React.useMemo(() => {
    if (filterLoading || debounceTextDifferent) return [];
    return accessibleEnvironments;
  }, [accessibleEnvironments, filterLoading, debounceTextDifferent]);

  const pageInfo = debounceTextDifferent ? undefined : data?.allEnvironments.pageInfo;

  const options = React.useMemo(() => {
    if (debounceTextDifferent) return [];

    let options = environments
      .filter(e => e.id !== value.id)
      .map(e => ({
        label: e.name,
        value: e.slug,
        type: "option"
      }));

    options = [
      {
        label: value.name,
        value: value.slug,
        type: "selected"
      },
      ...options
    ];

    if (pageInfo?.hasNextPage) {
      options = options.concat({
        label: "Load more",
        value: uuid(),
        type: "load-indicator"
      });
    }

    return options;
  }, [environments, pageInfo?.hasNextPage, value, debounceTextDifferent]);

  return (
    <div ref={rootRef}>
      <StyledSelect
        ref={selectRef}
        value={{ value: value.slug, label: value.name }}
        isClearable={false}
        isSearchable={true}
        filterOption={() => true}
        menuPortalTarget={document.getElementById("select-portal")}
        options={options}
        classNamePrefix="react-select"
        isLoading={loading || debounceTextDifferent}
        placeholder="Search environments"
        controlShouldRenderValue={showValue}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === "Escape") {
            selectRef.current?.blur();
          }
        }}
        onBlur={() => {
          setShowValue(true);
          setSearchText(undefined);
        }}
        onFocus={() => {
          setShowValue(false);
        }}
        components={{ Option: CustomOption }}
        styles={{
          control: (provided: Record<string, any>) => ({
            ...provided,
            height: "30px",
            minHeight: "30px",
            boxShadow: "none",
            minWidth: "100px",
            background: "transparent",
            borderColor: colorTokens.grey1400,
            "&:hover": {
              borderColor: colorTokens.purple700
            },
            maxHeight: "100%"
          }),
          valueContainer: (provided: Record<string, any>) => ({
            ...provided,
            position: "static",
            paddingLeft: "14px",
            userSelect: "none"
          }),
          singleValue: (provided: Record<string, any>) => ({
            ...provided,
            color: "white",
            fontSize: "12px",
            fontWeight: "500",
            maxWidth: "calc(100% - 40px)"
          }),
          dropdownIndicator: (provided: Record<string, any>) => ({
            ...provided,
            padding: "5px 16px",
            color: colorTokens.grey1400,
            zoom: 0.7,
            "&:hover": {
              color: colorTokens.purple400
            }
          }),
          indicatorSeparator: (provided: Record<string, any>) => ({
            ...provided,
            display: "none"
          }),
          input: (provided: Record<string, any>) => ({
            ...provided,
            margin: "unset"
          }),
          menu: (provided: Record<string, any>) => ({
            ...provided,
            marginTop: "9px"
          }),
          menuList: (provided: Record<string, any>) => ({
            ...provided,
            fontSize: "12px",
            fontWeight: 500,
            background: colorTokens.grey2000,
            color: "white"
          }),
          placeholder: (provided: Record<string, any>) => ({
            ...provided,
            fontSize: "12px",
            fontWeight: 500
          })
        }}
        theme={(provided: Record<string, any>) => ({
          ...provided,
          colors: {
            ...provided.colors,
            primary25: colorTokens.purpleTransparent400,
            primary50: colorTokens.purpleTransparent400,
            primary75: colorTokens.purpleTransparent400,
            primary: colorTokens.purple700,
            danger: colorTokens.red300
          }
        })}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          if (actionMeta.action === "input-change" && newValue !== searchText) {
            setSearchText(newValue !== "" ? newValue : undefined);
          }
        }}
        closeMenuOnSelect={false}
        onChange={(option: { value: string; type: string }) => {
          if (option.type === "load-indicator") {
            fetchMoreData();
            return;
          }

          if (option.type === "selected") {
            return;
          }

          const node = environments.find(e => e.slug === option.value);
          if (!node) {
            throw new Error("Expected environment to exist");
          }
          onChange(node);
        }}
      />
    </div>
  );
}
