import React from "react";

import { Skeleton } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import PageHeader from "../../../../layouts/Header/Header";
import Nav from "../../../../layouts/Nav";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import CardList, { Item } from "../../../common/CardList";
import useAuthUser from "../../../common/hooks/useAuthUser";
import usePaths from "../../../common/hooks/usePaths";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import {
  Cols,
  LeftRail,
  Main,
  RightRail
} from "../../../common/layout/styledComponents";
import Spacer from "../../../common/Spacer";
import {
  H6,
  Header,
  Hr,
  IconButton,
  LinkButtonNew,
  MainTitle,
  NewButton,
  P,
  SecondaryButtonList
} from "../../../common/StyledComponents";
import OrgDetail from "../../../spaces/Home/OrgDetail/OrgDetail";
import SettingsModal from "../../Config/SettingsModal/SettingsModal";
import AutomationToggle from "../AutomationToggle";
import { PermissionsModal } from "../Permissions/PermissionsModal";
import { useAllAutomations } from "../queries/AllAutomations";
import RunAutomationButton from "../RunAutomationButton";

import DeleteAutomationsModal from "./DeleteAutomationsModal";
import * as styled from "./styledComponents";

type AutomationModal = "settings" | "permissions" | "delete" | undefined;

export const Home = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAuthUser();
  const { automationsapp_getNewAutomation, automationsapp_getEditAutomation } =
    usePaths();
  const [searchParams] = useSearchParams();
  const updateParams = useSearchParamsUpdater();
  const selectedAutomationSlug = searchParams.get("selectedAutomation") || undefined;
  const [openModal, setOpenModal] = React.useState<AutomationModal>(undefined);

  const { loading, data, refetch } = useAllAutomations({
    fetchPolicy: "no-cache"
  });

  const items = React.useMemo(() => {
    return data?.allAutomations.edges.length
      ? data?.allAutomations.edges.map(edge => ({
          ...edge.node,
          href: automationsapp_getEditAutomation(edge.node.slug)
        }))
      : [];
  }, [data, automationsapp_getEditAutomation]);

  const selectedItem: Item | null = React.useMemo(() => {
    return items.find(item => item.slug === selectedAutomationSlug) || null;
  }, [items, selectedAutomationSlug]);

  const onSelect = React.useCallback(
    (slug: string | null) => {
      const found = items.find(item => item.slug === slug) || null;
      if (slug !== null && !found) {
        throw new Error("illegal state");
      }

      updateParams({ selectedAutomation: slug });
    },
    [items, updateParams]
  );

  const deselectAutomation = React.useCallback(() => {
    updateParams({ selectedAutomation: undefined });
  }, [updateParams]);

  return (
    <>
      <PageHeader key="automations-home" title="Home" />
      <Cols>
        <LeftRail open>
          <Nav />
        </LeftRail>
        {loading ? (
          <Main>
            {" "}
            <Skeleton />{" "}
          </Main>
        ) : !items.length ? (
          <styled.GettingStartedContainer>
            <styled.GettingStarted />
          </styled.GettingStartedContainer>
        ) : (
          <Main>
            <>
              <Header>
                <MainTitle>Your automations</MainTitle>
                {isAdmin && (
                  <NewButton
                    icon="plus"
                    type="link"
                    onClick={() => {
                      navigate(automationsapp_getNewAutomation());
                    }}
                  >
                    New automation
                  </NewButton>
                )}
              </Header>
              <Hr />
              <CardList
                items={items}
                moreButton={undefined}
                selectedSlug={selectedItem && selectedItem.slug}
                loading={loading}
                onSelect={onSelect}
                emptyState="You have no automations"
              />
            </>
          </Main>
        )}
        {!!items.length && (
          <RightRail isStatic isActive={!!selectedItem} onBack={() => onSelect(null)}>
            {selectedItem ? (
              <div>
                <H6>{selectedItem.name}</H6>
                <Spacer size="lg" />
                {selectedItem.description && <P>{selectedItem.description}</P>}
                {isAdmin && (
                  <SecondaryButtonList>
                    <IconButton
                      title="Update automation settings"
                      type="link"
                      shape="circle"
                      icon="setting"
                      onClick={() => {
                        setOpenModal("settings");
                      }}
                    />
                    <IconButton
                      title="Delete automation"
                      type="link"
                      shape="circle"
                      icon="delete"
                      onClick={() => {
                        setOpenModal("delete");
                      }}
                    />
                  </SecondaryButtonList>
                )}
                {isAdmin && (
                  <>
                    <ButtonNew
                      type="primary"
                      size="sm"
                      block
                      onClick={() => {
                        navigate(automationsapp_getEditAutomation(selectedItem.slug));
                      }}
                    >
                      Edit automation
                    </ButtonNew>
                    <Spacer size="smd" />
                    <RunAutomationButton id={selectedItem.id} />
                  </>
                )}
                <Hr />
                <AutomationToggle id={selectedItem.id} />
                <LinkButtonNew onClick={() => setOpenModal("permissions")}>
                  Manage access and permissions
                </LinkButtonNew>
              </div>
            ) : (
              <OrgDetail />
            )}
          </RightRail>
        )}
      </Cols>
      {selectedItem && openModal === "settings" && (
        <SettingsModal
          automation={selectedItem}
          onCancel={() => setOpenModal(undefined)}
          onComplete={() => {
            refetch();
            setOpenModal(undefined);
          }}
        />
      )}
      {selectedItem && openModal === "permissions" && (
        <PermissionsModal
          automationName={selectedItem.name}
          automationSlug={selectedItem.slug}
          onCancel={() => setOpenModal(undefined)}
        />
      )}
      {selectedItem && openModal === "delete" && (
        <DeleteAutomationsModal
          automationId={selectedItem?.id}
          onSuccess={() => deselectAutomation()}
          onComplete={() => setOpenModal(undefined)}
          onCancel={() => setOpenModal(undefined)}
        />
      )}
    </>
  );
};
