import React from "react";

import { uniqueId } from "lodash";
import styled from "styled-components";

import useObservedRect from "../../../common/hooks/useObservedRect";
import { useLayoutContextDispatcher } from "../LayoutContext/LayoutContext";
import { useLayoutDelegationContext } from "../LayoutDelegator/LayoutDelegator";

const DimensionsContext = React.createContext({
  key: "default-context",
  DOMRect: new DOMRect()
});

export function DimensionsContextContainer({
  children,
  waitUntilReady = false,
  contextKey
}: {
  children: React.ReactNode;
  waitUntilReady?: boolean;
  contextKey?: string;
}) {
  const dispatch = useLayoutContextDispatcher();
  const { isDelegated } = useLayoutDelegationContext();
  const [dimensionContextKey] = React.useState(
    contextKey || uniqueId("dimensions-context-")
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const DOMRect = useObservedRect(ref);
  React.useEffect(() => {
    if (isDelegated) return;
    dispatch({
      type: "UPDATE_DIMENSIONS_CONTEXT_RECT",
      payload: { key: dimensionContextKey, rect: DOMRect }
    });
  }, [dimensionContextKey, DOMRect, isDelegated, dispatch]);

  const contextValue = React.useMemo(
    () => ({
      key: dimensionContextKey,
      DOMRect
    }),
    [DOMRect, dimensionContextKey]
  );

  const isReady = DOMRect.width > 0;
  if (waitUntilReady && !isReady) {
    return <Root ref={ref}>{children}</Root>;
  }
  return (
    <DimensionsContext.Provider value={contextValue}>
      <Root ref={ref}>{children}</Root>
    </DimensionsContext.Provider>
  );
}

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const useDimensionsContext = () => React.useContext(DimensionsContext);
