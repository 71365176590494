import gql from "graphql-tag";

import { SpaceNode } from "../../../types";
import {
  FUNCTION_PARAMETER_FRAGMENT,
  FUNCTION_ATTRIBUTE_FRAGMENT
} from "../../common/FunctionEditor/useFunctionEditor/queries";

export const TREE_NODE_FRAGMENT = gql`
  fragment treeNodeFields on SpaceComponentNode {
    id
    type
    name
    slug
    container {
      id
      slug
      type
    }
    isRemovable
    sourceType
    notVisibleFunctions {
      edges {
        node {
          id
        }
      }
    }
    functions {
      edges {
        node {
          id
          name
          title
          returnSchema
          isUserGenerated
          metadata
          access {
            onlyParameters
            onlyAttributes
          }
          dataSource {
            id
            name
          }
          functionParameters {
            edges {
              node {
                ...FunctionParameterFragment
              }
            }
          }
          functionAttributes {
            edges {
              node {
                ...FunctionAttributeFragment
              }
            }
          }
        }
      }
    }
    properties
    layout
    view {
      id
      function {
        id
        name
        access {
          onlyParameters
          onlyAttributes
        }
      }
    }
  }
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;

export interface Data {
  space: SpaceNode;
}

export interface LastSavedSpaceVars {
  slug: string;
}

export type PublishedSpaceVars = LastSavedSpaceVars;

export const LATEST_SPACE_QUERY = gql`
  query Space($slug: String!) {
    space(slug: $slug) {
      __typename
      ... on NotFoundErrorResult {
        message
      }
      ... on SpaceNode {
        id
        name
        slug
        latestVersion {
          id
          componentTreeNodes {
            ...treeNodeFields
            componentTreeNodes {
              ...treeNodeFields
              componentTreeNodes {
                ...treeNodeFields
                componentTreeNodes {
                  ...treeNodeFields
                  componentTreeNodes {
                    ...treeNodeFields
                    componentTreeNodes {
                      ...treeNodeFields
                      componentTreeNodes {
                        ...treeNodeFields
                        componentTreeNodes {
                          ...treeNodeFields
                          componentTreeNodes {
                            ...treeNodeFields
                            componentTreeNodes {
                              ...treeNodeFields
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TREE_NODE_FRAGMENT}
`;

export const PUBLISHED_SPACE_QUERY = gql`
  query Space($slug: String!) {
    space(slug: $slug) {
      __typename
      ... on NotFoundErrorResult {
        message
      }
      ... on SpaceNode {
        id
        name
        slug
        isFavorite
        publishedVersion {
          __typename
          ... on NoPublishedVersionErrorResult {
            message
          }
          ... on SpaceVersionNode {
            id
            componentTreeNodes {
              ...treeNodeFields
              componentTreeNodes {
                ...treeNodeFields
                componentTreeNodes {
                  ...treeNodeFields
                  componentTreeNodes {
                    ...treeNodeFields
                    componentTreeNodes {
                      ...treeNodeFields
                      componentTreeNodes {
                        ...treeNodeFields
                        componentTreeNodes {
                          ...treeNodeFields
                          componentTreeNodes {
                            ...treeNodeFields
                            componentTreeNodes {
                              ...treeNodeFields
                              componentTreeNodes {
                                ...treeNodeFields
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TREE_NODE_FRAGMENT}
`;

export type AddSpaceFavoriteVariables = {
  spaceId: string;
};
export const ADD_SPACE_FAVORITE = gql`
  mutation OrderSpaceFavorite($spaceId: ID!) {
    orderSpaceFavorite(spaceId: $spaceId) {
      space {
        id
        isFavorite
      }
    }
  }
`;

export type DeleteSpaceFavoriteVariables = { spaceId: string };
export const DELETE_SPACE_FAVORITE = gql`
  mutation DeleteSpaceFavorite($spaceId: ID!) {
    deleteSpaceFavorite(spaceId: $spaceId) {
      space {
        id
        isFavorite
      }
    }
  }
`;
