import React from "react";

import styled from "styled-components";

import useObservedRect from "../../../common/hooks/useObservedRect";
import usePrevious from "../../../common/hooks/usePrevious";
import {
  useSelectionStateContext,
  useTransformationStateContext,
  useTransformationActionContext
} from "../../layout/TransformationContext/TransformationContext";
import { useResizeContext } from "../../SpaceRoot/ResizeContext";
import { useComponentPathContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { DimensionsContextContainer, useDimensionsContext } from "../DimensionsContext";
import Draggable from "../Draggable";
import { useGridContext } from "../GridContext";
import { useComponentLayoutContext } from "../LayoutContext/LayoutContext";
import Selection from "../Selection/Selection";
import {
  addPoints,
  subtractPoints,
  ElementLayout,
  TransformationType,
  LayoutUnit,
  parseCssUnit,
  DEFAULT_ELEMENT_UNITS,
  EMPTY_LAYOUT,
  DOMPointsEqual,
  PositionOption,
  OverflowOption
} from "../util";

import { ELEMENT_Z_INDEX } from "./constants";
import useElementTransformer from "./useElementTransformer";
import useLayoutRegistrations from "./useLayoutRegistrations";

import { ElementProps } from ".";

const initialTransformingElementState = {
  drag: {
    dragging: false,
    dragStart: new DOMPoint(),
    offsetStart: new DOMPoint(),
    dragCurrent: new DOMPoint()
  },
  layout: EMPTY_LAYOUT,
  transformationType: TransformationType.NONE,
  lastTransformationType: TransformationType.NONE,
  snapToGrid: false,
  elementMeasureRequired: true,
  canvasResizeInProgress: false,
  lockChildren: false,
  currentDOMRect: new DOMRect(),
  syncRequired: false
};

const NON_DEBOUNCED_LAYOUT_FIELDS = [
  "zIndex",
  "overflow",
  "minWidth",
  "snapToGrid",
  "position"
];

type TransformingElementState = typeof initialTransformingElementState;

type TransformingElementAction =
  | {
      type: "DRAG_START";
      payload: { dragStart: DOMPoint };
    }
  | {
      type: "DRAG";
      payload: {
        dragCurrent: DOMPoint;
      };
    }
  | {
      type: "DRAG_END";
      payload: { dragEnd: DOMPoint };
    }
  | {
      type: "TRANSFORM";
      payload: {
        changes: Partial<ElementLayout>;
        didSnap?: boolean;
      };
    }
  | {
      type: "TRANSFORM_START";
      payload: { transformationType: TransformationType; snapToGrid?: boolean };
    }
  | { type: "TRANSFORM_END" }
  | {
      type: "UPDATE_ELEMENT_DOM_RECT";
      payload: { rect: DOMRect };
    }
  | { type: "RESET_ELEMENT_DOM_RECT" }
  | { type: "LOCK_CHILDREN" }
  | { type: "UNLOCK_CHILDREN" }
  | { type: "FLAG_RESIZING" }
  | { type: "UNFLAG_RESIZING" }
  | { type: "ENQUEUE_SYNC" }
  | { type: "DEQUEUE_SYNC" };

function transformingElementReducer(
  state: TransformingElementState,
  action: TransformingElementAction
) {
  switch (action.type) {
    case "DRAG_START": {
      const { layout } = state;
      const { dragStart } = action.payload;
      const offsetStart = new DOMPoint(parseFloat(layout.left), parseFloat(layout.top));
      return {
        ...state,
        drag: {
          ...state.drag,
          dragStart: dragStart,
          offsetStart
        },
        transformationType: TransformationType.MOVE,
        snapToGrid: layout.snapToGrid
      };
    }

    case "DRAG": {
      const { drag, layout } = state;
      const { dragCurrent } = action.payload;
      const move = subtractPoints(dragCurrent, drag.dragStart);
      const nextOffset = addPoints(drag.offsetStart, move);
      const dragging = true;
      // noop if possible
      if (
        dragging === state.drag.dragging &&
        DOMPointsEqual(dragCurrent, state.drag.dragCurrent) &&
        `${nextOffset.x}${LayoutUnit.PIXEL}` === state.layout.left &&
        `${nextOffset.y}${LayoutUnit.PIXEL}` === state.layout.top
      ) {
        return state;
      }

      return {
        ...state,
        drag: {
          ...drag,
          dragging,
          dragCurrent
        },
        layout: new ElementLayout({
          ...layout,
          left: nextOffset.x + LayoutUnit.PIXEL,
          top: nextOffset.y + LayoutUnit.PIXEL
        })
      };
    }

    case "DRAG_END": {
      return {
        ...state,
        transformationType: TransformationType.NONE,
        lastTransformationType: TransformationType.MOVE,
        drag: {
          ...initialTransformingElementState.drag
        }
      };
    }

    case "TRANSFORM_START": {
      const { transformationType, snapToGrid = false } = action.payload;
      return {
        ...state,
        snapToGrid: state.layout.snapToGrid ? snapToGrid : false,
        transformationType
      };
    }

    case "TRANSFORM": {
      const { changes, didSnap = false } = action.payload;
      const nextLayout = new ElementLayout({
        ...state.layout,
        ...changes
      });
      if (nextLayout.isEqual(state.layout)) {
        return state;
      }
      let nextSnapToGrid = state.snapToGrid;
      if (!nextLayout.snapToGrid) {
        nextSnapToGrid = false;
      } else if (state.layout.snapToGrid === false && changes.snapToGrid === true) {
        nextSnapToGrid = true;
      } else if (nextSnapToGrid && didSnap) {
        nextSnapToGrid = false;
      }
      if (
        nextLayout.isEqual(state.layout, true) &&
        state.snapToGrid === nextSnapToGrid
      ) {
        return state;
      }
      return {
        ...state,
        layout: nextLayout,
        snapToGrid: nextSnapToGrid
      };
    }

    case "TRANSFORM_END": {
      return {
        ...state,
        transformationType: TransformationType.NONE,
        lastTransformationType: state.transformationType
      };
    }

    case "UPDATE_ELEMENT_DOM_RECT": {
      return {
        ...state,
        elementMeasureRequired: false,
        currentDOMRect: action.payload.rect
      };
    }

    case "RESET_ELEMENT_DOM_RECT": {
      if (state.elementMeasureRequired) return state;
      return {
        ...state,
        elementMeasureRequired: true
      };
    }

    case "LOCK_CHILDREN": {
      if (state.lockChildren) return state;
      return {
        ...state,
        lockChildren: true
      };
    }

    case "UNLOCK_CHILDREN": {
      if (!state.lockChildren) return state;
      return {
        ...state,
        lockChildren: false
      };
    }

    case "FLAG_RESIZING": {
      if (state.canvasResizeInProgress) return state;
      return {
        ...state,
        canvasResizeInProgress: true
      };
    }

    case "UNFLAG_RESIZING": {
      if (!state.canvasResizeInProgress) return state;
      return {
        ...state,
        canvasResizeInProgress: false
      };
    }

    case "ENQUEUE_SYNC": {
      if (state.syncRequired) return state;
      return {
        ...state,
        syncRequired: true
      };
    }

    case "DEQUEUE_SYNC": {
      if (!state.syncRequired) return state;
      return {
        ...state,
        syncRequired: false,
        lastTransformationType: TransformationType.NONE
      };
    }

    default:
      throw new Error();
  }
}

export default function TransformingElement({
  component: { slug },
  component,
  children
}: ElementProps) {
  const { layout } = useComponentLayoutContext();
  if (!layout) throw new Error("Expected layout for Element.");
  const rootEl = React.useRef<HTMLDivElement | null>(null);
  const childWrapperEl = React.useRef<HTMLDivElement | null>(null);
  const measurerEl = React.useRef<HTMLDivElement>(null);

  const { layoutContextReady, convertLayout, transform } = useElementTransformer();

  // Convert layout to units matching how layouts are tracked while transforming
  const convertedRootLayout = React.useMemo(
    () =>
      convertLayout(layout, {
        left: LayoutUnit.PIXEL,
        top: LayoutUnit.PIXEL,
        width: layout.width === LayoutUnit.AUTO ? LayoutUnit.AUTO : LayoutUnit.PIXEL,
        height: layout.height === LayoutUnit.AUTO ? LayoutUnit.AUTO : LayoutUnit.PIXEL
      }),
    [layout, convertLayout]
  );

  const { transferringEvent } = useTransformationStateContext();

  const initialState = {
    ...initialTransformingElementState,
    layout: convertedRootLayout,
    transformationType:
      // If there is a transferring event for this component adopt it
      transferringEvent?.slug === slug
        ? transferringEvent.transformationType
        : TransformationType.NONE
  };
  const [state, dispatch] = React.useReducer(transformingElementReducer, initialState);

  const { editMode } = useStableSpaceContext();
  const { updateTransformations, endMove, endResize, resolveEventTransfer } =
    useTransformationActionContext();
  const { activeDropTarget, locked } = useSelectionStateContext();
  const { gridUnit, gridReady, snapRectToGrid } = useGridContext();
  const { DOMRect: dimensionsContextDOMRect } = useDimensionsContext();
  const { hasResizedRecently } = useResizeContext();
  const path = useComponentPathContext();

  useLayoutRegistrations(slug, state.layout, measurerEl);

  const isReady = layoutContextReady && gridReady;
  const layoutRect = state.layout.toRect();
  const domRect = useObservedRect(measurerEl, new DOMPoint(layoutRect.x, layoutRect.y));

  // Track the measured DOMRect of the HTMLElement when required
  const skipUpdateRect =
    !isReady ||
    !state.elementMeasureRequired ||
    domRect.width === 0 ||
    domRect.height === 0;
  React.useEffect(() => {
    if (skipUpdateRect) return;
    dispatch({
      type: "UPDATE_ELEMENT_DOM_RECT",
      payload: { rect: domRect }
    });
  }, [skipUpdateRect, domRect]);

  // If a component change may result in its intrinsic size changing re-measure
  React.useEffect(() => {
    if (
      state.layout.width === LayoutUnit.AUTO ||
      state.layout.height === LayoutUnit.AUTO
    ) {
      dispatch({ type: "RESET_ELEMENT_DOM_RECT" });
    }
  }, [component.properties, state.layout.height, state.layout.width]);

  // Update local cached layout upon mount, rootLayout change, and space resize
  React.useEffect(() => {
    dispatch({
      type: "TRANSFORM",
      payload: { changes: convertedRootLayout }
    });
    if (hasResizedRecently) {
      dispatch({ type: "FLAG_RESIZING" });
    } else {
      dispatch({ type: "UNFLAG_RESIZING" });
    }
  }, [hasResizedRecently, convertedRootLayout]);

  // Sync non-dimensional layout fields from root layout
  React.useEffect(() => {
    const nonDimensionalLayout = Object.fromEntries(
      Object.entries(layout).filter(([key]) =>
        NON_DEBOUNCED_LAYOUT_FIELDS.includes(key)
      )
    );
    dispatch({ type: "TRANSFORM", payload: { changes: nonDimensionalLayout } });
  }, [layout]);

  // Parse units for root layout
  const targetLeftUnit = parseCssUnit(layout?.left);
  const targetTopUnit = parseCssUnit(layout?.top);
  const targetWidthUnit = parseCssUnit(layout?.width);
  const targetHeightUnit = parseCssUnit(layout?.height);
  const targetUnits = React.useMemo(() => {
    return {
      left: targetLeftUnit,
      top: targetTopUnit,
      width: targetWidthUnit,
      height: targetHeightUnit
    };
  }, [targetLeftUnit, targetTopUnit, targetWidthUnit, targetHeightUnit]);

  // Keep a given rect on canvas and prevent shrinking too much
  const getConstrainedRect = React.useCallback(
    layout => {
      const constrainedLayout = new ElementLayout(layout);

      // Enforce a reasonable portion of the Element be inside the canvas so its not "lost"
      const constrainedLayoutRect = constrainedLayout.toRect();
      const distanceFromLeft = constrainedLayoutRect.right;
      const distanceFromRight =
        dimensionsContextDOMRect.width - constrainedLayoutRect.left;
      if (
        distanceFromLeft < gridUnit ||
        (constrainedLayoutRect.left > 0 && distanceFromRight < gridUnit)
      ) {
        if (distanceFromLeft < distanceFromRight) {
          constrainedLayoutRect.x = gridUnit - constrainedLayoutRect.width;
        } else {
          constrainedLayoutRect.x = dimensionsContextDOMRect.width - gridUnit;
        }
      }
      // Don't need to handle out of bounds on bottom since canvas grows to accomodate
      const distanceFromTop = constrainedLayoutRect.bottom;
      if (distanceFromTop < gridUnit) {
        constrainedLayoutRect.y = gridUnit - constrainedLayoutRect.height;
      }
      return constrainedLayoutRect;
    },
    [dimensionsContextDOMRect, gridUnit]
  );

  // Sync local transformations to root once the transformation completes. Enforces
  // constraints on ElementLayout position to keep components visible on the canvas.
  // Handles snapping ElementLayouts to the grid when enabled. This effect should not
  // run until canvas has finished adjusting after a resize or zoom. Also, importantly,
  // the effect which calculates the absolute dimensions of an Element relative to the
  // current canvas dimensions must run first. This effect order dependency is managed
  // with the `canvasResizeInProgress` flag.
  const rootSnapToGrid = layout.snapToGrid;
  const lastRootSnapToGrid = usePrevious(rootSnapToGrid);
  const snapWasEnabled = lastRootSnapToGrid === false && rootSnapToGrid === true;

  const skipSync =
    !editMode ||
    !isReady ||
    state.canvasResizeInProgress ||
    state.transformationType !== TransformationType.NONE ||
    state.layout === EMPTY_LAYOUT;

  React.useEffect(() => {
    if (skipSync) return;

    // Enforce minimum dimensions so that Elements don't get "lost"
    const constrainedLayoutRect = getConstrainedRect(state.layout);

    // If required snap layout to the grid.
    const finalRect = state.snapToGrid
      ? snapRectToGrid(constrainedLayoutRect)
      : constrainedLayoutRect;
    const finalLayout = ElementLayout.fromRect(finalRect);

    // Only update layout props related to component moves / resizes.
    NON_DEBOUNCED_LAYOUT_FIELDS.forEach(field => {
      delete finalLayout[field];
    });

    // Restore `AUTO` units back to `AUTO`.
    if (targetUnits.width === LayoutUnit.AUTO) {
      finalLayout.width = LayoutUnit.AUTO;
    }
    if (targetUnits.height === LayoutUnit.AUTO) {
      finalLayout.height = LayoutUnit.AUTO;
    }
    // Update local layout
    dispatch({
      type: "TRANSFORM",
      payload: { changes: finalLayout, didSnap: state.snapToGrid }
    });

    // If sync occurs in this tick the effect clashes with
    // the effect to sync external changes locally.
    dispatch({ type: "ENQUEUE_SYNC" });
  }, [
    skipSync,
    state.layout,
    state.snapToGrid,
    snapWasEnabled,
    targetUnits.width,
    targetUnits.height,
    getConstrainedRect,
    snapRectToGrid
  ]);

  // Dispatch a queued transform and cleanup transformation
  // NOTE: This is a distinct effect from above as `transform` breaks
  // memoization when root `ElementLayout`s change causing a loop.
  React.useEffect(() => {
    if (!state.syncRequired) return;

    dispatch({ type: "DEQUEUE_SYNC" });

    // Special case `HEADER`, which for historical reasons has no path.
    const ensuredPath = component.type === "HEADER" ? slug : path;
    if (state.lastTransformationType === TransformationType.MOVE) {
      endMove(ensuredPath, state.layout);
    } else if (state.lastTransformationType === TransformationType.RESIZE) {
      endResize(ensuredPath);
    }

    // Update root layout
    const convertedLayout = convertLayout(state.layout, targetUnits);
    transform(
      Object.fromEntries(
        Object.entries(convertedLayout).filter(
          ([k]) => !NON_DEBOUNCED_LAYOUT_FIELDS.includes(k)
        )
      )
    );
  }, [
    slug,
    path,
    component.type,
    state.layout,
    state.syncRequired,
    state.lastTransformationType,
    targetUnits,
    convertLayout,
    transform,
    endMove,
    endResize
  ]);

  // If this element is currently transforming keep the transformation updated
  React.useLayoutEffect(() => {
    if (state.transformationType !== TransformationType.NONE) {
      updateTransformations({
        slug,
        transformationType: state.transformationType,
        layout: state.layout,
        originalLayout: layout
      });
    }
  }, [slug, layout, state.layout, state.transformationType, updateTransformations]);

  // HACK: Manage drag event propagation.
  const wasDragging = usePrevious(state.drag.dragging);
  React.useEffect(() => {
    let handle: any;
    if (state.drag.dragging && !wasDragging) {
      dispatch({ type: "LOCK_CHILDREN" });
    } else if (!state.drag.dragging && wasDragging) {
      // Unlocking children needs to be deferred one tick so that
      // the mouse up following a drag is blocked on downstream elements.
      handle = setTimeout(() => dispatch({ type: "UNLOCK_CHILDREN" }), 0);
    }
    return () => {
      clearTimeout(handle);
    };
  }, [wasDragging, state.drag.dragging]);

  const handleDragStart = React.useCallback(function handleDragStart(
    dragStart: DOMPoint
  ) {
    dispatch({
      type: "DRAG_START",
      payload: {
        dragStart
      }
    });
  },
  []);

  const handleDrag = React.useCallback(function handleDrag(dragCurrent: DOMPoint) {
    dispatch({
      type: "DRAG",
      payload: {
        dragCurrent
      }
    });
  }, []);

  const handleDragEnd = React.useCallback(function handleDragEnd(dragEnd: DOMPoint) {
    dispatch({
      type: "DRAG_END",
      payload: {
        dragEnd
      }
    });
  }, []);

  const handleResizeStart = React.useCallback(
    function handleResizeStart() {
      // If a dimension is set to LayoutUnit.AUTO when a resize occurs it needs to be
      // converted to a fixed unit when a manual resize begins
      if (layout.width === LayoutUnit.AUTO || layout.height === LayoutUnit.AUTO) {
        const nextUnits = {
          width:
            layout.width === LayoutUnit.AUTO
              ? DEFAULT_ELEMENT_UNITS.width
              : parseCssUnit(layout.width),
          height:
            layout.height === LayoutUnit.AUTO
              ? DEFAULT_ELEMENT_UNITS.height
              : parseCssUnit(layout.height),
          top: parseCssUnit(layout.top),
          left: parseCssUnit(layout.left)
        };
        transform(
          convertLayout(
            new ElementLayout({
              ...layout,
              width: state.currentDOMRect.width + LayoutUnit.PIXEL,
              height: state.currentDOMRect.height + LayoutUnit.PIXEL
            }),
            nextUnits
          )
        );
      }

      dispatch({
        type: "TRANSFORM_START",
        payload: {
          transformationType: TransformationType.RESIZE,
          snapToGrid: true
        }
      });
    },
    [
      layout,
      convertLayout,
      transform,
      state.currentDOMRect.width,
      state.currentDOMRect.height
    ]
  );

  const handleResize = React.useCallback(
    function handleResize({ x, y, width, height }) {
      if (state.transformationType !== TransformationType.RESIZE) return;
      dispatch({
        type: "TRANSFORM",
        payload: {
          changes: {
            width: width + LayoutUnit.PIXEL,
            height: height + LayoutUnit.PIXEL,
            left: x + LayoutUnit.PIXEL,
            top: y + LayoutUnit.PIXEL
          }
        }
      });
    },
    [state.transformationType]
  );

  const handleResizeEnd = React.useCallback(function handleResizeEnd() {
    dispatch({ type: "TRANSFORM_END" });
  }, []);

  const handleEventTransfer = React.useCallback(() => {
    resolveEventTransfer(slug);
  }, [slug, resolveEventTransfer]);

  // Construct element styles
  const componentStyle = component.properties.styles?.root || {};
  const style: Partial<ElementLayout> & {
    outline?: string;
  } = React.useMemo(() => {
    const { overflow, ...layout } = state.layout;
    const style: Partial<ElementLayout> = ElementLayout.stripNonStyleProps(
      layout as ElementLayout
    );
    style.position = PositionOption.ABSOLUTE;

    if (state.drag.dragging) {
      style.pointerEvents = "none";
    }

    if (state.drag.dragging && activeDropTarget) {
      style.opacity = "0.5";
    }

    // If dragging use a transform to shift work to GPU
    style.left = "0";
    style.top = "0";
    style.transform = `translate3d(${state.layout.left}, ${state.layout.top},  0)`;
    return style;
  }, [state.layout, state.drag.dragging, activeDropTarget]);

  let transferDragStart;
  if (
    transferringEvent &&
    transferringEvent.slug === slug &&
    transferringEvent.transformationType === TransformationType.MOVE
  ) {
    transferDragStart = transferringEvent.data.pagePosition;
  }

  // After reading the size of the element manually restyle
  // to prevent a flashing different styles
  React.useLayoutEffect(() => {
    if (state.elementMeasureRequired) {
      childWrapperEl.current!.style.overflow =
        state.layout.overflow || OverflowOption.AUTO;
      measurerEl.current!.style.width = "100%";
      measurerEl.current!.style.height = "100%";
    }
  }, [state.elementMeasureRequired, state.layout.overflow]);

  return (
    <Root data-test={`element-${slug}`} ref={rootEl} style={style}>
      <Draggable
        disable={!editMode || locked.includes(slug)}
        transferDragStart={transferDragStart}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        onTransfer={handleEventTransfer}
      >
        <ChildrenWrapper
          ref={childWrapperEl}
          style={{
            overflow: state.elementMeasureRequired ? "visible" : state.layout.overflow,
            pointerEvents: state.lockChildren ? "none" : "all",
            ...componentStyle
          }}
        >
          <Measurer
            ref={measurerEl}
            style={
              state.elementMeasureRequired
                ? {
                    width: "max-content",
                    height: "max-content"
                  }
                : { width: "100%", height: "100%" }
            }
          >
            <DimensionsContextContainer>{children}</DimensionsContextContainer>
          </Measurer>
        </ChildrenWrapper>
      </Draggable>
      <Selection
        component={component}
        transforming={true}
        onResizeStart={handleResizeStart}
        onResize={handleResize}
        onResizeEnd={handleResizeEnd}
      />
    </Root>
  );
}

const Root = styled.div`
  position: absolute;
  z-index: ${ELEMENT_Z_INDEX};
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Measurer = styled.div``;
