import { Divider as AntDivider } from "antd";
import styled from "styled-components";

import { colors } from "../../../../../cssConstants";

export const Container = styled.div`
  >div: last-child {
    margin-bottom: 0;
  }
`;

export const ButtonBar = styled.div``;

export const SpacedContainer = styled.div`
  background-color: white;
  border: none;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Panel = styled.div`
  border: 1px solid ${colors.newContainerPrimary};
  border-radius: 8px;
  background-color: ${colors.newContainerPrimary};
`;

export const Divider = styled(AntDivider)`
  margin: 24px -40px;
  color: ${props => props.theme.containerPrimaryTint};
  width: calc(100% + 80px);
`;

export const Content = styled.div`
  padding: 0 40px 22px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #2a282d;
`;

export const Description = styled.div`
  margin-top: 8px;
`;
