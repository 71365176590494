import { Cascader as AntCascader } from "antd";
import styled from "styled-components";

export const Root = styled.div`
  width: 100%;

  & .ant-cascader-menu-item {
    min-width: 130px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Cascader = styled(AntCascader as any)`
  width: 100%;
  &.hidden {
    .anticon.anticon-down.ant-cascader-picker-arrow {
      display: none;
    }
    .ant-input.ant-cascader-input {
      display: none;
    }

    .ant-cascader-picker-label {
      display: none;
    }
  }
`;
Cascader.displayName = "Cascader";
