import React from "react";

import { Input } from "antd";
import styled from "styled-components";

import JsToggleButton from "../../../../../common/JsToggleButton/JsToggleButton";
import Label from "../../../../../common/Label";
import { SortableItemCompact } from "../../../../../common/SortableList";
import { getSpaceComponentDisplayName } from "../../../SpaceContext/StableSpaceContext";
import { useComponentContext } from "../../contexts/ComponentContext";
import { useComponentConfigContext } from "../ComponentConfigContext";
import {
  ConditionalExpression,
  ConditionalOperator,
  ConditionalSynopsis
} from "../Conditionals";
import { ConfigPanelPopper, ConfigSection, Field } from "../ConfigPanel";
import {
  ConfigPanelActionTypes,
  useSpaceConfigPanelContext
} from "../ConfigPanel/ConfigPanelContext";
import { DebouncedTemplateEditor } from "../TemplateEditor";
import { ValidationRule } from "../ValidationField/types";

export function ValidationRulesSection() {
  const { state, errors, dispatch } = useComponentConfigContext();
  const configPanel = useSpaceConfigPanelContext();
  const validationRules: ValidationRule[] =
    state.draftComponent.properties.validation_rules || [];
  function onChangeValidationRules(value: ValidationRule[]) {
    dispatch({
      type: "SET_DRAFT_COMPONENT",
      payload: {
        path: "properties.validation_rules",
        value
      }
    });
  }

  const defaultManagedValidationRule: ValidationRule = {
    message_template: "`Please enter a valid value.`",
    conditional_expression: {
      type: "managed",
      operator: ConditionalOperator.Equals,
      subject_template: `${state.draftComponent.slug}.value`,
      object_template: "``"
    }
  };

  const defaultEvaluatedValidationRule: ValidationRule = {
    message_template: "`Please enter a valid value.`",
    conditional_expression: {
      type: "evaluated",
      template: `${state.draftComponent.slug}.value !== undefined`
    }
  };

  return (
    <ConfigSection
      title="Validation Rules"
      onAdd={() => {
        onChangeValidationRules(validationRules.concat(defaultManagedValidationRule));
      }}
    >
      {validationRules.map((vr, idx) => {
        const key = `validationRule${idx}`;
        const isSelected = configPanel.state.activePopperIdentifier === key;
        const error = errors.find(
          e => e.field === "VALIDATION_RULES" && e.index === idx
        );
        return (
          <React.Fragment key={key}>
            <SortableItemCompact
              id={key}
              key={key}
              sortKey={key}
              isSelected={isSelected}
              onClick={() => {
                configPanel.dispatch({
                  type: ConfigPanelActionTypes.OPEN_POPPER,
                  payload: { popperIdentifier: key }
                });
              }}
              onRemove={() => {
                const nextRules = [...validationRules];
                nextRules.splice(idx, 1);
                onChangeValidationRules(nextRules);
              }}
              errorMessage={error?.message || null}
            >
              <ConditionalSynopsis expression={vr.conditional_expression} />
            </SortableItemCompact>
            {isSelected && (
              <ConfigPanelPopper
                popperId={key}
                popperReferenceElement={document.getElementById(key) || undefined}
                onCancel={() =>
                  configPanel.dispatch({ type: ConfigPanelActionTypes.CLOSE_POPPER })
                }
              >
                <ValidationRuleDetails
                  rule={vr}
                  defaultManagedRule={defaultManagedValidationRule}
                  defaultEvaluatedRule={defaultEvaluatedValidationRule}
                  onChange={rule => {
                    const nextRules = [...validationRules];
                    nextRules[idx] = rule;
                    onChangeValidationRules(nextRules);
                  }}
                />
              </ConfigPanelPopper>
            )}
          </React.Fragment>
        );
      })}
    </ConfigSection>
  );
}

const StyledConfigSection = styled(ConfigSection)`
  /* prevent shifting when switching modes */
  min-height: 350px;
`;

const StyledJsToggleButton = styled(JsToggleButton)`
  position: absolute;
  top: ${props => props.theme.spacermd};
  right: ${props => props.theme.spacermd};
`;

function ValidationRuleDetails({
  rule,
  defaultManagedRule,
  defaultEvaluatedRule,
  onChange
}: {
  rule: ValidationRule;
  defaultManagedRule: ValidationRule;
  defaultEvaluatedRule: ValidationRule;
  onChange: (rule: ValidationRule) => void;
}) {
  const { component } = useComponentContext();
  return (
    <StyledConfigSection title="Validation Rule">
      <StyledJsToggleButton
        isOn={rule.conditional_expression.type === "evaluated"}
        onToggle={() => {
          onChange(
            rule.conditional_expression.type === "evaluated"
              ? {
                  ...rule,
                  message_template: rule.message_template,
                  conditional_expression: defaultManagedRule.conditional_expression
                }
              : {
                  ...rule,
                  message_template: rule.message_template,
                  conditional_expression: defaultEvaluatedRule.conditional_expression
                }
          );
        }}
      />
      <Field>
        <label>Error message</label>
        <DebouncedTemplateEditor
          value={rule.message_template}
          placeholder="Must be valid"
          onChange={value => onChange({ ...rule, message_template: value })}
        />
      </Field>
      <Field>
        <Label showInfoIcon title="If the expression below is true the input is valid.">
          Rule
        </Label>
        <ConditionalExpression
          subject={
            rule.conditional_expression.type === "managed" ? (
              <Input
                disabled
                value={`${getSpaceComponentDisplayName(component)}'s value`}
              />
            ) : null
          }
          expression={rule.conditional_expression}
          onChange={conditional_expression => {
            onChange({ ...rule, conditional_expression });
          }}
        />
      </Field>
    </StyledConfigSection>
  );
}
