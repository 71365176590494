import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.spacermd};
`;

const BigIcon = styled(Icon)`
  font-size: 40px;
`;

export default function NotFoundContent() {
  return (
    <Root>
      <BigIcon type="search" />
      <span>No results</span>
    </Root>
  );
}
