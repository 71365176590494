import React from "react";

import { ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT } from "../../../constants";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { ErrorField } from "../ConfigPanel/styledComponents";
import NameFields, { NameFieldsProps } from "../NameFields";
import useFormConfig from "../useFormConfig";

export default function InputComponentNameFields(props: NameFieldsProps) {
  const { state } = useComponentConfigContext();
  const { getFunctionParameterFromSlug } = useFormConfig();

  const isInvalidComponent = React.useMemo(() => {
    const fp = getFunctionParameterFromSlug(state.draftComponent.slug);
    if (
      fp &&
      !ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[fp.type].includes(
        state.draftComponent.type
      )
    ) {
      return true;
    }
    return false;
  }, [
    getFunctionParameterFromSlug,
    state.draftComponent.type,
    state.draftComponent.slug
  ]);

  return (
    <>
      {isInvalidComponent && (
        <ErrorField>
          This component is no longer compatible with its form parameter. Select the
          form to change the field's component type.
        </ErrorField>
      )}
      <NameFields {...props} />
    </>
  );
}
