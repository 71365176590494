import gql from "graphql-tag";

import { ClientErrorResult, RelayNode } from "../../../types";

export interface SSHKeyAuthOrganization extends RelayNode {
  sshKeyFormat: string;
}

export interface SSHKeyFormatData {
  authOrganization: SSHKeyAuthOrganization;
}

export const SSH_KEY_FORMAT = gql`
  query SSHKeyFormat {
    authOrganization {
      id
      sshKeyFormat
    }
  }
`;

export interface CreateDataSourceProviderVariables {
  integration: string;
  credentials: Record<string, any>;
}

interface AvailableDataSource {
  sourceName: string;
  name: string;
}

interface DataSourceProviderAvailableData extends RelayNode {
  availableDataSources: AvailableDataSource[];
}

interface CreateDataSourceProviderResultSuccess {
  dataSourceProvider: DataSourceProviderAvailableData;
  __typename?: "CreateDataSourceProviderResultSuccess";
}

export interface CreateDataSourceProviderResult {
  createDataSourceProvider: CreateDataSourceProviderResultSuccess | ClientErrorResult;
}

export const CREATE_DATA_SOURCE_PROVIDER = gql`
  mutation CreateDataSourceProvider(
    $integration: String!
    $credentials: GenericScalar!
  ) {
    createDataSourceProvider(integration: $integration, credentials: $credentials) {
      ... on ClientErrorResult {
        code
        message
      }
      ... on CreateDataSourceProviderResultSuccess {
        dataSourceProvider {
          id
          availableDataSources {
            sourceName
            name
          }
        }
      }
    }
  }
`;

export interface CreateDataSourceVariables {
  dataSourceProviderId: string;
  dataSourceName: string;
  name: string;
}

export interface CreateDataSourceResultSuccess {
  dataSource: RelayNode;
  __typename?: "CreateDefaultEnvironmentDataSourceResultSuccess";
}

export interface CreateDataSourceResult {
  createDefaultEnvironmentDataSource: CreateDataSourceResultSuccess | ClientErrorResult;
}

export const CREATE_DATA_SOURCE = gql`
  mutation CreateDefaultEnvironmentDataSource(
    $dataSourceProviderId: ID!
    $dataSourceName: String!
    $name: String!
  ) {
    createDefaultEnvironmentDataSource(
      dataSourceProviderId: $dataSourceProviderId
      dataSourceName: $dataSourceName
      name: $name
    ) {
      __typename
      ... on ClientErrorResult {
        code
        message
      }
      ... on CreateDefaultEnvironmentDataSourceResultSuccess {
        dataSource {
          id
        }
      }
    }
  }
`;
