import React from "react";

import styled from "styled-components";

import { H6 } from "../../common/StyledComponents";

interface SwitchProps {
  onChange: (value: string) => void;
  name: string;
  options: Record<string, string>;
}

interface StyledSwitchProps {
  radius?: number;
  switchColor?: string;
  padding?: number;
  height?: number;
  width?: number;
}

const LabelH6 = styled(H6)`
  line-height: 38px;
`;

const Switch = styled.div<StyledSwitchProps>`
  text-align: center;

  ul {
    list-style: none;
  }

  .radio-switch__input:checked ~ label {
    color: white;

    h6 {
      color: white;
    }
  }

  .radio-switch__input {
    display: none;
  }

  .radio-switch__input:checked ~ div.radio-switch__marker {
    transform: translateX(100%);
  }
`;

const RadioSwitchLabel = styled.label<StyledSwitchProps>`
  cursor: pointer;
  display: block;
  line-height: calc(
    ${props => props.height || 46}px - 2 * ${props => props.padding || 3}px
  );
  text-align: center;
  border-radius: ${props => props.radius || 50}em;
  position: relative;
  z-index: 1;

  :not(*):focus-within,
  using-feature-detection-conditionals-and-groups-with-selectors/
    .radio-switch__input:focus
    ~ & {
    // reset focus style for browsers supporting :focus-within
    background-color: transparent;
  }
`;

const RadioSwitchList = styled.ul<StyledSwitchProps>`
  background-color: ${props => props.theme.backgroundColor};
  display: inline-flex;
  padding: ${props => props.padding || 3}px;
  border-radius: ${props => props.radius || 50}em;
  border: 1px solid ${props => props.theme.borderColor};
`;

const RadioSwitchItem = styled.li<StyledSwitchProps>`
  height: calc(${props => props.height || 46}px - 2 * ${props => props.padding || 3}px);
  width: calc(${props => props.width || 220}px*0.5 - ${props => props.padding || 3}px);
  position: relative;
`;

const RadioSwitchMarker = styled.div<StyledSwitchProps>`
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: ${props => props.switchColor || props.theme.linkColor};
  border-radius: ${props => props.radius || 50}em;
  transition: transform 0.3s;
`;

const RadioSwitch = ({ onChange, name, options, ...rest }: SwitchProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <Switch {...rest}>
      <RadioSwitchList>
        <RadioSwitchItem>
          <input
            type="radio"
            className="radio-switch__input"
            id="radio1"
            name={name}
            defaultChecked={true}
            onChange={evt => handleChange(evt)}
            value={Object.keys(options)[0]}
          />
          <RadioSwitchLabel htmlFor="radio1" className="radio-switch__label">
            <LabelH6>{options[Object.keys(options)[0]]}</LabelH6>
          </RadioSwitchLabel>
        </RadioSwitchItem>

        <RadioSwitchItem>
          <input
            type="radio"
            className="radio-switch__input"
            id="radio2"
            name={name}
            onChange={evt => handleChange(evt)}
            value={Object.keys(options)[1]}
          />
          <RadioSwitchLabel htmlFor="radio2" className="radio-switch__label">
            <LabelH6>{options[Object.keys(options)[1]]}</LabelH6>
          </RadioSwitchLabel>
          <RadioSwitchMarker className="radio-switch__marker" />
        </RadioSwitchItem>
      </RadioSwitchList>
    </Switch>
  );
};

export default RadioSwitch;
