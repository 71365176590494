import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

const QUERY = gql`
  query NewTasksCreated($queueId: ID!, $withinSeconds: Int!) {
    newTasksCreated(queueId: $queueId, withinSeconds: $withinSeconds)
  }
`;

interface Variables {
  queueId: string;
  withinSeconds: number;
}

interface Data {
  newTasksCreated: number;
}

export const useNewTasksCreated = (options?: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(QUERY, options);
