import { AttributeTypes } from "../../../../../../constants";
import {
  FunctionParameterNode,
  SpaceComponentObject,
  SpaceComponentType
} from "../../../../../../types";
import { getFieldType } from "../../../../../common/AttributeInput";
import { FieldType } from "../../../../../common/AttributeInput/constants";
import { createPath } from "../../../../../util/binding";
import { createSpaceFunction } from "../../../../FunctionExecutor/FunctionExecutor";
import { LayoutUnit, ElementLayout } from "../../../../layout/util";
import { InsertComponentPayload } from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/reducer";
import {
  ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT,
  COMPONENTS_WITHOUT_BLANK_SUPPORT,
  COMPONENTS_WITHOUT_NULL_SUPPORT,
  BlankValueType,
  DEFAULT_VALUE_OPTIONS
} from "../../../constants";
import { ComponentNode, isComponentNode } from "../../../RenderTreeContext";
import { findSpaceComponentPackage } from "../../../SpaceContext/StableSpaceContext";
import { FORM_COMPONENT_TYPES } from "../../constants";
import { valueProvider } from "../BindingCascader/BindingCascader";
import { InputParameter, InputParameterOptions, ParameterType } from "../useFuncParams";
import { InputParameterComponent } from "../useFuncParams/types";
import { toProperty } from "../util";

export const FIELD_TYPES_WITH_CONFIG_REQUIRED = [FieldType.RADIO, FieldType.DROPDOWN];

export const getDefaultComponentType = (attributeType: AttributeTypes) => {
  const componentOptions = ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[attributeType];

  return componentOptions[0];
};

export const generateInputParameters = (component: SpaceComponentObject) => {
  const fn = createSpaceFunction(component);

  const existingInputParameters = component.properties
    .input_parameters as InputParameter[];

  const newInputParameters = fn.functionParameters
    .filter(
      param =>
        param.required && !existingInputParameters.some(eip => eip.name === param.name)
    )
    .map(fp => makeInputParameter(fp, component.type));

  return existingInputParameters.concat(newInputParameters);
};

// pass in functionParameterNodes, which already has reserved list params filtered out for list functions
export const selectExcludedParameters = (
  sc: SpaceComponentObject,
  functionParameterNodes: FunctionParameterNode[]
) => {
  const allParameters = functionParameterNodes.map(node => node.name);
  const presentParameters = sc.properties.input_parameters.map(
    (v: InputParameter) => v.name
  );
  return allParameters.filter(p => !presentParameters.includes(p));
};

export const makeInputParameter = (
  functionParameter: FunctionParameterNode,
  componentType: SpaceComponentType,
  isHidden?: boolean
): InputParameter => {
  const isVisibleFormComponent =
    FORM_COMPONENT_TYPES.includes(componentType) && !isHidden;

  const supportedComponentTypes =
    ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[functionParameter.type];
  const parameterComponentType = supportedComponentTypes[0];

  const paramConfig: Partial<InputParameter> = {
    name: functionParameter.name,
    required: true, // Force users to check allow blanks manually
    blank_value_type: BlankValueType.NULL_VALUE,
    hidden: false
  };

  if (isVisibleFormComponent) {
    paramConfig.type = ParameterType.COMPONENT;
    (paramConfig as InputParameterComponent).component_type = parameterComponentType;
  } else {
    paramConfig.type = ParameterType.PENDING;
    paramConfig.field_type = getFieldType(functionParameter.type);
  }

  return paramConfig as InputParameter;
};

const ensureBooleanOptions = (param: InputParameter): InputParameterOptions[] => {
  const newRenderOptions = [];
  const trueValueOption = param.render_options?.find(f => f.value === true);
  if (trueValueOption) {
    newRenderOptions.push(trueValueOption);
  } else {
    newRenderOptions.push({ value: true, label: "True" });
  }

  const falseValueOption = param.render_options?.find(f => f.value === false);
  if (falseValueOption) {
    newRenderOptions.push(falseValueOption);
  } else {
    newRenderOptions.push({ value: false, label: "False" });
  }

  if (!param.required) {
    const nullValueOption = param.render_options?.find(f => f.value === null);
    if (nullValueOption) {
      newRenderOptions.push(nullValueOption);
    } else {
      newRenderOptions.push({ value: null, label: "NULL" });
    }
  }

  return newRenderOptions;
};

export const makeUpdatedParam = (
  param: InputParameter,
  sc: SpaceComponentObject
): InputParameter => {
  if (param.type === "value" && param.value === null) {
    param.required = false;
  }
  const func = sc.functions.edges[0]?.node;

  const functionParameter = func?.functionParameters?.edges.find(
    fp => fp.node.name === param.name
  );
  const isBoolean = !!functionParameter
    ? functionParameter.node.type === AttributeTypes.BOOL
    : false;

  if (
    !param.field_type ||
    (!!param.field_type && !FIELD_TYPES_WITH_CONFIG_REQUIRED.includes(param.field_type))
  ) {
    param.render_options = undefined;
  } else if (isBoolean) {
    param.render_options = ensureBooleanOptions(param);
  } else {
    param.render_options = Array.isArray(param.render_options)
      ? param.render_options
      : [];
  }

  return param;
};

// this utility expects to retrieve binding path from the `componentNode`
// active in the param config section, which corresponds to the FIELDSET pseudocomponent.
// currently, path is built according to the form binding path (anything leading up
// to form path), followed by `fieldset`.
export const getBindingPath = (
  componentNode: ComponentNode | undefined, // expect componentNode to be FIELDSET pseudocomponent
  nextSlug?: string,
  attribute?: string
): string | undefined => {
  if (!componentNode) return undefined;
  const paths = [];
  let currentNode: ComponentNode | undefined = componentNode;
  while (currentNode) {
    const value = valueProvider(currentNode);
    paths.unshift(value);

    const pkg = findSpaceComponentPackage(currentNode.component.type);
    currentNode =
      (pkg.isPseudoComponent && pkg.type !== "FIELDSET") ||
      !isComponentNode(currentNode.parent)
        ? undefined
        : currentNode.parent;
  }

  if (nextSlug) {
    paths.push(nextSlug);
  }
  if (attribute) {
    paths.push(attribute);
  }
  return createPath(paths);
};

export const createInsertComponentPayload = (
  componentType: SpaceComponentType,
  parentSlug: string,
  functionParameterNode: FunctionParameterNode,
  propertiesOverrides?: any
): InsertComponentPayload & {
  componentConfig: Partial<SpaceComponentObject>;
} => {
  const {
    allow_blank,
    blank_value_type,
    default_value_type,
    default_value_binding,
    default_value_template,
    default_value
  } = propertiesOverrides || {};
  // The function parameter type may differ from the validation_type in the properties
  // passed in if the function parameter has changed since the component was created.
  // Only override the default value if the function parameter type is compatible
  // with the previous default_value_type.
  const shouldSetDefaultValue =
    default_value_type &&
    DEFAULT_VALUE_OPTIONS[functionParameterNode.type].includes(default_value_type);
  return {
    componentType,
    parentSlug: parentSlug,
    componentConfig: {
      name: functionParameterNode.name,
      properties: {
        validation_type: toProperty(functionParameterNode.type),
        allow_blank: COMPONENTS_WITHOUT_BLANK_SUPPORT.includes(componentType)
          ? undefined
          : allow_blank || false,
        blank_value_type: COMPONENTS_WITHOUT_NULL_SUPPORT.includes(componentType)
          ? undefined
          : blank_value_type || BlankValueType.NULL_VALUE,
        default_value_type: shouldSetDefaultValue ? default_value_type : undefined,
        default_value_binding: shouldSetDefaultValue
          ? default_value_binding
          : undefined,
        default_value_template: shouldSetDefaultValue
          ? default_value_template
          : undefined,
        default_value: shouldSetDefaultValue ? default_value : undefined
      },
      layout: {
        width: LayoutUnit.AUTO,
        height: LayoutUnit.AUTO
      } as ElementLayout
    }
  };
};
