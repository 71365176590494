import React from "react";

import useElementTransformer from "../../../../layout/Element/useElementTransformer";
import {
  OverflowOption,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  PositionOption,
  LayoutUnit
} from "../../../../layout/util";
import { useComponentContext } from "../../contexts/ComponentContext";
import * as styled from "../ConfigPanel/styledComponents";

import { BoxLayoutValueField, styledInputs } from "./Inputs";

export default function DesignConfig() {
  const {
    pkg: { elementLayoutOptions = DEFAULT_ELEMENT_LAYOUT_OPTIONS }
  } = useComponentContext();
  const { layout, transform, changeLayoutUnit } = useElementTransformer();

  if (!layout) return null;

  return (
    <styled.Section title="Layout">
      {layout.position === PositionOption.ABSOLUTE && (
        <>
          {elementLayoutOptions.left !== null && (
            <BoxLayoutValueField
              label="Left"
              value={layout.left}
              onChange={value => {
                transform({ left: value, snapToGrid: false });
              }}
              onChangeUnit={value => {
                changeLayoutUnit("left", value);
              }}
            />
          )}

          {elementLayoutOptions.top !== null && (
            <BoxLayoutValueField
              label="Top"
              value={layout.top}
              onChange={value => {
                transform({ top: value, snapToGrid: false });
              }}
              onChangeUnit={value => {
                changeLayoutUnit("top", value);
              }}
            />
          )}
        </>
      )}

      {elementLayoutOptions.width !== null && (
        <BoxLayoutValueField
          label="Width"
          value={layout.width}
          extraUnitOptions={
            elementLayoutOptions.width?.includes(LayoutUnit.AUTO)
              ? [
                  {
                    name: "auto",
                    value: LayoutUnit.AUTO,
                    hasNumericComponent: false
                  }
                ]
              : []
          }
          onChange={value => {
            transform({ width: value, snapToGrid: false });
          }}
          onChangeUnit={value => {
            changeLayoutUnit("width", value);
          }}
        />
      )}

      {elementLayoutOptions.height !== null && (
        <BoxLayoutValueField
          label="Height"
          value={layout.height}
          extraUnitOptions={
            elementLayoutOptions.height?.includes(LayoutUnit.AUTO)
              ? [
                  {
                    name: "auto",
                    value: LayoutUnit.AUTO,
                    hasNumericComponent: false
                  }
                ]
              : []
          }
          onChange={value => {
            transform({ height: value, snapToGrid: false });
          }}
          onChangeUnit={value => {
            changeLayoutUnit("height", value);
          }}
        />
      )}

      {elementLayoutOptions.overflow.length > 1 && (
        <styledInputs.Field>
          <styledInputs.Label>Overflow</styledInputs.Label>
          <styled.Select
            size="small"
            placeholder="Select overflow"
            value={layout.overflow}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            style={{ width: "145px" }}
            onChange={val => {
              transform({ overflow: val as OverflowOption });
            }}
          >
            {elementLayoutOptions.overflow.map(o => (
              <styled.SelectOption value={o} key={o}>
                {o}
              </styled.SelectOption>
            ))}
          </styled.Select>
        </styledInputs.Field>
      )}

      {layout.position === PositionOption.ABSOLUTE && (
        <styledInputs.Field>
          <styledInputs.Label>Snap to Grid</styledInputs.Label>
          <styled.Checkbox
            checked={layout.snapToGrid}
            onChange={evt => {
              transform({ snapToGrid: evt.target.checked });
            }}
          />
        </styledInputs.Field>
      )}
    </styled.Section>
  );
}
