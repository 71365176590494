import { Modal } from "antd";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  width: 70% !important;
  min-width: 650px;
  max-width: 950px;

  .ant-modal-header {
    padding: 32px 32px 6px 32px;
    border: none;
    line-height: 16px;

    i {
      margin-right: 16px;
      color: ${props => props.theme.primaryColor};
    }
  }

  .ant-modal-body {
    padding: 6px 32px 6px 32px;
    min-height: 350px;
    margin-left: 29px;

    a.syncModal__externalLink {
      i {
        visibility: hidden;
      }
    }

    a.syncModal__externalLink:hover {
      i {
        visibility: visible;
      }
    }

    div.syncModal__messageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
      div.message {
        text-align: center;
        width: 433px;
        height: 56px;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .ant-modal-footer {
    padding: 6px 32px 32px 32px;
    border: none;
  }

  .hidden {
    visibility: hidden;
  }
`;

export const TableContainer = styled.div`
  /* This min height is set so that while the table height changes dynamically depending on rows that wrap */
  /* it will prevent changes in the overall height of the modal */
  min-height: 440px;
`;
