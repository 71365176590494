import { AttributeTypes } from "../../../../../../../constants";
import {
  AttributeNodeTypes,
  SpaceComponentType,
  SpaceComponentPackage
} from "../../../../../../../types";
import { ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT } from "../../../../constants";
import { InputParameter } from "../../useFuncParams/types";

const SELECT_OPTIONS: {
  [key: string]: { form: string; button: string };
} = {
  value: {
    form: "Set a custom default value.",
    button: "Set a custom default value."
  },
  file: {
    form: "Use a value from a CSV or TSV.",
    button: "Use a value from a CSV or TSV."
  },
  binding: {
    form: "Pre-fill with a value from a component.",
    button: "Use a value from a component."
  },
  uuid: {
    form: "Set a UUID.",
    button: "Set a UUID."
  },
  time_now: {
    form: "Set time the form is submitted.",
    button: "Set time the button is clicked."
  },
  datetime_now: {
    form: "Set date and time the form is submitted.",
    button: "Set date and time the button is clicked."
  },
  date_today: {
    form: "Set date the form is submitted.",
    button: "Set date the button is clicked."
  },
  input: {
    form: "User completes the field.",
    button: ""
  },
  component: {
    form: "User completes the field.",
    button: ""
  },
  null: {
    form: "Set default to null.",
    button: "Set to null."
  }
};

export const getDisplayOptions = (
  type: AttributeNodeTypes,
  allowUserInput: boolean,
  allowFileInput: boolean,
  requiredByFunction: boolean
) => {
  const baseKeys = allowUserInput ? [] : ["value"];
  const copyType = allowUserInput ? "form" : "button";
  const displayNames = baseKeys.map((key: string) => [
    key,
    SELECT_OPTIONS[key][copyType]
  ]);
  if (allowUserInput) {
    // if component type is supported, add component option
    const supportedTypes = ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[type];
    if (supportedTypes.length) {
      displayNames.splice(0, 0, ["component", SELECT_OPTIONS["component"][copyType]]);
    }
  } else {
    displayNames.splice(0, 0, ["binding", SELECT_OPTIONS["binding"][copyType]]);
    if (allowFileInput) {
      displayNames.splice(0, 0, ["file", SELECT_OPTIONS["file"][copyType]]);
    }
    if (type === AttributeTypes.STRING) {
      displayNames.push(["uuid", SELECT_OPTIONS["uuid"][copyType]]);
    }
  }

  if (type === AttributeTypes.DATE || type === AttributeTypes.STRING) {
    displayNames.push(["date_today", SELECT_OPTIONS["date_today"][copyType]]);
  }

  if (
    type === AttributeTypes.DATETIME ||
    type === AttributeTypes.TIMESTAMP ||
    type === AttributeTypes.STRING
  ) {
    displayNames.push(["datetime_now", SELECT_OPTIONS["datetime_now"][copyType]]);
  }

  if (type === AttributeTypes.TIME || type === AttributeTypes.STRING) {
    displayNames.push(["time_now", SELECT_OPTIONS["time_now"][copyType]]);
  }

  if (!allowUserInput && !requiredByFunction) {
    displayNames.push(["null", SELECT_OPTIONS["null"][copyType]]);
  }

  return displayNames;
};

export const getCurrentSource = (
  parameter: InputParameter,
  requiredByFunction: boolean
) => {
  switch (parameter.type) {
    case "none":
      return undefined;
    case "value":
      return parameter.value === null && !requiredByFunction ? "null" : "value";

    default:
      return parameter.type;
  }
};

export const getSupportedComponentTypes = (
  parentComponentType: SpaceComponentType,
  type: AttributeTypes,
  spaceComponentPackages: SpaceComponentPackage[]
) => {
  const hostableChildComponentTypes = spaceComponentPackages
    .filter(p => p.allowedHosts.has(parentComponentType))
    .map(p => p.type);

  return ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[type].filter(type => {
    return hostableChildComponentTypes.includes(type);
  });
};
