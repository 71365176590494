import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import AggregationFunctionSelector from "../../common/AggregationFunctionSelector";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import {
  useComponentConfigContext,
  ComponentConfigContextContainer
} from "../../common/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import { Field } from "../../common/ConfigPanel/styledComponents";
import FilterListManagerSection from "../../common/FilterListManager";
import NameFields from "../../common/NameFields";
import ParametersConfigSection from "../../common/ParametersManager";
import SourceSelector from "../../common/SourceSelector";
import useViewConfig from "../../common/useViewConfig";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import DesignConfig from "./DesignConfig";
import { ensureStatConfigState } from "./reducer";

const MemoDataConfig = React.memo(SpaceStatConfigContent);
const MemoDesignConfig = React.memo(DesignConfig);
export default function SpaceStatConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<MemoDataConfig />}
      designConfig={<MemoDesignConfig />}
      slug={slug}
    />
  );
}

function SpaceStatConfigContent() {
  const context = useComponentConfigContext();
  useViewConfig(context);
  const state = ensureStatConfigState(context.state);
  const { draftComponent } = state;

  return (
    <>
      <ConfigSection title="Source">
        <Field>
          <SourceSelector />
        </Field>
      </ConfigSection>
      <BaseComponentConfigSection>
        <NameFields labelPlaceholder="Hint text" shouldRenderLabel />
      </BaseComponentConfigSection>
      <ConfigSection title="Stat Details">
        <label>How should your data be calculated?</label>
        <AggregationFunctionSelector
          type={draftComponent.properties.aggregation_function.type}
          attribute={draftComponent.properties.aggregation_function.attribute}
          attributes={state.attributes || []}
          onChangeFunctionType={type => {
            context.dispatch({
              type: "MERGE_DRAFT_COMPONENT",
              payload: {
                change: {
                  properties: {
                    aggregation_function: { type }
                  }
                }
              }
            });
          }}
          onChangeAttribute={attribute =>
            context.dispatch({
              type: "MERGE_DRAFT_COMPONENT",
              payload: {
                change: {
                  properties: {
                    aggregation_function: { attribute }
                  }
                }
              }
            })
          }
        />
      </ConfigSection>
      <ParametersConfigSection title="Parameters" />
      <FilterListManagerSection title="Filters" filtersOptions={state.filtersOptions} />
      <VisibilityRulesManagerSection />
    </>
  );
}
