import gql from "graphql-tag";

import { ObjectSchema } from "../CredentialsForm/schema";

export interface FetchCredentialsSchemaVariables {
  integration: string;
}

export interface CredentialsSchemaData {
  credentialsSchema: ObjectSchema;
}

export const FETCH_CREDENTIALS_SCHEMA = gql`
  query FetchCredentialsSchema($integration: String!) {
    credentialsSchema(integration: $integration)
  }
`;
