import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import { SpaceFlexBoxComponent } from "../types";

export interface FlexBoxConfigState
  extends BaseComponentConfigState<SpaceFlexBoxComponent> {
  type: "FLEX_BOX";
}

export const INITIAL_STATE = {
  type: "FLEX_BOX" as const,
  draftComponent: {} as SpaceFlexBoxComponent
};

function isFlexBoxConfigState(
  state: ComponentConfigState
): state is FlexBoxConfigState {
  return state.type === "FLEX_BOX";
}

export function ensureFlexBoxConfigState(
  state: ComponentConfigState
): FlexBoxConfigState {
  if (isFlexBoxConfigState(state)) return state;

  throw new Error("Expected Flex Box config state.");
}

export function ensureSpaceFlexBoxComponent(
  component: SpaceComponentObject
): SpaceFlexBoxComponent {
  if (Array.isArray(component.properties.stats)) return component;
  return {
    ...component,
    properties: {
      stats: [],
      style: {},
      ...component.properties
    }
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): FlexBoxConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceFlexBoxComponent(cloneDeep(draftComponent))
  };
}

function reducer(
  state: FlexBoxConfigState,
  action: SpaceConfigAction
): FlexBoxConfigState {
  switch (action.type) {
    default:
      return state;
  }
}

export default (state: FlexBoxConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(reducer, partial.placeholder, action)])(
    state,
    action
  );
