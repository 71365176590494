import { cloneDeep, flow, partial } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import { SpaceComponentObject } from "../../../../../../types";
import { commonComponentReducer } from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import {
  BaseComponentConfigState,
  ComponentConfigState,
  SpaceConfigAction
} from "../../../../types";
import { DefaultValueType } from "../../../constants";
import inputReducer from "../../common/inputReducer";
import { toProperty } from "../../common/util";
import { hasInputComponentProperties } from "../../util/util";
import { SpaceCheckboxComponent, SpaceCheckboxProperties } from "../types";

export interface CheckboxConfigState
  extends BaseComponentConfigState<SpaceCheckboxComponent> {
  type: "CHECKBOX";
}

export const INITIAL_STATE = {
  type: "CHECKBOX" as const,
  draftComponent: {} as SpaceCheckboxComponent
};

function isCheckboxConfigState(
  state: ComponentConfigState
): state is CheckboxConfigState {
  return state.type === "CHECKBOX";
}

export function ensureCheckboxConfigState(
  state: ComponentConfigState
): CheckboxConfigState {
  if (isCheckboxConfigState(state)) return state;

  throw new Error("Expected Checkbox config state.");
}

export const getComponentProperties = (
  properties: Partial<SpaceCheckboxProperties>
) => {
  const props = {
    ...properties,
    default_value_type: properties.default_value_type || DefaultValueType.BOOLEAN_VALUE,
    default_value: properties.default_value || false,
    validation_type:
      properties.validation_type || (toProperty(AttributeTypes.BOOL) as string),
    validation_rules: properties.validation_rules || []
  };
  // clear input component properties that are irrelevant for CHECKBOX
  if ("allow_blank" in props || "blank_value_type" in props) {
    delete props.allow_blank;
    delete props.blank_value_type;
  }
  return props;
};

export function ensureSpaceCheckboxComponent(
  component: SpaceComponentObject
): SpaceCheckboxComponent {
  if (!!component.type && hasInputComponentProperties(component)) {
    return component;
  }

  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): CheckboxConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceCheckboxComponent(cloneDeep(draftComponent))
  };
}

export default (state: CheckboxConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(inputReducer, partial.placeholder, action)])(
    state,
    action
  );
