import {
  SpaceConfigState as _SpaceConfigState,
  SpaceConfigDispatch as _SpaceConfigDispatch
} from "./reducer";
export { default, createBaseComponentConfigState, INITIAL_STATE } from "./reducer";
export { default as commonComponentReducer } from "./componentReducer";
export {
  ensureSlug,
  makeComponent,
  selectAllSlugs,
  getSpaceErrors,
  getFirstSpaceError,
  selectSpaceComponentTree
} from "./util";

export type SpaceConfigState = _SpaceConfigState;
export type SpaceConfigDispatch = _SpaceConfigDispatch;
