import { Dispatch as BaseDispatch } from "react";

import {
  AuthorizationFlowNode,
  AuthorizationFlowStepType,
  FunctionNode
} from "../../../../types";
import { assertNever } from "../../../util/assertNever";

export enum OAuth2GrantType {
  AUTHORIZATION_CODE = "authorization_code",
  CLIENT_CREDENTIALS = "client_credentials"
}

export enum OAuth2ClientAuthenticationTypes {
  BASIC = "client_secret_basic",
  POST = "client_secret_post",
  NONE = "none"
}

export interface OAuth2StepOptions {
  auth_uri: string;
  token_uri: string;
  client_id: string;
  client_secret: string;
  client_authentication: OAuth2ClientAuthenticationTypes;
  grant_type: OAuth2GrantType;
  scopes: string[];
}

export enum InputFieldType {
  TEXT = "text",
  PASSWORD = "password"
}

export interface InputField {
  name: string;
  label: string;
  type: InputFieldType;
  required: boolean;
}

export interface InputStepOptions {
  fields?: InputField[]; // temporary while fields -> input_fields migration is in place
  input_fields: InputField[];
}

export interface Expression {
  name: string;
  value: string;
}

export interface ExpressionStepOptions {
  expressions: Expression[];
  expiration: string | undefined;
  persist: boolean;
}

export interface Parameter {
  name: string;
  value: string;
}

export interface ExecuteFunctionStepOptions {
  parameters: Parameter[];
}

export interface CredentialHeader {
  name: string;
  value: string;
}

export interface CredentialStepOptions {
  adapter: "http";
  headers: CredentialHeader[];
  expiration: string | undefined;
}

export type AuthFlowStepOptions =
  | {}
  | OAuth2StepOptions
  | InputStepOptions
  | ExpressionStepOptions
  | ExecuteFunctionStepOptions
  | CredentialStepOptions;

export interface BasePendingAuthFlowStep {
  id?: string;
  slug?: string;
  function: Pick<FunctionNode, "id"> | null;
  order: number;
}

export interface PendingStep extends BasePendingAuthFlowStep {
  type: null;
  options: {};
}

export interface PendingOAuth2Step extends BasePendingAuthFlowStep {
  type: AuthorizationFlowStepType.OAUTH2;
  options: OAuth2StepOptions;
}

export interface PendingInputStep extends BasePendingAuthFlowStep {
  type: AuthorizationFlowStepType.INPUT;
  options: InputStepOptions;
}

export interface PendingExpressionStep extends BasePendingAuthFlowStep {
  type: AuthorizationFlowStepType.EXPRESSION;
  expires: boolean;
  options: ExpressionStepOptions;
}

export interface PendingExecuteFunctionStep extends BasePendingAuthFlowStep {
  type: AuthorizationFlowStepType.EXECUTEFUNCTION;
  options: ExecuteFunctionStepOptions;
}

export interface PendingCredentialStep extends BasePendingAuthFlowStep {
  type: AuthorizationFlowStepType.CREDENTIAL;
  expires: boolean;
  options: CredentialStepOptions;
}

export type PendingAuthFlowStep =
  | PendingStep
  | PendingOAuth2Step
  | PendingInputStep
  | PendingExpressionStep
  | PendingExecuteFunctionStep
  | PendingCredentialStep;

export interface State {
  name: string;
  steps: PendingAuthFlowStep[];
  errors: any;
  touched: boolean;
}

export const getEmptyState = (): State => ({
  name: "",
  steps: [],
  errors: {},
  touched: false
});

export const getInitialState = (flow: AuthorizationFlowNode): State => {
  const steps =
    flow.authSteps?.edges?.map<PendingAuthFlowStep>(e => ({
      ...e.node,
      function: e.node.function || null,
      expires: !!e.node.options.expiration
    })) || [];
  if (
    !steps.length ||
    steps[steps.length - 1].type !== AuthorizationFlowStepType.CREDENTIAL
  ) {
    const credential: PendingCredentialStep = {
      expires: false,
      function: null,
      options: { adapter: "http", expiration: undefined, headers: [] },
      order: steps.length,
      type: AuthorizationFlowStepType.CREDENTIAL
    };
    steps.push(credential);
  }
  return {
    ...getEmptyState(),
    name: flow.name,
    steps
  };
};

export enum ActionType {
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_ERRORS = "UPDATE_ERRORS",
  ADD_STEP = "ADD_STEP",
  REMOVE_STEP = "REMOVE_STEP",
  UPDATE_STEP_EXPIRATION_ENABLED = "UPDATE_STEP_EXPIRATION_ENABLED",
  UPDATE_STEP_OPTIONS = "UPDATE_STEP_OPTIONS",
  UPDATE_STEP_FUNCTION = "UPDATE_STEP_FUNCTION",
  UPDATE_STEP_TYPE = "UPDATE_STEP_TYPE",
  REORDER_STEP = "REORDER_STEP",
  RESET_TOUCHED = "RESET_TOUCHED"
}

interface UpdateName {
  type: ActionType.UPDATE_NAME;
  payload: { name: string };
}

interface UpdateErrors {
  type: ActionType.UPDATE_ERRORS;
  payload: { errors: any };
}

interface AddStep {
  type: ActionType.ADD_STEP;
}

interface RemoveStep {
  type: ActionType.REMOVE_STEP;
  payload: { step: number };
}

interface UpdateStepExpirationEnabled {
  type: ActionType.UPDATE_STEP_EXPIRATION_ENABLED;
  payload: { step: number; expires: boolean };
}

interface UpdateStepOptions {
  type: ActionType.UPDATE_STEP_OPTIONS;
  payload: { step: number; options: AuthFlowStepOptions };
}

interface UpdateStepFunction {
  type: ActionType.UPDATE_STEP_FUNCTION;
  payload: { step: number; functionId: string | null };
}

interface UpdateStepType {
  type: ActionType.UPDATE_STEP_TYPE;
  payload: { step: number; type: AuthorizationFlowStepType | null };
}

interface ReorderStep {
  type: ActionType.REORDER_STEP;
  payload: { startIndex: number; endIndex: number };
}

interface ResetTouched {
  type: ActionType.RESET_TOUCHED;
}

type Action =
  | UpdateName
  | UpdateErrors
  | AddStep
  | RemoveStep
  | UpdateStepExpirationEnabled
  | UpdateStepOptions
  | UpdateStepFunction
  | UpdateStepType
  | ReorderStep
  | ResetTouched;

export type Dispatch = BaseDispatch<Action>;

const getDefaultOptions = (
  type: AuthorizationFlowStepType | null
): AuthFlowStepOptions => {
  switch (type) {
    case AuthorizationFlowStepType.INPUT:
      return { input_fields: [] } as InputStepOptions;
    case AuthorizationFlowStepType.OAUTH2:
      return {
        auth_uri: "",
        token_uri: "",
        client_id: "",
        client_secret: "",
        client_authentication: OAuth2ClientAuthenticationTypes.BASIC,
        grant_type: OAuth2GrantType.AUTHORIZATION_CODE,
        scopes: []
      } as OAuth2StepOptions;
    case AuthorizationFlowStepType.EXECUTEFUNCTION:
      return { parameters: [] } as ExecuteFunctionStepOptions;
    case AuthorizationFlowStepType.EXPRESSION:
      return {
        expressions: [],
        expiration: undefined,
        persist: false
      } as ExpressionStepOptions;
    case null:
      return {};
    default:
      throw new Error("unexpected step type");
  }
};

export default function reducer(_state: State, action: Action): State {
  const state = { ..._state, touched: true };
  switch (action.type) {
    case ActionType.UPDATE_NAME: {
      const { name } = action.payload;
      return { ...state, name: name };
    }
    case ActionType.UPDATE_ERRORS: {
      const { errors } = action.payload;
      return { ...state, errors: errors };
    }
    case ActionType.ADD_STEP: {
      const steps = state.steps.concat();
      const credential = steps.pop();
      if (!credential || credential.type !== AuthorizationFlowStepType.CREDENTIAL) {
        throw new Error("last step must be a credential step");
      }
      credential.order = steps.length + 1;
      return {
        ...state,
        steps: [
          ...steps,
          {
            type: null,
            slug: `step${steps.length}`,
            order: steps.length,
            options: {},
            function: null
          },
          credential
        ]
      };
    }
    case ActionType.REMOVE_STEP: {
      const { step } = action.payload;
      return {
        ...state,
        steps: state.steps
          .filter((_, idx) => idx !== step)
          .map((s, idx) => ({ ...s, order: idx }))
      };
    }
    case ActionType.UPDATE_STEP_EXPIRATION_ENABLED: {
      const { step: order, expires } = action.payload;
      const steps = [...state.steps];
      const step = steps[order];
      if (
        step.type !== AuthorizationFlowStepType.EXPRESSION &&
        step.type !== AuthorizationFlowStepType.CREDENTIAL
      ) {
        throw new Error("step cannot expire");
      }
      step.expires = expires;
      return { ...state, steps };
    }
    case ActionType.UPDATE_STEP_OPTIONS: {
      const { step, options } = action.payload;
      const steps = [...state.steps];
      steps[step].options = { ...options } as AuthFlowStepOptions;
      return { ...state, steps: steps };
    }
    case ActionType.UPDATE_STEP_FUNCTION: {
      const { step, functionId } = action.payload;
      const steps = [...state.steps];
      if (functionId) {
        steps[step].function = { id: functionId };
      } else {
        steps[step].function = null;
      }
      steps[step].options = { ...steps[step].options, parameters: [] };
      return { ...state, steps: steps };
    }
    case ActionType.UPDATE_STEP_TYPE: {
      const { step, type } = action.payload;
      const steps = [...state.steps];
      steps[step].type = type;
      steps[step].options = getDefaultOptions(type);
      return { ...state, steps: steps };
    }
    case ActionType.REORDER_STEP: {
      const { startIndex, endIndex } = action.payload;
      const result = [...state.steps];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return {
        ...state,
        steps: result.map((s, idx) => ({ ...s, order: idx }))
      };
    }
    case ActionType.RESET_TOUCHED: {
      return { ...state, touched: false };
    }
    default:
      return assertNever(action);
  }
}
