import { useMutation } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import { FetchResult } from "apollo-link";
import gql from "graphql-tag";

import { BulkExecuteFunctionResult } from "../../../../../../types";

export const EXECUTE_FUNCTION = gql`
  mutation BulkExecuteFunction(
    $functionId: ID!
    $spaceId: ID!
    $parameters: [GenericScalar]!
  ) {
    bulkExecuteFunction(
      functionId: $functionId
      spaceId: $spaceId
      parameters: $parameters
    ) {
      __typename
      ... on PermissionErrorResult {
        message
      }
      ... on ClientErrorResult {
        code
        message
        errorInfo {
          reason
          domain
          metadata
        }
      }
      ... on ValidationErrorResult {
        messages {
          text
          index
        }
      }
      ... on ExecuteFunctionResultSuccess {
        value
      }
    }
  }
`;

export interface BulkFunctionCallResult {
  bulkExecuteFunction: BulkExecuteFunctionResult[];
}

export interface Result {
  bulkExecuteFunction: (
    parameters: any[],
    onComplete?: (data: BulkFunctionCallResult) => void,
    onError?: (err: Error) => void
  ) => Promise<FetchResult<BulkFunctionCallResult>>;
  mutationLoading: boolean;
  mutationError: ApolloError | undefined;
}

export default function useBulkSpaceFunction(
  functionId: string,
  spaceId: string
): Result {
  const [submitMutation, { loading: mutationLoading, error: mutationError }] =
    useMutation<BulkFunctionCallResult>(EXECUTE_FUNCTION);
  const bulkExecuteFunction = (
    parameters: any[],
    onComplete?: (data: BulkFunctionCallResult) => void,
    onError?: (err: Error) => void
  ) => {
    return submitMutation({
      variables: {
        functionId,
        spaceId,
        parameters
      }
    }).then(res => {
      if (res?.data) {
        onComplete?.(res?.data || []);
      } else {
        onError?.(new Error("Expected response data."));
      }
    });
  };

  return { bulkExecuteFunction, mutationLoading, mutationError };
}
