import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Col, Form, Input, Row } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import { FormComponentProps } from "antd/lib/form";
import gql from "graphql-tag";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../constants";
import { publicClient } from "../../graphql";
import { setTrackingUserId } from "../../logging";
import Button from "../common/Button";
import { AUTH_STATUS } from "../common/hooks/useAuthStatus";
import usePaths from "../common/hooks/usePaths";
import { LogoColor } from "../common/Logo";
import Message from "../common/Message";
import { DarkTheme, FormItem } from "../common/StyledComponents";
import TitleContainer from "../common/TitleContainer";
import { VALIDATION_MESSAGES } from "../util/ClientValidator";

const GET_IS_INVITE_VALID = gql`
  query GetIsInviteValid($inviteId: String!, $key: String!) {
    isInviteValid(inviteId: $inviteId, key: $key)
  }
`;

const ACCEPT_INVITE_MUTATION = gql`
  mutation AcceptOrganizationInvite(
    $inviteId: String!
    $key: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    acceptOrganizationInvite(
      inviteId: $inviteId
      key: $key
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      user {
        id
        email
        organization {
          id
        }
      }
      error
    }
  }
`;

const FormContainer = styled.div`
  width: 380px;
  max-width: 100%;
  margin-top: ${props => props.theme.spacerxl};
  margin-left: auto;
  margin-right: auto;
`;

const TOS = styled.div`
  font-size: ${props => props.theme.smallFontSize};
  line-height: 1.83;
  margin-bottom: ${props => props.theme.spacerlg};
`;

const FormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledPasswordInput = styled(PasswordInput)`
  & input:hover {
    border-color: ${props => props.theme.primaryColor} !important;
  }
`;

interface AcceptInviteVariables {
  inviteId: string;
  key: string;
  password: string;
  firstName: string;
  lastName: string;
}

interface AcceptInviteParams extends Record<string, string> {
  inviteId: string;
  key: string;
}

type Props = FormComponentProps;

const AcceptInviteForm = (props: Props) => {
  const { inviteId, key } = useParams<AcceptInviteParams>();
  const navigate = useNavigate();
  const { getFieldDecorator } = props.form;
  const { getDashboard } = usePaths();
  const [acceptInvite, { loading: submitting }] = useMutation(ACCEPT_INVITE_MUTATION, {
    client: publicClient,
    refetchQueries: [{ query: AUTH_STATUS }],
    awaitRefetchQueries: true,
    onCompleted: data => {
      const { user, error } = data.acceptOrganizationInvite;
      if (user) {
        setTrackingUserId(user.id);
        navigate(getDashboard());
      } else {
        Message.error(error || "An unknown error occurred, please try again.");
      }
    }
  });

  const { loading, data, error } = useQuery(GET_IS_INVITE_VALID, {
    client: publicClient,
    variables: { inviteId, key }
  });
  if (loading) {
    return null;
  }
  if (error) {
    Message.error(
      "An error occurred while validating your invitation, please try again."
    );
    return null;
  }

  if (!data.isInviteValid) {
    Message.error("This invitation has expired, please try again.");
    navigate(Routes.SIGNUP);
    return null;
  }

  const validateConfirmPassword = (
    rule: any,
    value: string,
    callback: (msg?: string) => void
  ) => {
    const form = props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback(VALIDATION_MESSAGES.confirmPassword);
    } else {
      callback();
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }

      const variables: AcceptInviteVariables = {
        inviteId: inviteId!,
        key: key!,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName
      };
      acceptInvite({ variables });
    });
  };

  return (
    <React.Fragment>
      <DarkTheme />

      <TitleContainer
        useLogoAsTitle
        logoColor={LogoColor.White}
        title="Internal"
        description="Welcome to Internal. Let's set up your account."
      >
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <Row gutter={8}>
              <Col span={12}>
                <FormItem>
                  {getFieldDecorator("firstName", {
                    validateTrigger: "onSubmit",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: VALIDATION_MESSAGES.requiredField
                      }
                    ]
                  })(
                    <Input
                      placeholder="First name"
                      autoComplete="given-name"
                      disabled={submitting}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem>
                  {getFieldDecorator("lastName", {
                    validateTrigger: "onSubmit",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: VALIDATION_MESSAGES.requiredField
                      }
                    ]
                  })(
                    <Input
                      placeholder="Last name"
                      autoComplete="family-name"
                      disabled={submitting}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem>
                  {getFieldDecorator("password", {
                    validateTrigger: "onSubmit",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: VALIDATION_MESSAGES.requiredField
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters"
                      }
                    ]
                  })(
                    <StyledPasswordInput
                      inputProps={{
                        placeholder: "Password",
                        type: "password",
                        disabled: submitting,
                        autoComplete: "new-password"
                      }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem>
                  {getFieldDecorator("confirmPassword", {
                    validateTrigger: "onSubmit",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: VALIDATION_MESSAGES.requiredField
                      },
                      { validator: validateConfirmPassword }
                    ]
                  })(
                    <Input
                      placeholder="Confirm password"
                      type="password"
                      disabled={submitting}
                      autoComplete="new-password"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <TOS>
              By clicking “Next” you agree to our{" "}
              <a href="/tos" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>
              .
            </TOS>

            <FormFooter>
              <Button htmlType="submit" type="primary" loading={submitting}>
                Next
              </Button>
            </FormFooter>
          </Form>
        </FormContainer>
      </TitleContainer>
    </React.Fragment>
  );
};

export default Form.create<FormComponentProps>({
  name: "acceptInviteForm"
})(AcceptInviteForm);
