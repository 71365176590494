import gql from "graphql-tag";
import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery
} from "react-apollo";

import { Connection } from "../../../../../../types";
import { CommentNode, CommentPermissionDenied, CommentSuccessResult } from "../queries";

export const FETCH_TASK_COMMENTS = gql`
  query TaskComments(
    $taskId: ID!
    $orderBy: String
    $first: Int
    $before: String
    $beforeComment: ID
    $afterComment: ID
    $after: String
  ) {
    allComments(
      taskId: $taskId
      orderBy: $orderBy
      first: $first
      before: $before
      beforeComment: $beforeComment
      afterComment: $afterComment
      after: $after
    ) {
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          edited
          content
          author {
            id
            name
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export interface CommentData {
  allComments: Connection<CommentNode> & { totalCount: number };
}

export interface CommentVariables {
  taskId: string;
  orderBy?: "createdAt" | "-createdAt" | "updatedAt" | "-updatedAt";
  first: number;
  after?: string;
  before?: string;
  beforeComment?: string;
  afterComment?: string;
}

export const useTaskComments = (
  options?: QueryHookOptions<CommentData, CommentVariables>
) => {
  return useQuery<CommentData, CommentVariables>(FETCH_TASK_COMMENTS, options);
};

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id) {
      ... on CommentSuccessResult {
        message
      }
      ... on CommentErrorObjectNotFound {
        message
      }
    }
  }
`;

export interface DeleteData {
  result: CommentSuccessResult | CommentPermissionDenied;
}

export interface DeleteVariables {
  id: string;
}

export const useDeleteComment = (
  options?: MutationHookOptions<DeleteData, DeleteVariables>
) => {
  return useMutation<DeleteData, DeleteVariables>(DELETE_COMMENT, options);
};
