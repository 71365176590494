import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "react-apollo";

export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      ok
    }
  }
`;

export interface Data {
  deleteTask: {
    ok: boolean;
  };
}

export interface Variables {
  id: string;
}

export const useDeleteTask = (options?: MutationHookOptions<Data, Variables>) => {
  return useMutation<Data, Variables>(DELETE_TASK, options);
};
