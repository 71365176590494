import { AttributeTypes } from "../../constants";

export const QUEUE_ATTRIBUTE_TYPES = [
  AttributeTypes.BOOL,
  AttributeTypes.DATE,
  AttributeTypes.DATETIME,
  AttributeTypes.DECIMAL,
  AttributeTypes.FLOAT,
  AttributeTypes.INT,
  AttributeTypes.JSON,
  AttributeTypes.TIME,
  AttributeTypes.STRING,
  AttributeTypes.TIMESTAMP
];
