import React from "react";

import {
  BindingCascader as CommonBindingCascader,
  Props as CommonBindingCascaderProps
} from "../../../../../common/BindingCascader";
import useComponentNode from "../../../../util/useComponentNode";
import { ComponentNode } from "../../../RenderTreeContext";
import {
  findSpaceComponentPackage as findPackage,
  getSpaceComponentDisplayName as getDisplayName
} from "../../../SpaceContext/StableSpaceContext";
import { useSpaceConfigPanelContext } from "../ConfigPanel/ConfigPanelContext";

import { getOptions } from "./util";

type Props = Omit<CommonBindingCascaderProps, "options">;

export const labelProvider = (node: ComponentNode, isAncestor: boolean) =>
  isAncestor
    ? `This ${findPackage(node.component.type).displayName}`
    : getDisplayName(node.component);

export const valueProvider = (node: ComponentNode) => {
  return findPackage(node.component.type).isPseudoComponent
    ? node.component.type.toLowerCase()
    : node.component.slug;
};

export function BindingCascader(props: Props) {
  const { generateGetPopupContainer } = useSpaceConfigPanelContext();
  const pivot = useComponentNode();
  const options = getOptions(
    pivot,
    (node: ComponentNode) => findPackage(node.component.type),
    labelProvider,
    valueProvider
  );
  return (
    <CommonBindingCascader
      {...props}
      options={options}
      popupContainerFactory={generateGetPopupContainer}
    />
  );
}
