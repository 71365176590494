import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { AttributeTypes, ReturnSchema } from "../../../constants";
import { Connection, RelayNode } from "../../../types";

export interface FunctionParameterNode extends RelayNode {
  name: string;
  type: AttributeTypes;
  required: boolean;
}

export interface FunctionNode extends RelayNode {
  id: string;
  name: string;
  returnSchema: ReturnSchema;
  title: string;
  functionParameters: Connection<FunctionParameterNode>;
}

interface Data {
  node: FunctionNode;
}

interface Variables {
  id: string;
}

const QUERY = gql`
  query GetFunctionById($id: ID!) {
    node(id: $id) {
      ... on FunctionNode {
        id
        name
        returnSchema
        title
        functionParameters {
          edges {
            node {
              id
              name
              type
              required
            }
          }
        }
      }
    }
  }
`;

export const useGetFunctionById = (options?: QueryHookOptions<Data, Variables>) => {
  return useQuery<Data, Variables>(QUERY, options);
};
