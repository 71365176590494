import React, { useState } from "react";

import { Button, Form, Input, Modal } from "antd";
import { ButtonProps } from "antd/lib/button";
import { FormComponentProps } from "antd/lib/form/Form";

interface Props extends FormComponentProps {
  modalOkText: string;
  modalWidth?: number | string;
  title: string;
  confirmationValueIsValid?: (p: string) => boolean;
  onSubmit?: (confirmationValue: string) => void;
  modalContent: React.ReactNode;
  visible: boolean;
  okButtonProps: Partial<ButtonProps>;
  onClose: () => void;
  confirmLoading?: boolean;
}

const htmlFormId = "confirm-form";

const ConfirmationModal = ({
  form,
  modalContent,
  modalOkText,
  modalWidth,
  okButtonProps,
  onSubmit,
  visible,
  title,
  onClose,
  confirmationValueIsValid = (_p: string) => true,
  confirmLoading
}: Props) => {
  const [disabled, setDisabled] = useState(!confirmationValueIsValid(""));

  return (
    <Form
      id={htmlFormId}
      onSubmit={() => {
        onSubmit && onSubmit(form.getFieldValue("confirm"));
      }}
    >
      <Modal
        width={modalWidth}
        title={title}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button
            key="confirmationModalCancel"
            data-test="modalCancel"
            onClick={onClose}
          >
            Cancel
          </Button>,
          <Button
            data-test="modalSubmit"
            htmlType="submit"
            key="confirmationModalSubmit"
            form={htmlFormId}
            disabled={disabled}
            loading={confirmLoading}
            onClick={event => {
              event.preventDefault();
              onSubmit && onSubmit(form.getFieldValue("confirm"));
            }}
            {...okButtonProps}
          >
            {modalOkText}
          </Button>
        ]}
      >
        {modalContent}
        <Form.Item>
          {form.getFieldDecorator("confirm", {
            preserve: false,
            rules: [{ required: true, message: "Confirmation is required" }]
          })(
            <Input
              data-test="modalInput"
              onChange={e => setDisabled(!confirmationValueIsValid(e.target.value))}
            />
          )}
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default Form.create<Props>({
  name: "confirm-modal"
})(ConfirmationModal);
