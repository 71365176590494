import React from "react";

import moment from "moment";
import styled from "styled-components";

import { H4, H3, B3, C2 } from "../../../../components/common/StyledComponents";
import { Price, Subscription } from "../../../../types";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import { planName, planTier } from "../../../util/billing";

const OrderSummaryContainer = styled.div<{ opacity: number }>`
  width: 40%;
  padding: 0 40px 40px 40px;
  opacity: ${props => props.opacity};
  transiion: opacity 1s;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

const OrderSummaryH4 = styled(H4)`
  margin-bottom: 25px;
`;

const LineItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  small {
    width: 180px;
    text-align: right;
  }

  &.total {
    border-top: 1px solid ${props => props.theme.borderGrey}
    padding-top: 24px;
    margin-bottom: 0;
  }

  .payment-term {
    text-transform: capitalize;
  }
`;

const LineItemSubtotal = styled(LineItem)`
  border-top: 1px solid ${props => props.theme.borderGrey}
    padding-top: 24px;
    margin-bottom: 24px;
`;

const LineRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const LineColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StartDateText = styled(C2)`
  color: ${props => props.theme.surfaceSecondary};
`;

const CheckoutSubmit = styled(ButtonNew)`
  width: 100%;
`;

interface OrderSummaryProps {
  subscription?: Subscription | null;
  priceData?: {
    getAllProductPrices: {
      data: Price[];
    };
  } | null;
  total?: number;
  loadingSubscription: boolean;
  currentPlanSlug?: string;
  newPlanSlug?: string;
  paymentTerm?: string;
  proration: boolean;
  handleOrderSubmit: React.MouseEventHandler<HTMLElement>;
  subscriptionPeriodEnd?: number;
  submitDisabled: boolean;
}

export const OrderSummary = ({
  loadingSubscription,
  currentPlanSlug,
  newPlanSlug,
  paymentTerm,
  priceData,
  subscription,
  total,
  proration,
  handleOrderSubmit,
  subscriptionPeriodEnd,
  submitDisabled
}: OrderSummaryProps) => {
  const isDowngrade = planTier(newPlanSlug) < planTier(currentPlanSlug);

  const monthlyUserRate = () => {
    if (paymentTerm === "annual") {
      const cost =
        (priceData?.getAllProductPrices.data.find(
          (x: Price) => x.recurring.interval === "year"
        )?.unit_amount || 0) / 100;
      return Math.floor(cost / 12);
    } else {
      return Math.floor(
        (priceData?.getAllProductPrices.data.find(
          (x: Price) => x.recurring.interval === "month"
        )?.unit_amount || 0) / 100
      );
    }
  };

  const periodEnd = subscriptionPeriodEnd && moment.unix(subscriptionPeriodEnd);
  const currentTime = moment();

  return (
    <OrderSummaryContainer opacity={loadingSubscription ? 0.5 : 1}>
      <OrderSummaryH4>Order summary</OrderSummaryH4>
      <ul>
        <LineItem>
          <LineColumn>
            <LineRow>
              <B3>
                <span className="payment-term">
                  {planName(newPlanSlug)} ({paymentTerm})
                </span>
              </B3>
              <B3>
                {priceData && (
                  <>
                    {priceData?.getAllProductPrices ? (
                      <>
                        ${monthlyUserRate()}
                        /user per month
                      </>
                    ) : (
                      "Free"
                    )}
                  </>
                )}
              </B3>
            </LineRow>

            <LineRow>
              <StartDateText>
                {periodEnd && periodEnd.format("MMMM Do, YYYY")}
              </StartDateText>
            </LineRow>
          </LineColumn>
        </LineItem>

        <LineItem>
          <B3>Number of seats</B3>
          <B3>{subscription && <>{subscription?.quantity}</>}</B3>
        </LineItem>

        <LineItemSubtotal>
          <B3>Subtotal</B3>
          <B3>
            {priceData && (
              <>
                {priceData?.getAllProductPrices ? (
                  <>
                    {subscription &&
                      priceData &&
                      (
                        Math.floor(
                          (priceData?.getAllProductPrices.data.find(
                            (x: Price) =>
                              x.recurring.interval ===
                              (paymentTerm === "annual" ? "year" : "month")
                          )?.unit_amount || 0) / 100
                        ) * subscription?.quantity
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD"
                      })}
                  </>
                ) : (
                  "Free"
                )}
              </>
            )}
          </B3>
        </LineItemSubtotal>

        <LineItem className="total">
          <H3>Billed Today</H3>
          {total && (
            <H3>
              {isDowngrade ? (
                <>$0.00</>
              ) : (
                <>
                  {(total / 100).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD"
                  })}
                </>
              )}
            </H3>
          )}
        </LineItem>

        {proration && (
          <>
            {periodEnd && (
              <LineItem>
                <C2>
                  Prorated amount for the remaining{" "}
                  {Math.round(moment.duration(periodEnd.diff(currentTime)).asDays())}{" "}
                  days in your billing cycle.
                </C2>
              </LineItem>
            )}
            {subscription && priceData && periodEnd && (
              <>
                {
                  <LineItem>
                    <C2>
                      You’re {isDowngrade ? "downgrading" : "upgrading"} to{" "}
                      {planName(newPlanSlug)} ({paymentTerm}).{" "}
                      {isDowngrade && periodEnd
                        ? `You still have ${Math.round(
                            moment.duration(periodEnd.diff(currentTime)).asDays()
                          )} days on your current plan, ${planName(currentPlanSlug)} (${
                            subscription?.items?.data[0].price.recurring.interval ===
                            "month"
                              ? "monthly"
                              : "annual"
                          })`
                        : `You will be charged ${((total || 0) / 100).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD"
                            }
                          )} today, which is the prorated amount for the current billing period`}
                      . Starting {periodEnd && periodEnd.format("MMMM Do, YYYY")} your
                      new monthly total will be{" "}
                      {priceData &&
                        (
                          Math.floor(
                            (priceData?.getAllProductPrices.data.find(
                              (x: Price) =>
                                x.recurring.interval ===
                                (paymentTerm === "annual" ? "year" : "month")
                            )?.unit_amount || 0) / 100
                          ) * (subscription?.quantity || 0)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })}
                      . This amount is based on your total number of seats.
                    </C2>
                  </LineItem>
                }
              </>
            )}
          </>
        )}
      </ul>

      <CheckoutSubmit
        size="lg"
        type="brand"
        onClick={handleOrderSubmit}
        disabled={loadingSubscription || submitDisabled}
      >
        Confirm {isDowngrade ? "Downgrade" : "Upgrade"}
      </CheckoutSubmit>

      <C2>
        By clicking “Upgrade" you agree to our{" "}
        <a href="https://secure.internal.io/tos" target="_blank" rel="noreferrer">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href="https://www.internal.io/privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </C2>
    </OrderSummaryContainer>
  );
};
