import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

const QUERY = gql`
  query OpenStateTasksAssignedRole($queueId: ID!) {
    openStateTasksAssignedRole(queueId: $queueId) {
      role {
        id
        name
      }
      taskCount
    }
  }
`;

interface Variables {
  queueId: string;
}

export interface RoleStatistic {
  role: {
    id: string;
    name: string;
  };
  taskCount: number;
}

interface Data {
  openStateTasksAssignedRole: RoleStatistic[];
}

export const useOpenStateTaskRoleStatistics = (
  options?: QueryHookOptions<Data, Variables>
) => useQuery<Data, Variables>(QUERY, options);
