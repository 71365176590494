import styled, { css } from "styled-components";

import { LeftCol } from "../../../layouts/Layout";
import Rail from "../Rail/Rail";

export const Cols = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  font-weight: 500;
`;

const colPadding = css`
  padding: 48px 32px 24px;
`;

const Col = styled.div`
  ${colPadding}
`;

export const LeftRail = styled(LeftCol)``;

export const Main = styled(Col)`
  flex: 1 1 75%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-style: solid;
  border-width: 0 1px;
  border-color: ${props => props.theme.borderColor};
  padding-bottom: 0;
  overflow: hidden;
`;

export const RightRail = styled(Rail)`
  flex: 0 0 25%;
  font-size: 12px;
  ${colPadding}
`;
