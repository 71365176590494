import gql from "graphql-tag";

import { Connection, RelayNode } from "../../../../types";

export interface EnvironmentNodeTerse extends RelayNode {
  name: string;
}
export interface EnvironmentSpaceVersionTerse extends RelayNode {
  version: Partial<SpaceVersionTerse>;
  environment: EnvironmentNodeTerse;
}

interface UserTerse extends RelayNode {
  fullName: string;
}
export interface SpaceVersionTerse extends RelayNode {
  createdAt: string;
  createdBy?: UserTerse;
}

export interface SpaceVersionsQuerySpaceNode extends RelayNode {
  name: string;
  slug: string;
  environmentSpaceVersions: Connection<EnvironmentSpaceVersionTerse>;
  versions: Connection<SpaceVersionTerse>;
}

export interface SpaceVersionsQueryData {
  space: SpaceVersionsQuerySpaceNode;
}

export interface SpaceVersionsQueryVariables {
  slug: string;
}

export const SPACE_VERSIONS_QUERY = gql`
  query SpaceVersions($slug: String!) {
    space(slug: $slug) {
      __typename
      ... on SpaceNode {
        id
        name
        slug
        environmentSpaceVersions {
          edges {
            node {
              id
              version {
                id
              }
              environment {
                id
                name
              }
            }
          }
        }
        versions {
          edges {
            node {
              id
              createdAt
              createdBy {
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export interface RestoreSpaceVersionMutationData {
  restoreSpaceVersion: {
    ok: boolean;
    message: string;
    source: string;
  };
}
export interface ResoreSpaceVersionMutationVariables {
  versionId: string;
}
export const RESTORE_SPACE_VERSION_MUTATION = gql`
  mutation RestoreSpaceVersion($versionId: ID!) {
    restoreSpaceVersion(versionId: $versionId) {
      ok
    }
  }
`;
