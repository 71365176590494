import React from "react";

import { SpaceConfigAction } from "../../../../../types";
import CollectionItemContainer from "../../../common/CollectionItemContainer";
import ValidationError from "../../../common/ComponentConfigContext/ValidationError";
import { Field } from "../../../common/ConfigPanel/styledComponents";
import { DebouncedTemplateEditor, Height } from "../../../common/TemplateEditor";
import TextConfigSection from "../../../common/TextConfigSection";
import { CardListConfigState } from "../reducer";

interface Props {
  state: CardListConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

export default function TextConfig({ state, dispatch }: Props) {
  return (
    <TextConfigSection
      additionalConfig={
        <Field>
          <label>Name each card</label>
          <CollectionItemContainer collectionKey="cards">
            <DebouncedTemplateEditor
              value={state.draftComponent.properties.card_name.template}
              placeholder="${card.data.name}"
              minHeight={Height.Medium}
              onChange={template =>
                dispatch({
                  type: "MERGE_DRAFT_COMPONENT",
                  payload: {
                    change: {
                      properties: { card_name: { template } }
                    }
                  }
                })
              }
            />
          </CollectionItemContainer>
          <ValidationError field="CARD_NAME" />
        </Field>
      }
    />
  );
}
