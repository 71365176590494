import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button, Checkbox, Icon } from "antd";
import styled from "styled-components";

import Spacer from "../../../../common/Spacer";
import { LinkButtonNew } from "../../../../common/StyledComponents";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MutationType } from "../../SpaceConfigContext/useSpaceConfig";
import HeaderPanel from "../HeaderPanel";
import useSaveStatus, { Status } from "../useSaveFlow/useSaveFlow";

import humanizeDateTimeFromNow from "./humanizeDateTimeFromNow";
import { Data, Vars, ENVIRONMENT_VERSION_QUERY } from "./queries";
import reducer, { initialState, PublishPanelState } from "./reducer";

export const Error = styled.p`
  color: ${props => props.theme.errorColor};
  font-size: ${props => props.theme.smallFontSize};
`;

export const SkinnyPrimaryButton = styled(Button)`
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  border: 0;

  &.ant-btn:not(.ant-btn-link) {
    font-size: ${props => props.theme.inputFontSize};
    min-height: 26px;
    padding: 0px 10px;
    &:last-child {
      margin-right: ${props => props.theme.spacersm};
    }

    i {
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

const PublishedButton = styled(Button)`
  &.ant-btn:not(.ant-btn-link).ant-btn-primary {
    background-color: ${props => props.theme.brandColors.accentGreen};
    color: ${props => props.theme.textColorOnDark};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.textColorOnDark};
      background-color: ${props => props.theme.successDarkGreenPressed};
    }
  }
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;

  position: absolute;
  top: calc(${props => props.theme.headerHeight} + 10px);
  right: ${props => props.theme.leftMenuWidth};

  border-radius: 8px;
  background-color: ${props => props.theme.backgroundColor};
`;
PanelContainer.displayName = "PanelContainer";

export const Heading = styled.p`
  color: ${props => props.theme.inputColor};
  font-weight: bold;
`;

export const EnvironmentsContainer = styled.div`
  padding: ${props => props.theme.spacermd};
`;

export const PublishingInformation = styled.p`
  font-style: italic;
  color: ${props => props.theme.textColorMid};
  font-size: ${props => props.theme.smallFontSize};
  margin-left: ${props => props.theme.spacerlg};
  margin-top: 2px;
`;

export const CallToActions = styled.div`
  border-top: 1px solid ${props => props.theme.menuBorderColor};
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: ${props => props.theme.spacermd};
  button + button {
    margin-left: ${props => props.theme.spacersm};
  }
`;

const getPublishCTA = (status: Status, state: PublishPanelState) => {
  if (!state.dirty && state.submitted) return "Published";
  switch (status) {
    case Status.SAVING:
      return "Saving";
    case Status.SAVE_SUCCESSFUL:
    case Status.UNSAVED:
    case Status.SAVE_UNSUCCESSFUL:
    default:
      return "Publish";
  }
};

export default function PublishButton() {
  const [visible, setVisible] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [environmentsLoaded, setEnvironmentsLoaded] = React.useState(false);
  const { status, reset } = useSaveStatus();
  const { save, mutationLoading } = useSpaceConfigContext();
  const { spaceId } = useStableSpaceContext();
  const { data } = useQuery<Data, Vars>(ENVIRONMENT_VERSION_QUERY, {
    variables: {
      space: spaceId
    }
  });

  const environmentIds = React.useMemo(() => {
    if (!data) return null;
    return data!.allEnvironments.edges.map(e => e.node.id) || [];
  }, [data]);

  React.useEffect(() => {
    if (environmentsLoaded || environmentIds === null) return;
    setEnvironmentsLoaded(true);
    dispatch({
      type: "SET_ALL_ENVIRONMENTS",
      payload: {
        environmentIds
      }
    });
  }, [environmentIds, environmentsLoaded]);

  React.useEffect(() => {
    if (status === Status.SAVE_SUCCESSFUL && state.submitted) {
      dispatch({ type: "HANDLE_SAVE" });
    }
  }, [status, state.submitted]);

  React.useEffect(() => {
    if (!visible) return;
    reset();
    dispatch({
      type: "SELECT_ALL"
    });
  }, [visible, reset]);

  const PublishPopperButton =
    !state.dirty && state.submitted ? PublishedButton : Button;
  const isSelectAll = state.environmentsToPublish.size < state.allEnvironmentIds.length;

  return (
    <>
      {visible && (
        <HeaderPanel
          maskClosable={mutationLoading !== MutationType.SAVE}
          onCancel={() => setVisible(false)}
        >
          <PanelContainer>
            <EnvironmentsContainer data-test="environmentsPanel">
              <Heading>Select Environments</Heading>
              {state.submitted && state.environmentsToPublish.size === 0 && (
                <Error>Please select at least one environment.</Error>
              )}
              <LinkButtonNew
                onClick={() =>
                  dispatch({
                    type: isSelectAll ? "SELECT_ALL" : "CLEAR_ALL"
                  })
                }
              >
                {isSelectAll ? "Select all" : "Deselect all"}
              </LinkButtonNew>
              <Spacer size="md" />
              {state.allEnvironmentIds.map(id => {
                const version = !!spaceId
                  ? data?.allEnvironmentSpaceVersions.edges.find(
                      edge => edge.node.environment.id === id
                    )
                  : undefined;
                const env = data?.allEnvironments.edges.find(e => e.node.id === id);
                const lastPublishedInformation = version?.node.createdAt
                  ? `Published ${humanizeDateTimeFromNow(version!.node.createdAt)}`
                  : "Not Published";
                return (
                  <div key={id}>
                    <Checkbox
                      value={id}
                      data-test={`environmentCheckbox-${id}`}
                      checked={state.environmentsToPublish.has(id)}
                      onChange={e =>
                        dispatch({
                          type: "SET_ENVIRONMENT",
                          payload: {
                            id: e.target.value,
                            includeEnvironment: e.target.checked
                          }
                        })
                      }
                    >
                      {env?.node.name + " Environment"}
                    </Checkbox>
                    <PublishingInformation data-test="publishing-information">
                      {lastPublishedInformation}
                    </PublishingInformation>
                  </div>
                );
              })}
            </EnvironmentsContainer>
            <CallToActions>
              <Button data-test="closePublish" onClick={() => setVisible(false)}>
                Close
              </Button>
              <PublishPopperButton
                type="primary"
                data-test="publishNow"
                disabled={!state.dirty && state.submitted}
                loading={status === Status.SAVING}
                onClick={() => {
                  dispatch({
                    type: "SUBMIT"
                  });
                  if (state.environmentsToPublish.size > 0) {
                    save(Array.from(state.environmentsToPublish));
                  }
                }}
              >
                {getPublishCTA(status, state)}
              </PublishPopperButton>
            </CallToActions>
          </PanelContainer>
        </HeaderPanel>
      )}
      <SkinnyPrimaryButton
        disabled={!!mutationLoading}
        type="primary"
        onClick={() => setVisible(true)}
        data-test="publishButton"
      >
        Publish <Icon type="caret-down" />
      </SkinnyPrimaryButton>
    </>
  );
}
