import { colorTokens } from "../../../cssConstants";
import { reportException } from "../../util/exceptionReporting";

export function addPoints(...args: DOMPoint[]) {
  const x = args.reduce((acc, curr) => acc + curr.x, 0);
  const y = args.reduce((acc, curr) => acc + curr.y, 0);
  return new DOMPoint(x, y);
}
export function subtractPoints(a: DOMPoint, b: DOMPoint) {
  return new DOMPoint(a.x - b.x, a.y - b.y);
}

export enum direction {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT
}

export enum TransformationType {
  NONE = "NONE",
  MOVE = "MOVE",
  RESIZE = "RESIZE"
}

export enum LayoutUnit {
  PIXEL = "px",
  PERCENTAGE = "%",
  AUTO = "auto"
}

export enum DisplayOption {
  BLOCK = "block",
  INLINE = "inline",
  INLINE_BLOCK = "inline-block"
}

export enum OverflowOption {
  AUTO = "auto",
  HIDDEN = "hidden",
  VISIBLE = "visible",
  SCROLL = "scroll"
}

export enum PositionOption {
  STATIC = "static",
  ABSOLUTE = "absolute"
}

export interface ElementLayoutOptions {
  width: Array<LayoutUnit> | null;
  height: Array<LayoutUnit> | null;
  top: LayoutUnit[] | null;
  left: LayoutUnit[] | null;
  overflow: OverflowOption[];
  snapToGrid: [true, false];
}

export const DEFAULT_ELEMENT_LAYOUT_OPTIONS: ElementLayoutOptions = {
  width: Object.values(LayoutUnit),
  height: Object.values(LayoutUnit),
  top: Object.values(LayoutUnit),
  left: Object.values(LayoutUnit),
  overflow: Object.values(OverflowOption),
  snapToGrid: [true, false]
};

export function DOMRectsEqual(a: DOMRect, b: DOMRect) {
  const epsilon = 0.01;
  return (
    Math.abs(a.x - b.x) <= epsilon &&
    Math.abs(a.y - b.y) <= epsilon &&
    Math.abs(a.width - b.width) <= epsilon &&
    Math.abs(a.height - b.height) <= epsilon
  );
}

export function isEmptyDOMRect(rect: DOMRect) {
  return DOMRectsEqual(rect, new DOMRect());
}

export function DOMPointsEqual(a: DOMPoint, b: DOMPoint) {
  return a.x === b.x && a.y === b.y && a.z === b.z && a.w === b.w;
}

export function parseCssUnit(cssValue: string | undefined): LayoutUnit {
  if (typeof cssValue !== "string") {
    const err = new Error("Expected parseCssUnit to have been called with a string.");
    reportException(err, { extra: { cssValue } });
  } else {
    if (cssValue === LayoutUnit.AUTO) return LayoutUnit.AUTO;
    const unitRegex = new RegExp(Object.values(LayoutUnit).join("|"));
    const match = cssValue.match(unitRegex);
    if (match && match[0]) {
      return match[0] as LayoutUnit;
    }
    const err = new Error("Expected LayoutUnit to be present in cssValue.");
    reportException(err, { extra: { cssValue } });
  }
  return "" as LayoutUnit;
}

export const DEFAULT_ELEMENT_UNITS = {
  width: LayoutUnit.PERCENTAGE,
  height: LayoutUnit.PIXEL,
  top: LayoutUnit.PIXEL,
  left: LayoutUnit.PERCENTAGE
};

/**
 * Components have an `ElementLayout` which is used to determine their position
 * and dimensions. The user can define dimensions in the styles tab, in px, %, or auto.
 *
 * In most cases, layout gets handled by `AbsoluteElement` or `StaticElement`.
 *
 * For button-type components (such as `SpaceFunctionButton`), the layout gets passed down
 * to the actual button.
 */
export class ElementLayout {
  // Disable eslint check on next line because eslint incorrectly flags `key` as an undefined variable.
  // This bug (https://github.com/eslint/typescript-eslint-parser/issues/557) has been reported and supposedly
  // fixed but it's not easy to track down whether this case was fixed or has regressed.
  [key: string]: any; // eslint-disable-line no-undef
  left: string;
  top: string;
  width: string;
  height: string;
  overflow?: OverflowOption;
  minWidth?: string | number; // TODO move to `style` property once we introduce it as a top level space component prop
  minHeight?: string | number;
  position: PositionOption;
  snapToGrid: boolean;

  constructor({
    left = `0${LayoutUnit.PIXEL}`,
    top = `0${LayoutUnit.PIXEL}`,
    width = `100${LayoutUnit.PIXEL}`,
    height = `100${LayoutUnit.PIXEL}`,
    overflow = OverflowOption.AUTO,
    minWidth = `10${LayoutUnit.PIXEL}`,
    minHeight = `10${LayoutUnit.PIXEL}`,
    position = PositionOption.STATIC,
    snapToGrid = true
  }: {
    left?: string;
    top?: string;
    width?: string;
    height?: string;
    overflow?: OverflowOption;
    minWidth?: string | number;
    minHeight?: string | number;
    position?: PositionOption;
    snapToGrid?: boolean;
  } = {}) {
    this.left = left;
    this.top = top;
    this.width = width;
    this.height = height;
    this.overflow = overflow;
    this.minWidth = minWidth;
    this.minHeight = minHeight;
    this.position = position;
    this.snapToGrid = snapToGrid;
  }

  merge(otherLayout: Partial<ElementLayout>) {
    Object.entries(otherLayout).forEach(([key, val]) => {
      if (key in this) {
        this[key as keyof ElementLayout] = val;
      }
    });
  }

  toRect() {
    return new DOMRect(
      parseFloat(this.left),
      parseFloat(this.top),
      this.width === "auto" ? 0.01 : parseFloat(this.width),
      this.height === "auto" ? 0.01 : parseFloat(this.height)
    );
  }

  static fromRect(rect: DOMRect) {
    return new ElementLayout({
      left: `${rect.left}${LayoutUnit.PIXEL}`,
      top: `${rect.top}${LayoutUnit.PIXEL}`,
      width: `${rect.width}${LayoutUnit.PIXEL}`,
      height: `${rect.height}${LayoutUnit.PIXEL}`
    });
  }

  static stripNonStyleProps({ snapToGrid, ...layout }: ElementLayout) {
    return layout;
  }

  isEqual(otherElementLayout: any, strict = false) {
    if (otherElementLayout === undefined) return false;
    const epsilon = strict ? 0 : 0.99;
    return (
      (["left", "top", "width", "height"] as Array<keyof ElementLayout>).every(
        (s: keyof ElementLayout) =>
          this[s] !== undefined &&
          otherElementLayout[s] !== undefined &&
          (this[s] === otherElementLayout[s] ||
            (parseCssUnit(this[s] as string) ===
              parseCssUnit(otherElementLayout[s] as string) &&
              Math.abs(
                parseFloat(this[s] as string) -
                  parseFloat(otherElementLayout[s] as string)
              ) <= epsilon))
      ) &&
      this.overflow === otherElementLayout.overflow &&
      this.minWidth === otherElementLayout.minWidth &&
      this.minHeight === otherElementLayout.minHeight &&
      this.position === otherElementLayout.position &&
      this.snapToGrid === otherElementLayout.snapToGrid
    );
  }
}

export const EMPTY_LAYOUT = Object.freeze(
  new ElementLayout({ width: "0px", height: "0px" })
);

export class ElementStyle {
  fontSize: string;
  lineHeight: string;
  fontWeight: string;
  textDecoration: string;
  fontStyle: string;
  color: string;
  textAlign: string;

  constructor({
    fontSize,
    lineHeight,
    fontWeight,
    textDecoration,
    fontStyle,
    color,
    textAlign
  }: {
    fontSize?: string;
    lineHeight?: string;
    fontWeight?: string;
    textDecoration?: string;
    fontStyle?: string;
    color?: string;
    textAlign?: string;
  } = {}) {
    this.fontSize = fontSize || `14${LayoutUnit.PIXEL}`;
    this.lineHeight = lineHeight || "21px";
    this.fontWeight = fontWeight || "400";
    this.textDecoration = textDecoration || "none";
    this.fontStyle = fontStyle || "normal";
    this.color = color || colorTokens.greyTransparent1300;
    this.textAlign = textAlign || "left";
  }
}

export function getDistanceBetweenPoints(a: DOMPoint, b: DOMPoint) {
  const { x, y } = subtractPoints(a, b);
  return Math.abs(Math.sqrt(x ** 2 + y ** 2));
}

export function getRectCorners(rect: DOMRect) {
  return [
    new DOMPoint(rect.left, rect.top),
    new DOMPoint(rect.right, rect.top),
    new DOMPoint(rect.right, rect.bottom),
    new DOMPoint(rect.left, rect.bottom)
  ];
}

export function getIsPtInsideRect(rect: DOMRect, pt: DOMPoint) {
  return (
    pt.x >= rect.left && pt.x <= rect.right && pt.y >= rect.top && pt.y <= rect.bottom
  );
}
