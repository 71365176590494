import React from "react";

import { EventOption } from "../../../../../types";
import { useComponentConfigContext } from "../../ComponentConfigContext";
import EffectsManager from "../../effects/EffectsManager/EffectsManager";
import {
  FORM_EVENTS,
  MODAL_FORM_EVENTS,
  BUTTON_EVENTS,
  BULK_IMPORT_EVENTS
} from "../effects";

export default function EffectsConfig() {
  const {
    state: {
      draftComponent: { type }
    }
  } = useComponentConfigContext();

  let events: Readonly<EventOption[]>;
  switch (type) {
    case "FUNCTION_BULK_IMPORT":
      events = BULK_IMPORT_EVENTS;
      break;
    case "FUNCTION_BUTTON":
      events = BUTTON_EVENTS;
      break;
    case "FUNCTION_FORM":
      events = FORM_EVENTS;
      break;
    case "FUNCTION_MODAL_FORM":
      events = MODAL_FORM_EVENTS;
      break;
    default:
      throw new Error(`Unexpected component type: ${type}`);
  }

  return <EffectsManager eventOptions={events} />;
}
