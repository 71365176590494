import React from "react";

import styled from "styled-components";

import SVGRocketShipIcon from "../../assets/rocket_ship_icon.svg";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${props => props.theme.textColor};
`;

const Title = styled.div`
  font-size: 20px;
  line-height: ${props => props.theme.spacermd};
  margin-bottom: 10px;
`;

const Instructions = styled.div`
  line-height: ${props => props.theme.spacermd};
  margin-bottom: 28px;
`;

const RocketShipIcon = styled(SVGRocketShipIcon)`
  height: 86px;
  width: 86px;
`;

interface Props {
  title: string;
  instructions: string;
  icon?: React.ReactNode;
}

export const Empty = ({ title, instructions, icon }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Instructions>{instructions}</Instructions>
      {icon ? icon : <RocketShipIcon />}
    </Container>
  );
};
