import React from "react";

import pluralize from "pluralize";

import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import { Headline } from "../common/styledComponents";

import { useAverageTimeToClose } from "./queries";

const SECONDS_IN_DAY = 24 * 60 * 60;
const SECONDS_IN_HOUR = 60 * 60;

function formatDuration(duration: number): string {
  let result = "";
  const days = Math.floor(duration / SECONDS_IN_DAY);
  const hours = Math.floor(duration / SECONDS_IN_HOUR) % 24;
  const minutes = Math.floor(duration / 60) % 60;

  if (days > 0) {
    result += `${days} ${pluralize("day", days)} `;
  }

  if (hours > 0 || days > 0) {
    result += `${hours} ${pluralize("hour", hours)} `;
  }

  result += `${minutes} ${pluralize("minute", minutes)}`;

  return result;
}

export default function AverageTimeToCloseStatistics({
  queueId,
  withinSeconds,
  className
}: {
  queueId?: string;
  withinSeconds: number;
  className?: string;
}) {
  const { data, loading, error } = useAverageTimeToClose({
    variables: { queueId: queueId!, withinSeconds },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="Average time to close"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
    >
      <Headline>
        {data?.averageTimeToClose
          ? formatDuration(data.averageTimeToClose)
          : "No data available"}
      </Headline>
    </AnalyticCard>
  );
}
