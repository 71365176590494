import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "react-apollo";

import { CommentNode, CommentErrorObjectNotFound } from "../queries";

export const CREATE_COMMENT = gql`
  mutation CreateComment($taskId: ID!, $content: String!) {
    createComment(taskId: $taskId, content: $content) {
      id
      content
    }
  }
`;

export interface Data {
  createComment: CommentNode | CommentErrorObjectNotFound;
}

export interface Variables {
  taskId: string;
  content: string;
}

export const useCreateComment = (options?: MutationHookOptions<Data, Variables>) => {
  return useMutation<Data, Variables>(CREATE_COMMENT, options);
};
