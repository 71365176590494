import React from "react";

import { Select } from "antd";
import invariant from "invariant";
import styledComponents from "styled-components";

import { SpaceConfigAction } from "../../../../../types";
import { ConfigSection } from "../../../common/ConfigPanel";
import { Alert, Field } from "../../../common/ConfigPanel/styledComponents";
import SourceSelector from "../../../common/SourceSelector";
import TextConfigSection from "../../../common/TextConfigSection";
import { RECORDS_PER_PAGE } from "../../SpaceChart";
import { ChartType, ChartTypeDisplayNames, ChartConfigState } from "../../types";

import ColumnChartConfig from "./ColumnChartConfig";
import LineChartConfig from "./LineChartConfig";
import PieChartConfig from "./PieChartConfig";

interface Props {
  state: ChartConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

const ConfigPanelAlert = styledComponents(Alert)`
  margin-top: ${props => props.theme.spacersm};
`;

export default function BasicConfig({ state, dispatch }: Props) {
  invariant(
    !!Object.values(ChartType).includes(state.draftComponent.properties.chart_type),
    "Chart type must be present and valid"
  );
  const resourceSelected = state.attributes !== null && state.attributes.length;

  return (
    <>
      <ConfigSection title="Source">
        <Field>
          <SourceSelector />
        </Field>
      </ConfigSection>

      <TextConfigSection />
      <ConfigSection title="Chart Type">
        <Field data-test="chart-type-field">
          <Select
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            value={state.draftComponent.properties.chart_type}
            onChange={(chart_type: ChartType) => {
              dispatch({ type: "CHANGE_CHART_TYPE", payload: { chart_type } });
            }}
          >
            {Object.entries(ChartTypeDisplayNames).map(([type, name]) => (
              <Select.Option key={type}>{name}</Select.Option>
            ))}
          </Select>
        </Field>
        <ConfigPanelAlert
          message={
            <>
              Only the first {RECORDS_PER_PAGE} records will be charted. Use filters to
              narrow down your data set or consider{" "}
              <a
                href="https://www.internal.io/docs/functions"
                target="_blank"
                rel="noopener noreferrer"
              >
                creating a custom function
              </a>{" "}
              to aggregate your data first.
            </>
          }
          type="info"
          showIcon
        />
      </ConfigSection>
      {resourceSelected && (
        <ConfigSection title="Chart Details">
          {state.draftComponent.properties.chart_type === ChartType.COLUMN && (
            <ColumnChartConfig state={state} dispatch={dispatch} />
          )}
          {state.draftComponent.properties.chart_type === ChartType.LINE && (
            <LineChartConfig state={state} dispatch={dispatch} />
          )}
          {state.draftComponent.properties.chart_type === ChartType.PIE && (
            <PieChartConfig state={state} dispatch={dispatch} />
          )}
        </ConfigSection>
      )}
    </>
  );
}
