import React from "react";

import { useEnvironmentContext } from "../../common/contexts/EnvironmentContext";

export default function EnvironmentWarning() {
  const { visibleEnvironments } = useEnvironmentContext();
  if (visibleEnvironments.length < 2) return null;
  const defaultEnvironment = visibleEnvironments.find(e => e.isDefault)!;

  return (
    <p>{`These credentials will be added to your ${defaultEnvironment.name} Environment.`}</p>
  );
}
