import { BaseFunctionName } from "../../../types";

export enum SupportedIntegration {
  BIGQUERY = "bigquery",
  CASSANDRA = "cassandra",
  HTTP = "http",
  HUBSPOT = "hubspot",
  MONGO = "mongo",
  MSSQL = "mssql",
  MYSQL = "mysql",
  POSTGRESQL = "postgresql",
  REDSHIFT = "redshift",
  SNOWFLAKE = "snowflake",
  STRIPE = "stripe",
  ZENDESK = "zendesk"
}

interface Settings {
  defaultFunctionName: BaseFunctionName | undefined;
  isSQLLike: boolean;
  isHTTPLike: boolean;
  supportedBaseFunctions: Record<BaseFunctionName, boolean>;
  supportsEditableReservedParams: boolean;
  supportsParamsInCode: boolean;
  reservedParamSupportingFunctionNames: BaseFunctionName[];
  editableInputSupportingFunctionNames: BaseFunctionName[];
}

const ALL_UNSUPPORTED = {
  [BaseFunctionName.INSERT]: false,
  [BaseFunctionName.UPDATE]: false,
  [BaseFunctionName.DELETE]: false,
  [BaseFunctionName.QUERY]: false,
  [BaseFunctionName.SQL_EXECUTE]: false,
  [BaseFunctionName.SQL_QUERY]: false,
  [BaseFunctionName.REQUEST]: false,
  [BaseFunctionName.AGGREGATE]: false
};

export const EditorSupport: Record<SupportedIntegration, Settings> = {
  [SupportedIntegration.BIGQUERY]: {
    defaultFunctionName: undefined,
    isSQLLike: true,
    isHTTPLike: false,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.QUERY]: true,
      [BaseFunctionName.SQL_QUERY]: true
    },
    supportsEditableReservedParams: false,
    reservedParamSupportingFunctionNames: [BaseFunctionName.QUERY],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.QUERY,
      BaseFunctionName.SQL_QUERY
    ]
  },
  [SupportedIntegration.CASSANDRA]: {
    defaultFunctionName: undefined,
    isSQLLike: true,
    isHTTPLike: false,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.INSERT]: true,
      [BaseFunctionName.UPDATE]: true,
      [BaseFunctionName.DELETE]: true
    },
    supportsEditableReservedParams: true,
    reservedParamSupportingFunctionNames: [],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.INSERT,
      BaseFunctionName.UPDATE,
      BaseFunctionName.DELETE
    ]
  },
  [SupportedIntegration.HTTP]: {
    defaultFunctionName: BaseFunctionName.REQUEST,
    isSQLLike: false,
    isHTTPLike: true,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.REQUEST]: true
    },
    supportsEditableReservedParams: true,
    reservedParamSupportingFunctionNames: [],
    editableInputSupportingFunctionNames: [BaseFunctionName.REQUEST]
  },
  [SupportedIntegration.HUBSPOT]: {
    defaultFunctionName: BaseFunctionName.REQUEST,
    isSQLLike: false,
    isHTTPLike: true,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.REQUEST]: true
    },
    supportsEditableReservedParams: true,
    reservedParamSupportingFunctionNames: [],
    editableInputSupportingFunctionNames: [BaseFunctionName.REQUEST]
  },
  [SupportedIntegration.MONGO]: {
    defaultFunctionName: BaseFunctionName.AGGREGATE,
    isSQLLike: false,
    isHTTPLike: false,
    supportsParamsInCode: true,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.AGGREGATE]: true,
      [BaseFunctionName.INSERT]: true,
      [BaseFunctionName.UPDATE]: true,
      [BaseFunctionName.DELETE]: true
    },
    supportsEditableReservedParams: true,
    reservedParamSupportingFunctionNames: [BaseFunctionName.AGGREGATE],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.AGGREGATE,
      BaseFunctionName.INSERT,
      BaseFunctionName.UPDATE,
      BaseFunctionName.DELETE
    ]
  },
  [SupportedIntegration.MSSQL]: {
    defaultFunctionName: undefined,
    isSQLLike: true,
    isHTTPLike: false,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.INSERT]: true,
      [BaseFunctionName.UPDATE]: true,
      [BaseFunctionName.QUERY]: true,
      [BaseFunctionName.DELETE]: true,
      [BaseFunctionName.SQL_EXECUTE]: true,
      [BaseFunctionName.SQL_QUERY]: true
    },
    supportsEditableReservedParams: false,
    reservedParamSupportingFunctionNames: [BaseFunctionName.QUERY],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.DELETE,
      BaseFunctionName.INSERT,
      BaseFunctionName.QUERY,
      BaseFunctionName.UPDATE,
      BaseFunctionName.SQL_EXECUTE,
      BaseFunctionName.SQL_QUERY
    ]
  },
  [SupportedIntegration.MYSQL]: {
    defaultFunctionName: undefined,
    isSQLLike: true,
    isHTTPLike: false,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.INSERT]: true,
      [BaseFunctionName.UPDATE]: true,
      [BaseFunctionName.QUERY]: true,
      [BaseFunctionName.DELETE]: true
    },
    supportsEditableReservedParams: false,
    reservedParamSupportingFunctionNames: [BaseFunctionName.QUERY],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.DELETE,
      BaseFunctionName.INSERT,
      BaseFunctionName.QUERY,
      BaseFunctionName.UPDATE
    ]
  },
  [SupportedIntegration.POSTGRESQL]: {
    defaultFunctionName: undefined,
    isSQLLike: true,
    isHTTPLike: false,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.INSERT]: true,
      [BaseFunctionName.QUERY]: true,
      [BaseFunctionName.UPDATE]: true,
      [BaseFunctionName.DELETE]: true,
      [BaseFunctionName.SQL_EXECUTE]: true,
      [BaseFunctionName.SQL_QUERY]: true
    },
    supportsEditableReservedParams: false,
    reservedParamSupportingFunctionNames: [BaseFunctionName.QUERY],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.DELETE,
      BaseFunctionName.INSERT,
      BaseFunctionName.QUERY,
      BaseFunctionName.UPDATE,
      BaseFunctionName.SQL_EXECUTE,
      BaseFunctionName.SQL_QUERY
    ]
  },
  [SupportedIntegration.REDSHIFT]: {
    defaultFunctionName: undefined,
    isSQLLike: true,
    isHTTPLike: false,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.INSERT]: true,
      [BaseFunctionName.QUERY]: true,
      [BaseFunctionName.UPDATE]: true,
      [BaseFunctionName.DELETE]: true,
      [BaseFunctionName.SQL_EXECUTE]: true,
      [BaseFunctionName.SQL_QUERY]: true
    },
    supportsEditableReservedParams: false,
    reservedParamSupportingFunctionNames: [BaseFunctionName.QUERY],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.DELETE,
      BaseFunctionName.INSERT,
      BaseFunctionName.QUERY,
      BaseFunctionName.UPDATE,
      BaseFunctionName.SQL_EXECUTE,
      BaseFunctionName.SQL_QUERY
    ]
  },
  [SupportedIntegration.SNOWFLAKE]: {
    defaultFunctionName: undefined,
    isSQLLike: true,
    isHTTPLike: false,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.DELETE]: true,
      [BaseFunctionName.INSERT]: true,
      [BaseFunctionName.QUERY]: true,
      [BaseFunctionName.UPDATE]: true,
      [BaseFunctionName.SQL_EXECUTE]: true,
      [BaseFunctionName.SQL_QUERY]: true
    },
    supportsEditableReservedParams: false,
    reservedParamSupportingFunctionNames: [BaseFunctionName.QUERY],
    editableInputSupportingFunctionNames: [
      BaseFunctionName.DELETE,
      BaseFunctionName.INSERT,
      BaseFunctionName.QUERY,
      BaseFunctionName.UPDATE,
      BaseFunctionName.SQL_EXECUTE,
      BaseFunctionName.SQL_QUERY
    ]
  },
  [SupportedIntegration.STRIPE]: {
    defaultFunctionName: BaseFunctionName.REQUEST,
    isSQLLike: false,
    isHTTPLike: true,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.REQUEST]: true
    },
    supportsEditableReservedParams: true,
    reservedParamSupportingFunctionNames: [],
    editableInputSupportingFunctionNames: [BaseFunctionName.REQUEST]
  },
  [SupportedIntegration.ZENDESK]: {
    defaultFunctionName: BaseFunctionName.REQUEST,
    isSQLLike: false,
    isHTTPLike: true,
    supportsParamsInCode: false,
    supportedBaseFunctions: {
      ...ALL_UNSUPPORTED,
      [BaseFunctionName.REQUEST]: true
    },
    supportsEditableReservedParams: true,
    reservedParamSupportingFunctionNames: [],
    editableInputSupportingFunctionNames: [BaseFunctionName.REQUEST]
  }
};

export const isSupported = (integration: SupportedIntegration | string): boolean =>
  !!EditorSupport[integration.toLowerCase() as SupportedIntegration];

export const isBaseFunctionSupported = (
  integration: SupportedIntegration | string,
  baseFunctionName: BaseFunctionName
): boolean =>
  !!EditorSupport[integration.toLowerCase() as SupportedIntegration]
    ?.supportedBaseFunctions[baseFunctionName];

export const areParamsInCodeSupported = (
  integration: SupportedIntegration | string
): boolean =>
  !!EditorSupport[integration.toLowerCase() as SupportedIntegration]
    ?.supportsParamsInCode;

export const areReservedParamsEditable = (
  integration: SupportedIntegration | string
): boolean =>
  !!EditorSupport[integration.toLowerCase() as SupportedIntegration]
    ?.supportsEditableReservedParams;

export const isSQLLike = (
  integration: SupportedIntegration | string | undefined
): boolean =>
  !!integration &&
  !!EditorSupport[integration.toLowerCase() as SupportedIntegration]?.isSQLLike;

export const isHTTPLike = (integration: SupportedIntegration | string): boolean =>
  !!EditorSupport[integration.toLowerCase() as SupportedIntegration]?.isHTTPLike;

export const supportsReservedParams = (
  integration: SupportedIntegration | string,
  baseFunctionName: BaseFunctionName
): boolean =>
  !!EditorSupport[
    integration.toLowerCase() as SupportedIntegration
  ]?.reservedParamSupportingFunctionNames.includes(baseFunctionName);

export const supportsEditableInput = (
  integration: SupportedIntegration | string,
  baseFunctionName: BaseFunctionName
): boolean =>
  !!EditorSupport[
    integration.toLowerCase() as SupportedIntegration
  ]?.editableInputSupportingFunctionNames.includes(baseFunctionName);
