import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input, Select } from "antd";
import gql from "graphql-tag";
import styled from "styled-components";

import { AuthorizationFlowNode, AuthorizationFlowStepType } from "../../../../types";
import Message from "../../../common/Message";
import { Header, LeftSide, RightSide } from "../styledComponents";

import FlowConfiguration from "./FlowConfiguration";
import reducer, { ActionType, getInitialState, PendingAuthFlowStep } from "./reducer";

interface Props {
  authorizationProvider: AuthorizationFlowNode;

  onCloseButtonClick(): void;

  onEditComplete(): void;
}

const UPDATE_AUTH_FLOW = gql`
  mutation UpdateCustomAuthFlow($flowInput: AuthorizationFlowInput!) {
    updateCustomAuthorizationProvider(flowInput: $flowInput) {
      __typename
      ... on AuthFlowUpdateSuccessResult {
        flow {
          id
          name
          authSteps {
            edges {
              node {
                id
                slug
                type
                order
                options
                function {
                  id
                  name
                  title
                  dataSource {
                    id
                    name
                    __typename
                  }
                  __typename
                }
                __typename
              }
            }
          }
        }
      }
      ... on AuthFlowUpdateErrorResult {
        message
      }
      ... on AuthFlowUpdateValidationErrorResult {
        errors
      }
    }
  }
`;

interface AuthFlowUpdateSuccessResult {
  __typename: "AuthFlowUpdateSuccessResult";
  flow: AuthorizationFlowNode;
}

interface AuthFlowUpdateErrorResult {
  __typename: "AuthFlowUpdateErrorResult";
  message: string;
}

interface AuthFlowUpdateValidationErrorResult {
  __typename: "AuthFlowUpdateValidationErrorResult";
  errors: any;
}

type AuthFlowUpdateResult =
  | AuthFlowUpdateSuccessResult
  | AuthFlowUpdateErrorResult
  | AuthFlowUpdateValidationErrorResult;

interface MutationData {
  updateCustomAuthorizationProvider: AuthFlowUpdateResult;
}

interface AuthFlowInput {
  id: string;
  name: string;
  steps: PendingAuthFlowStep[];
}

interface MutationVars {
  flowInput: AuthFlowInput;
}

const getFilterStepFields = (step: PendingAuthFlowStep) => {
  if (step.type === AuthorizationFlowStepType.CREDENTIAL) {
    return {
      id: step.id,
      type: step.type,
      order: step.order,
      function: null,
      options: step.expires ? step.options : { ...step.options, expiration: null }
    };
  } else {
    return {
      id: step.id,
      type: step.type,
      order: step.order,
      function: step.function?.id ? { id: step.function.id } : null,
      options: step.options
    };
  }
};

const Root = styled.div`
  margin-bottom: ${props => props.theme.spacerlg};
`;

const CustomAuthProviderDrawer = ({
  onCloseButtonClick,
  onEditComplete,
  authorizationProvider
}: Props) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState(authorizationProvider)
  );

  const [updateFlow, { loading }] = useMutation<MutationData, MutationVars>(
    UPDATE_AUTH_FLOW,
    {
      onCompleted: (data: MutationData) => {
        const result = data.updateCustomAuthorizationProvider;
        switch (result.__typename) {
          case "AuthFlowUpdateSuccessResult":
            dispatch({ type: ActionType.RESET_TOUCHED });
            return onEditComplete();
          case "AuthFlowUpdateErrorResult":
            return Message.error(result.message);
          case "AuthFlowUpdateValidationErrorResult":
            return dispatch({
              type: ActionType.UPDATE_ERRORS,
              payload: { errors: result.errors }
            });
          default:
            return Message.error("An unexpected error occurred.");
        }
      }
    }
  );

  const saveFlow = React.useCallback(() => {
    const flowInput = {
      id: authorizationProvider.id,
      name: state.name,
      steps: state.steps.map(getFilterStepFields)
    } as AuthFlowInput;
    return updateFlow({ variables: { flowInput: flowInput } });
  }, [authorizationProvider.id, state.name, state.steps, updateFlow]);

  return (
    <Root>
      <Header>
        <LeftSide>
          <h2>Custom Authorization Provider</h2>
        </LeftSide>
        <RightSide>
          <Button onClick={onCloseButtonClick}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!state.touched}
            loading={loading}
            onClick={saveFlow}
          >
            Save
          </Button>
        </RightSide>
      </Header>

      <Form hideRequiredMark layout="vertical">
        <Form.Item label="Name">
          <Input
            value={state.name}
            placeholder="Name"
            onChange={e =>
              dispatch({
                type: ActionType.UPDATE_NAME,
                payload: { name: e.target.value }
              })
            }
          />
        </Form.Item>
        <Form.Item label="Type">
          <Select disabled value={0}>
            <Select.Option value={0}>Custom Authorization</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <FlowConfiguration
        flow={authorizationProvider}
        state={state}
        dispatch={dispatch}
      />
    </Root>
  );
};

export default CustomAuthProviderDrawer;
