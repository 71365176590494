import styled from "styled-components";

export const Cols = styled.div`
  display: flex;
`;

export const CanvasContainer = styled.div`
  flex: 1 1 auto;
  padding: 32px 32px 0;
  background: ${props => props.theme.backgroundColor};
`;

export const RightMenu = styled.aside`
  position: relative;
  flex: 0 0 ${props => props.theme.configPanelWidth};
`;
