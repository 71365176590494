import React from "react";

import { AttributeValueInputProps } from "..";
import { TIME_DISPLAY_FORMAT, TIME_SAVE_FORMAT } from "../../../../constants";
import MomentFormItemPopper from "../../MomentFormItemPopper";
import TimePicker from "../../TimePicker";

export default function TimeInput({
  form,
  fieldOptions,
  displayNullPlaceholder,
  disabled,
  preventOverflow,
  onChange,
  value,
  sourceName,
  sourceType,
  generated,
  onFocus,
  onBlur
}: AttributeValueInputProps) {
  if (generated) return <em>This field will be set automatically.</em>;

  const decorator = form?.getFieldDecorator
    ? form.getFieldDecorator(sourceName, {
        initialValue: value,
        ...fieldOptions
      })
    : (comp: React.ReactNode) => comp;

  return (
    <>
      {decorator(
        <MomentFormItemPopper
          sourceName={sourceName}
          sourceType={sourceType}
          fieldOptions={fieldOptions}
          onChange={onChange ? onChange : () => null}
          getFieldValue={form ? form.getFieldValue : () => null}
          displayNullPlaceholder={displayNullPlaceholder}
          disabled={disabled}
          preventOverflow={preventOverflow}
          saveFormat={TIME_SAVE_FORMAT}
          displayFormat={TIME_DISPLAY_FORMAT}
          PopoverPicker={TimePicker}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    </>
  );
}
