import { SpaceComponentObject, SpaceComponentType } from "../../../types";
import { PSEUDO_CONTAINER_MAP } from "../constants";
import { ComponentNode, RootNode } from "../SpaceRoot/RenderTreeContext";
import { SUPERFICIAL_COMPONENT_TYPES } from "../SpaceRoot/SpaceComponent/constants";
import { findSpaceComponentPackage } from "../SpaceRoot/SpaceContext/StableSpaceContext";

type PackageLike = { isPseudoComponent?: boolean };
export type PackageLocator = (t: SpaceComponentType) => PackageLike;

// fromComponent returns a RenderTree from a componentTreeNode
// that includes pseudo components.
export const fromComponent = (obj: SpaceComponentObject, path = ""): ComponentNode => {
  path = path ? `${path}.${obj.slug}` : obj.slug;

  const root: ComponentNode = {
    component: obj,
    children: [],
    path,
    output: {},
    parent: undefined as any
  };
  let current = root;

  const pseudoComponents = PSEUDO_CONTAINER_MAP[obj.type];
  const addPseudoComponent = (type: SpaceComponentType, slug: string) => {
    const childPath = `${path}.${slug}[]`;
    const pseudo: ComponentNode = {
      component: { type, slug } as SpaceComponentObject,
      parent: current,
      path: childPath,
      output: {},
      children: []
    };
    current.children.push(pseudo);
    return pseudo;
  };
  if (pseudoComponents && pseudoComponents.length) {
    const [head, ...tail] = pseudoComponents;
    const pseudo = addPseudoComponent(head[0], head[1]);
    tail.forEach(([type, slug]) => {
      addPseudoComponent(type, slug);
    });
    current = pseudo;
    path = pseudo.path;
  }

  for (let i = 0; i < obj.componentTreeNodes.length; ++i) {
    const node = obj.componentTreeNodes[i];
    // Skip over superficial components, directly processing their children
    if (SUPERFICIAL_COMPONENT_TYPES.includes(node.type)) {
      for (let j = 0; j < node.componentTreeNodes.length; ++j) {
        const descendant = fromComponent(node.componentTreeNodes[j], path);
        descendant.parent = current;
        current.children.push(descendant);
      }
    } else {
      const child = fromComponent(node, path);
      child.parent = current;
      current.children.push(child);
    }
  }
  return root;
};

const collectChildrenOfSuperficialComponents = (nodes: SpaceComponentObject[]) => {
  const childrenOfSuperficial = nodes
    .filter(n => SUPERFICIAL_COMPONENT_TYPES.includes(n.type))
    .flatMap(n => n.componentTreeNodes);

  let nextCollected = childrenOfSuperficial.filter(
    n => !SUPERFICIAL_COMPONENT_TYPES.includes(n.type)
  );

  const superficials = childrenOfSuperficial.filter(n =>
    SUPERFICIAL_COMPONENT_TYPES.includes(n.type)
  );

  if (superficials.length) {
    nextCollected = nextCollected.concat(
      collectChildrenOfSuperficialComponents(superficials)
    );
  }
  return nextCollected;
};

// fromComponent returns a RenderTree from an array of
// componentTreeNodes that includes pseudo components.
export const fromComponents = (
  objects: SpaceComponentObject[],
  path = "",
  includeSuperficial = false // TODO: superficial is now only HEADER and should be removed
): RootNode => {
  const root: RootNode = {
    children: [],
    path: path,
    parent: undefined
  };
  let nodes: ComponentNode[] = [];
  if (includeSuperficial) {
    nodes = objects.map(n => fromComponent(n, path));
  } else {
    const superficialChildren = collectChildrenOfSuperficialComponents(objects);
    nodes = objects
      .filter(o => includeSuperficial || !SUPERFICIAL_COMPONENT_TYPES.includes(o.type))
      .concat(superficialChildren)
      .map(n => fromComponent(n, path));
  }
  nodes.forEach(n => (n.parent = root));
  root.children = nodes;
  return root;
};

// find returns the value of the first ComponentNode in
// the RenderTree where predicate is true, and undefined
// otherwise.
export const find = (
  tree: ComponentNode | RootNode,
  predicate: (node: ComponentNode) => boolean
): ComponentNode | undefined => {
  if ((tree as ComponentNode).component && predicate(tree as ComponentNode)) {
    return tree as ComponentNode;
  }
  for (let i = 0; i < tree.children.length; ++i) {
    const found = find(tree.children[i], predicate);
    if (found) return found;
  }
};

// findPivotInOtherTree finds a ComponentNode in another RenderTree that
// matches on slug instead of matching the reference of the object because
// the references are not the same.  If the pivot is a pseudo node, then
// the first ancestor with a unique slug is found in the pivot tree (aka the
// container).  The otherTree is then searched for the a matching container by
// slug and a pseudo node is then matched as a descendent of the otherTree
// container by the original pivot slug.
export const findPivotInOtherTree = (
  otherTree: RootNode | ComponentNode,
  pivot: ComponentNode,
  findPackage: PackageLocator = t => findSpaceComponentPackage(t)
): ComponentNode | undefined => {
  const _isComponentNode = (n: any): n is ComponentNode => {
    return n && n.component;
  };

  const _getContainer = (node: ComponentNode): ComponentNode | undefined => {
    let n: ComponentNode | RootNode = node;
    while (_isComponentNode(n) && findPackage(n.component.type).isPseudoComponent) {
      n = n.parent;
    }
    return _isComponentNode(n) ? n : undefined;
  };

  // If this is a pseudo component, find the parent container
  // node before searching by slug because pseudo component
  // slugs are only unique within a container scope.
  const container = _getContainer(pivot);
  if (!container) return;

  // Now find the container node in the other tree by slug.
  const other = find(otherTree, n => n.component.slug === container.component.slug);
  if (!other) return;

  // Now find the pivot node (if it was a pseudo node) in
  // the other tree because the descendent pseudo node slugs
  // are unique.
  let pivotInOtherTree = find(other, n => n.component.slug === pivot.component.slug);

  // in some cases (ie. repeateditem), the pseudo node is part of the
  // render tree (used for config) but does not exist in the component tree
  // (ie. form input components). for this case, if otherTreePivot is not found,
  // check its parent.
  if (!pivotInOtherTree && _isComponentNode(pivot.parent)) {
    pivotInOtherTree = find(
      other,
      n => n.component.slug === (pivot.parent as ComponentNode).component.slug
    );
  }
  return pivotInOtherTree;
};

export const collectComponentNodes = (
  node: RootNode | ComponentNode,
  nodes: Set<ComponentNode> = new Set()
) => {
  if ("component" in node) {
    nodes.add(node);
  }

  node.children.forEach(c => collectComponentNodes(c, nodes));

  return nodes;
};

// Finds a ComponentNode in a RenderTree based on slug equality. If an ancestor slug
// is provided, found nodes must have an ancestor whose slug matches that parameter.
// This is useful for finding the correct node for pseudo components, as pseudo component
// slugs are not unique.
export const findComponentNodeBySlug = (
  node: RootNode | ComponentNode,
  slug: string,
  ancestorSlug?: string
): ComponentNode | undefined => {
  if ("component" in node && node.component.slug === slug) {
    if (!ancestorSlug) {
      return node;
    }
    let ancestorNode = node.parent as ComponentNode;
    while (ancestorNode) {
      if ("component" in ancestorNode && ancestorNode.component.slug === ancestorSlug) {
        return node;
      }
      ancestorNode = ancestorNode.parent as ComponentNode;
    }
  }
  let match;
  for (let i = 0; i < node.children.length; i++) {
    match = findComponentNodeBySlug(node.children[i], slug, ancestorSlug);
    if (match) return match;
  }
};
