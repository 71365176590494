import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button, Skeleton } from "antd";
import _ from "lodash";
import styled from "styled-components";

import { GET_AUDIT_LOGS } from "../../../graphql/queries";

import AuditLogEntry from "./AuditLogEntry";

export const AuditLogContainer = styled.div`
  display: flex;
  flex-direction: column;

  .record {
    min-height: 54px;
    border-top: solid 1px ${props => props.theme.tableBorderColor};
    border-right: solid 1px ${props => props.theme.tableBorderColor};
    border-left: solid 1px ${props => props.theme.tableBorderColor};
    padding: 16px 22px;
    display: block;
    overflow-wrap: break-word;

    p {
      margin-bottom: 0px;
    }
  }

  .record:last-child {
    border-bottom: solid 1px ${props => props.theme.tableBorderColor};
  }

  button.loadMore {
    align-self: center;
    margin-top: 14px;
  }
`;

interface AuditLogProps {
  userId?: string;
  spaceId?: string;
  functionId?: string;
  environmentId?: string;
}

const AuditLog = (props: AuditLogProps) => {
  const { userId, spaceId, functionId, environmentId } = props;
  const { data, loading, error, fetchMore } = useQuery(GET_AUDIT_LOGS, {
    variables: {
      user: userId,
      space: spaceId,
      function: functionId,
      environment: environmentId
    },
    fetchPolicy: "network-only"
  });

  if (loading) return <Skeleton active />;
  if (error) throw error;

  const edges = _.get(data, "allAuditLogs.edges", []);
  if (_.isEmpty(edges)) {
    return !!userId ? (
      <p>This user has no activity at this time.</p>
    ) : (
      <p>There is no activity at this time.</p>
    );
  }

  const onLoadMore = (fetchMore: (p: any) => any, cursor: string) => {
    fetchMore({
      variables: {
        cursor: cursor,
        user: props.userId,
        space: props.spaceId,
        function: props.functionId,
        environment: environmentId
      },
      updateQuery: (
        previousResult: any,
        { fetchMoreResult }: { fetchMoreResult: any }
      ) => {
        const newEdges = fetchMoreResult.allAuditLogs.edges;
        const pageInfo = fetchMoreResult.allAuditLogs.pageInfo;
        return newEdges.length
          ? {
              ...previousResult,
              allAuditLogs: {
                ...previousResult.allAuditLogs,
                edges: [...previousResult.allAuditLogs.edges, ...newEdges],
                pageInfo
              }
            }
          : previousResult;
      }
    });
  };

  return (
    <AuditLogContainer>
      <div>
        {edges.map((edge: any, i: number) => {
          const humanReadableNode = (
            <AuditLogEntry entry={edge.node} showActor={!userId} />
          );
          return humanReadableNode ? (
            <div key={i} className="record">
              {humanReadableNode}
            </div>
          ) : null;
        })}
      </div>
      {data.allAuditLogs.pageInfo.hasNextPage && (
        <Button
          className="loadMore"
          type="primary"
          onClick={() => onLoadMore(fetchMore, data.allAuditLogs.pageInfo.endCursor)}
        >
          Load More
        </Button>
      )}
    </AuditLogContainer>
  );
};

export default AuditLog;
