import React from "react";

import { Button, Icon, Input, Select } from "antd";
import classNames from "classnames";
import { HotKeys } from "react-hotkeys";
import styled from "styled-components";
import { Key } from "ts-key-enum";

import useAuthUser from "../../common/hooks/useAuthUser";
import useDebouncedValue from "../../common/hooks/useDebouncedValue";
import { Tab as BaseTab } from "../../common/StyledComponents";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import {
  useSpaceFilters,
  useSpacesContext
} from "../hooks/useSpacesManager/useSpacesManager";

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 30px;
  font-size: 12px;
`;

const ControlGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacermd};
  flex-wrap: wrap;
  padding-bottom: ${props => props.theme.spacerlg};
`;

const SearchButtonWrapper = styled.div`
  padding: 5px;
  padding-left: 0;
  margin-right: -5px;
  cursor: pointer;
`;

const SearchButton = styled(Icon)`
  font-size: 18px;
  &.searchFocused,
  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`;

const SearchInput = styled(Input)`
  width: 0;
  height: 30px;
  padding: 0;
  border-width: 0;
  transition: width 0.3s;
  &.active {
    width: 200px;
    padding: 5px;
    border-width: 1px;
  }
`;

const Tab = styled(BaseTab)`
  &.searchActive {
    opacity: 0.5;
    max-width: 0;
    padding: 0;
  }
`;

const SortSelect = styled(Select)`
  transition: 0.3s;
  &.searchActive {
    opacity: 0;
  }
  .ant-select-selection {
    background: transparent;
    color: ${props => props.theme.textColor};
    border: none;
    font-size: 12px;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-select-arrow-icon {
    color: ${props => props.theme.textColor};
  }
  .ant-select-selection__rendered {
    margin-left: 0;
  }
`;

const SORT_OPTIONS = [
  { name: "A-Z", value: "name" },
  { name: "Z-A", value: "-name" }
] as const;

export const emptyFilter = {
  onlyUnpublished: undefined,
  isFavorite: undefined,
  isFeatured: undefined,
  allSpaces: undefined
};

export type SpaceSortOption = ReturnType<typeof useSpaceFilters>["filters"]["orderBy"];

export function SpaceSortingSelect({
  value,
  includeFavorites,
  searchActive,
  onChange
}: {
  value: SpaceSortOption | undefined;
  includeFavorites: boolean;
  searchActive: boolean;
  onChange: (value: SpaceSortOption) => void;
}) {
  return (
    <SortSelect
      data-test="spaceSort"
      value={value}
      className={classNames({ searchActive })}
      dropdownMatchSelectWidth={false}
      onChange={orderBy => {
        onChange(orderBy as SpaceSortOption);
      }}
    >
      {includeFavorites && (
        <Select.Option value="favorite" key="favorite">
          Custom
        </Select.Option>
      )}
      {SORT_OPTIONS.map(o => (
        <Select.Option key={o.value} value={o.value}>
          {o.name}
        </Select.Option>
      ))}
    </SortSelect>
  );
}

function SpaceFilters({
  hasFavorites,
  hasFeatured,
  className,
  searchActive,
  onActivateSearch,
  onDeactivateSearch
}: {
  hasFavorites: boolean | undefined;
  hasFeatured: boolean | undefined;
  className?: string;
  searchActive: boolean;
  onActivateSearch: () => void;
  onDeactivateSearch: () => void;
}) {
  const authUser = useAuthUser();
  const canManageSpaces = authUser.isAdmin || authUser.isSpaceAdmin;
  const { filters, updateFilters } = useSpaceFilters();
  const { queryVariables, activeQuery } = useSpacesContext();

  if (
    canManageSpaces &&
    !hasFavorites &&
    !hasFeatured &&
    !Object.entries(filters).length
  ) {
    updateFilters({ allSpaces: true });
  }

  // Only "favorites" may be sorted by "favorite"
  React.useEffect(() => {
    if (filters.orderBy?.match("favorite") && !filters.isFavorite) {
      updateFilters({ orderBy: "name" });
    }
  }, [filters, updateFilters]);

  if (hasFavorites === undefined) return <Root />;

  return (
    <Root className={className}>
      <ControlGroup>
        <Search
          onActivateSearch={() => {
            onActivateSearch();
          }}
          onDeactivateSearch={() => {
            onDeactivateSearch();
          }}
        />
        {hasFavorites && (
          <Tab
            className={classNames({
              active: activeQuery === "favorites",
              searchActive
            })}
            onClick={() => {
              updateFilters({
                ...emptyFilter,
                isFavorite: true,
                orderBy: "favorite"
              });
            }}
          >
            Favorites
          </Tab>
        )}
        <Tab
          className={classNames({ active: activeQuery === "shared", searchActive })}
          onClick={() => {
            updateFilters({
              ...emptyFilter,
              isFeatured: true
            });
          }}
        >
          Shared with you
        </Tab>
        {canManageSpaces && (
          <>
            <Tab
              className={classNames({
                active: activeQuery === "all",
                searchActive
              })}
              onClick={() => {
                updateFilters({ ...emptyFilter, allSpaces: true });
              }}
            >
              All spaces
            </Tab>
            <Tab
              className={classNames({
                active: activeQuery === "drafts",
                searchActive
              })}
              onClick={() => {
                updateFilters({
                  ...emptyFilter,
                  onlyUnpublished: true
                });
              }}
            >
              Drafts
            </Tab>
          </>
        )}
      </ControlGroup>
      <ControlGroup>
        <SpaceSortingSelect
          value={
            queryVariables.orderBy as ReturnType<
              typeof useSpaceFilters
            >["filters"]["orderBy"]
          }
          includeFavorites={activeQuery === "favorites"}
          searchActive={searchActive}
          onChange={orderBy => {
            updateFilters({
              orderBy: orderBy as typeof SORT_OPTIONS[number]["value"]
            });
          }}
        />
      </ControlGroup>
    </Root>
  );
}

export default withErrorBoundary(SpaceFilters);

const SearchRoot = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacerxs};
`;

const SearchInputWrapper = styled.div`
  position: relative;
`;

const ClearSearchButton = styled(Button)`
  position: absolute;
  top: 3px;
  right: 0;
  color: ${props => props.theme.textColorMid};
`;

function Search({
  onActivateSearch,
  onDeactivateSearch
}: {
  onActivateSearch: () => void;
  onDeactivateSearch: () => void;
}) {
  const { filters, updateFilters } = useSpaceFilters();
  const searchElRef = React.useRef<Input>(null);
  const [nameContains, setNameContains] = React.useState<null | string>(
    filters.nameContains || null
  );
  const [searchFocused, setSearchFocused] = React.useState(false);
  const [searchSynced, setSearchSynced] = React.useState(true);
  const debouncedNameContains = useDebouncedValue(nameContains, 300);

  React.useEffect(() => {
    // Pending debounced search term from search input.
    // Sync url to input
    if (!searchSynced && debouncedNameContains !== filters.nameContains) {
      if (!debouncedNameContains && !filters.nameContains) return;
      updateFilters({
        nameContains: debouncedNameContains ? debouncedNameContains : undefined
      });
      setSearchSynced(true);
    }

    // Url out of sync with input, likely due to navigation event.
    // Sync input to url.
    if (
      searchSynced &&
      (!!debouncedNameContains || !!filters.nameContains) &&
      debouncedNameContains !== filters.nameContains
    ) {
      setNameContains(filters.nameContains || null);
      if (!filters.nameContains && nameContains) {
        onDeactivateSearch();
        setNameContains(null);
        searchElRef.current?.blur();
      }
    }
  }, [
    debouncedNameContains,
    nameContains,
    filters,
    searchSynced,
    updateFilters,
    onDeactivateSearch
  ]);

  return (
    <HotKeys
      handlers={{
        SEARCH: evt => {
          searchElRef.current?.focus();
          evt?.preventDefault();
        }
      }}
      focused
      attach={window}
    >
      <SearchRoot>
        <SearchButtonWrapper
          data-test="searchButton"
          onMouseDown={(ev: React.MouseEvent) => {
            if (searchFocused) {
              ev.preventDefault();
            }
          }}
          onClick={() => {
            if (nameContains === null) {
              setNameContains("");
              if (searchElRef.current) {
                searchElRef.current.focus();
              }
            }
          }}
        >
          <SearchButton type="search" className={classNames({ searchFocused })} />
        </SearchButtonWrapper>
        <SearchInputWrapper>
          <SearchInput
            data-test="searchInput"
            ref={searchElRef}
            placeholder="Search…"
            value={nameContains || ""}
            className={classNames({ active: nameContains !== null })}
            onChange={e => {
              setSearchSynced(false);
              setNameContains(e.target.value);
            }}
            onFocus={() => {
              if (nameContains === null) {
                onActivateSearch();
                setNameContains("");
              }
              setSearchFocused(true);
            }}
            onBlur={() => {
              setSearchFocused(false);
              if (nameContains === "" || nameContains === null) {
                onDeactivateSearch();
                setNameContains(null);
              }
            }}
            onKeyDown={evt => {
              if (evt.key === Key.Escape) {
                searchElRef.current?.blur();
              }
            }}
          />
          {nameContains !== null && (
            <ClearSearchButton
              data-test="clearSearchButton"
              icon="close"
              type="link"
              size="small"
              onClick={() => {
                onDeactivateSearch();
                setNameContains(null);
                searchElRef.current?.blur();
                if (filters.nameContains) {
                  setSearchSynced(false);
                }
              }}
            />
          )}
        </SearchInputWrapper>
      </SearchRoot>
    </HotKeys>
  );
}
