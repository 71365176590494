import React, { useState, useEffect } from "react";

import { useStripe } from "@stripe/react-stripe-js";
import { Modal } from "antd";
import gql from "graphql-tag";
import moment from "moment";
import { useQuery, useMutation } from "react-apollo";
import PaymentIcon from "react-payment-icons";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import ButtonNew from "../../../components/common/ButtonNew/ButtonNew";
import useAuthUser from "../../../components/common/hooks/useAuthUser";
import { LinearProgressBar } from "../../../components/common/ProgressBar/LinearProgressBar";
import { H2, H3, H4, H6, Pill, B3 } from "../../../components/common/StyledComponents";
import { Urls } from "../../../constants";
import { defaultTheme, colors } from "../../../cssConstants";
import {
  GET_ACCOUNT_DATA,
  GET_ALL_USERS_DATA,
  GET_ALL_INVITES_DATA
} from "../../../graphql/queries";
import {
  PaymentIntent,
  Edge,
  UserNode,
  OrganizationInviteNode,
  EnvironmentNode
} from "../../../types";
import Message from "../../common/Message";
import { ModalNew } from "../../common/Modal";
import { planName, nextPlanUp, formatDescription } from "../../util/billing";
import { isInternalEmail } from "../../util/users";

import { BillingContext } from "./BillingContext";
import { DowngradeModal } from "./DowngradeModal";
import DowngradeNotice from "./DowngradeNotice";
import { useStripeCustomer } from "./hooks/useStripeCustomer";
import UpdateBillingInfoModal from "./UpdateBillingInfoModal";
import UpdatePaymentModal from "./UpdatePaymentModal";

const GET_DOWNGRADE_REQUIREMENTS = gql`
  query DowngradeRequirementsQuery {
    getDowngradeRequirements {
      changesRequired
      schedule
      requirements {
        userLimit {
          value
          limit
        }
        sso {
          value
          limit
        }
        permissions {
          value
          limit
        }
        roles {
          value
          limit
        }
        twoFactor {
          value
          limit
        }
        environments {
          value
          limit
        }
        dataSources {
          value
          limit
        }
      }
    }
  }
`;

export const ALL_ENVIRONMENTS = gql`
  query AllEnvironments {
    allEnvironments {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
          dataSourceCount
          isDefault
        }
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      ok
    }
  }
`;

export const CANCEL_PAYMENT_MUTATION = gql`
  mutation CancelPaymentMethod {
    cancelPaymentMethod {
      ok
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 40px;
`;

const CCDots = styled.span`
  font-size: 30px;
  margin-right: 6px;
`;

const ContentBox = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  border: 1px solid ${props => props.theme.borderGrey};
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  min-height: 260px;
  display: flex;
  flex-direction: column;

  .credit-card {
    width: 80%;
    margin: 0 0 0 20px;
  }

  a {
    display: block;
    margin-bottom: 20px;
  }
`;

const Separator = styled.hr`
  margin-top: 12px;
  margin-bottom: 24px;
`;

const DowngradeNoticeContainer = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  border: 1px solid ${props => props.theme.borderGrey};
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Card = styled(ContentBox)`
  background-color: ${props => props.theme.newContainerPrimary};
  min-height: 260px;
`;

const CreditCardIcon = styled(PaymentIcon)`
  width: 60px;
`;

const CreditCardIconSmall = styled(PaymentIcon)`
  width: 30px;
  margin-right: 10px;
  border-radius: 4px;
`;

const CardRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  margin-right: 20px;
  gap: 20px;

  &::before {
    content: none;
  }
`;

const RemoveCardLink = styled(Link)`
  color: ${props => props.theme.errorColor};
`;

const CardColumn = styled.div`
  flex: 0.333;
`;

const StackButton = styled(ButtonNew)`
  margin-top: 12px;
`;

const CancelButton = styled(ButtonNew)`
  margin-top: auto;
`;

const BlankSlateH3 = styled(H3)`
  text-align: center;
  margin-top: 30px;
  color: ${props => props.theme.textColorLight};
`;

const DefaultStatus = styled(Pill)`
  text-transform: capitalize;
  span {
    color: white;
  }
`;

const PaidStatus = styled.span`
  background-color: ${props => props.color || props.theme.linkColor};
  color: white;
  display: inline;
  padding: 2px 8px;
  border-radius: 4px;

  span {
    color: white;
  }
`;

const InvoiceTable = styled.table`
  width: calc(100% - 20px);
  border: 1px solid ${props => props.theme.borderGrey};
  border-radius: 10px;
  border-collapse: collapse;
  margin-bottom: 70px;
  margin-right: 20px;
  border-collapse: separate;
  border-spacing: 0;

  thead tr {
    background-color: ${props => props.theme.backgroundColor};
    border: 1px solid ${props => props.theme.borderGrey};
    text-align: left;
    border-radius: 10px;
  }

  th,
  td {
    padding: 20px;
  }

  tbody tr {
    border-bottom: 1px solid ${props => props.theme.borderGrey};
  }
`;

const BlankBillingHistory = styled.td`
  text-align: center;
  padding: 50px !important;
`;

const CreditCardNumber = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;

const DeleteAccountLink = styled(Link)`
  color: ${props => props.theme.errorColor};
  position: absolute;
  top: 56px;
  right: 40px;
`;

const CardTitle = styled(B3)`
  margin-bottom: 10px;
`;

const CardHeader = styled(H4)`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const CurrentPlanHeader = styled(CardHeader)`
  margin-bottom: 10px;
`;

const InvoiceTotalHeader = styled(B3)`
  margin-bottom: 10px;
`;

const InvoiceTotal = styled(H4)`
  margin-bottom: 20px;
`;

const TeamContainer = styled(Card)`
  min-height: auto;
  width: 100%;
`;

const invoiceStatus = (status: string) => {
  switch (status) {
    case "succeeded":
      return (
        <PaidStatus>
          <B3>Paid</B3>
        </PaidStatus>
      );
    default:
      return (
        <DefaultStatus color={defaultTheme.buttonColor}>
          <B3>{status}</B3>
        </DefaultStatus>
      );
  }
};

interface AccountBillingSettingsProps {
  paymentIntentClientSecret: string | null;
}

const AccountBillingSettings = ({
  paymentIntentClientSecret
}: AccountBillingSettingsProps) => {
  const [showCardUpdateModal, setShowCardUpdateModal] = useState(false);
  const [showBillingUpdateModal, setShowBillingUpdateModal] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState(false);
  const [formValidation, setFormValidation] = useState(false);
  const stripe = useStripe();
  const navigate = useNavigate();
  const { authUser } = useAuthUser();

  const { setCompanyInfo, contextValue } = useStripeCustomer();

  const { data: downgradeRequirementData } = useQuery(GET_DOWNGRADE_REQUIREMENTS, {
    fetchPolicy: "cache-and-network"
  });

  const { data: accountData, loading: accountDataLoading } = useQuery(
    GET_ACCOUNT_DATA,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: data => {
        const sub = data.stripeSubscription.subscription;
        if (typeof sub !== "undefined") {
          setCompanyInfo(sub);
        }
      }
    }
  );
  const { data: usersData } = useQuery(GET_ALL_USERS_DATA, {
    fetchPolicy: "cache-and-network"
  });
  const { data: invitesData } = useQuery(GET_ALL_INVITES_DATA, {
    fetchPolicy: "cache-and-network"
  });

  const { data: envData } = useQuery(ALL_ENVIRONMENTS, {
    fetchPolicy: "network-only"
  });

  const envs = envData?.allEnvironments?.edges.map(
    (e: Edge<EnvironmentNode>) => e.node
  );

  const users = usersData?.allUsers?.edges.map((e: Edge<UserNode>) => e.node) || [];
  const invites =
    invitesData?.allOrganizationInvites?.edges
      .map((e: Edge<OrganizationInviteNode>) => e.node)
      .filter((invite: OrganizationInviteNode) => !isInternalEmail(invite.email)) || [];
  const activeUsers = users.filter((user: UserNode) => {
    return user.status === "active" && !isInternalEmail(user.email);
  });

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_MUTATION);

  const [deleteCard] = useMutation(CANCEL_PAYMENT_MUTATION, {
    onCompleted: () => window.location.reload()
  });

  const handleCancellation = async () => {
    // Cancellation affects the state of the entire app, so we need to do a full refresh here.
    await cancelSubscription();

    window.location.href = "/settings/billing";
  };

  const showRemoveCardConfirmation = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    Modal.confirm({
      title: "Are you sure you want to remove this payment method?",
      content: (
        <>
          This action <strong>cannot</strong> be undone.
        </>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => deleteCard(),
      width: 520
    });
  };

  const openPaymentForm = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setShowCardUpdateModal(true);
  };

  const openBillingForm = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setShowBillingUpdateModal(true);
  };

  useEffect(() => {
    if (paymentIntentClientSecret && stripe) {
      stripe
        .retrievePaymentIntent(paymentIntentClientSecret)
        .then(({ paymentIntent }: { paymentIntent: PaymentIntent }) => {
          switch (paymentIntent?.status) {
            case "succeeded":
              Message.success("Your plan has been changed successfully.");
              break;
            case "processing":
              Message.info(
                "Payment processing. We'll update you when payment is received."
              );
              break;

            case "requires_payment_method":
              Message.error("Payment failed. Please try another payment method.");
              break;
            default:
              Message.error("Something went wrong.");
              break;
          }
        });
    }
  }, [paymentIntentClientSecret, stripe]);

  if (!stripe || accountDataLoading) {
    return null;
  }

  const { stripeSubscription, getUpcomingInvoice, getAllPaymentIntents } = accountData;

  const subscription = stripeSubscription?.subscription;
  const plan = subscription?.plan;
  const product = plan?.product;
  const schedule = downgradeRequirementData?.getDowngradeRequirements?.schedule;
  const requirements = downgradeRequirementData?.getDowngradeRequirements?.requirements;
  const changesRequired =
    downgradeRequirementData?.getDowngradeRequirements?.changesRequired;
  const currentPeriodEnd = subscription?.current_period_end;
  const nextTier = nextPlanUp(product?.name);

  const nextInvoiceItem =
    getUpcomingInvoice?.invoice?.lines?.data[
      getUpcomingInvoice?.invoice?.lines?.data.length - 1
    ];
  const nextInvoiceMonthlyAmount =
    nextInvoiceItem?.price.recurring.interval === "month"
      ? nextInvoiceItem?.price.unit_amount / 100
      : nextInvoiceItem?.price.unit_amount / 12 / 100;

  const paymentIntents = (getAllPaymentIntents?.data || []).filter(
    (intent: PaymentIntent) =>
      intent.status !== "canceled" && intent.status !== "requires_payment_method"
  );

  return (
    <BillingContext.Provider value={contextValue}>
      <div>
        <Header>
          <DeleteAccountLink onClick={() => setShowAccountDeleteModal(true)} to={"#"}>
            Delete account
          </DeleteAccountLink>
        </Header>

        {schedule &&
          schedule.phases.length > 1 &&
          schedule.phases[0].plans[0].plan !== schedule.phases[1].plans[0].plan && (
            <CardRow>
              <DowngradeNoticeContainer>
                <DowngradeNotice
                  schedule={schedule}
                  changesRequired={changesRequired}
                  requirements={requirements}
                  product={product}
                  envs={envs}
                />
              </DowngradeNoticeContainer>
            </CardRow>
          )}

        <CardRow>
          <CardColumn>
            <Card>
              <CardTitle color={colors.surfaceSecondary}>Current plan</CardTitle>
              {product ? (
                <CurrentPlanHeader>
                  {planName(product.name)} (
                  {plan.interval === "month" ? "Monthly" : "Yearly"})
                </CurrentPlanHeader>
              ) : (
                <h1>Basic</h1>
              )}

              {product && (
                <>
                  {plan && plan.interval === "month" ? (
                    <B3>
                      <Link
                        to={{
                          pathname: `/settings/change-plan/${product.name.toLowerCase()}/annual`
                        }}
                      >
                        Switch to annual billing and save
                      </Link>
                    </B3>
                  ) : (
                    <B3>
                      <Link
                        to={{
                          pathname: `/settings/change-plan/${product.name.toLowerCase()}/monthly`
                        }}
                      >
                        Switch to monthly billing
                      </Link>
                    </B3>
                  )}
                </>
              )}

              {plan && (
                <CancelButton
                  size="lg"
                  type="secondary"
                  onClick={() => setShowDowngradeModal(true)}
                  block={true}
                >
                  Cancel subscription
                </CancelButton>
              )}

              {nextTier && (
                <StackButton
                  size="lg"
                  type="brand"
                  onClick={() => navigate("/settings/change-plan")}
                  block={true}
                >
                  Change plans
                </StackButton>
              )}
            </Card>
          </CardColumn>
          {subscription && getUpcomingInvoice && (
            <CardColumn>
              <Card>
                <CardTitle color={colors.surfaceSecondary}>Next invoice date</CardTitle>
                {subscription ? (
                  <CardHeader>
                    {moment
                      .unix(subscription.current_period_end)
                      .format("MMMM Do, YYYY")}
                  </CardHeader>
                ) : (
                  <h1>None</h1>
                )}

                <Separator />

                <InvoiceTotalHeader color={colors.surfaceSecondary}>
                  Invoice total
                </InvoiceTotalHeader>
                <InvoiceTotal>
                  {getUpcomingInvoice?.invoice ? (
                    <>${getUpcomingInvoice.invoice.amount_due / 100}</>
                  ) : (
                    <>$0.00</>
                  )}
                </InvoiceTotal>
                {getUpcomingInvoice?.invoice && nextInvoiceItem?.price && (
                  <B3>
                    {activeUsers.length + invites.length} seats at{" "}
                    {nextInvoiceMonthlyAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD"
                    })}
                    /user per month paid{" "}
                    {nextInvoiceItem?.price.recurring.interval === "month"
                      ? "monthly"
                      : "annually"}
                  </B3>
                )}
              </Card>
            </CardColumn>
          )}

          <CardColumn>
            <Card>
              <CardTitle color={colors.surfaceSecondary}>Billing and payment</CardTitle>

              {subscription?.default_payment_method ? (
                <>
                  {subscription.default_payment_method.type === "card" && (
                    <>
                      <CreditCardNumber>
                        <CreditCardIcon
                          id={subscription.default_payment_method.card.brand}
                        />
                        <CardHeader className="credit-card">
                          <CCDots>
                            &middot;&middot;&middot;&middot;
                            &middot;&middot;&middot;&middot;
                            &middot;&middot;&middot;&middot;{" "}
                          </CCDots>{" "}
                          {subscription.default_payment_method.card.last4}
                        </CardHeader>
                      </CreditCardNumber>

                      <B3>
                        <Link
                          to={{ pathname: "/settings/billing/manage" }}
                          onClick={openBillingForm}
                        >
                          Update company information
                        </Link>
                      </B3>

                      <B3>
                        <Link
                          to={{ pathname: "/settings/billing/manage" }}
                          onClick={openPaymentForm}
                        >
                          Update credit card information
                        </Link>
                      </B3>

                      {subscription?.cancel_at && (
                        <RemoveCardLink
                          to={{ pathname: "/settings/billing/manage" }}
                          onClick={showRemoveCardConfirmation}
                        >
                          Remove card
                        </RemoveCardLink>
                      )}
                    </>
                  )}
                </>
              ) : (
                <BlankSlateH3>No information on file</BlankSlateH3>
              )}
            </Card>
          </CardColumn>
        </CardRow>

        {subscription && activeUsers.length > 0 && (
          <CardRow>
            <TeamContainer>
              <CardTitle color={colors.surfaceSecondary}>Your team</CardTitle>
              <H4>
                {activeUsers.length + invites.length} of {subscription.quantity} seats
                used
              </H4>
              <LinearProgressBar
                percentage={
                  ((activeUsers.length + invites.length) / subscription.quantity) * 100
                }
              />

              <B3>
                <Link to={{ pathname: "/settings/users" }}>Manage users</Link>
              </B3>
            </TeamContainer>
          </CardRow>
        )}

        <H2>Billing details</H2>

        <InvoiceTable>
          <thead>
            <tr>
              <th>
                <H6>Date</H6>
              </th>
              <th>
                <H6>Description</H6>
              </th>
              <th>
                <H6>Number of seats</H6>
              </th>
              <th>
                <H6>Payment method</H6>
              </th>
              <th>
                <H6>Invoice total</H6>
              </th>
              <th>
                <H6>Status</H6>
              </th>
            </tr>
          </thead>
          <tbody>
            {paymentIntents.length ? (
              <>
                {paymentIntents.map((intent: PaymentIntent) => {
                  return (
                    <tr key={intent.id}>
                      <td>
                        <a
                          href={intent.invoice?.hosted_invoice_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {moment.unix(intent.created).format("MMMM D, YYYY")}
                        </a>
                      </td>
                      <td>
                        {formatDescription(intent.invoice.lines.data[0]?.description)}
                      </td>
                      <td>{intent.invoice.lines.data[0]?.quantity}</td>

                      <td>
                        {intent.payment_method &&
                          intent.payment_method.type === "card" && (
                            <>
                              <CreditCardIconSmall
                                id={intent.payment_method.card?.brand}
                              />
                              &middot;&middot;&middot;&middot;
                              &middot;&middot;&middot;&middot;
                              &middot;&middot;&middot;&middot;{" "}
                              {intent.payment_method.card?.last4}
                            </>
                          )}
                      </td>
                      <td>
                        {(intent.amount / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })}
                      </td>
                      <td>{invoiceStatus(intent.status)}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <BlankBillingHistory colSpan={6}>
                  No billing history
                </BlankBillingHistory>
              </tr>
            )}
          </tbody>
        </InvoiceTable>

        <UpdatePaymentModal
          visible={showCardUpdateModal}
          onCancel={() => setShowCardUpdateModal(false)}
        />

        <UpdateBillingInfoModal
          visible={showBillingUpdateModal}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          onCancel={() => setShowBillingUpdateModal(false)}
        />

        <DowngradeModal
          name="Basic"
          showDowngradeModal={showDowngradeModal}
          onCancel={() => setShowDowngradeModal(false)}
          onOk={handleCancellation}
          currentPlanSlug={authUser?.organization.currentPlan?.slug}
          currentPeriodEnd={currentPeriodEnd}
        />

        <ModalNew
          title="Delete account"
          visible={showAccountDeleteModal}
          okText="Contact support"
          buttonType="brand"
          onOk={() => window.open(Urls.SUPPORT_URL, "_blank")}
          onCancel={() => setShowAccountDeleteModal(false)}
        >
          <p>To delete your account, please contact support.</p>
        </ModalNew>
      </div>
    </BillingContext.Provider>
  );
};

export default AccountBillingSettings;
