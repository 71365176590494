import _ from "lodash";

export const ALPHA_SORT_FN = (key: string) => (a: any, b: any) => {
  const aVal = _.get(a, key) || "";
  const bVal = _.get(b, key) || "";
  return aVal < bVal ? -1 : 1;
};

export const DATETIME_SORT_FN = (key: string) => (a: string, b: string) => {
  const aDate = new Date(_.get(a, key)) || "";
  const bDate = new Date(_.get(b, key)) || "";
  return aDate < bDate ? -1 : 1;
};
