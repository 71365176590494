import React from "react";

import styled from "styled-components";

import {
  useTransformationActionContext,
  useSelectionStateContext
} from "../../layout/TransformationContext/TransformationContext";
import useIsComponentVisible from "../../SpaceRoot/SpaceComponent/common/useIsComponentVisible";
import useNestedStatus from "../../SpaceRoot/SpaceComponent/common/useNestedStatus";
import { useComponentPathContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { useCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import Draggable from "../Draggable";
import { useComponentLayoutContext } from "../LayoutContext/LayoutContext";
import Selection from "../Selection/Selection";
import { ElementLayout } from "../util";

import { ElementProps } from "./AbsoluteElement";
import useLayoutRegistrations from "./useLayoutRegistrations";

export default function StaticElement({ component, children }: ElementProps) {
  const { slug } = component;
  const { layout, elementDOMRect } = useComponentLayoutContext();
  const { select, hover, unhover, startMove } = useTransformationActionContext();
  const { selected } = useSelectionStateContext();
  const { isManaged } = useNestedStatus();
  const { editMode } = useStableSpaceContext();
  const path = useComponentPathContext();
  const { getCanvasPointForPagePoint } = useCanvasViewportContext();
  const measurerRef = React.useRef(null);

  useLayoutRegistrations(slug, layout, measurerRef);
  const isVisible = useIsComponentVisible(component.properties);

  const styles = component.properties.styles;
  const style = React.useMemo(() => {
    return {
      ...(styles ? styles.root || {} : {}),
      ...(layout ? ElementLayout.stripNonStyleProps(layout) : {}),
      display: isVisible ? "block" : "none"
    };
  }, [styles, layout, isVisible]);

  const ancestorSelected = React.useMemo(() => {
    let ancestorSelected = false;
    let parent = component.container;
    while (parent) {
      if (parent.slug === selected) {
        ancestorSelected = true;
        break;
      }
      parent = parent.container;
    }
    return ancestorSelected;
  }, [component, selected]);

  const handleMouseDown = React.useCallback(
    (evt: React.MouseEvent) => {
      // If propogation is stopped while an ancestor is selected, drags will not work.
      if (!ancestorSelected) {
        evt.stopPropagation();
      }
    },
    [ancestorSelected]
  );

  const handleMouseEnter = React.useCallback(() => {
    if (!editMode) return;
    hover(slug);
  }, [editMode, hover, slug]);

  const handleMouseLeave = React.useCallback(() => {
    if (!editMode) return;
    unhover(slug);
  }, [editMode, slug, unhover]);

  const handleClick = React.useCallback(
    (evt: React.MouseEvent) => {
      if (!editMode) return;
      evt.stopPropagation();
      select(slug);
    },
    [editMode, slug, select]
  );

  const handleDragStart = React.useCallback(
    pagePt => {
      const canvasPt = getCanvasPointForPagePoint(pagePt);
      startMove(
        path,
        pagePt,
        layout,
        new DOMRect(canvasPt.x, canvasPt.y, elementDOMRect.width, elementDOMRect.height)
      );
    },
    [path, layout, elementDOMRect, getCanvasPointForPagePoint, startMove]
  );

  return (
    <Root
      data-test={`element-${slug}`}
      style={style}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Draggable disable={ancestorSelected || isManaged} onDragStart={handleDragStart}>
        <Positioner>
          <ChildWrapper ref={measurerRef}>{children}</ChildWrapper>
          <Selection component={component} resizeable={false} />
        </Positioner>
      </Draggable>
    </Root>
  );
}

const Root = styled.div``;

const Positioner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ChildWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
