import { SpaceComponentObject } from "../../../../../../../types";

import ensureSlug from "./ensureSlug";

export default function makeComponent(
  slugs: Set<string> = new Set(),
  { type = "TABLE", ...rest }: Partial<SpaceComponentObject>
): SpaceComponentObject {
  const slug = ensureSlug(type, slugs, rest.slug);

  return {
    type,
    slug,
    name: "",
    componentTreeNodes: [],
    container: null,
    properties: {},
    isRemovable: true,
    functions: { edges: [] },
    ...rest
  };
}
