import { Dispatch } from "react";

import { cloneDeep, omit } from "lodash";

import { deprecate } from "../../../../../../logging";
import {
  SpaceComponentObject,
  SpaceComponentType,
  SpaceComponentPackage
} from "../../../../../../types";
import * as exceptionReporting from "../../../../../util/exceptionReporting";
import { ElementLayout } from "../../../../layout/util";
import {
  SpaceConfigAction,
  BaseConfigAction,
  ComponentConfigState,
  BaseComponentConfigState
} from "../../../../types";
import { UseConfigMutationData } from "../useConfigMutation/useConfigMutation";

import commonComponentReducer from "./componentReducer";
import {
  insertComponentInTree,
  extractState,
  removeComponentFromTree,
  selectAllSlugs
} from "./util";
import makeComponent from "./util/makeComponent";
import moveTreeNode from "./util/moveTreeNode";

export interface TreeNode {
  slug: null | string;
  treeNodes: TreeNode[];
  container: null | TreeNode;
  type: null | SpaceComponentType;
}

export interface SpaceConfigState {
  name: string;
  hasNameError: boolean;
  components: Record<string, ComponentConfigState>;
  tree: TreeNode | null;
  elementLayouts: Map<string, Partial<ElementLayout>>;
  apiErrors: UseConfigMutationData["spaceUpdate"]["source"]["slugs"];
  removedSlugs?: Set<string>;
  submitted: boolean;
  dirty: boolean;
  isStateLoaded: boolean;
  touchedComponents: Set<string>; // components that have been updated (we currently only validate components that have been touched),
  packages: Map<SpaceComponentType, SpaceComponentPackage>;
}

interface SetComponentAction extends BaseConfigAction {
  type: "SET_COMPONENT";
  payload: { slug: string; component: SpaceComponentObject };
}

export interface InsertComponentPayload {
  componentType: SpaceComponentType;
  parentSlug: string | null;
  componentConfig?: Partial<SpaceComponentObject>;
}
interface InsertComponentAction extends BaseConfigAction {
  type: "INSERT_COMPONENT";
  payload: InsertComponentPayload;
}

// TODO: Rename once all components are flexible layout ;)
interface InsertFlexibleLayoutComponent extends BaseConfigAction {
  type: "INSERT_FLEXIBLE_LAYOUT_COMPONENT";
  payload: {
    componentType: SpaceComponentType;
    layout: ElementLayout;
  };
}

interface UpdateComponentLayout extends BaseConfigAction {
  type: "UPDATE_COMPONENT_LAYOUT";
  payload: {
    slug: string;
    layout: Partial<ElementLayout>;
  };
}

interface SaveSuccessAction extends BaseConfigAction {
  type: "HANDLE_SAVE";
}

interface InitNewSpaceAction extends BaseConfigAction {
  type: "INIT_NEW_SPACE";
  payload: { clientProvidedComponents: SpaceComponentObject[] };
}

interface InitExistingSpaceAction extends BaseConfigAction {
  type: "INIT_EXISTING_SPACE";
  payload: { name: string; components: SpaceComponentObject[] };
}

interface SetSpaceNameAction extends BaseConfigAction {
  type: "SET_SPACE_NAME";
  payload: { name: string };
}

interface RemoveComponentAction extends BaseConfigAction {
  type: "REMOVE_COMPONENT";
  payload: {
    slug: string;
  };
}

interface SetConfigErrors extends BaseConfigAction {
  type: "SET_API_ERRORS";
  payload: {
    errors: UseConfigMutationData["spaceUpdate"]["source"]["slugs"];
  };
}

interface SetComponentErrorState extends BaseConfigAction {
  type: "SET_COMPONENT_ERROR_STATE";
  payload: {
    slug: string;
    hasError: boolean;
  };
}

interface Submit extends BaseConfigAction {
  type: "SUBMIT";
}

interface SetNameError extends BaseConfigAction {
  type: "SET_NAME_ERROR";
}

interface RegisterDraftComponentReducers extends BaseConfigAction {
  type: "REGISTER_PACKAGES";
  payload: {
    packages: SpaceComponentPackage[];
  };
}

interface MoveComponent extends BaseConfigAction {
  type: "MOVE_COMPONENT";
  payload: {
    sourcePath: string;
    destinationPath: string;
    index: number;
  };
}

export type RootSpaceConfigAction =
  | InitNewSpaceAction
  | InitExistingSpaceAction
  | InsertComponentAction
  | InsertFlexibleLayoutComponent
  | UpdateComponentLayout
  | SetSpaceNameAction
  | SetComponentAction
  | SetComponentErrorState
  | SetConfigErrors
  | Submit
  | SetNameError
  | SaveSuccessAction
  | RemoveComponentAction
  | RegisterDraftComponentReducers
  | MoveComponent;

export type SpaceConfigDispatch = Dispatch<SpaceConfigAction>;

export const INITIAL_STATE: SpaceConfigState = {
  name: "",
  components: {},
  apiErrors: {},
  tree: null,
  submitted: false,
  hasNameError: false,
  dirty: false,
  isStateLoaded: false,
  touchedComponents: new Set(),
  packages: new Map(),
  elementLayouts: new Map()
};

const voidComponent = makeComponent(new Set(), {
  type: "VOID" as SpaceComponentType
});
export function createBaseComponentConfigState(
  component: SpaceComponentObject = voidComponent
): BaseComponentConfigState {
  return {
    type: component.type,
    draftComponent: component
  };
}

function createInitialComponentConfigState(
  type: SpaceComponentType,
  slugs: Set<string>,
  packages: Map<SpaceComponentType, SpaceComponentPackage>,
  componentConfig: Partial<SpaceComponentObject> = {}
): ComponentConfigState {
  const _package = packages.get(type);
  if (_package === undefined) {
    console.warn(`Expected package for component type ${type}.`);
    throw new Error("Expected to find component package.");
  }

  let component = makeComponent(slugs, {
    type,
    ...componentConfig
  });
  component = _package.ensureComponent
    ? _package.ensureComponent(component)
    : component;

  let getInitialDraftState = _package.getInitialDraftState;
  if (typeof getInitialDraftState !== "function") {
    getInitialDraftState = createBaseComponentConfigState;
  }

  return getInitialDraftState(component);
}

export default function reducer(
  state: SpaceConfigState = cloneDeep(INITIAL_STATE),
  action: SpaceConfigAction
): SpaceConfigState {
  setTimeout(() => {
    exceptionReporting.addBreadCrumb({
      type: "info",
      level: exceptionReporting.Severity.Info,
      category: "space config reducer",
      message: `Space config reducer action ${action.type}`,
      data: {
        action,
        flattened: exceptionReporting.getFlattenedRedactedData(action)
      }
    });
  }, 10);

  const slugs = selectAllSlugs(state);
  if ("componentSlug" in action && action.componentSlug) {
    const componentSlug = action.componentSlug;
    const draftState = state.components[componentSlug];
    let componentReducer = null;
    if (!draftState) {
      exceptionReporting.reportException(
        new Error("Expected draft state for component."),
        { extra: { action: JSON.stringify(action) } }
      );
    } else {
      componentReducer = state.packages.get(
        draftState.draftComponent.type
      )?.componentConfigReducer;
    }
    if (!componentReducer) {
      componentReducer = commonComponentReducer;
    }
    const nextComponentState = componentReducer(draftState, action);
    const touchedComponents = new Set(state.touchedComponents);
    // Do not break memoizations when component reducer no-oped
    if (nextComponentState === state.components[componentSlug]) return state;
    touchedComponents.add(componentSlug);
    const { [componentSlug]: obsoleteError, ...apiErrors } = state.apiErrors;
    return {
      ...state,
      dirty: true,
      touchedComponents,
      apiErrors: obsoleteError ? apiErrors : state.apiErrors,
      components: {
        ...state.components,
        [componentSlug]: nextComponentState
      }
    };
  }

  switch (action.type) {
    case "REGISTER_PACKAGES": {
      const { packages } = action.payload;
      const packagesMap = packages.reduce((acc, p) => {
        if (!p) return acc;
        acc.set(p.type, p);
        return acc;
      }, new Map());

      return {
        ...state,
        packages: packagesMap
      };
    }

    case "INIT_NEW_SPACE": {
      if (state.isStateLoaded) return state;
      const { clientProvidedComponents } = action.payload;
      const newSlugs = new Set<string>();
      const params = makeComponent(newSlugs, {
        type: "PARAMS",
        container: null,
        slug: "params",
        properties: { url_parameters: [] }
      });
      const header = makeComponent(newSlugs, {
        type: "HEADER",
        container: null,
        layout: new ElementLayout({
          left: "2%",
          top: "0px",
          width: "96%",
          height: "86px",
          snapToGrid: true
        })
      });
      const componentTreeNodes = [header, params, ...clientProvidedComponents];
      const newState = extractState(componentTreeNodes, state.packages);
      return {
        ...INITIAL_STATE,
        ...newState,
        packages: state.packages,
        isStateLoaded: true
      };
    }

    case "INIT_EXISTING_SPACE": {
      if (state.isStateLoaded) return state;
      const { name, components } = action.payload;
      return {
        ...INITIAL_STATE,
        ...extractState(cloneDeep(components), state.packages),
        packages: state.packages,
        dirty: false,
        name: name,
        isStateLoaded: true
      };
    }

    case "SET_SPACE_NAME": {
      const { name } = action.payload;
      return {
        ...state,
        name,
        hasNameError: !name && name !== "new",
        dirty: true
      };
    }

    case "SET_NAME_ERROR": {
      return { ...state, hasNameError: true };
    }

    // SET_COMPONENT is legacy and only used by header config at this point. TODO: remove
    case "SET_COMPONENT": {
      deprecate("SpaceConfigReducer SET_COMPONENT");
      const { slug, component } = cloneDeep(action.payload);
      let apiErrors = state.apiErrors;
      if (apiErrors[slug]) {
        apiErrors = cloneDeep(state.apiErrors);
        delete apiErrors[slug];
      }
      const components = {
        ...state.components,
        [slug]: {
          ...state.components[slug],
          draftComponent: component
        }
      };

      // update touchedComponents so the component is included in config validation
      const touchedComponents = state.touchedComponents.has(slug)
        ? state.touchedComponents
        : new Set(state.touchedComponents).add(slug);

      return {
        ...state,
        components,
        dirty: true,
        apiErrors,
        touchedComponents
      };
    }

    case "INSERT_FLEXIBLE_LAYOUT_COMPONENT": {
      const { componentType, layout } = action.payload;

      const initialDraftState = createInitialComponentConfigState(
        componentType,
        slugs,
        state.packages,
        { layout }
      );
      const component = initialDraftState.draftComponent;
      const components = {
        ...state.components,
        [component.slug]: initialDraftState
      };

      const newTreeNode: TreeNode = {
        slug: component.slug,
        type: component.type,
        treeNodes: [],
        container: null
      };
      if (state.tree === null) {
        throw new Error("Expected tree to be present.");
      }
      const tree = {
        slug: "root",
        container: null,
        type: null,
        treeNodes: state.tree.treeNodes.concat([newTreeNode])
      };
      const elementLayouts = new Map(state.elementLayouts);
      elementLayouts.set(component.slug, layout);
      return {
        ...state,
        components,
        dirty: true,
        tree,
        elementLayouts
      };
    }

    case "UPDATE_COMPONENT_LAYOUT": {
      const { slug, layout } = action.payload;
      const elementLayouts = new Map(state.elementLayouts);
      const rawExistingLayout = state.elementLayouts.get(slug);
      const existingLayout = new ElementLayout(rawExistingLayout);
      const nextElementLayout = new ElementLayout(rawExistingLayout);
      nextElementLayout.merge(layout);
      if (
        rawExistingLayout &&
        Object.keys(rawExistingLayout).length ===
          Object.keys(nextElementLayout).length &&
        existingLayout.isEqual(nextElementLayout)
      ) {
        return state;
      }
      elementLayouts.set(slug, nextElementLayout);
      return {
        ...state,
        dirty: true,
        elementLayouts
      };
    }

    case "INSERT_COMPONENT": {
      const { parentSlug, componentType, componentConfig } = action.payload;

      const updatedComponentConfig: Partial<SpaceComponentObject> =
        componentConfig || {};
      const initialDraftState = createInitialComponentConfigState(
        componentType,
        slugs,
        state.packages,
        updatedComponentConfig
      );
      const component = initialDraftState.draftComponent;
      const components = {
        ...state.components,
        [component.slug]: initialDraftState
      };

      const tree = insertComponentInTree(
        cloneDeep(state.tree), // See if this can be removed in favor of selectively creating new instances of affected tree branch
        state.components,
        slugs,
        component,
        parentSlug
      ) as TreeNode;

      // update touchedComponents so the component is included in config validation
      const touchedComponents = new Set(state.touchedComponents).add(component.slug);
      let elementLayouts = state.elementLayouts;
      if (componentConfig?.layout) {
        elementLayouts = new Map(state.elementLayouts);
        elementLayouts.set(component.slug, componentConfig.layout);
      }

      return {
        ...state,
        dirty: true,
        components,
        tree,
        elementLayouts,
        touchedComponents
      };
    }

    case "REMOVE_COMPONENT":
      const apiErrors = cloneDeep(state.apiErrors);
      if (!state.tree)
        throw new Error("Tree expected to exist before components may be swapped");

      const { slug } = action.payload;
      const { treeNode, removedSlugs } = removeComponentFromTree(
        state.tree,
        state.components,
        slug
      );
      const components = omit(state.components, removedSlugs);
      const allRemovedSlugs = new Set(
        removedSlugs.concat(Array.from(state.removedSlugs ? state.removedSlugs : []))
      );
      allRemovedSlugs.forEach(slug => {
        delete apiErrors[slug];
      });

      return {
        ...state,
        dirty: true,
        tree: treeNode,
        components,
        apiErrors,
        removedSlugs: allRemovedSlugs
      };

    case "MOVE_COMPONENT": {
      const { sourcePath, destinationPath, index } = action.payload;
      if (state.tree === null) return state;

      let nextTree;
      try {
        nextTree = moveTreeNode(state.tree, sourcePath, destinationPath, index);
      } catch (err) {
        exceptionReporting.reportException(err, { extra: action.payload });
        return state;
      }

      return {
        ...state,
        dirty: true,
        tree: nextTree
      };
    }

    case "SUBMIT":
      return { ...state, submitted: true };

    case "HANDLE_SAVE": {
      return { ...state, dirty: false };
    }

    case "SET_API_ERRORS":
      const { errors } = action.payload;

      return {
        ...state,
        apiErrors: errors
      };

    default:
      return state;
  }
}
