import React from "react";

import { AttributeValueInputProps } from "..";
import { DATE_DISPLAY_FORMAT, DATE_SAVE_FORMAT } from "../../../../constants";
import DatePicker from "../../DatePicker";
import MomentFormItemPopper from "../../MomentFormItemPopper/MomentFormItemPopper";

export default function DateInput(props: AttributeValueInputProps) {
  if (props.generated) return <em>This field will be set automatically.</em>;

  const decorator = props.form?.getFieldDecorator
    ? props.form.getFieldDecorator(props.sourceName, {
        initialValue: props.value,
        ...props.fieldOptions
      })
    : (comp: React.ReactNode) => comp;

  return (
    <>
      {decorator(
        <MomentFormItemPopper
          sourceName={props.sourceName}
          sourceType={props.sourceType}
          fieldOptions={props.fieldOptions}
          onChange={props.onChange ? props.onChange : () => null}
          getFieldValue={props.form ? props.form.getFieldValue : () => null}
          displayNullPlaceholder={props.displayNullPlaceholder}
          disabled={props.disabled}
          preventOverflow={props.preventOverflow}
          saveFormat={DATE_SAVE_FORMAT}
          displayFormat={DATE_DISPLAY_FORMAT}
          PopoverPicker={DatePicker}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
      )}
    </>
  );
}
