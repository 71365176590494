import React from "react";

import { Icon, Spin } from "antd";
import classNames from "classnames";
import { Link as BaseLink } from "react-router-dom";
import styled from "styled-components";

import ButtonNew from "../ButtonNew/ButtonNew";

const SectionRoot = styled.section`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.menuSeperatorBorderColor};
  transition: max-height ${props => props.theme.animationFn};
  height: auto;
  max-height: 43px;
  &.open {
    background: ${props => props.theme.headerBackgroundColor};
    border-bottom: none;
    max-height: 510px;
    overflow: hidden;
  }
`;

const SectionToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 12px 12px 12px 24px;
  font-size: 12px;
  cursor: pointer;
  &.open {
    font-weight: 600;
  }
  &:hover {
    background: ${props => props.theme.primaryColorBackgroundHover};
  }
`;

const ToggleIcon = styled(Icon)`
  font-size: 12px;
  color: ${props => props.theme.textColorMid};
`;

export default function NavMenu({
  title,
  open = true,
  showTitle = true,
  className = "",
  children,
  onToggle
}: {
  title: string;
  open?: boolean;
  showTitle?: boolean;
  className?: string;
  children: React.ReactNode;
  onToggle?: () => void;
}) {
  const toggleable = typeof onToggle === "function";
  const handleToggle = toggleable ? onToggle : () => {};

  return (
    <SectionRoot className={classNames({ open, className })}>
      {showTitle && (
        <SectionToggle
          data-test="spaceListHeader"
          className={classNames({ open })}
          onClick={() => handleToggle()}
        >
          <span>{title}</span>
          <ToggleIcon type={open ? "up" : "down"} />
        </SectionToggle>
      )}
      {open && <>{children}</>}
    </SectionRoot>
  );
}

const ListRoot = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
`;

export const ListItem = styled.li`
  &:last-of-type {
    padding-bottom: 14px;
  }
`;

export const Link = styled(BaseLink)`
  display: block;
  padding: 6px 12px 6px 24px;
  color: white;
  font-size: 12px;
  &:hover {
    color: white;
    background: ${props => props.theme.primaryColorBackgroundHover};
  }
  &.active {
    background: ${props => props.theme.primaryColorDark};
  }
`;

const EmptyState = styled.p`
  padding: 24px;
  text-align: center;
  color: ${props => props.theme.textColorMid};
  font-size: 13px;
`;

interface ListProps {
  loading?: boolean;
  fetchMoreLoading?: boolean;
  hasNextPage?: boolean;
  children: React.ReactNode;
  onFetchMore?: () => void;
}

export function List({
  loading = false,
  fetchMoreLoading = false,
  hasNextPage = false,
  children,
  onFetchMore = () => {}
}: ListProps) {
  const itemCount = React.Children.count(children);

  if (loading) {
    return <Spin />;
  }

  return (
    <ListRoot>
      {!loading && itemCount === 0 && <EmptyState>No items</EmptyState>}
      {children}
      {hasNextPage && (
        <ButtonNew
          /* 
            https://www.pivotaltracker.com/story/show/181542493
            If this button is retained after being used antd 
            leaves problematic attrs on the DOM node which break
            hover styles.
          */
          key={Date.now()}
          type="noFill"
          block
          loading={fetchMoreLoading}
          onClick={() => {
            onFetchMore();
          }}
        >
          Load more
        </ButtonNew>
      )}
    </ListRoot>
  );
}
