import React from "react";

import { Checkbox, Input } from "antd";
import styled from "styled-components";

import KeyValueInputs, { KeyValue } from "../../common/KeyValueInputs";
import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";
import { displayURL } from "../../util/http";

import BasicAuthFields from "./BasicAuthFields";
import { StyledAlert } from "./styledComponents";
import { CredentialFormProps } from "./types";

export const BASE_URL = "BaseUrl";
export const HAS_BASIC_AUTH = "hasBasicAuth";
export const HEADERS = "Headers";

export const isValidHeader = (header: KeyValue) => {
  return !!header.key && !!header.value;
};

const LabelColoredHeader = styled.h3`
  color: ${props => props.theme.labelColor};
`;

const headersToKeyValue = (obj: { [k: string]: string }) =>
  Object.keys(obj).map(key => ({ key, value: obj[key] }));

export default function HttpFieldSet(props: CredentialFormProps) {
  const {
    adapter = "",
    scheme = "",
    host = "",
    port = "",
    path = "",
    headers_json
  } = props.credentials || {};
  const baseUrl = host ? displayURL({ adapter, scheme, host, port, path }) : "";
  const initialHeaders = headers_json
    ? headersToKeyValue(JSON.parse(headers_json))
    : [{ key: "", value: "" }];

  const [isInsecure, setIsInsecure] = React.useState(false);
  const [headers, setHeaders] = React.useState(initialHeaders);
  const [isBasicAuthEnabled, setBasicAuthEnabled] = React.useState(
    !!props.credentials?.user
  );
  const { getFieldDecorator } = props.form;

  const toggleBasicAuth = React.useCallback(() => {
    if (isBasicAuthEnabled) {
      props.form.setFieldsValue({ User: "", Password: "" });
    }
    setBasicAuthEnabled(!isBasicAuthEnabled);
  }, [props.form, isBasicAuthEnabled, setBasicAuthEnabled]);

  return (
    <>
      <FormItem label={props.includeLabel && "Base URL"}>
        {getFieldDecorator(BASE_URL, {
          initialValue: baseUrl,
          validateTrigger: "onSubmit",
          rules: [
            {
              required: true,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            data-test="hostInput"
            placeholder="https://example.com/api"
            disabled={props.isLoading}
            onChange={e => {
              setIsInsecure(e.target.value.startsWith("http:"));
            }}
          />
        )}
      </FormItem>
      <FormItem>
        {getFieldDecorator(HAS_BASIC_AUTH, {
          initialValue: !!props.credentials?.user
        })(
          <Checkbox onChange={toggleBasicAuth} checked={isBasicAuthEnabled}>
            Enable Basic Authentication
          </Checkbox>
        )}
      </FormItem>
      {isBasicAuthEnabled && <BasicAuthFields {...props} />}
      <LabelColoredHeader>Headers</LabelColoredHeader>
      <FormItem>
        {getFieldDecorator(HEADERS, {
          initialValue: headers,
          validateTrigger: "onSubmit",
          rules: [
            {
              validator: (
                rule: any,
                value: KeyValue[],
                callback: (msg?: string) => void
              ) => {
                const hasError = value.some(
                  (item: KeyValue) => !item.key && item.value
                );
                if (hasError) {
                  callback("All headers must include a key.");
                  return;
                }
                callback();
              }
            }
          ]
        })(
          <KeyValueInputs
            itemName="header"
            onChange={(value: KeyValue[]) => {
              setHeaders(value);
            }}
          />
        )}

        {isInsecure && (
          <StyledAlert
            message="Connections to the host will not be private and your data will be visible to anyone in transit. Use HTTPS to ensure that your data is encrypted between the host and Internal."
            type="warning"
            showIcon
          />
        )}
      </FormItem>
    </>
  );
}
