import React, { ChangeEvent, Dispatch } from "react";

import GeneralSettings from "../../../common/GeneralSettings";
import { MaterialIconType } from "../../../common/Icons/MaterialIcons";
import { ReducerAction, ReducerActions } from "../reducer/actions";
import { ConfigState } from "../reducer/reducer";

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ReducerAction>;
}

export const General = ({ state, dispatch }: Props) => {
  const handleNameChange = React.useCallback(
    (evt: ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: ReducerActions.UPDATE_NAME, payload: evt.target.value }),
    [dispatch]
  );
  const handleDescriptionChange = React.useCallback(
    (evt: ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: ReducerActions.UPDATE_DESCRIPTION, payload: evt.target.value }),
    [dispatch]
  );
  const handleIconChange = React.useCallback(
    (icon: string) =>
      dispatch({ type: ReducerActions.UPDATE_ICON, payload: icon as MaterialIconType }),
    [dispatch]
  );
  const handleColorChange = React.useCallback(
    (color: string) =>
      dispatch({ type: ReducerActions.UPDATE_COLOR, payload: color.slice(1) }),
    [dispatch]
  );

  return (
    <GeneralSettings
      title="Queue"
      state={state}
      onNameChange={handleNameChange}
      onDescriptionChange={handleDescriptionChange}
      onIconChange={handleIconChange}
      onColorChange={handleColorChange}
    />
  );
};
