import { useCallback, useReducer } from "react";

import { MutationResult } from "@apollo/react-common";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { ReturnSchema } from "../../../../constants";
import {
  BaseFunctionName,
  BaseFunctionNodeBasic,
  DescribeColumn,
  FunctionAttribute,
  FunctionParameterInput,
  Metadata
} from "../../../../types";
import { CacheUpdaterFn } from "../../../util/updateCache";
import { DataSourceNodeWithFunctions, GeneralActionTypes } from "../forms/types";
import { EditorTab, OnAuthorizationFlowChangeCallback, PreviewResult } from "../index";

import {
  FETCH_FUNCTION_BY_ID,
  FetchFunctionByIdData,
  FetchFunctionByIdVars,
  FunctionFragmentNode,
  CREATE_FUNCTION,
  CreateFunctionData,
  CreateFunctionVariables,
  DELETE_FUNCTION,
  DeleteFunctionData,
  DeleteFunctionVariables,
  UPDATE_FUNCTION,
  UpdateFunctionData,
  UpdateFunctionVariables,
  WriteFunctionVariables,
  EditorFunctionNode
} from "./queries";
import {
  ActionType,
  BaseFunctionConfig,
  getEmptyState,
  reducer,
  State
} from "./reducer";
import { findFunction } from "./util";

export interface Result {
  state: State;
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  delete: () => Promise<boolean>;
  save: () => Promise<FunctionFragmentNode>;
  onDataSourceChange: (
    dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>
  ) => void;
  onBaseFunctionNameChange: (
    dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>,
    name: BaseFunctionName
  ) => void;
  onBaseConfigChange: <C>(config: BaseFunctionConfig<C>) => void;
  onFunctionIdChange: (functionId: string) => void;
  onMetadataChange: <C>(value: Metadata<C>) => void;
  onTitleChange: (value: string) => void;
  onReducerChange: (value: string) => void;
  onMetadataReducerChange: (value: string) => void;
  onParameterChange: (value: FunctionParameterInput) => void;
  onAttributesChange: (value: FunctionAttribute[]) => void;
  onPreviewResult: (result: PreviewResult) => void;
  onRefreshAttributes: () => void;
  onActiveEditorTabChange: (tab: EditorTab) => void;
  onReturnSchemaChange: (returnSchema: ReturnSchema) => void;
  onAuthorizationFlowChange: OnAuthorizationFlowChangeCallback;
  onDescribeColumnsChange: (columns: DescribeColumn[]) => void;
}

export const useFunctionEditor = (
  functionId: string | undefined,
  cacheUpdater?: CacheUpdaterFn<FunctionFragmentNode>,
  cacheEvictor?: CacheUpdaterFn<string>
): Result => {
  const [state, dispatch] = useReducer(reducer, getEmptyState({}));

  const { loading: isLoading } = useQuery<
    FetchFunctionByIdData<EditorFunctionNode<Metadata>>,
    FetchFunctionByIdVars
  >(FETCH_FUNCTION_BY_ID, {
    variables: { id: functionId! },
    skip: !functionId,
    onCompleted: (data: FetchFunctionByIdData<EditorFunctionNode<Metadata>>) => {
      // TODO: Remove conditional when apollo is upgraded.  This is to workaround an issue in 3.1.0 where
      // queries get executed even when `skip === true`. See more on this issue here:
      // https://github.com/apollographql/react-apollo/issues/3492
      if (data.node) {
        dispatch({
          type: ActionType.LOAD_FUNCTION,
          payload: data.node
        });
      }
    }
  });

  const [createFunctionMutation, { loading: isCreating }] = useMutation<
    CreateFunctionData,
    CreateFunctionVariables
  >(CREATE_FUNCTION, {
    refetchQueries: ["FunctionPickerFunctions"],
    onCompleted: data => {
      if (data.createFunction && data.createFunction.function) {
        dispatch({
          type: ActionType.LOAD_FUNCTION,
          payload: data.createFunction.function
        });
      }
    }
  });

  const [updateFunctionMutation, { loading: isUpdating }] = useMutation<
    UpdateFunctionData,
    UpdateFunctionVariables
  >(UPDATE_FUNCTION, {
    refetchQueries: ["FunctionForManageTableConfig", "FunctionPickerFunctions"],
    onCompleted: data => {
      if (data.updateFunction && data.updateFunction.function) {
        dispatch({
          type: ActionType.LOAD_FUNCTION,
          payload: data.updateFunction.function
        });
      }
    }
  });

  const [deleteFunctionMutation, { loading: isDeleting }] = useMutation<
    DeleteFunctionData,
    DeleteFunctionVariables
  >(DELETE_FUNCTION, {
    refetchQueries: ["FunctionPickerFunctions"]
  });

  const save = async (): Promise<EditorFunctionNode<Metadata>> => {
    if (!state.baseFunctionName) {
      throw new Error("useFunctionEditor: cannot save invalid state");
    }

    const base: WriteFunctionVariables = {
      title: state.title,
      baseFunctionParameterMapping: state.baseFunctionParameterMapping,
      metadata: state.metadata,
      reducer: state.reducer,
      metadataReducer: state.metadataReducer,
      parameters: state.parameters,
      attributes: state.attributes,
      returnSchema: state.returnSchema!,
      authorizationFlows: state.authorizationFlows
    };

    let func: EditorFunctionNode<Metadata> | undefined;
    if (state.functionId) {
      const result = await updateFunctionMutation({
        variables: { ...base, functionId: state.functionId },
        update: (cache, res: MutationResult<UpdateFunctionData>) => {
          // Cannot use null propagation operator "?" here because it
          // causes a babel parse error.
          const func =
            res.data && res.data.updateFunction && res.data.updateFunction.function;
          if (func) {
            cacheUpdater && cacheUpdater(cache, func);
          }
        }
      });
      func = result.data?.updateFunction?.function;
    } else {
      const result = await createFunctionMutation({
        variables: {
          ...base,
          baseFunctionId: state.baseFunctionId
        },
        update: (cache, res: MutationResult<CreateFunctionData>) => {
          const func = res.data?.createFunction?.function;
          if (func) {
            cacheUpdater && cacheUpdater(cache, func);
          }
        }
      });
      func = result.data?.createFunction?.function;
    }
    if (!func) {
      throw new Error("Failed to save function");
    }
    return func;
  };

  const deleteFn = async (): Promise<boolean> => {
    const result = await deleteFunctionMutation({
      variables: { functionId: state.functionId },
      update: (cache, res: MutationResult<DeleteFunctionData>) => {
        if (!!res.data?.deleteFunction?.ok) {
          cacheEvictor && cacheEvictor(cache, state.functionId);
        }
      }
    });
    return !!result.data?.deleteFunction?.ok;
  };

  return {
    state,
    save,
    delete: deleteFn,
    isLoading,
    isSaving: isCreating || isUpdating,
    isDeleting,
    onDataSourceChange: useCallback(
      (dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>) => {
        dispatch({
          type: ActionType.CHANGE_DATA_SOURCE,
          payload: dataSource
        });
      },
      [dispatch]
    ),
    onTitleChange: useCallback(
      (title: string) => {
        dispatch({
          type: GeneralActionTypes.SET_FIELD_VALUE,
          payload: { fieldName: "title", fieldValue: title }
        });
      },
      [dispatch]
    ),
    onBaseFunctionNameChange: useCallback(
      (
        dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>,
        baseFunctionName: BaseFunctionName
      ) => {
        const baseFunctionId = findFunction(dataSource, baseFunctionName)?.id;
        if (!baseFunctionId) {
          throw new Error(
            `onBaseFunctionNameChange: could not find baseFunctionId: ${baseFunctionName}`
          );
        }
        dispatch({
          type: ActionType.UPDATE_BASE_FUNCTION,
          payload: {
            baseFunctionId,
            baseFunctionName
          }
        });
      },
      [dispatch]
    ),
    onBaseConfigChange: useCallback(
      <C>(config: BaseFunctionConfig<C>) => {
        dispatch({
          type: ActionType.UPDATE_BASE_CONFIG,
          payload: config
        });
      },
      [dispatch]
    ),
    onFunctionIdChange: useCallback(
      (functionId: string) => {
        dispatch({
          type: GeneralActionTypes.SET_FIELD_VALUE,
          payload: {
            fieldName: "functionId",
            fieldValue: functionId
          }
        });
      },
      [dispatch]
    ),
    onMetadataChange: useCallback(
      <C>(metadata: Metadata<C>) => {
        dispatch({
          type: GeneralActionTypes.SET_FIELD_VALUE,
          payload: { fieldName: "metadata", fieldValue: metadata }
        });
      },
      [dispatch]
    ),
    onReducerChange: useCallback(
      (reducer: string) => {
        dispatch({
          type: ActionType.UPDATE_REDUCER,
          payload: reducer
        });
      },
      [dispatch]
    ),
    onMetadataReducerChange: useCallback(
      (reducer: string) => {
        dispatch({
          type: GeneralActionTypes.SET_FIELD_VALUE,
          payload: { fieldName: "metadataReducer", fieldValue: reducer }
        });
      },
      [dispatch]
    ),
    onParameterChange: useCallback(
      (parameter: FunctionParameterInput) => {
        dispatch({
          type: ActionType.UPDATE_PARAMETER,
          payload: parameter
        });
      },
      [dispatch]
    ),
    onAttributesChange: useCallback(
      (attributes: FunctionAttribute[]) => {
        dispatch({
          type: ActionType.UPDATE_ATTRIBUTES,
          payload: attributes
        });
      },
      [dispatch]
    ),
    onPreviewResult: useCallback(
      (result: PreviewResult) => {
        dispatch({
          type: ActionType.UPDATE_PREVIEW_RESULT,
          payload: result
        });
      },
      [dispatch]
    ),
    onRefreshAttributes: useCallback(() => {
      dispatch({ type: ActionType.REFRESH_ATTRIBUTES });
    }, [dispatch]),
    onActiveEditorTabChange: useCallback(
      (tab: EditorTab) => {
        dispatch({
          type: ActionType.UPDATE_ACTIVE_EDITOR_TAB,
          payload: { tab }
        });
      },
      [dispatch]
    ),
    onReturnSchemaChange: useCallback(
      (returnSchema: ReturnSchema) => {
        dispatch({
          type: ActionType.UPDATE_RETURN_SCHEMA,
          payload: returnSchema
        });
      },
      [dispatch]
    ),
    onAuthorizationFlowChange: useCallback(
      authorizationFlow =>
        dispatch({
          type: ActionType.UPDATE_FUNCTION_AUTHORIZATION_FLOW,
          payload: authorizationFlow
        }),
      [dispatch]
    ),
    onDescribeColumnsChange: useCallback(
      (columns: DescribeColumn[]) => {
        dispatch({
          type: ActionType.UPDATE_DESCRIBE_COLUMNS,
          payload: columns
        });
      },
      [dispatch]
    )
  };
};
