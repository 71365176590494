import React from "react";

import { SpaceComponentObject } from "../../../../types";
import ErrorBoundary from "../../../common/ErrorBoundary";
import { reportException } from "../../../util/exceptionReporting";
import { AbsoluteElement, StaticElement, ElementProps } from "../../layout/Element";
import TransformingElement from "../../layout/Element/TransformingElement";
import { ComponentLayoutContextContainer } from "../../layout/LayoutContext/LayoutContext";
import { useCurrentTransformingComponentContext } from "../../layout/TransformationContext/TransformationContext";
import { PositionOption, ElementLayout, LayoutUnit } from "../../layout/util";
import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../SpaceContext";

import { SUPERFICIAL_COMPONENT_TYPES } from "./constants";
import { useComponentContext } from "./contexts/ComponentContext";
import {
  useComponentPathContext,
  getIsFirstInstance
} from "./contexts/ComponentPathContext";
import { useComponentStateContext } from "./contexts/ComponentStateContext";

export default function LayoutComponent(props: {
  component: SpaceComponentObject;
  children: React.ReactNode;
}) {
  let Element: React.ComponentType<ElementProps>;
  const [isLayoutEnsured, setIsLayoutEnsured] = React.useState(false);
  const [ensuredLayout, setEnsuredLayout] = React.useState<ElementLayout | null>(null);
  const path = useComponentPathContext();
  const isFirstInstance = getIsFirstInstance(path);

  const { pkg } = useComponentContext();
  const { componentNode } = useComponentStateContext();
  const { editMode } = useStableSpaceContext();
  const { dispatch: spaceConfigDispatch } = useSpaceConfigContext();
  const { transformingSlug, selectedSlug } = useCurrentTransformingComponentContext();
  const handleLayoutEnsured = React.useCallback(
    (ensuredLayout: ElementLayout) => {
      if (!ensuredLayout) return;
      setIsLayoutEnsured(true);

      // If the ensuredLayout matches the existing layout it can be ignored.
      if (ensuredLayout.isEqual(props.component.layout)) {
        return;
      }

      if (editMode) {
        // In edit mode update the component's layout to use the ensuredLayout.
        if (isFirstInstance) {
          spaceConfigDispatch({
            type: "UPDATE_COMPONENT_LAYOUT",
            payload: { slug: props.component.slug, layout: ensuredLayout }
          });
        }
      } else {
        // In view mode locally cache the ensuredLayout so it doesn't need to be recalculated.
        setEnsuredLayout(ensuredLayout);
      }
    },
    [
      editMode,
      props.component.layout,
      props.component.slug,
      isFirstInstance,
      spaceConfigDispatch
    ]
  );

  const _layout = useEnsuredLayout(
    props.component,
    ensuredLayout || props.component.layout,
    isLayoutEnsured,
    handleLayoutEnsured
  );

  const isTransformingElement =
    transformingSlug === props.component.slug ||
    (selectedSlug === props.component.slug &&
      _layout?.position === PositionOption.ABSOLUTE);

  const layout = React.useMemo(() => new ElementLayout(_layout), [_layout]);
  if (
    componentNode === undefined &&
    !SUPERFICIAL_COMPONENT_TYPES.includes(props.component.type)
  ) {
    return null;
  } else if (pkg?.isHeadless) {
    Element = HeadlessElement;
  } else if (isTransformingElement) {
    Element = TransformingElement;
  } else if (layout?.position === PositionOption.STATIC) {
    Element = StaticElement;
  } else if (layout?.position === PositionOption.ABSOLUTE) {
    Element = AbsoluteElement;
  } else {
    Element = HeadlessElement;
    reportException(new Error("Could not determine LayoutElement to use."), {
      extra: {
        slug: props.component.slug,
        componentType: props.component.type,
        layout: props.component.layout
      }
    });
  }

  return (
    <ComponentLayoutContextContainer slug={props.component.slug} ensuredLayout={layout}>
      <Element component={props.component}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </Element>
    </ComponentLayoutContextContainer>
  );
}

function HeadlessElement(props: ElementProps) {
  return <>{props.children}</>;
}

/*
  useEnsuredLayout
  Enforces contraints on `ElementLayouts` as well as ensuring
  that all layout properties are present. Any missing layout
  properties will be defaulted. Allows new layout properties
  to be introduced without requireing a data migration.
*/
export function useEnsuredLayout(
  component: SpaceComponentObject,
  layout: ElementLayout | undefined,
  isEnsured: boolean,
  onLayoutEnsured: (layout: ElementLayout) => void
): ElementLayout | undefined {
  const isNested = !!component.container;
  const { pkg } = useComponentContext();
  const { dispatch } = useSpaceConfigContext();

  const { slug, type } = component;

  // Migrate existing layouts if any layout props not yet present
  const ensuredLayout = React.useMemo(() => {
    if (isEnsured && layout) {
      return layout;
    }
    if (pkg.isHeadless) return;
    const ensured = new ElementLayout(layout);
    const defaultLayout = pkg.defaultElementLayout || new ElementLayout();
    const position = isNested ? PositionOption.STATIC : PositionOption.ABSOLUTE;
    Object.entries(defaultLayout).forEach(([key, value]) => {
      if (layout && layout[key] === undefined) {
        ensured[key] = value;
      }
    });
    if (ensured.position !== position) {
      ensured.position = position;
    }

    // For some component types, if nested and dimensions not yet present,
    // default dimensions to 100% if component is contained by scrollable panel
    // and auto otherwise.
    if (isNested) {
      ["width", "height"].forEach(dim => {
        if (layout && layout[dim] === undefined) {
          if (
            [
              "CARD_LIST",
              "CHART",
              "DATA_VIEWER",
              "DETAIL",
              "FUNCTION_FORM",
              "IMAGE",
              "TABLE",
              "VIEWLESS_IMAGE",
              "JSON_INPUT"
            ].includes(type)
          ) {
            ensured[dim] = "100" + LayoutUnit.PERCENTAGE;
          } else if (["FLEX_BOX"].includes(type)) {
            ensured[dim] = LayoutUnit.AUTO;
          }
        }
      });
    }
    return ensured;
  }, [isEnsured, layout, pkg.isHeadless, pkg.defaultElementLayout, type, isNested]);

  React.useEffect(() => {
    if (isEnsured || ensuredLayout === undefined) {
      return;
    }
    onLayoutEnsured(ensuredLayout);
  }, [
    slug,
    layout,
    ensuredLayout,
    dispatch,
    component.slug,
    isEnsured,
    onLayoutEnsured
  ]);

  return ensuredLayout;
}
