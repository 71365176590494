import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

import { useSpaceConsoleContext } from ".";

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.errorColor};
  margin-right: ${props => props.theme.spacersm};
`;

export default function ErrorIcon() {
  const { setVisible, state } = useSpaceConsoleContext();

  if (state.errors.length === 0) return null;

  return (
    <StyledIcon
      data-test="consoleError"
      theme="filled"
      type="exclamation-circle"
      onClick={() => setVisible(true)}
    />
  );
}
