import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import BillingCheckoutContainer from "../components/settings/Billing/BillingCheckoutContainer";
import BillingConfirmation from "../components/settings/Billing/BillingConfirmation";
import SettingsContainer from "../components/settings/SettingsContainer";
import { Layout } from "../layouts";

const _Container = () => {
  return (
    <RequireAuth scopes={["admin"]}>
      <Layout>
        <SettingsContainer />
      </Layout>
    </RequireAuth>
  );
};

export const BillingCheckout = () => {
  return (
    <RequireAuth scopes={["admin"]}>
      <Layout>
        <BillingCheckoutContainer />
      </Layout>
    </RequireAuth>
  );
};

export const Confirmation = () => {
  return (
    <RequireAuth scopes={["admin"]}>
      <Layout>
        <BillingConfirmation />
      </Layout>
    </RequireAuth>
  );
};

export const Index = _Container;
export const Flyout = _Container;
