import { Input } from "antd";
import styled from "styled-components";

import { TableLayoutNoMargin } from "../../../../layouts/TableLayout";
import { ErrorField as _ErrorField } from "../../../common/StyledComponents";

export const TableLayoutNoMarginNoPadding = styled(TableLayoutNoMargin)`
  .ant-layout-content {
    padding-top: 8px;
  }
`;

export const HeaderToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  border-bottom: solid 1px #d9d9d9;
`;

export const RoleConfigSection = styled.div`
  padding-bottom: ${props => props.theme.spacerlg};
  margin-bottom: ${props => props.theme.spacermd};
`;

export const HeaderText = styled.div`
  i {
    color: ${props => props.theme.primaryColor};
    margin-right: 8px;
  }
`;

export const ButtonContainer = styled.div`
  button + button {
    margin-left: 8px;
  }
`;

export const RoleNameInput = styled(Input)`
  width: 300px;
`;

export const ErrorField = _ErrorField;
