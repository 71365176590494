import React, { useEffect, useReducer } from "react";

import classNames from "classnames";
import { TableRowProps } from "react-table";
import styled from "styled-components";

import { assertNever } from "../../util/assertNever";

const TR = styled.tr`
  &.isHighlighted {
    animation: highlight 1000ms ease-out;
  }

  @keyframes highlight {
    0% {
      background-color: ${props => props.theme.primaryAccent + "32"};
    }
    100% {
      background-color: transparent;
    }
  }
`;

const initialState = {
  isHighlighted: false,
  isMounted: false
};

function reducer(
  state: typeof initialState,
  action: { type: "UPDATE" } | { type: "TIMEOUT" }
) {
  switch (action.type) {
    case "UPDATE": {
      if (!state.isMounted) {
        return {
          isHighlighted: false,
          isMounted: true
        };
      }
      return {
        isHighlighted: true,
        isMounted: true
      };
    }
    case "TIMEOUT": {
      return {
        isMounted: true,
        isHighlighted: false
      };
    }
    default: {
      return assertNever(action);
    }
  }
}

export default function HighlightingTableRow({
  children,
  data,
  className = "",
  highlightKey = "updatedAt",
  ...others
}: TableRowProps & {
  children?: React.ReactNode;
  data: Record<string, any>;
  highlightKey?: string;
  className?: string;
  onClick: (evt: React.MouseEvent) => void;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const highlightVal = data[highlightKey];
  useEffect(() => {
    dispatch({ type: "UPDATE" });
    const timeout = setTimeout(() => {
      dispatch({ type: "TIMEOUT" });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [highlightVal]);

  const { isHighlighted } = state;

  return (
    <TR className={classNames(className, { isHighlighted })} {...others}>
      {children}
    </TR>
  );
}
