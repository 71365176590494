import React from "react";

import styled from "styled-components";

import {
  SpaceComponentType,
  SpaceComponentObject,
  SpaceComponentHostType
} from "../../../../../../types";
import { useTransformationActionContext } from "../../../../layout/TransformationContext/TransformationContext";
import { ElementLayout, LayoutUnit } from "../../../../layout/util";
import AddComponentPopover from "../../../../SpaceConfig/AddComponentPopover";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../../../SpaceContext";

export interface Props {
  parentSlug: string;
  hostType: SpaceComponentHostType;
  sibling?: SpaceComponentObject;
  className?: string;
  componentConfig?: Partial<SpaceComponentObject>;
  onMouseDown?: (e: React.MouseEvent) => void;
}

export const TARGET_SIZE = 72;

const StyledInsertTarget = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacerlg};
  border: dashed 1px ${props => props.theme.primaryColor};
  border-color: transparent;
`;

export default function InsertTarget({
  parentSlug,
  hostType,
  componentConfig = {},
  className = "",
  onMouseDown = () => {}
}: Props) {
  const { dispatch, predictSlug } = useSpaceConfigContext();
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const { select } = useTransformationActionContext();

  const handleAdd = (componentType: SpaceComponentType) => {
    const slug = predictSlug(componentType);
    dispatch({
      type: "INSERT_COMPONENT",
      payload: {
        componentType,
        parentSlug,
        componentConfig: {
          ...componentConfig,
          layout: {
            width: LayoutUnit.AUTO,
            height: LayoutUnit.AUTO
          } as ElementLayout
        }
      }
    });
    select(slug);
  };

  const componentTypes = getSpaceComponentPackages()
    .filter(p => p.allowedHosts.has(hostType))
    .map(p => p.type);

  return (
    <StyledInsertTarget className={className} onMouseDown={onMouseDown}>
      <AddComponentPopover componentTypes={componentTypes} onAdd={handleAdd} />
    </StyledInsertTarget>
  );
}
