import React, { useState } from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form, Checkbox, Divider, Icon, Button, Skeleton } from "antd";
import { FormComponentProps } from "antd/lib/form";
import FormItem from "antd/lib/form/FormItem";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../../constants";
import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";
import { MainTitle } from "../../../common/StyledComponents";
import { UpsellBadge } from "../../../common/Upsells";
import { SECURITY_SETTINGS_QUERY } from "../SecuritySettings";

import { TwoFactorDrawerContainer, CheckboxInfo } from "./StyledComponents";

interface Props extends FormComponentProps {
  onClose: () => void;
}

export const TWO_FACTOR_DRAWER_QUERY = gql`
  query TwoFactorDrawerQuery {
    authUser {
      id
      hasDevice
      email
      organization {
        id
        twoFactorRequired
        licenseData {
          twoFactor
        }
      }
    }
  }
`;

export const ENFORCE_TWO_FACTOR_MUTATION = gql`
  mutation EnforceTwoFactor($required: Boolean!) {
    configureOrganization(twoFactorRequired: $required) {
      organization {
        id
      }
    }
  }
`;

const TwoFactorUpsellBadge = styled(UpsellBadge)`
  margin: 0;
`;

const PaywallPopup = styled(ModalNew)`
  right: 0;
  top: 40px;
`;

const TwoFactorDrawer = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const { data } = useQuery(TWO_FACTOR_DRAWER_QUERY);
  const [showPaywall, setShowPaywall] = useState<boolean>(false);

  const [enforceTwoFactor] = useMutation(ENFORCE_TWO_FACTOR_MUTATION, {
    refetchQueries: [{ query: SECURITY_SETTINGS_QUERY }],
    onCompleted: () => {
      props.onClose();
      Message.success(
        "Two factor authentication settings have been updated for your organization."
      );
    }
  });

  const submit = (e: any) => {
    e.preventDefault();
    if (!props.form.isFieldTouched("required")) {
      return;
    }

    props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }

      enforceTwoFactor({
        variables: { required: values.required }
      });
    });
  };

  if (!data || !data.authUser) {
    return <Skeleton active={true} />;
  }

  const licenseData = data.authUser.organization.licenseData;
  const twoFactorEnforcementAllowed = licenseData?.twoFactor || false;

  return (
    <TwoFactorDrawerContainer>
      <Form onSubmit={submit}>
        <div onClick={props.onClose} className="icon_and_text bold">
          <Icon type="close" />
          <p>Close</p>
        </div>
        <div className="row spaced">
          <div className="icon_and_text">
            <Icon type="exclamation-circle" theme="filled" />
            <p>You're editing Two-Step Verification settings for your company.</p>
          </div>
          <div>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </div>
        </div>
        <Divider />
        <MainTitle>Two-Step Verification</MainTitle>
        <FormItem>
          {getFieldDecorator(
            "required",
            {}
          )(
            <CheckboxInfo>
              <Checkbox
                defaultChecked={data.authUser.organization.twoFactorRequired}
                disabled={!data.authUser.hasDevice || !twoFactorEnforcementAllowed}
              />
              <p>
                Require employees to use an authenticator app when signing into their
                Internal account.{" "}
                {!twoFactorEnforcementAllowed && (
                  <TwoFactorUpsellBadge onMouseOver={() => setShowPaywall(true)}>
                    Growth
                  </TwoFactorUpsellBadge>
                )}{" "}
              </p>

              <PaywallPopup
                absolutePositioning={true}
                title="Two-Step Verification requirement"
                visible={showPaywall}
                mask={false}
                onCancel={() => setShowPaywall(false)}
                footer={null}
                width={270}
              >
                <p>
                  To enable two-step verification requirement, upgrade to the{" "}
                  <Link to="/settings/change-plan">Growth plan</Link>.
                </p>
              </PaywallPopup>
            </CheckboxInfo>
          )}
          {!data.authUser.hasDevice && (
            <div className="row">
              <p>
                Before you can enable 2-Step Verification for your organization, enable
                it for yourself in your
              </p>
              <Link to={Routes.USER_PROFILE}>Profile</Link>
            </div>
          )}
        </FormItem>
      </Form>
    </TwoFactorDrawerContainer>
  );
};

export default Form.create<Props>({ name: "org-two-factor" })(TwoFactorDrawer);
