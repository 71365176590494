import { cloneDeep } from "lodash";

import { SpaceComponentObject } from "../../../../../../../types";
import {
  BaseConfigAction,
  ComponentConfigState,
  BaseComponentConfigState,
  SpaceConfigAction
} from "../../../../../types";
import { InputParameter } from "../../useFuncParams";

interface ParameterComponentProps {
  input_parameters: InputParameter[];
}

type ParameterComponent = SpaceComponentObject<ParameterComponentProps>;
export interface ParameterConfigState {
  parameterValueErrors: Record<string, boolean>;
}

export enum ParameterConfigActionTypes {
  SET_PARAMETER_VALIDITY = "SET_PARAMETER_VALIDITY"
}

interface SetParameterValidity extends BaseConfigAction {
  type: ParameterConfigActionTypes.SET_PARAMETER_VALIDITY;
  payload: {
    name: string;
    valid: boolean;
  };
}
export type ParameterConfigAction = SetParameterValidity;

export const INITIAL_STATE = {
  parameterValueErrors: {}
};

export const getInitialParameterState = (draftComponent: SpaceComponentObject) => {
  const component = ensureParameterComponent(draftComponent);
  return {
    parameterValueErrors: component.properties.input_parameters.reduce<
      Record<string, boolean>
    >((memo: Record<string, boolean>, param: InputParameter) => {
      memo[param.name] = true;
      return memo;
    }, {})
  };
};

export function ensureParameterComponent(sc: SpaceComponentObject): ParameterComponent {
  if (Array.isArray(sc.properties.input_parameters)) return sc;
  return {
    ...sc,
    properties: {
      ...sc.properties,
      input_parameters: sc.properties.input_parameters || []
    }
  };
}

export function ensureParameterConfigState(
  state: ComponentConfigState
): ParameterConfigState & BaseComponentConfigState {
  if ("parameterValueErrors" in state) {
    return state;
  }
  throw new Error("Expected component config state to include ParameterConfigState.");
}

export default function reducer(
  state: ParameterConfigState & BaseComponentConfigState,
  action: SpaceConfigAction
): ParameterConfigState {
  switch (action.type) {
    case ParameterConfigActionTypes.SET_PARAMETER_VALIDITY: {
      const { name, valid } = action.payload;
      const draftState = cloneDeep(state);
      draftState.parameterValueErrors[name] = valid;
      return draftState;
    }
    default:
      return state;
  }
}
