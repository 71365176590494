import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import { ComponentConfigContextContainer } from "../../common/ComponentConfigContext";
import { TerseComponentConfigContextContainer } from "../../common/ComponentConfigContext/ComponentConfigContext";
import DefaultValueField from "../../common/DefaultValueField";
import InputComponentNameFields from "../../common/InputComponentNameFields";
import ValidationField from "../../common/ValidationField";
import { ValidationRulesSection } from "../../common/ValidationRulesConfigSection/ValidationRulesConfigSection";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

export default function SpaceTextAreaConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={React.useMemo(
        () => (
          <SpaceTextAreaConfigContent />
        ),
        []
      )}
      slug={slug}
    />
  );
}

export function TerseConfig({ slug }: ComponentConfigProps) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <DefaultValueField />
      <ValidationField isTerse />
    </TerseComponentConfigContextContainer>
  );
}

export function InlineConfig({ slug }: ComponentConfigProps) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <InputComponentNameFields isInline />
      <DefaultValueField />
      <ValidationField isTerse />
    </TerseComponentConfigContextContainer>
  );
}

function SpaceTextAreaConfigContent() {
  return (
    <>
      <BaseComponentConfigSection>
        <InputComponentNameFields shouldRenderPlaceholder />
        <DefaultValueField />
        <ValidationField />
      </BaseComponentConfigSection>
      <ValidationRulesSection />
      <VisibilityRulesManagerSection />
    </>
  );
}
