import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Icon, Input } from "antd";
import styled from "styled-components";

import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";
import { B2, B3 } from "../../../common/StyledComponents";

import {
  FETCH_API_KEYS,
  CREATE_API_KEY,
  CreateAPIKeyData,
  CreateAPIKeyVariables
} from "./queries";

const Label = styled.label`
  display: block;
  input {
    margin: 6px 0 10px 0;
  }
`;

const Help = styled.div`
  color: ${props => props.theme.errorColor};
`;

const Success = styled.span`
  color: ${props => props.theme.brandColors.accentGreen}};
  margin-right: ${props => props.theme.spacersm}};
`;

const DescriptionList = styled.dl`
  background-color: ${props => props.theme.newContainerPrimary};
  border-radius: ${props => props.theme.borderRadiuslg};
  margin-top: ${props => props.theme.spacerlg};
  padding: ${props => props.theme.spacerlg};
`;

interface Props {
  onClose: () => void;
}

const CreateKeyModal = ({ onClose }: Props) => {
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [key, setKey] = useState<string | null>(null);

  const [createApiKey, { loading }] = useMutation<
    CreateAPIKeyData,
    CreateAPIKeyVariables
  >(CREATE_API_KEY, {
    onCompleted: data => setKey(data.createApiKey.key),
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        Message.error("Something went wrong, please try again.");
      }
    },
    refetchQueries: [{ query: FETCH_API_KEYS }]
  });

  const submit = () => {
    if (description.trim() === "") {
      setDescriptionError("This field cannot be blank.");
    } else {
      setDescriptionError(null);
      createApiKey({ variables: { description } });
    }
  };

  const copy = () => {
    navigator.clipboard.writeText(key!).then(
      () => Message.info("Copied!"),
      () => Message.error("Something went wrong, please copy the key manually.")
    );
  };

  const modalProps = { onCancel: onClose, width: 460, visible: true };
  return key === null ? (
    <ModalNew
      title="Create API key"
      confirmLoading={loading}
      okText="Create API key"
      onOk={submit}
      cancelText="Cancel"
      {...modalProps}
    >
      {
        <form
          onSubmit={e => {
            e.preventDefault();
            submit();
          }}
        >
          <Label>
            Description
            <Input
              disabled={loading}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <Help>{descriptionError}</Help>
          </Label>
        </form>
      }
    </ModalNew>
  ) : (
    <ModalNew
      title={
        <>
          <Success>
            <Icon type="check-circle" theme="filled" />
          </Success>
          API key created
        </>
      }
      keyboard={false}
      maskClosable={false}
      okText="Copy to clipboard"
      onOk={copy}
      cancelText={"Close"}
      {...modalProps}
    >
      {
        <>
          <p>
            This key will not be visible again. Make sure to store it in a secure
            location.
          </p>
          <B3>
            This key has the same permissions as your user. Treat it as you would any
            other password.
          </B3>
          <DescriptionList>
            <dt>Description:</dt>
            <dd>
              <B2>{description}</B2>
            </dd>
            <dt>Key:</dt>
            <dd>
              <B2>{key}</B2>
            </dd>
          </DescriptionList>
        </>
      }
    </ModalNew>
  );
};

export default CreateKeyModal;
