import { BaseCode } from "../../..";
import { BaseFunctionName } from "../../../../../../types";
import { DataSourceFunction } from "../../types";

import {
  MongoBaseFunctionParameterMapping,
  MongoCodeParameterType,
  isMongoBaseFunctionParameterMapping
} from "./types";

export const mongoSupportedBaseFunctions = [
  BaseFunctionName.AGGREGATE,
  BaseFunctionName.INSERT,
  BaseFunctionName.UPDATE,
  BaseFunctionName.DELETE
] as const;
export type MongoSupportedBaseFunctionNames =
  typeof mongoSupportedBaseFunctions[number];

export const supportedCodeTypes = {
  [BaseFunctionName.AGGREGATE]: [MongoCodeParameterType.PIPELINE],
  [BaseFunctionName.INSERT]: [MongoCodeParameterType.DOCUMENTS],
  [BaseFunctionName.UPDATE]: [
    MongoCodeParameterType.FILTER,
    MongoCodeParameterType.UPDATE
  ],
  [BaseFunctionName.DELETE]: [MongoCodeParameterType.FILTER]
};

export const baseFunctionsWithMultipleSupport = [
  BaseFunctionName.UPDATE,
  BaseFunctionName.DELETE
];

export const defaultAggregateBaseFunctionParameterMapping = Object.freeze({
  collection: undefined,
  pipeline: "[]"
}) as MongoBaseFunctionParameterMapping;

export const defaultInsertBaseFunctionParameterMapping = Object.freeze({
  collection: undefined,
  documents: "{}"
}) as MongoBaseFunctionParameterMapping;

export const defaultUpdateBaseFunctionParameterMapping = Object.freeze({
  collection: undefined,
  filter: "{}",
  update: "{}",
  multiple: "false"
}) as MongoBaseFunctionParameterMapping;

export const defaultDeleteBaseFunctionParameterMapping = Object.freeze({
  collection: undefined,
  filter: "{}",
  multiple: "false"
}) as MongoBaseFunctionParameterMapping;

export const defaultParameterMappings = {
  [BaseFunctionName.AGGREGATE]: defaultAggregateBaseFunctionParameterMapping,
  [BaseFunctionName.INSERT]: defaultInsertBaseFunctionParameterMapping,
  [BaseFunctionName.UPDATE]: defaultUpdateBaseFunctionParameterMapping,
  [BaseFunctionName.DELETE]: defaultDeleteBaseFunctionParameterMapping
};

export function getEmptyCodeMetadata(
  baseFunctionName: MongoSupportedBaseFunctionNames
): BaseCode {
  switch (baseFunctionName) {
    case BaseFunctionName.AGGREGATE: {
      return "[]";
    }
    case BaseFunctionName.INSERT: {
      return "{}";
    }
    case BaseFunctionName.UPDATE: {
      return {
        [MongoCodeParameterType.FILTER]: "{}",
        [MongoCodeParameterType.UPDATE]: "{}"
      };
    }
    case BaseFunctionName.DELETE: {
      return "{}";
    }
    default:
      throw new Error("Unexpected base function name.");
  }
}

const formatAggregate = ({
  collection,
  pipeline
}: MongoBaseFunctionParameterMapping): MongoBaseFunctionParameterMapping => ({
  collection: JSON.stringify(parseCollection(collection)),
  pipeline: `(function() {
  var pipeline = ${pipeline};
  if (!Array.isArray(pipeline)) { throw new Error("Expression must evaluate to an array"); }
  if (typeof filters !== 'undefined') {
    var op = { '=': '$eq', '!=': '$ne', '<': '$lt', '<=': '$lte', '>': '$gt', '>=': '$gte', 'in': '$in', 'not in': '$nin' };
    pipeline.push({
      '$match': filters.reduce(function(acc, f) {
        acc[f.attribute] = acc[f.attribute] || {};
        acc[f.attribute][op[f.operator]] = f.value;
        return acc;
      }, {})
    });
  }
  if (typeof sortBy !== 'undefined') {
    var sort = {};
    sort[sortBy] = (typeof sortAscending === 'undefined' || sortAscending) ? 1 : -1;
    pipeline.push({ '$sort': sort });
  }
  if (typeof pageOffset !== 'undefined') {
    pipeline.push({ '$skip': pageOffset });
  }
  if (typeof pageSize !== 'undefined') {
    pipeline.push({ '$limit': pageSize });
  }
  return pipeline;
})()`
});

function formatInsert(
  mapping: MongoBaseFunctionParameterMapping,
  codeMetadata: BaseCode
): MongoBaseFunctionParameterMapping {
  return {
    collection: JSON.stringify(parseCollection(mapping.collection)),
    documents: `[${codeMetadata}]`
  };
}

function formatUpdate(
  mapping: MongoBaseFunctionParameterMapping,
  codeMetadata: BaseCode
): MongoBaseFunctionParameterMapping {
  if (
    typeof codeMetadata === "string" ||
    typeof codeMetadata.filter !== "string" ||
    typeof codeMetadata.update !== "string"
  ) {
    throw new Error(
      "Expected code meta data to be a record with filter and update keys."
    );
  }
  return {
    collection: JSON.stringify(parseCollection(mapping.collection)),
    filter: codeMetadata.filter,
    update: codeMetadata.update,
    multiple: mapping.multiple
  };
}

function formatDelete(
  mapping: MongoBaseFunctionParameterMapping
): MongoBaseFunctionParameterMapping {
  return {
    collection: JSON.stringify(parseCollection(mapping.collection)),
    filter: mapping.filter,
    multiple: mapping.multiple
  };
}

export function prepareParameterMapping(
  mapping: MongoBaseFunctionParameterMapping,
  baseFunctionName: MongoSupportedBaseFunctionNames,
  codeMetadata: BaseCode
): MongoBaseFunctionParameterMapping {
  switch (baseFunctionName) {
    case BaseFunctionName.AGGREGATE: {
      return formatAggregate(mapping);
    }
    case BaseFunctionName.INSERT: {
      return formatInsert(mapping, codeMetadata);
    }
    case BaseFunctionName.UPDATE: {
      return formatUpdate(mapping, codeMetadata);
    }
    case BaseFunctionName.DELETE: {
      return formatDelete(mapping);
    }
    default:
      return mapping;
  }
}

export function changeBaseFunction(
  baseFunctionName: MongoSupportedBaseFunctionNames,
  collection: string | undefined
): MongoBaseFunctionParameterMapping {
  return {
    ...defaultParameterMappings[baseFunctionName],
    collection
  };
}

export const parseCollection = (
  rawCollection: string | undefined
): string | undefined => {
  try {
    return JSON.parse(rawCollection!);
  } catch (e) {
    if (typeof rawCollection === "string") return rawCollection || undefined;
  }
};

export function getCodeBlocks(
  code: BaseCode,
  baseFunctionName: MongoSupportedBaseFunctionNames,
  onCodeChange: (type: MongoCodeParameterType, code: BaseCode) => void
) {
  const codeParamTypes = supportedCodeTypes[baseFunctionName];
  if (typeof code === "string") {
    return [
      {
        label: codeParamTypes[0] as string,
        code,
        key: codeParamTypes[0],
        onChange: (nextCode: string) => onCodeChange(codeParamTypes[0], nextCode)
      }
    ];
  }

  return codeParamTypes.map(t => {
    return {
      label: t as string,
      code: code[t],
      key: t,
      onChange: (nextCode: string) => onCodeChange(t, { ...code, [t]: nextCode })
    };
  });
}

const codeErrorMessages = {
  [MongoCodeParameterType.PIPELINE]:
    "Please enter your aggregate pipeline stages as a valid JSON array.",
  [MongoCodeParameterType.DOCUMENTS]:
    "Please provide a valid document to write to your collection.",
  [MongoCodeParameterType.FILTER]:
    "Please provide a valid filter document to select the items in your collection to operate on.",
  [MongoCodeParameterType.UPDATE]:
    "Please provide a valid update document to apply to the filtered set of items from your collection."
};

export function selectErrors({
  baseFunction,
  baseFunctionParameterMapping: mapping
}: DataSourceFunction) {
  const errors = {
    collection: "",
    pipeline: "",
    documents: "",
    filter: "",
    update: "",
    mapping: ""
  };

  if (!isMongoBaseFunctionParameterMapping(mapping)) {
    errors.mapping = "Mapping is not a MongoBaseFunctionParameterMapping.";
    return errors;
  }

  if (baseFunction === null) throw new Error("Expected baseFunction to not be null.");

  const { name } = baseFunction;

  if (!mapping.collection) {
    errors.collection = "Please select a collection.";
  }

  supportedCodeTypes[name as MongoSupportedBaseFunctionNames].forEach(codeParam => {
    if (!mapping[codeParam]) {
      errors[codeParam] = codeErrorMessages[codeParam];
    }
  });
  return errors;
}

export function isCodeMetadataCompatible(
  baseFunctionName: MongoSupportedBaseFunctionNames,
  codeMetadata: BaseCode,
  mapping: MongoBaseFunctionParameterMapping
) {
  const supportedCodeParams = supportedCodeTypes[baseFunctionName];

  // If mapping has the wrong number of keys code it is not compatible
  if (
    Object.keys(mapping).length !==
    Object.keys(defaultParameterMappings[baseFunctionName]).length
  ) {
    return false;
  }

  // If mapping does not have a key for each code param it is not compatible
  if (!supportedCodeParams.every(p => !!mapping[p])) {
    return false;
  }

  // If only one code param for function type valid if codeMetadata is a string
  if (supportedCodeParams.length === 1) {
    return typeof codeMetadata === "string" && !!codeMetadata;
  }

  // If multiple code params code meta data must be a record with keys for each
  // and no additional keys
  if (supportedCodeParams.length > 1) {
    return (
      supportedCodeParams.every(p => {
        return typeof codeMetadata !== "string" && codeMetadata[p];
      }) && Object.keys(codeMetadata).length === supportedCodeParams.length
    );
  }

  throw new Error("Unexpected params for code meta data check.");
}
