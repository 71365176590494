import React from "react";

import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { isSupported } from "../../../../../common/FunctionEditor/support";
import useAuthUser from "../../../../../common/hooks/useAuthUser";
import Section, { Empty } from "../../../../../common/Menu/Section";

import {
  FETCH_DATA_PROVIDERS_AND_USER_DEFINED_FUNCTIONS,
  FetchDataProvidersAndUserDefinedFunctionsData
} from "./queries";
import * as styled from "./styledComponents";
import { selectDataSources } from "./util";

export interface Props {
  onAdd: (dataSourceId: string) => void;
  onSelect: (dataSourceId: string, functionId: string) => void;
  selected: string[];
}

export const FunctionList = (props: Props) => {
  const { isAdmin } = useAuthUser();
  const { data } = useQuery<FetchDataProvidersAndUserDefinedFunctionsData>(
    FETCH_DATA_PROVIDERS_AND_USER_DEFINED_FUNCTIONS
  );
  const dataSources = selectDataSources(data).filter(ds => isSupported(ds.integration));

  return (
    <>
      {dataSources.map(ds => (
        <Section
          key={ds.id}
          title={ds.name}
          onTitleClick={isAdmin ? () => props.onAdd(ds.id) : undefined}
        >
          {!ds.functions.edges.length ? (
            <Empty>No custom functions</Empty>
          ) : (
            <styled.List>
              {ds.functions.edges.map(f => (
                <styled.ListItem
                  data-test="functionListItem"
                  key={`${ds.id}-${f.node.id}`}
                  selected={_.isEqual(props.selected, [ds.id, f.node.id])}
                  onClick={() => props.onSelect(ds.id, f.node.id)}
                >
                  <styled.ListItemCopy>{f.node.title}</styled.ListItemCopy>
                  {isAdmin && <styled.EditButton type="edit" />}
                </styled.ListItem>
              ))}
            </styled.List>
          )}
        </Section>
      ))}
    </>
  );
};
