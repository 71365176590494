import React from "react";

import { Routes } from "../../constants";
import { Unauthorized } from "../../pages/errors";
import { AuthStatus } from "../../types";
import { EnvironmentContextProvider } from "../common/contexts/EnvironmentContext";
import useAuthStatus from "../common/hooks/useAuthStatus";
import useAuthUser from "../common/hooks/useAuthUser";
import useOrgClientSecret from "../common/hooks/useOrgClientSecret";
import { assertNever } from "../util/assertNever";

interface ContainerProps {
  children?: React.ReactNode;
}

const ClientSecret = ({ children }: ContainerProps) => {
  const { loading, clientSecret } = useOrgClientSecret();

  if (!loading && clientSecret) {
    window.INTERNAL_CLIENT_SECRET = clientSecret;
  } else {
    window.INTERNAL_CLIENT_SECRET = null;
  }

  if (loading) {
    return null;
  }

  return <EnvironmentContextProvider>{children}</EnvironmentContextProvider>;
};

const RequireScopes = ({
  children,
  scopes
}: ContainerProps & {
  scopes: string[];
}) => {
  const { isAdmin, authUser, loading } = useAuthUser();

  if (loading) return null;

  return scopes.every((scope: string) =>
    scope === "admin" ? isAdmin : authUser?.scopes?.includes(scope)
  ) ? (
    <>{children}</>
  ) : (
    <Unauthorized />
  );
};

export const getRedirectUrl = (status: AuthStatus, current: string): string => {
  switch (status) {
    case AuthStatus.Unauthenticated:
      const ssoKeyHint = new URL(current).searchParams.get("_sso_key_hint");
      if (ssoKeyHint) {
        const next = new URL(`/api/sso/${ssoKeyHint}/login`, current);
        next.searchParams.set("next", current);
        return next.toString();
      } else {
        const next = new URL(Routes.LOGIN, current);
        next.searchParams.set("next", current);
        return next.toString();
      }
    case AuthStatus.Partial:
      const next = new URL(Routes.LOGIN_TWO_FACTOR, current);
      next.searchParams.set("next", current);
      return next.toString();
    case AuthStatus.Authenticated:
      throw new Error("unhandled auth status");
    default:
      return assertNever(status);
  }
};

export const RequireAuth = ({
  children,
  scopes
}: ContainerProps & {
  scopes?: string[];
}) => {
  const { loading, status } = useAuthStatus();

  if (loading) {
    return null;
  } else if (status !== AuthStatus.Authenticated) {
    window.location.href = getRedirectUrl(status, window.location.href);
    return null;
  } else {
    return scopes ? (
      <RequireScopes scopes={scopes}>
        <ClientSecret>{children}</ClientSecret>
      </RequireScopes>
    ) : (
      <ClientSecret>{children}</ClientSecret>
    );
  }
};
