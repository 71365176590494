import React, { useEffect, useMemo, useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button, Dropdown, Icon, Menu } from "antd";
import _ from "lodash";

import { GET_ALL_ROLES } from "../../../graphql/queries";
import { Edge, RoleNode } from "../../../types";
import Message from "../../common/Message";

export enum RoleDropdownTypes {
  Button,
  Link
}

interface RolesDropdownProps {
  handleRoleSelected: (roleId: string) => void;
  type: RoleDropdownTypes;
  selectItemByDefault?: boolean;
  autoClearSelection?: boolean;
  disabled?: boolean;
}

const sorter = (a: any, b: any) => {
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  return 0;
};

const RolesDropdown = (props: RolesDropdownProps) => {
  const { autoClearSelection, handleRoleSelected, selectItemByDefault } = props;
  const [selectedRole, setSelectedRole] = useState<RoleNode | null | undefined>(null);

  const { data, error, loading } = useQuery(GET_ALL_ROLES);
  if (error) {
    Message.error("An error occurred while loading roles, please try again.");
  }

  const roles = useMemo(() => {
    return _.get<Edge<RoleNode>[]>(data, "allRoles.edges", [])
      .map(e => e.node)
      .sort(sorter);
  }, [data]);

  // Note that handleRoleSelected isn't included in the dependencies array, we
  // have too many places where we use an arrow function as that prop (which are
  // never equal to each other) and gets into an infinite loop on render.
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const role = roles[0];
    if (!selectItemByDefault || !role) {
      return;
    }

    setSelectedRole(role);
    handleRoleSelected(role.id);
  }, [selectItemByDefault, roles]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleClick = (e: any) => {
    setSelectedRole(roles.find((r: any) => r.id === e.key));
    handleRoleSelected(e.key);

    if (autoClearSelection) {
      setSelectedRole(null);
    }
  };

  const menu = (
    <Menu onClick={handleClick}>
      {roles.map((role: RoleNode) => {
        return <Menu.Item key={role.id}>{role.name}</Menu.Item>;
      })}
    </Menu>
  );

  let text = (
    <span>
      Assign Role <Icon type="down" style={{ fontSize: "10px" }} />
    </span>
  );
  if (selectedRole) {
    text = (
      <span>
        {selectedRole.name} <Icon type="down" style={{ fontSize: "10px" }} />
      </span>
    );
  }

  return (
    <Dropdown
      className="rolesDropdown"
      disabled={loading || props.disabled}
      overlay={menu}
    >
      {props.type === RoleDropdownTypes.Button ? (
        <Button>{text}</Button>
      ) : (
        <span className="ant-dropdown-link">{text}</span>
      )}
    </Dropdown>
  );
};

export default RolesDropdown;
