import Icon from "../../../../../assets/spaceicons/form.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  ElementLayout,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  OverflowOption
} from "../../../layout/util";
import ConfigPopover, {
  getOutputBinding,
  getSchema,
  errorSelector
} from "../common/FunctionBackedPopover";
import { SubmittableComponentConfigState } from "../common/FunctionBackedPopover/reducer";
import reducer, {
  ensureSubmittableComponent,
  makeInitialState,
  SubmittableComponent,
  SubmittableComponentProps
} from "../common/FunctionBackedPopover/reducer/reducer";

import SpaceFunctionForm from "./SpaceFunctionForm";

const pkg: SpaceComponentPackage<
  SubmittableComponentProps,
  SubmittableComponent,
  SubmittableComponentConfigState
> = {
  type: "FUNCTION_FORM",
  displayName: "Form",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["SPACE", "FLEX_BOX"]),
  isRepeatable: false,
  Component: SpaceFunctionForm as any,
  ConfigurationComponent: ConfigPopover,
  componentConfigReducer: reducer,
  ensureComponent: ensureSubmittableComponent,
  getInitialDraftState: makeInitialState,
  getOutputBinding,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.TALL.width,
    height: DEFAULT_SIZES.TALL.height,
    snapToGrid: true
  }),
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    overflow: [OverflowOption.HIDDEN]
  },
  allowSelfBinding: true,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
