import React from "react";

import { BindingShape } from "../../../../../../../types";
import { getOption, Option } from "../../../../../../common/BindingCascader";
import { createConfigContextParams } from "../../../../../../common/FormBuilderModal/utils";
import TemplateEditor from "../../../../../../common/TemplateEditor";
import { ReducerActions } from "../../../../reducer/actions";
import { getTaskSchemaFromFields } from "../../../../reducer/utils";
import { Field, OpenUrlOptions } from "../../../../types";
import * as common from "../styledComponents";
import { ActionProps } from "../types";

import * as styled from "./styledComponents";

const createBindingOptions = (fields: Field[]): Option[] => {
  const params = createConfigContextParams(getTaskSchemaFromFields(fields));
  return Object.entries(params).map(([k, v]) => ({
    label: v.title,
    value: k,
    bindingShape: BindingShape.OBJECT,
    children: v.schema.map(getOption)
  }));
};

const OpenUrl = ({ state, action, dispatch, index }: ActionProps<OpenUrlOptions>) => {
  const onChangeTemplate = (template: string) =>
    dispatch({
      type: ReducerActions.UPDATE_ACTION,
      payload: {
        action: { options: { template } },
        index
      }
    });

  const bindingOptions = createBindingOptions(state.fields);

  return (
    <common.Container>
      <styled.Label>Configure a URL template</styled.Label>
      <TemplateEditor
        bindingOptions={bindingOptions}
        value={action.options?.template || "``"}
        onChange={onChangeTemplate}
      />
    </common.Container>
  );
};

export default OpenUrl;
