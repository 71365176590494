import React, { ReactNode } from "react";

import { Checkbox, Select, Radio } from "antd";
import { uniqueId } from "lodash";
import styled from "styled-components";

import { AttributeValueInputProps } from "..";
import { FormItemNoBottomMargin } from "../../StyledComponents";
import { FieldType } from "../constants";

const MinWidthSelect = styled(Select)`
  min-width: 100px;
`;
MinWidthSelect.displayName = "MinWidthSelect";

export enum BooleanSelect {
  TRUE = "true",
  FALSE = "false",
  NULL = "null"
}

export default function BooleanInput({
  sourceName,
  sourceNullable,
  value,
  disabled,
  form,
  label,
  fieldOptions,
  fieldType,
  options,
  verbose,
  onFocus = () => {},
  onBlur = () => {}
}: AttributeValueInputProps) {
  const [shouldBlur, setShouldBlur] = React.useState(false);
  let initialValue = value;
  let input = null;
  const elementId = uniqueId("booleanInput");
  const rules = fieldOptions?.rules || [];
  const requiredRule = rules.find(rule => rule.required);
  if (requiredRule) {
    requiredRule.required = undefined;
    requiredRule.whitespace = undefined;
    requiredRule.validator = (_rule, value, cb) => {
      if (sourceNullable) {
        if (Object.values(BooleanSelect).includes(value)) return cb();
        cb({ error: true });
      } else {
        if (verbose) if (Object.values(BooleanSelect).includes(value)) return cb();
        typeof value === "boolean" ? cb() : cb({ error: true });
      }
    };
  }

  const decorator = form
    ? form.getFieldDecorator(sourceName, {
        initialValue: value,
        ...fieldOptions
      })
    : (comp: ReactNode) => comp;

  const handleFocus = () => {
    onFocus(new Event("focus"));
  };

  React.useEffect(() => {
    if (shouldBlur) {
      onBlur(new Event("focus"));
      setShouldBlur(false);
    }
  }, [shouldBlur, onBlur]);

  switch (fieldType) {
    case FieldType.RADIO:
      return (
        <FormItemNoBottomMargin>
          {decorator(
            <Radio.Group>
              {options!.map(o => (
                <Radio key={o.value} value={o.value}>
                  {o.label}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </FormItemNoBottomMargin>
      );
    case FieldType.DROPDOWN:
      return (
        <FormItemNoBottomMargin>
          {decorator(
            <MinWidthSelect
              placeholder="Select one"
              onFocus={handleFocus}
              onBlur={onBlur}
            >
              {options!.map(o => (
                <Select.Option key={o.value} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </MinWidthSelect>
          )}
        </FormItemNoBottomMargin>
      );
    case FieldType.BOOL_INPUT:
    default:
      if (sourceNullable === false && !verbose) {
        initialValue = !!value;
        input = (
          <Checkbox
            checked={form ? form!.getFieldValue(sourceName) : initialValue}
            onClick={() => {
              handleFocus();
              setShouldBlur(true);
            }}
            disabled={disabled}
          >
            {label || ""}
          </Checkbox>
        );
      } else if (sourceNullable === false && verbose) {
        initialValue = value === true ? BooleanSelect.TRUE : BooleanSelect.FALSE;
        input = (
          <MinWidthSelect
            getPopupContainer={() => document.getElementById(elementId) as HTMLElement}
            disabled={disabled}
            placeholder="Select one"
            onFocus={handleFocus}
            onBlur={onBlur}
          >
            <Select.Option value={BooleanSelect.TRUE}>TRUE</Select.Option>
            <Select.Option value={BooleanSelect.FALSE}>FALSE</Select.Option>
          </MinWidthSelect>
        );
      } else if (sourceNullable === true) {
        initialValue =
          value === true
            ? BooleanSelect.TRUE
            : value === false
            ? BooleanSelect.FALSE
            : BooleanSelect.NULL;
        input = (
          <MinWidthSelect
            getPopupContainer={() => document.getElementById(elementId) as HTMLElement}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={onBlur}
          >
            <Select.Option value={BooleanSelect.TRUE}>TRUE</Select.Option>
            <Select.Option value={BooleanSelect.FALSE}>FALSE</Select.Option>
            <Select.Option value={BooleanSelect.NULL}>NULL</Select.Option>
          </MinWidthSelect>
        );
      } else {
        throw new Error("Unexpected value for sourceNullable");
      }
      return (
        <div id={elementId}>
          <FormItemNoBottomMargin>
            {form
              ? form.getFieldDecorator(sourceName, {
                  ...fieldOptions,
                  initialValue
                })(input)
              : input}
          </FormItemNoBottomMargin>
        </div>
      );
  }
}
