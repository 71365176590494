import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import SelectDataSource from "../components/setup_flow/SelectDataSource";
import { BasicLayout } from "../layouts";

export const DataSource = () => (
  <RequireAuth>
    <BasicLayout>
      <SelectDataSource />
    </BasicLayout>
  </RequireAuth>
);
