import React from "react";

import ReactDOM from "react-dom";
import { Manager, PopperChildrenProps, Reference } from "react-popper";
import ResizeObserver from "resize-observer-polyfill";
import styled from "styled-components";

import { DARK_THEME_POPPER_PORTAL_ID } from "../../../../../../constants";
import {
  GlobalStyleVariables,
  SpacingUnitValue,
  zIndex
} from "../../../../../../cssConstants";
import useOutsideClick from "../../../../../common/hooks/useOutsideClick";
import Popper from "../../../../../common/Popper";
import {
  PopperOverrideContext,
  PopperOverrideContextValue
} from "../../../../../common/Popper/Popper";
import ThemeContainer, { Theme } from "../../../../../common/ThemeContainer";

import {
  useSpaceConfigPanelContext,
  ConfigPanelActionTypes
} from "./ConfigPanelContext";
import { StyledConfigContainer } from "./styledComponents";

const DEFAULT_HEIGHT = "200px";
const ConfigPopperStyledContainer = styled(StyledConfigContainer)`
  position: absolute;
  width: ${props => props.theme.configPanelWidth};
  background: ${props => props.theme.popperBackground};
  box-shadow: ${props => props.theme.popperBoxShadow};
  border-radius: ${props => props.theme.borderRadiusmd};
  z-index: ${zIndex.configPanel};
  overflow: visible; /* set to visible to allow large popovers/selects in the popper to render outside the container. side effect is child tabs need to turn animation off, otherwise you see it "fly in" from outside the container */

  .ant-tabs .ant-tabs-tab {
    margin: 0;
    padding: 12px ${props => props.theme.spacerxl};
  }
`;

const getPixelValue = (value: string) => {
  return parseInt(value.substring(0, value.indexOf("px")));
};

const TargetContainer = styled.div`
  cursor: pointer;
`;

interface ConfigPopperContentsProps {
  children: React.ReactNode;
}

const ConfigPopperContents = React.forwardRef(
  (
    props: ConfigPopperContentsProps,
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
  ) => {
    return (
      <ThemeContainer theme={Theme.Dark}>
        <ConfigPopperStyledContainer ref={ref}>
          {props.children}
        </ConfigPopperStyledContainer>
      </ThemeContainer>
    );
  }
);

interface ConfigPanelPopperProps {
  popperId: string;
  children: React.ReactNode;
  popperReferenceElement?: HTMLElement;
  onCancel?: () => void;
}

export const ConfigPanelPopper = (props: ConfigPanelPopperProps) => {
  const { state } = useSpaceConfigPanelContext();
  const container = document.getElementById(DARK_THEME_POPPER_PORTAL_ID) as Element;
  const popperContentsRef = React.useRef<HTMLDivElement>(null); // used for popper to determine outside click and height
  const onCancel = props.onCancel ? props.onCancel : () => {};
  useOutsideClick(popperContentsRef, onCancel);

  const contextValue: PopperOverrideContextValue = React.useMemo(
    () => ({
      placement: "top" as const, // aligns with top of reference element
      modifiers: [
        {
          name: "flip",
          enabled: false
        },
        {
          name: "popperOffsetsAdjusted",
          enabled: true,
          phase: "afterRead", // "popperOffsets" modifier occurs during "read" phase
          fn: (data: any) => {
            const { state } = data;
            const { y } = state.modifiersData["popperOffsets"];

            // Align the popper so it's always to the left of the config panel with a `sm` margin in between.
            // Subtract configPanelWidth twice, once for the panel and once for the popper, which has the same width.
            const newX =
              window.innerWidth -
              getPixelValue(GlobalStyleVariables.configPanelWidth) * 2 -
              SpacingUnitValue.sm;

            // To compute whether the popper's translateY value needs to be adjusted,
            // we need to get the height of the popper using the `popperContentsRef` ref.
            const popperContentHeight =
              popperContentsRef.current?.getBoundingClientRect().height ||
              getPixelValue(DEFAULT_HEIGHT);
            // Align the popper to be either vertically aligned with the reference point, or higher up if the reference
            // is lower than the height of the popper (so that the popper does not get cut off at the bottom).
            const newY = Math.min(
              y,
              window.innerHeight - popperContentHeight - SpacingUnitValue.sm // add `sm` margin below popper
            );

            state.modifiersData["popperOffsets"] = {
              x: newX,
              y: newY
            };
          }
        }
      ]
    }),
    []
  );

  const isVisible = state.activePopperIdentifier === props.popperId;
  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <PopperOverrideContext.Provider value={contextValue}>
      <Popper
        maskClosable
        onCancel={onCancel}
        referenceElement={props.popperReferenceElement}
      >
        {({ ref, style, placement, update }: PopperChildrenProps) => {
          return (
            <div ref={ref} style={style} data-placement={placement}>
              <MemoObservedPopperContents
                update={update}
                popperContentsRef={popperContentsRef}
              >
                {props.children}
              </MemoObservedPopperContents>
            </div>
          );
        }}
      </Popper>
    </PopperOverrideContext.Provider>,
    container
  );
};

interface ConfigPanelPopperWithTargetProps {
  popperId: string;
  target: React.ReactNode;
  children: React.ReactNode;
}

export const ConfigPanelPopperWithTarget = (
  props: ConfigPanelPopperWithTargetProps
) => {
  const { dispatch } = useSpaceConfigPanelContext();
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <TargetContainer
            ref={ref}
            onClick={() =>
              dispatch({
                type: ConfigPanelActionTypes.OPEN_POPPER,
                payload: {
                  popperIdentifier: props.popperId
                }
              })
            }
          >
            {props.target}
          </TargetContainer>
        )}
      </Reference>
      <ConfigPanelPopper
        popperId={props.popperId}
        onCancel={() =>
          dispatch({
            type: ConfigPanelActionTypes.CLOSE_POPPER
          })
        }
      >
        {props.children}
      </ConfigPanelPopper>
    </Manager>
  );
};

const MemoObservedPopperContents = React.memo(ObservedPopperContents);

function ObservedPopperContents({
  popperContentsRef,
  children,
  update
}: {
  popperContentsRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  update: () => void;
}) {
  React.useLayoutEffect(() => {
    if (popperContentsRef.current === null) return;
    new ResizeObserver(update).observe(popperContentsRef.current);
  }, [update, popperContentsRef]);

  /* ref `popperContentsRef` used for outside click and to determine popper height */
  return (
    <ConfigPopperContents ref={popperContentsRef}>{children}</ConfigPopperContents>
  );
}
