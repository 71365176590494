import React from "react";

import { titleize } from "inflection";

import {
  Cursor,
  CursorType,
  DataValue,
  ListFunctionCursor,
  ViewFilterOperator
} from "../../../../../types";
import Panel from "../../../../common/Panel";
import ToggleableRelatedRecords from "../common/ToggleableRelatedRecords";
import { FunctionNode } from "../useRecordData/queries";
import { getFriendlyFunctionName } from "../util";

interface Props {
  func: FunctionNode;
  record: Record<string, DataValue>;
}

interface RelatedRecordProps {
  id: string;
  name: string;
  cursor: Cursor;
}

const RelatedFunctions = ({ func, record }: Props) => {
  const getFunctions: RelatedRecordProps[] = [];
  const listFunctions: RelatedRecordProps[] = [];

  for (const e of func.outboundFunctionLinks.edges) {
    const categories = e.node.toFunction.metadata?.categories || [];

    if (categories.includes("returns:one")) {
      const [parameterName, attributeName] = Object.entries(
        e.node.parameterAttributeMapping
      )[0];
      getFunctions.push({
        id: e.node.id,
        name: titleize(attributeName),
        cursor: {
          type: CursorType.FUNCTION,
          id: e.node.toFunction.id,
          params: {
            [parameterName]: record[attributeName]
          }
        }
      });
    }

    if (categories.includes("returns:many")) {
      const [parameter, attribute] = Object.entries(
        e.node.parameterAttributeMapping
      )[0];

      const cursor: ListFunctionCursor = {
        type: CursorType.FUNCTION,
        id: e.node.toFunction.id,
        params: {
          filters: [
            {
              attribute: parameter,
              operator: ViewFilterOperator.EQUALS,
              value: record[attribute]
            }
          ]
        }
      };

      listFunctions.push({
        id: e.node.id,
        name: getFriendlyFunctionName(e.node.toFunction.title),
        cursor
      });
    }
  }

  getFunctions.sort((a, b) => a.name.localeCompare(b.name));
  listFunctions.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Panel title="Related Records">
      {getFunctions.map(p => (
        <ToggleableRelatedRecords
          key={p.id}
          name={p.name}
          cursor={p.cursor}
          isOutbound={true}
        />
      ))}
      {getFunctions.length > 0 && listFunctions.length > 0 ? <hr /> : null}
      {listFunctions.map(p => (
        <ToggleableRelatedRecords
          key={p.id}
          name={p.name}
          cursor={p.cursor}
          isOutbound={false}
        />
      ))}
    </Panel>
  );
};

export default RelatedFunctions;
