import React from "react";

import { Dispatch as FlowConfigDispatch, State as FlowConfigState } from "../reducer";

export { default as ExecuteFunctionStep } from "./ExecuteFunctionStep";
export { default as ExpressionStep } from "./ExpressionStep";
export { default as OAuth2Step } from "./OAuth2Step";
export { default as InputStep } from "./InputStep";
export { default as CredentialStep } from "./CredentialStep";

export type StepComponent<T> = React.FunctionComponent<StepProps<T>> & {
  selectExports: (step: T) => string[];
};

export interface StepProps<T> {
  step: T;
  state: FlowConfigState;
  dispatch: FlowConfigDispatch;
  updateOptions: (options: any) => void;
}
