import {
  ConditionalExpression,
  ManagedExpressionDefinitions,
  ConditionalOperator
} from "./types";

const evaluatedFields = ["template"];
const managedFields = ["subject_template", "operator", "object_template"];

export default function validateConditionalExpression(
  expression: ConditionalExpression
) {
  const errors = [];
  if (expression.type === "managed") {
    for (const field of evaluatedFields) {
      if (
        field in expression &&
        expression[field as keyof ConditionalExpression] !== null
      ) {
        errors.push(`Field ${field} is not allowed in this expression.`);
      }
      // Validate subject
      if (!expression.subject_template) {
        errors.push(`A subject must be provided for a conditional expression.`);
      }

      // Validate operator
      if (
        !expression.operator ||
        !Object.values(ConditionalOperator).includes(expression.operator)
      ) {
        errors.push("A valid operator must be provided for this expression.");
      }

      // Validate object
      const def = ManagedExpressionDefinitions.get(expression.operator);
      if (def?.hasConditionalObject) {
        if (!expression.object_template) {
          errors.push(
            `A comparison object must be provided for a conditional expression with operator ${expression.operator}.`
          );
        }
      } else {
        if (expression.object_template) {
          errors.push(
            `A comparison object must not be provided for a conditional expression with operator ${expression.operator}.`
          );
        }
      }
    }
  } else if (expression.type === "evaluated") {
    for (const field of managedFields) {
      if (
        field in expression &&
        expression[field as keyof ConditionalExpression] !== null
      ) {
        errors.push(`Field ${field} is not allowed in this expression.`);
      }
    }
    if (typeof expression.template !== "string" || !expression.template.trim().length) {
      errors.push("A template must be provided for evaluated conditional expressions.");
    }
  } else {
    errors.push("Invalid conditional expression type.");
  }
  return errors;
}
