import React from "react";

import { Radio } from "antd";

import { SpacingUnit } from "../../../../../../cssConstants";
import { SpaceComponentObject, ComponentConfigProps } from "../../../../../../types";
import Label from "../../../../../common/Label";
import { AuthenticationConfigSection } from "../../common/AuthenticationConfigSection";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError/ValidationError";
import { Field } from "../../common/ConfigPanel/styledComponents";
import NameFields from "../../common/NameFields";
import { DebouncedTemplateEditor, Height } from "../../common/TemplateEditor";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { LinkTarget, SpaceLinkComponent } from "../types";

import * as styled from "./styledComponents";

export function ensureSpaceLink(component: SpaceComponentObject): SpaceLinkComponent {
  if (
    typeof component.properties.label === "string" &&
    typeof component.properties.url_template === "string" &&
    typeof component.properties.target === "string"
  ) {
    return component;
  }
  return {
    ...component,
    properties: {
      label: "",
      url_template: "",
      text_template: "",
      target: LinkTarget.Blank,
      ...component.properties
    }
  };
}

export default function SpaceLinkConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<SpaceLinkConfigContent />}
      slug={slug}
    />
  );
}

function SpaceLinkConfigContent() {
  const { state, dispatch } = useComponentConfigContext();
  return (
    <>
      <BaseComponentConfigSection>
        <NameFields
          namePlaceholder="User Profile link"
          labelPlaceholder="User Profile"
        />
        <Field>
          <label>URL Template</label>
          <DebouncedTemplateEditor
            dataTest="link-url-template"
            value={state.draftComponent.properties.url_template}
            placeholder="https://example.com/users/${row.data.id}" // eslint-disable-line
            minHeight={Height.Large}
            onChange={template =>
              dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    properties: {
                      url_template: template
                    }
                  }
                }
              })
            }
          />
          <ValidationError field="URL_TEMPLATE" />
        </Field>
        <Field>
          <Label
            showInfoIcon
            title={
              "This is what appears in the actual field. To display the URL instead, you can leave this section blank."
            }
            style={{ marginBottom: SpacingUnit.sm }}
          >
            Link Text
          </Label>

          <DebouncedTemplateEditor
            dataTest="link-text-template"
            value={state.draftComponent.properties.text_template}
            placeholder="User ${row.data.id}" // eslint-disable-line
            minHeight={Height.Large}
            onChange={template =>
              dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    properties: {
                      text_template: template
                    }
                  }
                }
              })
            }
          />
          <ValidationError field="TEXT_TEMPLATE" />
        </Field>
        <Field>
          <label>How should the link be opened?</label>
          <styled.RadioGroup
            onChange={evt =>
              dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    properties: {
                      target: evt.target.value
                    }
                  }
                }
              })
            }
            value={state.draftComponent.properties.target}
          >
            <Radio key={LinkTarget.Blank} value={LinkTarget.Blank}>
              New window
            </Radio>
            <Radio key={LinkTarget.Self} value={LinkTarget.Self}>
              Same window
            </Radio>
            <Radio key={LinkTarget.Download} value={LinkTarget.Download}>
              Download file
            </Radio>
          </styled.RadioGroup>
          <ValidationError field="TARGET" />
        </Field>
      </BaseComponentConfigSection>
      <AuthenticationConfigSection title="Sign your link" />
      <VisibilityRulesManagerSection />
    </>
  );
}
