import React from "react";

import { Select, Input } from "antd";
import produce from "immer";
import styled from "styled-components";

import {
  PendingOAuth2Step,
  OAuth2ClientAuthenticationTypes,
  OAuth2GrantType,
  OAuth2StepOptions
} from "../reducer";

import { ErrorInput } from "./Errors";
import { Item, Label, Control } from "./StyledComponents";

import { StepProps } from ".";

const Root = styled.div`
  .ant-select:not(:first-child) {
    margin-top: ${props => props.theme.spacersm};
  }
`;

type Props = StepProps<PendingOAuth2Step>;

export const selectExports = (_step: PendingOAuth2Step): string[] => {
  return ["accessToken", "idToken", "refreshToken", "tokenExpiration"];
};

const OAuth2Step = ({ step, state, updateOptions }: Props) => {
  return (
    <Root>
      <Item>
        <Label>Redirect URI</Label>
        <Control>
          <span>{`${window.location.protocol}//${window.location.host}/api/authorization-flow/oauth2/callback`}</span>
        </Control>
      </Item>
      <Item>
        <Label>Grant Type</Label>
        <Control>
          <ErrorInput state={state} path={`step${step.order}.grant_type`}>
            <Select
              style={{ width: 200 }}
              value={step.options.grant_type}
              onChange={(val: OAuth2GrantType) =>
                updateOptions(
                  produce(step.options, (draft: OAuth2StepOptions) => {
                    draft.grant_type = val;
                  })
                )
              }
            >
              <Select.Option value={OAuth2GrantType.AUTHORIZATION_CODE}>
                Authorization Code
              </Select.Option>
              <Select.Option value={OAuth2GrantType.CLIENT_CREDENTIALS}>
                Client Credentials
              </Select.Option>
            </Select>
          </ErrorInput>
        </Control>
      </Item>

      <Item>
        <Label>Authorization URI</Label>
        <Control>
          <ErrorInput state={state} path={`step${step.order}.auth_uri`}>
            <Input
              value={step.options.auth_uri}
              onChange={e =>
                updateOptions(
                  produce(step.options, (draft: OAuth2StepOptions) => {
                    draft.auth_uri = e.target.value;
                  })
                )
              }
            />
          </ErrorInput>
        </Control>
      </Item>
      <Item>
        <Label>Token URI</Label>
        <Control>
          <ErrorInput state={state} path={`step${step.order}.token_uri`}>
            <Input
              value={step.options.token_uri}
              onChange={e =>
                updateOptions(
                  produce(step.options, (draft: OAuth2StepOptions) => {
                    draft.token_uri = e.target.value;
                  })
                )
              }
            />
          </ErrorInput>
        </Control>
      </Item>
      <Item>
        <Label>Client ID</Label>
        <Control>
          <ErrorInput state={state} path={`step${step.order}.client_id`}>
            <Input
              value={step.options.client_id}
              onChange={e =>
                updateOptions(
                  produce(step.options, (draft: OAuth2StepOptions) => {
                    draft.client_id = e.target.value;
                  })
                )
              }
            />
          </ErrorInput>
        </Control>
      </Item>
      <Item>
        <Label>Client Secret</Label>
        <Control>
          <ErrorInput state={state} path={`step${step.order}.client_secret`}>
            <Input
              value={step.options.client_secret}
              onChange={e =>
                updateOptions(
                  produce(step.options, (draft: OAuth2StepOptions) => {
                    draft.client_secret = e.target.value;
                  })
                )
              }
            />
          </ErrorInput>
        </Control>
      </Item>
      <Item>
        <Label>Scopes</Label>
        <Control>
          <ErrorInput state={state} path={`step${step.order}.scopes`}>
            <Input
              value={step.options.scopes?.join(" ") || ""}
              onChange={e =>
                updateOptions(
                  produce(step.options, (draft: OAuth2StepOptions) => {
                    draft.scopes = e.target.value.split(/\s+/);
                  })
                )
              }
            />
          </ErrorInput>
        </Control>
      </Item>
      <Item>
        <Label>Client Authentication</Label>
        <Control>
          <ErrorInput state={state} path={`step${step.order}.client_authentication`}>
            <Select
              style={{ width: 225 }}
              value={
                step.options.client_authentication ||
                OAuth2ClientAuthenticationTypes.BASIC
              }
              onChange={(val: OAuth2ClientAuthenticationTypes) =>
                updateOptions(
                  produce(step.options, (draft: OAuth2StepOptions) => {
                    draft.client_authentication = val;
                  })
                )
              }
            >
              <Select.Option value={OAuth2ClientAuthenticationTypes.BASIC}>
                Send as Basic Auth header
              </Select.Option>
              <Select.Option value={OAuth2ClientAuthenticationTypes.POST}>
                Send client credentials in body
              </Select.Option>
            </Select>
          </ErrorInput>
        </Control>
      </Item>
    </Root>
  );
};

OAuth2Step.selectExports = selectExports;

export default OAuth2Step;
