import React from "react";

import {
  ResponsiveContainer,
  BarChart as BaseBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from "recharts";

import { chartColors, getChartLabelColor } from "../../../../../../cssConstants";

import { ChartData } from "./selectChartData";
import * as styled from "./styledComponents";

interface Props {
  value: ChartData;
}

const CustomTooltip = (props: any) => {
  return (
    <styled.TooltipContainer>
      <p>{props.label}</p>
      {(props.payload || []).map((p: any, i: number) => {
        // Not all data points contain all secondary values
        // Therefore we cannot use index to choose color
        return (
          <p key={`${p.name}:${i}`} style={{ color: getChartLabelColor(p.fill) }}>
            {p.name}: {p.payload[p.name]}
          </p>
        );
      })}
    </styled.TooltipContainer>
  );
};

export default function BarChart({ value: { data, dataKeys } }: Props) {
  const colors = Object.values(chartColors);
  const bars = dataKeys.map((k, i) => (
    <Bar key={k} dataKey={k} fill={colors[i % colors.length]} />
  ));
  return (
    <ResponsiveContainer>
      <BaseBarChart data={data}>
        <XAxis dataKey="__xAxisDataKey" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {bars}
      </BaseBarChart>
    </ResponsiveContainer>
  );
}
