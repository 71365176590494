import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Form, Input, Select, Skeleton } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import gql from "graphql-tag";

import { AuthorizationFlowNode } from "../../../types";

import { OAuthInputFields } from "./OAuthInputFields";
import { Header, LeftSide, RightSide } from "./styledComponents";

const OAUTH2_DETAILS_QUERY = gql`
  query QueryOAuth2Details($id: ID!) {
    authorizationProvider(id: $id) {
      name
      oauth2Details {
        clientId
        clientSecret
        authUri
        tokenUri
        scopes
      }
    }
  }
`;

const UPDATE_OAUTH_PROVIDER = gql`
  mutation UpdateOAuthProvider(
    $id: ID!
    $name: String
    $clientId: String
    $clientSecret: String
    $authUri: String
    $tokenUri: String
    $scopes: [String]
  ) {
    updateOAuthAuthorizationProvider(
      id: $id
      name: $name
      clientId: $clientId
      clientSecret: $clientSecret
      authUri: $authUri
      tokenUri: $tokenUri
      scopes: $scopes
    ) {
      ok
    }
  }
`;

interface OAuth2Details {
  clientId: string;
  clientSecret: string;
  authUri: string;
  tokenUri: string;
  scopes: string[];
}

interface AuthProvider {
  name: string;
  oauth2Details: OAuth2Details;
}

interface Props extends FormComponentProps {
  authorizationProvider: AuthorizationFlowNode;

  onCloseButtonClick(): void;

  onEditComplete(): void;
}

const OAuthAuthProviderDrawer = ({
  onCloseButtonClick,
  onEditComplete,
  form,
  authorizationProvider
}: Props) => {
  const getFieldDecorator = form.getFieldDecorator;

  const { data, loading, refetch } = useQuery<
    { authorizationProvider: AuthProvider },
    { id: string }
  >(OAUTH2_DETAILS_QUERY, {
    variables: { id: authorizationProvider.id },
    fetchPolicy: "no-cache"
  });

  const [updateProvider, { loading: isSaveLoading }] = useMutation(
    UPDATE_OAUTH_PROVIDER,
    {
      onCompleted: () => {
        refetch();
        onEditComplete();
      }
    }
  );

  if (loading || !data) {
    return <Skeleton active />;
  }

  const ap = data.authorizationProvider;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    form.validateFields(err => {
      if (!err) {
        const vars = { id: authorizationProvider.id };

        const touchedFields = [
          "name",
          "clientId",
          "clientSecret",
          "authUri",
          "tokenUri",
          "scopes"
        ].filter(fieldName => form.isFieldTouched(fieldName));

        if (!touchedFields.length) return;

        const changes = form.getFieldsValue(touchedFields);

        if (changes.scopes) {
          changes.scopes = changes.scopes
            .split(" ")
            .filter((s: string) => s.trim() !== "");
        }

        updateProvider({ variables: { ...vars, ...changes } });
      }
    });
  };

  return (
    <>
      <Header>
        <LeftSide>
          <h2>OAuth 2.0 Authorization Provider</h2>
        </LeftSide>
        <RightSide>
          <Button onClick={onCloseButtonClick}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!form.isFieldsTouched()}
            loading={isSaveLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </RightSide>
      </Header>

      <Form hideRequiredMark layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator("name", {
            initialValue: ap.name,
            rules: [{ required: true, message: "Name is required." }]
          })(<Input placeholder="Name" />)}
        </Form.Item>
        <Form.Item label="Type">
          <Select disabled value={0}>
            <Select.Option value={0}>OAuth 2.0</Select.Option>
          </Select>
        </Form.Item>
        <OAuthInputFields
          createMode
          getFieldDecorator={getFieldDecorator}
          {...ap.oauth2Details}
        />
      </Form>
    </>
  );
};

export default Form.create<Props>({
  name: "editOAuthProvider"
})(OAuthAuthProviderDrawer);
