import React from "react";

import moment from "moment";

import { ClientErrorResult } from "../../../../types";

import SpaceConsoleModal from "./SpaceConsoleModal";
import useSpaceConsole, { INITIAL_STATE, SpaceConsoleState } from "./useSpaceConsole";

export interface SpaceConsoleContextValue {
  state: SpaceConsoleState;
  setVisible: (visible: boolean) => void;
  addClientError: (error: ClientErrorResult) => void;
}

export const SpaceConsoleContext = React.createContext<SpaceConsoleContextValue>({
  state: INITIAL_STATE,
  setVisible: () => null,
  addClientError: () => null
});

export interface SpaceConsoleContextProviderProps {
  children: React.ReactNode;
}

export function SpaceConsoleContextProvider({
  children
}: SpaceConsoleContextProviderProps) {
  const { state, dispatch } = useSpaceConsole();

  const setVisible = React.useCallback(
    (visible: boolean) => {
      dispatch({
        type: "SET_CONSOLE_VISIBLE",
        payload: { visible: visible }
      });
    },
    [dispatch]
  );

  const addClientError = React.useCallback(
    (error: ClientErrorResult) => {
      dispatch({
        type: "ADD_CLIENT_ERROR",
        payload: {
          error: {
            code: error.code,
            message: error.message,
            errorInfo: {
              reason: error.errorInfo?.reason,
              domain: error.errorInfo?.domain,
              metadata: error.errorInfo?.metadata
            },
            when: moment.utc().format()
          }
        }
      });
    },
    [dispatch]
  );

  const value = React.useMemo(() => {
    return {
      state,
      setVisible,
      addClientError
    };
  }, [state, setVisible, addClientError]);

  return (
    <SpaceConsoleContext.Provider value={value}>
      {children}
      <SpaceConsoleModal
        errors={value.state.errors}
        visible={value.state.visible}
        onClose={() => value.setVisible(false)}
      />
    </SpaceConsoleContext.Provider>
  );
}

export const useSpaceConsoleContext = () => React.useContext(SpaceConsoleContext);
