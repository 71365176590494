import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ApolloClient, DefaultOptions } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import Cookies from "js-cookie";

import Message from "../components/common/Message";

import { createEnvironmentLink } from "./environment";
import introspectionQueryResultData from "./fragmentTypes.json";

const csrfLink = setContext((_, { headers }: any) => {
  return { headers: { ...headers, "X-CSRFToken": Cookies.get("csrftoken") } };
});

const defaultOptions: DefaultOptions = {
  watchQuery: { errorPolicy: "all" },
  query: { errorPolicy: "all" }
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

export const cache = new InMemoryCache({ fragmentMatcher });

export const client = new ApolloClient({
  cache,
  defaultOptions,
  link: ApolloLink.from([
    onError(({ networkError }: any) => {
      if (!networkError) {
        return;
      }

      if (networkError.statusCode === 401) {
        window.location.reload();
        return;
      }

      if (networkError.statusCode === 403) {
        return;
      }

      Message.error("Something went wrong. Please try again!");
    }),
    csrfLink,
    createEnvironmentLink(cache),
    createHttpLink({
      uri: window.location.origin + "/api/graphql/",
      credentials: "same-origin"
    })
  ])
});

export const publicClient = new ApolloClient({
  cache,
  defaultOptions,
  link: ApolloLink.from([
    onError(() => {
      Message.error("Something went wrong. Please try again!");
    }),
    csrfLink,
    createHttpLink({
      uri: window.location.origin + "/api/public/graphql/",
      credentials: "same-origin"
    })
  ])
});

export const partialAuthClient = new ApolloClient({
  cache,
  defaultOptions,
  link: ApolloLink.from([
    onError(({ networkError }: any) => {
      if (!networkError) {
        return;
      }

      if (networkError.statusCode === 401) {
        window.location.reload();
        return;
      }

      if (networkError.statusCode === 403) {
        return;
      }

      Message.error("Something went wrong. Please try again!");
    }),
    csrfLink,
    createHttpLink({
      uri: window.location.origin + "/api/partial/graphql/",
      credentials: "same-origin"
    })
  ])
});
