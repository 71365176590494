import React from "react";

import { Switch, Tooltip } from "antd";

import { Dot } from "../../../../../common/Dot/dot";
import { ensureHexValue } from "../../../../../common/utils";
import { StateNode } from "../../useQueueClient/useQueue/queries/QueueBySlug";

import * as Styled from "./styledComponents";

const DISABLED_SWITCH_MESSAGE = "You must have at least one state toggled on";

function StateItem({
  state,
  selected,
  disabled,
  disabledMessage,
  onSelected
}: {
  state: StateNode;
  selected: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  onSelected: (value: boolean) => void;
}) {
  const toggleElement = (
    <Switch
      size={"small"}
      checked={selected}
      disabled={disabled}
      onChange={checked => onSelected(checked)}
    >
      Checkbox
    </Switch>
  );

  const maybeWrappedElement = disabled ? (
    <Tooltip title={disabledMessage} trigger="hover">
      {toggleElement}
    </Tooltip>
  ) : (
    toggleElement
  );

  return (
    <Styled.StateItemContainer>
      <Dot color={ensureHexValue(state.color)} />
      <Styled.StateName>{state.name}</Styled.StateName>
      <Styled.Spacer />
      {maybeWrappedElement}
    </Styled.StateItemContainer>
  );
}

interface Props {
  states: StateNode[];
  selectedStateIds: string[];
  setSelectedStateIds: (ids: string[]) => void;
}

export function StateFilterDropdown({
  states,
  selectedStateIds,
  setSelectedStateIds
}: Props) {
  const openStates = states.filter(s => !s.isArchive);
  const archivedStates = states.filter(s => s.isArchive);

  const onSelected = React.useCallback(
    (id: string) => {
      const index = selectedStateIds.findIndex(stateId => stateId === id);
      if (index > -1) {
        // Always have one state selected
        if (selectedStateIds.length === 1) {
          return;
        }
        setSelectedStateIds(selectedStateIds.filter(stateId => stateId !== id));
      } else {
        setSelectedStateIds(selectedStateIds.concat(id));
      }
    },
    [selectedStateIds, setSelectedStateIds]
  );

  return (
    <>
      <Styled.StateHeading>Open tasks</Styled.StateHeading>
      <Styled.StateList>
        {openStates.map(state => (
          <StateItem
            key={state.id}
            state={state}
            disabled={
              selectedStateIds.length === 1 && selectedStateIds.includes(state.id)
            }
            disabledMessage={DISABLED_SWITCH_MESSAGE}
            selected={selectedStateIds.includes(state.id)}
            onSelected={() => onSelected(state.id)}
          />
        ))}
      </Styled.StateList>
      <Styled.Hr />
      <Styled.StateHeading>Archived tasks</Styled.StateHeading>
      <Styled.StateList>
        {archivedStates.map(state => (
          <StateItem
            key={state.id}
            state={state}
            disabled={
              selectedStateIds.length === 1 && selectedStateIds.includes(state.id)
            }
            disabledMessage={DISABLED_SWITCH_MESSAGE}
            selected={selectedStateIds.includes(state.id)}
            onSelected={() => onSelected(state.id)}
          />
        ))}
      </Styled.StateList>
    </>
  );
}
