import React from "react";

import { useComponentConfigContext } from "../ComponentConfigContext";
import { Checkbox, ConfigSection } from "../ConfigPanel";

interface Props {
  searchBoxCheckboxEnabled?: boolean;
}

export default function HeaderConfigSection({
  searchBoxCheckboxEnabled = false
}: Props) {
  const {
    state: {
      draftComponent: {
        properties: { is_header_enabled, is_search_enabled }
      }
    },
    dispatch
  } = useComponentConfigContext();

  return (
    <ConfigSection title="Header">
      <Checkbox
        data-test="header-visibility-checkbox"
        checked={is_header_enabled}
        onChange={({ target: { checked } }) => {
          dispatch({
            type: "MERGE_DRAFT_COMPONENT",
            payload: {
              change: { properties: { is_header_enabled: checked } }
            }
          });
        }}
      >
        Show the header.
      </Checkbox>

      {searchBoxCheckboxEnabled && (
        <Checkbox
          data-test="search-box-visibility-checkbox"
          checked={is_search_enabled}
          disabled={!is_header_enabled}
          onChange={({ target: { checked } }) => {
            dispatch({
              type: "MERGE_DRAFT_COMPONENT",
              payload: {
                change: { properties: { is_search_enabled: checked } }
              }
            });
          }}
        >
          Show the search box.
        </Checkbox>
      )}
    </ConfigSection>
  );
}
