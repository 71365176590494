/* eslint-disable  no-template-curly-in-string */
import React from "react";

import { CodeExample } from "../../StyledComponents";
import EvaluatedString from "../EvaluatedString";
import { PopoverContainer } from "../styledComponents";

interface Props {
  value: string;
  innerContent?: React.ReactNode;
}

const TemplateStringPopoverContent = ({ value, innerContent }: Props) => (
  <PopoverContainer>
    <div>
      This is a JavaScript template literal input.{" "}
      {innerContent || (
        <span>
          You can add function parameters by using JavaScript expressions with the
          syntax: <CodeExample>{"${expression}"}</CodeExample>. For example,{" "}
          <CodeExample>{"/v1/users/${user_id}/"}</CodeExample> or{" "}
          <CodeExample>{"${user_id.toUpperCase()}"}</CodeExample>.
        </span>
      )}
    </div>
    <EvaluatedString input={value} showValue={true} needsQuote={true} />
  </PopoverContainer>
);

export default TemplateStringPopoverContent;
