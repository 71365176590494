import React from "react";

import { Icon, Input } from "antd";
import { InputProps } from "antd/es/input";
import classNames from "classnames";
import styled from "styled-components";

import withChangeOnBlur from "../../hoc/WithChangeOnBlur";

const StyledInput = styled(Input)`
  color: ${props => props.theme.inputColor};
  background-color: ${props => props.theme.inputBackgroundColor};

  &.hidden {
    display: none;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: ${props => props.theme.spacerxs};
  align-self: center;
`;

const Placeholder = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: ${props => props.theme.smallFontSize};
`;

const InlineLabel = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }
`;

interface InlineInputProps extends InputProps {
  isValidValue?: (p: string) => boolean;
  includeEditIcon?: boolean;
  className?: string;
}

export const InlineInput = (props: InlineInputProps) => {
  const { onChange, value, onBlur, isValidValue, className, includeEditIcon } = props;
  const [isEditing, setIsEditing] = React.useState(!value);
  const inputRef = React.useRef<Input>(null);

  if (!onChange) {
    throw new Error("Requires onChange");
  }

  React.useEffect(() => {
    if (isValidValue && !isValidValue(value as string) && !isEditing) {
      setIsEditing(true);
    }
  }, [isEditing, isValidValue, value]);

  React.useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
      if (inputRef.current?.input.value) {
        inputRef.current.input.setSelectionRange(
          0,
          inputRef.current.input.value.length
        );
      }
    }
  }, [isEditing]);

  React.useEffect(() => {
    if (!value) {
      inputRef?.current?.focus();
    }
  });

  const onKeyUp = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (!isEditing) return;
      if (e.keyCode === 13 || e.keyCode === 27) {
        inputRef.current?.blur();
        e.stopPropagation();
      }
    },
    [isEditing]
  );

  const classes: Record<string, boolean> = {
    hidden: !isEditing
  };
  if (typeof className === "string") {
    classes[className] = true;
  }

  return (
    <div className={className || ""} onKeyUp={onKeyUp}>
      {!isEditing && (
        <InlineLabel data-test="inlineLabel" onClick={() => setIsEditing(true)}>
          {value || <Placeholder>{props.placeholder}</Placeholder>}
          {includeEditIcon && <StyledIcon type="edit" />}
        </InlineLabel>
      )}
      <StyledInput
        data-test="inlineInput"
        className={classNames(classes)}
        ref={inputRef}
        size="small"
        placeholder={props.placeholder}
        onBlur={e => {
          setIsEditing(false);
          onBlur && onBlur(e);
        }}
        onChange={e => onChange(e)}
        value={value}
      />
    </div>
  );
};

export default withChangeOnBlur<{
  isValidValue?: (value: string) => boolean;
  includeEditIcon?: boolean;
}>(InlineInput);
