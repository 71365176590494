import React, { useEffect, useState } from "react";

import { Select as AntSelect } from "antd";
import styled from "styled-components";

import { AttributeTypes } from "../../../constants";
import {
  AttributeFilterProperties,
  FiltersOption,
  ViewFilterOperatorHumanizedDisplayNames
} from "../../../types";
import SingleLineEditor from "../SingleLineEditor";
import { Mode } from "../SingleLineEditor/SingleLineEditor";

const { Option } = AntSelect;

const AttributeFilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const AttributeSelect = styled(AntSelect)`
  min-width: 180px;
  width: 30%;
`;

const OperatorSelect = styled(AntSelect)`
  min-width: 180px;
  width: 30%;
  margin-left: ${props => props.theme.spacersm};
`;

const StyledSingleLineEditor = styled(SingleLineEditor)`
  margin-left: ${props => props.theme.spacersm};
`;

interface Props {
  value: AttributeFilterProperties;
  filtersOptions: FiltersOption[];
  allFiltersOptions: FiltersOption[]; // needed to determine attribute type
  showOperator: boolean;
  useAttributeDisplayNames: boolean;
  onChange: (value: AttributeFilterProperties) => void;
}

const AttributeFilter = (props: Props) => {
  const { value, showOperator } = props;
  const [currentValue, setCurrentValue] = useState<AttributeFilterProperties>(
    props.value
  );
  const [option, setOption] = useState<FiltersOption | undefined>(
    props.filtersOptions.find(attr => attr.sourceName === currentValue.column)
  );

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setOption(
      props.allFiltersOptions.find(attr => attr.sourceName === currentValue.column)
    );
  }, [currentValue.column, props.allFiltersOptions]);

  const onChange = (key: "column" | "operator" | "value", value: any) => {
    props.onChange({ ...currentValue, [key]: value });
  };

  const operators: { key: string; name: string }[] = option
    ? option.operators.map(operator => {
        return {
          key: operator,
          name: ViewFilterOperatorHumanizedDisplayNames[operator] || operator
        };
      })
    : [];

  const editorMode: Mode =
    !option?.sourceType || option.sourceType === AttributeTypes.STRING
      ? "jstl"
      : "javascript";

  // default value for string inputs when using JS expressions should be an empty template string,
  // otherwise an empty value can be used for non-string JS expressions or non-expression support.
  const defaultValue = editorMode === "jstl" ? "``" : "";

  return (
    <AttributeFilterContainer>
      <AttributeSelect
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        data-test="attributeColumnSelect"
        dropdownClassName="attributeColumnSelect"
        placeholder="Select an attribute"
        value={value ? value.column : undefined}
        onChange={value => {
          return onChange("column", value);
        }}
      >
        {props.filtersOptions.map(option => (
          <Option
            key={option.sourceName}
            value={option.sourceName}
            title={option.sourceName}
          >
            {props.useAttributeDisplayNames ? option.name : option.sourceName}
          </Option>
        ))}
      </AttributeSelect>

      {showOperator && (
        <OperatorSelect
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          data-test="attributeOperatorSelect"
          dropdownClassName="attributeOperatorSelect"
          placeholder="Select an operator"
          value={value ? value.operator : undefined}
          onChange={value => {
            return onChange("operator", value);
          }}
        >
          {operators.map(operator => (
            <Option key={operator.key} value={operator.key} title={operator.name}>
              {operator.name}
            </Option>
          ))}
        </OperatorSelect>
      )}
      <StyledSingleLineEditor
        data-test="attributeValueInput"
        placeholder="Enter a value or parameter"
        onChange={value => onChange("value", value || defaultValue)}
        mode={editorMode}
        value={value ? value.value || defaultValue : defaultValue}
        togglable
      />
    </AttributeFilterContainer>
  );
};

export default AttributeFilter;
