import { WrappedFormUtils } from "antd/lib/form/Form";
import { toLower, camelCase } from "lodash";

import { KeyValue } from "../../../common/KeyValueInputs";
import {
  ACCESS_KEY_FIELD,
  REGION_FIELD,
  SECRET_KEY_FIELD
} from "../../../setup_flow/common/AmazonFieldSet";
import { Id as API_KEY_FIELD_ID } from "../../../setup_flow/common/ApiKeyField";
import {
  BASIC_AUTH_PASSWORD,
  BASIC_AUTH_USER
} from "../../../setup_flow/common/BasicAuthFields";
import {
  DATABASE_FIELD_ID,
  HOST_FIELD_ID,
  PASSWORD_FIELD_ID,
  PORT_FIELD_ID,
  SSH_ENABLED_FIELD_ID,
  SSL_ENABLED_FIELD_ID,
  USERNAME_FIELD_ID
} from "../../../setup_flow/common/DatabaseFieldSet";
import {
  DATASET_ID,
  PROJECT_ID
} from "../../../setup_flow/common/GoogleServiceAccountFieldSet";
import {
  BASE_URL,
  isValidHeader,
  HAS_BASIC_AUTH,
  HEADERS
} from "../../../setup_flow/common/HttpFieldSet";
import {
  CONNECTION_STRING_FIELD,
  MONGO_DATABASE_FIELD
} from "../../../setup_flow/common/MongoFields";
import {
  CLIENT_ID_FIELD_ID,
  CLIENT_SECRET_FIELD_ID,
  SECURITY_TOKEN_FIELD_ID,
  PASSWORD_FIELD_ID as SALESFORCE_PASSWORD_ID,
  USERNAME_FIELD_ID as SALESFORCE_USERNAME_FIELD_ID
} from "../../../setup_flow/common/SalesforceFieldSet";
import {
  ACCOUNT_FIELD as SNOWFLAKE_ACCOUNT_FIELD,
  USER_FIELD as SNOWFLAKE_USER_FIELD,
  PASSWORD_FIELD as SNOWFLAKE_PASSWORD_FIELD,
  DATABASE_FIELD as SNOWFLAKE_DATABASE_FIELD,
  WAREHOUSE_FIELD as SNOWFLAKE_WAREHOUSE_FIELD,
  ROLE_FIELD as SNOWFLAKE_ROLE_FIELD,
  AUTHENTICATOR_FIELD as SNOWFLAKE_AUTHENTICATOR_FIELD,
  PRIVATE_KEY_FIELD as SNOWFLAKE_PRIVATE_KEY_FIELD,
  PRIVATE_KEY_PASSWORD_FIELD as SNOWFLAKE_PRIVATE_KEY_PASSWORD_FIELD
} from "../../../setup_flow/common/SnowflakeFields";

export const sqlDatabaseSettingsChangeHandler = (form: WrappedFormUtils) => {
  const fields = [
    USERNAME_FIELD_ID,
    PASSWORD_FIELD_ID,
    HOST_FIELD_ID,
    PORT_FIELD_ID,
    DATABASE_FIELD_ID,
    SSL_ENABLED_FIELD_ID
  ];
  const sshEnabled = form.getFieldValue(SSH_ENABLED_FIELD_ID);
  if (sshEnabled) {
    fields.push("SshUser", "SshHost", "SshPort");
  }
  const touchedFields = fields.filter(fieldName => form.isFieldTouched(fieldName));
  const changes = form.getFieldsValue(touchedFields);
  if (form.isFieldTouched(SSH_ENABLED_FIELD_ID) && !sshEnabled) {
    changes["SshUser"] = "";
    changes["SshHost"] = "";
  }
  return Object.fromEntries(Object.entries(changes).map(([k, v]) => [camelCase(k), v]));
};

export const amazonSettingsChangeHandler = (form: WrappedFormUtils) => {
  const touchedFields = [ACCESS_KEY_FIELD, SECRET_KEY_FIELD, REGION_FIELD].filter(
    fieldName => form.isFieldTouched(fieldName)
  );
  return form.getFieldsValue(touchedFields);
};

// AuthorizationFlowID is required regardless of it has been updated
export const httpSettingsChangeHandler = (form: WrappedFormUtils) => {
  let fields = [BASE_URL, HEADERS];
  const hasBasicAuth = form.getFieldValue(HAS_BASIC_AUTH);
  if (hasBasicAuth) {
    fields = fields.concat([BASIC_AUTH_USER, BASIC_AUTH_PASSWORD]);
  }
  let changes = form.getFieldsValue(fields.filter(f => form.isFieldTouched(f)));
  if (!hasBasicAuth) {
    changes = {
      ...changes,
      BASIC_AUTH_USER: "",
      BASIC_AUTH_PASSWORD: ""
    };
  }

  return Object.fromEntries(
    Object.entries(changes).map(([k, v]) =>
      k === HEADERS
        ? [camelCase(k), v.filter((header: KeyValue) => isValidHeader(header))]
        : [camelCase(k), v]
    )
  );
};

export const apiKeySettingsChangeHandler = (form: WrappedFormUtils) => {
  const touchedFields = [API_KEY_FIELD_ID].filter(fieldName =>
    form.isFieldTouched(fieldName)
  );
  const changes = form.getFieldsValue(touchedFields);
  return Object.fromEntries(Object.entries(changes).map(([k, v]) => [toLower(k), v]));
};

export const salesForceSettingsChangeHandler = (form: WrappedFormUtils) => {
  const touchedFields = [
    SALESFORCE_USERNAME_FIELD_ID,
    SALESFORCE_PASSWORD_ID,
    CLIENT_ID_FIELD_ID,
    CLIENT_SECRET_FIELD_ID,
    SECURITY_TOKEN_FIELD_ID
  ].filter(fieldName => form.isFieldTouched(fieldName));
  const changes = form.getFieldsValue(touchedFields);
  return Object.fromEntries(Object.entries(changes).map(([k, v]) => [toLower(k), v]));
};

export const snowflakeSettingsChangeHandler = (form: WrappedFormUtils) => {
  const touchedFields = [
    SNOWFLAKE_ACCOUNT_FIELD,
    SNOWFLAKE_USER_FIELD,
    SNOWFLAKE_PASSWORD_FIELD,
    SNOWFLAKE_DATABASE_FIELD,
    SNOWFLAKE_WAREHOUSE_FIELD,
    SNOWFLAKE_ROLE_FIELD,
    SNOWFLAKE_AUTHENTICATOR_FIELD,
    SNOWFLAKE_PRIVATE_KEY_PASSWORD_FIELD
  ].filter(fieldName => form.isFieldTouched(fieldName));
  if (form.getFieldValue(SNOWFLAKE_PRIVATE_KEY_FIELD)) {
    touchedFields.push(SNOWFLAKE_PRIVATE_KEY_FIELD);
  }
  const changes = form.getFieldsValue(touchedFields);
  return changes;
};

export const zendeskSettingsChangeHandler = (form: WrappedFormUtils) => {
  const touchedFields = ["user", "apiToken", "domain"].filter(fieldName =>
    form.isFieldTouched(fieldName)
  );
  return form.getFieldsValue(touchedFields);
};

export const mongoSettingsChangeHandler = (form: WrappedFormUtils) => {
  const fields = [CONNECTION_STRING_FIELD, MONGO_DATABASE_FIELD];
  const sshEnabled = form.getFieldValue("SSH Enabled");
  if (sshEnabled) {
    fields.push("SshUser", "SshHost", "SshPort");
  }

  const touchedFields = fields.filter(fieldName => form.isFieldTouched(fieldName));
  const changes = form.getFieldsValue(touchedFields);

  if (form.isFieldTouched("SSH Enabled") && !sshEnabled) {
    changes["SshUser"] = "";
    changes["SshHost"] = "";
  }

  return Object.fromEntries(Object.entries(changes).map(([k, v]) => [camelCase(k), v]));
};

export const bigQuerySettingsChangeHandler = (form: WrappedFormUtils) => {
  const touchedFields = [DATASET_ID, PROJECT_ID].filter(fieldName =>
    form.isFieldTouched(fieldName)
  );
  return form.getFieldsValue([...touchedFields, "key"]);
};

export const firestoreSettingsChangeHandler = (form: WrappedFormUtils) => {
  return form.getFieldsValue(["key"]);
};

export default function getChangedValues(integration: string, form: WrappedFormUtils) {
  switch (integration) {
    case "mongo":
      return mongoSettingsChangeHandler(form);
    case "mssql":
    case "mysql":
    case "postgresql":
      return sqlDatabaseSettingsChangeHandler(form);
    case "dynamodb":
    case "s3":
      return amazonSettingsChangeHandler(form);
    case "http":
      return httpSettingsChangeHandler(form);
    case "hubspot":
    case "mailgun":
    case "stripe":
      return apiKeySettingsChangeHandler(form);
    case "salesforce":
      return salesForceSettingsChangeHandler(form);
    case "snowflake":
      return snowflakeSettingsChangeHandler(form);
    case "zendesk":
      return zendeskSettingsChangeHandler(form);
    case "bigquery":
      return bigQuerySettingsChangeHandler(form);
    case "firestore":
      return firestoreSettingsChangeHandler(form);
    default:
      throw new Error("This data source does not support modifying credentials.");
  }
}
