import React from "react";

import { ValidationRule } from "antd/lib/form";

import { AttributeValueInputProps } from "..";
import { AttributeTypes, AttributeTypesDisplayNames } from "../../../../constants";
import { isValidType, NUMERIC_FORMATTERS } from "../../utils";
import StringInput from "../StringInput";

export default function NumberInput({
  fieldOptions: parentFieldOptions,
  value,
  verbose,
  useSimpleJsonInput,
  onValidate,
  ...props
}: AttributeValueInputProps) {
  const parentRules: ValidationRule[] = parentFieldOptions?.rules || [];

  const sourceType = props.sourceType;
  const rules = parentRules.concat({
    message: `Value must be ${sourceType === AttributeTypes.INT ? "an" : "a"} ${
      AttributeTypesDisplayNames[sourceType]
    }.`,
    validator: (_rule, value, cb) => {
      if (isValidType(value, sourceType)) {
        cb();
      } else {
        cb({ error: true });
      }
    }
  });

  // Replace required rule with one that handles numbers correctly
  const requireRuleIndex = rules.findIndex(rule => rule.required);
  if (requireRuleIndex !== -1) {
    rules[requireRuleIndex] = {
      ...rules[requireRuleIndex],
      required: undefined,
      validator: (_rule, value, cb) => {
        if (!!value || typeof value === "number") {
          cb();
        } else {
          cb({ error: true });
        }
      }
    };
  }

  const fieldOptions = {
    ...parentFieldOptions,
    rules,
    validateTrigger: "onChange"
  };

  const formattedValue =
    !!sourceType && (!!value || value === 0)
      ? NUMERIC_FORMATTERS[sourceType]!(value)
      : value;

  return <StringInput {...props} value={formattedValue} fieldOptions={fieldOptions} />;
}
