import React from "react";

import { Button, Icon, Input } from "antd";
import * as clipboard from "clipboard-polyfill/text";
import styled from "styled-components";

import Message from "../../../../common/Message";
import { ErrorField } from "../../../../common/StyledComponents";
import { ProviderProps } from "../SingleSignOnDrawer";

const BACKEND_SLUG = "azuread-v2-tenant-oauth2";

const Root = styled.div`
  & > div {
    margin-top: ${props => props.theme.spacermd};
  }
`;

const AzureADTenantProvider = ({
  connectionNode,
  connection,
  setConnection,
  redirect,
  errors
}: ProviderProps) => {
  return (
    <Root>
      <div>
        <h3>Azure Redirect URI</h3>
        <Input
          disabled
          value={connectionNode.oauthRedirectUri}
          addonAfter={
            <Icon
              type="copy"
              onClick={() => {
                if (!connectionNode.oauthRedirectUri) {
                  throw new Error("Unable to copy empty redirect URI.");
                }
                clipboard.writeText(connectionNode.oauthRedirectUri).then(
                  () => {
                    Message.info("Copied External Key to clipboard");
                  },
                  () => {
                    Message.error("Unable to copy to clipboard.");
                  }
                );
              }}
            />
          }
        />
      </div>
      <div>
        <h3>Azure Directory Tenant ID</h3>
        <Input
          value={connection.azureadTenantId || ""}
          onChange={e =>
            setConnection({ ...connection, azureadTenantId: e.target.value })
          }
        />
        {errors.azureadTenantId && <ErrorField>{errors.azureadTenantId}</ErrorField>}
      </div>
      <div>
        <h3>Client ID</h3>
        <Input
          value={connection.azureadClientId || ""}
          onChange={e =>
            setConnection({ ...connection, azureadClientId: e.target.value })
          }
        />
        {errors.azureadClientId && <ErrorField>{errors.azureadClientId}</ErrorField>}
      </div>
      <div>
        <h3>Client Secret</h3>
        <Input
          value={connection.azureadClientSecret || ""}
          onChange={e =>
            setConnection({
              ...connection,
              azureadClientSecret: e.target.value
            })
          }
        />
        {errors.azureadClientSecret && (
          <ErrorField>{errors.azureadClientSecret}</ErrorField>
        )}
      </div>
      <div>
        <a href={`/api/sso/login/${BACKEND_SLUG}?next=${encodeURIComponent(redirect)}`}>
          <Button disabled={connection.state !== "active"}>
            Test Azure Configuration
          </Button>
        </a>
      </div>
    </Root>
  );
};

export default AzureADTenantProvider;
