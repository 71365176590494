import React from "react";

import { ReturnSchema } from "../../../../constants";
import { FunctionAttribute } from "../../../../types";
import { DisabledMessage } from "../common/DisabledMessage";
import { FunctionAttributes } from "../FunctionAttributes/FunctionAttributes";

import { ReturnSchemaSelect } from "./ReturnSchemaSelect";
import * as styled from "./styledComponents";

export interface Props {
  editable: boolean;
  refreshable: boolean;
  attributes: FunctionAttribute[];
  returnSchema: ReturnSchema | undefined;
  onAttributesChange: (attributes: FunctionAttribute[]) => void;
  onRefreshAttributes: () => void;
  onReturnSchemaChange: (returnSchema: ReturnSchema) => void;
}

export const FunctionOutput = (props: Props) => {
  if (!props.editable && props.refreshable) {
    console.warn("FunctionOutput: refreshable is ignored when editable is false");
  }

  return (
    <styled.SectionList>
      {!props.editable && (
        <DisabledMessage message="Outputs are not editable because they are automatically generated from your query." />
      )}
      {props.editable &&
        (props.refreshable ? (
          <styled.Section>
            <styled.SectionContent>
              <span>
                Configure the outputs for your function. Click "Update" to infer outputs
                from the last execution of your function.
              </span>
              <styled.RefreshAttributesButton
                type="primary"
                onClick={props.onRefreshAttributes}
              >
                Update
              </styled.RefreshAttributesButton>
            </styled.SectionContent>
          </styled.Section>
        ) : (
          <styled.Section>
            <styled.SectionContent>
              <span>Configure the outputs of your function.</span>
            </styled.SectionContent>
          </styled.Section>
        ))}
      <styled.Section>
        <styled.SectionHeader>Format</styled.SectionHeader>
        <styled.SectionContent>
          <ReturnSchemaSelect
            data-test="formatDropdown"
            disabled={!props.editable}
            value={props.returnSchema}
            onChange={props.onReturnSchemaChange}
          />
        </styled.SectionContent>
      </styled.Section>
      <styled.Section>
        <styled.SectionHeader>Attributes</styled.SectionHeader>
        <FunctionAttributes
          editable={props.editable}
          attributes={props.attributes}
          onAttributesChange={props.onAttributesChange}
        />
      </styled.Section>
    </styled.SectionList>
  );
};
