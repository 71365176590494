import React from "react";

import { ReturnSchema } from "../../../../../../constants";
import { SpaceFunctionType } from "../../../../../../types";
import { toGlobalId } from "../../../../../util/graphql";
import { useFunctionExistsById } from "../../../../queries/useFunctionExistsById";
import { useGetFunctionById } from "../../../../queries/useGetFunctionById";
import SpaceComponentContext from "../../spaces/SpaceComponentContext";
import { createComponent, SpaceForm } from "../../spaces/SpaceForm";
import useSpaceContextParams from "../../spaces/useSpaceContextParams";
import { TaskNode, TransitionNode } from "../../useQueueClient/queries/AllTasks";

interface Props {
  task: TaskNode;
  transition: TransitionNode;
  visible?: boolean;
  onCancel?: () => void;
  onCompleted?: (result: any, metadata: unknown) => void;
}

export default function ModalTransitionForm({
  task,
  transition,
  onCompleted = () => null,
  onCancel = () => null,
  visible = false
}: Props) {
  const contextParams = useSpaceContextParams(task);

  const functionNodeId = toGlobalId("FunctionNode", transition.functionId);

  const { data: existData } = useFunctionExistsById({
    variables: { id: functionNodeId },
    skip: !transition.functionId
  });

  const { data } = useGetFunctionById({
    variables: { id: functionNodeId },
    skip: !transition.functionId
  });

  const func = data?.node
    ? data.node
    : existData?.functionExists
    ? {
        id: transition.functionId,
        name: "transition",
        type: SpaceFunctionType.NOT_VISIBLE,
        returnSchema: ReturnSchema.UNKNOWN
      }
    : {
        id: "placeholder",
        name: "placeholder",
        returnSchema: ReturnSchema.UNKNOWN
      };

  const component = createComponent(transition.name, func, transition.inputParameters);

  if (!visible) return null;

  return (
    <SpaceComponentContext component={component} contextParams={contextParams}>
      <SpaceForm
        inputParameters={component.properties.input_parameters}
        onCancel={onCancel}
        onCompleted={onCompleted}
      />
    </SpaceComponentContext>
  );
}
