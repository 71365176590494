import React from "react";

import styled from "styled-components";

interface ExpandWrapperProps {
  noAnimation?: boolean;
}

export const ExpandWrapper = styled.div<ExpandWrapperProps>`
  overflow-y: hidden;
  ${props => (props.noAnimation ? "" : "transition: max-height 0.3s;")}
`;

interface Props {
  expanded: boolean;
  skipFirstAnimation?: boolean;
  children?: React.ReactNode;
  className?: string;
}

/**
 * Expandable wraps a single child and only shows the content when "expanded" is true.
 * The collapse/expansion is animated - controlled via css.
 *
 * The child is allowed to grow in height.
 * On collapse, the child's height is recalculated to do a smooth animation back to 0.
 *
 * skipFirstAnimation will not have a transition for the first expansion.
 */
export default function Expandable({
  skipFirstAnimation,
  expanded,
  children,
  className
}: Props) {
  const [timesExpanded, setTimesExpanded] = React.useState(0);
  const [currentlyExpanded, setCurrentlyExpanded] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState<number | undefined>(0);
  const timeoutHandleRef = React.useRef<any>(-1);
  const wrapper = React.useRef<HTMLDivElement>(null);
  const getChildHeight = React.useCallback(() => {
    if (wrapper.current?.children.length !== 1) {
      throw new Error("Expandable must have a single child");
    }

    return wrapper.current?.children[0].getBoundingClientRect().height;
  }, [wrapper]);

  React.useEffect(() => {
    if (expanded !== currentlyExpanded) {
      setCurrentlyExpanded(expanded);

      if (expanded) {
        setTimesExpanded(timesExpanded + 1);
      }

      clearTimeout(timeoutHandleRef.current);

      if (expanded) {
        setMaxHeight(getChildHeight());

        timeoutHandleRef.current = setTimeout(() => {
          // Clear max height so the max height style is gone
          // This allows child element to keep growing and this wrapper to not
          // limit the height
          setMaxHeight(undefined);
        }, 350); // 350ms is a little after transition delay
      } else {
        const currentHeight = getChildHeight();

        // First set it to the current height so we can animate
        setMaxHeight(currentHeight);
        requestAnimationFrame(() => {
          // Then set it to 0 so we collapse
          setMaxHeight(0);
        });
      }
    }
  }, [
    expanded,
    currentlyExpanded,
    timesExpanded,
    setCurrentlyExpanded,
    setMaxHeight,
    getChildHeight
  ]);

  return (
    <ExpandWrapper
      ref={wrapper}
      noAnimation={skipFirstAnimation && timesExpanded === 1 && expanded}
      className={className}
      style={{ maxHeight: maxHeight }}
    >
      {children}
    </ExpandWrapper>
  );
}
