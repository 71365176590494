import React from "react";

import ReactDOM from "react-dom";

export default function Portal({
  id,
  children
}: {
  id: string;
  children: React.ReactNode;
}) {
  const [portalNode, setPortalNode] = React.useState(document.getElementById(id));
  const observerRef = React.useRef<MutationObserver | null>(null);
  React.useEffect(() => {
    const node = document.getElementById(id);
    if (node) {
      setPortalNode(node);
    } else {
      observerRef.current = new MutationObserver(() => {
        const node = document.getElementById(id);
        if (node) {
          observerRef.current?.disconnect();
          observerRef.current = null;
          setPortalNode(node);
        }
      });
      observerRef.current.observe(document, { subtree: true, childList: true });
    }
    return () => {
      observerRef.current?.disconnect();
      observerRef.current = null;
    };
  }, [id]);
  if (!portalNode) return null;
  return ReactDOM.createPortal(<>{children}</>, portalNode);
}
