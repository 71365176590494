import React from "react";

import { Editor, EditorState, ContentState, getDefaultKeyBinding } from "draft-js";
import styled from "styled-components";

import TextWithLineBreaks from "../TextWithNewLines";
import withDebouncedValue from "../withDebouncedValue";
import "draft-js/dist/Draft.css";

interface EditableTextProps {
  value: string;
  onChange: (value: string) => void;
  onEditStart: () => void;
  onEditEnd: () => void;
}

export default function EditableText({
  value,
  onChange,
  onEditStart,
  onEditEnd
}: EditableTextProps) {
  const [editing, setEditing] = React.useState(false);
  const handleBlur = React.useCallback(
    _evt => {
      setEditing(false);
      onEditEnd();
    },
    [onEditEnd]
  );

  return (
    <Root
      onDoubleClick={() => {
        setEditing(true);
        onEditStart();
      }}
    >
      {editing ? (
        <DebouncedDraftEditor
          value={value}
          onChange={val => {
            onChange(val);
          }}
          onBlur={handleBlur}
        />
      ) : (
        <BlockSelect>
          <TextWithLineBreaks value={value} />
        </BlockSelect>
      )}
    </Root>
  );
}

const Root = styled.div``;

const BlockSelect = styled.div`
  user-select: none;
  /* HACK: workaround slight shift when toggling into edit */
  border-left: 0.1px solid transparent;
`;

function DraftEditor({
  value,
  onChange,
  onBlur,
  onFocus
}: {
  value: any;
  onChange: (value: any) => void;
  onBlur: (evt: any) => void;
  onFocus?: (evt: any) => void;
}) {
  const editor = React.useRef<Editor>(null);

  const focusEditor = React.useCallback(() => {
    if (editor.current === null) return;
    editor.current.focus();
  }, []);

  React.useEffect(() => {
    focusEditor();
  }, [focusEditor]);

  function keyBindingFn(e: React.KeyboardEvent<{}>): string | null {
    if (e.keyCode === 27 /* `esc` key */) {
      return "EditableText-blur";
    }
    return getDefaultKeyBinding(e);
  }

  return (
    <DraftEditorRoot>
      <Editor
        ref={editor}
        editorState={value}
        keyBindingFn={keyBindingFn}
        handleKeyCommand={(command: string) => {
          if (command === "EditableText-blur") {
            editor.current?.blur();
            return "handled";
          }
          return "not-handled";
        }}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </DraftEditorRoot>
  );
}

const DraftEditorRoot = styled.div`
  width: 100%;
  height: 100%;
  min-height: 1em;
  background: ${props => props.theme.rowHoverColor};
  cursor: text;
  overflow: visible;
`;

const DebouncedDraftEditor = withDebouncedValue(DraftEditor, {
  serializeExternalValue: value => {
    return EditorState.createWithContent(ContentState.createFromText(value));
  },
  serializeLocalValue: (value: EditorState) => {
    return value.getCurrentContent().getPlainText();
  },
  isEqual: (localValue: EditorState, externalValue: string) => {
    return localValue.getCurrentContent().getPlainText() === externalValue;
  }
});
