import gql from "graphql-tag";

import { SpaceNode } from "../../../../types";

export interface AuthFlowsForSpaceResult {
  space: SpaceNode;
}

export interface AuthFlowsForSpaceVars {
  slug: string;
}

export const AUTH_FLOW_STEP_FRAGMENT = gql`
  fragment AuthFlowStepFragment on AuthorizationFlowStepNode {
    id
    type
    options
    order
    slug
  }
`;

export const AUTH_FLOWS_FOR_SPACE_QUERY = gql`
  query AuthFlowsForSpace($slug: String!) {
    space(slug: $slug) {
      __typename
      ... on SpaceNode {
        id
        slug
        authorizationFlows {
          id
          name
          authorized
          authSteps {
            edges {
              node {
                ...AuthFlowStepFragment
                __typename
              }
            }
          }
        }
      }
    }
  }
  ${AUTH_FLOW_STEP_FRAGMENT}
`;

export const LOGOUT_MUTATION = gql`
  mutation AuthFlowLogout($id: ID!, $environmentId: ID!) {
    authorizationFlowLogout(id: $id, environmentId: $environmentId) {
      ok
    }
  }
`;

interface BaseAuthFlowProcessStep {
  __typename: string;
  step: any;
  state: any;
}

export interface AuthFlowProcessStepSuccessResult extends BaseAuthFlowProcessStep {
  __typename: "AuthFlowProcessStepSuccessResult";
}

export interface AuthFlowProcessStepErrorResult extends BaseAuthFlowProcessStep {
  __typename: "AuthFlowProcessStepErrorResult";
  message: string;
}

export interface AuthFlowProcessStepRedirectResult extends BaseAuthFlowProcessStep {
  __typename: "AuthFlowProcessStepRedirectResult";
  location: string;
}

export interface AuthFlowProcessStepActionRequiredResult
  extends BaseAuthFlowProcessStep {
  __typename: "AuthFlowProcessStepActionRequiredResult";
}

export type AuthFlowProcessStepResult =
  | AuthFlowProcessStepSuccessResult
  | AuthFlowProcessStepErrorResult
  | AuthFlowProcessStepRedirectResult
  | AuthFlowProcessStepActionRequiredResult;

export interface AuthFlowProcessStepMutationVars {
  authFlowId: string;
  state: any;
  step: number;
  returnUri: string;
  environmentId?: string;
}

export interface AuthFlowProcessStepMutationResult {
  authFlowProcessStep: AuthFlowProcessStepResult;
}

export const AUTH_FLOW_PROCESS_STEP = gql`
  mutation AuthFlowProcessStep(
    $authFlowId: ID!
    $state: GenericScalar!
    $step: Int!
    $returnUri: String!
    $environmentId: ID
  ) {
    authFlowProcessStep(
      authFlowId: $authFlowId
      state: $state
      step: $step
      returnUri: $returnUri
      environmentId: $environmentId
    ) {
      __typename
      ... on AuthFlowProcessStepSuccessResult {
        step {
          ...AuthFlowStepFragment
        }
        state
      }
      ... on AuthFlowProcessStepErrorResult {
        step {
          ...AuthFlowStepFragment
        }
        state
        message
      }
      ... on AuthFlowProcessStepRedirectResult {
        step {
          ...AuthFlowStepFragment
        }
        state
        location
      }
      ... on AuthFlowProcessStepActionRequiredResult {
        step {
          ...AuthFlowStepFragment
        }
        state
      }
    }
  }
  ${AUTH_FLOW_STEP_FRAGMENT}
`;
