import { ValidationRule } from "antd/lib/form";

import { Schema } from "./schema";

interface Credentials extends Record<string, any> {
  meta?: CredentialsMeta;
}

interface CredentialsMeta {
  indexes: Record<string, number[]>;
  keys: Record<string, string[]>;
  values: Record<string, any[]>;
}

// Ant forms don't really support the scenario where one field provides the key
// for a value defined in another field. To work around this we set a `meta`
// property on credentials which contains `keys` (array of values for each key)
// field, `values` (array of values for each value field), and `indexes` (array
// of indexes which defines the ordering of the key/value rows).
//
// This is currently used for schemas with `additionalProperties` set, for
// example, HTTP headers.
//
// See: https://3x.ant.design/components/form/#components-form-demo-dynamic-form-item
export const applyAdditional = (credentials: Credentials) => {
  const meta = credentials.meta;
  if (!meta?.indexes) {
    return credentials;
  }

  const names = Object.keys(meta.indexes);
  const objs = names.reduce<Credentials>((obj, name) => {
    const indexes = meta.indexes[name];
    const keys = meta.keys[name];
    const values = meta.values[name];

    obj[name] = indexes.reduce<Record<string, any>>((acc, i) => {
      acc[keys[i]] = values[i];
      return acc;
    }, {});
    return obj;
  }, {});
  delete credentials.meta;
  return { ...objs, ...credentials };
};

export type ValidationRules = ValidationRule[];

// See: https://json-schema.org/draft/2020-12/json-schema-validation.html
export const fieldRules = (title: string, schema: Schema, required: boolean) => {
  switch (schema.type) {
    case "integer":
    case "number":
      return numberRules(title, schema, required);
    case "string":
      return stringRules(title, schema, required);
    default:
      return [];
  }
};

const numberRules = (title: string, schema: Schema, required: boolean) => {
  const rules: ValidationRules = [
    { type: "number", message: `${title} must be a number` }
  ];
  if (required) {
    rules.push({
      type: "number",
      required: true,
      message: `${title} is required`
    });
  }
  if (schema.minimum || schema.minimum === 0) {
    rules.push({
      type: "number",
      min: schema.minimum,
      message: `${title} cannot be less than ${schema.minimum}`
    });
  }
  if (schema.maximum || schema.maximum === 0) {
    rules.push({
      type: "number",
      max: schema.maximum,
      message: `${title} cannot be greater than ${schema.maximum}`
    });
  }
  return rules;
};

const stringRules = (title: string, schema: Schema, required: boolean) => {
  const rules: ValidationRules = [];
  if (required) {
    rules.push({
      type: "string",
      required: true,
      message: `${title} is required`
    });
    rules.push({
      type: "string",
      whitespace: true,
      message: `${title} cannot be empty`
    });
  }
  if (schema.minLength && schema.minLength > 1) {
    rules.push({
      type: "string",
      min: schema.minLength,
      message: `${title} must be at least ${schema.minLength} characters`
    });
  }
  if (schema.maxLength) {
    rules.push({
      type: "string",
      max: schema.maxLength,
      message: `${title} cannot be longer than ${schema.maxLength} characters`
    });
  }
  return rules;
};
