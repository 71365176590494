import "regenerator-runtime/runtime";
import "./polyfills";
import "geometry-polyfill";

import React from "react";

import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import { uniq } from "lodash";
import { ApolloProvider, Query } from "react-apollo";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import App from "./App";
import { ConfigContextProvider } from "./ConfigContext";
import { client } from "./graphql";
import * as serviceWorker from "./serviceWorker";
import { SpaceComponentPackage } from "./types";
import "./index.less";

if (!serviceWorker.isLocalhost && process.env.INTERNALIO_ONPREM !== "1") {
  Sentry.init({
    dsn: "https://dd6b07c313eb473a9514b88932f1b711@sentry.io/1517743",
    environment: window.location.hostname,
    release: process.env.SENTRY_RELEASE,
    ignoreErrors: [
      "Network request failed",
      "Failed to fetch",
      "NetworkError",
      "Network error",
      "ResizeObserver loop"
    ]
  });
  TagManager.initialize({ gtmId: "GTM-NV4XHTF" });
}

/**
 * partialRefetch should always be true, but it defaults to false for reasons
 * that are totally unimaginable to me. this hacks it on for us.
 *
 * note: today, Query has no defaultProps but this Object.assign ensures it works either way
 * and will explicitly override any current or future value for `partialRefetch`.
 */
interface QueryWithDefaultProps {
  defaultProps: any;
}

const Q = Query as unknown as QueryWithDefaultProps;

Q.defaultProps = Object.assign({}, Q.defaultProps, {
  partialRefetch: true
});

if (process.env.NODE_ENV !== "production" && !Q.defaultProps.partialRefetch) {
  throw new Error("Unable to default partialRefetch for Apollo Query component.");
}

declare global {
  interface Window {
    INTERNAL_CLIENT_SECRET: string | any;
    FEATURE_FLAGS: string[];
    jsonlint: any;
    FEATURE_FLAGS_OVERRIDES: { [organizationId: string]: string[] };
    __INTERNAL_SPACE_COMPONENT_PACKAGES: Set<SpaceComponentPackage>;
    __ERRORS: Error[];
  }
}
window.FEATURE_FLAGS = (localStorage?.getItem("FEATURE_FLAGS") || "").split(" ");
window.__INTERNAL_SPACE_COMPONENT_PACKAGES =
  window.__INTERNAL_SPACE_COMPONENT_PACKAGES || new Set();
window.__ERRORS = [];

fetch(window.location.origin + "/api/config/", { credentials: "same-origin" })
  .then((response: any) => response.json())
  .then((data: any) => {
    // Set any api defined feature flags
    if (data.featureFlags && Array.isArray(data.featureFlags)) {
      window.FEATURE_FLAGS = uniq(window.FEATURE_FLAGS.concat(data.featureFlags));
    }
    ReactDOM.render(
      <ConfigContextProvider value={data}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <App />
          </ApolloHooksProvider>
        </ApolloProvider>
      </ConfigContextProvider>,
      document.getElementById("root")
    );
  })
  .catch(error => {
    document.write(`
      <h1>We're sorry — something's gone wrong.</h1>
      <p>
        Our team has been notified, <a href="/">refresh</a> to continue.
      </p>
      <p>
        If the problem persists you can get
        <a target="_blank" href="https://internalapp.freshdesk.com/">help here</a>.
      </p>
    `);
    throw error;
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

message.config({ duration: 3, maxCount: 1 });
