import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { RelayNode, Connection } from "../../../../types";

export interface QueueNode extends RelayNode {
  __typename?: "QueueNode";
  name: string;
  description: string;
  slug: string;
  icon: string;
  color: string;
}

export interface Data {
  allQueues: Connection<QueueNode>;
}

export const QUERY = gql`
  query AllQueues {
    allQueues {
      edges {
        node {
          id
          name
          description
          icon
          color
          slug
        }
      }
    }
  }
`;

export const useAllQueues = (options?: QueryHookOptions<Data>) => {
  return useQuery<Data>(QUERY, options);
};
