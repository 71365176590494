import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`;

export const LoadingMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(255, 255, 255, 0.5);

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Table = styled.table`
  position: relative;
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: scroll;
  overflow: overlay;

  thead {
    th {
      z-index: 10;
    }

    &.hasMultipleHeaderGroups tr:first-child th {
      border-right: solid 1px ${props => props.theme.borderGrey};
    }
  }

  // Prevent tbody width from collapsing when empty
  &.emptyTable tbody {
    display: block;
    height: 100%;
    overflow: hidden;
    tr:first-child {
      display: block;
      height: calc(100% - 58px);
      overflow: hidden;
      td {
        display: block;
        height: 100%;
        overflow: hidden;
      }
    }
  }

  /* fake heights allow cell children to have 100% height */
  tbody tr,
  tbody td {
    min-height: 1px;
  }

  tbody td {
    z-index: 0;
  }

  thead > tr > :first-child > div,
  tbody > tr > :first-child {
    padding-left: ${props => props.theme.spacerlg};
  }

  td,
  th {
    border: solid 1px ${props => props.theme.borderGrey};
    border-width: 0 0 1px 0;
  }

  td,
  th > div {
    padding: 10px; /* Purposely different from cssConstants */
  }

  th {
    position: sticky;
    top: 0;
    background-color: ${props => props.theme.backgroundColor};
    white-space: nowrap;
  }

  td:not(.emptyStateCell) > * {
    display: flex;
    flex-direction: column;
    white-space: normal;
    width: max-content;
    max-width: 240px;
    height: 100%;
    justify-content: center;
  }

  td.noWrap > * {
    max-width: unset;
  }

  td.componentColumn {
    padding: 6px;
  }

  tr:hover:not(.loadMoreRow):not(.emptyStateRow) td {
    background-color: ${props => props.theme.rowHoverColor};
  }

  .loadMoreRow td {
    border-bottom: none;
    padding-top: ${props => props.theme.spacermd};
    padding-left: 0 !important;
  }

  .selectedRow {
    td {
      position: relative;
      transition: none !important;
      background-color: ${props => props.theme.rowHoverColor} !important;
      border-bottom-color: ${props => props.theme.primaryColor};

      &:before {
        content: "";
        z-index: 10;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: ${props => props.theme.primaryColor};
      }

      &:first-child {
        border-left-color: ${props => props.theme.primaryColor};
      }

      &:last-child {
        border-right-color: ${props => props.theme.primaryColor};
      }
    }
  }

  .isLastSelectedRow {
    td:first-child:before {
      content: "";
      z-index: 50;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: ${props => props.theme.primaryColor};
    }

    td:first-child {
      position: relative;
      margin-left: -4px;
    }
  }

  /* Scrollbars */
  margin-bottom: ${props => props.theme.scrollBarSize};

  &:not(.emptyTable):hover {
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 0;
  }

  &::-webkit-scrollbar:vertical {
    width: 0px;
  }

  &:hover::-webkit-scrollbar:horizontal {
    height: ${props => props.theme.scrollBarSize};
  }

  &:hover::-webkit-scrollbar:vertical {
    width: ${props => props.theme.scrollBarSize};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${props => props.theme.spacersm};
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: ${props => props.theme.spacersm};
  }
`;
