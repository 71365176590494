import {
  Binding,
  BindingShape,
  ObjectBinding,
  ScalarBinding
} from "../../../../../../types";
import { ComponentNode } from "../../../RenderTreeContext";
import { getChildrenSchema } from "../../util";

import {
  AttributeColumn,
  ComponentColumn,
  isAttributeColumn,
  isComponentColumn
} from ".";

export function getColumnsSchema(node: ComponentNode): Binding[] {
  // TODO: Add a test for this case. properties are not available on
  //       pseudo components.  "ROW" is an example.
  const columns = node.component.properties?.columns || [];

  const attributeColumns = columns.filter(isAttributeColumn) as AttributeColumn[];
  const componentColumns = columns.filter(isComponentColumn) as ComponentColumn[];

  const dataBinding: ObjectBinding = {
    name: "data",
    shape: BindingShape.OBJECT,
    attributes: attributeColumns.map((attr): ScalarBinding => {
      return {
        name: attr.attribute,
        shape: BindingShape.SCALAR
      };
    })
  };

  const componentBindings = getChildrenSchema(
    node,
    componentColumns.map(cc => cc.component_slug)
  );

  return [dataBinding, ...componentBindings];
}
