import React from "react";

import { Select } from "antd";
import styled from "styled-components";

import { colors } from "../../../cssConstants";
import { EnvironmentNode } from "../../../types";
import {
  useAccessibleEnvironments,
  useEnvironmentsQuery
} from "../contexts/EnvironmentContext/EnvironmentContext";
import useDebouncedValue from "../hooks/useDebouncedValue";
import Message from "../Message";

const LOAD_MORE = "page-info-load-next-page";

const { Option } = Select;

export const EnvironmentSelect = styled(Select)`
  width: 305px;
`;

export interface AntdExtraEnvironmentOption {
  key: string;
  display: string;
}

export function AntdEnvironmentSelect({
  first = 150,
  selectedEnvironment,
  setSelectedEnvironment,
  popupContainer,
  extraOptions = []
}: {
  first?: number;
  selectedEnvironment?: EnvironmentNode | string;
  setSelectedEnvironment: (environment?: EnvironmentNode | string) => void;
  popupContainer: HTMLElement;
  extraOptions?: AntdExtraEnvironmentOption[];
}) {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const selectRef = React.useRef<Select<unknown>>(null);
  const [searchText, setSearchText] = React.useState<string | undefined>();
  const debouncedSearchText = useDebouncedValue(searchText, 200);
  const debounceTextDifferent = searchText !== debouncedSearchText;
  const [open, setOpen] = React.useState(false);

  const { data, loading, error, fetchMore } = useEnvironmentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: first,
      offset: 0,
      searchText: debouncedSearchText
    }
  });

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data!.allEnvironments.edges.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          allEnvironments: {
            pageInfo: fetchMoreResult.allEnvironments.pageInfo,
            edges: [
              ...prev.allEnvironments.edges,
              ...fetchMoreResult.allEnvironments.edges
            ],
            __typename: prev.allEnvironments.__typename
          }
        };
      }
    });
  };

  const focusInput = React.useCallback(() => {
    const antSelectInput = rootRef.current?.querySelector(".ant-select-search__field");
    if (antSelectInput) {
      (antSelectInput as HTMLElement).focus();
    }
  }, [rootRef]);

  const { accessibleEnvironments, loading: filterLoading } = useAccessibleEnvironments(
    data?.allEnvironments.edges.map(e => e.node)
  );
  const environments = React.useMemo(() => {
    if (filterLoading || debounceTextDifferent) return [];
    return accessibleEnvironments;
  }, [accessibleEnvironments, filterLoading, debounceTextDifferent]);

  const pageInfo = debounceTextDifferent ? undefined : data?.allEnvironments.pageInfo;

  React.useEffect(() => {
    if (error) Message.error("Something went wrong. Please try again.");
  }, [error]);

  const selectedEnvironmentNode = selectedEnvironment as EnvironmentNode;
  const options = React.useMemo(() => {
    let filteredEnvironments = environments;

    if (selectedEnvironmentNode.id) {
      filteredEnvironments = [
        selectedEnvironmentNode,
        ...filteredEnvironments.filter(e => e.id !== selectedEnvironmentNode.id)
      ];
    }

    return {
      environments: filteredEnvironments
    };
  }, [environments, selectedEnvironmentNode]);

  const selectedEnvironmentValue = selectedEnvironmentNode.id
    ? selectedEnvironmentNode.id
    : selectedEnvironment;

  return (
    <div ref={rootRef}>
      <EnvironmentSelect
        ref={selectRef}
        loading={loading || filterLoading || debounceTextDifferent}
        showSearch={true}
        filterOption={false}
        onSearch={setSearchText}
        open={open}
        onFocus={() => {
          if (!open) {
            setOpen(true);
            focusInput();
          }
        }}
        onBlur={() => {
          if (open) {
            setOpen(false);
          }
        }}
        value={selectedEnvironmentValue}
        getPopupContainer={() => popupContainer}
        onSelect={environmentId => {
          const envId = environmentId as string;
          const extra = extraOptions.find(extra => extra.key === envId);
          if (extra) {
            setSelectedEnvironment(extra.key);
          } else if (envId === LOAD_MORE) {
            fetchMoreData();
            // when you click load more, the input loses focus, so put it back
            focusInput();
            return;
          } else {
            const env = options.environments.find(
              node => node.id === environmentId
            ) as EnvironmentNode;
            if (env.id !== selectedEnvironmentNode.id) {
              setSelectedEnvironment(env);
            }
          }

          // Reset search because the last search query may have been very specific
          // In which case it might only display 1 result, which looks odd when you click back
          // to open the select
          setSearchText(undefined);
          setOpen(false);
          selectRef.current?.blur();
        }}
      >
        {options.environments.map(environment => (
          <Option key={environment.id}>{environment.name}</Option>
        ))}

        {pageInfo?.hasNextPage && (
          <Option key={LOAD_MORE} style={{ textAlign: "center" }}>
            Load More
          </Option>
        )}

        {extraOptions.map((extra, index) => (
          <Option
            key={extra.key}
            style={{
              borderTop:
                index === 0 ? `1px solid ${colors.newContainerPrimaryTint}` : undefined
            }}
          >
            {extra.display}
          </Option>
        ))}
      </EnvironmentSelect>
    </div>
  );
}
