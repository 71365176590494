import React from "react";

import { Navigate } from "react-router";

import { Routes } from "../../constants";
import { AuthStatus } from "../../types";
import useAuthStatus from "../common/hooks/useAuthStatus";

interface Props {
  children?: React.ReactNode;
}

export const RequirePartialAuth = ({ children }: Props) => {
  const { status, loading } = useAuthStatus();

  if (loading) return null;

  return status === AuthStatus.Unauthenticated ? (
    <Navigate to={{ pathname: Routes.LOGIN }} />
  ) : (
    <>{children}</>
  );
};
