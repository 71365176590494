import { SpaceComponentObject } from "../../../../types";

export const getSpaceComponentDeleteCopy = (sc: SpaceComponentObject) => {
  switch (sc.type) {
    case "TABLE":
      return `Are you sure you want to remove your ${sc.name} table?`;
    case "FUNCTION_BUTTON":
      return "Are you sure you want to remove your button?";
    case "FUNCTION_FORM":
      return `Are you sure you want to remove your ${sc.properties.title} form?`;
    case "FUNCTION_MODAL_FORM":
      return `Are you sure you want to remove your ${sc.properties.title} pop-up form?`;
    case "CUSTOM_FIELD":
      return `Are you sure you want to remove your ${sc.name} filter?`;
    default:
      return "Are you sure you want to remove this component?";
  }
};
