import React, { useContext } from "react";

import styled from "styled-components";

import CountryDropdown from "../../../components/common/CountryDropdown";

import { BillingContext } from "./BillingContext";
import TAX_COUNTRY_OPTIONS from "./TaxCountryOptions";
import TaxIdTypeSelect from "./TaxIDTypeSelect";

interface BillingInformationFormProps {
  formValidation: boolean;
  showTaxIds: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    flex-basis: 48%;
  }
`;

const InfoForm = styled.form`
  label {
    display: block;
  }

  #taxIdType {
    display: inline-block;
    margin-right: 20px;
    width: 48%;
  }

  input[type="text"] {
    width: 100%;
    border: 1px solid ${props => props.theme.borderGrey};
    border-radius: 5px;
    padding-left: 10px;
    line-height: 2.4;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    position: relative
    margin-bottom: 20px;

    &[name="billingTaxId"] {
      display: inline-block;
      width: 48%;
    }
  }

  &.validate {
    input:invalid {
      border: 1px solid ${props => props.theme.errorColor};
    }
  }
`;

const BillingInformationForm = ({
  formValidation,
  onSubmit,
  showTaxIds
}: BillingInformationFormProps) => {
  const context = useContext(BillingContext);

  return (
    <InfoForm
      id="billingForm"
      className={formValidation ? "validate" : ""}
      onSubmit={onSubmit}
    >
      <label htmlFor="billingCountry">Country</label>
      <CountryDropdown
        onChange={context.onCountryUpdate}
        currentCountry={context.billingCountry}
        billingCountryError={context.billingCountryError}
      />

      {context.billingCountry === "" || TAX_COUNTRY_OPTIONS[context.billingCountry] ? (
        <>
          <label htmlFor="billingCompany">Company legal name</label>
          <input
            type="text"
            name="billingCompany"
            placeholder="Company legal name"
            value={context.billingCompany}
            onChange={context.onCompanyUpdate}
            required
          />

          <label htmlFor="billingAddress">Company address</label>
          <input
            type="text"
            name="billingAddress"
            onChange={context.onAddressUpdate}
            value={context.billingAddress}
            placeholder="Company billing address"
            required
          />

          <FlexRow>
            <div>
              <label htmlFor="billingCity">City</label>
              <input
                type="text"
                name="billingCity"
                placeholder="City name"
                onChange={context.onCityUpdate}
                value={context.billingCity}
                required
              />
            </div>

            <div>
              <label htmlFor="billingZip">Zip code</label>
              <input
                type="text"
                name="billingZip"
                placeholder="Zip code"
                value={context.billingZip}
                onChange={context.onZipUpdate}
                required
              />
            </div>
          </FlexRow>

          {showTaxIds && context.billingCountry !== "US" && (
            <>
              <label htmlFor="billingTaxId">Tax ID</label>
              <TaxIdTypeSelect
                onChange={context.onSelectTaxIdType}
                value={context.taxIdType}
                currentCountry={context.billingCountry}
              />
              <input
                type="text"
                name="billingTaxId"
                placeholder="Tax ID"
                onChange={context.onTaxIdUpdate}
                value={context.taxId}
                className={context.taxIdErrorMessage ? "invalid" : ""}
                required
              />

              {context.taxIdErrorMessage && (
                <p className="error">{context.taxIdErrorMessage}</p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          We cannot accept payments from the selected country at this time. Please
          contact support for more information.
        </>
      )}
    </InfoForm>
  );
};

export default BillingInformationForm;
