import React from "react";

export const initialBillingContext = {
  onCountryUpdate: () => null,
  onCompanyUpdate: () => null,
  onAddressUpdate: () => null,
  onCityUpdate: () => null,
  onZipUpdate: () => null,
  onSelectTaxIdType: () => null,
  onTaxIdUpdate: () => null,
  setBillingCountryError: () => null,
  billingCountry: "",
  billingCountryError: undefined,
  billingCompany: "",
  billingAddress: "",
  billingCity: "",
  billingZip: "",
  taxId: "",
  taxIdType: "",
  taxIdErrorMessage: undefined
};

export const billingReducer = (
  state: BillingContextValue,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_BILLING_COMPANY":
      return { ...state, billingCompany: payload };
    case "SET_BILLING_CITY":
      return { ...state, billingCity: payload };
    case "SET_BILLING_ADDRESS":
      return { ...state, billingAddress: payload };
    case "SET_BILLING_ZIP":
      return { ...state, billingZip: payload };
    case "SET_BILLING_COUNTRY":
      return { ...state, billingCountry: payload };
    case "SET_BILLING_COUNTRY_ERROR":
      return { ...state, billingCountryError: payload };
    case "SET_TAX_ID_ERROR_MESSAGE":
      return { ...state, taxIdErrorMessage: payload };
    case "SET_TAX_ID":
      return { ...state, taxId: payload };
    case "SET_TAX_ID_TYPE":
      return { ...state, taxIdType: payload };
    default:
      return state;
  }
};

export interface BillingContextValue {
  onCountryUpdate: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onCompanyUpdate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddressUpdate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCityUpdate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onZipUpdate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectTaxIdType: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onTaxIdUpdate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setBillingCountryError: (val: string) => void;
  billingCountry: string;
  billingCountryError?: string | undefined;
  billingCompany: string;
  billingAddress: string;
  billingCity: string;
  billingZip: string;
  taxId: string;
  taxIdType: string;
  taxIdErrorMessage?: string | undefined;
}

export const BillingContext =
  React.createContext<BillingContextValue>(initialBillingContext);
