import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { draftComponent } = state;
  return errors.concat(selectVisibilityRuleErrors(draftComponent));
}
