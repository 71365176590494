import { useEffect, useReducer } from "react";

import { isEqual } from "lodash";

import { useEvaluaterContext } from "../../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../../common/CodeSandbox/useCodeSandbox";
import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentContext } from "../../contexts/ComponentContext";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";
import { useConditionEvaluator } from "../Conditionals";
import { ValidationRule } from "../ValidationField/types";

function validationErrorReducer(
  state: { validationErrors: string[] },
  action: { type: "SET_VALIDATION_ERRORS"; payload: { validationErrors: string[] } }
) {
  switch (action.type) {
    case "SET_VALIDATION_ERRORS":
      if (isEqual(state.validationErrors, action.payload.validationErrors)) {
        return state;
      }
      return {
        validationErrors: action.payload.validationErrors
      };

    default:
      return state;
  }
}
const emptyRules: ValidationRule[] = [];
export default function useValidationErrors() {
  const [{ validationErrors }, dispatch] = useReducer(validationErrorReducer, {
    validationErrors: []
  });
  const { editMode } = useStableSpaceContext();
  const validationRules: ValidationRule[] =
    useComponentContext().component.properties.validation_rules || emptyRules;

  const { input } = useComponentStateContext();
  const evalConditional = useConditionEvaluator();
  const { evaluate, getConsoleError } = useEvaluaterContext();

  useEffect(() => {
    // Subsequent rule evals may occur before the previous ones have finished.
    // Keep a cancelled flag to minimize wasted work.
    let cancelled = false;
    const evalRule = async (idx: number) => {
      try {
        const result = await evalConditional(
          validationRules[idx].conditional_expression,
          input
        );
        if (cancelled) return;
        if (result) return undefined;
        const msg = await evaluate(validationRules[idx].message_template, input);
        if (cancelled) return;
        return [idx, msg] as [number, string];
      } catch (e) {
        if (cancelled) return;
        if (editMode) {
          console.warn(getConsoleError(e));
        }

        // fail if it cannot be evaluated (likely due to bindings not fulfilled)
        if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          return [idx, "Required data not yet present."];
        }
      }
    };
    const evalRules = async () => {
      const validationErrorTuples = await Promise.all(
        validationRules.map((_, idx) => evalRule(idx))
      );
      if (cancelled) return;
      const nextValidationErrors = (
        validationErrorTuples as unknown as [number, string][]
      )
        .sort((a, b) => a[0] - b[0])
        .filter(ve => ve !== undefined)
        .map(ve => ve[1]);

      dispatch({
        type: "SET_VALIDATION_ERRORS",
        payload: { validationErrors: nextValidationErrors }
      });
    };
    evalRules();
    return () => {
      cancelled = true;
    };
  }, [validationRules, input, editMode, evaluate, evalConditional, getConsoleError]);

  return validationErrors;
}
