import moment from "moment";

import {
  TIMESTAMP_SAVE_FORMAT,
  TIME_SAVE_FORMAT,
  DATE_TIME_SAVE_FORMAT,
  DATE_SAVE_FORMAT,
  AttributeTypes
} from "../../../../../constants";

const formatMoment = (value: string, format: string): [string, boolean] => {
  const m = moment(
    value,
    [
      moment.ISO_8601,
      moment.HTML5_FMT.DATETIME_LOCAL,
      moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
      moment.HTML5_FMT.DATETIME_LOCAL_MS,
      moment.HTML5_FMT.DATE,
      moment.HTML5_FMT.TIME,
      moment.HTML5_FMT.TIME_SECONDS,
      moment.HTML5_FMT.TIME_MS,
      "MM-DD-YYYY",
      "M-D-YYYY",
      "MM/DD/YYYY",
      "M/D/YYYY"
    ],
    true
  );
  if (!m.isValid()) {
    return [value, false];
  }
  return [m.format(format), true];
};

const formatNumeral = (value: string, type: AttributeTypes): [string, boolean] => {
  const val = value.replace(/,/g, ""); // remove any commas
  if (val === "Infinity" || val === "-Infinity" || val === "NaN" || val === "") {
    return [val, true];
  }

  const intRegex = /^([+-]?[1-9]\d*|0)$/;
  const floatOrDecimalRegex = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;

  if (type === AttributeTypes.DECIMAL || type === AttributeTypes.FLOAT) {
    return [val, floatOrDecimalRegex.test(val)];
  } else {
    return [val, intRegex.test(val)];
  }
};

export const parseText = (text: string, type: AttributeTypes): [string, boolean] => {
  let value = text;
  if (type !== AttributeTypes.STRING) {
    value = value.trim();
  }

  switch (type) {
    case AttributeTypes.BOOL:
      if (!["0", "1", "false", "true"].includes(value.toLowerCase())) {
        return [value, false];
      }
      return [String(["1", "true"].includes(value.toLowerCase())), true];
    case AttributeTypes.DATE:
      return formatMoment(value, DATE_SAVE_FORMAT);
    case AttributeTypes.DATETIME:
      return formatMoment(value, DATE_TIME_SAVE_FORMAT);
    case AttributeTypes.TIMESTAMP:
      return formatMoment(value, TIMESTAMP_SAVE_FORMAT);
    case AttributeTypes.TIME:
      return formatMoment(value, TIME_SAVE_FORMAT);
    case AttributeTypes.INT:
      return formatNumeral(value, type);
    case AttributeTypes.DECIMAL:
      return formatNumeral(value, type);
    case AttributeTypes.FLOAT:
      return formatNumeral(value, type);
    case AttributeTypes.STRING:
      return [value, true];
    case AttributeTypes.JSON:
      // bulk imports for json fields are unclear, temporarily consider invalid
      return [value, false];
    default:
      return [value, false];
  }
};
