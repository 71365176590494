import {
  SpaceComponentObject,
  Filter,
  Order,
  BaseComponentProperties
} from "../../../../../types";
import { Column } from "../common/ColumnListManager";

import {
  ChartConfigAction as _ChartConfigAction,
  ChartConfigState as _ChartConfigState
} from "./Config/reducer";

export enum ChartType {
  COLUMN = "column",
  LINE = "line",
  PIE = "pie"
}

export const ChartTypeDisplayNames = {
  [ChartType.COLUMN]: "Bar chart",
  [ChartType.LINE]: "Line graph",
  [ChartType.PIE]: "Pie chart"
};

export enum AggregationFunctionType {
  SUM = "sum",
  COUNT = "count",
  PREAGGREGATED = "preaggregated"
}
export interface AggregationFunction {
  type: AggregationFunctionType; // | (attribute: AttributeNode, row: DataValue[], attributes: AttributeNode[]) => number
  attribute: string | undefined;
}

export enum GroupingFunctionType {
  EQUALITY = "equality",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly"
}
export interface GroupingFunction {
  types: GroupingFunctionType[];
  attribute: string | undefined;
}

export type SpaceChartProperties = BaseComponentProperties & {
  title: string;
  chart_type: ChartType;
  y_axis: { aggregation_function: AggregationFunction };
  x_axis: { grouping_function: GroupingFunction };
  sub_group_functions: GroupingFunction[];
  preaggregated_attributes: string[];
  binding: string | undefined;
  columns: Column[] | null;
  filters: Filter[];
  is_header_enabled: boolean;
  is_filter_required: boolean;
  order: Order[];
};

export type SpaceChartComponent = SpaceComponentObject<SpaceChartProperties>;

export enum SubSeriesMode {
  NONE = "none",
  GROUP = "group",
  PREAGGREGATED = "preaggregated"
}

export type ChartConfigAction = _ChartConfigAction;

export type ChartConfigState = _ChartConfigState;
