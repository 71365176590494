import { Column as ColumnConfig, isAttributeColumn } from "../ColumnListManager";

// Array of ordered columns with their name and the index of the columns
// value in the view rows array.
type ColumnViewMappingEntry = {
  name: string;
  viewIndex: number;
};

export type ColumnViewMapping = ColumnViewMappingEntry[];

function isColumnViewMapping(
  mapping: ColumnViewMappingEntry | null
): mapping is ColumnViewMappingEntry {
  return mapping !== null;
}

export function createColumnViewMapping(
  columns: ColumnConfig[],
  attributes: { sourceName: string }[]
): ColumnViewMapping {
  return columns
    .map(c => {
      if (!isAttributeColumn(c)) return null;

      const i = attributes.findIndex(a => a.sourceName === c.attribute);
      return i > -1 ? { name: attributes[i].sourceName, viewIndex: i } : null;
    })
    .filter(isColumnViewMapping);
}
