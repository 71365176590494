import React from "react";

import { Button, Icon } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled from "styled-components";

import { colors } from "../../../../cssConstants";
import { ModalNew } from "../../../common/Modal";
import { H3 } from "../../../common/StyledComponents";
import { SpaceRolePermissions } from "../SpaceRolePermission/SpaceRolePermission";

export const List = styled.ul`
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  margin: 5px 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  color: ${props => props.theme.textColorMid};
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RoleList = styled.div`
  width: 100%;
`;

export const RoleListItem = styled.div`
  border: 1px solid ${colors.newContainerPrimaryTint};
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;

  &.expanded {
    border-color: ${colors.primaryAccent};
  }
`;

export const RoleHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 63px;

  background-color: ${colors.newContainerPrimary};

  &:hover {
    background-color: ${colors.newContainerHover};
  }

  &.expanded {
    border-bottom: 1px solid ${colors.newContainerPrimaryTint};
  }
`;

export const ShareWithRoleName = styled.div`
  line-height: 19px;
  flex-grow: 1;
`;

export const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledModal = styled(ModalNew)`
  max-width: 1400px;

  .ant-modal-title {
    color: ${colors.surfaceSecondary};
  }

  .ant-modal-close {
    top: 4px;
    right: 4px;
  }

  .ant-modal-content {
    min-height: 85vh;
    border-radius: 8px;

    .ant-modal-header {
      padding: 24px 48px 24px 48px;
    }

    .ant-modal-body {
      padding: 0 48px;
    }
  }
`;

export const StyledLinkButton = styled(Button)`
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  height: 22px;
  min-width: 185px;
  padding-left: 0;
  padding-right: 0;

  text-align: right;
`;

export const StyledLinkButtonNew = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledLinkButton type="link" {...props}>
      {children}
    </StyledLinkButton>
  );
};

export const StyledCloseIcon = styled(Icon)`
  color: ${colors.surfacePrimary};
`;

export const ShareWithRowCell = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;

  .switchToggle {
    margin-left: 12px;
  }
`;

export const HelpIcon = styled(Icon)`
  min-width: 20px;
`;

export const ButtonContainer = styled.div`
  min-width: 185px;
  text-align: right;

  button + button {
    margin-left: 8px;
  }
`;

export const HeaderSpaceName = styled(H3)`
  margin-top: 32px;
`;

export const StyledSpaceRolePermissions = styled(SpaceRolePermissions)`
  background-color: white;
`;
