import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import AutomationsIcon from "../../../assets/automations-icon.svg";
import usePaths from "../../common/hooks/usePaths";

export const HomeLink = styled(Link)`
  height: 24px;
`;

export default function HomeButton() {
  const { automations_getHome } = usePaths();
  return (
    <HomeLink to={automations_getHome()}>
      <AutomationsIcon />
    </HomeLink>
  );
}
