import React from "react";

import { SpaceConfigAction } from "../../../../../../types";
import { ChartConfigState } from "../../../types";
import ColumnChartConfig from "../ColumnChartConfig";

interface Props {
  state: ChartConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

export default function LineChartConfig(props: Props) {
  return <ColumnChartConfig {...props} />;
}
