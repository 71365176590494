import React from "react";

import { Icon as AntIcon } from "antd";
import { usePopper } from "react-popper";
import styled from "styled-components";

import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import EnvironmentSelector from "../../../common/EnvironmentSelector";
import Message from "../../../common/Message";

import { useRunAutomation } from "./queries/RunAutomation";

const Button = styled(ButtonNew)`
  position: relative;
  width: 100%;
`;

const Icon = styled(AntIcon)`
  position: absolute;
  right: 16px;
  top: 10px;
`;

const PopperContainer = styled.div`
  background-color: ${props => props.theme.newBackgroundPrimaryColor};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  box-shadow: ${props => props.theme.popperBoxShadow};
  width: 300px;
  z-index: ${props => props.theme.zIndex.popupOverPopover};
`;

const PopperLabel = styled.label`
  font-size: 13px;
  margin-bottom: ${props => props.theme.spacersm};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => props.theme.spacerlg};
`;

const ActionsContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.spacermd};
  border-top: 1px solid ${props => props.theme.newContainerPrimaryTint};
  justify-content: flex-end;
  button + button {
    margin-left: ${props => props.theme.spacersm};
  }
`;

const Error = styled.p`
  color: ${props => props.theme.errorColor};
  font-size: ${props => props.theme.smallFontSize};
  margin: 0;
`;

interface Props {
  id: string;
}

export const RunAutomationButton = ({ id }: Props) => {
  const [showPopper, setShowPopper] = React.useState(false);
  const [referenceRef, setReferenceRef] = React.useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = React.useState<HTMLElement | null>(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [selectedEnvironments, setSelectedEnvironments] = React.useState<Set<string>>(
    new Set()
  );

  const [runAutomation, { loading }] = useRunAutomation({
    onCompleted: data => {
      setShowPopper(false);
      if (data.runAutomation.ok) {
        Message.success("Running your automation");
      }
    },
    onError: _ => {
      Message.error(
        "An error occurred while trying to run your automation.  Please try again."
      );
    }
  });

  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 10]
        }
      }
    ]
  });

  const handleRunAutomation = React.useCallback(() => {
    setSubmitted(true);
    if (selectedEnvironments.size > 0) {
      const environmentIds = Array.from(selectedEnvironments);
      runAutomation({ variables: { id, environmentIds } });
    }
  }, [id, runAutomation, selectedEnvironments]);

  return (
    <>
      <div ref={setReferenceRef}>
        <Button onClick={() => setShowPopper(!showPopper)}>
          Run now <Icon type="caret-down" />
        </Button>
      </div>
      {showPopper && (
        <PopperContainer
          ref={setPopperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <Container>
            <PopperLabel>Select Environments</PopperLabel>
            {submitted && selectedEnvironments.size === 0 && (
              <Error>Please select at least one environment.</Error>
            )}
            <EnvironmentSelector
              value={selectedEnvironments}
              onChange={setSelectedEnvironments}
            />
          </Container>
          <ActionsContainer>
            <ButtonNew key="back" onClick={() => setShowPopper(false)}>
              Close
            </ButtonNew>
            <ButtonNew
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleRunAutomation}
            >
              Run now
            </ButtonNew>
          </ActionsContainer>
        </PopperContainer>
      )}
    </>
  );
};

export default RunAutomationButton;
