import { cloneDeep } from "lodash";

import { PSEUDO_CONTAINER_MAP } from "../../../../../constants";
import { TreeNode } from "../reducer";

import findTreeNode from "./findTreeNode";

const PSEUDO_COMPONENT_SLUGS = new Set(
  Object.entries(PSEUDO_CONTAINER_MAP)
    .map(([_key, val]) => val)
    .flatMap(v => v)
    .map(v => v[1])
);

const pseudoComponentRegex = new RegExp(Array.from(PSEUDO_COMPONENT_SLUGS).join("|"));

function prunePseudoComponents(path: string) {
  return path
    .split(".")
    .filter(s => !s.match(pseudoComponentRegex))
    .join(".");
}

export default function moveTreeNode(
  _tree: TreeNode,
  _sourcePath: string,
  _destinationPath: string,
  index: number
) {
  // Remove psuedos from paths
  if (_sourcePath === _destinationPath) return _tree;
  const sourcePath = prunePseudoComponents(_sourcePath);
  const destinationPath = prunePseudoComponents(_destinationPath);
  const tree = cloneDeep(_tree);
  // Find current parent
  const sourceNode = findTreeNode(tree, sourcePath.split("."));

  if (sourceNode === undefined) {
    throw new Error("Expected to find sourceNode.");
  }

  // Prune node from current parent
  sourceNode.container = null;
  const oldContainerPathParts = sourcePath.split(".");
  oldContainerPathParts.pop();
  const oldContainer =
    oldContainerPathParts.length === 0
      ? tree
      : findTreeNode(tree, oldContainerPathParts);
  if (oldContainer === undefined) {
    throw new Error("Expect to find old container.");
  }
  oldContainer.treeNodes = oldContainer.treeNodes.filter(
    tn => tn.slug !== sourceNode.slug
  );

  // Find next parent
  const destinationNode =
    destinationPath === "" ? tree : findTreeNode(tree, destinationPath.split("."));
  if (destinationNode === undefined) {
    throw new Error("Expected to find destinationNode.");
  }

  // Attach node
  destinationNode.treeNodes = [
    ...destinationNode.treeNodes.slice(0, index),
    sourceNode,
    ...destinationNode.treeNodes.slice(index)
  ];
  sourceNode.container = destinationNode;

  // Regenerate root TreeNode so selectSpaceComponentTree knows to update
  return tree;
}
