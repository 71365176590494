import React from "react";

import { ServiceUnavailable } from "../../../pages/errors";
import { AuthStatus } from "../../../types";
import useAuthStatus from "../../common/hooks/useAuthStatus";

export interface AuthStatusProps {
  status: AuthStatus;
}

export function withAuthStatus<P>(Component: React.ComponentType<P & AuthStatusProps>) {
  return (props: P) => {
    const { status, loading, error } = useAuthStatus();

    if (loading) {
      return null;
    }

    if (error) {
      return <ServiceUnavailable />;
    }

    return <Component {...props} status={status} />;
  };
}
