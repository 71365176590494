import React, { useEffect, useState } from "react";

export interface ScrollHandlerProps {
  hasScrolled: boolean;
}

const withScrollHandler =
  <P extends object>(Component: React.ComponentType<P & ScrollHandlerProps>) =>
  (props: P) => {
    const [hasScrolled, setHasScrolled] = useState(false);

    const handleScroll = () => {
      if (window.pageYOffset > 0 && !hasScrolled) {
        setHasScrolled(true);
      } else if (window.pageYOffset === 0 && hasScrolled) {
        setHasScrolled(false);
      }
    };

    useEffect(() => {
      window.addEventListener(
        "scroll",
        () => {
          handleScroll();
        },
        true
      );

      return function cleanup() {
        window.removeEventListener(
          "scroll",
          () => {
            handleScroll();
          },
          true
        );
      };
    });

    return <Component hasScrolled={hasScrolled} {...(props as P)} />;
  };

export default withScrollHandler;
