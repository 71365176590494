import React from "react";

import { Skeleton } from "antd";

import { GlobalStyleVariables } from "../../../cssConstants";
import { AuthorizationFlowNode } from "../../../types";
import Drawer from "../../common/Drawer";

import BasicAuthProviderDrawer from "./BasicAuthProviderDrawer";
import { CustomAuthProviderDrawer } from "./CustomAuthProviderDrawer";
import DeleteAuthProviderButton from "./DeleteAuthProviderButton/DeleteAuthProviderButton";
import OAuthAuthProviderDrawer from "./OAuthAuthProviderDrawer";

export interface AuthProviderDrawerProps {
  onClose: () => void;
  isVisible: boolean;
  loading: boolean;
  children?: React.ReactNode;
  authorizationProvider: AuthorizationFlowNode;
}

enum DrawerType {
  BASIC_AUTH = 0,
  OAUTH = 1,
  CUSTOM = 2
}

const drawerContentsComponentMap = {
  [DrawerType.BASIC_AUTH]: BasicAuthProviderDrawer,
  [DrawerType.OAUTH]: OAuthAuthProviderDrawer,
  [DrawerType.CUSTOM]: CustomAuthProviderDrawer
};

export const AuthProviderDrawer = ({
  onClose,
  loading,
  isVisible,
  authorizationProvider
}: AuthProviderDrawerProps) => {
  let drawerType = DrawerType.CUSTOM;

  if (authorizationProvider?.description === "Basic Authorization") {
    drawerType = DrawerType.BASIC_AUTH;
  }

  if (authorizationProvider?.description === "OAuth 2.0") {
    drawerType = DrawerType.OAUTH;
  }

  let drawerContents: React.ReactNode = null;
  if (loading) {
    drawerContents = <Skeleton active />;
  } else {
    const DrawerTypeComponent = drawerContentsComponentMap[drawerType];
    drawerContents = (
      <DrawerTypeComponent
        onCloseButtonClick={onClose}
        onEditComplete={onClose}
        authorizationProvider={authorizationProvider}
      />
    );
  }
  return (
    <Drawer
      width={GlobalStyleVariables.drawerWidth}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={isVisible}
    >
      {drawerContents}
      <DeleteAuthProviderButton
        authorizationProvider={authorizationProvider}
        onDelete={onClose}
        buttonProps={{ type: "danger" }}
      />
    </Drawer>
  );
};
