import styled from "styled-components";

export const EffectOptionField = styled.div`
  padding: ${props => props.theme.spacermd} 0 0 0;

  .ant-select {
    width: 100%;
  }
`;

export const EffectOptionLabel = styled.label`
  display: block;
`;
