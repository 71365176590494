import React from "react";

import { Select } from "antd";
import produce from "immer";
import styled from "styled-components";

import KeyValueInputs, { KeyValue } from "../../../../common/KeyValueInputs";
import { BasePanel } from "../../../../common/StyledComponents";
import {
  CredentialHeader,
  CredentialStepOptions,
  PendingCredentialStep
} from "../reducer";

import { ExpirationFields } from "./common/ExpirationFields";

import { StepProps } from "./index";

const StyledBasePanel = styled(BasePanel)`
  margin-bottom: ${props => props.theme.spacermd};
`;

const CredentialContent = styled.div`
  padding: ${props => props.theme.spacermd};

  & > div:not(:last-child) {
    margin-bottom: ${props => props.theme.spacermd};
  }
`;

type Props = StepProps<PendingCredentialStep>;

export const CredentialStep = ({ step, dispatch, updateOptions }: Props) => {
  return (
    <div>
      <h3>Credential</h3>
      <StyledBasePanel>
        <CredentialContent>
          <div>
            <h3>Type</h3>
            <Select
              style={{ width: 150 }}
              value={step.options?.adapter || "http"}
              disabled
            >
              <Select.Option value="http">HTTP</Select.Option>
            </Select>
          </div>
          <div>
            <h3>Headers</h3>
            <KeyValueInputs
              useTemplateStringInputsForValue
              itemName="header"
              value={
                step.options?.headers?.map?.((h: CredentialHeader) => ({
                  key: h.name,
                  value: h.value
                })) || []
              }
              onChange={(value: KeyValue[]) => {
                updateOptions(
                  produce(step.options, (draft: CredentialStepOptions) => {
                    draft.headers = value.map((kv: KeyValue) => ({
                      name: kv.key,
                      value: kv.value
                    }));
                    return draft;
                  })
                );
              }}
            />
          </div>
          <h3>Expiration</h3>
          <ExpirationFields
            step={step}
            dispatch={dispatch}
            updateOptions={updateOptions}
          />
        </CredentialContent>
      </StyledBasePanel>
    </div>
  );
};

export default CredentialStep;
