import { CascaderOptionType } from "antd/lib/cascader";

import { Binding, BindingShape } from "../../../types";
import { assertNever } from "../../util/assertNever";

export interface Option extends CascaderOptionType {
  label: string;
  bindingShape?: BindingShape;
  children: Option[];
}

export const getOption = (binding: Binding): Option => {
  const _children = (b: Binding): Option[] => {
    switch (b.shape) {
      case BindingShape.SCALAR:
      case BindingShape.UNKNOWN:
        return [];
      case BindingShape.SCALAR_ARRAY:
        return [{ shape: BindingShape.SCALAR, name: b.name } as const].map(getOption);
      case BindingShape.OBJECT:
        return b.attributes.map(getOption);
      case BindingShape.OBJECT_ARRAY:
        return b.attributes.map(getOption);
      default:
        return assertNever(b);
    }
  };
  return {
    label: binding.title || binding.name,
    value: binding.name,
    bindingShape: binding.shape,
    children: _children(binding)
  };
};

export const filterByShape = (
  options: Option[],
  shapes: Set<BindingShape>
): Option[] => {
  const _isTerminal = (o: Option) =>
    !o.children.length ||
    o.bindingShape === BindingShape.OBJECT_ARRAY ||
    o.bindingShape === BindingShape.SCALAR_ARRAY ||
    o.bindingShape === BindingShape.UNKNOWN ||
    o.bindingShape === BindingShape.SCALAR;

  const _isAcceptable = (o: Option) => o.bindingShape && shapes.has(o.bindingShape);

  const _filter = (o: Option): Option | null => {
    if (_isTerminal(o)) {
      return _isAcceptable(o) ? { ...o, children: [] } : null;
    }
    const matched = o.children.reduce<Option[]>((acc, child) => {
      const filtered = _filter(child);
      return filtered ? [...acc, filtered] : acc;
    }, []);

    return matched.length || _isAcceptable(o) ? { ...o, children: matched } : null;
  };
  return _filter({ label: "wrapper", children: options })?.children || [];
};
