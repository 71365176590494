import { ResourceNode, AttributeNode, Edge, RecordKey } from "../../../types";

const getResourceAttributeEdges = (resource: ResourceNode): Edge<AttributeNode>[] => {
  try {
    return resource.attributes!.edges!;
  } catch {
    return [];
  }
};

const getResourceAttributes = (resource: ResourceNode): AttributeNode[] =>
  getResourceAttributeEdges(resource).map(edge => edge.node);

const getPkResourceAttributes = (resource: ResourceNode): AttributeNode[] =>
  getResourceAttributes(resource).filter(attr => attr.sourcePrimaryKey);

export const getResourceRecordKey = (resource: ResourceNode, record: any): RecordKey =>
  getPkResourceAttributes(resource).reduce((key: RecordKey, node) => {
    key[node.sourceName] = record[node.sourceName] || record[node.id];
    return key;
  }, {});
