import React from "react";

import pluralize from "pluralize";

import { SpaceComponentObject } from "../../../../../../types";
import debug from "../../../../../util/debug";
import { ComponentContextContainer } from "../../contexts/ComponentContext";
import { ComponentStateContainer } from "../../contexts/ComponentStateContext";

export default function CollectionItemContainer({
  index = 0,
  collectionKey,
  children
}: {
  index?: number;
  collectionKey: string;
  children: React.ReactNode;
}) {
  debug("Rendering CollectionItemContainer", collectionKey, index);
  const shimComponent = React.useMemo(
    () =>
      ({
        slug: collectionKey,
        type: pluralize(collectionKey, 1).toUpperCase()
      } as SpaceComponentObject),
    [collectionKey]
  );

  return (
    <ComponentContextContainer isPseudo component={shimComponent}>
      <ComponentStateContainer
        component={shimComponent}
        collectionKey={collectionKey}
        index={index}
      >
        {children}
      </ComponentStateContainer>
    </ComponentContextContainer>
  );
}

// used for record (vs collection) pseudocomponents
export function ItemContainer({
  itemKey,
  children
}: {
  itemKey: string;
  children: React.ReactNode;
}) {
  debug("Rendering ItemContainer", itemKey);
  const shimComponent = React.useMemo(
    () =>
      ({
        slug: itemKey,
        type: itemKey.toUpperCase()
      } as SpaceComponentObject),
    [itemKey]
  );

  return (
    <ComponentContextContainer isPseudo component={shimComponent}>
      <ComponentStateContainer component={shimComponent} itemKey={itemKey}>
        {children}
      </ComponentStateContainer>
    </ComponentContextContainer>
  );
}
