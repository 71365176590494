import React, { Dispatch } from "react";

import Expandable from "../../../../common/Expandable";
import { RightDownChevron } from "../../../../common/ToggleChevron";
import { IconButton } from "../../common/styledComponents";
import { ReducerAction, ReducerActions } from "../../reducer/actions";
import { ConfigState } from "../../reducer/reducer";
import { ActionType, DispatcherType, Event, State, Transition } from "../../types";
import ActionList from "../ActionList";
import * as common from "../common/styledComponents";

import * as styled from "./styledComponents";
import TransitionComponent from "./Transition";

interface Props {
  currentState: State;
  state: ConfigState;
  dispatch: Dispatch<ReducerAction>;
}

const filterMap = <T, R>(
  items: T[],
  filterFunc: (item: T, itemsIndex: number) => boolean,
  mapFunc: (item: T, itemsIndex: number) => R
): R[] =>
  items.reduce<R[]>((acc, item, i) => {
    if (!filterFunc(item, i)) return acc;
    acc.push(mapFunc(item, i));
    return acc;
  }, []);

const TransitionList = ({ currentState, dispatch, state }: Props) => {
  const { states, transitions } = state;

  const filter = React.useCallback(
    (t: Transition) => {
      return t.fromStateKey === currentState.key;
    },
    [currentState.key]
  );

  const onDelete = React.useCallback(
    (transition: Transition) => {
      return dispatch({
        type: ReducerActions.DELETE_TRANSITION,
        payload: transition.key
      });
    },
    [dispatch]
  );

  const toggleExpand = React.useCallback(
    (key: string) => {
      dispatch({ type: ReducerActions.TOGGLE_TRANSITION_EXPANDED, payload: key });
    },
    [dispatch]
  );

  const transitionList = filterMap(transitions, filter, (t, i) => {
    const expanded = !!t.expanded;

    return (
      <styled.Panel key={t.key}>
        <common.CollapsableHeader onClick={() => toggleExpand(t.key)}>
          <div>
            <RightDownChevron expanded={expanded} />
            <common.CollapsableTitle>
              {t.name || "Untitled CTA"}
            </common.CollapsableTitle>
          </div>
          <styled.ButtonBar>
            <IconButton
              icon="delete"
              title="Delete transition"
              onClick={event => {
                event.preventDefault();
                onDelete(t);
              }}
            />
          </styled.ButtonBar>
        </common.CollapsableHeader>
        <Expandable expanded={expanded}>
          <styled.Content>
            <TransitionComponent
              currentState={currentState}
              dispatch={dispatch}
              states={states}
              transition={t}
            />
            <styled.Divider />
            <styled.Title>Actions</styled.Title>
            <styled.Description>
              When clicked, automatically perform these actions
            </styled.Description>
            <ActionList
              event={Event.BEFORE_EXECUTE}
              dispatcher={t}
              dispatcherIndex={i}
              dispatcherType={DispatcherType.TRANSITION}
              state={state}
              dispatch={dispatch}
              actionTypes={[
                ActionType.CHANGE_ASSIGNEE,
                ActionType.EXECUTE_FUNCTION,
                ActionType.OPEN_URL,
                ActionType.UPDATE_TASK
              ]}
            />
          </styled.Content>
        </Expandable>
      </styled.Panel>
    );
  });

  return (
    <styled.Container>
      {!transitionList.length && (
        <common.EmptyContainer>
          <common.EmptyTitle>No call-to-actions yet</common.EmptyTitle>
          <common.EmptySubtitle>Click New CTA to get started</common.EmptySubtitle>
        </common.EmptyContainer>
      )}
      {transitionList.length !== 0 && (
        <styled.SpacedContainer>{transitionList}</styled.SpacedContainer>
      )}
    </styled.Container>
  );
};
export default TransitionList;
