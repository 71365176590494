import React from "react";

import { Form, Input } from "antd";
import { GetFieldDecoratorOptions } from "antd/lib/form/Form";

interface Props {
  createMode: boolean;
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;

  clientId?: string;
  clientSecret?: string;
  authUri?: string;
  tokenUri?: string;
  scopes?: string[];
}

export const OAuthInputFields = ({
  getFieldDecorator,
  createMode,
  clientId,
  clientSecret,
  authUri,
  tokenUri,
  scopes
}: Props) => {
  let displayScopes = "";
  if (scopes) {
    displayScopes = scopes.join(" ");
  }
  return (
    <>
      <Form.Item label="Redirect URL">
        <span>{`${window.location.protocol}//${window.location.host}/api/authorization-flow/oauth2/callback`}</span>
      </Form.Item>
      <Form.Item label="Client Id">
        {getFieldDecorator("clientId", {
          initialValue: clientId,
          rules: [{ required: createMode, message: "Client Id is required." }]
        })(<Input placeholder="Client Id" />)}
      </Form.Item>
      <Form.Item label="Client Secret">
        {getFieldDecorator("clientSecret", {
          initialValue: clientSecret,
          rules: [{ required: createMode, message: "Client Secret is required." }]
        })(<Input placeholder="Client Secret" />)}
      </Form.Item>
      <Form.Item label="Authorization URL">
        {getFieldDecorator("authUri", {
          initialValue: authUri,
          rules: [{ required: createMode, message: "Authorization URL is required." }]
        })(<Input placeholder="Authorization URL" />)}
      </Form.Item>
      <Form.Item label="Authorization Token URL">
        {getFieldDecorator("tokenUri", {
          initialValue: tokenUri,
          rules: [
            {
              required: createMode,
              message: "Authorization Token URL is required."
            }
          ]
        })(<Input placeholder="Authorization Token URL" />)}
      </Form.Item>
      <Form.Item label="Scopes">
        {getFieldDecorator("scopes", {
          initialValue: displayScopes
        })(<Input placeholder="scope1 scope2 scope3..." />)}
      </Form.Item>
    </>
  );
};
