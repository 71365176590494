import React from "react";

import { useComponentConfigContext } from "../../../common/ComponentConfigContext";
import FilterListManagerSection from "../../../common/FilterListManager/FilterListManagerSection";
import { ensureCardListConfigState } from "../reducer";

interface Props {
  title: string;
}

export default function FiltersConfigSection({ title }: Props) {
  const { state: genericState } = useComponentConfigContext();
  const state = ensureCardListConfigState(genericState);
  const { draftComponent, filtersOptions } = state;
  const { columns } = draftComponent.properties;

  if (!columns) return null;

  return <FilterListManagerSection title={title} filtersOptions={filtersOptions} />;
}
