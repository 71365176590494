import React, { createContext, useMemo } from "react";

const LayoutDelegationContext = createContext({
  isDelegated: false
});

export default function LayoutDelegator({
  isDelegated,
  children
}: {
  isDelegated: boolean;
  children: React.ReactNode;
}) {
  const value = useMemo(() => ({ isDelegated }), [isDelegated]);
  return (
    <LayoutDelegationContext.Provider value={value}>
      {children}
    </LayoutDelegationContext.Provider>
  );
}

export const useLayoutDelegationContext = () =>
  React.useContext(LayoutDelegationContext);
