import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import { selectColumnErrors } from "../../common/ColumnListManager";
import selectFiltersErrors from "../../common/FilterListManager/selectErrors";
import selectParameterErrors from "../../common/ParametersManager/selectors/selectErrors";
import { selectViewConfigErrors } from "../../common/useViewConfig";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

import { ensureTableConfigState } from "./reducer";

export default function errorSelector(
  state: ComponentConfigState,
  findInvalidInputBindings: (component: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { filterValidityTrackers, attributes, draftComponent } =
    ensureTableConfigState(state);

  return errors.concat(
    selectFiltersErrors(
      filterValidityTrackers,
      draftComponent,
      findInvalidInputBindings
    )
      .concat(
        selectColumnErrors(
          draftComponent,
          findInvalidInputBindings,
          "COLUMNS",
          attributes
        )
      )
      .concat(selectVisibilityRuleErrors(draftComponent))
      .concat(selectViewConfigErrors(draftComponent))
      .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings))
  );
}
