import React from "react";

import { Input } from "antd";
import moment from "moment";
import momentTz from "moment-timezone";
import ReactDOM from "react-dom";
import { HotKeys } from "react-hotkeys";
import { Manager, Reference } from "react-popper";
import styled from "styled-components";

import Popper from "../../common/Popper";
import { PopperArrow, PopperContainer } from "../StyledComponents";

import { PopoverPickerContainer } from "./styledComponents";
import { PORTAL_QUERY_SELECTOR, getPopperValue, MomentPopperProps } from "./utils";

const Root = styled.div<{ hasError: boolean }>`
  ${props =>
    props.hasError &&
    `
    .ant-input,
    .ant-input:focus,
    .ant-input:hover {
      border-color: ${props.theme.errorColor};
      box-shadow: none;
    }
  `}
`;
export interface MomentPopperInputProps {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  displayFormat: string;
  saveFormat: string;
  PopoverPicker: React.ComponentType<MomentPopperProps>;
  onChange: (value: string) => void;
  onFocus?: (evt: Event) => void;
  onBlur?: (evt: Event) => void;
  hasError?: boolean;
}

// Popover for selecting date and time, adapted MomentFormItemPopper but this version is decoupled from antd forms
const MomentPopperInput = React.forwardRef(
  (props: MomentPopperInputProps, formItemRef) => {
    const {
      value,
      placeholder,
      disabled,
      displayFormat,
      saveFormat,
      PopoverPicker,
      onChange,
      hasError,
      onFocus = () => {},
      onBlur = () => {}
    } = props;

    const [visible, setVisible] = React.useState<boolean>(false);
    const [timeZone, setTimeZone] = React.useState<string | null>(null);
    const onCancel = () => {
      setVisible(false);
      onBlur(new Event("focus"));
    };

    const onKeyHandler = (e: React.KeyboardEvent) => {
      if (e.keyCode === 27 && visible) {
        e.stopPropagation();
        onCancel();
      }
    };

    // used when focus is in the popover
    const keyHandlers = {
      ESCAPE: onCancel
    };

    const keyHandlerProps = {
      onKeyUp: onKeyHandler,
      onKeyDown: onKeyHandler,
      onKeyPress: onKeyHandler
    };

    const isTimestamp = props.saveFormat.includes("Z");
    const nowMoment = isTimestamp
      ? momentTz().tz(timeZone || momentTz.tz.guess())
      : moment();
    const onMomentChange = React.useCallback(
      (m: moment.Moment) => {
        isTimestamp
          ? onChange(m.utc().format(saveFormat))
          : onChange(m.format(saveFormat));
      },
      [isTimestamp, saveFormat, onChange]
    );

    const popperValue = getPopperValue(isTimestamp, value, saveFormat, timeZone);
    const isValidValue = popperValue.isValid();

    return (
      <HotKeys handlers={keyHandlers} attach={window} focused={true}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <Root ref={ref} {...keyHandlerProps} hasError={!!hasError}>
                <Input
                  ref={formItemRef as any}
                  placeholder={placeholder}
                  value={isValidValue ? popperValue.format(displayFormat) : undefined}
                  disabled={disabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const m = moment(e.target.value, displayFormat);
                    if (m.isValid()) {
                      onMomentChange(m);
                    } else {
                      onChange("");
                    }
                  }}
                  onClick={() => {
                    setVisible(true);
                    onFocus(new Event("focus"));
                    if (!value) {
                      onMomentChange(nowMoment);
                    }
                  }}
                  onFocus={() => onFocus(new Event("focus"))}
                />
              </Root>
            )}
          </Reference>
          {visible &&
            ReactDOM.createPortal(
              <Popper
                maskClosable
                onCancel={onCancel}
                placement="top-start"
                modifiers={[
                  {
                    name: "preventOverflow",
                    enabled: false
                  }
                ]}
              >
                {({ ref, style, placement, arrowProps }) => (
                  <PopperContainer ref={ref} style={style} data-placement={placement}>
                    <PopoverPickerContainer>
                      <PopoverPicker
                        setTimeZone={(p: string) => setTimeZone(p)}
                        value={isValidValue ? popperValue : nowMoment}
                        onChange={onMomentChange}
                      />
                    </PopoverPickerContainer>
                    <PopperArrow
                      ref={arrowProps.ref}
                      style={arrowProps.style}
                      data-placement={placement}
                    />
                  </PopperContainer>
                )}
              </Popper>,
              document.querySelector(PORTAL_QUERY_SELECTOR) as Element
            )}
        </Manager>
      </HotKeys>
    );
  }
);
MomentPopperInput.displayName = "MomentPopperInput";
export default MomentPopperInput;
