import styled from "styled-components";

import { Main } from "../../../common/layout/styledComponents";

import BaseGettingStarted from "./GettingStarted/GettingStarted";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 48px 33px;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
`;

export const List = styled.ul`
  width: 600px;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
`;

export const Links = styled.div`
  display: flex;
`;

export const GettingStartedContainer = styled(Main)`
  padding: 0;
  overflow: initial;
`;

export const GettingStarted = styled(BaseGettingStarted)`
  padding: 48px 32px 24px;
`;
