import { Alert, Button, Button as AntButton } from "antd";
import styled from "styled-components";

export const PreviewContent = styled.div`
  display: flex;
  height: 100%;
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: ${props => props.theme.spacermd};
`;

export const RunButton = styled(AntButton)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Instructions = styled.div`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const Reminder = styled.div`
  border: 1px solid ${props => props.theme.tableBorderColor};
  border-radius: ${props => props.theme.borderRadiusmd};
  margin-top ${props => props.theme.spacermd};
  padding: 12px;
`;

export const LinkButton = styled(Button)`
  padding: 0;
`;

export const PreviewFormPane = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow-y: auto;
  height: 100%;
  padding: ${props => props.theme.spacermd};
`;

export const StyledAlert = styled(Alert)`
  margin-top: ${props =>
    props.theme.spacerlg}; /* add margin top for spacing between components */
  width: 100%;
`;

export const TempContainer = styled.div`
  height: 100%;
  display: flex;
`;
