import React from "react";

import { Tooltip, Button, Icon } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`
  padding: 0;

  &.ant-btn-link {
    color: ${props => props.theme.iconButtonColor};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.iconButtonColor};
    }

    &:enabled:hover,
    &:enabled:focus-visible, /* keyboard only focus */
    &:enabled:active {
      color: ${props => props.theme.iconButtonColor};
    }
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    color: ${props => props.theme.disabledLabelColor};
    pointer-events: none;
  }
`;

interface Props {
  className?: string;
  title?: string;
}

// Info icon that renders tooltip on hover and focus
export const InfoIconButton = ({ className = "", title = "" }: Props) => (
  <Tooltip
    title={title}
    trigger="focus"
    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
  >
    {/* workaround for our version of antd, where we cannot specify two trigger types */}
    <Tooltip
      title={title}
      trigger="hover"
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
    >
      <StyledButton type="link" className={className}>
        <Icon type="question-circle" />
      </StyledButton>
    </Tooltip>
  </Tooltip>
);
