import React from "react";

import { throttle } from "lodash";

export default function useMouseCoords() {
  const [mouseCoords, setMouseCoords] = React.useState({
    client: new DOMPoint(),
    page: new DOMPoint()
  });

  const throttledHandleMouseMove = React.useRef(
    throttle(
      (evt: MouseEvent) => {
        setMouseCoords({
          client: new DOMPoint(evt.clientX, evt.clientY),
          page: new DOMPoint(evt.pageX, evt.pageY)
        });
      },
      25,
      { leading: false }
    )
  );

  React.useEffect(() => {
    const cb = throttledHandleMouseMove.current;
    window.addEventListener("mousemove", cb);
    return () => {
      window.removeEventListener("mousemove", cb);
    };
  }, []);

  return mouseCoords;
}
