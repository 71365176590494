import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";

import ConfirmationModal from "../../../../common/ConfirmationModal";
import Message from "../../../../common/Message";
import { DataSource } from "../../../DataSourceTable";

import { DELETE_DATA_SOURCE_MUTATION, DeleteResult, DeleteVars } from "./queries";

interface Props {
  className?: string;
  dataSource: DataSource;
  buttonProps: Partial<ButtonProps>;
  onDelete: (dataSource: DataSource) => void;
}

export default function DeleteDataSourceButton({
  dataSource,
  buttonProps,
  className,
  onDelete
}: Props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const onCompleted = (data: DeleteResult) => {
    const { ok, message } = data.deleteDataSource;
    if (ok) {
      onDelete(dataSource);
    } else {
      Message.error(message);
    }
  };

  const [destroy, { loading }] = useMutation<DeleteResult, DeleteVars>(
    DELETE_DATA_SOURCE_MUTATION,
    { onCompleted }
  );

  const handleSubmit = (confirm: string) => {
    const variables = {
      dataSourceId: dataSource.id,
      confirm
    };
    return destroy({
      variables,
      refetchQueries: ["FetchDataSources"]
    });
  };

  return (
    <>
      {modalOpen && (
        <ConfirmationModal
          visible
          onClose={() => setModalOpen(false)}
          modalOkText="Delete"
          title="Delete this data source"
          confirmationValueIsValid={(p: string) => p === dataSource.name}
          onSubmit={(confirm: string) => {
            handleSubmit(confirm);
            setModalOpen(false);
          }}
          okButtonProps={{ loading, type: "danger" }}
          modalContent={
            <>
              <p>
                This action <strong>cannot</strong> be undone. This will permanently
                delete the <strong>{dataSource.name}</strong> data source and all of its
                functions. Spaces that use this data source will need to be fixed
                manually.
              </p>

              <p>
                Please type <strong>{dataSource.name}</strong> to confirm.
              </p>
            </>
          }
        />
      )}
      <Button
        className={className || ""}
        onClick={() => setModalOpen(true)}
        {...buttonProps}
      >
        Delete this data source
      </Button>
    </>
  );
}
