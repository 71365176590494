import { get, set } from "lodash";

import { DataValue } from "../../../../../types";
import { createPath, parsePath } from "../../../../util/binding";

// Returns a nested shallow copy of `existingState` setting `data` at the key
// defined by `path`. Finds any object path conflicts giving the injected `data`
// precedence. Notably handles removing "dotted" keys (e.g. "a.b.c") in the
// `existingState` if they would conflict with `path`.
export default function injectState(
  existingState: Record<string, DataValue> | null = {},
  path: string,
  data: DataValue
) {
  const pathParts = parsePath(path);
  const result = { ...existingState };

  const processedPath = [];
  const remainingPath = [...pathParts];
  for (let idx = 0; idx < pathParts.length; idx++) {
    // Clean any conflicting paths so that the injected data has precedence
    const currentNode = processedPath.length ? get(result, processedPath, {}) : result;
    const remainingPathStr = createPath(remainingPath);
    delete currentNode[remainingPathStr];
    Object.keys(currentNode).forEach(key => {
      if (key === remainingPathStr || key.startsWith(`${remainingPathStr}.`)) {
        delete currentNode[key];
      }
    });
    processedPath.push(pathParts[idx]);
    remainingPath.shift();
    if (idx === pathParts.length - 1) {
      set(result, processedPath, data);
    } else {
      set(result, processedPath, {
        ...currentNode[pathParts[idx]]
      });
    }
  }
  return result;
}
