/**
 * CodeMirror text input field meant for single-line entries,
 * e.g. the URL param fields in the HTTP function editor.
 *
 * For multi-line code input, use `Editor`.
 */
import React, { forwardRef, useMemo } from "react";

import { Popover } from "antd";
import * as codemirror from "codemirror";
import { Controlled as CodeMirror } from "react-codemirror2";
import { ThemeContext } from "styled-components";

import { tryError } from "../../util";
import { quasi, unquasi } from "../../util/javascript";

import * as styled from "./styledComponents";
import { detectMode, memoParse } from "./util";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/tomorrow-night-bright.css";

import "./mode/jstl";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/edit/closebrackets";

export type Mode = "javascript" | "jstl";

export interface Props {
  className?: string;
  value: string;
  placeholder?: string;
  mode?: Mode;
  minHeight?: string;
  disabled?: boolean;
  hidePopover?: boolean;
  showError?: boolean;
  togglable?: boolean;
  onChange?: (value: string) => void;
  onFocus?: (evt: any) => void;
  onBlur?: (evt: any) => void;
}

const SingleLineEditor = forwardRef(
  (
    {
      className,
      value,
      placeholder,
      minHeight,
      mode: modeProperty,
      disabled = false,
      hidePopover = false,
      showError = false,
      togglable = false,
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {}
    }: Props,
    ref: React.Ref<CodeMirror>
  ) => {
    const [mode, setMode] = React.useState<Mode>(
      () => modeProperty || detectMode(value)
    );
    const theme = React.useContext(ThemeContext) || {};
    const dispatchChange = React.useCallback(
      (m: Mode, text: string) => onChange(m === "jstl" ? quasi(text) : text),
      [onChange]
    );
    const editorTheme =
      theme.editorStyle === "dark" ? "tomorrow-night-bright" : "default";

    React.useEffect(() => {
      if (!modeProperty) return;
      setMode(modeProperty);
    }, [modeProperty]);

    const handleChange = React.useCallback(
      (editor: codemirror.Editor, data: codemirror.EditorChange, text: string) =>
        dispatchChange(mode, text),
      [mode, dispatchChange]
    );

    const handleToggleMode = () => {
      const nextMode: Mode = mode === "jstl" ? "javascript" : "jstl";
      setMode(nextMode);
      dispatchChange(nextMode, value);
    };

    let error = "";
    try {
      memoParse(value);
    } catch (e) {
      const err = tryError(e);
      error = err.message;
    }

    const popover = (
      <div>
        <styled.Mode>{mode === "jstl" ? "template" : "javascript"}</styled.Mode>
        <styled.Code isValid={!error}>= {value}</styled.Code>
        {error && <styled.ErrorMessage>{error}</styled.ErrorMessage>}
      </div>
    );

    const options = useMemo((): codemirror.EditorConfiguration => {
      return {
        mode,
        placeholder,
        theme: editorTheme,
        readOnly: disabled ? "nocursor" : false,
        lineWrapping: true,
        autoCloseBrackets: true,
        extraKeys: { Tab: false, "Shift-Tab": false }
      };
    }, [mode, placeholder, editorTheme, disabled]);

    const editor = (
      <CodeMirror
        ref={ref}
        className={togglable ? "togglable" : undefined}
        value={mode === "jstl" ? unquasi(value) : value}
        onBeforeChange={handleChange}
        options={options}
        // below onFocus and onBlur handlers are needed if this
        // component is wrapped with `withDebouncedValue`
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );

    const classNames = disabled ? [className, "disabled"] : [className];

    const container = hidePopover ? (
      <styled.EditorContainer minHeight={minHeight}>
        {editor}
        {showError && error && <styled.ErrorMessage>{error}</styled.ErrorMessage>}
      </styled.EditorContainer>
    ) : (
      <styled.EditorContainer minHeight={minHeight}>
        <Popover
          placement="bottomLeft"
          content={popover}
          trigger="focus"
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          {/* Wrap in a div to separate popover events from editor events */}
          <div>{editor}</div>
        </Popover>
      </styled.EditorContainer>
    );

    return (
      <styled.Container className={classNames.join(" ")}>
        {container}
        {togglable && (
          <styled.ToggleButton
            isSelected={mode === "javascript"}
            onMouseDown={e => {
              e.preventDefault(); // maintain editor focus if editing when clicked
              handleToggleMode();
            }}
          >
            JS
          </styled.ToggleButton>
        )}
      </styled.Container>
    );
  }
);
SingleLineEditor.displayName = "SingleLineEditor";

export default SingleLineEditor;
