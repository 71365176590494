import React, { ReactNode, MouseEvent } from "react";

import { Button as AntButton, Icon } from "antd";
import styled from "styled-components";

const StyledButton = styled(AntButton)`
  &.medium {
    font-size: ${props => props.theme.mediumFontSize};
    height: 40px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: ${props => props.theme.spacersm};
  }
`;

export interface Props {
  size?: "medium";
  children?: ReactNode;
  type?: "primary" | "link" | "dashed" | "danger" | "default";
  htmlType?: "submit";
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: {};
  fixedWidthLoadingState?: boolean;
  icon?: string;
  form?: string;
  onClick?: (e?: MouseEvent) => void;
}

const Button = ({
  size,
  children,
  className,
  fixedWidthLoadingState = false,
  loading,
  icon,
  onClick,
  ...rest
}: Props) => {
  const [width, setWidth] = React.useState(null);
  const [wasLoading, setWasLoading] = React.useState(false);
  const sizerRef = React.useRef<any>(null);
  React.useLayoutEffect(() => {
    if (!fixedWidthLoadingState || wasLoading === loading) {
      return;
    }
    if (wasLoading && !loading) {
      setWidth(null);
      setWasLoading(false);
      return;
    }
    if (!wasLoading && loading) {
      setWidth(sizerRef.current.getBoundingClientRect().width);
      setWasLoading(true);
      return;
    }
  }, [loading, wasLoading, fixedWidthLoadingState]);

  let buttonClass = `${className} ` || "";
  if (size) {
    buttonClass += `${size}`;
  }
  const disabled = rest.disabled || loading;
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (disabled || typeof onClick !== "function") return;
    onClick(e);
  };

  return (
    <StyledButton
      className={buttonClass}
      loading={loading && !fixedWidthLoadingState}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    >
      {fixedWidthLoadingState ? (
        loading && width ? (
          <FlexContainer style={width ? { width: `${width}px` } : {}}>
            <Icon type="loading" />
          </FlexContainer>
        ) : (
          <FlexContainer
            ref={sizerRef as any}
            style={width ? { width: `${width}px` } : {}}
          >
            {icon && <Icon type={icon} />}
            {children && <span>{children}</span>}
          </FlexContainer>
        )
      ) : loading ? (
        children
      ) : (
        <FlexContainer>
          {icon && <Icon type={icon} />}
          {children && <span>{children}</span>}
        </FlexContainer>
      )}
    </StyledButton>
  );
};

export default Button;
