import React from "react";

import { usePopper } from "react-popper";

import { B3 } from "../../../../common/StyledComponents";
import { StateNode } from "../useQueueClient/useQueue/queries/QueueBySlug";

import { StateFilterDropdown } from "./StateFilterDropdown/StateFilterDropdown";
import * as Styled from "./styledComponents";

interface Props {
  states: StateNode[];
  selectedStateIds: string[];
  setSelectedStateIds: (ids: string[]) => void;
}

function selectedStatesToTitle(
  states: StateNode[],
  selectedStateIds: string[]
): string {
  if (states.length === selectedStateIds.length) {
    return "All tasks";
  }

  const archiveStates = states.filter(s => s.isArchive);
  const allArchivedStatesSelected = archiveStates
    .map(s => s.id)
    .every(id => selectedStateIds.includes(id));

  if (allArchivedStatesSelected && archiveStates.length === selectedStateIds.length) {
    return "All archived tasks";
  }

  const openStates = states.filter(s => !s.isArchive);
  const allOpenStatesSelected = openStates
    .map(s => s.id)
    .every(id => selectedStateIds.includes(id));

  if (allOpenStatesSelected && openStates.length === selectedStateIds.length) {
    return "All open tasks";
  }

  return `State (${selectedStateIds.length})`;
}

export function StateFilter({ states, selectedStateIds, setSelectedStateIds }: Props) {
  const title = React.useMemo(() => {
    return selectedStatesToTitle(states, selectedStateIds);
  }, [states, selectedStateIds]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);

  const modifiers = React.useMemo(() => {
    return [
      {
        name: "offset",
        options: {
          offset: [0, 10]
        }
      },
      {
        name: "matchWidth",
        enabled: true,
        fn: ({ state }: any) => {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        phase: "beforeWrite" as const,
        requires: ["computeStyles"],
        effect({ state }: any) {
          state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
        }
      }
    ];
  }, []);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers
  });

  return (
    <>
      <Styled.StateFilter
        ref={setReferenceElement}
        focused={isOpen}
        onClick={() => setIsOpen(true)}
      >
        <B3>{title}</B3>
        <Styled.ChevronDown />
      </Styled.StateFilter>
      {isOpen && (
        <>
          <Styled.Mask onClick={() => setIsOpen(false)} />
          <Styled.Menu
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <StateFilterDropdown
              states={states}
              selectedStateIds={selectedStateIds}
              setSelectedStateIds={setSelectedStateIds}
            />
          </Styled.Menu>
        </>
      )}
    </>
  );
}
