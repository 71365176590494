import React from "react";

import { AttributeTypes, ErrorValues, ErrorValue } from "../../../../../constants";
import { FileObject } from "../../../../../types";
import FileReaderInput from "../../../../common/FileReaderInput";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDefaultValue from "../common/useDefaultValue";
import useOutputSyncing from "../common/useOutputSyncing";
import { toAttributeType } from "../common/util";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

type ValidFileType = FileObject | null | undefined;

export default function SpaceFilePicker({ spaceComponent, hasConfigError }: Props) {
  const { editMode } = useStableSpaceContext();
  const { output } = useComponentStateContext();
  const { defaultValue } = useDefaultValue();
  const { validation_type } = spaceComponent.properties;
  const serializeValueAsBinary =
    toAttributeType(validation_type) === AttributeTypes.BINARY;
  const [currentFile, setCurrentFile] = React.useState<ValidFileType | ErrorValue>();

  const hasPermissionError = React.useMemo(() => {
    return currentFile === ErrorValues.permissionDenied;
  }, [currentFile]);

  const fileFromData = (data?: string): FileObject | null =>
    data ? { size: 0, name: "(bound data)", data, type: "unknown" } : null;

  const setCurrentFileToDefault = React.useCallback(() => {
    if (typeof defaultValue === "string") {
      setCurrentFile(fileFromData(defaultValue));
    } else {
      setCurrentFile(defaultValue);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    setCurrentFileToDefault();
  }, [setCurrentFileToDefault]);

  // init output when output null
  React.useEffect(() => {
    if (output !== null) return;
    setCurrentFileToDefault();
  }, [output, setCurrentFileToDefault]);

  const onChange = React.useCallback((result: FileObject | null) => {
    setCurrentFile(result);
  }, []);

  const outputValue = React.useMemo(() => {
    if (hasPermissionError) {
      return currentFile;
    }
    if (serializeValueAsBinary) {
      return currentFile === null ? null : (currentFile as FileObject)?.data;
    }
    return currentFile;
  }, [currentFile, serializeValueAsBinary, hasPermissionError]);

  const { errorMessage } = useOutputSyncing(outputValue);

  return (
    <EditModeInteractionBlocker>
      <FlexContainer>
        {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
        <FileReaderInput
          onChange={onChange}
          value={hasPermissionError ? null : (currentFile as ValidFileType)}
        />
        {hasPermissionError && <PermissionWarningIcon />}
      </FlexContainer>
      {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
    </EditModeInteractionBlocker>
  );
}
