import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectParameterErrors from "../../common/ParametersManager/selectors/selectErrors";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";
// TODO: Stop sharing selectErrors with SpaceDetail
import spaceDetailSelectErrors from "../../SpaceDetail/Config/errorSelector";

export default function errorSelector(
  state: ComponentConfigState,
  findInvalidInputBindings: (component: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const { draftComponent } = state;
  let errors = spaceDetailSelectErrors(state, findInvalidInputBindings);
  errors = errors
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings));
  return errors;
}
