import React from "react";

import { Icon, Input } from "antd";
import styled from "styled-components";

import { TaskFilters } from "..";
import { colors } from "../../../../cssConstants";
import { SvgIconButton } from "../../../common/IconButton";
import { H4 } from "../../../common/StyledComponents";

import { StateFilter } from "./StateFilter/StateFilter";
import { Option as StateOption } from "./StateSelect/StateSelect";
import { StateTabSelect } from "./StateSelect/StateTabSelect";
import { StateNode } from "./useQueueClient/useQueue/queries/QueueBySlug";
import { TasksPageInfo } from "./useQueueClient/useQueueClient";

interface QueueHeaderProps {
  title: string;
  tabs: StateOption[];
  activeTab: string | null;
  onTabSelected: (state: StateOption) => void;
  controls: React.ReactNode;
  pageInfo: TasksPageInfo;
  search?: string;
  states?: StateNode[];
  taskFilters?: Partial<TaskFilters>;
  beforeTaskCount?: number;
  totalTaskCount?: number;
  visibleTaskCount: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  setSearch: (query?: string) => void;
  setTaskFilters?: (filter: Partial<TaskFilters>) => void;
}

const SearchInput = styled(Input)`
  height: 36px;
  input {
    border: 1px solid ${colors.dropdownBorderColor};
  }
`;

const QueueHeaderRoot = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: 1fr 1fr;
  column-gap: 24px;
  row-gap: 28px;
  height: 168px;
  width: 100%;
  padding: 36px 42px 20px 42px;
  flex-shrink: 0;
  background: ${props => props.theme.containerPrimary};
`;

const Pagination = styled.div`
  align-self: baseline;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const Controls = styled.div`
  align-self: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const StyledH4 = styled(H4)`
  align-self: baseline;
`;

const PaginationText = styled.div`
  color: ${colors.surfaceSecondary};
  font-size: 13px;
`;

function PaginationHeader({
  beforeTaskCount,
  totalCount,
  visibleTaskCount
}: {
  beforeTaskCount?: number;
  totalCount?: number;
  visibleTaskCount: number;
}) {
  const [message, setMessage] = React.useState("0 tasks");
  if (totalCount !== undefined && beforeTaskCount !== undefined) {
    let newMessage = "0 tasks";
    if (totalCount !== 0) {
      const start = beforeTaskCount + 1;
      const end = start + visibleTaskCount - 1;
      newMessage = `${start} - ${end} of ${totalCount} tasks`;
    }

    if (newMessage !== message) {
      setMessage(newMessage);
    }
  }

  return <PaginationText>{message}</PaginationText>;
}

export default function QueueHeader({
  activeTab,
  controls,
  tabs,
  title,
  pageInfo,
  search,
  states,
  taskFilters,
  beforeTaskCount,
  totalTaskCount,
  visibleTaskCount,
  onNextPage,
  onPreviousPage,
  onTabSelected,
  setSearch,
  setTaskFilters
}: QueueHeaderProps) {
  const selectedTab = tabs.find(t => t.value === activeTab);
  const updateSearch = React.useCallback(
    (value: string) => {
      setSearch(value.length === 0 ? undefined : value);
    },
    [setSearch]
  );

  return (
    <QueueHeaderRoot>
      <StyledH4>{title}</StyledH4>
      {pageInfo && (
        <Pagination>
          <PaginationHeader
            beforeTaskCount={beforeTaskCount}
            visibleTaskCount={visibleTaskCount}
            totalCount={totalTaskCount}
          />
          <SvgIconButton
            icon="left"
            disabled={!pageInfo.hasPreviousPage}
            onClick={() => onPreviousPage()}
          />
          <SvgIconButton
            icon="right"
            disabled={!pageInfo.hasNextPage}
            onClick={() => onNextPage()}
          />
        </Pagination>
      )}
      {states && search?.length ? (
        <StateFilter
          states={states}
          selectedStateIds={taskFilters?.stateIdIn || []}
          setSelectedStateIds={ids => setTaskFilters?.({ stateIdIn: ids })}
        />
      ) : (
        <StateTabSelect options={tabs} value={selectedTab} onSelected={onTabSelected} />
      )}
      <Controls>
        <SearchInput
          placeholder="Search all tasks"
          value={search}
          allowClear
          prefix={<Icon type="search" />}
          onChange={e => updateSearch(e.currentTarget.value)}
          style={{ width: 200 }}
        />
        {controls && <>{controls}</>}
      </Controls>
    </QueueHeaderRoot>
  );
}
