/**
 * Editor component for HTTP code input.
 * --
 * See FunctionEditor for UI notes.
 */
import React from "react";

import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

import Editor from "../../../Editor";
import { TemplateStringInput } from "../../../JavaScriptExpression/TemplateStringInput";
import { HttpCode, HttpFunctionMetadata } from "../../index";
import { RequestBodyType } from "../constants";
import * as common from "../styledComponents";

import { GraphQL, Form } from "./bodies";

interface Props {
  metadata: HttpFunctionMetadata;
  graphqlQuery: string;
  graphqlVariables: string;
  body: string;
  templatePopoverContent?: React.ReactNode;
  onRequestTypeChange: (e: RadioChangeEvent) => void;
  onGraphqlQueryChange: (value: string, event?: any) => void;
  onGraphqlVariablesChange: (value: string, event?: any) => void;
  onBodyChange: (serialized: string, code?: HttpCode) => void;
}

const HttpBodyField = (props: Props) => {
  const {
    metadata,
    graphqlQuery,
    graphqlVariables,
    body,
    templatePopoverContent,
    onRequestTypeChange,
    onGraphqlQueryChange,
    onGraphqlVariablesChange,
    onBodyChange
  } = props;
  return (
    <>
      <common.RadioGroup
        data-test="requestTypeRadio"
        onChange={onRequestTypeChange}
        value={metadata.request_type}
      >
        <Radio value={RequestBodyType.None}>None</Radio>
        <Radio value={RequestBodyType.Form}>Form</Radio>
        <Radio value={RequestBodyType.JSON}>JSON</Radio>
        <Radio value={RequestBodyType.GraphQL}>GraphQL</Radio>
        <Radio value={RequestBodyType.Binary}>Binary</Radio>
        <Radio value={RequestBodyType.Raw}>Plain Text</Radio>
      </common.RadioGroup>
      {metadata.request_type === RequestBodyType.GraphQL && (
        <GraphQL
          query={graphqlQuery}
          variables={graphqlVariables}
          onQueryChange={onGraphqlQueryChange}
          onVariablesChange={onGraphqlVariablesChange}
        />
      )}
      {metadata.request_type === RequestBodyType.Form && (
        <Form code={metadata.code} onChange={onBodyChange} />
      )}
      {[RequestBodyType.JSON, RequestBodyType.Binary].includes(
        metadata.request_type
      ) && <Editor mode="javascript" value={body} onChange={onBodyChange} />}
      {metadata.request_type === RequestBodyType.Raw && (
        <TemplateStringInput
          value={body}
          onValueChange={onBodyChange}
          useEditor={true}
          popoverInnerContent={templatePopoverContent}
        />
      )}
    </>
  );
};

export default HttpBodyField;
