import React from "react";

import { isEqual } from "lodash";
import styled from "styled-components";

import { APIFiltersOption, ViewFilter } from "../../../../../../types";
import * as common from "../../../common/styledComponents";

import { filter } from "./util";
import { ViewFilterInput } from "./ViewFilterInput/ViewFilterInput";

export interface Props {
  filtersOptions: APIFiltersOption[];
  viewFilters: Partial<ViewFilter>[];
  onViewFiltersChange?: (viewFilters: Partial<ViewFilter>[]) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddButton = styled(common.IconButton)`
  align-self: flex-start;
`;

export const ViewFiltersForm = ({
  filtersOptions,
  viewFilters,
  onViewFiltersChange = () => {}
}: Props) => {
  const onAdd = () => {
    const copy: Partial<ViewFilter>[] = [...viewFilters];
    copy.push({});
    onViewFiltersChange(copy);
  };

  const onChange = (idx: number, viewFilter: Partial<ViewFilter>) => {
    const copy = [...viewFilters];
    copy[idx] = viewFilter;
    onViewFiltersChange(copy);
  };

  const onRemove = (idx: number) => {
    const copy = [...viewFilters];
    copy.splice(idx, 1);
    onViewFiltersChange(copy);
  };

  React.useEffect(() => {
    const validFilters = viewFilters.filter(f => {
      // validate that a matching filter option is present **or** it's a pending filter
      return (
        filtersOptions.find(o => o.sourceName === f.sourceName) ||
        f.sourceName === undefined
      );
    });
    if (!isEqual(validFilters, viewFilters)) {
      onViewFiltersChange(validFilters);
    }
  }, [viewFilters, filtersOptions, onViewFiltersChange]);

  return (
    <Container>
      {viewFilters.map((f, i) => (
        <ViewFilterInput
          key={i}
          filtersOptions={filter(f, viewFilters, filtersOptions)}
          viewFilter={f}
          onChange={f => onChange(i, f)}
          onRemove={_ => onRemove(i)}
        />
      ))}
      <AddButton onClick={onAdd} type="link" icon="plus" title="Add Filter">
        Add Filter
      </AddButton>
    </Container>
  );
};
