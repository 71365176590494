import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

import {
  CloneFunctionData,
  CloneFunctionVariables,
  CLONE_FUNCTION
} from "../../FunctionEditor/useFunctionEditor/queries";

const useCloneFunction = (
  options?: MutationHookOptions<CloneFunctionData, CloneFunctionVariables>
) =>
  useMutation<CloneFunctionData, CloneFunctionVariables>(CLONE_FUNCTION, options || {});

export default useCloneFunction;
