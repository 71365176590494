import React, { useState } from "react";

import { Button, Icon } from "antd";

import InviteModal from "./InviteModal";

interface InviteButtonProps {
  className?: string;
}

const InviteButton = (props: InviteButtonProps) => {
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

  const openInviteModal = () => {
    if (!showInviteModal) {
      setShowInviteModal(true);
    }
  };

  const closeInviteModal = () => {
    if (showInviteModal) {
      setShowInviteModal(false);
    }
  };

  return (
    <Button className={props.className} type="link" onClick={() => openInviteModal()}>
      <Icon type="plus-circle" theme="filled" />
      Invite
      <InviteModal
        handleOk={() => closeInviteModal()}
        handleCancel={() => closeInviteModal()}
        visible={showInviteModal}
      />
    </Button>
  );
};

export default InviteButton;
