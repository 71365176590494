import { Binding } from "../../../../../../types";
import { ComponentNode } from "../../../RenderTreeContext";
import { getChildrenSchema } from "../../util";
import { ParameterType } from "../useFuncParams";
import { InputParameterComponent, InputParameter } from "../useFuncParams/types";

export default function getParametersSchema(node: ComponentNode): Binding[] {
  const params = node.component.properties?.input_parameters || [];

  const componentSlugs: string[] = params
    .filter(
      (param: InputParameter) =>
        param.type === ParameterType.COMPONENT && !!param.component_slug
    )
    .map((param: InputParameterComponent) => param.component_slug);

  return getChildrenSchema(node, componentSlugs);
}
