import inflection from "inflection";

export const humanize = (str: string | null, opts = { underscore: true }) => {
  if (str === null) return "";
  const value = str
    .trim()
    .replace(/^[_]+|[_]+$/g, "")
    .replace(/^(.*[a-z])([I][dD])$/g, "$1Id")
    .replace(/^(.*)[ ]([Ii][dD])$/g, "$1Id");
  const underscored = opts.underscore ? inflection.underscore(value) : value;
  let humanized = inflection.humanize(underscored);
  if (underscored.endsWith("_id")) humanized = humanized + " id";
  return humanized.trim();
};

export const sentenceJoin = (parts: string[]): string => {
  const partsCopy = [...parts];
  switch (partsCopy.length) {
    case 0:
      return "";
    case 1:
      return partsCopy[0];
    case 2:
      return partsCopy.join(" and ");
    default:
      let last = partsCopy.pop();
      last = `and ${last}`;
      partsCopy.push(last);
      return partsCopy.join(", ");
  }
};

export const isError = (e: unknown): e is Error =>
  !!e && typeof e === "object" && e.hasOwnProperty("message");

export const tryError = (e: unknown): Error => {
  if (!isError(e)) throw new Error("unexpected error type: " + e);
  return e;
};
