import React, { useEffect, useCallback } from "react";

import { Alert, Input, Form } from "antd";
import * as AntForm from "antd/lib/form/Form";
import invariant from "invariant";
import styled from "styled-components";

import SpaceComponent from "..";
import { ErrorValues } from "../../../../../constants";
import { formatTestHandle } from "../../../../../test";
import {
  FunctionParameterNode,
  SpaceComponentObject,
  SpaceFunctionType
} from "../../../../../types";
import AttributeInput from "../../../../common/AttributeInput";
import usePrevious from "../../../../common/hooks/usePrevious";
import RecordRow from "../../../../resource_dash/Record/common/RecordRow";
import { humanize } from "../../../../util";
import {
  SpaceFunction,
  FunctionExecutionStatus
} from "../../../FunctionExecutor/FunctionExecutor";
import { DimensionsContextContainer } from "../../../layout/DimensionsContext";
import SpaceApi from "../../../SpaceApi";
import { ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT } from "../../constants";
import { ItemContainer } from "../common/CollectionItemContainer/CollectionItemContainer";
import { isRequiredParameterDenied } from "../common/PermissionFeedback";
import {
  InputParameter,
  isGeneratedParameter,
  ParameterType
} from "../common/useFuncParams";
import { escapeParamName } from "../common/useFuncParams/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import useIsComponentVisible from "../common/useIsComponentVisible/useIsComponentVisible";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

const StyledForm = styled(Form)`
  .ant-form-explain {
    margin-top: ${props => props.theme.spacerxs};
  }
`;
StyledForm.displayName = "Form";

const StyledFormItem = styled(Form.Item)`
  width: 100%;
  max-width: 500px;
`;

export const DisabledAlert = styled(Alert)`
  margin-bottom: ${props => props.theme.spacerlg};
`;

const PermissionAlert = styled(Alert)`
  margin-top: 10px;
`;

export enum FunctionFormStatus {
  PENDING = "pending",
  PREPARING = "preparing",
  VALIDATING = "validating",
  SUBMITTING = "submitting",
  COMPLETED = "completed",
  FAILED = "failed"
}

export const IDLE_FUNCTION_FORM_STATUSES = [
  FunctionFormStatus.PENDING,
  FunctionFormStatus.COMPLETED,
  FunctionFormStatus.FAILED
];

const functionStatusMap = {
  [FunctionExecutionStatus.PENDING]: FunctionFormStatus.PENDING,
  [FunctionExecutionStatus.IN_PROGRESS]: FunctionFormStatus.SUBMITTING,
  [FunctionExecutionStatus.COMPLETED]: FunctionFormStatus.COMPLETED,
  [FunctionExecutionStatus.FAILED]: FunctionFormStatus.FAILED
};

export const getFormStatus = (status: FunctionExecutionStatus) => {
  return functionStatusMap[status] || FunctionFormStatus.PENDING;
};

const FunctionFormContext = React.createContext<{
  status: FunctionFormStatus | undefined;
}>({
  status: undefined
});
export const useFunctionFormContext = () => React.useContext(FunctionFormContext);

interface FunctionFormProps {
  formId: string;
  func: SpaceFunction;
  form: AntForm.WrappedFormUtils<any>;
  spaceApi: SpaceApi;
  spaceComponent: SpaceComponentObject;
  values?: Record<string, any>;
  initialValues?: Record<string, any>;
  inputParameters: InputParameter[];
  disabled: boolean;
  functionExecutionStatus: FunctionExecutionStatus;
  isValid: boolean;
  onSubmit: () => void;
}

export default function FunctionForm(props: FunctionFormProps) {
  const {
    formId,
    form,
    spaceApi,
    spaceComponent,
    inputParameters,
    func,
    values,
    initialValues,
    disabled,
    functionExecutionStatus,
    isValid,
    onSubmit
  } = props;

  invariant(
    disabled || (!!func && func.functionParameters),
    "An enabled function form requires a function."
  );
  const { output, updateOutput } = useComponentStateContext();
  useEffect(() => {
    updateOutput({ status: FunctionFormStatus.PENDING });
  }, [updateOutput]);

  const setStatus = useCallback(
    (status: FunctionFormStatus) => {
      updateOutput({ status });
    },
    [updateOutput]
  );

  const status =
    ((output as any)?.status as FunctionFormStatus) || FunctionFormStatus.PENDING;

  const access = useFunctionAccess(func);

  const functionParameterMap =
    func && func.functionParameters
      ? Object.fromEntries(func.functionParameters.map(fp => [fp.name, fp]))
      : {};
  const functionParameters = Object.keys(values ? values : {})
    .map(k => functionParameterMap[k])
    .filter(fp => fp);

  const isExecutableFunction = func.type !== SpaceFunctionType.NOT_VISIBLE;
  const hasMissingFunctionParameter =
    isExecutableFunction &&
    inputParameters.some(ip => !functionParameters.find(fp => fp.name === ip.name));

  const paramDenied =
    !isExecutableFunction || isRequiredParameterDenied(inputParameters, func, access);

  const hasMissingHiddenRequiredInputParameter = inputParameters.some(ip => {
    const fp = functionParameters.find(fp => fp.name === ip.name);
    if (!fp) return false;
    return (
      fp.required && ip.hidden && values?.[ip.name] === ErrorValues.permissionDenied
    );
  });
  const showFormDisabled =
    !isExecutableFunction || paramDenied || hasMissingHiddenRequiredInputParameter;

  const hasInvalidInputComponentType =
    isExecutableFunction &&
    inputParameters.some(ip => {
      const fp = functionParameterMap[ip.name];
      if (fp && ip.type === ParameterType.COMPONENT) {
        return !ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[fp.type].includes(
          ip.component_type
        );
      }
      return false;
    });

  React.useEffect(() => {
    setStatus(getFormStatus(functionExecutionStatus));
  }, [functionExecutionStatus, setStatus]);

  const lastStatus = usePrevious(status);

  const shouldValidate =
    status === FunctionFormStatus.PREPARING &&
    lastStatus &&
    IDLE_FUNCTION_FORM_STATUSES.includes(lastStatus);
  const shouldSubmit =
    status === FunctionFormStatus.VALIDATING &&
    lastStatus === FunctionFormStatus.PREPARING;

  React.useEffect(() => {
    if (shouldValidate) {
      setStatus(FunctionFormStatus.VALIDATING);
    } else if (shouldSubmit) {
      if (isValid) {
        setStatus(FunctionFormStatus.SUBMITTING);
        onSubmit();
      } else {
        setStatus(FunctionFormStatus.FAILED);
      }
    }
  }, [shouldValidate, shouldSubmit, isValid, onSubmit, setStatus]);

  const functionFormContextValue = React.useMemo(() => {
    return { status };
  }, [status]);

  return (
    <StyledForm
      id={formId}
      data-test="function-form"
      onSubmit={e => {
        e.preventDefault();
        setStatus(FunctionFormStatus.PREPARING);
      }}
    >
      <FunctionFormContext.Provider value={functionFormContextValue}>
        {hasMissingFunctionParameter && (
          <Alert
            showIcon
            type="warning"
            message="This form needs to be updated."
            description="A function parameter associated with a form field cannot be found. It's possible that the name of the function parameter was changed or that the function parameter was removed from your system."
          />
        )}

        {hasInvalidInputComponentType && (
          <Alert
            showIcon
            type="warning"
            message="This form needs to be updated."
            description="A function parameter associated with a form field has changed and the form needs to be updated."
          />
        )}

        {showFormDisabled && (
          <DisabledAlert
            showIcon
            type="warning"
            message="This form is disabled."
            description={
              paramDenied
                ? "You don't have access to one or more fields required to complete this form. Please contact your admin to update your permissions."
                : "At least one hidden value required for this form could not be retrieved because you do not have permissions to access the data. Please contact your admin to update your permissions."
            }
          />
        )}
        <ItemContainer itemKey="fieldset">
          {!showFormDisabled &&
            inputParameters
              .filter(ip => !ip.hidden)
              .map(ip => {
                const fp = functionParameters.find(fp => fp.name === ip.name);

                const fnParamDescriptor = func.describeFunctionParameter(
                  fp?.name || ""
                );
                const paramName = humanize(
                  fnParamDescriptor?.param.name || fp?.name || ""
                );
                const paramLabel = ip.label || paramName;
                if (fp === undefined) {
                  return (
                    <RecordRow
                      key={ip.name}
                      isDense
                      title={paramLabel}
                      data-test={formatTestHandle(`${paramName}-form-field`)}
                    >
                      <StyledFormItem>
                        <Input disabled={true} />
                      </StyledFormItem>
                    </RecordRow>
                  );
                }
                if (!access.parameterAllowed(fp.name)) return null;
                if (ip.type === ParameterType.COMPONENT) {
                  const sc = spaceComponent.componentTreeNodes.find(
                    sc => sc.slug === ip.component_slug
                  );
                  if (sc) {
                    return (
                      <ComponentField
                        functionParameter={fp}
                        component={sc}
                        values={values}
                        spaceApi={spaceApi}
                        paramName={paramName}
                        paramLabel={paramLabel}
                      />
                    );
                  }
                }
                return (
                  <RecordRow
                    key={fp.id}
                    isDense
                    title={paramLabel}
                    data-test={formatTestHandle(`${paramName}-form-field`)}
                  >
                    <StyledFormItem>
                      <AttributeInput
                        disabled={disabled}
                        generated={isGeneratedParameter(ip)}
                        form={form}
                        sourceType={fp.type}
                        sourceName={escapeParamName(fp.name)}
                        sourceNullable={!ip.required}
                        fieldType={ip.field_type}
                        options={ip.render_options}
                        value={
                          values?.[fp.name] !== ErrorValues.permissionDenied
                            ? values?.[fp.name]
                            : null
                        }
                      />

                      {initialValues?.[fp.name] === ErrorValues.permissionDenied && (
                        <PermissionAlert
                          message="This value could not be retrieved because you do not have permission to read it. Leaving this field blank may result in writing a blank or null value to your system."
                          type="warning"
                          showIcon
                        />
                      )}
                    </StyledFormItem>
                  </RecordRow>
                );
              })}
        </ItemContainer>
      </FunctionFormContext.Provider>
    </StyledForm>
  );
}

interface ComponentFieldProps {
  functionParameter: FunctionParameterNode;
  component: SpaceComponentObject;
  values: Record<string, any> | undefined;
  spaceApi: SpaceApi;
  paramName: string;
  paramLabel: string | undefined;
}

function ComponentField({
  functionParameter,
  component,
  values,
  spaceApi,
  paramName,
  paramLabel
}: ComponentFieldProps) {
  const isVisible = useIsComponentVisible(component.properties);
  const inputLabel = component.properties.label || paramLabel;

  return (
    <RecordRow
      key={functionParameter.id}
      isDense
      isHidden={!isVisible}
      title={inputLabel}
      data-test={formatTestHandle(`${paramName}-form-field`)}
    >
      <StyledFormItem>
        <DimensionsContextContainer>
          <SpaceComponent spaceComponent={component} spaceApi={spaceApi} />
          {values?.[functionParameter.name] === ErrorValues.permissionDenied && (
            <PermissionAlert
              message="This value could not be retrieved because you do not have permission to read it. Leaving this field blank may result in writing a blank or null value to your system."
              type="warning"
              showIcon
            />
          )}
        </DimensionsContextContainer>
      </StyledFormItem>
    </RecordRow>
  );
}
