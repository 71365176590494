import { useCallback } from "react";

import { reportException } from "../../../util/exceptionReporting";

export function useListHelper<T>(
  list: T[],
  factory: () => T,
  keyCallback: (item: T) => string,
  updateCallback: (updated: T[]) => void
) {
  if (Object.isFrozen(list)) {
    reportException(new Error("useListHelper: list is frozen"), {
      extra: { list }
    });
    updateCallback(list.concat());
  }

  const onCreate = useCallback(() => {
    if (list.findIndex(item => keyCallback(item) === "") > -1) return;
    list.push(factory());
    updateCallback(list.concat());
  }, [list, factory, keyCallback, updateCallback]);

  const onUpdate = useCallback(
    (i: number, item: T) => {
      list[i] = item;
      updateCallback(list.concat());
    },
    [list, updateCallback]
  );

  const onDelete = useCallback(
    (i: number) => {
      list.splice(i, 1);
      updateCallback(list.concat());
    },
    [list, updateCallback]
  );

  return {
    list,
    onCreate,
    onUpdate,
    onDelete
  };
}
