import React from "react";

import { Input } from "antd";
import styled from "styled-components";

import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import { INPUT_MIN_WIDTH } from "../../../layout/constants";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDebouncedOutputSyncing from "../common/useDebouncedOutputSyncing";
import { Props } from "../SpaceComponent";

import { ensureSpaceTextAreaComponent } from "./Config/reducer";

const Container = styled.div<{ editMode: boolean; hasError: boolean }>`
  height: 100%;
  width: 100%;

  ${props =>
    props.editMode &&
    `
      & .ant-input[disabled] {
        color: ${props.theme.textColor};
        background-color: white;
        cursor: not-allowed;
        opacity: 1;
      }
    `}

  ${props =>
    props.hasError &&
    `
    .ant-input,
    .ant-input:focus,
    .ant-input:hover {
      border-color: ${props.theme.errorColor};
      box-shadow: none;
    }
  `}
`;

export default function SpaceTextArea({ spaceComponent, hasConfigError }: Props) {
  const { properties } = ensureSpaceTextAreaComponent(spaceComponent);
  const { editMode } = useStableSpaceContext();
  const { placeholder } = properties;
  const { localValue, setLocalValue, errorMessage, hasPermissionError } =
    useDebouncedOutputSyncing();

  return (
    <EditModeInteractionBlocker>
      <Container hasError={!!errorMessage} editMode={editMode}>
        <FlexContainer>
          {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
          <Input.TextArea
            placeholder={placeholder}
            value={
              hasPermissionError || localValue === undefined || localValue === null
                ? ""
                : localValue
            }
            onChange={e => setLocalValue(e.target.value)}
            style={{
              height: "100%",
              minWidth: `${INPUT_MIN_WIDTH}px`,
              resize: "none"
            }}
          />
          {hasPermissionError && <PermissionWarningIcon />}
        </FlexContainer>

        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </Container>
    </EditModeInteractionBlocker>
  );
}
