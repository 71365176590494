import gql from "graphql-tag";

export interface UpdateDataSourceMutationResult {
  updateDataSource: {
    ok: boolean;
    message?: string;
  };
}

export interface UpdateHTTPDataSourceMutationResult {
  updateHttpDataSource: {
    ok: boolean;
    message?: string;
  };
}

export interface UpdateAPIKeyDataSourceMutationResult {
  updateAPIKeyDataSource: {
    ok: boolean;
    message?: string;
  };
}

export interface UpdateSalesForceDataSourceMutationResult {
  updateSalesForceDataSource: {
    ok: boolean;
    message?: string;
  };
}

export interface UpdateSnowflakeDataSourceMutationResult {
  updateSnowflakeDataSource: {
    ok: boolean;
    message?: string;
  };
}

export interface UpdateDataSourceArgumentsBase {
  dataSourceId: string;
  environmentId: string;
}

export interface UpdateDataSourceMutationVars extends UpdateDataSourceArgumentsBase {
  host?: string;
  port?: number;
  username?: string;
  password?: string;
  database?: string;
  sshUser?: string;
  sshHost?: string;
  sshPort?: number;
}

export interface UpdateHTTPDatasourceMutationVars
  extends UpdateDataSourceArgumentsBase {
  host?: string;
  port?: number;
  user?: string;
  password?: string;
  headers?: { key: string; value: string }[];
}

export interface UpdateAPIKeyDatasourceMutationVars
  extends UpdateDataSourceArgumentsBase {
  apiKey?: string;
}

export interface UpdateMongoDatasourceMutationVars
  extends UpdateDataSourceArgumentsBase {
  connectionString?: string;
  database?: string;
  sshUser?: string;
  sshHost?: string;
  sshPort?: number;
}

export interface UpdateSalesForceDataSourceMutationVars
  extends UpdateDataSourceArgumentsBase {
  username?: string;
  password?: string;
  clientId?: string;
  clientSecret?: string;
  securityToken?: string;
}

export interface UpdateSnowflakeDataSourceMutationVars
  extends UpdateDataSourceArgumentsBase {
  account?: string;
  user?: string;
  password?: string;
  database?: string;
  warehouse?: string;
  role?: string;
}

export const UPDATE_DATA_SOURCE_MUTATION = gql`
  mutation UpdateDataSource(
    $dataSourceId: ID!
    $environmentId: ID!
    $host: String
    $port: Int
    $username: String
    $password: String
    $database: String
    $requireSsl: Boolean
    $sshUser: String
    $sshHost: String
    $sshPort: Int
  ) {
    updateDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      host: $host
      port: $port
      username: $username
      password: $password
      database: $database
      requireSsl: $requireSsl
      sshUser: $sshUser
      sshHost: $sshHost
      sshPort: $sshPort
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_API_KEY_DATA_SOURCE_MUTATION = gql`
  mutation UpdateApiKeyDataSourceMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $apikey: String!
  ) {
    updateApiKeyDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      apikey: $apikey
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_DYNAMO_MUTATION = gql`
  mutation UpdateDynamoDbMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $apiAccessKey: String
    $secretKey: String
    $region: String
  ) {
    updateDynamoDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      apiAccessKey: $apiAccessKey
      secretKey: $secretKey
      region: $region
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_GOOGLE_SERVICE_ACCOUNT_MUTATION = gql`
  mutation UpdateGoogleServiceAccountMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $key: String
    $datasetId: String
    $projectId: String
  ) {
    updateGoogleServiceAccountDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      projectId: $projectId
      datasetId: $datasetId
      key: $key
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_HTTP_DATA_SOURCE_MUTATION = gql`
  mutation UpdateHTTPDataSource(
    $dataSourceId: ID!
    $environmentId: ID!
    $baseUrl: String
    $user: String
    $password: String
    $headers: [HeaderObjectType]
  ) {
    updateHttpDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      baseUrl: $baseUrl
      user: $user
      password: $password
      headers: $headers
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_MAILGUN_DATA_SOURCE_MUTATION = gql`
  mutation UpdateMailgunDataSourceMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $apikey: String
  ) {
    updateMailgunDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      apikey: $apikey
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_MONGO_DATA_SOURCE_MUTATION = gql`
  mutation UpdateMongoDataSourceMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $connectionString: String
    $database: String
    $sshUser: String
    $sshHost: String
    $sshPort: Int
  ) {
    updateMongoDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      connectionString: $connectionString
      database: $database
      sshUser: $sshUser
      sshHost: $sshHost
      sshPort: $sshPort
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_S3_MUTATION = gql`
  mutation UpdateS3Mutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $apiAccessKey: String
    $secretKey: String
    $region: String
  ) {
    updateS3DataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      apiAccessKey: $apiAccessKey
      secretKey: $secretKey
      region: $region
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_SALESFORCE_DATA_SOURCE_MUTATION = gql`
  mutation UpdateSalesForceDataSourceMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $username: String
    $password: String
    $clientid: String
    $clientsecret: String
    $securitytoken: String
  ) {
    updateSalesForceDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      username: $username
      password: $password
      clientid: $clientid
      clientsecret: $clientsecret
      securitytoken: $securitytoken
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_SNOWFLAKE_DATA_SOURCE_MUTATION = gql`
  mutation UpdateSnowflakeDataSourceMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $account: String
    $user: String
    $password: String
    $database: String
    $warehouse: String
    $role: String
    $authenticator: String
    $privateKey: String
    $privateKeyPassword: String
  ) {
    updateSnowflakeDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      account: $account
      user: $user
      password: $password
      database: $database
      warehouse: $warehouse
      role: $role
      authenticator: $authenticator
      privateKey: $privateKey
      privateKeyPassword: $privateKeyPassword
    ) {
      ok
      message
    }
  }
`;

export const UPDATE_ZENDESK_MUTATION = gql`
  mutation UpdateZendeskMutation(
    $dataSourceId: ID!
    $environmentId: ID!
    $domain: String
    $user: String
    $apiToken: String
  ) {
    updateZendeskDataSource(
      dataSourceId: $dataSourceId
      environmentId: $environmentId
      domain: $domain
      user: $user
      apiToken: $apiToken
    ) {
      ok
      message
    }
  }
`;
