import { sortBy } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import { BulkExecuteFunctionResult, SourceType } from "../../../../../../types";
import { InputParameter } from "../../common/useFuncParams";

export enum BulkImportStep {
  SelectFile,
  MapData,
  Review,
  Complete
}

export interface ParamMapping {
  name: string;
  type: AttributeTypes;
  required: boolean;
  ip: InputParameter;
}

export type ParsedRowEntry = [any, boolean];
export type ParsedRow = ParsedRowEntry[];
export type Mappings = Record<string, ParamMapping>;

export interface State {
  previousStep?: BulkImportStep;
  currentStep: BulkImportStep;
  sourceType: SourceType | null;
  file: File | null;
  fileConfig: any;
  fileHeader: string[];
  fileRows: string[][];
  parsedRows: ParsedRow[];
  bindingRows: any[][];
  isImporting: boolean;
  importingMessage: string;
  mappings: Mappings;
  results: BulkExecuteFunctionResult[];
}

export const initialState: State = {
  previousStep: undefined,
  currentStep: BulkImportStep.SelectFile,
  sourceType: null,
  file: null,
  fileConfig: {},
  fileHeader: [],
  fileRows: [],
  bindingRows: [],
  parsedRows: [],
  isImporting: false,
  importingMessage: "Submitting...",
  mappings: {},
  results: []
};

interface SetFileData {
  type: "SET_FILE_DATA";
  payload: { file: File; results: { data: string[][]; meta: any } };
}

interface InitBindingData {
  type: "INIT_BINDING_DATA";
  payload: { data: any[][]; mappings: Mappings };
}

interface SetParsedRows {
  type: "SET_PARSED_ROWS";
  payload: { data: ParsedRow[] };
}

interface SetCurrentStep {
  type: "SET_CURRENT_STEP";
  payload: { step: BulkImportStep };
}

interface SetMappings {
  type: "SET_MAPPINGS";
  payload: { mappings: { [key: string]: ParamMapping } };
}

interface ResetFlow {
  type: "RESET_FLOW";
  payload: { step: BulkImportStep };
}

interface StartImport {
  type: "START_IMPORT";
}

interface UpdateImportProgress {
  type: "UPDATE_IMPORT_PROGRESS";
  payload: {
    chunk: number;
    total: number;
  };
}

interface FinishImport {
  type: "FINISH_IMPORT";
  payload: {
    results: BulkExecuteFunctionResult[];
  };
}

type Action =
  | SetFileData
  | InitBindingData
  | SetParsedRows
  | SetMappings
  | SetCurrentStep
  | StartImport
  | UpdateImportProgress
  | FinishImport
  | ResetFlow;

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "RESET_FLOW": {
      const { step } = action.payload;
      const newState = {
        ...initialState,
        currentStep: step,
        sourceType: state.sourceType
      };
      // Persist binding mappings and data between renders
      if (state.sourceType === SourceType.BINDING) {
        newState.bindingRows = state.bindingRows;
        newState.mappings = state.mappings;
        newState.parsedRows = state.parsedRows;
      }
      return newState;
    }
    case "SET_CURRENT_STEP": {
      const { step } = action.payload;
      return { ...state, previousStep: state.currentStep, currentStep: step };
    }
    case "SET_FILE_DATA": {
      const { file, results } = action.payload;
      const fileRows = results.data?.slice(1) || [];
      return {
        ...state,
        file: file,
        fileHeader: results.data?.[0] || [],
        fileRows: fileRows,
        fileConfig: results.meta
      };
    }
    case "INIT_BINDING_DATA": {
      const { data, mappings } = action.payload;
      return {
        ...state,
        bindingRows: data,
        parsedRows: data.map(row => row.map(val => [val, true])),
        mappings,
        fileHeader: sortBy(
          Object.entries(mappings),
          ([idx, _]: [string, ParamMapping]) => Number(idx)
        ).map(([_, m]: [string, ParamMapping]) => m.name)
      };
    }
    case "SET_PARSED_ROWS": {
      const { data } = action.payload;
      return {
        ...state,
        parsedRows: data
      };
    }
    case "SET_MAPPINGS": {
      const { mappings } = action.payload;
      return {
        ...state,
        mappings
      };
    }
    case "START_IMPORT": {
      return {
        ...state,
        isImporting: true
      };
    }
    case "UPDATE_IMPORT_PROGRESS": {
      const { chunk, total } = action.payload;
      const min = chunk * 10 + 1;
      const max = Math.min(total, (chunk + 1) * 10);
      return {
        ...state,
        importingMessage: `Submitting records ${min} through ${max} of ${total}`
      };
    }
    case "FINISH_IMPORT": {
      const { results } = action.payload;
      return {
        ...state,
        isImporting: false,
        results: results
      };
    }
    default:
      throw new Error("unknown bulk import reducer action");
  }
}
