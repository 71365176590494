import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import ButtonNew from "../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../common/hooks/useAuthUser";
import usePaths from "../../common/hooks/usePaths";
import Message from "../../common/Message";
import { H3 } from "../../common/StyledComponents";
import LeftNav from "../App/Queue/LeftNav";
import { PermissionsModal } from "../App/Queue/Permissions/PermissionsModal";
import { useQueueBySlug } from "../App/Queue/useQueueClient/useQueue/queries/QueueBySlug";
import AnalyticsButton from "../common/AnalyticsButton";
import PageHeader from "../common/PageHeader";

import { THIRTY_DAYS_IN_SECONDS } from "./DurationSelect/DurationSelect";
import {
  AnalyticsMain,
  AnalyticsRoot,
  AverageTimeToCloseStatistics,
  BackIcon,
  BackLink,
  BackLinkText,
  ButtonContainer,
  DayLabel,
  NewTasksCreatedStatistics,
  QueueName,
  TasksCompletedStatistics,
  TodayStatistics
} from "./styledComponents";
import * as styled from "./styledComponents";

interface Params extends Record<string, any> {
  slug: string;
}

export function Analytics() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [statsBy, setStatsBy] = React.useState<"user" | "role">("user");
  const [openModal, setOpenModal] = useState<"permissions" | undefined>();
  const [withinDuration, setWithinDuratin] = useState<number>(THIRTY_DAYS_IN_SECONDS);

  const { queuesapp_getEditQueue, queuesapp_getQueue } = usePaths();
  const params = useParams<Params>();
  const navigate = useNavigate();

  if (!params.slug) throw new Error("Not found");
  const queueSlug = params.slug!;

  const { isAdmin } = useAuthUser();
  const { data, error } = useQueueBySlug({ variables: { slug: queueSlug } });
  const queue = data?.queueBySlug;

  useEffect(() => {
    if (error) {
      Message.error("An error occurred. Please try again.");
    }
  }, [error]);

  return (
    <AnalyticsRoot>
      <PageHeader
        title={queue?.name || ""}
        controls={
          <ButtonContainer>
            {isAdmin && (
              <>
                <ButtonNew
                  type="noFill"
                  onClick={() => navigate(queuesapp_getEditQueue(queueSlug))}
                >
                  Edit queue
                </ButtonNew>
                <ButtonNew type="brand" onClick={() => setOpenModal("permissions")}>
                  Share
                </ButtonNew>
                <AnalyticsButton queueSlug={queueSlug} active={true} />
              </>
            )}
          </ButtonContainer>
        }
        onToggleNav={() => setIsNavOpen(!isNavOpen)}
      />
      <LeftNav open={isNavOpen} selected={queueSlug} />
      <AnalyticsMain>
        <BackLink to={queuesapp_getQueue(queueSlug)}>
          <BackIcon />
          <BackLinkText>Back to Queue</BackLinkText>
        </BackLink>
        <QueueName>{queue?.name}</QueueName>
        <H3>Analytics</H3>
        <DayLabel>Today</DayLabel>
        <TodayStatistics>
          <styled.OpenStateTaskStatistics queueId={queue?.id} />
          {statsBy === "user" && (
            <styled.OpenStateTaskUserStatistics
              queueId={queue?.id}
              actionText="View by role"
              onActionClick={() => setStatsBy("role")}
            />
          )}
          {statsBy === "role" && (
            <styled.OpenStateTaskRoleStatistics
              queueId={queue?.id}
              actionText="View by user"
              onActionClick={() => setStatsBy("user")}
            />
          )}
        </TodayStatistics>
        <styled.PastActivityContainer>
          <styled.PastActivityHeader>
            <H3>Past activity</H3>
            <styled.WithinTimeSelector
              value={withinDuration}
              onChange={(value: number) => setWithinDuratin(value)}
            />
          </styled.PastActivityHeader>
          <styled.PastActivityStats>
            <NewTasksCreatedStatistics
              queueId={queue?.id}
              withinSeconds={withinDuration}
            />
            <TasksCompletedStatistics
              queueId={queue?.id}
              withinSeconds={withinDuration}
            />
            <AverageTimeToCloseStatistics
              queueId={queue?.id}
              withinSeconds={withinDuration}
            />
          </styled.PastActivityStats>
        </styled.PastActivityContainer>
      </AnalyticsMain>
      {openModal === "permissions" && !!queue && (
        <PermissionsModal
          queueName={queue.name}
          queueSlug={queue.slug}
          onCancel={() => setOpenModal(undefined)}
        />
      )}
    </AnalyticsRoot>
  );
}
