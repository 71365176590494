import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

import { publicClient } from "../../graphql";
import ButtonNew from "../common/ButtonNew/ButtonNew";
import { B3 } from "../common/StyledComponents";

const POLL_INTERVAL_BASE_SECONDS = 90;
const POLL_INTERVAL_MILLISECONDS =
  (POLL_INTERVAL_BASE_SECONDS + Math.random() * 10) * 1000;

const BannerRoot = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 20px;
  margin-left: 25%;
  margin-right: 25%;
  padding: 10px 20px;
  background: white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 100;
`;

const VERSION = gql`
  query AppVersion {
    version
  }
`;

const ReloadBanner = () => {
  const [version, setVersion] = useState("");
  const [versionMismatch, setVersionMismatch] = useState(false);

  const { stopPolling } = useQuery(VERSION, {
    client: publicClient,
    pollInterval: POLL_INTERVAL_MILLISECONDS,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (version && version !== data.version) {
        setVersionMismatch(true);
        stopPolling();
      }
      setVersion(data.version);
    }
  });

  return (
    <>
      {versionMismatch && (
        <BannerRoot>
          <B3>
            You're using an old version of Internal. Reload the page to get the latest
            bug fixes and security improvements.
          </B3>
          <ButtonNew type="noFillAccent" onClick={() => window.location.reload()}>
            Reload Page
          </ButtonNew>
        </BannerRoot>
      )}
    </>
  );
};

export default ReloadBanner;
