import React from "react";

import { Input } from "antd";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import { CredentialFormProps } from "./types";

export const SECRET_KEY_FIELD = "secretKey";
export const ACCESS_KEY_FIELD = "apiAccessKey";
export const REGION_FIELD = "region";

export default function AmazonFieldSet(props: CredentialFormProps) {
  return (
    <>
      <FormItem label={props.includeLabel && "Api Access Key"}>
        {props.form.getFieldDecorator(ACCESS_KEY_FIELD, {
          rules: [
            {
              required: !props.allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="Api Access Key"
            data-test="apiAccessKey-input"
            disabled={props.isLoading}
          />
        )}
      </FormItem>
      <FormItem label={props.includeLabel && "Secret Key"}>
        {props.form.getFieldDecorator(SECRET_KEY_FIELD, {
          rules: [
            {
              required: !props.allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="Secret Key"
            data-test="secretKey-input"
            disabled={props.isLoading}
            type="password"
          />
        )}
      </FormItem>
      <FormItem label={props.includeLabel && "Region"}>
        {props.form.getFieldDecorator(REGION_FIELD, {
          rules: [
            {
              required: !props.allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="region"
            data-test="region-input"
            disabled={props.isLoading}
          />
        )}
      </FormItem>
    </>
  );
}
