import { Input, Table } from "antd";
import styled from "styled-components";

export const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchInput = styled(Input)`
  flex-basis: 50%;
`;

export const ButtonBar = styled.div`
  flex-basis: 50%;
`;

export const FunctionTable = styled(Table)`
  height: 100%;
  margin-top: ${props => props.theme.spacermd};
`;
