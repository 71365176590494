import styled from "styled-components";

import { colors } from "../../../../../cssConstants";
import { B2, B3, C2 } from "../../../../common/StyledComponents";

export const EmptyContainer = styled.div`
  background-color: ${props => props.theme.containerPrimary};
  padding: 48px 0 48px;
  border-radius: 8px;
  margin-bottom: ${props => props.theme.spacerxl};
  text-align: center;
`;

export const EmptyContent = styled.div``;

export const EmptyTitle = styled(B2)`
  display: block;
  color: ${props => props.theme.surfacePrimary};
  margin-bottom: ${props => props.theme.spacersm};
`;

export const EmptySubtitle = styled(C2)`
  display: block;
  color: ${props => props.theme.surfaceSecondary};
`;

export const CollapsableTitle = styled(B3)`
  font-weight: 600;
  padding-left: 12px;
`;

export const CollapsableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 40px;

  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  font-family: var(--body-font-family);
  color: ${colors.surfacePrimary};

  cursor: pointer;
`;
