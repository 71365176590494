import React from "react";

import { Input } from "antd";

import { FormItem } from "../../common/StyledComponents";

import * as styled from "./styledComponents";
import { CredentialFormProps } from "./types";

export const BASIC_AUTH_USER = "User";
export const BASIC_AUTH_PASSWORD = "Password";

export default function BasicAuthFields(props: CredentialFormProps) {
  return (
    <>
      <styled.StyledAlert
        type="info"
        message="This will overwrite any Authorization headers provided."
        showIcon
      />
      <FormItem label={props.includeLabel && "User"}>
        {props.form.getFieldDecorator(BASIC_AUTH_USER, {
          initialValue: props.credentials?.user
        })(
          <Input data-test="userInput" placeholder="User" disabled={props.isLoading} />
        )}
      </FormItem>
      <FormItem label={props.includeLabel && "Password"}>
        {props.form.getFieldDecorator(
          BASIC_AUTH_PASSWORD,
          {}
        )(
          <Input.Password
            placeholder="Password"
            type="password"
            data-test="password-input"
            autoComplete="new-password"
            disabled={props.isLoading}
          />
        )}
      </FormItem>
    </>
  );
}
