import { TreeNode } from "../reducer";

export default function findTreeNode(
  tree: TreeNode,
  pathParts: string[]
): TreeNode | undefined {
  const slug = pathParts.shift();
  for (let i = 0; i < tree.treeNodes.length; i++) {
    const node = tree.treeNodes[i];
    if (node.slug === slug) {
      if (pathParts.length === 0) {
        return node;
      } else {
        return findTreeNode(node, pathParts);
      }
    }
  }
}
