import { useCallback, useMemo } from "react";

import { useQuery } from "@apollo/react-hooks";

import { SpaceFunctionType } from "../../../../../../types";
import useAuthUser from "../../../../../common/hooks/useAuthUser";
import { assertNever } from "../../../../../util/assertNever";
import {
  SpaceFunction,
  FunctionParameterDescriptor,
  FunctionAttributeDescriptor
} from "../../../../FunctionExecutor/FunctionExecutor";

import { FunctionAccessData, FunctionAccessVars, GET_FUNCTION_ACCESS } from "./queries";
import { FunctionAccess } from "./types";

const ALL_ACCESS: FunctionAccess = {
  __typename: "FunctionAccess",
  onlyParameters: null,
  onlyAttributes: null
};

interface AccessResult {
  parameterAllowed: (name: string) => boolean;
  attributeAllowed: (name: string) => boolean;
}

interface LoadedAccessResult extends AccessResult {
  loading: boolean;
}

function allowed(name: string, only?: string[] | null) {
  return only === null || (only || []).includes(name);
}

export function useAccess(
  access: FunctionAccess[] | FunctionAccess | undefined = [],
  fn?: SpaceFunction
): AccessResult {
  const getDescriptor = useCallback(
    (name: string, type: "functionParameter" | "functionAttribute") => {
      let n = name;
      let i = 0;
      if (fn) {
        const desc =
          type === "functionParameter"
            ? fn.describeFunctionParameter(name)
            : fn.describeFunctionAttribute(name);
        if (type === "functionParameter") {
          n = (desc as FunctionParameterDescriptor)?.param.name || n;
        } else {
          n = (desc as FunctionAttributeDescriptor)?.attr.name || n;
        }
        i = desc?.fnIndex || 0;
      }
      return {
        name: n,
        fnIndex: i
      };
    },
    [fn]
  );
  const _access = useMemo(
    () => (Array.isArray(access) ? access : !!access ? [access] : []),
    [access]
  );

  const parameterAllowed = useCallback(
    (name: string) => {
      const desc = getDescriptor(name, "functionParameter");
      return allowed(desc.name, _access[desc.fnIndex]?.onlyParameters);
    },
    [_access, getDescriptor]
  );
  const attributeAllowed = useCallback(
    (name: string) => {
      const desc = getDescriptor(name, "functionAttribute");
      return allowed(desc.name, _access[desc.fnIndex]?.onlyAttributes);
    },
    [_access, getDescriptor]
  );
  const returnValue = useMemo(
    () => ({ parameterAllowed, attributeAllowed }),
    [parameterAllowed, attributeAllowed]
  );

  return returnValue;
}

export default function useFunctionAccess(fn: SpaceFunction): LoadedAccessResult {
  const { isAdmin } = useAuthUser();
  let ids: string[] = [];
  switch (fn.type) {
    case SpaceFunctionType.REMOTE:
      ids.push(fn.id);
      break;
    case SpaceFunctionType.PIPELINE:
      ids = fn.spaceFunctions.map(sf => sf.id);
      break;
    case SpaceFunctionType.NOT_VISIBLE:
    case SpaceFunctionType.INVALID:
    case SpaceFunctionType.VOID:
      break;
    default:
      assertNever(fn);
  }

  const { loading, data } = useQuery<FunctionAccessData, FunctionAccessVars>(
    GET_FUNCTION_ACCESS,
    { skip: isAdmin || !ids.length, variables: { ids } }
  );

  const access = useAccess(
    isAdmin
      ? Array<FunctionAccess>(ids.length).fill(ALL_ACCESS, 0, ids.length)
      : data?.nodes.map(n => n.access) || [],
    fn
  );
  return { loading, ...access };
}
