import React from "react";

import { Input } from "antd";

import { AttributeTypes } from "../../../../constants";
import { FunctionAttribute } from "../../../../types";
import { AttributeTypeSelect } from "../../AttributeTypeSelect";
import IconButton from "../../IconButton";
import * as common from "../common/styledComponents";
import { useListHelper } from "../common/useListHelper";

import * as styled from "./styledComponents";

export const EMPTY_ATTRIBUTES_MESSAGE = "There are no available outputs.";

export interface Props {
  editable: boolean;
  attributes: FunctionAttribute[];
  onAttributesChange: (attributes: FunctionAttribute[]) => void;
}

const createDefault = () => ({
  name: "",
  type: AttributeTypes.STRING,
  key: false
});

export const FunctionAttributes = ({
  editable,
  attributes,
  onAttributesChange
}: Props) => {
  const {
    list: attrs,
    onCreate,
    onUpdate,
    onDelete
  } = useListHelper(
    editable && !attributes.length ? [createDefault()] : attributes,
    createDefault,
    o => o.name,
    onAttributesChange
  );

  return editable || attrs.length ? (
    <>
      <styled.OutputContent>
        <common.FieldColumnHeader>Name</common.FieldColumnHeader>
        <common.FieldColumnHeader>Type</common.FieldColumnHeader>
        <common.FieldColumnHeader center>Key</common.FieldColumnHeader>
        <common.FieldColumnHeader />
        {attrs.map((a, i) => (
          <React.Fragment key={`attribute-${i}`}>
            <Input
              placeholder="Enter a name"
              value={a.name}
              onChange={evt => onUpdate(i, { ...a, name: evt.target.value })}
              disabled={!editable}
            />
            <AttributeTypeSelect
              key={`attribute-${a.name}-${a.type}`}
              value={a.type}
              onChange={type => onUpdate(i, { ...a, type })}
              disabled={!editable}
            />
            <common.Checkbox
              checked={a.key}
              onChange={evt => onUpdate(i, { ...a, key: evt.target.checked })}
            />
            <IconButton
              icon="minus"
              title="Remove"
              type="link"
              onClick={() => onDelete(i)}
              disabled={!editable}
            />
          </React.Fragment>
        ))}
      </styled.OutputContent>
      <common.IconButton
        type="link"
        icon="plus"
        title="Add Output"
        onClick={onCreate}
        disabled={!editable}
      >
        Add Output
      </common.IconButton>
    </>
  ) : (
    <div data-test="empty-attributes">{EMPTY_ATTRIBUTES_MESSAGE}</div>
  );
};
