import React from "react";

import styled from "styled-components";

import { DebouncedTextArea } from "../../../../../../common/DebouncedInput";
import { SpaceConfigAction } from "../../../../../types";
import { FORM_COMPONENT_TYPES } from "../../../constants";
import ButtonTextConfig from "../../ButtonDesignConfig/ButtonTextConfig";
import { Field } from "../../ConfigPanel/styledComponents";
import NameFields from "../../NameFields";
import { SubmittableComponentConfigState } from "../reducer";

interface TextConfigProps {
  state: SubmittableComponentConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

const Root = styled.div``;

export default function TextConfig({ dispatch, state }: TextConfigProps) {
  const { draftComponent } = state;
  const isForm = FORM_COMPONENT_TYPES.includes(draftComponent.type);
  const hasButton = draftComponent.type !== "FUNCTION_HEADLESS";

  return (
    <Root>
      <NameFields
        namePlaceholder="Create User"
        labelPlaceholder="Actions"
        titleLabel="Form Name"
        titlePlaceholder="Create User"
        shouldRenderTitle={isForm}
      />
      {hasButton && (
        <ButtonTextConfig
          placeholder="Submit"
          button_icon={draftComponent.properties.button_icon}
          button_text={draftComponent.properties.button_text}
          onIconChange={value =>
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.button_icon",
                value
              }
            })
          }
          onTextChange={value =>
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.button_text",
                value
              }
            })
          }
        />
      )}
      {isForm && (
        <Field>
          <label>Instructions (optional) </label>
          <DebouncedTextArea
            placeholder="Enter instructions here"
            value={draftComponent.properties.instructions}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.instructions",
                  value
                }
              })
            }
          />
        </Field>
      )}
    </Root>
  );
}
