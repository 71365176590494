import React from "react";

import { Select } from "antd";

import { AttributeNode } from "../../../../../../types";
import Spacer from "../../../../../common/Spacer";
import { AggregationFunctionType } from "../../SpaceChart/types";
import ValidationError from "../ComponentConfigContext/ValidationError";

interface AggregationFunctionSelectorProps {
  type: AggregationFunctionType;
  attribute: string | undefined;
  attributes: AttributeNode[];
  onChangeFunctionType: (func: AggregationFunctionType) => void;
  onChangeAttribute: (attribute: string) => void;
}

export default function AggregationFunctionSelector(
  props: AggregationFunctionSelectorProps
) {
  return (
    <>
      <Select
        placeholder="e.g. users"
        data-test="aggregation-function-type-select"
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={props.type}
        onChange={props.onChangeFunctionType}
      >
        {/* Pre-aggregated uses SUM during chart data calculation, since it ends up being a SUM of a single group */}
        <Select.Option key={AggregationFunctionType.COUNT}>
          Count of records
        </Select.Option>
        <Select.Option key={AggregationFunctionType.SUM}>Sum of</Select.Option>
        <Select.Option key={AggregationFunctionType.PREAGGREGATED}>
          Pre-aggregated value
        </Select.Option>
      </Select>
      <Spacer size="sm" />
      {[AggregationFunctionType.SUM, AggregationFunctionType.PREAGGREGATED].includes(
        props.type
      ) && (
        <Select
          data-test="aggregation-attribute-select"
          placeholder="Select an attribute"
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          value={props.attribute}
          onChange={props.onChangeAttribute}
        >
          {props.attributes.map(a => (
            <Select.Option key={a.sourceName}>{a.name}</Select.Option>
          ))}
        </Select>
      )}
      <ValidationError field="AGGREGATION_FUNCTION" />
    </>
  );
}
