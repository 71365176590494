import { AttributeTypes } from "../../../../../constants";
import { Binding, BindingShape } from "../../../../../types";
import { ComponentNode } from "../../RenderTreeContext";

export function getSchema(_: ComponentNode): Binding[] {
  return [
    {
      name: "email",
      title: "Email",
      shape: BindingShape.SCALAR,
      type: AttributeTypes.STRING
    },
    {
      name: "role",
      title: "Role",
      shape: BindingShape.OBJECT,
      attributes: [
        {
          name: "name",
          title: "Name",
          shape: BindingShape.SCALAR,
          type: AttributeTypes.STRING
        }
      ]
    }
  ];
}
