import { Select as AntSelect } from "antd";
import styled from "styled-components";

export const Select = styled(AntSelect)`
  margin-bottom: ${props => props.theme.spacersm};
  width: 100%;
`;

export const Spacer = styled.div`
  height: ${props => props.theme.spacermd};
`;
