import React from "react";

import { DataValue } from "../../../../../types";
import Panel from "../../../../common/Panel";
import FunctionRecordDescription from "../common/FunctionRecordDescription";
import { FunctionNode } from "../useRecordData/queries";

interface Props {
  dataAccessor: FunctionNode;
  record: Record<string, DataValue>;
}

export default function CoreRecord({ dataAccessor, record }: Props) {
  return (
    <Panel title="Basic Info">
      <FunctionRecordDescription func={dataAccessor} record={record} />
    </Panel>
  );
}
