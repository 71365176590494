import React from "react";

import AttributeValue from "../../common/AttributeValue";
import { SpaceTableAttributeColumn, SpaceTableColumn } from "../useColumns";

export interface SpaceCell<T extends SpaceTableColumn> {
  column: T;
  value: any;
}

interface Props {
  row: any;
  cell: SpaceCell<SpaceTableAttributeColumn>;
}

export default function AttributeCell({ row, cell }: Props) {
  return (
    <AttributeValue
      format={cell.column.options!}
      attribute={cell.column.attribute}
      value={cell.value}
      rowValues={row.values}
    />
  );
}
