import { cloneDeep } from "lodash";

import { SpaceConfigState } from "..";
import { SpaceComponentType, SpaceComponentPackage } from "../../../../../../../types";
import { ElementLayout } from "../../../../../layout/util";
import { ComponentConfigState } from "../../../../../types";
import { INITIAL_STATE, TreeNode } from "../reducer";
import makeComponent from "../util/makeComponent";

export default function createNewSpaceState(
  packages: Map<SpaceComponentType, SpaceComponentPackage>
): SpaceConfigState {
  const header = makeComponent(new Set(), {
    type: "HEADER",
    container: null,
    slug: "header1",
    layout: new ElementLayout({
      left: "2%",
      top: "0px",
      width: "96%",
      height: "86px",
      snapToGrid: true
    })
  });
  const params = makeComponent(new Set(), {
    type: "PARAMS",
    container: null,
    slug: "params",
    properties: { url_parameters: [] }
  });

  const headerTreeNode: TreeNode = {
    slug: header.slug,
    container: null,
    treeNodes: [],
    type: "HEADER"
  };

  const paramsTreeNode: TreeNode = {
    slug: params.slug,
    container: null,
    treeNodes: [],
    type: "PARAMS"
  };

  const treeRoot = {
    slug: "root",
    container: null,
    treeNodes: [headerTreeNode, paramsTreeNode],
    type: null
  };

  return {
    ...cloneDeep(INITIAL_STATE),
    components: Object.entries({
      [header.slug]: header,
      [params.slug]: params
    }).reduce<Record<string, ComponentConfigState>>((acc, [k, v]) => {
      const _package = packages.get(v.type);
      acc[k] =
        _package && _package.getInitialDraftState
          ? _package.getInitialDraftState!(v)
          : { draftComponent: v, type: v.type };
      return acc;
    }, {}),
    tree: treeRoot,
    packages,
    elementLayouts: new Map([
      [
        "header1",
        new ElementLayout({
          left: "2%",
          top: "0px",
          width: "96%",
          height: "86px",
          snapToGrid: true
        })
      ] as [string, ElementLayout]
    ])
  };
}
