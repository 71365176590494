import React, { ReactNode } from "react";

import { Input, Radio, Select } from "antd";
import styled from "styled-components";

import { AttributeValueInputProps } from "..";
import { FormItemNoBottomMargin } from "../../StyledComponents";
import { FieldType } from "../constants";

interface Props extends AttributeValueInputProps {
  inputType?: "number" | "time" | "date" | undefined;
  step?: number;
}

const MinWidthSelect = styled(Select)`
  min-width: 100px;
`;
MinWidthSelect.displayName = "MinWidthSelect";

const Warning = styled.div`
  margin-top: ${props => props.theme.spacerxs};
  color: ${props => props.theme.warningBorderColor};
  font-size: 12px;
`;
Warning.displayName = "Warning";

const RadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: ${props => props.theme.spacerxs};
    }
  }
`;
RadioGroup.displayName = "RadioGroup";

export default function StringInput({
  form,
  sourceName,
  value,
  fieldOptions,
  sourceNullable,
  displayNullPlaceholder,
  placeholder,
  fieldType,
  rows,
  disabled,
  inputType,
  options,
  step,
  generated,
  sourceType,
  verbose,
  useSimpleJsonInput,
  onValidate,
  ...others
}: Props) {
  const inputProps = {
    ...others,
    type: inputType,
    step: inputType === "time" ? step : undefined,
    placeholder: placeholder ? placeholder : !displayNullPlaceholder ? "" : "NULL",
    rows,
    disabled
  };
  const decorator = form
    ? form.getFieldDecorator(sourceName, {
        initialValue: value,
        ...fieldOptions
      })
    : (comp: ReactNode) => comp;

  switch (fieldType) {
    case FieldType.RADIO:
      const radioComponent = (
        <RadioGroup>
          {options!.map(o => (
            <Radio value={o.value}>{o.label}</Radio>
          ))}
          {sourceNullable && <Radio value={null}>Null</Radio>}
        </RadioGroup>
      );
      const radioValue = options!.find(o => o.value === value) ? value : undefined;
      return (
        <FormItemNoBottomMargin>
          {form
            ? form.getFieldDecorator(sourceName, {
                ...fieldOptions,
                initialValue: radioValue
              })(radioComponent)
            : radioComponent}
          {!radioValue && !!value && (
            <Warning>
              This field could not be pre-filled because the value did not match the
              available options.
            </Warning>
          )}
        </FormItemNoBottomMargin>
      );
    case FieldType.DROPDOWN:
      const dropdownValue = options!.find(o => o.value === value) ? value : undefined;
      const dropdownComponent = (
        <MinWidthSelect
          key={sourceName + "Select"}
          placeholder="Select one"
          showSearch
          filterOption={(input, option) => {
            if (!option.props.children || typeof option.props.children !== "string") {
              return false;
            }
            return (
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
        >
          {options!.map((o, i) => (
            <Select.Option key={sourceName + "selectOption" + i} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
          {/* this will work because the space form adapter changes empty strings to nulls 
              and for now every form that renders as a dropdwon is in spaces */}
          {sourceNullable && <Select.Option value={""}>Null</Select.Option>}
        </MinWidthSelect>
      );

      return (
        <FormItemNoBottomMargin>
          {form
            ? form.getFieldDecorator(sourceName, {
                ...fieldOptions,
                initialValue: dropdownValue
              })(dropdownComponent)
            : dropdownComponent}
          {!dropdownValue && !!value && (
            <Warning>
              This field could not be pre-filled because the value did not match the
              available options.
            </Warning>
          )}
        </FormItemNoBottomMargin>
      );
    case FieldType.TEXTAREA:
    case FieldType.TEXT_INPUT:
    default:
      return (
        <FormItemNoBottomMargin>
          {decorator(
            rows !== undefined || fieldType === FieldType.TEXTAREA ? (
              <Input.TextArea {...inputProps} autosize={{ minRows: 3, maxRows: 6 }} />
            ) : (
              <Input {...inputProps} />
            )
          )}
        </FormItemNoBottomMargin>
      );
  }
}
