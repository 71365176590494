import React from "react";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import DatabaseIcon from "../../../../assets/database.svg";
import ComponentsIcon from "../../../../assets/plus-square-outline.svg";
import ComponentTreeIcon from "../../../../assets/tree-icon.svg";
import { MENU_DRAWER_SIZE } from "../../../../cssConstants";
import { formatTestHandle } from "../../../../test";
import usePaths from "../../../common/hooks/usePaths";
import { Theme } from "../../../common/ThemeContainer";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { SpaceConfigMenuType, SpaceConfigMenuPathItem } from "../index";

import AppDataFlyout from "./AppDataFlyout";
import ComponentsFlyout from "./ComponentsFlyout";
import ComponentTreeFlyout from "./ComponentTreeFlyout";
import { FunctionConfigFlyout } from "./FunctionConfigFlyout";
import * as styled from "./styledComponents";
import { FunctionConfigMenuItemProps } from "./types";

export interface MenuProps<T = any> {
  menuPath: SpaceConfigMenuPathItem[];
  onOpen: (pathItem: SpaceConfigMenuPathItem<T>) => void;
  setMenuPath: (...menuPaths: SpaceConfigMenuPathItem<T>[]) => void;
  onClose: () => void;
}

interface MenuDefinition {
  Component: React.ComponentType<MenuProps>;
  name: string;
}

const menuMap: Record<SpaceConfigMenuType, MenuDefinition> = {
  APP_DATA: { Component: AppDataFlyout, name: "App data" },
  COMPONENTS: { Component: ComponentsFlyout, name: "Components" },
  FUNCTION_CONFIG: {
    Component: FunctionConfigFlyout as React.ComponentType<
      MenuProps<FunctionConfigMenuItemProps>
    >,
    name: "Function Editor"
  },
  COMPONENT_TREE: { Component: ComponentTreeFlyout, name: "Component tree" }
};

const getComponent = (pathItem: SpaceConfigMenuPathItem | undefined) =>
  pathItem ? menuMap[pathItem.type].Component : () => null;

interface Props {
  menuPath: SpaceConfigMenuPathItem[];
}

const COLLAPSED_WIDTH = 40;

export default function Sider({ menuPath }: Props) {
  const { editMode, spaceSlug } = useStableSpaceContext();
  const { getEditSpace } = usePaths();
  const navigate = useNavigate();

  const tabs = [];
  tabs.push(createTab("COMPONENTS", ComponentsIcon));
  tabs.push(createTab("APP_DATA", DatabaseIcon));
  tabs.push(createTab("COMPONENT_TREE", ComponentTreeIcon));

  const setPath = (...menu: SpaceConfigMenuPathItem[]) => {
    navigate(getEditSpace(spaceSlug, { menu }));
  };

  if (!editMode) return null;

  const componentTreeOpen = menuPath[0] && menuPath[0].type === "COMPONENT_TREE";

  return (
    <styled.ThemeContainer theme={Theme.Dark}>
      <styled.AntSider
        collapsed
        collapsedWidth={COLLAPSED_WIDTH}
        className={classNames({
          push: componentTreeOpen
        })}
      >
        {tabs.map(t => (
          <MenuTab
            key={t.type}
            active={!!menuPath.length && t.type === menuPath[0].type}
            tab={t}
            onClick={() => {
              if (t.type !== menuPath[0]?.type) {
                setPath({ type: t.type });
              } else {
                setPath();
              }
            }}
          />
        ))}
        {menuPath.length > 0 && (
          <styled.Drawer
            getContainer={false}
            width={MENU_DRAWER_SIZE}
            closable
            maskClosable
            mask={!componentTreeOpen}
            maskStyle={{ marginLeft: `${COLLAPSED_WIDTH}px` }}
            visible={menuPath.length > 0}
            onClose={() => setPath()}
            placement="left"
          >
            <styled.DrawerContainer>
              {React.createElement(getComponent(menuPath[0]), {
                ...menuPath[0].props,
                menuPath,
                onOpen: pathItem => setPath(menuPath[0], pathItem),
                onClose: () => setPath()
              })}
            </styled.DrawerContainer>
          </styled.Drawer>
        )}
        {menuPath.length > 1 && (
          <styled.ExpandDrawer
            getContainer={false}
            width="100%"
            closable
            maskClosable
            visible={menuPath.length > 1}
            onClose={() => setPath(menuPath[0])}
            placement="left"
          >
            {React.createElement(getComponent(menuPath[1]), {
              ...menuPath[1].props,
              menuPath,
              onOpen: _ => null,
              setMenuPath: (...menuPaths) => setPath(menuPath[0], ...menuPaths),
              onClose: () => setPath(menuPath[0])
            })}
          </styled.ExpandDrawer>
        )}
      </styled.AntSider>
    </styled.ThemeContainer>
  );
}

interface MenuTabProps {
  tab: LeftNavTab;
  active: boolean;
  onClick: () => void;
}

interface IconProps {
  onClick: () => void;
}

function createTab(type: SpaceConfigMenuType, Icon: React.ComponentType<IconProps>) {
  return {
    type,
    name: menuMap[type].name,
    Component: menuMap[type].Component,
    Icon
  };
}

type LeftNavTab = ReturnType<typeof createTab>;

const MenuTab = ({ tab, active, onClick }: MenuTabProps) => {
  return (
    <styled.MenuTab data-test={formatTestHandle(`${tab.name}-tab`)}>
      <styled.Tooltip title={tab.name} placement="right">
        <styled.IconWrapper active={active}>
          <tab.Icon onClick={onClick} />
        </styled.IconWrapper>
      </styled.Tooltip>
    </styled.MenuTab>
  );
};
