export function planName(name: string | undefined) {
  const slug = name?.toLowerCase();

  switch (slug) {
    case "basic":
      return "Basic";
    case "teams":
      return "Teams";
    case "business":
      return "Business";
    case "enterprise":
      return "Enterprise";
    default:
      return name;
  }
}

export type BillingPlan =
  | "Starter"
  | "Teams"
  | "Essentials"
  | "Business"
  | "Growth"
  | "Enterprise";

export const nextPlanUp = (planName?: string) => {
  switch (planName?.toLowerCase()) {
    case "starter":
    case "basic":
      return "Teams";
    case "essentials":
    case "teams":
      return "Business";
    case "growth":
    case "business":
      return "Enterprise";
    case "enterprise":
      return null;
    default:
      return "Teams";
  }
};

export const formatDescription = (description?: string) => {
  if (!description) {
    return;
  }
  const match = description.match(
    /([0-9]+) × ([a-zA-Z]+) \(at \$([0-9]+).00 \/ ([a-z]+)\)/
  );
  if (!match) {
    return description;
  }
  const [_, _quantity, product, price, term] = match;

  const unitPrice = parseInt(price);

  const monthlyPrice = term === "year" ? unitPrice / 12 : unitPrice;

  return `${product} (${
    term === "year" ? "year" : "month"
  }) - ${monthlyPrice.toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  })} per user/month`;
};

export const planTier = (slug?: string) => {
  switch (slug?.toLowerCase()) {
    case "starter":
    case "basic":
      return 0;
    case "teams":
      return 1;
    case "business":
      return 2;
    case "essentials":
      return 3;
    case "growth":
      return 4;
    case "enterprise":
      return 5;
    default:
      return 0;
  }
};
