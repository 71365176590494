import React from "react";

import { Button, Icon, Input, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import styled from "styled-components";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import { CredentialFormProps } from "./types";

export const StyledUpload = styled(Upload)`
  .anticon-close,
  .anticon-close:hover,
  .anticon-paper-clip,
  .ant-upload-list {
    color: ${props => props.theme.secondaryColor};
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: transparent;
  }
`;

export const PROJECT_ID = "projectId";
export const DATASET_ID = "datasetId";

export function DatasetProjectFieldSet(props: CredentialFormProps) {
  const { getFieldDecorator } = props.form;
  return (
    <>
      <FormItem label={props.includeLabel && "Project Id"}>
        {getFieldDecorator(PROJECT_ID, {
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(<Input placeholder="Project ID" disabled={props.isLoading} />)}
      </FormItem>
      <FormItem label={props.includeLabel && "Dataset Id"}>
        {getFieldDecorator(DATASET_ID, {
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(<Input placeholder="Dataset ID" disabled={props.isLoading} />)}
      </FormItem>
    </>
  );
}

export function UploadKeyField(props: CredentialFormProps) {
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const { getFieldDecorator } = props.form;

  return (
    <>
      <FormItem label={props.includeLabel && "Key"}>
        {getFieldDecorator("key", {
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <StyledUpload
            accept=".json"
            disabled={props.isLoading}
            fileList={fileList}
            showUploadList={true}
            beforeUpload={(file: any) => {
              file.text().then((key: string) => {
                props.form.setFieldsValue({ key });
              });
              return false;
            }}
            onChange={data => {
              setFileList([data.file]);
            }}
            onRemove={() => {
              setFileList([]);
              props.form.setFieldsValue({ key: null });
            }}
          >
            <Button disabled={props.isLoading}>
              <Icon type="upload" /> Upload
            </Button>
          </StyledUpload>
        )}
      </FormItem>
    </>
  );
}

export function BigqueryFields(props: CredentialFormProps) {
  return (
    <>
      <DatasetProjectFieldSet {...props} />
      <UploadKeyField {...props} />
    </>
  );
}

export function FirestoreFields(props: CredentialFormProps) {
  return (
    <>
      <UploadKeyField {...props} />
    </>
  );
}
