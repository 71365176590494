import React, { useEffect, useMemo } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Select, Tabs } from "antd";
import { ApolloError } from "apollo-client";
import SplitPane from "react-split-pane";

import {
  APIFiltersOption,
  APISortByOption,
  BaseFunctionName,
  ClientErrorInfo,
  FunctionAuthorizationFlowInput,
  FunctionParameterInput,
  Metadata
} from "../../../../types";
import { assertNever } from "../../../util/assertNever";
import { useEnvironmentContext } from "../../contexts/EnvironmentContext";
import { Empty } from "../../Empty";
import * as common from "../common/styledComponents";
import {
  BaseFunctionParameterMapping,
  EditorTab,
  ParameterValues,
  PreviewResult,
  FunctionValidationStatus
} from "../index";
import { SupportedIntegration } from "../support";
import { parseIdentifiers } from "../useFunctionEditor/parsers";

import { FunctionResponse } from "./FunctionResponse/FunctionResponse";
import { ParameterValuesForm } from "./ParameterValuesForm/ParameterValuesForm";
import {
  HttpTransformExceptions,
  HttpTransformResults,
  PREVIEW_FUNCTION_MUTATION,
  PreviewResponse,
  PreviewVariables
} from "./queries";
import { FunctionPreviewAction, FunctionPreviewState } from "./reducer";
import * as styled from "./styledComponents";
import useFunctionEnvironments from "./useFunctionEnvironments";
import {
  fillDefaults,
  isValidListOutput,
  readAttributes,
  readReturnSchema,
  serialize
} from "./util";

export interface Props<M> {
  baseFunctionId: string;
  baseFunctionName: BaseFunctionName;
  // TODO: remove `string` type
  baseFunctionParameterMapping: BaseFunctionParameterMapping | string;
  metadata: Metadata<M>;
  reducer: string;
  metadataReducer: string;
  integration: SupportedIntegration;
  parameters: FunctionParameterInput[];
  filtersOptions: APIFiltersOption[];
  sortByOptions: APISortByOption[];
  authorizationFlows: FunctionAuthorizationFlowInput[];
  validationStatus: FunctionValidationStatus;
  previewer: [FunctionPreviewState, React.Dispatch<FunctionPreviewAction>];
  onActiveEditorTabChange: (tab: EditorTab) => void;
  onPreviewResult: (result: PreviewResult) => void;
}

const GENERAL_ERROR = "An error occurred while running preview.";

export function FunctionPreview<M>({
  parameters,
  baseFunctionId,
  baseFunctionName,
  baseFunctionParameterMapping,
  metadata,
  reducer,
  integration,
  metadataReducer,
  filtersOptions,
  sortByOptions,
  authorizationFlows,
  validationStatus,
  previewer,
  onActiveEditorTabChange,
  onPreviewResult
}: Props<M>) {
  const { getCurrentEnvironment } = useEnvironmentContext();
  const { environments } = useFunctionEnvironments(baseFunctionId);

  const [
    {
      error,
      environmentId,
      isValidResponse,
      parameterValues,
      previewResults,
      previewExceptions,
      showResults
    },
    dispatch
  ] = previewer;

  useEffect(() => {
    // If no environment is selected, select the current environment if it is
    // supported, otherwise falling back to the default environment.
    if (environmentId) {
      return;
    }
    const id = getCurrentEnvironment().id;
    if (environments.some(env => env.id === id)) {
      dispatch({ type: "SET_ENVIRONMENT_ID", payload: id });
    } else {
      dispatch({
        type: "SET_ENVIRONMENT_ID",
        payload: environments.find(e => e.isDefault)?.id
      });
    }
  }, [environments, environmentId, getCurrentEnvironment, dispatch]);

  const authorizationFlowId = useMemo(
    () =>
      authorizationFlows.find(flow => flow.environmentId === previewer[0].environmentId)
        ?.authorizationFlowId,
    [authorizationFlows, previewer]
  );

  const [previewFunction, { loading }] = useMutation<
    PreviewResponse<HttpTransformResults, HttpTransformExceptions>,
    PreviewVariables
  >(PREVIEW_FUNCTION_MUTATION);

  const onParameterValuesChange = (updated: ParameterValues) => {
    dispatch({ type: "SET_PARAMETER_VALUES", payload: updated });
  };

  const onPreviewError = (message: string, errorInfo?: ClientErrorInfo) => {
    dispatch({ type: "SET_ERROR", payload: { message, errorInfo } });
  };

  const onPreviewResponse = (
    results: HttpTransformResults,
    exceptions: HttpTransformExceptions
  ) => {
    const isValidResponse = isValidListOutput(results.transformedData);
    const returnSchema = readReturnSchema(results.transformedData);
    dispatch({
      type: "SET_RESPONSE",
      payload: { results, exceptions, returnSchema, isValidResponse }
    });

    if (isValidResponse) {
      onPreviewResult({
        returnSchema: readReturnSchema(results.transformedData),
        attributes: readAttributes(results.transformedData)
      });
    }
  };

  const onRun = async () => {
    dispatch({ type: "RESET" });

    const [_, error] = parseIdentifiers(
      integration,
      baseFunctionName,
      baseFunctionParameterMapping,
      metadata
    );
    if (error) {
      return onPreviewError(error.message);
    }

    const serializedValues = serialize(fillDefaults(parameterValues));
    const previewParameters = parameters.filter(p => p.name in serializedValues);

    const variables: PreviewVariables = {
      functionId: baseFunctionId,
      environmentId: environmentId!,
      parameterMapping: baseFunctionParameterMapping,
      parameters: previewParameters,
      reducer: {
        metadata: "metadata",
        data: "data",
        transformedData: reducer,
        transformedMetadata: metadataReducer
      },
      values: serializedValues,
      authorizationFlowId
    };
    try {
      const result = await previewFunction({ variables });
      const response = result.data?.previewFunction;

      if (!response?.__typename) {
        return onPreviewError(GENERAL_ERROR);
      }

      switch (response.__typename) {
        case "PreviewFunctionResultSuccess":
          return onPreviewResponse(response.results, response.exceptions);
        case "PermissionErrorResult":
          return onPreviewError(response.message);
        case "ClientErrorResult":
          return onPreviewError(response.message, response.errorInfo);
        default:
          return assertNever(response);
      }
    } catch (err) {
      const e = err as ApolloError;
      const message = e.graphQLErrors?.length
        ? e.graphQLErrors[0].message
        : GENERAL_ERROR;
      onPreviewError(message);
    }
  };

  return (
    <>
      <SplitPane minSize={300}>
        <styled.PreviewFormPane>
          <styled.Title>Run Function</styled.Title>
          <Select
            value={environmentId}
            onChange={(id: string) =>
              dispatch({ type: "SET_ENVIRONMENT_ID", payload: id })
            }
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            placeholder="Select an environment"
            disabled={baseFunctionId === ""}
          >
            {environments.map(env => (
              <Select.Option key={env.id}>{env.name} Environment</Select.Option>
            ))}
          </Select>
          <span>
            <styled.RunButton
              type="primary"
              loading={loading}
              disabled={
                !environmentId || validationStatus !== FunctionValidationStatus.VALID
              }
              data-test="runButton"
              onClick={onRun}
            >
              Run Function
            </styled.RunButton>
          </span>
          {!!parameters.length && (
            <styled.Instructions>Specify inputs for your query:</styled.Instructions>
          )}
          <ParameterValuesForm
            filtersOptions={filtersOptions}
            parameters={parameters}
            sortByOptions={sortByOptions}
            parameterValues={parameterValues}
            onParameterValuesChange={onParameterValuesChange}
          />
          <styled.Reminder>
            Don't forget to{" "}
            <styled.LinkButton
              type="link"
              onClick={() => onActiveEditorTabChange(EditorTab.OUTPUTS)}
            >
              configure outputs
            </styled.LinkButton>{" "}
            for this function. You can also{" "}
            <styled.LinkButton
              type="link"
              onClick={() => onActiveEditorTabChange(EditorTab.TRANSFORM)}
            >
              use transformers
            </styled.LinkButton>{" "}
            to manipulate the response.
          </styled.Reminder>
        </styled.PreviewFormPane>
        <common.Tabs animated={false}>
          <Tabs.TabPane tab="Transformed" key="transformed">
            {showResults && (
              <FunctionResponse
                loading={loading}
                isValid={isValidResponse}
                error={error}
                data={previewResults.transformedData}
                dataException={previewExceptions.transformedData?.error}
                metadata={previewResults.transformedMetadata}
                metadataException={previewExceptions.transformedMetadata?.error}
              />
            )}
            {!showResults && (
              <Empty
                title="Waiting for liftoff!"
                instructions="Click Preview to execute your function and see the results"
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Raw" key="raw">
            {showResults && (
              <FunctionResponse
                loading={loading}
                error={error}
                data={previewResults.data}
                metadata={previewResults.metadata}
              />
            )}
            {!showResults && (
              <Empty
                title="Waiting for liftoff!"
                instructions="Click Preview to execute your function and see the results"
              />
            )}
          </Tabs.TabPane>
        </common.Tabs>
      </SplitPane>
    </>
  );
}
