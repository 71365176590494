import { isError } from "../../util";

export interface SpaceReloadComponentEvent {
  components: string[];
}

export type SpaceEventMessage = SpaceReloadComponentEvent;
export type SpaceEventType = "refresh_component";

export type SpaceEventCallback = (message: SpaceEventMessage) => void;

export default class SpaceApi {
  private subscriptions: Map<string, Set<SpaceEventCallback>>;

  constructor() {
    this.subscriptions = new Map();
  }

  public on(type: SpaceEventType, callback: (message: any) => void): void {
    const subs = this.subscriptions.get(type) || new Set();
    this.subscriptions.set(type, subs.add(callback));
  }

  public off(type: SpaceEventType, callback: (message: any) => void): void {
    const subs = this.subscriptions.get(type);
    if (!subs) return;
    subs?.delete(callback);
  }

  public notify(type: SpaceEventType, message: any): void {
    // Notify on a copy of subscriptions as side effects triggered
    // by callbacks can mutate the set.
    const callbacks = Array.from(this.subscriptions.get(type) || []);
    callbacks.forEach(cb => {
      try {
        cb(message);
      } catch (e) {
        console.error(e, isError(e) ? e.stack : undefined); // eslint-disable-line no-console
      }
    });
  }
}
