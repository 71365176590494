import React, { Dispatch } from "react";

import { Link } from "react-router-dom";

import DataIcon from "../../../../assets/icons/data-icon.svg";
import SettingsIcon from "../../../../assets/icons/settings-icon.svg";
import StateIcon from "../../../../assets/icons/state-icon.svg";
import { colors } from "../../../../cssConstants";
import * as common from "../../../common/StyledComponents";
import { assertNever } from "../../../util/assertNever";
import { State } from "../reducer";
import { ReducerAction } from "../reducer/actions";
import { Tab } from "../types";

import * as styled from "./styledComponents";

export interface Item {
  tab: Tab;
  title: string;
  description: string;
}

interface Props {
  dispatch: Dispatch<ReducerAction>;
  state: State;
  tab: Tab;
  className?: string;
}

const tabs: Item[] = [
  {
    tab: Tab.GENERAL,
    title: "General settings",
    description: "Manage name, description, and who can see and access this queue."
  },
  {
    tab: Tab.DATA,
    title: "Data",
    description: "Manage data shown in your queue."
  },
  {
    tab: Tab.STATES,
    title: "States and actions",
    description:
      "Manage states and call-to-actions for your queue. Each state can have its own set of unique actions."
  }
];

const TabIcon = ({ tab, selected }: { tab: Tab; selected: boolean }) => {
  const color = selected ? colors.primaryColor : colors.surfaceSecondary;
  switch (tab) {
    case Tab.GENERAL:
      return <SettingsIcon fill={color} />;
    case Tab.DATA:
      return <DataIcon fill={color} />;
    case Tab.STATES:
      return <StateIcon fill={color} />;
    default:
      return assertNever(tab);
  }
};

const Navigation = ({ dispatch, tab, state, className = "" }: Props) => {
  return (
    <div className={className}>
      <common.Timeline>
        {tabs.map(item => (
          <common.TimelineItem
            key={item.tab}
            dot={<TabIcon tab={item.tab} selected={item.tab === tab} />}
          >
            <Link to={`?tab=${item.tab}`}>
              <common.TimelineTitle selected={tab === item.tab}>
                {item.title}
              </common.TimelineTitle>
            </Link>
            <common.TimelineDescription>
              {tab === item.tab ? item.description : ""}
            </common.TimelineDescription>
          </common.TimelineItem>
        ))}
      </common.Timeline>
      {tab === Tab.STATES && (
        <>
          <styled.Divider />
          <styled.StateList state={state} dispatch={dispatch} />
        </>
      )}
    </div>
  );
};

export default Navigation;
