import React from "react";

import { Alert, Typography } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import styled from "styled-components";

import { Credentials } from "../../../../../types";
import { CredentialsUpdateForms } from "../../../../common/Adapters";
import useExternalCidrRanges from "../../../../common/hooks/useExternalCidrRanges";
import { inboundIpConfig } from "../../../../setup_flow/CredentialsForm";

import { Row } from "./common/styledComponents";
import SecureConnectionRow from "./SecureConnectionRow";

const StyledConnectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacermd};

  .ant-form-item {
    display: flex;
    flex-direction: row;

    label {
      font-size: ${props => props.theme.mediumFontSize};
      font-weight: ${props => props.theme.boldFontWeight};
      color: ${props => props.theme.textColorMid};
    }
    margin-bottom: ${props => props.theme.spacersm};
  }

  .ant-form-item-control-wrapper {
    flex-grow: 1;
  }
`;

const UpdateErrorAlert = styled(Alert)`
  margin-bottom: ${props => props.theme.spacerlg};
`;

export interface Props {
  integration: string;
  credentials: Credentials;
  form: WrappedFormUtils<any>;
  isUpdateLoading: boolean;
  error: string;
}

const DataSourceSettingsForm = ({
  integration,
  credentials,
  isUpdateLoading,
  form,
  error
}: Props) => {
  const { externalCidrRanges } = useExternalCidrRanges();
  const ConnectionSettingsUpdateForm = CredentialsUpdateForms[integration];
  if (ConnectionSettingsUpdateForm === null || integration === "queues") return null;

  return (
    <StyledConnectionInfo>
      {error && (
        <UpdateErrorAlert
          message="There was an error updating your data source:"
          description={<Typography.Text code>{error}</Typography.Text>}
          type="error"
        />
      )}
      <ConnectionSettingsUpdateForm
        credentials={credentials as Credentials}
        form={form}
        isLoading={isUpdateLoading}
        includeLabel
        allowPartialSubmission
      />
      {inboundIpConfig.has(integration) && (
        <Row>
          <label>IP addresses to allow:</label>
          <span>{externalCidrRanges.join(", ")}</span>
        </Row>
      )}
      <SecureConnectionRow credentials={credentials} />
    </StyledConnectionInfo>
  );
};

export default DataSourceSettingsForm;
