import * as codemirror from "codemirror";
import { Mode } from "codemirror";

interface State {
  innerState: any;
}

codemirror.defineMode("jstl", function (config): Mode<State> {
  const jsMode = codemirror.getMode(config, "javascript");

  return {
    startState: function () {
      return { innerState: null };
    },
    token: function (stream, state) {
      if (!state.innerState) {
        if (stream.match("${")) {
          state.innerState = jsMode.startState!();
          return "jstl-start";
        } else {
          stream.next();
        }
      } else {
        const jsToken = jsMode.token!(stream, state.innerState);
        if (!jsToken) {
          if (
            state.innerState.lastType === "}" &&
            state.innerState.lexical.type === "stat"
          ) {
            state.innerState = null;
            return "jstl-end";
          }
        } else {
          return jsToken;
        }
      }
      return null;
    }
  };
});
