import React from "react";

import { Select as AntSelect } from "antd";
import styled from "styled-components";

import { AttributeColumn } from "..";
import { AttributeNode } from "../../../../../../../types";
import useStableId from "../../../../../../common/hooks/useStableId";
import {
  ValueRenderOption,
  RENDER_TYPE_DISPLAY_NAMES,
  RENDER_TYPES_WITH_VALUE_RENDER_OPTIONS,
  getRenderTypes,
  RenderType
} from "../../AttributeValue/options";
import { useComponentConfigContext } from "../../ComponentConfigContext";
import { ConfigSection } from "../../ConfigPanel";
import { Select } from "../../ConfigPanel/styledComponents";

import DateFormatSelection from "./DateFormatSelection";
import { ensureValidAttributeColumn } from "./util";
import ValueRenderOptions from "./ValueRenderOptions";

const { Option } = AntSelect;

export const Root = styled.div`
  position: relative;
  border-top: 0px;

  .has-error {
    display: flex;
    flex-direction: column;
  }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.labelColor};
  padding: ${props => props.theme.spacersm};
`;

export const Section = styled.section`
  margin-bottom: ${props => props.theme.spacerlg};

  &:last-of-type {
    margin-bottom: 0;
  }

  & > label {
    margin-bottom: ${props => props.theme.spacersm};
    font-size: ${props => props.theme.defaultFontSize};
    font-weight: normal;
  }
`;

interface Props {
  attribute: AttributeNode;
  configuration: AttributeColumn;
  path?: string;
}

export default function AttributeColumnConfig({
  attribute,
  configuration,
  path
}: Props) {
  const domId = useStableId("filterConfigurationPanel");
  const componentConfigContext = useComponentConfigContext();
  if (!attribute) {
    return (
      <Root>
        <ErrorMessage>This field no longer exists.</ErrorMessage>
      </Root>
    );
  }

  if (path === undefined) return null;

  const renderTypes = getRenderTypes(attribute);
  return (
    <Root id={domId}>
      <ConfigSection title={attribute.sourceName}>
        <label>Display Type</label>
        <Select
          data-test="renderTypeSelect"
          value={configuration.render_type || "default"}
          dropdownMatchSelectWidth={false}
          onChange={(render_type: any) => {
            componentConfigContext.dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path,
                value: ensureValidAttributeColumn(attribute, render_type, configuration)
              }
            });
          }}
          getPopupContainer={() => document.getElementById(domId) as HTMLElement}
        >
          {renderTypes.map(opt => {
            return (
              <Option key={opt} title={RENDER_TYPE_DISPLAY_NAMES[opt]}>
                {RENDER_TYPE_DISPLAY_NAMES[opt]}
              </Option>
            );
          })}
        </Select>
      </ConfigSection>
      {configuration.render_type === RenderType.DATE_TEMPLATE && (
        <DateFormatSelection
          attribute={attribute as AttributeNode}
          configuration={configuration}
          onChange={(date_template: string) => {
            componentConfigContext.dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path,
                value: {
                  ...configuration,
                  date_template
                }
              }
            });
          }}
        />
      )}
      {RENDER_TYPES_WITH_VALUE_RENDER_OPTIONS.includes(configuration.render_type) && (
        <ConfigSection>
          <ValueRenderOptions
            data-test="valueRenderOptions"
            type={configuration.render_type}
            setRenderOptions={(render_options: ValueRenderOption[]) => {
              componentConfigContext.dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path,
                  value: {
                    ...configuration,
                    render_options
                  }
                }
              });
            }}
            key={attribute.id}
            renderOptions={configuration.render_options!}
            attribute={attribute}
          />
        </ConfigSection>
      )}
    </Root>
  );
}
