import React from "react";

import ColumnListManagerSection from "../../common/ColumnListManager/ColumnListManagerSection";
import ColumnOrder from "../../common/ColumnOrder";
import ValidationError from "../../common/ComponentConfigContext/ValidationError";
import { ConfigSection } from "../../common/ConfigPanel";
import { TableConfigState } from "../types";

interface Props {
  state: TableConfigState;
}

interface SectionProps extends Props {
  title: string;
}

export default function ColumnsConfigSection({ title, state }: SectionProps) {
  return (
    <>
      <ColumnListManagerSection
        title={title}
        attributes={state.attributes || []}
        columnLabel="columns"
        errorField={<ValidationError field="COLUMNS" />}
      />
      <ConfigSection title="Sorting">
        <ColumnOrder sortByOptions={state.sortByOptions} />
      </ConfigSection>
    </>
  );
}
