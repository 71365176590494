import React from "react";

import styled from "styled-components";

import { SpaceComponentObject } from "../../../../types";
import {
  useSelectionStateContext,
  useTransformationActionContext
} from "../../layout/TransformationContext/TransformationContext";
import useIsComponentVisible from "../../SpaceRoot/SpaceComponent/common/useIsComponentVisible";
import { useComponentPathContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { DimensionsContextContainer } from "../DimensionsContext";
import Draggable from "../Draggable";
import { useComponentLayoutContext } from "../LayoutContext/LayoutContext";
import Selection from "../Selection/Selection";
import { ElementLayout } from "../util";

import { ELEMENT_Z_INDEX } from "./constants";
import useLayoutRegistrations from "./useLayoutRegistrations";

export interface ElementProps {
  component: SpaceComponentObject;
  children: React.ReactNode;
}

export default function AbsoluteElement({
  component: { slug },
  component,
  children
}: ElementProps) {
  const { layout, elementDOMRect } = useComponentLayoutContext();
  if (!layout) throw new Error("Expected layout for Element.");
  const rootEl = React.useRef<HTMLDivElement | null>(null);
  const { editMode } = useStableSpaceContext();
  const path = useComponentPathContext();
  const { select, hover, unhover, startMove } = useTransformationActionContext();
  const { locked } = useSelectionStateContext();

  useLayoutRegistrations(slug, layout, rootEl);

  const handleDragStart = React.useCallback(
    function handleDragStart(coords: DOMPoint) {
      // Special case `HEADER`, which for historical reasons does not have a path.
      const ensuredPath = component.type === "HEADER" ? component.slug : path;
      startMove(ensuredPath, coords, layout, elementDOMRect);
    },
    [component.type, component.slug, path, layout, elementDOMRect, startMove]
  );

  const isVisible = useIsComponentVisible(component.properties);
  const componentStyle = component.properties.styles?.root || {};
  const style: Partial<ElementLayout> & {
    outline?: string;
  } = React.useMemo(() => {
    const { overflow, ..._layout } = layout;
    const style: Partial<ElementLayout> = ElementLayout.stripNonStyleProps(
      _layout as ElementLayout
    );

    style.display = isVisible ? "block" : "none";

    return style;
  }, [layout, isVisible]);

  return (
    <Root
      ref={rootEl}
      data-test={`element-${slug}`}
      style={style}
      onClick={e => {
        if (!editMode) return;
        e.stopPropagation();
        select(slug);
      }}
      onMouseEnter={() => {
        if (!editMode) {
          return;
        }
        hover(slug);
      }}
      onMouseLeave={() => {
        if (!editMode) return;
        unhover(slug);
      }}
    >
      <Draggable
        disable={!editMode || locked.includes(slug)}
        onDragStart={handleDragStart}
      >
        <ChildrenWrapper
          style={{
            overflow: layout.overflow,
            ...componentStyle
          }}
        >
          <DimensionsContextContainer>{children}</DimensionsContextContainer>
        </ChildrenWrapper>
      </Draggable>
      <Selection component={component} />
    </Root>
  );
}

const Root = styled.div`
  position: absolute;
  // HACK: Make sure components are on top of z-index positioned elements of other SpaceComponents
  //       Like the gutter in JSON Input or the thead of table.
  z-index: ${ELEMENT_Z_INDEX};
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
