import React from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form, Input } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import { FormComponentProps } from "antd/lib/form";
import gql from "graphql-tag";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../constants";
import { publicClient } from "../../../graphql";
import Button from "../../common/Button";
import Message from "../../common/Message";
import { DarkTheme, FormItem } from "../../common/StyledComponents";
import TitleContainer, { ContainerSize } from "../../common/TitleContainer";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

const CHECK_RESET_PASSWORD_TOKEN = gql`
  query CheckResetPasswordToken($uid: String!, $token: String!) {
    passwordResetTokenExpired(uid: $uid, token: $token)
  }
`;

const CONFIRM_RESET_PASSWORD_MUTATION = gql`
  mutation ConfirmPasswordReset($uid: String!, $token: String!, $password: String!) {
    confirmPasswordReset(uid: $uid, token: $token, password: $password) {
      status
      message
    }
  }
`;

const FormContainer = styled.div`
  width: 380px;
  max-width: 100%;
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
`;

const FormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const StyledPasswordInput = styled(PasswordInput)`
  & input {
    &:hover {
      border-color: ${props => props.theme.primaryColor} !important;
    }
  }
`;

interface Fields {
  password1: string;
  password2: string;
}

interface Params extends Record<string, string> {
  uid: string;
  token: string;
}

type Props = FormComponentProps;

const ResetPasswordForm = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const { uid, token } = useParams<Params>();
  const navigate = useNavigate();

  const { loading, data } = useQuery(CHECK_RESET_PASSWORD_TOKEN, {
    client: publicClient,
    variables: { uid, token }
  });
  const [confirmResetPassword] = useMutation(CONFIRM_RESET_PASSWORD_MUTATION, {
    client: publicClient,
    onCompleted: data => {
      const { status, message } = data.confirmPasswordReset;
      switch (status) {
        case "SUCCESS":
          navigate(Routes.LOGIN, {
            state: {
              message: "Log in to your account now with your new password.",
              messageTitle: "Your password has been reset!"
            }
          });
          break;
        case "INVALID_PASSWORD":
          Message.error(message || "This password is too common.");
          break;
        default:
          Message.error(
            "This link has expired. To reset your password, click Forgot Password."
          );
          navigate(Routes.LOGIN);
      }
    }
  });

  if (!loading && _.get(data, "passwordResetTokenExpired", true)) {
    Message.error(
      "This link has expired. To reset your password, click Forgot Password."
    );
    navigate(Routes.LOGIN);
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.form.validateFields((errors: any, values: Fields) => {
      if (errors) {
        return;
      }
      const password = values.password1;
      confirmResetPassword({ variables: { uid, token, password } });
    });
  };

  const validateConfirmPassword = (
    rule: any,
    value: string,
    callback: (msg?: string) => void
  ) => {
    const form = props.form;
    if (value && value !== form.getFieldValue("password1")) {
      callback(VALIDATION_MESSAGES.confirmPassword);
    } else {
      callback();
    }
  };

  return (
    <React.Fragment>
      <DarkTheme />

      <TitleContainer
        title={"Create a new password."}
        description={"To reset your password, create a new one for your account"}
        size={ContainerSize.Large}
      >
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <FormItem>
              {getFieldDecorator("password1", {
                validateTrigger: "onSubmit",
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: VALIDATION_MESSAGES.requiredField
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters"
                  }
                ]
              })(<StyledPasswordInput />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator("password2", {
                validateTrigger: "onSubmit",
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: VALIDATION_MESSAGES.requiredField
                  },
                  { validator: validateConfirmPassword }
                ]
              })(<Input type="password" placeholder="Confirm password" />)}
            </FormItem>
            <FormFooter>
              <Button htmlType="submit" type="primary">
                Reset
              </Button>
            </FormFooter>
          </Form>
        </FormContainer>
      </TitleContainer>
    </React.Fragment>
  );
};

export default Form.create<Props>({
  name: "resetPasswordForm"
})(ResetPasswordForm);
