import React, { forwardRef, useEffect, useImperativeHandle, Ref } from "react";

import { Spin } from "antd";
import classNames from "classnames";
import { useTable, useRowSelect, Column, TableRowProps } from "react-table";
import styled, { css } from "styled-components";

import usePrevious from "../hooks/usePrevious";

interface TableProps {
  columns: ReadonlyArray<Column<any>>;
  data: Record<string, any>[] | undefined;
  loading?: boolean;
  emptyState?: boolean | React.ReactNode;
  multiSelect?: boolean;
  className?: string;
  Row?: React.ComponentType<
    TableRowProps & {
      data: Record<string, any>;
      onClick: (evt: React.MouseEvent) => void;
    }
  >;
  onSelectRows?: (rowIndexes: number[]) => void;
}

export interface TableInstance {
  selectRows: (rowIndexes: number[]) => void;
  toggleAllRowsSelected: (selected?: boolean) => void;
}

const Table = styled.table`
  width: 100%;
  height: 100%;
  background: white;
  font-size: 13px;
  font-weight: 400; // TODO why is 500 here so much heavier than in Figma
`;

const THead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
  white-space: nowrap;
  background: white;
`;

const TR = styled.tr``;

const THeadTR = styled(TR)``;

// const TBodyTR = styled(TR)`
//   :hover {
//     background-color: ${props => props.theme.containerHover};
//   }
//   &.isSelected {
//     background-color: ${props => props.theme.containerPrimary};
//   }
//   &:first-of-type > td {
//     border-top: none;
//   }
// `;

const tdCss = css`
  border: solid 1px ${props => props.theme.tableBorderColor};
  border-left: none;
  border-right: none;
  font-size: 13px;
  &:first-of-type {
    padding-left: 50px;
  }
  &:last-of-type {
    padding-right: 50px;
  }
`;

const TH = styled.th`
  color: ${props => props.theme.textColorMid};
  font-weight: 500;
  font-size: 13px;
  padding: 0;
  &:first-of-type > * {
    padding-left: 50px;
  }
  &:last-of-type > * {
    padding-right: 50px;
  }
`;

const THWrap = styled.div`
  border: solid 1px ${props => props.theme.tableBorderColor};
  border-left: none;
  border-right: none;
  padding: 15px;
`;

const TBody = styled.tbody``;

const CenteredTD = styled.td`
  ${tdCss}
  height: 100%;
  width: 100%;
  text-align: center;
`;

const TD = styled.td`
  ${tdCss}
  height: 40px;
  padding: 0 15px;
`;

const TDWrap = styled.div`
  /* 
    Truncate with ellipsis without using white-space: nowrap which 
    expands the table past 100% width.
  */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TFoot = styled.tfoot`
  height: 100%;
`;

const emptyData: Record<string, any>[] = [];

/**
 * All params should be memoized before being provided to <Table />, otherwise
 * useTable will recompute on every render.
 *
 * @param columns - Column[]
 * @param data - Record<string, any>[]
 * @param loading - boolean | undefined
 * @param emptyState - boolean | React.ReactNode | undefined
 * @param multiSelect - boolean | undefined - default false
 * @param className - string | undefined
 * @param Row - React.ComponentType<RowProps I guess> | undefined
 * @param onSelectRows - (rowIds: string[]) => void | undefined
 */
export default forwardRef(function TableNew(
  {
    columns,
    data = emptyData,
    loading,
    emptyState,
    multiSelect = false,
    className = "",
    Row = TR as TableProps["Row"],
    onSelectRows = () => {}
  }: TableProps,
  ref: Ref<TableInstance>
) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
    toggleAllRowsSelected
  } = useTable(
    {
      columns,
      data,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case "toggleRowSelected":
            if (multiSelect) return newState;
            return {
              ...newState,
              selectedRowIds: { [action.id]: true }
            };
          default:
            return newState;
        }
      },
      autoResetSelectedRows: false
    },
    useRowSelect
  );

  useImperativeHandle(ref, () => ({
    selectRows: (rowIndexes: number[]) => {
      rowIndexes.forEach(rowIndex => {
        if (rowIndex === -1) return;
        if (selectedFlatRows.some(r => r.id === String(rowIndex))) return;
        if (rowIndex >= rows.length) return;
        toggleRowSelected(String(rowIndex), true);
      });
    },
    toggleAllRowsSelected: toggleAllRowsSelected
  }));

  const TBodyTR = React.useMemo(
    () => styled(Row!)`
      :hover {
        background-color: ${props => props.theme.containerHover};
      }
      &.isSelected {
        background-color: ${props => props.theme.containerPrimary};
      }
      &:first-of-type > td {
        border-top: none;
      }
    `,
    [Row]
  );

  const selectedRows = selectedFlatRows.map(({ id }) => parseInt(id));
  const lastSelectedRows = usePrevious(selectedRows, {
    returnCurrentOnFirstRender: true
  });
  useEffect(() => {
    if (
      selectedRows.length === lastSelectedRows?.length &&
      selectedRows.every((sr, i) => lastSelectedRows[i] === sr)
    ) {
      return;
    }
    onSelectRows(selectedRows);
  }, [selectedRows, lastSelectedRows, onSelectRows]);

  const loadingEl = loading ? (
    <CenteredTD colSpan={columns.length}>
      <Spin size="large" />
    </CenteredTD>
  ) : null;

  const emptyStateEl = emptyState ? (
    typeof emptyState === "boolean" ? (
      <CenteredTD colSpan={columns.length}>
        <span>No items</span>
      </CenteredTD>
    ) : (
      <CenteredTD colSpan={columns.length}>{emptyState}</CenteredTD>
    )
  ) : null;

  return (
    <Table className={className} {...getTableProps()}>
      <THead>
        {headerGroups.map(headerGroup => (
          <THeadTR {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TH {...column.getHeaderProps()}>
                <THWrap>{column.render("Header")}</THWrap>
              </TH>
            ))}
          </THeadTR>
        ))}
      </THead>
      <TBody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <TBodyTR
              {...row.getRowProps()}
              key={`${row.original.id || row.index}`}
              data={row.original}
              className={classNames({ isSelected: row.isSelected })}
              onClick={() => {
                row.toggleRowSelected();
              }}
            >
              {row.cells.map(cell => (
                <TD {...cell.getCellProps()}>
                  <TDWrap>{cell.render("Cell")}</TDWrap>
                </TD>
              ))}
            </TBodyTR>
          );
        })}
      </TBody>
      <TFoot>{loadingEl ? loadingEl : emptyStateEl ? emptyStateEl : null}</TFoot>
    </Table>
  );
});
