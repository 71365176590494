import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "antd";
import { ButtonProps } from "antd/lib/button";

import { AuthorizationFlowNode } from "../../../../types";
import Message from "../../../common/Message";

import { DELETE_AUTH_PROVIDER_MUTATION, DeleteResult, DeleteVars } from "./queries";

interface Props {
  className?: string;
  authorizationProvider: AuthorizationFlowNode;
  buttonProps: Partial<ButtonProps>;
  onDelete: () => void;
}

export default function DeleteAuthProviderButton({
  authorizationProvider,
  buttonProps,
  className,
  onDelete
}: Props) {
  const onCompleted = (data: DeleteResult) => {
    const { ok, message } = data.deleteAuthorizationProvider;
    if (ok) {
      onDelete();
    } else {
      Message.error(message);
    }
  };

  const [destroy] = useMutation<DeleteResult, DeleteVars>(
    DELETE_AUTH_PROVIDER_MUTATION,
    { refetchQueries: ["FetchAuthorizationProviders"], onCompleted }
  );

  const handleSubmit = () => {
    const variables = {
      id: authorizationProvider.id
    };
    return destroy({
      variables
    });
  };

  const onClick = () =>
    Modal.confirm({
      title: "Are you sure you want to delete this authorization provider?",
      content: (
        <>
          This action <strong>cannot</strong> be undone. This will permanently delete
          the <strong>{authorizationProvider.name}</strong> authorization provider. Any
          data sources that use this authorization provider will be updated.
        </>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleSubmit(),
      width: 520
    });

  return (
    <>
      <Button className={className || ""} onClick={onClick} {...buttonProps}>
        Delete this authorization provider
      </Button>
    </>
  );
}
