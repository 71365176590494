import styled from "styled-components";

interface ContainerProps {
  readonly height: string;
  readonly isFullHeight: boolean;
}

// Using absolute position for full height, due to bug in SQL
// function editor where `height: 100%` causes field to auto-expand
// with content (and overlap other elements) even though codemirror
// docs state that only `height: auto` will auto-expand.
export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;

  .react-codemirror2 {
    height: 100%;
    position: relative;
  }

  .CodeMirror {
    height: ${props => props.height};
    min-height: 96px;
    line-height: 1.5;
    border: ${props => props.theme.inputBorder};
    border-radius: 4px;
    position: ${props => (props.isFullHeight ? "absolute" : "static")};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .CodeMirror-lines {
    padding-top: ${props => props.theme.editorPaddingTop};
    padding-bottom: ${props => props.theme.editorPaddingBottom};
  }

  // JSON colors for default theme
  .cm-s-default .cm-string.cm-property {
    color: ${props => props.theme.editorIdentifierColor};
  }
  .cm-s-default .cm-string {
    color: ${props => props.theme.inputColor};
  }

  // Use internal.io purple for variable names
  .cm-s-default .cm-variable,
  .cm-s-material-ocean .cm-variable {
    color: ${props => props.theme.editorIdentifierColor};
    font-weight: bold;
  }

  .CodeMirror-scroll {
    min-height: 96px;
  }

  // Override codemirror.css
  .CodeMirror-scroll,
  .CodeMirror-hscrollbar {
    overflow-x: auto !important;
  }
`;
