import React from "react";

import BaseComponentConfigSection from "../BaseComponentConfigSection";
import NameFields from "../NameFields";

interface Props {
  additionalConfig?: React.ReactNode;
}

export default function TextConfigSection({ additionalConfig }: Props) {
  return (
    <BaseComponentConfigSection>
      <NameFields shouldRenderTitle />
      {additionalConfig}
    </BaseComponentConfigSection>
  );
}
