import gql from "graphql-tag";

import { Connection, RelayNode } from "../../../../types";

export interface APIKeyNode extends RelayNode {
  prefix: string;
  suffix: string;
  description: string;
  createdAt: string;
  disabledAt: string | null;
  lastUsedAt: string | null;
}

export interface FetchAPIKeysData {
  allApiKeys: Connection<APIKeyNode>;
}

export const FETCH_API_KEYS = gql`
  query FetchApiKeys {
    allApiKeys {
      edges {
        node {
          id
          prefix
          suffix
          description
          createdAt
          disabledAt
          lastUsedAt
        }
      }
    }
  }
`;

export interface CreateAPIKeyVariables {
  description: string;
}

export interface CreateAPIKeyData {
  createApiKey: {
    key: string;
  };
}

export const CREATE_API_KEY = gql`
  mutation CreateApiKey($description: String!) {
    createApiKey(description: $description) {
      key
    }
  }
`;

export interface TransitionAPIKeyVariables {
  id: string;
}

export const ENABLE_API_KEY = gql`
  mutation EnableApiKey($id: ID!) {
    enableApiKey(id: $id) {
      apiKey {
        id
      }
    }
  }
`;

export const DISABLE_API_KEY = gql`
  mutation DisableApiKey($id: ID!) {
    disableApiKey(id: $id) {
      apiKey {
        id
      }
    }
  }
`;

export const DELETE_API_KEY = gql`
  mutation DeleteApiKey($id: ID!) {
    deleteApiKey(id: $id) {
      ok
    }
  }
`;
