import { AttributeTypes, ReturnSchema } from "../../../constants";
import { FunctionAttribute, Metadata, ViewFilter } from "../../../types";

import { RequestBodyType } from "./forms/constants";
import { Field, FormEncoding } from "./forms/http/bodies/Form/utils";
import { MongoBaseFunctionParameterMapping } from "./forms/mongo";
import { SqlBaseFunctionParameterMapping } from "./forms/sql/SqlActionForm";
import { FunctionEditor } from "./FunctionEditor";

export { FunctionEditor };

export interface HttpBaseFunctionParameterMapping {
  method: string;
  path: string;
  query: string;
  header: string;
  json_body?: string;
  raw_body?: string;
  binary_body?: string;
}

export interface HttpCode {
  encoding?: FormEncoding;
  fields?: Field[];
}

export interface HttpFunctionMetadata extends Metadata<HttpCode> {
  request_type: RequestBodyType;
}

export enum ReservedListParameter {
  FILTER = "filter",
  FILTERS = "filters",
  SORT_BY = "sortBy",
  SORT_ASCENDING = "sortAscending",
  PAGE_CURSOR = "pageCursor",
  PAGE_NUMBER = "pageNumber",
  PAGE_OFFSET = "pageOffset",
  PAGE_SIZE = "pageSize"
}

export const PaginationParameterSet = new Set([
  ReservedListParameter.PAGE_CURSOR,
  ReservedListParameter.PAGE_NUMBER,
  ReservedListParameter.PAGE_OFFSET,
  ReservedListParameter.PAGE_SIZE
]);
export const SortingParameterSet = new Set([
  ReservedListParameter.SORT_BY,
  ReservedListParameter.SORT_ASCENDING
]);

export const ReservedListParameterDefault: Record<ReservedListParameter, any> = {
  [ReservedListParameter.FILTER]: {},
  [ReservedListParameter.FILTERS]: [],
  [ReservedListParameter.SORT_BY]: undefined,
  [ReservedListParameter.SORT_ASCENDING]: true,
  [ReservedListParameter.PAGE_CURSOR]: "",
  [ReservedListParameter.PAGE_NUMBER]: 1,
  [ReservedListParameter.PAGE_OFFSET]: 0,
  [ReservedListParameter.PAGE_SIZE]: 10
};

export type BaseFunctionParameterMapping =
  | {}
  | HttpBaseFunctionParameterMapping
  | SqlBaseFunctionParameterMapping
  | MongoBaseFunctionParameterMapping;

export enum EditorTab {
  CONFIGURE = "configure",
  INPUTS = "inputs",
  OUTPUTS = "outputs",
  TRANSFORM = "transformer",
  AUTH = "auth"
}

export type ParameterDataValue =
  | null
  | boolean
  | number
  | string
  | object
  | undefined
  | ViewFilter[];

export type ParameterValues = Record<string, ParameterDataValue>;

export type BaseCode = string | Record<string, string>;

export interface PreviewResult {
  attributes: FunctionAttribute[];
  returnSchema: ReturnSchema;
}

export enum FunctionValidationStatus {
  VALID = "valid",
  INVALID = "invalid",
  VALIDATING = "validating"
}

export type Identifier = {
  name: string;
  type?: AttributeTypes;
};

export type IdentifierMap = Record<string, Identifier>;

export const ReservedListIdentifierMap: Record<ReservedListParameter, Identifier> = {
  [ReservedListParameter.FILTER]: {
    name: ReservedListParameter.FILTER,
    type: AttributeTypes.JSON
  },
  [ReservedListParameter.FILTERS]: {
    name: ReservedListParameter.FILTERS,
    type: AttributeTypes.JSON
  },
  [ReservedListParameter.SORT_BY]: {
    name: ReservedListParameter.SORT_BY,
    type: AttributeTypes.STRING
  },
  [ReservedListParameter.SORT_ASCENDING]: {
    name: ReservedListParameter.SORT_ASCENDING,
    type: AttributeTypes.BOOL
  },
  [ReservedListParameter.PAGE_CURSOR]: {
    name: ReservedListParameter.PAGE_CURSOR,
    type: AttributeTypes.STRING
  },
  [ReservedListParameter.PAGE_NUMBER]: {
    name: ReservedListParameter.PAGE_NUMBER,
    type: AttributeTypes.INT
  },
  [ReservedListParameter.PAGE_OFFSET]: {
    name: ReservedListParameter.PAGE_OFFSET,
    type: AttributeTypes.INT
  },
  [ReservedListParameter.PAGE_SIZE]: {
    name: ReservedListParameter.PAGE_SIZE,
    type: AttributeTypes.INT
  }
};

export type OnAuthorizationFlowChangeCallback = (authorizationFlow: {
  environmentId: string;
  authorizationFlowId: string | undefined;
}) => void;
