import { assertNever } from "../../../../util/assertNever";
import { FunctionNode } from "../../../queries/useGetFunctionById";

import { QueueNode } from "./queries/useAllQueues";

export enum ActionType {
  LOAD_FUNCTION = "LOAD_FUNCTION",
  LOAD_QUEUES = "LOAD_QUEUES",
  SELECT_QUEUE_BY_SLUG = "SELECT_QUEUE_BY_SLUG",
  UPDATE_FIELD = "UPDATE_FIELD",
  UPDATE_TITLE = "UPDATE_TITLE"
}

interface LoadFunction {
  type: ActionType.LOAD_FUNCTION;
  payload: FunctionNode;
}

interface LoadQueues {
  type: ActionType.LOAD_QUEUES;
  payload: QueueNode[];
}

interface SelectQueue {
  type: ActionType.SELECT_QUEUE_BY_SLUG;
  payload: string;
}

interface UpdateField {
  type: ActionType.UPDATE_FIELD;
  payload: { name: string; value: any };
}

interface UpdateTitle {
  type: ActionType.UPDATE_TITLE;
  payload: string;
}

type Action = LoadFunction | LoadQueues | SelectQueue | UpdateField | UpdateTitle;

interface State {
  func: FunctionNode | undefined;
  queues: QueueNode[];
  selectedQueueSlug: string | undefined;
  title: string;
  values: { [k: string]: any };
}

export const getDefaultState = (): State => ({
  func: undefined,
  queues: [],
  selectedQueueSlug: undefined,
  title: "",
  values: {}
});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SELECT_QUEUE_BY_SLUG:
      const selectedQueue = state.queues.find(q => q.slug === action.payload);
      if (!selectedQueue) throw new Error("illegal state");
      return {
        ...state,
        selectedQueueSlug: selectedQueue.slug
      };
    case ActionType.LOAD_FUNCTION:
      return {
        ...state,
        func: action.payload
      };
    case ActionType.LOAD_QUEUES:
      return {
        ...state,
        queues: action.payload
      };
    case ActionType.UPDATE_FIELD:
      const { name, value } = action.payload;
      return {
        ...state,
        values: { ...state.values, [name]: value }
      };
    case ActionType.UPDATE_TITLE:
      return {
        ...state,
        title: action.payload
      };
    default:
      return assertNever(action);
  }
};
