import React from "react";

import { Checkbox, Col, Icon, Tooltip } from "antd";
import Column from "antd/lib/table/Column";

import { EnvironmentNode, RoleNode } from "../../../../types";
import { AntdEnvironmentSelect } from "../../../common/EnvironmentSelect/AntdEnvironmentSelect";
import {
  FunctionParameterRow,
  getFunctionParameterRow,
  PermissionsPaginator
} from "../../../common/Permissions/permissions";
import {
  FunctionPolicyActionTypes,
  PolicySpaceFunctions,
  PolicySpaceReducerAction
} from "../../../common/Permissions/reducer";
import { FunctionH5, RightCol } from "../../../common/Permissions/styledComponents";
import { C2, B3 } from "../../../common/StyledComponents";
import { HelpIcon } from "../SpaceDetail/styledComponents";

import { Space } from "./queries";
import {
  SpaceBetweenLayout,
  SpaceRowPermissionTop,
  StyledInput,
  StyledTableRow,
  StyledTableRowHeader,
  StyledInternalEmpty,
  FlexRow,
  SpaceRowPermissionsContent,
  PermissionsTable,
  AutoGrantAllHeaderSwitch
} from "./styledComponents";
import { EnvironmentOptionType, EnvironmentSelectOption } from "./useSpaceRolePolicies";

const NO_DISABLE_PERMISSIONS_FOR_ADMIN = "Permissions cannot be disabled for Admins";
const LAST_SAVED_VERSION_KEY = "last-saved-version";

function SpaceRolePermissionsFilter({
  selectedEnvironment,
  setSelectedEnvironment,
  setSearchText
}: {
  selectedEnvironment: EnvironmentSelectOption;
  setSelectedEnvironment: (environment: EnvironmentSelectOption) => void;
  setSearchText: (query: string) => void;
}) {
  const rootEleRef = React.useRef(null);
  const extraOptions = [
    {
      key: LAST_SAVED_VERSION_KEY,
      display: "Last saved version"
    }
  ];

  const selectedEnvironmentValue =
    selectedEnvironment.type === EnvironmentOptionType.Environment
      ? selectedEnvironment.payload
      : LAST_SAVED_VERSION_KEY;

  return (
    <SpaceBetweenLayout ref={rootEleRef}>
      <AntdEnvironmentSelect
        selectedEnvironment={selectedEnvironmentValue}
        setSelectedEnvironment={env => {
          const envNode = env as EnvironmentNode;
          if (envNode.slug) {
            setSelectedEnvironment({
              type: EnvironmentOptionType.Environment,
              payload: envNode
            });
          } else {
            setSelectedEnvironment({ type: EnvironmentOptionType.LastSavedVersion });
          }
        }}
        popupContainer={rootEleRef.current!}
        extraOptions={extraOptions}
      />
      <StyledInput
        placeholder="Search functions"
        prefix={<Icon type="search" />}
        onChange={e => setSearchText(e.currentTarget.value)}
      />
    </SpaceBetweenLayout>
  );
}

interface SpaceRolePermissionsProps {
  space: Space;
  role: RoleNode;
  page: number;
  pageSize: number;
  loading: boolean;
  selectedEnvironment: EnvironmentSelectOption;
  state: PolicySpaceFunctions;
  className?: string;
  dispatch: (action: PolicySpaceReducerAction) => void;
  setSearchText: (query: string) => void;
  setPage: (page: number) => void;
  setSelectedEnvironment: (environment: EnvironmentSelectOption) => void;
}

export function SpaceRolePermissions({
  space,
  role,
  page,
  pageSize,
  loading,
  selectedEnvironment,
  state,
  className,
  dispatch,
  setSearchText,
  setPage,
  setSelectedEnvironment
}: SpaceRolePermissionsProps) {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  const roleIsAdmin = role.isManaged && role.slug === "admin";

  const dataSource = state.functions.map(func =>
    getFunctionParameterRow(
      func,
      action => dispatch(action),
      expandedRowKeys,
      roleIsAdmin
    )
  );

  return (
    <SpaceRowPermissionsContent className={className} data-test="expandedRowCell">
      <SpaceRowPermissionTop>
        <FunctionH5>Functions for {space.name}</FunctionH5>
        <C2>Changes to function permissions are global</C2>
        <SpaceRolePermissionsFilter
          selectedEnvironment={selectedEnvironment}
          setSelectedEnvironment={setSelectedEnvironment}
          setSearchText={setSearchText}
        />
      </SpaceRowPermissionTop>
      <PermissionsTable
        data-test="spaceRowPermissionContent"
        dataSource={dataSource}
        pagination={false}
        indentSize={0}
        components={{
          header: {
            row: StyledTableRow,
            cell: StyledTableRowHeader
          },
          body: {
            row: StyledTableRow
          }
        }}
        locale={{
          emptyText: <StyledInternalEmpty content="No functions to display" />
        }}
        onRow={record => {
          const functionRow = record as FunctionParameterRow;
          return {
            onClick: () => {
              // Don't expand beyond top level
              if (functionRow.level > 0) {
                return;
              }

              const expanded = expandedRowKeys.includes(functionRow.id);
              if (expanded) {
                setExpandedRowKeys([]);
              } else if (functionRow.fieldsCount) {
                // Only if it has fields, expand.
                setExpandedRowKeys([functionRow.id]);
              }
            }
          };
        }}
        loading={loading}
        expandedRowKeys={expandedRowKeys}
        expandIcon={() => <></>}
        rowKey="id"
        rowClassName={record => {
          const element = record as { id: string };
          return expandedRowKeys.includes(element.id) ? "expanded" : "";
        }}
      >
        <Column
          title="Function"
          dataIndex="name"
          width="28%"
          render={text => <B3>{text}</B3>}
        />
        <Column
          title="Data source"
          dataIndex="dataSource"
          width="20%"
          render={text => <B3>{text}</B3>}
        />
        <Column
          className="full-width"
          title={() => (
            <FlexRow type="flex" justify="space-between">
              <Col>
                <Tooltip
                  title={roleIsAdmin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}
                >
                  <Checkbox
                    data-test="allPermissionsCheckBox"
                    checked={roleIsAdmin || state.fieldsEnabled === "all"}
                    disabled={roleIsAdmin}
                    indeterminate={state.fieldsEnabled === "some"}
                    onClick={() =>
                      dispatch({
                        type: FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE
                      })
                    }
                  >
                    Parameters and attributes
                  </Checkbox>
                </Tooltip>
              </Col>

              <RightCol>
                Auto-grant all
                <Tooltip title="Turning Auto-grant all on will enable access to all parameters and attributes (current and future)">
                  <HelpIcon type="question-circle" />
                </Tooltip>
                <Tooltip
                  title={roleIsAdmin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}
                >
                  <AutoGrantAllHeaderSwitch
                    data-test="autoGrantToggle"
                    disabled={roleIsAdmin}
                    checked={roleIsAdmin || state.autoGrant === "all"}
                    indeterminate={state.autoGrant === "some"}
                    onClick={() =>
                      dispatch({
                        type: FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE,
                        payload: {
                          allowAll: state.autoGrant !== "all"
                        }
                      })
                    }
                  >
                    Parameters and attributes
                  </AutoGrantAllHeaderSwitch>
                </Tooltip>
              </RightCol>
            </FlexRow>
          )}
          dataIndex="permission"
        />
      </PermissionsTable>
      {!loading && state.totalCount !== 0 && (
        <PermissionsPaginator
          page={page}
          pageSize={pageSize}
          total={state.totalCount}
          onPageChange={page => setPage(page)}
        />
      )}
    </SpaceRowPermissionsContent>
  );
}
