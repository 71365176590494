import { Button as AntButton } from "antd";
import styled from "styled-components";

import { colorTokens, Size } from "../../../../cssConstants";
import { HelpIcon as CommonHelpIcon } from "../../../common/Icons";
import { ErrorMessageField, FieldWithError } from "../common/ErrorMessageField";

export const ErrorMessageFieldWithMargin = styled(ErrorMessageField)`
  margin: ${props => props.theme.spacermd} ${props => props.theme.spacersm}
    ${props => props.theme.spacersm} ${props => props.theme.spacermd};
  display: inline;
`;

export const TitleLinkButton = styled(AntButton)`
  &.ant-btn-link {
    color: ${colorTokens.white};
  }

  &.ant-btn-link:hover {
    color: ${colorTokens.white};
  }

  &.ant-btn-link[disabled] {
    color: ${props => props.theme.buttonDisabledColor};
  }
`;

export const DismissButton = styled(AntButton)`
  &.ant-btn-link {
    color: ${props => props.theme.errorColor};
  }

  &.ant-btn-link:hover {
    color: ${props => props.theme.darkErrorColor};
  }
`;

export const NameSection = styled.section`
  display: flex;
  margin-bottom: ${props => props.theme.spacermd};
  max-width: 100%;
  align-items: center;
  width: ${Size.lg};

  padding: ${props => props.theme.spacermd} ${props => props.theme.spacermd} 0
    ${props => props.theme.spacermd};

  .ant-btn,
  .anticon-delete {
    margin-left: ${props => props.theme.spacersm};
  }

  .ant-select {
    margin-left: 0;
  }
`;

export const FieldWithErrorGrow = styled(FieldWithError)`
  flex-grow: 1;
  margin-right: ${props => props.theme.spacersm};
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderSection = styled.section`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 114px;
`;

export const MenuTitle = styled.header`
  padding: ${props => props.theme.spacersm} ${props => props.theme.spacermd};
  font-size: ${props => props.theme.h4FontSize};
  border-bottom: 1px solid ${props => props.theme.menuBorderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EditorSection = styled.section`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

export const HelpPrompt = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.spacermd};
  color: ${props => props.theme.textColorMid};
`;

export const HelpIcon = styled(CommonHelpIcon)`
  margin-right: ${props => props.theme.spacersm};
`;
