import React from "react";

import { get } from "lodash";

import { SpaceStateInputs } from "..";
import {
  SpaceComponentDynamicState,
  SpaceComponentCondition,
  ViewFilterOperator,
  SpaceComponentObject
} from "../../../../../types";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import SpaceComponent, { Props } from "../SpaceComponent";

const rowHasConditions = (
  state: SpaceComponentDynamicState,
  input: SpaceStateInputs
) => {
  if (!state.conditions) return false;
  return state.conditions.every((condition: SpaceComponentCondition) => {
    const { binding, value, operator } = condition;
    const stateValue = get(input, binding);
    switch (operator as ViewFilterOperator) {
      case ViewFilterOperator.EQUALS:
        return stateValue === value;
      case ViewFilterOperator.NOT_EQUALS:
        return stateValue !== value;
      case ViewFilterOperator.GREATER_THAN:
        return stateValue > value;
      case ViewFilterOperator.GREATER_THAN_OR_EQUAL:
        return stateValue >= value;
      case ViewFilterOperator.LESS_THAN:
        return stateValue < value;
      case ViewFilterOperator.LESS_THAN_OR_EQUAL:
        return stateValue <= value;
      case ViewFilterOperator.ICONTAINS:
        return typeof stateValue === "string" && stateValue.includes(value);
      default: {
        return false;
      }
    }
  });
};

export const getComponentFromStates = (
  sc: SpaceComponentObject,
  input: SpaceStateInputs
) => {
  const { states } = sc.properties;
  if (!states) return null;
  const currentState =
    states.find((s: SpaceComponentDynamicState) => rowHasConditions(s, input)) ||
    states.find((s: SpaceComponentDynamicState) => s.conditions === null);
  if (!currentState.slug) return null;
  return sc.componentTreeNodes.find(node => node.slug === currentState.slug);
};

export default function SpaceDynamicFunction({ spaceComponent, spaceApi }: Props) {
  const { componentNode, input, updateOutput, registerBinding, unregisterBinding } =
    useComponentStateContext();

  const activeChildComponent = getComponentFromStates(spaceComponent, input || {});

  const activeChildBindingPath = activeChildComponent
    ? `${componentNode?.path}.${activeChildComponent?.slug}.lastExecutionResult`
    : null;
  React.useEffect(() => {
    if (activeChildBindingPath) registerBinding(activeChildBindingPath);
    return () => {
      if (activeChildBindingPath) unregisterBinding(activeChildBindingPath);
    };
  }, [activeChildBindingPath, registerBinding, unregisterBinding]);

  const activeChildResult = input ? input[activeChildBindingPath || ""] : null;
  React.useEffect(() => {
    updateOutput({
      lastExecutionResult: activeChildResult || null
    });
  }, [activeChildResult, updateOutput]);
  if (!input || !spaceComponent.properties.states) return null;
  if (!activeChildComponent) return null;

  return <SpaceComponent spaceComponent={activeChildComponent} spaceApi={spaceApi} />;
}
