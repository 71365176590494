import { Node, parse } from "acorn";
import { ancestor, simple } from "acorn-walk";

import { AttributeTypes } from "../../constants";

// Rather than use global object directly, which changes given context and includes
// a lot more than the core global objects, explicitly hardcoding values here for known
// global values, functions, and objects (used to exclude when extracting identifiers).
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
export const GLOBAL_JS = [
  // Value properties
  "Infinity",
  "NaN",
  "undefined",
  "globalThis",
  // Function properties
  "eval",
  "uneval",
  "isFinite",
  "isNaN",
  "parseFloat",
  "parseInt",
  "decodeURI",
  "decodeURIComponent",
  "encodeURI",
  "encodeURIComponent",
  "Deprecated",
  "escape",
  "unescape",
  // Fundamental objects
  "Object",
  "Function",
  "Boolean",
  "Symbol",
  // Error objects
  "Error",
  "AggregateError",
  "EvalError",
  "InternalError",
  "RangeError",
  "ReferenceError",
  "SyntaxError",
  "TypeError",
  "URIError",
  // Numbers and dates
  "Number",
  "BigInt",
  "Math",
  "Date",
  // Text processing
  "String",
  "RegExp",
  // Indexed collections
  "Array",
  "Int8Array",
  "Uint8Array",
  "Uint8ClampedArray",
  "Int16Array",
  "Uint16Array",
  "Int32Array",
  "Uint32Array",
  "Float32Array",
  "Float64Array",
  "BigInt64Array",
  "BigUint64Array",
  // Keyed collections
  "Map",
  "Set",
  "WeakMap",
  "WeakSet",
  // Structured data
  "ArrayBuffer",
  "SharedArrayBuffer",
  "Atomics",
  "DataView",
  "JSON",
  // Control abstraction objects
  "Promise",
  "Generator",
  "GeneratorFunction",
  "AsyncFunction",
  // Reflection
  "Reflect",
  "Proxy",
  // Internationalization
  "Intl",
  // WebAssembly
  "WebAssembly"
];

// Acorn types that are not defined in their exported types
interface FunctionParamNode extends Node {
  name: string;
}

interface FunctionExpressionNode extends Node {
  params: FunctionParamNode[];
  body: Node;
}

interface LocalVariableData {
  name: string;
  start: number;
  end: number;
}
export type Identifier = {
  name: string;
  type?: AttributeTypes;
};

export type IdentifierMap = Record<string, Identifier>;

export function parseIdentifiers(input: string): IdentifierMap {
  try {
    return unsafeParseIdentifiers(input);
  } catch {
    return {};
  }
}

export function unsafeParseIdentifiers(input: string): IdentifierMap {
  const identifiers: IdentifierMap = {};
  const ast = parse(`(${input})`);
  const localVars: LocalVariableData[] = [];
  const addFunctionParameters = (node: FunctionExpressionNode) => {
    node.params.forEach((paramNode: FunctionParamNode) => {
      if (paramNode.type === "Identifier") {
        localVars.push({
          name: paramNode.name,
          start: node.start,
          end: node.end
        });
      }
    });
  };

  // walk through AST to extract local variables and scope
  ancestor(ast, {
    FunctionExpression(node: Node) {
      addFunctionParameters(node as FunctionExpressionNode);
    },
    ArrowFunctionExpression(node: Node) {
      addFunctionParameters(node as FunctionExpressionNode);
    },
    VariableDeclarator(node: any, ancestors: Node[]) {
      // sort from bottom up (current node --> farthest ancestor)
      ancestors.reverse().shift();
      const parentBlock = ancestors.find(
        ancestor =>
          // find the closest scope within which the variable is declared for start/end data
          ancestor.type === "BlockStatement" ||
          (ancestor.type === "FunctionExpression" &&
            (ancestor as FunctionExpressionNode).body.type === "BlockStatement")
      );

      if (parentBlock) {
        localVars.push({
          name: node.id.name,
          start: parentBlock.start,
          end: parentBlock.end
        });
      }
    }
  });

  // walk through AST to extract identifiers
  simple(ast, {
    Identifier(node) {
      const name = (node as any).name;
      const isLocalVar = localVars.some(localVar => {
        return (
          localVar.name === name &&
          node.start > localVar.start &&
          node.end < localVar.end
        );
      });
      if (!GLOBAL_JS.includes(name) && !isLocalVar) {
        identifiers[name] = { name };
      }
    }
  });
  return identifiers;
}
