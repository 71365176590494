import { Select as BaseSelect } from "antd";
import styled from "styled-components";

import { Icon as BaseIcon } from "../Icons/MaterialIcons";
import { C2, H4, H5 } from "../StyledComponents";

export const Title = styled(H4)`
  margin-bottom: ${props => props.theme.spacerlg};
`;

export const SubTitle = styled(H5)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const Label = styled(C2)`
  display: block;
  color: ${props => props.theme.surfaceSecondary};
  padding-bottom: ${props => props.theme.spacersm};
`;

export const Fieldset = styled.fieldset`
  padding-bottom: ${props => props.theme.spacermd};
`;

export const IconColor = styled(Fieldset)`
  display: flex;
  gap: 8px;
`;

export const Icon = styled(BaseIcon)<{ color: string }>`
  font-size: 30px;
  color: ${props => props.color};
`;

export const IconSelectOptionsWrapper = styled.div`
  .ant-select-dropdown-menu-item {
    display: inline-block;
  }
`;

export const Select = styled(BaseSelect)`
  display: block;
  min-width: 200px;
`;

export const SelectContainer = styled.div`
  flex-grow: 1;
`;
