import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import HeaderConfigSection from "../../common/HeaderConfig";
import ParametersConfigSection from "../../common/ParametersManager";
import TextConfigSection from "../../common/TextConfigSection";
import useViewConfig from "../../common/useViewConfig";
import { hasSource } from "../../common/util";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import ColumnsConfigSection from "./ColumnsConfig";
import DataConfig from "./DataConfig";
import EffectsConfigs from "./EffectsConfig";
import ExportConfig from "./ExportConfig";
import FiltersConfigSection from "./FiltersConfig";
import { ensureTableConfigState } from "./reducer";
import SelectMultipleConfig from "./SelectMultipleConfig";

export default function TableConfig(props: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<TableConfigSections />}
      effectsConfig={<EffectsConfigs />}
      collectionKey="rows"
      designConfig={<DesignConfig />}
      slug={props.slug}
    />
  );
}

export function TableConfigSections() {
  const context = useComponentConfigContext();
  const state = ensureTableConfigState(context.state);
  useViewConfig({
    state: context.state,
    dispatch: context.dispatch
  });

  return (
    <div>
      <ConfigSection title="Source" children={<DataConfig />} />
      {hasSource(state.draftComponent) && (
        <>
          <TextConfigSection />
          <ParametersConfigSection title="Parameters" />
          <ColumnsConfigSection title="Columns" state={state} />
          <FiltersConfigSection title="Filters" state={state} />
          <ExportConfig />
          <VisibilityRulesManagerSection />
          <SelectMultipleConfig />
        </>
      )}
    </div>
  );
}

function DesignConfig() {
  return <HeaderConfigSection searchBoxCheckboxEnabled={true} />;
}
