import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { publicClient } from "../../../../graphql";
import { AuthStatus } from "../../../../types";

export const AUTH_STATUS = gql`
  query authStatus {
    authStatus
  }
`;

export default function useAuthStatus() {
  const { loading, data, error } = useQuery(AUTH_STATUS, {
    client: publicClient
  });

  return {
    loading,
    error,
    status: (data?.authStatus as AuthStatus) || AuthStatus.Unauthenticated
  };
}
