import { DataProxy } from "apollo-cache";
import _ from "lodash";

export type CacheUpdaterFn<T> = (cache: DataProxy, node: T) => void;

export const updateCache = <TData = any, TVariables = any>(
  options: DataProxy.Query<TVariables>,
  cache: DataProxy,
  cb: (payload: TData) => TData
) => {
  const q = cache.readQuery<TData>(options);
  if (!q) return;

  const payload = cb(_.cloneDeep(q));
  cache.writeQuery({ ...options, data: payload });
};
