import { useQuery } from "@apollo/react-hooks";

import { publicClient } from "../../../graphql";

import { GET_SSO_STATE, SSOStateData, SSOStateVars } from "./queries";

export default function useSSOState(email: string) {
  // This is used only to limit the number of queries made when we know that the
  // email cannot possibly be valid. Actual validation is performed server-side.
  //
  // [1+ non-space]@[1+ non-space].[2+ non-space]
  const invalid = !email.match(/\S+@\S+\.\S\S+/);
  const { data, loading } = useQuery<SSOStateData, SSOStateVars>(GET_SSO_STATE, {
    client: publicClient,
    skip: invalid,
    variables: { email }
  });
  return {
    loading,
    required: data?.ssoData.required || false,
    url: data?.ssoData.url || ""
  };
}
