import { AttributeTypes } from "../../../../../../../constants";
import { FunctionNode } from "../../../../../../common/FormBuilderModal";
import { fieldsToCreateFunctionNode } from "../../../../reducer/utils";
import { Field } from "../../../../types";

export const fieldsToUpdateFunctionNode = (fields: Field[]): FunctionNode => {
  const node = fieldsToCreateFunctionNode(fields);
  return {
    ...node,
    functionParameters: {
      ...node.functionParameters,
      edges: [
        { node: { name: "id", required: true, type: AttributeTypes.STRING } },
        ...node.functionParameters.edges.map(e => ({
          ...e,
          node: { ...e.node, required: false }
        }))
      ]
    }
  };
};
