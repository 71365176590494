import Icon from "../../../../../assets/spaceicons/card-list.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  ElementLayout,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  OverflowOption,
  LayoutUnit
} from "../../../layout/util";

import Config from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  makeInitialState,
  ensureSpaceCardListComponent,
  CardListConfigState
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import { SpaceCardListComponent, SpaceCardListProperties } from "./types";

import SpaceCardList from ".";

const pkg: SpaceComponentPackage<
  SpaceCardListProperties,
  SpaceCardListComponent,
  CardListConfigState
> = {
  type: "CARD_LIST",
  displayName: "Card list",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["SPACE", "FLEX_BOX"]),
  isCollection: true,
  isRepeatable: false,
  Component: SpaceCardList,
  ConfigurationComponent: Config,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceCardListComponent,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.WIDE.width,
    height: DEFAULT_SIZES.WIDE.height,
    snapToGrid: true
  }),
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    height: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL],
    overflow: [OverflowOption.HIDDEN]
  },
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
