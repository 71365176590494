import React from "react";

import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styled from "styled-components";

import Button from "../../../../common/Button";

import { StepProps } from ".";

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.theme.spacermd};
  min-width: 280px;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: ${props => props.theme.defaultFontSize};
    line-height: ${props => props.theme.spacerlg};
  }

  .buttonContainer {
    border: 1px solid ${props => props.theme.borderGrey};
    box-sizing: border-box;
    padding-top: ${props => props.theme.spacermd};
    padding-bottom: ${props => props.theme.spacerlg};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .errorMessage {
      font-style: normal;
      font-weight: normal;
      font-size: ${props => props.theme.smallFontSize};
      line-height: ${props => props.theme.spacermd};

      text-align: center;
      letter-spacing: 0.3px;
      color: #f07368;
      margin: 0px;
      margin-top: 6px;
    }
  }
`;

export const FormContainer = styled.div`
  border: 1px solid ${props => props.theme.borderGrey};
  border-bottom: 0px;
  box-sizing: border-box;
  width: 100%;
  line-height: ${props => props.theme.spacermd};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
  padding: ${props => props.theme.spacermd};
`;

export const FormItem = styled(Form.Item)`
  padding: 0px;
  line-height: ${props => props.theme.spacermd};
  label {
    font-style: normal;
    font-weight: normal;
    font-size: ${props => props.theme.inputFontSize};
    line-height: ${props => props.theme.spacermd};
  }
  width: 95%;
`;

interface InputStepField {
  name: string;
  label: string;
  required: boolean;
  type: "text" | "password";
}

interface InputStepProps extends FormComponentProps, StepProps {
  // empty
}

const InputStep = ({ state, processStep, form, showSubText }: InputStepProps) => {
  const fields = state.step.options?.fields
    ? state.step.options.fields
    : state.step.options.input_fields;

  const handleSubmit = React.useCallback(() => {
    form.validateFields((err, fields) => {
      const inputState = { [state.step.slug]: { ...fields } };
      if (!err) {
        return processStep(inputState);
      }
    });
  }, [form, processStep, state.step.slug]);

  return (
    <StyledForm
      colon={false}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
      hideRequiredMark={true}
      layout={"vertical"}
    >
      <FormContainer>
        {showSubText && <p>Login with {state.flow.name}.</p>}
        {(fields || []).map((field: InputStepField) => {
          let input = (
            <Input key={field.name} placeholder={field.label} autoComplete="off" />
          );
          switch (field.type) {
            case "password":
              input = (
                <Input.Password
                  key={field.name}
                  placeholder={field.label}
                  autoComplete="off"
                />
              );
              break;
            case "text":
            default:
              // default input component
              break;
          }
          return (
            <FormItem key={field.name} label={field.label}>
              {form.getFieldDecorator(field.name, {
                rules: [
                  {
                    required: field.required || true,
                    message: field.label + " is required."
                  }
                ]
              })(input)}
            </FormItem>
          );
        })}
      </FormContainer>
      <Form.Item className={"buttonContainer"}>
        <Button loading={state.isProcessing} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default Form.create<InputStepProps>({})(InputStep);
