import React from "react";

import { Switch } from "antd";
import styled from "styled-components";

import { useAutomationStatusById } from "./queries/AutomationStatusById";
import { useSaveAutomationStatus } from "./queries/SaveAutomationStatus";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 13px 16px;
  background-color: ${props => props.theme.buttonSecondaryFillDisabled};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.newContainerPrimaryTint};
`;

const Label = styled.h6`
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  margin: 0;
`;

const SwitchWrapper = styled.div`
  display: flex;
`;

const SwitchLabel = styled(Label)`
  margin-right: ${props => props.theme.spacermd};
`;

const SwitchError = styled(Label)`
  color: ${props => props.theme.errorColor};
`;

interface Props {
  id: string;
}

export const AutomationToggle = ({ id }: Props) => {
  const { loading: fetchLoading, error: fetchError } = useAutomationStatusById({
    fetchPolicy: "no-cache",
    variables: { id },
    onCompleted: data => setIsActive(data.node.isActive)
  });

  const [saveAutomationStatus, { loading: saveLoading, error: saveError }] =
    useSaveAutomationStatus({
      onCompleted: data => {
        setIsActive(data.saveAutomationStatus.automation.isActive);
      }
    });

  const [isActive, setIsActive] = React.useState(false);

  const toggleAutomation = React.useCallback(() => {
    saveAutomationStatus({ variables: { id, isActive: !isActive } });
  }, [saveAutomationStatus, id, isActive]);

  const loading = fetchLoading || saveLoading;
  const error = fetchError || saveError;

  return (
    <Container>
      <Label>Status</Label>
      <SwitchWrapper>
        {error ? (
          <SwitchError>Error</SwitchError>
        ) : (
          <>
            <SwitchLabel>{loading ? "" : isActive ? "Active" : "Paused"}</SwitchLabel>
            <Switch
              defaultChecked
              checked={isActive}
              loading={loading}
              onClick={toggleAutomation}
            />
          </>
        )}
      </SwitchWrapper>
    </Container>
  );
};

export default AutomationToggle;
