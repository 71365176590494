import React from "react";

import CommonTemplateEditor, {
  TemplateEditorProps as CommonTemplateEditorProps
} from "../../../../../common/TemplateEditor";
import withDebouncedValue from "../../../../../common/withDebouncedValue";
import useComponentNode from "../../../../util/useComponentNode";
import { ComponentNode } from "../../../RenderTreeContext";
import { findSpaceComponentPackage as findPackage } from "../../../SpaceContext/StableSpaceContext";
import { labelProvider, valueProvider, getOptions } from "../BindingCascader";
import { useSpaceConfigPanelContext } from "../ConfigPanel/ConfigPanelContext";

export enum Height {
  Small = "30px",
  Medium = "60px",
  Large = "90px"
}

type TemplateEditorProps = Omit<CommonTemplateEditorProps, "bindingOptions">;

export default function TemplateEditor(props: TemplateEditorProps) {
  const { generateGetPopupContainer } = useSpaceConfigPanelContext();
  const pivot = useComponentNode();
  const options = React.useMemo(
    () =>
      getOptions(
        pivot,
        (node: ComponentNode) => findPackage(node.component.type),
        labelProvider,
        valueProvider
      ),
    [pivot]
  );

  return (
    <CommonTemplateEditor
      {...props}
      bindingOptions={options}
      popupContainerFactory={generateGetPopupContainer}
    />
  );
}

TemplateEditor.displayName = "TemplateEditorWrapper";

export const DebouncedTemplateEditor = withDebouncedValue(TemplateEditor);
