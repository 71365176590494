import React from "react";

import { Input } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";

import { FormItem, FormItemNoBottomMargin } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import SSHFieldSet from "./SSHFieldSet";
import * as styled from "./styledComponents";
import { CredentialFormProps } from "./types";

export const HOST_FIELD_ID = "Host";
export const PORT_FIELD_ID = "Port";
export const USERNAME_FIELD_ID = "Username";
export const PASSWORD_FIELD_ID = "Password";
export const DATABASE_FIELD_ID = "Database";
export const SSL_ENABLED_FIELD_ID = "requireSsl";
export const SSH_ENABLED_FIELD_ID = "SSH Enabled";

export const CommonDataSourceFields = ({
  isLoading,
  credentials,
  form,
  allowPartialSubmission,
  includeLabel,
  hasDatabase = true
}: CommonFormProps) => {
  const [isSSLEnabled, setSSLEnabled] = React.useState(
    credentials.sslmode ? credentials.sslmode === "require" : true
  );
  const { getFieldDecorator } = form;
  return (
    <>
      <FormItem label={includeLabel && "Host"}>
        {getFieldDecorator(HOST_FIELD_ID, {
          initialValue: credentials?.host,
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(<Input placeholder="Host" data-test="host-input" disabled={isLoading} />)}
      </FormItem>
      <FormItem label={includeLabel && "Port"}>
        {getFieldDecorator(PORT_FIELD_ID, {
          initialValue: credentials?.port,
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(<Input placeholder="Port" data-test="port-input" disabled={isLoading} />)}
      </FormItem>
      <FormItem label={includeLabel && "Username"}>
        {getFieldDecorator(USERNAME_FIELD_ID, {
          initialValue: credentials?.user,
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="Username"
            data-test="username-input"
            autoComplete="off"
            disabled={isLoading}
          />
        )}
      </FormItem>
      <FormItem label={includeLabel && "Password"}>
        {getFieldDecorator(PASSWORD_FIELD_ID, {
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input.Password
            placeholder="Password"
            type="password"
            data-test="password-input"
            autoComplete="new-password"
            disabled={isLoading}
          />
        )}
      </FormItem>
      {hasDatabase && (
        <FormItem label={includeLabel && "Database"}>
          {getFieldDecorator(DATABASE_FIELD_ID, {
            initialValue: credentials?.database,
            preserve: true,
            rules: [
              {
                required: !allowPartialSubmission,
                message: VALIDATION_MESSAGES.requiredField
              }
            ]
          })(
            <Input
              placeholder="Database"
              data-test="database-input"
              disabled={isLoading}
            />
          )}
        </FormItem>
      )}
      <FormItemNoBottomMargin>
        {getFieldDecorator(SSL_ENABLED_FIELD_ID, { initialValue: true })(
          <Checkbox
            indeterminate={false}
            defaultChecked={isSSLEnabled}
            onChange={(e: CheckboxChangeEvent) => setSSLEnabled(e.target.checked)}
          >
            Enable SSL
          </Checkbox>
        )}
        {!isSSLEnabled && (
          <styled.StyledAlert
            message="Connections to your database will not be private and your data will be visible to anyone in transit. Enable SSL to ensure that your data is encrypted between your database and Internal."
            type="warning"
            showIcon
          />
        )}
      </FormItemNoBottomMargin>
    </>
  );
};

// TODO remove when we support cassandra update form
interface CommonFormProps extends CredentialFormProps {
  hasDatabase?: boolean;
  supportSSH?: boolean;
}

export default function DatabaseFieldSet(props: CommonFormProps) {
  const { getFieldDecorator } = props.form;
  const [isSSHTunnelingEnabled, setSSHTunnelingEnabled] = React.useState(
    !!(props.credentials?.ssh_user || props.credentials?.ssh_host)
  );
  return (
    <>
      <CommonDataSourceFields {...props} />
      <FormItemNoBottomMargin>
        {props.supportSSH !== false &&
          getFieldDecorator(SSH_ENABLED_FIELD_ID, {
            initialValue: isSSHTunnelingEnabled
          })(
            <Checkbox
              checked={isSSHTunnelingEnabled}
              onChange={(e: CheckboxChangeEvent) => {
                setSSHTunnelingEnabled(e.target.checked);
              }}
            >
              SSH Tunneling
            </Checkbox>
          )}
      </FormItemNoBottomMargin>
      {isSSHTunnelingEnabled && <SSHFieldSet {...props} />}
    </>
  );
}
