import pluralize from "pluralize";

import {
  AttributeNode,
  SpaceComponentObject,
  ConfigValidationError
} from "../../../../../../types";
import humanizeList from "../../../../../util/humanizeList";

import { ColumnType } from "./ColumnListManagerSection";

import { AttributeColumn, Column } from ".";

export default function selectColumnErrors(
  component: SpaceComponentObject,
  findInvalidInputBindings: (component: SpaceComponentObject) => string[],
  errorFieldKey: string,
  attributes: AttributeNode[] | null
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];

  // If columns haven't been loaded yet, prevent submission so saves with invalid columns
  // are not attempted.
  if (component.properties.columns === null) {
    return [
      { field: errorFieldKey, message: "Please wait for data to finish loading." }
    ];
  }
  if (!component.properties.columns?.length) {
    errors.push({
      field: errorFieldKey,
      message: "Please add a field before saving this component."
    });
  }

  const attributeColumns: AttributeColumn[] = component.properties.columns.filter(
    (c: Column) => c.column_type === ColumnType.ATTRIBUTE
  );

  function hasComponentsWithInvalidBindings(component: SpaceComponentObject): boolean {
    if (findInvalidInputBindings(component).length) {
      return true;
    } else {
      return component.componentTreeNodes.some(cn =>
        hasComponentsWithInvalidBindings(cn)
      );
    }
  }

  if (component.componentTreeNodes.some(ctn => hasComponentsWithInvalidBindings(ctn))) {
    errors.push({
      field: errorFieldKey,
      message: "One of this component's children has an error."
    });
  }

  const attrs = attributeColumns.map(a => a.attribute);
  if (attrs.some(ac => !ac)) {
    errors.push({
      field: errorFieldKey,
      message: "Each column based on an attribute requires an attribute."
    });
  }

  const columnsMissingAttributes = attrs.filter(
    ac => !!ac && attributes !== null && !attributes.find(ra => ra.sourceName === ac)
  );
  if (columnsMissingAttributes.length > 0) {
    errors.push({
      field: errorFieldKey,
      message: `The ${pluralize(
        "field",
        columnsMissingAttributes.length
      )} ${humanizeList(columnsMissingAttributes)} no longer ${
        columnsMissingAttributes.length > 1 ? "exist" : "exists"
      }.`
    });
  }

  const allAttrs = new Set(attrs);
  const missingRequiredAttrs = (attributes || []).filter(
    attr => attr.sourcePrimaryKey && !allAttrs.has(attr.sourceName)
  );

  if (missingRequiredAttrs.length > 0) {
    errors.push({
      field: errorFieldKey,
      message: `This component must include columns for each primary key. Please add ${humanizeList(
        missingRequiredAttrs.map(a => a.sourceName)
      )} before saving.`
    });
  }
  return errors;
}
