import React from "react";

import gql from "graphql-tag";
import moment from "moment";
import { useQuery } from "react-apollo";
import styled from "styled-components";

import { B3, H4, C2 } from "../../../../components/common/StyledComponents";
import { planName } from "../../../util/billing";

const LineItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const LineItemBorderBottom = styled(LineItem)`
  border-bottom: 1px solid ${props => props.theme.newContainerPrimaryTint};
  padding-bottom: 20px;
`;

const ModalBody = styled.ul`
  padding: 0;
`;

export const GET_PRICE_ESTIMATE = gql`
  query PriceEstimate($term: String!, $quantity: Int!) {
    getPriceEstimate(term: $term, quantity: $quantity) {
      total
      proration
      periodEnd
    }
  }
`;

interface AdditionalSeatsSummaryProps {
  subscription?: {
    quantity: number;
    plan: {
      amount: number;
      interval: string;
      product: {
        name: string;
      };
    };
  };
  seatsRequired: number;
  currentSeatCount?: number;
}

export const AdditionalSeatsSummary = ({
  subscription,
  seatsRequired,
  currentSeatCount
}: AdditionalSeatsSummaryProps) => {
  const plan = subscription?.plan;
  const product = plan?.product;

  const { data: priceEstimate } = useQuery(GET_PRICE_ESTIMATE, {
    fetchPolicy: "cache-and-network",
    variables: {
      term: plan?.interval === "year" ? "annual" : "monthly",
      quantity: currentSeatCount ? currentSeatCount + seatsRequired : 0
    }
  });

  if (!subscription || !priceEstimate) {
    return null;
  }

  const total =
    priceEstimate?.getPriceEstimate.total &&
    priceEstimate?.getPriceEstimate.total / 100;

  const pricePerUser =
    plan?.amount && plan.interval === "year" ? plan.amount / 12 : plan?.amount || 0;

  const subtotal = seatsRequired && plan ? (seatsRequired * plan?.amount) / 100 : 0;

  const periodEnd = moment.unix(priceEstimate?.getPriceEstimate?.periodEnd);
  const proration = priceEstimate?.getPriceEstimate?.proration;
  const currentTime = moment();

  return (
    <ModalBody>
      <LineItem>
        <B3>
          {planName(product?.name)} ({plan?.interval === "year" ? "annual" : "monthly"})
        </B3>
        <B3>
          {(pricePerUser / 100).toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })}
          /user per month
        </B3>
      </LineItem>

      <LineItemBorderBottom>
        <B3>Number of seats</B3>
        <B3>{seatsRequired}</B3>
      </LineItemBorderBottom>

      <LineItemBorderBottom>
        <B3>Subtotal</B3>
        <B3>
          {subtotal.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })}
        </B3>
      </LineItemBorderBottom>

      <LineItem>
        <H4>Billed today</H4>

        {total && (
          <H4>
            {total.toLocaleString("en-US", {
              style: "currency",
              currency: "USD"
            })}
          </H4>
        )}
      </LineItem>

      {proration && (
        <>
          <LineItem>
            <C2>
              Prorated amount for the remaining{" "}
              {Math.round(moment.duration(periodEnd.diff(currentTime)).asDays())} days
              in your billing cycle.
            </C2>
          </LineItem>

          {currentSeatCount && (
            <LineItem>
              <C2>
                You’re adding {seatsRequired} seats to your current plan,{" "}
                {planName(product?.name)} (
                {plan?.interval === "year" ? "annual" : "monthly"}). You will be charged{" "}
                {total.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })}{" "}
                today, which is the prorated amount for the current billing period.
                Starting {periodEnd.format("MMMM Do, YYYY")} your new monthly total will
                be{" "}
                {(
                  (pricePerUser / 100) *
                  (currentSeatCount + seatsRequired)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })}
                . This amount is based on your total number of seats.
              </C2>
            </LineItem>
          )}
        </>
      )}
    </ModalBody>
  );
};
