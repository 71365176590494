import React from "react";

import { Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import {
  BindingShape,
  SourceType,
  SourceTypeDisplayNames
} from "../../../../../../types";
import Spacer from "../../../../../common/Spacer";
import { useComponentContext } from "../../contexts/ComponentContext";
import { BindingCascader } from "../BindingCascader";
import { useComponentConfigContext } from "../ComponentConfigContext";
import ValidationError from "../ComponentConfigContext/ValidationError";
import { Alert, Checkbox } from "../ConfigPanel/styledComponents";
import useNestedStatus from "../useNestedStatus";
import { Action } from "../useViewConfig/reducer";
import { hasSource } from "../util";
import ViewPicker from "../ViewPicker";

import * as styled from "./styledComponents";

interface Props {
  requiresSource?: boolean;
  selectableSourceTypes?: SourceType[];
  compatibleBindingShapes?: BindingShape[];
}

export default function SourceSelector({
  requiresSource = true,
  selectableSourceTypes = [SourceType.VIEW, SourceType.BINDING],
  compatibleBindingShapes = [BindingShape.OBJECT, BindingShape.OBJECT_ARRAY]
}: Props) {
  const { pkg } = useComponentContext();
  const {
    state: { draftComponent },
    dispatch
  } = useComponentConfigContext();

  const [sourceSelectorVisible, setSourceSelectorVisible] = React.useState(
    requiresSource || hasSource(draftComponent)
  );

  const { isRepeated } = useNestedStatus();

  const onChange = React.useCallback(
    (sourceType: SourceType) => {
      dispatch({ type: Action.CHANGE_SOURCE, payload: { sourceType } });
    },
    [dispatch]
  );

  const onSelectBinding = (path: string) => {
    dispatch({
      type: Action.CHANGE_BINDING,
      payload: { path }
    });
  };

  const handleChangeSourceSelectorVisible = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      dispatch({ type: Action.RESET_SOURCE });
    }
    setSourceSelectorVisible(!e.target.checked);
  };

  React.useEffect(() => {
    if (
      selectableSourceTypes.length === 1 &&
      draftComponent.sourceType !== selectableSourceTypes[0] &&
      sourceSelectorVisible
    ) {
      onChange(selectableSourceTypes[0]);
    }
  }, [
    selectableSourceTypes,
    draftComponent.sourceType,
    sourceSelectorVisible,
    onChange
  ]);

  const { sourceType } = draftComponent;

  return (
    <div>
      {sourceSelectorVisible && (
        <>
          {selectableSourceTypes.length > 1 && (
            <styled.Select
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              placeholder="Select a source type"
              defaultValue={sourceType}
              value={sourceType}
              onChange={s => onChange(s as SourceType)}
            >
              {selectableSourceTypes.map(t => (
                <Select.Option key={t} value={t}>
                  {SourceTypeDisplayNames.get(t)}
                </Select.Option>
              ))}
            </styled.Select>
          )}
          {sourceType === "FILE" && <span>Source data from a file upload.</span>}
          {sourceType === "VIEW" && <ViewPicker />}
          {sourceType === "BINDING" && (
            <BindingCascader
              value={draftComponent.properties.binding || ""}
              selectable={compatibleBindingShapes}
              onChange={onSelectBinding}
            />
          )}
          {!pkg.isCollection && isRepeated && sourceType === SourceType.VIEW && (
            <>
              <Spacer size="sm" />
              <Alert
                showIcon
                type="warning"
                message="This component will be rendered repeatedly by one of its ancestors. If the function's parameters change between repetitions, the function will be executed multiple times. This can result in degraded performance."
              />
            </>
          )}
        </>
      )}
      {!requiresSource && (
        <>
          {sourceSelectorVisible && <styled.Spacer />}
          <Checkbox
            checked={!sourceSelectorVisible}
            onChange={handleChangeSourceSelectorVisible}
          >
            This component does not require a source.
          </Checkbox>
        </>
      )}
      <ValidationError field="SOURCE" />
    </div>
  );
}
