import React, { ChangeEvent } from "react";

import { Input, Select } from "antd";

import ColorPicker from "../ColorPicker";
import { ICON_NAMES, MaterialIconType } from "../Icons/MaterialIcons";
import { ensureHexValue } from "../utils";

import * as styled from "./styledComponents";

export interface GeneralSettingsProps {
  name: string;
  description?: string;
  icon?: MaterialIconType;
  color?: string;
}

interface Props {
  title: string;
  state: GeneralSettingsProps;
  onNameChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  onIconChange: (icon: string) => void;
  onColorChange: (color: string) => void;
}

export const GeneralSettings = (props: Props) => {
  const {
    title,
    state,
    onNameChange,
    onDescriptionChange,
    onIconChange,
    onColorChange
  } = props;
  return (
    <div>
      <styled.Title>General Settings</styled.Title>
      <styled.SubTitle>Name and description</styled.SubTitle>

      <styled.Fieldset>
        <styled.Label>{title} name</styled.Label>
        <Input
          type="text"
          placeholder="Enter name"
          value={state.name}
          onChange={onNameChange}
        />
      </styled.Fieldset>
      <styled.Fieldset>
        <styled.Label>Description (optional)</styled.Label>
        <Input
          type="text"
          placeholder="Add description"
          value={state.description}
          onChange={onDescriptionChange}
        />
      </styled.Fieldset>
      <styled.IconColor>
        <styled.SelectContainer>
          <styled.Label>Icon (optional)</styled.Label>
          <styled.Select
            data-test="iconSelectDropdown"
            value={state.icon}
            dropdownRender={children => {
              return (
                <styled.IconSelectOptionsWrapper>
                  {children}
                </styled.IconSelectOptionsWrapper>
              );
            }}
            onChange={icon => onIconChange(icon as string)}
          >
            {ICON_NAMES.map(n => (
              <Select.Option data-test="iconOption" key={n} title={n} value={n}>
                <styled.Icon color={ensureHexValue(state.color || "")} type={n} />
              </Select.Option>
            ))}
          </styled.Select>
        </styled.SelectContainer>
        <div>
          <styled.Label>Color</styled.Label>
          <ColorPicker
            value={ensureHexValue(state.color || "")}
            border={false}
            onChange={onColorChange}
          ></ColorPicker>
        </div>
      </styled.IconColor>
    </div>
  );
};

export default GeneralSettings;
