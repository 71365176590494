import React, { Dispatch } from "react";

import { Select } from "antd";

import { assertNever } from "../../../../../util/assertNever";
import * as common from "../../../common/styledComponents";
import { ReducerAction, ReducerActions } from "../../../reducer/actions";
import { ConfigState } from "../../../reducer/reducer";
import {
  Action,
  ActionType,
  ChangeAssigneeOptions,
  ExecuteFunctionOptions,
  OpenUrlOptions,
  UpdateTaskOptions
} from "../../../types";
import ChangeAssigneeAction from "../actions/ChangeAssignee";
import ExecuteFunctionAction from "../actions/ExecuteFunction";
import OpenUrlAction from "../actions/OpenUrl";
import UpdateTaskAction from "../actions/UpdateTask";

import * as styled from "./styledComponents";

interface Props {
  action: Action;
  actionIndex: number;
  actionTypes: ActionType[];
  dispatch: Dispatch<ReducerAction>;
  state: ConfigState;
}

const actionTypeDescription: Record<ActionType, string> = {
  [ActionType.CHANGE_ASSIGNEE]: "Change assignee",
  [ActionType.EXECUTE_FUNCTION]: "Execute a function",
  [ActionType.OPEN_URL]: "Open a url",
  [ActionType.UPDATE_TASK]: "Update the current task"
};

const ActionSelector = ({
  action,
  actionIndex,
  actionTypes,
  dispatch,
  state
}: Props) => {
  const onChangeActionType = (type: ActionType) =>
    dispatch({
      type: ReducerActions.UPDATE_ACTION,
      payload: { action: { type }, index: actionIndex }
    });

  const onDelete = () =>
    dispatch({
      type: ReducerActions.DELETE_ACTION,
      payload: {
        actionIndex
      }
    });

  const renderOptionsComponent = (type: ActionType) => {
    switch (type) {
      case ActionType.CHANGE_ASSIGNEE:
        return (
          <ChangeAssigneeAction
            index={actionIndex}
            action={action as Action<ChangeAssigneeOptions>}
            dispatch={dispatch}
            state={state}
          />
        );
      case ActionType.EXECUTE_FUNCTION:
        return (
          <ExecuteFunctionAction
            index={actionIndex}
            action={action as Action<ExecuteFunctionOptions>}
            dispatch={dispatch}
            state={state}
          />
        );
      case ActionType.OPEN_URL:
        return (
          <OpenUrlAction
            index={actionIndex}
            action={action as Action<OpenUrlOptions>}
            dispatch={dispatch}
            state={state}
          />
        );
      case ActionType.UPDATE_TASK:
        return (
          <UpdateTaskAction
            index={actionIndex}
            action={action as Action<UpdateTaskOptions>}
            dispatch={dispatch}
            state={state}
          />
        );
      default:
        return assertNever(type);
    }
  };

  return (
    <styled.Action>
      <styled.ActionSelect>
        <styled.ContentContainer>
          <Select
            style={{ width: "100%" }}
            placeholder="None selected"
            value={action.type}
            onChange={onChangeActionType}
          >
            {actionTypes.map(type => (
              <Select.Option key={type}>
                {actionTypeDescription[type as ActionType]}
              </Select.Option>
            ))}
          </Select>
          {action.type ? renderOptionsComponent(action.type) : null}
        </styled.ContentContainer>
        <common.IconButton icon="delete" title="Delete action" onClick={onDelete} />
      </styled.ActionSelect>
    </styled.Action>
  );
};

export default ActionSelector;
