import React from "react";

import { Collapse, Spin } from "antd";
import _ from "lodash";
import * as moment from "moment";
import { useQuery } from "react-apollo";
import styled from "styled-components";

import { FETCH_SYNC_PLANS_FOR_DATA_SOURCE } from "../../../../graphql/sync";
import { Table } from "../../../common/StyledComponents";
import { SubHead } from "../DataSourceSettingsPanel/styledComponents";

const Root = styled.div`
  margin-top: ${props => props.theme.spacermd};
  border: none;
`;

const Panel = styled(Collapse)`
  margin-top: ${props => props.theme.spacermd};
  background-color: white;

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-table-pagination {
    margin-right: ${props => props.theme.spacermd};
  }

  .ant-collapse-header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
`;

const syncHistoryColumns = [
  {
    title: "Change",
    dataIndex: "operationLabel",
    key: "change",
    width: "35%"
  },
  {
    title: "Name",
    dataIndex: "description",
    key: "description",
    width: "65%",
    render: (text: string) => {
      return <span>{text || "-"}</span>;
    }
  }
];

const renderSyncHistory = (sync: any) => {
  const operations =
    (sync && sync.operations.edges.map((edge: any) => edge.node)) || [];

  const createdAt = moment
    .utc(sync.createdAt)
    .local()
    .format("MM/DD/YYYY [at] hh:mma z");

  return (
    <Panel key={sync.id} expandIconPosition="right">
      <Collapse.Panel key="history-view" header={<h4>{createdAt}</h4>}>
        <Table
          dataSource={operations}
          pagination={{ pageSize: 4 }}
          columns={syncHistoryColumns}
        />
      </Collapse.Panel>
    </Panel>
  );
};

interface DataSourceSyncHistoryPanelProps {
  dataSourceId: string;
}

export const DataSourceSyncHistoryPanel = ({
  dataSourceId
}: DataSourceSyncHistoryPanelProps) => {
  const { data, loading } = useQuery(FETCH_SYNC_PLANS_FOR_DATA_SOURCE, {
    variables: { dataSourceId }
  });
  const queryData = data;
  const syncHistory =
    queryData && _.orderBy(queryData.allSyncsForDataSourceId, ["createdAt"], ["desc"]);
  return (
    <Root>
      {loading && <Spin size="large" />}
      {!loading && <SubHead>Sync History:</SubHead>}
      {syncHistory &&
        _.chain(syncHistory)
          .filter(sync => sync.status === "COMPLETE")
          .map(sync => renderSyncHistory(sync))
          .value()}
    </Root>
  );
};
