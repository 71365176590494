import { BaseFunctionName } from "../../../../types";

export enum HttpMethods {
  Get = "GET",
  Head = "HEAD",
  Post = "POST",
  Put = "PUT",
  Patch = "PATCH",
  Delete = "DELETE"
}

export enum RequestBodyType {
  None = "none",
  JSON = "json",
  GraphQL = "graphql",
  Raw = "raw",
  Binary = "binary",
  Form = "form"
}

export enum GraphqlPart {
  Query = "graphqlQuery",
  Variables = "graphqlVariables"
}

export type SqlFunctionName =
  | BaseFunctionName.QUERY
  | BaseFunctionName.INSERT
  | BaseFunctionName.UPDATE
  | BaseFunctionName.DELETE
  | BaseFunctionName.SQL_EXECUTE
  | BaseFunctionName.SQL_QUERY;

export type SqlQueryFunctionName =
  | BaseFunctionName.QUERY
  | BaseFunctionName.SQL_EXECUTE
  | BaseFunctionName.SQL_QUERY;

export type SqlWriteFunctionName =
  | BaseFunctionName.INSERT
  | BaseFunctionName.UPDATE
  | BaseFunctionName.DELETE;

export const SqlFunctionDisplayNames: Record<SqlFunctionName, string> = {
  [BaseFunctionName.QUERY]: "Select (with automatic filtering and pagination)",
  [BaseFunctionName.INSERT]: "Insert",
  [BaseFunctionName.UPDATE]: "Update",
  [BaseFunctionName.DELETE]: "Delete",
  [BaseFunctionName.SQL_EXECUTE]: "Raw SQL Exec",
  [BaseFunctionName.SQL_QUERY]: "Raw SQL Query"
};

export const isSQLQueryFunctionName = (function () {
  // If a new SqlQueryFunctionName is added then this will trigger a
  // type warning if it is not included in the map.
  const map: Record<SqlQueryFunctionName, boolean> = {
    [BaseFunctionName.QUERY]: true,
    [BaseFunctionName.SQL_EXECUTE]: true,
    [BaseFunctionName.SQL_QUERY]: true
  };
  return (action: BaseFunctionName | undefined): action is SqlQueryFunctionName =>
    !!action && map[action as SqlQueryFunctionName];
})();

export const isSQLWriteFunctionName = (function () {
  // If a new SqlWriteFunctionName is added then this will trigger a
  // type warning if it is not included in the map.
  const map: Record<SqlWriteFunctionName, boolean> = {
    [BaseFunctionName.INSERT]: true,
    [BaseFunctionName.UPDATE]: true,
    [BaseFunctionName.DELETE]: true
  };
  return (action: BaseFunctionName | undefined): action is SqlWriteFunctionName =>
    !!action && map[action as SqlWriteFunctionName];
})();
