import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { AttributeTypes } from "../../../../../../constants";
import { InputParameter, RelayNode } from "../../../../../../types";
import { INPUT_PARAMETER_FRAGMENT } from "../../../../queries/common";

interface _FieldNode extends RelayNode {
  id: string;
  name: string;
  type: AttributeTypes;
  required: boolean;
}

export interface StateNode extends RelayNode {
  name: string;
}

export interface QueueNode extends RelayNode {
  __typename?: "QueueNode";
  name: string;
  slug: string;
  fields: _FieldNode[];
  createTaskForm: { functionId: string; inputParameters: InputParameter[] };
  updateTaskForm: { functionId: string; inputParameters: InputParameter[] };
}

interface Data {
  queueBySlug: QueueNode;
}

interface Variables {
  slug: string;
}

const QUERY = gql`
  query QueueBySlug($slug: String!) {
    queueBySlug(slug: $slug) {
      id
      name
      description
      slug
      states {
        id
        name
      }
      updateTaskForm {
        functionId
        inputParameters {
          ...InputParameterFragment
        }
      }
      fields {
        id
        name
        type
        required
      }
    }
  }
  ${INPUT_PARAMETER_FRAGMENT}
`;

export type Result = QueryResult<Data, Variables>;

export const useQueueBySlug = (options?: QueryHookOptions<Data, Variables>): Result =>
  useQuery<Data, Variables>(QUERY, options);
