import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import QueuesAnalyticsIcon from "../../../assets/queues-analytics-icon.svg";
import usePaths from "../../common/hooks/usePaths";

// TODO use transientProps in styled 5.1: https://styled-components.com/docs/api#transient-props
export const AnalyticsLink = styled(Link)<{ active?: number }>`
  height: 24px;
  color: ${props => (props.active ? props.theme.primaryColor : "white")};
`;

interface Props {
  queueSlug: string;
  active?: boolean;
  className?: string;
}

export default function AnalyticsButton({ queueSlug, active, className }: Props) {
  const { queuesapp_getQueueAnalytics } = usePaths();

  return (
    <AnalyticsLink
      active={active ? 1 : 0}
      className={className}
      to={queuesapp_getQueueAnalytics(queueSlug)}
    >
      <QueuesAnalyticsIcon />
    </AnalyticsLink>
  );
}
