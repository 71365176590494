import React, { useCallback, useState } from "react";

import { Icon, Tooltip } from "antd";
import * as dnd from "react-beautiful-dnd";
import styled from "styled-components";

import { zIndex } from "../../../cssConstants";
import IconButton from "../IconButton";

import DragHandle from "./dragHandleCompact.svg";

interface Props extends Partial<dnd.DraggableProvidedDraggableProps> {
  id: string;
  children: React.ReactNode;
  sortKey: string;
  errorMessage: string | null;
  isSelected: boolean;
  isHidden?: boolean;
  title?: string;
  isHideDisabled?: boolean;
  hideButtonTooltip?: string;
  dragHandleProps?: dnd.DraggableProvidedDragHandleProps;
  onRemove?: (sortKey: string) => void;
  onToggleHide?: () => void /* if no function is passed in, hide button is hidden */;
  onClick?: () => void /* component click handler */;
}

const DragHandleContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  color: ${props => props.theme.rowDragHandleColor};
  visibility: hidden;
  transform: translateX(-13px);
`;

export const Root = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  width: ${props =>
    `calc(100% + ${
      +props.theme.spacervaluemd * 2
    }px)`}; /* account for negative margin */
  margin: 0 -${props => props.theme.spacervaluemd}px;
  padding: ${props => `0 ${props.theme.spacermd}`};
  background: ${props =>
    props.isSelected ? props.theme.rowSelectedBackground : props.theme.backgroundColor};
  cursor: pointer;

  &.isDragging {
    background: ${props => props.theme.rowDragBackground};
    opacity: 0.85;
  }

  &:hover {
    background: ${props => props.theme.rowHoverBackground};
    ${DragHandleContainer} {
      visibility: visible;
    }
  }
  &:focus {
    background: ${props => props.theme.backgroundColor};
    border: ${props => props.theme.rowFocusBorder};
    outline: none;
  }
  &:active {
    background: ${props => props.theme.rowActiveBackground};
  }
`;

const Contents = styled.div`
  flex-grow: 1;
  color: ${props => props.theme.textColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.rowFontSize};
`;

// styling `Icon` directly doesn't work so using a wrapper
const ErrorIconWrapper = styled.div`
  margin-right: ${props => props.theme.spacerxs};
  color: ${props => props.theme.errorColor};
`;

export default React.forwardRef(function SortableItemCompact(
  {
    id,
    children,
    dragHandleProps,
    sortKey,
    onRemove,
    onClick,
    errorMessage,
    onToggleHide,
    isHidden,
    isHideDisabled,
    hideButtonTooltip,
    isSelected,
    title,
    ...rest
  }: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const onRemoveClick = useCallback(() => {
    if (!onRemove) return;
    onRemove(sortKey);
  }, [sortKey, onRemove]);

  const handleClick = useCallback(
    event => {
      if (
        !onClick ||
        (event.target as HTMLDivElement).getAttribute("role") === "tooltip"
      ) {
        return;
      }
      onClick();
    },
    [onClick]
  );

  const removeIcon = (
    <IconButton
      icon="minus"
      disabled={!onRemove}
      title="Remove item from list"
      onClick={onRemoveClick}
    />
  );

  const hideButton = (
    <IconButton
      data-test="hide-button"
      icon={isHidden ? "eye-invisible" : "eye"}
      onClick={onToggleHide}
      disabled={isHideDisabled}
    />
  );

  return (
    <Root
      id={id}
      ref={ref}
      title={title}
      onClick={handleClick}
      isSelected={isSelected}
      {...rest}
    >
      {!!dragHandleProps && (
        <DragHandleContainer
          {...dragHandleProps}
          onMouseDown={() => isOpen && toggleOpen()}
        >
          <DragHandle />
        </DragHandleContainer>
      )}
      <Contents>{children}</Contents>
      {!!errorMessage && (
        <Tooltip
          overlayStyle={{ zIndex: zIndex.popoverOverDrawer }}
          title={errorMessage}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          <ErrorIconWrapper>
            <Icon type="exclamation-circle" theme="filled" />
          </ErrorIconWrapper>
        </Tooltip>
      )}
      {!!onToggleHide &&
        (hideButtonTooltip ? (
          <Tooltip
            overlayStyle={{ zIndex: zIndex.popoverOverDrawer }}
            title={hideButtonTooltip}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          >
            {/* wrapping button in div as workaround for tooltip not disappearing
            https://github.com/ant-design/ant-design/issues/9581#issuecomment-599668648 */}
            <div>{hideButton}</div>
          </Tooltip>
        ) : (
          hideButton
        ))}
      {!!onRemove ? (
        removeIcon
      ) : (
        <Tooltip
          overlayStyle={{ zIndex: zIndex.popoverOverDrawer }}
          title="This is a required field."
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          {/* wrapping button in div as workaround for tooltip not disappearing
            https://github.com/ant-design/ant-design/issues/9581#issuecomment-599668648 */}
          <div>{removeIcon}</div>
        </Tooltip>
      )}
    </Root>
  );
});
