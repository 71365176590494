import React, { useState } from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Skeleton, Select, Modal } from "antd";
import gql from "graphql-tag";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { useConfigContext } from "../../ConfigContext";
import {
  GET_USER_BY_ID,
  GET_SUBSCRIPTION_DATA,
  GET_ALL_USERS_DATA,
  GET_ALL_INVITES_DATA
} from "../../graphql/queries";
import { TableLayoutNoMargin } from "../../layouts/TableLayout";
import {
  UserNode,
  UserStatus,
  SpaceNode,
  FunctionNode,
  Edge,
  OrganizationInviteNode
} from "../../types";
import AuditLog from "../common/AuditLog";
import Drawer from "../common/Drawer";
import useAuthUser from "../common/hooks/useAuthUser";
import Message from "../common/Message";
import { isInternalEmail } from "../util/users";

import { AdditionalSeatsSummary } from "./Billing/checkout/AdditionalSeatsSummary";
import DeleteUserButton from "./DeleteUserButton";

interface AuditLogDrawerProps {
  onClose: () => void;
  isVisible: boolean;
}

const AUDIT_FILTER_OPTIONS_QUERY = gql`
  query GetAuditFilterOptions {
    allSpaces {
      edges {
        node {
          id
          name
        }
      }
    }
    allFunctions(scope: "wrapped") {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const USER_SET_IS_ACTIVE = gql`
  mutation UserSetIsActive($userId: ID!, $isActive: Boolean!) {
    userSetIsActive(userId: $userId, isActive: $isActive) {
      ok
      user {
        id
        email
        status
      }
    }
  }
`;

const StyledUserButtons = styled.p`
  button.ant-btn {
    margin-right: ${props => props.theme.spacersm};
  }
`;

const UserDetails = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
  margin-left: 16px;

  .userInfo {
    display: flex;
    flex-direction: row;
    .label {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      min-width: 200px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  .ant-select {
    min-width: 225px;
  }
`;

const auditLogBreadCrumbs = (userId: string) => {
  return [
    {
      path: "",
      breadcrumbName: "Settings",
      children: []
    },
    {
      path: "/settings/users",
      breadcrumbName: "Users",
      children: []
    },
    {
      path: `/settings/users/${userId}`,
      breadcrumbName: "User Details",
      children: []
    }
  ];
};

const AuditLogDrawerContents = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const { userNodeId, authUser } = useAuthUser();
  const { isOnPrem } = useConfigContext();
  const { data: userData, loading: userDataLoading } = useQuery(GET_USER_BY_ID, {
    variables: { id: userId },
    fetchPolicy: "network-only"
  });
  const organization = authUser?.organization;
  const { data: filterData, loading: filterDataLoading } = useQuery(
    AUDIT_FILTER_OPTIONS_QUERY
  );

  const { data: subscriptionData } = useQuery(GET_SUBSCRIPTION_DATA, {
    fetchPolicy: "cache-and-network",
    skip: isOnPrem
  });

  const { data: invitesData } = useQuery(GET_ALL_INVITES_DATA, {
    fetchPolicy: "cache-and-network",
    skip: isOnPrem
  });
  const { data: usersData } = useQuery(GET_ALL_USERS_DATA, {
    fetchPolicy: "cache-and-network",
    skip: isOnPrem
  });
  const users = usersData?.allUsers?.edges.map((e: Edge<UserNode>) => e.node) || [];
  const invites =
    invitesData?.allOrganizationInvites?.edges.map(
      (e: Edge<OrganizationInviteNode>) => e.node
    ) || [];
  const activeUsers = users.filter((user: UserNode) => {
    return user.status === "active" && !isInternalEmail(user.email);
  });
  const totalUserCount =
    activeUsers.length +
    invites.filter((invite: OrganizationInviteNode) => !isInternalEmail(invite.email))
      .length;
  const subscription = subscriptionData?.stripeSubscription?.subscription;
  const saasSeatCount = subscription?.quantity;

  const saasSeatsAvailable = saasSeatCount ? saasSeatCount - totalUserCount : null;

  const [setUserIsActive, { loading: setUserIsActiveLoading }] =
    useMutation(USER_SET_IS_ACTIVE);
  const [filters, setFilters] = React.useState<{
    spaceId?: string;
    functionId?: string;
  }>({});

  const [showAddSeatModal, setShowAddSeatModal] = useState<boolean>(false);
  const [addedSeats, setAddedSeats] = useState<number>(0);

  const activateUser = React.useCallback(
    (vars: { variables: { userId: string; isActive: boolean } }) => {
      setAddedSeats(addedSeats - 1);
      setUserIsActive(vars);
    },
    [addedSeats, setUserIsActive]
  );

  const openAddSeatModal = React.useCallback(() => {
    if (!showAddSeatModal) {
      setShowAddSeatModal(true);
    }
  }, [showAddSeatModal, setShowAddSeatModal]);

  const closeAddSeatModal = React.useCallback(() => {
    if (showAddSeatModal) {
      setShowAddSeatModal(false);
    }
  }, [showAddSeatModal, setShowAddSeatModal]);

  const onFilter = React.useCallback((input, option: any) => {
    if (option.type === Select.OptGroup) return false;
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }, []);

  const onChange = React.useCallback(
    (value: string) => {
      const [filterType, filterValue] = value.split(".", 2);
      if (filterType === "function") {
        setFilters({ functionId: filterValue });
      } else if (filterType === "space") {
        setFilters({ spaceId: filterValue });
      } else {
        setFilters({});
      }
    },
    [setFilters]
  );

  const handleUserToggleActivate = React.useCallback(
    (userId: string, isActive: boolean) => {
      if (
        isOnPrem ||
        !isActive ||
        saasSeatsAvailable === null ||
        saasSeatsAvailable >= addedSeats
      ) {
        return activateUser({ variables: { userId, isActive } });
      } else {
        openAddSeatModal();
      }
    },
    [saasSeatsAvailable, addedSeats, openAddSeatModal, activateUser, isOnPrem]
  );

  const handleUserDeleted = React.useCallback(
    (user: UserNode) => {
      navigate("/settings/users");
      Message.success(`User ${user.email} has been deleted.`);
    },
    [navigate]
  );

  const loading = userDataLoading || filterDataLoading;
  const spaces = filterData?.allSpaces.edges.map((e: Edge<SpaceNode>) => e.node) || [];
  const functions =
    filterData?.allFunctions.edges.map((e: Edge<FunctionNode>) => e.node) || [];

  if (loading) return <Skeleton active />;

  const user: UserNode = userData?.user;

  if (!user) {
    return (
      <>
        <h2>Requested user does not exist.</h2>
        <h3>They may have been deleted.</h3>
      </>
    );
  }

  return (
    <TableLayoutNoMargin
      title={`${user.firstName} ${user.lastName}`}
      breadcrumbRoutes={auditLogBreadCrumbs(userId!)}
    >
      <React.Fragment>
        <h3>User Details</h3>
        <UserDetails>
          <div className="userInfo">
            <p className="label">Email</p>
            <p>{user.email}</p>
          </div>
          <div className="userInfo">
            <p className="label">Roles</p>
            <p>
              {_.chain(user.roles!.edges)
                .map(edge => edge.node.name)
                .join(", ")
                .value()}
            </p>
          </div>
          <div className="userInfo">
            <p className="label">Status</p>
            <p>{user.status}</p>
          </div>
          {user.id !== userNodeId && (
            <div className="userInfo">
              <p className="label"></p>
              <StyledUserButtons>
                {user.status === UserStatus.Active && (
                  <Button
                    type="danger"
                    loading={setUserIsActiveLoading}
                    onClick={() => handleUserToggleActivate(user.id, false)}
                  >
                    Deactivate
                  </Button>
                )}
                {user.status === UserStatus.Inactive && (
                  <Button
                    type="primary"
                    loading={setUserIsActiveLoading}
                    onClick={() => handleUserToggleActivate(user.id, true)}
                  >
                    Reactivate
                  </Button>
                )}
                {user.status === UserStatus.Inactive && (
                  <DeleteUserButton user={user} onDelete={handleUserDeleted} />
                )}
              </StyledUserButtons>
            </div>
          )}
        </UserDetails>
        {organization?.licenseData?.auditLogs && (
          <>
            <Header>
              <h3>User Activity Log</h3>
              <Select
                defaultValue="everything"
                onChange={onChange}
                filterOption={onFilter}
                showSearch
              >
                <Select.Option value="everything">Everything</Select.Option>
                {spaces?.length && (
                  <Select.OptGroup label="Spaces">
                    {spaces.map((s: SpaceNode) => (
                      <Select.Option key={s.id} value={`space.${s.id}`}>
                        {s.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )}

                {functions && functions.length && (
                  <Select.OptGroup label="Functions">
                    {functions.map((f: FunctionNode) => (
                      <Select.Option key={f.id} value={`function.${f.id}`}>
                        {f.title}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )}
              </Select>
            </Header>
            <AuditLog
              userId={user.id}
              spaceId={filters.spaceId}
              functionId={filters.functionId}
            />
          </>
        )}
        <Modal
          title="Add additional seats"
          visible={showAddSeatModal}
          onCancel={() => closeAddSeatModal()}
          footer={[
            <Button key="cancel" onClick={() => closeAddSeatModal()}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => {
                setShowAddSeatModal(false);
                setUserIsActive({ variables: { userId, isActive: true } });
              }}
            >
              Confirm purchase
            </Button>
          ]}
        >
          {subscription && saasSeatsAvailable !== null && saasSeatsAvailable > 0 && (
            <AdditionalSeatsSummary
              subscription={subscription}
              seatsRequired={Math.abs(saasSeatsAvailable)}
              currentSeatCount={saasSeatCount}
            />
          )}
        </Modal>
      </React.Fragment>
    </TableLayoutNoMargin>
  );
};

const AuditLogDrawer = ({ onClose, isVisible }: AuditLogDrawerProps) => {
  return (
    <Drawer placement="right" closable={false} onClose={onClose} visible={isVisible}>
      <AuditLogDrawerContents />
    </Drawer>
  );
};

export default AuditLogDrawer;
