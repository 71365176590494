import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Input } from "antd";
import classNames from "classnames";
import gql from "graphql-tag";
import { ErrorCode, useDropzone } from "react-dropzone";
import styled from "styled-components";

import { FileInput, OrganizationNode, OrganizationNodeInput } from "../../../../types";
import LimitCounter from "../../../common/LimitCounter/LimitCounter";
import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";

type ConfigureOrgModalProps = {
  org: OrganizationNode;
  onCancel: () => void;
};

const LogoField = styled.div`
  margin-bottom: ${props => props.theme.spacermd};
`;

const StyledDropTarget = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.spacersm};
  color: ${props => props.theme.textColorMid};
  border-width: 2px;
  border-radius: ${props => props.theme.spacerxs};
  border-color: ${props => props.theme.borderGrey};
  border-style: dashed;
  outline: none;
  &.active {
    border-color: ${props => props.theme.primaryColorFaded};
  }
`;

const PreviewWrapper = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacermd} 0;
`;

const PreviewImage = styled.img`
  max-width: 100%;
`;

const ResetButton = styled(Button)`
  font-size: 12px;
  padding: 0;
`;

const Label = styled.label``;

const StyledInput = styled(Input)`
  margin-bottom: ${props => props.theme.spacermd};
`;

const StyledLimitCounter = styled(LimitCounter)`
  margin-top: ${props => props.theme.spacerxs};
  text-align: right;
`;

export default function ConfigureOrgModal({ org, onCancel }: ConfigureOrgModalProps) {
  const [draftOrg, setDraftOrg] = React.useState(org);
  const [pendingLogo, setPendingLogo] = React.useState<FileInput | null>(null);
  const [configureOrg, { loading, error }] =
    useMutation<OrganizationNodeInput>(CONFIGURE_ORGANIZATION);

  const handleChange = (change: Partial<OrganizationNode>) => {
    setDraftOrg({
      ...draftOrg,
      ...change
    });
  };

  const onDrop = React.useCallback(acceptedFiles => {
    const reader = new FileReader();
    const file = acceptedFiles[0];
    if (file) {
      reader.onerror = () => Message.error("There was an error processing your image.");
      reader.onload = () => {
        const encodedFile = reader.result as string;
        setPendingLogo({ data: encodedFile });
      };
      reader.readAsDataURL(file);
    }
  }, []);
  const onDropRejected = React.useCallback(fileRejections => {
    const error = fileRejections[0].errors[0];
    switch (error.code) {
      case ErrorCode.FileInvalidType:
        Message.error("Logo must be gif, jpeg, png, or webp image file");
        break;
      case ErrorCode.FileTooLarge:
        Message.error("Logo must be smaller than 1MB");
        break;
      default:
        Message.error(error.message);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 1024 * 1024,
    accept: ["image/gif", "image/jpeg", "image/png", "image/webp"],
    onDrop,
    onDropRejected
  });

  React.useEffect(() => {
    if (error) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [error]);

  return (
    <ModalNew
      visible
      title="Organization settings"
      confirmLoading={loading}
      onOk={async () => {
        const { id, name, description } = draftOrg;
        // Strip js type data from base64 encoded string
        const maybeFileInput = pendingLogo
          ? { data: pendingLogo.data.split(",")[1] }
          : undefined;
        await configureOrg({
          variables: {
            id,
            name,
            description,
            logo: maybeFileInput
          },
          refetchQueries: ["OrgDetailQuery"]
        });
        Message.success("Organization settings updated.");
        onCancel();
      }}
      onCancel={onCancel}
    >
      <LogoField>
        <Label>Logo</Label>
        <StyledDropTarget
          {...getRootProps()}
          className={classNames({ active: isDragActive })}
        >
          <input {...getInputProps()} data-test="logoTarget" />
          {isDragActive ? (
            <>Drop the image here…</>
          ) : (
            <>Drag 'n drop an image here, or click to select.</>
          )}
        </StyledDropTarget>
        {pendingLogo ? (
          <PreviewWrapper>
            <PreviewImage src={pendingLogo.data} alt="Organization's logo" />
            <div>
              New logo{" "}
              <ResetButton
                data-test="resetButton"
                type="link"
                onClick={() => {
                  setPendingLogo(null);
                }}
              >
                reset
              </ResetButton>
            </div>
          </PreviewWrapper>
        ) : org.logo?.url ? (
          <PreviewWrapper>
            <PreviewImage src={org.logo.url} alt="Organization's logo" />
            <div>Current logo</div>
          </PreviewWrapper>
        ) : null}
      </LogoField>
      <Label>
        Name
        <StyledInput
          data-test="spaceNameInput"
          value={draftOrg.name}
          onChange={ev => {
            handleChange({ name: ev.target.value });
          }}
        />
      </Label>
      <Label>
        Description (optional)
        <Input.TextArea
          data-test="spaceDescInput"
          value={draftOrg.description}
          maxLength={1000}
          onChange={ev => {
            handleChange({ description: ev.target.value });
          }}
        />
        <StyledLimitCounter current={draftOrg.description?.length} limit={1000} />
      </Label>
    </ModalNew>
  );
}

const CONFIGURE_ORGANIZATION = gql`
  mutation ConfigureOrganization(
    $name: String
    $description: String
    $logo: FileInput
  ) {
    configureOrganization(name: $name, description: $description, logo: $logo) {
      organization {
        id
      }
    }
  }
`;
