import React from "react";

import { isEqual, cloneDeep } from "lodash";

import {
  SpaceComponentType,
  SpaceComponentObject,
  SpaceComponentPackage
} from "../../../../types";
import ErrorBoundary from "../../../common/ErrorBoundary";
import withErrorBoundary from "../../../hoc/withErrorBoundary";
import debug from "../../../util/debug";
import { reportException } from "../../../util/exceptionReporting";
import { ElementLayout } from "../../layout/util";
import SpaceApi from "../../SpaceApi";
import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../SpaceContext";

import { SpaceInputFieldState } from "./common/useOutputSyncing/useOutputSyncing";
import { ComponentContextContainer } from "./contexts/ComponentContext";
import {
  ComponentStateContainer,
  useComponentStateContext
} from "./contexts/ComponentStateContext";
import LayoutComponent from "./LayoutComponent";
import {
  Card as _cardPackage,
  Fieldset as _fieldsetPackage,
  Option as _optionPackage,
  RepeatedItem as _repeatedItemPackage,
  Row as _rowPackage,
  PseudoSpaceComponentPackage
} from "./pseudo";
import _caclulatedFieldPackage from "./SpaceCalculatedField/package";
import _cardListPackage from "./SpaceCardList/package";
import _chartPackage from "./SpaceChart/package";
import _checkboxPackage from "./SpaceCheckbox/package";
import _contextParamPackage from "./SpaceContextParam/package";
import _customFieldPackage from "./SpaceCustomField/package";
import _dataViewerPackage from "./SpaceDataViewer/package";
import _dateTimePickerPackage from "./SpaceDateTimePicker/package";
import _detailPackage from "./SpaceDetail/package";
import { SpaceDetailState } from "./SpaceDetail/types";
import _dropdownPackage from "./SpaceDropdown/package";
import _dynamicFunction from "./SpaceDynamicFunction/package";
import _environmentPackage from "./SpaceEnvironment/package";
import _filePickerPackage from "./SpaceFilePicker/package";
import _flexBoxPackage from "./SpaceFlexBox/package";
import { SpaceFunctionState } from "./SpaceFunction";
import _functionBulkImportPackage from "./SpaceFunctionBulkImport/package";
import _functionButtonPackage from "./SpaceFunctionButton/package";
import _functionFormPackage from "./SpaceFunctionForm/package";
import _functionHeadlessPackage from "./SpaceFunctionHeadless/package";
import _functionModalFormPackage from "./SpaceFunctionModalForm/package";
import _headerPackage from "./SpaceHeader/package";
import _imagePackage from "./SpaceImage/package";
import _jsonInputPackage from "./SpaceJsonInput/package";
import _linkPackage from "./SpaceLink/package";
import { SpaceParamsState } from "./SpaceParams";
import _spaceParamsPackage from "./SpaceParams/package";
import _radioButtonPackage from "./SpaceRadioButton/package";
import _s3UploaderPackage from "./SpaceS3Uploader/package";
import _statPackage from "./SpaceStat/package";
import { SpaceTableState } from "./SpaceTable";
import _tablePackage from "./SpaceTable/package";
import _tagSelectorPackage from "./SpaceTagSelector/package";
import _textAreaPackage from "./SpaceTextArea/package";
import _userPackage from "./SpaceUser/package";
import { SpaceUserState } from "./SpaceUser/types";
import _viewlessImagePackage from "./SpaceViewlessImage/package";

const packages = [
  _caclulatedFieldPackage,
  _cardListPackage,
  _chartPackage,
  _checkboxPackage,
  _contextParamPackage,
  _customFieldPackage,
  _spaceParamsPackage,
  _dataViewerPackage,
  _dateTimePickerPackage,
  _detailPackage,
  _dropdownPackage,
  _dynamicFunction,
  _environmentPackage,
  _filePickerPackage,
  _flexBoxPackage,
  _functionButtonPackage,
  _functionBulkImportPackage,
  _functionFormPackage,
  _functionHeadlessPackage,
  _functionModalFormPackage,
  _headerPackage,
  _imagePackage,
  _jsonInputPackage,
  _linkPackage,
  _radioButtonPackage,
  _s3UploaderPackage,
  _statPackage,
  _tablePackage,
  _tagSelectorPackage,
  _textAreaPackage,
  _userPackage,
  _viewlessImagePackage,

  // pseudo component packages
  _cardPackage,
  _fieldsetPackage,
  _optionPackage,
  _rowPackage,
  _repeatedItemPackage
] as Array<SpaceComponentPackage | PseudoSpaceComponentPackage>;

export const registerPackages = () => {
  window.__INTERNAL_SPACE_COMPONENT_PACKAGES = new Set(packages);
};

export const getTestPackages = () => {
  registerPackages();
  return window.__INTERNAL_SPACE_COMPONENT_PACKAGES;
};

export const getTestPackagesMap = () =>
  Array.from(getTestPackages()).reduce<Map<SpaceComponentType, SpaceComponentPackage>>(
    (acc, curr) => {
      acc.set(curr.type, curr);
      return acc;
    },
    new Map()
  );

registerPackages();

export interface SpaceTableRowState {
  data: any;
}

// This defines all the different shapes of state that the various components can expose.
export type SpaceComponentState =
  | SpaceTableState
  | SpaceInputFieldState
  | SpaceTableRowState
  | SpaceFunctionState
  | SpaceDetailState
  | SpaceParamsState
  | SpaceUserState
  | null; // | FutureSpaceComponentState …

export type StateTree = Record<string, SpaceComponentState>;

export type SpaceStateInputs = { [bindingPath: string]: any };

export interface Props {
  spaceComponent: SpaceComponentObject;
  spaceApi: SpaceApi;
  hasConfigError?: boolean;
  layoutConstraints?: { width: number; height: number };
}

function areSubTreesEqual(a: SpaceComponentObject, b: SpaceComponentObject): boolean {
  if (
    a.componentTreeNodes.length !== b.componentTreeNodes.length ||
    !a.componentTreeNodes.every((ctn, i) =>
      areComponentsEqual(ctn, b.componentTreeNodes[i])
    )
  ) {
    return false;
  }
  return true;
}

// Compare space components recursively
type K = keyof SpaceComponentObject;
export function areComponentsEqual(a: SpaceComponentObject, b: SpaceComponentObject) {
  for (const key in a) {
    if (key === "container") {
      if (a.container?.slug !== b.container?.slug) {
        return false;
      }
    } else if (key === "componentTreeNodes") {
      if (!areSubTreesEqual(a, b)) {
        return false;
      }
    } else if (a[key as K] !== b[key as K] && !isEqual(a[key as K], b[key as K])) {
      return false;
    }
  }
  return true;
}

const comparisonFunc = (prevProps: Props, nextProps: Props) => {
  const propsToCheck = [
    "spaceApi",
    "spaceComponent",
    "hasConfigError",
    "layoutConstraints"
  ];
  return (propsToCheck as Array<keyof Props>).every(key => {
    let propsEqual = prevProps[key] === nextProps[key];
    if (!propsEqual && key !== "spaceComponent") {
      propsEqual = isEqual(prevProps[key], nextProps[key]);
    } else if (!propsEqual && key === "spaceComponent") {
      propsEqual = areComponentsEqual(
        prevProps.spaceComponent,
        nextProps.spaceComponent
      );
    }
    if (!propsEqual) {
      debug(
        `Component memoization invalidated by key: ${key}`,
        `\n - Last prop.${key}`,
        prevProps[key],
        `\n - Next prop.${key}`,
        nextProps[key]
      );
    }
    return propsEqual;
  });
};

type SpaceComponentHash = {
  [key: string]: React.FunctionComponent<Props>;
};

let memoizedComponents: SpaceComponentHash;
const getMemoizedComponents = (): SpaceComponentHash => {
  if (memoizedComponents) return memoizedComponents;

  memoizedComponents = Array.from(window.__INTERNAL_SPACE_COMPONENT_PACKAGES).reduce(
    (memo, { type, Component }) => ({
      ...memo,
      [type]: React.memo(Component as React.FunctionComponent<Props>, comparisonFunc)
    }),
    {}
  );
  return memoizedComponents;
};

function SpaceComponent(props: Props) {
  debug("Rendering component", props.spaceComponent.slug);
  const { spaceComponent } = props;
  const { shouldDisplayError, state } = useSpaceConfigContext();
  const { editMode, packagesRegistered } = useStableSpaceContext();

  const hasConfigError = editMode && shouldDisplayError(spaceComponent.slug);

  const layout = state.elementLayouts.get(spaceComponent.slug);
  const patchedComponent = React.useMemo(() => {
    if (!editMode) return spaceComponent;
    spaceComponent.layout = layout as ElementLayout;
    return spaceComponent;
  }, [spaceComponent, editMode, layout]);

  if (editMode && !spaceComponent) return null;
  if (!packagesRegistered) return null;

  // https://sentry.io/organizations/internal/issues/2792757161/?environment=secure.internal.io&project=1517743&query=is%3Aunresolved&statsPeriod=7d
  if (spaceComponent === undefined) {
    const configState = JSON.stringify(cloneDeep(state), (key, value) => {
      if (key === "container") return undefined;
      return value;
    });
    reportException(new Error("Expected spaceComponent to be present."), {
      extra: {
        configState
      }
    });
    return null;
  }

  return (
    <ErrorBoundary>
      <ComponentContextContainer component={patchedComponent}>
        <ComponentStateContainer component={patchedComponent}>
          <MemoComponent
            {...props}
            spaceComponent={patchedComponent}
            hasConfigError={hasConfigError}
          />
        </ComponentStateContainer>
      </ComponentContextContainer>
    </ErrorBoundary>
  );
}

export default withErrorBoundary(SpaceComponent);

function MemoComponent(props: Props) {
  const { input, componentNode } = useComponentStateContext();
  const propsWithContextState = {
    ...props,
    input,
    output: componentNode?.output
  };
  const MemoComponent = getMemoizedComponents()[props.spaceComponent.type];

  return (
    <LayoutComponent component={props.spaceComponent}>
      <MemoComponent {...propsWithContextState} />
    </LayoutComponent>
  );
}
