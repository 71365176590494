import React from "react";

import { uniqueId } from "lodash";

import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { Field } from "../ConfigPanel";
import { DebouncedInput } from "../ConfigPanel/styledComponents";
import useNestedStatus from "../useNestedStatus";

export interface NameFieldsProps {
  isInline?: boolean;
  namePlaceholder?: string;
  titleLabel?: string;
  titlePlaceholder?: string;
  labelPlaceholder?: string;
  shouldRenderLabel?: boolean; // if true, will override other logic determining whether or not to render label
  shouldRenderTitle?: boolean;
  shouldRenderPlaceholder?: boolean;
}
export default function NameFields(props: NameFieldsProps) {
  const [id] = React.useState(uniqueId("nameFields"));
  const { state, dispatch } = useComponentConfigContext();
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const { isManaged, isFormItem } = useNestedStatus();

  const pkg = findSpaceComponentPackage(state.draftComponent.type);

  const displayName = pkg?.displayName || "";
  const namePlaceholder = props.namePlaceholder || "Enter a component name";
  const labelPlaceholder =
    props.labelPlaceholder || state.draftComponent.name || "Enter a label";
  const titlePlaceholder = props.titlePlaceholder || `${displayName} Title`;
  const titleLabel = props.titleLabel || `${displayName} Title`;

  const labelField = (
    <Field data-test="labelField">
      <label htmlFor={`${id}Label`}>Label</label>
      <DebouncedInput
        id={`${id}Label`}
        data-test="labelInput"
        value={state.draftComponent.properties.label}
        placeholder={labelPlaceholder}
        onChange={value =>
          dispatch({
            type: "SET_DRAFT_COMPONENT",
            payload: {
              path: "properties.label",
              value
            }
          })
        }
      />
    </Field>
  );

  if (props.isInline) {
    return labelField;
  }
  return (
    <>
      <Field data-test="nameField">
        <label htmlFor={`${id}Name`}>Component Name</label>
        <DebouncedInput
          id={`${id}Name`}
          data-test="nameInput"
          value={state.draftComponent.name}
          placeholder={namePlaceholder}
          disabled={
            isFormItem /* form component input fields are set to their input parameter names. since binding cascader uses component names, disable editing to avoid confusion when binding to a form input */
          }
          title={isFormItem ? "Form inputs cannot be renamed" : ""}
          onChange={value =>
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "name",
                value
              }
            })
          }
        />
      </Field>
      {props.shouldRenderTitle && (
        <Field data-test="titleField">
          <label htmlFor={`${id}Title`}>{titleLabel}</label>
          <DebouncedInput
            id={`${id}Title`}
            data-test="titleInput"
            value={state.draftComponent.properties.title}
            placeholder={titlePlaceholder}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.title",
                  value
                }
              })
            }
          />
        </Field>
      )}
      {(props.shouldRenderLabel || isManaged) && labelField}
      {props.shouldRenderPlaceholder && (
        <Field>
          <label htmlFor={`${id}Placeholder`}>Placeholder Text</label>
          <DebouncedInput
            id={`${id}Placeholder`}
            data-test="placeholderInput"
            placeholder="Enter a placeholder"
            value={state.draftComponent.properties.placeholder}
            onChange={value => {
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.placeholder",
                  value
                }
              });
            }}
          />
        </Field>
      )}
    </>
  );
}
