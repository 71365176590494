import React from "react";

import * as moment from "moment-timezone";
import styled from "styled-components";

import { AUDIT_LOG_ACTIONS, AuditLogNode } from "../../../../types";
import JsonViewer from "../../JsonViewer";

interface AuditLogEntryProps {
  entry: AuditLogNode;
  showActor: boolean;
}

interface AuditLogLineProps {
  entry: AuditLogNode;
  actor: string | null;
  at: string;
}

const Root = styled.div`
  div.jsonViewer {
    margin-top: ${props => props.theme.spacersm};
  }
`;

const FunctionExecutedAuditLogLine = (props: AuditLogLineProps) => {
  const {
    entry: { payload, function: func },
    actor,
    at
  } = props;
  const parameters = payload.parameters;
  const json = (
    <JsonViewer json={parameters} canCollapse={true} defaultExpandedDepth={1} />
  );
  return (
    <React.Fragment>
      <div>
        {actor ? `${actor} used` : "Used"} {func.title} at {at}
      </div>
      {json}
    </React.Fragment>
  );
};

const getAction = (
  entry: AuditLogNode,
  actor: string | null,
  at: string
): React.ReactNode | null => {
  let line;
  switch (entry.action) {
    case AUDIT_LOG_ACTIONS.FUNCTION_EXECUTED:
      line = <FunctionExecutedAuditLogLine entry={entry} at={at} actor={actor} />;
      break;
    default:
      line = (
        <React.Fragment>
          {entry.action} at {at}
        </React.Fragment>
      );
  }
  return line;
};

const AuditLogEntry = (props: AuditLogEntryProps) => {
  const { entry, showActor } = props;
  const at = moment
    .tz(entry.createdAt, "Etc/GMT")
    .tz(moment.tz.guess())
    .format("h:mma on M/D/YYYY z");

  const actor = showActor ? `${entry.user.firstName} ${entry.user.lastName}` : null;
  const log = getAction(entry, actor, at);

  return log ? <Root>{log}</Root> : null;
};

export default AuditLogEntry;
