import { InputParameter } from "../../../../types";
import {
  Action,
  ActionType,
  FormState,
  Parameter
} from "../../../common/FormBuilderModal/reducer";
import { FunctionParameterNode } from "../../../common/FormBuilderModal/types";
import { getFunctionParameter } from "../../../common/FormBuilderModal/utils";
import { findSpaceComponentPackage } from "../../../spaces/SpaceRoot/SpaceContext/StableSpaceContext";
import { cacheBy } from "../../../util/cacheBy";
import {
  AssigneeParameter,
  getAllDisabledParameters,
  getAllEnabledParameters,
  getDefaultAssigneeInputParameter,
  getOnlyRoleEnabledParameters,
  getOnlyUserEnabledParameters,
  isAssigneeParameter
} from "../../App/Queue/useAssigneeInputParameters";

export const INTERNAL_TASK_PARAMETERS = [
  {
    name: "title",
    isAutoConfigured: false
  },
  {
    name: AssigneeParameter.TYPE,
    isAutoConfigured: true
  },
  {
    name: AssigneeParameter.ROLE_ID,
    isAutoConfigured: true
  },
  {
    name: AssigneeParameter.USER_ID,
    isAutoConfigured: true
  }
];

const getAssigneeParameter = (
  node: FunctionParameterNode,
  cachedInputParameters: Record<string, InputParameter | undefined>,
  assigneeTypeIndex: number
) => {
  const ip = cachedInputParameters[node.name];
  const isAssigneeTypeIncluded = cachedInputParameters[AssigneeParameter.TYPE];
  const isAssigneeRoleIncluded = cachedInputParameters[AssigneeParameter.ROLE_ID];
  const isAssigneeUserIncluded = cachedInputParameters[AssigneeParameter.USER_ID];

  const overrides: Partial<Parameter> = {
    included: node.required || !!ip
  };
  // update whether checkbox to add/remove fields should be disabled ("required" fields are disabled)
  switch (node.name) {
    case AssigneeParameter.TYPE:
      if (
        (isAssigneeRoleIncluded && !isAssigneeUserIncluded) ||
        (!isAssigneeRoleIncluded && isAssigneeUserIncluded)
      ) {
        overrides.required = true;
      }
      break;
    case AssigneeParameter.ROLE_ID:
    case AssigneeParameter.USER_ID:
      if (isAssigneeTypeIncluded && isAssigneeRoleIncluded && isAssigneeUserIncluded) {
        overrides.required = true;
      }
      break;

    default:
      throw new Error("Expected valid assignee parameter");
  }
  return getDefaultAssigneeInputParameter(
    node.name as AssigneeParameter,
    assigneeTypeIndex,
    findSpaceComponentPackage,
    overrides
  );
};

export const reducer = (state: FormState, action: Action) => {
  switch (action.type) {
    case ActionType.LOAD_FUNCTION: {
      const { func, allowedParameterTypes } = action.payload;
      const ips = cacheBy(state.initialInputParameters, "name");
      const isInternalParameter = (parameterName: string) => {
        return state.internalFields.map(field => field.name).includes(parameterName);
      };

      const assigneeTypeIndex = func.functionParameters.edges.findIndex(edge => {
        const { node } = edge;
        return node.name === AssigneeParameter.TYPE;
      });

      const copy: Parameter[] = state.parameters.map(parameter => {
        const functionParameterNode = getFunctionParameter(
          parameter.name,
          func.functionParameters
        );
        if (
          functionParameterNode &&
          isAssigneeParameter(parameter.name, isInternalParameter(parameter.name))
        ) {
          return getAssigneeParameter(functionParameterNode, ips, assigneeTypeIndex);
        } else {
          return parameter;
        }
      });

      return {
        ...state,
        allowedParameterTypes,
        func,
        parameters: copy
      };
    }
    case ActionType.UPDATE_PARAMETER: {
      if (
        !(
          "included" in action.payload &&
          isAssigneeParameter(
            action.payload.name,
            state.internalFields.map(field => field.name).includes(action.payload.name)
          )
        )
      ) {
        return state;
      }
      const idx = state.parameters.findIndex(p => p.name === action.payload.name);
      if (idx === -1) throw new Error("illegal state");
      const copy = [...state.parameters];

      // if parameter is assignee parameter, ensure that other assignee parameters are in a valid state
      // so that user does not get into an invalid state
      const typeIndex = copy.findIndex(p => p.name === AssigneeParameter.TYPE);
      const roleIndex = copy.findIndex(p => p.name === AssigneeParameter.ROLE_ID);
      const userIndex = copy.findIndex(p => p.name === AssigneeParameter.USER_ID);
      if (typeIndex === -1 || roleIndex === -1 || userIndex === -1) {
        throw Error("Expected assignee parameters to be included.");
      }
      const roleParam = copy[roleIndex];
      const userParam = copy[userIndex];
      let updatedTypeParam, updatedRoleParam, updatedUserParam;
      switch (action.payload.name) {
        case AssigneeParameter.TYPE: {
          [updatedTypeParam, updatedRoleParam, updatedUserParam] = action.payload
            .included
            ? getAllEnabledParameters(typeIndex, findSpaceComponentPackage)
            : getAllDisabledParameters(typeIndex, findSpaceComponentPackage);
          break;
        }
        case AssigneeParameter.ROLE_ID: {
          if (action.payload.included) {
            [updatedTypeParam, updatedRoleParam, updatedUserParam] = userParam.included
              ? getAllEnabledParameters(typeIndex, findSpaceComponentPackage)
              : getOnlyRoleEnabledParameters(typeIndex, findSpaceComponentPackage);
          } else {
            [updatedTypeParam, updatedRoleParam, updatedUserParam] = userParam.included
              ? getOnlyUserEnabledParameters(typeIndex, findSpaceComponentPackage)
              : getAllDisabledParameters(typeIndex, findSpaceComponentPackage);
          }
          break;
        }
        case AssigneeParameter.USER_ID: {
          if (action.payload.included) {
            [updatedTypeParam, updatedRoleParam, updatedUserParam] = roleParam.included
              ? getAllEnabledParameters(typeIndex, findSpaceComponentPackage)
              : getOnlyUserEnabledParameters(typeIndex, findSpaceComponentPackage);
          } else {
            [updatedTypeParam, updatedRoleParam, updatedUserParam] = roleParam.included
              ? getOnlyRoleEnabledParameters(typeIndex, findSpaceComponentPackage)
              : getAllDisabledParameters(typeIndex, findSpaceComponentPackage);
          }
          break;
        }
        default:
          throw new Error("Expected valid assignee parameter");
      }
      copy.splice(typeIndex, 1, updatedTypeParam);
      copy.splice(roleIndex, 1, updatedRoleParam);
      copy.splice(userIndex, 1, updatedUserParam);
      return { ...state, parameters: copy };
    }
    default:
      return state;
  }
};

export default reducer;
