import React from "react";

import styled from "styled-components";

import { SpaceComponentType } from "../../../../../types";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";

interface SpaceComponentIconProps {
  type: SpaceComponentType;
  className?: string;
  onClick?: (type: SpaceComponentType) => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
  padding: ${props => props.theme.spacersm};
  text-align: center;
  min-width: 88px;

  &:hover {
    color: ${props => props.theme.primaryColor};
    cursor: pointer;
  }

  svg {
    max-width: 62px;
    height: 30px;
    margin-bottom: ${props => props.theme.spacersm};
  }
`;

export default function SpaceComponentIcon({
  type,
  className = "",
  onClick = () => {}
}: SpaceComponentIconProps) {
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const pkg = findSpaceComponentPackage(type);
  const { Icon, displayName } = pkg || {};

  return (
    <Root className={className} onClick={() => onClick(type)} data-test={type}>
      {!!Icon && <Icon />}
      <span>{displayName}</span>
    </Root>
  );
}
