import React from "react";

import styled from "styled-components";

import { Props } from "..";
import { useEvaluaterContext } from "../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../common/CodeSandbox/useCodeSandbox";
import EditableText from "../../../../common/EditableText";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { useTransformationActionContext } from "../../../layout/TransformationContext/TransformationContext";
import { useSpaceConfigContext } from "../../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../../SpaceContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { evaluateTemplate } from "../util";

import { ensureSpaceCalculatedField } from "./Config";

const Root = styled.div`
  max-height: 100%;
  white-space: pre-wrap;
`;

export default function SpaceCalculatedField(props: Props) {
  const calculatedField = ensureSpaceCalculatedField(props.spaceComponent);
  const { input, updateOutput } = useComponentStateContext();
  const { lock, unlock } = useTransformationActionContext();
  const { editMode } = useStableSpaceContext();
  const { dispatch } = useSpaceConfigContext();
  const { evaluate, isJsEvalEnabled, getConsoleError } = useEvaluaterContext();
  const { template } = calculatedField.properties;
  const [evaluated, setEvaluated] = React.useState("");

  React.useEffect(() => {
    if (isJsEvalEnabled) {
      const evalExpression = async () => {
        try {
          const text = await evaluate(template, input);
          setEvaluated(text as string);
        } catch (e) {
          // clear template if it cannot be evaluated (likely due to bindings not fulfilled)
          if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
            setEvaluated("");
          }
          if (editMode) {
            console.warn(getConsoleError(e));
          }
        }
      };
      evalExpression();
    } else {
      try {
        const value = evaluateTemplate(template, input, {
          humanizeInputs: true,
          renderWithPlaceHolders: !editMode
        }) as string;
        setEvaluated(value);
      } catch {}
    }
  }, [
    template,
    input,
    evaluate,
    isJsEvalEnabled,
    updateOutput,
    editMode,
    getConsoleError
  ]);

  React.useEffect(() => {
    updateOutput({ value: evaluated });
  }, [updateOutput, evaluated]);

  const enableClickToEdit = editMode && window.FEATURE_FLAGS.includes("CLICK_TO_EDIT");
  // Remove wrapping backticks
  const text = editMode ? template.replace(/^`|`$/g, "") : evaluated;
  return (
    <Root>
      {props.hasConfigError && <AbsoluteErrorIcon />}
      {enableClickToEdit ? (
        <EditableText
          value={template}
          onChange={value => {
            dispatch({
              type: "MERGE_DRAFT_COMPONENT",
              componentSlug: props.spaceComponent.slug,
              payload: {
                change: {
                  properties: { template: value }
                }
              }
            });
          }}
          onEditStart={() => {
            lock(props.spaceComponent.slug);
          }}
          onEditEnd={() => {
            unlock(props.spaceComponent.slug);
          }}
        />
      ) : (
        text
      )}
    </Root>
  );
}
