import { ClientErrorResult } from "../../../types";

interface CustomErrorOptions {
  friendlyMessage?: string;
  cause?: Error;
}

export class CustomError extends Error {
  public friendlyMessage?: string;
  public cause?: Error;

  constructor(message: string, { friendlyMessage, cause }: CustomErrorOptions = {}) {
    super(message);
    this.name = this.constructor.name;
    this.friendlyMessage = friendlyMessage;
    this.cause = cause;
    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

export class UnknownError extends CustomError {
  constructor(message: string, options?: CustomErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, UnknownError.prototype);
  }
}

export class RemoteFunctionError extends CustomError {
  constructor(message: string, options?: CustomErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, RemoteFunctionError.prototype);
  }
}

export class ClientError extends RemoteFunctionError {
  public details: ClientErrorResult;

  constructor(
    message: string,
    details: ClientErrorResult,
    options?: CustomErrorOptions
  ) {
    super(message, options);
    this.details = details;
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export class PermissionError extends RemoteFunctionError {
  constructor(message: string, options?: CustomErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, PermissionError.prototype);
  }
}
