import React from "react";

import styled from "styled-components";

import { H5, H4 } from "../../../components/common/StyledComponents";

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Plan = styled.div`
  background-color: ${props => props.theme.cardBackgroundColor};
  border: 1px solid ${props => props.theme.borderGrey};
  border-radius: 10px;
  padding: 20px;
  pointer: cursor !important;
  width: 48%;
  position: relative;

  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: red;
    width: 30px;
    height: 30px;
    border: 0.15em solid #323232;
    border-radius: 50%;
    display: grid;
    place-content: center;
    position: absolute;
    right: 20px;
    top: calc(50% - 15px);
  }

  input[type="radio"]::before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${props => props.theme.primaryColor};
  }

  input[type="radio"]:checked {
    border: 0.15em solid ${props => props.theme.primaryColor};
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
`;

interface PriceData {
  monthly?: number;
  annual?: number;
}

export default function PlanPicker({
  onClick,
  priceData,
  paymentTerm
}: {
  onClick: any;
  priceData: PriceData;
  paymentTerm: string | undefined;
}) {
  const handleClick = (e: React.MouseEvent) => {
    const { currentTarget } = e;
    if (!currentTarget) {
      return;
    }

    const termVal = currentTarget.querySelector("input")?.value;

    onClick(termVal);
  };

  return (
    <div>
      <H4>Choose a plan</H4>
      <PickerContainer>
        <Plan onClick={handleClick}>
          <H5>Annual</H5>
          <p>${Math.floor((priceData["annual"] || 0) / 12)} per user/month</p>
          <small>Billed annually</small>
          <input
            type="radio"
            value="annual"
            name="term"
            readOnly={true}
            checked={paymentTerm === "annual"}
          />
        </Plan>

        <Plan onClick={handleClick}>
          <H5>Monthly</H5>
          <p>${priceData["monthly"]} per user/month</p>
          <small>Billed monthly</small>
          <input
            type="radio"
            value="monthly"
            name="term"
            readOnly={true}
            checked={paymentTerm === "monthly"}
          />
        </Plan>
      </PickerContainer>
    </div>
  );
}
