import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { RelayNode } from "../../../../../../../types";

export interface StateNode extends RelayNode {
  name: string;
  color: string;
  isArchive: boolean;
}
export interface QueueNode extends RelayNode {
  __typename: "QueueNode";
  name: string;
  description: string;
  slug: string;
  states?: StateNode[];
  color: string;
}

interface Data {
  queueBySlug: QueueNode;
}

interface Variables {
  slug: string;
}

const QUERY = gql`
  query QueueBySlug($slug: String!) {
    queueBySlug(slug: $slug) {
      id
      name
      description
      slug
      states {
        id
        name
        color
        isArchive
      }
    }
  }
`;

export type Result = QueryResult<Data, Variables>;

export const useQueueBySlug = (options?: QueryHookOptions<Data, Variables>): Result =>
  useQuery<Data, Variables>(QUERY, options);
