import { v4 as uuid } from "uuid";

import {
  EnvironmentDataSourceCredentialNode,
  EnvironmentNode
} from "../../../../types";

export interface ManageCredentialsState {
  environmentCredentials: EnvironmentDataSourceCredentialNode[];
  modalOpen: boolean;
  popoverOpen: boolean;
  openCredentialPanelId: string;
}

const makeNewCredentialsObject = (environment: EnvironmentNode) => {
  return {
    id: `temp-${uuid()}`,
    credentials: {},
    environment
  } as EnvironmentDataSourceCredentialNode;
};

export type ManageCredentialsAction =
  | {
      type: "INITIALIZE_CREDENTIALS";
      payload: { credentials: EnvironmentDataSourceCredentialNode[] };
    }
  | { type: "REMOVE_CREDENTIAL"; payload: { id: string } }
  | { type: "ADD_CREDENTIAL"; payload: { environment: EnvironmentNode } }
  | { type: "SET_POPOVER"; payload: { popoverOpen: boolean } }
  | { type: "SET_MODAL"; payload: { isOpen: boolean } };

export const initialState: ManageCredentialsState = {
  environmentCredentials: [],
  modalOpen: false,
  popoverOpen: false,
  openCredentialPanelId: ""
};

function reducer(
  state: ManageCredentialsState,
  action: ManageCredentialsAction
): ManageCredentialsState {
  switch (action.type) {
    case "INITIALIZE_CREDENTIALS": {
      const { credentials } = action.payload;
      const openCredentialPanelId = credentials.find(c => c.environment.isDefault)!.id;
      return {
        ...state,
        environmentCredentials: credentials,
        openCredentialPanelId
      };
    }
    case "REMOVE_CREDENTIAL": {
      const { id } = action.payload;
      return {
        ...state,
        environmentCredentials: state.environmentCredentials.filter(c => c.id !== id)
      };
    }
    case "SET_POPOVER": {
      return { ...state, popoverOpen: action.payload.popoverOpen };
    }
    case "ADD_CREDENTIAL": {
      const { environment } = action.payload;
      const credentials = makeNewCredentialsObject(environment);
      return {
        ...state,
        popoverOpen: false,
        openCredentialPanelId: credentials.id,
        environmentCredentials: [...state.environmentCredentials, credentials]
      };
    }
    case "SET_MODAL": {
      const { isOpen: modalOpen } = action.payload;
      return {
        ...state,
        modalOpen,
        popoverOpen: state.popoverOpen && !modalOpen
      };
    }
    default:
      return state;
  }
}

export default reducer;
