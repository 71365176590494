import styled from "styled-components";

import { colors } from "../../../../cssConstants";

export const Label = styled.label`
  margin-top: 24px;
  margin-bottom: 8px;
  display: block;
  color: ${colors.surfaceSecondary};
`;

export const Content = styled.div`
  // This prevents the modal from hiding the box-shadow for the dropdown
  // and keeps the padding for the content the same
  padding: 0 0 1px 1px;
`;
