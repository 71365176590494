import React from "react";

import SpreadsheetIcon from "../../assets/spreadsheet_icon.png";

const icons: Record<string, string> = {
  google_sheets: SpreadsheetIcon
};

interface Props {
  name: string;
  defaultSrc?: string;
}

const IntegrationIcon = ({ name, defaultSrc }: Props) => {
  const icon = icons[name] || defaultSrc;
  return icon ? <img src={icon} alt="" /> : null;
};

export default IntegrationIcon;
