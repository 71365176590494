import Icon from "../../../../../assets/spaceicons/function.svg";
import {
  SpaceComponentHostType,
  SpaceComponentPackage,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import {
  errorSelector,
  getOutputBinding,
  getSchema
} from "../common/FunctionBackedPopover";
import reducer, {
  ensureSubmittableComponent,
  makeInitialState,
  SubmittableComponent,
  SubmittableComponentConfigState,
  SubmittableComponentProps
} from "../common/FunctionBackedPopover/reducer/reducer";

import Config from "./Config";
import SpaceFunctionHeadless from "./SpaceFunctionHeadless";

const pkg: SpaceComponentPackage<
  SubmittableComponentProps,
  SubmittableComponent,
  SubmittableComponentConfigState
> = {
  Icon,
  Component: SpaceFunctionHeadless,
  ConfigurationComponent: Config,
  allowAncestorBinding: false,
  allowSelfBinding: false,
  allowedHosts: new Set<SpaceComponentHostType>(),
  displayName: "Function",
  componentConfigReducer: reducer,
  ensureComponent: ensureSubmittableComponent,
  errorSelector,
  getInitialDraftState: makeInitialState,
  getOutputBinding,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: "10%",
    height: "32px",
    snapToGrid: false
  }),
  isHeadless: false,
  type: "FUNCTION_HEADLESS",
  tags: [SpaceComponentTags.Base],
  isContainer: false
};
export default pkg;
