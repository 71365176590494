import * as Sentry from "@sentry/browser";

export const setTrackingUserId = (userId: string | null) => {
  if (process.env.NODE_ENV !== "production" || process.env.INTERNALIO_ONPREM === "1") {
    return;
  }

  if (userId === null) {
    Sentry.configureScope(scope => scope.setUser(null));
    return;
  }

  try {
    const id = atob(userId).split(":")[1];
    Sentry.configureScope(scope => scope.setUser({ id }));
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const deprecate = (msg: string) => {
  if (process.env.NODE_ENV !== "development") return;
  console.warn(`Deprecated: ${msg}`);
};
