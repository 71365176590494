import { Select as AntSelect } from "antd";
import styled from "styled-components";

import { Size } from "../../../../cssConstants";

export const Table = styled.table`
  width: 100%;
  max-width: ${Size.md};
  border-collapse: separate;
  border-spacing: 12px;

  tr > th:first-child,
  tr > td:first-child {
    width: 250px;
  }
`;

export const Select = styled(AntSelect)`
  width: 100%;
`;
