import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";

import { fromGlobalId } from "../components/util/graphql";

export const CURRENT_ENVIRONMENT = gql`
  query GetCurrentEnvironment {
    currentEnvironment {
      __typename
      id
    }
  }
`;

interface CurrentEnvironmentData {
  currentEnvironment?: {
    id: string;
  };
}

export const setClientEnvironmentId = (
  client: ApolloClient<object>,
  currentEnvironmentId: string | undefined
): void => {
  const uuid = currentEnvironmentId ? fromGlobalId(currentEnvironmentId)[1] : null;
  client.writeQuery({
    query: CURRENT_ENVIRONMENT,
    data: {
      currentEnvironment: {
        id: uuid,
        __typename: "EnvironmentNode"
      }
    }
  });
};

export const createEnvironmentLink = (cache: InMemoryCache) => {
  return setContext((_, { headers }: Record<string, any>) => {
    try {
      const data = cache.readQuery<CurrentEnvironmentData>({
        query: CURRENT_ENVIRONMENT
      });
      headers["X-EnvironmentId"] = data?.currentEnvironment?.id;
    } catch (e) {
      // ignore error
    }
    return { headers };
  });
};
