import React from "react";

import { isEqual } from "lodash";

import { DataValue } from "../../../../types";
import {
  InputParameter,
  ParameterType
} from "../SpaceComponent/common/useFuncParams/types";

const ParamGenerationContext = React.createContext({
  stabilizeGeneratedParams: (
    _key: string,
    _inputParameters: InputParameter[],
    _funcParamValues: Record<string, DataValue>
  ) => ({} as Record<string, DataValue>)
});

type ParamGeneration = {
  nonGeneratedParams: Record<string, DataValue>;
  generatedParams: Record<string, DataValue>;
};

const GENERATED_PARAM_TYPES = [
  ParameterType.DATE_TODAY,
  ParameterType.DATETIME_NOW,
  ParameterType.TIME_NOW,
  ParameterType.UUID
];
const stabilizeGeneratedParams = (
  paramHistory: Map<string, ParamGeneration>,
  key: string,
  inputParameters: InputParameter[],
  funcParamValues: Record<string, DataValue>
) => {
  const generatedParamNames = new Set(
    inputParameters
      .filter(ip => GENERATED_PARAM_TYPES.includes(ip.type))
      .map(ip => ip.name)
  );
  const nonGeneratedParamNames = new Set(
    inputParameters
      .filter(ip => !GENERATED_PARAM_TYPES.includes(ip.type))
      .map(ip => ip.name)
  );

  const nonGeneratedParams = Object.fromEntries(
    Object.entries(funcParamValues).filter(([k]) => nonGeneratedParamNames.has(k))
  );
  const generatedParams = Object.fromEntries(
    Object.entries(funcParamValues).filter(([k]) => generatedParamNames.has(k))
  );

  const lastParamGeneration = paramHistory.get(key);
  if (
    !lastParamGeneration ||
    !isEqual(nonGeneratedParams, lastParamGeneration.nonGeneratedParams)
  ) {
    paramHistory.set(key, { nonGeneratedParams, generatedParams });
    return generatedParams;
  } else {
    return lastParamGeneration.generatedParams;
  }
};

export function ParamGenerationContextContainer({
  children
}: {
  children: React.ReactNode;
}) {
  const paramHistoryRef = React.useRef<Map<string, ParamGeneration> | null>(new Map());
  React.useEffect(() => {
    return () => {
      paramHistoryRef.current = null;
    };
  }, []);
  const value = React.useMemo(
    () => ({
      stabilizeGeneratedParams: (
        key: string,
        inputParams: InputParameter[],
        funcParamValues: Record<string, DataValue>
      ) =>
        stabilizeGeneratedParams(
          paramHistoryRef.current!,
          key,
          inputParams,
          funcParamValues
        )
    }),
    []
  );
  return (
    <ParamGenerationContext.Provider value={value}>
      {children}
    </ParamGenerationContext.Provider>
  );
}

export const useParamGenerationContext = () => React.useContext(ParamGenerationContext);
