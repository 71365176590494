import React from "react";

import ColumnOrder from "../../../common/ColumnOrder";
import { ConfigSection } from "../../../common/ConfigPanel";
import FilterListManagerSection from "../../../common/FilterListManager";
import ParametersConfigSection from "../../../common/ParametersManager";
import VisibilityRulesManagerSection from "../../../common/VisibilityRulesManager";
import { ChartConfigState } from "../../types";

interface Props {
  state: ChartConfigState;
}

export default function AdvancedConfigSections({ state }: Props) {
  const { draftComponent, filtersOptions, sortByOptions } = state;
  if (!draftComponent.properties.columns) return null;

  return (
    <>
      <ParametersConfigSection title="Parameters" />
      <FilterListManagerSection title="Filters" filtersOptions={filtersOptions} />
      <ConfigSection title="Sorting">
        <ColumnOrder sortByOptions={sortByOptions} />
      </ConfigSection>
      <VisibilityRulesManagerSection />
    </>
  );
}
