import { useReducer, useEffect, useCallback, useMemo } from "react";

import { cloneDeep } from "lodash";

import {
  MaskedSpaceNode,
  SpaceComponentType,
  SpaceComponentObject,
  StatusCode,
  ConfigValidationError
} from "../../../../../types";
import Message from "../../../../common/Message";
import useSpacesManager from "../../../hooks/useSpacesManager/useSpacesManager";
import { useStableSpaceContext, maskSpace } from "../../../SpaceRoot/SpaceContext";
import { OPAQUE_EDIT_MODE_KEY } from "../../../SpaceRoot/SpaceContext/StableSpaceContext";
import useClientProvidedComponents from "../../../SpaceRoot/SpaceContext/useClientProvidedComponents";
import useSpace, { EMPTY_SPACE } from "../../../SpaceRoot/SpaceContext/useSpace";

import reducer, {
  INITIAL_STATE,
  SpaceConfigState,
  SpaceConfigDispatch,
  ensureSlug,
  getSpaceErrors,
  selectAllSlugs,
  selectSpaceComponentTree,
  getFirstSpaceError
} from "./reducer";
import useConfigErrors from "./useConfigErrors/useConfigErrors";
import useConfigMutation from "./useConfigMutation";
import {
  MutationOptions,
  UseConfigMutationData
} from "./useConfigMutation/useConfigMutation";
import useDestroyConfigMutation from "./useDestroyConfigMutation";
import { DestroyConfigMutationData } from "./useDestroyConfigMutation/useDestroyConfigMutation";

export enum MutationType {
  SAVE = "save",
  DESTROY = "destroy"
}

export interface Result {
  [OPAQUE_EDIT_MODE_KEY]: boolean;
  dispatch: SpaceConfigDispatch;
  state: SpaceConfigState;
  loading: boolean;
  space: MaskedSpaceNode;
  mutationLoading: MutationType | boolean;
  shouldDisplayError: (p: string) => boolean;
  predictSlug: (componentType: SpaceComponentType) => string;
  destroy: () => void;
  save: (environmentsToPublish?: string[]) => void;
  componentTree: SpaceComponentObject[];
  queryStatus: StatusCode;
  componentsWithErrors: Set<string>;
  configErrors: Record<string, ConfigValidationError[]>;
}

export default function useSpaceConfig(
  onDestroy: (result: DestroyConfigMutationData) => void,
  onNewSpaceCreated: (result: UseConfigMutationData) => void,
  spaceSlug?: string
): Result {
  const { findSpaceComponentPackage, getSpaceComponentPackages } =
    useStableSpaceContext();
  const packageMap = new Map(getSpaceComponentPackages().map(p => [p.type, p]));
  const [state, dispatch] = useReducer(
    reducer,
    cloneDeep({ ...INITIAL_STATE, packages: packageMap })
  );
  const { loading, space, componentTree, queryStatus } = useSpace(spaceSlug, {
    editMode: true
  });
  const { favorite } = useSpacesManager();

  const options: MutationOptions = space.id
    ? { id: space.id }
    : {
        onNewSpaceCreated: result => {
          onNewSpaceCreated(result);
          favorite(result.spaceUpdate.space.id, true);
        }
      };

  const { save: saveMutation, loading: saveLoading } = useConfigMutation(
    state,
    dispatch,
    options
  );

  const { destroy: destroyMutation, loading: destroyLoading } =
    useDestroyConfigMutation(state, dispatch, {
      id: space.id,
      onCompleted: onDestroy
    });

  const clientProvidedComponents = useClientProvidedComponents();

  useEffect(() => {
    if (loading) return;
    dispatch(
      !!space.id
        ? {
            type: "INIT_EXISTING_SPACE",
            payload: {
              name: space.name,
              components: componentTree
            }
          }
        : { type: "INIT_NEW_SPACE", payload: { clientProvidedComponents } }
    );
  }, [space, componentTree, loading, clientProvidedComponents]);

  const componentTreeNodes = useMemo(() => {
    return selectSpaceComponentTree(state.components, state.tree);
  }, [state.components, state.tree]);

  const configErrors = useConfigErrors(state.components, componentTreeNodes);

  const slugsWithErrors = useMemo(() => {
    return new Set([
      ...Object.entries(configErrors)
        .filter(([_, v]) => v.length)
        .map(([k]) => k),
      ...Object.keys(state.apiErrors)
    ]);
  }, [configErrors, state.apiErrors]);

  const mutationLoading =
    (saveLoading && MutationType.SAVE) || (destroyLoading && MutationType.DESTROY);

  const save = useCallback(
    (environmentsToPublish: string[] | undefined = []) => {
      if (mutationLoading) return;

      dispatch({
        type: "SUBMIT"
      });
      const errorsPreventingSubmit = getSpaceErrors(
        state,
        findSpaceComponentPackage,
        Array.from(slugsWithErrors)
      );
      if (errorsPreventingSubmit.NAME.length > 0) {
        dispatch({ type: "SET_NAME_ERROR" });
      }
      const message = getFirstSpaceError(errorsPreventingSubmit);
      if (message) {
        Message.error(message);
        return;
      }
      return saveMutation(environmentsToPublish);
    },
    [slugsWithErrors, state, mutationLoading, findSpaceComponentPackage, saveMutation]
  );

  const destroy = useCallback(() => {
    if (mutationLoading) return;
    return destroyMutation();
  }, [mutationLoading, destroyMutation]);

  const shouldDisplayError = useCallback(
    (slug: string) => {
      if (!state.submitted) return false;
      const node = findComponentTreeNode(componentTreeNodes, slug);
      if (!node) return false;
      if (slugsWithErrors.has(node.slug)) return true;
      let match;
      for (const slugWithError of slugsWithErrors) {
        match = findComponentTreeNode(node.componentTreeNodes, slugWithError);
        if (match) return true;
      }
      return false;
    },
    [slugsWithErrors, componentTreeNodes, state.submitted]
  );

  const predictSlug = useCallback(
    componentType => {
      return ensureSlug(componentType, selectAllSlugs(state));
    },
    [state]
  );

  const memoSpace = useMemo(() => {
    return space
      ? {
          ...maskSpace(space)
        }
      : maskSpace(EMPTY_SPACE);
  }, [space]);

  return useMemo(
    () => ({
      [OPAQUE_EDIT_MODE_KEY]: true,
      space: memoSpace,
      queryStatus,
      componentsWithErrors: slugsWithErrors,
      dispatch,
      componentTree: componentTreeNodes,
      state,
      save,
      destroy,
      loading: !state.isStateLoaded,
      mutationLoading,
      shouldDisplayError,
      predictSlug,
      configErrors
    }),
    [
      memoSpace,
      queryStatus,
      componentTreeNodes,
      state,
      save,
      destroy,
      mutationLoading,
      shouldDisplayError,
      predictSlug,
      slugsWithErrors,
      configErrors
    ]
  );
}

function findComponentTreeNode(
  nodes: SpaceComponentObject[],
  slug: string
): SpaceComponentObject | undefined {
  let match = nodes.find(c => c.slug === slug);
  if (match) {
    return match;
  }

  for (let i = 0; i < nodes.length; i++) {
    match = findComponentTreeNode(nodes[i].componentTreeNodes, slug);
    if (match) {
      return match;
    }
  }
}
