import { Checkbox as AntCheckbox } from "antd";
import styled from "styled-components";

import { StateSelect as BaseStateSelect } from "../../../../App/Queue/StateSelect/StateSelect";

export const Container = styled.div``;

export const Title = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #2a282d;
`;

export const OptionContainer = styled.div`
  margin-bottom: 8px;
`;

export const OptionContent = styled.div`
  width: 100%;
  margin-right: ${props => props.theme.spacersm};
`;

export const Checkbox = styled(AntCheckbox)`
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;

  .ant-checkbox + span {
    padding: 0 0 0 5px;
  }
`;

export const StateSelect = styled(BaseStateSelect)<{ hidden: boolean }>`
  width: 100%;
  visibility: ${props => (props.hidden ? "hidden" : "initial")};
`;
