import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

interface Data {
  saveAutomationStatus: { automation: { isActive: boolean } };
}

interface Variables {
  id: string;
  isActive: boolean;
}

const SAVE_AUTOMATION_STATUS_MUTATION = gql`
  mutation SaveAutomationStatus($id: ID!, $isActive: Boolean!) {
    saveAutomationStatus(id: $id, isActive: $isActive) {
      automation {
        isActive
      }
    }
  }
`;

export const useSaveAutomationStatus = (
  options?: MutationHookOptions<Data, Variables>
) => {
  return useMutation<Data, Variables>(SAVE_AUTOMATION_STATUS_MUTATION, options);
};
