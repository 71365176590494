import React from "react";

import styled from "styled-components";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.header`
  padding: ${props => props.theme.spacersm} ${props => props.theme.spacermd};
  font-size: ${props => props.theme.h4FontSize};
  border-bottom: 1px solid ${props => props.theme.menuBorderColor};
  flex: 0 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  flex: 1;
`;

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
}
export default function Menu({ children, title }: Props) {
  return (
    <MenuContainer>
      <Title>{title}</Title>
      <Body>{children}</Body>
    </MenuContainer>
  );
}
