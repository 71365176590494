import React from "react";

import { Form, Skeleton } from "antd";
import * as AntForm from "antd/lib/form/Form";

import {
  ComponentInputParameter,
  FunctionNode,
  InputParameter,
  ParameterType,
  SpaceComponentObject
} from "../../../../../../types";
import { FieldType } from "../../../../../common/AttributeInput/constants";
import Message from "../../../../../common/Message";
import {
  createSpaceFunction,
  FunctionExecutionStatus
} from "../../../../../spaces/FunctionExecutor/FunctionExecutor";
import { ElementLayout } from "../../../../../spaces/layout/util";
import SpaceApi from "../../../../../spaces/SpaceApi";
import { makeComponent } from "../../../../../spaces/SpaceConfig";
import { BlankValueType } from "../../../../../spaces/SpaceRoot/constants";
import useFuncParams from "../../../../../spaces/SpaceRoot/SpaceComponent/common/useFuncParams";
import { InputParameter as SpaceInputParameter } from "../../../../../spaces/SpaceRoot/SpaceComponent/common/useFuncParams/types";
import useSpaceFunction, {
  FunctionResult
} from "../../../../../spaces/SpaceRoot/SpaceComponent/common/useSpaceFunction";
import { useComponentStateContext } from "../../../../../spaces/SpaceRoot/SpaceComponent/contexts/ComponentStateContext";
import FunctionModalForm from "../../../../../spaces/SpaceRoot/SpaceComponent/SpaceFunctionModalForm/FunctionModalForm";
import { tryError } from "../../../../../util";
import { assertNever } from "../../../../../util/assertNever";
import { getComponentValueBinding, getComponentErrorBinding } from "../../utils";

interface Props {
  inputParameters: SpaceInputParameter[];
  form: AntForm.WrappedFormUtils;
  contentLoading?: boolean;
  onCancel: () => void;
  onCompleted: (data: FunctionResult, metadata: unknown) => void;
}

export const SpaceForm = Form.create<Props>({
  name: "task-form"
})(function ({ form, inputParameters, contentLoading, onCancel, onCompleted }: Props) {
  const { input, componentNode } = useComponentStateContext();

  const component = componentNode?.component || createEmptyComponent();
  const spaceFunc = createSpaceFunction(component);

  const {
    prefillParams,
    funcParams,
    hasRequiredValues,
    hasValidValues,
    hasRequiredComponentValues,
    getCurrentFuncParams
  } = useFuncParams(spaceFunc, inputParameters, input, form, component);
  const { executeFunction, status } = useSpaceFunction(
    spaceFunc,
    inputParameters,
    undefined,
    onCompleted,
    err => {
      const e = tryError(err);
      console.error(err);
      Message.error(e.message);
    }
  );

  const onSubmit = React.useCallback(() => {
    executeFunction(getCurrentFuncParams());
  }, [executeFunction, getCurrentFuncParams]);

  return (
    <FunctionModalForm
      form={form}
      functionExecutionStatus={status}
      spaceComponent={component}
      inputParameters={inputParameters}
      onSubmit={onSubmit}
      onClose={onCancel}
      func={spaceFunc}
      emptyContent={<Skeleton active />}
      contentLoading={contentLoading}
      loading={status === FunctionExecutionStatus.IN_PROGRESS}
      funcParams={funcParams}
      isValid={hasRequiredComponentValues}
      disabled={!hasRequiredValues || !hasValidValues}
      spaceApi={new SpaceApi()}
      prefillParams={prefillParams}
    />
  );
});

/* assumes all taskInputParameters shown, required, and custom fields */
export const toSpaceInputParameter = (
  ip: InputParameter,
  index: number
): SpaceInputParameter => {
  switch (ip.type) {
    case ParameterType.COMPONENT:
      // action parameters are prefixed with "{action_index}__"
      // if an action_index prefix is found, remove it from the label.
      const labelParts = ip.name.split("__");
      const label =
        labelParts.length && !isNaN(parseInt(labelParts[0]))
          ? labelParts.slice(1).join("__")
          : ip.name;

      return {
        name: ip.name,
        label,
        type: ip.type,
        component_slug: `component${index}`,
        component_type: ip.componentType,
        hidden: false,
        required: true,
        blank_value_type: BlankValueType.NULL_VALUE,
        binding: getComponentValueBinding(index),
        error: {
          binding: getComponentErrorBinding(index)
        }
      };
    case ParameterType.BINDING:
      return {
        name: ip.name,
        type: ip.type,
        blank_value_type: BlankValueType.NULL_VALUE,
        field_type: FieldType.TEXT_INPUT,
        hidden: true,
        required: true,
        binding: ip.binding
      };
    case ParameterType.STATIC:
      return {
        name: ip.name,
        type: ip.type,
        blank_value_type: BlankValueType.NULL_VALUE,
        field_type: FieldType.TEXT_INPUT,
        hidden: true,
        required:
          typeof ip.required === "boolean"
            ? ip.required
            : ip.value ===
              null /* if null value was saved, input param must allow blank values (required=false) */
            ? false
            : true,
        value: ip.value
      };
    case ParameterType.TEMPLATE:
      throw new Error("template parameters are not supported in SpaceInputParameter");
    case ParameterType.UUID:
    case ParameterType.DATETIME_NOW:
    case ParameterType.DATE_TODAY:
    case ParameterType.TIME_NOW:
      return {
        name: ip.name,
        type: ip.type,
        hidden: true,
        required: true,
        blank_value_type: BlankValueType.NULL_VALUE
      };
    default:
      return assertNever(ip);
  }
};

const isComponentInputParameter = (ip: InputParameter): ip is ComponentInputParameter =>
  ip.type === ParameterType.COMPONENT;

export const createComponent = (
  title: string,
  func: FunctionNode,
  inputParameters: InputParameter[]
): SpaceComponentObject => {
  const component = makeComponent(new Set(), {
    id: "client_generated",
    type: "FUNCTION_MODAL_FORM",
    name: "client_generated",
    slug: "modalForm1",
    container: null,
    layout: new ElementLayout({
      width: "auto",
      height: "auto",
      snapToGrid: false
    }),
    componentTreeNodes: [],
    functions: { edges: [{ node: func }] },
    properties: {
      title,
      input_parameters: inputParameters.reduce<[SpaceInputParameter[], number]>(
        (acc, ip) => {
          // templates are not supported in space forms
          if (ip.type === ParameterType.TEMPLATE) {
            return acc;
          }
          acc[0].push(toSpaceInputParameter(ip, acc[1]));
          if (ip.type === ParameterType.COMPONENT) {
            acc[1]++;
          }
          return acc;
        },
        [[], 0]
      )[0],
      instructions: inputParameters.every(ip => ip.type !== ParameterType.COMPONENT)
        ? "Are you sure?"
        : undefined
    }
  });
  component.componentTreeNodes = inputParameters
    .filter(isComponentInputParameter)
    .map((ip, i) => ({
      type: ip.componentType!,
      slug: "component" + i,
      name: ip.name,
      layout: new ElementLayout({
        width: "100%",
        height: "auto",
        snapToGrid: false
      }),
      properties: {
        ...ip.componentProperties
      },
      container: component, // container needed for proper handling of nested layout components
      componentTreeNodes: [],
      id: "client_generated",
      functions: { edges: [] }
    }));

  return component;
};

const createEmptyComponent = (): SpaceComponentObject => ({
  functions: { edges: [] },
  properties: {},
  componentTreeNodes: [],
  container: null,
  id: "",
  name: "",
  slug: "",
  type: "FUNCTION_MODAL_FORM"
});
