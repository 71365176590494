import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Tooltip } from "antd";
import styled from "styled-components";

import HeartFilled from "../../../../assets/icons/heart-filled.svg";
import HeartOutline from "../../../../assets/icons/heart-outline.svg";
import { SpaceNode } from "../../../../types";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../../common/hooks/useAuthUser";
import usePaths from "../../../common/hooks/usePaths";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import {
  H6,
  P,
  Hr,
  TinyLightText,
  SecondaryButtonList,
  IconButton
} from "../../../common/StyledComponents";
import withErrorBoundary from "../../../hoc/withErrorBoundary";
import copyToClipboard from "../../../util/copyToClipboard";
import { useSpacesContext } from "../../hooks/useSpacesManager/useSpacesManager";
import { emptyFilter } from "../SpaceFilters";

import ConfigureSpaceModal from "./ConfigureSpaceModal";
import DeleteSpaceModal from "./DeleteSpaceModal";
import { CloneSpaceData, CloneSpaceVariables, CLONE_SPACE } from "./queries";
import SpaceVisibility from "./SpaceVisibility";

const UpdatedAtNotice = styled(TinyLightText)`
  display: block;
  margin-bottom: 2em;
`;

const StyledButton = styled(ButtonNew)`
  margin: 6px 0;
`;

const StyledHeartFilled = styled(HeartFilled)`
  cursor: pointer;
  margin: 0 11px;
`;

const StyledHeartOutline = styled(HeartOutline)`
  cursor: pointer;
  margin: 0 11px;
`;

export default function SpaceDetail({
  space,
  onFavorite,
  onUnfavorite
}: {
  space: SpaceNode;
  onFavorite: (spaceId: string, refetch?: boolean) => void;
  onUnfavorite: (spaceId: string) => void;
}) {
  return (
    <div>
      <SpaceInfo space={space} onFavorite={onFavorite} onUnfavorite={onUnfavorite} />
      <SpaceVisibility slug={space.slug} />
    </div>
  );
}

const TOOLTIP_DISPLAY_LENGTH = 1500;

const SpaceInfo = withErrorBoundary(
  ({
    space,
    onFavorite,
    onUnfavorite
  }: {
    space: SpaceNode;
    onFavorite: (spaceId: string, refetch?: boolean) => void;
    onUnfavorite: (spaceId: string) => void;
  }) => {
    const [openModal, setOpenModal] = React.useState<
      null | "CONFIGURE_SPACE" | "DELETE_SPACE"
    >(null);
    const { updateSpaceListQuery } = useSpacesContext();
    const [favoriteTooltip, setFavoriteTooltip] = React.useState<null | string>(null);
    const [showCopiedTooltip, setShowCopiedTooltip] = React.useState(false);
    const { isAdmin, isSpaceAdmin } = useAuthUser();
    const { getSpace, getEditSpace } = usePaths();
    const viewSpaceUrl = getSpace(space.slug);
    const canManageSpaces = isAdmin || isSpaceAdmin;
    const updateSearchParams = useSearchParamsUpdater();
    const [cloneSpace] = useMutation<CloneSpaceData, CloneSpaceVariables>(CLONE_SPACE, {
      onCompleted: data => {
        const { id, slug } = data.spaceClone.space;
        onFavorite(id, true);
        updateSearchParams({
          ...emptyFilter,
          selectedSpace: slug,
          onlyUnpublished: true
        });
        updateSpaceListQuery("REFETCH");
      }
    });

    return (
      <div>
        <H6 data-test="metaDataSpaceName">{space.name}</H6>
        {space.latestVersion?.createdAt && (
          <UpdatedAtNotice>
            Last updated {new Date(space.latestVersion.createdAt).toLocaleDateString()}
          </UpdatedAtNotice>
        )}
        {space.description && <P data-test="metaDataSpaceDesc">{space.description}</P>}
        <SecondaryButtonList>
          {canManageSpaces && (
            <IconButton
              title="Update space settings"
              type="link"
              shape="circle"
              icon="setting"
              onClick={() => {
                setOpenModal("CONFIGURE_SPACE");
              }}
            />
          )}
          <Tooltip trigger="click" title={favoriteTooltip} visible={!!favoriteTooltip}>
            {space.isFavorite ? (
              <StyledHeartFilled
                title="Remove from favorites"
                onClick={() => {
                  onUnfavorite(space.id);
                  setFavoriteTooltip("Removed favorite");
                  setTimeout(() => {
                    setFavoriteTooltip(null);
                  }, TOOLTIP_DISPLAY_LENGTH);
                }}
              />
            ) : (
              <StyledHeartOutline
                title="Add space to favorites"
                onClick={() => {
                  onFavorite(space.id);
                  setFavoriteTooltip("Added favorite");
                  setTimeout(() => {
                    setFavoriteTooltip(null);
                  }, TOOLTIP_DISPLAY_LENGTH);
                }}
              />
            )}
          </Tooltip>
          <Tooltip trigger="click" title="Link copied" visible={showCopiedTooltip}>
            <IconButton
              title="Copy link to space"
              type="link"
              shape="circle"
              icon="link"
              onClick={() => {
                copyToClipboard(window.location.origin + viewSpaceUrl);
                setShowCopiedTooltip(true);
                setTimeout(() => {
                  setShowCopiedTooltip(false);
                }, TOOLTIP_DISPLAY_LENGTH);
              }}
            />
          </Tooltip>
          {canManageSpaces && (
            <>
              <IconButton
                title="Duplicate space"
                type="link"
                shape="circle"
                icon="copy"
                onClick={() => {
                  cloneSpace({
                    variables: { spaceId: space.id }
                  });
                }}
              />
              <IconButton
                title="Delete space"
                type="link"
                shape="circle"
                icon="delete"
                onClick={() => {
                  setOpenModal("DELETE_SPACE");
                }}
              />
            </>
          )}
        </SecondaryButtonList>
        <StyledButton size="sm" type="primary" block to={viewSpaceUrl}>
          Open space
        </StyledButton>
        {canManageSpaces && (
          <StyledButton size="sm" type="secondary" block to={getEditSpace(space.slug)}>
            Edit space
          </StyledButton>
        )}
        <Hr />
        {openModal === "CONFIGURE_SPACE" && (
          <ConfigureSpaceModal space={space} onCancel={() => setOpenModal(null)} />
        )}
        {openModal === "DELETE_SPACE" && (
          <DeleteSpaceModal space={space} onCancel={() => setOpenModal(null)} />
        )}
      </div>
    );
  }
);
