import { cloneDeep } from "lodash";

import { SpaceComponentObject } from "../../../../../../../types";
import {
  SpaceConfigAction,
  BaseConfigAction,
  ComponentConfigState,
  BaseComponentConfigState
} from "../../../../../types";

export interface FilterListManagerState {
  filterValidityTrackers: boolean[];
}

export interface SetFilterValidityTrackers extends BaseConfigAction {
  type: "SET_FILTER_VALIDITITY_TRACKERS";
  payload: { filterValidityTrackers: boolean[] };
}

export type FilterListManagerAction = SetFilterValidityTrackers;

export const INITIAL_STATE = {
  filterValidityTrackers: []
};

export const getInitialFilterState = (draftComponent: SpaceComponentObject) => {
  return {
    // initialize array, assuming all filters are valid since they had already been saved
    filterValidityTrackers: (draftComponent.properties.filters || []).map(() => true)
  };
};

export function ensureFilterListManagerState(
  state: ComponentConfigState
): FilterListManagerState & BaseComponentConfigState {
  if ("filterValidityTrackers" in state) {
    return state;
  }
  throw new Error("Expected component config state to include FilterListManagerState.");
}

export default function reducer(
  state: FilterListManagerState,
  action: SpaceConfigAction
): FilterListManagerState {
  switch (action.type) {
    case "SET_FILTER_VALIDITITY_TRACKERS": {
      const { filterValidityTrackers } = action.payload;
      const draftState = cloneDeep(state);
      draftState.filterValidityTrackers = filterValidityTrackers;
      return draftState;
    }

    default:
      return state;
  }
}
