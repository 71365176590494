import React from "react";

import { Checkbox, Icon, Table, Input } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import { ColumnProps } from "antd/lib/table";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import {
  FunctionNode,
  FunctionAttributeNode,
  FunctionParameterNode,
  Edge
} from "../../../../types";

import { FunctionPolicyActionTypes, PolicyMemberType } from "./useFunctionPolicies";

import { RoleDerivedPermissionInput, useFunctionPolicies } from ".";

interface FunctionPermissionProps {
  functionPolicies: ReturnType<typeof useFunctionPolicies>;
  allowsAllFunctions: boolean;
  isEditMode: boolean;
  maxHeight?: number;
  features?: any;
}

const StyledTableHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacermd} 0;
`;

const StyledRequiredNotice = styled.div`
  white-space: nowrap;
  margin-right: ${props => props.theme.spacermd};
`;

const StyledInput = styled(Input)`
  max-width: 60%;
`;

const StyledTable = styled(Table)`
  tbody > tr {
    td {
      overflow: hidden;
      max-width: 150px;
      text-overflow: ellipsis;

      &:first-child {
        position: relative;
        padding-left: 32px;

        div[role="button"] {
          left: 8px;
          top: calc(50% - 7px); // Half of font height
          position: absolute;
        }
      }
    }
  }
`;

export default function FunctionPermissions(props: FunctionPermissionProps) {
  const debouncedSearch = useDebouncedCallback((searchText: string) => {
    props.functionPolicies.dispatch({
      type: FunctionPolicyActionTypes.SEARCH_FUNCTIONS,
      payload: { searchText }
    });
  }, 100).callback;

  const rows = React.useMemo(() => {
    return props.functionPolicies.functions.map(f => getFunctionRow(props, f));
  }, [props]);

  return (
    <div>
      <StyledTableHeader>
        <StyledRequiredNotice>* Required parameters</StyledRequiredNotice>
        <StyledInput
          placeholder="Search by function or data source"
          prefix={<Icon type="search" />}
          onChange={e => debouncedSearch(e.currentTarget.value)}
        />
      </StyledTableHeader>
      <StyledTable
        loading={props.functionPolicies.loading}
        dataSource={rows}
        columns={COLUMNS}
        scroll={!!props.maxHeight ? { y: props.maxHeight } : undefined}
        pagination={
          {
            pageSize: props.functionPolicies.pageSize,
            current: props.functionPolicies.page + 1,
            total: props.functionPolicies.filteredFunctionCount,
            onChange: page => {
              props.functionPolicies.dispatch({
                type: FunctionPolicyActionTypes.GOTO_PAGE,
                payload: { page: page - 1 }
              });
            }
          } as PaginationConfig
        }
      />
    </div>
  );
}
const getFunctionAttributeRow = (
  props: FunctionPermissionProps,
  func: FunctionNode,
  policy: RoleDerivedPermissionInput,
  functionAttribute: FunctionAttributeNode
) => {
  const hasPermission = policy.attributes.includes(functionAttribute.id);

  return {
    key: functionAttribute.id,
    title: "",
    dataSource: "",
    memberName: functionAttribute.name,
    memberType: "Attribute",
    permission: (
      <div>
        <Checkbox
          disabled={!props.isEditMode}
          checked={props.allowsAllFunctions || hasPermission}
          onChange={e =>
            props.functionPolicies.dispatch({
              type: FunctionPolicyActionTypes.CHANGE_POLICY,
              payload: {
                functionId: func.id,
                memberType: PolicyMemberType.ATTRIBUTES,
                memberId: functionAttribute.id,
                hasPermission: e.target.checked
              }
            })
          }
        />
      </div>
    )
  };
};

const getFunctionParameterRow = (
  props: FunctionPermissionProps,
  func: FunctionNode,
  policy: RoleDerivedPermissionInput,
  functionParameter: FunctionParameterNode
) => {
  const hasPermission = policy.parameters.includes(functionParameter.id);
  const parameterName = functionParameter.required
    ? functionParameter.name + " *"
    : functionParameter.name;
  return {
    key: functionParameter.id,
    title: "",
    dataSource: "",
    memberName: parameterName,
    memberType: "Parameter",
    permission: (
      <Checkbox
        disabled={!props.isEditMode}
        checked={props.allowsAllFunctions || hasPermission}
        onChange={e =>
          props.functionPolicies.dispatch({
            type: FunctionPolicyActionTypes.CHANGE_POLICY,
            payload: {
              functionId: func.id,
              memberType: PolicyMemberType.PARAMETERS,
              memberId: functionParameter.id,
              hasPermission: e.target.checked
            }
          })
        }
      />
    )
  };
};

const getFunctionRow = (props: FunctionPermissionProps, func: FunctionNode) => {
  const policy = props.functionPolicies.getPolicy(func.id);
  const allMembersHavePermission =
    func.functionAttributes?.edges.length === policy.attributes.length &&
    func.functionParameters?.edges.length === policy.parameters.length;
  const someMembersHavePermissions =
    policy.attributes.length > 0 || policy.parameters.length > 0;
  return {
    key: func.id,
    title: <span>{func.title}</span>,
    dataSource: func.dataSource?.name,
    memberName: "All",
    memberType: "-",
    permission: (
      <Checkbox
        onChange={() => {
          props.functionPolicies.dispatch({
            type: allMembersHavePermission
              ? FunctionPolicyActionTypes.REVOKE_ALL_ACCESS
              : FunctionPolicyActionTypes.GRANT_ALL_ACCESS,
            payload: { functionId: func.id }
          });
        }}
        indeterminate={
          !(props.allowsAllFunctions || allMembersHavePermission) &&
          someMembersHavePermissions
        }
        checked={props.allowsAllFunctions || allMembersHavePermission}
        disabled={!props.isEditMode}
      />
    ),
    children: [
      ...(func.functionParameters?.edges.map(({ node }: Edge<FunctionParameterNode>) =>
        getFunctionParameterRow(props, func, policy, node)
      ) || []),
      ...(func.functionAttributes?.edges.map(({ node }: Edge<FunctionAttributeNode>) =>
        getFunctionAttributeRow(props, func, policy, node)
      ) || [])
    ]
  };
};

const COLUMNS: ColumnProps<any>[] = [
  {
    title: "Function",
    dataIndex: "title",
    key: "title",
    width: "25%"
  },
  {
    title: "Data Source",
    dataIndex: "dataSource",
    key: "dataSource",
    width: "20%"
  },
  {
    title: "Attribute/Parameters",
    dataIndex: "memberName",
    key: "memberName",
    width: "20%",
    align: "left"
  },
  {
    title: "Type",
    dataIndex: "memberType",
    key: " memberType",
    width: "20%",
    align: "left"
  },
  {
    title: "Permission",
    dataIndex: "permission",
    key: "permission",
    width: "15%",
    align: "center"
  }
];
