import usePrevious from "../../../../common/hooks/usePrevious";
import { EventHandler } from "../../../types";
import { Row } from "../common/useView";

import { TableEventType } from "./effects";
import { SpaceTableProperties, SpaceTableStatus } from "./types";

export default function useEffectsManager(
  tableStatus: SpaceTableStatus,
  properties: SpaceTableProperties,
  rows: Row[],
  lastSelectedRows: { ids: string[]; indexes: number[] },
  selectRows: (ids: string[]) => void | undefined = () => {}
) {
  const lastTableStatus = usePrevious(tableStatus);

  function handleEvent(effects: EventHandler[]) {
    effects.forEach(e => {
      switch (e.effect.type) {
        case "select_first_row":
          if (rows[0]) {
            selectRows([rows[0].id]);
          }
          break;
        case "maintain_selected_rows_by_index":
          selectRows(
            rows.filter((_, i) => lastSelectedRows.indexes.includes(i)).map(r => r.id)
          );
          break;
        case "maintain_selected_rows_by_id":
          selectRows(lastSelectedRows.ids);
          break;
        default:
          throw new Error(`Unexpected TableActionType: ${e.effect.type}`);
      }
    });
  }

  if (
    tableStatus === SpaceTableStatus.INITIAL_LOAD_COMPLETE &&
    lastTableStatus !== SpaceTableStatus.INITIAL_LOAD_COMPLETE
  ) {
    handleEvent(properties.effects.filter(e => e.type === TableEventType.INITIAL_LOAD));
  } else if (
    tableStatus === SpaceTableStatus.RELOAD_COMPLETE &&
    lastTableStatus !== SpaceTableStatus.RELOAD_COMPLETE
  ) {
    handleEvent(properties.effects.filter(e => e.type === TableEventType.RELOAD));
  }
}
