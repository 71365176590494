import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "../../common/Button";
import usePaths from "../../common/hooks/usePaths";
import { DetailParams } from "../common";

import AddAuthProviderModal from "./AddAuthProviderModal";
import { AuthProviderDrawer } from "./AuthProviderDrawer";
import AuthProviderTable from "./AuthProviderTable";
import { AuthorizationFlowNode } from "./types";

export const FETCH_AUTHORIZATION_PROVIDERS = gql`
  query FetchAuthorizationProviders {
    allAuthorizationProviders {
      id
      name
      description
      createdAt
      createdBy {
        id
        fullName
        __typename
      }
      authSteps {
        edges {
          node {
            id
            slug
            type
            order
            options
            function {
              id
              name
              title
              dataSource {
                id
                name
                __typename
              }
              __typename
            }
          }
        }
      }
      environmentsWithState {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export interface ResultData {
  allAuthorizationProviders: AuthorizationFlowNode[];
}

const AddAuthProviderButton = styled(Button)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const AuthProviderList = () => {
  const { data, loading, refetch } = useQuery<ResultData>(
    FETCH_AUTHORIZATION_PROVIDERS,
    {
      fetchPolicy: "cache-and-network"
    }
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const { getAuthSettings } = usePaths();
  const navigate = useNavigate();
  const { id: authProviderId } = useParams<DetailParams>();
  const showDrawer = !!authProviderId;
  const selectedAuthProvider = data?.allAuthorizationProviders.find(authProvider => {
    return authProvider.id === authProviderId;
  });

  return (
    <React.Fragment>
      <AddAuthProviderButton type="primary" onClick={() => setShowModal(true)}>
        Add Authorization Provider
      </AddAuthProviderButton>
      <AuthProviderTable
        authProviders={data?.allAuthorizationProviders || []}
        isLoading={loading}
        refetchProviders={refetch}
        enableRowClick
      />
      {selectedAuthProvider && (
        <AuthProviderDrawer
          onClose={() => {
            navigate(getAuthSettings());
          }}
          isVisible={showDrawer}
          loading={loading}
          authorizationProvider={selectedAuthProvider}
        />
      )}
      <AddAuthProviderModal
        visible={showModal}
        handleCancel={() => setShowModal(false)}
        handleOk={() => {
          refetch();
          setShowModal(false);
        }}
      />
    </React.Fragment>
  );
};
