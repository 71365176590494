import { Binding, BindingShape } from "../../../../../types";

export const getSchema = (): Binding[] => [
  {
    title: "Current",
    name: "current",
    shape: BindingShape.OBJECT,
    attributes: [
      {
        title: "Name",
        name: "name",
        shape: BindingShape.SCALAR
      },
      {
        title: "Slug",
        name: "slug",
        shape: BindingShape.SCALAR
      }
    ]
  }
];
