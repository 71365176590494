import { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

/**
 * Hook providing updateSearchParams which updates search params in place.
 *
 * @returns updateSearchParams
 *
 */
export default function useSearchParamsUpdater() {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * updateSearchParams leaving any omitted params in place.
   *
   * @param params - Record<string, string | undefined> of search params to be applied to existing search.
   * @returns void
   *
   */
  const updateSearchParams = useCallback(
    (params: Record<string, any>) => {
      const nextParams = { ...Object.fromEntries(searchParams), ...params };
      setSearchParams(
        Object.fromEntries(
          Object.entries(nextParams)
            .filter(([_, v]) => v !== undefined)
            .map(([k, v]) => [k, String(v)])
        )
      );
    },
    [searchParams, setSearchParams]
  );
  return updateSearchParams;
}
