import * as React from "react";
import { useCallback } from "react";

import { ButtonProps } from "antd/lib/button/button";

import IconButton from "../../IconButton";

interface RenderHandler<T> {
  update: (item: T) => void;
  delete: () => void;
  DeleteButton: React.FunctionComponent<ButtonProps>;
}

interface Props<T> {
  items: T[];
  render: (item: T, handlers: RenderHandler<T>) => React.ReactNode;
  factory: () => T;
  createPrompt?: string;
  onChange?: (items: T[]) => void;
  onItemChange?: (i: number, item: T) => void;
}

export default function Repeat<T>({
  items,
  render,
  factory,
  createPrompt = "Add",
  onChange = () => {}
}: Props<T>) {
  // const _onItemChange = (item: T) => onItemChange(item);

  const _onItemCreate = useCallback(() => {
    // if (list.findIndex(item => keyCallback(item) === "") > -1) return;
    const copy = items.concat();
    copy.push(factory());
    onChange(copy);
  }, [items, factory, onChange]);

  const _onItemChange = useCallback(
    (item: T, i: number) => {
      const copy = items.concat();
      copy[i] = item;
      onChange(copy);
    },
    [items, onChange]
  );

  const _onItemDelete = useCallback(
    (i: number) => {
      const copy = items.concat();
      copy.splice(i, 1);
      onChange(copy);
    },
    [items, onChange]
  );

  return (
    <>
      {items.map((item, i) => {
        const DeleteButton = (props: ButtonProps) => (
          <IconButton
            icon="minus"
            title="Remove"
            type="link"
            onClick={() => _onItemDelete(i)}
            {...props}
          />
        );

        return (
          <React.Fragment key={i}>
            {render(item, {
              update: (item: T) => _onItemChange(item, i),
              delete: () => _onItemDelete(i),
              DeleteButton
            })}
          </React.Fragment>
        );
      })}
      <IconButton
        type="link"
        icon="plus"
        title={createPrompt}
        onClick={() => _onItemCreate()}
      >
        {createPrompt}
      </IconButton>
    </>
  );
}
