import React from "react";

import { useQuery } from "@apollo/react-hooks";

import { useConfigContext } from "../../../ConfigContext";
import {
  GET_ALL_USERS_DATA,
  GET_ALL_INVITES_DATA,
  GET_SUBSCRIPTION_DATA
} from "../../../graphql/queries";
import { UserNode, OrganizationInviteNode, Edge } from "../../../types";

import InviteModalContents from "./InviteModalContents";

interface InviteModalProps {
  handleOk: () => void;
  handleCancel: () => void;
  visible: boolean;
  defaultValue?: string;
}

const InviteModal = (props: InviteModalProps) => {
  const { isOnPrem } = useConfigContext();
  const { data: usersData, loading: usersLoading } = useQuery(GET_ALL_USERS_DATA, {
    skip: !props.visible
  });
  const { data: invitesData, loading: invitesLoading } = useQuery(
    GET_ALL_INVITES_DATA,
    { skip: !props.visible }
  );
  const { data: subscriptionData } = useQuery(GET_SUBSCRIPTION_DATA, {
    fetchPolicy: "cache-and-network",
    skip: isOnPrem
  });

  const loading = usersLoading || invitesLoading;
  const users = usersData?.allUsers?.edges.map((e: Edge<UserNode>) => e.node) || [];
  const invites =
    invitesData?.allOrganizationInvites?.edges.map(
      (e: Edge<OrganizationInviteNode>) => e.node
    ) || [];
  return (
    <InviteModalContents
      dataLoading={loading}
      defaultValue={props.defaultValue}
      users={users}
      invites={invites}
      handleCancel={props.handleCancel}
      handleOk={props.handleOk}
      visible={props.visible}
      subscription={subscriptionData?.stripeSubscription?.subscription}
    />
  );
};

export default InviteModal;
