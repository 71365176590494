import React from "react";

import { Skeleton, Tooltip } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../../../layouts/Header/Header";
import Nav from "../../../../layouts/Nav";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import CardList, { Item } from "../../../common/CardList";
import useAuthUser from "../../../common/hooks/useAuthUser";
import usePaths from "../../../common/hooks/usePaths";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import {
  Cols,
  LeftRail,
  Main,
  RightRail
} from "../../../common/layout/styledComponents";
import {
  H6,
  Header,
  Hr,
  IconButton,
  LinkButtonNew,
  MainTitle,
  NewButton,
  P,
  SecondaryButtonList
} from "../../../common/StyledComponents";
import OrgDetail from "../../../spaces/Home/OrgDetail/OrgDetail";
import copyToClipboard from "../../../util/copyToClipboard";
import SettingsModal from "../../Config/SettingsModal/SettingsModal";
import { LocalQueues, resolveQueues } from "../index";
import { useAllQueues } from "../queries/AllQueues";
import { PermissionsModal } from "../Queue/Permissions/PermissionsModal";

import DeleteQueueModal from "./DeleteQueueModal";
import * as Styled from "./styledComponents";

// begin - duped from src/components/spaces/Home/SpaceDetail/SpaceDetail.tsx
const TOOLTIP_DISPLAY_LENGTH = 1500;

const StyledButton = styled(ButtonNew)`
  margin: 6px 0;
`;
// end - duped from src/components/spaces/Home/SpaceDetail/SpaceDetail.tsx

type HomeModal = "DELETE_QUEUE_MODAL" | "QUEUE_SETTINGS_MODAL" | "PERMISSIONS_MODAL";

export const Home = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAuthUser();

  const [openModal, setOpenModal] = React.useState<null | HomeModal>(null);
  const [showCopiedTooltip, setShowCopiedTooltip] = React.useState(false);
  const { queuesapp_getNewQueue, queuesapp_getEditQueue, queuesapp_getQueue } =
    usePaths();

  const [searchParams] = useSearchParams();
  const updateParams = useSearchParamsUpdater();

  const selectedQueueSlug = searchParams.get("selectedQueue") || undefined;

  const { loading, data, refetch } = useAllQueues({
    fetchPolicy: "no-cache"
  });

  const items = React.useMemo(() => {
    return data?.allQueues.edges.length
      ? resolveQueues(data?.allQueues.edges)
          .map(queue => ({
            ...queue,
            href: queuesapp_getQueue(queue.slug)
          }))
          .filter(queue => queue.isListed)
      : [];
  }, [data, queuesapp_getQueue]);

  const selectedItem: Item | null = React.useMemo(() => {
    return items.find(item => item.slug === selectedQueueSlug) || null;
  }, [items, selectedQueueSlug]);

  const isReservedQueue =
    selectedItem && ["all", "assigned"].includes(selectedItem.slug);

  const onSelect = React.useCallback(
    (slug: string | null) => {
      const found = items.find(item => item.slug === slug) || null;
      if (slug !== null && !found) {
        throw new Error("illegal state");
      }

      updateParams({ selectedQueue: slug });
    },
    [items, updateParams]
  );

  const deselectQueue = React.useCallback(() => {
    updateParams({ selectedQueue: undefined });
  }, [updateParams]);

  return (
    <>
      <PageHeader key="queues-home" title="Home" />
      <Cols>
        <LeftRail open>
          <Nav />
        </LeftRail>
        <>
          {loading ? (
            <Main>
              {" "}
              <Skeleton />{" "}
            </Main>
          ) : !items.length ? (
            <Styled.GettingStartedContainer>
              <Styled.GettingStarted />
            </Styled.GettingStartedContainer>
          ) : (
            <Main>
              <Header>
                <MainTitle>Your queues</MainTitle>
                {isAdmin && (
                  <NewButton
                    icon="plus"
                    type="link"
                    onClick={() => {
                      navigate(queuesapp_getNewQueue());
                    }}
                  >
                    New queue
                  </NewButton>
                )}
              </Header>
              <Hr />
              <CardList
                items={items}
                moreButton={undefined}
                selectedSlug={selectedItem && selectedItem.slug}
                loading={loading}
                onSelect={onSelect}
                emptyState="You have no queues"
              />
            </Main>
          )}
        </>
        {!!items.length && (
          <RightRail isStatic isActive={!!selectedItem} onBack={() => onSelect(null)}>
            {selectedItem ? (
              <div>
                <H6>{selectedItem.name}</H6>
                {selectedItem.description && <P>{selectedItem.description}</P>}
                <SecondaryButtonList>
                  <IconButton
                    title="Update queue settings"
                    type="link"
                    shape="circle"
                    icon="setting"
                    onClick={() => {
                      setOpenModal("QUEUE_SETTINGS_MODAL");
                    }}
                  />
                  <Tooltip
                    trigger="click"
                    title="Link copied"
                    visible={showCopiedTooltip}
                  >
                    <IconButton
                      title="Copy link to queue"
                      type="link"
                      shape="circle"
                      icon="link"
                      onClick={() => {
                        copyToClipboard(
                          window.location.origin +
                            queuesapp_getQueue(selectedItem?.slug)
                        );
                        setShowCopiedTooltip(true);
                        setTimeout(() => {
                          setShowCopiedTooltip(false);
                        }, TOOLTIP_DISPLAY_LENGTH);
                      }}
                    />
                  </Tooltip>
                  {!isReservedQueue && isAdmin && (
                    <IconButton
                      title="Delete queue"
                      type="link"
                      shape="circle"
                      icon="delete"
                      onClick={() => setOpenModal("DELETE_QUEUE_MODAL")}
                    />
                  )}
                </SecondaryButtonList>
                <StyledButton
                  size="sm"
                  type="primary"
                  block
                  onClick={() => navigate(selectedItem.href)}
                >
                  Open queue
                </StyledButton>
                {!isReservedQueue && isAdmin && (
                  <StyledButton
                    size="sm"
                    block
                    onClick={() => {
                      navigate(queuesapp_getEditQueue(selectedItem.slug));
                    }}
                  >
                    Edit queue
                  </StyledButton>
                )}

                {isAdmin &&
                  selectedItem &&
                  selectedItem.slug !== LocalQueues.assigned.id && (
                    <>
                      <Hr />
                      <LinkButtonNew onClick={() => setOpenModal("PERMISSIONS_MODAL")}>
                        Manage access and permissions
                      </LinkButtonNew>
                    </>
                  )}
              </div>
            ) : (
              <OrgDetail />
            )}
          </RightRail>
        )}
      </Cols>
      {selectedItem && openModal === "QUEUE_SETTINGS_MODAL" && (
        <SettingsModal
          queue={selectedItem}
          onCancel={() => setOpenModal(null)}
          onComplete={() => {
            refetch();
            setOpenModal(null);
          }}
        />
      )}
      {selectedItem?.slug && openModal === "PERMISSIONS_MODAL" && (
        <PermissionsModal
          queueName={selectedItem.name}
          queueSlug={selectedItem.slug}
          onCancel={() => setOpenModal(null)}
        />
      )}
      {selectedItem && openModal === "DELETE_QUEUE_MODAL" && (
        <DeleteQueueModal
          queueId={selectedItem?.id}
          onSuccess={() => deselectQueue()}
          onComplete={() => setOpenModal(null)}
          onCancel={() => setOpenModal(null)}
        />
      )}
    </>
  );
};
