import {
  RoleDerivedPermissionInput as _RoleDerivedPermissionInput,
  FunctionPermissionInput as _FunctionPermissionInput
} from "./useFunctionPolicies";

export { default } from "./FunctionPermissions";
export {
  default as useFunctionPolicies,
  FunctionPolicyActionTypes
} from "./useFunctionPolicies";

export type RoleDerivedPermissionInput = _RoleDerivedPermissionInput;
export type FunctionPermissionInput = _FunctionPermissionInput;
