import React from "react";

import { useComponentConfigContext } from "../../ComponentConfigContext";
import { Field, Checkbox } from "../../ConfigPanel";
import { InputConfigActionTypes } from "../../inputReducer";
import useNestedStatus from "../../useNestedStatus";

export default function ConvertToStringField() {
  const { state, dispatch } = useComponentConfigContext();
  const { isFormItem } = useNestedStatus();
  return (
    <Field>
      <Checkbox
        data-test="convertToStringCheckbox"
        disabled={isFormItem}
        checked={state.draftComponent.properties.serialize_value_as_csv}
        onChange={({ target: { checked } }) =>
          dispatch({
            type: InputConfigActionTypes.SET_SERIALIZE_VALUE_AS_CSV,
            payload: {
              serialize_value_as_csv: checked
            }
          })
        }
      >
        Convert value to CSV string
      </Checkbox>
    </Field>
  );
}
