import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled.a`
  font-weight: 500;
`;

type Props = {
  href?: string;
  children?: React.ReactNode;
};

const BackButton = ({ href, children = "Back" }: Props) => {
  const navigate = useNavigate();

  return (
    <StyledLink
      onClick={evt => {
        evt.preventDefault();
        href ? navigate(href) : navigate(-1);
      }}
    >
      &#12296;&nbsp;&nbsp;{children}
    </StyledLink>
  );
};

export default BackButton;
