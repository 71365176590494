import React from "react";

import { Drawer } from "antd";
import styled from "styled-components";

import ThemeContainer, { Theme } from "../../../../common/ThemeContainer";

interface RightDrawerProps {
  children: React.ReactNode;
  onClose: () => void;
}

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 33% !important;
    min-width: 380px;
  }

  .ant-drawer-body {
    padding: 0;
  }
`;

const DrawerContents = styled.div`
  color: ${props => props.theme.textColor};
  min-height: 100vh; /* ensures dark bg while sliding open */
  background-color: ${props => props.theme.backgroundColor};
  padding: ${props => props.theme.spacerxl};
`;

export default function RightDrawer({ children, onClose }: RightDrawerProps) {
  return (
    <StyledDrawer visible onClose={onClose}>
      <ThemeContainer theme={Theme.Dark} depthOverride={1}>
        <DrawerContents>{children}</DrawerContents>
      </ThemeContainer>
    </StyledDrawer>
  );
}
