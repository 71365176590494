import { useCallback } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Severity } from "@sentry/browser";

import { SpaceNode, SpaceComponentInputType } from "../../../../../../types";
import { useEnvironmentContext } from "../../../../../common/contexts/EnvironmentContext";
import Message from "../../../../../common/Message";
import { reportException } from "../../../../../util/exceptionReporting";
import { useStableSpaceContext } from "../../../../SpaceRoot/SpaceContext";
import { ENVIRONMENT_VERSION_QUERY } from "../../../ConfigHeader/PublishButton/queries";
import { getComponentsForMutation } from "../../../util/util";
import {
  SpaceConfigState,
  SpaceConfigDispatch,
  selectSpaceComponentTree
} from "../reducer";

import { SPACE_UPDATE } from "./queries";

interface Result {
  save: (environmentsToPublish?: string[]) => void;
  loading: boolean;
}

export interface MutationOptions {
  id?: string;
  onNewSpaceCreated?: (data: UseConfigMutationData) => void;
}

type ValidationError = string | { [key: string]: string | ValidationError };

export type UseConfigMutationData = {
  spaceUpdate: {
    ok: boolean;
    message?: string;
    space: SpaceNode;
    source: {
      slugs: Record<string, ValidationError>;
      space: string;
    };
  };
};
export type UseConfigMutationVars = {
  spaceInput: {
    id?: string;
    name: string;
    componentTreeNodes: SpaceComponentInputType[];
  };
  environmentsToPublish: string[];
  currentEnvironmentId: string;
};

export default function useConfigMutation(
  state: SpaceConfigState,
  dispatch: SpaceConfigDispatch,
  options: MutationOptions
): Result {
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const [saveConfig, { loading }] = useMutation<
    UseConfigMutationData,
    UseConfigMutationVars
  >(SPACE_UPDATE, {
    refetchQueries: options.id
      ? [{ query: ENVIRONMENT_VERSION_QUERY, variables: { space: options.id } }]
      : [],
    onCompleted: result => {
      if (result.spaceUpdate.ok) {
        dispatch({ type: "HANDLE_SAVE" });
        if (options.onNewSpaceCreated) {
          options.onNewSpaceCreated(result);
        }
        return;
      }
      const sourceKeys = Object.keys(result.spaceUpdate.source);
      if (sourceKeys.includes("slugs")) {
        reportException(new Error("Invalid space submission allowed by client."), {
          extra: { result: JSON.stringify(result.spaceUpdate) },
          level: Severity.Warning
        });
        console.error(
          "Space failed to save. Contact support with the following information for more help.",
          result.spaceUpdate.source
        );
        Message.error(
          "Space failed to save. More information is available in your browser's development console."
        );
        dispatch({
          type: "SET_API_ERRORS",
          payload: {
            errors: result.spaceUpdate.source.slugs
          }
        });
        return;
      }

      if (sourceKeys.includes("space")) {
        Message.error(result.spaceUpdate.source.space);
        return;
      }

      Message.error(
        "Something went wrong saving this state. Our team has been notified. Please try again later. "
      );
      throw Error("Unexpected error while saving this state.");
    }
  });
  const environment = useEnvironmentContext().getCurrentEnvironment();

  const save = useCallback(
    (environmentsToPublish: string[] | undefined) => {
      const rootComponents = selectSpaceComponentTree(state.components, state.tree);
      const componentTreeNodes = getComponentsForMutation(
        rootComponents,
        state,
        findSpaceComponentPackage
      );
      const name = state.name;

      saveConfig({
        variables: {
          currentEnvironmentId: environment.id,
          environmentsToPublish: environmentsToPublish || [],
          spaceInput: { id: options.id, name, componentTreeNodes }
        }
      });
    },
    [state, options, environment, findSpaceComponentPackage, saveConfig]
  );

  return {
    save,
    loading
  };
}
