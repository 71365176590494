import React, { useState } from "react";

import { Form, Input, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { DataProxy } from "apollo-cache";
import { ApolloError } from "apollo-client";
import _ from "lodash";
import { QueryResult, Mutation, MutationFunction } from "react-apollo";
import styled from "styled-components";

import { ADD_ROLE, GET_ALL_ROLES } from "../../../graphql/queries";
import { RoleNode } from "../../../types";
import { ErrorField, FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import FunctionPermissions, { useFunctionPolicies } from "./FunctionPermissions";

const ModalSectionContainer = styled.div`
  margin-top: 20px;
`;

interface ModalSectionProps {
  title: string;
  children: JSX.Element;
}

const ModalSection = (props: ModalSectionProps) => {
  return (
    <ModalSectionContainer>
      <h3>{props.title}</h3>
      {props.children}
    </ModalSectionContainer>
  );
};

interface AddRoleModalProps extends FormComponentProps {
  handleOk: () => void;
  handleCancel: () => void;
  visible: boolean;
  roles: RoleNode[];
  features: any;
}

type AddRoleData = any;
type AddRoleVariables = any;
type AddRoleMutationFn = MutationFunction<AddRoleData, AddRoleVariables>;
interface GetAllRolesData {
  allRoles: any;
}

const getRoleNameValidator = (roles: RoleNode[]) => {
  return (rule: any, value: string, callback: (msg?: string) => void) => {
    const doesRoleExist = _.find(roles, (role: RoleNode) => {
      return role.name === value;
    });
    if (value && doesRoleExist) {
      callback("A role with this name already exists.");
    } else {
      callback();
    }
  };
};

const handleUpdate = (cache: DataProxy, result: QueryResult<GetAllRolesData>) => {
  const { allRoles } = cache.readQuery<GetAllRolesData>({
    query: GET_ALL_ROLES
  })!;
  const existingEdgeType = _.get(allRoles, "edges[0].__typename");
  const newEdge = {
    node: _.get(result, "data.createRole.role"),
    __typename: existingEdgeType
  };
  const updatedEdges = allRoles.edges.concat([newEdge]);
  cache.writeQuery({
    query: GET_ALL_ROLES,
    data: {
      allRoles: {
        edges: updatedEdges,
        __typename: allRoles.__typename
      }
    }
  });
};

const AddRoleModal = (props: AddRoleModalProps) => {
  const functionPolicies = useFunctionPolicies();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { getFieldDecorator } = props.form;

  const clearFields = () => {
    setErrorMessage("");
    props.form.setFieldsValue({
      roleName: ""
    });
  };

  const onSaveHandler = (addRole: (variables: any) => void) => {
    addRole({
      variables: {
        roleName: props.form.getFieldValue("roleName"),
        permissions: [...functionPolicies.policyChanges.values()]
      }
    });
  };

  return (
    <Mutation
      mutation={ADD_ROLE}
      onCompleted={() => {
        clearFields();
        props.handleOk();
      }}
      onError={(data: ApolloError) => {
        const text = data.graphQLErrors ? data.graphQLErrors[0].message : "";
        setErrorMessage(text);
      }}
      update={handleUpdate}
    >
      {(addRole: AddRoleMutationFn, { loading }: any) => {
        const mutationLoading = loading;
        return (
          <Modal
            title="Add a role"
            width="90%"
            style={{ maxWidth: "650px" }}
            visible={props.visible}
            onOk={() => {
              props.form.validateFields(err => {
                if (!err) {
                  onSaveHandler(addRole);
                }
              });
            }}
            onCancel={() => {
              clearFields();
              props.handleCancel();
            }}
            confirmLoading={mutationLoading}
          >
            <div>
              <p>
                Create a role for a team or other groups of users requiring the same
                level of permissions.
              </p>
              <ModalSection title="Role Name">
                <FormItem>
                  {getFieldDecorator("roleName", {
                    rules: [
                      {
                        required: true,
                        message: VALIDATION_MESSAGES.requiredField
                      },
                      {
                        validator: getRoleNameValidator(props.roles)
                      }
                    ]
                  })(
                    <Input
                      placeholder="e.g. Customer Support"
                      onChange={e => {
                        props.form.setFieldsValue({
                          roleName: e.target.value
                        });
                      }}
                    />
                  )}
                </FormItem>
              </ModalSection>
              <FunctionPermissions
                functionPolicies={functionPolicies}
                isEditMode={true}
                allowsAllFunctions={false}
                maxHeight={400}
                features={props.features}
              />
              <ErrorField>{errorMessage}</ErrorField>
            </div>
          </Modal>
        );
      }}
    </Mutation>
  );
};

export default Form.create<AddRoleModalProps>({ name: "addRole" })(AddRoleModal);
