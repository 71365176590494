import React, { useCallback, useMemo } from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button, Select, Skeleton } from "antd";
import gql from "graphql-tag";
import { debounce, sortBy } from "lodash";
import styled from "styled-components";

import {
  Connection,
  DataSourceNode,
  Edge,
  FunctionNode,
  FunctionScope
} from "../../../types";
import useStableId from "../hooks/useStableId";
import NotFoundContent from "../Select/NotFoundContent";
import { ConfigErrorField } from "../StyledComponents";

// APPROACH:
// <FunctionPickerDataContainer /> is responsible for querying the selected function
// if present and providing that function and its datasource as props to FunctionPicker.
// <FunctionPickerDataContainer /> manipulates its queries in ApolloCache to prevent
// unneeded re-querying that would be presented to end users as loading steps for data
// which is already present. <FunctionPicker /> also manually manages its queries, to
// ensure that the selected function is present even if it may have been excluded by
// RELAY_CONNECTION_MAX_LIMIT

interface SelectedFunctionData {
  function: FunctionNode;
}
export const SELECTED_FUNCTION_QUERY = gql`
  query SelectedFunctionQuery($id: ID!) {
    function(id: $id) {
      __typename
      id
      name
      title
      isUserGenerated
      dataSource {
        __typename
        id
        name
      }
    }
  }
`;

interface DataSourceData {
  allDataSources: Connection<DataSourceNode>;
}
export const DATA_SOURCE_QUERY = gql`
  query FunctionPickerDataSources {
    allDataSources {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
        }
      }
    }
  }
`;

interface FunctionData {
  allFunctions: Connection<FunctionNode>;
}
export const FUNCTION_QUERY = gql`
  query FunctionPickerFunctions(
    $functionScope: String!
    $dataSourceId: ID
    $searchText: String
  ) {
    allFunctions(
      scope: $functionScope
      dataSourceId: $dataSourceId
      searchText: $searchText
    ) {
      edges {
        __typename
        node {
          __typename
          id
          name
          title
          isUserGenerated
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Edit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const EditButton = styled(Button)`
  padding-right: 0;
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.theme.surfaceSecondary};
  margin-top: ${props => props.theme.spacermd};
  margin-bottom: ${props => props.theme.spacerxs};
`;

export interface EditFunctionEvent {
  dataSourceId: string;
  functionId: string;
}

export interface FunctionPickerDataContainerProps {
  className?: string;
  functionId: string | null;
  functionScope: FunctionScope;
  onChange: (id: string | null) => void;
  onEditFunctionClick?: (e: EditFunctionEvent) => void;
  showLabels?: boolean;
}

interface FunctionPickerProps {
  className?: string;
  functionId: string | null;
  functionScope: FunctionScope;
  selectedFunction?: FunctionNode | undefined;
  selectedDataSource?: DataSourceNode | undefined;
  onChange: (functionNode: FunctionNode | null) => void;
  onEditFunctionClick?: (e: EditFunctionEvent) => void;
  showLabels?: boolean;
}

function FunctionPicker({ onChange, ...props }: FunctionPickerProps) {
  const domId = useStableId("functionPicker");
  const [dataSourceId, setDataSourceId] = React.useState(props.selectedDataSource?.id);
  const [funcSearchText, setFuncSearchText] = React.useState<string | undefined>();

  const dataSourceQuery = useQuery<DataSourceData>(DATA_SOURCE_QUERY, {
    fetchPolicy: "cache-and-network"
  });
  const functionQuery = useQuery<FunctionData>(FUNCTION_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      functionScope: props.functionScope,
      dataSourceId,
      searchText: funcSearchText
    },
    skip: !dataSourceId
  });
  const debouncedSetFuncSearchText = React.useRef(
    debounce(setFuncSearchText, 50)
  ).current;

  // NOTE: Data sources can be deleted at any given time, so we reset
  // the data source choice when it is no longer available as an option.
  React.useEffect(() => {
    if (
      !dataSourceQuery.loading &&
      !!props.selectedDataSource?.id &&
      !dataSourceQuery.data?.allDataSources.edges.some(edge => {
        return edge.node.id === props.selectedDataSource?.id;
      })
    ) {
      setDataSourceId(undefined);
    }
  }, [dataSourceQuery, props.selectedDataSource]);

  // NOTE: Ensure selectedFunction is in included and in the topmost position.
  //       Graphene enforces a limit to the number Edges returned in a query and
  //       it is possible for the selectedFunction to be excluded by that limit.
  //       In the case that funcSearchText is present, cicrumvent this behavior
  //       and only include the selectedFunction when it is naturally included
  //       in the query.
  const functionEdges = useMemo(() => {
    if (props.selectedFunction === undefined)
      return functionQuery.data?.allFunctions?.edges || [];
    let updatedEdges = [...(functionQuery.data?.allFunctions.edges || [])];
    const selectedIndex = updatedEdges.findIndex(
      edge => edge.node.id === props.selectedFunction?.id
    );
    if (selectedIndex >= 0) {
      updatedEdges.splice(selectedIndex, 1);
    }
    const selectedFunction: Edge<FunctionNode> = {
      node: props.selectedFunction,
      __typename: "FunctionNode"
    };
    // include selected function only if it belongs to selected data source
    if (
      selectedFunction?.node &&
      selectedFunction?.node?.dataSource?.id === dataSourceId
    ) {
      updatedEdges = [selectedFunction].concat(updatedEdges);
    }
    return updatedEdges;
  }, [functionQuery, props.selectedFunction, dataSourceId]);

  const handleDataSourceChange = useCallback(
    (id: string) => {
      setDataSourceId(id);
      setFuncSearchText(undefined);
      // only call onChange if user is making an edit because we do not want to render an error for the new config case
      if (props.selectedFunction) {
        onChange(null);
      }
    },
    [props.selectedFunction, onChange]
  );

  const dataSources = useMemo(
    () =>
      sortBy(dataSourceQuery.data?.allDataSources.edges || [], [
        e => e.node.name.toLowerCase()
      ]),
    [dataSourceQuery.data]
  );
  const functions = functionEdges || [];

  const hasDataSourceChangedForNewConfig =
    !props.selectedFunction && props.selectedDataSource?.id !== dataSourceId;
  // if data source has changed, set Select's function value to be undefined
  const functionId =
    hasDataSourceChangedForNewConfig || !functionQuery.data
      ? undefined
      : props.selectedFunction?.id;

  if (!dataSourceQuery.loading && dataSources.length === 0) {
    return (
      <div>
        You don’t have any functions enabled for your data sources.{" "}
        <a href="https://internal.io/contact" target="_blank" rel="noopener noreferrer">
          Contact us
        </a>{" "}
        to learn more.
      </div>
    );
  }

  return (
    <Container id={domId} data-test="function-picker-asdf">
      {props.showLabels && <Label>Data source</Label>}
      <Select
        data-test="select-data-source"
        placeholder="Select a datasource"
        value={dataSourceQuery.data ? dataSourceId : undefined}
        loading={dataSourceQuery.loading}
        notFoundContent={<NotFoundContent />}
        onChange={handleDataSourceChange}
        getPopupContainer={() => document.getElementById(domId) as HTMLElement}
      >
        {dataSources.map(e => (
          <Select.Option key={e.node.id} title={e.node.name}>
            {e.node.name}
          </Select.Option>
        ))}
      </Select>
      {props.showLabels && <Label>Function</Label>}
      <Select
        data-test="select-function"
        placeholder="Select a function"
        value={functionId}
        loading={!!dataSourceId && (functionQuery.loading || !functionQuery.data)}
        showSearch
        filterOption={false}
        notFoundContent={<NotFoundContent />}
        onSearch={value => {
          debouncedSetFuncSearchText(value || undefined);
        }}
        onChange={(id: string) => {
          const nextSelectedFuncEdge = functions.find(f => f.node.id === id);
          if (nextSelectedFuncEdge === undefined)
            throw new Error(`Expected to find function with id ${id}`);
          const nextSelectedFuncDataSource = dataSources.find(
            d => d.node.id === dataSourceId
          );
          if (nextSelectedFuncDataSource === undefined)
            throw new Error(`Expected to find data source with id ${dataSourceId}`);
          onChange({
            ...nextSelectedFuncEdge.node,
            dataSource: nextSelectedFuncDataSource.node
          });
          setFuncSearchText(undefined);
        }}
        getPopupContainer={() => document.getElementById(domId) as HTMLElement}
      >
        {functions.map(e => (
          <Select.Option key={e.node.id} title={e.node.title}>
            {e.node.title}
          </Select.Option>
        ))}
      </Select>
      {props.selectedFunction?.isUserGenerated &&
        props.onEditFunctionClick &&
        dataSourceId &&
        functionId && (
          <Edit>
            <EditButton
              type="link"
              onClick={e => {
                props.onEditFunctionClick &&
                  props.onEditFunctionClick({ dataSourceId, functionId });
                e.preventDefault();
              }}
            >
              Edit function
            </EditButton>
          </Edit>
        )}
      {props.functionId && !props.selectedFunction && (
        <ConfigErrorField>
          The selected function is no longer available. Please select a new function.
        </ConfigErrorField>
      )}
    </Container>
  );
}

export default function FunctionPickerDataContainer({
  onChange,
  ...props
}: FunctionPickerDataContainerProps) {
  const { data, client, loading } = useQuery<SelectedFunctionData>(
    SELECTED_FUNCTION_QUERY,
    {
      variables: { id: props.functionId },
      skip: !props.functionId
    }
  );
  // First case is initial load when functionId present. Second is initial load in new config
  if ((props.functionId && !data) || (!props.functionId && loading))
    return <Skeleton title={false} paragraph={{ rows: 1 }} active />;

  const func = data?.function;
  return (
    <FunctionPicker
      {...props}
      selectedFunction={func}
      selectedDataSource={func?.dataSource}
      onChange={func => {
        if (func === null) {
          onChange(null);
          return;
        }
        // NOTE: Construct and write a query for the selected FunctionNode
        //       to keep ui in sync without needing to re-query api
        //       and go into unneeded loading states.
        client.writeQuery({
          query: SELECTED_FUNCTION_QUERY,
          data: { function: func },
          variables: { id: func.id }
        });
        onChange(func.id);
      }}
    />
  );
}
