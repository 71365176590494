import {
  InputParameter as _InputParameter,
  InputParameterOptions as _InputParameterOptions
} from "./types";
import { Result as _Result } from "./useFuncParams";
export { default } from "./useFuncParams";
export { ParameterType } from "./types";
export { isGeneratedParameter } from "./util";

export type InputParameter = _InputParameter;
export type InputParameterOptions = _InputParameterOptions;
export type Result = _Result;
