import { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { Cursor, CursorType, StatusCode } from "../../../../../types";
import {
  FetchFunctionByIdData,
  FetchFunctionByIdVars
} from "../../../../common/FunctionEditor/useFunctionEditor/queries";
import { RemoteFunctionCallResult } from "../../../../spaces/FunctionExecutor/FunctionExecutor";
import { getRecordIdentifier } from "../util";

import {
  EXECUTE_FUNCTION,
  FETCH_FUNCTION_BY_ID,
  FunctionCallVariables,
  FunctionNode
} from "./queries";

export interface Result {
  loading: boolean;
  errors: any[];
  dataAccessor: FunctionNode | undefined;
  records: any[];
}

interface DataError {
  code: StatusCode;
  message: string;
}

const PAGE_SIZE = 10;

export default function useRecordData(cursor: Cursor): Result {
  const [loading, setLoading] = useState(true);
  const [dataAccessor, setDataAccessor] = useState<FunctionNode | undefined>(undefined);
  const [records, setRecords] = useState<any[]>([]);
  const [errors, setErrors] = useState<DataError[]>([]);
  const { slug, key } = getRecordIdentifier(cursor);

  const [executeMutation, { loading: functionExecuting }] = useMutation<
    RemoteFunctionCallResult,
    FunctionCallVariables
  >(EXECUTE_FUNCTION, {
    onError: e => {
      setLoading(false);
      setErrors([{ code: StatusCode.UNKNOWN, message: e.message }]);
      setRecords([]);
    },
    onCompleted: data => {
      setLoading(false);

      switch (data.executeFunction.__typename) {
        case "ExecuteFunctionResultSuccess":
          setRecords(
            _.isArray(data.executeFunction.value)
              ? data.executeFunction.value
              : [data.executeFunction.value]
          );
          break;
        case "ClientErrorResult":
        case "PermissionErrorResult":
          setErrors([
            {
              code: StatusCode.PERMISSION_DENIED,
              message: data.executeFunction.message
            }
          ]);
          setRecords([]);
          break;
        default:
          throw new Error("unrecognized typename");
      }
    }
  });

  const { loading: functionQueryLoading } = useQuery<
    FetchFunctionByIdData<FunctionNode>,
    FetchFunctionByIdVars
  >(FETCH_FUNCTION_BY_ID, {
    skip: cursor.type === CursorType.RESOURCE,
    variables: { id: slug },
    onError: e => {
      setLoading(false);
      setErrors([{ code: StatusCode.UNKNOWN, message: e.message }]);
      setRecords([]);
    },
    onCompleted: data => {
      setDataAccessor(data.node);
      setRecords([]);
      executeMutation({
        variables: {
          functionId: slug,
          parameters: { ...key, pageSize: PAGE_SIZE }
        }
      });
    }
  });

  // This is required because data loading is a two step process. It is possible to get into a state
  // where the data accessor has loaded its definition before the call to retrieve data has finished.
  // Because of this, we use a separate state variable to track the loading state across both steps.
  // The loading state is only reset when there is an error in one of the steps or if the data accessor
  // has finished loading data.
  useEffect(() => {
    if (functionQueryLoading || functionExecuting) {
      setLoading(true);
    }
  }, [functionQueryLoading, functionExecuting]);

  return {
    loading,
    errors,
    dataAccessor,
    records
  };
}
