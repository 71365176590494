/**
 * JSON Input component in a space.
 * --
 * UI notes:
 * This can be found:
 * - as a root component in a space in view or edit mode,
 * - as a column in table,
 * - as a form field, e.g. for a custom HTTP function with a ${json} param
 *   with data type JSON for the input.
 */
import React, { useMemo } from "react";

import styled from "styled-components";

import { ErrorValue, ErrorValues } from "../../../../../constants";
import Editor from "../../../../common/Editor";
import useDebouncedValue from "../../../../common/hooks/useDebouncedValue";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import { INPUT_MIN_WIDTH } from "../../../layout/constants";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDefaultValue from "../common/useDefaultValue";
import useNestedStatus from "../common/useNestedStatus";
import useOutputSyncing from "../common/useOutputSyncing";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

export const GUTTER_WIDTH = 41;

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 96px;
  min-width: ${INPUT_MIN_WIDTH + GUTTER_WIDTH}px;
`;

// expect jsonValue to be valid json or undefined.
const getStringifiedValue = (jsonValue: any) => {
  if (jsonValue === ErrorValues.permissionDenied) {
    return jsonValue;
  }
  const stringified = JSON.stringify(jsonValue);
  try {
    // check if value is valid json
    JSON.parse(stringified);
    return stringified;
  } catch (e) {
    // JSON.parse fails when jsonValue is undefined
    return undefined;
  }
};

export default function SpaceJsonInput({ hasConfigError, layoutConstraints }: Props) {
  const { editMode } = useStableSpaceContext();
  const { isNested } = useNestedStatus();
  const { output } = useComponentStateContext();
  const { defaultValue } = useDefaultValue();
  const [localValue, setLocalValue] = React.useState<string | undefined | ErrorValue>(
    getStringifiedValue(defaultValue)
  );

  const hasPermissionError = React.useMemo(() => {
    return localValue === ErrorValues.permissionDenied;
  }, [localValue]);

  const editorStyle = useMemo(() => {
    return layoutConstraints
      ? {
          maxWidth: `${layoutConstraints.width}px`,
          maxHeight: `${layoutConstraints.height}px`
        }
      : undefined;
  }, [layoutConstraints]);

  React.useEffect(() => {
    setLocalValue(getStringifiedValue(defaultValue));
  }, [defaultValue]);

  React.useEffect(() => {
    if (output !== null) return;
    setLocalValue(getStringifiedValue(defaultValue));
  }, [output, defaultValue]);

  const debouncedValue = useDebouncedValue(localValue, 300);
  const { errorMessage } = useOutputSyncing(debouncedValue);

  return (
    <EditModeInteractionBlocker>
      <Root>
        <FlexContainer>
          {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
          <Editor
            mode="application/json"
            value={
              hasPermissionError || localValue === undefined || localValue === null
                ? ""
                : (localValue as string)
            }
            onChange={value => setLocalValue(value)}
            isFullHeight={!isNested}
            style={editorStyle}
          />
          {hasPermissionError && <PermissionWarningIcon />}
        </FlexContainer>

        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </Root>
    </EditModeInteractionBlocker>
  );
}
