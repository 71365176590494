import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

import { INPUT_PARAMETER_FRAGMENT } from "../../../../queries/common";

import { TaskNode } from "./AllTasks";

export const TASK_QUERY = gql`
  query Task($id: ID!) {
    task(id: $id) {
      id
      createdAt
      updatedAt
      transitionedAt
      title
      requester {
        id
        name
      }
      queue {
        id
        slug
        name
      }
      assignee {
        id
        type
        name
      }
      state {
        id
        name
        color
        fromTransitions {
          id
          name
          isPrimary
          functionId
          inputParameters {
            ...InputParameterFragment
          }
        }
      }
      fieldValues {
        name
        value
        type
      }
    }
  }
  ${INPUT_PARAMETER_FRAGMENT}
`;

export interface TaskVariables {
  id: string;
}

export interface TaskData {
  task: TaskNode;
}

export const useTask = (options: QueryHookOptions<TaskData, TaskVariables>) => {
  return useQuery<TaskData, TaskVariables>(TASK_QUERY, options);
};
