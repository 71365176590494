import { APIFiltersOption, ViewFilter } from "../../../../../../types";

export const filter = (
  current: Partial<ViewFilter>,
  used: Partial<ViewFilter>[],
  filtersOptions: APIFiltersOption[]
) =>
  filtersOptions.reduce<APIFiltersOption[]>(
    (acc, o) =>
      current.sourceName === o.sourceName ||
      !used.some(f => f.sourceName === o.sourceName)
        ? [...acc, o]
        : acc,
    []
  );
