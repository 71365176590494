import { useCallback, useContext, useEffect } from "react";

import { Modal } from "antd";
import { ModalFuncProps } from "antd/lib/modal";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

/*
NOTE: usePrompt uses unsafe react-router APIs and may break with minor version upgrades.
As of react-router version 6.2.1 this works and there is no other alternative to using
the private/hidden navigator.block API.
 */

// Source: https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
export function useBlocker(blocker: any, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    // unsafe API call here
    const unblock = (navigator as any).block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        }
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

// Heavily inspired from usePrompt here: https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
const usePrompt = ({ onOk, ...props }: ModalFuncProps, when: boolean) => {
  const blocker = useCallback(
    tx => {
      Modal.confirm({
        ...props,
        onOk: () => {
          tx.retry();
          onOk && onOk();
        }
      });
    },
    [onOk, props]
  );

  useBlocker(blocker, when);
};

export default usePrompt;
