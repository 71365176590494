import React from "react";

import { Radio, Select } from "antd";
import styled from "styled-components";

import { APISortByOption } from "../../../../../../types";
import {
  ParameterDataValue,
  ReservedListParameter,
  ReservedListParameterDefault
} from "../../../index";
import * as common from "../common/styledComponents";

export interface Props {
  sortAscending: boolean | undefined;
  sortBy: string | undefined;
  sortByOptions: APISortByOption[];
  onParameterValueChange?: (name: string, value: ParameterDataValue) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  margin-bottom: ${props => props.theme.spacersm};
`;

const StyledRadioGroup = styled(Radio.Group)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const SortByForm = ({
  sortAscending,
  sortBy,
  sortByOptions,
  onParameterValueChange = () => {}
}: Props) => {
  const field = sortBy || ReservedListParameterDefault[ReservedListParameter.SORT_BY];
  const ascending =
    sortAscending !== undefined
      ? sortAscending
      : ReservedListParameterDefault[ReservedListParameter.SORT_ASCENDING];

  React.useEffect(() => {
    // validate that sortBy is still valid, otherwise reset to undefined
    if (sortBy && !sortByOptions.includes(sortBy)) {
      onParameterValueChange(ReservedListParameter.SORT_BY, undefined);
    }
  }, [onParameterValueChange, sortBy, sortByOptions]);

  return (
    <Container>
      <Label>Sort By</Label>
      <common.Select
        placeholder="Select a field"
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={field}
        onChange={value =>
          onParameterValueChange(ReservedListParameter.SORT_BY, value as string)
        }
      >
        {sortByOptions
          .filter(name => !!name)
          .map(name => (
            <Select.Option key={name}>{name}</Select.Option>
          ))}
      </common.Select>
      <Label>Sort Direction</Label>
      <StyledRadioGroup
        value={ascending}
        onChange={evt =>
          onParameterValueChange(ReservedListParameter.SORT_ASCENDING, evt.target.value)
        }
      >
        <Radio value={true}>Ascending</Radio>
        <Radio value={false}>Descending</Radio>
      </StyledRadioGroup>
    </Container>
  );
};
