import React from "react";

import useStableId from "../../../../../common/hooks/useStableId";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { ConfigSection, Field } from "../ConfigPanel";
import { Select } from "../ConfigPanel/styledComponents";
import useS3DataSources from "../useS3DataSources";

import * as styled from "./styledComponents";

interface Props {
  title: string;
}

export function AuthenticationConfigSection({ title }: Props) {
  const { dispatch, state } = useComponentConfigContext();
  const { s3DataSources } = useS3DataSources();
  const { draftComponent } = state;
  const domId = useStableId("authenticationConfigSection");
  const { authentication } = draftComponent.properties;

  return !s3DataSources || !Object.keys(s3DataSources).length ? null : (
    <ConfigSection id={domId} title={title}>
      <Field>
        <Select
          getPopupContainer={() => document.getElementById(domId) as HTMLElement}
          value={authentication?.function_id || "NONE"}
          onChange={val => {
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.authentication",
                value: val !== "NONE" ? { type: "s3.presign", function_id: val } : null
              }
            });
          }}
        >
          {Object.keys(s3DataSources).map((key: string) => {
            const ds = s3DataSources[key];
            return (
              <styled.Option
                key={ds.presignId}
              >{`${ds.name}'s credentials`}</styled.Option>
            );
          })}
          <styled.Option key="NONE">Do not sign</styled.Option>
        </Select>
      </Field>
    </ConfigSection>
  );
}
