import React from "react";

export default function (objects: string[]) {
  switch (objects.length) {
    case 0:
      return "";
    case 1:
      return objects[0];
    case 2:
      return objects.join(" and ");
    case 3:
    default:
      const clone = [...objects];
      const lastItem = clone.splice(clone.length - 1, 1);
      return `${clone.join(", ")} and ${lastItem}`;
  }
}

// humanizeCollection returns a new array with separator elements added
// e.g. (a simplified version) ["a", "b", "c"] => ["a", ", ", "b", " and ", "c"]
export function humanizeCollection(
  objects: React.ReactNode[],
  wrapper: (separator: string, index: number) => React.ReactNode
) {
  switch (objects.length) {
    case 0:
      return [];
    case 1:
      return objects;
    case 2:
      return [objects[0], wrapper(" and ", 0), objects[1]];
    default:
      return objects.flatMap((value, index) => {
        if (index === objects.length - 1) {
          return [value];
        }

        if (index === objects.length - 2) {
          return [value, wrapper(" and ", index)];
        }

        return [value, wrapper(", ", index)];
      });
  }
}
