import React from "react";

import { Col, Row, Button } from "antd";
import styled from "styled-components";

import { GoogleLogoPng } from "../../../../../constants";
import { ProviderProps } from "../SingleSignOnDrawer";

const GoogleSignUpRow = styled(Row)`
  button {
    height: 40px;
    border-radius: ${props => props.theme.borderRadiussm};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;

    span:before {
      content: "";
      background-image: url(${GoogleLogoPng});
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position-y: center;
      padding-right: 18px;
      margin-right: 17px;
    }
  }
`;

const GoogleSSOProvider = ({ connection, redirect }: ProviderProps) => {
  return (
    <Col>
      <Row>
        Before requiring SSO for all employees, you must verify your own account with
        Google.
      </Row>
      <GoogleSignUpRow>
        <a href={`/api/sso/signup/google-oauth2?next=${redirect}`}>
          <Button disabled={connection.state !== "active"}>Sign in with Google</Button>
        </a>
      </GoogleSignUpRow>
    </Col>
  );
};

export default GoogleSSOProvider;
