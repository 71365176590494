import React, { useEffect, useState } from "react";

import { Button } from "antd";
import * as codemirror from "codemirror";
import { Controlled as CodeMirror } from "react-codemirror2";
import styled from "styled-components";

import { BindingCascader, Option } from "../BindingCascader";
import SingleLineEditor, { Mode } from "../SingleLineEditor";
import withDebouncedValue from "../withDebouncedValue";

export enum Height {
  Small = "30px",
  Medium = "60px",
  Large = "90px"
}

export interface TemplateEditorProps {
  value: string;
  bindingOptions: Option[];
  placeholder?: string;
  minHeight?: Height;
  dataTest?: string;
  mode?: Mode;
  onChange: (s: string) => void;
  onFocus?: (evt: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLTextAreaElement>) => void;
  popupContainerFactory?: (
    domId?: string | undefined
  ) => ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}

export const TemplateBindings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    padding: 0px;
  }
`;

const InsertButton = styled(Button)`
  max-width: 100px;
`;

const getStrippedValue = (value: string) => {
  if (value.startsWith("`") && value.endsWith("`")) {
    return value.substring(1, value.length - 1);
  }
  return value;
};

export default function TemplateEditor({
  value,
  bindingOptions,
  placeholder = "",
  minHeight = Height.Small,
  mode = "jstl",
  dataTest,
  onChange,
  popupContainerFactory,
  onFocus = () => {},
  onBlur = () => {}
}: TemplateEditorProps) {
  const editorRef = React.useRef<CodeMirror>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [showCascader, setShowCascader] = useState(false);

  const setFocusOnInput = React.useCallback(
    cursorIdx => {
      const editor = (editorRef.current as any)?.editor as codemirror.Editor;
      if (cursorIdx === null || !editor) return;
      editor.setSelection({ line: 0, ch: cursorIdx });
      editor.focus();
    },
    [editorRef]
  );

  const onInsertVariable = React.useCallback(() => {
    onFocus(new Event("focus") as any);
    const editor = (editorRef.current as any)?.editor as codemirror.Editor;
    // when "Insert a variable" link is clicked, save cursor position
    setCursorPosition(
      typeof editor?.getCursor().ch === "number" ? editor?.getCursor().ch : value.length
    );
  }, [editorRef, value, onFocus]);

  // set focus after inserting variable
  useEffect(() => {
    setFocusOnInput(cursorPosition);
  }, [setFocusOnInput, cursorPosition]);

  // NOTE: Templates for nullable component properties may be null.
  // E.g. The `text_template` of a `SpaceLink` is optional and will
  // be set as null when persisted as an empty string.
  if (value === undefined || value === null) {
    value = "``";
  }

  return (
    <div data-test={dataTest}>
      <SingleLineEditor
        ref={editorRef}
        value={value}
        mode={mode}
        minHeight={minHeight}
        placeholder={placeholder}
        hidePopover
        showError
        onChange={value => onChange(value)}
        onFocus={evt => {
          onFocus(evt);
        }}
        onBlur={onBlur}
      />
      <TemplateBindings>
        <BindingCascader
          options={bindingOptions}
          value=""
          popupVisible={showCascader}
          popupContainerFactory={popupContainerFactory}
          onChange={(path: string) => {
            onFocus(new Event("focus") as any);
            if (mode === "jstl") {
              // get value without wrapping backticks so that cursorPosition is accurate
              // (the value without wrapping backticks is what's rendered in the editor)
              const strippedValue = getStrippedValue(value);
              const start = `${strippedValue.slice(0, cursorPosition!)}$\{${path}}`;
              onChange(`\`${start}${strippedValue.slice(cursorPosition!)}\``);
              setCursorPosition(start.length);
            } else if (mode === "javascript") {
              const start = `${value.slice(0, cursorPosition!)}${path}`;
              onChange(`${start}${value.slice(cursorPosition!)}`);
              setCursorPosition(start.length);
            }
          }}
          onPopupVisibleChange={(value: boolean) => {
            setShowCascader(value);
          }}
        >
          <InsertButton onClick={onInsertVariable} type="link">
            Insert a variable
          </InsertButton>
        </BindingCascader>
      </TemplateBindings>
    </div>
  );
}

export const DebouncedTemplateEditor = withDebouncedValue(TemplateEditor);
