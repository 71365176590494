import React from "react";

import useAuthUser from "../../../../common/hooks/useAuthUser";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { getRoleForOutput } from "./util";

export default function SpaceUser() {
  const { updateOutput } = useComponentStateContext();
  const authUserResult = useAuthUser();
  const email = authUserResult?.authUser?.email;

  React.useEffect(() => {
    updateOutput({ email, role: getRoleForOutput(authUserResult?.authUser) });
  }, [updateOutput, email, authUserResult]);

  return null;
}
