import React from "react";

import usePrevious from "../../../../../common/hooks/usePrevious";
import { SpaceImageComponent } from "../../SpaceImage/types";
import { SpaceViewlessImageComponent } from "../../SpaceViewlessImage/types";

export default function useTrackImageErrors(
  component: SpaceImageComponent | SpaceViewlessImageComponent
) {
  const [imageErrored, setImageErrored] = React.useState(false);

  const handleImageError = React.useCallback(
    function handleImageError() {
      if (imageErrored) return;
      setImageErrored(true);
    },
    [imageErrored, setImageErrored]
  );

  const templateChanged =
    component.properties.template !== usePrevious(component.properties.template);
  React.useEffect(() => {
    if (!templateChanged || !imageErrored) return;
    setImageErrored(false);
  }, [templateChanged, imageErrored, setImageErrored]);

  return {
    imageErrored,
    handleImageError
  };
}
