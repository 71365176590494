import { DataProxy } from "apollo-cache";
import gql from "graphql-tag";

import {
  Connection,
  DataSourceProviderNode,
  Edge,
  FunctionAttributeNode,
  FunctionNodeBasic,
  FunctionParameterNode
} from "../../../../../../types";
import { DataSourceNodeWithFunctions } from "../../../../../common/FunctionEditor/forms/types";
import { updateCache } from "../../../../../util/updateCache";

export interface FetchDataProvidersAndUserDefinedFunctionsData {
  allDataSourceProviders: Connection<
    DataSourceProviderNode<DataSourceNodeWithFunctions<FunctionNodeBasic>>
  >;
}

export const FETCH_DATA_PROVIDERS_AND_USER_DEFINED_FUNCTIONS = gql`
  query FetchDataProvidersAndUserDefinedFunctions {
    allDataSourceProviders {
      edges {
        node {
          id
          adapter
          dataSources {
            edges {
              node {
                id
                name
                credentials
                integration
                functions(isUserGenerated: true) {
                  edges {
                    node {
                      id
                      title
                      name
                      isUserGenerated
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface CacheUpdaterFunctionNode extends FunctionNodeBasic {
  dataSource: {
    id: string;
  };
  functionParameters?: Connection<FunctionParameterNode>;
  functionAttributes?: Connection<FunctionAttributeNode>;
}

const _findDataSourceNodeOrThrow = (
  dspConnection: Connection<
    DataSourceProviderNode<DataSourceNodeWithFunctions<FunctionNodeBasic>>
  >,
  dataSourceId: string
): DataSourceNodeWithFunctions<FunctionNodeBasic> => {
  for (let i = 0; i < dspConnection.edges.length; ++i) {
    const dspe = dspConnection.edges[i];
    const dse = dspe.node.dataSources?.edges.find(dse => dse.node.id === dataSourceId);
    if (dse) {
      return dse.node;
    }
  }
  throw new Error(`expected data source to exist: ${dataSourceId}`);
};

const _findFunction = (
  dspConnection: Connection<
    DataSourceProviderNode<DataSourceNodeWithFunctions<FunctionNodeBasic>>
  >,
  functionId: string
): [FunctionNodeBasic | undefined, Edge<FunctionNodeBasic>[], number] => {
  for (let i = 0; i < dspConnection.edges.length; ++i) {
    const dataSourceEdges = dspConnection.edges[i].node.dataSources?.edges || [];
    for (let j = 0; j < dataSourceEdges.length; ++j) {
      const functionEdges = dataSourceEdges[j].node.functions.edges || [];
      for (let k = 0; k < functionEdges.length; ++k) {
        if (functionEdges[k].node.id === functionId) {
          return [functionEdges[k].node, functionEdges, k];
        }
      }
    }
  }
  return [undefined, [], -1];
};

export const addOrUpdateFunctionCacheUpdater = (
  cache: DataProxy,
  func: CacheUpdaterFunctionNode
) =>
  updateCache<FetchDataProvidersAndUserDefinedFunctionsData>(
    { query: FETCH_DATA_PROVIDERS_AND_USER_DEFINED_FUNCTIONS },
    cache,
    payload => {
      const dataSource = _findDataSourceNodeOrThrow(
        payload.allDataSourceProviders,
        func.dataSource.id
      );
      const functionEdges = dataSource.functions.edges;
      const functionIndex = functionEdges.findIndex(fe => fe.node.id === func.id);
      if (functionIndex > -1) {
        functionEdges.splice(functionIndex, 1);
      }
      const insertIndex = functionEdges.findIndex(
        e => func.title.localeCompare(e.node.title) < 1
      );
      const newEdge = { node: func, __typename: "FunctionNodeEdge" };
      if (insertIndex > -1) {
        functionEdges.splice(insertIndex, 0, newEdge);
      } else {
        functionEdges.push(newEdge);
      }

      return payload;
    }
  );

export const deleteFunctionCacheUpdater = (cache: DataProxy, functionId: string) => {
  updateCache<FetchDataProvidersAndUserDefinedFunctionsData>(
    { query: FETCH_DATA_PROVIDERS_AND_USER_DEFINED_FUNCTIONS },
    cache,
    payload => {
      const [_, functionEdges, index] = _findFunction(
        payload.allDataSourceProviders,
        functionId
      );
      if (index > -1) {
        functionEdges.splice(index, 1);
      }
      return payload;
    }
  );
};
