import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import HeaderConfigSection from "../../common/HeaderConfig";
import ParametersConfigSection from "../../common/ParametersManager";
import TextConfigSection from "../../common/TextConfigSection";
import useViewConfig from "../../common/useViewConfig";
import { hasSource } from "../../common/util";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import BasicConfig from "./BasicConfig";
import FieldsConfigSection from "./FieldsConfig";
import FiltersConfigSection from "./FiltersConfig";
import { ensureDetailConfigState } from "./reducer/reducer";

export default function SpaceDetailConfig(props: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={React.useMemo(
        () => (
          <SpaceDetailConfigTabs />
        ),
        []
      )}
      designConfig={<DesignConfig />}
      slug={props.slug}
    />
  );
}

function SpaceDetailConfigTabs() {
  const context = useComponentConfigContext();
  const state = ensureDetailConfigState(context.state);
  useViewConfig({
    state: context.state,
    dispatch: context.dispatch
  });

  return (
    <div>
      <ConfigSection title="Source">
        <BasicConfig />
      </ConfigSection>
      {hasSource(context.state.draftComponent) && (
        <>
          <TextConfigSection />
          <ParametersConfigSection title="Parameters" />
          <FieldsConfigSection title="Fields" state={state} />
          <FiltersConfigSection title="Filters" state={state} />
          <VisibilityRulesManagerSection />
        </>
      )}
    </div>
  );
}

function DesignConfig() {
  return <HeaderConfigSection />;
}
