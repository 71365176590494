import { SpaceComponentObject } from "../../../../../../types";
import {
  isComponentColumn,
  isAttributeColumn,
  Column as ColumnConfig
} from "../ColumnListManager";

import { Row, Column } from ".";

export default function insertComponentColumns(
  component: SpaceComponentObject,
  rows: Row[] | null = []
): Row[] {
  if (!component.properties.columns || !rows) return rows || [];

  return rows.map(r => {
    const columns = component.properties.columns
      .map((c: ColumnConfig) => {
        if (isComponentColumn(c)) {
          const childComponent = component.componentTreeNodes.find(
            sc => sc.slug === c.component_slug
          );
          if (!childComponent) {
            console.warn(
              `Component with slug ${c.component_slug} was not found in componentTreeNodes`
            );
            return null;
          }
          return [
            c.column_type,
            c,
            component.componentTreeNodes.find(sc => sc.slug === c.component_slug)
          ];
        } else if (isAttributeColumn(c)) {
          const attributeColumn = r.columns.find(([, col]) => col === c);
          if (!attributeColumn) {
            console.warn(
              `Could not find attribute columns for attribute ${c.attribute}.`
            );
            return null;
          }
          return attributeColumn;
        }
        throw new Error("Unexpected column type");
      })
      .filter((c: Column | null) => c !== null);
    return {
      ...r,
      columns
    };
  });
}
