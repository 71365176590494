import React from "react";

import { useComponentConfigContext } from "..";
import { useSpaceConfigContext } from "../../../../../SpaceConfig/SpaceConfigContext";
import { ErrorField } from "../../ConfigPanel/styledComponents";

interface Props {
  field: string;
  index?: number;
  alwaysRender?: boolean;
}

export default function ValidationError({ field, index, alwaysRender }: Props) {
  const { errors } = useComponentConfigContext();
  const { state: rootConfigState } = useSpaceConfigContext();
  if (!rootConfigState.submitted && !alwaysRender) return null;

  const error =
    typeof index === "number"
      ? errors.find(e => e.field === field && e.index === index)
      : errors.find(e => e.field === field);

  if (!error) return null;

  return <ErrorField>{error.message}</ErrorField>;
}
