import React from "react";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

import { publicClient } from "../../graphql";
import Button from "../common/Button";
import Message from "../common/Message";
import { DarkTheme } from "../common/StyledComponents";
import TitleContainer, { ContainerSize } from "../common/TitleContainer";

const REQUEST_INVITE_MUTATION = gql`
  mutation RequestInvite($email: String!) {
    requestInvite(email: $email) {
      ok
    }
  }
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;

  button:first-child {
    margin-bottom: 12px;
  }
`;

interface Props {
  email: string;
  onComplete: () => void;
}

const RequestInvite = (props: Props) => {
  const { email, onComplete } = props;

  const [requestInvite, { loading: submitting }] = useMutation(
    REQUEST_INVITE_MUTATION,
    {
      client: publicClient,
      variables: { email },
      onCompleted: () => {
        onComplete();
        Message.success(
          "You'll get an invite to Internal once your company admin approves your request."
        );
      }
    }
  );

  const title = (
    <span>
      Good news!
      <br />
      Your company has an Internal account.
    </span>
  );

  const handleClick = () => {
    requestInvite();
  };

  const domain = email.split("@")[1];
  return (
    <React.Fragment>
      <DarkTheme />

      <TitleContainer
        title={title}
        description={`${domain} already has an Internal account. Do you want to request access?`}
        size={ContainerSize.Large}
      >
        <ButtonWrapper>
          <Button
            onClick={handleClick}
            type="primary"
            size="medium"
            loading={submitting}
          >
            Request Access
          </Button>
          <Button type="link" onClick={onComplete}>
            Go Back
          </Button>
        </ButtonWrapper>
      </TitleContainer>
    </React.Fragment>
  );
};

export default RequestInvite;
