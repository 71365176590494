import React, { useState, MouseEventHandler } from "react";

import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import PaymentIcon from "react-payment-icons";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { H4, H3 } from "../../../components/common/StyledComponents";
import { GET_SUBSCRIPTION_DATA, GET_ALL_USERS_DATA } from "../../../graphql/queries";
import PageHeader from "../../../layouts/Header/Header";
import { Price, Edge, UserNode } from "../../../types";
import { planName } from "../../util/billing";
import { isInternalEmail } from "../../util/users";

import { BillingContext } from "./BillingContext";
import { OrderSummary } from "./checkout/OrderSummary";
import { useStripeCustomer } from "./hooks/useStripeCustomer";
import PlanPicker from "./PlanPicker";
import UpdateBillingInfoModal from "./UpdateBillingInfoModal";
import UpdatePaymentModal from "./UpdatePaymentModal";

export const UPDATE_SUBSCRIPTION_MUTATION = gql`
  mutation UpdateSubscription(
    $newProductSlug: String!
    $newProductTerm: String!
    $quantity: Int!
  ) {
    updateSubscription(
      newProductSlug: $newProductSlug
      newProductTerm: $newProductTerm
      quantity: $quantity
    ) {
      subscription
    }
  }
`;

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      ok
    }
  }
`;

export const GET_PRICE_ESTIMATE = gql`
  query PriceEstimate($slug: String!, $term: String!) {
    getPriceEstimate(slug: $slug, term: $term) {
      total
      proration
      periodEnd
    }
  }
`;

const PlanFormContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SwitchHeader = styled(H3)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const PlanForm = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;

  .title {
    span {
      text-transform: capitalize;
    }
    margin-bottom: 40px;
  }
  .body {
    display: flex;

    .plan-picker {
      display: flex;
      justify-content: space-between;

      .plan {
        background-color: ${props => props.theme.cardBackgroundColor};
        border: 1px solid ${props => props.theme.borderGrey};
        border-radius: 10px;
        padding: 20px;
        pointer: cursor !important;
        width: 100%;
        position: relative;

        input[type="radio"] {
          appearance: none;
          background-color: #fff;
          margin: 0;
          font: inherit;
          color: red;
          width: 30px;
          height: 30px;
          border: 0.15em solid ${props => props.theme.primaryColor};
          border-radius: 50%;
          display: grid;
          place-content: center;
          position: absolute;
          right: 20px;
          top: calc(50% - 15px);
        }

        input[type="radio"]::before {
          content: "";
          width: 20px;
          height: 20px;
          border-radius: 50%;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em ${props => props.theme.primaryColor};
        }

        input[type="radio"]:checked::before {
          transform: scale(1);
        }
      }
    }
  }
`;

const PaymentDetails = styled.div`
  width: 60%;
  border-right: 1px solid #cccccc;
  padding: 0 40px 40px 0;

  h4 {
    a {
      font-size: 14px;
      float: right;
    }
  }
`;

const CreditCardIconSmall = styled(PaymentIcon)`
  width: 30px;
  margin-right: 10px;
  margin-bottom: 4px;
`;

const PlanChangeConfirmation = ({
  paymentTerm,
  setPaymentTerm,
  currentPlanSlug,
  newPlanSlug,
  loadingSubscription,
  priceData
}: {
  paymentTerm: string | undefined;
  setPaymentTerm: React.Dispatch<React.SetStateAction<any>>;
  currentPlanSlug: string | undefined;
  newPlanSlug: string | undefined;
  loadingSubscription: boolean;
  priceData: {
    getAllProductPrices: {
      data: Price[];
    };
  };
}) => {
  const [formValidation, setFormValidation] = useState(false);

  const { setCompanyInfo, contextValue } = useStripeCustomer();

  const [showCardUpdateModal, setShowCardUpdateModal] = useState(false);
  const [showBillingInfoUpdateModal, setShowBillingInfoUpdateModal] = useState(false);
  const navigate = useNavigate();

  const { data: subscriptionData, loading: subscriptionLoading } = useQuery(
    GET_SUBSCRIPTION_DATA,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (typeof subscription !== "undefined") {
          setCompanyInfo(subscription);
        }
      }
    }
  );

  const { data: priceEstimate } = useQuery(GET_PRICE_ESTIMATE, {
    fetchPolicy: "cache-and-network",
    variables: {
      slug: newPlanSlug,
      term: paymentTerm
    }
  });

  const priceSelectionData: { monthly?: number; annual?: number } = {};

  if (priceData) {
    priceData.getAllProductPrices.data.forEach((price: Price) => {
      const key = price.recurring.interval === "month" ? "monthly" : "annual";
      priceSelectionData[key] = price.unit_amount / 100;
    });
  }

  const { data: usersData } = useQuery(GET_ALL_USERS_DATA, {
    fetchPolicy: "cache-and-network"
  });
  const users = usersData?.allUsers?.edges.map((e: Edge<UserNode>) => e.node) || [];
  const activeUsers = users.filter((user: UserNode) => {
    return user.status === "active" && !isInternalEmail(user.email);
  });

  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION_MUTATION);
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_MUTATION);

  const handlePlanClick = (termVal: string) => {
    if (termVal) {
      setPaymentTerm(termVal);
    }
  };

  const handleOrderSubmit: MouseEventHandler<HTMLElement> = async (e: any) => {
    e.preventDefault();

    if (newPlanSlug === "basic") {
      cancelSubscription().then(() => navigate("/settings/billing"));
    } else {
      updateSubscription({
        variables: {
          newProductSlug: newPlanSlug,
          newProductTerm: paymentTerm,
          quantity: activeUsers.length || 1
        }
      }).then(() => {
        navigate("/settings/change-plan/confirmation");
      });
    }
  };

  const updatePayment = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowCardUpdateModal(true);
  };

  const updateBillingInfo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowBillingInfoUpdateModal(true);
  };

  const subscription = subscriptionData?.stripeSubscription?.subscription;

  return (
    <BillingContext.Provider value={contextValue}>
      <PlanFormContainer>
        <PageHeader title="Checkout" />
        <PlanForm>
          <div className="title">
            <Link to="/settings/change-plan">Cancel</Link>
            <SwitchHeader>
              <span>
                Switch to {planName(newPlanSlug)} ({paymentTerm})
              </span>
            </SwitchHeader>
            <small>
              Current plan: {planName(currentPlanSlug)} (
              {!subscriptionLoading &&
                (subscription?.items.data[0].price.recurring.interval === "month"
                  ? "Monthly"
                  : "Annual")}
              )
            </small>
          </div>
          <div className="body">
            <PaymentDetails>
              <PlanPicker
                onClick={handlePlanClick}
                priceData={priceSelectionData}
                paymentTerm={paymentTerm}
              />

              <hr />

              <H4>
                Company Information{" "}
                <a href="/settings/billing" onClick={updateBillingInfo}>
                  Edit details
                </a>
              </H4>

              {!subscriptionLoading && (
                <>
                  <p>{subscription?.customer?.name}</p>
                  <p>{subscription?.customer?.address?.line1}</p>
                  <p>
                    {subscription?.customer?.address?.city},
                    {subscription?.customer?.address?.state} &nbsp;
                    {subscription?.customer?.address?.country} &nbsp;
                    {subscription?.customer?.address?.postal_code}
                  </p>
                  <p>
                    <br />
                  </p>

                  {subscription?.customer?.tax_ids?.data[0] && (
                    <p>
                      Tax ID: {subscription?.customer?.tax_ids?.data[0].value} (
                      {subscription?.customer?.tax_ids?.data[0].country})
                    </p>
                  )}
                </>
              )}

              <hr />

              <H4>
                Payment Information{" "}
                <a href="/settings/billing" onClick={updatePayment}>
                  Update payment info
                </a>
              </H4>

              {!subscriptionLoading && (
                <>
                  <CreditCardIconSmall
                    id={subscription?.default_payment_method?.card?.brand}
                  />
                  **** **** **** {subscription?.default_payment_method?.card?.last4}
                </>
              )}
            </PaymentDetails>

            <OrderSummary
              loadingSubscription={loadingSubscription}
              submitDisabled={false}
              currentPlanSlug={currentPlanSlug}
              newPlanSlug={newPlanSlug}
              paymentTerm={paymentTerm}
              priceData={priceData}
              subscription={subscription}
              total={priceEstimate?.getPriceEstimate?.total}
              proration={priceEstimate?.getPriceEstimate?.proration}
              handleOrderSubmit={handleOrderSubmit}
              subscriptionPeriodEnd={priceEstimate?.getPriceEstimate?.periodEnd}
            />
          </div>
        </PlanForm>
        <UpdatePaymentModal
          visible={showCardUpdateModal}
          onCancel={() => setShowCardUpdateModal(false)}
        />
        <UpdateBillingInfoModal
          visible={showBillingInfoUpdateModal}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          onCancel={() => setShowBillingInfoUpdateModal(false)}
        />
      </PlanFormContainer>
    </BillingContext.Provider>
  );
};

export default PlanChangeConfirmation;
