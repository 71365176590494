import { Icon, Menu } from "antd";
import styled from "styled-components";

import { colors } from "../../../../../../cssConstants";
import { default as BaseAvatar } from "../../../../../common/Avatar/Avatar";
import PerspectiveTimestamp from "../../../../../common/PerspectiveTimestamp/PerspectiveTimestamp";

export const Avatar = styled(BaseAvatar)`
  margin-right: 16px;
`;

export const CommentBy = styled.div`
  color: ${colors.surfacePrimary};
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
`;

export const Timestamp = styled(PerspectiveTimestamp)`
  margin-left: 8px;
  flex-grow: 1;

  color: ${colors.surfaceTertiary};
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div<{ disabled?: boolean }>`
  opacity: ${props => (!!props.disabled ? "0.6" : "1.0")};
`;

export const Content = styled.p`
  color: ${colors.surfacePrimary};
  word-break: break-all;

  padding-left: 40px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  white-space: pre-wrap;
`;

export const MoreIcon = styled(Icon)`
  color: ${colors.buttonSecondaryText};
  font-size: 16px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // Align icon with submit button for create comment
  margin-right: 10px;
`;

export const MoreMenuItem = styled(Menu.Item)`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
`;
