import React from "react";

import { Input } from "antd";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import { CredentialFormProps } from "./types";

export const USERNAME_FIELD_ID = "Username";
export const PASSWORD_FIELD_ID = "Password";
export const CLIENT_ID_FIELD_ID = "ClientID";
export const CLIENT_SECRET_FIELD_ID = "ClientSecret";
export const SECURITY_TOKEN_FIELD_ID = "SecurityToken";

export default function SalesForceDataSourceFieldSet({
  form,
  allowPartialSubmission,
  includeLabel,
  isLoading
}: CredentialFormProps) {
  const { getFieldDecorator } = form;
  return (
    <>
      <FormItem label={includeLabel ? "User" : undefined}>
        {getFieldDecorator(USERNAME_FIELD_ID, {
          validateTrigger: "onSubmit",
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            disabled={isLoading}
            placeholder="Salesforce Username"
            data-test="username-input"
          />
        )}
      </FormItem>
      <FormItem label={includeLabel ? "Password" : undefined}>
        {getFieldDecorator(PASSWORD_FIELD_ID, {
          validateTrigger: "onSubmit",
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            disabled={isLoading}
            placeholder="Salesforce Password"
            data-test="password-input"
            type="password"
          />
        )}
      </FormItem>
      <FormItem label={includeLabel ? "Consumer Key" : undefined}>
        {getFieldDecorator(CLIENT_ID_FIELD_ID, {
          validateTrigger: "onSubmit",
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            disabled={isLoading}
            placeholder="Salesforce Consumer Key"
            data-test="consumer-key-input"
          />
        )}
      </FormItem>
      <FormItem label={includeLabel ? "Consumer Secret" : undefined}>
        {getFieldDecorator(CLIENT_SECRET_FIELD_ID, {
          validateTrigger: "onSubmit",
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            disabled={isLoading}
            placeholder="Salesforce Consumer Secret"
            data-test="consumer-secret-input"
            type="password"
          />
        )}
      </FormItem>
      <FormItem label={includeLabel ? "Security Token" : undefined}>
        {getFieldDecorator(SECURITY_TOKEN_FIELD_ID, {
          validateTrigger: "onSubmit",
          preserve: true,
          rules: [
            {
              required: !allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            disabled={isLoading}
            placeholder="Salesforce Security Token"
            data-test="security-token-input"
            type="password"
          />
        )}
      </FormItem>
    </>
  );
}
