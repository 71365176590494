import styled from "styled-components";

import { ViewFilterOperatorDisplayNames } from "../../../../../types";

export const FiltersContent = styled.div`
  display: grid;
  grid-template-columns:
    minmax(max-content, 200px) 200px repeat(
      ${Object.keys(ViewFilterOperatorDisplayNames).length},
      min-content
    )
    max-content;
  grid-column-gap: ${props => props.theme.spacersm};
  grid-row-gap: ${props => props.theme.spacersm};
  align-items: center;
`;
