import gql from "graphql-tag";

import { Connection, RelayNode, SyncStatus } from "../types";

interface SpaceNode extends RelayNode {
  name: string;
}

interface DataSourceNode extends RelayNode {
  name: string;
  lastSync: { id: string; status: SyncStatus } | null;
  spaces: Connection<SpaceNode>;
}

export interface SyncStatusNode extends RelayNode {
  status: SyncStatus;
}

export interface SyncStatusData {
  dataSource: DataSourceNode;
}

export const FETCH_DATA_SOURCE_SYNC_STATUS = gql`
  query FetchDataSourceSyncStatus($dataSourceId: ID!) {
    dataSource(id: $dataSourceId) {
      id
      name
      lastSync {
        id
        status
      }
      spaces {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

interface OperationNode extends RelayNode {
  operationLabel: string;
  status: string;
  description: string;
}

export interface SyncNode extends RelayNode {
  status: SyncStatus;
  createdAt: string;
  operations: Connection<OperationNode>;
}

export const SYNC_PLAN_ATTRIBUTES_FRAGMENT = gql`
  fragment SyncPlanAttributes on SyncNode {
    id
    status
    createdAt
    operations {
      edges {
        node {
          id
          operationLabel
          status
          description
        }
      }
    }
  }
`;

export const FETCH_SYNC_PLANS_FOR_DATA_SOURCE = gql`
  query FetchSyncPlansForDataSource($dataSourceId: ID!) {
    allSyncsForDataSourceId(dataSourceId: $dataSourceId) {
      ...SyncPlanAttributes
    }
  }
  ${SYNC_PLAN_ATTRIBUTES_FRAGMENT}
`;

export const TOGGLE_AUTO_SYNC = gql`
  mutation ToggleAutoSync($dataSourceId: ID!, $autoSyncEnabled: Boolean!) {
    toggleAutoSync(dataSourceId: $dataSourceId, autoSyncEnabled: $autoSyncEnabled) {
      ok
    }
  }
`;

export interface SyncPlanMutationResult {
  syncPlan: { sync: SyncNode };
}

export const SYNC_PLAN_MUTATION = gql`
  mutation SyncPlan($dataSourceId: ID!) {
    syncPlan(dataSourceId: $dataSourceId) {
      sync {
        ...SyncPlanAttributes
      }
    }
  }
  ${SYNC_PLAN_ATTRIBUTES_FRAGMENT}
`;

export interface SyncConfirmMutationResult {
  syncConfirm: { sync: SyncNode };
}

export const SYNC_CONFIRM_MUTATION = gql`
  mutation SyncConfirm($syncId: ID!) {
    syncConfirm(syncId: $syncId) {
      sync {
        ...SyncPlanAttributes
      }
    }
  }
  ${SYNC_PLAN_ATTRIBUTES_FRAGMENT}
`;
