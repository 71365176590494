import React from "react";

import _ from "lodash";

import {
  SpaceComponentObject,
  MaskedSpaceNode,
  StatusCode,
  Binding,
  SpaceComponentNode
} from "../../../../types";
import { BindingNode, createNodeFromArray } from "../../../util/binding";
import { reportException } from "../../../util/exceptionReporting";
import { fromComponents } from "../../util/tree";
import { RootNode } from "../RenderTreeContext";
import { SpaceContextParamComponent } from "../SpaceComponent/SpaceContextParam/types";

import { findSpaceComponentPackage, OPAQUE_SPACE_DATA_KEY } from "./StableSpaceContext";
import useSpace, { EMPTY_SPACE } from "./useSpace";
import {
  maskSpace,
  getAncestorComponents as getAncestorComponentsFromComponents
} from "./util";

export type SpaceContextParams = Record<string, SpaceContextParamValue>;

export interface SpaceContextParamValue<T = any> {
  schema: Binding[];
  title: string;
  value?: T;
}

export interface SpaceContextValue {
  space: MaskedSpaceNode;
  loading: boolean;
  components: SpaceComponentObject[];
  componentTree: SpaceComponentObject[];
  contextParams: SpaceContextParams;
  queryStatus: StatusCode;
  // returns array of components from root to leaf, inclusive of component for slug passed in
  getAncestorComponents: (slug: string | undefined) => SpaceComponentObject[];
  getBinding: (path: string | null | undefined) => Binding | undefined;
  slug: string | undefined;
}

export interface SpaceContextProviderProps {
  slug?: string;
  editing?: boolean;
  children: React.ReactNode;
}

export const initialSpaceContext = {
  loading: true,
  space: maskSpace(EMPTY_SPACE),
  components: [],
  componentTree: [],
  contextParams: {},
  resourceCursor: null,
  queryStatus: 0,
  slug: "",
  getBinding: (_path: string | null | undefined): Binding | undefined => undefined,
  getAncestorComponents: () => []
};

export const SpaceContext = React.createContext<SpaceContextValue>(initialSpaceContext);

export function collectComponents(
  components: SpaceComponentObject[] = []
): SpaceComponentObject[] {
  const children = components.flatMap(c => {
    if (!c.componentTreeNodes) {
      reportException(
        new Error("Space query was not deep enough to include some components"),
        {
          extra: { component: c }
        }
      );
      return [];
    }
    return collectComponents(c.componentTreeNodes);
  });
  return components.concat(children);
}

export const createSpaceContextParamComponent = (
  key: string,
  value: SpaceContextParamValue
): SpaceContextParamComponent => ({
  id: `__clientProvided${key}`,
  slug: key,
  name: value.title,
  type: "CONTEXT_PARAM",
  functions: { edges: [] },
  container: null,
  componentTreeNodes: [],
  properties: {
    context_param: key,
    schema: value.schema
  }
});

export function EmbedSpaceContextProvider({
  children,
  componentTreeNodes,
  contextParams
}: {
  children: React.ReactNode;
  componentTreeNodes: SpaceComponentNode[];
  contextParams: Record<string, SpaceContextParamValue>;
}) {
  const components = React.useMemo(() => {
    const contextParamComponents = Object.entries(contextParams).map(([key, value]) =>
      createSpaceContextParamComponent(key, value)
    );
    const nodes = [...componentTreeNodes, ...contextParamComponents];
    return nodes.length > 0 ? collectComponents(nodes) : [];
  }, [componentTreeNodes, contextParams]);

  const getAncestorComponents = React.useCallback(
    (slug: string | undefined) => {
      return getAncestorComponentsFromComponents(slug, components);
    },
    [components]
  );

  const getBinding = React.useCallback(
    (path: string | null | undefined): Binding | undefined => {
      return _getBinding(componentTreeNodes, path);
    },
    [componentTreeNodes]
  );

  const spaceValue: SpaceContextValue = React.useMemo(
    () => ({
      loading: false,
      componentTree: componentTreeNodes,
      contextParams: contextParams,
      queryStatus: StatusCode.OK,
      slug: undefined,
      space: {
        [OPAQUE_SPACE_DATA_KEY]: {
          id: "single-component-embed",
          name: "single-component-embed",
          slug: "single-component-embed"
        }
      },
      components,
      getAncestorComponents,
      getBinding
    }),
    [components, componentTreeNodes, getAncestorComponents, getBinding, contextParams]
  );

  return <SpaceContext.Provider value={spaceValue}>{children}</SpaceContext.Provider>;
}

export function SpaceContextProvider({ slug, children }: SpaceContextProviderProps) {
  const result = useSpace(slug, { editMode: false });

  const components = React.useMemo(() => {
    return !!result.componentTree && result.componentTree.length > 0
      ? collectComponents(result.componentTree)
      : [];
  }, [result.componentTree]);

  const getAncestorComponents = React.useCallback(
    (slug: string | undefined) => {
      return getAncestorComponentsFromComponents(slug, components);
    },
    [components]
  );

  const getBinding = React.useCallback(
    (path: string | null | undefined): Binding | undefined => {
      return _getBinding(result.componentTree, path);
    },
    [result.componentTree]
  );

  const spaceValue = React.useMemo(
    () => ({
      ...result,
      space: maskSpace(result.space),
      components,
      contextParams: {},
      getAncestorComponents,
      getBinding
    }),
    [result, components, getAncestorComponents, getBinding]
  );

  return <SpaceContext.Provider value={spaceValue}>{children}</SpaceContext.Provider>;
}

export const useSpaceContext = () => React.useContext(SpaceContext);

export function _getBinding(
  componentTree: SpaceComponentObject[],
  path: string | null | undefined
) {
  if (!path) return undefined;

  const renderTree = fromComponents(componentTree);
  const bindingState = getBindingState(renderTree);
  return (_.get(bindingState, path) as BindingNode | undefined)?.__meta;
}

function getBindingState(renderTree: RootNode) {
  return renderTree.children.reduce<{ [k: string]: any }>((acc, n) => {
    const pkg = findSpaceComponentPackage(n.component.type);
    if (!pkg.getSchema) return acc;
    acc[n.path] = createNodeFromArray(pkg.getSchema(n));
    return acc;
  }, {});
}
