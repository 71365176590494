import React from "react";

import { RequireAuth } from "../components/auth/RequireAuth";
import RootRecordDetail from "../components/resource_dash/Record/RootRecordDetail";
import LeftNav from "../components/spaces/SpaceRoot/LeftNav/LeftNav";
import { Layout, Header } from "../layouts";

const _Root = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <RequireAuth>
      <Layout backgroundColor="grey">
        <Header
          onToggleNav={() => {
            setOpen(!open);
          }}
        />
        <LeftNav open={open} />
        <RootRecordDetail />
      </Layout>
    </RequireAuth>
  );
};

export const Detail = _Root;
export const Flyout = _Root;
