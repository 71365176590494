import React from "react";

import { Button, Icon } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GlobalStyleVariables } from "../../../../../cssConstants";
import DarkThemeModal from "../../../../common/DarkThemeModal";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import usePaths from "../../../../common/hooks/usePaths";
import copyToClipboard from "../../../../util/copyToClipboard";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MutationType } from "../../SpaceConfigContext/useSpaceConfig";
import VersionHistoryDrawer from "../../VersionHistoryDrawer";
import HeaderPanel, { HeaderPanelMenu, HeaderPanelMenuButton } from "../HeaderPanel";
import UnpublishModal from "../UnpublishModal";

export const SaveLinkButton = styled(Button)`
  &.ant-btn {
    padding: 0;
    color: ${props => props.theme.inputColor};
    line-height: 30px;
    height: 30px;

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.inputColor};
    }
  }
`;

export default function MoreOptions() {
  const navigate = useNavigate();
  const { getDashboard } = usePaths();
  const { authUser } = useAuthUser();
  const { getEncodedHashParam, updateHashParams } = usePaths();

  const [visible, setVisible] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = React.useState(false);

  const { destroy, mutationLoading, space } = useSpaceConfigContext();
  const { spaceId } = useStableSpaceContext();

  const showVersionHistoryDrawer = getEncodedHashParam("drawer") === "version-history";
  const organization = authUser?.organization;

  return (
    <>
      <SaveLinkButton onClick={() => setVisible(true)} type="link" data-test="moreMenu">
        <Icon type="ellipsis" />
      </SaveLinkButton>
      {visible && (
        <HeaderPanel maskClosable onCancel={() => setVisible(false)}>
          <HeaderPanelMenu>
            <HeaderPanelMenuButton
              onClick={() => {
                if (!spaceId) {
                  navigate(getDashboard());
                  return;
                }
                setVisible(false);
                setShowDeleteModal(true);
              }}
            >
              Delete
            </HeaderPanelMenuButton>
            <HeaderPanelMenuButton
              onClick={() => {
                setVisible(false);
                setShowUnpublishModal(true);
              }}
            >
              Unpublish
            </HeaderPanelMenuButton>
            {!!spaceId && organization?.licenseData?.versionHistory && (
              <HeaderPanelMenuButton
                onClick={() => {
                  setVisible(false);
                  const nextPath = updateHashParams({
                    drawer: "version-history"
                  });
                  navigate(nextPath);
                }}
              >
                Version History
              </HeaderPanelMenuButton>
            )}
            {process.env.INTERNALIO_ONPREM === "1" && (
              <HeaderPanelMenuButton
                onClick={() => {
                  copyToClipboard(
                    JSON.stringify({
                      space,
                      errors: window.__ERRORS.map(errObj => {
                        const err: { [key: string]: any } = {};
                        Object.getOwnPropertyNames(errObj).forEach(function (propName) {
                          err[propName] = (
                            errObj as {
                              [index: string]: any;
                            }
                          )[propName];
                        });
                        return err;
                      })
                    })
                  );
                  setVisible(false);
                }}
              >
                Copy Debug Data
              </HeaderPanelMenuButton>
            )}
          </HeaderPanelMenu>
        </HeaderPanel>
      )}
      {showUnpublishModal && (
        <UnpublishModal
          modalVisible={showUnpublishModal}
          onClose={() => setShowUnpublishModal(false)}
        />
      )}
      {showDeleteModal && (
        <DarkThemeModal
          visible={showDeleteModal}
          title="Are you sure?"
          onCancel={() => setShowDeleteModal(false)}
          width={GlobalStyleVariables.confirmModal}
          content="This space and all of its configuration will be permanently deleted."
          okText="Delete"
          onOk={destroy}
          okButtonProps={{
            loading: mutationLoading === MutationType.DESTROY
          }}
        />
      )}
      {showVersionHistoryDrawer && (
        <VersionHistoryDrawer
          onClose={() => {
            const nextPath = updateHashParams({ drawer: undefined });
            navigate(nextPath);
          }}
        />
      )}
    </>
  );
}
