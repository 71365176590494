const TAX_COUNTRY_OPTIONS: { [key: string]: string[] } = {
  AU: ["au_abn", "au_arn"],
  AT: ["eu_vat"],
  BE: ["eu_vat"],
  BR: ["br_cnpj", "br_cpf"],
  BG: ["bg_uic", "eu_vat"],
  CA: ["ca_bn", "ca_gst_hst", "ca_pst_bc", "ca_pst_mb", "ca_pst_sk", "ca_qst"],
  CL: ["cl_tin"],
  HR: ["eu_vat"],
  CY: ["eu_vat"],
  CZ: ["eu_vat"],
  DK: ["eu_vat"],
  EE: ["eu_vat"],
  FI: ["eu_vat"],
  FR: ["eu_vat"],
  GE: ["ge_vat"],
  DE: ["eu_vat"],
  GR: ["eu_vat"],
  HK: ["hk_br"],
  HU: ["hu_tin", "eu_vat"],
  IS: ["is_vat"],
  IN: ["in_gst"],
  ID: ["id_npwp"],
  IE: ["eu_vat"],
  IL: ["il_vat"],
  IT: ["eu_vat"],
  JP: ["jp_cn", "jp_rn"],
  LV: ["eu_vat"],
  LI: ["li_uid"],
  LT: ["eu_vat"],
  LU: ["eu_vat"],
  MY: ["my_frp", "my_itn", "my_sst"],
  MI: ["eu_vat"],
  MX: ["mx_rfc"],
  NL: ["eu_vat"],
  NZ: ["nz_gst"],
  NO: ["no_vat"],
  PL: ["eu_vat"],
  PT: ["eu_vat"],
  RO: ["eu_vat"],
  RU: ["ru_inn", "ru_kpp"],
  SA: ["sa_vat"],
  SG: ["sg_gst", "sg_uen"],
  SK: ["eu_vat"],
  SI: ["si_tin", "eu_vat"],
  ZA: ["za_vat"],
  KR: ["kr_brn"],
  ES: ["es_cif", "eu_vat"],
  SE: ["eu_vat"],
  CH: ["ch_vat"],
  TW: ["tw_vat"],
  TH: ["th_vat"],
  UA: ["ua_vat"],
  AE: ["ae_trn"],
  GB: ["gb_vat", "eu_vat"],
  US: []
};

export default TAX_COUNTRY_OPTIONS;
