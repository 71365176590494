import styled from "styled-components";

import { colors } from "../../../../../../../cssConstants";

export const EncodingChoice = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${colors.darkInputOnDarkBackground}
  border-bottom: 1px solid ${colors.darkInputOnDarkBackground}
  padding: 6px 0;
  margin-top: ${props => props.theme.spacersm};
`;

export const Table = styled.table`
  margin-top: 12px;
  width: 100%;

  td {
    padding-top: ${props => props.theme.spacersm};
    padding-right: ${props => props.theme.spacersm};
  }
`;

export const WideHeader = styled.th`
  width: 266px;
`;

export const SmallHeader = styled.th`
  width: 40px;
`;

export const SmallCell = styled.td`
  text-align: center;
`;
