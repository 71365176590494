import React from "react";

import { Link } from "react-router-dom";

import ScheduleIcon from "../../../assets/icons/schedule-icon.svg";
import SettingsIcon from "../../../assets/icons/settings-icon.svg";
import StateIcon from "../../../assets/icons/state-icon.svg";
import { colors } from "../../../cssConstants";
import * as styled from "../../common/StyledComponents";
import { assertNever } from "../../util/assertNever";

export enum Tab {
  GENERAL = "general",
  SCHEDULE = "schedule",
  ACTIONS = "actions"
}

export interface Item {
  tab: Tab;
  title: string;
  description: string;
}

interface Props {
  tab: Tab;
  className?: string;
}

const tabs: Item[] = [
  {
    tab: Tab.GENERAL,
    title: "General settings",
    description: "Manage name, description, and other settings for this automation."
  },
  {
    tab: Tab.SCHEDULE,
    title: "Schedule",
    description: "Manage schedule for your automation."
  },
  {
    tab: Tab.ACTIONS,
    title: "Actions and environments",
    description: "Manage actions and environments for your automation."
  }
];

const TabIcon = ({ tab, selected }: { tab: Tab; selected: boolean }) => {
  const color = selected ? colors.primaryColor : colors.surfaceSecondary;
  switch (tab) {
    case Tab.GENERAL:
      return <SettingsIcon fill={color} />;
    case Tab.SCHEDULE:
      return <ScheduleIcon fill={color} />;
    case Tab.ACTIONS:
      return <StateIcon fill={color} />;
    default:
      return assertNever(tab);
  }
};

const Navigation = ({ tab, className = "" }: Props) => {
  return (
    <div className={className}>
      <styled.Timeline>
        {tabs.map(item => (
          <styled.TimelineItem
            key={item.tab}
            dot={<TabIcon tab={item.tab} selected={item.tab === tab} />}
          >
            <Link to={`?tab=${item.tab}`}>
              <styled.TimelineTitle selected={tab === item.tab}>
                {item.title}
              </styled.TimelineTitle>
            </Link>
            <styled.TimelineDescription>
              {tab === item.tab ? item.description : ""}
            </styled.TimelineDescription>
          </styled.TimelineItem>
        ))}
      </styled.Timeline>
    </div>
  );
};

export default Navigation;
