import React from "react";

import { Input, Select } from "antd";
import { capitalize } from "lodash";
import styled from "styled-components";

import withDebouncedValue from "../../../../../../common/withDebouncedValue";
import { EffectConfig } from "../../../../../types";

import { EffectOptionField, EffectOptionLabel } from "./styledComponents";
import { OptionsComponent, EffectErrorSelector } from "./types";

export enum MessageType {
  SUCCESS = "success",
  ERROR = "error"
}

const TextArea = withDebouncedValue(Input.TextArea, {
  selectOnChangeValue: evt => evt.target.value
});

interface DisplayMessageOptionsValue {
  message: string;
  type: MessageType;
}
const DisplayMessageOptions: OptionsComponent<DisplayMessageOptionsValue> = ({
  value,
  onChange
}) => {
  return (
    <div>
      <EffectOptionField>
        <EffectOptionLabel>Type</EffectOptionLabel>
        <Select
          value={value.type}
          placeholder="Select the message style"
          onChange={(val: MessageType) => onChange({ type: val })}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          {Object.values(MessageType).map(t => (
            <Select.Option value={t} key={t}>
              {capitalize(t)}
            </Select.Option>
          ))}
        </Select>
      </EffectOptionField>
      <EffectOptionField>
        <EffectOptionLabel>Message*</EffectOptionLabel>
        <TextArea
          autosize
          value={value.message}
          onChange={value => onChange({ message: value })}
        />
        <FootNote>
          * Optional - if a message is provided it will be used instead of the default
          system message.
        </FootNote>
      </EffectOptionField>
    </div>
  );
};

const FootNote = styled.div`
  padding-top: ${props => props.theme.spacersm};
  font-size: ${props => props.theme.smallFontSize};
  line-height: ${props => props.theme.mediumFontSize};
`;

const errorSelector: EffectErrorSelector = eventHandler => {
  const options: Extract<EffectConfig, { type: "display_message" }>["options"] =
    eventHandler.effect.options;
  if (!options.type) {
    return "Please select a type for your message.";
  }

  if (typeof options.message !== "string") {
    return "Please provide a message to display.";
  }

  return null;
};

export const DISPLAY_MESSAGE = {
  name: "Display message",
  type: "display_message" as const,
  Options: DisplayMessageOptions,
  errorSelector
};
