import styled from "styled-components";

import { DebouncedAttributeInput } from "../../../../../../common/AttributeInput";
import { ErrorIcon } from "../../../../../../common/Icons";

export const StyledTextSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icons {
    display: flex;
    align-items: center;
  }

  & > input {
    width: 70%;
  }
  & > .ant-select {
    width: 70%;
  }
`;

export const BindingCascaderContainer = styled.div`
  width: 80%;
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  transform: scale(0.7);
`;

export const NumberInput = styled(DebouncedAttributeInput)`
  max-width: 100%;
  margin-right: ${props => props.theme.spacerxs};
`;

export const RelativeValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-select {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:first-child) {
    margin-top: 12px;
  }
  .ant-form-item-control {
    line-height: inherit; // override line-height
  }
`;
