import { TextEncoder } from "text-encoding";

// encode encodes str to base64. It uses TextEncoder to encode str to a
// Uint8Array and then converts that to base64 using btoa. btoa cannot be
// used directly as it only supports characters in the Latin1 range.
export function encode(str: string) {
  const encoder = new TextEncoder();
  return btoa(String.fromCharCode(...encoder.encode(str)));
}

export function decode(str: string) {
  const encoder = new TextEncoder();
  return atob(String.fromCharCode(...encoder.encode(str)));
}
