import React, { useMemo, useState } from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form, Select, Checkbox, Divider, Icon, Button, Skeleton } from "antd";
import FormItem from "antd/lib/form/FormItem";
import gql from "graphql-tag";
import _ from "lodash";
import { useLocation, generatePath } from "react-router";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";

import { useConfigContext } from "../../../../ConfigContext";
import { Routes } from "../../../../constants";
import {
  Edge,
  UserNode,
  SSOProvider,
  SSOUserNode,
  SSOConnectionNode
} from "../../../../types";
import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";
import { MainTitle } from "../../../common/StyledComponents";
import { UpsellBadge } from "../../../common/Upsells";
import { assertNever } from "../../../util/assertNever";
import { SECURITY_SETTINGS_QUERY } from "../SecuritySettings";

import AzureADTenantProvider from "./AzureADTenantProvider";
import GoogleSSOProvider from "./GoogleSSOProvider";
import OktaOpenIdSSOProvider from "./OktaOpenIdSSOProvider";
import SAMLSSOProvider from "./SAMLSSOProvider";
import { Root, CheckboxInfo } from "./StyledComponents";

const { Option } = Select;

interface ContainerProps {
  onClose: () => void;
}

interface DrawerProps extends ContainerProps {
  data: any;
  ssoConnection: SSOConnectionNode;
  isSSORequired: boolean;
}

export interface ProviderProps {
  connectionNode: SSOConnectionNode;
  connection: SSOConnectionInput;
  setConnection: (connection: SSOConnectionInput) => void;
  errors: SSOErrors;
  redirect: string;
}

interface SSOErrors {
  azureadTenantId?: string;
  azureadClientId?: string;
  azureadClientSecret?: string;
  samlEntityId?: string;
  samlSsoUrl?: string;
  samlX509Cert?: string;
  oktaDomain?: string;
  oauthClientId?: string;
  oauthClientSecret?: string;
}

export interface SSOConnectionInput {
  connectionType: SSOProvider;
  azureadTenantId?: string | null;
  azureadClientId?: string | null;
  azureadClientSecret?: string | null;
  externalKey: string;
  samlEntityId?: string | null;
  samlSsoUrl?: string | null;
  samlX509Cert?: string | null;
  samlAcsUrl?: string | null;
  oktaDomain?: string | null;
  oauthClientId?: string | null;
  oauthClientSecret?: string | null;
  state?: string;
}

interface MutationData {
  enforceSingleSignOn: {
    ok: boolean;
    errors: SSOErrors | null;
    authUser: UserNode;
  };
}

interface MutationVars {
  required: boolean;
  connection: SSOConnectionInput;
}

export const SINGLE_SIGN_ON_DRAWER_QUERY = gql`
  query SingleSignOnDrawerQuery {
    authUser {
      id
      email
      organization {
        id
        ssoRequired
        licenseData {
          sso
        }
        ssoConnection {
          id
          connectionType
          externalKey
          state
          azureadTenantId
          azureadClientId
          azureadClientSecret
          ssoUrl
          samlEntityId
          samlSsoUrl
          samlX509Cert
          samlAcsUrl
          oauthClientId
          oauthClientSecret
          oauthRedirectUri
          oktaDomain
        }
      }
      ssoUsers {
        edges {
          node {
            id
            providerType
          }
        }
      }
    }
  }
`;

export const ENFORCE_SINGLE_SIGN_ON_MUTATION = gql`
  mutation EnforceSingleSignOn($required: Boolean!, $connection: SSOConnectionInput!) {
    enforceSingleSignOn(ssoRequired: $required, ssoConnection: $connection) {
      ok
      errors {
        azureadTenantId
        azureadClientId
        azureadClientSecret
        samlEntityId
        samlSsoUrl
        samlX509Cert
        oauthClientId
        oauthClientSecret
        oktaDomain
      }
      authUser {
        id
        email
        organization {
          id
          ssoRequired
          ssoConnection {
            id
            connectionType
            externalKey
            state
            ssoUrl
            azureadTenantId
            azureadClientId
            azureadClientSecret
            samlEntityId
            samlSsoUrl
            samlX509Cert
            samlAcsUrl
            oauthClientId
            oauthClientSecret
            oktaDomain
          }
        }
        ssoUsers {
          edges {
            node {
              id
              providerType
            }
          }
        }
      }
    }
  }
`;

const SingleSignOnDrawerContainer = (props: ContainerProps) => {
  const { data } = useQuery(SINGLE_SIGN_ON_DRAWER_QUERY);

  if (!data || !data.authUser) return <Skeleton active />;

  return (
    <SingleSignOnDrawer
      {...props}
      data={data}
      ssoConnection={data.authUser.organization.ssoConnection}
      isSSORequired={data.authUser.organization.ssoRequired}
    />
  );
};

const PaywallPopup = styled(ModalNew)`
  right: 40px;
  top: 140px;
`;

const SingleSignOnDrawer = (props: DrawerProps) => {
  const { data } = props;

  const config = useConfigContext();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useMemo(
    () => new URLSearchParams(location.search || ""),
    [location.search]
  );

  const [ssoConnection, setSSOConnection] = React.useState<SSOConnectionInput>({
    ...props.ssoConnection
  } as SSOConnectionInput);
  const [ssoProvider, setSSOProvider] = React.useState<SSOProvider>(
    props.ssoConnection.connectionType || SSOProvider.DISABLED
  );
  const [isSSORequired, setIsSSORequired] = React.useState<boolean>(
    props.isSSORequired
  );
  const [errors, setErrors] = React.useState<object>({});

  const [enforceSingleSignOn] = useMutation<MutationData, MutationVars>(
    ENFORCE_SINGLE_SIGN_ON_MUTATION,
    {
      refetchQueries: [{ query: SECURITY_SETTINGS_QUERY }],
      onCompleted: (data: any) => {
        if (data.enforceSingleSignOn.ok) {
          props.onClose();
          Message.success(
            "Single Sign-On settings have been updated for your organization."
          );
        } else {
          setErrors(data.enforceSingleSignOn.errors);
        }
      }
    }
  );

  const ssoRoute = generatePath(Routes.SETTINGS_SECURITY_DRAWER, {
    drawerId: "sso"
  });
  const ssoRedirectRoute = `${ssoRoute}?ssoProvider=${ssoProvider}`;

  const handleProviderChange = React.useCallback(
    (val: SSOProvider) => {
      setSSOProvider(val);
      setSSOConnection({
        ...props.ssoConnection,
        connectionType: val,
        state: "draft"
      });
      setIsSSORequired(false);
    },
    [setSSOProvider, setSSOConnection, setIsSSORequired, props.ssoConnection]
  );

  React.useEffect(() => {
    const newSSOProvider = urlParams.get("ssoProvider") as SSOProvider;
    if (newSSOProvider && newSSOProvider !== ssoProvider) {
      handleProviderChange(newSSOProvider);
      navigate(ssoRoute);
    }
  }, [urlParams, ssoProvider, handleProviderChange, navigate, ssoRoute]);

  const submit = (e: any) => {
    e.preventDefault();
    enforceSingleSignOn({
      variables: {
        required: isSSORequired,
        connection: {
          connectionType: ssoProvider,
          externalKey: ssoConnection.externalKey,
          azureadTenantId: ssoConnection.azureadTenantId,
          azureadClientId: ssoConnection.azureadClientId,
          azureadClientSecret: ssoConnection.azureadClientSecret,
          samlEntityId: ssoConnection.samlEntityId,
          samlSsoUrl: ssoConnection.samlSsoUrl,
          samlX509Cert: ssoConnection.samlX509Cert,
          oauthClientId: ssoConnection.oauthClientId,
          oauthClientSecret: ssoConnection.oauthClientSecret,
          oktaDomain: ssoConnection.oktaDomain
        }
      }
    });
  };

  const ssoUsers = _.get<Edge<SSOUserNode>[]>(data, "authUser.ssoUsers.edges", []).map(
    edge => edge.node
  );

  if (
    urlParams.get("ssoProvider") &&
    !urlParams.get("fail") &&
    ssoUsers &&
    ssoUsers.length
  ) {
    Message.success(
      "You have successfully authenticated with your Single Sign-On provider."
    );
    navigate(ssoRoute);
  }

  const hasConfiguredSSOProvider = !_.isEmpty(
    _.filter(ssoUsers, ssoUser => ssoUser.providerType === ssoProvider)
  );
  const licenseData = data.authUser.organization.licenseData;
  const ssoEnforcementPermitted = licenseData?.sso || false;
  const isSSORequiredDisabled =
    (!hasConfiguredSSOProvider && !isSSORequired) || !ssoEnforcementPermitted;

  const [showPaywall, setShowPaywall] = useState<boolean>(false);

  let SSOProviderComponent: React.ElementType<ProviderProps> | null = null;
  switch (ssoProvider) {
    case SSOProvider.AZUREAD_TENANT:
      SSOProviderComponent = AzureADTenantProvider;
      break;
    case SSOProvider.GOOGLE:
      SSOProviderComponent = GoogleSSOProvider;
      break;
    case SSOProvider.SAML:
      SSOProviderComponent = SAMLSSOProvider;
      break;
    case SSOProvider.OKTA_OPENIDCONNECT:
      SSOProviderComponent = OktaOpenIdSSOProvider;
      break;
    case SSOProvider.DISABLED:
      SSOProviderComponent = null;
      break;
    default:
      assertNever(ssoProvider);
  }

  return (
    <Root>
      <Form onSubmit={submit}>
        <div onClick={props.onClose} className="icon_and_text bold">
          <Icon type="close" />
          <p>Close</p>
        </div>
        <div className="row spaced">
          <div className="icon_and_text">
            <Icon type="exclamation-circle" theme="filled" />
            <p>You're editing Single Sign-On settings for your company.</p>
          </div>
          <div>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </div>
        </div>
        <Divider />
        <MainTitle>Single Sign-On</MainTitle>
        <div>
          <p>
            Select an SSO provider. Once configured, all employees will be required to
            sign in with the SSO provider.
          </p>
          <Select value={ssoProvider} onChange={handleProviderChange}>
            <Option value={SSOProvider.DISABLED}>Disabled</Option>
            <Option
              disabled={!ssoEnforcementPermitted}
              value={SSOProvider.AZUREAD_TENANT}
            >
              Azure AD Single Tenant{" "}
              {!ssoEnforcementPermitted && <UpsellBadge>Enterprise</UpsellBadge>}
            </Option>
            <Option disabled={!config.isGoogleOAuthEnabled} value={SSOProvider.GOOGLE}>
              Google OAuth
            </Option>
            <Option disabled={!ssoEnforcementPermitted} value={SSOProvider.SAML}>
              Generic SAML 2.0{" "}
              {!ssoEnforcementPermitted && <UpsellBadge>Enterprise</UpsellBadge>}
            </Option>
            <Option
              disabled={!ssoEnforcementPermitted}
              value={SSOProvider.OKTA_OPENIDCONNECT}
            >
              Okta OpenID Connect{" "}
              {!ssoEnforcementPermitted && <UpsellBadge>Enterprise</UpsellBadge>}
            </Option>
          </Select>
          {SSOProviderComponent && (
            <SSOProviderComponent
              connectionNode={props.ssoConnection}
              connection={ssoConnection}
              setConnection={setSSOConnection}
              redirect={ssoRedirectRoute}
              errors={errors}
            />
          )}
        </div>
        <FormItem>
          <CheckboxInfo>
            <Checkbox
              onChange={e => setIsSSORequired(e.target.checked)}
              disabled={isSSORequiredDisabled}
              checked={isSSORequired}
            />
            <p>
              Require employees to use Single Sign-On when signing into their Internal
              account.{" "}
              {!ssoEnforcementPermitted && (
                <UpsellBadge onMouseOver={() => setShowPaywall(true)}>
                  Growth
                </UpsellBadge>
              )}{" "}
            </p>
            <PaywallPopup
              absolutePositioning={true}
              title="Single Sign-on requirement"
              visible={showPaywall}
              mask={false}
              onCancel={() => setShowPaywall(false)}
              footer={null}
              width={270}
            >
              <p>
                To enable single sign-on requirement, upgrade to the{" "}
                <Link to="/settings/change-plan">Growth plan</Link>.
              </p>
            </PaywallPopup>
          </CheckboxInfo>
        </FormItem>
      </Form>
    </Root>
  );
};

export default SingleSignOnDrawerContainer;
