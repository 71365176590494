import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";

export default function selectErrors(
  component: SpaceComponentObject
): ConfigValidationError[] {
  if (
    component.properties.default_value_type === "binding" &&
    !component.properties.default_value_binding?.binding
  ) {
    return [
      {
        field: "DEFAULT_VALUE_BINDING",
        message: "Please add a binding to this field or change the default value type."
      }
    ];
  }
  return [];
}
