import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { AuthorizationFlowNode } from "../../../settings/AuthProviders/types";
import { AUTH_FLOW_STEP_FRAGMENT } from "../../../spaces/SpaceRoot/AuthorizationContext/queries";

export const FETCH_AUTHORIZATION_FLOWS = gql`
  query FetchAuthorizationProviders {
    allAuthorizationProviders {
      id
      name
      environmentsWithState {
        edges {
          node {
            id
            name
          }
        }
      }
      authSteps {
        edges {
          node {
            ...AuthFlowStepFragment
          }
        }
      }
    }
  }
  ${AUTH_FLOW_STEP_FRAGMENT}
`;

interface _Result {
  allAuthorizationProviders: AuthorizationFlowNode[];
}

export const useAuthorizationFlows = () => {
  const { data, refetch } = useQuery<_Result>(FETCH_AUTHORIZATION_FLOWS);
  return {
    authorizationFlows: data?.allAuthorizationProviders || [],
    refetch
  };
};
