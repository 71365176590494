import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { AttributeTypes } from "../../../../../../constants";
import {
  Connection,
  InputParameter,
  PageInfo,
  RelayNode
} from "../../../../../../types";
import { AssigneeType } from "../../../../Config/types";

export type TaskOrder =
  | "-created_at"
  | "created_at"
  | "-updated_at"
  | "updated_at"
  | "transitioned_at"
  | "-transitioned_at";

export const DEFAULT_TASK_ORDER = "-transitioned_at";

interface FieldValueNode {
  name: string;
  value: any;
  type: AttributeTypes;
}

export interface TransitionNode extends RelayNode {
  name: string;
  isPrimary: boolean;
  functionId: string;
  inputParameters: InputParameter[];
}

export interface StateNode extends RelayNode {
  name: string;
  fromTransitions?: TransitionNode[];
  color: string;
}

export interface AssigneeNode extends RelayNode {
  name: string;
  type: AssigneeType;
}

export interface RequesterNode extends RelayNode {
  name: string;
}

export interface TaskNode extends RelayNode {
  createdAt: string;
  updatedAt: string;
  transitionedAt: string;
  title: string;
  state: StateNode;
  fieldValues?: FieldValueNode[];
  queue: {
    id: string;
    slug: string;
  };
  assignee?: AssigneeNode;
  requester?: RequesterNode;
}

export interface TasksConnectionPageInfo extends PageInfo {
  startCursor: string;
}

export interface TasksConnection extends Connection<TaskNode> {
  pollingCursor: string;
  beforeCount?: number;
  totalCount?: number;
  pageInfo: TasksConnectionPageInfo;
}

export interface AllTasksData {
  allTasks: TasksConnection;
}

export interface AllTasksVariables {
  orderBy: TaskOrder;
  id?: string;
  queueSlug?: string;
  isArchived?: boolean;
  isAssignedTo?: boolean;
  isAssignedToUser?: boolean;
  isAssignedToUsersRole?: boolean;
  stateIdIn?: string[];
  search?: string;
  before?: string;
  after?: string;
  first?: number;
}

export const ALL_TASKS_QUERY = gql`
  query AllTasks(
    $orderBy: String
    $queueSlug: String
    $isAssignedTo: Boolean
    $isAssignedToUser: Boolean
    $isAssignedToUsersRole: Boolean
    $stateIdIn: [String]
    $isArchived: Boolean
    $search: String
    $after: String
    $before: String
    $first: Int
  ) {
    allTasks(
      orderBy: $orderBy
      queueSlug: $queueSlug
      isArchived: $isArchived
      isAssignedTo: $isAssignedTo
      isAssignedToUser: $isAssignedToUser
      isAssignedToUsersRole: $isAssignedToUsersRole
      stateIdIn: $stateIdIn
      search: $search
      after: $after
      before: $before
      first: $first
    ) {
      pollingCursor
      totalCount
      beforeCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          transitionedAt
          title
          requester {
            id
            name
          }
          queue {
            id
            slug
            name
          }
          assignee {
            id
            type
            name
          }
          state {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export const useAllTasks = (
  options?: QueryHookOptions<AllTasksData, Partial<AllTasksVariables>>
) => {
  const variables = options?.variables;
  return useQuery<AllTasksData, AllTasksVariables>(ALL_TASKS_QUERY, {
    ...options,
    variables: {
      ...variables,
      first: variables?.first ?? 50,
      orderBy: variables?.orderBy === undefined ? DEFAULT_TASK_ORDER : variables.orderBy
    }
  });
};
