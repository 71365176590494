import React from "react";

import Message from "../../../../common/Message";
import { ModalNew } from "../../../../common/Modal";

import useDeleteAutomation from "./useDeleteAutomation";

interface Props {
  automationId: string;
  onCancel?: () => void;
  onComplete?: () => void;
  onSuccess?: () => void;
}

export default function DeleteAutomationModal({
  automationId,
  onCancel = () => null,
  onComplete = () => null,
  onSuccess = () => null
}: Props) {
  const [deleteAutomation, { loading }] = useDeleteAutomation({
    onCompleted: onComplete,
    onError: onComplete
  });

  return (
    <ModalNew
      title="Are you sure?"
      confirmLoading={loading}
      okText="Delete"
      onCancel={onCancel}
      onOk={async () => {
        const result = await deleteAutomation({
          refetchQueries: ["AllAutomations"],
          variables: { id: automationId }
        });
        if (result.data?.deleteAutomation.ok) {
          Message.success("The automation has been deleted.");
          onSuccess();
        } else {
          Message.error("Something went wrong. Please try again.");
        }
      }}
      visible
    >
      This automation will be permanently deleted.
    </ModalNew>
  );
}
