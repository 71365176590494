import React from "react";

import { get } from "lodash";

import { ErrorField as CommonErrorField } from "../../../../common/StyledComponents";
import { State } from "../reducer";

import { FormItem } from "./StyledComponents";

interface ErrorFieldProps {
  path: string;
  state: State;
  className?: string;
}

interface ErrorInputProps {
  path: string;
  state: State;
  className?: string;
  children: React.ReactNode;
}

export const ErrorField = (props: ErrorFieldProps) => {
  const { path, state, className } = props;
  const error = get(state.errors, path, null);
  return error ? (
    <CommonErrorField className={className}>{error}</CommonErrorField>
  ) : null;
};

export const ErrorInput = (props: ErrorInputProps) => {
  const { path, state, className, children } = props;
  const error = get(state.errors, path, null);

  return (
    <FormItem className={className} validateStatus={error ? "error" : ""} help={error}>
      {children}
    </FormItem>
  );
};
