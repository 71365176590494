import { SpaceComponentHostType, SpaceComponentPackage } from "../../../../../types";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import UserConfigurationSection from "./ConfigurationSection";
import { getSchema } from "./getSchema";
import SpaceUser from "./SpaceUser";

const pkg: SpaceComponentPackage = {
  type: "USER",
  displayName: "Current User",
  allowedHosts: new Set<SpaceComponentHostType>([]),
  Component: SpaceUser,
  ConfigurationComponent: null,
  ConfigurationSection: UserConfigurationSection,
  componentConfigReducer: commonComponentReducer,
  ensureComponent: component => component,
  getSchema,
  isHeadless: true,
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isClientProvidedComponent: true,
  errorSelector: () => [],
  isContainer: false
};

export default pkg;
