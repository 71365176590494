import {
  SpaceComponentObject,
  SourceType,
  FunctionAttributeNode,
  AttributeNode
} from "../../../../../../types";

export function hasValidSource(component: SpaceComponentObject) {
  if (
    component.sourceType === SourceType.VIEW &&
    !!component.view &&
    component.functions.edges.length > 0
  ) {
    return true;
  } else if (
    component.sourceType === SourceType.BINDING &&
    !!component.properties.binding
  ) {
    return true;
  } else if (component.sourceType === SourceType.FILE) {
    return true;
  } else {
    return false;
  }
}

export const functionAttributeToAttribute = (
  attribute: FunctionAttributeNode
): AttributeNode => ({
  __typename: attribute.__typename,
  canRead: false,
  id: attribute.id,
  name: attribute.name,
  recordIndex: 0,
  renderTemplate: undefined,
  sourceIndex: attribute.sourceIndex,
  sourceName: attribute.sourceName,
  sourcePrimaryKey: attribute.sourceKey,
  sourceType: attribute.sourceType
});
