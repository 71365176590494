import { useMemo } from "react";

import { useQuery } from "@apollo/react-hooks";

import {
  FunctionEnvironmentsVariables,
  FunctionEnvironmentsData,
  FUNCTION_ENVIRONMENTS_QUERY
} from "./queries";

export default function useFunctionEnvironments(functionId: string) {
  const { loading, data } = useQuery<
    FunctionEnvironmentsData,
    FunctionEnvironmentsVariables
  >(FUNCTION_ENVIRONMENTS_QUERY, {
    skip: !functionId,
    variables: { id: functionId }
  });
  const environments = useMemo(
    () =>
      data?.function.dataSource.environmentDataSources.edges.map(edge => {
        const { id, name, isDefault } = edge.node.environment;
        return { id, name, isDefault };
      }) || [],
    [data]
  );
  return { loading, environments };
}
