import React from "react";

import { ErrorValues, ErrorValue } from "../../../../../constants";
import {
  DataValue,
  SpaceComponentObject,
  AttributeNode,
  FunctionAttributeNode
} from "../../../../../types";
import { ColumnType } from "../common/ColumnListManager";
import { useAccess } from "../common/useFunctionAccess";
import { FunctionAccess } from "../common/useFunctionAccess/types";
import { Row } from "../common/useView/useView";

export type SpaceTableRow = Record<
  string,
  DataValue | ErrorValue | SpaceComponentObject | SpaceStateTableRow
>;

export interface SpaceStateTableRow {
  id: string;
  data: SpaceTableRow;
}

export interface Result {
  rows: SpaceTableRow[];
  getRowIndex: (row: Object) => number;
  findRowById: (id: string) => SpaceTableRow;
}

const getDataIndex = (attr: AttributeNode | FunctionAttributeNode) => {
  if (!attr) return "";
  return attr.sourceName;
};

export function getSpaceStateRow(r: SpaceTableRow) {
  const col = r.spaceStateRow;
  if (col && typeof col === "object" && "id" in col && "data" in col) {
    return col as SpaceStateTableRow;
  }
  throw new Error("Expected to find spaceStateRow column.");
}

export function toSpaceStateRow(
  rowId: string,
  row: SpaceTableRow,
  attributeMap: Record<string, AttributeNode | FunctionAttributeNode>
) {
  const data = Object.fromEntries(
    Object.entries(row)
      .filter(
        ([attrId]) => attrId !== "isSelected" && !attrId.startsWith("__component-")
      )
      .map(([attrId, val]) => {
        return [getDataIndex(attributeMap[attrId]), val];
      })
  );
  return { id: rowId, data };
}

export default function useRows(
  attributes: (AttributeNode | FunctionAttributeNode)[],
  rows: Row[] | null,
  selectedRowIds?: string[],
  viewFunctionAccess?: FunctionAccess
): Result {
  const access = useAccess(viewFunctionAccess);
  const rowDataMask = React.useMemo(
    () =>
      Object.fromEntries(
        attributes
          .filter(
            a => !(a as AttributeNode).canRead && !access.attributeAllowed(a.sourceName)
          )
          .map(a => [a.id, ErrorValues.permissionDenied])
      ),
    [attributes, access]
  );

  const attributeMap = React.useMemo(
    () => Object.fromEntries(attributes.map(a => [a.id, a])),
    [attributes]
  );

  const _rows = React.useMemo(() => {
    if (rows === null) return [];
    return rows.map(row => {
      const tableRow = row.columns.reduce<SpaceTableRow>((acc, column) => {
        if (column[0] === ColumnType.ATTRIBUTE) {
          if (column[2] !== undefined) acc[column[2].id] = column[3];
        } else if (column[0] === ColumnType.COMPONENT) {
          acc[`__component-${column[2].slug}`] = column[2];
        } else {
          throw new Error(`Unknown ColumnType: ${column[0]}`);
        }
        return acc;
      }, {});
      Object.assign(tableRow, rowDataMask);
      tableRow.isSelected = !!selectedRowIds?.includes(row.id);
      tableRow.spaceStateRow = toSpaceStateRow(row.id, tableRow, attributeMap);
      return tableRow;
    });
  }, [rows, rowDataMask, attributeMap, selectedRowIds]);

  const rowIds = React.useMemo(() => {
    if (rows === null) return [];
    return rows.map(r => r.id);
  }, [rows]);

  const getRowIndex = React.useCallback(row => _rows.indexOf(row), [_rows]);

  const findRowById = React.useCallback(
    function findRowById(id: string) {
      return _rows[rowIds.indexOf(id)];
    },
    [_rows, rowIds]
  );

  return {
    rows: _rows,
    getRowIndex,
    findRowById
  };
}
