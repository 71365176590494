import { SpaceComponentHostType, SpaceComponentPackage } from "../../../../../types";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import { BaseComponentConfigState } from "../../../types";

import ParamsConfigurationSection from "./ConfigurationSection/ConfigurationSection";
import { getSchema } from "./getSchema";
import SpaceParams from "./SpaceParams";
import { SpaceParamsComponent, SpaceParamsProperties } from "./types";
import validateComponent from "./validateComponent";

const pkg: SpaceComponentPackage<
  SpaceParamsProperties,
  SpaceParamsComponent,
  BaseComponentConfigState
> = {
  type: "PARAMS",
  displayName: "URL Parameter",
  allowedHosts: new Set<SpaceComponentHostType>([]),
  Component: SpaceParams,
  ConfigurationComponent: null,
  validateComponent,
  ensureComponent: component => component,
  componentConfigReducer: commonComponentReducer,
  ConfigurationSection: ParamsConfigurationSection,
  getSchema,
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isHeadless: true,
  errorSelector: () => [],
  isContainer: false
};

export default pkg;
