import React, { ReactNode, forwardRef } from "react";

import classNames from "classnames";
import styled from "styled-components";

interface RecordCellProps {
  scroll?: number; // using number instead of boolean due to https://github.com/styled-components/styled-components/issues/1198
  children: ReactNode;
}

const StyledRecordRow = styled.div`
  display: flex;
  justify-content: center;
  min-height: 48px;
  overflow-wrap: break-word;
  border: ${props => props.theme.greyBorder};
  border-bottom: none;

  &.hidden {
    display: none;
  }

  &.no-border {
    border: 0px;
    margin: 0;

    &:last-child {
      border-bottom: 0px;
      margin-bottom: ${props => props.theme.spacerlg};
    }
  }

  &:first-child {
    border-radius: ${props => props.theme.borderRadiussm}
      ${props => props.theme.borderRadiussm} 0 0;
  }

  &:last-child {
    margin-bottom: ${props => `calc(${props.theme.spacerxl} * 2)`};
    border-radius: 0 0 ${props => props.theme.borderRadiussm}
      ${props => props.theme.borderRadiussm};
    border-bottom: ${props => props.theme.greyBorder};
  }

  &.noBottomMargin:last-child {
    margin-bottom: 0;
  }
`;

const RecordCell = styled.div`
  padding: ${props => props.theme.spacermd} ${props => props.theme.spacersm};
  overflow-y: ${(props: RecordCellProps) => (props.scroll ? "auto" : "visible")};

  @media (min-width: ${props => props.theme.breakpointmd}) {
    padding: ${props => props.theme.spacerlg} ${props => props.theme.spacerxl};
  }

  &.tight {
    padding: ${props => props.theme.spacermd};
  }

  &.padSpacerSm {
    padding: ${props => props.theme.spacersm} 0;
  }

  &.denseKey {
    min-width: 70px;
    width: 25%;
    margin-right: ${props => props.theme.spacermd};
    flex-wrap: wrap;
    padding-top: 16px; /* lines up with baseline of text input text */
    color: ${props => props.theme.textColorMid};
    font-weight: 400;
    text-transform: uppercase;
    font-size: ${props => props.theme.tinyFontSize};
  }

  &.key {
    display: flex;
    overflow: hidden;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    width: 33%;
    border-right: ${props => props.theme.greyBorder};
    background-color: ${props => props.theme.backgroundColor};
    font-weight: 500;

    & > div {
      flex-grow: 1;
    }
  }

  &.value {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .overflowMasker {
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .ant-form-item-control {
    line-height: unset;
  }

  .pretty-json-container {
    word-break: break-word;
  }
`;

interface SimpleProps extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode;
}

interface RecordRowProps {
  title: string;
  children: JSX.Element;
  scroll?: number;
  isDense: boolean;
  isHidden?: boolean;
}

interface ValueRecordCellProps extends SimpleProps {
  scroll?: number;
}

export const SimpleRecordRow = forwardRef(
  ({ children, className = "", ...props }: any, ref: any) => (
    <StyledRecordRow ref={ref as any} className={`row ${className}`} {...props}>
      {children}
    </StyledRecordRow>
  )
);

export const KeyRecordCell = ({ children, className = "" }: SimpleProps) => (
  <RecordCell className={`key ${className}`}>
    <div>{children}</div>
  </RecordCell>
);

export const ValueRecordCell = ({
  children,
  scroll,
  className = ""
}: ValueRecordCellProps) => (
  <RecordCell scroll={scroll} className={`value ${className}`}>
    <div className="overflowMasker">{children}</div>
  </RecordCell>
);

// TODO: title -> key
const RecordRow = ({
  title,
  children,
  scroll,
  isDense,
  isHidden,
  ...others
}: RecordRowProps) => {
  const classes = classNames({ "no-border": isDense }, { hidden: isHidden });
  return isDense ? (
    <SimpleRecordRow className={classes} {...others}>
      <RecordCell className="padSpacerSm denseKey">{title}</RecordCell>
      <RecordCell className="padSpacerSm value">{children}</RecordCell>
    </SimpleRecordRow>
  ) : (
    <SimpleRecordRow className={classes} {...others}>
      <KeyRecordCell>{title}</KeyRecordCell>
      <ValueRecordCell scroll={scroll}>{children}</ValueRecordCell>
    </SimpleRecordRow>
  );
};

export default RecordRow;
