import { AttributeTypes } from "../../../../../constants";
import { BindingShape, Binding } from "../../../../../types";

export function getSchema(): Binding[] {
  return [
    {
      name: "value",
      shape: BindingShape.SCALAR,
      type: AttributeTypes.STRING
    },
    {
      name: "error",
      shape: BindingShape.UNKNOWN
    }
  ];
}
