import React from "react";

import { Input } from "antd";
import moment from "moment";
import momentTz from "moment-timezone";
import ReactDOM from "react-dom";
import { HotKeys } from "react-hotkeys";
import { Manager, Reference } from "react-popper";

import Popper from "../../common/Popper";
import { AttributeValueInputProps } from "../AttributeInput";
import {
  FormItemNoBottomMargin,
  PopperArrow,
  PopperContainer
} from "../StyledComponents";

import { PopoverPickerContainer } from "./styledComponents";
import { PORTAL_QUERY_SELECTOR, getPopperValue, MomentPopperProps } from "./utils";

export interface MomentFormItemProps extends AttributeValueInputProps {
  getFieldValue: (p: string) => any;
  onChange: (p: any) => void;
  saveFormat: string;
  displayFormat: string;
  PopoverPicker: React.ComponentType<MomentPopperProps>;
}

const MomentFormItemPopper = React.forwardRef(
  (props: MomentFormItemProps, formItemRef) => {
    const {
      getFieldValue,
      sourceName,
      onChange,
      saveFormat,
      PopoverPicker,
      displayFormat,
      displayNullPlaceholder,
      preventOverflow,
      disabled,
      onBlur = () => {},
      onFocus = () => {}
    } = props;
    // Value is necessary because the MomentFormItemPopper can mount before
    // the form registers the field
    const [initialFormValue, setInitialFormValue] = React.useState<string>(
      getFieldValue(sourceName)
    );
    const [visible, setVisible] = React.useState<boolean>(false);
    const [timeZone, setTimeZone] = React.useState<string | null>(null);
    const onCancel = () => {
      onBlur(new Event("focus"));
      setVisible(false);
    };

    const onKeyHandler = (e: React.KeyboardEvent) => {
      if (e.keyCode === 27 && visible) {
        e.stopPropagation();
        onCancel();
      }
    };

    // used when focus is in the popover
    const keyHandlers = {
      ESCAPE: onCancel
    };

    const keyHandlerProps = {
      onKeyUp: onKeyHandler,
      onKeyDown: onKeyHandler,
      onKeyPress: onKeyHandler
    };
    const currentValue = getFieldValue(sourceName);

    React.useEffect(() => {
      if (!initialFormValue && moment(currentValue, saveFormat).isValid()) {
        setInitialFormValue(currentValue);
      }
    }, [initialFormValue, currentValue, saveFormat]);

    const isTimestamp = props.saveFormat.includes("Z");
    const nowMoment = isTimestamp
      ? momentTz().tz(timeZone || momentTz.tz.guess())
      : moment();
    const onMomentChange = React.useCallback(
      (m: moment.Moment) => {
        isTimestamp
          ? onChange(m.utc().format(saveFormat))
          : onChange(m.format(saveFormat));
      },
      [isTimestamp, saveFormat, onChange]
    );

    const popperValue = getPopperValue(isTimestamp, currentValue, saveFormat, timeZone);
    const isValidValue = popperValue.isValid();

    return (
      <HotKeys handlers={keyHandlers} attach={window} focused={true}>
        <FormItemNoBottomMargin>
          <Manager>
            <Reference>
              {({ ref }) => (
                <div ref={ref} {...keyHandlerProps}>
                  <Input
                    ref={formItemRef as any}
                    placeholder={displayNullPlaceholder ? "NULL" : ""}
                    value={isValidValue ? popperValue.format(displayFormat) : undefined}
                    disabled={disabled}
                    onFocus={onFocus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const m = moment(e.target.value, displayFormat);
                      if (m.isValid()) {
                        onMomentChange(m);
                      } else {
                        onChange("");
                      }
                    }}
                    onClick={() => {
                      onFocus(new Event("focus"));
                      setVisible(true);
                      if (!initialFormValue) {
                        onMomentChange(nowMoment);
                      }
                    }}
                  />
                </div>
              )}
            </Reference>
            {visible &&
              ReactDOM.createPortal(
                <Popper
                  maskClosable
                  onCancel={onCancel}
                  placement="top-start"
                  modifiers={[
                    {
                      name: "preventOverflow",
                      enabled: preventOverflow
                    }
                  ]}
                >
                  {({ ref, style, placement, arrowProps }) => (
                    <PopperContainer ref={ref} style={style} data-placement={placement}>
                      <PopoverPickerContainer>
                        <PopoverPicker
                          setTimeZone={(p: string) => setTimeZone(p)}
                          value={isValidValue ? popperValue : nowMoment}
                          onChange={onMomentChange}
                        />
                      </PopoverPickerContainer>
                      <PopperArrow
                        ref={arrowProps.ref}
                        style={arrowProps.style}
                        data-placement={placement}
                      />
                    </PopperContainer>
                  )}
                </Popper>,
                document.querySelector(PORTAL_QUERY_SELECTOR) as Element
              )}
          </Manager>
        </FormItemNoBottomMargin>
      </HotKeys>
    );
  }
);

export default MomentFormItemPopper;
