import gql from "graphql-tag";

import { EXTENDED_ROLE_FRAGMENT } from "../../../../graphql/queries";
import { Connection, RelayNode, RoleNode } from "../../../../types";
import { FunctionPermissionInput } from "../../../settings/roles/FunctionPermissions/useFunctionPolicies";

export const SPACE_FEATURED_ROLE_DATA = gql`
  fragment SpaceFeaturedRole on SpaceNode {
    id
    name
    slug
    featuredRoles {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export interface Space {
  id: string;
  name: string;
  slug: string;
  featuredRoles: Connection<RoleNode>;
}

export const FRAGMENT_FUNCTION_DATA = gql`
  fragment FunctionData on FunctionNodeConnection {
    totalCount
    edges {
      node {
        id
        name
        title
        dataSource {
          id
          name
        }
        policies(roleId: $roleId) {
          edges {
            node {
              allowsAll
              policyFunctionParameters {
                edges {
                  node {
                    functionParameter {
                      id
                      name
                    }
                  }
                }
              }
              policyFunctionAttributes {
                edges {
                  node {
                    functionAttribute {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        functionParameters {
          edges {
            node {
              id
              name
              required
            }
          }
        }
        functionAttributes {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const FUNCTIONS_FOR_SPACE_NODE_VERSION = gql`
  query FunctionsForSpaceNodeVersion(
    $spaceId: ID!
    $environmentId: ID!
    $roleId: ID!
    $first: Int
    $offset: Int
    $searchText: String
  ) {
    node(id: $spaceId) {
      ... on SpaceNode {
        publishedVersion(environmentId: $environmentId) {
          ... on NoPublishedVersionErrorResult {
            message
          }
          ... on SpaceVersionNode {
            functions(first: $first, offset: $offset, searchText: $searchText) {
              ...FunctionData
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_FUNCTION_DATA}
`;

export const FUNCTIONS_FOR_SPACE_LATEST_VERSION = gql`
  query FunctionsForLatestVersion(
    $spaceId: ID!
    $roleId: ID!
    $first: Int
    $offset: Int
    $searchText: String
  ) {
    node(id: $spaceId) {
      ... on SpaceNode {
        latestVersion {
          functions(first: $first, offset: $offset, searchText: $searchText) {
            ...FunctionData
          }
        }
      }
    }
  }
  ${FRAGMENT_FUNCTION_DATA}
`;

export interface Identifiable {
  id: string;
}

export interface Nameable {
  name: string;
}

export interface TotalCountable {
  totalCount: number;
}

export interface PolicyFunctionParameters extends RelayNode {
  functionParameter: Identifiable & Nameable;
}

export interface PolicyFunctionAttributes extends RelayNode {
  functionAttribute: Identifiable & Nameable;
}

export interface RoleFunctionPermissions {
  allowsAll: boolean;
  policyFunctionParameters: Connection<PolicyFunctionParameters>;
  policyFunctionAttributes: Connection<PolicyFunctionAttributes>;
}

export interface FunctionNode extends Identifiable, Nameable {
  title: string;
  dataSource: Identifiable & Nameable;
  policies: Connection<RoleFunctionPermissions & Identifiable>;
  functionAttributes: Connection<Identifiable & Nameable>;
  functionParameters: Connection<Identifiable & Nameable & { required: boolean }>;
}

export interface FunctionsForSpaceNodeVersionData {
  node: {
    publishedVersion: {
      message?: string;
      functions?: Connection<FunctionNode> & TotalCountable;
    };
  };
}

export interface FunctionsForSpaceLatestVersionData {
  node: {
    latestVersion: {
      functions?: Connection<FunctionNode> & TotalCountable;
    };
  };
}

export interface Permission {
  id: string;
  name: string;
  type: "attribute" | "parameter";
  permitted: boolean;
  required: boolean;
}

export interface SpaceEnvironmentFunction {
  id: string;
  title: string;
  dataSource: string;
  permissionsGranted: number;
  permissions: Array<Permission>;
}

export type UpdateRolePermissionsData = RoleNode;
export type UpdateRolePermissionsVariables = {
  id: string;
  name: string;
  scopes: string[];
  applyPermissions: FunctionPermissionInput[];
};

export const UPDATE_ROLE_PERMISSIONS = gql`
  mutation UpdateRolePermissions(
    $id: ID!
    $name: String!
    $scopes: [String!]
    $applyPermissions: [FunctionPermissionInput!]
  ) {
    updateRolePermissions(
      id: $id
      name: $name
      scopes: $scopes
      applyPermissions: $applyPermissions
    ) {
      role {
        ...ExtendedRoleFragment
        scopes
      }
    }
  }
  ${EXTENDED_ROLE_FRAGMENT}
`;
