import gql from "graphql-tag";

import { ViewFilter, ViewOrder, DataValue } from "../../../../../../types";

export interface ViewRowsVariables {
  id: string | undefined;
  spaceId: string | undefined;
  environmentId: string;
  limit: number;
  filters: ViewFilter[];
  orders: ViewOrder[];
  after?: string;
  parameters: Record<string, DataValue>;
  columns: string[];
}

export const VIEW_ROWS_QUERY = gql`
  query ViewRows(
    $id: ID!
    $limit: Int = 1
    $filters: [ViewFilter]
    $orders: [ViewOrder]
    $after: String
    $spaceId: ID
    $environmentId: ID!
    $parameters: GenericScalar!
    $columns: [String!]
  ) {
    node(id: $id) {
      ... on ViewNode {
        id
        data(
          first: $limit
          filters: $filters
          orders: $orders
          after: $after
          spaceId: $spaceId
          environmentId: $environmentId
          parameters: $parameters
          columns: $columns
        ) {
          __typename
          ... on PermissionErrorResult {
            message
          }
          ... on ClientErrorResult {
            code
            message
            errorInfo {
              reason
              domain
              metadata
            }
          }
          ... on ViewDataResultSuccess {
            rows {
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                node {
                  id
                  row
                }
              }
            }
          }
        }
      }
    }
  }
`;
