import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Form, Input, Modal, Select } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AuthorizationFlowNode } from "../../../types";
import usePaths from "../../common/hooks/usePaths";

import { OAuthInputFields } from "./OAuthInputFields";

const CREATE_CUSTOM_AUTHORIZATION_PROVIDER = gql`
  mutation CreateCustomAuthFlow($name: String!) {
    createCustomAuthorizationProvider(name: $name) {
      ok
      flow {
        id
      }
    }
  }
`;

const CREATE_BASIC_AUTHORIZATION_PROVIDER = gql`
  mutation CreateBasicAuthorizationProvider($name: String!) {
    createBasicAuthorizationProvider(name: $name) {
      ok
    }
  }
`;

const CREATE_OAUTH_AUTHORIZATION_PROVIDER = gql`
  mutation CreateOAuthAuthorizationProvider(
    $name: String!
    $clientId: String!
    $clientSecret: String!
    $authorizationUrl: String!
    $tokenUrl: String!
    $scopes: [String]!
  ) {
    createOAuthAuthorizationProvider(
      name: $name
      clientId: $clientId
      clientSecret: $clientSecret
      authorizationUrl: $authorizationUrl
      tokenUrl: $tokenUrl
      scopes: $scopes
    ) {
      ok
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.errorColor};
`;

interface AddAuthProviderModalProps extends FormComponentProps {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

enum AuthProviderTypes {
  BASIC_AUTH = "BasicAuth",
  OAUTH = "OAuth",
  CUSTOM = "Custom"
}

const AddAuthProviderModal = (props: AddAuthProviderModalProps) => {
  const navigate = useNavigate();
  const { getAuthSettingsDetails } = usePaths();
  const form = props.form;
  const { getFieldDecorator } = form;

  const [authProviderType, setAuthProviderType] = useState<AuthProviderTypes>(
    AuthProviderTypes.BASIC_AUTH
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const mutationOptions = {
    onCompleted: () => {
      form.resetFields();
      props.handleOk();
    },
    onError: () => setErrorMessage("An unexpected error occurred.")
  };
  const [createBasicAuthProvider] = useMutation<{ ok: boolean }, { name: string }>(
    CREATE_BASIC_AUTHORIZATION_PROVIDER,
    mutationOptions
  );

  const [createOAuthProvider] = useMutation<
    { ok: boolean },
    {
      name: string;
      clientId: string;
      clientSecret: string;
      authorizationUrl: string;
      tokenUrl: string;
      scopes: string[];
    }
  >(CREATE_OAUTH_AUTHORIZATION_PROVIDER, mutationOptions);

  const [createCustomAuthProvider] = useMutation<
    {
      createCustomAuthorizationProvider: {
        ok: boolean;
        flow: AuthorizationFlowNode;
      };
    },
    { name: string }
  >(CREATE_CUSTOM_AUTHORIZATION_PROVIDER, {
    ...mutationOptions,
    onCompleted: (data: {
      createCustomAuthorizationProvider: {
        ok: boolean;
        flow: AuthorizationFlowNode;
      };
    }) => {
      mutationOptions.onCompleted();
      if (data?.createCustomAuthorizationProvider?.flow?.id) {
        navigate(
          getAuthSettingsDetails(data.createCustomAuthorizationProvider.flow.id)
        );
      }
    }
  });

  const handleCancel = () => {
    form.resetFields();
    props.handleCancel();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    form.validateFields(err => {
      if (!err) {
        setErrorMessage("");

        switch (authProviderType) {
          case AuthProviderTypes.BASIC_AUTH:
            return createBasicAuthProvider({
              variables: { name: form.getFieldValue("Name") }
            });
          case AuthProviderTypes.OAUTH:
            let scopeList = [];

            const scopes = form.getFieldValue("scopes");
            if (scopes) {
              scopeList = scopes.split(" ");
            }

            return createOAuthProvider({
              variables: {
                name: form.getFieldValue("Name"),
                clientId: form.getFieldValue("clientId"),
                clientSecret: form.getFieldValue("clientSecret"),
                authorizationUrl: form.getFieldValue("authUri"),
                tokenUrl: form.getFieldValue("tokenUri"),
                scopes: scopeList
              }
            });
          case AuthProviderTypes.CUSTOM:
            return createCustomAuthProvider({
              variables: { name: form.getFieldValue("Name") }
            });
          default:
            throw new Error("unexpected flow type");
        }
      }
    });
  };

  return (
    <Modal
      title="Add an Authorization Provider"
      visible={props.visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText="Save"
    >
      <Form hideRequiredMark layout="vertical" onSubmit={handleSubmit}>
        <Form.Item label="Name">
          {getFieldDecorator("Name", {
            rules: [{ required: true, message: "Name is required." }]
          })(<Input placeholder="Name" />)}
        </Form.Item>
        <Form.Item label="Auth Type">
          {getFieldDecorator("AuthType", {
            initialValue: authProviderType,
            rules: [{ required: true, message: "Auth Type is required." }]
          })(
            <Select onSelect={(t: any) => setAuthProviderType(t)}>
              <Select.Option value={AuthProviderTypes.BASIC_AUTH}>
                Basic Authorization
              </Select.Option>
              <Select.Option value={AuthProviderTypes.OAUTH}>OAuth 2.0</Select.Option>
              <Select.Option value={AuthProviderTypes.CUSTOM}>Custom</Select.Option>
            </Select>
          )}
        </Form.Item>
        {authProviderType === AuthProviderTypes.OAUTH && (
          <OAuthInputFields createMode getFieldDecorator={getFieldDecorator} />
        )}
      </Form>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Modal>
  );
};

export default Form.create<AddAuthProviderModalProps>({
  name: "addAuthProvider"
})(AddAuthProviderModal);
