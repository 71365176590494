import { QueryHookOptions } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

export const QUERY = gql`
  query GetStateById($id: ID!) {
    state(id: $id) {
      id
      name
      taskCount
    }
  }
`;

export interface Data {
  state: {
    id: string;
    name: string;
    taskCount: number;
  };
}

export interface Variables {
  id: string;
}

export const useGetStateById = (options?: QueryHookOptions<Data, Variables>) => {
  return useQuery<Data, Variables>(QUERY, options);
};
