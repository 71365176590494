import styled from "styled-components";

import { colors } from "../../../../cssConstants";
import { B3 } from "../../../common/StyledComponents";

export const Root = styled.div<{ gap?: number }>`
  background-color: ${colors.newContainerPrimary};
  border: 1px solid ${colors.newContainerPrimaryTint};
  border-radius: 8px;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled(B3)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const Title = styled(B3)`
  color: ${props => props.theme.surfaceSecondary};
`;

export const CenterCardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
