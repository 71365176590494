import styled from "styled-components";

const DescriptionList = styled.dl``;
DescriptionList.displayName = "DescriptionList";

const DescriptionWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacermd};

  &:last-child {
    margin-bottom: 0;
  }
`;
DescriptionWrapper.displayName = "DescriptionList.DescriptionWrapper";

const Term = styled.dt`
  width: 25%;
  margin-right: ${props => props.theme.spacersm};
  font-size: ${props => props.theme.tinyFontSize};
  line-height: 21px;
  color: ${props => props.theme.textColorMid};
  text-transform: uppercase;
`;
Term.displayName = "DescriptionList.Term";

const Details = styled.dd`
  display: flex;
  width: 75%;

  & > *:first-child {
    flex-grow: 1;
  }
`;
Details.displayName = "DescriptionList.Details";

export default { DescriptionList, DescriptionWrapper, Term, Details };
