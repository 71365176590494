import React from "react";

import styled from "styled-components";

import JsToggleButton from "../../../../../common/JsToggleButton/JsToggleButton";
import { BindingCascader } from "../BindingCascader";
import { ConditionalExpression, ConditionalExpressionType } from "../Conditionals";
import { ConfigSection } from "../ConfigPanel";

const Content = styled.div`
  > * {
    width: 100%;

    &:not(:first-child) {
      margin-top: 12px;
    }

    .ant-form-item-control {
      line-height: inherit;
    }
  }
`;

const StyledConfigSection = styled(ConfigSection)`
  /* prevent shifting when switching modes */
  min-height: 200px;
`;

const StyledJsToggleButton = styled(JsToggleButton)`
  position: absolute;
  top: ${props => props.theme.spacermd};
  right: ${props => props.theme.spacermd};
`;

export interface Props {
  header: string;
  rule: ConditionalExpressionType;
  defaultManagedRule: ConditionalExpressionType;
  defaultEvaluatedRule: ConditionalExpressionType;
  onChange: (rule: ConditionalExpressionType) => void;
}

export default function RuleDetails(props: Props) {
  const { header, rule, defaultManagedRule, defaultEvaluatedRule, onChange } = props;

  const subjectPicker =
    rule.type === "managed" ? (
      <BindingCascader
        data-test="bindingSelect"
        placeholder="Select a value"
        value={rule.subject_template || ""}
        onChange={(path: string) => {
          const updated = {
            ...rule,
            subject_template: path
          };
          onChange(updated);
        }}
      />
    ) : null;

  return (
    <StyledConfigSection title={header}>
      <StyledJsToggleButton
        isOn={rule.type === "evaluated"}
        onToggle={() => {
          onChange(
            rule.type === "evaluated" ? defaultManagedRule : defaultEvaluatedRule
          );
        }}
      />
      <Content>
        <ConditionalExpression
          expression={rule}
          subject={subjectPicker}
          onChange={onChange}
        />
      </Content>
    </StyledConfigSection>
  );
}
