import { cloneDeep, flow, partial } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import { SpaceComponentObject } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import { DefaultValueType } from "../../../constants";
import inputReducer from "../../common/inputReducer";
import { getCommonInputProperties } from "../../common/util";
import { hasInputComponentProperties } from "../../util/util";
import { SpaceDateTimePickerComponent, SpaceDateTimePickerProperties } from "../types";

export interface DateTimePickerConfigState
  extends BaseComponentConfigState<SpaceDateTimePickerComponent> {
  type: "DATE_TIME_PICKER";
}

export const INITIAL_STATE = {
  type: "DATE_TIME_PICKER" as const,
  draftComponent: {} as SpaceDateTimePickerComponent
};

function isDateTimePickerConfigState(
  state: ComponentConfigState
): state is DateTimePickerConfigState {
  return state.type === "DATE_TIME_PICKER";
}

export function ensureDateTimePickerConfigState(
  state: ComponentConfigState
): DateTimePickerConfigState {
  if (isDateTimePickerConfigState(state)) return state;

  throw new Error("Expected DateTimePicker config state.");
}

export const getComponentProperties = (
  properties: Partial<SpaceDateTimePickerProperties>
) => ({
  ...properties,
  ...getCommonInputProperties(
    properties,
    DefaultValueType.DATETIME_VALUE,
    "",
    AttributeTypes.TIMESTAMP,
    true
  )
});

export function ensureSpaceDateTimePickerComponent(
  component: SpaceComponentObject
): SpaceDateTimePickerComponent {
  if (
    !!component.type &&
    typeof component.properties.placeholder === "string" &&
    hasInputComponentProperties(component)
  ) {
    return component;
  }

  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): DateTimePickerConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceDateTimePickerComponent(cloneDeep(draftComponent))
  };
}

export default (state: DateTimePickerConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(inputReducer, partial.placeholder, action)])(
    state,
    action
  );
