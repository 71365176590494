/**
 * Editor component for SQL code input.
 * --
 * See FunctionEditor for UI notes.
 */
import React, { useEffect, useReducer } from "react";

import { useDebouncedCallback } from "use-debounce";

import { BaseCode, FunctionValidationStatus } from "../../..";
import { BaseFunctionName, DescribeColumn } from "../../../../../../types";
import { tryError } from "../../../../../util";
import SingleLineEditor from "../../../../SingleLineEditor";
import { ErrorMessageField } from "../../../common/ErrorMessageField";
import useDescribe from "../../../common/useDescribe";
import { BaseDataSource } from "../../common";
import { SqlQueryFunctionName } from "../../constants";
import { DataSourceFunction, GeneralActionTypes } from "../../types";
import {
  QueryBaseFunctionParameterMapping,
  RawBaseFunctionParameterMapping
} from "../SqlActionForm";

import reducer, { ActionTypes, getInitialState } from "./reducer";
import * as styled from "./styledComponents";
import { format, formatRaw, nonUnique } from "./util";

export interface Props {
  func?: DataSourceFunction;
  actionType: SqlQueryFunctionName;
  selectedDataSource: BaseDataSource;
  setDescribeColumns: (columns: DescribeColumn[]) => void;
  setBaseConfig: (
    baseFunctionParameterMapping:
      | QueryBaseFunctionParameterMapping
      | RawBaseFunctionParameterMapping,
    code: BaseCode
  ) => void;
  setValidationStatus: (status: FunctionValidationStatus) => void;
}

export const QueryForm = ({
  actionType,
  func,
  selectedDataSource,
  setBaseConfig,
  setDescribeColumns,
  setValidationStatus
}: Props) => {
  const initialState = getInitialState(actionType, func);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { describe } = useDescribe();

  const { sql, args, status, error } = state;

  useEffect(() => {
    const mapping =
      actionType === BaseFunctionName.QUERY ? format(sql, args) : formatRaw(sql, args);
    setBaseConfig(mapping, { sql, args: JSON.stringify(args) });
  }, [actionType, sql, args, setBaseConfig]);

  useEffect(() => {
    setValidationStatus(status);
  }, [status, setValidationStatus]);

  const setErrorMessage = (errorMessage: string) =>
    dispatch({
      type: GeneralActionTypes.SET_ERROR_MESSAGE,
      payload: { fieldName: "", errorMessage }
    });

  const doDescribe = async () => {
    if (sql.trim() === "") {
      dispatch({ type: GeneralActionTypes.RESET_FIELDS, payload: null });
      return;
    }

    try {
      const payload = await describe(selectedDataSource.id, sql);
      const dupes = nonUnique(payload.columns.map(c => c.sourceName));
      if (dupes.length !== 0) {
        setErrorMessage(
          `Duplicate column names: ${dupes.join(
            ", "
          )}. Please provide an alias for columns that have the same name.`
        );
        return;
      }

      dispatch({
        type: ActionTypes.DESCRIBE_SUCCESS,
        payload: {
          args: payload.parameters.map((param, i) => ({
            name: param.sourceName,
            value: args[i]?.value || param.sourceName || `parameter${i + 1}`
          }))
        }
      });
      setDescribeColumns(
        actionType === BaseFunctionName.SQL_EXECUTE ? [] : payload.columns
      );
    } catch (e) {
      const err = tryError(e);
      setErrorMessage(err.message);
      return;
    }
  };

  const onChange = async (sql: string) => {
    dispatch({ type: ActionTypes.DESCRIBE_START, payload: { sql } });
    debounced.callback();
  };

  const debounced = useDebouncedCallback(doDescribe, 1000);

  return (
    <styled.Container>
      <styled.EditorContainer>
        <styled.Editor mode="sql" isFullHeight={true} onChange={onChange} value={sql} />
        {args.length > 0 && (
          <styled.Parameters>
            {args.map((arg, i) => {
              return (
                <styled.ParameterRow key={i}>
                  {arg.name || `Parameter ${i + 1}`}:
                  <SingleLineEditor
                    value={arg.value}
                    onChange={value =>
                      dispatch({
                        type: ActionTypes.SET_ARG_VALUE,
                        payload: { index: i, value }
                      })
                    }
                  />
                </styled.ParameterRow>
              );
            })}
          </styled.Parameters>
        )}
      </styled.EditorContainer>
      <ErrorMessageField errorMessage={error} />
    </styled.Container>
  );
};
