import React from "react";

import { Button, Col, Icon as AntdIcon, Input, Row, Select, Table } from "antd";
import styled from "styled-components";

import { colors } from "../../../cssConstants";
import ButtonNew from "../ButtonNew/ButtonNew";
import { IndeterminateSwitch } from "../IndeterminateSwitch/IndeterminateSwitch";
import { ModalNew } from "../Modal";
import { B2, B3, H3 } from "../StyledComponents";
import { UpDownChevron } from "../ToggleChevron";

export const StyledModal = styled(ModalNew)`
  max-width: 1400px;

  .ant-modal-title {
    color: ${colors.surfaceSecondary};
  }

  .ant-modal-close {
    top: 4px;
    right: 4px;
  }

  .ant-modal-content {
    min-height: 85vh;
    border-radius: 8px;

    .ant-modal-header {
      padding: 24px 48px 24px 48px;
    }

    .ant-modal-body {
      padding: 0 48px;
    }
  }
`;

export const CloseIcon = styled(AntdIcon)`
  color: ${colors.surfacePrimary};
`;

export const HeaderName = styled(H3)`
  margin-top: 32px;
`;

export const RoleListItem = styled.div`
  border: 1px solid ${colors.newContainerPrimaryTint};
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;

  &.expanded {
    border-color: ${colors.primaryAccent};
  }
`;

export const RoleHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 63px;

  background-color: ${colors.newContainerPrimary};

  &:hover {
    background-color: ${colors.newContainerHover};
  }

  &.expanded {
    border-bottom: 1px solid ${colors.newContainerPrimaryTint};
  }
`;

export const ShareWithRoleName = styled.div`
  line-height: 19px;
  flex-grow: 1;
`;

export const PermissionsTable = styled(Table)`
  min-height: 210px;

  .full-width {
    .ant-table-header-column {
      width: 100%;
    }
  }

  // Remove the spacing after the table so our footer can be flush against it
  &:after {
    height: 0 !important;
    content: unset;
  }

  .ant-table-placeholder {
    border: none;
  }
`;

export const StyledTableRow = styled.tr`
  &:hover {
    td {
      background-color: ${colors.newContainerHover} !important;
    }
  }

  &.expanded {
    td {
      background-color: ${colors.newContainerHover};
      border: none;
    }
  }

  &.ant-table-row-level-1 {
    &:hover {
      td {
        background-color: ${colors.newContainerPrimary} !important;
      }
    }

    td {
      background-color: ${colors.newContainerPrimary} !important;
      border: none !important;
      padding-top: 0;
      padding-bottom: 0;

      &:last-child {
        display: inline-block;
        width: 100%;
      }
    }
  }

  th,
  td {
    &:first-child {
      padding-left: 29px !important;
    }

    &:last-child {
      padding-right: 32px !important;
    }
  }
`;

export const StyledTableRowHeader = styled.th`
  background-color: white !important;
  border-top: none !important;

  font-weight: 600 !important;
  font-size: 13px;
  line-height: 19px;
  color: ${colors.surfacePrimary} !important;
`;

export const IconButton = styled(Button)`
  border: none;
  background-color: unset;
  box-shadow: none;

  &:hover {
    background-color: unset;
  }

  &:active {
    background-color: unset;
  }

  &:focus {
    background-color: unset;
  }

  &[disabled] {
    background-color: unset;
    border: none;

    &:hover {
      background-color: unset;
      border: none;
    }
  }
`;

export const FunctionFieldLabel = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  color: ${colors.surfaceSecondary};
  border: 1px solid ${colors.surfaceTertiary};
  border-radius: 2px;

  padding: 0 6px;
  margin-left: 11px;
`;

export const FunctionFieldCell = styled.span`
  min-width: 70x;
  margin-left: 14.5px;
  cursor: pointer;
  user-select: none;
`;

export const StyledToggleChevron = styled(UpDownChevron)`
  margin-left: 8.6px;
`;

export const PrefixSwitchLabel = styled(B3)`
  min-width: 30px;
  display: inline-block;
  text-align: left;
`;

export const DisabledText = styled.span`
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  color: ${colors.textDisabled};
`;

export const InternalEmpty = function ({
  content,
  className
}: {
  content: string;
  className?: string;
}) {
  return (
    <div className={className}>
      <B2>{content} </B2>
    </div>
  );
};

export const StyledInternalEmpty = styled(InternalEmpty)`
  min-height: 222px;
  color: ${colors.surfacePrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexRow = styled(Row)`
  flex-wrap: nowrap;
`;

export const RightCol = styled(Col)`
  text-align: right;
`;

export const HelpIcon = styled(AntdIcon)`
  min-width: 20px;
`;

export const StyledHeaderButton = styled(ButtonNew)`
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  padding: 8px 14px;

  &.fakeDisabled {
    opacity: 0.6;
    color: white;
    background: #29292d;
    border-color: transparent;
    cursor: not-allowed;
  }
`;

export const StyledButtonSecondary = styled(ButtonNew)`
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  padding: 8px 14px;

  background-color: ${colors.buttonSecondaryFill};

  &:focus {
    background-color: ${colors.buttonSecondaryFill};
  }

  &:hover {
    background-color: ${colors.buttonSecondaryFillHover};
  }

  &:active {
    background-color: ${colors.buttonSecondaryFillActive};
  }
`;

export const ButtonContainer = styled.div`
  min-width: 185px;
  text-align: right;

  button + button {
    margin-left: 8px;
  }
`;

export const PermissionsHeader = styled.div`
  padding: 25px 29px 0px;
`;

export const SpaceBetweenLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
`;

export const EnvironmentSelect = styled(Select)`
  width: 305px;
`;

export const StyledInput = styled(Input)`
  width: 245px;
`;

export const TablePaginationButton = styled(IconButton)`
  color: ${colors.surfacePrimary};
  &[disabled]: {
    color: ${colors.surfaceDisabled};
  }

  &:hover {
    color: ${colors.buttonPrimaryFillHover};
  }

  svg {
    width: 10px;
    height: 16px;
  }
`;

export const FunctionH5 = styled.h5`
  font-size: 15px;
  line-height: 21px;
  font-family: var(--body-font-family);
  font-weight: 600;
`;

export const RolePermissionsRoot = styled.div`
  background-color: white;
`;

export const AutoGrantAllHeaderSwitch = styled(IndeterminateSwitch)`
  margin-left: 11px;
`;
