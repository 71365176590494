import { Checkbox as AntCheckbox, Form } from "antd";
import styled from "styled-components";

export const Item = styled.div`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const Label = styled.div`
  margin-bottom: ${props => props.theme.spacersm};
`;

export const Control = styled.div``;

export const FormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0px;
  }
`;

export const Checkbox = styled(AntCheckbox)`
  margin-bottom: ${props => props.theme.spacermd};
`;
