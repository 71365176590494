import { BaseComponentProperties, SpaceComponentObject } from "../../../../../types";
import { ComponentAuthProperties } from "../common/types";

export enum LinkTarget {
  Self = "self",
  Blank = "blank",
  Download = "download"
}

export type SpaceLinkProperties = BaseComponentProperties &
  ComponentAuthProperties & {
    label: string;
    url_template: string;
    text_template: string;
    target: LinkTarget;
  };

export type SpaceLinkComponent = SpaceComponentObject<SpaceLinkProperties>;
