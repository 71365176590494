import { AttributeNode, FunctionAttributeNode } from "../../../../../../types";

export enum RenderType {
  DEFAULT = "default",
  ICON = "custom",
  LINK = "link",
  DATE_TEMPLATE = "templated_date",
  PILLBOX = "pillbox",
  AUTOLINK = "autolink"
}

function attributeHasOutboundFunctionLink(
  attribute: AttributeNode | FunctionAttributeNode
) {
  if (attribute.function === undefined) return false;
  const outbounds =
    attribute.function.outboundFunctionLinks?.edges.map(e => e.node) || [];

  return outbounds.some(
    o => Object.values(o.parameterAttributeMapping)[0] === attribute.sourceName
  );
}

type RenderTypeRule = {
  condition: (attribute: AttributeNode) => boolean;
  type: RenderType;
};

export const GENERAL_RENDER_TYPES = [
  RenderType.DEFAULT,
  RenderType.DATE_TEMPLATE,
  RenderType.ICON,
  RenderType.PILLBOX,
  RenderType.LINK
];

export const CONDITIONAL_RENDER_TYPES: RenderTypeRule[] = [
  {
    condition: attr => attr.sourcePrimaryKey || attributeHasOutboundFunctionLink(attr),
    type: RenderType.AUTOLINK
  }
];

export function getRenderTypes(attribute: AttributeNode): RenderType[] {
  const conditionalRenderTypes = CONDITIONAL_RENDER_TYPES.filter(({ condition }) =>
    condition(attribute)
  ).map(({ type }) => type);
  return conditionalRenderTypes.concat(GENERAL_RENDER_TYPES);
}

export function getInitialRenderType(
  attribute: AttributeNode | FunctionAttributeNode
): RenderType {
  if (attribute.sourcePrimaryKey || attributeHasOutboundFunctionLink(attribute)) {
    return RenderType.AUTOLINK;
  }
  return RenderType.DEFAULT;
}

export const RENDER_TYPE_DISPLAY_NAMES: {
  [key in RenderType]: string;
} = {
  default: "Text",
  custom: "Icon",
  pillbox: "Pillbox",
  link: "Link",
  templated_date: "Date/Time format",
  autolink: "Autolink"
};

export enum RenderIcon {
  CHECK = "check",
  CLOSE = "close",
  CLOCK = "clock",
  BULB = "bulb",
  CALENDAR = "calendar",
  CAR = "car",
  CREDIT_CARD = "creditcard",
  THUNDERBOLT = "thunderbolt",
  SHOPPING_CART = "shopping-cart",
  STOP = "stop",
  WARNING = "warning",
  BELL = "bell",
  ENVIRONMENT = "environment",
  FLAG = "flag",
  HEART = "heart",
  HOME = "home",
  MESSAGE = "message",
  NOTIFICATION = "notification",
  PHONE = "phone",
  PUSHPIN = "pushpin",
  TOOL = "tool",
  ROCKET = "rocket",
  CERT = "cert",
  SMILE = "smile",
  STAR = "star",
  TROPHY = "trophy",
  LOCK = "lock",
  USER = "user",
  NONE = "none"
}

export enum RenderColor {
  GREEN = "green",
  RED = "red",
  PINK = "pink",
  ORANGE = "orange",
  BLUE = "blue",
  PURPLE = "purple",
  YELLOW = "yellow",
  WHITE = "white",
  GREY = "grey",
  NONE = "none"
}

export const RENDER_TYPES_WITH_VALUE_RENDER_OPTIONS = [
  RenderType.PILLBOX,
  RenderType.ICON
];

export interface ValueRenderOption {
  value: any;
  icon?: RenderIcon;
  label: string;
  schema?: RenderColor;
}
