import { parse } from "acorn";
import { recursive } from "acorn-walk";

import { AttributeTypes } from "../../../../constants";
import {
  AttributeFilterProperties,
  ChangeSetProperties,
  Edge,
  FunctionParameterInput,
  FunctionParameterNode
} from "../../../../types";
import { tryError } from "../../../util";
import { getFormattedJavaScriptError } from "../../../util/javascript";
import { KeyValue } from "../../KeyValueInputs";
import { parseIdentifiers } from "../../parseUtils";
import { HttpFunctionMetadata, IdentifierMap } from "../index";

import { HttpMethods, RequestBodyType } from "./constants";
import { FieldType } from "./http/bodies/Form/utils";
import { HttpFunctionBaseState } from "./http/reducer";
import { DataSourceFunction, ParameterSettings } from "./types";

// converts array of key value objects to template string of JSON object,
// ie. [{ key: '"foo"', value: "bar" }] -> `{"foo": bar}`
export const formatKeyValuesForSubmit = (keyValues: KeyValue[]) => {
  return `{${keyValues
    .filter(keyValue => !isEmptyString(keyValue.key) && !!keyValue.value)
    .map(({ key, value }: KeyValue) => `[${key}]: ${value}`) // wrap keys in brackets to handle as computed property names
    .join()}}`;
};

// convert template string to valid JSON array of key value objects with string values
// ie. `{"foo": bar}` -> [{ key: '"foo"', value: "bar" }]
export const getKeyValuesFromTemplateString = (template: string) => {
  const DEFAULT_VALUE = "``";
  if (!template || typeof template !== "string" || template === "{}") {
    return [{ key: DEFAULT_VALUE, value: DEFAULT_VALUE }];
  }
  const result: KeyValue[] = [];
  try {
    const formattedInput = `(${template})`;
    const ast = parse(formattedInput);
    recursive(ast, undefined, {
      ObjectExpression(node: any) {
        const propNodes = node.properties;
        propNodes.forEach((propNode: { key: any; value: any }) => {
          const key = formattedInput.substring(propNode.key.start, propNode.key.end);
          const value = formattedInput.substring(
            propNode.value.start,
            propNode.value.end
          );
          result.push({ key, value });
        });
      }
    });
  } catch (e) {
    // noop
  }
  return result;
};

// converts array of attribute filter objects with string values to template string
// ie. [{ column: "name", operator: "=", value: "filter_name" }] -> `[{"column": "name", "operator": "=", "value": filter_name}]`
export const formatFiltersForSubmit = (filters: AttributeFilterProperties[]) => {
  return `[${filters
    .filter(filter => filter.column && filter.operator)
    .map(
      ({ column, operator, value }: AttributeFilterProperties) =>
        `{"column": "${column}", "operator": "${operator}", "value": ${value || '""'}}` // TODO update when support string and non-string inputs
    )
    .join()}]`;
};

// converts template string to array of attribute filter objects with string values
// ie. `[{"column": "name", "operator": "=", "value": filter_name}]` ->
// [{ column: "name", operator: "=", value: "filter_name" }]
export const getFiltersFromTemplateString = (template: string) => {
  const result: AttributeFilterProperties[] = [];
  try {
    const formattedInput = `(${template})`;
    const ast = parse(formattedInput);
    recursive(ast, undefined, {
      ObjectExpression(node: any) {
        const obj: AttributeFilterProperties = {};
        const propNodes = node.properties;
        propNodes.forEach((propNode: { key: any; value: any }) => {
          const key = JSON.parse(
            formattedInput.substring(propNode.key.start, propNode.key.end)
          );
          const valueStr = formattedInput.substring(
            propNode.value.start,
            propNode.value.end
          );
          // if parsing value for "value" key, do not need to JSON.parse
          const value = key === "value" ? valueStr : JSON.parse(valueStr);
          obj[key as "column" | "operator" | "value"] = value;
        });
        result.push(obj);
      }
    });
  } catch (e) {
    // noop
  }
  return result;
};

// converts array of changeset objects with string values to template string
// ie. [{ column: "name", value: "set_name" }] -> `{"name": set_name}`
export const formatChangeSetForSubmit = (sets: ChangeSetProperties[]) => {
  return `{${sets
    .filter(set => !!set.column)
    .map(({ column, value }: ChangeSetProperties) => `"${column}": ${value || '""'}`) // TODO update when support string and non-string inputs
    .join()}}`;
};

// converts template string to array of changeset objects with string values
// ie. `{"name": set_name}` -> [{ column: "name", value: "set_name" }]
export const getChangeSetsFromTemplateString = (template: string) => {
  const result: ChangeSetProperties[] = [];
  try {
    const formattedInput = `(${template})`;
    const ast = parse(formattedInput);
    recursive(ast, undefined, {
      ObjectExpression(node: any) {
        const propNodes = node.properties;
        propNodes.forEach((propNode: { key: any; value: any }) => {
          const key = JSON.parse(
            formattedInput.substring(propNode.key.start, propNode.key.end)
          );
          const value = formattedInput.substring(
            propNode.value.start,
            propNode.value.end
          );
          result.push({ column: key, value: value });
        });
      }
    });
  } catch (e) {
    // noop
  }
  return result;
};

export const getParsedString = (valueStr: string) => {
  if (valueStr) {
    try {
      return JSON.parse(valueStr);
    } catch {
      return valueStr;
    }
  }
  return undefined;
};

export const getFunctionParameterInputs = (
  identifiers: Set<string>,
  parameterSettings: ParameterSettings
): FunctionParameterInput[] => {
  const result: FunctionParameterInput[] = [];
  identifiers.forEach(identifier => {
    const param: FunctionParameterInput = {
      name: identifier,
      type: parameterSettings[identifier]
        ? parameterSettings[identifier].type
        : AttributeTypes.STRING,
      required: parameterSettings[identifier]
        ? parameterSettings[identifier].required
        : true
    };
    result.push(param);
  });
  return result;
};

export const getFunctionParameterSettings = (
  functionParameterEdges: Edge<FunctionParameterNode>[]
) => {
  const paramSettings = functionParameterEdges.reduce(
    (settings, edge: Edge<FunctionParameterNode>) => {
      const param = edge.node;
      return {
        ...settings,
        [param.name]: {
          type: param.type,
          required: param.required
        }
      };
    },
    {}
  );
  return paramSettings;
};

// TODO: Remove when baseFunctionParameterMapping is returned as an object from the API.
export const getBaseFunctionParameterMapping = (baseFunctionParameterMapping: any) => {
  if (typeof baseFunctionParameterMapping !== "string") {
    return baseFunctionParameterMapping;
  }
  try {
    const obj = JSON.parse(baseFunctionParameterMapping);
    return obj;
  } catch (e) {
    return {};
  }
};

const getRequestType = (metadata: any) => {
  if (metadata && metadata.request_type) {
    return metadata.request_type;
  }
  return RequestBodyType.None;
};

const getGraphqlData = (metadata: any, jsonStr: string) => {
  const requestType = getRequestType(metadata);
  const graphqlData =
    requestType === RequestBodyType.GraphQL && jsonStr
      ? getGraphqlQueryDataFromJsonString(jsonStr)
      : undefined;
  return graphqlData;
};

export const getHttpFunctionParams = (
  func: DataSourceFunction<HttpFunctionMetadata>
): HttpFunctionBaseState => {
  const baseFunctionParameterMapping = getBaseFunctionParameterMapping(
    func.baseFunctionParameterMapping
  );
  const metadata = {
    request_type: RequestBodyType.None,
    ...func.metadata
  };
  return parseBaseFunctionParameterMapping(baseFunctionParameterMapping, metadata);
};

export const parseBaseFunctionParameterMapping = (
  baseFunctionParameterMapping: any,
  metadata: HttpFunctionMetadata
): HttpFunctionBaseState => {
  const requestType = getRequestType(metadata);
  const graphqlData = getGraphqlData(metadata, baseFunctionParameterMapping.json_body);
  const path = baseFunctionParameterMapping.path
    ? baseFunctionParameterMapping.path
    : "``";
  const method = getParsedString(baseFunctionParameterMapping.method) as HttpMethods;
  const urlParameters = getKeyValuesFromTemplateString(
    baseFunctionParameterMapping.query
  );
  const headers = getKeyValuesFromTemplateString(baseFunctionParameterMapping.header);
  const graphqlQuery = graphqlData?.query || "";
  const graphqlVariables = graphqlData?.variables || "";

  let body: RequestBodyType | "" = "";
  switch (requestType) {
    case RequestBodyType.JSON:
      body = baseFunctionParameterMapping.json_body || "";
      break;
    case RequestBodyType.Raw:
      body = baseFunctionParameterMapping.raw_body || "";
      break;
    case RequestBodyType.Binary:
      body = baseFunctionParameterMapping.binary_body || "";
      break;
  }

  return {
    metadata,
    path,
    method,
    urlParameters,
    headers,
    body,
    graphqlQuery,
    graphqlVariables
  };
};

export const getBodyFromGraphqlValues = (
  queryValue: string,
  queryVariableValue: string
) => {
  const body = `{
  "query": ${JSON.stringify(queryValue)}${
    queryVariableValue
      ? `, 
  "variables": ${queryVariableValue}`
      : ""
  }
}`;
  return body;
};

// quote or unquote value when switching between Raw and other request types
export const getBodyForRequestType = (body: string, requestType: RequestBodyType) => {
  let updatedBody = body;
  // first convert to non-quoted value if request type is not Raw
  if (requestType !== RequestBodyType.Raw && isTemplateQuoted(body)) {
    updatedBody = updatedBody.substring(1, updatedBody.length - 1);
  } else if (requestType === RequestBodyType.Raw && !isTemplateQuoted(body)) {
    // convert value to string when sending over
    updatedBody = `\`${body}\``;
  }
  return updatedBody;
};

// used for general function fields and specific function form fields
export const isFunctionValid = (errors: any) => {
  const hasError =
    errors &&
    Object.keys(errors).some(errorKey => {
      return !!errors[errorKey];
    });
  return !hasError;
};

export const getGraphqlQueryDataFromJsonString = (template: string) => {
  const graphqlData = {
    query: "",
    variables: ""
  };
  try {
    const formattedInput = `(${template})`;
    const ast = parse(formattedInput);
    recursive(ast, undefined, {
      ObjectExpression(node: any) {
        const propNodes = node.properties;
        propNodes.forEach((propNode: { key: any; value: any }) => {
          const key = JSON.parse(
            formattedInput.substring(propNode.key.start, propNode.key.end)
          );
          const value = formattedInput.substring(
            propNode.value.start,
            propNode.value.end
          );
          if (key === "query") {
            graphqlData.query = JSON.parse(value);
          } else if (key === "variables") {
            graphqlData.variables = value;
          }
        });
      }
    });
  } catch (e) {
    // noop - it is possible for a json body to not be convertible to graphql, so do nothing
  }
  return graphqlData;
};

export const getHttpIdentifiersFromState = (s: HttpFunctionBaseState) => {
  const { path, urlParameters, headers, metadata, body, graphqlVariables } = s;
  const { request_type: requestType, code } = metadata;
  const bodyContent = requestType === RequestBodyType.GraphQL ? graphqlVariables : body;
  let map: IdentifierMap = parseIdentifiers(path);
  urlParameters.forEach(urlParam => {
    map = {
      ...map,
      ...parseIdentifiers(urlParam.key),
      ...parseIdentifiers(urlParam.value)
    };
  });
  headers.forEach(header => {
    map = {
      ...map,
      ...parseIdentifiers(header.key),
      ...parseIdentifiers(header.value)
    };
  });
  map = {
    ...map,
    ...parseIdentifiers(bodyContent)
  };
  code?.fields?.forEach(field => {
    map = {
      ...map,
      ...parseIdentifiers(field.key),
      ...Object.entries(parseIdentifiers(field.value)).reduce<IdentifierMap>(
        (acc, [name, identifier]) => ({
          ...acc,
          [name]: {
            ...identifier,
            type: field.type === FieldType.FILE ? AttributeTypes.FILE : undefined
          }
        }),
        {}
      )
    };
  });
  return map;
};

export const GENERAL_JSON_ERROR = "Please enter a valid JSON object.";
export const getJsonError = (input: string) => {
  let hasError = true;
  try {
    /*
     * The following code works as follows:
     * `recursive` ensures a top down traversal (see https://www.npmjs.com/package/acorn-walk for more details)
     *  Wrapping the input in parens before parsing allows the parser to evaluate the input as an expression,
     *    so there will always be an `ExpressionStatement` at the top.
     *  For JSON to be valid, we want the first child after that to either be an identifier (ie. `some_val`)
     *    or object (ie. `{"key": "val"}`. For there to be another type in addition to `Identifier`
     *    or `ObjectExpression`, there would need to be a different type at the top level after
     *    `ExpressionStatement` that parents the two, ie. `SequenceExpression` or other.
     *  See https://developer.mozilla.org/en-US/docs/Mozilla/Projects/SpiderMonkey/Parser_API for details on Node types
     */
    const ast = parse(`(${input})`);
    recursive(ast, undefined, {
      ExpressionStatement(node: any) {
        switch (node.expression.type) {
          case "Identifier":
          case "ObjectExpression":
            hasError = false;
            break;
          default:
            hasError = true;
        }
      }
    });
  } catch (e) {
    // error parsing string (error message contains issue and location)
    // location consists of line number (1-indexed) and a column number (0-indexed)
    // see https://developer.mozilla.org/en-US/docs/Mozilla/Projects/SpiderMonkey/Parser_API `Position` for details
    const formattedError = getFormattedJavaScriptError(tryError(e));
    formattedError.message = `Invalid JSON: ${formattedError.message}`;
    return formattedError;
  }
  if (hasError) {
    // valid expression, but not a JSON object
    return new Error(GENERAL_JSON_ERROR);
  } else {
    return null;
  }
};

// checks against strings that are empty strings
export const isEmptyString = (input: string) => {
  return input === "" || input === "``" || input === "''" || input === `""`;
};

export const isKeyValueInputValid = (inputs: KeyValue[] | undefined) => {
  if (!inputs) return true;
  const hasError = inputs.some(input => {
    // if missing key
    if (isEmptyString(input.key) && !isEmptyString(input.value)) {
      return true;
    }
    if (
      getJavaScriptParsingError(input.key, true) ||
      getJavaScriptParsingError(input.value, true)
    ) {
      return true;
    }
    return false;
  });
  return !hasError;
};

export const getJavaScriptParsingError = (
  input: string,
  allowEmptyString?: boolean
) => {
  if (allowEmptyString && input === "") {
    return null;
  }
  try {
    parse(`(${input})`);
  } catch (e) {
    return getFormattedJavaScriptError(tryError(e));
  }
  return null;
};

export const isTemplateQuoted = (input: string) => {
  return input.charAt(0) === "`" && input.charAt(input.length - 1) === "`";
};
