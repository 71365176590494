import React from "react";

import AirtableLogo from "../../assets/airtable_logo.svg";
import BigQueryLogoSvg from "../../assets/bigquery.svg";
import CassandraLogo from "../../assets/cassandra_logo.svg";
import DynamoDBLogo from "../../assets/dynamodb_logo.svg";
import FirebaseLogoSvg from "../../assets/firebase_logo.svg";
import GoogleSheetsLogoSvg from "../../assets/google_sheets_logo.svg";
import GraphQLLogoSvg from "../../assets/graphql_logo.svg";
import HTTPLogo from "../../assets/http_logo.svg";
import HubSpotLogo from "../../assets/hubspot_logo.svg";
import MailgunLogo from "../../assets/mailgun_logo.svg";
import MongoLogo from "../../assets/mongo_logo.svg";
import MySQLLogoSvg from "../../assets/mysql.svg";
import PostgresLogoSvg from "../../assets/postgres_logo.svg";
import QueuesLogo from "../../assets/queues-logo.svg";
import RedshiftLogo from "../../assets/redshift_log.svg";
import S3Logo from "../../assets/s3_logo.svg";
import SalesForceLogo from "../../assets/salesforce_logo.svg";
import SnowflakeLogo from "../../assets/snowflake_logo.svg";
import MSSQLLogo from "../../assets/sqlserver_logo.svg";
import StripeLogo from "../../assets/stripe_logo.svg";
import ZendeskLogo from "../../assets/zendesk_logo.svg";

const logos: Record<string, React.ComponentType> = {
  airtable: AirtableLogo,
  bigquery: BigQueryLogoSvg,
  cassandra: CassandraLogo,
  dynamodb: DynamoDBLogo,
  firestore: FirebaseLogoSvg,
  google_sheets: GoogleSheetsLogoSvg,
  graphql: GraphQLLogoSvg,
  http: HTTPLogo,
  hubspot: HubSpotLogo,
  mailgun: MailgunLogo,
  mongo: MongoLogo,
  mssql: MSSQLLogo,
  mysql: MySQLLogoSvg,
  postgresql: PostgresLogoSvg,
  queues: QueuesLogo,
  redshift: RedshiftLogo,
  s3: S3Logo,
  salesforce: SalesForceLogo,
  snowflake: SnowflakeLogo,
  stripe: StripeLogo,
  zendesk: ZendeskLogo
};

interface Props {
  name: string;
  defaultLogo?: React.ComponentType;
}

const IntegrationLogo = ({ name, defaultLogo }: Props) => {
  const Logo = logos[name] || defaultLogo;
  return Logo ? <Logo /> : null;
};

export default IntegrationLogo;
