import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";
import FormItem from "antd/lib/form/FormItem";
import gql from "graphql-tag";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../../constants";
import { SpacingUnit } from "../../../../cssConstants";
import { client, partialAuthClient, publicClient } from "../../../../graphql";
import { AuthStatus } from "../../../../types";
import {
  withAuthStatus,
  AuthStatusProps
} from "../../../auth/WithAuthStatus/withAuthStatus";
import Button from "../../../common/Button";
import { AUTH_STATUS } from "../../../common/hooks/useAuthStatus";
import Input from "../../../common/Input";
import Message from "../../../common/Message";
import TitleContainer, { ContainerSize } from "../../../common/TitleContainer";
import { USER_PROFILE_QUERY } from "../../UserProfile";

export const TwoFactorTokenContainer = styled.div`
  margin-top: ${SpacingUnit.sm};
  text-align: center;

  p {
    color: ${props => props.theme.textColorMid};
  }

  .confirmForm {
    margin-top: ${SpacingUnit.lg};
  }
`;

export const CONFIRM_DEVICE_MUTATION = gql`
  mutation ConfirmDevice($token: String!) {
    confirmDevice(token: $token) {
      user {
        id
        email
        organization {
          id
        }
        roles {
          edges {
            node {
              id
              isManaged
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const ConfirmTwoFactorComponent = (
  props: FormComponentProps & AuthStatusProps
) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const next = params.get("next");
  const { refetch } = useQuery(AUTH_STATUS, { client: publicClient });
  const [confirmTwoFactor] = useMutation(CONFIRM_DEVICE_MUTATION, {
    refetchQueries:
      props.status === AuthStatus.Authenticated
        ? [
            {
              query: USER_PROFILE_QUERY
            }
          ]
        : [],
    client: props.status === AuthStatus.Authenticated ? client : partialAuthClient,
    awaitRefetchQueries: true,
    onCompleted: res => {
      const ok = _.get(res, "confirmDevice.user", false);
      if (!ok) {
        Message.error("Invalid token. Please try again");
      } else {
        refetch().then(() => {
          if (next) {
            const url = new URL(next);
            navigate(url.pathname + url.search);
          } else {
            navigate(Routes.USER_PROFILE);
          }
          notification.success({
            message: "Two-Step Verification is now set!",
            description:
              "Whenever you log in, you'll be prompted for a code generated by your authenticator app. ",
            style: { backgroundColor: "#f6ffed", border: "1px solid #b7eb8f" }
          });
        });
      }
    }
  });
  const { getFieldDecorator } = props.form;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }

      confirmTwoFactor({
        variables: { token: values.Token }
      });
    });
  };

  return (
    <React.Fragment>
      <TitleContainer title="Confirm that it works." size={ContainerSize.Large}>
        <TwoFactorTokenContainer>
          <p>
            Enter the code you see in your Authenticator app to finish setting up 2-step
            verification.
          </p>
          <div className="confirmForm">
            <Form onSubmit={handleSubmit}>
              <FormItem>
                {getFieldDecorator("Token", {
                  rules: [{ required: true }]
                })(
                  <Input
                    autocomplete="off"
                    customSize="medium"
                    placeholder={"Enter your code."}
                  />
                )}
              </FormItem>
              <Button size="medium" htmlType="submit" type="primary">
                Complete Setup
              </Button>
            </Form>
          </div>
        </TwoFactorTokenContainer>
      </TitleContainer>
    </React.Fragment>
  );
};

export default Form.create({ name: "confirm2fa" })(
  withAuthStatus(ConfirmTwoFactorComponent)
);
