import React from "react";

import styled from "styled-components";

import { BindingShape, ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import { BindingCascader } from "../../common/BindingCascader";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError";
import { Field } from "../../common/ConfigPanel/styledComponents";
import NameFields from "../../common/NameFields";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

export const ConfigPopoverField = styled.div`
  max-width: 60%;
  margin-bottom: ${props => props.theme.spacerxl};
`;

export const Label = styled.label`
  margin-bottom: ${props => props.theme.spacerlg};
  font-size: ${props => props.theme.largeFontSize};
`;

// Configuration Popover for the SpaceDataViewer component.  At first this will be used to view the result of a function
// execution, but a derivative or future version of this could be bound to almost anything we expose as a binding in
// a space.
export default function Config({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer dataConfig={<DataViewerConfig />} slug={slug} />
  );
}

export function DataViewerConfig() {
  const { state, dispatch } = useComponentConfigContext();
  const { draftComponent } = state;

  return (
    <>
      <BaseComponentConfigSection>
        <NameFields shouldRenderTitle />
        <Field>
          <Label>What to display</Label>
          <BindingCascader
            data-test="dataViewerBindingCascader"
            value={draftComponent.properties.binding || ""}
            selectable={[
              BindingShape.SCALAR,
              BindingShape.OBJECT,
              BindingShape.OBJECT_ARRAY,
              BindingShape.UNKNOWN
            ]}
            changeOnSelect
            onChange={(path: string) => {
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.binding",
                  value: path
                }
              });
            }}
          />
          <ValidationError field="BINDING" />
        </Field>
      </BaseComponentConfigSection>
      <VisibilityRulesManagerSection />
    </>
  );
}
