import Icon from "../../../../../assets/spaceicons/dynamic-button.svg";
import { SpaceComponentPackage, SpaceComponentHostType } from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import { getSchema } from "../common/FunctionBackedPopover";

import SpaceDynamicFunction from "./SpaceDynamicFunction";

const pkg: SpaceComponentPackage = {
  type: "DYNAMIC_FUNCTION",
  displayName: "Dynamic Button",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["TABLE", "DETAIL", "CARD_LIST"]),
  getSchema,
  Component: SpaceDynamicFunction,
  ensureComponent: component => component,
  componentConfigReducer: commonComponentReducer,
  ConfigurationComponent: null, // we no longer support modifying a dynamic button's config; users must use Visibility Rules instead
  defaultElementLayout: new ElementLayout({
    width: "auto",
    height: "auto",
    snapToGrid: false
  }),
  allowSelfBinding: false,
  allowAncestorBinding: false,
  isHeadless: false,
  errorSelector: () => [],
  isContainer: false
};

export default pkg;
