import styled from "styled-components";

import { B3 } from "../../../../common/StyledComponents";
import { IconButton } from "../../common/styledComponents";

export const Item = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;

  background-color: ${props => (props.selected ? props.theme.containerActive : "none")};
  padding: 0 16px 0 40px;
  cursor: pointer;
`;

export const Title = styled(B3)`
  margin-left: 12px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  align-self: stretch;
`;

export const DragItem = styled(Item)`
  padding: 0 16px 0 0;

  .drag-item-icon {
    margin: 0 16px 0 16px;
    height: 18px;
    visibility: hidden;
  }

  &:hover .drag-item-icon {
    visibility: visible;
  }
`;

interface DotProps {
  color?: string;
}

export const Dot = styled.div<DotProps>`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${props => (props.color ? props.color : props.theme.primaryColor)};
`;

export const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 14px;
`;

export const DeleteButton = styled(IconButton)`
  margin-left: auto;
`;
