import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  margin-bottom: ${props => props.theme.spacersm};
`;

export const Parameter = styled.div`
  margin-bottom: ${props => props.theme.spacermd};
`;
