import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectFiltersErrors from "../../common/FilterListManager/selectErrors";
import selectParameterErrors from "../../common/ParametersManager/selectors/selectErrors";
import { selectViewConfigErrors } from "../../common/useViewConfig";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";
import {
  AggregationFunction,
  AggregationFunctionType,
  GroupingFunction
} from "../types";

import { ensureChartConfigState } from "./reducer";

function validateAggregationFunction(func: AggregationFunction) {
  switch (func.type) {
    case AggregationFunctionType.COUNT:
      return !func.attribute;
    case AggregationFunctionType.SUM:
      return !!func.attribute;
    case AggregationFunctionType.PREAGGREGATED:
      return !!func.attribute;
    default:
      return false;
  }
}

function validateGroupingFunction(func: GroupingFunction) {
  return !!func.attribute;
}

export default function errorSelector(
  state: ComponentConfigState,
  findInvalidInputBindings: (component: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { draftComponent, filterValidityTrackers, attributes } =
    ensureChartConfigState(state);

  if (attributes && attributes.length < 1) {
    errors.push({
      field: "FUNCTION",
      message: "Please select a function with at least one attribute."
    });
  }

  if (
    !validateAggregationFunction(draftComponent.properties.y_axis.aggregation_function)
  ) {
    errors.push({
      field: "Y_AXIS",
      message: "Please select an attribute for the aggregation calculation."
    });
  }

  if (!validateGroupingFunction(draftComponent.properties.x_axis.grouping_function)) {
    errors.push({
      field: "X_AXIS",
      message: "Please select an attribute to group by."
    });
  }

  if (!draftComponent.properties.sub_group_functions.every(validateGroupingFunction)) {
    errors.push({
      field: "SUB_GROUPING_FUNCTION",
      message: "Please select an attribute to group by."
    });
  }

  return errors
    .concat(
      selectFiltersErrors(
        filterValidityTrackers,
        draftComponent,
        findInvalidInputBindings
      )
    )
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(selectViewConfigErrors(draftComponent))
    .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings));
}
