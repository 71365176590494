import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "react-apollo";

import { AUTOMATION_NODE_FRAGMENT } from "../queries/common";
import { SaveAutomationResult } from "../queries/SaveAutomation";

export interface AutomationSettingsInput {
  id: string;
  name: string;
  icon?: string;
  color?: string;
  description: string;
}

export interface Variables {
  automation: AutomationSettingsInput;
}

export interface Data {
  updateAutomationSettings: SaveAutomationResult;
}

const UPDATE_AUTOMATION_SETTINGS = gql`
  mutation UpdateAutomationSettings($automation: AutomationSettingsInput!) {
    updateAutomationSettings(automation: $automation) {
      __typename
      ...AutomationNodeFragment
      ... on ValidationErrorResult {
        messages {
          text
          index
        }
      }
    }
  }
  ${AUTOMATION_NODE_FRAGMENT}
`;

export const useUpdateAutomationSettings = (
  options?: MutationHookOptions<Data, Variables>
) => {
  return useMutation<Data, Variables>(UPDATE_AUTOMATION_SETTINGS, options);
};
