import React from "react";

import { Select } from "antd";

import { BaseFunctionName, DescribeColumn } from "../../../../../types";
import { ErrorMessageField } from "../../common/ErrorMessageField";
import { BaseFunctionProps } from "../common";
import {
  isSQLQueryFunctionName,
  isSQLWriteFunctionName,
  SqlFunctionDisplayNames,
  SqlFunctionName
} from "../constants";
import * as common from "../styledComponents";

import { QueryForm } from "./QueryForm/QueryForm";
import * as styled from "./styledComponents";
import { getSqlFunctionTypes } from "./util";
import { WriteBaseFunctionParameterMapping, WriteForm } from "./WriteForm/WriteForm";

const { Option } = Select;

export interface InsertBaseFunctionParameterMapping {
  into: string;
  values: string;
}

export interface DeleteBaseFunctionParameterMapping {
  from: string;
  where: string;
  multiple: string;
}

export interface UpdateBaseFunctionParameterMapping {
  table: string;
  where: string;
  set: string;
  multiple: string;
}

export interface QueryBaseFunctionParameterMapping {
  select: string;
  from: string;
  where: string;
  order_by: string;
  limit: string;
  offset: string;
  arguments: string;
}

export interface RawBaseFunctionParameterMapping {
  sql: string;
  arguments: string;
}

export type SqlBaseFunctionParameterMapping =
  | QueryBaseFunctionParameterMapping
  | WriteBaseFunctionParameterMapping;

export interface SqlActionProps extends BaseFunctionProps {
  setDescribeColumns?: (columns: DescribeColumn[]) => void;
  setBaseFunctionName: (name: BaseFunctionName) => void;
}

const SqlActionForm = (props: SqlActionProps) => {
  const actionType = props.func?.baseFunction?.name;
  const onActionTypeChange = (value: SqlFunctionName) => {
    props.setBaseFunctionName(value);
  };
  const isEditMode = !!props.func?.id;
  const actionTypes = getSqlFunctionTypes(props.selectedDataSource.integration);
  const errorMessage = !actionType ? "Please select an action type." : "";

  return (
    <common.Container>
      <common.FormGridContainerWithMaxWidth>
        <common.GridLabel>Action type:</common.GridLabel>
        <common.GridFormItem vertical>
          <common.Select
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            data-test="actionTypeSelect"
            placeholder="Select an action type"
            value={actionType}
            onChange={value => onActionTypeChange(value as SqlFunctionName)}
            disabled={isEditMode || (actionTypes.length === 1 && !!actionType)}
          >
            {actionTypes.map(type => (
              <Option value={type} key={type}>
                {SqlFunctionDisplayNames[type]}
              </Option>
            ))}
          </common.Select>
          <ErrorMessageField errorMessage={props.showErrors ? errorMessage : ""} />
        </common.GridFormItem>
        {isSQLWriteFunctionName(actionType) && (
          <WriteForm
            key={props.func?.id}
            func={props.func}
            actionType={actionType}
            selectedDataSource={props.selectedDataSource}
            setBaseConfig={props.setBaseConfig}
            setValidationStatus={props.setValidationStatus}
            showErrors={props.showErrors}
          />
        )}
      </common.FormGridContainerWithMaxWidth>
      <styled.QueryContainer>
        {isSQLQueryFunctionName(actionType) && (
          <QueryForm
            func={props.func}
            actionType={actionType}
            selectedDataSource={props.selectedDataSource}
            setDescribeColumns={props.setDescribeColumns!}
            setBaseConfig={props.setBaseConfig}
            setValidationStatus={props.setValidationStatus}
          />
        )}
      </styled.QueryContainer>
    </common.Container>
  );
};

export default SqlActionForm;
