import gql from "graphql-tag";

export interface ExecutePresignData {
  executePresign: {
    ok: boolean;
    urlString: string;
    __typename: "ExecutePresignFunction";
  };
}

export interface ExecutePresignVars {
  functionId: string;
  path: string;
  responseContentDisposition?: string;
}

export const EXECUTE_PRESIGN = gql`
  mutation ExecuteFunction(
    $functionId: ID!
    $path: String!
    $responseContentDisposition: String
  ) {
    executePresign(
      functionId: $functionId
      path: $path
      responseContentDisposition: $responseContentDisposition
    ) {
      ok
      urlString
      __typename
    }
  }
`;
