import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { assertNever } from "../../../../../util/assertNever";
import { ComponentConfigState } from "../../../../types";
import validateConditionalExpression from "../../common/Conditionals/validateConditional";
import { ensureFilterListManagerState } from "../../common/FilterListManager/reducer/reducer";
import selectFiltersErrors from "../../common/FilterListManager/selectErrors";
import selectParameterErrors from "../../common/ParametersManager/selectors/selectErrors";
import { selectViewConfigErrors } from "../../common/useViewConfig";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";
import { AggregationFunctionType } from "../../SpaceChart/types";

import { ensureStatConfigState } from "./reducer";

export default function selectErrors(
  state: ComponentConfigState,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const { draftComponent } = state;
  const errors: ConfigValidationError[] = [];
  return errors
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(
      selectFiltersErrors(
        ensureFilterListManagerState(state).filterValidityTrackers,
        draftComponent,
        findInvalidInputBindings
      )
    )
    .concat(selectViewConfigErrors(draftComponent))
    .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings))
    .concat(selectAggregationFunctionErrors(state))
    .concat(selectConditionalStyleErrors(state));
}

function selectAggregationFunctionErrors(
  state: ComponentConfigState
): ConfigValidationError[] {
  const {
    attributes,
    draftComponent: {
      properties: { aggregation_function }
    }
  } = ensureStatConfigState(state);

  switch (aggregation_function.type) {
    case AggregationFunctionType.COUNT:
      return [];
    case AggregationFunctionType.PREAGGREGATED:
    case AggregationFunctionType.SUM: {
      if (aggregation_function.attribute === undefined) {
        return [
          {
            field: "AGGREGATION_FUNCTION",
            message: "Select an attribute to aggregate."
          }
        ];
      }
      if (
        !(attributes || [])
          .map(a => a.sourceName)
          .includes(aggregation_function.attribute)
      ) {
        return [
          {
            field: "AGGREGATION_FUNCTION",
            message:
              "The aggregation attribute is no longer returned by the selected function."
          }
        ];
      }
      return [];
    }
    default:
      assertNever(aggregation_function.type);
  }
  throw new Error("Unexpected aggregation function");
}

function selectConditionalStyleErrors(
  state: ComponentConfigState
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const {
    draftComponent: {
      properties: { conditional_styles }
    }
  } = ensureStatConfigState(state);
  conditional_styles.forEach((cs, index) => {
    const conditional_errors = validateConditionalExpression(cs.conditional_expression);
    conditional_errors.forEach(message => {
      errors.push({
        field: "CONDITIONAL_STYLES",
        message,
        index
      });
    });
  });
  return errors;
}
