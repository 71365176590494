import { AttributeTypes } from "../../../constants";
import {
  DataValue,
  InputParameter,
  ParameterType,
  Resolver,
  SpaceComponentPackage,
  SpaceComponentType
} from "../../../types";
import { assertNever } from "../../util/assertNever";
import { cacheBy } from "../../util/cacheBy";

import { BindingSchema, FunctionNode } from "./types";
import {
  ensureComponentProperties,
  getDefaultComponentType,
  getFunctionParameter,
  getParameter
} from "./utils";

type RequireOnly<T, K extends keyof T> = Partial<Omit<T, K>> & Required<Pick<T, K>>;

export interface InternalField {
  name: string;
  isAutoConfigured: boolean;
}

export enum ActionType {
  LOAD_FUNCTION = "LOAD_FUNCTION",
  UPDATE_PARAMETER = "UPDATE_PARAMETER",
  UPDATE_PARAMETER_TYPE = "UPDATE_PARAMETER_TYPE",
  UPDATE_PARAMETER_ORDER = "UPDATE_PARAMETER_ORDER",
  SET_PARAMETER_VALIDITY = "SET_PARAMETER_VALIDITY"
}

interface LoadFunction {
  type: ActionType.LOAD_FUNCTION;
  payload: {
    func: FunctionNode;
    allowedParameterTypes: ParameterType[];
  };
}

interface UpdateParameter {
  type: ActionType.UPDATE_PARAMETER;
  payload: RequireOnly<Parameter, "name">;
}

interface UpdateParameterType {
  type: ActionType.UPDATE_PARAMETER_TYPE;
  payload: {
    name: string;
    type: ParameterTypeOption;
  };
}

interface UpdateParameterOrder {
  type: ActionType.UPDATE_PARAMETER_ORDER;
  payload: {
    sourceIndex: number;
    destinationIndex: number;
  };
}

interface SetParameterValidity {
  type: ActionType.SET_PARAMETER_VALIDITY;
  payload: {
    name: string;
    isValid: boolean;
  };
}

export type Action =
  | LoadFunction
  | UpdateParameter
  | UpdateParameterType
  | UpdateParameterOrder
  | SetParameterValidity;

export interface Parameter {
  name: string;
  type: ParameterType;
  attributeType: AttributeTypes;
  required: boolean;
  included: boolean;
  binding: string | undefined;
  resolver: Resolver | undefined;
  template: string | undefined;
  value: DataValue | undefined;
  componentType: SpaceComponentType | undefined;
  componentProperties: Record<string, any> | undefined;
}

export interface FormState {
  additionalSchema: Record<string, BindingSchema>;
  initialInputParameters: InputParameter[];
  parameters: Parameter[];
  func?: FunctionNode;
  parameterErrors: Record<string, boolean>;
  allowedParameterTypes: ParameterType[];
  internalFields: InternalField[];
}

export type ParameterTypeOption = ParameterType | "null";

export const reducerFactory =
  (
    findSpaceComponentPackage: (
      type: SpaceComponentType
    ) => SpaceComponentPackage | undefined,
    customReducer?: (state: FormState, action: Action) => FormState
  ) =>
  (state: FormState, action: Action): FormState => {
    // each action must update `updatedState` instead of return state early
    // because if a customReducer is passed in, that handling
    // is done after any handling in this base reducer.
    let updatedState: FormState;
    switch (action.type) {
      case ActionType.LOAD_FUNCTION: {
        const { func, allowedParameterTypes } = action.payload;
        const ips = cacheBy(state.initialInputParameters, "name");
        const isInternalParameter = (parameterName: string) => {
          return state.internalFields.map(field => field.name).includes(parameterName);
        };

        const internalParameters: Parameter[] = func.functionParameters.edges
          .filter(({ node }) => {
            return isInternalParameter(node.name);
          })
          .map(({ node }) => {
            const ip = ips[node.name];
            return getParameter(
              node,
              ip,
              state.allowedParameterTypes,
              state.parameters,
              state.initialInputParameters,
              findSpaceComponentPackage
            );
          })
          .filter(parameter => !!parameter) as Parameter[];

        // enabled parameters
        const inputParameters: Parameter[] = state.initialInputParameters
          .filter(ip => !isInternalParameter(ip.name))
          .map(ip => {
            const node = getFunctionParameter(ip.name, func.functionParameters);
            if (!node) return undefined;
            return getParameter(
              node,
              ip,
              state.allowedParameterTypes,
              state.parameters,
              state.initialInputParameters,
              findSpaceComponentPackage
            );
          })
          .filter(p => p !== undefined) as Parameter[];

        // disabled parameters
        const remainingFunctionParameters: Parameter[] = func.functionParameters.edges
          .filter(({ node }) => {
            return !ips[node.name] && !isInternalParameter(node.name);
          })
          .map(({ node }) => {
            return getParameter(
              node,
              undefined,
              state.allowedParameterTypes,
              state.parameters,
              state.initialInputParameters,
              findSpaceComponentPackage
            );
          });

        updatedState = {
          ...state,
          allowedParameterTypes,
          func,
          parameters: internalParameters
            .concat(inputParameters)
            .concat(remainingFunctionParameters)
        };
        break;
      }
      case ActionType.UPDATE_PARAMETER: {
        const idx = state.parameters.findIndex(p => p.name === action.payload.name);
        if (idx === -1) throw new Error("illegal state");
        const copy = [...state.parameters];
        const p = {
          ...state.parameters[idx],
          ...action.payload
        };
        p.componentProperties = ensureComponentProperties(
          p.name,
          p.attributeType,
          state.parameters,
          state.initialInputParameters,
          findSpaceComponentPackage,
          p.componentType,
          p.componentProperties
        );
        copy.splice(idx, 1, p);
        updatedState = { ...state, parameters: copy };
        break;
      }
      case ActionType.UPDATE_PARAMETER_TYPE: {
        const { name, type } = action.payload;
        const fp = state.func
          ? getFunctionParameter(name, state.func.functionParameters)
          : undefined;
        const idx = state.parameters.findIndex(p => p.name === name);
        if (idx === -1) throw new Error("illegal state");
        const parametersCopy = [...state.parameters];
        const p: Parameter = {
          ...state.parameters[idx],
          componentType: undefined,
          componentProperties: {},
          binding: undefined,
          resolver: undefined,
          template: undefined,
          value: undefined,
          required: fp?.required === undefined ? true : fp.required
        };
        if (type === "null") {
          p.type = ParameterType.STATIC;
          p.value = null;
          // update input parameter to allow blank values (required=false)
          // so that `null` values may be submitted. Blank values are allowed
          // even for required function parameters because a value (ie. `null`)
          // will always be submitted.
          p.required = false;
        } else {
          p.type = type;
        }

        if (p.type === ParameterType.COMPONENT) {
          p.componentType = fp ? getDefaultComponentType(fp.type) : undefined;
          p.componentProperties = ensureComponentProperties(
            p.name,
            p.attributeType,
            state.parameters,
            state.initialInputParameters,
            findSpaceComponentPackage,
            p.componentType,
            p.componentProperties
          );
        }
        parametersCopy.splice(idx, 1, p);

        // clear errors
        const parameterErrorsCopy = { ...state.parameterErrors };
        delete parameterErrorsCopy[name];

        updatedState = {
          ...state,
          parameters: parametersCopy,
          parameterErrors: parameterErrorsCopy
        };
        break;
      }
      case ActionType.UPDATE_PARAMETER_ORDER: {
        const { sourceIndex, destinationIndex } = action.payload;
        // internal task parameters are always first and cannot be reordered
        const offset = state.internalFields.length;
        const copy = [...state.parameters];
        const param = copy.splice(sourceIndex + offset, 1)[0];
        copy.splice(destinationIndex + offset, 0, param);
        updatedState = { ...state, parameters: copy };
        break;
      }
      case ActionType.SET_PARAMETER_VALIDITY: {
        const { name, isValid } = action.payload;
        updatedState = {
          ...state,
          parameterErrors: {
            ...state.parameterErrors,
            [name]: !isValid
          }
        };
        break;
      }
      default:
        return assertNever(action);
    }
    if (customReducer) {
      updatedState = customReducer(updatedState, action);
    }
    return updatedState;
  };
