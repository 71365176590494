import React, { useState } from "react";

import { Icon as AntIcon } from "antd";
import styled from "styled-components";

import { Cursor } from "../../../../../../types";
import { getRecordIdentifier } from "../../util";
import RelatedRecords from "../RelatedRecords/RelatedRecords";

export interface Props {
  name: string;
  cursor: Cursor;
  isOutbound: boolean;
}

const ResourceWrapper = styled.div`
  margin: ${props => props.theme.spacerlg} 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ToggleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled(AntIcon)`
  margin-right: ${props => props.theme.spacerlg};
`;

const EmptyContainer = styled.div`
  margin: ${props => props.theme.spacersm} 0 ${props => props.theme.spacersm}
    ${props => props.theme.spacerxl};
  overflow: hidden;
`;

export function ToggleableRelatedRecords({ name, cursor, isOutbound }: Props) {
  const [open, setOpen] = useState(false);
  const { key } = getRecordIdentifier(cursor);
  const valid = Object.values(key).every(
    value => value !== null && value !== undefined
  );
  return (
    <ResourceWrapper>
      <ToggleRow onClick={() => setOpen(!open)}>
        {open ? <strong>{name}</strong> : <span>{name}</span>}
        <Icon type={open ? "down" : "right"} />
      </ToggleRow>
      {open &&
        (valid ? (
          <RelatedRecords cursor={cursor} isOutbound={isOutbound} />
        ) : (
          <EmptyContainer>No records found</EmptyContainer>
        ))}
    </ResourceWrapper>
  );
}

export default ToggleableRelatedRecords;
