import React from "react";

import { Input } from "antd";
import styled from "styled-components";

import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDebouncedOutputSyncing from "../common/useDebouncedOutputSyncing";
import { Props } from "../SpaceComponent";

import { ensureCustomFieldComponent } from "./Config/reducer";

interface RootProps {
  editMode: boolean;
  hasError: boolean;
}
const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${props =>
    props.editMode &&
    `
      & input[disabled] {
        color: ${props.theme.textColor};
        background-color: white;
        cursor: not-allowed;
        opacity: 1;
      }
    `}

  ${props =>
    props.hasError &&
    `
    .ant-input,
    .ant-input:focus,
    .ant-input:hover {
      border-color: ${props.theme.errorColor};
      box-shadow: none;
    }
  `}

  .ant-switch {
    margin-right: 3px;
  }
`;

export default function SpaceCustomField({ spaceComponent, hasConfigError }: Props) {
  const { properties } = ensureCustomFieldComponent(spaceComponent);
  const { type = "text", placeholder = "placeholder text…" } = properties;
  const { editMode } = useStableSpaceContext();
  const { localValue, setLocalValue, errorMessage, hasPermissionError } =
    useDebouncedOutputSyncing();

  let inputComponent = null;
  switch (type) {
    case "text":
      inputComponent = (
        <Input
          placeholder={placeholder}
          value={
            hasPermissionError || localValue === undefined || localValue === null
              ? ""
              : localValue
          }
          onChange={e => setLocalValue(e.target.value)}
        />
      );
      break;
    default:
      throw new Error(`Unexpected CustomField type ${type}`);
  }

  return (
    <EditModeInteractionBlocker>
      <Root editMode={editMode} hasError={!!errorMessage}>
        <FlexContainer>
          {hasConfigError && <AbsoluteErrorIcon />}
          {inputComponent}
          {hasPermissionError && <PermissionWarningIcon />}
        </FlexContainer>
        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </Root>
    </EditModeInteractionBlocker>
  );
}
