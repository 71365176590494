import React from "react";

import { Button, Icon } from "antd";
import styled from "styled-components";

import { StatusCode } from "../../../types";

interface Props {
  errorCode: StatusCode;
  customOnClick?: () => void;
  customCTAText?: string;
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

const ErrorContainer = styled.div`
  text-align: center;
  max-width: 600px;
`;

const ErrorHeader = styled.header`
  font-size: ${props => props.theme.h2FontSize};
  color: ${props => props.theme.textColor};
  margin-bottom: ${props => props.theme.spacersm};
  text-align: center;

  i {
    margin-right: ${props => props.theme.spacersm};
    color: ${props => props.theme.warningColor};
  }
`;
ErrorHeader.displayName = "ErrorHeader";

const ErrorMessage = styled.div`
  white-space: normal;
  color: ${props => props.theme.textColorMid};
  margin-bottom: ${props => props.theme.spacerlg};
  text-align: center;
`;

ErrorMessage.displayName = "ErrorMessage";

const ErrorFooter = styled.div`
  text-align: center;
`;
ErrorFooter.displayName = "ErrorFooter";

const ViewErrorMessage = ({ errorCode, customOnClick, customCTAText }: Props) => {
  let headerMessage;
  let errorSnippet;
  let showFooter = true;

  switch (errorCode) {
    case StatusCode.INVALID_ARGUMENT:
    case StatusCode.OUT_OF_RANGE:
      headerMessage = "Hmm, we couldn't find anything that matched your query.";
      errorSnippet = "Please try something else.";
      break;
    case StatusCode.NOT_FOUND:
      headerMessage =
        "There may be changes to your data preventing this data from being displayed.";
      errorSnippet = "Please contact a company admin.";
      break;
    case StatusCode.PERMISSION_DENIED:
    case StatusCode.UNAUTHENTICATED:
      headerMessage =
        "The credentials you entered are incorrect or you are not authorized to view this data.";
      errorSnippet = "Please contact a company admin.";
      break;
    case StatusCode.DEADLINE_EXCEEDED:
      headerMessage = "Your query timed out.";
      errorSnippet =
        "Please try again. If the issue persists, contact a company admin.";
      break;
    case StatusCode.UNAVAILABLE:
    case StatusCode.RESOURCE_EXHAUSTED:
      headerMessage = "Internal could not access this data.";
      errorSnippet =
        "Please try again. If the issue persists, contact a company admin.";
      break;
    case StatusCode.UNIMPLEMENTED:
      headerMessage =
        "This environment is missing the necessary credentials to display data.";
      errorSnippet = "Please contact a company admin.";
      showFooter = false;
      break;
    case StatusCode.UNKNOWN:
    default:
      headerMessage = "Internal could not access this data.";
      errorSnippet = "Please contact a company admin.";
  }

  return (
    <Root>
      <ErrorContainer>
        <ErrorHeader>
          <Icon theme="filled" type="exclamation-circle" />
          <span>{headerMessage}</span>
        </ErrorHeader>
        <ErrorMessage>{errorSnippet}</ErrorMessage>
        {showFooter && customOnClick && (
          <ErrorFooter>
            <Button type="primary" onClick={customOnClick}>
              {customCTAText || "See details"}
            </Button>
          </ErrorFooter>
        )}
      </ErrorContainer>
    </Root>
  );
};

export default ViewErrorMessage;
