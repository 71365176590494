import styled from "styled-components";

import { SpacingUnit } from "../../../../cssConstants";

export const TwoFactorDrawerContainer = styled.div`
  .row {
    display: flex;
    align-items: baseline;

    &.spaced {
      justify-content: space-between;
    }

    p {
      margin-right: ${SpacingUnit.xs};
    }
  }

  p {
    margin-bottom: 0px;
  }

  .icon_and_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 21px;

    &.bold {
      margin-bottom: 24px;

      p {
        color: ${props => props.theme.primaryColor};
        font-weight: 600;
      }

      &:hover {
        cursor: pointer;
      }
    }

    i {
      color: ${props => props.theme.primaryColor};
      margin-right: ${SpacingUnit.sm};
    }

    p {
      color: ${props => props.theme.textColorMid};
    }
  }

  button:first-child {
    margin-right: ${SpacingUnit.sm};
  }

  #org-two-factor_required {
    p {
      white-space: nowrap;
    }
  }
`;

export const CheckboxInfo = styled.div`
  display: flex;

  p {
    margin-left: ${SpacingUnit.md};
    color: ${props => props.theme.textColorMid};
  }
`;
