import deepmerge from "deepmerge";
import { cloneDeep, set } from "lodash";

import { SpaceComponentObject } from "../../../../../../types";
import { ElementStyle } from "../../../../layout/util";
import {
  ComponentConfigState,
  BaseConfigAction,
  SpaceConfigAction
} from "../../../../types";

type ArrayMergeFunc<T> = (destination: Array<T>, source: Array<T>) => Array<T>;
interface MergeDraftComponent extends BaseConfigAction {
  type: "MERGE_DRAFT_COMPONENT";
  payload: {
    change: Partial<SpaceComponentObject>;
    arrayMerge?: ArrayMergeFunc<any>;
  };
}

interface SetDraftComponent extends BaseConfigAction {
  type: "SET_DRAFT_COMPONENT";
  payload: {
    path: string;
    value: any;
  };
}

interface ReplaceComponentConfigState extends BaseConfigAction {
  type: "REPLACE_COMPONENT_CONFIG_STATE";
  payload: {
    state: ComponentConfigState;
  };
}

interface UpdateComponentStyle extends BaseConfigAction {
  type: "UPDATE_COMPONENT_STYLE";
  payload: {
    styles: Record<string, ElementStyle>;
  };
}

export type BaseComponentConfigAction =
  | MergeDraftComponent
  | SetDraftComponent
  | ReplaceComponentConfigState
  | UpdateComponentStyle;

export default function baseReducer<T extends ComponentConfigState>(
  state: T,
  action: SpaceConfigAction
): T {
  switch (action.type) {
    case "MERGE_DRAFT_COMPONENT": {
      const { change, arrayMerge = (_destination: [], source: []): [] => source } =
        action.payload;
      return {
        ...state,
        draftComponent: deepmerge(state.draftComponent, change, {
          arrayMerge
        })
      };
    }

    case "SET_DRAFT_COMPONENT": {
      const { path, value } = action.payload;
      return {
        ...state,
        draftComponent: set(cloneDeep(state.draftComponent), path, value)
      };
    }

    case "REPLACE_COMPONENT_CONFIG_STATE": {
      return action.payload.state as T;
    }

    case "UPDATE_COMPONENT_STYLE": {
      const { styles } = action.payload;

      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          properties: {
            ...state.draftComponent.properties,
            styles
          }
        }
      };
    }

    default:
      return state;
  }
}
