import React from "react";

import { colorTokens } from "../../../cssConstants";

export const COLOR_OPTIONS = [
  colorTokens.greyTransparent1300,
  colorTokens.red300,
  colorTokens.red200,
  colorTokens.orange300,
  colorTokens.yellow200,
  colorTokens.green400,
  colorTokens.blue200,
  colorTokens.black
];

function hashString(value: string): number {
  if (value.length === 0) {
    return 0;
  }

  let hash = 0;

  for (let i = 0; i < value.length; i++) {
    const chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

function stringToColor(value: string): string {
  const index = Math.abs(hashString(value) % COLOR_OPTIONS.length);

  return COLOR_OPTIONS[index];
}

export function useColorConverter(value?: string) {
  return React.useMemo(() => {
    if (!value) {
      return undefined;
    }

    return stringToColor(value);
  }, [value]);
}
