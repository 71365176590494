import { uniqueId } from "lodash";
import { Column } from "react-table";

import { assertNever } from "../../util/assertNever";

interface TableReducerState {
  visibleColumns: ColumnType[];
  scrollbarWidth: number;
  measureRequest: string;
  allowSelectMultiple: boolean;
}

export const initialState: TableReducerState = {
  visibleColumns: [],
  scrollbarWidth: 0,
  measureRequest: uniqueId("measureRequest"),
  allowSelectMultiple: false
};

const multiSelectColWidth = 50;

export type ColumnType = Partial<Column & { hidden: boolean }>;

type TableReducerAction =
  | { type: "RESIZE_TABLE" }
  | { type: "SET_MULTI_SELECT"; payload: { allowSelectMultiple: boolean } }
  | { type: "LOAD_SCROLLBAR_WIDTH"; payload: { scrollbarWidth: number } }
  | { type: "LOAD_COLUMNS"; payload: { columns: ColumnType[] } }
  | {
      type: "LOAD_TD_WIDTHS";
      payload: { naturalTdWidths: number[]; tableWidth: number };
    };

export default function tableReducer(
  state: TableReducerState,
  action: TableReducerAction
): TableReducerState {
  function getRemeasureState() {
    return {
      ...state,
      measureRequest: uniqueId("measureRequest"),
      visibleColumns: state.visibleColumns.map(c => ({
        ...c,
        minWidth: undefined
      }))
    };
  }

  switch (action.type) {
    case "RESIZE_TABLE": {
      return getRemeasureState();
    }

    case "SET_MULTI_SELECT": {
      if (action.payload.allowSelectMultiple === state.allowSelectMultiple) {
        return state;
      }
      return {
        ...getRemeasureState(),
        allowSelectMultiple: action.payload.allowSelectMultiple
      };
    }

    case "LOAD_SCROLLBAR_WIDTH": {
      if (state.scrollbarWidth === action.payload.scrollbarWidth) return state;
      return {
        ...state,
        scrollbarWidth: action.payload.scrollbarWidth
      };
    }

    case "LOAD_COLUMNS": {
      const { columns } = action.payload;
      const visibleColumns = columns.filter(c => !c.hidden);
      if (
        visibleColumns.length === state.visibleColumns.length &&
        visibleColumns.every(
          (c, idx) =>
            c.accessor === state.visibleColumns[idx].accessor &&
            c.hidden === state.visibleColumns[idx].hidden
        )
      ) {
        return state;
      }

      return {
        ...state,
        measureRequest: uniqueId("measureRequest"),
        visibleColumns
      };
    }

    case "LOAD_TD_WIDTHS": {
      const { naturalTdWidths, tableWidth } = action.payload;
      let tableWidthAdjustment = 0;
      if (state.allowSelectMultiple) {
        // Deduct first column from table width and remove from naturalTdWidths
        // when multi-select column present
        tableWidthAdjustment = multiSelectColWidth;
        naturalTdWidths.shift();
      }
      const colWidthSum = naturalTdWidths.reduce((a, b) => a + b, 0);
      const remainder =
        tableWidth - colWidthSum - tableWidthAdjustment - state.scrollbarWidth;

      // Columns wider than table so no overriding required
      if (remainder <= 0) {
        return {
          ...state,
          visibleColumns: state.visibleColumns.map(c => ({
            ...c,
            minWidth: undefined
          }))
        };
      }

      return {
        ...state,
        visibleColumns: state.visibleColumns.map((c, idx) => {
          // If there is width left over allocate proportionally to each column
          const tdWidth = naturalTdWidths[idx];
          const relativeWidth = tdWidth / colWidthSum;
          const overrideWidth = tdWidth + remainder * relativeWidth;
          return {
            ...c,
            minWidth: overrideWidth
          };
        })
      };
    }

    default:
      return assertNever(action);
  }
}
