import React from "react";

import { isEqual } from "lodash";

export default function useStableRef(val: any) {
  const ref = React.useRef(val);
  if (!isEqual(val, ref.current)) {
    ref.current = val;
    return val;
  }
  return ref.current;
}
