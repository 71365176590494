import {
  SpaceComponentObject,
  SpaceComponentType,
  FunctionParameterNode,
  ConfigValidationError
} from "../../../../../../../types";
import {
  createSpaceFunction,
  SpaceFunction
} from "../../../../../FunctionExecutor/FunctionExecutor";
import { ComponentConfigState } from "../../../../../types";
import { ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT } from "../../../../constants";
import { InputParameter } from "../../useFuncParams";
import { ensureParameterConfigState } from "../reducer/reducer";
import { FIELD_TYPES_WITH_CONFIG_REQUIRED } from "../utils";

const BINDING_DELETED =
  "This field has an invalid value because a binding it was referencing no longer exists.";

const isInvalidComponentType = (
  fp: FunctionParameterNode | undefined,
  componentType: SpaceComponentType
) => {
  return fp && !ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[fp.type].includes(componentType);
};

const getFunctionParameterMap = (func: SpaceFunction | undefined) => {
  return func && func.functionParameters
    ? Object.fromEntries(func.functionParameters.map(fp => [fp.name, fp]))
    : {};
};

export const selectFieldErrors = (
  genericState: ComponentConfigState,
  component: SpaceComponentObject,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[]
): { [key: string]: string[] } => {
  const invalidBindings = findInvalidInputBindings(component);
  const state = ensureParameterConfigState(genericState);
  const parameters = state.draftComponent.properties.input_parameters || [];
  const fieldErrors = Object.fromEntries(
    parameters.map((p: InputParameter) => [p.name, []])
  );
  const func = createSpaceFunction(state.draftComponent);

  // parent pseudonode for components
  const fieldsNode = component.componentTreeNodes.find(ctn => ctn.slug === "fieldset");

  const functionParameterMap = getFunctionParameterMap(func);

  parameters.forEach((p: InputParameter) => {
    const options = p.render_options ? p.render_options.map(p => p.value) : [];
    if (func && !func.functionParameters.some(fp => fp.name === p.name)) {
      fieldErrors[p.name].push(
        "This field does not exist on the function, please remove it."
      );
    }
    if (p.type === "none") {
      fieldErrors[p.name].push("Please configure this field.");
    }
    if (
      p.type === "value" &&
      (state.parameterValueErrors[p.name] === false || p.value === undefined)
    ) {
      fieldErrors[p.name].push("This field has an invalid default value.");
    }
    if (p.type === "binding" && !p.binding) {
      fieldErrors[p.name].push("Please add a binding to this field.");
    }
    if (p.type === "binding" && p.binding && invalidBindings.includes(p.binding)) {
      fieldErrors[p.name].push(BINDING_DELETED);
    }
    if (p.type === "component") {
      // if fieldsNode exists, check its children
      const node = (fieldsNode || component).componentTreeNodes.find(ctn => {
        return ctn.slug === p.component_slug;
      });
      if (node && findInvalidInputBindings(node).length) {
        fieldErrors[p.name].push(BINDING_DELETED);
      }
      const fp = functionParameterMap[p.name];
      if (isInvalidComponentType(fp, p.component_type)) {
        fieldErrors[p.name].push(
          "This field's component is incompatible with its function parameter's type and must be updated."
        );
      }
    }

    if (
      !!p.field_type &&
      FIELD_TYPES_WITH_CONFIG_REQUIRED.includes(p.field_type) &&
      (!p.render_options || p.render_options.length === 0)
    ) {
      fieldErrors[p.name].push("Please add options for this field type.");
    }

    if (
      !!p.field_type &&
      FIELD_TYPES_WITH_CONFIG_REQUIRED.includes(p.field_type) &&
      p.type === "value" &&
      !options.includes(p.value)
    ) {
      fieldErrors[p.name].push(
        "Please add a value that is included in your list of options."
      );
    }
  });
  return fieldErrors;
};

const getRequiredParametersPresent = (sc: SpaceComponentObject) => {
  const func = createSpaceFunction(sc);
  if (!func?.functionParameters) return [];
  const requiredParameters = func.functionParameters
    .filter(e => e.required)
    .map(e => e.name);
  const presentParameters = (sc.properties.input_parameters || []).map(
    (v: InputParameter) => v.name
  );
  const missingParameters = requiredParameters.filter(
    p => !presentParameters.includes(p)
  );
  const errors = [];

  if (missingParameters.length > 0) {
    errors.push(
      `This form is missing the following fields: ${missingParameters.join(", ")}.`
    );
  }
  return errors;
};

const getInvalidComponentErrors = (state: ComponentConfigState) => {
  const paramConfigState = ensureParameterConfigState(state);
  const parameters = paramConfigState.draftComponent.properties.input_parameters || [];
  const func = createSpaceFunction(state.draftComponent);
  const functionParameterMap = getFunctionParameterMap(func);
  const errors: ConfigValidationError[] = [];
  parameters.forEach((p: InputParameter, idx: number) => {
    if (p.type === "component") {
      const fp = functionParameterMap[p.name];
      if (isInvalidComponentType(fp, p.component_type)) {
        errors.push({
          field: "COMPONENT_TYPE",
          index: idx,
          message: "Please select a valid component type."
        });
      }
    }
  });
  return errors;
};

export default function selectErrors(
  state: ComponentConfigState,
  _component: SpaceComponentObject,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const component = state.draftComponent;

  const fieldErrors: ConfigValidationError[] = Object.entries(
    selectFieldErrors(state, component, findInvalidInputBindings)
  ).flatMap(([key, errors]) =>
    errors.map(err => ({ field: "FIELDS", key, message: err }))
  );

  const requiredParameterErrors: ConfigValidationError[] = getRequiredParametersPresent(
    component
  ).map(message => ({ field: "REQUIRED_PARAMETERS", message }));

  const invalidComponentErrors: ConfigValidationError[] =
    getInvalidComponentErrors(state);

  return fieldErrors.concat(requiredParameterErrors).concat(invalidComponentErrors);
}
