import React from "react";

import ColumnListManagerSection from "../../../common/ColumnListManager/ColumnListManagerSection";
import ColumnOrder from "../../../common/ColumnOrder";
import { useComponentConfigContext } from "../../../common/ComponentConfigContext";
import ValidationError from "../../../common/ComponentConfigContext/ValidationError";
import { ConfigSection } from "../../../common/ConfigPanel";
import { ensureViewConfigState } from "../../../common/useViewConfig/reducer";
interface Props {
  title: string;
}
export default function FieldsConfigSection({ title }: Props) {
  const { state: genericState } = useComponentConfigContext();
  const state = ensureViewConfigState(genericState);
  return (
    <>
      <ColumnListManagerSection
        title={title}
        attributes={state.attributes || []}
        columnLabel="fields"
        errorField={<ValidationError field="FIELDS" />}
      />
      <ConfigSection title="Sorting">
        <ColumnOrder sortByOptions={state.sortByOptions} />
      </ConfigSection>
    </>
  );
}
