import React from "react";

import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { decodeCursor } from "../../../../constants";
import usePaths from "../../../common/hooks/usePaths";
import RecordDetail from "../RecordDetail";
import { RecordParams } from "../RecordDetail/common/useGetRecordPath";

import RecordDetailDrawer from "./RecordDetailDrawer";

export const Root = styled.div`
  min-height: ${props => `calc(100vh - ${props.theme.headerHeight} - 1px)`};
  padding: ${props => props.theme.spacersm};

  @media (min-width: ${props => props.theme.breakpointmd}) {
    padding: ${props => props.theme.spacerlg};
  }

  @media (min-width: ${props => props.theme.breakpointlg}) {
    padding: ${props => props.theme.spacerxl};
  }
`;

export default function RootRecordDetail() {
  const { cursor } = useParams<RecordParams>();
  const decodedCursor = decodeCursor(decodeURIComponent(cursor!));

  const { contextCursor } = useParams<RecordParams>();
  const paths = usePaths();
  const decodedContextCursor = contextCursor
    ? decodeCursor(decodeURIComponent(contextCursor))
    : null;

  if (!cursor) return <Navigate to={paths.getNotFound()} />;

  let context = null;
  if (decodedContextCursor) {
    context = <RecordDetailDrawer cursor={decodedContextCursor} />;
  }

  return (
    <Root>
      <RecordDetail cursor={decodedCursor} />
      {context}
    </Root>
  );
}
