import React from "react";

import { Select } from "antd";
import momentTz from "moment-timezone";
import styled from "styled-components";

import useStableId from "../hooks/useStableId";
import { getZoneLocale, momentZoneLocales, zoneDisplayNameMap } from "../TimeZoneUtils";

const { Option } = Select;

const FullWidthSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    width: 100%;
  }
`;

interface TimezoneProps {
  value: momentTz.Moment;
  setTimeZone: (p: string) => void;
}

export default function TimezonePicker(props: TimezoneProps) {
  const { value, setTimeZone } = props;
  const timeZoneId = useStableId("timezoneselect");

  // this is necessary because some time zones change with daylight savings
  // and others do not. Due to this, it is possible to have conflicts.
  // When this happens, we should disambiguate.
  const zoneDisplayNameRenderTimeMap = React.useMemo(() => {
    const zonesAndAbbrs = momentZoneLocales.map(z => [
      z,
      momentTz(value).tz(z).zoneName()
    ]); // [["Etc/GMT", GMT], ["Europe/Dublin, GMT]]
    return zonesAndAbbrs.reduce(
      (aggregate: Record<string, string>, nextValue: string[]) => {
        const [zone, abbr] = nextValue;
        if (zonesAndAbbrs.filter(n => n[1] === abbr).length === 1) {
          aggregate[zone] = zoneDisplayNameMap[abbr] || abbr;
        } else {
          aggregate[zone] = `${zoneDisplayNameMap[abbr] || abbr} (${zone})`;
        }
        return aggregate;
      },
      {}
    );
  }, [value]);

  return (
    <div id={timeZoneId}>
      <FullWidthSelect
        data-test="tz"
        onSelect={(p: any) => setTimeZone(p as string)}
        optionFilterProp={"children"}
        value={getZoneLocale(value)}
        getPopupContainer={() => document.getElementById(timeZoneId) as HTMLElement}
        showSearch
      >
        {momentZoneLocales.map((zone, i: number) => (
          <Option key={"tz" + i} value={zone}>
            {zoneDisplayNameRenderTimeMap[zone]}
          </Option>
        ))}
      </FullWidthSelect>
    </div>
  );
}
