import React from "react";

import { Checkbox, Collapse, Modal, Radio, Select, Tabs, Tree } from "antd";
import styled from "styled-components";

import {
  AttributeTypes,
  DARK_THEME_POPPER_PORTAL_ID,
  DEFAULT_THEME_POPPER_PORTAL_ID
} from "../../constants";
import { BasicLayout } from "../../layouts";
import Button from "../common/Button";
import Input from "../common/Input";
import Message from "../common/Message/index";

import DateTimeInput from "./AttributeInput/DateTimeInput";
import ButtonNew from "./ButtonNew/ButtonNew";
import IconButton from "./IconButton";
import { ErrorField, MainTitle, SectionTitle } from "./StyledComponents";
import ThemeContainer, { Theme } from "./ThemeContainer";

const StyleContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;

  .ant-input {
    margin-bottom: ${props => props.theme.spacersm};
  }
`;

const FlexContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.vertical ? "column" : "row")};
  width: 100%;
`;

const StyledContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  background-color: ${props => props.theme.backgroundColor};
`;

const StyledThemeContainer = styled(ThemeContainer)`
  width: calc(100% - 40px);
  margin-left: 40px;
  margin-top: 40px;
  border: 1px solid ${props => props.theme.tableBorderColor};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  button {
    margin-left: ${props => props.theme.spacersm};
  }
  & + & {
    margin-top: ${props => props.theme.spacermd};
  }
`;

const NestedTestContainer = styled.div`
  margin-top: 100px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & + & {
    margin-top: ${props => props.theme.spacerlg};
  }
`;

interface SectionProps {
  title: string;
  children: React.ReactNode;
}
const Section = ({ title, children }: SectionProps) => {
  return (
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </SectionContainer>
  );
};

const themedComponents = (
  <>
    <Section title="Text Field">
      <FlexContainer vertical>
        <Input placeholder="Empty input" />
        <Input placeholder="Default input" value="Input with text" />
        <Input placeholder="Disabled input placeholder text" disabled />
        <Input
          placeholder="Disabled input with text"
          value="Disabled input with text"
          disabled
        />
        <Input customSize="medium" placeholder={"Medium Input"} />
      </FlexContainer>
    </Section>
    <Section title="Select">
      <FlexContainer vertical>
        <Select
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          defaultValue="lucy"
          style={{ width: 120 }}
          onChange={() => {}}
        >
          <Select.Option value="jack">Jack</Select.Option>
          <Select.Option value="lucy">Lucy</Select.Option>
          <Select.Option value="disabled" disabled>
            Disabled
          </Select.Option>
          <Select.Option value="Yiminghe">yiminghe</Select.Option>
        </Select>
        <br />
        <Select
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          defaultValue="lucy"
          style={{ width: 120 }}
          onChange={() => {}}
          disabled
        >
          <Select.Option value="jack">Jack</Select.Option>
          <Select.Option value="lucy">Lucy</Select.Option>
          <Select.Option value="disabled" disabled>
            Disabled
          </Select.Option>
          <Select.Option value="Yiminghe">yiminghe</Select.Option>
        </Select>
      </FlexContainer>
    </Section>
    <Section title="Checkbox">
      <FlexContainer vertical>
        <Checkbox>Checkbox</Checkbox>
        <Checkbox checked>Checked Checkbox</Checkbox>
        <Checkbox disabled>Disabled Checkbox</Checkbox>
        <Checkbox checked disabled>
          Disabled Checked Checkbox
        </Checkbox>
      </FlexContainer>
    </Section>
    <Section title="Radio">
      <FlexContainer vertical>
        <Radio.Group defaultValue={4}>
          <Radio value={1}>Option 1</Radio>
          <Radio value={2}>Option 2</Radio>
          <Radio value={3} disabled>
            Disabled Option
          </Radio>
          <Radio value={4} disabled>
            Disabled Selected
          </Radio>
        </Radio.Group>
      </FlexContainer>
    </Section>
    <Section title="Tree">
      <FlexContainer vertical>
        <Tree defaultExpandedKeys={["foo-bar"]} defaultSelectedKeys={["foo-bar"]}>
          <Tree.TreeNode key="foo" title="foo">
            <Tree.TreeNode key="foo-bar" title="bar" />
          </Tree.TreeNode>
          <Tree.TreeNode key="baz" title="baz">
            <Tree.TreeNode key="baz-qux" title="qux" />
          </Tree.TreeNode>
        </Tree>
      </FlexContainer>
    </Section>
    <Section title="Errors">
      <ErrorField>Error Field</ErrorField>
    </Section>
    <Section title="Tabs">
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Tab 1" key="1">
          Content of Tab Pane 1
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tab 2" key="2">
          Content of Tab Pane 2
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tab 3" key="3">
          Content of Tab Pane 3
        </Tabs.TabPane>
      </Tabs>
    </Section>
    <Section title="Collapse">
      <Collapse defaultActiveKey={["foo"]}>
        <Collapse.Panel key="foo" header="Bordered">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
          in culpa qui officia deserunt mollit anim id est laborum.
        </Collapse.Panel>
        <Collapse.Panel key="bar" header="Bar">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
          in culpa qui officia deserunt mollit anim id est laborum.
        </Collapse.Panel>
      </Collapse>

      <br />
      <Collapse defaultActiveKey={["foo"]} bordered={false}>
        <Collapse.Panel key="foo" header="Not Bordered">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
          in culpa qui officia deserunt mollit anim id est laborum.
        </Collapse.Panel>
        <Collapse.Panel key="bar" header="Bar">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
          in culpa qui officia deserunt mollit anim id est laborum.
        </Collapse.Panel>
      </Collapse>
    </Section>
    <Section title="Date Time Picker">
      <DateTimeInput
        sourceName="dateTest"
        sourceType={AttributeTypes.TIMESTAMP}
        fieldOptions={{ validateTrigger: "", rules: [] }}
      />
    </Section>
  </>
);
const components = (
  <>
    {themedComponents}
    <Section title="Buttons">
      <ButtonContainer>
        <Button>Button</Button>
        <Button type="primary">Primary Button</Button>
        <Button disabled>Disabled Button</Button>
        <Button type="primary" disabled>
          Disabled Primary Button
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button size="medium">Medium Button</Button>
        <Button size="medium" type="primary">
          Primary Button
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button>Button</Button>
        <Button loading>Loading Button</Button>
        <Button icon="save">Button with Icon</Button>
        <Button loading icon="save">
          Loading Button with Icon
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button fixedWidthLoadingState>Fixed Width Button</Button>
        <Button fixedWidthLoadingState loading>
          Fixed Width Button
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button icon="save" fixedWidthLoadingState>
          Fixed Width Button with Icon
        </Button>
        <Button icon="save" fixedWidthLoadingState loading>
          Fixed Width Button with Icon
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <IconButton icon="eye" />
        <IconButton icon="eye" disabled />
      </ButtonContainer>
    </Section>
    <Section title="ButtonNew">
      <ButtonContainer>
        <ButtonNew size="lg" type="brand">
          Brand large
        </ButtonNew>
        <ButtonNew disabled size="lg" type="brand">
          Brand large disabled
        </ButtonNew>
        <ButtonNew size="lg" type="primary">
          Primary large
        </ButtonNew>
        <ButtonNew disabled size="lg" type="primary">
          Primary large disabled
        </ButtonNew>
        <ButtonNew size="lg" type="secondary">
          Secondary large
        </ButtonNew>
        <ButtonNew disabled size="lg" type="secondary">
          Secondary large disabled
        </ButtonNew>
        <ButtonNew size="lg" type="noFill">
          No-fill large
        </ButtonNew>
        <ButtonNew disabled size="lg" type="noFill">
          No-fill large disabled
        </ButtonNew>
        <ButtonNew size="lg" type="noFillAccent">
          No-fill accent large
        </ButtonNew>
        <ButtonNew disabled size="lg" type="noFillAccent">
          No-fill accent large disabled
        </ButtonNew>
        <ButtonNew size="sm" type="brand">
          Brand small
        </ButtonNew>
        <ButtonNew disabled size="sm" type="brand">
          Brand small disabled
        </ButtonNew>
        <ButtonNew disabled size="sm" type="primary">
          Primary small disabled
        </ButtonNew>
        <ButtonNew size="sm" type="secondary">
          Secondary small
        </ButtonNew>
        <ButtonNew disabled size="sm" type="secondary">
          Secondary small disabled
        </ButtonNew>
        <ButtonNew size="sm" type="noFill">
          No-fill small
        </ButtonNew>
        <ButtonNew disabled size="sm" type="noFill">
          No-fill small disabled
        </ButtonNew>
        <ButtonNew size="sm" type="noFillAccent">
          No-fill accent small
        </ButtonNew>
        <ButtonNew disabled size="sm" type="noFillAccent">
          No-fill accent small disabled
        </ButtonNew>
      </ButtonContainer>
    </Section>
    <Section title="Messages">
      <ButtonContainer>
        <Button
          onClick={() =>
            Message.info(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eros arcu, placerat ut nisl vitae, sodales consequat lacus. Proin pharetra est eu consectetur molestie. ",
              0
            )
          }
          size="medium"
        >
          Info & Text-wrap
        </Button>
        <Button
          onClick={() => Message.warning("Lorem ipsum dolor sit amet.")}
          size="medium"
        >
          Warning
        </Button>
        <Button
          onClick={() => Message.success("Lorem ipsum dolor sit amet.")}
          size="medium"
        >
          Success
        </Button>
        <Button
          onClick={() => Message.error("Lorem ipsum dolor sit amet.")}
          size="medium"
        >
          Error
        </Button>
      </ButtonContainer>
    </Section>
  </>
);

const StyleGuide = () => {
  const [noThemeModalVisible, setNoThemeModalVisible] = React.useState(false);
  const [defaultModalVisible, setDefaultModalVisible] = React.useState(false);
  const [darkModalVisible, setDarkModalVisible] = React.useState(false);
  return (
    <BasicLayout>
      <StyleContainer>
        {/* Todo: consolidate into a single common modal */}
        <Modal
          visible={noThemeModalVisible}
          onCancel={() => setNoThemeModalVisible(false)}
          title="Confirm"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eros arcu,
          placerat ut nisl vitae, sodales consequat lacus. Proin pharetra est eu
          consectetur molestie.
        </Modal>
        <Modal
          visible={defaultModalVisible}
          getContainer={document.getElementById(DEFAULT_THEME_POPPER_PORTAL_ID)}
          onCancel={() => setDefaultModalVisible(false)}
          title="Confirm"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eros arcu,
          placerat ut nisl vitae, sodales consequat lacus. Proin pharetra est eu
          consectetur molestie.
        </Modal>
        <Modal
          visible={darkModalVisible}
          getContainer={document.getElementById(DARK_THEME_POPPER_PORTAL_ID)}
          onCancel={() => setDarkModalVisible(false)}
          title="Confirm"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eros arcu,
          placerat ut nisl vitae, sodales consequat lacus. Proin pharetra est eu
          consectetur molestie.s
        </Modal>
        <MainTitle>Style Guide</MainTitle>
        <FlexContainer>
          <StyledContent>
            <SectionTitle>No Theme</SectionTitle>
            {components}
            <Section title="Modal">
              <Button size="medium" onClick={() => setNoThemeModalVisible(true)}>
                Open Modal
              </Button>
            </Section>
          </StyledContent>
          <ThemeContainer theme={Theme.Default}>
            <StyledContent>
              <SectionTitle>Default Theme</SectionTitle>
              {components}
              <Section title="Modal">
                <Button size="medium" onClick={() => setDefaultModalVisible(true)}>
                  Open Modal
                </Button>
              </Section>
            </StyledContent>
          </ThemeContainer>
          <ThemeContainer theme={Theme.Dark}>
            <StyledContent>
              <SectionTitle>Dark Theme</SectionTitle>
              {components}
              <Section title="Modal">
                <Button size="medium" onClick={() => setDarkModalVisible(true)}>
                  Open Modal
                </Button>
              </Section>
            </StyledContent>
          </ThemeContainer>
        </FlexContainer>

        <NestedTestContainer>
          <Section title="Nested ThemeContainer">
            <StyledThemeContainer theme={Theme.Default}>
              <StyledContent>
                <SectionTitle>Default</SectionTitle>
                {themedComponents}
                <StyledThemeContainer theme={Theme.Dark}>
                  <StyledContent>
                    <SectionTitle>Dark on Default</SectionTitle>
                    {themedComponents}
                    <StyledThemeContainer theme={Theme.Default}>
                      <StyledContent>
                        <SectionTitle>Default on Dark on Default</SectionTitle>
                        {themedComponents}
                        <StyledThemeContainer theme={Theme.Dark}>
                          <StyledContent>
                            <SectionTitle>
                              Dark on Default on Dark on Default
                            </SectionTitle>
                            {themedComponents}
                          </StyledContent>
                        </StyledThemeContainer>
                      </StyledContent>
                    </StyledThemeContainer>
                  </StyledContent>
                </StyledThemeContainer>
              </StyledContent>
            </StyledThemeContainer>
          </Section>
        </NestedTestContainer>
      </StyleContainer>
    </BasicLayout>
  );
};
export default StyleGuide;
