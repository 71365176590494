import Icon from "../../../../../assets/spaceicons/image.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  ElementLayout,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  LayoutUnit
} from "../../../layout/util";

import { getSchema } from "./getSchema";
import ImageConfig from "./ImageConfig";
import componentConfigReducer, {
  makeInitialState,
  ensureImageComponent
} from "./ImageConfig/reducer/reducer";
import { errorSelector } from "./ImageConfig/selectors/errorSelector";
import SpaceImage from "./SpaceImage";
import { SpaceImageComponent, ImageConfigState, SpaceImageProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceImageProperties,
  SpaceImageComponent,
  ImageConfigState
> = {
  type: "IMAGE",
  displayName: "Image",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["SPACE"]),
  isRepeatable: false,
  Component: SpaceImage,
  getSchema,
  ConfigurationComponent: ImageConfig,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureImageComponent,
  allowSelfBinding: true,
  allowAncestorBinding: true,
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    width: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL],
    height: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL]
  },
  defaultElementLayout: new ElementLayout({
    snapToGrid: true,
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.STANDARD.height
  }),
  tags: [SpaceComponentTags.Content],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
