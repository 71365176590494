import React from "react";

import { useQuery } from "@apollo/react-hooks";

import { FETCH_AUTH_USER } from "../../../../graphql/queries";
import { setTrackingUserId } from "../../../../logging";
import { AuthStatus, UserNode } from "../../../../types";
import { fromGlobalId } from "../../../util/graphql";
import useAuthStatus from "../useAuthStatus";

export interface Result {
  loading: boolean;
  authUser: UserNode | null;
  userNodeId: string | null;
  isAdmin: boolean;
  isSpaceAdmin: boolean;
  status: AuthStatus;
}

export function convertToUserNodeId(id: string) {
  const [ignored, decodedId] = atob(id).split(":");
  return btoa(`UserNode:${decodedId}`);
}

export default function useAuthUser() {
  const { status } = useAuthStatus();
  const { loading, data } = useQuery<{ authUser: UserNode }>(FETCH_AUTH_USER, {
    skip: status !== AuthStatus.Authenticated,
    onCompleted: data => {
      setTrackingUserId(data?.authUser.id || null);
    }
  });
  const authUser = !loading ? data?.authUser : null;
  const userNodeId = authUser ? convertToUserNodeId(authUser.id) : null;

  const isAdmin = authUser ? authUser.isAdmin : false;
  const isSpaceAdmin = authUser?.scopes?.includes("space:admin") || false;

  return {
    loading,
    authUser: data && data.authUser ? data.authUser : null,
    userNodeId,
    isAdmin,
    isSpaceAdmin,
    status
  };
}

export function useAuthUserUuid(authUser: UserNode | null) {
  return React.useMemo(() => {
    if (!authUser) {
      return undefined;
    }
    return fromGlobalId(authUser.id)[1];
  }, [authUser]);
}
