import { Row } from "antd";
import styled from "styled-components";

export const FormContainer = styled.div`
  width: 380px;
  max-width: 100%;
  margin-top: 44px;
  margin-left: auto;
  margin-right: auto;

  button[type="submit"] {
    margin-bottom: ${props => props.theme.spacersm};
  }
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.spacerlg};
`;

export const ForgotPasswordDiv = styled.div`
  height: ${props => props.theme.spacerxl};
  text-align: right;
`;

export const AlertContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  margin: 0 auto;

  .ant-alert {
    margin: ${props => props.theme.spacerxl} auto;
    width: 100%;
    min-width: 300px;
    max-width: 600px;
  }
`;

export const OrRow = styled(Row)`
  line-height: ${props => props.theme.spacerxl};
  margin: ${props => props.theme.spacerxl} 0px;

  div#or_strikethrough {
    display: flex;
    flex-direction: row;

    span {
      margin-left: ${props => props.theme.spacersm};
      margin-right: ${props => props.theme.spacersm};
    }
  }

  div:before,
  div:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin: auto;
  }
`;
