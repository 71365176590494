import { BaseFunctionNodeBasic, FunctionParameterNode } from "../../../types";

import { DataSourceFunction, DataSourceNodeWithFunctions } from "./forms/types";
import { SupportedIntegration } from "./support";
import { State } from "./useFunctionEditor/reducer";

export const toDataSourceFunction = (
  dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>,
  state: State
): DataSourceFunction | undefined => {
  const baseFunction = dataSource.functions.edges.find(
    e => e.node.id === state.baseFunctionId
  )?.node;

  if (!baseFunction) {
    return;
  }

  return {
    baseFunction: {
      id: baseFunction.id,
      name: baseFunction.name
    },
    baseFunctionParameterMapping: state.baseFunctionParameterMapping,
    dataSource: {
      id: dataSource.id,
      integration: dataSource.integration as SupportedIntegration
    },
    functionParameters: {
      edges: state.parameters.map(p => ({ node: p as FunctionParameterNode }))
    },
    id: state.functionId,
    isUserGenerated: true,
    metadata: state.metadata,
    reducer: state.reducer,
    title: state.title
  };
};
