import React, { useState } from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Modal } from "antd";
import { ColumnProps } from "antd/lib/table";
import { ApolloError } from "apollo-client";
import { useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../constants";
import { Size } from "../../cssConstants";
import {
  GET_ALL_INVITES_DATA,
  GET_ALL_USERS_DATA,
  UPDATE_ROLE_FOR_USERS,
  GET_ALL_ROLES
} from "../../graphql/queries";
import {
  Edge,
  UserStatus,
  OrganizationInviteNode,
  RoleNode,
  UserNode
} from "../../types";
import usePaths from "../common/hooks/usePaths";
import { Table } from "../common/StyledComponents";
import { ALPHA_SORT_FN } from "../util/TableSortFunctions";

import AuditLogDrawer from "./AuditLogDrawer";
import { DetailParams } from "./common";
import InviteButton from "./invite/InviteButton";
import InviteEmailButton from "./invite/InviteEmailButton";
import InviteRemoveButton from "./invite/InviteRemoveButton";
import InviteReview from "./invite/InviteReview";
import RolesDropdown, { RoleDropdownTypes } from "./roles/RolesDropdown";

const LeftTools = styled.div`
  button + button {
    margin-left: 12px;
  }
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: ${Size.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const handleError = (error: ApolloError) => {
  const text = error.graphQLErrors ? error.graphQLErrors[0].message : "";
  if (text.indexOf("one remaining admin") > -1) {
    Modal.warning({
      title: "You must have at least one admin.",
      content: "At least one person at your company must be designated as the Admin."
    });
  } else {
    Modal.warning({
      title: "An error has occurred.",
      content: `${text}  Please try again.`
    });
  }
};

const columns: ColumnProps<any>[] = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: ALPHA_SORT_FN("firstName"),
    sortDirections: ["ascend", "descend"],
    defaultSortOrder: "ascend"
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    sorter: ALPHA_SORT_FN("lastName"),
    sortDirections: ["ascend", "descend"],
    defaultSortOrder: "ascend"
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: ALPHA_SORT_FN("email"),
    sortDirections: ["ascend", "descend"],
    defaultSortOrder: "ascend"
  },
  {
    title: "Roles",
    dataIndex: "roles",
    key: "roles",
    sorter: ALPHA_SORT_FN("roles"),
    sortDirections: ["ascend", "descend"],
    defaultSortOrder: "ascend"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: ALPHA_SORT_FN("status"),
    sortDirections: ["ascend", "descend"],
    defaultSortOrder: "ascend",
    render: (text: string, record: any) => {
      switch (record.status) {
        case UserStatus.Active:
          return "Active";
        case UserStatus.Inactive:
          return "Inactive";
        case "invited":
          return (
            <span>
              Invite Sent <InviteEmailButton email={record.email} />{" "}
              <InviteRemoveButton email={record.email} />
            </span>
          );
      }
    }
  }
];

interface UserSettingsRow {
  id: string;
  email: string;
  status: UserStatus | "invited";
  roles: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
}

const UserSettingsComponent = () => {
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const params = useParams<DetailParams>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { getUserSettings } = usePaths();
  const [updateRoleForUsers] = useMutation(UPDATE_ROLE_FOR_USERS, {
    refetchQueries: [{ query: GET_ALL_ROLES }],
    onCompleted: () => setSelectedUsers([]),
    onError: e => handleError(e)
  });
  const { data: usersData, loading: usersLoading } = useQuery(GET_ALL_USERS_DATA, {
    fetchPolicy: "cache-and-network"
  });
  const { data: invitesData, loading: invitesLoading } = useQuery(
    GET_ALL_INVITES_DATA,
    {
      fetchPolicy: "cache-and-network"
    }
  );
  const loading = usersLoading || invitesLoading;
  const data = React.useMemo((): UserSettingsRow[] => {
    const userRows =
      usersData?.allUsers?.edges
        .map((e: Edge<UserNode>) => e.node)
        .map((u: UserNode) => {
          return {
            id: u.id,
            userId: u.id,
            email: u.email,
            firstName: u.firstName,
            lastName: u.lastName,
            status: u.status,
            roles: u.roles?.edges.map((e: Edge<RoleNode>) => e.node.name).join(", ")
          };
        }) || [];
    const inviteRows =
      invitesData?.allOrganizationInvites?.edges
        .map((e: Edge<OrganizationInviteNode>) => e.node)
        .map((i: OrganizationInviteNode) => {
          return {
            id: i.id,
            email: i.email,
            status: "invited",
            roles: i.role.name
          };
        }) || [];
    return [...inviteRows, ...userRows];
  }, [usersData, invitesData]);

  const inviteRequest = urlParams.get("invite_request");
  return (
    <React.Fragment>
      <InviteReview email={inviteRequest || undefined} />

      <Toolbar>
        <LeftTools>
          <RolesDropdown
            handleRoleSelected={(roleId: string) => {
              updateRoleForUsers({
                variables: { userIds: selectedUsers, roleId }
              });
            }}
            autoClearSelection={true}
            disabled={selectedUsers.length === 0}
            type={RoleDropdownTypes.Button}
          />
          <InviteButton />
        </LeftTools>
      </Toolbar>

      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        onRow={record => {
          const _record = record as UserSettingsRow;
          return {
            onClick: () => {
              if (_record.userId) navigate(getUserSettings(_record.userId));
            }
          };
        }}
        rowKey="id"
        rowSelection={{
          onChange: (selectedRowKeys: any[]) => {
            setSelectedUsers(selectedRowKeys);
          },
          selectedRowKeys: selectedUsers,
          getCheckboxProps: record => {
            if (!(record as UserSettingsRow).userId) return { disabled: true };
            return {};
          }
        }}
        pagination={false}
      />

      {params.id && (
        <AuditLogDrawer
          onClose={() => navigate(Routes.SETTINGS_USERS)}
          isVisible={!!params.id}
        />
      )}
    </React.Fragment>
  );
};

export default UserSettingsComponent;
