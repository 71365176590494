import { useState } from "react";

import { BigNumber } from "bignumber.js";
import { flatten } from "flat";
import { get, set } from "lodash";

import { AttributeTypes, ErrorValues } from "../../../../../constants";
import {
  SpaceComponentObject,
  DataValue,
  ObjectBinding,
  Binding,
  BindingShape,
  SpaceComponentType
} from "../../../../../types";
import { createPath, parsePath } from "../../../../util/binding";
import { COMPONENTS_WITHOUT_BLANK_SUPPORT, BlankValueType } from "../../constants";
import { ComponentNode } from "../../RenderTreeContext";
import {
  findSpaceComponentPackage as findPackage,
  getSpaceComponentDisplayName as getDisplayName
} from "../../SpaceContext/StableSpaceContext";
import { toProperty } from "../common/util";
import { TEMPLATE_BINDING_EXTRACTER } from "../constants";
import { StateTree } from "../SpaceComponent";

export function useInputState(spaceState: StateTree, bindings: Set<string>) {
  const [inputState, setInputState] = useState<Record<string, any>>({});
  let hasChanges = false;
  const nextInputState: Record<string, any> = {};
  for (const b of bindings) {
    const input = get(spaceState, b);
    if (input !== inputState[b]) {
      hasChanges = true;
    }
    if (input !== undefined) {
      nextInputState[b] = input;
    }
  }
  // Binding was removed from the set, so we need to remove it from the input state
  for (const b of Object.keys(inputState)) {
    if (!bindings.has(b)) {
      hasChanges = true;
      delete nextInputState[b];
    }
  }
  let returnState = inputState;
  if (hasChanges) {
    setInputState(nextInputState);
    returnState = nextInputState;
  }
  return Object.keys(returnState).length > 0 ? returnState : null;
}

export const areTemplateBindingsFulfilled = (
  template: string | null,
  input: Record<string, any> | null
): boolean => {
  if (!template) return true;
  const bindings = template
    .match(TEMPLATE_BINDING_EXTRACTER)
    ?.map(re => re.replace(TEMPLATE_BINDING_EXTRACTER, `$1`));
  return bindings ? bindings!.every(b => input && !!input[b]) : true;
};

export const getDisplayValue = (p: any) => {
  const asNumber = new BigNumber(p);
  if (!isNaN(asNumber.toNumber())) return asNumber.dp(4).toString();
  return p;
};

export const missingBindingPlaceHolder = "_______";
const defaultOptions = {
  humanizeInputs: false,
  renderWithPlaceHolders: false,
  missingBindingPlaceHolder,
  returnErrors: false,
  flattenInput: false
};
export const evaluateTemplate = (
  template: string | null,
  input: Record<string, any> | null,
  options?: Partial<typeof defaultOptions>
) => {
  const {
    humanizeInputs,
    renderWithPlaceHolders,
    missingBindingPlaceHolder,
    returnErrors,
    flattenInput
  } = {
    ...defaultOptions,
    ...options
  };
  if (input && flattenInput) {
    input = flatten(input);
  }
  if (
    !template ||
    (!renderWithPlaceHolders && !areTemplateBindingsFulfilled(template, input))
  ) {
    return "";
  }
  const bindings =
    template
      .match(TEMPLATE_BINDING_EXTRACTER)
      ?.map(re => re.replace(TEMPLATE_BINDING_EXTRACTER, `$1`)) || [];

  const bindingResolutions =
    input && bindings.length > 0
      ? Object.entries(input).reduce<Record<string, DataValue>>((agg, pair) => {
          if (!bindings.includes(pair[0])) return agg;

          if (returnErrors && pair[1] === ErrorValues.permissionDenied) {
            return set(agg, pair[0], pair[1]);
          }

          const value = String(pair[1]).trim();
          return set(agg, pair[0], humanizeInputs ? getDisplayValue(value) : value);
        }, {})
      : {};

  const hasPermissionsError = bindings.some(
    b => get(bindingResolutions, b) === ErrorValues.permissionDenied
  );

  // when returnErrors flag is true, return an error instead of evaluated string
  if (returnErrors && hasPermissionsError) {
    return ErrorValues.permissionDenied;
  }

  if (renderWithPlaceHolders) {
    const unresolvedBindingPaths = bindings.filter(b => !get(bindingResolutions, b));
    unresolvedBindingPaths.map(b =>
      set(bindingResolutions, b, missingBindingPlaceHolder)
    );
  }
  return template.replace(TEMPLATE_BINDING_EXTRACTER, (_, b) => {
    return String(get(bindingResolutions, b));
  });
};

function findChildNodeBySlug(
  slug: string,
  node: ComponentNode
): ComponentNode | undefined {
  if (slug === node.component.slug) {
    return node;
  }
  for (let i = 0; i < node.children.length; ++i) {
    const found = findChildNodeBySlug(slug, node.children[i]);
    if (found) {
      return found;
    }
  }
}

export function getChildrenSchema(node: ComponentNode, componentSlugs: string[]) {
  return componentSlugs.reduce<ObjectBinding[]>((acc, slug) => {
    const child = findChildNodeBySlug(slug, node);
    if (child) {
      const pkg = findPackage(child.component.type);

      // Do not call getSchema on pseudo components. Doing so will cause
      // infinite loops if getSchema is called by a pseudo component from
      // it's own package.
      if (pkg && !pkg.isPseudoComponent) {
        const bindings = pkg.getSchema(child);
        acc.push({
          name: child.component.slug,
          title: getDisplayName(child.component),
          attributes: bindings,
          shape: BindingShape.OBJECT
        });
      }
    }
    return acc;
  }, []);
}

export const getBindingFromPath = (
  node: ComponentNode,
  path: string
): Binding | null => {
  const parts = parsePath(path || "");
  if (!parts.length) return null;

  let root: ComponentNode = node;
  while (root.parent) {
    root = root.parent as ComponentNode;
  }

  // Form a componentMap by iterating the component tree from root
  const nodeMap = new Map();
  const queue = [root];
  while (queue && queue.length) {
    const next = queue.pop() as ComponentNode;
    if (next && next.component) nodeMap.set(next.component.slug, next);
    if (next && next.children) queue.push(...next.children);
  }

  // Start at the tail of the binding path and walk up looking for a path
  // part that is the slug of a component in the space.
  let partsCursor = parts.length - 1;
  let bindingNode;
  while (bindingNode === undefined) {
    bindingNode = nodeMap.get(parts[partsCursor]);
    if (bindingNode === undefined) {
      partsCursor = partsCursor - 1;
    }
    if (partsCursor === -1) {
      return null;
    }
  }

  // Take the tail of the path up to the component which was found
  // and use as the output property.
  const outputProperty = createPath(parts.slice(partsCursor + 1, parts.length));

  if (!outputProperty) return null;

  const pkg = findPackage(bindingNode.component.type);
  if (pkg?.getOutputBinding) {
    return pkg.getOutputBinding(bindingNode.component, outputProperty) || null;
  }

  if (pkg?.getSchema) {
    const bindings = pkg.getSchema(bindingNode);
    return bindings.find(binding => binding.name === outputProperty) || null;
  }
  return null;
};

export const hasInputComponentProperties = (component: SpaceComponentObject) => {
  const supportsBlank = !COMPONENTS_WITHOUT_BLANK_SUPPORT.includes(component.type);
  if (
    typeof component.properties.default_value_type === "string" &&
    typeof component.properties.validation_type === "string" &&
    (!supportsBlank ||
      (typeof component.properties.allow_blank === "boolean" &&
        typeof component.properties.blank_value_type === "string"))
  ) {
    return true;
  }
  return false;
};

// binding shapes that should be available in binding cascader
// to populate input fields based on attribute type
export const BINDING_SHAPES_BY_TYPE: Record<AttributeTypes, BindingShape[]> = {
  [AttributeTypes.BOOL]: [BindingShape.SCALAR],
  [AttributeTypes.DECIMAL]: [BindingShape.SCALAR],
  [AttributeTypes.FLOAT]: [BindingShape.SCALAR],
  [AttributeTypes.INT]: [BindingShape.SCALAR],
  [AttributeTypes.STRING]: [BindingShape.SCALAR],
  [AttributeTypes.DATE]: [BindingShape.SCALAR],
  [AttributeTypes.TIME]: [BindingShape.SCALAR],
  [AttributeTypes.DATETIME]: [BindingShape.SCALAR],
  [AttributeTypes.TIMESTAMP]: [BindingShape.SCALAR],
  [AttributeTypes.JSON]: [
    BindingShape.SCALAR,
    BindingShape.OBJECT_ARRAY,
    BindingShape.OBJECT,
    BindingShape.UNKNOWN
  ],
  [AttributeTypes.BINARY]: [BindingShape.SCALAR],
  [AttributeTypes.FILE]: [BindingShape.OBJECT]
};

export const PARAMETER_BLANK_VALUE_TYPES = [
  BlankValueType.NULL_VALUE,
  BlankValueType.EMPTY_STRING,
  BlankValueType.UNDEFINED
];

export const getSupportedBlankValueTypes = (
  type: SpaceComponentType,
  validation_type: string
) => {
  switch (type) {
    case "CHECKBOX":
      return [];
    case "TAG_SELECTOR":
      if (validation_type === toProperty(AttributeTypes.STRING)) {
        return [
          BlankValueType.NULL_VALUE,
          BlankValueType.EMPTY_STRING,
          BlankValueType.UNDEFINED
        ];
      }
      return [
        BlankValueType.NULL_VALUE,
        BlankValueType.EMPTY_ARRAY,
        BlankValueType.UNDEFINED
      ];
    case "DROPDOWN":
    case "RADIO_BUTTON":
      if (validation_type === toProperty(AttributeTypes.BOOL)) {
        return [];
      }
      return [
        BlankValueType.NULL_VALUE,
        BlankValueType.EMPTY_STRING,
        BlankValueType.UNDEFINED
      ];
    case "CUSTOM_FIELD":
    case "DATE_TIME_PICKER":
    case "FILE_PICKER":
    case "JSON_INPUT":
    case "TEXT_AREA":
      return [
        BlankValueType.NULL_VALUE,
        BlankValueType.EMPTY_STRING,
        BlankValueType.UNDEFINED
      ];
    default:
      return [];
  }
};

export const BlankValueTypesDisplayNames: Record<BlankValueType, string> = {
  [BlankValueType.NULL_VALUE]: "Null",
  [BlankValueType.EMPTY_STRING]: "Empty String",
  [BlankValueType.UNDEFINED]: "Ignored (don't pass value)",
  [BlankValueType.EMPTY_ARRAY]: "Empty Array"
};
