import styled from "styled-components";

export const Section = styled.div`
  padding-bottom: ${props => props.theme.spacerxxl};

  :last-child {
    padding-bottom: 0;
  }

  .ant-select {
    padding-right: ${props => props.theme.spacerxl};
  }
`;

export const CategoriesContent = styled.div`
  padding-bottom: ${props => props.theme.spacerlg};
`;

export const ParametersContent = styled.div`
  display: grid;
  grid-template-columns: minmax(max-content, 200px) 200px min-content;
  grid-column-gap: ${props => props.theme.spacersm};
  grid-row-gap: ${props => props.theme.spacersm};
  align-items: center;
`;

export const FieldColumnHeader = styled.div<{ center?: boolean }>`
  line-height: 30px;
  font-weight: ${props => props.theme.boldFontWeight};
  text-transform: uppercase;
  font-size: ${props => props.theme.smallFontSize};
  ${props => (props.center ? "justify-self: center;" : "")}
`;
