import React from "react";

import styled from "styled-components";

const ProgressBar = styled.div`
  height: 10px;
  background-color: ${props => props.theme.newContainerPrimaryTint};
  padding: 0;
  min-height: 0;
  overflow: hidden;
  margin: 10px 0 30px 0;
  width: 100%;
`;

const ProgressFilling = styled.div<{ percentage: number }>`
  background-color: ${props => props.theme.primaryAccent}
  height: 10px;
  width: ${props => props.percentage}%;
`;

export const LinearProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <ProgressBar>
      <ProgressFilling percentage={percentage} />
    </ProgressBar>
  );
};
