import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import HeaderConfigSection from "../../common/HeaderConfig";
import ParametersConfigSection from "../../common/ParametersManager";
import useViewConfig from "../../common/useViewConfig";
import { hasSource } from "../../common/util";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import BasicConfig from "./BasicConfig";
import FieldsConfigSection from "./FieldsConfig";
import FiltersConfigSection from "./FiltersConfig/FiltersConfigs";
import { ensureCardListConfigState } from "./reducer";
import TextConfigSection from "./TextConfig";

export default function SpaceCardListConfig(props: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<SpaceCardListConfigTabs />}
      designConfig={<DesignConfig />}
      slug={props.slug}
      collectionKey="cards"
    />
  );
}

function SpaceCardListConfigTabs() {
  const context = useComponentConfigContext();
  const state = ensureCardListConfigState(context.state);
  useViewConfig({
    state: state,
    dispatch: context.dispatch
  });

  return (
    <div>
      <ConfigSection title="Source" children={<BasicConfig />} />
      {hasSource(context.state.draftComponent) && (
        <>
          <TextConfigSection state={state} dispatch={context.dispatch} />
          <ParametersConfigSection title="Parameters" />
          <FieldsConfigSection title="Fields" />
          <FiltersConfigSection title="Filters" />
          <VisibilityRulesManagerSection />
        </>
      )}
    </div>
  );
}

function DesignConfig() {
  return <HeaderConfigSection />;
}
