import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Skeleton, Tabs } from "antd";
import gql from "graphql-tag";

import { GlobalStyleVariables } from "../../../cssConstants";
import { DataSource } from "../DataSourceTable";

import { DataSourceSyncPanel } from "./DataSourceSyncPanel/DataSourceSyncPanel";
import { FunctionsPanel } from "./FunctionsPanel/FunctionsPanel";
import ManageCredentialsTab from "./ManageCredentialsTab";
import * as styled from "./styledComponents";

interface Props {
  onClose: () => void;
  isVisible: boolean;
  loading: boolean;
  dataSource: DataSource | undefined;
}

interface DrawerContentProps {
  dataSource: DataSource;
  onClose: () => void;
  loading: boolean;
}

export const RENAME_DATA_SOURCE_MUTATION = gql`
  mutation RenameDataSourceMutation($dataSourceId: ID!, $name: String!) {
    renameDataSource(dataSourceId: $dataSourceId, name: $name) {
      ok
      dataSource {
        id
        name
      }
    }
  }
`;

export const DrawerWithEnvironmentCredentials = ({
  dataSource,
  onClose,
  loading
}: DrawerContentProps) => {
  const [rename] = useMutation(RENAME_DATA_SOURCE_MUTATION);
  const renameDataSource = (name: string) => {
    rename({
      variables: {
        dataSourceId: dataSource.id,
        name: name
      }
    });
  };
  return (
    <>
      <styled.HeaderWithDrawerPadding>
        <div>{dataSource.integrationTitle}</div>
        <styled.HeaderInlineInput
          data-test="headerInline"
          includeEditIcon
          isValidValue={e => e.trim().length > 0}
          onChange={e => renameDataSource(e.target.value)}
          value={dataSource.name}
        />
      </styled.HeaderWithDrawerPadding>
      <styled.StyledTabs defaultActiveKey="1">
        <Tabs.TabPane tab="Environments" key="1">
          <styled.DrawerSidePadding>
            <ManageCredentialsTab dataSource={dataSource} onClose={onClose} />
          </styled.DrawerSidePadding>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Functions" key="2">
          <styled.FunctionTabPadding>
            {dataSource.supportsSync && (
              <DataSourceSyncPanel loading={loading} dataSource={dataSource} />
            )}
            <FunctionsPanel
              dataSource={dataSource}
              modalRightOffset={GlobalStyleVariables.drawerWidth}
            />
          </styled.FunctionTabPadding>
        </Tabs.TabPane>
      </styled.StyledTabs>
    </>
  );
};

const DataSourceDrawer = ({ onClose, loading, isVisible, dataSource }: Props) => (
  <styled.DrawerNoPadding
    width={GlobalStyleVariables.drawerWidth}
    placement="right"
    closable={false}
    onClose={onClose}
    visible={isVisible}
  >
    <Skeleton loading={loading} active={loading}>
      {dataSource && (
        <DrawerWithEnvironmentCredentials
          dataSource={dataSource}
          loading={loading}
          onClose={onClose}
        />
      )}
    </Skeleton>
  </styled.DrawerNoPadding>
);

export default DataSourceDrawer;
