import React from "react";

import { Skeleton } from "antd";

import Message from "../../../../../common/Message";
import { useInterval } from "../../../../../common/useInterval/useInterval";
import { Comment } from "../Comment/Comment";
import { CreateCommentForm } from "../CreateComment/CreateComment";
import { UpdateComment } from "../UpdateComment/UpdateComment";

import { CommentData, useDeleteComment, useTaskComments } from "./queries";
import { CommentListDispatch, ReducerActions, State } from "./reducer";
import * as Styled from "./styledComponents";

export interface Props {
  className?: string;
  state: State;
  dispatch: CommentListDispatch;
}

export function CommentList({ className, state, dispatch }: Props) {
  const [editingComment, setEditingComment] = React.useState("");
  const [loadingNewComment, setLoadingNewComment] = React.useState(false);
  const { taskId, comments } = state;

  const { loading, error, refetch, fetchMore } = useTaskComments({
    variables: {
      taskId: taskId!,
      orderBy: "-createdAt",
      first: 100
    },
    skip: !taskId,
    onCompleted: data => {
      if (!data) {
        return;
      }

      if (!taskId) {
        throw new Error("Task id missing");
      }

      if (taskId === state.taskId) {
        dispatch({
          type: ReducerActions.Load,
          payload: data
        });
      }
    }
  });

  React.useEffect(() => {
    if (error) Message.error("Something went wrong. Please try again.");
  }, [error]);

  const onLoadNewComments = React.useCallback(
    (prev: CommentData, { fetchMoreResult }: { fetchMoreResult?: CommentData }) => {
      if (!taskId) {
        throw new Error("Task id missing");
      }

      if (!fetchMoreResult) return prev;

      if (taskId === state.taskId) {
        dispatch({
          type: ReducerActions.LoadNewer,
          payload: fetchMoreResult
        });
      }

      return prev;
    },
    [dispatch, taskId, state.taskId]
  );

  const loadMoreComments = React.useCallback(() => {
    if (!state.endCursor) {
      return;
    }

    fetchMore({
      variables: { beforeComment: state.endCursor! },
      updateQuery: (
        prev: CommentData,
        { fetchMoreResult }: { fetchMoreResult?: CommentData }
      ) => {
        if (!taskId) {
          throw new Error("Task id missing");
        }

        if (!fetchMoreResult) return prev;

        if (taskId === state.taskId) {
          dispatch({
            type: ReducerActions.LoadOlder,
            payload: fetchMoreResult
          });
        }

        return prev;
      }
    });
  }, [fetchMore, dispatch, taskId, state.taskId, state.endCursor]);

  const onNewCommentAdded = React.useCallback(async () => {
    setLoadingNewComment(true);

    await fetchMore({
      variables: { afterComment: state.startCursor },
      updateQuery: onLoadNewComments
    });

    refetch();

    setLoadingNewComment(false);
  }, [onLoadNewComments, fetchMore, setLoadingNewComment, refetch, state.startCursor]);

  const [deleteComment, { loading: deleteLoading, error: deleteError }] =
    useDeleteComment();

  const onCommentDelete = React.useCallback(
    async (id: string) => {
      if (!taskId) {
        throw new Error("Task id missing");
      }

      await deleteComment({ variables: { id } });

      dispatch({
        type: ReducerActions.Delete,
        payload: {
          commentId: id
        }
      });

      refetch();

      Message.success("Comment deleted");
    },
    [deleteComment, dispatch, refetch, taskId]
  );

  React.useEffect(() => {
    if (deleteError) Message.error("Something went wrong. Please try again.");
  }, [deleteError]);

  useInterval(() => {
    fetchMore({
      variables: { afterComment: state.startCursor },
      updateQuery: onLoadNewComments
    });
  }, 5000);

  if (loading || !taskId) {
    return (
      <div className={className}>
        <Skeleton loading active avatar />
        <Skeleton loading active avatar />
        <Skeleton loading active avatar />
      </div>
    );
  }

  return (
    <Styled.Container className={className}>
      <CreateCommentForm taskId={taskId!} onSuccess={() => onNewCommentAdded()} />
      <Styled.CommentsContainer>
        {loadingNewComment && <Skeleton avatar />}
        <Styled.Comments>
          {comments &&
            comments.map(comment => {
              return comment.id !== editingComment ? (
                <Comment
                  key={comment.id}
                  comment={comment}
                  disabled={deleteLoading}
                  onEditClick={() => setEditingComment(comment.id)}
                  onDeleteClick={() => onCommentDelete(comment.id)}
                />
              ) : (
                <UpdateComment
                  key={comment.id}
                  comment={comment}
                  dispatch={dispatch}
                  onCancel={() => setEditingComment("")}
                  onCompleted={() => setEditingComment("")}
                />
              );
            })}
        </Styled.Comments>
      </Styled.CommentsContainer>
      {state.pageInfo?.hasNextPage && (
        <Styled.LoadMoreButton type="primary" onClick={() => loadMoreComments()}>
          Load more
        </Styled.LoadMoreButton>
      )}
    </Styled.Container>
  );
}
