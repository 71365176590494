import Icon from "../../../../../assets/spaceicons/text.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout, OverflowOption, LayoutUnit } from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import Config, { ensureSpaceCalculatedField } from "./Config";
import errorSelector from "./Config/errorSelector";
import { getSchema } from "./getSchema";
import SpaceCalculatedField from "./SpaceCalculatedField";
import {
  SpaceCalculatedFieldComponent,
  CalculatedFieldConfigState,
  SpaceCalculatedFieldProperties
} from "./types";

const pkg: SpaceComponentPackage<
  SpaceCalculatedFieldProperties,
  SpaceCalculatedFieldComponent,
  CalculatedFieldConfigState
> = {
  type: "CALCULATED_FIELD",
  displayName: "Text",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "DETAIL",
    "CARD_LIST",
    "TABLE",
    "FLEX_BOX"
  ]),
  Component: SpaceCalculatedField,
  ConfigurationComponent: Config,
  componentConfigReducer: commonComponentReducer,
  ensureComponent: ensureSpaceCalculatedField,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: LayoutUnit.AUTO,
    height: LayoutUnit.AUTO,
    overflow: OverflowOption.VISIBLE,
    snapToGrid: true
  }),
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Content],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
