import { FunctionValidationStatus } from "../../..";
import { SqlQueryFunctionName } from "../../constants";
import {
  CommonFunctionAction,
  DataSourceFunction,
  GeneralActionTypes
} from "../../types";

export interface QueryArg {
  name: string;
  value: string;
}

export interface SqlActionState {
  actionType: SqlQueryFunctionName;
  sql: string;
  args: QueryArg[];
  status: FunctionValidationStatus;
  error: string;
}

const getEmptyInitialState = (actionType: SqlQueryFunctionName): SqlActionState => ({
  actionType: actionType,
  sql: "",
  args: [],
  status: FunctionValidationStatus.INVALID,
  error: ""
});

export const getInitialState = (
  actionType: SqlQueryFunctionName,
  func?: DataSourceFunction
): SqlActionState => {
  if (typeof func?.metadata?.code !== "object") {
    return getEmptyInitialState(actionType);
  }
  return {
    ...getEmptyInitialState(actionType),
    status:
      func.metadata.code.sql && func.metadata.code.args
        ? FunctionValidationStatus.VALID
        : FunctionValidationStatus.INVALID,
    sql: func.metadata.code.sql || "",
    args: JSON.parse(func.metadata.code.args) || []
  };
};

export enum ActionTypes {
  DESCRIBE_START = "DESCRIBE_START",
  DESCRIBE_SUCCESS = "DESCRIBE_SUCCESS",
  SET_ARG_VALUE = "SET_ARG_VALUE"
}

export interface DescribeStart {
  type: ActionTypes.DESCRIBE_START;
  payload: {
    sql: string;
  };
}

export interface DescribeSuccess {
  type: ActionTypes.DESCRIBE_SUCCESS;
  payload: {
    args: QueryArg[];
  };
}

export interface SetArgValue {
  type: ActionTypes.SET_ARG_VALUE;
  payload: {
    index: number;
    value: string;
  };
}

type FunctionAction =
  | CommonFunctionAction
  | DescribeStart
  | DescribeSuccess
  | SetArgValue;

function reducer(state: SqlActionState, action: FunctionAction): SqlActionState {
  switch (action.type) {
    case ActionTypes.DESCRIBE_START:
      return {
        ...state,
        sql: action.payload.sql,
        status: FunctionValidationStatus.VALIDATING
      };

    case ActionTypes.DESCRIBE_SUCCESS:
      return {
        ...state,
        args: action.payload.args,
        status: FunctionValidationStatus.VALID,
        error: ""
      };

    case ActionTypes.SET_ARG_VALUE: {
      const args = [...state.args];
      args[action.payload.index].value = action.payload.value;
      return { ...state, args };
    }

    case GeneralActionTypes.RESET_FIELDS:
      return { ...getEmptyInitialState(state.actionType) };

    case GeneralActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        status: FunctionValidationStatus.INVALID,
        error: action.payload.errorMessage
      };

    default:
      return state;
  }
}

export default reducer;
