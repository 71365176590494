import React from "react";

import { SketchPicker } from "react-color";
import styled from "styled-components";

import { colorTokens } from "../../../cssConstants";

const swatchGutter = "2px";

const BaseRoot = styled.div<{ border: boolean }>`
  position: relative;
  padding: ${swatchGutter};
  background: ${props => (props.border ? colorTokens.grey600 : "none")};
`;

const NormalRoot = styled(BaseRoot)`
  width: ${props => props.theme.inputHeight};
  height: ${props => props.theme.inputHeight};
`;

const SmallRoot = styled(BaseRoot)`
  width: ${props => props.theme.inputHeightSm};
  height: ${props => props.theme.inputHeightSm};
`;

const Swatch = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

interface StyledSketchPickerProps {
  position: "fixed" | "relative";
}

const StyledSketchPicker = styled(SketchPicker)<StyledSketchPickerProps>`
  position: ${props => props.position};
  z-index: 1;
`;

export const DEFAULT_COLOR_OPTIONS = [
  colorTokens.greyTransparent1300,
  colorTokens.red300,
  colorTokens.red200,
  colorTokens.orange300,
  colorTokens.yellow200,
  colorTokens.green400,
  colorTokens.blue200,
  colorTokens.black
];

interface ColorPickerProps {
  value: string;
  colorOptions?: string[];
  className?: string;
  border?: boolean;
  size?: "small" | "normal";
  position?: "relative" | "fixed";
  onChange: (value: string) => void;
  onChangeComplete?: (value: string) => void;
}

function ColorPicker({
  value,
  colorOptions = DEFAULT_COLOR_OPTIONS,
  className,
  border = true,
  size = "normal",
  position = "fixed",
  onChange,
  onChangeComplete
}: ColorPickerProps) {
  const [popperOpen, setPopperOpen] = React.useState(false);
  const Root = size === "normal" ? NormalRoot : SmallRoot;
  return (
    <Root className={className} border={border}>
      <Swatch
        style={{ background: value }}
        onClick={() => {
          setPopperOpen(!popperOpen);
        }}
      />
      {popperOpen && (
        <>
          <Cover
            onClick={() => {
              setPopperOpen(false);
            }}
          />
          <StyledSketchPicker
            position={position}
            color={value}
            presetColors={colorOptions}
            disableAlpha={true}
            onChange={color => {
              onChange(color.hex);
            }}
            onChangeComplete={color => {
              onChangeComplete?.(color.hex);
            }}
          />
        </>
      )}
    </Root>
  );
}

/**
 * CachedColorPicker only updates the color value onChangeComplete.
 * This allows the UI picker to show updates, but reduces UI rerendering on each change.
 */
export function CachedColorPicker(props: ColorPickerProps) {
  const [color, setColor] = React.useState(props.value);

  React.useEffect(() => {
    setColor(props.value);
  }, [props.value, setColor]);

  return (
    <ColorPicker
      {...props}
      value={color}
      onChange={setColor}
      onChangeComplete={props.onChange}
    />
  );
}

export default ColorPicker;
