import React from "react";

import { Select } from "antd";

import { ReturnSchema, ReturnSchemaDisplayNames } from "../../../../constants";

import * as styled from "./styledComponents";

interface Props {
  value: ReturnSchema | undefined;
  onChange: (value: ReturnSchema) => void;
  disabled?: boolean;
}

export const ReturnSchemaSelect = (props: Props) => (
  <styled.Select
    data-test="formatDropdown"
    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
    value={props.value}
    onChange={v => props.onChange(v as ReturnSchema)}
    disabled={!!props.disabled}
    placeholder="Select an output format..."
  >
    {Object.entries(ReturnSchemaDisplayNames).map(([returnSchema, display]) => (
      <Select.Option key={returnSchema} value={returnSchema}>
        {display}
      </Select.Option>
    ))}
  </styled.Select>
);
