import React from "react";

import styled from "styled-components";

export default function TextWithLineBreaks({ value }: { value: string | undefined }) {
  if (value === undefined) {
    return null;
  }
  return (
    <>
      {value.split("\n").map((ln, i) => (
        <Line key={`ln${i}`}>{ln || <>&nbsp;</>}</Line>
      ))}
    </>
  );
}

const Line = styled.p`
  margin-bottom: 0;
`;
