import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

import { SpaceComponentObject } from "../../../../../types";
import Section, { SectionChild } from "../../../../common/Menu/Section";

interface Props {
  displayName: string;
  spaceComponents: SpaceComponentObject[];
}

const Container = styled.div`
  i {
    margin-right: ${props => props.theme.spacerxs};
  }
`;

export default function UserConfigurationSection(props: Props) {
  return (
    <Section title={props.displayName}>
      <SectionChild>
        <Container>
          <Icon type="mail" />
          Email
        </Container>
      </SectionChild>
    </Section>
  );
}
