import React from "react";

import { FunctionParameterNode } from "../../../../../../types";
import { createSpaceFunction } from "../../../../FunctionExecutor/FunctionExecutor";
import { useSpaceContext } from "../../../SpaceContext";
import { FORM_COMPONENT_TYPES } from "../../constants";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { InputParameter, ParameterType } from "../useFuncParams/types";

export default function useFormConfig() {
  const { components } = useSpaceContext();
  const { state } = useComponentConfigContext();

  const formContainer = React.useMemo(() => {
    let component = state.draftComponent;
    const getComponent = (slug: string) => components.find(c => c.slug === slug);

    while (component.container) {
      component = component.container;
      if (component && FORM_COMPONENT_TYPES.includes(component.type)) {
        return getComponent(component.slug);
      }
    }
    return undefined;
  }, [state.draftComponent, components]);

  const func = React.useMemo(() => {
    return formContainer ? createSpaceFunction(formContainer) : undefined;
  }, [formContainer]);

  const getFunctionParameterFromSlug = React.useCallback(
    (slug: string) => {
      if (!func) return undefined;
      const functionParameters = func?.functionParameters;
      const inputParameters = formContainer?.properties.input_parameters;
      const ip = inputParameters.find(
        (param: InputParameter) =>
          param.type === ParameterType.COMPONENT && param.component_slug === slug
      );
      if (!ip) return undefined;
      return functionParameters.find(
        (fp: FunctionParameterNode) => fp.name === ip.name
      );
    },
    [func, formContainer]
  );

  return {
    formContainer,
    getFunctionParameterFromSlug
  };
}
