import { Link } from "react-router-dom";
import styled from "styled-components";

import ChevronLeft from "../../../assets/icons/chevron-left.svg";
import { colors } from "../../../cssConstants";
import { B3, H4 } from "../../common/StyledComponents";

import BaseAverageTimeToCloseStatistics from "./AverageTimeToCloseStatistics";
import DurationSelect from "./DurationSelect";
import BaseNewTasksCreatedStatistics from "./NewTasksCreatedStatistics";
import BaseOpenStateTaskRoleStatistics from "./OpenStateTaskRoleStatistics";
import BaseOpenStateTaskStatistics from "./OpenStateTaskStatistics";
import BaseOpenStateTaskUserStatistics from "./OpenStateTaskUserStatistics";
import BaseTasksCompletedStatistics from "./TasksCompletedStatistics";

export const AnalyticsRoot = styled.div`
  display: flex;
  align-items: stretch;
`;

export const AnalyticsMain = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BackLink = styled(Link)`
  height: 24px;
  display: flex;
  align-items: baseline;
  gap: 24px;
`;

export const BackLinkText = styled(B3)`
  font-weight: 600;
  line-height: 21px;
`;

export const BackIcon = styled(ChevronLeft)`
  color: ${colors.dropdownIndicator};
  width: 6px;
  height: 11px;
`;

export const QueueName = styled(B3)`
  line-height: 21px;
  color: ${props => props.theme.surfaceSecondary};
  display: block;
  margin-top: 28px;
`;

export const DayLabel = styled(H4)`
  margin-top: 40px;
`;

export const TodayStatistics = styled.div`
  margin-top: 24px;
  display: flex;
  height: 240px;
  gap: 24px;
`;

export const OpenStateTaskStatistics = styled(BaseOpenStateTaskStatistics)`
  min-width: 450px;
`;

export const OpenStateTaskUserStatistics = styled(BaseOpenStateTaskUserStatistics)`
  min-width: 450px;
`;

export const OpenStateTaskRoleStatistics = styled(BaseOpenStateTaskRoleStatistics)`
  min-width: 450px;
`;

export const PastActivityContainer = styled.div`
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const PastActivityHeader = styled.div`
  display: flex;
  align-items: baseline;
  gap: 24px;
`;

export const PastActivityStats = styled.div`
  display: flex;
  gap: 24px;
  height: 130px;
`;

export const WithinTimeSelector = styled(DurationSelect)`
  min-width: 240px;
`;

export const NewTasksCreatedStatistics = styled(BaseNewTasksCreatedStatistics)`
  flex-grow: 1;
`;

export const TasksCompletedStatistics = styled(BaseTasksCompletedStatistics)`
  flex-grow: 1;
`;

export const AverageTimeToCloseStatistics = styled(BaseAverageTimeToCloseStatistics)`
  flex-grow: 2;
`;
