import { useQuery } from "@apollo/react-hooks";

import { FETCH_RESOURCES, ResourceData, ResourceVars } from "./queries";

export const useResources = (dataSourceId: string) => {
  const { data, error, loading } = useQuery<ResourceData, ResourceVars>(
    FETCH_RESOURCES,
    {
      variables: { dataSourceId }
    }
  );
  return {
    resources: data?.dataSource.resources.edges.map(e => e.node) || [],
    error,
    loading
  };
};
