import React from "react";

import { useNavigate } from "react-router-dom";

import QueuesHero from "../../../../../../public/images/queues_hero.png";
import QueuesIcon from "../../../../../assets/queues-icon.svg";
import { Urls } from "../../../../../constants";
import ButtonNew from "../../../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import usePaths from "../../../../common/hooks/usePaths";
import * as styled from "../../../../common/StyledComponents";

const GettingStarted = ({ className }: { className?: string }) => {
  const { isAdmin } = useAuthUser();
  const { queuesapp_getNewQueue } = usePaths();
  const navigate = useNavigate();
  return (
    <styled.Container className={className}>
      <styled.InnerContainer>
        <styled.GettingStartedHeader>
          <styled.TitleHeaderContainer>
            <styled.TitleContainer>
              <styled.IconContainer>
                <QueuesIcon />
              </styled.IconContainer>
              <styled.Title>Queues</styled.Title>
              {!isAdmin && (
                <styled.PillContainer>
                  <styled.Pill color="#807C86">Not enabled yet</styled.Pill>
                </styled.PillContainer>
              )}
            </styled.TitleContainer>
            <styled.Tagline>
              Create custom workflows to boost your team's productivity and get work
              done.
            </styled.Tagline>
          </styled.TitleHeaderContainer>
          {isAdmin && (
            <ButtonNew
              type="brand"
              size="lg"
              icon="plus"
              onClick={() => navigate(queuesapp_getNewQueue())}
            >
              Create new queue
            </ButtonNew>
          )}
        </styled.GettingStartedHeader>
        <styled.Hero src={QueuesHero} />
        <styled.DescriptionContainer>
          <div>
            <styled.BigP>
              Queues is the fast way to build custom workflows for your business. Define
              every field, state, and action to handle and resolve tasks the way you
              want. No code is required — but you’re always welcome to enhance your
              workflows with Javascript and SQL.
            </styled.BigP>
            <styled.BigP>
              Designed to integrate deeply with your backend systems, Queues makes it
              easy to trigger database queries and API calls at any step of your
              workflow. In addition, the Internal API makes it possible to automatically
              create or update tasks based on custom business logic.
            </styled.BigP>
            <styled.BigP>
              Queues can be used separately or together with Spaces to create powerful
              workflows that not only track and manage work — but help you get the
              actual work done. Get started now!
            </styled.BigP>
          </div>
          <styled.AppLinkContainer>
            <styled.AppLinks>
              <styled.AppLinkItem>
                <styled.AppLink href={Urls.QUEUES_DOCUMENTATION} target="blank">
                  <styled.AppLinkIcon type="file" />
                  <span>Documentation</span>
                </styled.AppLink>
              </styled.AppLinkItem>
            </styled.AppLinks>
          </styled.AppLinkContainer>
        </styled.DescriptionContainer>
      </styled.InnerContainer>
    </styled.Container>
  );
};

export default GettingStarted;
