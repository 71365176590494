import React from "react";

import { Popover, Button } from "antd";
import styled from "styled-components";

import { SpaceComponentType } from "../../../../types";
import { useComponentConfigContext } from "../../SpaceRoot/SpaceComponent/common/ComponentConfigContext";
import useNestedStatus from "../../SpaceRoot/SpaceComponent/common/useNestedStatus";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";

import SpaceComponentIcon from "./SpaceComponentIcon";

interface AddComponentPopoverProps {
  componentTypes: SpaceComponentType[];
  className?: string;
  onAdd: (type: SpaceComponentType) => void;
}

interface AddComponentPopoverContentProps {
  componentTypes: SpaceComponentType[];
  onClick: (type: SpaceComponentType) => void;
}

const Root = styled.div``;

const PopoverTitle = styled.div`
  display: flex;
  min-height: 55px;
  align-items: center;
  justify-content: center;
`;

const IconRow = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const IconButtonContainer = styled(IconRow)`
  margin-top: -${props => props.theme.spacersm}; /* to account for margin in IconButton */
  margin-left: -${props => props.theme.spacersm}; /* to account for margin in IconButton */
`;

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(50% - ${props => props.theme.spacersm});
  height: fit-content;
  margin-top: ${props => props.theme.spacersm};
  margin-left: ${props => props.theme.spacersm};
`;

// use default Button colors
const StyledSpaceComponentIcon = styled(SpaceComponentIcon)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

export const InsertComponentButtons = ({
  componentTypes,
  onClick
}: AddComponentPopoverContentProps) => {
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const { isRepeated } = useNestedStatus();
  const {
    state: {
      draftComponent: { type }
    }
  } = useComponentConfigContext();

  const packages = getSpaceComponentPackages();
  const shouldFilterNonRepeatable =
    packages.find(p => p.type === type)?.isCollection || isRepeated;

  return (
    <IconButtonContainer>
      {componentTypes
        .filter(ct => {
          // filter dynamic button when in config panel
          if (ct === "DYNAMIC_FUNCTION") {
            return false;
          }
          if (
            packages.find(p => p.type === ct)?.isRepeatable === false &&
            shouldFilterNonRepeatable
          ) {
            return false;
          }
          return true;
        })
        .map(type => (
          <IconButton key={type} onClick={() => onClick(type)}>
            <StyledSpaceComponentIcon type={type} />
          </IconButton>
        ))}
    </IconButtonContainer>
  );
};

export default function AddComponentPopover({
  componentTypes,
  className,
  onAdd
}: AddComponentPopoverProps) {
  const [visible, setVisible] = React.useState(false);
  const handleAddClick = (type: SpaceComponentType) => {
    setVisible(false);
    onAdd(type);
  };

  return (
    <Root className={className}>
      <Popover
        placement="bottom"
        visible={visible}
        onVisibleChange={v => setVisible(v)}
        trigger="click"
        title={<PopoverTitle>Add a component</PopoverTitle>}
        content={
          <InsertComponentButtons
            componentTypes={componentTypes}
            onClick={handleAddClick}
          />
        }
        overlayClassName="spaceConfigAddComponentPopover"
      >
        <Button
          type="primary"
          shape="circle"
          icon="plus"
          title="Add component"
          onClick={evt => {
            setVisible(!visible);
            evt.stopPropagation();
          }}
        />
      </Popover>
    </Root>
  );
}
