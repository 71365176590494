import React from "react";

import { BindingShape, SourceType } from "../../../../../../types";
import { Field } from "../../common/ConfigPanel";
import SourceSelector from "../../common/SourceSelector";

export default function SourceConfig() {
  return (
    <Field>
      <SourceSelector
        selectableSourceTypes={[SourceType.BINDING, SourceType.FILE]}
        compatibleBindingShapes={[BindingShape.OBJECT_ARRAY, BindingShape.SCALAR_ARRAY]}
      />
    </Field>
  );
}
