import { BaseFunctionName, Metadata } from "../../../../types";
import { tryError } from "../../../util";
import {
  parseIdentifiers as parseJavascriptIdentifiers,
  unsafeParseIdentifiers as unsafeParseJavascriptIdentifiers
} from "../../parseUtils";
import {
  isSQLQueryFunctionName,
  isSQLWriteFunctionName,
  SqlWriteFunctionName
} from "../forms/constants";
import { QueryBaseFunctionParameterMapping } from "../forms/sql/SqlActionForm";
import { parseBaseFunctionParameterMapping as parseSqlWriteBaseFunctionParameterMapping } from "../forms/sql/WriteForm/reducer";
import {
  getHttpIdentifiersFromState,
  parseBaseFunctionParameterMapping as parseHttpBaseFunctionParameterMapping
} from "../forms/utils";
import {
  BaseFunctionParameterMapping,
  BaseCode,
  HttpFunctionMetadata,
  IdentifierMap,
  ReservedListIdentifierMap
} from "../index";
import {
  areParamsInCodeSupported,
  isHTTPLike,
  isSQLLike,
  SupportedIntegration,
  supportsReservedParams
} from "../support";

export type ParseIdentifierTuple = [IdentifierMap, Error | undefined];

const isCodeMetadata = (code: any): code is BaseCode =>
  code &&
  (typeof code === "string" ||
    (typeof code === "object" && code[Object.keys(code)[0]]));

export function parseIdentifiers<M>(
  integration: SupportedIntegration,
  baseFunctionName: BaseFunctionName,
  baseFunctionParameterMapping: BaseFunctionParameterMapping,
  metadata: Metadata<M>
): ParseIdentifierTuple {
  let identifiers: IdentifierMap = {};
  let error = undefined;
  if (isHTTPLike(integration)) {
    return [parseHttp(baseFunctionParameterMapping, metadata), undefined];
  }

  if (isSQLLike(integration) && isSQLQueryFunctionName(baseFunctionName)) {
    identifiers = {
      ...identifiers,
      ...parseSqlQueryAction(baseFunctionParameterMapping)
    };
  }

  if (isSQLLike(integration) && isSQLWriteFunctionName(baseFunctionName)) {
    identifiers = {
      ...identifiers,
      ...parseSqlWriteAction(baseFunctionName, baseFunctionParameterMapping)
    };
  }

  if (isCodeMetadata(metadata.code) && areParamsInCodeSupported(integration)) {
    const codeParseResult = parseCodeForIdentifiers(metadata.code);
    identifiers = { ...identifiers, ...codeParseResult[0] };
    error = error || codeParseResult[1];
  }

  if (supportsReservedParams(integration, baseFunctionName)) {
    identifiers = {
      ...identifiers,
      ...ReservedListIdentifierMap
    };
  }

  return [identifiers, error];
}

export const parseSqlQueryAction = (mapping: BaseFunctionParameterMapping) => {
  const { arguments: args } = mapping as QueryBaseFunctionParameterMapping;
  return parseJavascriptIdentifiers(args);
};

export const parseSqlWriteAction = (
  action: SqlWriteFunctionName,
  mapping: BaseFunctionParameterMapping
): IdentifierMap => {
  const { filters, changeSet } = parseSqlWriteBaseFunctionParameterMapping(
    action,
    mapping
  );
  return {
    ...filters.reduce<IdentifierMap>(
      (acc, filter) => ({
        ...acc,
        ...parseJavascriptIdentifiers(filter.value)
      }),
      {}
    ),
    ...changeSet.reduce<IdentifierMap>(
      (acc, change) => ({
        ...acc,
        ...parseJavascriptIdentifiers(change.value)
      }),
      {}
    )
  };
};

export const parseHttp = <M>(
  mapping: BaseFunctionParameterMapping,
  metadata: Metadata<M>
) => {
  const m = metadata as HttpFunctionMetadata;
  const httpState = parseHttpBaseFunctionParameterMapping(mapping, m);
  return getHttpIdentifiersFromState(httpState);
};

const parseCodeForIdentifiers = (c: BaseCode): ParseIdentifierTuple => {
  let identifiers: IdentifierMap = {},
    error: Error | undefined = undefined;
  try {
    let code = c;
    // If code is not a string it is a record keyed by the code supporting param type.
    // For the purposes of extracting identifiers join them into an array of code
    // chunks.
    if (typeof code !== "string") {
      code = `[${Object.values(c).join(",")}]`;
    }
    identifiers = unsafeParseJavascriptIdentifiers(code || "");
  } catch (e) {
    const err = tryError(e);
    error = new Error(`SyntaxError: ${err.message}`);
  }
  return [identifiers, error];
};
