import React, { useMemo } from "react";

import { SortableItemCompact } from "../../../../../common/SortableList";
import { useComponentConfigContext } from "../ComponentConfigContext";
import {
  ConditionalExpressionType,
  ConditionalOperator,
  ConditionalSynopsis
} from "../Conditionals";
import {
  EvaluatedConditionalExpression,
  ManagedConditionalExpression
} from "../Conditionals/types";
import { ConfigPanelPopper, ConfigSection } from "../ConfigPanel";
import {
  ConfigPanelActionTypes,
  useSpaceConfigPanelContext
} from "../ConfigPanel/ConfigPanelContext";

import RuleDetails from "./RuleDetails";

const createEmptyManagedRule = (): ManagedConditionalExpression => {
  return {
    type: "managed",
    subject_template: "",
    operator: ConditionalOperator.Equals,
    object_template: "``"
  };
};

const createEmptyEvaluatedRule = (): EvaluatedConditionalExpression => {
  return {
    type: "evaluated",
    template: "true"
  };
};

const getRuleId = (ruleIdx: number) => {
  return `rule${ruleIdx}`;
};

const getRuleName = (ruleIdx: number, componentName: string) => {
  return `${componentName} Rule ${ruleIdx + 1}`;
};

export default function VisibilityRulesManagerSection() {
  const [activeIdx, setActiveIdx] = React.useState(-1);
  const { state, dispatch, errors } = useComponentConfigContext();
  const { state: configPanelState, dispatch: configPanelDispatch } =
    useSpaceConfigPanelContext();
  const rules = useMemo(() => {
    return (
      (state.draftComponent.properties
        .visibility_rules as ConditionalExpressionType[]) || []
    );
  }, [state.draftComponent.properties.visibility_rules]);

  const ruleErrors = errors?.filter(e => e.field === "VISIBILITY_RULES") || [];

  const onChange = React.useCallback(
    visibility_rules => {
      dispatch({
        type: "MERGE_DRAFT_COMPONENT",
        payload: {
          change: {
            properties: { visibility_rules }
          }
        }
      });
    },
    [dispatch]
  );

  const closeConfigPopper = React.useCallback(() => {
    setActiveIdx(-1);
    configPanelDispatch({
      type: ConfigPanelActionTypes.CLOSE_POPPER
    });
  }, [configPanelDispatch]);

  const handleAdd = React.useCallback(() => {
    onChange(rules.concat(createEmptyManagedRule()));
    setActiveIdx(rules.length); // does not include new empty rule
  }, [onChange, rules]);

  React.useEffect(() => {
    if (activeIdx > -1 && activeIdx < rules.length) {
      const ruleId = getRuleId(activeIdx);
      configPanelDispatch({
        type: ConfigPanelActionTypes.OPEN_POPPER,
        payload: {
          popperIdentifier: ruleId
        }
      });
    }
  }, [activeIdx, configPanelDispatch, rules]);

  const activeRuleId = activeIdx > -1 ? getRuleId(activeIdx) : undefined;
  const isConfigOpen =
    activeRuleId && configPanelState.activePopperIdentifier === activeRuleId;

  return (
    <ConfigSection
      id="addVisibilityRuleButton"
      title="Visibility Rules"
      onAdd={handleAdd}
    >
      {rules.map((rule, idx) => {
        const ruleId = getRuleId(idx);
        const errorMessage = ruleErrors.find(e => e.index === idx)?.message || "";

        return (
          <SortableItemCompact
            id={ruleId}
            key={idx}
            sortKey={ruleId}
            isSelected={activeRuleId === ruleId}
            onClick={() => {
              setActiveIdx(idx);
            }}
            onRemove={() => {
              const nextRules = [...rules];
              nextRules.splice(idx, 1);
              onChange(nextRules);
            }}
            errorMessage={errorMessage}
          >
            <ConditionalSynopsis expression={rule} />
          </SortableItemCompact>
        );
      })}
      {isConfigOpen && activeRuleId && (
        <ConfigPanelPopper
          popperId={activeRuleId}
          popperReferenceElement={document.getElementById(activeRuleId) || undefined}
          onCancel={closeConfigPopper}
        >
          <RuleDetails
            key={activeIdx}
            header={getRuleName(activeIdx, state.draftComponent.name)}
            rule={rules[activeIdx]}
            defaultManagedRule={createEmptyManagedRule()}
            defaultEvaluatedRule={createEmptyEvaluatedRule()}
            onChange={(rule: ConditionalExpressionType) => {
              const nextRules = [...rules];
              nextRules[activeIdx] = rule;
              onChange(nextRules);
            }}
          />
        </ConfigPanelPopper>
      )}
    </ConfigSection>
  );
}
