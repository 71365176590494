import { cloneDeep } from "lodash";

import { AttributeTypes } from "../../../../../../constants";
import {
  BaseConfigAction,
  BaseComponentConfigState,
  SpaceConfigAction
} from "../../../../types";
import { DefaultValueTypes, BlankValueType } from "../../../constants";
import { getBooleanHardcodedOptionsString } from "../HardcodedOptionFields/utils";
import { toProperty } from "../util";

export enum InputConfigActionTypes {
  SET_DEFAULT_VALUE_TYPE = "SET_DEFAULT_VALUE_TYPE",
  SET_VALIDATION_TYPE = "SET_VALIDATION_TYPE",
  SET_ALLOW_NULL = "SET_ALLOW_NULL", // adds null option
  SET_ALLOW_BLANK = "SET_ALLOW_BLANK", // used to determine if form input is required
  SET_SERIALIZE_VALUE_AS_CSV = "SET_SERIALIZE_VALUE_AS_CSV"
}

interface SetDefaultValueType extends BaseConfigAction {
  type: InputConfigActionTypes.SET_DEFAULT_VALUE_TYPE;
  payload: {
    value: DefaultValueTypes;
  };
}

interface SetValidationType extends BaseConfigAction {
  type: InputConfigActionTypes.SET_VALIDATION_TYPE;
  payload: {
    value: AttributeTypes;
  };
}

// used to determine if null option should be added
interface SetAllowNull extends BaseConfigAction {
  type: InputConfigActionTypes.SET_ALLOW_NULL;
  payload: {
    allow_null: boolean;
  };
}

// used to determine if an input can be left blank (during form validation)
interface SetAllowBlank extends BaseConfigAction {
  type: InputConfigActionTypes.SET_ALLOW_BLANK;
  payload: {
    allow_blank: boolean;
  };
}

interface SetSerializeValueAsCsv extends BaseConfigAction {
  type: InputConfigActionTypes.SET_SERIALIZE_VALUE_AS_CSV;
  payload: {
    serialize_value_as_csv: boolean;
  };
}

export type InputConfigAction =
  | SetDefaultValueType
  | SetValidationType
  | SetAllowNull
  | SetAllowBlank
  | SetSerializeValueAsCsv;

export default function reducer(
  state: BaseComponentConfigState,
  action: SpaceConfigAction
): BaseComponentConfigState {
  switch (action.type) {
    case InputConfigActionTypes.SET_DEFAULT_VALUE_TYPE: {
      const { value } = action.payload;
      const draftState = cloneDeep(state);
      draftState.draftComponent.properties.default_value_type = value;
      if (value !== "binding") {
        draftState.draftComponent.properties.default_value_binding = undefined;
      }
      if (value !== "template") {
        draftState.draftComponent.properties.default_value_template = undefined;
      }
      return draftState;
    }
    case InputConfigActionTypes.SET_VALIDATION_TYPE: {
      const { value } = action.payload;
      const draftState = cloneDeep(state);
      draftState.draftComponent.properties.validation_type = toProperty(value);
      if (["DROPDOWN", "RADIO_BUTTON"].includes(draftState.draftComponent.type)) {
        draftState.draftComponent.properties.hardcoded_options =
          getBooleanHardcodedOptionsString(draftState.draftComponent.properties);
      }
      return draftState;
    }
    case InputConfigActionTypes.SET_ALLOW_NULL: {
      const { allow_null } = action.payload;
      const draftState = cloneDeep(state);
      draftState.draftComponent.properties.allow_null = allow_null;
      if (draftState.draftComponent.type === "RADIO_BUTTON") {
        draftState.draftComponent.properties.hardcoded_options =
          getBooleanHardcodedOptionsString(draftState.draftComponent.properties);
      }
      return draftState;
    }
    case InputConfigActionTypes.SET_ALLOW_BLANK: {
      const { allow_blank } = action.payload;
      const draftState = cloneDeep(state);
      draftState.draftComponent.properties.allow_blank = allow_blank;
      if (!allow_blank && draftState.draftComponent.type === "RADIO_BUTTON") {
        draftState.draftComponent.properties.allow_null = false;
      }
      return draftState;
    }
    case InputConfigActionTypes.SET_SERIALIZE_VALUE_AS_CSV: {
      const { serialize_value_as_csv } = action.payload;
      const draftState = cloneDeep(state);
      draftState.draftComponent.properties.serialize_value_as_csv =
        serialize_value_as_csv;
      draftState.draftComponent.properties.validation_type = serialize_value_as_csv
        ? toProperty(AttributeTypes.STRING)
        : toProperty(AttributeTypes.JSON);
      if (
        serialize_value_as_csv &&
        draftState.draftComponent.properties.blank_value_type ===
          BlankValueType.EMPTY_ARRAY
      ) {
        draftState.draftComponent.properties.blank_value_type =
          BlankValueType.EMPTY_STRING;
      } else if (
        !serialize_value_as_csv &&
        draftState.draftComponent.properties.blank_value_type ===
          BlankValueType.EMPTY_STRING
      ) {
        draftState.draftComponent.properties.blank_value_type =
          BlankValueType.EMPTY_ARRAY;
      }
      return draftState;
    }
    default:
      return state;
  }
}
