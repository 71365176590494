import React from "react";

import { Alert } from "antd";
import { Row as ReactTableRow } from "react-table";

import { Props } from "..";
import { MAX_TABLE_WITH_BINDING_ROWS } from "../../../../../constants";
import { SourceType } from "../../../../../types";
import Table, { TableHandle } from "../../../../common/Table";
import LayoutDelegator from "../../../layout/LayoutDelegator/LayoutDelegator";
import { useStableSpaceContext } from "../../SpaceContext";
import CollectionItemContainer from "../common/CollectionItemContainer";
import Panel from "../common/Panel";
import useRefreshComponent from "../common/useRefreshComponent";
import {
  FetchType,
  QueryExecutionRequirement,
  useViewWithComponentColumns
} from "../common/useView";
import useViewBlockingStates, {
  ViewBlockingStates
} from "../common/useViewBlockingStates/useViewBlockingStates";
import ViewComponentTitle from "../common/ViewComponentTitle/ViewComponentTitle";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { ensureSpaceTableComponent } from "./Config/reducer";
import ExportButton from "./ExportButton";
import SpaceTableSearchBar from "./SpaceTableSearchBar";
import * as styledComponents from "./SpaceTableStyledComponents";
import { SpaceTableState, SpaceTableStatus } from "./types";
import useColumns, { SpaceTableAttributeColumn } from "./useColumns";
import useComponentStatus from "./useComponentStatus";
import useEffectsManager from "./useEffectsManager";
import useRows, { SpaceTableRow, getSpaceStateRow } from "./useRows";
import useRowSelection from "./useRowSelection";
import useSyncLocationToFilters from "./useSyncLocationToFilters";

const { StyledContainerDiv, StyledSearchDiv } = styledComponents;

export default function SpaceTable({ spaceApi, ...props }: Props) {
  const [lastSelectedRows, setLastSelectedRows] = React.useState({
    ids: [] as string[],
    indexes: [] as number[]
  });
  const { spaceId, editMode } = useStableSpaceContext();
  const { input, output, recursivelyClearOutput, updateOutput } =
    useComponentStateContext();
  const spaceTable = ensureSpaceTableComponent(props.spaceComponent);
  const tableRef = React.useRef<TableHandle>(null);
  const spaceTableStatus = (output as SpaceTableState)?.status || SpaceTableStatus.IDLE;

  const viewResult = useViewWithComponentColumns(spaceId || "", spaceTable, input, {
    limit: 20,
    clearRowsOnVariablesChange: false,
    queryExecutionRequirement: !!spaceTable?.properties?.is_filter_required
      ? QueryExecutionRequirement.ANY_FILTER
      : QueryExecutionRequirement.NONE
  });

  const refresh = React.useCallback(() => {
    viewResult.refresh();
    if (tableRef.current !== null) tableRef.current!.clearSelectedRows();
    recursivelyClearOutput();
  }, [recursivelyClearOutput, viewResult]);

  useRefreshComponent(spaceTable, spaceApi, refresh);

  useSyncLocationToFilters(viewResult.filters, spaceTable, viewResult.attributes);

  const selectedRows = (output as SpaceTableState | null)?.selectedRows;
  const selectedRowIds = React.useMemo(() => {
    return (selectedRows || []).map(row => row.id);
  }, [selectedRows]);
  const rowsResult = useRows(
    viewResult.attributes,
    viewResult.rows,
    selectedRowIds,
    viewResult.viewFunction?.access
  );

  React.useEffect(() => {
    if (
      selectedRows === undefined ||
      (selectedRows.length === lastSelectedRows.ids.length &&
        lastSelectedRows.ids.every(id => !!selectedRows.find(sr => sr.id === id)))
    ) {
      return;
    }
    setLastSelectedRows({
      ids: selectedRows.map(({ id }) => id),
      indexes: selectedRows.map(({ id }) =>
        (viewResult.rows || []).findIndex(r => r.id === id)
      )
    });
  }, [selectedRows, lastSelectedRows, viewResult.rows]);

  const rawColumns = useColumns(spaceTable, viewResult, spaceApi, input);

  const columns = React.useMemo(
    () =>
      // Ensure attribute columns are present before providing columns
      // to common table to prevent prematurely measuring table columns
      rawColumns.filter(c => !!(c as SpaceTableAttributeColumn).attribute).length === 0
        ? []
        : rawColumns,
    [rawColumns]
  );

  const { sort } = viewResult;
  const onSort = React.useCallback(
    function onSort(sourceName, direction) {
      sort(sourceName ? { sourceName, direction } : null);
    },
    [sort]
  );

  const { lastSelectedRowIndexes, onSelectRows } = useRowSelection(
    rowsResult,
    selectedRows
  );

  useComponentStatus(viewResult.fetchType, spaceTableStatus, updateOutput);

  useEffectsManager(
    spaceTableStatus,
    spaceTable.properties,
    viewResult.rows || [],
    lastSelectedRows,
    tableRef.current?.selectRows
  );

  const getRowState = React.useCallback(
    function getRowState(row: ReactTableRow<Object>) {
      return {
        isLastSelectedRow: lastSelectedRowIndexes.includes(row.index)
      };
    },
    [lastSelectedRowIndexes]
  );

  const title = spaceTable.properties.is_header_enabled && (
    <ViewComponentTitle component={spaceTable} viewResult={viewResult} />
  );

  const searchFilter =
    spaceTable.properties.is_search_enabled &&
    viewResult.filtersOptions !== undefined &&
    viewResult.filtersOptions.length > 0 ? (
      <StyledSearchDiv>
        <SpaceTableSearchBar
          filtersOptions={viewResult.filtersOptions}
          filters={viewResult.filters}
          getUniqueFilterClientId={viewResult.getUniqueFilterClientId}
          onFilter={viewResult.filter}
        />
      </StyledSearchDiv>
    ) : null;

  const exportButton =
    (spaceTable.properties.is_csv_export_enabled && (
      <ExportButton component={spaceTable} viewResult={viewResult} />
    )) ||
    undefined;

  const { viewBlockingState, viewBlockingStateNode } = useViewBlockingStates({
    component: spaceTable,
    viewResult,
    editMode
  });

  const rows = React.useMemo(
    () =>
      viewResult.sourceType === SourceType.BINDING
        ? rowsResult.rows.slice(0, MAX_TABLE_WITH_BINDING_ROWS)
        : rowsResult.rows,
    [rowsResult, viewResult]
  );

  if (
    ![
      ViewBlockingStates.NONE,
      ViewBlockingStates.EDIT_MODE_BINDING_SOURCE,
      ViewBlockingStates.LACKING_REQUIRED_FILTER
    ].includes(viewBlockingState)
  ) {
    return (
      <Panel
        data-test="space-table"
        title={title}
        headerChildren={searchFilter}
        hasError={props.hasConfigError}
      >
        {viewBlockingStateNode}
      </Panel>
    );
  }

  const alertDescription = (
    <div>
      This table is incomplete. It is displaying data from the first{" "}
      {MAX_TABLE_WITH_BINDING_ROWS} records, but more are available.{" "}
    </div>
  );

  return (
    <Panel
      data-test="space-table"
      title={title}
      headerChildren={spaceTable.properties.is_header_enabled && searchFilter}
      actions={exportButton}
      hasError={props.hasConfigError}
    >
      <StyledContainerDiv>
        {rowsResult.rows.length > rows.length && (
          <Alert
            data-test="alert-too-many-rows"
            message={null}
            showIcon
            type="warning"
            description={alertDescription}
          />
        )}
        <Table
          ref={tableRef}
          refetching={viewResult.fetchType === FetchType.REFETCHING}
          fetchingMore={viewResult.fetchType === FetchType.FETCHING_MORE}
          columns={columns}
          data={rows}
          lastRefetch={viewResult.lastRefetch}
          hasMoreData={viewResult.hasNextPage}
          allowSelectMultiple={!!spaceTable?.properties?.allow_select_multiple}
          sortState={viewResult.orders}
          emptyState={viewBlockingStateNode}
          RowContainer={RowContainer}
          getRowId={getRowId}
          getRowState={getRowState}
          onSort={onSort}
          onScrollToBottom={viewResult.fetchMore}
          onSelectRows={onSelectRows}
        />
      </StyledContainerDiv>
    </Panel>
  );
}

function RowContainer({
  row,
  index,
  children
}: {
  row: ReactTableRow;
  index: number;
  children: React.ReactNode;
}) {
  return (
    <LayoutDelegator isDelegated={index > 0}>
      <CollectionItemContainer collectionKey="rows" index={index}>
        <RowDataSetter row={row}>{children}</RowDataSetter>
      </CollectionItemContainer>
    </LayoutDelegator>
  );
}

function RowDataSetter({
  row,
  children
}: {
  row: ReactTableRow;
  children: React.ReactNode;
}) {
  const { updateOutput } = useComponentStateContext();
  React.useEffect(() => {
    updateOutput(getSpaceStateRow(row.original));
  }, [row.original, updateOutput]);
  return <>{children}</>;
}

function getRowId(row: Object) {
  return getSpaceStateRow(row as SpaceTableRow).id;
}
