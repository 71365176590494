import React from "react";

import { ErrorValues, isBlankValue } from "../../../../../../constants";
import useDebouncedValue from "../../../../../common/hooks/useDebouncedValue";
import usePrevious from "../../../../../common/hooks/usePrevious";
import { getSerializer } from "../../../../../common/utils";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";
import {
  useFunctionFormContext,
  FunctionFormStatus,
  IDLE_FUNCTION_FORM_STATUSES
} from "../../SpaceFunctionForm/FunctionForm";
import useDefaultValue from "../useDefaultValue";
import useOutputSyncing from "../useOutputSyncing";
import { SpaceInputFieldState } from "../useOutputSyncing/useOutputSyncing";
import { toAttributeType } from "../util";

// consolidates debounced updating of output values for input components, custom_field and text_area components
// (any component that requires debounce handling)
export default function useDebouncedOutputSyncing() {
  const { componentNode, output, updateOutput } = useComponentStateContext();
  const { status } = useFunctionFormContext();
  const properties = componentNode?.component.properties || {};
  const { validation_type } = properties;

  const { blankValue, defaultValue } = useDefaultValue();
  const [localValue, setLocalValue] = React.useState(defaultValue);

  const format = React.useCallback(
    value => {
      if (value === ErrorValues.permissionDenied) {
        return ErrorValues.permissionDenied;
      }
      if (isBlankValue(value)) {
        // serialize value as `undefined` if no blank_value_type is specified if
        // value is an empty string (may be the value for the default value returned).
        // `undefined` is needed as a default value for places where the component
        // is used (ie. filter) and no value is set yet. if users want to change
        // this behavior, they can use blank_value_type.
        return blankValue;
      }
      const formatter = getSerializer(toAttributeType(validation_type));
      return formatter ? formatter(value) : value;
    },
    [validation_type, blankValue]
  );

  // formattedDefaultValue will honor any blank_value_type specified when defaultValue is undefined
  const formattedDefaultValue = React.useMemo(() => {
    return format(defaultValue);
  }, [format, defaultValue]);

  const setValue = React.useCallback(
    value => {
      updateOutput({
        value
      });
      setLocalValue(value);
    },
    [updateOutput]
  );

  // Sync if defaultValue changes (e.g. set via binding and binding's value changes)
  // Importantly, this effect does not trigger when output changes.
  React.useEffect(() => {
    if (
      !isBlankValue(defaultValue) &&
      defaultValue === (componentNode?.output as SpaceInputFieldState)?.value
    ) {
      return;
    }
    setValue(formattedDefaultValue);
  }, [defaultValue, formattedDefaultValue, componentNode, setValue]);

  // When output is null set default as output
  React.useEffect(() => {
    if (output !== null) return;
    setValue(formattedDefaultValue);
  }, [output, formattedDefaultValue, setValue]);

  const lastFormStatus = usePrevious(status) || FunctionFormStatus.PENDING;
  const shouldFlush =
    status === FunctionFormStatus.PREPARING &&
    IDLE_FUNCTION_FORM_STATUSES.includes(lastFormStatus);
  const debouncedValue = useDebouncedValue(localValue, 300, shouldFlush);
  const { errorMessage } = useOutputSyncing(debouncedValue);

  return {
    localValue,
    setLocalValue,
    errorMessage,
    hasPermissionError: localValue === ErrorValues.permissionDenied
  };
}
