import React from "react";

import ColumnListManagerSection from "../../../common/ColumnListManager/ColumnListManagerSection";
import ColumnOrder from "../../../common/ColumnOrder";
import ValidationError from "../../../common/ComponentConfigContext/ValidationError";
import { ConfigSection } from "../../../common/ConfigPanel";
import { hasSource } from "../../../common/util";
import { DetailConfigState } from "../reducer";

interface Props {
  state: DetailConfigState;
  title: string;
}
// what to render in config panel
export default function FieldsConfigSection({ title, state }: Props) {
  if (!hasSource(state.draftComponent)) return null;
  return (
    <>
      <ColumnListManagerSection
        title={title}
        attributes={state.attributes || []}
        columnLabel="fields"
        errorField={<ValidationError field="FIELDS" />}
      />
      <ConfigSection title="Sorting">
        <ColumnOrder sortByOptions={state.sortByOptions} />
      </ConfigSection>
    </>
  );
}
