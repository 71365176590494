import styled from "styled-components";

export const EditorContainer = styled.div<{ minHeight?: string }>`
  width: 100%;

  .togglable .CodeMirror {
    border-right: 0;
    border-radius: 4px 0 0 4px;
  }

  .CodeMirror {
    font-family: Arial, monospace;
    font-size: 14px;
    color: ${props => props.theme.inputColor};

    height: auto;
    border: ${props => props.theme.inputBorder};
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    ${props => props.minHeight && `min-height: ${props.minHeight};`}

    .CodeMirror-placeholder {
      color: ${props => props.theme.inputPlaceholderColor};
      font-family: Arial;
    }

    .CodeMirror-lines {
      padding-top: ${props => props.theme.editorPaddingTop};
      padding-bottom: ${props => props.theme.editorPaddingBottom};
    }

    .CodeMirror-gutters {
      ${props => props.minHeight && `min-height: ${props.minHeight};`}
    }

    .CodeMirror-lines {
      ${props => props.minHeight && `min-height: ${props.minHeight};`}
    }

    .CodeMirror-sizer {
      overflow: auto;
    }
  }

  &.disabled .CodeMirror {
    // transition copied from antd @ease-in-out
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    background-color: ${props => props.theme.inputDisabledBackground};
    cursor: not-allowed;

    .CodeMirror-lines {
      cursor: not-allowed;
    }
  }

  // Default theme override
  .cm-s-default .cm-string {
    color: ${props => props.theme.inputColor};
  }

  // tomorrow-night-bright / Dark theme override
  .cm-s-tomorrow-night-bright.CodeMirror {
    background-color: ${props => props.theme.inputBackgroundColor};

    div.CodeMirror-selected {
      background: ${props => props.theme.editorSelectionColor};
    }

    .cm-number {
      color: #e7c547;
    }

    .cm-keyword {
      color: ${props => props.theme.inputPlaceholderColor};
    }

    .cm-def {
      color: ${props => props.theme.editorIdentifierColor};
    }

    .cm-string-2 {
      color: #e7c547;
    }

    .cm-operator {
      color: ${props => props.theme.inputPlaceholderColor};
    }

    .CodeMirror-cursor {
      border-left: 1px solid #bdc1c7;
    }
  }

  // JSTL overrides
  .cm-s-tomorrow-night-bright,
  .cm-s-default {
    .cm-jstl-start,
    .cm-jstl-end {
      color: ${props => props.theme.editorIdentifierColor};
    }

    .cm-variable {
      color: ${props => props.theme.editorIdentifierColor};
      font-weight: bold;
    }
  }

  .ant-popover.ant-popover-placement-bottomLeft {
    padding-top: 0px;
  }

  .ant-popover-inner-content {
    padding: ${props => props.theme.spacerxs} ${props => props.theme.spacersm};
  }

  .ant-popover {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 4px;
      background: linear-gradient(
          0deg,
          ${props => props.theme.editorPopoverBackgroundColor},
          ${props => props.theme.editorPopoverBackgroundColor}
        ),
        ${props => props.theme.editorPopoverBackgroundColor};
    }
  }
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.errorColor};
  margin-top: ${props => props.theme.spacersm};
`;
ErrorMessage.displayName = "ErrorMessage";

export const Mode = styled.span`
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro",
    monospace;
  font-size: ${props => props.theme.smallFontSize}
  color: ${props => props.theme.textColorMid};
  margin-right: ${props => props.theme.spacersm};
`;

export const Code = styled.pre<{ isValid: boolean }>`
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro",
    monospace;
  font-size: ${props => props.theme.smallFontSize};
  display: inline;
  color: ${props =>
    props.isValid ? props.theme.editorPopoverValidTextColor : props.theme.errorColor};
`;

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`;

export const ToggleButton = styled.button<{ isSelected: boolean }>`
  color: ${props =>
    props.isSelected
      ? props.theme.editorJSToggleOnColor
      : props.theme.editorJSToggleOffColor};
  border: 1px solid
    ${props =>
      props.isSelected
        ? props.theme.editorJSToggleOnBorder
        : props.theme.editorJSToggleOffBorder};
  border-radius: 0 4px 4px 0;
  font-size: 12px;
  background-color: ${props =>
    props.isSelected
      ? props.theme.editorJSToggleOnBackground
      : props.theme.editorJSToggleOffBackground};
`;
ToggleButton.displayName = "ToggleButton";
