import React from "react";

import { Icon } from "antd";
import styled from "styled-components";

import { colors } from "../../../../../cssConstants";

interface Props {
  isValid: boolean | undefined;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Message = styled.div`
  margin-left: ${props => props.theme.spacersm};
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
`;

interface IconWrapperProps {
  isValid: boolean;
}

const IconWrapper = styled.span<IconWrapperProps>`
  color: ${props => (props.isValid ? colors.successDarkGreen : props.theme.errorColor)};
`;

export const ValidationFeedback = ({ isValid }: Props) => {
  if (isValid === undefined) return null;

  return (
    <Container>
      {isValid && (
        <>
          <IconWrapper isValid={isValid}>
            <StyledIcon type="check-circle" theme="filled" />
          </IconWrapper>
          <Message>Your data looks good.</Message>
        </>
      )}
      {!isValid && (
        <>
          <IconWrapper isValid={isValid}>
            <StyledIcon type="exclamation-circle" theme="filled" />
          </IconWrapper>
          <Message>
            Your data isn't in the right format. Go to the transformer tab and make
            changes to your result.
          </Message>
        </>
      )}
    </Container>
  );
};
