import React from "react";

import { useParams } from "react-router";

import { RequireAuth } from "../components/auth/RequireAuth";
import SpaceHome from "../components/spaces/Home/Home";
import { SpaceConfigPage } from "../components/spaces/SpaceConfig/SpaceConfigRoot";
import Space, { SpacePage } from "../components/spaces/SpaceRoot/SpaceRoot";
import { Layout, NoHeaderLayout } from "../layouts";

import { AccountLockedGate } from "./errors";

const _Config = () => {
  return (
    <RequireAuth scopes={["space:admin"]}>
      <Layout>
        <AccountLockedGate>
          <SpaceConfigPage />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};

const _Root = () => {
  return (
    <RequireAuth>
      <Layout backgroundColor="grey">
        <AccountLockedGate>
          <SpacePage />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};

export const Home = () => {
  return (
    <RequireAuth>
      <Layout>
        <AccountLockedGate>
          <SpaceHome />
        </AccountLockedGate>
      </Layout>
    </RequireAuth>
  );
};
export const New = _Config;
export const Edit = _Config;
export const Detail = _Root;
export const Activity = _Root;
export const WithResourceCursor = _Root;
export const Embed = () => {
  const { spaceSlug } = useParams<{
    spaceSlug: string;
  }>();
  return (
    <RequireAuth>
      <NoHeaderLayout>
        <AccountLockedGate>
          <Space slug={spaceSlug} />
        </AccountLockedGate>
      </NoHeaderLayout>
    </RequireAuth>
  );
};
