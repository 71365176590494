import moment from "moment";
import momentTz from "moment-timezone";

export const POPPER_PORTAL_ID = "moment-formitem-popper-portal";
export const PORTAL_QUERY_SELECTOR = `#${POPPER_PORTAL_ID}`;

// Utils carried over from MomentFormItemPopper:
// This is tricky. To display PST, EDT etc, a moment object must be
// instantiated with momentTz. However, when the user changes the
// timezone in the picker, it shouldn't affect the input
export const getInputValue = (
  isTimestamp: boolean,
  currentValue: any,
  saveFormat: string
) => {
  return isTimestamp
    ? momentTz(currentValue, saveFormat).tz(momentTz.tz.guess())
    : moment(currentValue, saveFormat);
};
export const getPopperValue = (
  isTimestamp: boolean,
  currentValue: any,
  saveFormat: string,
  timeZone: string | null
) => {
  return isTimestamp
    ? momentTz(currentValue, saveFormat).tz(timeZone || momentTz.tz.guess())
    : moment(currentValue, saveFormat);
};

export interface MomentEditorProps {
  value: moment.Moment;
  onChange: (m: moment.Moment) => void;
}

export interface MomentPopperProps extends MomentEditorProps {
  setTimeZone: (s: string) => void;
}
