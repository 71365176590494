import { useNavigate, useParams } from "react-router-dom";

import { decodeCursor } from "../../../../../constants";
import { FunctionDescriptor } from "../../../../../types";
import usePaths from "../../../../common/hooks/usePaths";
import { getFunctionCursor } from "../util";

import { RecordParams } from "./useGetRecordPath";

interface Result {
  getContextRecordPath: (descriptor: FunctionDescriptor) => string;
  gotoContextRecordPath: (descriptor: FunctionDescriptor) => void;
}

export const useGetFunctionPath = (): Result => {
  const navigate = useNavigate();
  const { getFunctionRecord } = usePaths();
  const params = useParams<RecordParams>();
  const { cursor: rootCursor } = params;
  const decodedRootCursor = decodeCursor(decodeURIComponent(rootCursor!));

  const getContextRecordPath = (descriptor: FunctionDescriptor) => {
    return getFunctionRecord(
      getFunctionCursor(decodedRootCursor),
      "detail",
      descriptor
    );
  };

  return {
    getContextRecordPath,
    gotoContextRecordPath: (descriptor: FunctionDescriptor) =>
      navigate(getContextRecordPath(descriptor))
  };
};
