import React from "react";

import { Select as AntSelect } from "antd";

import { AttributeTypes } from "../../../../../../constants";
import AttributeInput from "../../../../../common/AttributeInput";
import DebouncedInput, {
  DebouncedTextArea
} from "../../../../../common/DebouncedInput";
import { DebouncedEditor } from "../../../../../common/Editor";
import Spacer from "../../../../../common/Spacer";
import { serializeBoolean } from "../../../../../common/utils";
import {
  DEFAULT_TYPE_EXCLUSION_LIST,
  DEFAULT_VALUE_DISPLAY_NAMES,
  DEFAULT_VALUE_OPTIONS,
  DefaultValueTypes
} from "../../../constants";
import { BINDING_SHAPES_BY_TYPE } from "../../util/util";
import { BindingCascader } from "../BindingCascader";
import { useComponentConfigContext } from "../ComponentConfigContext";
import ValidationError from "../ComponentConfigContext/ValidationError";
import { Field } from "../ConfigPanel";
import { Select } from "../ConfigPanel/styledComponents";
import { booleanValueToString } from "../HardcodedOptionFields/utils";
import { InputConfigActionTypes } from "../inputReducer";
import { DebouncedTemplateEditor, Height } from "../TemplateEditor";
import { toAttributeType } from "../util";

enum BooleanSelectOptions {
  TRUE = "true",
  FALSE = "false",
  NULL = "null"
}
interface DefaultValueFieldProps {
  placeholder?: string;
}

export default function DefaultValueField(props: DefaultValueFieldProps) {
  const { state, dispatch } = useComponentConfigContext();

  const defaultValueOptions = React.useMemo(() => {
    return (
      DEFAULT_VALUE_OPTIONS[
        toAttributeType(state.draftComponent.properties.validation_type)
      ] || []
    ).filter(value => {
      const exclusionList =
        DEFAULT_TYPE_EXCLUSION_LIST[state.draftComponent.type] || [];
      return !exclusionList.includes(value);
    });
  }, [state.draftComponent.properties.validation_type, state.draftComponent.type]);

  const {
    validation_type,
    default_value_type,
    default_value_binding,
    default_value_template,
    default_value
  } = state.draftComponent.properties;

  const bindingPath: string =
    typeof default_value_binding?.binding === "string"
      ? default_value_binding.binding
      : "";
  const bindingShapes = BINDING_SHAPES_BY_TYPE[toAttributeType(validation_type)];

  return (
    <Field>
      <label>Default Value</label>
      <Select
        data-test="defaultValueTypeSelect"
        defaultValue={default_value_type}
        dropdownMatchSelectWidth={false}
        onChange={value =>
          dispatch({
            type: InputConfigActionTypes.SET_DEFAULT_VALUE_TYPE,
            payload: {
              value: value as DefaultValueTypes
            }
          })
        }
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      >
        {defaultValueOptions.map(option => {
          const displayName = DEFAULT_VALUE_DISPLAY_NAMES[option as DefaultValueTypes];
          return (
            <AntSelect.Option
              data-test="defaultValueTypeOption"
              value={option}
              key={option}
              title={displayName}
            >
              {displayName}
            </AntSelect.Option>
          );
        })}
      </Select>
      {default_value_type === "text_value" && (
        <>
          <Spacer size="sm" />
          <DebouncedInput
            data-test="textValueInput"
            value={default_value}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.default_value",
                  value
                }
              })
            }
          />
        </>
      )}
      {default_value_type === "template" && (
        <>
          <Spacer size="sm" />
          <DebouncedTemplateEditor
            data-test="templateInput"
            value={default_value_template || ""}
            placeholder={props.placeholder || "${variable}"}
            minHeight={Height.Medium}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: `properties.default_value_template`,
                  value
                }
              })
            }
          />
          <ValidationError field="DEFAULT_VALUE_TEMPLATE" />
        </>
      )}
      {default_value_type === "boolean_value" && (
        <>
          <Select
            data-test="defaultValueSelect"
            placeholder="Select a value"
            defaultValue={booleanValueToString(default_value)}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.default_value",
                  value: serializeBoolean(value as string)
                }
              })
            }
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          >
            <AntSelect.Option
              data-test="defaultValueOption"
              value={BooleanSelectOptions.TRUE}
            >
              TRUE
            </AntSelect.Option>
            <AntSelect.Option
              data-test="defaultValueOption"
              value={BooleanSelectOptions.FALSE}
            >
              FALSE
            </AntSelect.Option>
          </Select>
        </>
      )}
      {default_value_type === "binding" && (
        <>
          <Spacer size="sm" />
          <BindingCascader
            defaultValue={bindingPath}
            selectable={bindingShapes}
            onChange={(path: string) => {
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.default_value_binding",
                  value: {
                    binding: path
                  }
                }
              });
            }}
            value={bindingPath}
          />
          <ValidationError field="DEFAULT_VALUE_BINDING" />
        </>
      )}
      {default_value_type === "csv_value" && (
        <>
          <Spacer size="sm" />
          <DebouncedTextArea
            data-test="csvValue"
            value={default_value}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.default_value",
                  value
                }
              })
            }
            placeholder="tag1,tag2"
            autosize={{
              minRows: 2
            }}
          />
        </>
      )}
      {default_value_type === "json_value" && (
        <>
          <Spacer size="sm" />
          <DebouncedEditor
            mode="application/json"
            value={default_value}
            hideLineNumbers
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.default_value",
                  value
                }
              })
            }
          />
        </>
      )}
      {default_value_type === "datetime_value" && (
        <>
          <Spacer size="sm" />
          <AttributeInput
            sourceName={state.draftComponent.name}
            onChange={value => {
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.default_value",
                  value
                }
              });
            }}
            value={default_value}
            sourceType={toAttributeType(validation_type)}
          />
        </>
      )}
      {default_value_type === "file_value" && (
        <>
          <Spacer size="sm" />
          <AttributeInput
            sourceName={state.draftComponent.name}
            onChange={value => {
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.default_value",
                  value
                }
              });
            }}
            value={default_value}
            sourceNullable={false}
            sourceType={
              AttributeTypes.FILE /* save as file for file and binary types, in case user switches between them */
            }
          />
        </>
      )}
    </Field>
  );
}
