import * as querystring from "querystring";

import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button, Icon, Popover } from "antd";
import produce from "immer";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { GlobalStyleVariables } from "../../../../../cssConstants";
import { FunctionScope } from "../../../../../types";
import {
  CloneFunctionProps,
  FunctionEditorModal
} from "../../../../common/FunctionEditor/FunctionEditorModal/Modal";
import FunctionPicker from "../../../../common/FunctionPicker";
import SingleLineEditor from "../../../../common/SingleLineEditor";
import ThemeContainer, { Theme } from "../../../../common/ThemeContainer";
import { EditorDrawer } from "../../../styledComponents";
import {
  ActionType,
  ExecuteFunctionStepOptions,
  Parameter,
  PendingExecuteFunctionStep
} from "../reducer";

import { ErrorField, ErrorInput } from "./Errors";
import { FUNCTION_WITH_PARAMETERS, FunctionWithParametersQuery } from "./queries";
import { Control, FormItem, Item, Label } from "./StyledComponents";

import { StepProps } from ".";

const Root = styled.div`
  .ant-select:not(:first-child) {
    margin-top: ${props => props.theme.spacersm};
  }
`;

const ParametersLabel = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapTextButton = styled(Button)`
  height: fit-content;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  padding-top: ${props => props.theme.spacerxs};
  padding-bottom: ${props => props.theme.spacerxs};

  & + & {
    margin-top: ${props => props.theme.spacersm};
  }
`;

const AddIcon = styled(Icon)`
  font-size: ${props => props.theme.spacermd};
  margin: ${props => props.theme.spacersm};
`;

const DeleteIcon = styled(Icon)`
  font-size: ${props => props.theme.spacermd};
  margin: ${props => props.theme.spacersm};
`;

const StyledParameter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacersm};

  & > div {
    display: flex;
    align-items: center;
  }

  div.ant-form-item {
    margin-bottom: 0px;
  }

  div.ant-form-item-control {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 21px;
  }
`;

const StyledSingleLineEditor = styled(SingleLineEditor)`
  width: 200px;
`;

const ParameterLabel = styled.div`
  min-width: 150px;
`;

const PopoverContents = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.theme.configPanelWidth};
  max-height: 300px;
  overflow: auto;
`;

type Props = StepProps<PendingExecuteFunctionStep>;

export const selectExports = (_step: PendingExecuteFunctionStep): string[] => {
  return ["data"];
};

const ExecuteFunctionStep = ({ step, state, dispatch, updateOptions }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const { data } = useQuery<FunctionWithParametersQuery>(FUNCTION_WITH_PARAMETERS, {
    variables: { functionId: step.function?.id },
    skip: !step.function?.id
  });

  const excludedParameters = React.useMemo(() => {
    const existing = step.options?.parameters?.map?.((p: Parameter) => p.name) || [];
    return (
      data?.node?.functionParameters?.edges
        ?.map(e => e.node)
        ?.filter(p => !existing.includes(p.name)) || []
    );
  }, [step.options, data]);

  const updateFunction = React.useCallback(
    (functionId: string | null) => {
      dispatch({
        type: ActionType.UPDATE_STEP_FUNCTION,
        payload: { step: step.order, functionId: functionId }
      });
    },
    [dispatch, step.order]
  );

  const params = querystring.parse(
    location.hash.substring(location.hash.indexOf("#") + 1)
  );

  const editFunctionId = !Array.isArray(params.functionId)
    ? params.functionId
    : undefined;

  const editorKey = editFunctionId || (params.key as string) || uuid();

  const onCloneOrPick = ({ functionId }: CloneFunctionProps) =>
    navigate(`#action=edit&functionId=${functionId}`);

  const onClose = () => {
    navigate(location.pathname);
  };

  return (
    <>
      <Root>
        <Item>
          <Label>Function</Label>
          <Control>
            <FunctionPicker
              functionId={step.function?.id || null}
              functionScope={FunctionScope.Submittable}
              onChange={updateFunction}
              onEditFunctionClick={props =>
                onCloneOrPick({ functionId: props.functionId })
              }
            />
            <ErrorField path={`step${step.order}.function`} state={state} />
          </Control>
        </Item>
        <Item>
          <ParametersLabel>
            Parameters{" "}
            <Popover
              title="Add parameters"
              placement="rightTop"
              visible={isPopoverVisible}
              onVisibleChange={v => setPopoverVisible(v)}
              trigger="click"
              content={
                <PopoverContents>
                  {!excludedParameters.length && (
                    <span>All available parameters have been added.</span>
                  )}
                  {!!excludedParameters.length && (
                    <>
                      <span>Select a parameter to add to your step.</span>
                      {excludedParameters.length > 0 &&
                        excludedParameters
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(p => (
                            <WrapTextButton
                              key={p.id}
                              onClick={() => {
                                updateOptions(
                                  produce(
                                    step.options,
                                    (draft: ExecuteFunctionStepOptions) => {
                                      draft.parameters = draft.parameters || [];
                                      draft.parameters.push({
                                        name: p.name,
                                        value: ""
                                      });
                                    }
                                  )
                                );
                                setPopoverVisible(false);
                              }}
                            >
                              {p.name}
                            </WrapTextButton>
                          ))}
                    </>
                  )}
                </PopoverContents>
              }
            >
              <AddIcon type="plus" onClick={() => setPopoverVisible(true)} />
            </Popover>
          </ParametersLabel>
          <Control>
            {!step.options.parameters?.length && (
              <span>There are currently no parameters configured.</span>
            )}
            {step.options.parameters?.map?.((p: Parameter, index: number) => (
              <StyledParameter key={`param-${index}`}>
                <FormItem>
                  <ParameterLabel>{p.name}</ParameterLabel>
                </FormItem>
                <ErrorInput
                  state={state}
                  path={`step${step.order}.parameters[${index}].value`}
                >
                  <StyledSingleLineEditor
                    value={p.value}
                    onChange={value =>
                      updateOptions(
                        produce(step.options, (draft: ExecuteFunctionStepOptions) => {
                          draft.parameters[index].value = value;
                        })
                      )
                    }
                  />
                </ErrorInput>
                <FormItem>
                  <DeleteIcon
                    type="minus"
                    onClick={() =>
                      updateOptions(
                        produce(step.options, (draft: ExecuteFunctionStepOptions) => {
                          draft.parameters.splice(index, 1);
                        })
                      )
                    }
                  />
                </FormItem>
              </StyledParameter>
            ))}
          </Control>
        </Item>
      </Root>
      {params.action && (
        <ThemeContainer theme={Theme.Default}>
          <ThemeContainer theme={Theme.Dark}>
            <EditorDrawer offsetRight={GlobalStyleVariables.drawerWidth}>
              <FunctionEditorModal
                key={editorKey}
                onClone={onCloneOrPick}
                onClose={onClose}
                cacheEvictor={(_, id) => {
                  if (id === step.function?.id) {
                    updateFunction(null);
                  }
                }}
                dataSourceId={data?.node.dataSource?.id}
                functionId={editFunctionId}
              />
            </EditorDrawer>
          </ThemeContainer>
        </ThemeContainer>
      )}
    </>
  );
};

ExecuteFunctionStep.selectExports = selectExports;

export default ExecuteFunctionStep;
