import React from "react";

import { StyledButtonSecondary, StyledHeaderButton } from "../styledComponents";

import { ActionModal } from "./styledComponents";

export enum Action {
  Discard,
  Cancel,
  Save
}

interface Props {
  visible?: boolean;
  className?: string;
  saveLoading?: boolean;
  onClick: (action: Action) => void;
}

export default function UnsavedChangesModal({
  visible,
  saveLoading,
  className,
  onClick
}: Props) {
  return (
    <ActionModal
      title="You have unsaved changes"
      visible={visible}
      closable={false}
      className={className}
      footer={[
        <StyledButtonSecondary
          type="secondary"
          size="lg"
          key="cancel"
          onClick={() => onClick(Action.Cancel)}
        >
          Cancel
        </StyledButtonSecondary>,
        <StyledButtonSecondary
          type="secondary"
          size="lg"
          key="discard"
          onClick={() => onClick(Action.Discard)}
        >
          Discard changes
        </StyledButtonSecondary>,
        <StyledHeaderButton
          type="primary"
          key="save"
          loading={saveLoading}
          onClick={() => onClick(Action.Save)}
        >
          Save
        </StyledHeaderButton>
      ]}
    >
      If you don’t save, these changes will be discarded.
    </ActionModal>
  );
}
