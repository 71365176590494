import { Binding, BindingShape } from "../../types";

import { assertNever } from "./assertNever";

export interface BindingNode {
  [k: string]: any;
  __meta: Binding;
}

export function createNodeFromArray(bindings: Binding[]): BindingNode {
  const obj = bindings.reduce<{ [k: string]: any }>((acc, b) => {
    acc[b.name] = createNode(b);
    return acc;
  }, {});
  return {
    ...obj,
    __meta: {
      shape: BindingShape.OBJECT,
      name: "",
      attributes: bindings.concat()
    }
  };
}

export function createNode(binding: Binding): BindingNode {
  switch (binding.shape) {
    case BindingShape.SCALAR:
    case BindingShape.SCALAR_ARRAY:
    case BindingShape.UNKNOWN:
      return { __meta: binding };
    case BindingShape.OBJECT:
      const obj = binding.attributes.reduce<{ [k: string]: any }>((acc, b) => {
        acc[b.name] = createNode(b);
        return acc;
      }, {});
      return { ...obj, __meta: binding };
    case BindingShape.OBJECT_ARRAY:
      return new Proxy(
        { __meta: binding },
        {
          get(target, prop): any {
            if (prop === "__meta") {
              return target.__meta;
            } else if (typeof prop === "string" && String(parseInt(prop)) === prop) {
              return createNode({
                shape: BindingShape.OBJECT,
                name: target.__meta.name,
                attributes: target.__meta.attributes.concat()
              });
            }
            return undefined;
          }
        }
      );
    default:
      assertNever(binding);
  }
  throw new Error("unreachable");
}

export function createPath(parts: (string | number)[]) {
  let out = "";

  for (let i = 0; i < parts.length; ++i) {
    const part = parts[i];
    if (typeof part === "string" && part.match(/^[a-zA-Z$_][a-zA-Z\d$_]*$/)) {
      if (i > 0) {
        out += ".";
      }
      out += part;
    } else {
      if (i === 0) {
        throw new Error("illegal character");
      } else if (typeof part === "string") {
        const json = JSON.stringify(part);
        if (json.match(/\\[^tn"\\]/)) {
          throw new Error("illegal escape sequence");
        }
        out += `[${json}]`;
      } else {
        out += `[${part}]`;
      }
    }
  }
  return out;
}

const IDENTIFIER_START = /[A-Za-z$_]/;
const IDENTIFIER_CONTINUE = /[A-Za-z0-9$_]/;

// We only handle a very limited set of escape sequences.
const escape = (char: string) => {
  switch (char) {
    case "n":
      return "\n";
    case "t":
      return "\t";
    default:
      return char;
  }
};

type LexState = "start" | "string" | "identifier" | "decimalInteger";
type ParseState =
  | "start"
  | "value"
  | "afterIdentifier"
  | "propertyExpression"
  | "afterPropertyExpression";

type TokenValue = string | number;

interface Token {
  value: TokenValue;
  type: "punctuator" | "identifier" | "string" | "numeric" | "eof";
}

export function parsePath(path: string) {
  if (path.match(/^\s*$/)) return [];

  let token: Token | undefined;
  let buffer = "";
  let pos = 0;

  const line = 1;

  const peek = () => path[pos];
  const read = () => path[pos++];

  const invalidEOF = () => new Error("unexpected end of input");
  const invalidInput = () =>
    new Error(`unexpected input '${path[pos]}' (${line}:${pos + 1})`);
  const invalidToken = () =>
    new Error(
      `unexpected token '${token!.value}' (${line}:${
        pos + String(token!.value).length - 1
      })`
    );

  let lexState: LexState = "start";

  const lex = (): Token | undefined => {
    const c = peek();

    switch (lexState) {
      case "start": {
        switch (c) {
          case ".":
          case "[":
          case "]":
            return { value: read(), type: "punctuator" };
          case undefined:
            return { value: read(), type: "eof" };
          case " ":
          case "\t":
            read();
            return;
          case '"':
            read();
            buffer = "";
            lexState = "string";
            return;
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            buffer = read();
            lexState = "decimalInteger";
            return;
          default:
            if (c.match(IDENTIFIER_START)) {
              buffer = read();
              lexState = "identifier";
              return;
            }
            throw invalidInput();
        }
      }
      case "string":
        switch (c) {
          case "\\":
            read();
            buffer += escape(read());
            return;
          case '"':
            read();
            lexState = "start";
            return { type: "string", value: buffer };
          case undefined:
            throw invalidEOF();
          default:
            buffer += read();
            return;
        }
      case "identifier":
        if (c && c.match(IDENTIFIER_CONTINUE)) {
          buffer += read();
          return;
        }
        lexState = "start";
        return { value: buffer, type: "identifier" };
      case "decimalInteger":
        if (c && c.match(/[0-9]/)) {
          buffer += read();
          return;
        }
        lexState = "start";
        return { value: Number(buffer), type: "numeric" };
      default:
        assertNever(lexState);
    }
  };

  const parts: (string | number)[] = [];

  let parseState: ParseState = "start";
  for (;;) {
    token = lex();

    if (!token) continue;

    // Covers EOF handling for all parser states.
    if (token.type === "eof") {
      if (parseState !== "afterIdentifier") {
        throw invalidEOF();
      } else {
        break;
      }
    }

    switch (parseState) {
      case "start":
        if (token.type !== "identifier") {
          throw invalidToken();
        }
        parts.push(token.value);
        parseState = "afterIdentifier";
        break;
      case "afterIdentifier":
        if (token.type !== "punctuator") {
          throw invalidToken();
        }
        switch (token.value) {
          case ".":
            parseState = "start";
            break;
          case "[":
            parseState = "propertyExpression";
            break;
          default:
            throw invalidToken();
        }
        break;
      case "propertyExpression":
        if (token.type !== "string" && token.type !== "numeric") {
          throw invalidToken();
        }
        parseState = "afterPropertyExpression";
        parts.push(token.value);
        break;
      case "afterPropertyExpression":
        if (token.type !== "punctuator" || token.value !== "]") {
          throw invalidToken();
        }
        parseState = "afterIdentifier";
        break;
      default:
        assertNever(parseState);
    }
  }
  return parts;
}
