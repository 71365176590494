import React, { FormEvent, useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

import { UserNode } from "../../../types";
import Message from "../../common/Message";

import { DELETE_USER_MUTATION, DeleteResult, DeleteVars } from "./queries";
import * as styled from "./styledComponents";

interface Props extends FormComponentProps {
  user: UserNode;
  onDelete: (user: UserNode) => void;
}

const htmlFormId = "delete-user-form";

const ButtonWithModalForm = ({ form, user, onDelete }: Props) => {
  const [open, setModalOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [remoteError, setRemoteError] = useState("");
  const name = user.firstName + " " + user.lastName;

  const reset = (isOpen: boolean) => {
    setEnabled(false);
    setRemoteError("");
    setModalOpen(isOpen);
  };

  const handleConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemoteError("");
    setEnabled(name === e.target.value);
  };

  const handleCompleted = (data: DeleteResult) => {
    const { ok, message } = data.userDelete;
    if (ok) {
      setModalOpen(false);
      onDelete(user);
    } else {
      setRemoteError(message);
    }
  };

  const [destroy, { loading }] = useMutation<DeleteResult, DeleteVars>(
    DELETE_USER_MUTATION,
    { refetchQueries: ["AllUsersQuery"], onCompleted: handleCompleted }
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setRemoteError("");

    const variables = {
      userId: user.id,
      confirm: form.getFieldValue("confirm")
    };
    return destroy({ variables });
  };

  if (remoteError) {
    Message.error(remoteError);
  }

  return (
    <span>
      <Button type="danger" onClick={() => reset(true)}>
        Delete User
      </Button>

      {open && (
        <Form id={htmlFormId} onSubmit={handleSubmit}>
          <styled.Modal
            title="Delete User"
            visible={true}
            confirmLoading={loading}
            okText="Delete"
            onCancel={() => reset(false)}
            footer={[
              <Button key="cancel" onClick={() => reset(false)}>
                Cancel
              </Button>,
              <Button
                type="danger"
                key="delete"
                htmlType="submit"
                form={htmlFormId}
                disabled={!enabled}
                loading={loading}
                onClick={handleSubmit}
              >
                Delete
              </Button>
            ]}
          >
            <p>
              This action <strong>cannot</strong> be undone. The user’s information and
              activity log will be deleted. Any data sources, functions, resources, and
              spaces added by the user will remain.
            </p>

            <p>
              Please type <strong>{name}</strong> to confirm.
            </p>

            <Form.Item>
              {form.getFieldDecorator("confirm", {
                preserve: false,
                rules: [{ required: true, message: "Confirmation is required" }]
              })(
                <Input
                  form={htmlFormId}
                  placeholder=""
                  onChange={e => handleConfirmChange(e)}
                />
              )}
            </Form.Item>
          </styled.Modal>
        </Form>
      )}
    </span>
  );
};

export const DeleteButton = Form.create<Props>({
  name: "user-delete"
})(ButtonWithModalForm);
