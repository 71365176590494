import Icon from "../../../../../assets/spaceicons/Action.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import ConfigPopover, {
  getOutputBinding,
  getSchema,
  errorSelector
} from "../common/FunctionBackedPopover";
import reducer, {
  ensureSubmittableComponent,
  makeInitialState,
  SubmittableComponent,
  SubmittableComponentConfigState,
  SubmittableComponentProps
} from "../common/FunctionBackedPopover/reducer/reducer";

import SpaceFunctionButton from "./SpaceFunctionButton";

const pkg: SpaceComponentPackage<
  SubmittableComponentProps,
  SubmittableComponent,
  SubmittableComponentConfigState
> = {
  type: "FUNCTION_BUTTON",
  displayName: "Button",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "HEADER",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FLEX_BOX"
  ]),
  Component: SpaceFunctionButton,
  ConfigurationComponent: ConfigPopover,
  componentConfigReducer: reducer,
  ensureComponent: ensureSubmittableComponent,
  getInitialDraftState: makeInitialState,
  getOutputBinding,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: "auto",
    height: "auto",
    snapToGrid: false
  }),
  fallbackWidth: 80,
  allowSelfBinding: true,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
