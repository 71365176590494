import React, { Dispatch } from "react";

import Expandable from "../../../../common/Expandable";
import { RightDownChevron } from "../../../../common/ToggleChevron";
import { ReducerAction, ReducerActions } from "../../reducer/actions";
import { ConfigState } from "../../reducer/reducer";
import { ActionType, DispatcherType, Event } from "../../types";
import ActionList from "../ActionList";
import * as common from "../common/styledComponents";

import * as styled from "./styledComponents";

interface Props {
  dispatch: Dispatch<ReducerAction>;
  index: number;
  state: ConfigState;
}

export const Assignment = ({ dispatch, index, state: configState }: Props) => {
  const state = configState.states[index];

  const toggleExpand = React.useCallback(
    (stateKey: string) => {
      dispatch({
        type: ReducerActions.TOGGLE_STATE_AUTOMATIONS_EXPANDED,
        payload: stateKey
      });
    },
    [dispatch]
  );

  const expanded = state.assignmentExpanded;

  if (!state.subscriptions.beforeEnter.length) {
    return (
      <common.EmptyContainer>
        <common.EmptyTitle>No changes to assignment yet</common.EmptyTitle>
        <common.EmptySubtitle>Click Add assignment to get started</common.EmptySubtitle>
      </common.EmptyContainer>
    );
  }

  return (
    <styled.Container>
      <common.CollapsableHeader onClick={() => toggleExpand(state.key)}>
        <div>
          <RightDownChevron expanded={!!expanded} />
          <common.CollapsableTitle>Assignment</common.CollapsableTitle>
        </div>
      </common.CollapsableHeader>
      <Expandable skipFirstAnimation={true} expanded={!!expanded}>
        <styled.ActionListContainer>
          <ActionList
            state={configState}
            dispatch={dispatch}
            event={Event.BEFORE_ENTER}
            dispatcher={state}
            dispatcherType={DispatcherType.STATE}
            dispatcherIndex={index}
            actionTypes={[ActionType.CHANGE_ASSIGNEE]}
            hideAddAction
          />
        </styled.ActionListContainer>
      </Expandable>
    </styled.Container>
  );
};

export default Assignment;
