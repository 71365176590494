import { Binding, BindingShape } from "../../../../../types";
import { ComponentNode } from "../../RenderTreeContext";

import { SpaceParamsComponent } from "./types";

export function getSchema(node: ComponentNode): Binding[] {
  const component: SpaceParamsComponent = node.component;
  return component.properties.url_parameters.map(param => ({
    name: param.name,
    shape: BindingShape.SCALAR
  }));
}
