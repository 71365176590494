import { Binding, BindingShape } from "../../../../../types";

export function getSchema(): Binding[] {
  return [
    {
      name: "value",
      shape: BindingShape.SCALAR
    },
    {
      name: "error",
      shape: BindingShape.UNKNOWN
    }
  ];
}
