import React from "react";

import { captureException } from "@sentry/browser";
import styled from "styled-components";

interface Props {
  hideMessage?: boolean;
  className?: string;
}

interface State {
  hasError: boolean;
}

const Root = styled.div`
  &.unstyled {
    width: fit-content;
    text-align: center;
  }

  &.drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    .header {
      font-size: 24px;
    }
  }
`;

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  componentDidCatch(error: Error) {
    captureException(error);
    window.__ERRORS = Array.isArray(window.__ERRORS) ? window.__ERRORS : [];
    window.__ERRORS.push(error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.hideMessage) {
        return null;
      }
      return (
        <Root className={!!this.props.className ? this.props.className : "unstyled"}>
          <strong className="header">We’re sorry - something went wrong.</strong>
          <p>
            The Internal team has been notified. If the problem persists, you can get{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://internalapp.freshdesk.com/"
            >
              help here
            </a>
            .
          </p>
        </Root>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
