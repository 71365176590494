import React from "react";

import { assertNever } from "../../../util/assertNever";
import { DOMRectsEqual, ElementLayout } from "../util";

/*
  LayoutContainer

  LayoutContainer provides the current layout state of a space and functions for
  transforming that state.
  
  This state includes the following:
    - DOMRects for each Element's current position and dimensions.  
    - DOMRects for each DimensionsContext's current position and dimensions.
    - A mapping from each Element to its DimensionsContext (via the Element's component slug)
  
  This context is very volatile, especially when many components are inserted or removed in a
  space, as each of these operations will trigger one or more dispatchs. In order
  to minimize unneeded re-renders, layout information is provided through a number of
  sub-contexts.
  
  Provided contexts:
    - LayoutContext - Provides all layout data and re-renders on all state changes.
    - LayoutContextDispatcher - Provides the dispatcher to the layout context and never rerenders.
    - ComponentLayoutContext - Provides component specific layout information and re-renders when
      a component's layout related data changes.
    - BoundaryContext - Provides a DOMRect representing the total dimensions of all root Elements and
      re-renders whenever a elementDOMRect change results in a change to those dimensions.
    - CanvasScrollContext - Provides the current scrollTop of the canvas. This is available to
      descendants of CanvasViewportContext, but is also required further up the tree.
*/

export const LayoutContext = React.createContext({
  elementDOMRects: new Map() as Map<string, DOMRect>,
  dimensionsContextDOMRects: new Map() as Map<string, DOMRect>,
  componentDimensionsContexts: new Map() as Map<string, string>,
  rootDOMRect: new DOMRect()
});

const LayoutContextDispatcher = React.createContext(
  (_action: LayoutContainerAction) => {}
);

interface UpdateElementRect {
  type: "UPDATE_ELEMENT_DOM_RECT";
  payload: {
    slug: string;
    rect: DOMRect;
  };
}

interface UpdateDimensionsContextRect {
  type: "UPDATE_DIMENSIONS_CONTEXT_RECT";
  payload: {
    key: string;
    rect: DOMRect;
  };
}

interface UpdateComponentDimensionsContextMapping {
  type: "UPDATE_COMPONENT_DIMENSIONS_CONTEXT_MAPPING";
  payload: {
    componentSlug: string;
    dimensionsContextKey: string;
  };
}

interface UpdateCanvasScrollTop {
  type: "UPDATE_CANVAS_SCROLL_TOP";
  payload: { canvasScrollTop: number };
}

type LayoutContainerAction =
  | UpdateElementRect
  | UpdateDimensionsContextRect
  | UpdateComponentDimensionsContextMapping
  | UpdateCanvasScrollTop;

const layoutContainerInitialState = {
  elementDOMRects: new Map() as Map<string, DOMRect>,
  dimensionsContextDOMRects: new Map() as Map<string, DOMRect>,
  componentDimensionsContexts: new Map() as Map<string, string>,
  canvasScrollTop: 0
};

function layoutContainerReducer(
  state: typeof layoutContainerInitialState,
  action: LayoutContainerAction
) {
  switch (action.type) {
    case "UPDATE_ELEMENT_DOM_RECT": {
      const { slug, rect } = action.payload;
      const existingRect = state.elementDOMRects.get(slug);
      if (existingRect && DOMRectsEqual(rect, existingRect)) return state;
      const elementDOMRects = new Map(state.elementDOMRects);
      elementDOMRects.set(slug, rect);
      return {
        ...state,
        elementDOMRects
      };
    }

    case "UPDATE_DIMENSIONS_CONTEXT_RECT": {
      const { key, rect } = action.payload;
      const existingRect = state.dimensionsContextDOMRects.get(key);
      if (existingRect && DOMRectsEqual(rect, existingRect)) return state;
      const dimensionsContextDOMRects = new Map(state.dimensionsContextDOMRects);
      dimensionsContextDOMRects.set(key, rect);
      return {
        ...state,
        dimensionsContextDOMRects
      };
    }

    case "UPDATE_COMPONENT_DIMENSIONS_CONTEXT_MAPPING": {
      const { componentSlug, dimensionsContextKey } = action.payload;
      if (
        state.componentDimensionsContexts.get(componentSlug) === dimensionsContextKey
      ) {
        return state;
      }
      const componentDimensionsContexts = new Map(state.componentDimensionsContexts);
      componentDimensionsContexts.set(componentSlug, dimensionsContextKey);
      return {
        ...state,
        componentDimensionsContexts
      };
    }

    case "UPDATE_CANVAS_SCROLL_TOP": {
      const { canvasScrollTop } = action.payload;
      if (canvasScrollTop === state.canvasScrollTop) return state;
      return {
        ...state,
        canvasScrollTop
      };
    }

    default:
      return assertNever(action);
  }
}

export default function LayoutContainer({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(
    layoutContainerReducer,
    layoutContainerInitialState
  );
  const value = React.useMemo(() => {
    const key = state.componentDimensionsContexts.get("root");
    return {
      ...state,
      rootDOMRect: state.dimensionsContextDOMRects.get(key || "") || new DOMRect()
    };
  }, [state]);

  const scrollContextValue = React.useMemo(
    () => ({ canvasScrollTop: state.canvasScrollTop }),
    [state.canvasScrollTop]
  );

  return (
    <LayoutContext.Provider value={value}>
      <LayoutContextDispatcher.Provider value={dispatch}>
        <ScrollContext.Provider value={scrollContextValue}>
          <BoundaryContextContainer>{children}</BoundaryContextContainer>
        </ScrollContext.Provider>
      </LayoutContextDispatcher.Provider>
    </LayoutContext.Provider>
  );
}

export const useLayoutContext = () => React.useContext(LayoutContext);
export const useLayoutContextDispatcher = () =>
  React.useContext(LayoutContextDispatcher);

const ComponentLayoutContext = React.createContext({
  elementDOMRect: new DOMRect(),
  dimensionsContextDOMRect: new DOMRect(),
  dimensionsContextKey: "",
  layout: new ElementLayout()
});

const emptyDOMRect = new DOMRect();
export function ComponentLayoutContextContainer({
  slug,
  ensuredLayout = new ElementLayout(),
  children
}: {
  slug: string;
  ensuredLayout?: ElementLayout;
  children: React.ReactNode;
}) {
  const { elementDOMRects, dimensionsContextDOMRects, componentDimensionsContexts } =
    useLayoutContext();

  const elementDOMRect = elementDOMRects.get(slug) || emptyDOMRect;
  const dimensionsContextKey = componentDimensionsContexts.get(slug) || "";
  const dimensionsContextDOMRect =
    dimensionsContextDOMRects.get(dimensionsContextKey) || emptyDOMRect;

  const value = React.useMemo(
    () => ({
      elementDOMRect,
      dimensionsContextDOMRect,
      dimensionsContextKey,
      layout: ensuredLayout
    }),
    [elementDOMRect, dimensionsContextDOMRect, dimensionsContextKey, ensuredLayout]
  );

  return (
    <ComponentLayoutContext.Provider value={value}>
      {children}
    </ComponentLayoutContext.Provider>
  );
}

export const useComponentLayoutContext = () => React.useContext(ComponentLayoutContext);

const BoundaryContext = React.createContext({
  boundaryRect: new DOMRect(),
  rootDOMRect: new DOMRect()
});

export function BoundaryContextContainer({ children }: { children: React.ReactNode }) {
  const { componentDimensionsContexts, elementDOMRects, rootDOMRect } =
    useLayoutContext();
  const rootDimensionsContextKey = componentDimensionsContexts.get("root");
  const rootComponentSlugs = React.useMemo(
    () =>
      Array.from(componentDimensionsContexts)
        .filter(([_, contextKey]) => contextKey === rootDimensionsContextKey)
        .map(([slug]) => slug),
    [componentDimensionsContexts, rootDimensionsContextKey]
  );

  const maxes = React.useMemo(() => {
    let maxX = Number.NEGATIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    Array.from(elementDOMRects)
      .filter(([slug]) => rootComponentSlugs.includes(slug))
      .forEach(([_, rect]) => {
        maxX = Math.max(rect.right, maxX);
        maxY = Math.max(rect.bottom, maxY);
      });
    return { x: maxX, y: maxY };
  }, [elementDOMRects, rootComponentSlugs]);

  const boundaryRect = React.useMemo(
    () => new DOMRect(0, 0, maxes.x, maxes.y),
    [maxes.x, maxes.y]
  );

  const value = React.useMemo(
    () => ({ boundaryRect, rootDOMRect }),
    [boundaryRect, rootDOMRect]
  );

  return <BoundaryContext.Provider value={value}>{children}</BoundaryContext.Provider>;
}

export const useBoundaryContext = () => React.useContext(BoundaryContext);

const ScrollContext = React.createContext({ canvasScrollTop: 0 });

export const useScrollContext = () => React.useContext(ScrollContext);
