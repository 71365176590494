import * as codemirror from "codemirror";
import CodeMirror from "codemirror";
import jsonlint from "jsonlint";

import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/json-lint";

import { getJavaScriptParseError, isParseError } from "../../util/javascript";

type Linter<T> = (content: string, options: T, codeMirror: CodeMirror.Editor) => any[];

window.jsonlint = jsonlint;

export function get(mode: string): Linter<any> | true {
  switch (mode) {
    case "javascript":
      return javascript;
    case "application/json":
      return json;
    default:
      return true;
  }
}

function javascript<T>(content: string, _options: T, _codeMirror: CodeMirror.Editor) {
  const err = getJavaScriptParseError(content);
  if (!isParseError(err)) return [];

  return [
    {
      message: err.message,
      severity: "error",
      from: codemirror.Pos(err.line - 1, err.column),
      to: codemirror.Pos(err.line - 1, err.column)
    }
  ];
}

function json<T>(content: string, options: T, editor: CodeMirror.Editor) {
  return content ? (codemirror as any).helpers.lint.json(content, options, editor) : [];
}
