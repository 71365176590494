import { DataProxy } from "apollo-cache";
import gql from "graphql-tag";

import { Connection, EnvironmentSpaceVersionNode } from "../../../../../types";
import { updateCache } from "../../../../util/updateCache";

export const UNPUBLISH = gql`
  mutation UnpublishSpace($spaceId: ID!, $environments: [ID]!) {
    spaceUnpublish(spaceId: $spaceId, environmentIds: $environments) {
      environmentSpaceVersions {
        __typename
        id
        environment {
          __typename
          id
          isDefault
          name
        }
      }
    }
  }
`;

export const VERSIONS = gql`
  query EnvironmentVersionsForSpace($spaceId: ID!) {
    allEnvironmentSpaceVersions(space: $spaceId) {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          environment {
            __typename
            id
            isDefault
            name
          }
        }
      }
    }
  }
`;

export interface Vars {
  spaceId: string;
}

export interface Result {
  allEnvironmentSpaceVersions: Connection<EnvironmentSpaceVersionNode>;
}

export interface MutationResult {
  spaceUnpublish: {
    environmentSpaceVersions: EnvironmentSpaceVersionNode[];
  };
}

export const createCacheEvictor = (
  cache: DataProxy,
  spaceId: string,
  versions: EnvironmentSpaceVersionNode[]
) => {
  const options = {
    query: VERSIONS,
    variables: { spaceId: spaceId }
  };
  const newPayload = {
    allEnvironmentSpaceVersions: {
      __typename: "EnvironmentSpaceVersionNodeConnection",
      edges: versions.map(v => ({
        __typename: "EnvironmentSpaceVersionNodeEdge",
        node: {
          __typename: "EnvironmentSpaceVersionNode",
          ...v
        }
      }))
    }
  };
  updateCache(options, cache, _ => newPayload);
};
