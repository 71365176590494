import styled from "styled-components";

import CommonIconButton from "../../../../../IconButton";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacersm};

  .ant-form-item-control {
    line-height: unset;
  }
`;

export const IconButton = styled(CommonIconButton)`
  align-self: flex-end;
`;
