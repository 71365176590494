import gql from "graphql-tag";

import {
  ClientErrorResult,
  FunctionParameterInput,
  PermissionErrorResult
} from "../../../../types";

export interface FunctionEnvironmentsVariables {
  id: string;
}

export interface FunctionEnvironmentsData {
  function: {
    __typename: "FunctionNode";
    id: string;
    dataSource: {
      __typename: "DataSourceNode";
      id: string;
      environmentDataSources: {
        __typename: "EnvironmentDataSourceNodeConnection";
        edges: {
          __typename: "EnvironmentDataSourceNodeEdge";
          node: {
            __typename: "EnvironmentDataSourceNode";
            id: string;
            environment: {
              __typename: "EnvironmentNode";
              id: string;
              name: string;
              isDefault: boolean;
            };
          };
        }[];
      };
    };
  };
}

export const FUNCTION_ENVIRONMENTS_QUERY = gql`
  query FunctionEnvironments($id: ID!) {
    function(id: $id) {
      id
      dataSource {
        id
        environmentDataSources {
          edges {
            node {
              id
              environment {
                id
                name
                isDefault
              }
            }
          }
        }
      }
    }
  }
`;

export interface PreviewVariables {
  functionId: string;
  environmentId: string;
  parameterMapping: any;
  parameters: FunctionParameterInput[];
  values: any;
  reducer: PreviewReducer;
  authorizationFlowId?: string;
}

interface PreviewReducer {
  data: string;
  metadata: string;
  transformedData: string;
  transformedMetadata: string;
}

export interface HttpTransformResults {
  data?: any;
  metadata?: any;
  transformedData?: any;
  transformedMetadata?: any;
}

interface PreviewException {
  error: string;
  value: any;
}

export interface HttpTransformExceptions {
  data?: PreviewException;
  metadata?: PreviewException;
  transformedData?: PreviewException;
  transformedMetadata?: PreviewException;
}

interface PreviewFunctionResultSuccess<T, E> {
  __typename: "PreviewFunctionResultSuccess";
  results: T;
  exceptions: E;
}

export interface PreviewResponse<T = any, E = any> {
  previewFunction?:
    | PreviewFunctionResultSuccess<T, E>
    | PermissionErrorResult
    | ClientErrorResult;
}

export const PREVIEW_FUNCTION_MUTATION = gql`
  mutation PreviewFunction(
    $functionId: ID!
    $environmentId: ID!
    $parameterMapping: GenericScalar!
    $parameters: [FunctionParameterInput]!
    $reducer: GenericScalar!
    $values: GenericScalar!
    $authorizationFlowId: ID
  ) {
    previewFunction(
      functionId: $functionId
      environmentId: $environmentId
      parameterMapping: $parameterMapping
      parameters: $parameters
      reducer: $reducer
      values: $values
      authorizationFlowId: $authorizationFlowId
    ) {
      __typename
      ... on PermissionErrorResult {
        message
      }
      ... on ClientErrorResult {
        code
        message
        errorInfo {
          reason
          domain
          metadata
        }
      }
      ... on PreviewFunctionResultSuccess {
        results
        exceptions
      }
    }
  }
`;
