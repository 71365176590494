import React from "react";

import { Select, Checkbox, Tooltip } from "antd";

import {
  AttributeTypes,
  AttributeTypesDisplayNames
} from "../../../../../../constants";
import Label from "../../../../../common/Label";
import {
  ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT,
  BlankValueType
} from "../../../constants";
import {
  getSupportedBlankValueTypes,
  BlankValueTypesDisplayNames
} from "../../util/util";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { Field } from "../ConfigPanel";
import { InputConfigActionTypes } from "../inputReducer";
import useFormConfig from "../useFormConfig";
import useNestedStatus from "../useNestedStatus";
import { toAttributeType, toProperty } from "../util";

// we do not show the validation type dropdown for the components
// listed below, which have only one validation_type or updates
// validation type under the hood (ie. tag selector)
const COMPONENT_TYPES_HIDE_VALIDATION_TYPE = ["CHECKBOX", "JSON_INPUT", "TAG_SELECTOR"];

interface ValidationFieldProps {
  isTerse?: boolean;
}

export default function ValidationField(props: ValidationFieldProps) {
  const { state, dispatch } = useComponentConfigContext();
  const { isFormItem } = useNestedStatus();
  const { getFunctionParameterFromSlug } = useFormConfig();

  // for each attribute, check if current component is a supported component type
  const supportedTypes = Object.keys(ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT).reduce(
    (supportedTypes: AttributeTypes[], key: string) => {
      if (
        ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT[key as AttributeTypes].includes(
          state.draftComponent.type
        )
      ) {
        supportedTypes.push(key as AttributeTypes);
      }
      return supportedTypes;
    },
    []
  );

  const supportedBlankValueTypes = getSupportedBlankValueTypes(
    state.draftComponent.type,
    state.draftComponent.properties.validation_type
  );

  const attributeType = toAttributeType(
    state.draftComponent.properties.validation_type
  );

  const showValidationType =
    !COMPONENT_TYPES_HIDE_VALIDATION_TYPE.includes(state.draftComponent.type) &&
    !props.isTerse;

  return (
    <>
      {showValidationType && (
        <Field>
          <label>Format</label>
          <Select
            disabled={isFormItem}
            value={attributeType}
            onChange={(value: AttributeTypes) =>
              dispatch({
                type: InputConfigActionTypes.SET_VALIDATION_TYPE,
                payload: {
                  value
                }
              })
            }
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          >
            {supportedTypes.map(attributeType => {
              return (
                <Select.Option value={attributeType} key={attributeType}>
                  {AttributeTypesDisplayNames[attributeType]}
                </Select.Option>
              );
            })}
          </Select>
        </Field>
      )}
      <Field>
        <Checkbox
          data-test="allowBlankCheckbox"
          checked={state.draftComponent.properties.allow_blank}
          onChange={({ target: { checked } }) =>
            dispatch({
              type: InputConfigActionTypes.SET_ALLOW_BLANK,
              payload: {
                allow_blank: checked
              }
            })
          }
        >
          <Tooltip
            placement="right"
            title="Blank values include null, undefined, or empty strings."
          >
            Allow this field to be blank
          </Tooltip>
        </Checkbox>
      </Field>
      {state.draftComponent.properties.validation_type !==
        toProperty(AttributeTypes.BOOL) &&
        !!supportedBlankValueTypes.length && (
          <Field>
            <Label
              showInfoIcon
              title="This will determine what value to use when the field is blank."
            >
              Treat blank values as
            </Label>
            <Select
              disabled={!state.draftComponent.properties.allow_blank}
              value={state.draftComponent.properties.blank_value_type}
              onChange={(value: BlankValueType) =>
                dispatch({
                  type: "SET_DRAFT_COMPONENT",
                  payload: {
                    path: "properties.blank_value_type",
                    value
                  }
                })
              }
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            >
              {supportedBlankValueTypes.map(blankValueType => {
                const fp = getFunctionParameterFromSlug(state.draftComponent.slug);

                const isDisabled =
                  fp && fp.required && blankValueType === BlankValueType.UNDEFINED;
                return (
                  <Select.Option
                    value={blankValueType}
                    key={blankValueType}
                    disabled={isDisabled}
                    title={
                      isDisabled
                        ? "This field is associated with a required parameter, therefore this option is not available."
                        : undefined
                    }
                  >
                    {BlankValueTypesDisplayNames[blankValueType]}
                  </Select.Option>
                );
              })}
            </Select>
          </Field>
        )}
    </>
  );
}
