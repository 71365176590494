import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

interface Data {
  runAutomation: { ok: boolean };
}

interface Variables {
  id: string;
  environmentIds: string[];
}

const RUN_AUTOMATION_MUTATION = gql`
  mutation RunAutomation($id: ID!, $environmentIds: [ID!]!) {
    runAutomation(id: $id, environmentIds: $environmentIds) {
      ok
    }
  }
`;

export const useRunAutomation = (options?: MutationHookOptions<Data, Variables>) => {
  return useMutation<Data, Variables>(RUN_AUTOMATION_MUTATION, options);
};
