import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

const QUERY = gql`
  query OpenStateTaskStatistics($queueId: ID!) {
    openStateTaskStatistics(queueId: $queueId) {
      state {
        id
        name
        color
      }
      taskCount
    }
  }
`;

interface Variables {
  queueId: string;
}

export interface StateStatistic {
  state: {
    id: string;
    name: string;
    color: string;
  };
  taskCount: number;
}

interface Data {
  openStateTaskStatistics: StateStatistic[];
}

export const useOpenStateTaskStatistics = (
  options?: QueryHookOptions<Data, Variables>
) => useQuery<Data, Variables>(QUERY, options);
