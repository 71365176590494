import React from "react";

import { SpaceConfigAction } from "../../../../../../types";
import ValidationError from "../../../../common/ComponentConfigContext/ValidationError/ValidationError";
import { Field } from "../../../../common/ConfigPanel/styledComponents";
import { ChartConfigState } from "../../../types";
import { GroupingFunctionSelect, AggregationFunctionSelect } from "../common/Inputs";

interface Props {
  state: ChartConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
}

export default function PieChartConfig({ state, dispatch }: Props) {
  return (
    <>
      <Field>
        <label>Grouped by</label>
        <GroupingFunctionSelect state={state} dispatch={dispatch} />
        <ValidationError field="X_AXIS" />
      </Field>
      <Field>
        <label>How should your data be calculated?</label>
        <AggregationFunctionSelect state={state} dispatch={dispatch} />
        <ValidationError field="Y_AXIS" />
      </Field>
    </>
  );
}
