import React, { Dispatch } from "react";

import { camelCase } from "lodash";

import { ReducerAction, ReducerActions } from "../../reducer/actions";
import { ConfigState } from "../../reducer/reducer";
import { ActionType, Dispatcher, DispatcherType, Event } from "../../types";

import ActionSelector from "./ActionSelector";
import * as styled from "./styledComponents";

interface Props {
  event: Event;
  dispatcher: Dispatcher;
  dispatcherIndex: number;
  dispatcherType: DispatcherType;
  dispatch: Dispatch<ReducerAction>;
  actionTypes: ActionType[];
  state: ConfigState;
  hideAddAction?: boolean;
}

const ActionList = ({
  event,
  dispatch,
  dispatcher,
  dispatcherIndex,
  dispatcherType,
  actionTypes,
  state,
  hideAddAction = false
}: Props) => {
  const onAddAction = () =>
    dispatch({
      type: ReducerActions.ADD_ACTION,
      payload: {
        dispatcherIndex,
        dispatcherType,
        event
      }
    });

  const actionIndexes = dispatcher.subscriptions[camelCase(event)];

  return (
    <styled.Root>
      {actionIndexes.map(i => (
        <ActionSelector
          key={state.actions[i].key}
          action={state.actions[i]}
          actionIndex={i}
          dispatch={dispatch}
          actionTypes={actionTypes}
          state={state}
        />
      ))}
      {!hideAddAction && (
        <styled.ButtonContainer>
          <styled.Button
            type="link"
            icon="plus"
            onClick={onAddAction}
            disabled={actionTypes.length === actionIndexes.length}
          >
            Add Action
          </styled.Button>
        </styled.ButtonContainer>
      )}
    </styled.Root>
  );
};

export default ActionList;
