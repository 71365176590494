import React from "react";

import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { ConfigSection } from "../ConfigPanel";

interface Props {
  title?: string;
  children?: React.ReactNode;
}

function BaseComponentConfigSection({ title, children }: Props) {
  const context = useComponentConfigContext();
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const pkg = findSpaceComponentPackage(context.state.draftComponent.type);
  const textSectionTitle = title || pkg?.displayName || "Text";

  return <ConfigSection title={textSectionTitle}>{children}</ConfigSection>;
}

export default BaseComponentConfigSection;
