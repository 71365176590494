import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Table, Drawer, Skeleton } from "antd";
import gql from "graphql-tag";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../constants";
import { SpacingUnit, GlobalStyleVariables } from "../../../cssConstants";
import { DetailParams } from "../common";

import SingleSignOnDrawer from "./SingleSignOnDrawer";
import TwoFactorDrawer from "./TwoFactorDrawer";

const SettingsContainer = styled.div`
  padding-top: ${SpacingUnit.lg};

  .column-with-padding {
    padding-left: 40px;
  }

  .ant-table-body {
    .ant-table-thead {
      th:first-child {
        padding-left: 40px;
      }
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-close {
    display: none;
  }
`;

const columns = [
  {
    title: "Feature",
    key: "feature",
    dataIndex: "feature",
    width: "25%",
    className: "column-with-padding"
  },
  {
    title: "Settings",
    key: "setting",
    dataIndex: "setting"
  }
];

export enum DrawerType {
  TWO_FACTOR = "two-factor",
  SINGLE_SIGN_ON = "sso"
}

export const SECURITY_SETTINGS_QUERY = gql`
  query SecuritySettingsQuery {
    authUser {
      id
      organization {
        id
        twoFactorRequired
        ssoRequired
      }
    }
  }
`;

export const SecuritySettings = () => {
  const navigate = useNavigate();
  const { id: drawerId } = useParams<DetailParams>();

  const { data } = useQuery(SECURITY_SETTINGS_QUERY);
  if (!data || !data.authUser) {
    return <Skeleton active={true} />;
  }

  const handleRowClick = (record: any) => {
    if (record.drawer) {
      navigate(
        generatePath(Routes.SETTINGS_SECURITY_DRAWER, {
          drawerId: record.drawer
        })
      );
    }
  };

  const twoFactorSetting = data.authUser.organization.twoFactorRequired
    ? "Require employees to use Two-Step Verification when logging in."
    : "Not enabled.";

  const isSSORequired = data.authUser.organization.ssoRequired
    ? "Require employees to use Single Sign-On when logging in."
    : "Not enabled.";

  const dataSource = [
    {
      key: "1",
      feature: "Two-Step Verification",
      setting: twoFactorSetting,
      drawer: DrawerType.TWO_FACTOR
    },
    {
      key: "2",
      feature: "Single Sign-On",
      setting: isSSORequired,
      drawer: DrawerType.SINGLE_SIGN_ON
    }
  ];

  const onClose = () => navigate(Routes.SETTINGS_SECURITY);

  return (
    <SettingsContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onRow={record => {
          return {
            onClick: () => handleRowClick(record)
          };
        }}
      />
      <StyledDrawer
        placement="right"
        onClose={onClose}
        width={GlobalStyleVariables.drawerWidth}
        visible={!!drawerId}
      >
        {drawerId === DrawerType.TWO_FACTOR && <TwoFactorDrawer onClose={onClose} />}
        {drawerId === DrawerType.SINGLE_SIGN_ON && (
          <SingleSignOnDrawer onClose={onClose} />
        )}
      </StyledDrawer>
    </SettingsContainer>
  );
};

export default SecuritySettings;
