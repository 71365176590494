import gql from "graphql-tag";

export type DeleteResult = {
  userDelete: {
    ok: boolean;
    message: string;
  };
};

export interface DeleteVars {
  userId: string;
  confirm: string;
}

export const DELETE_USER_MUTATION = gql`
  mutation UserDeleteMutation($userId: ID!, $confirm: String!) {
    userDelete(userId: $userId, confirm: $confirm) {
      ok
      message
    }
  }
`;
