import * as moment from "moment-timezone";

export const waitForFakeTimers = () =>
  new Promise(resolve => {
    jest.runAllTimers();
    jest.useRealTimers();
    setTimeout(() => {
      resolve(null);
      jest.useFakeTimers();
    });
  });

export const mockTimeZoneGuess = (zone: string) =>
  jest.spyOn(moment.default.tz, "guess").mockReturnValue(zone);

export const restoreTimeZoneGuess = () =>
  (moment.default.tz.guess as jest.Mock).mockRestore();

const spaceRegex = new RegExp(" ", "g");
export const formatTestHandle = (handle: string) =>
  handle.toLocaleLowerCase().replace(spaceRegex, "-");
