import React, { memo, useMemo, useRef, useState, MutableRefObject } from "react";

import { Icon } from "antd";
import classNames from "classnames";
import styled from "styled-components";

import { SpacingUnitValue } from "../../../../../cssConstants";
import { SpaceComponentObject } from "../../../../../types";
import { B3, H6 } from "../../../../common/StyledComponents";
import {
  useSelectionStateContext,
  useTransformationActionContext
} from "../../../layout/TransformationContext/TransformationContext";
import { ComponentNode } from "../../../SpaceRoot/RenderTreeContext";
import {
  getIsManaged,
  getIsManager
} from "../../../SpaceRoot/SpaceComponent/common/useNestedStatus/useNestedStatus";
import {
  useSpaceContext,
  useStableSpaceContext
} from "../../../SpaceRoot/SpaceContext";
import { findComponentNodeBySlug, fromComponents } from "../../../util/tree";
import { useSpaceConfigContext } from "../../SpaceConfigContext";

type DropPosition = "before" | "on" | "after";

const Root = styled.div`
  height: 100%;
  position: relative;
  overflow: auto;

  .positionedDropTarget {
    display: none;
  }

  &.isDragging .positionedDropTarget {
    display: block;
  }

  .toggleIcon {
    visibility: hidden;
  }

  &:hover .toggleIcon {
    visibility: visible;
  }
`;

const SectionTitle = styled(H6)`
  padding: ${props => props.theme.spacermd}
  padding-bottom: ${props => props.theme.spacerlg};
  margin-bottom: 0;
`;

const TreeNodeRoot = styled.div`
  min-height: ${props => props.theme.menuItemHeight};
`;

const NodeTitle = styled(B3)`
  position: relative;
  display: flex;
  font-size: 13px;
  color: ${props => props.theme.backgroundTertiary};
  margin-right: 1px;
  border: solid 1px transparent;
  height: ${props => props.theme.menuItemHeight};
  align-items: center;
  padding-left: ${props => props.theme.spacermd};
  flex-grow: 0;
  flex-shrink: 0;

  &.selected {
    background-color: ${props => props.theme.primaryAccent}33;
  }

  &.ancestorSelected {
    background-color: ${props => props.theme.primaryAccent}1A;
  }

  &.activeDropTarget {
    background: ${props => props.theme.primaryAccent};
  }

  &:hover:not(.selected) {
    border-color: ${props => props.theme.primaryAccent};
  }
`;

const NodeTitleText = styled.span`
  padding-right: ${props => props.theme.spacermd};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;

const ToggleIcon = styled(Icon)`
  margin-right: ${props => props.theme.spacersmd};
  flex-grow: 0;
  flex-shrink: 0;
  color: ${props => props.theme.surfaceTertiary};
  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`;

const IconShim = styled.div`
  width: 13px;
  margin-right: ${props => props.theme.spacersmd};
  flex-grow: 0;
  flex-shrink: 0;
`;

const Indent = styled.div`
  height: 1px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const PositionedDropTarget = styled.div`
  position: absolute;
  height: 7px;
  left: -1px;
  right: -1px;
  z-index: 1;

  &.activeDropTarget {
    background: ${props => props.theme.primaryAccent};
  }
`;

const TopDropTarget = styled(PositionedDropTarget)`
  top: -5px;
`;

const BottomDropTarget = styled(PositionedDropTarget)`
  bottom: -5px;
`;

export default function ComponentTreeFlyout() {
  const { components } = useSpaceContext();
  const { dispatch } = useSpaceConfigContext();
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [expandedSlugs, setExpandedKeys] = useState<Set<string>>(
    new Set(components.map(c => c.slug))
  );
  const [isDragging, setIsDragging] = useState(false);
  const dragNodeRef = useRef<HTMLElement | null>(null);
  const rootComponents = components
    .filter(c => c.container === null)
    .filter(c => !findSpaceComponentPackage(c.type)?.isHeadless);
  const hiddenIndexes = components
    .filter(c => c.container === null)
    .reduce<number[]>((memo, curr, idx) => {
      if (findSpaceComponentPackage(curr.type)?.isHeadless) {
        memo.push(idx);
      }
      return memo;
    }, []);
  const renderTree = useMemo(
    () =>
      fromComponents(
        components.filter(c => c.container === null),
        "",
        true
      ),
    [components]
  );
  const visibleNodes = selectVisibleNodeList(rootComponents, expandedSlugs);

  return (
    <Root ref={rootRef} className={classNames({ isDragging })}>
      <SectionTitle>Component tree</SectionTitle>
      <div>
        {rootComponents.reverse().map(c => (
          <TreeNode
            key={c.slug}
            component={c}
            expandedSlugs={expandedSlugs}
            renderTree={renderTree}
            dragNodeRef={dragNodeRef}
            visibleNodes={visibleNodes}
            onToggle={(key, forceState) => {
              const copy = new Set(expandedSlugs);
              if (forceState === "open") {
                if (expandedSlugs.has(key)) return;
                copy.add(key);
              } else if (forceState === "close") {
                if (!expandedSlugs.has(key)) return;
                copy.delete(key);
              } else {
                expandedSlugs.has(key) ? copy.delete(key) : copy.add(key);
              }
              setExpandedKeys(copy);
            }}
            onDragStart={() => {
              setIsDragging(true);
            }}
            onDragEnd={() => {
              setIsDragging(false);
              rootRef.current
                ?.querySelectorAll(".activeDropTarget")
                .forEach(n => n.classList.remove("activeDropTarget"));
            }}
            onDrop={(
              sourceNode: ComponentNode,
              destinationNode: ComponentNode | undefined,
              pos: DropPosition,
              isHeader = false
            ) => {
              if (destinationNode === undefined) {
                if (isHeader) {
                  dispatch({
                    type: "MOVE_COMPONENT",
                    payload: {
                      sourcePath: sourceNode?.path,
                      destinationPath: "",
                      index: rootComponents.findIndex(c => c.type === "HEADER") + 1
                    }
                  });
                }
                return;
              }

              let index = -1;
              const indexInParent = destinationNode.parent.children
                .filter(c => !findSpaceComponentPackage(c.component.type)?.isHeadless)
                .indexOf(destinationNode);
              const destinationIsContainer = !!findSpaceComponentPackage(
                destinationNode.component.type
              )?.isContainer;
              const parentIsContainer = getIsParentContainer(
                destinationNode.component,
                findSpaceComponentPackage
              );
              let isRootDrop = !destinationNode.parent;

              switch (pos) {
                case "before": {
                  if (parentIsContainer) {
                    destinationNode = destinationNode.parent as ComponentNode;
                    isRootDrop = !destinationNode.parent;
                    index = isRootDrop ? indexInParent + 1 : indexInParent;
                  } else {
                    throw new Error(
                      `Illegal drop. ${sourceNode.path} before ${destinationNode.path}`
                    );
                  }
                  break;
                }
                case "on": {
                  if (destinationIsContainer) {
                    // Drop as first child of container
                    index = 0;
                  } else if (
                    parentIsContainer &&
                    !getIsManager(destinationNode.component)
                  ) {
                    destinationNode = destinationNode.parent as ComponentNode;
                    isRootDrop = !destinationNode.parent;
                    index = isRootDrop ? indexInParent : indexInParent + 1;
                  } else {
                    throw new Error(
                      `Illegal drop. ${sourceNode.path} on ${destinationNode.path}`
                    );
                  }
                  break;
                }
                case "after": {
                  if (
                    destinationIsContainer &&
                    destinationNode.children.length &&
                    expandedSlugs.has(destinationNode.component.slug)
                  ) {
                    // Drop as first child of open container
                    index = 0;
                  } else if (parentIsContainer) {
                    destinationNode = destinationNode.parent as ComponentNode;
                    isRootDrop = !destinationNode.parent;
                    index = isRootDrop ? indexInParent : indexInParent + 1;
                  } else {
                    throw new Error(
                      `Illegal drop. ${sourceNode.path} after ${destinationNode.path}`
                    );
                  }
                  break;
                }
              }
              // If it has the same container and preceeds the destination index, adjust down
              // to account for its move shifting down indexes
              const existingIndexInParent =
                destinationNode.children.indexOf(sourceNode);
              if (existingIndexInParent >= 0 && existingIndexInParent < index) {
                index = index - 1;
              }

              // Account for headless components
              if (isRootDrop) {
                index = index + hiddenIndexes.filter(idx => idx <= index).length;
              }
              const destinationPath = destinationNode?.path;

              dispatch({
                type: "MOVE_COMPONENT",
                payload: {
                  sourcePath: sourceNode?.path,
                  destinationPath,
                  index
                }
              });
            }}
          />
        ))}
      </div>
    </Root>
  );
}

const TreeNode = memo(
  ({
    component,
    depth = 0,
    ancestorSelected = false,
    expandedSlugs,
    renderTree,
    dragNodeRef,
    visibleNodes,
    onToggle,
    onDragStart,
    onDragEnd,
    onDrop
  }: {
    component: SpaceComponentObject;
    depth?: number;
    ancestorSelected?: boolean;
    expandedSlugs: Set<string>;
    renderTree: ReturnType<typeof fromComponents>;
    dragNodeRef: MutableRefObject<HTMLElement | null>;
    visibleNodes: ReturnType<typeof selectVisibleNodeList>;
    onToggle: (key: string, forceState?: "open" | "close") => void;
    onDragStart: () => void;
    onDragEnd: () => void;
    onDrop: (
      sourceNode: ComponentNode,
      destinationNode: ComponentNode | undefined,
      pos: DropPosition,
      isHeader?: boolean
    ) => void;
  }) => {
    const { findSpaceComponentPackage, getSpaceComponentPackages } =
      useStableSpaceContext();
    const { getAncestorComponents } = useSpaceContext();
    const { select, hover, unhover } = useTransformationActionContext();
    const { selected } = useSelectionStateContext();
    const openTimeoutHandle = useRef<any>(null);
    const children: SpaceComponentObject[] = component.componentTreeNodes;
    const pkg = findSpaceComponentPackage(component.type);
    const nextDepth = depth + 1;
    const isOpen = expandedSlugs.has(component.slug);
    const componentName = component.name || pkg?.displayName;
    const ancestors = getAncestorComponents(component.slug);

    const node = findComponentNodeBySlug(renderTree, component.slug);

    const isHeader = component.type === "HEADER";

    const handleDragEnter = (event: React.DragEvent, pos: DropPosition) => {
      event.stopPropagation();
      if (isHeader && pos.match(/on|after/)) return;
      if (dragNodeRef.current === null) return;
      if (
        (isHeader && pos === "before") ||
        canDrop(
          node?.component.slug || "",
          pos,
          visibleNodes,
          expandedSlugs,
          findSpaceComponentPackage
        )
      ) {
        event.dataTransfer.dropEffect = "move";
        dragNodeRef.current.style.cursor = "move";
        event.preventDefault();
        return false;
      } else {
        event.dataTransfer.dropEffect = "none";
        dragNodeRef.current.style.cursor = "not-allowed";
        return true;
      }
    };

    const handleDragOver = (event: React.DragEvent, pos: DropPosition) => {
      event.stopPropagation();
      if (isHeader && pos.match(/on|after/)) return;
      if (dragNodeRef.current === null) return;
      if (
        (isHeader && pos === "before") ||
        canDrop(
          node?.component.slug || "",
          pos,
          visibleNodes,
          expandedSlugs,
          findSpaceComponentPackage
        )
      ) {
        event.dataTransfer.dropEffect = "move";
        dragNodeRef.current.style.cursor = "move";
        const currentTarget = event.currentTarget;
        currentTarget.classList.add("activeDropTarget");
        event.preventDefault();
        if (
          !isOpen &&
          node?.component.componentTreeNodes.length &&
          openTimeoutHandle.current === null
        ) {
          openTimeoutHandle.current = setTimeout(() => {
            if (currentTarget.classList.contains("activeDropTarget")) {
              onToggle(node.component.slug, "open");
            }
            openTimeoutHandle.current = null;
          }, 300);
        }
        return false;
      } else {
        event.dataTransfer.dropEffect = "none";
        dragNodeRef.current.style.cursor = "not-allowed";
        return true;
      }
    };
    const handleDragLeave = (event: React.DragEvent) => {
      event.stopPropagation();
      event.currentTarget.classList.remove("activeDropTarget");
    };
    const handleDrop = (event: React.DragEvent, pos: DropPosition) => {
      event.stopPropagation();
      onDragEnd();
      const sourceSlug = event.dataTransfer.getData("application/dragging-tree-node");
      const sourceNode = findComponentNodeBySlug(renderTree, sourceSlug);
      if ((!isHeader && node === undefined) || sourceNode === undefined) return;
      onDrop(sourceNode, node, pos, isHeader);
    };

    return (
      <TreeNodeRoot key={component.slug}>
        <NodeTitle
          draggable={!getIsManaged(ancestors, getSpaceComponentPackages()) && !isHeader}
          className={classNames({
            selected: component.slug === selected,
            ancestorSelected,
            canDrop
          })}
          onClick={() => {
            select(component.slug);
          }}
          onMouseEnter={event => {
            event.stopPropagation();
            hover(component.slug);
          }}
          onMouseLeave={() => {
            unhover(component.slug);
          }}
          onDragStart={event => {
            onDragStart();
            event.dataTransfer.setData(
              "application/dragging-tree-node",
              node?.component.slug || ""
            );
            const target = event.target as HTMLElement;
            dragNodeRef.current = target;
            event.dataTransfer.effectAllowed = "move";
            target.style.cursor = "move";
            return true;
          }}
          onDragEnter={event => handleDragEnter(event, "on")}
          onDragOver={event => handleDragOver(event, "on")}
          onDragLeave={handleDragLeave}
          onDragEnd={() => {
            onDragEnd();

            if (dragNodeRef.current === null) return;
            dragNodeRef.current.style.cursor = "unset";
            dragNodeRef.current = null;
          }}
          onDrop={event => handleDrop(event, "on")}
        >
          <Indent style={{ width: `${depth * SpacingUnitValue.md}px` }} />
          {!!component.componentTreeNodes.length ? (
            <ToggleIcon
              className="toggleIcon"
              type={isOpen ? "caret-down" : "caret-right"}
              onClick={evt => {
                evt.stopPropagation();
                onToggle(component.slug);
              }}
            />
          ) : (
            <IconShim />
          )}
          <NodeTitleText title={componentName}>{componentName}</NodeTitleText>
          <TopDropTarget
            className="positionedDropTarget"
            onDragEnter={event => handleDragEnter(event, "before")}
            onDragOver={event => handleDragOver(event, "before")}
            onDragLeave={handleDragLeave}
            onDrop={event => handleDrop(event, "before")}
          />
          <BottomDropTarget
            className="positionedDropTarget"
            onDragEnter={event => handleDragEnter(event, "after")}
            onDragOver={event => handleDragOver(event, "after")}
            onDragLeave={handleDragLeave}
            onDrop={event => handleDrop(event, "after")}
          />
        </NodeTitle>
        <div>
          {expandedSlugs.has(component.slug) &&
            children.map((child: SpaceComponentObject) => (
              <TreeNode
                key={child.slug}
                component={child}
                depth={nextDepth}
                ancestorSelected={selected === component.slug || ancestorSelected}
                expandedSlugs={expandedSlugs}
                renderTree={renderTree}
                dragNodeRef={dragNodeRef}
                visibleNodes={visibleNodes}
                onToggle={onToggle}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
              />
            ))}
        </div>
      </TreeNodeRoot>
    );
  }
);

function selectVisibleNodeList(
  components: SpaceComponentObject[],
  expanded: Set<string>,
  list: SpaceComponentObject[] = []
) {
  components.forEach(c => {
    list.push(c);
    if (expanded.has(c.slug)) {
      list = selectVisibleNodeList(c.componentTreeNodes, expanded, list);
    }
  });
  return list;
}

function getIsParentContainer(
  component: SpaceComponentObject,
  findSpaceComponentPackage: ReturnType<
    typeof useStableSpaceContext
  >["findSpaceComponentPackage"]
) {
  if (component.container === null) return true;
  return !!findSpaceComponentPackage(component.container.type)?.isContainer;
}

function canDrop(
  destinationSlug: string,
  pos: DropPosition,
  visibleNodeList: SpaceComponentObject[],
  expandedSlugs: Set<string>,
  findSpaceComponentPackage: ReturnType<
    typeof useStableSpaceContext
  >["findSpaceComponentPackage"]
) {
  const node = visibleNodeList.find(n => n.slug === destinationSlug);
  // Order is reversed for root components but not subcomponents
  const isRoot = node?.container === null;
  if (!node) {
    throw new Error("Drop node not found.");
  }
  const isContainer = findSpaceComponentPackage(node.type)?.isContainer;
  switch (pos) {
    case "before": {
      if (isRoot && isContainer && expandedSlugs.has(node.slug)) {
        return true;
      } else {
        return getIsParentContainer(node, findSpaceComponentPackage);
      }
    }
    case "on": {
      return (
        isContainer ||
        (getIsParentContainer(node, findSpaceComponentPackage) && !getIsManager(node))
      );
    }
    case "after": {
      if (!isRoot && isContainer && expandedSlugs.has(node.slug)) {
        return true;
      } else {
        return getIsParentContainer(node, findSpaceComponentPackage);
      }
    }
  }
}
