import { AttributeTypes } from "../../../../constants";
import {
  BaseFunctionName,
  BaseFunctionNodeBasic,
  Connection,
  Credentials,
  DataSourceNodeBase,
  FunctionNodeBasic,
  FunctionParameterNode,
  Metadata,
  RelayNode
} from "../../../../types";
import { BaseFunctionParameterMapping } from "../index";
import { SupportedIntegration } from "../support";

export interface DataSourceNodeWithFunctions<
  T extends BaseFunctionNodeBasic | FunctionNodeBasic
> extends DataSourceNodeBase {
  integration: SupportedIntegration;
  credentials: Credentials;
  functions: Connection<T>;
}

interface _BaseFunctionNode extends RelayNode {
  name: BaseFunctionName;
}

export interface DataSourceFunction<M = Metadata> extends RelayNode {
  id: string;
  title: string;
  functionParameters?: Connection<FunctionParameterNode>;
  baseFunctionParameterMapping?: BaseFunctionParameterMapping | string;
  baseFunction: _BaseFunctionNode | null;
  metadata?: M;
  reducer?: string;
  isUserGenerated: boolean;
  dataSource: { id: string; integration: SupportedIntegration };
}

export interface FunctionParameterSetting {
  type: AttributeTypes;
  required: boolean;
}

export type ParameterSettings = Record<string, FunctionParameterSetting>;

export enum GeneralActionTypes {
  SET_FIELD_VALUE = "SET_FIELD_VALUE",
  RESET_FIELDS = "RESET_FIELDS",
  SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE",
  SET_ERROR_MESSAGES = "SET_ERROR_MESSAGES",
  SET_INITIAL_STATE = "SET_INITIAL_STATE"
}

export interface SetFieldValueAction<T = string> {
  type: GeneralActionTypes.SET_FIELD_VALUE;
  payload: {
    fieldName: T;
    fieldValue: any;
  };
}

export interface ResetFieldsAction {
  type: GeneralActionTypes.RESET_FIELDS;
  payload: null;
}

export interface SetErrorMessageAction {
  type: GeneralActionTypes.SET_ERROR_MESSAGE;
  payload: {
    fieldName: string;
    errorMessage: string;
  };
}

export interface SetErrorMessagesAction {
  type: GeneralActionTypes.SET_ERROR_MESSAGES;
  payload: {
    errorMessages: any; // any object of key/value pairs
  };
}

export type CommonFunctionAction =
  | SetFieldValueAction
  | ResetFieldsAction
  | SetErrorMessageAction
  | SetErrorMessagesAction;
