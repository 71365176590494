export interface Duration {
  quantity?: string;
  unit?: UnitOfTime;
}

type UnitOfTime = "year" | "month" | "day" | "week" | "hour" | "minute" | "second";

const TIME_UNITS = ["hour", "minute", "second"];

// choose characters that are not allowed in ISO 8601
export const QUANTITY_UNDEFINED = "n";
export const UNIT_UNDEFINED = "X";

// returns valid ISO string with at most 1 unit
// returns invalid string if unit or quantity is undefiend
export const getDurationISO = (unit?: UnitOfTime, quantity?: string | number) => {
  const q = quantity !== undefined ? quantity : QUANTITY_UNDEFINED;
  const i = unit ? getISOUnit(unit) : UNIT_UNDEFINED;
  // put into format 1D or nX
  const d = `${q}${i}`;

  // if duration is of time < 1 day, requires T to designate Time
  // distinguishes PT8M (8minutes) from P8M (8 months)
  return TIME_UNITS.includes(unit || "") ? `PT${d}` : `P${d}`;
};

const getISOUnit = (s: UnitOfTime) => {
  switch (s) {
    case "day":
      return "D";
    case "hour":
      return "H";
    case "minute":
    case "month":
      return "M";
    case "second":
      return "S";
    case "week":
      return "W";
    case "year":
      return "Y";
    default:
      throw new Error("unexpected unit");
  }
};

const getHumanUnitOfTime = (duration: string): UnitOfTime => {
  const i = duration.charAt(duration.length - 1);
  switch (i) {
    case "Y":
      return "year";
    case "M":
      return duration.includes("T") ? "minute" : "month";
    case "W":
      return "week";
    case "D":
      return "day";
    case "H":
      return "hour";
    case "S":
      return "second";
    default:
      throw new Error("unexpected character");
  }
};

export const getObjectFromDurationString = (duration?: string): Duration => {
  if (!duration) return {};
  const noQuantity = duration.includes("n");
  const noUnit = duration.includes("X");
  return {
    quantity: noQuantity ? undefined : duration.replace(/[PTYMDWHSXn]/gi, ""),
    unit: noUnit ? undefined : getHumanUnitOfTime(duration)
  };
};
