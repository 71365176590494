import React from "react";

import gql from "graphql-tag";
import { useQuery } from "react-apollo";

import { Connection, FunctionNode, PageInfo } from "../../../../types";
import { assertNever } from "../../../util/assertNever";

interface FunctionNodeConnection extends Connection<FunctionNode> {
  pageInfo: PageInfo;
  totalCount: Number;
}

interface FunctionPoliciesForRoleData {
  allFunctions: FunctionNodeConnection;
}

interface PermissionInput {
  parameterIds: string[];
  attributeIds: string[];
  allowsAll: boolean;
}

// A FunctionPermissionInput lacking a permission is used to revoke all access to a function.
export interface FunctionPermissionInput {
  functionId: string;
  permission?: PermissionInput;
}

const PAGE_SIZE = 10;

export const FUNCTION_POLICIES_FOR_ROLE = gql`
  query FunctionPoliciesForRole(
    $roleId: ID
    $first: Int!
    $offset: Int = 0
    $searchText: String
    $withPolicies: Boolean!
  ) {
    allFunctions(
      scope: "wrapped"
      searchText: $searchText
      first: $first
      offset: $offset
    ) {
      totalCount
      edges {
        node {
          id
          name
          title
          dataSource {
            id
            name
          }
          functionParameters {
            edges {
              node {
                id
                name
                required
              }
            }
          }
          functionAttributes {
            edges {
              node {
                id
                name
              }
            }
          }
          policies(roleId: $roleId) @include(if: $withPolicies) {
            edges {
              node {
                id
                role {
                  id
                  name
                }
                allowsAll
                policyFunctionParameters {
                  edges {
                    node {
                      id
                      functionParameter {
                        id
                      }
                    }
                  }
                }
                policyFunctionAttributes {
                  edges {
                    node {
                      id
                      functionAttribute {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface RoleDerivedPermissionInput {
  function: string;
  parameters: string[];
  attributes: string[];
}

export enum PolicyMemberType {
  ATTRIBUTES = "attributes",
  PARAMETERS = "parameters"
}

function createInitialFunctionPolicyReducerState() {
  return {
    functions: [] as FunctionNode[],
    existingPolicies: new Map<string, RoleDerivedPermissionInput>(),
    policyChanges: new Map<string, RoleDerivedPermissionInput>(),
    searchText: undefined as undefined | string,
    page: 0
  };
}

export enum FunctionPolicyActionTypes {
  LOAD_EXISTING_POLICIES,
  SEARCH_FUNCTIONS,
  GOTO_PAGE,
  CHANGE_POLICY,
  GRANT_ALL_ACCESS,
  REVOKE_ALL_ACCESS,
  REVERT_CHANGES
}

interface BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes;
}

interface LoadExistingPolicy extends BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes.LOAD_EXISTING_POLICIES;
  payload: {
    functions: FunctionNode[];
  };
}

interface SearchFunctions extends BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes.SEARCH_FUNCTIONS;
  payload: { searchText: string };
}

interface GotoPage extends BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes.GOTO_PAGE;
  payload: { page: number };
}

interface ChangePolicy extends BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes.CHANGE_POLICY;
  payload: {
    functionId: string;
    memberType: PolicyMemberType;
    memberId: string;
    hasPermission: boolean;
  };
}

interface GrantAllAccess extends BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes.GRANT_ALL_ACCESS;
  payload: { functionId: string };
}

interface RevokeAllAccess extends BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes.REVOKE_ALL_ACCESS;
  payload: { functionId: string };
}

interface RevertChanges extends BaseFunctionPolicyAction {
  type: FunctionPolicyActionTypes.REVERT_CHANGES;
}

type FunctionPolicyAction =
  | LoadExistingPolicy
  | SearchFunctions
  | GotoPage
  | ChangePolicy
  | GrantAllAccess
  | RevokeAllAccess
  | RevertChanges;

function getRequiredParamaterIds(functions: FunctionNode[], functionId: string) {
  return (
    functions
      .find(f => f.id === functionId)
      ?.functionParameters?.edges.filter(({ node }) => node.required)
      .map(({ node }) => node.id) || []
  );
}

function functionPolicyReducer(
  state: ReturnType<typeof createInitialFunctionPolicyReducerState>,
  action: FunctionPolicyAction
) {
  switch (action.type) {
    case FunctionPolicyActionTypes.LOAD_EXISTING_POLICIES: {
      const {
        payload: { functions }
      } = action;
      return {
        ...state,
        existingPolicies: selectRoleDerivedPermissionInputs(functions),
        functions
      };
    }

    case FunctionPolicyActionTypes.SEARCH_FUNCTIONS: {
      const {
        payload: { searchText }
      } = action;
      return {
        ...state,
        searchText: searchText === "" ? undefined : searchText,
        page: 0
      };
    }

    case FunctionPolicyActionTypes.GOTO_PAGE: {
      const {
        payload: { page }
      } = action;
      return {
        ...state,
        page
      };
    }

    case FunctionPolicyActionTypes.CHANGE_POLICY: {
      const { payload } = action;
      const requiredParams = getRequiredParamaterIds(
        state.functions,
        payload.functionId
      );
      const nextPolicyChanges = new Map(state.policyChanges);

      // If there aren't any changes for this policy yet copy current state from existing
      if (!state.policyChanges.has(payload.functionId)) {
        const existingPolicy = state.existingPolicies.get(payload.functionId);
        if (existingPolicy === undefined)
          throw new Error("Expected to find an existing policy for function.");
        nextPolicyChanges.set(payload.functionId, existingPolicy);
      }

      const policy = nextPolicyChanges.get(payload.functionId);
      if (!policy) throw new Error("Expected policy to exist");

      if (
        payload.hasPermission &&
        !policy[payload.memberType].includes(payload.memberId)
      ) {
        policy[payload.memberType] = policy[payload.memberType].concat(
          payload.memberId
        );
        // If granting any permission ensure all required params have permission
        policy.parameters = policy.parameters.concat(
          requiredParams.filter(p => !policy.parameters.includes(p))
        );
      } else {
        // If a required param is removed revoke all access
        if (
          payload.memberType === PolicyMemberType.PARAMETERS &&
          requiredParams.includes(payload.memberId)
        ) {
          policy.attributes = [];
          policy.parameters = [];
        } else {
          policy[payload.memberType] = policy[payload.memberType].filter(
            id => id !== payload.memberId
          );
        }
      }

      nextPolicyChanges.set(payload.functionId, { ...policy });

      return { ...state, policyChanges: nextPolicyChanges };
    }

    case FunctionPolicyActionTypes.GRANT_ALL_ACCESS: {
      const { payload } = action;
      const nextPolicyChanges = new Map(state.policyChanges);
      const func = state.functions.find(f => f.id === payload.functionId);
      if (func === undefined) throw new Error("Expected to find function");
      const policy: RoleDerivedPermissionInput = {
        function: func.id,
        attributes: func.functionAttributes?.edges.map(({ node }) => node.id) || [],
        parameters: func.functionParameters?.edges.map(({ node }) => node.id) || []
      };
      nextPolicyChanges.set(func.id, policy);

      return { ...state, policyChanges: nextPolicyChanges };
    }

    case FunctionPolicyActionTypes.REVOKE_ALL_ACCESS: {
      const { payload } = action;
      const nextPolicyChanges = new Map(state.policyChanges);
      nextPolicyChanges.set(payload.functionId, {
        function: payload.functionId,
        parameters: [],
        attributes: []
      });
      return { ...state, policyChanges: nextPolicyChanges };
    }

    case FunctionPolicyActionTypes.REVERT_CHANGES: {
      return {
        ...state,
        policyChanges: new Map<string, RoleDerivedPermissionInput>()
      };
    }

    default:
      return assertNever(action);
  }
}

export default function useFunctionPolicies(roleId?: string) {
  const [state, dispatch] = React.useReducer(
    functionPolicyReducer,
    createInitialFunctionPolicyReducerState()
  );

  const { data, loading } = useQuery<FunctionPoliciesForRoleData>(
    FUNCTION_POLICIES_FOR_ROLE,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        roleId: roleId,
        searchText: state.searchText,
        first: PAGE_SIZE,
        offset: state.page * PAGE_SIZE,
        withPolicies: !!roleId
      }
    }
  );

  React.useEffect(() => {
    const functions = data?.allFunctions.edges.map(({ node }) => node) || [];
    // TODO: Need to merge these guys
    dispatch({
      type: FunctionPolicyActionTypes.LOAD_EXISTING_POLICIES,
      payload: { functions }
    });
  }, [data]);

  // Merge the existingPolicies and any policyChanges to produce
  // the current draftstate
  const policies = React.useMemo(() => {
    const policies = new Map(state.existingPolicies);
    state.policyChanges.forEach(change => {
      policies.set(change.function, change);
    });
    return policies;
  }, [state]);

  const functionPermissionChanges: FunctionPermissionInput[] = React.useMemo(() => {
    return [...state.policyChanges.values()].map(p => {
      const permission =
        p.attributes.length > 0 || p.parameters.length > 0
          ? {
              attributeIds: p.attributes,
              parameterIds: p.parameters,
              allowsAll: false
            }
          : undefined;
      return {
        functionId: p.function,
        permission
      };
    });
  }, [state.policyChanges]);

  const getPolicy = React.useCallback(
    function getPolicy(functionId: string) {
      const policy = policies.get(functionId);
      if (!policy) throw new Error("Expected to find policy for function.");
      return policy;
    },
    [policies]
  );

  return {
    loading,
    functions: state.functions,
    policies,
    // Used when creating a role.
    policyChanges: state.policyChanges,
    // Used when updating a role.
    functionPermissionChanges,
    filteredFunctionCount: data?.allFunctions.totalCount || 0,
    page: state.page,
    pageSize: PAGE_SIZE,
    getPolicy,
    dispatch
  };
}

// Derives RoleDerivedPermissionInputs from exising role policies
// filling missing policies with an empty policy granting no permissions
function selectRoleDerivedPermissionInputs(functions: FunctionNode[]) {
  const existingPolicies = new Map<string, RoleDerivedPermissionInput>();
  functions.forEach(f => {
    const existingPolicyNodes = f.policies?.edges.map(({ node }) => node) || [];
    const existingRolePolicy = existingPolicyNodes[0] || null;
    let parameters: string[] = [];
    let attributes: string[] = [];
    if (existingRolePolicy) {
      parameters = existingRolePolicy.allowsAll
        ? f.functionParameters?.edges.map(({ node }) => node.id) || []
        : existingRolePolicy.policyFunctionParameters.edges.map(
            ({ node }) => node.functionParameter.id
          );
      attributes = existingRolePolicy.allowsAll
        ? f.functionAttributes?.edges.map(({ node }) => node.id) || []
        : existingRolePolicy.policyFunctionAttributes.edges.map(
            ({ node }) => node.functionAttribute.id
          );
    }
    existingPolicies.set(f.id, { function: f.id, parameters, attributes });
  });
  return existingPolicies;
}
