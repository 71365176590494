import { Checkbox, Alert } from "antd";
import styled from "styled-components";

export const LargeBoldText = styled.p`
  margin-bottom: ${props => props.theme.spacermd};
  color: ${props => props.theme.labelColor};
  font-size: 16px;
  font-weight: 600;
`;

export const PTagWithTextColor = styled.p`
  color: ${props => props.theme.textColor};
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${props => props.theme.spacerlg};
  margin-bottom: ${props => props.theme.spacerlg};

  button {
    margin-bottom: ${props => props.theme.spacermd};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: ${props => props.theme.spacermd};

  &.ant-alert {
    background-color: unset;
    border: 0px;
    padding-left: 22px;

    .ant-alert-icon {
      left: 0;
    }

    span {
      color: ${props => props.theme.textColor};
    }
  }
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.errorColor};
`;
