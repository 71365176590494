import React from "react";

import { InputProps } from "antd/lib/input";

type ChangeOnBlurInputProps<T = {}> = InputProps & T;

function withChangeOnBlur<T>(
  Component: React.ComponentType<ChangeOnBlurInputProps<T>>
) {
  return function Wrapped(props: ChangeOnBlurInputProps<T>) {
    const { value, onChange } = props;
    const [val, setVal] = React.useState<any>(value);
    if (!onChange) {
      throw new Error("withChangeOnBlur requires an onChange prop");
    }

    const onBlur = () => {
      onChange({ target: { value: val } } as React.ChangeEvent<HTMLInputElement>);
    };

    return (
      <Component
        {...props}
        onChange={e => {
          setVal(e.target.value);
        }}
        value={val}
        onBlur={onBlur}
      />
    );
  };
}

export default withChangeOnBlur;
