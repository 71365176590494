import moment from "moment-timezone";

import { Edge, InputParameter } from "../../../../types";
import { GeneralSettingsProps } from "../../../common/GeneralSettings";
import { MaterialIconType } from "../../../common/Icons/MaterialIcons";
import { AutomationEnvironmentNode, AutomationNode } from "../queries/common";
import { IntervalPeriod } from "../types";

import {
  DEFAULT_TRIGGER_TIME,
  getLocalTime,
  getFrequencyOptions,
  TIME_DISPLAY_FORMAT,
  setTimeToMidnight,
  INTERVAL_PERIODS_WITH_START_END_TIMES,
  naiveToLocal
} from "./utils";

export type ConfigState = GeneralSettingsProps & {
  id: string | undefined;
  description: string;
  icon: MaterialIconType | "";
  color: string;
  intervalPeriod: IntervalPeriod;
  frequency: number;
  daysOfWeek: number[];
  daysOfMonth: number[];
  triggerTime: moment.Moment | undefined; // converted to UTC when saving
  startAt: moment.Moment | undefined;
  endAt: moment.Moment | undefined;
  functionId: string | undefined;
  inputParameters: InputParameter[];
  environmentIds: Set<string>;
};

export const INITIAL_STATE: ConfigState = {
  id: undefined,
  name: "Untitled automation",
  description: "",
  icon: "",
  color: "",
  intervalPeriod: IntervalPeriod.Days,
  frequency: 1,
  daysOfWeek: [],
  daysOfMonth: [],
  triggerTime: moment(DEFAULT_TRIGGER_TIME, TIME_DISPLAY_FORMAT),
  startAt: undefined,
  endAt: undefined,
  functionId: undefined,
  inputParameters: [],
  environmentIds: new Set()
};

export enum ReducerActions {
  LOAD_AUTOMATION = "LOAD_AUTOMATION",
  UPDATE_SETTINGS = "UPDATE_SETTINGS",
  UPDATE_INTERVAL_PERIOD = "UPDATE_INTERVAL_PERIOD"
}

export interface LoadAutomation {
  type: ReducerActions.LOAD_AUTOMATION;
  payload: AutomationNode;
}

export interface UpdateAutomation {
  type: ReducerActions.UPDATE_SETTINGS;
  payload: Partial<ConfigState>;
}

export interface UpdateIntervalPeriod {
  type: ReducerActions.UPDATE_INTERVAL_PERIOD;
  payload: {
    intervalPeriod: IntervalPeriod;
  };
}

export type ConfigAction = LoadAutomation | UpdateAutomation | UpdateIntervalPeriod;

export default function configReducer(
  state: ConfigState,
  action: ConfigAction
): ConfigState {
  switch (action.type) {
    case ReducerActions.LOAD_AUTOMATION:
      const automation = action.payload;
      const environments = automation.automationEnvironments.edges.map(
        (edge: Edge<AutomationEnvironmentNode>) => edge.node.environment.id
      );
      return {
        id: automation.id,
        name: automation.name || "",
        description: automation.description || "",
        icon: automation.icon || "",
        color: automation.color || "",
        intervalPeriod: automation.intervalPeriod || IntervalPeriod.Days,
        frequency: automation.frequency || 1,
        daysOfWeek: automation.daysOfWeek || [],
        daysOfMonth: automation.daysOfMonth || [],
        triggerTime: getLocalTime(automation.triggerTime),
        functionId: automation.function.id,
        inputParameters: automation.inputParameters || [],
        environmentIds: new Set(environments),
        startAt: naiveToLocal(automation.startAt),
        endAt: naiveToLocal(automation.endAt)
      };
    case ReducerActions.UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case ReducerActions.UPDATE_INTERVAL_PERIOD:
      const intervalPeriod = action.payload.intervalPeriod;
      const frequency = getFrequencyOptions(intervalPeriod)[0].value;
      let triggerTime = state.triggerTime;
      let startAt = state.startAt;
      let endAt = state.endAt;
      if (INTERVAL_PERIODS_WITH_START_END_TIMES.includes(intervalPeriod)) {
        triggerTime = undefined;
      } else {
        // add trigger_at default and update time to midnight for days/months/years
        triggerTime = triggerTime || moment(DEFAULT_TRIGGER_TIME, TIME_DISPLAY_FORMAT);
        startAt = state.startAt ? setTimeToMidnight(state.startAt) : undefined;
        endAt = state.endAt ? setTimeToMidnight(state.endAt) : undefined;
      }
      return {
        ...state,
        intervalPeriod,
        frequency,
        daysOfWeek: [],
        daysOfMonth: [],
        triggerTime,
        startAt,
        endAt
      };
    default:
      return state;
  }
}
