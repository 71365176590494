import React from "react";

import { SpaceComponentObject, ComponentConfigProps } from "../../../../../../types";
import { BaseComponentConfigState } from "../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError/ValidationError";
import { Field } from "../../common/ConfigPanel/styledComponents";
import NameFields from "../../common/NameFields";
import { DebouncedTemplateEditor, Height } from "../../common/TemplateEditor";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { SpaceCalculatedFieldComponent } from "../types";

import TypographyConfig from "./TypographyConfig";

export interface CalculatedFieldConfigState
  extends BaseComponentConfigState<SpaceCalculatedFieldComponent> {
  type: "CALCULATED_FIELD";
}

export const INITIAL_STATE = {
  type: "CALCULATED_FIELD" as const,
  draftComponent: {} as SpaceCalculatedFieldComponent
};

export function ensureSpaceCalculatedField(
  component: SpaceComponentObject
): SpaceCalculatedFieldComponent {
  if (
    typeof component.properties.template === "string" &&
    typeof component.properties.label === "string"
  ) {
    return component;
  }
  return {
    ...component,
    properties: {
      template: "`Text`", // render text so that component is visible in space even if user hasn't configured it
      label: "",
      ...component.properties
    }
  };
}

export default function SpaceCalculatedFieldConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={React.useMemo(
        () => (
          <SpaceCalculatedFieldConfigContent />
        ),
        []
      )}
      designConfig={React.useMemo(
        () => (
          <TypographyConfig />
        ),
        []
      )}
      slug={slug}
    />
  );
}

function SpaceCalculatedFieldConfigContent() {
  const { state, dispatch } = useComponentConfigContext();

  if (state === null) return null;

  return (
    <>
      <BaseComponentConfigSection>
        <NameFields />
        <Field>
          <label>Template</label>
          <DebouncedTemplateEditor
            dataTest="text-template"
            value={state.draftComponent.properties.template}
            placeholder="${row.data.first_name} ${row.data.last_name}" // eslint-disable-line
            minHeight={Height.Medium}
            onChange={template =>
              dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    properties: { template }
                  }
                }
              })
            }
          />
          <ValidationError field="TEMPLATE" />
        </Field>
      </BaseComponentConfigSection>
      <VisibilityRulesManagerSection />
    </>
  );
}
