import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import SettingsIcon from "../../../../assets/header_cog.svg";
import ActivityIcon from "../../../../assets/icons/activity-icon.svg";
import HeartFilled from "../../../../assets/icons/heart-filled.svg";
import HeartOutline from "../../../../assets/icons/heart-outline.svg";
import useAuthUser from "../../../common/hooks/useAuthUser";
import usePaths from "../../../common/hooks/usePaths";
import Message from "../../../common/Message";
import useSpacesManager from "../../hooks/useSpacesManager/useSpacesManager";
import ErrorIcon from "../SpaceConsoleContext/ErrorIcon";
import { useSpaceContext, useStableSpaceContext } from "../SpaceContext";

export const Button = styled.div`
  line-height: 0;
  cursor: pointer;
  color: ${props => props.theme.headerButtonColor};

  &:hover {
    color: ${props => props.theme.primaryColorDark};
  }
`;

export const Actions = () => {
  const { authUser, isAdmin } = useAuthUser();
  const { space } = useSpaceContext();
  const { spaceId } = useStableSpaceContext();
  const { favorite, unfavorite } = useSpacesManager();
  const isSpaceEditor = authUser?.scopes?.includes("space:admin");
  const paths = usePaths();
  const { spaceSlug } = useParams<{ spaceSlug: string }>();
  const navigate = useNavigate();

  return (
    <>
      {typeof space.isFavorite === "boolean" && (
        <>
          {space.isFavorite ? (
            <Button
              data-test="spaceFavorite"
              title="Unfavorite space"
              onClick={() => {
                unfavorite(spaceId);
                Message.success("Favorite removed.");
              }}
            >
              <HeartFilled />
            </Button>
          ) : (
            <Button
              data-test="spaceFavorite"
              title="Favorite space"
              onClick={() => {
                favorite(spaceId, true);
                Message.success("Favorite added.");
              }}
            >
              <HeartOutline />
            </Button>
          )}
        </>
      )}
      {isSpaceEditor && (
        <Button
          data-test="editLink"
          title="Edit space"
          onClick={() => navigate(paths.getEditSpace(spaceSlug!))}
        >
          <SettingsIcon />
        </Button>
      )}
      {isAdmin && (
        <Button
          data-test="auditLogLink"
          title="View space activity"
          onClick={() => navigate(paths.getAuditSpace(spaceSlug!))}
        >
          <ActivityIcon />
        </Button>
      )}
    </>
  );
};

export const SpaceTitle = () => {
  const { spaceName } = useStableSpaceContext();

  return (
    <>
      <ErrorIcon />
      {spaceName}
    </>
  );
};
