import React from "react";

import { Checkbox, Input, Tooltip } from "antd";
import styled from "styled-components";

import { AttributeTypes } from "../../../../../../constants";
import { DebouncedTextArea } from "../../../../../common/DebouncedInput";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { Field } from "../ConfigPanel";
import { DebouncedInput } from "../ConfigPanel/styledComponents";
import { InputConfigActionTypes } from "../inputReducer";
import useNestedStatus from "../useNestedStatus";
import { toAttributeType } from "../util";

import { Option, parseOptionsCsv } from "./utils";

const BoolWrapper = styled.div`
  display: flex;

  &:not(:last-child) > * {
    margin-bottom: ${props => props.theme.spacersm};
  }

  > *:last-child {
    margin-left: ${props => props.theme.spacersm};
  }
`;
BoolWrapper.displayName = "BoolWrapper";

interface Props {
  showAllowNull?: boolean;
}

export default function HardcodedOptionFields(props: Props) {
  const { state, dispatch } = useComponentConfigContext();
  const { isFormItem } = useNestedStatus();
  const validationType = state.draftComponent.properties.validation_type;
  const isBoolean = toAttributeType(validationType) === AttributeTypes.BOOL;
  const disableAllowNull = isFormItem && !state.draftComponent.properties.allow_blank;

  const options = React.useMemo(() => {
    return parseOptionsCsv(state.draftComponent.properties.hardcoded_options || "");
  }, [state.draftComponent.properties.hardcoded_options]);

  return (
    <>
      {isBoolean ? (
        <Field>
          <label>Options (include display name and value, separated by a comma)</label>
          {options.map((option, i) => {
            return (
              <BoolWrapper key={`${option.value}`}>
                <DebouncedInput
                  data-test="labelInput"
                  key={"label" + i}
                  onChange={value => {
                    const updatedOptionsStr = options
                      .map((o: Option) => {
                        // update label if value matches current option
                        if (o.value === option.value) {
                          return `${value}, ${o.value}`;
                        }
                        return `${o.label}, ${o.value}`;
                      })
                      .join("\n");

                    dispatch({
                      type: "SET_DRAFT_COMPONENT",
                      payload: {
                        path: "properties.hardcoded_options",
                        value: updatedOptionsStr
                      }
                    });
                  }}
                  value={option.label}
                />
                <Input data-test="valueInput" value={option.value || ""} disabled />
              </BoolWrapper>
            );
          })}
        </Field>
      ) : (
        <Field>
          <label>Options (include display name and value, separated by a comma)</label>
          <DebouncedTextArea
            data-test="hardcodedValuesInput"
            value={state.draftComponent.properties.hardcoded_options}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.hardcoded_options",
                  value
                }
              })
            }
            placeholder="Display Name, Actual Value"
            autosize={{
              minRows: 3
            }}
          />
        </Field>
      )}
      {props.showAllowNull && (
        <Field>
          <Tooltip
            title={
              disableAllowNull
                ? "To enable this option, update validation to allow this field to be blank."
                : undefined
            }
          >
            <Checkbox
              checked={state.draftComponent.properties.allow_null}
              disabled={disableAllowNull}
              onChange={e =>
                dispatch({
                  type: InputConfigActionTypes.SET_ALLOW_NULL,
                  payload: {
                    allow_null: e.target.checked
                  }
                })
              }
            >
              Include null option
            </Checkbox>
          </Tooltip>
        </Field>
      )}
    </>
  );
}
