import React from "react";

import styled from "styled-components";

import ColorPicker, { DEFAULT_COLOR_OPTIONS } from "../../../../../common/ColorPicker";
import IconButton from "../../../../../common/IconButton";
import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError";
import {
  ConditionalExpression,
  ConditionalOperator as ConditionalOperatorType
} from "../../common/Conditionals";
import { ConfigSection } from "../../common/ConfigPanel";
import { StyleField } from "../../common/DesignConfig/styledComponents";
import { ConditionalStyle } from "../../common/DesignConfig/types";

import { ensureStatConfigState } from "./reducer";

const createStatCondtionalStyle = (componentSlug: string): ConditionalStyle => ({
  conditional_expression: {
    type: "managed",
    subject_template: `${componentSlug}.value`,
    operator: ConditionalOperatorType.Equals,
    object_template: "``"
  },
  rule_sets: [{ target: "stat_text", style: {} }]
});

export default function DesignConfig() {
  const context = useComponentConfigContext();
  const state = ensureStatConfigState(context.state);
  const {
    draftComponent: {
      properties: { conditional_styles }
    }
  } = state;
  return (
    <ConfigSection
      title="Style"
      onAdd={() => {
        const conditionalStyle = createStatCondtionalStyle(state.draftComponent.slug);
        context.dispatch({
          type: "SET_DRAFT_COMPONENT",
          payload: {
            path: "properties.conditional_styles",
            value: conditional_styles.concat(conditionalStyle)
          }
        });
      }}
    >
      {conditional_styles.map((cs, i) => (
        <ConditionalStyleItem
          key={i}
          index={i}
          value={cs}
          onChange={(value: ConditionalStyle) => {
            const nextConditionalStyles = [...conditional_styles];

            nextConditionalStyles.splice(i, 1, value);
            context.dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.conditional_styles",
                value: nextConditionalStyles
              }
            });
          }}
          onRemove={() => {
            const nextConditionalStyles = [...conditional_styles];
            nextConditionalStyles.splice(i, 1);
            context.dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.conditional_styles",
                value: nextConditionalStyles
              }
            });
          }}
        />
      ))}
    </ConfigSection>
  );
}

function ConditionalStyleItem({
  value,
  index,
  onChange,
  onRemove
}: {
  value: ConditionalStyle;
  index: number;
  onChange: (value: ConditionalStyle) => void;
  onRemove: () => void;
}) {
  const statRuleSet = value.rule_sets.find(c => c.target === "stat_text");
  if (statRuleSet === undefined) {
    console.warn("Unexpected rulesets in conditional style.", value);
    return null;
  }

  return (
    <StyleField>
      <label>Color</label>
      <FlexContainer>
        <ColorPickerContainer>
          <ColorPicker
            value={statRuleSet.style.color || DEFAULT_COLOR_OPTIONS[0]}
            onChange={(color: string) =>
              onChange({
                ...value,
                rule_sets: [
                  {
                    ...value.rule_sets[0],
                    style: { color }
                  }
                ]
              })
            }
          />
        </ColorPickerContainer>
        <ConditionalContainer>
          <ConditionalExpression
            expression={value.conditional_expression}
            onChange={conditional_expression =>
              onChange({ ...value, conditional_expression })
            }
          />
        </ConditionalContainer>
        <RemoveButton icon="minus" title="Remove item from list" onClick={onRemove} />
      </FlexContainer>
      <ValidationError field="CONDITIONAL_STYLES" index={index} />
    </StyleField>
  );
}

const FlexContainer = styled.div`
  display: flex;
`;

const ColorPickerContainer = styled.div`
  flex-basis: content;
`;

const ConditionalContainer = styled.div`
  flex-grow: 1;
  flex-basis: content;
  min-width: 1px;
  padding: 0 ${props => props.theme.spacersm};
  & > * {
    width: 100%;
  }
`;

const RemoveButton = styled(IconButton)`
  flex-basis: content;
`;
