import React from "react";

import { SpaceComponentObject } from "../../../../../types";
import { SUPERFICIAL_COMPONENT_TYPES } from "../constants";

const ComponentPathContext = React.createContext("");

export default ComponentPathContext;

export const useComponentPathContext = () => React.useContext(ComponentPathContext);

export function createComponentPath(
  parentPath: string,
  component: SpaceComponentObject | undefined,
  options: { collectionKey?: string; index?: number; itemKey?: string }
) {
  const { collectionKey, index, itemKey } = options;
  if (!component) return "";
  const ownPathPart = SUPERFICIAL_COMPONENT_TYPES.includes(component.type)
    ? ""
    : component.slug;
  if (!collectionKey && !itemKey) {
    return [parentPath, ownPathPart].filter(p => !!p).join(".");
  } else if (!!collectionKey) {
    const collectionPath = `${collectionKey}[${
      typeof index === "number" ? index : `"${index}"`
    }]`;

    return [parentPath, collectionPath].filter(p => !!p).join(".");
  } else {
    return [parentPath, itemKey].filter(p => !!p).join(".");
  }
}

// TODO: Use createPath or parsePath
export function useNextComponentPath(
  component: SpaceComponentObject | undefined,
  collectionKey?: string,
  index?: number,
  itemKey?: string
) {
  const parentComponentPath = useComponentPathContext();
  return createComponentPath(parentComponentPath, component, {
    collectionKey,
    index,
    itemKey
  });
}

// TODO: Use createPath or parsePath
export function extractIterationIndexes(path: string) {
  return path.split(".").map(p => {
    const match = p.match(/\[[0-9]+\]$/);
    return match === null ? null : Number(match[0].replace("[", "").replace("]", ""));
  });
}

export function getIsFirstInstance(path: string) {
  return extractIterationIndexes(path).every(index => index === null || index === 0);
}

export function useIsFirstInstance() {
  return getIsFirstInstance(useComponentPathContext());
}
