import gql from "graphql-tag";

export const SELECTED_FUNCTION_QUERY = gql`
  query SelectedFunctionQuery($id: ID!) {
    function(id: $id) {
      __typename
      id
      name
      title
      isUserGenerated
      dataSource {
        __typename
        id
        name
        integration
      }
      functionParameters {
        edges {
          node {
            id
            name
            type
            required
          }
        }
      }
    }
  }
`;
