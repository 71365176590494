import React from "react";

import { Button as AntButton } from "antd";
import { ButtonProps } from "antd/lib/button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export interface Action extends ButtonProps {
  label?: string;
  icon: string;
  href?: string;
  onClick?: () => void;
}

type Props = Action;

const Button = styled(AntButton)`
  margin-left: ${props => props.theme.spacersm};
`;

export default function ActionButton({
  label,
  icon,
  size = "default",
  type = "default",
  onClick,
  href,
  ...rest
}: Props) {
  const navigate = useNavigate();
  return (
    <Button
      size={size}
      type={type}
      icon={icon}
      {...rest}
      onClick={() => {
        if (typeof onClick === "function") return onClick();
        if (typeof href === "string") return navigate(href);
        throw new Error("Action requires an onClick function or href");
      }}
    >
      {label}
    </Button>
  );
}
