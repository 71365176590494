import React from "react";

import { useQuery } from "@apollo/react-hooks";

import { EnvironmentNode } from "../../../../types";
import useDebouncedValue from "../../../common/hooks/useDebouncedValue";
import Message from "../../../common/Message";
import {
  createInitialState,
  FunctionAttribute,
  FunctionParameter,
  FunctionPermission,
  functionPoliciesForSpaceReducer,
  FunctionPolicyActionTypes
} from "../../../common/Permissions/reducer";

import {
  FunctionsForSpaceNodeVersionData,
  FUNCTIONS_FOR_SPACE_NODE_VERSION,
  FunctionsForSpaceLatestVersionData,
  FUNCTIONS_FOR_SPACE_LATEST_VERSION,
  FunctionNode,
  Identifiable,
  RoleFunctionPermissions
} from "./queries";

export enum EnvironmentOptionType {
  Environment,
  LastSavedVersion
}

export interface EnvironmentOption {
  type: EnvironmentOptionType.Environment;
  payload: EnvironmentNode;
}

export interface LastSavedOption {
  type: EnvironmentOptionType.LastSavedVersion;
}

export type EnvironmentSelectOption = EnvironmentOption | LastSavedOption;

export interface SpaceFunctionsArguments {
  spaceId: string;
  environment: EnvironmentSelectOption;
  roleId?: string;
  first?: number;
  offset?: number;
  searchText?: string;
}

// Sort order is required first, then name alphabetical
export function parameterRequiredAlphaComparator(
  lhs: FunctionParameter,
  rhs: FunctionParameter
): number {
  if (lhs.required && !rhs.required) {
    return -1;
  }

  if (!lhs.required && rhs.required) {
    return 1;
  }

  return lhs.name.localeCompare(rhs.name);
}

export function functionPermissionsInputFromNode(
  node: FunctionNode
): FunctionPermission {
  let allowsAll = false;
  const attributes = new Map<string, FunctionAttribute>();
  const parameters = new Map<string, FunctionParameter>();
  let parametersPermitted = 0;
  let attributesPermitted = 0;

  let policy: RoleFunctionPermissions & Identifiable = {
    id: "",
    allowsAll: false,
    policyFunctionAttributes: { edges: [] },
    policyFunctionParameters: { edges: [] }
  };

  if (node.policies.edges.length) {
    policy = node.policies.edges[0].node;
  }

  allowsAll = policy.allowsAll;

  // Set all of the potential attributes
  for (const { node: attribute } of node.functionAttributes.edges) {
    attributes.set(attribute.id, {
      id: attribute.id,
      name: attribute.name,
      permitted: false
    });
  }

  // If it is in the policies, then it is permitted already
  for (const { node: attributePolicy } of policy.policyFunctionAttributes.edges) {
    const existingAttribute = attributes.get(attributePolicy.functionAttribute.id);
    if (existingAttribute) {
      existingAttribute.permitted = true;
      attributesPermitted++;
    }
  }

  // Set all of the potential parameters
  for (const { node: parameter } of node.functionParameters.edges) {
    parameters.set(parameter.id, {
      id: parameter.id,
      name: parameter.name,
      permitted: false,
      required: parameter.required
    });
  }

  // If it is in the policies, then it is permitted already
  for (const { node: parameterPolicy } of policy.policyFunctionParameters.edges) {
    const existingParameter = parameters.get(parameterPolicy.functionParameter.id);
    if (existingParameter) {
      existingParameter.permitted = true;
      parametersPermitted++;
    }
  }

  return {
    functionId: node.id,
    name: node.title,
    dataSource: node.dataSource.name,
    allowsAll: allowsAll,
    // Sort the parameters to required first, then alphabetical
    parameters: [...parameters.values()].sort(parameterRequiredAlphaComparator),
    parametersPermitted: parametersPermitted,
    // We don't sort attributes since their order is based on a pre-existing index
    attributes: [...attributes.values()],
    attributesPermitted: attributesPermitted
  };
}

export function useFunctionPoliciesForSpace({
  spaceId,
  environment,
  roleId,
  first,
  offset,
  searchText
}: SpaceFunctionsArguments) {
  const useEnvironmentData =
    roleId !== undefined && environment.type !== EnvironmentOptionType.LastSavedVersion;
  const environmentOption = environment as EnvironmentOption;
  const environmentId = environmentOption.payload
    ? environmentOption.payload.id
    : undefined;

  const [state, dispatch] = React.useReducer(
    functionPoliciesForSpaceReducer,
    createInitialState()
  );

  const [lastValidRoleId, setLastValidRoleId] = React.useState<string | undefined>();
  const [lastValidEnvironmentId, setLastValidEnvironmentId] = React.useState<
    string | undefined
  >();

  // This is a work-around for apollo which doesn't work nicely
  // when changing skip off and on
  React.useEffect(() => {
    if (roleId === undefined || roleId === lastValidRoleId) {
      return;
    }

    setLastValidRoleId(roleId);
  }, [roleId, lastValidRoleId, setLastValidRoleId]);

  React.useEffect(() => {
    if (environmentId === undefined || environmentId === lastValidEnvironmentId) {
      return;
    }

    setLastValidEnvironmentId(environmentId);
  }, [environmentId, lastValidEnvironmentId, setLastValidEnvironmentId]);

  const debouncedSearchText = useDebouncedValue(searchText, 200);

  const {
    data: environmentData,
    loading: environmentLoading,
    error: environmentError
  } = useQuery<FunctionsForSpaceNodeVersionData>(FUNCTIONS_FOR_SPACE_NODE_VERSION, {
    fetchPolicy: "cache-and-network",
    variables: {
      spaceId: spaceId,
      environmentId: lastValidEnvironmentId,
      roleId: lastValidRoleId,
      first: first,
      offset: offset,
      searchText: debouncedSearchText
    },
    skip: lastValidRoleId === undefined || lastValidEnvironmentId === undefined
  });

  const { data, loading, error } = useQuery<FunctionsForSpaceLatestVersionData>(
    FUNCTIONS_FOR_SPACE_LATEST_VERSION,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        spaceId: spaceId,
        roleId: lastValidRoleId,
        first: first,
        offset: offset,
        searchText: debouncedSearchText
      },
      skip: lastValidRoleId === undefined
    }
  );

  React.useEffect(() => {
    const message = environmentData?.node.publishedVersion.message;
    if (message) Message.error(message);
  }, [data, environmentData]);

  const pendingRole = roleId !== lastValidRoleId;
  const pendingEnvironment = environmentId !== lastValidEnvironmentId;

  React.useEffect(() => {
    let functionNodes: FunctionNode[] = [];
    let totalCount = 0;

    // Don't display previous loaded data if we are loading new data
    if (pendingRole || (useEnvironmentData && pendingEnvironment)) {
      totalCount = 0;
      functionNodes = [];
    } else if (useEnvironmentData && environmentData) {
      totalCount = environmentData.node.publishedVersion.functions?.totalCount || 0;
      functionNodes =
        environmentData.node.publishedVersion.functions?.edges.map(
          ({ node }) => node
        ) || [];
    } else if (data) {
      totalCount = data.node.latestVersion.functions?.totalCount || 0;
      const functions = data.node.latestVersion.functions?.edges || [];
      functionNodes = functions.map(({ node }) => node);
    }

    dispatch({
      type: FunctionPolicyActionTypes.LOAD_EXISTING_POLICIES,
      payload: {
        functions: functionNodes.map(func => functionPermissionsInputFromNode(func)),
        totalCount: totalCount
      }
    });
  }, [useEnvironmentData, environmentData, data, pendingRole, pendingEnvironment]);

  return {
    state: state,
    loading: environmentLoading || loading,
    error: environmentError || error,
    dispatch
  };
}
