import { SpaceComponentObject } from "../../../../../../types";

import { hasValidSource } from "./util";

export default function selectViewConfigErrors(component: SpaceComponentObject) {
  const errors = [];
  if (!hasValidSource(component)) {
    errors.push({
      field: "SOURCE",
      message: "Please select a source to provide data for your component."
    });
  }
  return errors;
}
