import React from "react";

import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import styled from "styled-components";

import { DARK_THEME_POPPER_PORTAL_ID } from "../../../constants";

export const DarkModal = styled(Modal)`
  .ant-modal-body {
    color: ${props => props.theme.textColorOnDarkModal};

    border-radius: ${props => props.theme.spacerxs} ${props => props.theme.spacerxs} 0px
      0px;
    padding-bottom: 0px;
    padding: ${props => props.theme.spacermd} ${props => props.theme.spacermd} 0px
      ${props => props.theme.spacermd};
  }

  .ant-modal-footer {
    border: 0px;
    padding: ${props => props.theme.spacermd};
  }
`;

export const Title = styled.p`
  color: ${props => props.theme.textColorOnDarkModal};
  font-weight: bold;
`;

interface Props extends ModalProps {
  content: string | React.ReactNode;
}

// TODO: Deprecate this component & migrate entirely to dark theme
export default function DarkThemeModal(props: Props) {
  const container = document.getElementById(DARK_THEME_POPPER_PORTAL_ID);
  return (
    <DarkModal
      {...props}
      getContainer={container}
      mask={false}
      title={null}
      maskClosable
    >
      <Title>{props.title}</Title>
      {props.content}
    </DarkModal>
  );
}
