import gql from "graphql-tag";

export type DeleteResult = {
  deleteDataSource: {
    ok: boolean;
    message: string;
  };
};

export interface DeleteVars {
  dataSourceId: string;
  confirm: string;
}

export const DELETE_DATA_SOURCE_MUTATION = gql`
  mutation DeleteDataSourceMutation($dataSourceId: ID!, $confirm: String!) {
    deleteDataSource(dataSourceId: $dataSourceId, confirm: $confirm) {
      ok
      message
    }
  }
`;
