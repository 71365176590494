import React from "react";

import { Input, Radio, Button, Icon } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { snakeCase } from "lodash";

import { FormItem } from "../../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../../util/ClientValidator";

import { StyledUpload } from "./GoogleServiceAccountFieldSet";
import { CredentialFormProps } from "./types";

export const ACCOUNT_FIELD = "account";
export const USER_FIELD = "user";
export const AUTHENTICATOR_FIELD = "authenticator";
export const PASSWORD_FIELD = "password";
export const PRIVATE_KEY_FIELD = "privateKey";
export const PRIVATE_KEY_PASSWORD_FIELD = "privateKeyPassword";
export const DATABASE_FIELD = "database";
export const WAREHOUSE_FIELD = "warehouse";
export const ROLE_FIELD = "role";

export default function SnowflakeFields(props: CredentialFormProps) {
  const credentials = props.credentials || {};
  const [authenticator, setAuthenticator] = React.useState(
    credentials.authenticator || PASSWORD_FIELD
  );
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const { getFieldDecorator } = props.form;

  return (
    <>
      <FormItem label={props.includeLabel ? "Account" : undefined}>
        {getFieldDecorator(ACCOUNT_FIELD, {
          initialValue: credentials.account,
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Input
            placeholder="Account (example: xy12345.us-central1.gcp)"
            disabled={props.isLoading}
          />
        )}
      </FormItem>
      <FormItem label={props.includeLabel ? "User" : undefined}>
        {getFieldDecorator(USER_FIELD, {
          initialValue: credentials.user,
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(<Input placeholder="User" disabled={props.isLoading} />)}
      </FormItem>
      <FormItem label={props.includeLabel ? "Authentication Method" : undefined}>
        {getFieldDecorator(AUTHENTICATOR_FIELD, {
          initialValue: credentials.authenticator || snakeCase(PASSWORD_FIELD),
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(
          <Radio.Group
            onChange={evt => {
              setAuthenticator(evt.target.value);
            }}
          >
            <Radio.Button value={snakeCase(PASSWORD_FIELD)}>Password</Radio.Button>
            <Radio.Button value={snakeCase(PRIVATE_KEY_FIELD)}>Key Pair</Radio.Button>
          </Radio.Group>
        )}
      </FormItem>
      {authenticator === PASSWORD_FIELD ? (
        <FormItem label={props.includeLabel ? "Password" : undefined}>
          {getFieldDecorator(PASSWORD_FIELD, {
            validateTrigger: "onSubmit",
            rules: [
              {
                required: !props.allowPartialSubmission,
                whitespace: true,
                message: VALIDATION_MESSAGES.requiredField
              }
            ]
          })(
            <Input placeholder="Password" disabled={props.isLoading} type="password" />
          )}
        </FormItem>
      ) : (
        <>
          <FormItem label={props.includeLabel ? "Private Key" : undefined}>
            {getFieldDecorator(PRIVATE_KEY_FIELD, {
              validateTrigger: "onSubmit",
              rules: [
                {
                  required: !props.allowPartialSubmission,
                  whitespace: true,
                  message: VALIDATION_MESSAGES.requiredField
                }
              ]
            })(
              <StyledUpload
                disabled={props.isLoading}
                fileList={fileList}
                showUploadList={true}
                beforeUpload={(file: any) => {
                  file.text().then((file: string) => {
                    props.form.setFieldsValue({ [PRIVATE_KEY_FIELD]: file });
                  });
                  return false;
                }}
                onChange={data => {
                  setFileList([data.file]);
                }}
                onRemove={() => {
                  setFileList([]);
                  props.form.setFieldsValue({ [PRIVATE_KEY_FIELD]: null });
                }}
              >
                <Button disabled={props.isLoading}>
                  <Icon type="upload" /> Upload
                </Button>
              </StyledUpload>
            )}
          </FormItem>
          <FormItem label={props.includeLabel ? "Private Key Password" : undefined}>
            {getFieldDecorator(PRIVATE_KEY_PASSWORD_FIELD, {
              validateTrigger: "onSubmit",
              rules: [
                {
                  required: !props.allowPartialSubmission,
                  whitespace: true,
                  message: VALIDATION_MESSAGES.requiredField
                }
              ]
            })(
              <Input
                placeholder="Password"
                disabled={props.isLoading}
                type="password"
              />
            )}
          </FormItem>
        </>
      )}
      <FormItem label={props.includeLabel ? "Database" : undefined}>
        {getFieldDecorator(DATABASE_FIELD, {
          initialValue: credentials.database,
          validateTrigger: "onSubmit",
          rules: [
            {
              required: !props.allowPartialSubmission,
              whitespace: true,
              message: VALIDATION_MESSAGES.requiredField
            }
          ]
        })(<Input placeholder="Database" disabled={props.isLoading} />)}
      </FormItem>
      <FormItem label={props.includeLabel ? "Warehouse" : undefined}>
        {getFieldDecorator(WAREHOUSE_FIELD, {
          initialValue: credentials.warehouse,
          validateTrigger: "onSubmit"
        })(<Input placeholder="Warehouse" disabled={props.isLoading} />)}
      </FormItem>
      <FormItem label={props.includeLabel ? "Role" : undefined}>
        {getFieldDecorator(ROLE_FIELD, {
          initialValue: credentials.role,
          validateTrigger: "onSubmit"
        })(<Input placeholder="Role" disabled={props.isLoading} />)}
      </FormItem>
    </>
  );
}
