import Icon from "../../../../../assets/spaceicons/file-picker.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";

import Config, { InlineConfig, TerseConfig } from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceFilePickerComponent,
  makeInitialState,
  FilePickerConfigState,
  getComponentProperties
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceFilePicker from "./SpaceFilePicker";
import { SpaceFilePickerComponent, SpaceFilePickerProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceFilePickerProperties,
  SpaceFilePickerComponent,
  FilePickerConfigState
> = {
  type: "FILE_PICKER",
  displayName: "File Picker",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FUNCTION_FORM",
    "FUNCTION_MODAL_FORM",
    "FLEX_BOX"
  ]),
  Component: SpaceFilePicker,
  ConfigurationComponent: Config,
  TerseConfigurationComponent: TerseConfig,
  InlineConfigurationComponent: InlineConfig,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceFilePickerComponent,
  getComponentProperties,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.INPUT.width,
    height: DEFAULT_SIZES.INPUT.height,
    snapToGrid: false
  }),
  fallbackWidth: 132,
  fallbackHeight: 32,
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Input],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
