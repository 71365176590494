import React, { useCallback } from "react";

import { Checkbox, Collapse } from "antd";
import invariant from "invariant";

import {
  APIFiltersOption,
  APISortByOption,
  FunctionParameterInput
} from "../../../../types";
import { AttributeTypeSelect } from "../../AttributeTypeSelect";
import { DisabledMessage } from "../common/DisabledMessage";
import * as common from "../common/styledComponents";
import { ReservedListIdentifierMap, ReservedListParameter } from "../index";

import { FiltersOptions } from "./FiltersOptions/FiltersOptions";
import { SortByOptions } from "./SortByOptions/SortByOptions";
import * as styled from "./styledComponents";

export interface Props {
  editable: boolean;
  reservedEditable: boolean;
  parameters: FunctionParameterInput[];
  onParameterChange: (parameter: FunctionParameterInput) => void;
  filtersOptions: APIFiltersOption[];
  onFiltersOptionsChange: (filtersOptions: APIFiltersOption[]) => void;
  sortByOptions: APISortByOption[];
  onSortByOptionsChange: (sortByOptions: APISortByOption[]) => void;
  isMutation: boolean;
  onIsMutationChange: (isMutation: boolean) => void;
}

export const FunctionInput = ({ parameters, onParameterChange, ...props }: Props) => {
  const setParameterAttribute = useCallback(
    function <T>(name: string, property: keyof FunctionParameterInput, value: T) {
      const idx = parameters.findIndex(p => p.name === name);
      invariant(idx !== -1, "parameter must exist");
      onParameterChange({ ...parameters[idx], [property]: value });
    },
    [onParameterChange, parameters]
  );

  const unreserved = parameters.filter(
    p => !ReservedListIdentifierMap[p.name as ReservedListParameter]
  );

  return (
    <div>
      <styled.CategoriesContent>
        <Checkbox
          checked={props.isMutation}
          onChange={e => props.onIsMutationChange(e.target.checked)}
        >
          Works with forms and buttons
        </Checkbox>
      </styled.CategoriesContent>
      {!props.editable && (
        <DisabledMessage message="Inputs are not editable because they are automatically generated." />
      )}
      {props.editable && !parameters.length && (
        <div>Configure the inputs of your function.</div>
      )}
      <Collapse bordered={false} defaultActiveKey="parameters">
        {unreserved.length && (
          <Collapse.Panel key="parameters" header="Parameters">
            <styled.Section data-test="parametersPane">
              <styled.ParametersContent>
                <common.FieldColumnHeader>Name</common.FieldColumnHeader>
                <common.FieldColumnHeader>Type</common.FieldColumnHeader>
                <common.FieldColumnHeader>Required</common.FieldColumnHeader>
                {unreserved.map(p => (
                  <React.Fragment key={`parameter-${p.name}`}>
                    <div>{p.name}</div>
                    <AttributeTypeSelect
                      value={p.type}
                      onChange={type => setParameterAttribute(p.name, "type", type)}
                    />
                    <common.Checkbox
                      checked={p.required}
                      onChange={evt =>
                        setParameterAttribute(p.name, "required", evt.target.checked)
                      }
                    />
                  </React.Fragment>
                ))}
              </styled.ParametersContent>
            </styled.Section>
          </Collapse.Panel>
        )}
        {parameters.some(
          p =>
            p.name === ReservedListParameter.FILTERS ||
            p.name === ReservedListParameter.FILTER
        ) && (
          <Collapse.Panel key="filters" header="Filters (reserved)">
            <styled.Section>
              <FiltersOptions
                editable={props.editable && props.reservedEditable}
                options={props.filtersOptions}
                onOptionsChange={props.onFiltersOptionsChange}
              />
            </styled.Section>
          </Collapse.Panel>
        )}
        {parameters.some(p => p.name === ReservedListParameter.SORT_BY) && (
          <Collapse.Panel key="sortBy" header="Sorting (reserved)">
            <styled.Section>
              <SortByOptions
                editable={props.editable && props.reservedEditable}
                options={props.sortByOptions}
                onOptionsChange={props.onSortByOptionsChange}
              />
            </styled.Section>
          </Collapse.Panel>
        )}
      </Collapse>
    </div>
  );
};
