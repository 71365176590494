import {
  SpaceComponentObject,
  ConfigValidationError
} from "../../../../../../../types";
import { ComponentConfigState } from "../../../../../types";
import selectFiltersErrors from "../../../common/FilterListManager/selectErrors";
import selectParameterErrors from "../../../common/ParametersManager/selectors/selectErrors";
import selectVisibilityRuleErrors from "../../../common/VisibilityRulesManager/selectErrors";
import { ensureImageConfigState } from "../reducer/reducer";

export const errorSelector = (
  state: ComponentConfigState,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[]
): ConfigValidationError[] => {
  const { draftComponent, filterValidityTrackers } = ensureImageConfigState(state);
  const errors: ConfigValidationError[] = [];
  if (!draftComponent.properties.template) {
    errors.push({ field: "TEMPLATE", message: "Please add a template." });
  }

  if (draftComponent.properties.columns === null) {
    errors.push({
      field: "SOURCE",
      message: "Please wait for your source to finish loading."
    });
  }

  return errors
    .concat(
      selectFiltersErrors(
        filterValidityTrackers,
        draftComponent,
        findInvalidInputBindings
      )
    )
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings));
};
