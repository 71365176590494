import React from "react";

import { Tooltip, Icon } from "antd";
import styled from "styled-components";

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.warningColor};
  margin-right: ${props => props.theme.spacerxs};
`;

interface WarningIconProps {
  tooltip: string;
  className?: string;
}

export default function WarningIcon(props: WarningIconProps) {
  return (
    <Tooltip
      title={props.tooltip}
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
    >
      <StyledIcon type="warning" className={props.className} />
    </Tooltip>
  );
}
