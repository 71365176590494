import React from "react";

import { AttributeValueBaseProps } from "..";

export default function BooleanValue({
  className = "",
  value
}: AttributeValueBaseProps) {
  return <div className={className}>{String(value)}</div>;
}
