import React from "react";

import { CascaderOptionType, CascaderProps } from "antd/lib/cascader";

import { BindingShape } from "../../../types";
import { createPath, parsePath } from "../../util/binding";

import * as styled from "./styledComponents";
import { filterByShape, Option } from "./util";

export interface Props
  extends Omit<
    CascaderProps,
    "options" | "getPopupContainer" | "onChange" | "value" | "defaultValue"
  > {
  options: Option[];
  selectable?: BindingShape[];
  children?: React.ReactNode;
  defaultValue?: string;
  value?: string;
  onChange?: (
    path: string,
    parts: string[],
    selectedOptions?: CascaderOptionType[]
  ) => void;
  popupContainerFactory?: (
    domId?: string | undefined
  ) => ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}

export function BindingCascader({
  options,
  value,
  defaultValue,
  selectable = [BindingShape.SCALAR],
  onChange = () => {},
  popupContainerFactory = () => undefined,
  ...rest
}: Props) {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const filtered = React.useMemo(
    () => filterByShape(options, new Set(selectable)),
    [options, selectable]
  );

  const placeholder = filtered.length ? "Select a binding" : "No bindings available";

  const _onChange = (parts: string[], selectedOptions?: CascaderOptionType[]) =>
    onChange(createPath(parts), parts, selectedOptions);

  return (
    <styled.Root ref={ref}>
      <styled.Cascader
        {...(filtered.length ? rest : {})}
        defaultValue={defaultValue && parsePath(defaultValue)}
        value={value && parsePath(value)}
        onChange={_onChange}
        getPopupContainer={popupContainerFactory() || (() => ref.current)}
        options={filtered}
        placeholder={placeholder}
        disabled={!filtered.length}
        data-test="binding-cascader"
      />
    </styled.Root>
  );
}
