import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import { selectColumnErrors } from "../../common/ColumnListManager";
import { ensureFilterListManagerState } from "../../common/FilterListManager/reducer/reducer";
import selectFiltersErrors from "../../common/FilterListManager/selectErrors";
import selectParameterErrors from "../../common/ParametersManager/selectors/selectErrors";
import { selectViewConfigErrors } from "../../common/useViewConfig";
import { ensureViewConfigState } from "../../common/useViewConfig/reducer";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

export default function errorSelector(
  state: ComponentConfigState,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[]
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { draftComponent } = state;
  return errors
    .concat(
      selectFiltersErrors(
        ensureFilterListManagerState(state).filterValidityTrackers,
        draftComponent,
        findInvalidInputBindings
      )
    )
    .concat(
      selectColumnErrors(
        draftComponent,
        findInvalidInputBindings,
        "FIELDS",
        ensureViewConfigState(state).attributes
      )
    )
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(selectViewConfigErrors(draftComponent))
    .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings));
}
