import gql from "graphql-tag";

import { QUEUE_NODE_FRAGMENT, QueueNode } from "./common";

export interface Data {
  queueBySlug: QueueNode;
}

export interface Variables {
  slug: string;
}

export const QUERY = gql`
  query QueueBySlug($slug: String!) {
    queueBySlug(slug: $slug) {
      ...QueueNodeFragment
    }
  }
  ${QUEUE_NODE_FRAGMENT}
`;
