import React, { Dispatch } from "react";

import styled from "styled-components";

import { H4 } from "../../../common/StyledComponents";
import { ReducerAction } from "../reducer/actions";
import { ConfigState } from "../reducer/reducer";

import Fields from "./Fields";
import Form from "./Form";

export const Title = styled(H4)`
  margin-bottom: ${props => props.theme.spacerlg};
`;

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ReducerAction>;
}

export const Data = ({ state, dispatch }: Props) => (
  <div>
    <Title>Data</Title>
    <Fields state={state} dispatch={dispatch} />
    <Form state={state} dispatch={dispatch} />
  </div>
);
