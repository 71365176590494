import React from "react";

import { Select } from "antd";

import { SpacingUnit } from "../../../../../../cssConstants";
import { ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import {
  useComponentConfigContext,
  ComponentConfigContextContainer
} from "../../common/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import { Field, Checkbox } from "../../common/ConfigPanel/styledComponents";
import NameFields from "../../common/NameFields";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import ChildrenSection from "./ChildrenSection";
import { ensureFlexBoxConfigState } from "./reducer";

const MemoDataConfig = React.memo(SpaceFlexBoxConfigContent);
const MemoDesignConfig = React.memo(DesignConfig);
export default function SpaceFlexBoxConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<MemoDataConfig />}
      dataTabTitle="Main"
      designConfig={<MemoDesignConfig />}
      slug={slug}
    />
  );
}

function SpaceFlexBoxConfigContent() {
  return (
    <>
      <BaseComponentConfigSection>
        <NameFields />
      </BaseComponentConfigSection>
      <ChildrenSection />
      <VisibilityRulesManagerSection />
    </>
  );
}

type FlexDirectionOption = "row" | "column";

type JustifyContentOption =
  | undefined
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly";

type AlignItemsOption =
  | undefined
  | "stretch"
  | "flex-start"
  | "flex-end"
  | "center"
  | "baseline";

function DesignConfig() {
  const context = useComponentConfigContext();
  const {
    draftComponent: {
      properties: { style }
    }
  } = ensureFlexBoxConfigState(context.state);

  const handleChange = React.useCallback(
    (change: React.CSSProperties) => {
      context.dispatch({
        type: "MERGE_DRAFT_COMPONENT",
        payload: {
          change: {
            properties: {
              style: {
                ...style,
                ...change
              }
            }
          }
        }
      });
    },
    [context, style]
  );

  return (
    <ConfigSection title="Children">
      <Field>
        <label>Lay out in</label>
        <Select
          value={
            style.flexDirection === undefined
              ? "row"
              : (style.flexDirection as FlexDirectionOption)
          }
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          onChange={(flexDirection: FlexDirectionOption) => {
            handleChange({
              flexDirection
            });
          }}
        >
          <Select.Option key="row" value="row">
            Horizontal Direction
          </Select.Option>
          <Select.Option key="column" value="column">
            Vertical Direction
          </Select.Option>
        </Select>
      </Field>
      <Field>
        <label>
          {style.flexDirection === "column" ? "Align Vertically" : "Align Horizontally"}
        </label>
        <Select
          value={
            (style.justifyContent === undefined
              ? "flex-start"
              : style.justifyContent) as JustifyContentOption
          }
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          onChange={(justifyContent: JustifyContentOption) =>
            handleChange({ justifyContent })
          }
        >
          <Select.Option key="flex-start" value="flex-start">
            {style.flexDirection === "column" ? "Top" : "Left"}
          </Select.Option>
          <Select.Option key="flex-end" value="flex-end">
            {style.flexDirection === "column" ? "Bottom" : "Right"}
          </Select.Option>
          <Select.Option key="center" value="center">
            Center
          </Select.Option>
          <Select.Option key="space-between" value="space-between">
            Space Between
          </Select.Option>
          <Select.Option key="space-around" value="space-around">
            Space Around
          </Select.Option>
          <Select.Option key="space-evenly" value="space-evenly">
            Space Evenly
          </Select.Option>
        </Select>
      </Field>
      <Field>
        <label>
          {style.flexDirection === "column" ? "Align Horizontally" : "Align Vertically"}
        </label>
        <Select
          value={
            (style.alignItems === undefined
              ? "flex-start"
              : style.alignItems) as AlignItemsOption
          }
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          onChange={(alignItems: AlignItemsOption) => handleChange({ alignItems })}
        >
          <Select.Option key="flex-start" value="flex-start">
            {style.flexDirection === "column" ? "Left" : "Top"}
          </Select.Option>
          <Select.Option key="flex-end" value="flex-end">
            {style.flexDirection === "column" ? "Right" : "Bottom"}
          </Select.Option>
          <Select.Option key="center" value="center">
            Center
          </Select.Option>
        </Select>
      </Field>
      <Field>
        <label>Padding</label>
        <Select
          value={(style.gap === undefined ? "none" : style.gap) as string}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          onChange={(gap: string) => handleChange({ gap })}
        >
          <Select.Option key="none" value="0">
            None
          </Select.Option>
          <Select.Option key="sm" value={SpacingUnit.sm}>
            Small
          </Select.Option>
          <Select.Option key="md" value={SpacingUnit.md}>
            Medium
          </Select.Option>
          <Select.Option key="lg" value={SpacingUnit.lg}>
            Large
          </Select.Option>
        </Select>
      </Field>
      <Field>
        <Checkbox
          checked={style.flexWrap === "wrap"}
          onChange={({ target: { checked } }) => {
            handleChange({ flexWrap: checked === true ? "wrap" : "nowrap" });
          }}
        >
          Allow wrapping.
        </Checkbox>
      </Field>
    </ConfigSection>
  );
}
