import React from "react";

import classNames from "classnames";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { LeftCol } from "../../../../layouts/Layout";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../../common/hooks/useAuthUser";
import usePaths from "../../../common/hooks/usePaths";
import NavMenu, { List, ListItem, Link } from "../../../common/NavMenu/NavMenu";
import { resolveQueues } from "../index";
import { useAllQueues } from "../queries/AllQueues";

const CtaWrapper = styled.div`
  padding: 0 20px;
`;

export default function LeftNav({
  selected,
  open
}: {
  selected: string;
  open: boolean;
}) {
  const navigate = useNavigate();
  const { queuesapp_getNewQueue, queuesapp_getQueue } = usePaths();
  const { isAdmin } = useAuthUser();
  const { data } = useAllQueues();

  const queues = resolveQueues(data?.allQueues.edges).filter(queue => queue.isListed);

  return (
    <LeftCol darkMode open={open}>
      <NavMenu title="Queues">
        <List>
          {queues.map(q => (
            <ListItem key={q.slug}>
              <Link
                className={classNames({ active: selected === q.slug })}
                to={queuesapp_getQueue(q.slug)}
              >
                {q.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </NavMenu>
      {isAdmin && (
        <CtaWrapper>
          <ButtonNew
            type="brand"
            block
            icon="plus"
            onClick={() => {
              navigate(queuesapp_getNewQueue());
            }}
          >
            Create new queue
          </ButtonNew>
        </CtaWrapper>
      )}
    </LeftCol>
  );
}
