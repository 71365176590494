import { useCallback, useState } from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  Connection,
  EnvironmentDataSourceCredentialNode,
  EnvironmentNode
} from "../../../../types";
import Message from "../../../common/Message";
import { DataSource } from "../../DataSourceTable";

interface Result {
  loading: boolean;
  deleteLoading: boolean;
  allEnvironments: Connection<EnvironmentNode> | undefined;
  allEnvironmentDataSourceCredentials:
    | Connection<EnvironmentDataSourceCredentialNode>
    | undefined;
  deleteCredentials: (p: string) => Promise<any>;
}

export const CREDENTIALS_QUERY = gql`
  query CredentialsForProvider($dataSourceProvider: ID!) {
    allEnvironmentDataSourceCredentials(dataSourceProvider: $dataSourceProvider) {
      edges {
        node {
          id
          credentials
          environment {
            id
            name
            isDefault
          }
        }
      }
    }
    allEnvironments {
      edges {
        node {
          id
          name
          isDefault
        }
      }
    }
  }
`;

export const DELETE_CREDENTIALS_MUTATION = gql`
  mutation DeleteCredentialsDataSourceMutation(
    $dataSourceId: ID!
    $environmentCredentialId: ID!
  ) {
    deleteCredentials(
      dataSourceId: $dataSourceId
      environmentCredentialId: $environmentCredentialId
    ) {
      ok
    }
  }
`;

interface AllEnvironmentDataSourceCredentials {
  allEnvironmentDataSourceCredentials: Connection<EnvironmentDataSourceCredentialNode>;
  allEnvironments: Connection<EnvironmentNode>;
}

export default function useManageCredentialsData(dataSource: DataSource): Result {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { data, loading, refetch } = useQuery<AllEnvironmentDataSourceCredentials>(
    CREDENTIALS_QUERY,
    {
      variables: { dataSourceProvider: dataSource.dataSourceProviderId }
    }
  );
  const [deleteCredentialsMutation] = useMutation(DELETE_CREDENTIALS_MUTATION);

  const deleteCredentials = useCallback(
    async (environmentCredentialId: string) => {
      setDeleteLoading(true);
      try {
        await deleteCredentialsMutation({
          variables: {
            dataSourceId: dataSource.id,
            environmentCredentialId
          }
        });
      } catch {
        setDeleteLoading(false);
        Message.error(
          "An error occurred deleting the credentials. Please try again later"
        );
        return;
      }

      // Note: this is not done in "onComplete" so that this callback can be awaited
      // until the data is deleted, and then refreshed in one go.
      try {
        await refetch({ dataSourceProvider: dataSource.dataSourceProviderId });
        Message.success("Your credentials were deleted.");
      } catch {
        Message.error("An error occurred loading credentials. Please try again later");
      }

      setDeleteLoading(false);
    },
    [deleteCredentialsMutation, refetch, dataSource]
  );

  return {
    loading,
    deleteLoading,
    allEnvironments: data?.allEnvironments,
    allEnvironmentDataSourceCredentials: data?.allEnvironmentDataSourceCredentials,
    deleteCredentials
  };
}
